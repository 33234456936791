import { createStyles, Theme, WithStyles } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme: Theme) => {
    const padding = theme.spacing();

    return createStyles({
        iconButton: {
            color: 'white',
        },
        notificationContainer: {
            display: 'flex',
            flexDirection: 'column',
            maxWidth: '400px',
            wordBreak: 'break-word',
        },
        notificationAlert: {
            zIndex: 1,
        },
        notificationDescription: {
            padding: `${2 * padding}px ${padding}px ${padding}px ${padding}px`,
            borderRadius: '0 0 4px 4px',
            marginTop: -padding,
            zIndex: 0,
        },
    });
};

export type TThemableProps = WithStyles<typeof styles>;

export const Themable = withStyles(styles, { withTheme: true });
