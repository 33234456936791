import React, { ReactNode, useEffect, useRef, useState } from 'react';

import { SelectionChangedEvent } from 'ag-grid-community';

import { useSnackbar } from 'notistack';

import { Divider, LinearProgress, Paper } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import {
    enterPreviewMode,
    resetMode,
} from '../../../../../state/ui/customerService/bottomGrid';

import {
    useSelectedGridItemId,
    useSelectedClientsAndLocations,
    useValidRfidFilter,
    useMap,
} from '../../../../../state/ui/customerService/clientsAndLocations/index.hooks';

import { useAppDispatch, useToolkitDispatch } from '../../../../../hooks';
import TranslationHelper from '../../../../../helpers/TranslationHelper';
import { errorMessageHandler } from '../../../../../helpers/errorMessageHandler';

import { useStyles } from '../../../Themable.hooks';

import IconButtonWithTooltip from '../../../../../components/IconButtonWithTooltip';
import PaneHeader from '../../../../../components/PaneHeader';
import SearchField from '../../../../../components/common/SearchField/SearchField';
import SourceSetGrid from '../../../../../components/SourceSetGrid';
import { ISourceSetEntity } from '../../../../../state/types';
import DateRangeDisplay from '../../../../../components/DateRangeDisplay';
import CscExportButtons from '../../CscExportButtons/CscExportButtons';
import {
    EVENTS_BY_RFID_ID,
    fetchEventsWithQuery,
    selectEvent,
    setDates,
} from '../../../../../state/ui/customerService/bottomGrid/eventsByRfid';
import {
    useEventDates,
    useEvents,
    useEventsStatus,
    useEventsSourceSetModel,
} from '../../../../../state/ui/customerService/bottomGrid/eventsByRfid/index.hooks';
import { activatePreview } from '../../../../../state/_actions';
import preparePreviewAction from '../../../../../state/_actions/preparePreviewAction';
import { zoomAfterSelect } from '../../../helpers/zoomAfterSelect';
import {
    IFeatureSelectedOnGridParams,
    TVectorGeometrySource,
} from '../../../types';
import { useBottomGrid } from '../../../../../state/ui/customerService/bottomGrid/index.hooks';

interface IOwnProps {
    handleClose: () => void;
    titleComponent?: ReactNode;
    onFeatureSelected: (params: IFeatureSelectedOnGridParams) => void;

    source: TVectorGeometrySource;
    selectedItems: string[];
    lastSelectedBottomItem: string;
    handleSelectLastItem: (sourceSetId: string, lastItemId: string) => void;
}

const CHECKBOX_POSITION = 0;

const EventsGridByRfid = ({
    handleClose,
    titleComponent,
    onFeatureSelected,
    source,
    selectedItems,
    lastSelectedBottomItem,
    handleSelectLastItem,
}: IOwnProps) => {
    const toolkitDispatch = useToolkitDispatch();
    const dispatch = useAppDispatch();

    const notInitialRender = useRef(false);

    const sourceSetModel = useEventsSourceSetModel();

    const [filter, setFilter] = useState(sourceSetModel?.quickFilter || '');
    const [gridApi, setGridApi] = useState(undefined);

    const dates = useEventDates();
    const classes = useStyles();
    const events = useEvents();
    const status = useEventsStatus();
    const rfidFilter = useValidRfidFilter();
    const map = useMap();
    const selectedGridItemId = useSelectedGridItemId();
    const selectedClientsAndLocations = useSelectedClientsAndLocations();
    const selectedGrid = useBottomGrid();
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        resetMultiSelection();
    }, [selectedGrid]);

    useEffect(() => {
        if (status !== 'loading') {
            handleFetchEvents(dates.from, dates.to, rfidFilter)
                ?.unwrap()
                .catch((error) => {
                    const message = errorMessageHandler(error.status)();
                    enqueueSnackbar(message, {
                        variant: 'error',
                    });
                });
        }
    }, [selectedClientsAndLocations, rfidFilter, dates.from, dates.to]);

    useEffect(() => {
        if (notInitialRender.current) {
            toolkitDispatch(resetMode());
        } else {
            notInitialRender.current = true;
        }
    }, [selectedGridItemId]);

    const handleDateChange = (from: string, to: string) => {
        toolkitDispatch(setDates({ from, to }));
    };
    const handleFetchEvents = (
        from: string,
        to: string,
        rfidFilter: string | null
    ) => {
        if (!selectedClientsAndLocations.length) {
            return;
        }

        const locationsWithCoordinates = selectedClientsAndLocations.filter(
            (location) => location.coordinates
        );
        return toolkitDispatch(
            fetchEventsWithQuery({
                selectedClientsAndLocations: locationsWithCoordinates,
                from,
                to,
                rfidFilter: rfidFilter || undefined,
            })
        );
    };

    const renderRightCustomControls = () => {
        return (
            <>
                <CscExportButtons api={gridApi} prefix="events" />
                <IconButtonWithTooltip
                    title={TranslationHelper.translate('Close')}
                    onClick={() => handleClose()}
                >
                    <CloseIcon />
                </IconButtonWithTooltip>
            </>
        );
    };

    const onSelectionChanged = (e: SelectionChangedEvent) => {
        const selectedNodes = e.api
            .getSelectedNodes()
            .map((selected) => {
                return {
                    id: selected.data.externalId,
                    name: selected.data.locationName,
                    clientId: selected.data.clientId,
                    clientName: selected.data.name,
                    sourceSetId: selected.data.id,
                    locationId: selected.data.locationId,
                    coordinates: selected.data.coordinate,
                    radius: selected.data.radius,
                };
            })
            .filter(Boolean);
        if (!selectedNodes.length) {
            resetMultiSelection();

            return;
        }
        onFeatureSelected({
            source,
            sourceSetId: EVENTS_BY_RFID_ID,
            items: selectedNodes.map((node) => node.sourceSetId),
        });
    };

    const resetMultiSelection = () => {
        onFeatureSelected({
            source,
            sourceSetId: EVENTS_BY_RFID_ID,
            items: [],
        });
        toolkitDispatch(resetMode());
        toolkitDispatch(selectEvent(null));
    };

    const handleCellInteraction = (data: ISourceSetEntity) => {
        handleSelectLastItem(EVENTS_BY_RFID_ID, data.id);
        zoomAfterSelect(source, map, data.id);
        toolkitDispatch(enterPreviewMode());
        toolkitDispatch(selectEvent(data.id));
        dispatch(
            activatePreview(
                'archiveEvents',
                data.id,
                'sourceSetElement',
                'preview',
                {
                    type: 'source-set-element',
                    level: 0,
                },
                preparePreviewAction(
                    undefined,
                    data,
                    {
                        api: '/rest/api/source-sets/archive-events/{id}',
                        label: 'Show',
                        method: 'GET',
                        componentName: undefined,
                        params: {},
                        _webx: {
                            changeSourceSetDisabled: true,
                        },
                    },
                    data._meta.icon,
                    { id: data.id }
                ),
                false,
                undefined,
                false
            )
        );
    };
    const handleSetApiGrid = (
        gridApi: any,
        gridColumnApi: any | undefined = undefined
    ) => {
        setGridApi(gridApi);
    };

    const multiSelectProps = {
        rowSelection: 'multiple',
        checkBox: { index: CHECKBOX_POSITION },
        onSelectionChanged,
        items: selectedItems.map((item) => {
            return { sourceSetId: item };
        }),
    };

    return (
        <Paper className={classes.gridPane}>
            <div className={classes.wrapper}>
                <PaneHeader
                    title={TranslationHelper.translate('EventsByRfid')}
                    titleComponent={titleComponent}
                    renderLeftCustomControls={() => (
                        <>
                            <SearchField
                                value={filter}
                                filterChange={setFilter}
                            />
                            <DateRangeDisplay
                                onDateChange={handleDateChange}
                                sourceSetId={events?.id}
                                dates={dates}
                                datesFormat={'short'}
                                small
                            />
                        </>
                    )}
                    renderRightCustomControls={() =>
                        renderRightCustomControls()
                    }
                />
                {status === 'loading' ? <LinearProgress /> : <Divider />}
                {status === 'done' && events && (
                    <SourceSetGrid
                        sourceSet={events}
                        cellInteractionHandler={handleCellInteraction}
                        quickFilterValue={filter}
                        setGridApi={handleSetApiGrid}
                        sourceSetModel={sourceSetModel}
                        selectedSourceSetElementId={
                            lastSelectedBottomItem || null
                        }
                        multiSelectProps={multiSelectProps}
                    />
                )}
                {status === 'done' && !events && (
                    <div className={classes.noDataContainer}>
                        {TranslationHelper.translate(
                            "None of selected locations have rfid set or doesn't match rfid filter"
                        )}
                    </div>
                )}
            </div>
        </Paper>
    );
};

export default EventsGridByRfid;
