import URI from 'urijs';
import AppConfig from '../../constants/AppConfig';
import UrlConst from '../../constants/UrlConst';
import { IDeviceAttributes } from '../../pages/management/components/Devices/components/DevicesPane/components/DeviceEditionForm/DeviceEditionForm';

import apiClient from '../common/apiClient';
import {
    makePutRequestSettings,
    makePatchRequestSettings,
    makeUrl,
} from '../common/utils';
import TranslationHelper from '../../helpers/TranslationHelper';
import { IDevice, IntegrationsETollStatus } from '../../state/types';
import { INormalizedData } from '../../state/app/collections/dataTypes';

export const makeDevicesUrl = (suffix: string = '', params = {}) => {
    const url = new URI(
        AppConfig.instance.getUrl(UrlConst.REGISTERS_DEVICES_URL) + suffix
    );
    return makeUrl(url, params);
};

export interface IRestPutDevice {
    id: number;
    attributes?: IDeviceAttributes;
    integrations?: {
        eToll: {
            businessNumber: string;
            pin: string;
        };
    };
}

export interface IRestPatchDevice {
    id: number;
    attributes?: IDeviceAttributes;
}

export const restPutDevice = (device: IRestPutDevice): Promise<null> =>
    apiClient.send(
        makePutRequestSettings(makeDevicesUrl(`${device.id}`), device)
    );
export const restPatchDevices = (devices: IRestPatchDevice[]): Promise<null> =>
    apiClient.send(makePatchRequestSettings(makeDevicesUrl(), devices));

export const makeRestReadDevicesCollection =
    <T extends { id: number; serialNumber: string; recurringId: number }>(
        restGetCollection: () => Promise<T[]>
    ): (() => Promise<INormalizedData<T>>) =>
    () =>
        restGetCollection().then((data) => normalizeCollectionDevices<T>(data));

const normalizeCollectionDevices = <T extends { id: number }>(
    data: IDevice[]
): INormalizedData<T> => {
    return {
        result: data.map(
            ({ id, serialNumber }) => id.toString() + serialNumber
        ),
        entities: data.reduce((result, next) => {
            if (next.integrations?.eToll.status !== undefined) {
                const statusTranslated = TranslationHelper.translate(
                    IntegrationsETollStatus[next.integrations.eToll.status]
                );
                next.integrations.eToll.status = statusTranslated;
            }
            if (next.attributes?.eToll) {
                const eTollTranslated = TranslationHelper.translate(
                    next.attributes.eToll === true ? 'Yes' : 'No'
                );
                next.eTollTranslated = eTollTranslated;
            } else {
                delete next.integrations;
                next.eTollTranslated = TranslationHelper.translate('No');
            }

            result[next.id + next.serialNumber] = next;
            return result;
        }, {}),
    };
};
