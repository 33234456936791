import { makeReduxDuck } from 'teedux';
import { restGetContextTranslations } from '../../../services/translations';
import { makeRequest } from '../sync';

import { TRootState } from '../../../store';

const translationsStorePath = `app/translations`;

export interface ITranslations {
    [key: string]: string;
}

export interface IContextTranslations {
    [key: string]: ITranslations;
}

interface IState {
    contextTranslations: IContextTranslations;
    mainTranslationLanguage: string;
}

const initialState: IState = {
    contextTranslations: {},
    mainTranslationLanguage: '',
};

const duck = makeReduxDuck(translationsStorePath, initialState);

const setContextTranslations = duck.defineAction<{
    data: IContextTranslations;
    lang: string;
}>('SET_CONTEXT_TRANSLATIONS', (state, { data, lang }) => ({
    ...state,
    contextTranslations: data,
    mainTranslationLanguage: lang,
}));

export default duck.getReducer();

const makeTranslationsStorePath = `get:${translationsStorePath}`;

export const fetchContextTranslations = (lang: string) =>
    makeRequest(
        makeTranslationsStorePath,
        () => restGetContextTranslations(lang),
        (dispatch, data) => dispatch(setContextTranslations({ data, lang })),
        (dispatch, error) => undefined
    );

export const getContextTranslations = (state: TRootState) => {
    return state.app.translations.contextTranslations || {};
};
export const getMainTranslationsLang = (state: TRootState) => {
    return state.app.translations.mainTranslationLanguage || {};
};
