import React, { Component, ComponentType } from 'react';
import { compose } from 'redux';

import { Field, FormikErrors, FormikValues } from 'formik';
import moment from 'moment-timezone';

import { withSnackbar, WithSnackbarProps } from 'notistack';
import { Connectable, TConnectableProps } from './Connectable.hoc';
import { Themable, TThemableProps } from './Themable.hoc';

import DateTimeFieldWrapper from '../../../../../../components/common/formikWrappers/DateTimeFieldWrapper';
import FieldWrapper from '../../../../../../components/common/formikWrappers/FieldWrapper';
import PhraseFilterWrapper from '../../../../../../components/common/formikWrappers/PhraseFilterWrapper';
import CrudPane from '../../../../../../components/CrudPane';

import { LinearProgress } from '@material-ui/core';

import VehicleIcon from '@material-ui/icons/LocalShipping';
import EmployeeIcon from '@material-ui/icons/Person';

import { responseMessage } from '../../../../../../helpers/responseMessage';
import TimeFormatter from '../../../../../../helpers/TimeFormatter';
import TranslationHelper from '../../../../../../helpers/TranslationHelper';

import { registerRefuelingSchema } from '../../../../../../schemas';
import {
    ISearchResult,
    TSearchType,
} from '../../../../../../state/app/searchers';
import {
    IDictionaryItem,
    IRefuelingEventPostData,
    IRegisterRefuelingForm,
} from '../../../../../../state/ui/forms';

type TProps = TThemableProps & TConnectableProps & WithSnackbarProps;

interface IState {
    blankForm: IRegisterRefuelingForm;
}

class RegisterRefueling extends Component<TProps, IState> {
    public state: IState = {
        blankForm: {
            refuelDate: moment().format(),
            vehicle: { label: '' },
            employee: { label: '' },
            refuelValue: '',
            odometer: '',
            notice: '',
        },
    };

    public areSearchResultsVisibleHandler = (type: TSearchType) => {
        const {
            areVehiclesSearchResultVisible,
            areEmployeesSearchResultVisible,
        } = this.props;
        switch (type) {
            case 'vehicles':
                return areVehiclesSearchResultVisible;
            case 'employees':
                return areEmployeesSearchResultVisible;
            default:
                return false;
        }
    };

    public onSearchRegisterHandler = (name: string, type: TSearchType) => {
        const {
            updateSearcherForType,
            toggleVehiclesSearchResult,
            toggleEmployeesSearchResult,
        } = this.props;
        updateSearcherForType(name, type);
        switch (type) {
            case 'vehicles':
                toggleVehiclesSearchResult(true);
                break;
            case 'employees':
                toggleEmployeesSearchResult(true);
                break;
        }
    };

    public areSearchResultsFetchingHandler = (type: TSearchType) => {
        const { isVehiclesFetching, isEmployeesFetching } = this.props;
        switch (type) {
            case 'vehicles':
                return isVehiclesFetching;
            case 'employees':
                return isEmployeesFetching;
            default:
                return false;
        }
    };

    public getRegisterResultsHandler = (type: TSearchType) => {
        const { vehiclesSearcherResults, employeesSearcherResults } =
            this.props;
        switch (type) {
            case 'vehicles':
                return vehiclesSearcherResults;
            case 'employees':
                return employeesSearcherResults;
            default:
                return [];
        }
    };

    public validateCards = (object: IDictionaryItem) => {
        let error;

        if (object.id && (!object.cards || object.cards.length === 0)) {
            error = TranslationHelper.translate('No RFID card');
        }

        return error;
    };

    public validateDate = (date: string) => {
        let error;

        if (moment().diff(moment(date), 'minutes') < 0) {
            error = TranslationHelper.translate('Cannot select future date');
        }

        return error;
    };

    public mapFilterResult = ({ id, label, cards }: ISearchResult) => {
        return { id, label, cards };
    };

    public renderModification = (
        values: FormikValues,
        errors: FormikErrors<IRegisterRefuelingForm>,
        setFieldValue: (name: string, value: any) => void
    ) => {
        const { classes, userSettings, resetSearcherForType } = this.props;

        return (
            <div>
                <div className={classes.paneContent}>
                    <Field
                        className={classes.field}
                        error={!!(errors && errors.refuelDate)}
                        helperText={
                            (errors && errors.refuelDate) ||
                            TranslationHelper.translateFormat(
                                'Events can be registered up to %d days back',
                                [14]
                            )
                        }
                        validate={this.validateDate}
                        name={'refuelDate'}
                        label={TranslationHelper.translate('Refueling date')}
                        fullWidth={true}
                        required={true}
                        component={DateTimeFieldWrapper}
                        minDate={moment().subtract(14, 'days')}
                        maxDate={moment()}
                        strictCompareDates={true}
                        displayFormat={userSettings.dateFormatWithoutSeconds}
                        withDateFormatter={TimeFormatter.toISOString}
                    />
                    <Field
                        name={'employee'}
                        error={
                            !!(
                                errors &&
                                (errors.employee || errors.employeeOrVehicle)
                            )
                        }
                        helperText={
                            errors &&
                            (errors.employee || errors.employeeOrVehicle)
                        }
                        placeholder={TranslationHelper.translate(
                            'Find by name'
                        )}
                        fetchDataHandler={(name: string) =>
                            this.onSearchRegisterHandler(name, 'employees')
                        }
                        visible={this.areSearchResultsVisibleHandler(
                            'employees'
                        )}
                        fetching={this.areSearchResultsFetchingHandler(
                            'employees'
                        )}
                        results={this.getRegisterResultsHandler('employees')}
                        onClose={() => resetSearcherForType('employees')}
                        mapResults={this.mapFilterResult}
                        validate={this.validateCards}
                        component={PhraseFilterWrapper}
                        icon={<EmployeeIcon />}
                    />
                    <Field
                        name={'vehicle'}
                        error={
                            !!(
                                errors &&
                                (errors.vehicle || errors.employeeOrVehicle)
                            )
                        }
                        helperText={
                            errors &&
                            (errors.vehicle || errors.employeeOrVehicle)
                        }
                        placeholder={TranslationHelper.translate(
                            'Find by name'
                        )}
                        fetchDataHandler={(name: string) =>
                            this.onSearchRegisterHandler(name, 'vehicles')
                        }
                        visible={this.areSearchResultsVisibleHandler(
                            'vehicles'
                        )}
                        fetching={this.areSearchResultsFetchingHandler(
                            'vehicles'
                        )}
                        results={this.getRegisterResultsHandler('vehicles')}
                        onClose={() => resetSearcherForType('vehicles')}
                        validate={this.validateCards}
                        mapResults={this.mapFilterResult}
                        component={PhraseFilterWrapper}
                        icon={<VehicleIcon />}
                    />
                    <Field
                        error={!!(errors && errors.refuelValue)}
                        helperText={errors && errors.refuelValue}
                        required={true}
                        name={'refuelValue'}
                        label={TranslationHelper.translate('Refuel value')}
                        type={'number'}
                        fullWidth={true}
                        component={FieldWrapper}
                        inputProps={{
                            min: 0,
                        }}
                    />
                    <Field
                        error={!!(errors && errors.odometer)}
                        helperText={errors && errors.odometer}
                        required={true}
                        name={'odometer'}
                        label={TranslationHelper.translate('Odometer')}
                        type={'number'}
                        fullWidth={true}
                        component={FieldWrapper}
                        inputProps={{
                            min: 0,
                        }}
                    />
                    <Field
                        error={!!(errors && errors.notice)}
                        helperText={errors && errors.notice}
                        name={'notice'}
                        label={TranslationHelper.translate('Notice')}
                        type={'text'}
                        fullWidth={true}
                        multiline={true}
                        component={FieldWrapper}
                    />
                </div>
            </div>
        );
    };

    public render() {
        const { pane } = this.props;
        const initialValues = this.state.blankForm;

        return (
            <CrudPane
                titles={{ add: 'Register refueling event' }}
                loaded={pane !== null && pane.mode === 'add'}
                initialValues={initialValues}
                validationSchema={registerRefuelingSchema()}
                onClose={this.handleCloseClick}
                changeModeHandler={this.handleCloseClick}
                mode={(pane && pane.mode) || undefined}
                onAdd={(values, onSuccess) => {
                    this.handleSubmit(values);
                }}
                renderContent={(
                    mode,
                    errors,
                    values,
                    setFieldValue,
                    isSubmitting
                ) => {
                    if (isSubmitting) {
                        return <LinearProgress />;
                    }
                    return (
                        <>
                            {this.renderModification(
                                values,
                                errors,
                                setFieldValue
                            )}
                        </>
                    );
                }}
            />
        );
    }

    private handleCloseClick = () => {
        this.props.resetLevel(this.props.creatorLevel);
    };

    private handleSubmit = (data: FormikValues) => {
        const { pane, customAction, enqueueSnackbar, objectHeader } =
            this.props;
        const action = pane && pane.previewAction;

        if (!action) {
            return;
        }

        const refuelDate = new Date(
            new Date(data.refuelDate).setSeconds(0)
        ).toISOString();

        const refuelingEventData: IRefuelingEventPostData[] = [
            {
                parameters: {
                    k: 'XTP',
                    t: 19,
                    lat: objectHeader?.coordinates?.y,
                    lon: objectHeader?.coordinates?.x,
                    dt: refuelDate,
                    dtst: refuelDate,
                    userId: data.employee.cards?.[0].code,
                    masterId: data.vehicle.cards?.[0].code,
                    fuel: data.refuelValue,
                    odometer: data.odometer,
                    note: `${data.notice} (${TranslationHelper.translate(
                        'Added manually'
                    )})`.trim(),
                },
            },
        ];

        customAction(
            action,
            refuelingEventData,
            () => {
                this.handleCloseClick();
                enqueueSnackbar(
                    TranslationHelper.translate('Event saved successfully'),
                    { variant: 'success' }
                );
            },
            (error) => {
                const message = responseMessage(error.status);
                enqueueSnackbar(
                    `${TranslationHelper.translate(
                        'Event save failed'
                    )}: ${TranslationHelper.translate(message.text)}`,
                    { variant: message.type }
                );
            }
        );
    };
}

export default compose(
    Themable,
    Connectable,
    withSnackbar
)(RegisterRefueling) as ComponentType<{}>;
