import { connect } from 'react-redux';

import { TRootState } from '../../../../../../store';

import { customAction, resetLevel } from '../../../../../../state/_actions';

import {
    getDiscoveryUIPreviewPane,
    getPreviewCreatorLevel,
} from '../../../../selectors';

import {
    fetchSourceParamsDefinitions,
    fetchTargetParamsDefinitions,
} from '../../../../../../state/app/mappings';
import {
    getSourceParamsDefinitions,
    getTargetParamsDefinitions,
} from '../../../../selectors/mappings';

const mapStateToProps = (state: TRootState) => ({
    pane: getDiscoveryUIPreviewPane(state),
    creatorLevel: getPreviewCreatorLevel(state),
    sourceParamsDefs: getSourceParamsDefinitions(state),
    targetParamsDefs: getTargetParamsDefinitions(state),
});

const mapDispatchToProps = {
    resetLevel,
    customAction,
    fetchSourceParamsDefinitions,
    fetchTargetParamsDefinitions,
};

type TStateProps = ReturnType<typeof mapStateToProps>;
type TDispatchProps = typeof mapDispatchToProps;

export type TConnectableProps = TStateProps & TDispatchProps;

export const Connectable = connect(mapStateToProps, mapDispatchToProps);
