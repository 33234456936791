import { createStyles, Theme, WithStyles } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme: Theme) => {
    const makeShared = (color: string) => ({
        content: '" "',
        backgroundColor: color,
        display: 'inline-block',
        margin: '1px 4px 0 0',
        width: '10px',
        height: '10px',
    });
    return createStyles({
        chart: {
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
        },
        eventsChartHeader: {
            display: 'flex',
            justifyContent: 'space-between',
            margin: '10px 15px',
            fontSize: '12px',
            fontFamily: 'OpenSans-Bold',
        },
        fuelLegend: {
            '&:before': makeShared('#4A90E2'),
        },
        temperatureLegend: {
            '&:before': makeShared('#E79B1E'),
        },
        eventsChartContainer: {
            display: 'flex',
            flex: 1,
            overflow: 'hidden',
        },
        outerChart: {
            position: 'absolute',
        },
    });
};

export type TThemableProps = WithStyles<typeof styles>;

export const Themable = withStyles(styles, { withTheme: true });
