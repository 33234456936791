import { FieldProps } from 'formik';
import React from 'react';

import TextField, { TextFieldProps } from '@material-ui/core/TextField';

interface IOwnProps {
    afterOnChange?: (value: string) => void;
    regex: RegExp;
}

type TProps = TextFieldProps & FieldProps & IOwnProps;

const floatRegex = /^[-+]?\d{0,3}[.,]?\d{0,6}$/;

const setValue = (
    value: string,
    setFieldValue: (value: string) => void,
    regex: RegExp = floatRegex
) => {
    if (!value || value.match(regex)) {
        setFieldValue(value.replace(',', '.'));
    }
};

const NumberFieldWrapper = ({
    field,
    form,
    afterOnChange,
    regex,
    ...props
}: TProps) => (
    <TextField
        fullWidth={false}
        {...field}
        {...props}
        onChange={(event) =>
            setValue(
                event.target.value,
                (val) => form.setFieldValue(field.name, val),
                regex
            )
        }
    />
);

export default NumberFieldWrapper;
