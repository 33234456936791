interface IRGB {
    r: number;
    g: number;
    b: number;
}

// Create a cache
const hexToRgbCache = new Map<string, IRGB | null>();

export const hexToRgb = (hex: string): IRGB | null => {
    // Normalize the hex string by removing the leading '#' and converting to lowercase
    const normalizedHex = hex.replace(/^#/, '').toLowerCase();

    // Check if the value is already in the cache
    if (hexToRgbCache.has(normalizedHex)) {
        return hexToRgbCache.get(normalizedHex)!;
    }

    // Validate the length of the hex code
    if (normalizedHex.length !== 3 && normalizedHex.length !== 6) {
        return null; // Invalid hex code
    }

    // Expand shorthand form (e.g., 'abc' becomes 'aabbcc')
    let fullHex = normalizedHex;
    if (normalizedHex.length === 3) {
        fullHex = normalizedHex
            .split('')
            .map((char) => char + char)
            .join('');
    }

    const bigint = parseInt(fullHex, 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;

    const rgb: IRGB = { r, g, b };

    hexToRgbCache.set(normalizedHex, rgb);

    return rgb;
};
