import React, { useEffect } from 'react';
import { useSnackbar } from 'notistack';

import { default as EditIcon } from '@material-ui/icons/Edit';
import { LinearProgress } from '@material-ui/core';

import { Field, Form, Formik } from 'formik';

import { useCreatorLevel, useLocationId } from './Connectable.hooks';
import { useStyles } from './Themable.hooks';

import TranslationHelper from '../../../../../../../../helpers/TranslationHelper';
import { errorMessageHandler } from '../../../../../../../../helpers/errorMessageHandler';

import {
    resetLastMapClickPosition,
    storeLastMapClickPosition,
} from '../../../../../../../../state/ui/discovery/general';
import {
    activatePreview,
    closePreview,
    fetchLocations,
} from '../../../../../../../../state/_actions';
import { deleteLocation } from '../../../../../../../../state/app/locations';

import {
    useAppDispatch,
    useToolkitDispatch,
} from '../../../../../../../..//hooks';

import PaneHeader from '../../../../../../../../components/PaneHeader/PaneHeader';
import GoogleMapsButton from '../../../../../../../../components/common/GoogleMapsButton/GoogleMapsButton';
import DeleteButton from '../../../../../../../../components/DeleteButton';
import IconButtonWithTooltip from '../../../../../../../../components/IconButtonWithTooltip';
import VisibilityForm from '../../../../../../../../components/VisibilityForm';
import FieldWrapper from '../../../../../../../../components/common/formikWrappers/FieldWrapper';
import {
    prepareGroups,
    prepareVisibilityType,
} from '../../../../../../../../components/VisibilityForm/VisibilityForm';
import { useRegistersGroups } from '../../../../../../../../state/app/registers/index.hooks';
import { fetchRegistersGroups } from '../../../../../../../../state/app/registers/groups';
import { useUserDataPermissions } from '../../../../../../../../state/user/index.hooks';
import { useLocation } from '../../../../../../selectors/composed/index.hooks';
import { usePane } from '../../../../../../selectors/index.hooks';

interface IOwnProps {
    loading: boolean;
}

const LocationPreview = ({ loading }: IOwnProps) => {
    const classes = useStyles();
    const dispatch = useAppDispatch();
    const pane = usePane();
    const location = useLocation();
    const locationId = useLocationId();
    const creatorLevel = useCreatorLevel();
    const { enqueueSnackbar } = useSnackbar();
    const groups = useRegistersGroups();
    const toolkitDispatch = useToolkitDispatch();
    const dataPermissions = useUserDataPermissions();

    useEffect(() => {
        if (!groups) {
            toolkitDispatch(fetchRegistersGroups({}));
        }
    }, [groups, toolkitDispatch]);

    const handleCloseClick = (deselect?: boolean) => {
        dispatch(resetLastMapClickPosition());
        dispatch(closePreview(creatorLevel, deselect));
    };
    const showNotification = (success: boolean, message: string) => {
        enqueueSnackbar(message, {
            variant: success ? 'success' : 'error',
        });
    };
    const handleOpenEditModeClick = () => {
        if (typeof locationId !== 'boolean') {
            if (location && location.coordinate) {
                dispatch(
                    storeLastMapClickPosition(
                        location.coordinate.x,
                        location.coordinate.y
                    )
                );
            }
            dispatch(
                activatePreview(
                    locationId || pane?.elementId || '',
                    pane?.elementId || locationId || '',
                    'location',
                    'edit',
                    {
                        type: 'location',
                        level: creatorLevel + 1,
                    }
                )
            );
        }
    };

    const handleDelete = () => {
        if (locationId) {
            dispatch(
                deleteLocation(
                    locationId,
                    () => {
                        dispatch(fetchLocations());
                        dispatch(closePreview(creatorLevel));

                        showNotification(
                            true,
                            TranslationHelper.translate(
                                'Location has been removed'
                            )
                        );
                    },
                    (error) => {
                        const message = errorMessageHandler(error.status, {
                            409: 'The location is used / assigned to other objects. It cannot be removed',
                        })();
                        showNotification(false, message);
                    }
                )
            );
        }
    };
    const renderRightCustomControls = () => {
        return (
            <>
                <GoogleMapsButton coordinates={location?.coordinate} />
                <IconButtonWithTooltip
                    title={TranslationHelper.translate('Edit')}
                    onClick={handleOpenEditModeClick}
                >
                    <EditIcon />
                </IconButtonWithTooltip>
                <DeleteButton
                    title="Removal confirmation"
                    tooltip="Delete location"
                    message="Are you sure?"
                    deleteMethod={handleDelete}
                />
            </>
        );
    };
    const address = location?.address;

    const initialValues = {
        id: location?.id,
        name: location?.name,
        address: [address?.city, address?.street, address?.streetNumber].join(
            ', '
        ),
        notes: location?.notes,
        visibilityType: prepareVisibilityType(
            groups,
            location?.groupIds,
            dataPermissions
        ),
        groups: prepareGroups(groups, location?.groupIds),
    };
    return (
        <>
            <PaneHeader
                title={TranslationHelper.translate('Location preview')}
                onCloseClick={handleCloseClick}
                renderRightCustomControls={renderRightCustomControls}
            />
            {loading ? (
                <LinearProgress />
            ) : (
                <div className={classes.formWrapper}>
                    <Formik
                        enableReinitialize={true}
                        onSubmit={() => {}}
                        initialValues={initialValues}
                        validateOnBlur={false}
                        validateOnChange={false}
                        validationSchema={null}
                    >
                        {({ errors, values, setFieldValue }) => (
                            <Form noValidate={true}>
                                <Field
                                    name={'name'}
                                    label={TranslationHelper.translate('Name')}
                                    fullWidth={true}
                                    required={true}
                                    component={FieldWrapper}
                                    disabled
                                />

                                <Field
                                    name={'address'}
                                    label={TranslationHelper.translate(
                                        'Address'
                                    )}
                                    fullWidth={true}
                                    component={FieldWrapper}
                                    disabled
                                />

                                <Field
                                    name={'notes'}
                                    label={TranslationHelper.translate('Notes')}
                                    fullWidth={true}
                                    component={FieldWrapper}
                                    multiline={true}
                                    disabled
                                />
                                <VisibilityForm
                                    values={values}
                                    setFieldValue={setFieldValue}
                                    errors={errors}
                                    disabled
                                />
                            </Form>
                        )}
                    </Formik>
                </div>
            )}
        </>
    );
};

export default LocationPreview;
