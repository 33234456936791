import { connect } from 'react-redux';
import {
    deleteRole,
    getRole,
    setRole,
    setRoleToDelete,
} from '../../../../state/app/adminPanel';
import { enterCreateMode, selectRole } from '../../../../state/ui/roles';
import { TRootState } from '../../../../store';
import {
    getRoleList,
    getRoleListRequest,
    getRoleToDelete,
    getSelectedRoleId,
} from '../../selectors';

const mapStateToProps = (state: TRootState) => ({
    rolesList: getRoleList(state),
    selectedRoleId: getSelectedRoleId(state),
    roleToDeleteId: getRoleToDelete(state),
    getRolesRequest: getRoleListRequest(state),
});

const mapDispatchToProps = {
    setRole,
    getRole,
    deleteRole,
    selectRole,
    enterCreateMode,
    setRoleToDelete,
};

type TStateProps = ReturnType<typeof mapStateToProps>;
type TDispatchProps = typeof mapDispatchToProps;

export type TConnectableProps = TStateProps & TDispatchProps;

export const Connectable = connect(mapStateToProps, mapDispatchToProps);
