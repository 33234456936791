import { combineReducers } from 'redux';

import PageableCollection from '../../../models/common/PageableCollection';

import employees from './employees';
import vehicles from './vehicles';
import tanks from './tanks';
import rfidCards from './rfidCards';
import groups from './groups';
import monitoredObjects from './monitoredObjects';

export interface IPage<V> {
    values: V[];
}

export interface ICard {
    cardNumber: string;
    code: string;
}

export interface IRegisterFilter {
    limit?: number;
    start?: number;
    name?: string;
    group?: string;
    externalId?: number;
}

export const mapToPageableCollection = (data: any, mapModel: any) => {
    return data
        ? new PageableCollection(data.values.map(mapModel), {
              start: data.start,
              total: data.total,
              limit: data.limit,
          })
        : new PageableCollection();
};

export default combineReducers({
    employees,
    vehicles,
    tanks,
    rfidCards,
    groups,
    monitoredObjects,
});
