import { connect } from 'react-redux';

import { TRootState } from '../../../../../../store';

import { customAction, resetLevel } from '../../../../../../state/_actions';

import {
    getDiscoveryUIPreviewPane,
    getPreviewCreatorLevel,
} from '../../../../selectors';
import {
    getCalibrationParamId,
    getParamsDefinitions,
} from '../../../../selectors/calibrations';
import { getPreviewMonitoredObjectHeader } from '../../../../selectors/composed';
import {
    fetchEvents,
    fetchParamsDefinitions,
    setCalibrationData,
} from '../../../../../../state/app/calibrations';
import { getUserSettings } from '../../../../../../state/user';

const mapStateToProps = (state: TRootState) => ({
    pane: getDiscoveryUIPreviewPane(state),
    creatorLevel: getPreviewCreatorLevel(state),
    definitions: getParamsDefinitions(state),
    userSettings: getUserSettings(state),
    objectHeader: getPreviewMonitoredObjectHeader(state),
    selectedParamId: getCalibrationParamId(state),
});

const mapDispatchToProps = {
    resetLevel,
    customAction,
    fetchParamsDefinitions,
    fetchEvents,
    setCalibrationData,
};

type TStateProps = ReturnType<typeof mapStateToProps>;
type TDispatchProps = typeof mapDispatchToProps;

export type TConnectableProps = TStateProps & TDispatchProps;

export const Connectable = connect(mapStateToProps, mapDispatchToProps);
