import React, { ComponentType } from 'react';
import { compose } from 'redux';

import { CircularProgress, TextField } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import {
    Autocomplete,
    AutocompleteChangeDetails,
    AutocompleteChangeReason,
    AutocompleteInputChangeReason,
} from '@material-ui/lab';

import { Themable, TThemableProps } from './Themable.hoc';

import TranslationHelper from '../../../helpers/TranslationHelper';

interface IObject {
    id: string | number;
    name: string;
}
interface IOwnProps {
    inputValue: string;
    value: any;
    onInputChange: (
        event: React.ChangeEvent<{}>,
        value: string,
        reason: AutocompleteInputChangeReason
    ) => void;
    onChange: (
        event: React.ChangeEvent<{}>,
        value: any | null,
        reason: AutocompleteChangeReason,
        details?: AutocompleteChangeDetails<unknown> | undefined
    ) => void;
    options: IObject[];
    loading: boolean;
    placeholder: string;
}

type TProps = IOwnProps & TThemableProps;

const AsyncSearch = (props: TProps) => {
    return (
        <Autocomplete
            getOptionSelected={(option, value) => option.id === value.id}
            getOptionLabel={(option) => option.name}
            noOptionsText={TranslationHelper.translate('No options to display')}
            loadingText={TranslationHelper.translate('Loading...')}
            onChange={props.onChange}
            options={props.options}
            loading={props.loading}
            size="small"
            classes={{
                root: props.classes.autocompleteRoot,
                inputRoot: props.classes.autocompleteInput,
            }}
            autoHighlight={true}
            clearOnBlur={false}
            value={props.value ?? null}
            inputValue={props.inputValue}
            onInputChange={props.onInputChange}
            clearText={TranslationHelper.translate('Clear')}
            openText={TranslationHelper.translate('Open')}
            renderInput={(params) => (
                <TextField
                    {...params}
                    placeholder={TranslationHelper.translate(props.placeholder)}
                    variant="outlined"
                    InputProps={{
                        ...params.InputProps,
                        startAdornment: <SearchIcon />,
                        endAdornment: (
                            <React.Fragment>
                                {props.loading ? (
                                    <CircularProgress
                                        color="inherit"
                                        size={20}
                                    />
                                ) : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                />
            )}
        />
    );
};

export default compose(Themable)(AsyncSearch) as ComponentType<IOwnProps>;
