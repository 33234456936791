import React from 'react';

import Report from '../../../../../Report';

import {
    IReportSet,
    IReportSetHeader,
} from '../../../../../../../../state/types';
import EventHeader from '../../../EventHeader';

interface IOwnProps {
    creatorLevel: number;
    sourceSetElementReportSet: IReportSet;
    getHeaderName?: (header: IReportSetHeader) => string;
}

type TProps = IOwnProps;

const SourceSetElement = ({
    sourceSetElementReportSet,
    getHeaderName,
    creatorLevel,
}: TProps) => {
    if (!sourceSetElementReportSet) {
        return null;
    }

    const header =
        sourceSetElementReportSet._meta &&
        sourceSetElementReportSet._meta.header;

    return (
        <React.Fragment>
            {header && (
                <EventHeader header={header} getHeaderName={getHeaderName} />
            )}

            {sourceSetElementReportSet.reports.map((report, index) => (
                <Report
                    key={index}
                    report={report}
                    expanded={true}
                    creatorLevel={creatorLevel}
                />
            ))}
        </React.Fragment>
    );
};

export default SourceSetElement;
