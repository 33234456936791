import GeoJSON, { GeoJSONFeatureCollection } from 'ol/format/GeoJSON';
import BaseLayer from 'ol/layer/Base';
import TileLayer from 'ol/layer/Tile';
import VectorLayer from 'ol/layer/Vector';
import olMap from 'ol/Map';
import VectorSource from 'ol/source/Vector';
import Fill from 'ol/style/Fill';
import Stroke from 'ol/style/Stroke';
import Style from 'ol/style/Style';
import Text from 'ol/style/Text';
import { Geometry, Point } from 'ol/geom';
import { Feature } from 'ol';

import {
    IAttribute,
    ILayersData,
    ISourceSetEntity,
} from '../../../../../state/types';

import { restGetSHPLayers } from '../../../../../services/mapLayers';

import TranslationHelper from '../../../../../helpers/TranslationHelper';

import { makePoint } from './features';

interface ILayersDefs {
    caption: string;
    visible: boolean;
    geoJson: GeoJSONFeatureCollection;
}
const PADDING = 5;
const CUSTOM_FEATURE_PADDING = [PADDING, PADDING, PADDING, PADDING];
const FONT_SIZE = 10;
const STROKE_WIDTH = 10;
const MIN_WIDTH = 1.25;

const setCustomFeatureStyle = (feature: Feature<Point>) => {
    const label = feature.get('label');

    feature.setStyle(
        new Style({
            fill: new Fill({
                color: feature.get('fill') || 'rgba(255,255,255,0.4)',
            }),
            stroke: new Stroke({
                color: feature.get('stroke') || '#3399CC',
                width: Math.max(
                    feature.get('stroke-width') / STROKE_WIDTH || MIN_WIDTH,
                    0.5
                ),
            }),
            text: new Text({
                text: label?.text || '',
                fill: new Fill({
                    color: label?.fontColor || '#333',
                }),
                font: `${label?.fontStyle ? label.fontStyle : ''} ${
                    label?.fontSize || FONT_SIZE
                }px sans-serif`,
                backgroundFill: new Fill({
                    color: label?.backgroundColor || 'transparent',
                }),
                backgroundStroke: new Stroke({
                    color: label?.backgroundOutlineColor || 'transparent',
                    width: label?.backgroundOutlineWidth / STROKE_WIDTH || 0,
                }),
                padding: CUSTOM_FEATURE_PADDING,
            }),
        })
    );
    return feature;
};

const getGeometryFeatures = (features: Feature<Geometry>[]) => {
    return features
        .filter((feature) => !!feature.getGeometry() && feature.get('label'))
        .map((feature) => ({
            geometry: feature.getGeometry(),
            label: feature.get('label').text || '',
        }));
};

const getChangedEntityLayersLabels = (
    layers: ILayersData[],
    point: [number, number],
    entity: ISourceSetEntity
) => {
    return layers.reduce((result, layer) => {
        const features = layer.features;
        const label = entity[layer.title];
        const found = features.find(
            (feature) =>
                feature.geometry && feature.geometry.intersectsCoordinate(point)
        );
        if (found && label !== found.label) {
            result[layer.title] = found.label;
        } else if (!found && label) {
            result[layer.title] = null;
        }
        return result;
    }, {});
};

export const getCustomLayers = () => {
    return restGetSHPLayers().then(
        (data: ILayersDefs[]): VectorLayer<VectorSource<Geometry>>[] => {
            return data.map((layer) => {
                const features = new GeoJSON().readFeatures(layer.geoJson, {
                    featureProjection: 'EPSG:900913',
                });
                //@ts-ignore
                features.forEach(setCustomFeatureStyle);

                return new VectorLayer({
                    // @ts-ignore
                    title: layer.caption,
                    source: new VectorSource({
                        features,
                    }),
                });
            });
        }
    );
};

const getLayersData = (layers: BaseLayer[]) => {
    return layers.map((layer) => {
        const features = (layer as VectorLayer<VectorSource<Geometry>>)
            .getSource()
            ?.getFeatures();

        //@ts-ignore
        const geometryFeatures = getGeometryFeatures(features);

        return {
            title: layer.get('title'),
            id: layer.get('title'),
            label: `${TranslationHelper.translate('Layer')}: ${layer.get(
                'title'
            )}`,
            type: 'layer',
            features: geometryFeatures,
        };
    });
};

export const getCurrentLayers = (map: olMap) => {
    const layers = getFilteredLayers(map.getLayers().getArray());

    const attributes: IAttribute[] = layers.map((layer) => {
        return {
            id: layer.get('title'),
            label: `${TranslationHelper.translate('Layer')}: ${layer.get(
                'title'
            )}`,
            type: 'layer',
        };
    });

    const layersData = getLayersData(layers);

    return {
        attributes,
        layersData,
    };
};

export const setEntitiesLayers = (
    entity: ISourceSetEntity,
    layers: ILayersData[]
) => {
    const x = entity._meta.coordinates?.x;
    const y = entity._meta.coordinates?.y;

    if (x && y) {
        const point = makePoint(x, y);
        const entityLayersTitles = getChangedEntityLayersLabels(
            layers,
            point,
            entity
        );
        return { ...entity, ...entityLayersTitles };
    } else {
        return entity;
    }
};

export const getFilteredLayers = (layers: BaseLayer[]) => {
    return layers.filter(
        (layer) =>
            layer.get('displayInLayerSwitcher') !== false &&
            layer.get('visible') !== false &&
            !(layer instanceof TileLayer)
    );
};
