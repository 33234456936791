import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        dialog: {
            justifyContent: 'flex-start',
        },
        smallMap: {
            flexDirection: 'column',
            display: 'flex',
            height: 'calc(100% - 55px)',
        },
        map: {
            height: '100%',
        },
        gridPane: {
            display: 'flex',
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
        },
        formPane: {
            display: 'flex',
            position: 'absolute',
            minHeight: '100%',
            top: 0,
            right: 0,
            left: 0,
        },
        pageWrapper: {
            position: 'absolute',
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
        },
        wrapper: {
            display: 'flex',
            position: 'relative',
            flexGrow: 1,
            flexDirection: 'column',
        },
        paneWrapper: {
            position: 'absolute',
            top: 0,
            left: 'calc(70% - 400px)',
            width: 400,
            maxHeight: '100%',
            right: 0,
            overflowY: 'auto',
            overflowX: 'hidden',
            display: 'flex',
            height: '100%',
            zIndex: 200,
        },

        pageContent: {
            position: 'absolute',
            top: 10,
            left: 10,
            bottom: 10,
            right: 10,
            [theme.breakpoints.down('sm')]: {
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
            },
        },

        visibleGridPane: {
            position: 'absolute',
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            width: 'calc(70% - 410px)',
        },
        hiddenGridPane: {
            position: 'absolute',
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            width: '70%',
        },
        container: {
            top: 0,
            right: 0,
            width: 'calc(30% - 10px)',
            bottom: 0,
            position: 'absolute',
        },

        formWrapper: {
            padding: theme.spacing(3),
            '& .MuiTextField-root': {
                margin: `${theme.spacing(1)}px 0`,
            },
        },
        visibleBottomGrid: {
            position: 'absolute',
            top: 0,
            left: 0,
            bottom: '60%',
            right: 0,
        },
        hiddenBottomGrid: {
            position: 'absolute',
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
        },
        bottomGrid: {
            position: 'absolute',
            top: '40%',
            left: 0,
            bottom: 0,
            right: 0,
        },
        mapPane: {},
    })
);
