import { combineReducers } from 'redux';

import lastStateFetching from './lastStateFetching';
import lastStatesFetching from './lastStatesFetching';
import alertSettingsFetching from './alertSettingsFetching';
import locationsFetching from './locationsFetching';
import locationsRegister from './locationsRegister';
import monitoredObjectFetching from './monitoredObjectFetching';
import reportSetFetching from './reportSetFetching';
import routesFetching from './routesFetching';
import { reducer as searchersReducer } from './searchers';
import searchResultsFetching from './searchResultsFetching';
import sourceSetElementFetching from './sourceSetElementFetching';
import sourceSetFetching from './sourceSetFetching';
import taskFetching from './taskFetching';

import tasksFetching from './tasksFetching';
import trailFetching from './trailFetching';
import recognizedNumbersFetching from './recognizedNumbersFetching';

export default combineReducers({
    lastStateFetching,
    lastStatesFetching,
    alertSettingsFetching,
    locationsFetching,
    locationsRegister,
    monitoredObjectFetching,
    reportSetFetching,
    searchResultsFetching,
    sourceSetElementFetching,
    sourceSetFetching,
    taskFetching,
    tasksFetching,
    trailFetching,
    recognizedNumbersFetching,
    routesFetching,
    vehiclesSearcher: searchersReducer.vehicles,
    employeesSearcher: searchersReducer.employees,
});
