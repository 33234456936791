import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => {
    return {
        deviceDetails: {
            paddingBottom: '30px',
            '& b': {
                fontWeight: 'bold',
            },
        },
        spinner: {
            display: 'flex',
            justifyContent: 'center',
            height: '37px',
        },
    };
});
