import UrlConst from '../../constants/UrlConst';

import { IDictionaryItem } from '../../state/app/dictionaries';
import { IDevice } from '../../state/types';
import { IRegistersMonitoredObject } from '../../state/app/registers/monitoredObjects';

import apiClient from '../common/apiClient';
import {
    restGetEmployees,
    restPostEmployee,
    restPutEmployee,
    restPutEmployeeCards,
} from './employees';
import {
    restGetVehicles,
    restPostVehicle,
    restPutVehicle,
    restPutVehicleCards,
} from './vehicles';
import {
    restGetSentCards,
    restGetTanks,
    restPutFuelTankRfidCards,
    restPutTank,
} from './fuelTanks';
import {
    restGetAvailableRfidCards,
    restGetRfidCard,
    restGetRfidCards,
    restGetRfidCardsByGroup,
    restPostRfidCard,
    restPutRfidCard,
} from './rfidCards';

import {
    makeEndpoint,
    makeGetRequestSettings,
    makePostMultipartRequestSettings,
} from '../common/utils';
import { makeRestReadDevicesCollection } from '../devices';

export interface IImportRfidResponse {
    success: boolean;
    messages: string[];
}

export interface IGetMonitoredObjectsParams {
    name?: string;
    individualForUserId?: number;
}

export const makeRfidCardsUrl = (suffix: string = '', params = {}) => {
    return makeEndpoint(UrlConst.REGISTERS_URL, '/rfid-cards' + suffix, {
        ...params,
    });
};

export const makeDevicesUrl = (suffix: string = '', params = {}) => {
    return makeEndpoint(UrlConst.REGISTERS_URL, '/devices' + suffix, {
        ...params,
    });
};

export const makeImportsRfidUrl = (suffix: string = '', params = {}) => {
    return makeEndpoint(UrlConst.REGISTERS_URL, '/imports' + suffix, {
        ...params,
    });
};
export const makeMonitoredObjectsUrl = (suffix: string = '', params = {}) => {
    return makeEndpoint(UrlConst.REGISTERS_URL, '/monitored-objects' + suffix, {
        ...params,
    });
};
export const makeDictionariesUrl = (suffix: string = '', params = {}) => {
    return makeEndpoint(UrlConst.REGISTERS_URL, '/dictionaries' + suffix, {
        ...params,
    });
};

export {
    restGetEmployees,
    restPostEmployee,
    restPutEmployee,
    restPutEmployeeCards,
    restGetVehicles,
    restPostVehicle,
    restPutVehicle,
    restPutVehicleCards,
    restGetTanks,
    restPutTank,
    restPutFuelTankRfidCards,
    restGetSentCards,
    restGetRfidCards,
    restGetRfidCard,
    restPostRfidCard,
    restPutRfidCard,
    restGetAvailableRfidCards,
    restGetRfidCardsByGroup,
};

export const restGetRegistersMonitoredObjects = (
    params?: IGetMonitoredObjectsParams
): Promise<IRegistersMonitoredObject[]> =>
    apiClient.send(makeGetRequestSettings(makeMonitoredObjectsUrl('', params)));

export const restGetDictionary = (name: string): Promise<IDictionaryItem[]> =>
    apiClient.sendWithoutErrorsHandling(
        makeGetRequestSettings(makeDictionariesUrl(`/${name}`))
    );

export const restGetTimezoneDictionary = (): Promise<IDictionaryItem[]> =>
    apiClient.send(makeGetRequestSettings(makeDictionariesUrl('/timezones')));

export const restGetDevices = (): Promise<IDevice[]> =>
    apiClient.send(makeGetRequestSettings(makeDevicesUrl('')));

export const importRfid = (data: FormData): Promise<IImportRfidResponse> =>
    apiClient.send(
        makePostMultipartRequestSettings(makeImportsRfidUrl('/'), data)
    );
export const restReadDevicesCollection =
    makeRestReadDevicesCollection(restGetDevices);
