import { makeReduxDuck } from 'teedux';

import {
    restGetMonitoredObjects,
    restGetMonitoredObjectsParamsDefinition,
    restPostRecalcuateData,
    restPutCoordinates,
} from '../../../services/monitored';

import { makeRequest } from '../sync';
import { ICoordinates, IMonitoredObject } from '../../types';

interface IParamDefinition {
    id: string;
    name: string;
    type: string;
}

export interface IRecalculateData {
    start: Date;
    reason: string;
}
export interface IMonitoredObjectCoordinates {
    coordinates: ICoordinates;
    monitoredId: number | undefined;
}
export type TParamDefinitions = IParamDefinition[];

interface IState {
    paramDefinitions: TParamDefinitions | undefined;
}

const initialState: IState = {
    paramDefinitions: undefined,
};

const monitoredObjectsStorePath = `app/discovery/locations`;

const duck = makeReduxDuck('app/monitoredObjects', initialState);

const setParamDefinitionsAction = duck.defineAction<{
    paramDefinitions: TParamDefinitions;
}>('SET_PARAM_DEFINITIONS', (_, action) => ({
    paramDefinitions: action.paramDefinitions,
}));

export default duck.getReducer();

export const fetchMonitoredObjects = (
    afterRequest?: (data: IMonitoredObject[]) => void,
    afterFail?: () => void
) =>
    makeRequest(
        `get:${monitoredObjectsStorePath}`,
        () => restGetMonitoredObjects(),
        (dispatch, data) => {
            if (afterRequest) {
                afterRequest(data);
            }
        },
        (dispatch, error) => {
            if (afterFail) {
                afterFail();
            }
        }
    );

export const fetchParamsDefinition = (
    numerical: boolean = false,
    afterRequest?: (data: TParamDefinitions) => void,
    afterFail?: () => void
) =>
    makeRequest(
        `get:${monitoredObjectsStorePath}`,
        () => restGetMonitoredObjectsParamsDefinition(numerical),
        (dispatch, data) => {
            dispatch(setParamDefinitionsAction({ paramDefinitions: data }));
            if (afterRequest) {
                afterRequest(data);
            }
        },
        (dispatch, error) => {
            if (afterFail) {
                afterFail();
            }
        }
    );

export const updateCoordinates = (
    data: IMonitoredObjectCoordinates,
    afterRequest?: () => void,
    afterFail?: () => void
) =>
    makeRequest(
        `put:${monitoredObjectsStorePath}/${data.monitoredId}/coordinates`,
        () => restPutCoordinates(data),
        (dispatch) => {
            if (afterRequest) {
                afterRequest();
            }
        },
        (dispatch, error) => undefined
    );

export const recalcuateData = (
    data: IRecalculateData,
    id: string,
    afterRequest?: () => void,
    afterFail?: () => void
) =>
    makeRequest(
        `post:${monitoredObjectsStorePath}/objects${id}`,
        () => restPostRecalcuateData(data, id),
        (dispatch) => {
            if (afterRequest) {
                afterRequest();
            }
        },
        (dispatch, error) => undefined
    );
