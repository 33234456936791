import { connect } from 'react-redux';
import { TRootState } from '../../../../../../store';

import { updateCalibrationChartAttribute } from '../../../../../../state/ui/discovery/snapshotting';
import { getDateFormat } from '../../../../../../state/user';
import { getCalibrationChartAttributes } from '../../../../selectors';
import { getCalibrationParamId } from '../../../../selectors/calibrations';
import {
    getCalibrationChartData,
    getCalibrationChartType,
} from '../../../../selectors/composed';

const mapStateToProps = (state: TRootState) => ({
    activeAttributes: getCalibrationChartAttributes(state),
    type: getCalibrationChartType(state),
    dateFormat: getDateFormat(state),
    data: getCalibrationChartData(state),
    selectedParamId: getCalibrationParamId(state),
});

const mapDispatchToProps = {
    updateCalibrationChartAttribute,
};

type TStateProps = ReturnType<typeof mapStateToProps>;
type TDispatchProps = typeof mapDispatchToProps;

export type TConnectableProps = TStateProps & TDispatchProps;

export const Connectable = connect(mapStateToProps, mapDispatchToProps);
