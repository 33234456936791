import React, { ChangeEvent, useState } from 'react';

import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import ClearIcon from '@mui/icons-material/Clear';

import Translator from '../../../helpers/TranslationHelper';
import { useStyles } from './Themable.hooks';
import {
    IconButton,
    InputAdornment,
    TextField,
    TextFieldVariants,
} from '@mui/material';

interface IOwnProps {
    filterChange: (input: string) => void;
    resetSearch: () => void;
    value: string;
    variant?: TextFieldVariants;
}

const rfidPattern = /^[a-fA-F0-9]+$/;

const RfidFilter = (props: IOwnProps) => {
    const [rfidFilter, setRfidFilter] = useState(props.value.toUpperCase());
    const [hasError, setHasError] = useState(false);
    const classes = useStyles();

    const handleChange = ({
        target: { value: filter },
    }: ChangeEvent<HTMLInputElement>) => {
        setRfidFilter(filter.toUpperCase());
        if (filter.length === 0 || filter.match(rfidPattern)) {
            setHasError(false);
            props.filterChange(filter);
        } else {
            setHasError(true);
            props.filterChange('');
        }
    };

    const handleReset = () => {
        setRfidFilter('');
        setHasError(false);
        props.resetSearch();
    };

    return (
        <TextField
            type="text"
            placeholder={Translator.translate('grid.filterRfid')}
            onChange={handleChange}
            value={rfidFilter}
            margin="normal"
            error={hasError}
            variant={props.variant}
            className={classes.search}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <IconButton
                            className={
                                hasError
                                    ? classes.errorIcon
                                    : classes.searchIcon
                            }
                        >
                            {hasError ? (
                                <FilterAltOffIcon />
                            ) : (
                                <FilterAltIcon />
                            )}
                        </IconButton>
                    </InputAdornment>
                ),
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton color="primary" onClick={handleReset}>
                            <ClearIcon />
                        </IconButton>
                    </InputAdornment>
                ),
            }}
        />
    );
};

export default RfidFilter;
