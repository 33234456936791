import React, { useEffect } from 'react';

import { Skeleton } from '@material-ui/lab';
import { IconButton, ListItem, Tooltip } from '@material-ui/core';

import { default as EditIcon } from '@material-ui/icons/Edit';

import { IServiceClient } from '../../../../../../../models/customerService';

import { usePrivileges } from '../../../../../../../state/auth/index.hooks';
import {
    fetchClient,
    resetClient,
    selectClient,
    toggleCollapseClients,
} from '../../../../../../../state/ui/customerService/clientsAndLocations';
import {
    useClientDetailsLoading,
    useClientsCollections,
    useOpenSectionClients,
    useSelectedClientId,
    useSelectedClientsAndLocations,
} from '../../../../../../../state/ui/customerService/clientsAndLocations/index.hooks';

import { useToolkitDispatch } from '../../../../../../../hooks';
import TranslationHelper from '../../../../../../../helpers/TranslationHelper';

import { useStyles } from '../../../../../Themable.hooks';

import ClientPreviewForm from '../ClientPreviewForm';
import { CollapseListItem } from '../../../../../../../components/CollapseListItem/CollapseListItem';

interface IOwnProps {
    client: IServiceClient | null;
    handleEditClient: () => void;
    showList?: boolean;
}
const ClientSection = ({ client, handleEditClient, showList }: IOwnProps) => {
    const classes = useStyles();

    const openSectionClients = useOpenSectionClients();
    const privileges = usePrivileges();
    const clientId = useSelectedClientId();
    const loading = useClientDetailsLoading();
    const clientsCollection = useClientsCollections();

    const selectedClientsAndLocations = useSelectedClientsAndLocations();

    const toolkitDispatch = useToolkitDispatch();

    const handleToggleCollapseClients = () => {
        toolkitDispatch(toggleCollapseClients());
    };

    const selectedClients = selectedClientsAndLocations.filter(
        (item) => item.clientId
    );
    const uniqueSelectedClients = [
        ...new Set(selectedClients.map((item) => item.clientId)),
    ];
    const multipleClients = uniqueSelectedClients.length > 1;

    const isMultiClient = multipleClients && showList;

    useEffect(() => {
        clientId
            ? toolkitDispatch(fetchClient(clientId))
            : toolkitDispatch(resetClient());
    }, [clientId]);
    if (!clientId && !uniqueSelectedClients.length) {
        return null;
    }

    const handleEditClientFromList = (id: string) => {
        toolkitDispatch(selectClient(id));

        toolkitDispatch(fetchClient(Number(id)))
            .unwrap()
            .then(() => {
                handleEditClient();
            });
    };
    const renderClientsList = (list: string[]) => {
        const maxClientsLength = 5;
        const clientsList = list.slice(0, maxClientsLength).map((clientId) =>
            clientsCollection?.[clientId]?.name ? (
                <ListItem key={clientId} className={classes.listItem}>
                    <div className={classes.itemLabel}>
                        {TranslationHelper.translate(
                            clientsCollection[clientId].name
                        )}
                    </div>
                    {privileges.manageCustomerServiceCentre && (
                        <Tooltip title={TranslationHelper.translate('Edit')}>
                            <IconButton
                                onClick={() =>
                                    handleEditClientFromList(clientId)
                                }
                            >
                                <EditIcon />
                            </IconButton>
                        </Tooltip>
                    )}
                </ListItem>
            ) : null
        );
        return (
            <>
                {clientsList}
                {list.length > maxClientsLength && (
                    <ListItem key={'...'} className={classes.listItem}>
                        <div className={classes.itemLabel}>...</div>
                    </ListItem>
                )}
            </>
        );
    };

    return (
        <>
            {loading || (!client && !uniqueSelectedClients) ? (
                <Skeleton variant="rect" height={50} />
            ) : (
                <CollapseListItem
                    title={isMultiClient ? 'Clients' : 'Client'}
                    openSection={openSectionClients}
                    additionalText={
                        isMultiClient
                            ? `${TranslationHelper.translate('Selected')}: ${
                                  uniqueSelectedClients.length
                              }`
                            : ''
                    }
                    handleToggleCollapse={handleToggleCollapseClients}
                    handleEdit={
                        privileges.manageCustomerServiceCentre && !isMultiClient
                            ? handleEditClient
                            : undefined
                    }
                >
                    {isMultiClient ? (
                        <>{renderClientsList(uniqueSelectedClients)}</>
                    ) : (
                        <ClientPreviewForm initialValues={client} />
                    )}
                </CollapseListItem>
            )}
        </>
    );
};
export default ClientSection;
