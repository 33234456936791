import { AverageGroup, CalculationType, IDataEntry } from './averageGroup';

const MIN_COUNT_SIZE = 5;

export interface IAverageResult {
    average: number;
    entryIndex: number;
}

export class AverageAlgorithm {
    private group: AverageGroup;
    private prevGroup: AverageGroup;
    private firstIndex: number;
    private prevIndex: number;
    private readonly isGrowing: boolean;

    constructor(private type: CalculationType, growing: boolean) {
        this.group = new AverageGroup(type);
        this.prevGroup = new AverageGroup(type);
        this.firstIndex = 0;
        this.prevIndex = -1;
        this.isGrowing = growing;
    }

    public apply(entry: IDataEntry, index: number) {
        this.group.apply(entry);

        let result: IAverageResult[] | undefined;
        if (this.group.isFinished()) {
            const currentAverage = this.group.calculateAverage();
            const prevAverage = this.prevGroup.calculateAverage();
            if (
                this.group.count >= MIN_COUNT_SIZE &&
                (!this.isGrowing
                    ? currentAverage > prevAverage
                    : currentAverage < prevAverage)
            ) {
                result = [
                    {
                        average: currentAverage,
                        entryIndex:
                            this.firstIndex +
                            Math.floor((index - this.firstIndex) / 2),
                    },
                ];
                if (this.prevIndex > -1) {
                    result.push({
                        average: prevAverage,
                        entryIndex:
                            this.prevIndex +
                            Math.floor((this.firstIndex - this.prevIndex) / 2),
                    });
                }
            }
            this.prevGroup = this.group;
            this.group = new AverageGroup(this.type);
            this.group.apply(entry);
            this.prevIndex = this.firstIndex;
            this.firstIndex = index;
        }
        return result;
    }
}
