import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formWrapper: {
            padding: theme.spacing(),
            overflowX: 'hidden',
            overflowY: 'auto',
        },
        fieldMd: {
            paddingBottom: theme.spacing(),
            width: '50%',
            margin: '6px',
        },
        fieldLg: {
            paddingBottom: theme.spacing(),
            width: '90%',
            margin: '6px',
        },
    })
);
