import UrlConst from '../../constants/UrlConst';

import { IPage, IRegisterFilter } from '../../state/app/registers/';
import { IEmployee } from '../../state/app/registers/employees';

import { IRequestWithMessages } from '../../state/app/sync';

import apiClient from '../common/apiClient';
import {
    makeEndpoint,
    makeGetRequestSettings,
    makePostRequestSettings,
    makePutRequestSettings,
} from '../common/utils';
export const makeEmployeesUrl = (suffix: string = '', params = {}) => {
    return makeEndpoint(UrlConst.REGISTERS_URL, '/employees' + suffix, {
        ...params,
    });
};

export const restGetEmployees = (
    params: IRegisterFilter
): Promise<IPage<IEmployee>> =>
    apiClient.send(makeGetRequestSettings(makeEmployeesUrl('', params)));

export const restPostEmployee = (
    employee: IEmployee
): Promise<IRequestWithMessages> =>
    apiClient.send(makePostRequestSettings(makeEmployeesUrl(), employee));

export const restPutEmployee = (
    employee: IEmployee
): Promise<IRequestWithMessages> =>
    apiClient.send(
        makePutRequestSettings(makeEmployeesUrl(`/${employee.id}`), employee)
    );

export const restPutEmployeeCards = (
    id: string,
    cards: string[]
): Promise<IRequestWithMessages> =>
    apiClient.send(
        makePutRequestSettings(makeEmployeesUrl(`/${id}/cards`), cards)
    );
