import URI from 'urijs';
import AppConfig from '../../constants/AppConfig';
import UrlConst from '../../constants/UrlConst';
import apiClient from '../common/apiClient';
import {
    makeGetRequestSettings,
    makeCredentialsOnlyDeleteRequestSettings,
    makeUrl,
    makeCredentialsOnlyGetRequestSettings,
    makeFormContentPostRequestSettings,
    makePostRequestSettings,
    makePutRequestSettings,
    makeDeleteRequestSettings,
} from '../common/utils';
import {
    IReports,
    IReportsDefinition,
    IFilter,
    INewReport,
    IReportSchedule,
    INewSchedule,
    IUpdateSchedule,
} from '../../state/ui/reportsSlice';

export const makeReportsUrl = (suffix: string, params = {}) => {
    const url = new URI(
        AppConfig.instance.getUrl(UrlConst.REPORTS_URL) + suffix
    );
    return makeUrl(url, params);
};

export const restGetReports = (queryParams?: IFilter): Promise<IReports> =>
    apiClient.send(makeGetRequestSettings(makeReportsUrl('', queryParams)));

export const restGetReportsDefinitions = (): Promise<IReportsDefinition[]> =>
    apiClient.send(
        makeCredentialsOnlyGetRequestSettings(makeReportsUrl('/definitions'))
    );

export const restPostGenerateReport = (
    report: INewReport
): Promise<IReportsDefinition[]> =>
    apiClient.send(
        makeFormContentPostRequestSettings(makeReportsUrl(''), report)
    );

export const restDeleteReport = (id: string): Promise<null> =>
    apiClient.send(
        makeCredentialsOnlyDeleteRequestSettings(makeReportsUrl(`/${id}`))
    );

export const restGetReportSchedules = (): Promise<IReportSchedule[]> =>
    apiClient.send(makeGetRequestSettings(makeReportsUrl('/schedules')));

export const restPostCreateSchedule = (
    schedule: INewSchedule
): Promise<IReportsDefinition[]> =>
    apiClient.send(
        makePostRequestSettings(makeReportsUrl('/schedules'), schedule)
    );

export const restPutUpdateSchedule = (
    id: string,
    schedule: IUpdateSchedule
): Promise<IReportsDefinition[]> =>
    apiClient.send(
        makePutRequestSettings(makeReportsUrl(`/schedules/${id}`), schedule)
    );

export const restDeleteSchedule = (id: string): Promise<null> =>
    apiClient.send(
        makeDeleteRequestSettings(makeReportsUrl(`/schedules/${id}`))
    );
