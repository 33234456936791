import symbolPaths from './defaultSymbolPaths.json';

const makeSymbol = (d) => (symbolColor) =>
    `<path d="${d}" fill="${symbolColor}" fill-rule="evenodd"/>`;

const svg = ({ width, height }, ...children) => {
    const viewBox = `0 0 ${width} ${height}`;
    const xmlns = `http://www.w3.org/2000/svg`;
    const inner = children.join('');

    return `<svg xmlns="${xmlns}" viewBox="${viewBox}" width="${width}px" height="${height}px" fill="red">${inner}</svg>`;
};

const group = ({ width, height, azimuth }, ...children) => {
    const inner = children.join('');

    return `<g fill-rule="nonzero" fill="none" transform="rotate(${
        azimuth ? Math.round(azimuth) : 0
    }, ${width / 2}, ${height / 2})">${inner}</g>`;
};

export default {
    n2: ({
        width = 46,
        height = 46,
        azimuth = null,
        alert = false,
        note = false,
        noteColor = 'blue',
        fillColor = 'white',
        frameColor = 'black',
        symbolName = null,
        symbolColor = 'black',
    }) => ({
        width,
        height,
        content: svg(
            { width, height },
            group(
                { width, height, azimuth },
                azimuth === null
                    ? [
                          `<circle fill="${frameColor}" cx="23" cy="23" r="16"/>`,
                          `<circle fill="${fillColor}" cx="23" cy="23" r="13"/>`,
                      ]
                    : [
                          `<circle fill="${fillColor}" cx="23" cy="23" r="13"/>`,
                          `<path d="M11.686 11.686L23 .373l11.314 11.313c6.248 6.249 6.248 16.38 0 22.628-6.249 6.248-16.38 6.248-22.628 0-6.248-6.249-6.248-16.38 0-22.628zm20.506 20.506c5.077-5.076 5.077-13.308 0-18.384-5.076-5.077-13.308-5.077-18.384 0-5.077 5.076-5.077 13.308 0 18.384 5.076 5.077 13.308 5.077 18.384 0z" fill="${frameColor}"/>`,
                      ]
            ),
            (symbolName && symbolPaths[symbolName]
                ? makeSymbol(symbolPaths[symbolName])
                : () => '')(symbolColor),
            alert === true
                ? [
                      `<path d="M41 1h4v13h-4V1zm0 16h4v4h-4v-4z" fill="#FF0000" stroke="#FFF" stroke-width="1.6"/>`,
                  ].join('')
                : '',
            note === true
                ? [
                      `<circle fill="${noteColor}" stroke="#FFF" stroke-width="2" cx="5" cy="5" r="5"/>`,
                  ].join('')
                : ''
        ),
    }),
};
