import React, { ReactNode, MouseEvent, useState } from 'react';

import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Tooltip from '@material-ui/core/Tooltip';

import { useStyles } from './Themable.hooks';

import TranslationHelper from '../../helpers/TranslationHelper';
interface IOwnProps {
    children: ReactNode;
    iconComponent?: (
        onClick: (e: MouseEvent<HTMLElement>) => void
    ) => ReactNode;
}

const MenuDropdown = ({ children, iconComponent }: IOwnProps) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const classes = useStyles();

    const handleClick = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            {iconComponent ? (
                iconComponent(handleClick)
            ) : (
                <Tooltip
                    title={TranslationHelper.translate('Available functions')}
                >
                    <IconButton
                        aria-label="more"
                        aria-controls="long-menu"
                        aria-haspopup="true"
                        className={classes.menuIcon}
                        onClick={handleClick}
                    >
                        <MoreVertIcon />
                    </IconButton>
                </Tooltip>
            )}
            <Menu
                id="long-menu"
                anchorEl={anchorEl}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                keepMounted
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                classes={{
                    paper: classes.menu,
                }}
            >
                {children}
            </Menu>
        </div>
    );
};

export default MenuDropdown;
