import React, { Component, ComponentType } from 'react';
import { compose } from 'redux';

import { IParameterMapping } from '../../../../../../../../state/ui/forms';
import { Connectable, TConnectableProps } from './Connectable.hoc';
import { Themable, TThemableProps } from './Themable.hoc';

import { Collapse, IconButton, TextField, Tooltip } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Autocomplete, {
    AutocompleteRenderInputParams,
} from '@material-ui/lab/Autocomplete';
import TranslationHelper from '../../../../../../../../helpers/TranslationHelper';
import { IDefinitionItem } from '../../../../../../../../state/app/mappings';

export interface IOwnProps {
    parameters: IParameterMapping[];
    removeMapping: (index: number) => void;
}

type TProps = IOwnProps & TThemableProps & TConnectableProps;

interface IState {
    listVisible: boolean;
}

class MappingsList extends Component<TProps, IState> {
    public state: IState = {
        listVisible: true,
    };

    public toggleList = () => {
        this.setState((state) => {
            return {
                listVisible: !state.listVisible,
            };
        });
    };

    public getOptionName = (option: IDefinitionItem) => {
        return option.name;
    };

    public getOptionSelected = (
        option: IDefinitionItem,
        value: IDefinitionItem
    ) => {
        return option.name === value.name;
    };

    public renderSourceInput = (params: AutocompleteRenderInputParams) => {
        return (
            <TextField
                {...params}
                label={TranslationHelper.translate('Source')}
            />
        );
    };

    public renderTargetInput = (params: AutocompleteRenderInputParams) => {
        return (
            <TextField
                {...params}
                label={TranslationHelper.translate('Target')}
            />
        );
    };

    public renderAutocomplete = (
        value: IDefinitionItem | null,
        render: (params: AutocompleteRenderInputParams) => JSX.Element
    ) => {
        const { classes, sourceParamsDefs } = this.props;

        return (
            <Tooltip title={value?.name || ''}>
                <Autocomplete
                    value={value}
                    renderInput={(params) => render(params)}
                    className={classes.combobox}
                    options={sourceParamsDefs}
                    getOptionLabel={this.getOptionName}
                    disabled={true}
                    getOptionSelected={this.getOptionSelected}
                />
            </Tooltip>
        );
    };

    public render() {
        const { parameters, classes, removeMapping } = this.props;

        return (
            <div>
                <div className={classes.listItemHeading}>
                    <span className={classes.heading}>
                        {TranslationHelper.translate('Mapped parameters')}
                    </span>
                    <IconButton onClick={this.toggleList}>
                        {this.state.listVisible ? (
                            <ExpandLess />
                        ) : (
                            <ExpandMore />
                        )}
                    </IconButton>
                </div>
                <Collapse
                    in={this.state.listVisible}
                    timeout="auto"
                    unmountOnExit={true}
                >
                    {parameters.map((mapping, index) => {
                        return (
                            <div
                                className={classes.formRow}
                                key={`${mapping?.source}${mapping?.target}${index}`}
                            >
                                {this.renderAutocomplete(
                                    mapping.source,
                                    this.renderSourceInput
                                )}
                                {this.renderAutocomplete(
                                    mapping.target,
                                    this.renderTargetInput
                                )}
                                <IconButton
                                    onClick={() => removeMapping(index)}
                                    className="remove-mapping-button"
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </div>
                        );
                    })}
                </Collapse>
            </div>
        );
    }
}
export default compose(
    Themable,
    Connectable
)(MappingsList) as ComponentType<IOwnProps>;
