import { Divider, Paper } from '@material-ui/core';
import React from 'react';
import PaneHeader from '../../../../components/PaneHeader';

import TranslationHelper from '../../../../helpers/TranslationHelper';
import DetectionChart from './components/DetectionChart';
import { useStyles } from './Themable.hooks';

const DetectionPane = () => {
    const classes = useStyles();

    return (
        <Paper className={classes.pane}>
            <div className={classes.wrapper}>
                <PaneHeader title={TranslationHelper.translate('Fuel chart')} />
                <Divider />
                <DetectionChart />
            </div>
        </Paper>
    );
};

export default DetectionPane;
