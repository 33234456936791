import UrlConst from '../../constants/UrlConst';

import { IPage, IRegisterFilter } from '../../state/app/registers/';
import { IRFIDCard } from '../../state/app/registers/rfidCards';
import { IRequestWithMessages } from '../../state/app/sync';

import apiClient from '../common/apiClient';

import {
    makeEndpoint,
    makeGetRequestSettings,
    makePostRequestSettings,
    makePutRequestSettings,
} from '../common/utils';

export interface IImportRfidResponse {
    success: boolean;
    messages: string[];
}

export interface IGetMonitoredObjectsParams {
    name?: string;
    individualForUserId?: number;
}

export const makeRfidCardsUrl = (suffix: string = '', params = {}) => {
    return makeEndpoint(UrlConst.REGISTERS_URL, '/rfid-cards' + suffix, {
        ...params,
    });
};

export const restGetRfidCards = (
    params: IRegisterFilter
): Promise<IPage<IRFIDCard>> =>
    apiClient.send(makeGetRequestSettings(makeRfidCardsUrl('', params)));

export const restGetRfidCard = (id: string): Promise<IRequestWithMessages> =>
    apiClient.send(makeGetRequestSettings(makeRfidCardsUrl(`/${id}`)));

export const restPostRfidCard = (
    rfidCard: IRFIDCard
): Promise<IRequestWithMessages> =>
    apiClient.send(makePostRequestSettings(makeRfidCardsUrl(), rfidCard));

export const restPutRfidCard = (
    rfidCard: IRFIDCard
): Promise<IRequestWithMessages> =>
    apiClient.send(
        makePutRequestSettings(makeRfidCardsUrl(`/${rfidCard.id}`), rfidCard)
    );

export const restGetAvailableRfidCards = (): Promise<IRFIDCard[]> =>
    apiClient.send(
        makeGetRequestSettings(
            makeRfidCardsUrl('/all', { availableOnly: true })
        )
    );

export const restGetRfidCardsByGroup = (id: string): Promise<IRFIDCard[]> =>
    apiClient.send(
        makeGetRequestSettings(makeRfidCardsUrl('/all', { groupId: id }))
    );
