import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => {
    return {
        plannedOnWrapper: {
            display: 'flex',
            alignItems: 'center',
        },
        iconButton: {
            height: 30,
            width: 30,
            padding: 0,
        },
        iconButtonIcon: {
            height: 25,
            width: 25,
        },
    };
});
