import { IValueFormatterParams } from './types';
import TimeFormatter from '../../../helpers/TimeFormatter';

export default (params: IValueFormatterParams) => {
    if (params.value?.type === 'count') {
        return params.value.value;
    }
    if (params.value === undefined || params.value === null) {
        return '';
    }
    if (params.type === 'bar') {
        return [params.value];
    }
    if (params.type === 'duration') {
        const rawValue = params.value?.toNumber
            ? params.value.toNumber()
            : params.value;
        return TimeFormatter.formatDuration(rawValue as number);
    }
    if (params.type === 'number') {
        if (params.formatting && params.formatting.asDuration) {
            const value = params.value as number;
            return value >= 0
                ? TimeFormatter.formatDuration(value, 'minutes', 'hh:mm')
                : '';
        }
    }
    return params.value.toString();
};
