import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => {
    return createStyles({
        label: {
            margin: '10px 0 10px 0',
        },
        slider: {
            '& .MuiSlider-thumb': {
                margin: '0px',
            },
        },
        sliderGroup: {
            display: 'flex',
            marginLeft: '10px',
        },
        input: {
            maxWidth: '70px',
            margin: '0 0 15px 20px',
            paddingLeft: '15px',
        },
    });
});
