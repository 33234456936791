import React, {
    Component,
    ComponentType,
    FormEventHandler,
    KeyboardEventHandler,
} from 'react';
import { compose } from 'redux';

import { default as IconButton } from '@material-ui/core/IconButton';
import { default as BackIcon } from '@material-ui/icons/ArrowBack';
import { default as ClearIcon } from '@material-ui/icons/Clear';
import { default as SearchIcon } from '@material-ui/icons/Search';

import Translator from '../../../../helpers/TranslationHelper';
import { Connectable, TConnectableProps } from './Connectable.hoc';
import { Themable, TThemableProps } from './Themable.hoc';

interface IOwnProps {
    isMobile?: boolean;
    closeSearch?: () => void;
}

type TProps = TThemableProps & TConnectableProps & IOwnProps;

class SearchBar extends Component<TProps> {
    public handleSearchInputChange: FormEventHandler<HTMLInputElement> = ({
        target,
    }) => {
        const { updateSearchPhrase, isMobile } = this.props;
        updateSearchPhrase((target as HTMLInputElement).value, isMobile);
    };

    public handleSearchInputKeyUp: KeyboardEventHandler<HTMLInputElement> = ({
        keyCode,
    }) => {
        const { resetSearcher } = this.props;
        if (keyCode === 27) {
            // ESC
            resetSearcher();
        }
    };

    public handleSearchIconClick = () => this.props.toggleSearchResults(true);
    public handleSearchInputFocus = () => this.props.toggleSearchResults(true);
    public handleSearchReset = () => {
        this.props.toggleSearchResults(false);
        this.props.resetSearcher();
    };

    public render() {
        const { classes, searchPhrase, isMobile, closeSearch } = this.props;

        const displayCloseSearch = isMobile && closeSearch;

        return (
            <div className={!isMobile ? classes.root : classes.mobileRoot}>
                {displayCloseSearch ? (
                    <IconButton
                        aria-label="Close search"
                        color="primary"
                        onClick={closeSearch}
                    >
                        <BackIcon />
                    </IconButton>
                ) : (
                    <IconButton
                        aria-label="Search"
                        color="primary"
                        onClick={this.handleSearchIconClick}
                    >
                        <SearchIcon />
                    </IconButton>
                )}

                <input
                    placeholder={Translator.translate('Find object...')}
                    onFocus={this.handleSearchInputFocus}
                    className={classes.searchInput}
                    type="text"
                    value={searchPhrase}
                    onChange={this.handleSearchInputChange}
                    onKeyUp={this.handleSearchInputKeyUp}
                    autoFocus={true}
                />

                <IconButton
                    aria-label="Delete"
                    color="primary"
                    onClick={this.handleSearchReset}
                    className={classes.resetSearchBtn}
                >
                    <ClearIcon />
                </IconButton>
            </div>
        );
    }
}

export default compose(
    Themable,
    Connectable
)(SearchBar) as ComponentType<IOwnProps>;
