import { Theme, makeStyles } from '@material-ui/core';

export interface StyleProps {
    size: number;
}

export const useStyles = makeStyles<Theme, StyleProps>(() => ({
    root: {
        maxWidth: ({ size }) => size + 'px',
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        minWidth: '400px',
    },
    img: {
        objectFit: 'cover',
        width: ({ size }) => size + 'px',
        height: ({ size }) => size + 'px',
    },
    header: {
        color: 'white',
        display: 'flex',
        alignItems: 'center',
        height: '85%',
        width: '80%',
        position: 'absolute',
        top: 0,
        left: 60,
    },
    a: {
        zIndex: 2,
    },
    stepper: {
        position: 'relative',
        top: -32,
        backgroundColor: 'transparent',
    },
    buttonsContainer: {
        position: 'absolute',
        height: '100%',
        width: '100%',
    },
    buttons: {
        display: 'flex',
        justifyContent: 'space-between',
        height: '100%',
        alignItems: 'center',
    },
    button: {
        backgroundColor: 'rgba(0, 0, 0, 0.1)',
        color: 'white',
        margin: '0 8px',
    },
}));
