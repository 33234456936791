import React, { useEffect, useState } from 'react';

import { useToolkitDispatch } from '../../hooks';

import SearchableSelectionList from '../SearchableSelectionList';
import { fetchRegistersGroups } from '../../state/app/registers/groups';
import { IGroup } from '../../state/app/collections/dataTypes';

interface IOwnProps {
    selectedGroups: IMappedObject[];
    errors?: string;
    handleFormChange: <T>(name: string, value: T) => void;
    disabled?: boolean;
}

export interface IMappedObject {
    id: string;
    key: string;
    name: string;
}

const GroupsSection = ({
    selectedGroups,
    errors,
    handleFormChange,
    disabled,
}: IOwnProps) => {
    const [items, setItems] = useState<IMappedObject[]>([]);
    const [loaded, setLoaded] = useState(false);

    const toolkitDispatch = useToolkitDispatch();

    useEffect(() => {
        fetchGroups();
    }, []);

    const mapToSelectOptions = (group: IGroup): IMappedObject => {
        return {
            id: group.id,
            key: group.id,
            name: group.name,
        };
    };

    const fetchGroups = () => {
        toolkitDispatch(fetchRegistersGroups({}))
            .unwrap()
            .then((groups) => {
                setItems(groups.map(mapToSelectOptions));
                setLoaded(true);
            });
    };

    return (
        <SearchableSelectionList
            listError={errors}
            title="Groups"
            items={items}
            selectedItems={selectedGroups}
            loaded={loaded}
            filterKey={'name'}
            handleFormChange={handleFormChange}
            disabled={disabled}
        />
    );
};

export default GroupsSection;
