import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => {
    return createStyles({
        select: {
            margin: ' 0 5px',
            width: '-webkit-fill-available',
            maxWidth: '200px',
        },
        icon: {
            fill: theme.palette.getContrastText(theme.palette.primary.main),
        },
        root: {
            color: theme.palette.getContrastText(theme.palette.primary.main),
            '& label.Mui-focused': {
                color: theme.palette.getContrastText(
                    theme.palette.primary.main
                ),
            },
            '& .MuiFormLabel-root': {
                color: theme.palette.getContrastText(
                    theme.palette.primary.main
                ),
            },
            '& .MuiInput-underline:after': {
                borderBottomColor: theme.palette.getContrastText(
                    theme.palette.primary.main
                ),
            },
            '& .MuiOutlinedInput-root': {
                '& fieldset': {
                    borderColor: theme.palette.getContrastText(
                        theme.palette.primary.main
                    ),
                },
                '&:hover fieldset': {
                    borderColor: theme.palette.getContrastText(
                        theme.palette.primary.main
                    ),
                },
                '&.Mui-focused fieldset': {
                    borderColor: theme.palette.getContrastText(
                        theme.palette.primary.main
                    ),
                },
            },
        },
    });
});
