import { useReducer } from 'react';

const useStateReducer = <T,>(initialState: T) => {
    const [values, setValues] = useReducer(
        (curVals, newVals) => ({ ...curVals, ...newVals }),
        initialState
    );

    const handleChangeValues = <T,>(name: string, value: T) => {
        setValues({ [name]: value });
    };

    return {
        values,
        handleChangeValues,
    };
};

export default useStateReducer;
