import { FieldProps } from 'formik';
import React from 'react';

import Checkbox from '@material-ui/core/Checkbox';

const CustomCheckbox: React.FunctionComponent<FieldProps> = ({
    field,
    form,
    ...props
}) => (
    <Checkbox
        checked={field.value ? true : false}
        {...field}
        {...props}
        value={field.name}
        color="primary"
    />
);

export default CustomCheckbox;
