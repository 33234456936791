import React, { useEffect, useState } from 'react';
import Slide, { SlideProps } from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions';
import Dialog from '@material-ui/core/Dialog';
import ErrorBoundary from '../../../../../../components/ErrorBoundary';
import { closeDialog } from '../../../../../../state/_actions';
import { useAppDispatch } from '../../../../../../hooks';
import { useDiscoveryUIDialog } from '../../../../selectors/index.hooks';

const TransitionComponent = React.forwardRef<unknown, TransitionProps>(
    function Transition(props, ref) {
        return <Slide direction="up" ref={ref} {...(props as SlideProps)} />;
    }
);

const PreviewDialog: React.FC = ({ children }) => {
    const dialog = useDiscoveryUIDialog();
    const dispatch = useAppDispatch();
    const [isDialogOpen, setIsDialogOpen] = useState(!!dialog);

    const handleCloseDialog = () => {
        dialog && dispatch(closeDialog(dialog.creatorLevel));
        setIsDialogOpen(false);
    };

    useEffect(() => {
        setIsDialogOpen(!!dialog);
    }, [dialog]);

    return (
        <Dialog
            maxWidth="lg"
            open={isDialogOpen}
            TransitionComponent={TransitionComponent}
            keepMounted={true}
            onClose={handleCloseDialog}
        >
            <ErrorBoundary>
                {React.cloneElement(children as React.ReactElement, {
                    close: handleCloseDialog,
                })}
            </ErrorBoundary>
        </Dialog>
    );
};
export default PreviewDialog;
