class UrlConst {
    constructor() {
        this.vehiclesHost = '';
        this.iframeHost = '';
        this.authHost = '';
        this.reportsHost = '';
        this.registersHost = '';
        this.alertsHost = '';
        this.locationsHost = '';
        this.resourcesHost = '';
        this.uiHost = '';
        this.fuelTanksHost = '';
        this.monitoredObjectsHost = '';
        this.sourceSetsHost = '';
        this.reportSetsHost = '';
        this.reportSectionsHost = '';
        this.usersHost = '';
        this.routesHost = '';
        this.translationsHost = '';
        this.eventTypes = '';
        this.fuelingHost = '';
        this.filesHost = '';
        this._setPaths();
    }

    _setPaths() {
        this.loginPageUrl = this.uiHost + '/login';
        this.error404PageUrl = this.uiHost + '/404';
        this.error500PageUrl = this.uiHost + '/500';

        this.currentVehiclesUrl =
            this.vehiclesHost + UrlConst.REST_PREFIX + '/vehicles/current';
        this.vehiclesUrl =
            this.vehiclesHost + UrlConst.REST_PREFIX + '/vehicles/current';
        this.todoListUrl =
            this.vehiclesHost + UrlConst.REST_PREFIX + '/todo-list';
        this.archiveMapUrl =
            this.vehiclesHost + UrlConst.REST_PREFIX + '/vehicles/archiveMap';
        this.vehiclesEventsUrl =
            this.vehiclesHost + UrlConst.REST_PREFIX + '/vehicles';
        this.iframeUrl = this.iframeHost + UrlConst.REST_PREFIX + '/modules';
        this.loginUrl = this.authHost + UrlConst.REST_PREFIX + '/auth/login';
        this.customersUrl =
            this.authHost + UrlConst.REST_PREFIX + '/auth/customers';
        this.adsUrl =
            this.authHost + UrlConst.REST_PREFIX + '/registers/advertisements';
        this.authenticationUrl = this.authHost + UrlConst.REST_PREFIX + '/auth';
        this.vehicleStatesUrl =
            this.vehiclesHost + UrlConst.REST_PREFIX + '/vehicles/states';
        this.reportsUrl = this.reportsHost + UrlConst.REST_PREFIX + '/reports';
        this.routesUrl = this.routesHost + UrlConst.REST_PREFIX + '/routes';
        this.routesManagementUrl =
            this.routesHost + UrlConst.REST_PREFIX + '/routes-management';

        this.customerServiceUrl =
            this.routesHost + UrlConst.REST_PREFIX + '/csc';
        this.registersUrl =
            this.registersHost + UrlConst.REST_PREFIX + '/registers';
        this.registersTranslationsUrl = this.registersUrl + '/translations';
        this.alertsUrl = this.alertsHost + UrlConst.REST_PREFIX + '/alerts';
        this.monitoredObjectsUrl =
            this.monitoredObjectsHost + UrlConst.REST_PREFIX + '/monitored';
        this.alertDefinitionsUrl = this.alertsUrl + '/definitions';
        this.paramsDefinitionsUrl =
            this.monitoredObjectsUrl + '/params/definitions';
        this.sourceEventsUrl =
            this.monitoredObjectsUrl + '/archives/events/source';
        this.eventsUrl = this.monitoredObjectsUrl + '/archives/events';
        this.userUrl = this.authHost + UrlConst.REST_PREFIX + '/auth/user';
        this.userRightsUrl =
            this.authHost + UrlConst.REST_PREFIX + '/auth/rights';
        this.usersEndpointUrl =
            this.usersHost + UrlConst.REST_PREFIX + '/users';
        this.rolesEndpointUrl =
            this.usersHost + UrlConst.REST_PREFIX + '/users/roles';
        this.privilegesEndpointUrl =
            this.usersHost + UrlConst.REST_PREFIX + '/users/privileges';
        this.xssUrl = this.authHost + UrlConst.REST_PREFIX + '/auth/xss';
        this.locationsUrl =
            this.locationsHost + UrlConst.REST_PREFIX + '/locations';
        this.resourcesUrl =
            this.resourcesHost + UrlConst.REST_PREFIX + '/resources';
        this.vehiclesParamsUrl =
            this.vehiclesHost + UrlConst.REST_PREFIX + '/vehicles-params';
        this.fuelTanksUrl =
            this.fuelTanksHost + UrlConst.REST_PREFIX + '/tanks';
        this.fuelTanksEventsUrl =
            this.fuelTanksHost + UrlConst.REST_PREFIX + '/tanks';
        this.sourceSetsUrl =
            this.sourceSetsHost + UrlConst.REST_PREFIX + '/source-sets';
        this.sourceSetsViewsUrl =
            this.sourceSetsHost + UrlConst.REST_PREFIX + '/source-sets/views';
        this.filesUrl = this.filesHost + UrlConst.REST_PREFIX + '/files';
        this.reportSetsUrl =
            this.reportSetsHost + UrlConst.REST_PREFIX + '/report-sets';
        this.reportSectionsUrl =
            this.reportSectionsHost + UrlConst.REST_PREFIX + '/report-sections';
        this.monitoredObjectsStatesUrl =
            this.monitoredObjectsHost +
            UrlConst.REST_PREFIX +
            '/monitored/states';
        this.tasksUrl = this.routesHost + UrlConst.REST_PREFIX + '/tasks';
        this.translationsUrl = this.routesHost + '/translations';
        this.mapShpLayersUrl = '/configuration/layers';
        this.externalProviders = '/configuration/external';

        this.registersDevicesUrl = this.registersUrl + '/devices/';
        this.groupsEndpointUrl = this.registersUrl + '/groups';
        this.eventTypes = this.registersUrl + '/event-types';
        this.fuelingDetection =
            this.fuelingHost + UrlConst.REST_PREFIX + '/fueling/detection';
        this.fuelingOnDemandDataUrl = this.fuelingDetection + '/on-demand';
        this.layers = this.alertsHost + UrlConst.REST_PREFIX + '/layers';
    }

    changeToProduction() {
        this.vehiclesHost = '';
        this.iframeHost = '';
        this.authHost = '';
        this.reportsHost = '';
        this.registersHost = '';
        this.alertsHost = '';
        this.locationsHost = '';
        this.resourcesHost = '';
        this.uiHost = '';
        this.sourceSetsHost = '';
        this.filesHost = '';
        this.reportSetsHost = '';
        this.reportSectionsHost = '';
        this.routesHost = '';
        this.translationsHost = '';
        this.monitoredObjectsHost = '';
        this.fuelingHost = '';

        this._setPaths();
    }

    changeToTest() {
        let servicesHost = 'http://192.168.1.32:2000';
        this.vehiclesHost = servicesHost;
        this.iframeHost = servicesHost;
        this.authHost = servicesHost;
        this.reportsHost = servicesHost;
        this.registersHost = servicesHost;
        this.alertsHost = servicesHost;
        this.locationsHost = servicesHost;
        this.resourcesHost = servicesHost;
        this.sourceSetsHost = servicesHost;
        this.reportSetsHost = servicesHost;
        this.reportSectionsHost = servicesHost;
        this.routesHost = servicesHost;
        this.translationsHost = servicesHost;
        this.monitoredObjectsHost = servicesHost;
        this.fuelingHost = servicesHost;
        this.filesHost = servicesHost;
        this.uiHost = 'http://localhost:8090';

        this._setPaths();
    }

    changeToLocalDocker() {
        let servicesHost = 'http://192.168.99.100:8080';
        this.vehiclesHost = servicesHost;
        this.iframeHost = servicesHost;
        this.authHost = servicesHost;
        this.reportsHost = servicesHost;
        this.registersHost = servicesHost;
        this.alertsHost = servicesHost;
        this.locationsHost = servicesHost;
        this.resourcesHost = servicesHost;
        this.sourceSetsHost = servicesHost;
        this.reportSetsHost = servicesHost;
        this.reportSectionsHost = servicesHost;
        this.routesHost = servicesHost;
        this.translationsHost = servicesHost;
        this.monitoredObjectsHost = servicesHost;
        this.fuelingHost = servicesHost;
        this.uiHost = 'http://localhost:8090';

        this._setPaths();
    }

    getProperty(constVal) {
        return this[constVal];
    }
}

UrlConst.REST_PREFIX = '/rest/api';
UrlConst.NYI_PREFIX = '/nyi';

UrlConst.LOGIN_PAGE_URL = 'loginPageUrl';
UrlConst.ERROR_404_PAGE_URL = 'error404PageUrl';
UrlConst.ERROR_500_PAGE_URL = 'error500PageUrl';

UrlConst.CURRENT_VEHICLES_URL = 'currentVehiclesUrl';
UrlConst.CUSTOMER_SERVICE_URL = 'customerServiceUrl';

UrlConst.VEHICLES_URL = 'vehiclesUrl';
UrlConst.ARCHIVE_MAP_URL = 'archiveMapUrl';
UrlConst.VEHICLES_EVENTS_URL = 'vehiclesEventsUrl';
UrlConst.IFRAME_URL = 'iframeUrl';
UrlConst.LOGIN_URL = 'loginUrl';
UrlConst.CUSTOMERS_URL = 'customersUrl';
UrlConst.ADS_URL = 'adsUrl';
UrlConst.AUTHENTICATION_URL = 'authenticationUrl';
UrlConst.VEHICLE_STATES_URL = 'vehicleStatesUrl';
UrlConst.REPORTS_URL = 'reportsUrl';
UrlConst.ROUTES_URL = 'routesUrl';
UrlConst.ROUTES_MANAGEMENT_URL = 'routesManagementUrl';

UrlConst.TODO_LIST_URL = 'todoListUrl';
UrlConst.REGISTERS_URL = 'registersUrl';
UrlConst.REGISTERS_TRANSLATIONS_URL = 'registersTranslationsUrl';
UrlConst.TASKS_URL = 'tasksUrl';
UrlConst.ALERTS_URL = 'alertsUrl';
UrlConst.USER_URL = 'userUrl';
UrlConst.USER_RIGHTS_URL = 'userRightsUrl';
UrlConst.USERS_ENDPOINT_URL = 'usersEndpointUrl';
UrlConst.ROLES_ENDPOINT_URL = 'rolesEndpointUrl';
UrlConst.PRIVILEGES_ENDPOINT_URL = 'privilegesEndpointUrl';
UrlConst.XSS_URL = 'xssUrl';
UrlConst.LOCATIONS_URL = 'locationsUrl';
UrlConst.RESOURCES_URL = 'resourcesUrl';
UrlConst.VEHICLE_PARAMS_URL = 'vehiclesParamsUrl';
UrlConst.FUELTANKS_URL = 'fuelTanksUrl';
UrlConst.FUEL_TANKS_EVENTS_URL = 'fuelTanksEventsUrl';
UrlConst.MONITORED_OBJECTS_URL = 'monitoredObjectsUrl';
UrlConst.SOURCE_SETS_URL = 'sourceSetsUrl';
UrlConst.SOURCE_SETS_VIEWS_URL = 'sourceSetsViewsUrl';
UrlConst.REPORT_SETS_URL = 'reportSetsUrl';
UrlConst.REPORT_SECTIONS_URL = 'reportSectionsUrl';
UrlConst.MAP_SHP_LAYERS = 'mapShpLayersUrl';
UrlConst.MONITORED_OBJECTS_STATES_URL = 'monitoredObjectsStatesUrl';
UrlConst.ALERT_DEFINITIONS_URL = 'alertDefinitionsUrl';
UrlConst.TRANSLATIONS_URL = 'translationsUrl';
UrlConst.PARAMS_DEFINITIONS_URL = 'paramsDefinitionsUrl';
UrlConst.SOURCE_EVENTS_URL = 'sourceEventsUrl';
UrlConst.REGISTERS_DEVICES_URL = 'registersDevicesUrl';
UrlConst.GROUPS_ENDPOINT_URL = 'groupsEndpointUrl';
UrlConst.EVENT_TYPES = 'eventTypes';
UrlConst.EXTERNAL_PROVIDERS = 'externalProviders';

UrlConst.FILES_URL = 'filesUrl';

UrlConst.EVENTS_URL = 'eventsUrl';
UrlConst.FUELING_ON_DEMAND_DATA_URL = 'fuelingOnDemandDataUrl';
UrlConst.LAYERS = 'layers';

export default UrlConst;
