import React, { ComponentType } from 'react';
import { Prompt, useLocation } from 'react-router-dom';
import { compose } from 'redux';
import { Connectable, TConnectableProps } from './Connectable.hoc';
import { Themable, TThemableProps } from './Themable.hoc';

import TranslationHelper from '../../../../helpers/TranslationHelper';

interface IOwnProps {
    hidden?: boolean;
}

type TProps = IOwnProps & TThemableProps & TConnectableProps;

const EditOverlay = ({
    classes,
    discoveryEdit,
    adminEdit,
    hidden,
    managementEdit,
    reportsEdit,
    clientsAndLocationsEdit,
    bottomGridEdit,
    planningEdit,
}: TProps) => {
    let editMode = false;
    const location = useLocation();

    switch (location.pathname) {
        case '/discovery':
            editMode = !!discoveryEdit;
            break;

        case '/admin':
            editMode = !!adminEdit;
            break;

        case '/management':
            editMode = !!managementEdit;
            break;
        case '/reports':
            editMode = !!reportsEdit;
            break;
        case '/customer-service':
            editMode = !!clientsAndLocationsEdit || !!bottomGridEdit;
            break;
        case '/planning':
            editMode = !!planningEdit;
    }

    editMode = !hidden && editMode;

    return (
        <>
            {editMode ? <div className={classes.overlay} /> : null}
            {location.pathname !== '/discovery' && (
                <Prompt
                    when={editMode}
                    message={TranslationHelper.translate(
                        'Unsaved data will be lost. Do you want to continue?'
                    )}
                />
            )}
        </>
    );
};

export default compose(
    Themable,
    Connectable
)(EditOverlay) as ComponentType<IOwnProps>;
