import { createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => {
    return createStyles({
        usersPageWrapper: {
            position: 'absolute',
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
        },
        visibleUserPane: {
            position: 'absolute',
            top: 0,
            left: 0,
            bottom: 0,
            right: 500 + 10,
        },
        hiddenUserPane: {
            position: 'absolute',
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
        },
        userPaneWrapper: {
            position: 'absolute',
            top: 0,
            left: 'calc(100% - 500px)',
            width: 500,
            maxHeight: '100%',
            right: 0,
            overflowY: 'auto',
            overflowX: 'hidden',
            display: 'flex',
            height: '100%',
        },
    });
});
