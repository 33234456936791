import { createStyles, makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => {
    return createStyles({
        wrapper: {
            minHeight: '500px',
            minWidth: '200px',
            display: 'flex',
            position: 'relative',
            flexGrow: 1,
            flexDirection: 'column',
        },
        paper: {
            width: '1200px',
            height: '700px',
            margin: '0',
        },
    });
});
