import { createStyles, makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() =>
    createStyles({
        container: {
            flexGrow: 1,
            display: 'flex',
            overflow: 'hidden',
            height: '100%',
        },
        chart: {
            flex: '1 1 auto',
            width: 50,
        },
        placeholder: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            width: '100%',
        },
    })
);
