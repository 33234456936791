import React, { Component } from 'react';
import { connect } from 'react-redux';

class AuthGuard extends Component {
    render() {
        return !!this.props.user === true
            ? this.props.children
            : this.props.renderCover();
    }
}

const mapStateToProps = (state) => ({
    user: state.login.user,
});

export default connect(mapStateToProps)(AuthGuard);
