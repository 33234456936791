import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        itemLabel: {
            fontFamily: 'OpenSans-Regular',
            fontSize: theme.typography.caption.fontSize,
            paddingRight: theme.spacing(),
            fontWeight: 'bold',
            wordWrap: 'break-word',
            width: '50%',
        },
        secondaryAction: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            width: '50%',
            wordWrap: 'break-word',
            textAlign: 'right',
        },
        attachment: {
            fontSize: theme.typography.caption.fontSize,
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
        },
        downloadLink: {
            display: 'inline-block',
            width: 100,
            cursor: 'pointer',
            textDecoration: 'underline',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            justifySelf: 'flex-start',
        },
        iconButton: {
            height: 30,
            width: 30,
            padding: 0,
        },
        iconButtonIcon: {
            height: 25,
            width: 25,
        },
        listItem: {
            fontSize: theme.typography.caption.fontSize,
            justifyContent: 'space-between',
            borderTop: '1px solid ' + theme.palette.divider,
            color: theme.palette.primary.dark,
        },
    })
);
