import React, { ChangeEvent, Component, ComponentType } from 'react';
import { compose } from 'redux';

import { InputBase } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

import Translator from '../../../helpers/TranslationHelper';
import { Themable, TThemableProps } from './Themable.hoc';

interface IOwnProps {
    filterChange: (input: string) => void;
    value: string;
}

type TProps = IOwnProps & TThemableProps;

class SearchField extends Component<TProps> {
    public render() {
        const { classes } = this.props;

        return (
            <>
                <div className={classes.search}>
                    <div className={classes.searchIcon}>
                        <SearchIcon />
                    </div>
                    <InputBase
                        placeholder={Translator.translate('grid.filterOoo')}
                        autoFocus={true}
                        value={this.props.value}
                        onChange={this.handleChange}
                        classes={{
                            root: classes.inputRoot,
                            input: classes.inputInput,
                        }}
                        inputProps={{ 'aria-label': 'search' }}
                    />
                </div>
            </>
        );
    }

    private handleChange = ({
        target: { value: filter },
    }: ChangeEvent<HTMLInputElement>) => {
        this.props.filterChange(filter);
    };
}

export default compose(Themable)(SearchField) as ComponentType<IOwnProps>;
