import React from 'react';
import { func, string, node } from 'prop-types';

class Dialog extends React.Component {
    constructor(props, state) {
        super(props, state);

        this._onCloseButtonClick = this._onCloseButtonClick.bind(this);
        this._keyboardButtonListener = this._keyboardButtonListener.bind(this);
    }

    componentDidMount() {
        document.addEventListener('keyup', this._keyboardButtonListener);
    }

    componentWillUnmount() {
        document.removeEventListener('keyup', this._keyboardButtonListener);
    }

    _keyboardButtonListener(event) {
        if (event.keyCode === 27) {
            this._onCloseButtonClick(event);
        }
    }

    _onCloseButtonClick(event) {
        this.props.onClose && this.props.onClose(event);
    }

    render() {
        return (
            <section className={this.props.classNames || 'dialog'}>
                <header className="dialog--header">
                    <button
                        className="dialog--close-button button button-icon"
                        onClick={this._onCloseButtonClick}
                    >
                        {<span className="icon icon-close" />}
                    </button>
                    <h1 className="dialog--title">{this.props.title}</h1>
                </header>
                <div className="dialog--content">{this.props.children}</div>
            </section>
        );
    }
}

Dialog.propTypes = {
    onClose: func,
    classNames: string,
    title: string,
    children: node,
};

export default Dialog;
