import { ITransferListObject } from 'src/app/components/TransferList/TransferList';

export type TSelectedRight = 'SELECTED' | 'ALL' | 'NONE' | '';

const MAX_SPECIAL_RIGHTS_SIZE = 4;

export const deduceSelectedRight = (
    selectedRight: TSelectedRight,
    assignedObjects: ITransferListObject[]
): TSelectedRight => {
    const specialRightsSize = assignedObjects.filter(
        (o) => o.type == 'SPECIAL'
    ).length;

    if (specialRightsSize === MAX_SPECIAL_RIGHTS_SIZE) {
        return 'ALL';
    }

    return selectedRight;
};
