import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        //grid
        gridPane: {
            display: 'flex',
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
        },

        gridContainer: {
            flexGrow: 1,
            display: 'flex',
        },
        gridWrapper: {
            flexGrow: 1,
            width: '100%',
        },
        gridMapVisible: {
            position: 'absolute',
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            width: '70%',
        },
        gridMapHidden: {
            position: 'absolute',
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            width: '100%',
        },
        visibleBottomGrid: {
            position: 'absolute',
            top: 0,
            left: 0,
            bottom: '60%',
            right: 0,
        },
        hiddenBottomGrid: {
            position: 'absolute',
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
        },
        bottomGrid: {
            position: 'absolute',
            top: '40%',
            left: 0,
            bottom: 0,
            right: 0,
        },
        paneVisible: {
            position: 'absolute',
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            width: 'calc(100% - 410px)',
        },
        hiddenPane: {
            position: 'absolute',
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            width: '100%',
        },
        //right pane
        paneWrapperMapVisible: {
            position: 'absolute',
            top: 0,
            left: 'calc(70% - 400px)',
            width: 400,
            maxHeight: '100%',
            right: 0,
            overflowY: 'auto',
            overflowX: 'hidden',
            display: 'flex',
            height: '100%',
            zIndex: 200,
        },
        paneWrapperMapHidden: {
            position: 'absolute',
            top: 0,
            left: 'calc(100% - 400px)',
            width: 400,
            maxHeight: '100%',
            right: 0,
            overflowY: 'auto',
            overflowX: 'hidden',
            display: 'flex',
            height: '100%',
            zIndex: 200,
        },
        //map
        smallMap: {
            flexDirection: 'column',
            display: 'flex',
            height: 'calc(100% - 55px)',
        },
        mapContainer: {
            top: 0,
            right: 0,
            width: 'calc(30% - 10px)',
            bottom: 0,
            position: 'absolute',
        },
        map: {
            height: '100%',
        },
        mapPane: {},
        //components
        field: {
            marginTop: theme.spacing(),
            paddingBottom: theme.spacing(),
        },
        typography: {
            margin: `${theme.spacing()}px 0px`,
        },
        itemLabel: {
            fontFamily: 'OpenSans-Regular',
            fontSize: theme.typography.caption.fontSize,
            padding: `${theme.spacing()}px ${theme.spacing()}px ${theme.spacing()}px 0 `,
            fontWeight: 'bold',
            wordWrap: 'break-word',
            width: '60%',
        },
        listItem: {
            fontSize: theme.typography.caption.fontSize,
            justifyContent: 'space-between',
            borderTop: '1px solid ' + theme.palette.divider,
            color: theme.palette.primary.dark,
        },
        inlineContainer: {
            display: 'flex',
            justifyContent: 'space-between',
            gap: '10px',
        },
        container: {
            flexGrow: 1,
            display: 'flex',
            '@media not print': {
                overflow: 'hidden',
            },
        },

        formPane: {
            position: 'absolute',
            minHeight: '100%',
            top: 0,
            right: 0,
            left: 0,
        },
        pageWrapper: {
            position: 'absolute',
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
        },
        wrapper: {
            display: 'flex',
            position: 'relative',
            flexGrow: 1,
            flexDirection: 'column',
        },
        paneWrapper: {
            position: 'absolute',
            top: 0,
            left: 'calc(70% - 400px)',
            width: 400,
            maxHeight: '100%',
            right: 0,
            overflowY: 'auto',
            overflowX: 'hidden',
            display: 'flex',
            height: '100%',
            zIndex: 200,
        },
        formWrapper: {
            padding: theme.spacing(3),
            '& .MuiTextField-root': {
                margin: `${theme.spacing(1)}px 0`,
            },
        },
        pageContent: {
            position: 'absolute',
            top: 10,
            left: 10,
            bottom: 10,
            right: 10,
            [theme.breakpoints.down('sm')]: {
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
            },
        },

        noDataContainer: {
            display: 'flex',
            justifyContent: 'center',
            height: '30vh',
            alignItems: 'center',
        },
    })
);
