import React from 'react';

export const trimObjectValues = (object: {}) => {
    const trimmedObject = { ...object };
    for (const key in trimmedObject) {
        if (trimmedObject.hasOwnProperty(key)) {
            trimmedObject[key] = trimmedObject[key].trim();
        }
    }

    return trimmedObject;
};

export const trimValue = (
    event: React.FocusEvent<HTMLInputElement>,
    callback: (name: string, value: any) => void
) => {
    const value = event.target.value.trim();
    callback(event.target.name, value);
};
