import React from 'react';
import { useStyles } from './Themable.hooks';

import { Checkbox, FormControlLabel } from '@material-ui/core';
import TranslationHelper from '../../helpers/TranslationHelper';

export interface IDataSet {
    id: string;
    name: string;
    dataKey: string;
    color: string;
    type: string;
}

interface IOwnProps {
    handleAttributeChange: (name: string) => void;
    dataSets: IDataSet[];
    activeAttributes: { [name: string]: boolean };
}

const ChartParamsList = ({
    handleAttributeChange,
    dataSets,
    activeAttributes,
}: IOwnProps) => {
    const classes = useStyles();

    return (
        <div className={classes.paramSelectorWrapper}>
            <span className={classes.paramSelectorHeader}>
                {TranslationHelper.translate('Select parameters')}:
            </span>
            <ul className={classes.paramList}>
                {dataSets.map((dataSet) => (
                    <li
                        key={dataSet.id}
                        className={classes.paramSelectorItem}
                        style={{ borderBottomColor: dataSet.color }}
                    >
                        <FormControlLabel
                            control={
                                <Checkbox
                                    color="primary"
                                    className={classes.checkbox}
                                    checked={activeAttributes[dataSet.dataKey]}
                                    onChange={() =>
                                        handleAttributeChange(dataSet.dataKey)
                                    }
                                />
                            }
                            label={TranslationHelper.translate(dataSet.name)}
                        />
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default ChartParamsList;
