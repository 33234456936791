import createStyles from '@material-ui/core/styles/createStyles';

import { Theme, WithStyles, withStyles } from '@material-ui/core/styles';

const styles = (theme: Theme) => {
    const spacing = theme.spacing(3);
    return createStyles({
        pane: {
            position: 'absolute',
            zIndex: 300,
            top: spacing,
            left: spacing,
            width: 400,
        },
    });
};

export type TThemableProps = WithStyles<typeof styles>;

export const Themable = withStyles(styles, { withTheme: true });
