import React from 'react';

import { ISourceSet } from '../../../../../../state/types';

import ErrorMessage from '../ErrorMessage';
import SearchField from '../../../../../../components/common/SearchField/SearchField';
import SwitchToggle from '../../../../../../components/SwitchToggle';

interface IOwnProps {
    showSearchField: boolean | null;
    sourceSet: ISourceSet | null;
    searchPhrase: string;
    setGridSearch: (filter: string) => void;
}

const GridHeaderLeftControls = ({
    sourceSet,
    setGridSearch,
    showSearchField,
    searchPhrase,
}: IOwnProps) => {
    const handleFilterChange = (filter: string) => {
        setGridSearch(filter);
    };
    const toggleFilters = sourceSet?._meta?.globalEntities?.filter(
        (entity) => entity.type === 'unmanagedToggle'
    );

    const renderSwitchFields = () => {
        return toggleFilters?.map((toggle) => (
            <SwitchToggle
                title={toggle.label}
                filterId={toggle.id}
                key={toggle.id + sourceSet?.definitionId}
                initialValue={toggle.value as boolean}
                sourceSetId={sourceSet?.id || ''}
            />
        ));
    };
    const renderSearchField = () => {
        if (showSearchField) {
            return (
                <SearchField
                    value={searchPhrase}
                    filterChange={handleFilterChange}
                />
            );
        }
        return null;
    };

    return (
        <>
            {renderSearchField()}
            {renderSwitchFields()}
            <ErrorMessage messages={sourceSet?.messages} />
        </>
    );
};

export default GridHeaderLeftControls;
