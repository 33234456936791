import React from 'react';

import { ClassNameMap } from '@material-ui/core/styles/withStyles';

import ChipsFieldWrapper from '../../../../common/formikWrappers/ChipsFieldWrapper';
import TranslationHelper from '../../../../../helpers/TranslationHelper';
import {
    validateDouble,
    validateInteger,
    validateText,
} from '../../../../../helpers/validationHelpers';
import { FormikErrors } from 'formik';
import { IAttributesForm } from './AttributesForm';

interface IOwnProps {
    formValues: IAttributesForm;
    errors: FormikErrors<IAttributesForm>;
    classes: ClassNameMap<'field'>;
    handleFormChange: (name: string, value: string | number | boolean) => void;
    inputType: string;
    setFieldError: (name: string, value: string) => void;
}
export const DictionaryWrapper = ({
    formValues,
    errors,
    classes,
    handleFormChange,
    inputType,
    setFieldError,
}: IOwnProps) => {
    const field = {
        name: 'dictionary',
        value: formValues.dictionary,
        onChange: () => {
            return;
        },
        onBlur: () => {
            return;
        },
    };
    const form = {
        values: formValues,
        setFieldValue: handleFormChange,
        setFieldError,
    } as any;

    const chipFieldProps = {
        className: classes.field,
        name: 'Dictionary',
        error: errors?.['dictionary'] || errors?.['dictionaryField'],
        helperText: errors?.['dictionary'] || errors?.['dictionaryField'],
        label: TranslationHelper.translate('New value'),
        type: inputType === 'String' ? 'text' : 'number',
        value: formValues.dictionary.map((dict) => dict.value),
        form,
        field,
    };
    const handleRemoveChip = () => {
        if (formValues.lockedToDictionary) {
            handleFormChange('dictionaryItemRemoved', true);
        }
    };
    const handleValidateInput = () => {
        if (inputType === 'Integer') {
            return validateInteger;
        }
        if (inputType === 'Double') {
            return validateDouble;
        }
        return validateText;
    };
    const validateFn = handleValidateInput();
    return (
        <ChipsFieldWrapper
            inputController={'dictionaryField'}
            validateInput={validateFn}
            onDeleteCallback={handleRemoveChip}
            complexChipData={formValues.dictionary.map((item) => {
                return { value: item.value, disabled: item.internal };
            })}
            {...chipFieldProps}
        />
    );
};
