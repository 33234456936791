import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => {
    return createStyles({
        cell: {
            display: 'flex',
            alignItems: 'center',
        },
        icon: {
            color: theme.palette.primary.main,
            height: theme.spacing(3),
            marginRight: theme.spacing(1),
            cursor: 'pointer',
        },
        popover: {
            padding: theme.spacing(1),
        },
    });
});
