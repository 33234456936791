import {
    createSlice,
    createSelector,
    createAsyncThunk,
} from '@reduxjs/toolkit';

import {
    restGetContractItem,
    restGetContractItems,
} from '../../../../../services/customerService/contracts';
import { getMappedDictionaries } from '../../../../../state/app/dictionaries';

import { ISourceSet } from '../../../../types';

import { TRootState } from '../../../../../store';
import {
    IContractItem,
    IContractsPreFetchParams,
    IServiceContractsUrlParams,
} from '../../../../../models/customerService';
import { getSourceSetWithFilter } from '../../../../../pages/discovery/selectors/sourceSet/getSourceSetWithFilter';

interface IState {
    selectedContractItemId: number | null;
    contractItem: IContractItem | null;
    contractItems: IGroupedContractItems[] | null;
    loading: boolean;
    paneLoading: boolean;
    openSectionContracts: boolean;
    openSectionContractAttachments: boolean;
    openSectionContract: boolean;
    selectedCycleId: number | undefined;
}

interface IGroupedContractItems {
    locationName: string;
    clientName: string;
    locationId: number;
    clientId: string;
    coordinate: { x: number; y: number };
    values: IContractItem[];
}

export const initialState: IState = {
    selectedContractItemId: null,
    contractItem: null,
    contractItems: null,
    loading: false,
    paneLoading: false,
    openSectionContracts: false,
    openSectionContractAttachments: false,
    openSectionContract: false,
    selectedCycleId: undefined,
};

export const CONTRACT_ITEMS_GRID_ID = 'ContractItems';

export const fetchContractItems = createAsyncThunk(
    'get:ui/customerService/bottomGrid/contractItems',
    async (params: IContractsPreFetchParams, { rejectWithValue }) => {
        const locations = params.selectedClientsAndLocations.filter(
            (item) => item.id
        );
        const responses = await Promise.all(
            locations.map(async (location) => {
                const updatedParams = {
                    ...params,
                    locationId: Number(location.id),
                    clientId: null,
                    selectedClientsAndLocations: null,
                } as IServiceContractsUrlParams;

                const getValues = async () => {
                    try {
                        const response = await restGetContractItems(
                            updatedParams
                        );
                        return response;
                    } catch (e: any) {
                        throw rejectWithValue({
                            status: e.status,
                            errorMsg: e.responseJSON.message,
                        });
                    }
                };

                const values = await getValues();

                return {
                    clientName: location.clientName,
                    locationName: location.name,
                    clientId: location.clientId,
                    locationId: Number(location.id),
                    coordinate: location.coordinates,
                    values,
                };
            })
        );

        return responses;
    }
);

export const fetchContractItem = createAsyncThunk(
    'get:ui/customerService/bottomGrid/contractItems/id',
    async (params: { id: number; cycleId: number | undefined }) => {
        const response = await restGetContractItem(params.id, params.cycleId);

        return response;
    }
);

const contractItems = createSlice({
    name: 'contractItems',
    initialState,
    reducers: {
        toggleCollapseContracts(state) {
            state.openSectionContracts = !state.openSectionContracts;
        },
        toggleCollapseContractAttachments(state) {
            state.openSectionContractAttachments =
                !state.openSectionContractAttachments;
        },
        toggleCollapseContract(state) {
            state.openSectionContract = !state.openSectionContract;
        },
        selectContractItem(state, action) {
            if (action.payload) {
                state.selectedContractItemId = action.payload.id;
                state.selectedCycleId = action.payload.cycleId;
            }
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchContractItems.rejected, (state) => {
            state.loading = false;
        });
        builder.addCase(fetchContractItems.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(fetchContractItems.fulfilled, (state, action) => {
            state.contractItems = action.payload;
            state.loading = false;
        });
        builder.addCase(fetchContractItem.pending, (state) => {
            state.contractItem = null;
            state.paneLoading = true;
        });
        builder.addCase(fetchContractItem.fulfilled, (state, action) => {
            state.contractItem = action.payload;
            state.paneLoading = false;
        });
    },
});

const getCurrent = (state: TRootState) =>
    state.ui.customerService.contractItems as IState;

export const getContractItems = (state: TRootState) =>
    getCurrent(state).contractItems;

export const getContractItem = (state: TRootState) =>
    getCurrent(state).contractItem;

export const getSelectedContractItemId = (state: TRootState) =>
    getCurrent(state).selectedContractItemId;

export const getSelectedContractItemCycleId = (state: TRootState) =>
    getCurrent(state).selectedCycleId;

export const getContractItemsLoading = (state: TRootState) =>
    getCurrent(state).loading;

export const getContractItemsPaneLoading = (state: TRootState) =>
    getCurrent(state).paneLoading;

export const getSourceSetModels = (state: TRootState) =>
    state.ui.discovery.general.sourceSetModels;

export const getOpenSectionContracts = (state: TRootState) =>
    state.ui.customerService.contractItems.openSectionContracts;
export const getOpenSectionContractAttachments = (state: TRootState) =>
    state.ui.customerService.contractItems.openSectionContractAttachments;
export const getOpenSectionContract = (state: TRootState) =>
    state.ui.customerService.contractItems.openSectionContract;

export const getContractItemssSourceSetModel = createSelector(
    [getSourceSetModels],
    (models) => models.contractItems || null
);

export const getContractItemsAsSourceSet = createSelector(
    [getContractItems, getMappedDictionaries],
    (contractItems, dictionaries): ISourceSet | null =>
        contractItems && {
            id: 'contractItems',
            definitionId: CONTRACT_ITEMS_GRID_ID,
            label: 'Contract items',
            attributes: [
                {
                    id: 'contractItems',
                    type: 'parent',
                    label: 'Contract item',
                    selectable: true,
                    children: [
                        {
                            id: 'clientName',
                            label: 'Client',
                            type: 'text',
                        },
                        {
                            id: 'locationName',
                            label: 'Location name',
                            type: 'text',
                        },
                        {
                            id: 'startDate',
                            label: 'Start date',
                            type: 'date',
                            formatting: {
                                pattern: 'short',
                            },
                        },
                        {
                            id: 'stopDate',
                            label: 'Stop date',
                            type: 'date',
                            formatting: {
                                pattern: 'short',
                            },
                        },
                        {
                            id: 'nextOccurrenceDate',
                            label: 'Next occurrence date',
                            type: 'date',
                            formatting: {
                                pattern: 'short',
                            },
                        },
                        {
                            id: 'cycleDefinition',
                            label: 'Cycle definition',
                            type: 'text',
                        },
                        {
                            id: 'activityCategoryId',
                            label: 'Type 1',
                            type: 'text',
                        },
                        {
                            id: 'serviceTypeId',
                            label: 'Type 2',
                            type: 'text',
                        },
                        {
                            id: 'objectCategoryId',
                            label: 'Type 3',
                            type: 'text',
                        },
                        {
                            id: 'serviceClassId',
                            label: 'Type 4',
                            type: 'text',
                        },
                        {
                            id: 'assetsCount',
                            label: 'Assets count',
                            type: 'number',
                        },
                        { id: 'notice', label: 'Notice', type: 'text' },
                        {
                            id: 'externalNumber',
                            label: 'External number',
                            type: 'text',
                        },
                        {
                            id: 'hasAttachments',
                            label: 'Attachments',
                            type: 'iconAttachment',
                            cellClass: 'booleanType',
                        },
                    ],
                },
                {
                    id: 'contract',
                    type: 'parent',
                    label: 'Contract',
                    children: [
                        {
                            id: 'name',
                            label: 'Name',
                            type: 'text',
                        },
                        {
                            id: 'contractStartDate',
                            label: 'Start date',
                            type: 'date',
                            formatting: {
                                pattern: 'short',
                            },
                        },
                        {
                            id: 'contractStopDate',
                            label: 'Stop date',
                            type: 'date',
                            formatting: {
                                pattern: 'short',
                            },
                        },
                        {
                            id: 'isActive',
                            label: 'Is active',
                            type: 'statusIcon',
                            cellClass: 'booleanType',
                        },
                    ],
                },
            ],
            layersAttributes: [],
            _meta: {},
            entities: contractItems
                .map((contractItem: IGroupedContractItems): any => {
                    return contractItem.values.map(
                        (contractItemValue: IContractItem): any => {
                            const {
                                name,
                                startDate: contractStartDate,
                                stopDate: contractStopDate,
                                isActive,
                            } = contractItemValue.contract;
                            return {
                                ...contractItemValue,
                                id: contractItemValue.cycleId
                                    ? contractItemValue.id +
                                      contractItemValue.cycleId
                                    : contractItemValue.id,
                                contractItemId: contractItemValue.id,
                                activityCategoryId:
                                    dictionaries['activity-category']?.[
                                        contractItemValue.activityCategoryId
                                    ]?.name,
                                serviceTypeId:
                                    dictionaries['service-type']?.[
                                        contractItemValue.serviceTypeId
                                    ]?.name,
                                objectCategoryId:
                                    dictionaries['object-category']?.[
                                        contractItemValue.objectCategoryId
                                    ]?.name,
                                serviceClassId:
                                    dictionaries['service-class']?.[
                                        contractItemValue?.serviceClassId || ''
                                    ]?.name,
                                locationId: contractItem.locationId,
                                clientId: contractItem.clientId,
                                clientName: contractItem.clientName,
                                locationName: contractItem.locationName,
                                coordinate: contractItem.coordinate,
                                name,
                                contractStartDate,
                                contractStopDate,
                                isActive,

                                _meta: {},
                            };
                        }
                    );
                })
                .flat(),
        }
);
export const getContractItemsSourceSetWithFilter = getSourceSetWithFilter(
    getContractItemssSourceSetModel,
    getContractItemsAsSourceSet
);

export const {
    selectContractItem,
    toggleCollapseContract,
    toggleCollapseContractAttachments,
    toggleCollapseContracts,
} = contractItems.actions;

export default contractItems.reducer;
