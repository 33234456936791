import {
    makeCredentialsOnlyDeleteRequestSettings,
    makeEndpoint,
    makeGetRequestSettings,
    makePostRequestSettings,
    makePutRequestSettings,
} from '../common/utils';
import UrlConst from '../../constants/UrlConst';
import apiClient from '../common/apiClient';
import {
    IAttribute,
    TPostServiceAttribute,
    TPutServiceAttribute,
} from '../../state/ui/attributes';

export const makeAttributesUrl = (suffix: string = '', params = {}) => {
    return makeEndpoint(
        UrlConst.REGISTERS_URL,
        '/attributes/settings' + suffix,
        {
            ...params,
        }
    );
};

export const restGetAttributes = (): Promise<IAttribute[]> =>
    apiClient.send(makeGetRequestSettings(makeAttributesUrl('')));

export const restGetAttribute = (id: string): Promise<IAttribute> =>
    apiClient.send(makeGetRequestSettings(makeAttributesUrl(`/${id}`)));

export const restPostAttribute = (
    attribute: TPostServiceAttribute
): Promise<IAttribute> =>
    apiClient.send(makePostRequestSettings(makeAttributesUrl(''), attribute));

export const restPutAttribute = (
    attribute: TPutServiceAttribute,
    id: string
): Promise<IAttribute> =>
    apiClient.send(
        makePutRequestSettings(makeAttributesUrl(`/${id}`), attribute)
    );

export const restDeleteAttribute = (id: string): Promise<null> =>
    apiClient.send(
        makeCredentialsOnlyDeleteRequestSettings(makeAttributesUrl(`/${id}`))
    );
