import { useSelector } from 'react-redux';
import { TRootState } from '../../../../store';

import {
    areSearcherResultsBeingFetched,
    getTaskFetching,
    isLastStateBeingFetched,
    isLocationsRegisterBeingFetched,
    isReportSetBeingFetched,
    isSourceSetBeingFetched,
    isSourceSetElementFetching,
    isTrailBeingFetched,
} from '.';

export const useTaskFetching = () => useSelector(getTaskFetching);
export const useIsSourceSetBeingFetched = () =>
    useSelector(isSourceSetBeingFetched);
export const useIsTrailBeingFetched = () => useSelector(isTrailBeingFetched);
export const useIsReportSetBeingFetched = () =>
    useSelector(isReportSetBeingFetched);

export const useIsLocationsFetching = () =>
    useSelector(isLocationsRegisterBeingFetched);
export const useIsSourceSetElementFetching = () =>
    useSelector(isSourceSetElementFetching);
export const useIsLastStateBeingFetched = () =>
    useSelector(isLastStateBeingFetched);
export const useIsVehiclesFetching = () =>
    useSelector((state: TRootState) =>
        areSearcherResultsBeingFetched(state, 'vehicles')
    );
export const useIsEmployeesFetching = () =>
    useSelector((state: TRootState) =>
        areSearcherResultsBeingFetched(state, 'employees')
    );
