export const styles = () => {
    return {
        wrapper: {
            px: 2,
            backgroundColor: 'primary.main',
            position: 'sticky',
            top: 0,
            zIndex: 11,
        },
        contrastText: {
            color: 'primary.contrastText',
            '&:disabled': {
                color: 'primary.contrastText',
                opacity: 0.5,
            },
        },
        leftCustomControls: {
            color: 'primary.contrastText',
            height: 35,
            pl: 3,
            display: 'flex',
            '@media print': {
                display: 'none',
            },
        },
        rightCustomControls: {
            display: 'inline-flex',
            alignItems: 'center',
            color: 'primary.contrastText',
        },
    };
};
