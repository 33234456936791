import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        importResponseWrapper: {
            width: 396,
        },
        templateLink: {
            fontWeight: 'bold',
        },
        noTopMargin: {
            marginTop: 0,
        },
    })
);
