import { createSelector } from 'reselect';

import { TSearchType } from '../../../state/app/searchers';

import {
    ICalibrationPane,
    IDetectionPane,
    ISnapshot,
    TChartPane,
    TDataGridPane,
    TDialog,
    TPreviewPane,
} from '../../../state/ui/discovery/snapshotting';
import { ISourceSetAction } from '../../../state/ui/discovery/types';
import { TRootState } from '../../../store';
import { getOrCreatePool } from '../../../helpers/createPool';
import { IColor } from '../../../helpers/ColorPool';

export const getParamHelper = (state: TRootState, param: string) => param;

//ui/general

export const getLastMapClickPosition = (state: TRootState) =>
    state.ui.discovery.general.lastMapClickPosition;

export const getNearEventsRadius = (state: TRootState) =>
    state.ui.discovery.general.nearEventsRadius;

export const getGeneralClustering = (state: TRootState) =>
    state.ui.discovery.general.mapClustering;

export const getSourceSetModels = (state: TRootState) =>
    getDiscoveryUI(state).general.sourceSetModels;

const getDiscoveryUI = (state: TRootState) => state.ui.discovery;

//ui/visibility

export const getVisibility = (state: TRootState) =>
    getDiscoveryUI(state).visibility;
export const areSearchResultsVisible = (state: TRootState) =>
    getVisibility(state).searchResults;

export const isMobileSearchOpen = (state: TRootState) =>
    getVisibility(state).mobileSearch;

export const areSearcherResultsVisible = (
    state: TRootState,
    type: TSearchType
) => {
    switch (type) {
        case 'employees':
            return getVisibility(state).employeesSearchResults;
        case 'vehicles':
            return getVisibility(state).vehiclesSearchResults;
    }
};

export const isPreviewPaneVisible = (state: TRootState) =>
    getVisibility(state).previewPane;
export const isGridPaneVisible = (state: TRootState) =>
    getVisibility(state).dataGridPane;
export const isReportSetPaneVisible = (state: TRootState) =>
    getVisibility(state).reportSetPane;
export const isSourceSetElementPaneVisible = (state: TRootState) =>
    getVisibility(state).sourceSetElementPane;

//ui/snapshotting
export const getCurrentSnapshot = (state: TRootState) =>
    getDiscoveryUI(state).snapshotting.currentSnapshot;
export const getCurrentSnapshotLevel = (state: TRootState) =>
    getDiscoveryUI(state).snapshotting.currentSnapshot.level;
export const getSnapshotPaths = (state: TRootState) =>
    getDiscoveryUI(state).snapshotting.snapshotPaths;
export const getTimeLock = (state: TRootState) =>
    getDiscoveryUI(state).snapshotting.timeLocked;
export const getIsStateRestoring = (state: TRootState) =>
    getDiscoveryUI(state).snapshotting.isStateRestoring;
export const getShouldRestorerWait = (state: TRootState) =>
    getDiscoveryUI(state).snapshotting.shouldRestorerWait;

export const getExtendedReports = (state: TRootState) =>
    state.ui.discovery.snapshotting.expandedReports;

const getInitialSnapshot = (state: TRootState) =>
    getDiscoveryUI(state).snapshotting.initialSnapshot;

export const getActiveChartAttributes = (state: TRootState) =>
    getDiscoveryUI(state).snapshotting.activeChartAttributes;

export const getMapFitToExtentIsPending = (state: TRootState) =>
    getDiscoveryUI(state).snapshotting.mapFitToExtentIsPending;

export const getCurrentSnapshotPath = createSelector(
    [getCurrentSnapshot, getSnapshotPaths],
    ({ pathIndex, level }, paths) =>
        (paths[pathIndex] && paths[pathIndex].slice(0, level)) || null
);

export const getCalculatedMobileUIState = createSelector(
    [getCurrentSnapshotPath, getInitialSnapshot],
    (path) => (path ? path[path.length - 1] : []) as ISnapshot
);
export const getClustering = createSelector(
    [getCurrentSnapshotPath],
    (path) => {
        return path?.length ? path[path.length - 1].clustering : undefined;
    }
);

export const getCurrentSnapshotPathId = createSelector(
    [getCurrentSnapshotPath],
    (snapshotPath) =>
        snapshotPath?.filter((snapshot) => snapshot.type === 'preview')[0]
            ?.selectedId
);

export const getActionsToTrigger = createSelector(
    [getCurrentSnapshotPath],
    (path) => {
        return path?.length
            ? path[path.length - 1].actionsToTrigger
            : undefined;
    }
);

export const getActiveChartAttributesColors = createSelector(
    [getActiveChartAttributes],
    (attributes) => {
        const pool = getOrCreatePool('grid_chart');
        const selectedAttributes = Object.keys(attributes).filter(
            (o) => attributes[o]
        );
        pool.releaseColors(selectedAttributes);
        return selectedAttributes.reduce(
            (attributes: { [key: string]: IColor }, id: string) => {
                attributes[id] = pool.assignColor(id);
                return attributes;
            },
            {}
        );
    }
);

export const getDesktopSnapshotPath = createSelector(
    [getSnapshotPaths],
    (paths) =>
        paths.map((path) =>
            path.map((el) => {
                if (el.dataGridPane) {
                    const { mobileScrollOffset, ...rest } = el.dataGridPane;
                    return { ...el, dataGridPane: rest };
                }
                return el;
            })
        )
);

//ui/snapshotinng/calculatedUIState
export const getCalculatedUIState = createSelector(
    [getCurrentSnapshotPath, getInitialSnapshot],
    (path, initialSnapshot) =>
        (path || []).reduce(
            (
                result,
                {
                    previewPane,
                    dataGridPane,
                    chartPane,
                    selectedId,
                    calibrationPane,
                    dialog,
                    detectionPane,
                }
            ) => {
                result = {
                    ...result,
                    previewPane:
                        previewPane !== undefined
                            ? previewPane
                            : result.previewPane,
                    dialog: dialog !== undefined ? dialog : result.dialog,
                    dataGridPane:
                        dataGridPane !== undefined
                            ? dataGridPane
                            : result.dataGridPane,
                    chartPane:
                        chartPane !== undefined ? chartPane : result.chartPane,
                    selectedId:
                        selectedId !== undefined
                            ? selectedId
                            : result.selectedId,
                    calibrationPane:
                        calibrationPane !== undefined
                            ? calibrationPane
                            : result.calibrationPane,
                    detectionPane:
                        detectionPane !== undefined
                            ? detectionPane
                            : result.detectionPane,
                };

                return result;
            },
            initialSnapshot
        )
);
export const getDiscoveryUICalibrationPane = createSelector(
    [getCalculatedUIState],
    ({ calibrationPane }) => calibrationPane as ICalibrationPane
);
export const getDiscoveryUIDialog = createSelector(
    [getCalculatedUIState],
    ({ dialog }) => dialog as TDialog
);
export const getDiscoveryUIPreviewPane = createSelector(
    [getCalculatedUIState],
    ({ previewPane }) => previewPane as TPreviewPane
);

export const getPreviewCreatorLevel = createSelector(
    [getDiscoveryUIPreviewPane],
    (pane) => (pane && pane.creatorLevel) || 0
);
export const getPreviewAction = createSelector(
    [getDiscoveryUIPreviewPane],
    (pane) => (pane && pane.previewAction) || null
);
export const getPreviewDataSegmentsReports = createSelector(
    [getDiscoveryUIPreviewPane],
    (pane) => (pane && pane.dataSegmentsReports) || null
);

export const getPreviewElementId = createSelector(
    [getDiscoveryUIPreviewPane],
    (pane) => pane && pane.elementId
);
export const getPreviewLocationId = createSelector(
    [getDiscoveryUIPreviewPane],
    (pane) => {
        return pane && pane.elementCollectionName === 'locations'
            ? pane.elementId
            : pane?.elementCollectionName;
    }
);

export const shouldMapBeInEditMode = createSelector(
    [getDiscoveryUIPreviewPane],
    (pane) =>
        pane !== null &&
        (pane.elementType === 'location' ||
            pane.elementType === 'monitoredObject' ||
            pane.elementType === 'searchEvents') &&
        (pane.mode === 'edit' || pane.mode === 'add')
);

export const mapInDrawMode = createSelector(
    [getDiscoveryUIPreviewPane],
    (pane) =>
        pane !== null &&
        pane.elementType === 'searchEvents' &&
        (pane.mode === 'edit' || pane.mode === 'add')
);

export const shouldDiscoveryBeInEditMode = createSelector(
    [getDiscoveryUIPreviewPane],
    (pane) => pane?.mode === 'edit' || pane?.mode === 'add'
);

export const getPreviewPaneMonitoredId = createSelector(
    [getDiscoveryUIPreviewPane],
    (previewPane) => previewPane && previewPane.elementId
);

export const getDiscoveryUIDataGridPane = createSelector(
    [getCalculatedUIState],
    ({ dataGridPane }) => dataGridPane as TDataGridPane
);
export const getDiscoveryUIChartPane = createSelector(
    [getCalculatedUIState],
    ({ chartPane }) => chartPane as TChartPane
);
export const getDiscoveryBottomGridPane = createSelector(
    [getDiscoveryUIDataGridPane, getDiscoveryUIChartPane],
    (dataGridPane, chartPane) => dataGridPane || chartPane
);

export const getDiscoveryUIDetectionPane = createSelector(
    [getCalculatedUIState],
    ({ detectionPane }) => detectionPane as IDetectionPane
);

export const getPreviewPaneObjectId = createSelector(
    [getCalculatedUIState],
    ({ selectedId }) => selectedId
);

//ui/snapshotting/calculated/grid

export const getSourceSetDates = createSelector(
    [getDiscoveryUIDataGridPane],
    (grid) => grid?.sourceSetDates || undefined
);

export const getSourceSetTime = createSelector(
    [getDiscoveryUIDataGridPane],
    (grid) => grid?.time || undefined
);

export const getNotAssignedFilter = createSelector(
    [getDiscoveryUIDataGridPane],
    (grid) => !!grid?.onlyNotAssigned
);

export const getGridFilters = createSelector(
    [getDiscoveryUIDataGridPane],
    (grid) => grid?.filters
);

export const getIncludeNotPlanned = createSelector(
    [getDiscoveryUIDataGridPane],
    (grid) => !!grid?.includeNotPlanned
);

export const getOnlyNotPlannedFilter = createSelector(
    [getDiscoveryUIDataGridPane],
    (grid) => !!grid?.onlyNotPlanned
);

export const isLastStateVisible = createSelector(
    [getDiscoveryUIDataGridPane],
    (pane) => pane?.sourceSetId === 'lastStates' && pane?.creatorLevel >= 0
);
export const isMobileGridVisible = createSelector(
    [getDiscoveryUIDataGridPane],
    (pane) => pane?.visibleOnMobile
);
export const getMobileGridScrollOffset = createSelector(
    [getDiscoveryUIDataGridPane],
    (pane) => pane?.mobileScrollOffset
);

export const isDiscoverUIGridFullscreen = createSelector(
    [getDiscoveryUIDataGridPane],
    (pane) => (pane && pane.isFullscreen) || false
);
export const isAnalyticsModeEnabled = createSelector(
    [getDiscoveryUIDataGridPane],
    (pane) => (pane && pane.isAnalyticsEnabled) || false
);

export const getGridSourceSetId = createSelector(
    [getDiscoveryUIDataGridPane],
    (pane) => pane && pane.sourceSetId
);

export const getGridChartFlag = createSelector(
    [getDiscoveryUIDataGridPane],
    (pane) => (pane && pane.isChart) || false
);

export const getGridSourceSetAction = createSelector(
    [getDiscoveryUIDataGridPane],
    (pane): null | ISourceSetAction => (pane && pane.sourceSetAction) || null
);

export const isDiscoverUIDataGridPaneVisible = createSelector(
    [getDiscoveryUIDataGridPane, getDiscoveryUICalibrationPane],
    (pane, calibration) => !!calibration || (pane && pane.isHidden === false)
);
export const getCalibrationChartAttributes = createSelector(
    [getDiscoveryUICalibrationPane],
    (pane) => pane?.activeAttributes || {}
);
export const isDiscoveryUICalibrationPaneVisible = createSelector(
    [getDiscoveryUICalibrationPane],
    (pane) => !!pane
);

export const getCalibrationChartCreatorLevel = createSelector(
    [getDiscoveryUICalibrationPane],
    (pane) => (pane && pane.creatorLevel) || 0
);
export const getDetectionChartAttributes = createSelector(
    [getDiscoveryUIDetectionPane],
    (pane) => pane?.activeAttributes || {}
);
export const isDiscoveryUIDetectionPaneVisible = createSelector(
    [getDiscoveryUIDetectionPane],
    (pane) => !!pane
);

export const isDiscoveryUIChartPaneVisible = createSelector(
    [getDiscoveryUIChartPane],
    (pane) => !!pane
);

export const getGridCreatorLevel = createSelector(
    [
        getDiscoveryUIDataGridPane,
        getDiscoveryUICalibrationPane,
        getDiscoveryUIChartPane,
    ],
    (pane, calibration, chartPane) =>
        pane?.creatorLevel ||
        calibration?.creatorLevel ||
        chartPane?.creatorLevel ||
        0
);

export const getChartCreatorLevel = createSelector(
    [getDiscoveryUIChartPane],
    (pane) => (pane && pane.creatorLevel) || 0
);
