import { IEventItem } from '../../../../state/app/calibrations';
import { ICalibrationFormReferentialValue } from '../../../../state/ui/forms';

import { IDataEntry } from './averageGroup';
import Simplify from '../../../../algorithms/simplify';
import { AverageAlgorithm } from './charts';
import { CalibrationAlgorithm } from './calibrationAlgorithm';
import { calculateRefueling } from './calculateRefueling';

export const processCalibrationChartData = (
    allEvents: IEventItem[] | null,
    paramId: string | undefined,
    type: 'time' | 'distance',
    settings: ICalibrationFormReferentialValue[],
    capacity: number
): IDataEntry[] | undefined => {
    if (!paramId || !allEvents) {
        return;
    }

    let growing = 0;
    const limit = Math.min(allEvents.length, 100);
    for (let i = 1; i < limit; i++) {
        growing +=
            allEvents[i].params[paramId].value >
            allEvents[i - 1].params[paramId].value
                ? 1
                : -1;
    }

    const simplifiedEvents = Simplify.simplifyN(
        allEvents,
        1000,
        'processedParams.distance',
        `params.${paramId}.value`
    ) as IEventItem[];

    const data: IDataEntry[] = new Array(simplifiedEvents.length);
    const avgAlgorithm = new AverageAlgorithm(type, growing > 0);
    const calibrationAlgorithm = new CalibrationAlgorithm(settings);

    for (let i = 0; i < simplifiedEvents.length; i++) {
        const e = simplifiedEvents[i];
        const value = e.params[paramId].value;
        const calibrationValue =
            calibrationAlgorithm.calculateCalibratedFuelLevel(value);
        const entry = {
            y: value,
            calibratedY: calibrationValue,
            time: e.date,
            distance: e.processedParams.distance,
            ...calculateRefueling(
                capacity,
                calibrationValue,
                data[i - 1]?.calibratedY
            ),
        };
        const result = avgAlgorithm.apply(entry, i);
        if (result) {
            result.forEach((r) => (data[r.entryIndex].averageY = r.average));
        }
        data[i] = entry;
    }

    return data;
};
