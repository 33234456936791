import '../../../../../../assets/images/location-marker.svg';
import { FEATURE_SELECTED } from './utils';

const ANGLE = Math.PI / 4;

export const STYLE = {
    'icon-src': '/images/location-marker.svg',
    'icon-color': [
        'match',
        ['get', 'selected'],
        FEATURE_SELECTED,
        '#ed8311',
        'white',
    ],
};

export const STYLE_TASKS = {
    'shape-points': 4,
    'shape-radius': 12,
    'shape-stroke-color': [
        'match',
        ['get', 'selected'],
        FEATURE_SELECTED,
        '#ed8311', // Border color when selected
        'white', // Border color when not selected
    ],
    'shape-stroke-width': [
        'match',
        ['get', 'selected'],
        FEATURE_SELECTED,
        4,
        0.2,
    ],

    'shape-fill-color': [
        'case',
        ['==', ['get', 'statusId'], 1],
        '#03a9f4',
        ['==', ['get', 'statusId'], 2],
        '#f44336',
        ['==', ['get', 'statusId'], 3],
        '#4caf50',
        ['==', ['get', 'statusId'], 4],
        '#ff9800',
        'gray', // default color if status doesn't match
    ],
    'shape-angle': ANGLE,
};

export const STYLE_CONTRACTS = {
    'shape-points': 4,
    'shape-radius': 12,
    'shape-stroke-color': [
        'match',
        ['get', 'selected'],
        FEATURE_SELECTED,
        '#ed8311', // Border color when selected
        'black', // Border color when not selected
    ],
    'shape-stroke-width': [
        'match',
        ['get', 'selected'],
        FEATURE_SELECTED,
        4,
        0.2,
    ],
    'shape-angle': ANGLE,

    'shape-fill-color': 'purple',
};

export const STYLE_EVENTS = {
    'circle-stroke-width': [
        'match',
        ['get', 'selected'],
        FEATURE_SELECTED,
        4,
        0.2,
    ],
    'circle-stroke-color': [
        'match',
        ['get', 'selected'],
        FEATURE_SELECTED,
        '#ed8311', // Border color when selected
        'black', // Border color when not selected
    ],
    'circle-radius': 8,
    'circle-fill-color': ['color', ['get', 'r'], ['get', 'g'], ['get', 'b']],
};
