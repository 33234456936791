import React from 'react';

import { Paper } from '@material-ui/core';
import SaveIcon from '@mui/icons-material/Save';

import { useStyles } from '../../../Themable.hooks';

import { useRoutesMode } from '../../../../../state/ui/planning/routes/index.hooks';

import TranslationHelper from '../../../../../helpers/TranslationHelper';

import IconButtonWithTooltip from '../../../../../components/IconButtonWithTooltip';

import RoutesPreviewPane from './RoutesPreviewPane';
import RoutesCreatePane from './RoutesCreatePane';
import RoutesEditPane from './RoutesEditPane';

const RoutesPane = () => {
    const classes = useStyles();

    const mode = useRoutesMode();

    const customControlsSubmit = (
        submitForm: () => void,
        submitting: boolean
    ) => (
        <IconButtonWithTooltip
            title={TranslationHelper.translate('Save')}
            onClick={submitForm}
            disabled={submitting}
        >
            <SaveIcon
                sx={{
                    color: 'primary.contrastText',
                }}
            />
        </IconButtonWithTooltip>
    );
    const renderPane = () => {
        switch (mode) {
            case 'create':
                return (
                    <RoutesCreatePane rightControls={customControlsSubmit} />
                );

            case 'preview':
                return <RoutesPreviewPane />;
            case 'edit':
                return <RoutesEditPane rightControls={customControlsSubmit} />;

            default:
                return <></>;
        }
    };
    return (
        <Paper className={classes.formPane}>
            <div className={classes.wrapper}>{renderPane()}</div>
        </Paper>
    );
};

export default RoutesPane;
