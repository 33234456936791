import { GridApi } from 'ag-grid-community';

export const handleGridNodeSelection = (
    api: GridApi,
    id: any,
    idName: string,
    errorCallback: () => void
) => {
    let nodeExists = false;
    api.forEachNode((node) => {
        if (id === node.data[idName]) {
            nodeExists = true;
            node.setSelected(true, false);
            api.ensureNodeVisible(node);
        }
    });
    if (!nodeExists) {
        errorCallback();
    }
};
