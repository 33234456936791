import UrlConst from '../../constants/UrlConst';
import { IGetFuelingOnDemandParams } from '../../state/app/refuellAndLossConfiguration';
import { IRefuelAndLossConfiguration } from '../../state/ui/forms';
import apiClient from '../common/apiClient';
import { makeEndpoint, makePostRequestSettings } from '../common/utils';

export interface IOnDemandData {
    d: string;
    v: number;
}

export const restGetOnDemandData = (
    params: IGetFuelingOnDemandParams,
    body: IRefuelAndLossConfiguration
): Promise<IOnDemandData[]> =>
    apiClient.sendWithoutErrorsHandling(
        makePostRequestSettings(
            makeEndpoint(UrlConst.FUELING_ON_DEMAND_DATA_URL, '', params),
            body
        )
    );
