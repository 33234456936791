import React from 'react';

import { Paper } from '@material-ui/core';

import { useStyles } from '../../../../Themable.hooks';

import TranslationHelper from '../../../../../../helpers/TranslationHelper';

import { useMode } from '../../../../../../state/ui/reportsSlice/index.hooks';

import ReportsForm from '../ReportsForm';

interface IOwnProps {
    handleClose: () => void;
}
const ReportsPane = ({ handleClose }: IOwnProps) => {
    const classes = useStyles();

    const mode = useMode();
    const renderReportsPane = () => {
        switch (mode) {
            case 'create':
                return (
                    <ReportsForm
                        title={TranslationHelper.translate('New report')}
                        tooltip={TranslationHelper.translate('Save')}
                        handleClose={handleClose}
                    />
                );
            default:
                return;
        }
    };
    return (
        <Paper className={classes.formPane}>
            <div className={classes.wrapper}>{renderReportsPane()}</div>
        </Paper>
    );
};

export default ReportsPane;
