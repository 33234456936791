import {
    createSlice,
    createSelector,
    createAsyncThunk,
} from '@reduxjs/toolkit';

import {
    restDeleteReport,
    restDeleteSchedule,
    restGetReports,
    restGetReportSchedules,
    restGetReportsDefinitions,
    restPostCreateSchedule,
    restPostGenerateReport,
    restPutUpdateSchedule,
} from '../../../services/reports';
import { TRootState } from '../../../store';
import { ILocation, ISourceSet, TStatus } from '../../types';
import { TOptionalNormalizedData } from '../../app/collections/dataTypes';
import { restReadLocationsCollection } from '../../../services/locations';
import { restGetLocationIds } from '../../../services/customerService/contracts';

export interface IReport {
    id: string;
    status: number;
    type: {
        name: string;
    };
    subjects: { id: string; name: string }[];
    from: string;
    to: string;
    generatedAt: string;
    fileSize: number;
}

export interface IReports {
    total: number;
    start: number;
    values: IReport[];
}

export interface IFilter {
    start: number;
    limit: number;
}

export interface IReportsDefinition {
    typeId: number;
    name: string;
    templateId: number;
    isLocationReport: boolean;
    isCustomerServiceReport: boolean;
}

export interface INewReport {
    type: number;
    template: string;
    from: string;
    to: string;
    subjects: string;
    language: string;
}

export interface IReportSchedule {
    id: string;
    type: {
        id: number;
        name: string;
    };
    repetition: {
        interval: string;
        startBoundaryDate: string;
        endBoundaryDate: string;
    };
    timeZone: {
        id: number;
        name: string;
    };
    subjects: { id: number; name: string }[];
    template: { id: number; name: string };
    language: string;
    emails?: string[];
}

export interface INewSchedule {
    type: number;
    repetition: {
        interval: string;
        startBoundaryDate: string;
        endBoundaryDate: string;
    };
    timeZone: number;
    subjects: number[];
    template: number;
    language: string;
    emails?: string[];
}

export interface IUpdateSchedule extends INewSchedule {
    id: string;
}

interface IState {
    tab: null | string;
    status: TStatus;
    mode: null | 'create' | 'edit';
    reports: IReports | null;
    reportsDefinitions: IReportsDefinition[] | null;
    reportsFilter: IFilter;
    schedules: IReportSchedule[] | null;
    selectedScheduleId: string | null;
    currentPage: number;
    locations: TOptionalNormalizedData<ILocation>;
    dialogRfidFilter: string | null;
    locationsIdsByRfid: number[] | null;
    locationsStatus: TStatus;
}

export const initialState: IState = {
    tab: 'reports',
    mode: null,
    reports: null,
    status: 'idle',
    reportsDefinitions: null,
    reportsFilter: {
        start: 0,
        limit: 25,
    },
    schedules: null,
    selectedScheduleId: null,
    currentPage: 1,
    locations: null,
    dialogRfidFilter: null,
    locationsIdsByRfid: null,
    locationsStatus: 'idle',
};

export const fetchReports = createAsyncThunk(
    'get:ui/reports/',
    async (params: IFilter = initialState.reportsFilter) => {
        const response = await restGetReports(params);
        return response;
    }
);
export const fetchReportsDefinitions = createAsyncThunk(
    'get:ui/reports/reportsDefinitions',
    async () => {
        const response = await restGetReportsDefinitions();
        return response;
    }
);

export const generateReport = createAsyncThunk(
    'post:ui/reports/',
    async (report: INewReport) => {
        const response = await restPostGenerateReport(report);
        return response;
    }
);

export const deleteReport = createAsyncThunk(
    'delete:ui/reports/',
    async (id: string) => {
        const response = await restDeleteReport(id);
        return response;
    }
);

export const fetchReportSchedules = createAsyncThunk(
    'get:ui/reports/schedules',
    async () => {
        const response = await restGetReportSchedules();
        return response;
    }
);

export const createSchedule = createAsyncThunk(
    'post:ui/reports/schedules',
    async (schedule: INewSchedule) => {
        const response = await restPostCreateSchedule(schedule);
        return response;
    }
);

export const updateSchedule = createAsyncThunk(
    'put:ui/reports/schedules',
    async ({ id, schedule }: { id: string; schedule: IUpdateSchedule }) => {
        const response = await restPutUpdateSchedule(id, schedule);
        return response;
    }
);

export const deleteSchedule = createAsyncThunk(
    'delete:ui/reports/schedules',
    async (id: string) => {
        const response = await restDeleteSchedule(id);
        return response;
    }
);

export const fetchLocations = createAsyncThunk(
    'get:ui/reports/locations',
    async () => {
        const response = await restReadLocationsCollection({
            limit: 1000000,
            context: 'CUSTOMER_SERVICE',
        });
        return response;
    }
);

export const fetchLocationIdsByRfid = createAsyncThunk(
    'get:ui/reports/locationIds',
    async (rfid: string) => {
        const response = await restGetLocationIds({ rfid });
        return response;
    }
);

const reportsSlice = createSlice({
    name: 'reports',
    initialState,
    reducers: {
        enterCreateMode(state) {
            state.mode = 'create';
        },
        enterEditMode(state, action) {
            state.mode = 'edit';
            state.selectedScheduleId = action.payload;
        },
        resetMode(state) {
            state.mode = null;
        },
        selectTab(state, action) {
            state.tab = action.payload;
        },
        setDialogRfidFilter(state, action) {
            state.dialogRfidFilter = action.payload;
        },
        resetDialogRfidFilter(state) {
            state.dialogRfidFilter = null;
            state.locationsIdsByRfid = null;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchReports.fulfilled, (state, action) => {
            state.reports = action.payload;
        });
        builder.addCase(fetchReportSchedules.fulfilled, (state, action) => {
            state.schedules = action.payload;
        });
        builder.addCase(fetchReportsDefinitions.pending, (state) => {
            state.status = 'loading';
        });
        builder.addCase(fetchReportsDefinitions.fulfilled, (state, action) => {
            state.reportsDefinitions = action.payload.map((definition) => ({
                ...definition,
                isLocationReport: checkIsLocationReport(definition.typeId),
                isCustomerServiceReport: checkIsCustomerServiceReport(
                    definition.typeId
                ),
            }));
            state.status = 'done';
        });
        builder.addCase(fetchLocations.pending, (state) => {
            state.locationsStatus = 'loading';
        });
        builder.addCase(fetchLocations.fulfilled, (state, action) => {
            state.locations = action.payload;
            state.locationsStatus = 'done';
        });
        builder.addCase(fetchLocationIdsByRfid.pending, (state) => {
            state.locationsStatus = 'loading';
        });
        builder.addCase(fetchLocationIdsByRfid.fulfilled, (state, action) => {
            state.locationsIdsByRfid = action.payload;
            state.locationsStatus = 'done';
        });
        builder.addCase(fetchLocationIdsByRfid.rejected, (state, action) => {
            state.locationsStatus = 'done';
        });
    },
});
const LOCATION_REPORT_IDS = [252, 253];
export const checkIsLocationReport = (typeId: number) =>
    LOCATION_REPORT_IDS.includes(typeId);

export const checkIsCustomerServiceReport = (typeId: number) => typeId === 252;

const getCurrent = (state: TRootState) => state.ui.reports as IState;

export const getReportsMode = (state: TRootState) => getCurrent(state).mode;

export const reportsPaneVisible = createSelector(
    [getReportsMode],
    (mode) => !!mode
);

export const getCurrentPage = (state: TRootState) =>
    getCurrent(state).currentPage;

export const getTab = (state: TRootState) => getCurrent(state).tab;

export const getReports = (state: TRootState) => getCurrent(state).reports;

export const getReportsDefinitions = (state: TRootState) =>
    getCurrent(state).reportsDefinitions;

export const getSchedules = (state: TRootState) => getCurrent(state).schedules;

export const getReportDefinitionsStatus = (state: TRootState) =>
    getCurrent(state).status;

export const getSelectedScheduleId = (state: TRootState) =>
    getCurrent(state).selectedScheduleId;

export const getSelectedSchedule = createSelector(
    [getSelectedScheduleId, getSchedules],
    (id, schedules) =>
        schedules?.filter((schedule) => schedule.id === id)?.[0] || null
);

export const getLocations = (state: TRootState) => getCurrent(state).locations;

export const getLocationIdsByRfid = (state: TRootState) =>
    getCurrent(state).locationsIdsByRfid;

export const getLocationsStatus = (state: TRootState) =>
    getCurrent(state).locationsStatus;

export const getDialogRfidFilter = (state: TRootState) =>
    getCurrent(state).dialogRfidFilter;

const getLocationsAsArray = createSelector(
    [getLocations],
    (locations) =>
        locations && locations.result.map((id) => locations.entities[id])
);

const getFilteredLocations = createSelector(
    [getLocationsAsArray, getLocationIdsByRfid],
    (locations, ids) => {
        if (ids === null) {
            return locations;
        }
        if (ids.length === 0) {
            return [];
        }
        return (
            locations &&
            locations.filter((location) => ids.includes(+location.externalId))
        );
    }
);

export const getLocationsAsSourceSet = createSelector(
    [getFilteredLocations],
    (locations): ISourceSet | null =>
        locations && {
            id: 'reportsLocations',
            definitionId: 'reportsLocations',
            label: 'Locations',
            attributes: [
                {
                    id: 'location',
                    type: 'parent',
                    label: 'Location',
                    selectable: true,
                    children: [
                        { id: 'name', label: 'Name', type: 'text' },
                        {
                            id: 'postalCode',
                            label: 'Postal code',
                            type: 'text',
                        },
                        {
                            id: 'city',
                            label: 'City',
                            type: 'text',
                        },
                        { id: 'street', label: 'Street', type: 'text' },
                        {
                            id: 'apartmentNo',
                            label: 'Building and apartment number',
                            type: 'text',
                        },
                        { id: 'commune', label: 'Commune', type: 'text' },
                        { id: 'notes', label: 'Notice', type: 'text' },
                        {
                            id: 'externalNo',
                            label: 'External number',
                            type: 'text',
                        },
                    ],
                },
            ],
            layersAttributes: [],
            _meta: {},
            entities: locations.map((location: ILocation): any => {
                return {
                    ...location,
                    city: location.address.city,
                    street: location.address.street,
                    apartmentNo: location.address.apartmentNo
                        ? `${location.address.streetNumber} / ${
                              location.address.apartmentNo || ''
                          }`
                        : location.address.streetNumber,
                    postalCode: location.address.zip,
                    commune: location.address.areaName3,
                    address: '',
                    _meta: {},
                };
            }),
        }
);

export const {
    enterCreateMode,
    enterEditMode,
    resetMode,
    selectTab,
    resetDialogRfidFilter,
    setDialogRfidFilter,
} = reportsSlice.actions;

export default reportsSlice.reducer;
