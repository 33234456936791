import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        gridPane: {
            display: 'flex',
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
        },
        formPane: {
            display: 'flex',
            position: 'absolute',
            minHeight: '100%',
            top: 0,
            right: 0,
            left: 0,
        },
        pageWrapper: {
            position: 'absolute',
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
        },
        wrapper: {
            display: 'flex',
            position: 'relative',
            flexGrow: 1,
            flexDirection: 'column',
        },
        paneWrapper: {
            position: 'absolute',
            top: 0,
            left: 'calc(100% - 500px)',
            width: 500,
            maxHeight: '100%',
            right: 0,
            overflowY: 'auto',
            overflowX: 'hidden',
            display: 'flex',
            height: '100%',
            zIndex: 200,
        },
        formWrapper: {
            padding: theme.spacing(3),
            overflowX: 'hidden',
            overflowY: 'auto',
        },
        pageContent: {
            position: 'absolute',
            top: 10,
            left: 10,
            bottom: 10,
            right: 10,
            [theme.breakpoints.down('sm')]: {
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
            },
        },
        gridContainer: {
            flexGrow: 1,
            display: 'flex',
        },
        gridWrapper: {
            flexGrow: 1,
            width: '100%',
        },
        visibleGridPane: {
            position: 'absolute',
            top: 0,
            left: 0,
            bottom: 0,
            right: 500 + 10,
        },
        hiddenGridPane: {
            position: 'absolute',
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
        },
        field: {
            marginTop: theme.spacing(),

            paddingBottom: theme.spacing(),
        },
        fieldMulti: {
            marginTop: theme.spacing(),
            marginBottom: theme.spacing(2),
            paddingBottom: theme.spacing(),
            width: '440px',
        },
        typography: {
            margin: `${theme.spacing()}px 0px`,
        },
    })
);
