import React from 'react';

import { MenuItem } from '@material-ui/core';

import TranslationHelper from '../../../../../helpers/TranslationHelper';
import { IReportSetHeader } from '../../../../../state/types';

export const getHeaderName = ({ name, offset }: IReportSetHeader) => {
    const headerName = TranslationHelper.translate(name, 'et');
    const headerOffset = offset !== undefined ? `offset: ${offset}s` : '';
    return `${headerName} ${headerOffset}`;
};

interface IDictionaryItem {
    id: number | string;
    parentId?: number;
    name: string;
    shortName?: string;
}
export const mapDictionaryItems = (
    items: IDictionaryItem[],
    parentId?: number,
    hasEmpty?: boolean,
    translate?: boolean
) => {
    const initial = hasEmpty
        ? [
              <MenuItem key={'-1'} value={-1}>
                  {''}
              </MenuItem>,
          ]
        : [];
    return initial.concat(
        items
            .filter(
                (item: IDictionaryItem) =>
                    parentId === undefined || item.parentId === parentId
            )
            .map((item: IDictionaryItem) => (
                <MenuItem key={item.id} value={item.id}>
                    {translate
                        ? TranslationHelper.translate(item.name)
                        : item.name}
                </MenuItem>
            ))
    );
};
