import { makeReduxDuck } from 'teedux';

export interface IState {
    searchPane: boolean;
    searchResults: boolean;
    previewPane: boolean;
    reportSetPane: boolean;
    dataGridPane: boolean;
    sourceSetElementPane: boolean;
    employeesSearchResults: boolean;
    vehiclesSearchResults: boolean;
    mobileSearch: boolean;
}

const initialState: IState = {
    searchPane: true,
    searchResults: false,
    previewPane: false,
    reportSetPane: false,
    dataGridPane: false,
    sourceSetElementPane: false,
    employeesSearchResults: false,
    vehiclesSearchResults: false,
    mobileSearch: false,
};

const duck = makeReduxDuck('ui/discovery/visibility', initialState);

export const toggleAction = duck.defineAction<{
    visibilityName: string;
    shouldBeVisible: boolean | undefined;
}>('TOGGLE', (state, { visibilityName, shouldBeVisible }) => {
    const isVisible = state[visibilityName];
    return {
        ...state,
        [visibilityName]:
            shouldBeVisible === undefined ? !isVisible : shouldBeVisible,
    };
});

const makeToggle =
    (visibilityName: keyof IState) =>
    (shouldBeVisible: boolean | undefined = undefined) =>
        toggleAction({
            visibilityName,
            shouldBeVisible,
        });

export const toggleMobileSearch = makeToggle('mobileSearch');
export const toggleSearchPane = makeToggle('searchPane');
export const toggleSearchResults = makeToggle('searchResults');
export const togglePreviewPane = makeToggle('previewPane');
export const toggleReportSetPane = makeToggle('reportSetPane');
export const toggleDataGridPane = makeToggle('dataGridPane');
export const toggleSourceSetElementPane = makeToggle('sourceSetElementPane');
export const toggleEmployeesSearchResult = makeToggle('employeesSearchResults');
export const toggleVehiclesSearchResult = makeToggle('vehiclesSearchResults');

export default duck.getReducer();
