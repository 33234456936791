export default {
    preview: {
        _webx: {
            menu: [
                {
                    id: 'showEvent',
                    label: 'Show vehicle events',
                    value: '',
                    type: 'button',
                    translate: 'wx',
                    actions: {
                        onClick: {
                            params: {
                                reportId: 'archiveEvents',
                            },

                            label: 'Show vehicle events',
                        },
                    },
                },
            ],
            paramsStrategy: ['startAndEndOfDay', 'monitoredId'],
        },
    },
};
