import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => {
    return createStyles({
        wrapper: {
            minHeight: '500px',
            minWidth: '200px',
            display: 'flex',
            position: 'relative',
            flexGrow: 1,
            flexDirection: 'column',
        },
        paper: {
            width: '1200px',
            minHeight: '600px',
            margin: '0',
        },
    });
});
