import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { createSelector } from 'reselect';
import { ISourceSet, ISourceSetEntity } from 'src/app/state/types';
import { selectObject } from '../../../../state/_actions';
import { IOwnProps } from './types';

export const createCellInteractionHandler = createSelector(
    (dispatch: ThunkDispatch<null, null, Action>, ownProps: IOwnProps) =>
        dispatch,
    (dispatch: ThunkDispatch<null, null, Action>, ownProps: IOwnProps) =>
        ownProps.sourceSet,
    (dispatch: ThunkDispatch<null, null, Action>, ownProps: IOwnProps) =>
        ownProps.creatorLevel,
    (dispatch: ThunkDispatch<null, null, Action>, ownProps: IOwnProps) =>
        ownProps.timeLocked,
    (dispatch: ThunkDispatch<null, null, Action>, ownProps: IOwnProps) =>
        ownProps.selectedSourceSetElementId,
    (
            dispatch: ThunkDispatch<null, null, Action>,
            sourceSet: ISourceSet | null,
            creatorLevel: number,
            timeLocked: boolean,
            selectedSourceSetElementId: string | null | undefined
        ) =>
        (data: ISourceSetEntity) => {
            return dispatch(
                sourceSet
                    ? selectObject(
                          sourceSet,
                          data,
                          creatorLevel,
                          timeLocked,
                          selectedSourceSetElementId
                      )
                    : () => undefined
            );
        }
);
