import { connect } from 'react-redux';
import {
    fetchReportSet,
    fetchTrail,
    notifyRestorerToContinue,
    notifyRestorerToWait,
    resetLevel,
    resetTrail,
} from '../../../../../../state/_actions';
import {
    getDiscoveryUIPreviewPane,
    getIsStateRestoring,
    getPreviewCreatorLevel,
    getCurrentSnapshot,
} from '../../../../selectors';

import { TRootState } from '../../../../../../store';
import { getPreviewReportSet } from '../../../../selectors/composed';

const mapStateToProps = (state: TRootState) => ({
    preview: getDiscoveryUIPreviewPane(state),
    creatorLevel: getPreviewCreatorLevel(state),
    reportSet: getPreviewReportSet(state),
    isStateRestoring: getIsStateRestoring(state),
    currentSnapshot: getCurrentSnapshot(state),
});

const mapDispatchToProps = {
    resetLevel,
    fetchReportSet,
    fetchTrail,
    resetTrail,
    notifyRestorerToWait,
    notifyRestorerToContinue,
};

type TStateProps = ReturnType<typeof mapStateToProps>;
type TDispatchProps = typeof mapDispatchToProps;

export type TConnectableProps = TStateProps & TDispatchProps;

export const Connectable = connect(mapStateToProps, mapDispatchToProps);
