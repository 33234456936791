import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
    chipInput: {
        position: 'relative',
    },
    chip: {
        maxWidth: '85%',
    },
});
