import { connect } from 'react-redux';
import {
    resetSearcher,
    toggleSearchResults,
    updateSearchPhrase,
} from '../../../../state/_actions';
import { TRootState } from '../../../../store';
import { getSearchPhrase } from '../../selectors/searcher';

const mapStateToProps = (state: TRootState) => ({
    searchPhrase: getSearchPhrase(state),
});
const mapDispatchToProps = {
    toggleSearchResults,
    updateSearchPhrase,
    resetSearcher,
};

type TStateProps = ReturnType<typeof mapStateToProps>;
type TDispatchProps = typeof mapDispatchToProps;

export type TConnectableProps = TStateProps & TDispatchProps;

export const Connectable = connect(mapStateToProps, mapDispatchToProps);
