import React, { useCallback, useEffect, useState } from 'react';

import {
    ColDef,
    GridApi,
    GridReadyEvent,
    ICellRendererParams,
    IDatasource,
    IGetRowsParams,
    IServerSideDatasource,
    IServerSideGetRowsParams,
} from 'ag-grid-community';

import { LinearProgress, Paper } from '@material-ui/core';

import { AutocompleteInputChangeReason } from '@material-ui/lab';

import { useDebounceEffect } from '../../../../helpers/hooks';
import TranslationHelper from '../../../../helpers/TranslationHelper';

import { useStyles } from '../../Themable.hooks';

import AsyncSearch from '../../../../components/common/AsyncSearch';
import CustomCheckbox from '../../../../components/common/CustomCheckbox';
import InfiniteRowModelGrid from '../../../../components/InfiniteRowModelGrid';
import PaneHeader from '../../../../components/PaneHeader';
import ErrorMessage from '../../../discovery/components/DataGridPane/components/ErrorMessage';

import {
    cellRenderers,
    gridValueGetter,
} from '../../../../components/SourceSetGrid/_utils';
import AppConfig from '../../../../constants/AppConfig';

import { IAlert } from '../../../../services/alerts';
import { IMonitoredObject } from '../../../../state/types';
import AlertActions from '../cellRenderers/AlertActions';
import AlertDetails from '../AlertDetails';
import DetailsCell from '../../../../components/DetailsCell';
import ServerSideRowModelGrid from '../../../../components/ServerSideRowModelGrid';

import { useUserSettings } from '../../../../state/user/index.hooks';
import {
    useAlertsObjectId,
    useAlertStatuses,
    useAlertStatusesAsString,
    useFoundObjects,
    useNewAlertsMessage,
    useObjectFilter,
    useShouldRefreshAlertsData,
} from '../../../../state/app/alertsPage/index.hook';
import {
    clearFoundObjects,
    clearNewAlertsMessage,
    clearObjectFilter,
    fetchAlerts,
    searchObjectByName,
    setAlertsReadStatus,
    setAlertsUnreadStatus,
    setObjectFilter,
    setShouldRefreshData,
} from '../../../../state/app/alertsPage';

import { useAppDispatch } from '../../../../hooks';
import { AlertCellRenderer } from '../../../../components/SourceSetGrid/components/cellRenderers/IconCellRenderers';

const components = {
    alertActions: AlertActions,
    alertCellRenderer: AlertCellRenderer,
    communityDetailCellRenderer: ({ data }: ICellRendererParams) => {
        return (
            <>
                {data?.detailedContent && (
                    <DetailsCell summary={data?.name}>
                        <AlertDetails contentDetail={data.detailedContent} />
                    </DetailsCell>
                )}
                {data?.name}
            </>
        );
    },
    detailCellRenderer: ({ data }: ICellRendererParams) => {
        return <AlertDetails contentDetail={data.detailedContent} />;
    },
};

const ALERTS_LIMIT = 50;

const AlertsGrid = () => {
    const [gridApi, setGridApi] = useState<GridApi | null>(null);
    const [objectsLoading, setObjectsLoading] = useState<boolean>(false);
    const [dataSourceLoading, setDataSourceLoading] = useState<boolean>(false);
    const [objectFilterInput, setObjectFilterInput] = useState<string>('');

    const dispatch = useAppDispatch();

    const userSettings = useUserSettings();
    const statuses = useAlertStatuses();
    const statusesAsString = useAlertStatusesAsString();
    const objectId = useAlertsObjectId();
    const foundObjects = useFoundObjects();
    const objectFilter = useObjectFilter();
    const newAlertsMessage = useNewAlertsMessage();
    const shouldRefreshData = useShouldRefreshAlertsData();

    const classes = useStyles();

    const { read: readStatus, unread: unreadStatus } = statuses;

    const enterpriseKey = AppConfig?.instance.getConfigKey(
        AppConfig.PROPERTY_GRID
    )?.key;

    const colDefs: ColDef[] = [
        {
            field: 'status',
            headerName: TranslationHelper.translate('Status'),
            flex: 0.3,
            cellRenderer: cellRenderers.alertIcon,
        },
        {
            field: 'object.name',
            headerName: TranslationHelper.translate('Object'),
            flex: 0.5,
        },
        {
            field: 'occurAt',
            headerName: TranslationHelper.translate('Occurrence date'),
            flex: 0.5,
            valueGetter: (params) =>
                gridValueGetter({
                    value: params.data?.occurAt,
                    type: 'date',
                    userSettings: userSettings,
                }),
        },
        {
            field: 'name',
            headerName: TranslationHelper.translate('Name'),
        },
        {
            field: 'content',
            headerName: TranslationHelper.translate('Message'),
            flex: 2,
            cellRenderer: enterpriseKey
                ? 'agGroupCellRenderer'
                : 'communityDetailCellRenderer',
        },
        {
            field: 'id',
            headerName: TranslationHelper.translate('Actions'),
            cellRenderer: 'alertActions',
        },
    ];

    useEffect(() => {
        dispatch(clearNewAlertsMessage());
        setObjectFilterInput(objectFilter?.name || '');
    }, []);

    useDebounceEffect(
        () => {
            if (shouldRefreshData && gridApi) {
                dispatch(setShouldRefreshData(false));
                setDataSource(gridApi);
            }
        },
        [shouldRefreshData],
        1000
    );

    useDebounceEffect(
        () => {
            if (!shouldRefreshData && gridApi) {
                setDataSource(gridApi);
            }
        },
        [statusesAsString, objectId],
        1000
    );

    useDebounceEffect(
        () => {
            if (objectFilterInput === '') {
                return;
            }
            setObjectsLoading(true);
            dispatch(
                searchObjectByName(
                    objectFilterInput,
                    () => {
                        setObjectsLoading(false);
                    },
                    () => {
                        setObjectsLoading(false);
                    }
                )
            );
        },
        [objectFilterInput],
        500
    );

    const getRows = (params: IGetRowsParams | IServerSideGetRowsParams) => {
        setDataSourceLoading(true);
        gridApi?.hideOverlay();

        const { start, end } = enterpriseKey
            ? getRowsDataServerSide(params as IServerSideGetRowsParams)
            : getRowsData(params as IGetRowsParams);

        dispatch(
            fetchAlerts(
                {
                    statuses: statusesAsString,
                    limit: ALERTS_LIMIT,
                    start,
                    objectId: objectId,
                    orderBy: 'occurAt',
                    sortOrder: 'desc',
                },
                ({ values, total }) => {
                    setDataSourceLoading(false);
                    const lastRow = total <= end ? total : -1;
                    params.successCallback(values, lastRow);
                    if (total === 0) {
                        gridApi?.showNoRowsOverlay();
                    }
                }
            )
        );
    };

    const getRowsData = (params: IGetRowsParams) => ({
        start: params.startRow,
        end: params.endRow,
    });

    const getRowsDataServerSide = (params: IServerSideGetRowsParams) => ({
        start: params.request.startRow || 0,
        end:
            params.request.endRow ||
            (params.request.startRow || 0) + ALERTS_LIMIT,
    });

    const alertsDataSource: IDatasource = { getRows };

    const alertsEnterpriseDataSource: IServerSideDatasource = { getRows };

    const setDataSource = (api: GridApi) => {
        if (enterpriseKey) {
            api.setServerSideDatasource(alertsEnterpriseDataSource);
        } else {
            api.setDatasource(alertsDataSource);
        }
    };

    const onGridReady = ({ api }: GridReadyEvent) => {
        setGridApi(api);
        setDataSource(api);
    };

    const toggleRead = useCallback(() => {
        dispatch(setAlertsReadStatus(!statuses.read));
    }, [setAlertsReadStatus, statuses.read]);

    const toggleUnread = useCallback(() => {
        dispatch(setAlertsUnreadStatus(!statuses.unread));
    }, [setAlertsUnreadStatus, statuses.unread]);

    const onObjectFilterInputChange = (
        event: React.ChangeEvent<{}>,
        value: string,
        reason: AutocompleteInputChangeReason
    ) => {
        if (reason === 'clear') {
            dispatch(clearFoundObjects());
            dispatch(clearObjectFilter());
            setObjectFilterInput('');
            return;
        }
        if (reason === 'input') {
            setObjectFilterInput(value);
        }
    };

    const onObjectFilterOptionChange = (
        event: React.ChangeEvent<{}>,
        value: IMonitoredObject | null
    ) => {
        if (!value) {
            return;
        }
        dispatch(setObjectFilter(value));
        setObjectFilterInput(value.name);
    };

    const newAlertsHandler = useCallback(() => {
        setDataSourceLoading(true);
        dispatch(clearNewAlertsMessage());
        dispatch(setShouldRefreshData(true));
    }, [clearNewAlertsMessage, setShouldRefreshData]);

    const statusControls = (
        <>
            <CustomCheckbox
                checked={readStatus}
                onChange={toggleRead}
                label="read"
                disabled={readStatus && !unreadStatus}
            />
            <CustomCheckbox
                checked={unreadStatus}
                onChange={toggleUnread}
                label="unread"
                disabled={unreadStatus && !readStatus}
            />
            <AsyncSearch
                inputValue={objectFilterInput}
                onInputChange={onObjectFilterInputChange}
                value={objectFilter}
                onChange={onObjectFilterOptionChange}
                options={foundObjects}
                loading={objectsLoading}
                placeholder="Find by object"
            />
        </>
    );

    const newAlertsNotification = (
        <ErrorMessage messages={newAlertsMessage} onClick={newAlertsHandler} />
    );

    const isRowMaster = (data: IAlert) => !!data.detailedContent;

    const gridProps = {
        components,
    };

    const serverSideGridProps = {
        ...gridProps,
        masterDetail: true,
        detailCellRenderer: 'detailCellRenderer',
        detailRowAutoHeight: true,
        isRowMaster,
    };

    return (
        <Paper className={classes.pane}>
            <div className={classes.pageWrapper}>
                <PaneHeader
                    title={TranslationHelper.translate('Alerts')}
                    renderLeftCustomControls={
                        gridApi ? () => statusControls : undefined
                    }
                    renderRightCustomControls={
                        gridApi ? () => newAlertsNotification : undefined
                    }
                />
                {dataSourceLoading && <LinearProgress />}
                {enterpriseKey ? (
                    <ServerSideRowModelGrid
                        columnDefs={colDefs}
                        onGridReady={onGridReady}
                        {...serverSideGridProps}
                    />
                ) : (
                    <InfiniteRowModelGrid
                        columnDefs={colDefs}
                        onGridReady={onGridReady}
                        {...gridProps}
                    />
                )}
            </div>
        </Paper>
    );
};

export default AlertsGrid;
