import React from 'react';
import { connect } from 'react-redux';

import { object } from 'prop-types';

import { Link } from 'react-router-dom';
import queryString from 'query-string';

import LoadingSpinner from '../components/loadingSpinner/LoadingSpinner';
import MenulessPageTemplate from './MenulessPageTemplate';
import Translator from '../helpers/TranslationHelper';

import confirmEmail from '../state/login';

class EmailVerification extends MenulessPageTemplate {
    constructor(props, context) {
        super(props, context);

        this.state = this._getInitialState();
    }

    _getInitialState() {
        return {
            status: 0,
        };
    }

    componentDidMount() {
        const query = queryString.parse(this.props.location.search, {
            ignoreQueryPrefix: true,
        });
        this.props.confirmEmail(
            query.token,
            query.email,
            () => {
                this.setState({ status: 200 });
            },
            () => {
                this.setState({ status: 404 });
            }
        );
    }

    componentWillUnmount() {
        if (this._request) {
            this._request = this._request.abort();
        }
    }

    render() {
        let message;
        switch (this.state.status) {
            case 200:
                message = (
                    <p>{Translator.translate('Email has been confirmed')}</p>
                );
                break;
            case 404:
                message = (
                    <p>
                        {Translator.translate('Verification token has expired')}
                    </p>
                );
                break;
            default:
                message = (
                    <div>
                        <div>
                            {Translator.translate('Waiting for verification')}
                        </div>
                        <div className="spinner">
                            <LoadingSpinner
                                size="50"
                                top="0"
                                left="0"
                                right="0"
                                bottom="0"
                            />
                        </div>
                    </div>
                );
                break;
        }

        return super.render(
            <div className="menuless-task-page">
                <div className="task">
                    <div className="error-page">
                        {message}
                        <Link to="/login">
                            {Translator.translate('Back to login page')}
                        </Link>
                    </div>
                </div>
            </div>
        );
    }
}

EmailVerification.propTypes = {
    location: object,
};

const mapDispatchToProps = {
    confirmEmail,
};

export default connect(null, mapDispatchToProps)(EmailVerification);
