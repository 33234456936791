import React, { Component, ComponentType } from 'react';
import { compose } from 'redux';

import { Connectable, TConnectableProps } from './Connectable.hoc';
import { Themable, TThemableProps } from './Themable.hoc';

import { Paper } from '@material-ui/core';

import PrivilegesList from './components/PrivilegesList';
import RolesList from './components/RolesList';

import hasAccess from '../../components/accessControl/hasAccess';

type TProps = TThemableProps & TConnectableProps;

class RolesPage extends Component<TProps> {
    public componentDidMount() {
        this.props.getRoles();
        this.props.getPrivileges();
    }

    public render() {
        const { roleEditIsVisible, classes } = this.props;

        return (
            <div className={classes.rolesPageWrapper}>
                <Paper className={classes.rolesWrapper} square={true}>
                    <RolesList />
                </Paper>

                {roleEditIsVisible && (
                    <Paper
                        className={classes.permissionsOuterWrapper}
                        square={true}
                    >
                        <PrivilegesList />
                    </Paper>
                )}
            </div>
        );
    }
}

export default compose(
    Themable,
    Connectable,
    hasAccess((privileges) => privileges.rolesPanel)
)(RolesPage) as ComponentType<{}>;
