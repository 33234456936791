import React, { useEffect } from 'react';

import AlertsIcon from '@material-ui/icons/Notifications';

import Badge from '@material-ui/core/Badge';

import { useSnackbar } from 'notistack';

import { useAlertCount, useFetchAlertsAfter } from './Connectable.hooks';
import { useStyles } from './Themable.hooks';

import {
    fetchAlertsCount,
    fetchAlertsForNotifications,
} from '../../../../../state/app/alertsPage';

import { TAlertResponse } from '../../../../../services/alerts';

import { useAppDispatch } from '../../../../../hooks';

import { useIntervalEffect } from '../../../../../helpers/hooks';

import AlertDetails from '../../../../../pages/alerts/components/AlertDetails';
import notification from '../../../../../../assets/notification.mp3';
import AlertNotification from '../../../../AlertNotification';

const sound = new Audio(notification);

const AlertWithBadge = () => {
    const classes = useStyles();
    const dispatch = useAppDispatch();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const alertCount = useAlertCount();
    const fetchAlertsAfter = useFetchAlertsAfter();

    useEffect(() => {
        dispatch(fetchAlertsCount());
    }, []);

    useIntervalEffect(
        () => {
            dispatch(fetchAlertsCount());
        },
        [alertCount],
        10000
    );

    useIntervalEffect(
        () => {
            dispatch(
                fetchAlertsForNotifications(
                    fetchAlertsAfter,
                    afterFetchAlertsSuccess
                )
            );
        },
        [fetchAlertsAfter],
        10000
    );

    const afterFetchAlertsSuccess = (data: TAlertResponse) => {
        showAlertNotification(data);
    };

    // ! TODO this never gets cleared, analize notistack for possible solutions (ideal - dont hold this const here)
    const snacks: Array<string | number | null | undefined> = [];

    const showAlertNotification = ({ values }: TAlertResponse) => {
        const allAlerts = values;
        let alertsToDisplay = [];

        if (allAlerts.length > 0) {
            sound.play();
            if (allAlerts.length > 3) {
                alertsToDisplay = allAlerts.slice(0, 3);
            } else {
                alertsToDisplay = allAlerts;
            }

            alertsToDisplay = alertsToDisplay.map((value, index) => {
                let contentDetail = value.detailedContent;
                if (contentDetail) {
                    contentDetail.content = '';
                }
                const keyIndex = snacks.length + index;
                return enqueueSnackbar('', {
                    content: () => (
                        <AlertNotification
                            title={value.name}
                            description={
                                contentDetail ? (
                                    <AlertDetails
                                        contentDetail={contentDetail}
                                    />
                                ) : (
                                    <>{value.content}</>
                                )
                            }
                            onClose={() => hideAlertNotification(keyIndex)}
                        />
                    ),
                    autoHideDuration: 10000,
                    preventDuplicate: false,
                });
            });

            snacks.push(...alertsToDisplay);
        }
    };

    const hideAlertNotification = (index: string | number) => {
        closeSnackbar(snacks[index]);
    };

    const badgeContent = alertCount > 99 ? '99+' : alertCount;

    return (
        <Badge
            badgeContent={badgeContent}
            color="error"
            showZero={false}
            classes={{ badge: classes.badge }}
        >
            <AlertsIcon />
        </Badge>
    );
};

export default AlertWithBadge;
