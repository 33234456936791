import React from 'react';

import { Field, Form, Formik, FormikValues } from 'formik';

import SaveIcon from '@material-ui/icons/Save';

import { useSnackbar } from 'notistack';

import { useStyles } from './Themable.hooks';

import { customAction, resetLevel } from '../../../../../../state/_actions';

import TranslationHelper from '../../../../../../helpers/TranslationHelper';
import { errorMessageHandler } from '../../../../../../helpers/errorMessageHandler';

import { APPLICATION_ITEMS } from '../../../../../../constants/dictionaries/ExternalApplicationItems';
import { DIGITS_ONLY_REGEX } from '../../../../../../constants/regex';

import { usePane, useCreatorLevel } from '../../../../selectors/index.hooks';

import { taskLinkageSchema } from '../../../../../../schemas';

import { mapDictionaryItems } from '../../_utils';

import PaneHeader from '../../../../../../components/PaneHeader';
import IconButtonWithTooltip from '../../../../../../components/IconButtonWithTooltip';
import FieldWrapper from '../../../../../../components/common/formikWrappers/FieldWrapper';
import NumberFieldWrapper from '../../../../../../components/common//formikWrappers/NumberFieldWrapper';
import { useAppDispatch } from '../../../../../../hooks';

const TaskLinkagePreview = () => {
    const initialValues = {
        appName: APPLICATION_ITEMS[0].id,
        externalId: '',
    };

    const dispatch = useAppDispatch();

    const { enqueueSnackbar } = useSnackbar();

    const classes = useStyles();

    const creatorLevel = useCreatorLevel();
    const pane = usePane();

    const handleCloseClick = () => {
        dispatch(resetLevel(creatorLevel));
    };
    const getRightCustomControls = (
        submitForm: () => void,
        submitting: boolean
    ) => (
        <IconButtonWithTooltip
            title={'Save'}
            onClick={submitForm}
            children={<SaveIcon />}
            disabled={submitting}
        />
    );

    const handleValues = (data: FormikValues) => {
        const action = pane && pane.previewAction;

        if (!action) {
            return;
        }
        dispatch(
            customAction(
                action,
                data,
                () => {
                    handleCloseClick();
                    enqueueSnackbar(
                        TranslationHelper.translate(
                            'Linkage saved succesfully'
                        ),
                        { variant: 'success' }
                    );
                },
                (error) => {
                    handleCloseClick();
                    const message = errorMessageHandler(error.status, {
                        409: 'Linkage exists already',
                    })();
                    enqueueSnackbar(
                        `${TranslationHelper.translate(
                            'Linkage save failed'
                        )}: ${message}`,
                        { variant: 'error' }
                    );
                }
            )
        );
    };
    return (
        <div>
            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                onSubmit={handleValues}
                validateOnBlur={false}
                validateOnChange={false}
                validationSchema={taskLinkageSchema()}
            >
                {({
                    submitForm,
                    values,
                    isSubmitting,
                    setFieldValue,
                    errors,
                }) => (
                    <>
                        <PaneHeader
                            title={TranslationHelper.translate(
                                'External linkage'
                            )}
                            onCloseClick={handleCloseClick}
                            renderRightCustomControls={() =>
                                getRightCustomControls(submitForm, isSubmitting)
                            }
                        />
                        <div className={classes.formWrapper}>
                            <Form>
                                <Field
                                    name={'appName'}
                                    component={FieldWrapper}
                                    label={TranslationHelper.translate(
                                        'linkage.application'
                                    )}
                                    required={true}
                                    error={!!(errors && errors.appName)}
                                    helperText={errors && errors.appName}
                                    select={true}
                                    className={classes.fieldMd}
                                >
                                    {mapDictionaryItems(APPLICATION_ITEMS)}
                                </Field>
                                <Field
                                    name={'externalId'}
                                    component={NumberFieldWrapper}
                                    regex={DIGITS_ONLY_REGEX}
                                    fullWidth={true}
                                    label={TranslationHelper.translate(
                                        'linkage.identificator'
                                    )}
                                    required={true}
                                    error={!!(errors && errors.externalId)}
                                    helperText={errors && errors.externalId}
                                    className={classes.fieldLg}
                                />
                            </Form>
                        </div>
                    </>
                )}
            </Formik>
        </div>
    );
};

export default TaskLinkagePreview;
