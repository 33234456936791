import React, { ComponentType } from 'react';

import useMediaQuery from '@material-ui/core/useMediaQuery';

export const withMediaQuery =
    (...args: [string]) =>
    (Component: ComponentType) =>
    (props: any) => {
        const mediaQuery = useMediaQuery(...args);
        return <Component mediaQuery={mediaQuery} {...props} />;
    };

export interface IWithMediaQueryProps {
    mediaQuery: boolean;
}
