import { connect } from 'react-redux';
import {
    closePreview,
    fetchAggregationDetails,
} from '../../../../../../state/_actions';
import { TRootState } from '../../../../../../store';
import {
    getDiscoveryUIPreviewPane,
    getPreviewCreatorLevel,
} from '../../../../selectors';
import { getAggregationSourceSetElement } from '../../../../selectors/composed';

const mapStateToProps = (state: TRootState) => ({
    sourceSetElementReportSet: getAggregationSourceSetElement(state),
    creatorLevel: getPreviewCreatorLevel(state),
    preview: getDiscoveryUIPreviewPane(state),
});

const mapDispatchToProps = {
    fetchAggregationDetails,
    closePreview,
};

type TStateProps = ReturnType<typeof mapStateToProps>;
type TDispatchProps = typeof mapDispatchToProps;

export type TConnectableProps = TStateProps & TDispatchProps;

export const Connectable = connect(mapStateToProps, mapDispatchToProps);
