import { FieldProps } from 'formik';

import React, { useEffect } from 'react';

import { KeyboardTimePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import AccessTimeIcon from '@material-ui/icons/AccessTime';

import TranslationHelper from '../../../../helpers/TranslationHelper';

const TimeFieldWrapper = ({
    field: { name, value },
    form: { setFieldValue, errors },
    ...props
}: FieldProps) => {
    useEffect(() => {
        if (value && !value._isValid) {
            setFieldValue(name, null);
        }
    }, []);

    const handleChange = (val: MaterialUiPickersDate) => {
        setFieldValue(name, val, true);
    };

    const getTimeDialogTranslations = () => {
        const dateTranslations = TranslationHelper.makeDateTimeTranslations();
        const { cancelLabel, okLabel } = dateTranslations;
        return {
            cancelLabel,
            okLabel,
        };
    };

    return (
        <KeyboardTimePicker
            value={value}
            onChange={handleChange}
            ampm={false}
            keyboardIcon={<AccessTimeIcon />}
            invalidDateMessage={
                errors[name]
                    ? ''
                    : TranslationHelper.translate('Invalid date format')
            }
            {...getTimeDialogTranslations()}
            {...props}
        />
    );
};

export default TimeFieldWrapper;
