import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { TRootState } from '../../store';
import { getAllModules, getWebXClientUrl } from './selectors';

import { getLoginState } from '../../state/login';

interface IMatchParams {
    iframe: string;
}

const mapStateToProps = (state: TRootState) => ({
    webxClientUrl: getWebXClientUrl(state),
    modules: getAllModules(state),
    loginStore: getLoginState(state),
});

const mapDispatchToProps = {};

type TStateProps = ReturnType<typeof mapStateToProps>;
type TDispatchProps = typeof mapDispatchToProps;

export type TConnectableProps = TStateProps &
    TDispatchProps &
    RouteComponentProps<IMatchParams>;

export const Connectable = connect(mapStateToProps, mapDispatchToProps);
