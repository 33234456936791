import { connect } from 'react-redux';
import { resetLevel } from '../../../../state/_actions';
import { updateCalibrationChartType } from '../../../../state/ui/discovery/snapshotting';
import { TRootState } from '../../../../store';
import { getCalibrationChartCreatorLevel } from '../../selectors';
import { getCalibrationChartType } from '../../selectors/composed';

const mapStateToProps = (state: TRootState) => ({
    creatorLevel: getCalibrationChartCreatorLevel(state),
    calibrationType: getCalibrationChartType(state),
});

const mapDispatchToProps = {
    resetLevel,
    updateCalibrationChartType,
};

type TStateProps = ReturnType<typeof mapStateToProps>;
type TDispatchProps = typeof mapDispatchToProps;

export type TConnectableProps = TStateProps & TDispatchProps;

export const Connectable = connect(mapStateToProps, mapDispatchToProps);
