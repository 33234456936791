import { Dispatch } from 'redux';
import { fetchRecognizedNumbersSection } from '../../../../services/discovery/fetchRecognizedNumbersSection';
import { makeFetchModule } from '../../../_utils';
import { storeReports, resetReports } from '../../entities';

const callbacks = {
    onFetchBegin: () => resetReports(),
    onSuccess: (dispatch: Dispatch, { entities }: { entities: object }) => {
        dispatch(storeReports(entities));
    },
    onReset: (dispatch: Dispatch) => {
        dispatch(resetReports());
    },
};
const { reducer, fetchData, reset } = makeFetchModule(
    'app/discovery/recognizedNumbersSectionFetching',
    fetchRecognizedNumbersSection,
    callbacks
);

export default reducer;
export { fetchData, reset };
