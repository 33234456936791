import React, { useEffect, useState } from 'react';

import { FieldProps, FormikValues } from 'formik';
import { useSnackbar } from 'notistack';

import CrudPane from '../../../../../../components/CrudPane';
import DetectionSettingsForm from './components/DetectionSettingsForm';

import {
    useCreatorLevel,
    usePane,
    useUserSettings,
    useConfigParamId,
} from './Connectable.hooks';

import { customAction, resetLevel } from '../../../../../../state/_actions';
import {
    clearDetectionData,
    fetchEvents,
    fetchOnDemandData,
    IGetFuelingOnDemandParams,
} from '../../../../../../state/app/refuellAndLossConfiguration';

import { LinearProgress } from '@material-ui/core';

import { responseMessage } from '../../../../../../helpers/responseMessage';
import TimeFormatter from '../../../../../../helpers/TimeFormatter';
import TranslationHelper from '../../../../../../helpers/TranslationHelper';

import { refuellAndLossConfigurationSchema } from '../../../../../../schemas';
import { IRefuellAndLossConfigurationForm } from '../../../../../../state/ui/forms';
import { useAppDispatch } from '../../../../../../hooks';

const DetectionSettings = () => {
    const [blankForm, setBlankForm] = useState({
        params: {
            referentialFrom: '',
            referentialTo: '',
        },
        detector: {
            levelThreshold: 0,
            refuelingThreshold: 0,
            lossThreshold: 0,
            samplesNumber: 0,
        },
        simplify: {
            enabled: false,
            tolerance: 0,
            highQuality: false,
        },
        smooth: {
            enabled: false,
            bandwidth: 0,
        },
    });

    const [eventsFetching, setEventsFetching] = useState<boolean>(false);
    const [requestType, setRequestType] = useState<string>('PUT');
    const [configLoaded, setConfigLoaded] = useState<boolean>(false);
    const [fetchParams, setFetchParams] =
        useState<IGetFuelingOnDemandParams | null>(null);

    const pane = usePane();
    const userSettings = useUserSettings();
    const creatorLevel = useCreatorLevel();
    const selectedParam = useConfigParamId();

    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        getInitialValues();
    }, []);

    const dispatch = useAppDispatch();

    const handleCloseClick = () => {
        dispatch(resetLevel(creatorLevel));
        dispatch(clearDetectionData());
    };
    const setBlankFormWithConfig = (data: IRefuellAndLossConfigurationForm) => {
        setBlankForm(data);
        setConfigLoaded(true);
    };
    const setBlankFormWithDefaultConfig = () => {
        const action = pane && pane.previewAction;

        if (!action) {
            return;
        }
        const actionWithoutParams = { ...action };
        delete actionWithoutParams.params;
        const getAction = {
            ...actionWithoutParams,
            api: actionWithoutParams.api + '/default',
            label: 'GET',
            method: 'GET',
        };

        dispatch(
            customAction(
                getAction,
                undefined,
                (data: IRefuellAndLossConfigurationForm) => {
                    data.params = {
                        referentialFrom: blankForm.params.referentialFrom,
                        referentialTo: blankForm.params.referentialTo,
                    };
                    setRequestType('POST');
                    setBlankForm(data);
                    setConfigLoaded(true);
                }
            )
        );
    };

    const getInitialValues = () => {
        const action = pane && pane.previewAction;
        if (!action) {
            return;
        }
        const getAction = {
            ...action,
            api: action.api + `?monitoredId=${pane?.elementId}`,
            label: 'GET',
            method: 'GET',
        };
        dispatch(
            customAction(
                getAction,
                undefined,
                (data: IRefuellAndLossConfigurationForm[]) => {
                    if (data) {
                        data[0].params = {
                            referentialFrom: blankForm.params.referentialFrom,
                            referentialTo: blankForm.params.referentialTo,
                        };
                        setRequestType('PUT');
                        setBlankFormWithConfig(data[0]);
                    }
                },
                () => {
                    setBlankFormWithDefaultConfig();
                }
            )
        );
    };
    const handleSubmit = (data: FormikValues) => {
        const action = pane && pane.previewAction;

        if (!action) {
            return;
        }
        let modifyAction;

        if (requestType === 'POST') {
            modifyAction = {
                ...action,
                api: action.api,
                label: requestType,
                method: requestType,
            };
        } else {
            modifyAction = {
                ...action,
                api: `${action.api}/${data.id}`,
                label: requestType,
                method: requestType,
            };
        }

        const dataWithoutDates = { ...data };
        delete dataWithoutDates.params;
        const dataWithMonitoredId = {
            ...dataWithoutDates,
            monitoredId: pane?.elementId,
        };
        dispatch(
            customAction(
                modifyAction,
                dataWithMonitoredId,
                () => {
                    handleCloseClick();
                    enqueueSnackbar(
                        TranslationHelper.translate('config.snackbar.success'),
                        { variant: 'success' }
                    );
                },
                (error: any) => {
                    const message = responseMessage(error.status);
                    enqueueSnackbar(
                        `${TranslationHelper.translate(
                            'Configuration save failed'
                        )}: ${TranslationHelper.translate(message.text)}`,
                        { variant: message.type }
                    );
                }
            )
        );
    };
    const fetchArchiveEvents = (
        from?: string,
        to?: string,
        paramName?: string
    ) => {
        const objectId = pane?.elementId;

        if (!objectId || !from || !to || !paramName) {
            return;
        }
        setEventsFetching(true);
        setFetchParams({
            monitoredId: Number(objectId),
            from: TimeFormatter.toISOString(from),
            to: TimeFormatter.toISOString(to),
        });

        dispatch(
            fetchEvents(
                {
                    objectId: Number(objectId),
                    from: TimeFormatter.toISOString(from),
                    to: TimeFormatter.toISOString(to),
                    params: paramName,
                },
                () => {
                    setEventsFetching(false);
                },
                (error) => {
                    const message = responseMessage(error.status);
                    enqueueSnackbar(
                        `${TranslationHelper.translate(
                            'Archive events download failed'
                        )}: ${TranslationHelper.translate(message.type)}`,
                        { variant: message.type }
                    );
                    setEventsFetching(false);
                }
            )
        );
    };

    const updateEventsDates = (
        from: string,
        to: string,
        { form }: FieldProps
    ) => {
        form.setFieldValue(
            'params.referentialFrom',
            TimeFormatter.toTimeZonedDate(from, userSettings.timeZone).format()
        );
        form.setFieldValue(
            'params.referentialTo',
            TimeFormatter.toTimeZonedDate(to, userSettings.timeZone).format()
        );

        fetchArchiveEvents(from, to, selectedParam);
        if (pane?.elementId) {
            dispatch(
                fetchOnDemandData(
                    {
                        from,
                        to,
                        monitoredId: Number(pane.elementId),
                    },
                    blankForm
                )
            );
        }
    };

    return (
        <CrudPane
            titles={{
                add: 'detectionConfig.title',
            }}
            loaded={pane?.mode === 'add'}
            initialValues={blankForm}
            validationSchema={refuellAndLossConfigurationSchema()}
            onClose={handleCloseClick}
            changeModeHandler={handleCloseClick}
            mode={(pane && pane.mode) || undefined}
            onAdd={(values, onSuccess) => {
                handleSubmit(values);
            }}
            renderContent={(
                mode,
                errors,
                values,
                setFieldValue,
                isSubmitting,
                validateForm
            ) => {
                if (isSubmitting || !configLoaded) {
                    return <LinearProgress />;
                }
                return (
                    <>
                        {eventsFetching && <LinearProgress />}
                        <DetectionSettingsForm
                            values={values}
                            errors={errors}
                            setFieldValue={setFieldValue}
                            updateEventsDates={updateEventsDates}
                            validateForm={validateForm}
                            fetchParams={fetchParams}
                        />
                    </>
                );
            }}
        />
    );
};

export default DetectionSettings;
