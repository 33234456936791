import React, { Component, ErrorInfo } from 'react';
import { compose } from 'redux';

import { Themable, TThemableProps } from './Themable.hoc';

import TranslationHelper from '../../helpers/TranslationHelper';

type TProps = TThemableProps;

class ErrorBoundary extends Component<TProps> {
    public state = {
        error: null,
        errorInfo: null,
    };

    public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        this.setState({
            error,
            errorInfo,
        });
    }

    public render() {
        const { classes } = this.props;

        if (this.state.error) {
            return (
                <div className={classes.container}>
                    <h1>
                        {TranslationHelper.translate('Something went wrong.')}
                    </h1>
                </div>
            );
        }

        return this.props.children;
    }
}

export default compose(Themable)(ErrorBoundary);
