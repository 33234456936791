import React from 'react';

import { Formik } from 'formik';

import { Paper } from '@material-ui/core';

import { useUser } from '../../Connectable.hooks';
import { useStyles } from '../../Themable.hooks';

import { goToPreviewMode } from '../../../../../../state/ui/usersSlice';
import { updateUser } from '../../../../../../state/app/collections';
import { trimObjectValues } from '../../../../../../helpers/TrimValuesHelper';

import { useAppDispatch } from '../../../../../../hooks';

import { userEditionSchema } from '../../../../../../schemas';
import { TFormEditUser, titles } from '../../settings';
import UserPaneHeader from '../UserPaneHeader';
import UserForm from '../UserForm/UserForm';

import { IRestPutUser } from '../../../../../../services/users';

const blankFormUser: TFormEditUser = {
    name: '',
    surname: '',
    email: '',
    roles: {},
};

const UserEditionForm = () => {
    const user = useUser();

    const classes = useStyles();

    const dispatch = useAppDispatch();

    const initialValues: TFormEditUser =
        (user && {
            name: user.name || '',
            surname: user.surname || '',
            email: user.email || '',
            roles:
                (user.roles &&
                    user.roles.reduce((result, next) => {
                        result[next] = true;
                        return result;
                    }, {})) ||
                {},
        }) ||
        blankFormUser;

    const handleCloseClick = () => {
        dispatch(goToPreviewMode());
    };

    const handleValues = ({ roles, ...values }: TFormEditUser) => {
        if (user === null) {
            return;
        }
        dispatch(
            updateUser({
                ...(trimObjectValues(values) as IRestPutUser),
                id: user.id,
                roles: Object.keys(roles)
                    .filter((key) => roles[key] === true)
                    .map((key) => parseInt(key, 10)),
            })
        );
        handleCloseClick();
    };
    return (
        <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            onSubmit={handleValues}
            validateOnBlur={false}
            validateOnChange={false}
            validationSchema={userEditionSchema()}
        >
            {({ submitForm, errors }) => (
                <Paper className={classes.pane}>
                    <div className={classes.wrapper}>
                        <UserPaneHeader
                            title={titles.edit()}
                            onClose={handleCloseClick}
                            onSave={submitForm}
                        />

                        <div className={classes.formWrapper}>
                            <UserForm
                                fieldSetType="edit"
                                errors={errors}
                                noValidate={true}
                            />
                        </div>
                    </div>
                </Paper>
            )}
        </Formik>
    );
};

export default UserEditionForm;
