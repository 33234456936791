export default {
    // ! enableValue: true, (ag-Grid-Enterprise only) Set to true if you want to be able to aggregate by this column via the GUI
    // ! enableRowGroup: true, (ag-Grid-Enterprise only) Set to true if you want to be able to row group by this column via the GUI.
    // ! enablePivot: true, (ag-Grid-Enterprise only) Set to true if you want to be able to pivot by this column via the GUI
    resizable: true,
    sortable: true,
    filter: true,
    filterParams: {
        apply: false, // This one makes filtering on input change not on apply button click
        newRowsAction: 'keep',
    },
    useValueFormatterForExport: true,
};
