import React, { useEffect, useState } from 'react';

import { useToolkitDispatch } from '../../hooks';

import { IMappedObject } from '../../pages/reports/components/GeneratedReports/components/ReportsForm/ReportsForm';
import SearchableSelectionList from '../SearchableSelectionList';
import {
    fetchRegistersMonitoredObjects,
    IRegistersMonitoredObject,
} from '../../state/app/registers/monitoredObjects';

interface IOwnProps {
    objects: IMappedObject[];
    errors?: string;
    handleFormChange: <T>(name: string, value: T) => void;
}

const ObjectsSection = ({ objects, errors, handleFormChange }: IOwnProps) => {
    const [items, setItems] = useState<IMappedObject[]>([]);
    const [loaded, setLoaded] = useState(false);

    const toolkitDispatch = useToolkitDispatch();

    useEffect(() => {
        fetchObjects();
    }, []);

    const mapToSelectOptions = (object: IRegistersMonitoredObject) => {
        return {
            id: object.id,
            key: String(object.id),
            name: object.name,
        };
    };

    const fetchObjects = () => {
        toolkitDispatch(fetchRegistersMonitoredObjects())
            .unwrap()
            .then((objectsData) => {
                setItems(objectsData.map(mapToSelectOptions));
                setLoaded(true);
            });
    };

    return (
        <SearchableSelectionList
            listError={errors}
            title="Objects"
            items={items}
            selectedItems={objects}
            loaded={loaded}
            filterKey={'name'}
            handleFormChange={handleFormChange}
        />
    );
};

export default ObjectsSection;
