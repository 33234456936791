import React from 'react';

import Moment from 'moment/moment';
import getParamValueByName from '../../../../selectors/getParamValueByName';

import TranslationHelper from '../../../../helpers/TranslationHelper';
import EventsTable from '../EventsTable';
import { IDataItem } from './selectors';
import { useDefinitions } from './Connectable.hooks';
import { useDateFormat } from '../../../../state/user/index.hooks';
import {
    REFUELING,
    SUPPLY,
} from '../../../../constants/dictionaries/FuelTankStates';

interface IProps {
    data?: IDataItem[];
}

type TProps = IProps;

const FuelTankOrdinaryEventsTable = ({ data }: TProps) => {
    const paramDefinitions = useDefinitions();
    const dateFormat = useDateFormat();

    const dataStructure = [
        { header: '', key: 'icon', width: '30px' },
        {
            header: TranslationHelper.translate('Date'),
            key: 'date',
            width: '150px',
        },
        { header: TranslationHelper.translate('Vehicle'), key: 'vehicle' },
        {
            header: TranslationHelper.translate('Employee'),
            key: 'employee',
        },
        {
            header: TranslationHelper.translate('Amount'),
            key: 'amount',
            width: '150px',
        },
    ];

    const getPreparedData = (dataArr?: IDataItem[]) => {
        return (
            dataArr &&
            dataArr.map(
                ({
                    date,
                    params,
                    state: { type },
                    data: { vehicle = '-', employee = '-' },
                }) => {
                    const amountOptions = {
                        [REFUELING]: 'FUEL',
                        [SUPPLY]: 'REFUEL',
                    };
                    const amountOption = amountOptions[type] || null;
                    const amount =
                        amountOption &&
                        getParamValueByName(
                            paramDefinitions,
                            amountOption,
                            params
                        );
                    return {
                        icon:
                            type === REFUELING
                                ? 'icon-truck green'
                                : 'icon-gas yellow',
                        date: Moment(date).format(dateFormat),
                        vehicle,
                        employee,
                        amount,
                    };
                }
            )
        );
    };
    const prepatedData = getPreparedData(data);

    return (
        <>
            <EventsTable data={prepatedData} structure={dataStructure} />
        </>
    );
};

export default FuelTankOrdinaryEventsTable;
