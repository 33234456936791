import { useSelector } from 'react-redux';

import {
    getNotAssignedFilter,
    getIncludeNotPlanned,
    getPreviewCreatorLevel,
    getSourceSetDates,
    shouldDiscoveryBeInEditMode,
    getOnlyNotPlannedFilter,
} from '../../pages/discovery/selectors';

export const useSourceSetDates = () => useSelector(getSourceSetDates);
export const useOnlyNotAssigned = () => useSelector(getNotAssignedFilter);
export const useIncludeNotPlanned = () => useSelector(getIncludeNotPlanned);
export const useOnlyNotPlanned = () => useSelector(getOnlyNotPlannedFilter);
export const useCreatorLevel = () => useSelector(getPreviewCreatorLevel);
export const useEditMode = () => useSelector(shouldDiscoveryBeInEditMode);
