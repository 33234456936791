import { createSelector } from 'reselect';
import { TRootState } from '../../store';

const getConfig = (state: TRootState) => state.app.iframes.modules;

export const getAllModules = createSelector([getConfig], (iframeConfig) => {
    return iframeConfig && iframeConfig.modules;
});

export const getWebXClientUrl = createSelector([getConfig], (config) => {
    return (config && config.webXClientUrl) || '';
});
