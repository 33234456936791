import UrlConst from './UrlConst';
import { MAX_RANGE } from './videoPlayerSettings';
import { DEFAULT_PRIMARY_COLOR } from './ColorConst';

let singleton = Object();
let singletonEnforcer = Object();

class AppConfig {
    constructor(enforcer) {
        if (enforcer !== singletonEnforcer) {
            throw 'Cannot construct singleton';
        }

        this._config = {};
        this._licenseKeys = {};
        this.urls = new UrlConst();
    }

    static get instance() {
        if (!this[singleton]) {
            this[singleton] = new AppConfig(singletonEnforcer);
        }
        return this[singleton];
    }

    setConfig(config) {
        this._config = config;
        this._setDefaultValues();

        switch (this.getConfigKey('env')) {
            case AppConfig.PRODUCTION:
                this.urls.changeToProduction();
                break;
            case AppConfig.TEST:
                this.urls.changeToTest();
                break;
            case AppConfig.LOCAL_DOCKER:
                this.urls.changeToLocalDocker();
                break;
            default:
                break;
        }
    }

    setLicenseKeys(licenseKeys) {
        this._licenseKeys = licenseKeys;
        this._setLicenseKeys();
    }

    get config() {
        return this._config;
    }

    getConfigKey(key) {
        if (this._config) {
            if (this._config[key] !== undefined) {
                return this._config[key];
            }
        }
    }

    getUrl(key) {
        return this.urls.getProperty(key);
    }

    _setDefaultValues() {
        if (this._config[AppConfig.PROPERTY_ARCHIVE] === undefined) {
            this._config[AppConfig.PROPERTY_ARCHIVE] = true;
        }
        if (this._config[AppConfig.PROPERTY_DASHBOARD] === undefined) {
            this._config[AppConfig.PROPERTY_DASHBOARD] = true;
        }
        if (this._config[AppConfig.PROPERTY_LIVE] === undefined) {
            this._config[AppConfig.PROPERTY_LIVE] = false;
        }
        if (this._config[AppConfig.PROPERTY_SERVICE_CENTER] === undefined) {
            this._config[AppConfig.PROPERTY_SERVICE_CENTER] = false; // default to false
        }
        if (this._config[AppConfig.PROPERTY_LOCATIONS] === undefined) {
            this._config[AppConfig.PROPERTY_LOCATIONS] = true;
        }
        if (this._config[AppConfig.PROPERTY_CUSTOMERS] === undefined) {
            this._config[AppConfig.PROPERTY_CUSTOMERS] = [];
        }

        if (this._config[AppConfig.PROPERTY_BRANDING] === undefined) {
            this._config[AppConfig.PROPERTY_BRANDING] = {
                primaryColor: DEFAULT_PRIMARY_COLOR,
                topLogo: '',
                loginLogo: '',
                xtrackBrand: true,
            };
        }
        if (this._config[AppConfig.PROPERTY_MOVIE_PLAYER] === undefined) {
            this._config[AppConfig.PROPERTY_MOVIE_PLAYER] = {
                maxRange: MAX_RANGE,
                initialRange: [-1, 1],
            };
        }
        if (this._config[AppConfig.PROPERTY_EXTERNAL_SECTIONS] === undefined) {
            this._config[AppConfig.PROPERTY_EXTERNAL_SECTIONS] = [];
        }
        if (
            this._config[AppConfig.PROPERTY_BRANDING].xtrackBrand === undefined
        ) {
            this._config[AppConfig.PROPERTY_BRANDING].xtrackBrand = true;
        }
        if (this._config[AppConfig.PROPERTY_ROUTES] === undefined) {
            this._config[AppConfig.PROPERTY_ROUTES] = true;
        }
        if (this._config['th'] === undefined) {
            this._config['th'] = 'default';
        }
        if (this._config['title'] === undefined) {
            this._config['title'] = 'WebX';
        }
        if (this._config['xss'] === undefined) {
            this._config['xss'] = 'xss.xtrack.pl';
        }
        if (this._config[AppConfig.PROPERTY_REPORTS] === undefined) {
            this._config[AppConfig.PROPERTY_REPORTS] = true;
        }
        if (this._config[AppConfig.PROPERTY_REGISTERS] === undefined) {
            this._config[AppConfig.PROPERTY_REGISTERS] = true;
        }
        if (this._config[AppConfig.PROPERTY_ALERTS] === undefined) {
            this._config[AppConfig.PROPERTY_ALERTS] = true;
        }
        if (this._config[AppConfig.PROPERTY_LASTSTATES] === undefined) {
            this._config[AppConfig.PROPERTY_LASTSTATES] = true;
        }
        if (this._config[AppConfig.PROPERTY_DISCOVERYTASKS] === undefined) {
            this._config[AppConfig.PROPERTY_DISCOVERYTASKS] = true;
        }
    }

    _setLicenseKeys() {
        if (
            this._licenseKeys[AppConfig.PROPERTY_LAYERS] === undefined ||
            this._licenseKeys[AppConfig.PROPERTY_LAYERS] === []
        ) {
            this._config[AppConfig.PROPERTY_LAYERS] = [{ type: 'OSM' }];
        } else {
            this._config[AppConfig.PROPERTY_LAYERS] =
                this._licenseKeys[AppConfig.PROPERTY_LAYERS];
        }
        if (this._licenseKeys[AppConfig.PROPERTY_GRID] === undefined) {
            this._config[AppConfig.PROPERTY_GRID] = {};
        } else {
            this._config[AppConfig.PROPERTY_GRID] =
                this._licenseKeys[AppConfig.PROPERTY_GRID];
        }
    }
}

AppConfig.PRODUCTION = 'production';
AppConfig.TEST = 'test';
AppConfig.LOCAL_DOCKER = 'local-docker';
AppConfig.PROPERTY_ARCHIVE = 'archiveEnabled';
AppConfig.PROPERTY_LIVE = 'liveEnabled';
AppConfig.PROPERTY_DASHBOARD = 'dashboardEnabled';
AppConfig.PROPERTY_SERVICE_CENTER = 'serviceCenterEnabled';
AppConfig.PROPERTY_LOCATIONS = 'locationsEnabled';
AppConfig.PROPERTY_CUSTOMERS = 'customers';
AppConfig.PROPERTY_LAYERS = 'tileLayers';
AppConfig.PROPERTY_GRID = 'gridEnterprise';
AppConfig.PROPERTY_BRANDING = 'branding';
AppConfig.PROPERTY_REPORTS = 'reportsEnabled';
AppConfig.PROPERTY_REGISTERS = 'registersEnabled';
AppConfig.PROPERTY_ALERTS = 'alertsEnabled';
AppConfig.PROPERTY_LASTSTATES = 'lastStatesEnabled';
AppConfig.PROPERTY_ROUTES = 'routesEnabled';
AppConfig.PROPERTY_DISCOVERYTASKS = 'discoveryTasksEnabled';
AppConfig.PROPERTY_LANGUAGES = 'languages';
AppConfig.PROPERTY_FEATURES = 'features';
AppConfig.PROPERTY_DATA_SEGMENTS_CONTEXR = 'dataSegmentsContextEnabled';
AppConfig.PROPERTY_MOVIE_PLAYER = 'moviePlayer';
AppConfig.PROPERTY_EXTERNAL_SECTIONS = 'externalSections';
export const areLocationsEnabled = () =>
    AppConfig.instance.getConfigKey(AppConfig.PROPERTY_LOCATIONS);

export const areLastStatesEnabled = () =>
    AppConfig.instance.getConfigKey(AppConfig.PROPERTY_LASTSTATES);

export const areRoutesEnabled = () =>
    AppConfig.instance.getConfigKey(AppConfig.PROPERTY_ROUTES);

export const areTasksEnabled = () =>
    AppConfig.instance.getConfigKey(AppConfig.PROPERTY_DISCOVERYTASKS);

export const areArchivesEnabled = () =>
    AppConfig.instance.getConfigKey(AppConfig.PROPERTY_ARCHIVE);

export const areLayersEnabled = () =>
    AppConfig.instance.getConfigKey(AppConfig.PROPERTY_FEATURES);

export const isDataSegmentsContextEnabled = () =>
    AppConfig.instance.getConfigKey(AppConfig.PROPERTY_DATA_SEGMENTS_CONTEXR);

export default AppConfig;
