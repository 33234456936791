import { useSelector } from 'react-redux';
import {
    getDiscoveryUIPreviewPane,
    getLastMapClickPosition,
    getPreviewLocationId,
} from '../../../../../../selectors';

export const usePane = () => useSelector(getDiscoveryUIPreviewPane);
export const useLocationId = () => useSelector(getPreviewLocationId);
export const useMapCoordinates = () => useSelector(getLastMapClickPosition);
