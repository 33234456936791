import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => {
    const spacing = theme.spacing(3);
    return createStyles({
        formWrapper: {
            padding: spacing,
            '& .MuiTextField-root': {
                margin: `${theme.spacing(1)}px 0`,
            },
        },
        inputWrapper: {
            textOverflow: 'ellipsis',
        },
    });
});
