import React, { Fragment, PureComponent } from 'react';

import { Hidden } from '@material-ui/core';

import Bookmarks from './components/Bookmarks';
import DiscoveryMap from './components/DiscoveryMap';
import GridTogglers from './components/GridTogglers';
import SearchPane from './components/SearchPane';

import ErrorBoundary from '../../components/ErrorBoundary';

class MapPaneComponents extends PureComponent<{
    layoutClassName?: string;
    searchEnabled: boolean;
}> {
    public render() {
        return (
            <Fragment>
                <DiscoveryMap layout={this.props.layoutClassName} />
                <Hidden smDown={true}>
                    {this.props.searchEnabled && <SearchPane />}
                    <ErrorBoundary>
                        <Bookmarks />
                    </ErrorBoundary>
                    <GridTogglers />
                </Hidden>
            </Fragment>
        );
    }
}

export default MapPaneComponents;
