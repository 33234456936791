import { ILocation } from '../../state/types';
import { makeFetch, makeManagedSourceSetFetch } from './_shared/fetchMakers';
import {
    normalizeSingleEntity,
    normalizeSingleSourceSet,
} from './_shared/normalizers';
import { makeLocationsUrl } from './_shared/urlMakers';

export const fetchLocations = makeManagedSourceSetFetch<ILocation[]>(
    makeLocationsUrl,
    normalizeSingleEntity
);

export const fetchLocationsRegister = makeFetch<ILocation[]>(
    makeLocationsUrl,
    normalizeSingleSourceSet
);
