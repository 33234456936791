import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import URI from 'urijs';

import { restoreMonitoringState } from '../ui/discovery/snapshotting';

import { stringToData } from '../../helpers/converters';
import {
    IHandledContexts,
    IPossibleContexts,
    TPossibleContexts,
} from './types';

export const contexts: IPossibleContexts = {
    monitoring: '/discovery',
};

const handledContexts: IHandledContexts = {
    [contexts.monitoring]: {
        queryName: 'monitoringData',
        restoreHandler: (data) => restoreMonitoringState(data),
    },
};

export const getContextQueryName = (context: TPossibleContexts): string =>
    handledContexts[context].queryName;

export const restoreState =
    (
        fullRestore = false,
        onFail?: (context: string) => void
    ): ThunkAction<void, null, null, Action<any>> =>
    (dispatch) => {
        const url = new URI(window.location.href);
        const urlQueries = url.query(true);

        if (fullRestore) {
            const contextEntries = Object.entries(handledContexts);
            for (const [context, contextDetails] of contextEntries) {
                const contextQuery = urlQueries[contextDetails.queryName];
                if (typeof contextQuery === 'string') {
                    stringToData(
                        contextQuery,
                        (data) => dispatch(contextDetails.restoreHandler(data)),
                        onFail ? () => onFail(context) : undefined
                    );
                }
            }
        } else {
            const pathName = url.pathname();
            const availableHandler = handledContexts[pathName];
            if (availableHandler) {
                const contextQuery = urlQueries[availableHandler.queryName];
                if (typeof contextQuery === 'string') {
                    stringToData(
                        contextQuery,
                        (data) =>
                            dispatch(availableHandler.restoreHandler(data)),
                        undefined
                    );
                }
            }
        }
    };
