import * as H from 'history';
import { contexts, getContextQueryName } from '../state/_actions';
import { TPossibleContexts } from '../state/_actions/types';
import { dataToString } from './converters';

interface IRouterProps {
    history: H.History;
    location: H.Location;
}

const handleQueryData = (data: any, forceEncode: boolean) => {
    if (typeof data === 'string' && !forceEncode) {
        return data;
    } else {
        return dataToString(data);
    }
};

const generateQuery = (path: string, data: any, forceEncode: boolean) => {
    const handledContexts: string[] = Object.values(contexts);

    if (!handledContexts.includes(path)) {
        return undefined;
    } else {
        return `${getContextQueryName(
            path as TPossibleContexts
        )}=${handleQueryData(data, forceEncode)}`;
    }
};

const replaceCurrentPath = (
    { history, location }: IRouterProps,
    data?: any,
    forceEncode: boolean = false,
    mobile: boolean = false
) => {
    const urlObject = {
        pathname: location.pathname,
        search: data
            ? generateQuery(location.pathname, data, forceEncode)
            : undefined,
    };

    if (mobile && window.location.search) {
        history.push(urlObject);
    } else {
        history.replace(urlObject);
    }
};

export { replaceCurrentPath, handleQueryData };
