import React, { Component, ComponentType } from 'react';
import { compose } from 'redux';

import { Connectable, TConnectableProps } from './Connectable.hoc';
import { Themable, TThemableProps } from './Themable.hoc';

import { IReportEntity } from '../../../../state/types';
import DoughnutChartWithText from './components/DoughnutChart';

interface IOwnProps {
    entities: IReportEntity[];
}

type TProps = IOwnProps & TThemableProps & TConnectableProps;

const params = {
    'Fill level [l]': { icon: 'icon-meter3', unit: 'l' },
    'Temperature [°C]': { icon: 'icon-temperature2', unit: '°C', decimals: 2 },
    'Capacity [l]': { icon: 'icon-database3', unit: 'l' },
    'Total [l]': { icon: 'icon-gas', unit: 'l', decimals: 0 },
};

const fillLevelChart = 'Fill level [%]';

class VisualHint extends Component<TProps> {
    public getFormatting = (
        value: number,
        entityFormatting?: number,
        fallbackFormatting?: number
    ) => {
        if (entityFormatting !== undefined) {
            return value.toFixed(entityFormatting);
        } else if (fallbackFormatting !== undefined) {
            return value.toFixed(fallbackFormatting);
        } else {
            return value;
        }
    };

    public render() {
        const { entities, classes } = this.props;

        return (
            <div className={classes.paramContainer}>
                {entities.map((entity) => {
                    if (entity.id === fillLevelChart) {
                        return (
                            <div key={entity.id} className={classes.paramItem}>
                                <DoughnutChartWithText value={entity.value} />
                            </div>
                        );
                    }

                    const entityFormatting =
                        entity.formatting && entity.formatting.decimalPlaces;
                    return (
                        <div key={entity.id} className={classes.paramItem}>
                            <div className={classes.paramItemIcon}>
                                <span className={params[entity.label].icon} />
                            </div>
                            <div>
                                {`${this.getFormatting(
                                    entity.value as number,
                                    entityFormatting,
                                    params[entity.label].decimals
                                )} ${params[entity.label].unit}`}
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    }
}

export default compose(
    Themable,
    Connectable
)(VisualHint) as ComponentType<IOwnProps>;
