import { styled } from '@mui/system';

export const StyledBarBox = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    mr: -1,
    color: 'primary.contrastText',
    minHeight: 55,
    [theme.breakpoints.down('md')]: {
        height: '64px',
    },
}));
