import { Dispatch } from 'redux';
import { fetchLastStates } from '../../../../services/discovery/fetchLastStates';
import { makeFetchModule } from '../../../_utils';
import { storeSourceSets } from '../../entities';

const callbacks = {
    onSuccess: (
        dispatch: Dispatch,
        {
            entities,
            isDifferentialResponse,
        }: { entities: object; isDifferentialResponse?: boolean }
    ) => {
        dispatch(storeSourceSets(entities, isDifferentialResponse));
    },
};
const { reducer, fetchData, reset } = makeFetchModule(
    'app/discovery/lastStatesFetching',
    fetchLastStates,
    callbacks
);

export default reducer;
export { fetchData, reset };
