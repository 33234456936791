import React, {
    Dispatch,
    MouseEventHandler,
    SetStateAction,
    useEffect,
} from 'react';

import { IGroupAssignment } from '../../../../../../../../../../state/ui/groupsSlice';

import TranslationHelper from '../../../../../../../../../../helpers/TranslationHelper';
import { useDebounceEffect } from '../../../../../../../../../../helpers/hooks';

import { DEBOUNCE_LOW } from '../../../../../../../../../../constants/DebounceValues';

import {
    filterByName,
    filterByType,
    lowerCaseFilter,
} from '../../_utils/filterObjects';

import CustomList from '../../../../../../../../../../components/TransferList/components/CustomList';
import {
    IFilterInitialState,
    IListObjectInitialState,
} from '../../../../../../../../../../components/TransferList/TransferList';
import { useUserDataPermissions } from '../../../../../../../../../../state/user/index.hooks';
import { hasAccessToLocations } from '../../_utils/access';

const TYPES = ['ALL', 'VEHICLE', 'EMPLOYEE', 'RFID_CARD', 'TANK', 'LOCATION'];

interface IOwnProps {
    groupId: string;
    objects: IListObjectInitialState<IGroupAssignment>;
    setObjects: React.Dispatch<
        Partial<IListObjectInitialState<IGroupAssignment>>
    >;
    checked: IGroupAssignment[];
    setChecked: Dispatch<SetStateAction<IGroupAssignment[]>>;
    filter: IFilterInitialState;
    setFilter: React.Dispatch<Partial<IFilterInitialState>>;
    handleToggle: (value: IGroupAssignment) => MouseEventHandler;
}

const RightList = ({
    groupId,
    objects,
    setObjects,
    checked,
    setChecked,
    filter,
    setFilter,
    handleToggle,
}: IOwnProps) => {
    const assignedTitle = TranslationHelper.translate('objects.assigned');
    const dataPermissions = useUserDataPermissions();

    const types = TYPES.filter(
        (t) =>
            t !== 'LOCATION' || hasAccessToLocations(groupId, dataPermissions)
    );

    const { nameAssigned, typeAssigned } = filter;
    const { assignedObjects, filteredAssignedObjects } = objects;
    useDebounceEffect(
        () => {
            handleChangeNameAssigned();
        },
        [nameAssigned, assignedObjects],
        DEBOUNCE_LOW
    );

    useEffect(() => {
        handleChangeTypeAssigned(typeAssigned);
    }, [typeAssigned]);

    const handleChangeNameAssigned = () => {
        if (nameAssigned === '') {
            setObjects({
                filteredAssignedObjects: filterByType(
                    assignedObjects,
                    typeAssigned
                ),
            });

            return;
        }
        const filteredObjects = assignedObjects.filter(
            (object) =>
                lowerCaseFilter(object, nameAssigned) &&
                (typeAssigned === 'ALL'
                    ? object
                    : object.registerType === typeAssigned)
        );
        setObjects({ filteredAssignedObjects: filteredObjects });
    };

    const handleChangeTypeAssigned = (type: string) => {
        if (type === 'ALL') {
            setObjects({
                filteredAssignedObjects: filterByName(
                    assignedObjects,
                    nameAssigned
                ),
            });
            return;
        }
        const filteredObjects = assignedObjects.filter(
            (object) =>
                object.registerType === type &&
                (nameAssigned === ''
                    ? object
                    : lowerCaseFilter(object, nameAssigned))
        );
        setObjects({ filteredAssignedObjects: filteredObjects });
    };

    return (
        <CustomList
            loaded={true}
            typeInput={{
                type: typeAssigned,
                setType: (type) => setFilter({ typeAssigned: type }),
            }}
            nameInput={{
                name: nameAssigned,
                setName: (name) => setFilter({ nameAssigned: name }),
            }}
            title={assignedTitle}
            items={filteredAssignedObjects}
            handleToggle={handleToggle}
            checked={checked}
            setChecked={setChecked}
            tooltipTitle="You can use * as a wildcard."
            types={types}
        />
    );
};

export default RightList;
