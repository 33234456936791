import React from 'react';

import { connect } from 'react-redux';

import { bool, instanceOf } from 'prop-types';

import { Connectable } from '../../Connectable.hoc';

import { restGetTimezoneDictionary } from '../../../../services/registers';
import { restGetGroups } from '../../../../services/groups';

import Vehicle from '../../../../models/registers/Vehicle';

import Translator from '../../../../helpers/TranslationHelper';

import PrimaryButton from '../../../button/PrimaryButton';
import SecondaryButton from '../../../button/SecondaryButton';
import ValidationMessage from '../../../common/ValidationMessage';
import CustomAutocomplete from '../../../common/CustomAutocomplete';
import Dialog from '../../../dialog/Dialog';
import AsyncFilterSelect from '../../../AsyncFilterSelect';
import CustomCheckbox from '../../../common/CustomCheckbox';

class VehicleDialog extends React.Component {
    state = {
        groups: this.props.vehicle.groups || [],
        timeZone: this.props.vehicle.timeZone,
        assignedToPersonalGroup: this.props.vehicle.assignedToPersonalGroup,
        message: undefined,
        loading: false,
        fuelConsumptionType: this.props.vehicle.fuelConsumption ? 'km' : 'mth',
    };

    componentDidMount() {
        this._isMounted = true;
        this.name.focus();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    handleGroupChange = (e, groups) => {
        this.setState({ groups });
    };

    handleTimeZoneChange = (option) => {
        option &&
            this.setState({
                timeZone: {
                    id: option.id,
                    name: option.name,
                },
            });
    };

    handleFuelConsumptionChange = (event) => {
        this.setState({
            fuelConsumptionType: event.target.value,
        });
    };

    handleSubmit = (e) => {
        e.preventDefault();
        let vehicle = this._prepareVehicleData();

        const {
            vehiclesFilter: filter,
            updateVehicle,
            createVehicle,
            hideGlobalDialog,
        } = this.props;

        this.setState({
            loading: true,
        });

        const successCallback = () => {
            hideGlobalDialog();
            this.setState({
                loading: false,
            });
        };
        const failCallback = (response) => {
            let errorMessage =
                (response &&
                    response.responseJSON &&
                    response.responseJSON.message) ||
                'Unrecognized error has occurred';
            if (this._isMounted) {
                this.setState({
                    message: Translator.translate(errorMessage),
                    loading: false,
                });
            }
        };

        const action = vehicle.id ? updateVehicle : createVehicle;

        action(
            vehicle,
            filter,
            () => successCallback(),
            (error) => failCallback(error)
        );
    };

    handleCancel = (e) => {
        e.preventDefault();
        this.props.hideGlobalDialog();
    };

    formatInput = (ref) => {
        ref.value = ref.value.trim();
    };

    _prepareVehicleData() {
        const {
            fuelConsumptionType,
            groups,
            timeZone,
            assignedToPersonalGroup,
        } = this.state;

        return this.props.vehicle.merge({
            name: this.name.value,
            plateNumber: this.plateNumber.value || undefined,
            vin: this.vin.value || undefined,
            sideNumber: this.sideNumber.value || undefined,
            model: this.model.value || undefined,
            mark: this.mark.value || undefined,
            productionYear: +this.year.value || undefined,
            fuelConsumption:
                fuelConsumptionType === 'km' ? this.fuelConsumption.value : 0,
            fuelConsumptionWork:
                fuelConsumptionType === 'mth' ? this.fuelConsumption.value : 0,
            tankSize: this.tankSize.value || undefined,
            groupIds: groups.map((group) => group.id),
            groups: undefined,
            timeZone,
            assignedToPersonalGroup,
        });
    }

    render() {
        const { groups, timeZone, message, loading, fuelConsumptionType } =
            this.state;
        const { vehicle, accessAll } = this.props;

        const currentYear = new Date().getFullYear();

        return (
            <Dialog
                title={
                    vehicle.id === undefined
                        ? Translator.translate('New vehicle')
                        : Translator.translate('Edit vehicle')
                }
                onClose={this.handleCancel}
                classNames="dialog vehicles-dialog"
            >
                <form
                    className="form vehicle-form"
                    onSubmit={this.handleSubmit}
                    autoComplete="off"
                >
                    <div className="section">
                        <div className="form-group single">
                            <div className="form-title">
                                {Translator.translate('Vehicle name')}
                            </div>
                            <div className="form-input">
                                <input
                                    type="text"
                                    className="mtl-input"
                                    ref={(element) => (this.name = element)}
                                    placeholder={
                                        Translator.translate('E.g. Vehicle') +
                                        ' '
                                    }
                                    defaultValue={vehicle.name}
                                    required
                                    maxLength="255"
                                    onBlur={() => this.formatInput(this.name)}
                                />
                            </div>
                        </div>
                        <div className="form-group single">
                            <div className="form-title">
                                {Translator.translate('Group')}
                            </div>
                            <div className="form-input">
                                <CustomAutocomplete
                                    value={groups}
                                    inputTexts={{ placeholder: 'Select group' }}
                                    multi
                                    onChange={this.handleGroupChange}
                                    onOpen={restGetGroups}
                                    optionKeys={{ name: 'name', value: 'id' }}
                                    required={true}
                                />
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="form-title">
                                {Translator.translate('Vehicle plate number')}
                            </div>
                            <div className="form-input">
                                <input
                                    type="text"
                                    className="mtl-input"
                                    ref={(element) =>
                                        (this.plateNumber = element)
                                    }
                                    placeholder={
                                        Translator.translate('E.g. XYZ 32423') +
                                        ' '
                                    }
                                    defaultValue={vehicle.plateNumber}
                                    maxLength="255"
                                />
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="form-title">
                                {Translator.translate('Vehicle side number')}
                            </div>
                            <div className="form-input">
                                <input
                                    type="text"
                                    className="mtl-input"
                                    ref={(element) =>
                                        (this.sideNumber = element)
                                    }
                                    placeholder={
                                        Translator.translate('E.g. 1344435') +
                                        ' '
                                    }
                                    defaultValue={vehicle.sideNumber}
                                    maxLength="255"
                                />
                            </div>
                        </div>
                        <div className="form-group single">
                            <div className="form-title">
                                {Translator.translate('Time zone')}
                            </div>
                            <div className="form-input">
                                <AsyncFilterSelect
                                    name="timeZone"
                                    selectedOptionId={timeZone && timeZone.id}
                                    getData={() => restGetTimezoneDictionary()}
                                    clearable={false}
                                    required
                                    value={timeZone}
                                    placeholder={Translator.translate(
                                        'Select time zone'
                                    )}
                                    onOptionSelect={this.handleTimeZoneChange}
                                />
                            </div>
                        </div>
                        <div className="form-group triple">
                            <div className="form-title">
                                {Translator.translate('Vehicle mark')}
                            </div>
                            <div className="form-input">
                                <input
                                    type="text"
                                    className="mtl-input"
                                    ref={(element) => (this.mark = element)}
                                    placeholder={
                                        Translator.translate('E.g. Toyota') +
                                        ' '
                                    }
                                    defaultValue={vehicle.mark}
                                    maxLength="255"
                                />
                            </div>
                        </div>
                        <div className="form-group triple">
                            <div className="form-title">
                                {Translator.translate('Vehicle model')}
                            </div>
                            <div className="form-input">
                                <input
                                    type="text"
                                    className="mtl-input"
                                    ref={(element) => (this.model = element)}
                                    placeholder={
                                        Translator.translate('E.g. V4') + ' '
                                    }
                                    defaultValue={vehicle.model}
                                    maxLength="255"
                                />
                            </div>
                        </div>
                        <div className="form-group triple">
                            <div className="form-title">
                                {Translator.translate('VIN')}
                            </div>
                            <div className="form-input">
                                <input
                                    type="text"
                                    className="mtl-input"
                                    ref={(element) => (this.vin = element)}
                                    placeholder={
                                        Translator.translate(
                                            'E.g. JM1NA3521L010111'
                                        ) + ' '
                                    }
                                    defaultValue={vehicle.vin}
                                    maxLength="255"
                                />
                            </div>
                        </div>
                        <div className="form-group triple">
                            <div className="form-title">
                                {Translator.translate(
                                    'Vehicle production year'
                                )}
                            </div>
                            <div className="form-input">
                                <input
                                    type="number"
                                    className="mtl-input"
                                    size="4"
                                    ref={(element) => (this.year = element)}
                                    placeholder={
                                        Translator.translate('E.g. 2015') + ' '
                                    }
                                    defaultValue={vehicle.productionYear}
                                    min="1900"
                                    max={currentYear}
                                />
                            </div>
                        </div>
                        <div className="form-group triple">
                            <div className="form-title">
                                {Translator.translate(
                                    'Vehicle fuel consumption'
                                )}
                            </div>
                            <div className="form-input inline">
                                <input
                                    type="number"
                                    className="mtl-input double"
                                    step="0.0001"
                                    ref={(element) =>
                                        (this.fuelConsumption = element)
                                    }
                                    placeholder={
                                        Translator.translate('E.g. 15') + ' '
                                    }
                                    defaultValue={
                                        vehicle.fuelConsumption ||
                                        vehicle.fuelConsumptionWork
                                    }
                                    min="0"
                                    max="99999999999999"
                                />
                                <select
                                    className="mtl-input double"
                                    onChange={this.handleFuelConsumptionChange}
                                    defaultValue={fuelConsumptionType}
                                >
                                    <option value="km">l/100km</option>
                                    <option value="mth">l/h (l/mth)</option>
                                </select>
                            </div>
                        </div>
                        <div className="form-group triple">
                            <div className="form-title">{`${Translator.translate(
                                'Vehicle fuel capacity'
                            )} (l)`}</div>
                            <div className="form-input">
                                <input
                                    type="number"
                                    className="mtl-input"
                                    step="1"
                                    ref={(element) => (this.tankSize = element)}
                                    placeholder={
                                        Translator.translate('E.g. 15') + ' '
                                    }
                                    defaultValue={vehicle.tankSize}
                                    min="0"
                                    max="99999999999999"
                                />
                            </div>
                        </div>
                        <div className="form-group">
                            <ValidationMessage
                                message={Translator.translate(message)}
                                error={message !== undefined}
                            />
                        </div>
                    </div>
                    <div className="form-actions">
                        <PrimaryButton
                            icon="icon-floppy-disk"
                            label={Translator.translate('Save')}
                            disabled={loading}
                        />
                        <SecondaryButton
                            onClick={this.handleCancel}
                            icon="icon-cross"
                            label={Translator.translate('Cancel')}
                        />
                    </div>
                </form>
            </Dialog>
        );
    }
}

VehicleDialog.propTypes = {
    vehicle: instanceOf(Vehicle),
    accessAll: bool,
};

export default Connectable(VehicleDialog);
