import { responseMessage } from './responseMessage';
import TranslationHelper from './TranslationHelper';

interface IUniqueCase {
    [status: number]: string;
}
export const errorMessageHandler = (
    status: number,
    uniqueCase: IUniqueCase = {}
) => {
    return uniqueCase[status]
        ? () => TranslationHelper.translate(uniqueCase[status])
        : () => TranslationHelper.translate(responseMessage(status).text);
};
