import { connect } from 'react-redux';
import { TRootState } from '../../store';

import {
    getCurrentSnapshot,
    getCurrentSnapshotPath,
} from '../../pages/discovery/selectors';

const mapStateToProps = (state: TRootState) => ({
    currentSnapshot: getCurrentSnapshot(state),
    snapshotPath: getCurrentSnapshotPath(state),
});

const mapDispatchToProps = {};

type TStateProps = ReturnType<typeof mapStateToProps>;
type TDispatchProps = typeof mapDispatchToProps;

export type TConnectableProps = TStateProps & TDispatchProps;

export const Connectable = connect(mapStateToProps, mapDispatchToProps);
