import React from 'react';

import { Formik, FormikActions } from 'formik';

import { useSnackbar } from 'notistack';

import SaveIcon from '@material-ui/icons/Save';

import {
    createClient,
    resetMode,
} from '../../../../../../../state/ui/customerService/clientsAndLocations';

import { INewServiceClient } from '../../../../../../../models/customerService';

import { cscAddClientSchema } from '../../../../../../../schemas';

import { useToolkitDispatch } from '../../../../../../../hooks';
import TranslationHelper from '../../../../../../../helpers/TranslationHelper';
import { errorMessageHandler } from '../../../../../../../helpers/errorMessageHandler';

import PaneHeader from '../../../../../../../components/PaneHeader';
import ClientForm from '../ClientForm/ClientForm';
import { BLANK_FORM_CLIENT } from '../blankForms';
import IconButtonWithTooltip from '../../../../../../../components/IconButtonWithTooltip';

const ClientAddForm = () => {
    const toolkitDispatch = useToolkitDispatch();

    const handleClose = () => {
        toolkitDispatch(resetMode());
    };

    const { enqueueSnackbar } = useSnackbar();

    const showNotification = (success: boolean, message: string) => {
        enqueueSnackbar(message, {
            variant: success ? 'success' : 'error',
        });
    };

    const handleValues = (
        values: INewServiceClient,
        { setSubmitting }: FormikActions<INewServiceClient>
    ) => {
        toolkitDispatch(createClient(values))
            .unwrap()
            .then(() => {
                showNotification(
                    true,
                    TranslationHelper.translate('Client created')
                );
                handleClose();
            })
            .catch((error) => {
                const message = errorMessageHandler(error.status, {
                    409: error.errorMsg,
                })();
                showNotification(false, message);
                setSubmitting(false);
            });
    };
    const getRightCustomControls = (
        submitForm: () => void,
        submitting: boolean
    ) => (
        <IconButtonWithTooltip
            title={TranslationHelper.translate('Save')}
            onClick={submitForm}
            disabled={submitting}
        >
            <SaveIcon />
        </IconButtonWithTooltip>
    );
    return (
        <Formik
            enableReinitialize={true}
            initialValues={BLANK_FORM_CLIENT}
            onSubmit={handleValues}
            validateOnBlur={false}
            validateOnChange={false}
            validationSchema={cscAddClientSchema()}
        >
            {({ errors, values, submitForm, isSubmitting, setFieldValue }) => (
                <>
                    <PaneHeader
                        onCloseClick={handleClose}
                        title={TranslationHelper.translate('Create client')}
                        renderRightCustomControls={() =>
                            getRightCustomControls(submitForm, isSubmitting)
                        }
                    />

                    <ClientForm
                        values={values}
                        errors={errors}
                        setFieldValue={setFieldValue}
                    />
                </>
            )}
        </Formik>
    );
};

export default ClientAddForm;
