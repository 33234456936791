import React from 'react';

import { Connectable, TConnectableProps } from './Connectable.hoc';

import View from '../../components/View';
import Translator from '../../helpers/TranslationHelper';
import RegistersTab from './RegistersTab';

import {
    Employees,
    FuelTanks,
    RFIDCards,
    Vehicles,
} from '../../components/registers';

const EMPLOYEES = 'employees_tab';
const VEHICLES = 'vehicles_tab';
const CARDS = 'rfids_tab';
const TANKS = 'fuel_tanks_tab';

type TProps = TConnectableProps;

class RegistersPage extends React.Component<TProps> {
    public state = {
        activeTab: this.getInitTab(),
    };

    public getVisibleTabs() {
        const tabs = [];
        if (this.props.hasAccessToVehicles) {
            tabs.push(VEHICLES);
        }
        if (this.props.hasAccessToEmployees) {
            tabs.push(EMPLOYEES);
        }
        if (this.props.hasAccessToCards) {
            tabs.push(CARDS);
        }
        if (this.props.hasAccessToTanks) {
            tabs.push(TANKS);
        }
        return tabs;
    }

    public getInitTab() {
        const tabs = this.getVisibleTabs();
        return tabs.length > 0 ? tabs[0] : null;
    }

    public handleTabClick = (tab: string) => {
        this.setState({ activeTab: tab });
    };

    public _renderTabs() {
        const tabs = this.getVisibleTabs();
        return tabs.map((tab, index) => {
            const buttonClass = tab === this.state.activeTab ? 'selected' : '';
            return (
                <RegistersTab
                    key={index}
                    id={index}
                    buttonClass={buttonClass}
                    handleClick={this.handleTabClick}
                    tabName={tab}
                />
            );
        });
    }

    public _renderTabContent() {
        let content;
        switch (this.state.activeTab) {
            case VEHICLES: {
                content = this.props.hasAccessToVehicles && <Vehicles />;
                break;
            }
            case EMPLOYEES: {
                content = this.props.hasAccessToEmployees && <Employees />;
                break;
            }
            case CARDS: {
                content = this.props.hasAccessToCards && <RFIDCards />;
                break;
            }
            case TANKS: {
                content = this.props.hasAccessToTanks && <FuelTanks />;
                break;
            }
            default: {
                content = null;
                break;
            }
        }
        return content;
    }

    public render() {
        return (
            <View title={Translator.translate('Registers')}>
                <div
                    className="page-content--main-component"
                    id="registersPageContent"
                >
                    <div className="page-block">
                        <div className="tabs">
                            <ul className="tabs-header">
                                {this._renderTabs()}
                            </ul>
                        </div>

                        <div className="registers-ui">
                            {this._renderTabContent()}
                        </div>
                    </div>
                </div>
            </View>
        );
    }
}

export default Connectable(RegistersPage);
