import React, { useEffect } from 'react';

import { LinearProgress } from '@material-ui/core';
import { Field, FormikErrors, FormikValues } from 'formik';

import TranslationHelper from '../../../../helpers/TranslationHelper';
import { useAppDispatch } from '../../../../hooks';

import { IEventAlertCondition } from '../../../../state/ui/forms';
import { fetchEventTypes } from '../../../../state/app/alerts';
import { useTranslatedEventTypes } from '../../../../state/app/alerts/index.hooks';

import { mapDictionaryItems } from '../../_utils/utils';
import { useStyles } from '../FormsThemable.hooks';
import FieldWrapper from '../../../../components/common/formikWrappers/FieldWrapper';

interface IOwnProps {
    errors?: FormikErrors<IEventAlertCondition>;
    values: FormikValues;
    mode: string;
    name: string;
}

const EventAlertDefinitionForm = ({ name, errors, mode }: IOwnProps) => {
    const classes = useStyles({});

    const inEditMode = mode === 'edit';

    const events = useTranslatedEventTypes();
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (!events.length) {
            dispatch(fetchEventTypes(true));
        }
    }, []);

    const eventIdErrors = errors?.eventType?.id;
    return (
        <>
            {
                <Field
                    className={classes.fieldLg}
                    error={!!eventIdErrors}
                    helperText={eventIdErrors}
                    disabled={inEditMode}
                    name={`${name}.eventType.id`}
                    label={TranslationHelper.translate(
                        'Trigger alert on event'
                    )}
                    select={true}
                    fullWidth={true}
                    component={FieldWrapper}
                    required={true}
                    variant="outlined"
                >
                    {mapDictionaryItems(events, undefined, 'id')}
                </Field>
            }
            {!events && <LinearProgress />}
        </>
    );
};

export default EventAlertDefinitionForm;
