import React from 'react';

import { Box, Button } from '@mui/material';

import TranslationHelper from '../../helpers/TranslationHelper';
import {
    useGridViews,
    useSelectedGridViewModified,
    useSelectedViewName,
} from '../../state/ui/gridViews/index.hooks';
import { useIsAnalyticsModeEnabled } from '../../pages/discovery/selectors/index.hooks';
import { hasAccessToView } from './access';
import { usePrivileges } from '../../state/auth/index.hooks';

interface IOwnProps {
    viewId: string;
    handleSaveView: () => void;
    handleResetView: () => void;
    handleUpdateView: (id: string) => void;
}
const GridViewOptions = ({
    handleSaveView,
    handleResetView,
    handleUpdateView,
    viewId,
}: IOwnProps) => {
    const viewName = useSelectedViewName();
    const selectedGridViewModified = useSelectedGridViewModified();
    const isAnalyticsEnabled = useIsAnalyticsModeEnabled();
    const views = useGridViews();
    const view = views?.find((v) => v.id === viewId);
    const privileges = usePrivileges();
    const hasAccess = (view && hasAccessToView(view, privileges)) || false;

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                justifyContent: 'flex-start',
                maxWidth: 400,
            }}
        >
            {!isAnalyticsEnabled && (
                <>
                    <Button onClick={handleSaveView}>
                        {TranslationHelper.translate('Save view')}
                    </Button>
                    {selectedGridViewModified && hasAccess && (
                        <Button onClick={() => handleUpdateView(viewId)}>
                            <Box
                                sx={{
                                    maxWidth: 300,
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    textWrap: 'nowrap',
                                }}
                            >
                                {`${TranslationHelper.translate(
                                    'Update view'
                                )} ${viewName}`}
                            </Box>
                        </Button>
                    )}
                </>
            )}
            <Button onClick={handleResetView}>
                {TranslationHelper.translate('Restore default view')}
            </Button>
        </Box>
    );
};

export default GridViewOptions;
