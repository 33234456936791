import React from 'react';

import moment, { Moment } from 'moment';

import { Box, Typography } from '@material-ui/core';
import { MobileDateTimePicker } from '@mui/x-date-pickers';
import { DatePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

import { useStyles } from '../../pages/reports/Themable.hooks';

import { useUserSettings } from '../../state/user/index.hooks';

import TranslationHelper from '../../helpers/TranslationHelper';

import TimeRangeButtons from '../TimeRangeButtons';
import { IErrors } from '../../pages/reports/components/GeneratedReports/components/ReportsForm/ReportsForm';

interface IOwnProps {
    dateFrom: Moment;
    dateTo: Moment;
    errors?: IErrors;
    title?: string;
    showButtons?: boolean;
    dateOnly?: boolean;
    disablePast?: boolean;
    handleFormChange: <T>(name: string, value: T) => void;
}
const DatePresets = ({
    dateFrom,
    dateTo,
    errors,
    title = 'Date preset',
    showButtons = true,
    disablePast = false,
    dateOnly,
    handleFormChange,
}: IOwnProps) => {
    const classes = useStyles();
    const userSettings = useUserSettings();

    const dateTranslations = TranslationHelper.makeDateTimeTranslations();

    const buttons = [
        {
            onClick: () => handleChangeDate(moment(), moment()),
            text: 'Today',
        },
        {
            onClick: () =>
                handleChangeDate(
                    moment().subtract(1, 'day'),
                    moment().subtract(1, 'day')
                ),
            text: 'Yesterday',
        },
        {
            onClick: () =>
                handleChangeDate(
                    moment().subtract(1, 'week'),
                    moment().subtract(1, 'day')
                ),
            text: 'Last week',
        },
    ];

    const handleChangeDate = (dateFrom: Moment, dateTo: Moment) => {
        handleChangeFromDate(dateFrom.clone().startOf('day'));
        handleChangeToDate(dateTo.clone().endOf('day'));
    };

    const handleChangeFromDate = (date: MaterialUiPickersDate) => {
        handleFormChange('dateFrom', date);
    };

    const handleChangeToDate = (date: MaterialUiPickersDate) => {
        handleFormChange('dateTo', date);
    };

    const commonPickersProps = {
        fullWidth: true,
        required: true,
        className: classes.field,
        ...dateTranslations,
    };
    const renderDatePickers = () => {
        return (
            <>
                <DatePicker
                    error={!!errors?.dateFrom}
                    helperText={errors?.dateFrom}
                    name="dateFrom"
                    value={dateFrom}
                    maxDate={dateTo || moment()}
                    onChange={handleChangeFromDate}
                    format={userSettings.shortDateFormat}
                    label={TranslationHelper.translate('From')}
                    autoOk={true}
                    variant="inline"
                    disablePast={disablePast}
                    {...commonPickersProps}
                />
                <DatePicker
                    error={!!errors?.dateTo}
                    helperText={errors?.dateTo}
                    name="dateTo"
                    value={dateTo}
                    minDate={dateFrom}
                    onChange={handleChangeToDate}
                    format={userSettings.shortDateFormat}
                    label={TranslationHelper.translate('To')}
                    autoOk={true}
                    variant="inline"
                    {...commonPickersProps}
                />
            </>
        );
    };
    const renderDateTimePickers = () => {
        return (
            <Box
                sx={{ marginTop: 10, display: 'flex', flexDirection: 'column' }}
            >
                <MobileDateTimePicker
                    views={[
                        'year',
                        'month',
                        'day',
                        'hours',
                        'minutes',
                        'seconds',
                    ]}
                    name="dateFrom"
                    value={dateFrom}
                    maxDate={dateTo || moment()}
                    onChange={handleChangeFromDate}
                    label={TranslationHelper.translate('From')}
                    format={userSettings.dateFormat}
                    ampm={false}
                    localeText={dateTranslations}
                    slots={{}}
                    slotProps={{
                        textField: {
                            fullWidth: true,
                            variant: 'standard',
                            error: !!errors?.dateFrom,
                            helperText: errors?.dateFrom,
                        },
                    }}
                />

                <MobileDateTimePicker
                    views={[
                        'year',
                        'month',
                        'day',
                        'hours',
                        'minutes',
                        'seconds',
                    ]}
                    name="dateTo"
                    value={dateTo}
                    maxDate={moment()}
                    minDate={dateFrom}
                    onChange={handleChangeToDate}
                    localeText={dateTranslations}
                    label={TranslationHelper.translate('To')}
                    format={userSettings.dateFormat}
                    ampm={false}
                    slotProps={{
                        textField: {
                            fullWidth: true,
                            variant: 'standard',
                            error: !!errors?.dateTo,
                            helperText: errors?.dateTo,
                        },
                    }}
                />
            </Box>
        );
    };
    return (
        <>
            <Typography color={'primary'} className={classes.typography}>
                {TranslationHelper.translate(title)}
            </Typography>
            {showButtons && <TimeRangeButtons buttons={buttons} />}
            {dateOnly ? renderDatePickers() : renderDateTimePickers()}
        </>
    );
};

export default DatePresets;
