import {
    createSlice,
    createSelector,
    createAsyncThunk,
} from '@reduxjs/toolkit';

import { ISourceSet, TStatus } from '../../../state/types';

import { TRootState } from '../../../store';
import {
    restDeleteAttribute,
    restGetAttribute,
    restGetAttributes,
    restPostAttribute,
    restPutAttribute,
} from '../../../services/registers/attributes';
import { getSourceSetModels } from '../../../pages/discovery/selectors';

import { SYSTEM_OBJECTS } from '../../../constants/dictionaries/SystemObjects';
import { ATTRIBUTE_TYPES } from '../../../constants/dictionaries/AttributeTypes';

import TranslationHelper from '../../../helpers/TranslationHelper';

export type TAttributeValue = string | number | boolean;

export interface IConnectedObject {
    type: number;
    defaultValue?: TAttributeValue;
    internal?: boolean;
    multipleValue: boolean;
    required: boolean;
    selected?: boolean;
}

export interface IConnectedObjectWithId extends IConnectedObject {
    id: string | number;
    name: string;
}

export interface IAttributeDefinition {
    label: string;
    name: string;
    type: number;
    internal?: boolean;
    lockedToDictionary?: boolean;
    regex?: string;
}
export interface IAttribute {
    connectedTo: IConnectedObject[];
    definition: IAttributeDefinition;
    id: number;
    dictionary?: { internal: boolean; value: TAttributeValue }[];
}
export type TPostServiceAttribute = Omit<IAttribute, 'id' | 'dictionary'> & {
    dictionary?: TAttributeValue[];
};

export type TPutServiceAttribute = Omit<
    IAttribute,
    'id' | 'dictionary' | 'definition'
> & {
    dictionary?: TAttributeValue[];
    definition?: Omit<IAttributeDefinition, 'type'>;
};

interface IState {
    attributes: IAttribute[] | null;
    selectedAttributeId: string | null;
    status: TStatus;
    mode: null | 'preview' | 'edit' | 'create';
    attribute: IAttribute | null;
}

export const initialState: IState = {
    attributes: null,
    mode: null,
    selectedAttributeId: null,
    attribute: null,
    status: 'idle',
};

export const fetchAttributes = createAsyncThunk(
    'get:ui/attributes',
    async () => {
        const response = await restGetAttributes();

        return response;
    }
);

export const fetchAttribute = createAsyncThunk(
    'get:ui/attributes/:id',
    async (id: string) => {
        const response = await restGetAttribute(id);

        return response;
    }
);
export const createAttribute = createAsyncThunk(
    'post:ui/attributes',
    async (attribute: TPostServiceAttribute, { rejectWithValue, dispatch }) => {
        try {
            const response = await restPostAttribute(attribute);
            await dispatch(fetchAttributes());
            return response;
        } catch (e: any) {
            throw rejectWithValue({
                status: e.status,
                errorMsg: e.responseJSON.message,
            });
        }
    }
);

export const updateAttribute = createAsyncThunk(
    'put:ui/attributes',
    async (
        { attribute, id }: { attribute: TPutServiceAttribute; id: string },
        { rejectWithValue, dispatch }
    ) => {
        try {
            const response = await restPutAttribute(attribute, id);
            await dispatch(fetchAttributes());
            return response;
        } catch (e: any) {
            throw rejectWithValue({
                status: e.status,
                errorMsg: e.responseJSON.message,
            });
        }
    }
);

export const deleteAttribute = createAsyncThunk(
    'delete:ui/attributes/id',
    async (payload: { id: string }, { dispatch }) => {
        const response = await restDeleteAttribute(payload.id);
        await dispatch(fetchAttributes());
        return response;
    }
);

const attributes = createSlice({
    name: 'attributes',
    initialState,
    reducers: {
        selectAttributeId(state, action) {
            state.selectedAttributeId = action.payload;
        },
        setStatus(state, action) {
            state.status = action.payload;
        },
        resetAttributeState(state) {
            state.attribute = null;
            state.selectedAttributeId = null;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchAttributes.pending, (state) => {
            state.status = 'loading';
        });
        builder.addCase(fetchAttributes.rejected, (state) => {
            state.status = 'error';
        });
        builder.addCase(fetchAttributes.fulfilled, (state, action) => {
            state.status = 'done';
            state.attributes = action.payload;
        });
        builder.addCase(fetchAttribute.pending, (state) => {
            state.attribute = null;
            state.status = 'loading';
        });
        builder.addCase(fetchAttribute.rejected, (state) => {
            state.status = 'error';
        });
        builder.addCase(fetchAttribute.fulfilled, (state, action) => {
            state.status = 'done';
            state.attribute = action.payload;
        });
    },
});

const getCurrent = (state: TRootState) => state.ui.attributes as IState;
export const getAttributesMode = (state: TRootState) =>
    state.ui.attributes.mode;

export const getAttributes = (state: TRootState) =>
    getCurrent(state).attributes;
export const getSelectedAttributeId = (state: TRootState) =>
    getCurrent(state).selectedAttributeId;

export const getAttribute = (state: TRootState) => getCurrent(state).attribute;
export const getAttributesStatus = (state: TRootState) =>
    getCurrent(state).status;

export const getAttributesSourceSetModel = createSelector(
    [getSourceSetModels],
    (models) => models.attributes || null
);
export const getAttributesAsSourceSet = createSelector(
    [getAttributes],
    (attributes): ISourceSet | null =>
        attributes && {
            id: 'attributes',
            definitionId: 'Attributes',
            label: 'Attributes',
            attributes: [
                {
                    id: 'name',
                    label: 'Name',
                    type: 'text',
                },
                {
                    id: 'label',
                    label: 'attribute.label',
                    type: 'text',
                    translate: 'wx',
                },
                {
                    id: 'type',
                    label: 'Type',
                    type: 'text',
                    translate: 'wx',
                },
                {
                    id: 'objects',
                    label: 'Objects',
                    type: 'tooltipList',
                },
                {
                    id: 'regex',
                    label: 'Regex',
                    type: 'text',
                },
                {
                    id: 'actions',
                    label: 'Actions',
                    type: 'actions',
                },
            ],
            layersAttributes: [],
            _meta: {
                actions: {
                    delete: {
                        label: 'Delete',
                        method: 'DELETE',
                        api: '/rest/api/registers/attributes/settings/{id}',
                        params: {},
                    },
                },
            },

            entities: attributes.map((attribute: IAttribute): any => ({
                ...attribute,
                actions: ['edit', 'delete'],
                id: attribute.id.toString(),
                objects: attribute.connectedTo.map((object) =>
                    TranslationHelper.translate(
                        SYSTEM_OBJECTS[
                            object.type as keyof typeof SYSTEM_OBJECTS
                        ]
                    )
                ),
                name: attribute.definition.name,
                label: attribute.definition.label,
                type: ATTRIBUTE_TYPES[
                    attribute.definition.type as keyof typeof ATTRIBUTE_TYPES
                ],
                regex: attribute.definition.regex,

                _meta: {
                    actions: {
                        delete: attribute.definition.internal
                            ? null
                            : {
                                  params: {
                                      id: attribute.id,
                                  },
                              },
                        edit: {
                            params: {
                                id: attribute.id,
                            },
                        },
                    },
                },
            })),
        }
);

export const { selectAttributeId, resetAttributeState, setStatus } =
    attributes.actions;

export default attributes.reducer;
