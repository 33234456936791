import React from 'react';
import { useStyles } from './Themable.hooks';

import { Paper } from '@material-ui/core';
import DevicesMultipleEditionForm from './components/DevicesMultipleEditionForm';
import DeviceEditionForm from './components/DeviceEditionForm';
import DevicesPreview from './components/DevicesPreview';
import { useDevicesMode } from '../../../../../../state/ui/devices/index.hooks';

const DevicePane = () => {
    const classes = useStyles();

    const mode = useDevicesMode();
    const renderDevicesPane = () => {
        switch (mode) {
            case 'preview':
                return <DevicesPreview />;
            case 'editMultiple':
                return <DevicesMultipleEditionForm />;
            case 'edit':
                return <DeviceEditionForm />;
            default:
                return;
        }
    };

    return (
        <Paper className={classes.pane}>
            <div className={classes.wrapper}>{renderDevicesPane()}</div>
        </Paper>
    );
};

export default DevicePane;
