import React from 'react';

import { Field, FormikErrors, FormikValues } from 'formik';

import { useMapCoordinates } from './Connectable.hooks';
import { useStyles } from './Themable.hooks';

import TranslationHelper from '../../../../../../../../../../helpers/TranslationHelper';

import { useAppDispatch } from '../../../../../../../../../../hooks';

import { storeLastMapClickPosition } from '../../../../../../../../../../state/ui/discovery/general';
import { ICoordinates } from '../../../../../../../../../../state/types';

import { ILocationForm } from '../../LocationEdit';
import FieldWrapper from '../../../../../../../../../../components/common/formikWrappers/FieldWrapper';
import AlertCard from '../../../../../../../../../../components/alertCard';
import AddressSection from '../../../../../../../../../../components/AddressSection/AddressSection';
import VisibilityForm from '../../../../../../../../../../components/VisibilityForm';

interface IOwnProps {
    errors: FormikErrors<ILocationForm>;
    values: FormikValues;
    showAlert: boolean;
    setFieldValue: (field: string, value: any) => void;
}

const LocationForm = ({
    errors,
    values,
    showAlert,
    setFieldValue,
}: IOwnProps) => {
    const classes = useStyles();
    const dispatch = useAppDispatch();

    const mapCoordinates = useMapCoordinates();

    const handleStoreClickPosition = (coords: ICoordinates) => {
        dispatch(storeLastMapClickPosition(coords.x, coords.y));
    };
    return (
        <div className={classes.formWrapper}>
            {showAlert && (
                <AlertCard
                    message={
                        'Changes to the POI list will only affect the data received after their execution.'
                    }
                    type="info"
                />
            )}

            {mapCoordinates === null && (
                <p>
                    {TranslationHelper.translate(
                        'Click on map to set this location coordinates'
                    )}
                </p>
            )}

            <Field
                className={classes.field}
                error={!!errors?.name}
                helperText={errors?.name}
                name={'name'}
                label={TranslationHelper.translate('Name')}
                fullWidth={true}
                required={true}
                component={FieldWrapper}
            />

            <AddressSection
                mapCoordinates={mapCoordinates}
                errors={errors}
                values={values}
                setFieldValue={setFieldValue}
                callback={handleStoreClickPosition}
            />
            <VisibilityForm
                values={values}
                setFieldValue={setFieldValue}
                errors={errors}
            />

            <Field
                className={classes.field}
                error={!!errors?.notes}
                helperText={errors?.notes}
                name={'notes'}
                label={TranslationHelper.translate('Notes')}
                fullWidth={true}
                component={FieldWrapper}
                multiline={true}
            />
        </div>
    );
};

export default LocationForm;
