import React from 'react';

import Paper from '@material-ui/core/Paper';

import { useStyles } from './Themable.hoc';

import { useIsGroupsPaneVisible } from '../../../../state/ui/groupsSlice/index.hooks';

import GroupsGridPane from './components/GroupsGridPane';
import GroupsPane from './components/GroupsPane';
import MessageHandler from './components/MessageHandler/MessageHandler';

const Groups = () => {
    const classes = useStyles();

    const groupsPaneVisible = useIsGroupsPaneVisible();

    const gridPaneClass = groupsPaneVisible
        ? classes.visibleGridPane
        : classes.hiddenGridPane;

    return (
        <div className={classes.pageWrapper}>
            <MessageHandler />

            <div className={gridPaneClass}>
                <GroupsGridPane />
            </div>

            {groupsPaneVisible && (
                <Paper className={classes.paneWrapper}>
                    <GroupsPane />
                </Paper>
            )}
        </div>
    );
};

export default Groups;
