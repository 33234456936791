import React, { useState } from 'react';

import { Box, Dialog, DialogContent, Input, Slider } from '@mui/material';

import useToggle from '../../../../hooks/useToggle';
import { useAppDispatch } from '../../../../hooks';
import TranslationHelper from '../../../../helpers/TranslationHelper';

import { hideGlobalDialog } from '../../../../state/ui/dialog';

import DialogTitle from '../../../dialogs/DialogTitle';
import { SelectLabelsModels } from '../../../../pages/discovery/components/Report/components/ReportListItem/components/PhotoOverlay/components/SelectLabelsModels';
import { useStyles } from './Themable.hooks';
import {
    CLASS_THRESRHOLD_NOT_SELECTED,
    LABELS_MODEL_NOT_SELECTED,
    OBJECT_THRESRHOLD_NOT_SELECTED,
    SLIDER_INPUT_MAX_LENGTH,
    SLIDER_MARKS,
    SLIDER_MAX,
    SLIDER_MIN,
    SLIDER_STEP,
} from '../../../../constants/videoPlayerSettings';
import { IPlayerSettings } from '../../../../state/ui/discoverySlice';

interface IOwnProps {
    handleChange: any;
    playerSettings: IPlayerSettings;
}

export const PlayerSettingsDialog = ({
    handleChange,
    playerSettings,
}: IOwnProps) => {
    const dispatch = useAppDispatch();
    const classes = useStyles();
    const { isOpen, handleToggle } = useToggle(true);

    const [settings, setSettings] = useState<IPlayerSettings>({
        labels: !!playerSettings.model,
        model: playerSettings.model ?? LABELS_MODEL_NOT_SELECTED,
        classThreshold:
            playerSettings.classThreshold ?? CLASS_THRESRHOLD_NOT_SELECTED,
        objectThreshold:
            playerSettings.objectThreshold ?? OBJECT_THRESRHOLD_NOT_SELECTED,
    });

    const handleClose = (event?: object, reason?: string) => {
        if (reason === 'backdropClick') {
            return;
        }
        dispatch(hideGlobalDialog());
        handleToggle();
    };

    const selectedModelOnChange = (e: any) => {
        setSettings({ ...settings, model: e.target.value });
    };
    const checkAndGetInput = (num: any) => {
        if (num > SLIDER_MAX) {
            num = SLIDER_MAX;
        } else if (num < SLIDER_MIN) {
            num = SLIDER_MIN;
        }
        if (num.length > SLIDER_INPUT_MAX_LENGTH) {
            num = num.substr(0, SLIDER_INPUT_MAX_LENGTH);
        }
        return num;
    };
    const selectedClassThreshold = (e: any) => {
        setSettings({
            ...settings,
            classThreshold: checkAndGetInput(e.target.value),
        });
    };
    const selectedObjectThreshold = (e: any) => {
        setSettings({
            ...settings,
            objectThreshold: checkAndGetInput(e.target.value),
        });
    };

    const handleSubmit = () => {
        handleChange(settings);
        handleClose();
    };

    const dialogContent = () => {
        return (
            <Box sx={{ minWidth: '400px' }}>
                <div>
                    <div style={{ marginTop: '20px' }}>
                        <div className={classes.label}>
                            {TranslationHelper.translate('Labels')}
                        </div>
                        <SelectLabelsModels
                            key={settings.model}
                            handleChange={selectedModelOnChange}
                            selectedModel={settings.model}
                        />
                    </div>

                    <div style={{ marginTop: '20px' }}>
                        <div className={classes.label}>
                            {TranslationHelper.translate('Class threshold')}
                        </div>
                        <div className={classes.sliderGroup}>
                            <Slider
                                classes={{
                                    root: classes.slider,
                                }}
                                step={SLIDER_STEP}
                                min={SLIDER_MIN}
                                max={SLIDER_MAX}
                                value={settings.classThreshold}
                                onChange={selectedClassThreshold}
                                marks={SLIDER_MARKS}
                            />
                            <Input
                                disableUnderline={true}
                                inputProps={{
                                    maxLength: SLIDER_INPUT_MAX_LENGTH,
                                    step: SLIDER_STEP,
                                    min: SLIDER_MIN,
                                    max: SLIDER_MAX,
                                    type: 'number',
                                }}
                                className={classes.input}
                                value={settings.classThreshold}
                                onChange={selectedClassThreshold}
                            />
                        </div>
                    </div>
                    <div style={{ marginTop: '20px' }}>
                        <div className={classes.label}>
                            {TranslationHelper.translate('Object threshold')}
                        </div>
                        <div className={classes.sliderGroup}>
                            <Slider
                                classes={{
                                    root: classes.slider,
                                }}
                                step={SLIDER_STEP}
                                min={SLIDER_MIN}
                                max={SLIDER_MAX}
                                value={settings.objectThreshold}
                                onChange={selectedObjectThreshold}
                                marks={SLIDER_MARKS}
                            />
                            <Input
                                disableUnderline={true}
                                inputProps={{
                                    maxLength: SLIDER_INPUT_MAX_LENGTH,
                                    step: SLIDER_STEP,
                                    min: SLIDER_MIN,
                                    max: SLIDER_MAX,
                                    type: 'number',
                                }}
                                className={classes.input}
                                value={settings.objectThreshold}
                                onChange={selectedObjectThreshold}
                            />
                        </div>
                    </div>
                </div>
            </Box>
        );
    };
    return (
        <>
            <Dialog
                open={isOpen}
                onClose={handleClose}
                maxWidth={'lg'}
                disableEscapeKeyDown
            >
                <DialogTitle
                    title={TranslationHelper.translate('Player settings')}
                    close={handleClose}
                    save={handleSubmit}
                />

                <DialogContent style={{ maxWidth: 800 }}>
                    {dialogContent()}
                </DialogContent>
            </Dialog>
        </>
    );
};

export default PlayerSettingsDialog;
