import { connect } from 'react-redux';
import {
    activateGrid,
    fetchLastStates,
    fetchLocations,
    fetchRoutes,
    fetchTasks,
    nextSnapshot,
    setNotAssignedFilter,
    setNotPlannedFilter,
    setIncludeNotPlanned,
    setSourceSetDates,
    stopFetchingCollection,
    toggleDataGridPane,
    fetchSourceSet,
    notifyRestorerToContinue,
    notifyRestorerToWait,
    queueMapFitToExtent,
    resetLevel,
} from '../../state/_actions';
import { TRootState } from '../../store';
import {
    areSearchResultsVisible,
    getCalculatedMobileUIState,
    getDiscoveryBottomGridPane,
    getDiscoveryUIPreviewPane,
    getGridCreatorLevel,
    getGridFilters,
    getGridSourceSetAction,
    getIsStateRestoring,
    getPreviewPaneObjectId,
    getShouldRestorerWait,
    getTimeLock,
    isAnalyticsModeEnabled,
    isDiscoverUIDataGridPaneVisible,
    isDiscoverUIGridFullscreen,
    isDiscoveryUICalibrationPaneVisible,
    isDiscoveryUIChartPaneVisible,
    isDiscoveryUIDetectionPaneVisible,
    isMobileGridVisible,
    shouldDiscoveryBeInEditMode,
} from './selectors';
import { getModeledGridSourceSet } from './selectors/composed';
import { getPrivileges } from '../../state/auth';

const mapStateToProps = (state: TRootState) => ({
    isDataGridPaneVisible: isDiscoverUIDataGridPaneVisible(state),
    isChartPaneVisible: isDiscoveryUIChartPaneVisible(state),
    isCalibrationChartPaneVisible: isDiscoveryUICalibrationPaneVisible(state),
    isDetectionChartPaneVisible: isDiscoveryUIDetectionPaneVisible(state),
    isPreviewPaneVisible: getDiscoveryUIPreviewPane(state) !== null,
    isDataGridFullscreen: isDiscoverUIGridFullscreen(state),
    isAnalyticsEnabled: isAnalyticsModeEnabled(state),
    gridSnapshot: getDiscoveryBottomGridPane(state),
    sourceSet: getModeledGridSourceSet(state),
    privileges: getPrivileges(state),
    creatorLevel: getGridCreatorLevel(state),
    timeLocked: getTimeLock(state),
    selectedSourceSetElementId: getPreviewPaneObjectId(state),
    isStateRestoring: getIsStateRestoring(state),
    shouldRestorerWait: getShouldRestorerWait(state),
    areSearchResultsVisible: areSearchResultsVisible(state),
    mobileUIState: getCalculatedMobileUIState(state),
    isMobileGridVisible: isMobileGridVisible(state),
    editMode: shouldDiscoveryBeInEditMode(state),
    sourceSetAction: getGridSourceSetAction(state),
    gridFilters: getGridFilters(state),
});

const mapDispatchToProps = {
    activateGrid,
    fetchLastStates,
    toggleDataGridPane,
    fetchLocations,
    fetchRoutes,
    fetchTasks,
    setSourceSetDates,
    setNotAssignedFilter,
    setNotPlannedFilter,
    setIncludeNotPlanned,
    stopFetchingCollection,
    nextSnapshot,
    resetLevel,
    fetchSourceSet,
    queueMapFitToExtent,
    notifyRestorerToWait,
    notifyRestorerToContinue,
};

type TStateProps = ReturnType<typeof mapStateToProps>;
type TDispatchProps = typeof mapDispatchToProps;

export type TConnectableProps = TStateProps & TDispatchProps;

export const Connectable = connect(mapStateToProps, mapDispatchToProps);
