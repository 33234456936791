import { RowNode } from 'ag-grid-community';
import { Geometry } from 'ol/geom';
import VectorSource from 'ol/source/Vector';
import { FEATURE_SELECTED } from './components/PlanningRoutesMap/utils/handleSelect';
import TranslationHelper from '../../../app/helpers/TranslationHelper';

export const countNodesByRouteName = (
    nodes: RowNode[]
): { tasksList: { routeName: string; count: number }[]; ids: number[] } => {
    const countsArray = nodes.reduce((acc, node) => {
        const routeName =
            node.data.routeName || TranslationHelper.translate('Unassigned');
        const existingEntry = acc.find(
            (entry) => entry.routeName === routeName
        );

        if (existingEntry) {
            existingEntry.count += 1;
        } else {
            acc.push({ routeName, count: 1 });
        }

        return acc;
    }, [] as { routeName: string; count: number }[]);

    const ids = nodes.map((node) => node.data.id);

    return { tasksList: countsArray, ids };
};

export const handleSelectFeaturesOnMap = (
    source: VectorSource<Geometry>,
    items: string[]
) => {
    const itemSet = new Set(items);

    const features = source.getFeatures();
    features.forEach((feature) => {
        const data = feature?.get('data');
        const id = data?.id;

        // Unset 'selected' for all features first
        feature.unset('selected');

        // Set 'selected' if the feature's id is in the itemSet
        if (id && itemSet.has(id)) {
            feature.set('selected', FEATURE_SELECTED);
        }
    });
};
