import { useSelector } from 'react-redux';
import {
    getDataGridObjectName,
    getDataSegmentsTrails,
    getDiscoveryMapSourceSet,
    getFrameElementDate,
    getFrameElementMonitoredId,
    getFramesMoviesIds,
    getGridSourceSetEntitiesIds,
    getModeledGridSourceSet,
    getPreviewLastState,
    getPreviewLocation,
    getPreviewSourceSetElement,
    getPreviewTask,
    getSourceSetElementDate,
    getSourceSetElementLocationActions,
    getSourceSetElementMonitoredId,
    getSourceSetElementStartDate,
    getSourceSetMovieFramesIds,
    getTrail,
} from '.';
import { TRootState } from 'src/app/store';

export const useGridSourceSet = () => useSelector(getModeledGridSourceSet);
export const useSourceSet = () => useSelector(getDiscoveryMapSourceSet);
export const useSelectedMonitoredObjectTrail = () => useSelector(getTrail);
export const useDataSegmentsTrails = () => useSelector(getDataSegmentsTrails);
export const useSourceSetEntitiesIds = () =>
    useSelector(getGridSourceSetEntitiesIds);
export const useSourceSetElementMonitoredId = () =>
    useSelector(getSourceSetElementMonitoredId);
export const useSourceSetElementDate = () =>
    useSelector(getSourceSetElementDate);
export const useGridObjectName = () => useSelector(getDataGridObjectName);
export const useLastState = () => useSelector(getPreviewLastState);
export const useTask = () => useSelector(getPreviewTask);
export const usePreviewSourceSetElement = () =>
    useSelector(getPreviewSourceSetElement);
export const useSourceSetMovieFramesIds = (id: string) =>
    useSelector((state: TRootState) => getSourceSetMovieFramesIds(state, id));
export const useLocation = () => useSelector(getPreviewLocation);
export const useSourceSetElementStartDate = () =>
    useSelector(getSourceSetElementStartDate);

export const useFrameElementMonitoredId = () =>
    useSelector(getFrameElementMonitoredId);
export const useFrameElementDate = () => useSelector(getFrameElementDate);
export const useFramesMoviesIds = () => useSelector(getFramesMoviesIds);
export const useSourceSetElementLocationActions = () =>
    useSelector(getSourceSetElementLocationActions);
