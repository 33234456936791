import { useSelector } from 'react-redux';
import {
    isGroupsPaneVisible,
    getGroupsMode,
    getAssignments,
    getEmptyAssignments,
    getEmptyAssignmentsLoading,
} from './index';

export const useIsGroupsPaneVisible = () => useSelector(isGroupsPaneVisible);
export const useGroupsMode = () => useSelector(getGroupsMode);
export const useGroupAssignments = () => useSelector(getAssignments);
export const useGroupEmptyAssignmentsLoading = () =>
    useSelector(getEmptyAssignmentsLoading);
export const useGroupEmptyAssignments = () => useSelector(getEmptyAssignments);
