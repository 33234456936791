import { EMPLOYEE, TANK, VEHICLE } from '../constants/dictionaries/ObjectType';

export const monitoredObjectTypeConverter = (type: number) => {
    switch (type) {
        case TANK:
            return 'TANK';
        case VEHICLE:
            return 'VEHICLE';
        case EMPLOYEE:
            return 'EMPLOYEE';
        default:
            return '';
    }
};
