import {
    IRestGetFuelTanksParams,
    restGetFuelTankEvents,
    restGetMonitoredFuelTanks,
} from '../../../services/fuelTanks';
import { makeReduxDuck } from 'teedux';

import { makeRequest } from '../sync';
import { TRootState } from 'src/app/store';
import { Moment } from 'moment';
import { IObject } from 'src/app/helpers/IconGenerator/IconGenerator';

const fuelTanksStorePath = `app/fuelTanks`;

interface IState {
    fuelTanks: IFuelTank[] | undefined;
    fuelTankEvents: IFuelTankDataItem[] | undefined;
    fuelTankOrdinaryEvents: IFuelTankDataItem[] | undefined;
}

interface IFuelTankDataItem {
    date: string;
    params: { [key: string]: any };
    state: { type: number };
    data: {
        vehicle?: string;
        employee?: string;
        fillLevel?: string;
        batteryLevel?: string;
    };
}

interface IFuelTank extends IObject {
    id: string;
    traits?: { binMonitor: boolean };
    updatedAt: Moment;
    data: {
        fillLevel: number;
        fuelLevel: number;
        capacity: number;
        logicType: number;
    };
}
export interface IFuelTanks {
    limit: number;
    number: number;
    start: number;
    total: number;
    values: IFuelTank[] | undefined;
}

export interface IFuelTanksEvents {
    id: number;
    values: IFuelTankDataItem[] | undefined;
}

const initialState: IState = {
    fuelTanks: undefined,
    fuelTankEvents: undefined,
    fuelTankOrdinaryEvents: undefined,
};

const duck = makeReduxDuck(fuelTanksStorePath, initialState);

const setFuelTanks = duck.defineAction<{
    data: IFuelTanks;
}>('SET_FUEL_TANKS_LIST', (state, { data }) => ({
    ...state,
    fuelTanks: data.values,
}));

const setFuelTanksEvents = duck.defineAction<{
    data: IFuelTanksEvents | { values: undefined };
}>('SET_FUEL_TANK_EVENTS', (state, { data }) => ({
    ...state,
    fuelTankEvents: data.values,
}));

const setFuelTanksOrdinaryEvents = duck.defineAction<{
    data: IFuelTanksEvents | { values: undefined };
}>('SET_FUEL_TANK_ORDINARY_EVENTS', (state, { data }) => ({
    ...state,
    fuelTankOrdinaryEvents: data.values,
}));

export default duck.getReducer();

export const fetchMonitoredFuelTanks = (
    afterSuccess?: (data: IFuelTanks) => void,
    afterFail?: (error: Error) => void
) =>
    makeRequest(
        `get:${fuelTanksStorePath}`,
        () => restGetMonitoredFuelTanks(),
        (dispatch, data) => {
            dispatch(setFuelTanks({ data }));
            if (afterSuccess) {
                afterSuccess(data);
            }
        },
        (dispatch, error) => {
            if (afterFail) {
                afterFail(error);
            }
        }
    );

export const fetchFuelTankEvents = (
    id: string,
    params: IRestGetFuelTanksParams,
    afterSuccess?: (data: IFuelTanksEvents) => void,
    afterFail?: (error: Error) => void
) =>
    makeRequest(
        `get:${fuelTanksStorePath}/events`,
        () => restGetFuelTankEvents(id, params),
        (dispatch, data) => {
            dispatch(setFuelTanksEvents({ data }));
            if (afterSuccess) {
                afterSuccess(data);
            }
        },
        (dispatch, error) => {
            if (afterFail) {
                afterFail(error);
            }
        },
        (dispatch) => {
            dispatch(setFuelTanksEvents({ data: { values: undefined } }));
        }
    );

export const fetchRefuelingsAndSupplies = (
    id: string,
    params: IRestGetFuelTanksParams,
    afterSuccess?: (data: IFuelTanksEvents) => void,
    afterFail?: (error: Error) => void
) =>
    makeRequest(
        `get:${fuelTanksStorePath}/events/refuelingsAndSupplies`,
        () => restGetFuelTankEvents(id, params),
        (dispatch, data) => {
            dispatch(setFuelTanksOrdinaryEvents({ data }));
            if (afterSuccess) {
                afterSuccess(data);
            }
        },
        (dispatch, error) => {
            if (afterFail) {
                afterFail(error);
            }
        },
        (dispatch) => {
            dispatch(
                setFuelTanksOrdinaryEvents({ data: { values: undefined } })
            );
        }
    );

const getFuelTanksStore = (state: TRootState) => state.app.fuelTanks;

export const getFuelTanks = (state: TRootState) =>
    getFuelTanksStore(state).fuelTanks;
export const getFuelTankEvents = (state: TRootState) =>
    getFuelTanksStore(state).fuelTankEvents;
export const getFuelTankOrdinaryEvents = (state: TRootState) =>
    getFuelTanksStore(state).fuelTankOrdinaryEvents;
