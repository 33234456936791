import React from 'react';

import { Formik, FormikActions, FormikValues } from 'formik';

import { useSnackbar } from 'notistack';

import SaveIcon from '@material-ui/icons/Save';

import { updateClient } from '../../../../../../../state/ui/customerService/clientsAndLocations';
import { useSelectedClient } from '../../../../../../../state/ui/customerService/clientsAndLocations/index.hooks';

import { IServiceClient } from '../../../../../../../models/customerService';

import { STATUS_ARCHIVAL } from '../../../../../../../constants/dictionaries/CscClientsStatus';

import { cscEditClientSchema } from '../../../../../../../schemas';

import { useToolkitDispatch } from '../../../../../../../hooks';
import TranslationHelper from '../../../../../../../helpers/TranslationHelper';
import { errorMessageHandler } from '../../../../../../../helpers/errorMessageHandler';

import PaneHeader from '../../../../../../../components/PaneHeader';
import ClientForm from '../ClientForm/ClientForm';
import { BLANK_FORM_CLIENT_EDIT } from '../blankForms';
import IconButtonWithTooltip from '../../../../../../../components/IconButtonWithTooltip';

interface IOwnProps {
    handleClose: () => void;
}
const ClientEditForm = ({ handleClose }: IOwnProps) => {
    const client = useSelectedClient();

    const toolkitDispatch = useToolkitDispatch();

    const { enqueueSnackbar } = useSnackbar();

    const initialValues = {
        ...BLANK_FORM_CLIENT_EDIT,
        ...client,
    };

    const showNotification = (success: boolean, message: string) => {
        enqueueSnackbar(message, {
            variant: success ? 'success' : 'error',
        });
    };

    const handleValues = (
        values: FormikValues,
        { setSubmitting }: FormikActions<IServiceClient>
    ) => {
        const updatedClient = { ...values } as IServiceClient;
        updatedClient.status = Number(values.status);
        if (updatedClient.status !== Number(STATUS_ARCHIVAL) && values.noDate) {
            updatedClient.archivalDate = undefined;
        }

        toolkitDispatch(updateClient(updatedClient))
            .unwrap()
            .then(() => {
                showNotification(
                    true,
                    TranslationHelper.translate('Client updated')
                );
                handleClose();
            })
            .catch((error) => {
                const message = errorMessageHandler(error.status, {
                    409: error.errorMsg,
                })();
                showNotification(false, message);
                setSubmitting(false);
            });
    };
    const getRightCustomControls = (
        submitForm: () => void,
        submitting: boolean
    ) => (
        <IconButtonWithTooltip
            title={TranslationHelper.translate('Save')}
            onClick={submitForm}
            disabled={submitting}
        >
            <SaveIcon />
        </IconButtonWithTooltip>
    );

    return (
        <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            onSubmit={handleValues}
            validateOnBlur={false}
            validateOnChange={false}
            validationSchema={cscEditClientSchema()}
        >
            {({ errors, values, submitForm, isSubmitting, setFieldValue }) => (
                <>
                    <PaneHeader
                        onCloseClick={handleClose}
                        title={TranslationHelper.translate('Edit client')}
                        renderRightCustomControls={() =>
                            getRightCustomControls(submitForm, isSubmitting)
                        }
                    />

                    <ClientForm
                        values={values}
                        errors={errors}
                        setFieldValue={setFieldValue}
                    />
                </>
            )}
        </Formik>
    );
};

export default ClientEditForm;
