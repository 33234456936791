import { create } from 'xmlbuilder2';
import {
    ISourceSet,
    ISourceSetEntity,
    TSourceSetAction,
} from '../../../../../state/types';
import TimeFormatter from './../../../../../helpers/TimeFormatter';

import gridValueGetter from '../../../../../components/SourceSetGrid/_utils/gridValueGetter';

export const exportAsGpx = (
    data: ISourceSet,
    entities: ISourceSetEntity[],
    meta: TSourceSetAction,
    fileName: string
): void => {
    const nameField: string = meta.params.nameField
        ? String(meta.params.nameField)
        : '';
    const dateField: string = meta.params.dateField
        ? String(meta.params.dateField)
        : '';

    const root = create({ version: '1.0', encoding: 'UTF-8' }).ele('gpx', {
        version: '1.1',
        'xmlns:xsi': 'http://www.w3.org/2001/XMLSchema-instance',
        xmlns: 'http://www.topografix.com/GPX/1/1',
        'xsi:schemaLocation':
            'http://www.topografix.com/GPX/1/1 http://www.topografix.com/GPX/1/1/gpx.xsd',
    });

    entities
        .filter((el) => !!el)
        .forEach((el) => {
            const hasDate = dateField ? !!el[dateField] : true;

            if (el._meta.coordinates && hasDate) {
                const item = root.ele('wpt', {
                    lat: el._meta.coordinates.y,
                    lon: el._meta.coordinates.x,
                });

                if (el[nameField]) {
                    const fieldAttribute = data.attributes.filter(
                        (attribute) => attribute.id === nameField
                    );
                    const value = gridValueGetter({
                        value: el[nameField],
                        ...fieldAttribute[0],
                    });

                    item.ele('name').txt(String(value || ''));
                }
                if (el[dateField]) {
                    item.ele('time').txt(
                        TimeFormatter.toISOString(el[dateField]) || ''
                    );
                }
            }
        });

    const gpx = root.end();

    downloadFile(String(gpx), fileName + '.gpx');
};

export const downloadFile = (content: string, fileName: string): void => {
    const blob = new Blob([content], { type: 'application/gpx+xml' });
    const exportUrl = URL.createObjectURL(blob);

    const link = document.createElement('a');
    document.body.appendChild(link);
    link.style.display = 'none';
    link.href = exportUrl;
    link.download = fileName;
    link.click();

    if (link.parentNode) {
        link.parentNode.removeChild(link);
    }
};
