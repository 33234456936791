import { Record } from 'immutable';

class RFIDLinkedObject extends Record({
    type: undefined,
    objectName: undefined,
    objectId: undefined,
}) {
    static fromJson(options) {
        return new RFIDLinkedObject(options);
    }

    toJson() {
        return this.toObject();
    }
}

export default RFIDLinkedObject;
