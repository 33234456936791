import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { monitoredObjectTypeConverter } from '../../../../helpers/monitoredObjectTypeConverter';
import {
    IGetMonitoredObjectsParams,
    restGetRegistersMonitoredObjects,
} from '../../../../services/registers';

import { TRootState } from '../../../../store';

export interface IRegistersMonitoredObject {
    id: number;
    type: number;
    name: string;
    objectId: number;
}

export interface IMonitoredObjectItem {
    relationId: string;
    registerId: number;
    registerType: string;
    type: string;
    name: string;
}

interface IState {
    monitoredObjects: IMonitoredObjectItem[];
    objectsLoading: boolean;
}
const initialState: IState = {
    monitoredObjects: [],
    objectsLoading: false,
};

export const fetchRegistersMonitoredObjects = createAsyncThunk(
    'get:app/registers/monitoredObjects',
    async (queryParams?: IGetMonitoredObjectsParams) => {
        const response = await restGetRegistersMonitoredObjects(queryParams);
        return response;
    }
);

const registersMonitoredObjects = createSlice({
    name: 'registersMonitoredObjects',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchRegistersMonitoredObjects.pending, (state) => {
            state.objectsLoading = true;
        });
        builder.addCase(
            fetchRegistersMonitoredObjects.fulfilled,
            (state, action) => {
                const monitoredObjects = [...action.payload];
                const assignedObjects = [...monitoredObjects].map((object) => {
                    const rightObject = {} as IMonitoredObjectItem;
                    rightObject.name = object.name;
                    rightObject.relationId = String(object.id);
                    rightObject.registerType = monitoredObjectTypeConverter(
                        object.type
                    );
                    rightObject.type = 'MONITORED_OBJECT';
                    rightObject.registerId = object.objectId;
                    return rightObject;
                });
                state.monitoredObjects = assignedObjects;
                state.objectsLoading = false;
            }
        );

        builder.addCase(fetchRegistersMonitoredObjects.rejected, (state) => {
            state.monitoredObjects = [];
            state.objectsLoading = false;
        });
    },
});

export const getMonitoredObjects = (state: TRootState) =>
    state.app.registers.monitoredObjects.monitoredObjects;

export const getMonitoredObjectsLoading = (state: TRootState) =>
    state.app.registers.monitoredObjects.objectsLoading;

export default registersMonitoredObjects.reducer;
