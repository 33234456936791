import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => {
    return {
        formButtons: {
            display: 'flex',
            margin: '10px 0',
            alignItems: 'center',
            justifyContent: 'space-between',
            '& a': {
                textDecoration: 'none',
            },
        },
        saveButton: {
            margin: `${theme.spacing(2)}px 0px`,
        },
    };
});
