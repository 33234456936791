import React, { useEffect } from 'react';

import { useSnackbar } from 'notistack';

import useToggle from '../../../../hooks/useToggle';
import { useAppDispatch, useToolkitDispatch } from '../../../../hooks';
import TranslationHelper from '../../../../helpers/TranslationHelper';
import { errorMessageHandler } from '../../../../helpers/errorMessageHandler';

import { hideGlobalDialog } from '../../../../state/ui/dialog';
import { useRegistersGroups } from '../../../../state/app/registers/index.hooks';
import { useUserDataPermissions } from '../../../../state/user/index.hooks';
import {
    IPostServiceGridView,
    createGridView,
    fetchGridView,
    updateGridView,
} from '../../../../state/ui/gridViews';
import {
    useCurrentGridConfiguration,
    useGridView,
} from '../../../../state/ui/gridViews/index.hooks';

import {
    DEFAULT_VISIBILITY_TYPE,
    prepareGroupIds,
    prepareGroups,
    prepareVisibilityType,
} from '../../../../components/VisibilityForm/VisibilityForm';
import { fetchRegistersGroups } from '../../../../state/app/registers/groups';
import GridViewForm from './GridViewForm';
import { IMappedMenuItem } from 'src/app/state/types';

interface IOwnProps {
    viewId?: string;
    sourceSet: string;
}

export interface IGridViewForm {
    name: string;
    visibilityType: string;
    groups: IMappedMenuItem[];
    description?: string;
}
const BLANK_FORM = {
    name: '',
    visibilityType: DEFAULT_VISIBILITY_TYPE,
    groups: [],
    description: '',
};

const GridViewDialog = ({ viewId, sourceSet }: IOwnProps) => {
    const dispatch = useAppDispatch();
    const toolkitDispatch = useToolkitDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const { isOpen, handleToggle } = useToggle(true);

    const groups = useRegistersGroups();
    const dataPermissions = useUserDataPermissions();
    const view = useGridView();
    const gridConfiguration = useCurrentGridConfiguration();

    useEffect(() => {
        if (!groups) {
            toolkitDispatch(fetchRegistersGroups({}));
        }
    }, [groups, toolkitDispatch]);
    useEffect(() => {
        if (viewId) {
            toolkitDispatch(fetchGridView(viewId))
                .unwrap()
                .catch((error) => {
                    const message = errorMessageHandler(error.status)();
                    enqueueSnackbar(message, {
                        variant: 'error',
                    });
                    handleClose();
                });
        }
    }, [viewId, toolkitDispatch]);

    const title = viewId ? 'Edit view' : 'Create view';

    const initialValues =
        viewId && view
            ? {
                  name: view.name,
                  visibilityType: prepareVisibilityType(
                      groups,
                      view.groupIds,
                      dataPermissions
                  ),

                  groups: prepareGroups(groups, view.groupIds),
                  description: view.description,
              }
            : BLANK_FORM;

    const showNotification = (success: boolean, message: string) => {
        enqueueSnackbar(message, {
            variant: success ? 'success' : 'error',
        });
    };

    const handleClose = (event?: object, reason?: string) => {
        if (reason === 'backdropClick') {
            return;
        }
        dispatch(hideGlobalDialog());

        handleToggle();
    };

    const handleSubmit = (values: IGridViewForm, resetForm: () => void) => {
        resetForm();

        const viewObject = {
            name: values.name,
            description: values.description,
            configuration: gridConfiguration,
            groupIds: prepareGroupIds(values, dataPermissions, view?.groupIds),
            sourceSet: sourceSet,
        } as IPostServiceGridView;

        const action = viewId
            ? {
                  request: updateGridView({
                      view: { ...viewObject, id: viewId, sourceSet: undefined },
                      sourceSet: sourceSet,
                  }),
                  message: 'View updated',
              }
            : {
                  request: createGridView({
                      view: viewObject,
                      sourceSet: sourceSet,
                  }),
                  message: 'View created',
              };

        toolkitDispatch(action.request)
            .unwrap()
            .then(() => {
                sessionStorage.removeItem(sourceSet);
                showNotification(
                    true,
                    TranslationHelper.translate(action.message)
                );
                handleClose();
            })
            .catch((error) => {
                const message = errorMessageHandler(error.status)();
                showNotification(false, message);
            });
    };

    return (
        <GridViewForm
            isOpen={isOpen}
            title={title}
            initialValues={initialValues}
            handleClose={handleClose}
            handleSubmit={handleSubmit}
            editMode={!!viewId}
        />
    );
};

export default GridViewDialog;
