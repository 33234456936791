import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { makeReduxDuck } from 'teedux';

import { IBookmark } from '../../types';

import {
    deleteBookmarkInLocalStorage,
    fetchBookmarks,
    saveBookmarkToLocalStorage,
} from '../../../services/discovery/BookmarksService';

import { TRootState } from '../../../store';
import TimeFormatter from '../../../helpers/TimeFormatter';

interface IState {
    bookmarks: IBookmark[];
    checkedBookmarks: string[];
    isSnackbarOpen: boolean;
}

const initialState: IState = {
    bookmarks: [],
    checkedBookmarks: [],
    isSnackbarOpen: false,
};

const duck = makeReduxDuck('app/bookmarks', initialState);

const storeBookmarksAction = duck.defineAction<{
    bookmarks: IBookmark[];
}>('STORE_BOOKMARKS', (_, action) => ({
    bookmarks: action.bookmarks,
}));

const storeCheckedBookmarksAction = duck.defineAction<{
    checkedBookmarks: string[];
}>('STORE_CHECKED_BOOKMARKS', (_, { checkedBookmarks }) => ({
    checkedBookmarks,
}));

const saveBookmarkAction = duck.defineAction<{
    bookmark: IBookmark;
}>('SAVE_BOOKMARK', (state, { bookmark }) => {
    if (state.bookmarks.some((entity: any) => entity.id === bookmark.id)) {
        return state;
    }

    return { bookmarks: [bookmark, ...state.bookmarks] };
});

const deleteBookmarkAction = duck.defineAction<{
    bookmarkId: string;
}>('DELETE_BOOKMARK', (state, { bookmarkId }) => {
    return {
        bookmarks: state.bookmarks.filter(
            (bookmark) => bookmarkId !== bookmark.id
        ),
    };
});

const handleSnackbarAction = duck.defineAction<{
    isSnackbarOpen: boolean;
}>('HANDLE_SNACKBAR', (state, { isSnackbarOpen }) => {
    return { isSnackbarOpen };
});

export default duck.getReducer();

export const getBookmarks = (state: TRootState) => state.app.bookmarks;
export const getBookmarkList = (state: TRootState) =>
    getBookmarks(state).bookmarks;
export const getCheckedBookmarksList = (state: TRootState) =>
    getBookmarks(state).checkedBookmarks;
export const getIsSnackbarOpen = (state: TRootState) =>
    getBookmarks(state).isSnackbarOpen;

export const setCheckedBookmarks = (checkedBookmarks: string[]) =>
    storeCheckedBookmarksAction({ checkedBookmarks });

export const getStoreBookmarks =
    (): ThunkAction<void, null, null, Action> => (dispatch) =>
        fetchBookmarks().then((data) => {
            dispatch(storeBookmarksAction({ bookmarks: data }));
        });

export const saveBookmark =
    (bookmark: IBookmark): ThunkAction<void, null, null, Action> =>
    (dispatch) => {
        const bookmarkId = `${
            bookmark.monitoredId
        }_${TimeFormatter.getISOString(
            bookmark.from
        )}_${TimeFormatter.getISOString(bookmark.to)}`;

        const updatedBookmark = { ...bookmark, id: bookmarkId };
        saveBookmarkToLocalStorage(updatedBookmark).then(() =>
            dispatch(saveBookmarkAction({ bookmark: updatedBookmark }))
        );
    };

export const deleteBookmark =
    (bookmarkId: string): ThunkAction<void, null, null, Action> =>
    (dispatch) => {
        deleteBookmarkInLocalStorage(bookmarkId).then(() =>
            dispatch(deleteBookmarkAction({ bookmarkId }))
        );
    };

export const handleSnackbar =
    (isSnackbarOpen: boolean): ThunkAction<void, null, null, Action> =>
    (dispatch) => {
        dispatch(handleSnackbarAction({ isSnackbarOpen }));
    };
