import React, { useEffect } from 'react';

import { Formik, FormikValues } from 'formik';

import { Box, Slider } from '@material-ui/core';

import SearchIcon from '@material-ui/icons/Search';

import TranslationHelper from '../../../../../../helpers/TranslationHelper';
import PaneHeader from '../../../../../../components/PaneHeader/PaneHeader';
import {
    closePreview,
    storeLastMapClickPosition,
    storeNearEventsRadius,
} from '../../../../../../state/_actions';
import { useStyles } from './Themable.hooks';
import SectionWithTitle from '../../../../../../components/SectionWithTitle/SectionWithTitle';
import AddressSection from '../../../../../../components/AddressSection/AddressSection';
import DatePresets from '../../../../../../components/DatePresets/DatePresets';
import moment from 'moment';

import { useAppDispatch } from '../../../../../../../app/hooks';

import { activateGrid } from '../../../../../../state/_actions';

import { ISourceSetAction } from '../../../../../../state/ui/discovery/types';
import { ICoordinates } from '../../../../../../state/types';
import TimeFormatter from '../../../../../../helpers/TimeFormatter';
import { useMapCoordinates, useNearEventsRadius } from './Connectable.hooks';
import IconButtonWithTooltip from '../../../../../../components/IconButtonWithTooltip';
import { makeSourceSetId } from '../../../../../../services/discovery/_shared/utils';
import { searchEventsSchema } from '../../../../../../schemas';
import showEventAction from '../../../../../../constants/showEventAction';

const SearchEvents = () => {
    const BLANK_FORM = {
        address: '',
        longitude: '',
        latitude: '',
        dateFrom: moment().startOf('day'),
        dateTo: moment().endOf('day'),
    };

    const dispatch = useAppDispatch();

    const classes = useStyles();

    const handleCloseClick = (deselect?: boolean) => {
        dispatch(closePreview(0, deselect));
    };

    const mapCoordinates = useMapCoordinates();
    const nearEventsRadius = useNearEventsRadius();

    const handleValues = (data: FormikValues) => {
        activateNewGrid(data);
    };
    const activateNewGrid = (data: FormikValues) => {
        const { latitude, longitude, dateFrom, dateTo } = data;
        const sourceSetAction: ISourceSetAction = {
            api: `/rest/api/source-sets/archive-events/near`,
            method: 'GET',
            label: '',
            _webxMeta: {
                actions: showEventAction,
            },
            params: {
                from: TimeFormatter.toISOString(dateFrom),
                to: TimeFormatter.toISOString(dateTo),
                radius: nearEventsRadius!,
                latitude,
                longitude,
                monitoredId: longitude.toString() + latitude.toString(),
                reportId: 'archiveEvents',
            },
        };

        dispatch(
            activateGrid(
                makeSourceSetId(sourceSetAction.params)!,
                {
                    type: 'source-set-grid',
                    level: 0,
                },
                null,
                false,
                sourceSetAction
            )
        );
    };
    const marks = [
        {
            value: 50,
            label: '50m',
        },
        {
            value: 100,
            label: '100m',
        },
        {
            value: 150,
            label: '150m',
        },
        {
            value: 200,
            label: '200m',
        },
    ];

    useEffect(() => {
        dispatch(storeNearEventsRadius(marks[0].value));
        return () => {
            dispatch(storeNearEventsRadius(null));
        };
    }, []);

    useEffect(() => {}, [nearEventsRadius]);

    const handleSetCoords = (coords: ICoordinates) => {
        dispatch(storeLastMapClickPosition(coords.x, coords.y));
    };
    const handleChangeDates = <T,>(
        field: string,
        data: T,
        setFieldValue: (field: string, value: any) => void
    ) => {
        setFieldValue(field, data);
    };

    const getRightCustomControls = (submitForm: () => void) => (
        <IconButtonWithTooltip
            title={TranslationHelper.translate('Search')}
            onClick={submitForm}
        >
            <SearchIcon />
        </IconButtonWithTooltip>
    );

    return (
        <Formik
            enableReinitialize={true}
            initialValues={BLANK_FORM}
            onSubmit={handleValues}
            validateOnBlur={false}
            validateOnChange={false}
            validationSchema={searchEventsSchema()}
        >
            {({ errors, values, submitForm, setFieldValue }) => (
                <>
                    <PaneHeader
                        title={TranslationHelper.translate(
                            'Search events nearby'
                        )}
                        onCloseClick={handleCloseClick}
                        renderRightCustomControls={() =>
                            getRightCustomControls(submitForm)
                        }
                    />
                    <div className={classes.formWrapper}>
                        <SectionWithTitle title="Address">
                            <AddressSection
                                mapCoordinates={mapCoordinates}
                                values={values}
                                errors={errors}
                                setFieldValue={setFieldValue}
                                callback={handleSetCoords}
                            />
                        </SectionWithTitle>
                        <SectionWithTitle title="Range">
                            <Box
                                sx={{
                                    width: 400,
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                <Slider
                                    aria-label="Custom marks"
                                    step={50}
                                    min={50}
                                    max={200}
                                    value={nearEventsRadius || marks[0].value}
                                    onChange={(
                                        e: any,
                                        newValue: number | number[]
                                    ) =>
                                        dispatch(
                                            storeNearEventsRadius(
                                                newValue as number
                                            )
                                        )
                                    }
                                    marks={marks}
                                    classes={{
                                        root: classes.slider,
                                    }}
                                />
                            </Box>
                        </SectionWithTitle>
                        <DatePresets
                            dateFrom={values.dateFrom}
                            dateTo={values.dateTo}
                            handleFormChange={(field, date) =>
                                handleChangeDates(field, date, setFieldValue)
                            }
                        />
                    </div>
                </>
            )}
        </Formik>
    );
};

export default SearchEvents;
