import { makeReduxDuck } from 'teedux';
import { createSelector } from 'reselect';

import moment from 'moment-timezone';

import {
    IGetAlertsParams,
    restGetAlerts,
    restGetAlertsCount,
    restPostAlertStatus,
    TAlertResponse,
} from '../../../services/alerts';
import { restGetMonitoredObjects } from '../../../services/monitored';
import { makeRequest } from '../sync';

import { IMonitoredObject, ISourceSetMessage } from '../../types';
import { TRootState } from '../../../store';
import { TDialog } from '../../ui/discovery/snapshotting';
import { IPreviewAction } from '../../ui/discovery/types';

const alertsStorePath = `app/alertsPage`;

interface IStatuses {
    read: boolean;
    unread: boolean;
}

export interface IDialogPayload {
    id: string;
    action: IPreviewAction;
}

export type TAlertDialog = Pick<
    TDialog,
    'elementId' | 'mode' | 'dialogAction' | 'elementCollectionName'
>;

interface IState {
    alertsCount: number;
    statuses: IStatuses;
    objectFilter?: IMonitoredObject;
    foundObjects: IMonitoredObject[];
    newAlertsMessage?: ISourceSetMessage[];
    shouldRefreshData: boolean;
    fetchAlertsAfter: string;
    tab: null | string;
    dialog: TAlertDialog | null;
}

const initialState: IState = {
    alertsCount: 0,
    statuses: {
        read: false,
        unread: true,
    },
    objectFilter: undefined,
    foundObjects: [],
    newAlertsMessage: undefined,
    shouldRefreshData: false,
    fetchAlertsAfter: moment().utc().format(),
    tab: null,
    dialog: null,
};

const duck = makeReduxDuck(alertsStorePath, initialState);

const setAlertsCountAction = duck.defineAction<{
    data: number;
}>('SET_ALERTS_COUNT', (state, { data }) => ({
    ...state,
    alertsCount: data,
}));

const setAlertsStatusAction = duck.defineAction<{
    statusName: string;
    statusState: boolean;
}>('SET_ALERTS_STATUSES', (state, { statusName, statusState }) => ({
    ...state,
    statuses: {
        ...state.statuses,
        [statusName]: statusState,
    },
}));

const storeFoundObjectsAction = duck.defineAction<{
    data: IMonitoredObject[];
}>('STORE_FOUND_OBJECTS', (state, { data }) => ({
    ...state,
    foundObjects: data,
}));

const clearFoundObjectsAction = duck.definePayloadlessAction(
    'CLEAR_FOUND_OBJECTS',
    () => ({
        foundObjects: [],
    })
);

const setObjectFilterAction = duck.defineAction<{
    objectFilter?: IMonitoredObject;
}>('SET_OBJECT_FILTER', (state, { objectFilter }) => ({
    ...state,
    objectFilter,
}));

const alertMessage: ISourceSetMessage[] = [
    {
        content: 'New alerts - click to refresh',
        level: 'info',
        translate: 'wx',
    },
];
const setNewAlertsMessageAction = duck.defineAction<{
    clear: boolean;
}>('SET_NEW_ALERTS_MSG', (state, { clear }) => ({
    ...state,
    newAlertsMessage: clear ? undefined : alertMessage,
}));

const setShouldRefreshDataAction = duck.defineAction<{
    refresh: boolean;
}>('SHOULD_REFRESH_DATA', (state, { refresh }) => ({
    ...state,
    newAlertsMessage: undefined,
    fetchAlertsAfter: state.fetchAlertsAfter,
    shouldRefreshData: refresh,
}));

export const setAlertsAfterAction = duck.defineAction<{
    data: TAlertResponse;
}>('SET_ALERTS_AFTER', (state, { data }) => {
    const allAlerts = data.values;
    let fetchAlertsAfter = state.fetchAlertsAfter;
    let newAlertsMessage = state.newAlertsMessage;

    if (allAlerts.length > 0) {
        fetchAlertsAfter = allAlerts[0].createdAt;
        newAlertsMessage = alertMessage;
    }

    return {
        ...state,
        fetchAlertsAfter,
        newAlertsMessage,
    };
});

export const selectTab = duck.defineAction<{
    tab: string;
}>('SELECT_TAB', (_, { tab }) => ({
    tab,
}));

export const resetAlertDialog = duck.definePayloadlessAction(
    'RESET_ALERT_DIALOG',
    (state) => ({
        ...state,
        dialog: null,
    })
);
export const activateAddAlertDialog = duck.definePayloadlessAction(
    'SET_ADD_ALERT_DIALOG',
    (state) => ({
        ...state,
        dialog: {
            mode: 'add',
            elementId: null,
            elementCollectionName: 'alertSettings',
            dialogAction: {
                api: 'rest/api/alerts/settings',
                label: 'Add',
                method: 'POST',
            },
        },
    })
);

export const activateEditAlertDialog = duck.defineAction<IDialogPayload>(
    'SET_EDIT_ALERT_DIALOG',
    (state, params) => ({
        ...state,
        dialog: {
            mode: 'edit',
            elementId: null,
            elementCollectionName: params.id,
            dialogAction: {
                api: '/rest/api/alerts/settings/{id}',
                label: 'Edit',
                method: 'PUT',
            },
        },
    })
);

export default duck.getReducer();

const makeAlertsStorePath = () => `get:${alertsStorePath}`;

export const fetchAlerts = (
    params: IGetAlertsParams,
    afterSuccess?: (data: TAlertResponse) => void,
    afterFail?: (error: Error) => void
) =>
    makeRequest(
        makeAlertsStorePath(),
        () => restGetAlerts(params),
        (dispatch, data) => {
            if (afterSuccess) {
                afterSuccess(data);
            }
        },
        (dispatch, error) => {
            if (afterFail) {
                afterFail(error);
            }
        }
    );

export const fetchAlertsCount = (
    status: string = 'unread',
    afterSuccess?: (data: number) => void,
    afterFail?: (error: Error) => void
) =>
    makeRequest(
        makeAlertsStorePath(),
        () => restGetAlertsCount(status),
        (dispatch, data) => {
            dispatch(setAlertsCountAction({ data }));

            if (afterSuccess) {
                afterSuccess(data);
            }
        },
        (dispatch, error) => {
            if (afterFail) {
                afterFail(error);
            }
        }
    );

export const markAlertAsRead = (
    id: string,
    afterSuccess?: () => void,
    afterFail?: (error: Error) => void
) =>
    makeRequest(
        makeAlertsStorePath(),
        () => restPostAlertStatus(id),
        (dispatch, data) => {
            if (afterSuccess) {
                afterSuccess();
            }
        },
        (dispatch, error) => {
            if (afterFail) {
                afterFail(error);
            }
        }
    );

export const searchObjectByName = (
    name: string,
    afterSuccess?: () => void,
    afterFail?: (error: Error) => void
) =>
    makeRequest(
        makeAlertsStorePath(),
        () => restGetMonitoredObjects(name),
        (dispatch, data) => {
            dispatch(storeFoundObjectsAction({ data }));
            if (afterSuccess) {
                afterSuccess();
            }
        },
        (dispatch, error) => {
            if (afterFail) {
                afterFail(error);
            }
        }
    );

export const fetchAlertsForNotifications = (
    after: string,
    afterSuccess?: (data: TAlertResponse) => void,
    afterFail?: (error: Error) => void
) =>
    makeRequest(
        makeAlertsStorePath(),
        () =>
            restGetAlerts({
                after,
                limit: 100,
                orderBy: 'createdAt',
                sortOrder: 'desc',
            }),
        (dispatch, data) => {
            dispatch(setAlertsAfterAction({ data }));

            if (afterSuccess) {
                afterSuccess(data);
            }
        },
        (dispatch, error) => {
            if (afterFail) {
                afterFail(error);
            }
        }
    );

export const setAlertsReadStatus = (statusState: boolean) =>
    setAlertsStatusAction({ statusName: 'read', statusState });

export const setAlertsUnreadStatus = (statusState: boolean) =>
    setAlertsStatusAction({ statusName: 'unread', statusState });

export const clearFoundObjects = () => clearFoundObjectsAction();

export const setObjectFilter = (objectFilter: IMonitoredObject) =>
    setObjectFilterAction({ objectFilter });

export const clearObjectFilter = () => setObjectFilterAction({});

export const setNewAlertsMessage = () =>
    setNewAlertsMessageAction({ clear: false });

export const clearNewAlertsMessage = () =>
    setNewAlertsMessageAction({ clear: true });

export const setShouldRefreshData = (refresh: boolean) =>
    setShouldRefreshDataAction({ refresh });

const getAlertsPageStore = (state: TRootState) => state.app.alertsPage;

export const getAlertsCount = (state: TRootState) =>
    getAlertsPageStore(state).alertsCount;

export const getObjectFilter = (state: TRootState) =>
    getAlertsPageStore(state).objectFilter;

export const getTab = (state: TRootState) => getAlertsPageStore(state).tab;

export const getAlertDialog = (state: TRootState) =>
    getAlertsPageStore(state).dialog;

export const getAlertsObjectId = (state: TRootState) =>
    getObjectFilter(state)?.monitoredId;

export const getFoundObjects = (state: TRootState) =>
    getAlertsPageStore(state).foundObjects;

export const getNewAlertsMessage = (state: TRootState) =>
    getAlertsPageStore(state).newAlertsMessage;

export const getShouldRefreshData = (state: TRootState) =>
    getAlertsPageStore(state).shouldRefreshData;

export const getAlertsStatuses = (state: TRootState) =>
    getAlertsPageStore(state).statuses;

export const getAlertsStatusesAsString = createSelector(
    [getAlertsStatuses],
    ({ read, unread }) => {
        if (read && !unread) {
            return 'read';
        }
        if (!read && unread) {
            return 'unread';
        } else {
            return 'unread,read';
        }
    }
);

export const getFetchAlertsAfter = (state: TRootState) => {
    return getAlertsPageStore(state).fetchAlertsAfter;
};
