import React from 'react';

import { Checkbox, Box } from '@mui/material';

import { TColumn, ColumnType, RowData } from './types';
import TranslationHelper from '../../helpers/TranslationHelper';
import {
    DateCellComponent,
    DateTimeCell,
    TimeCellComponent,
} from './DateTimeCells';
import {
    AutocompleteCell,
    SelectCell,
    TextFieldCell,
} from './InputCellComponents';

export interface IEditableCellProps<T extends RowData> {
    column: TColumn;
    row: T;
    handleValueChange: (
        rowId: string | number,
        columnId: string,
        value: any
    ) => void;
}

const EditableTableCell = <T extends RowData>({
    column,
    row,
    handleValueChange,
}: IEditableCellProps<T>) => {
    const value = row[column.id];
    return (
        <Box sx={{ '& .MuiTextField-root': { width: '25ch' } }}>
            <div>
                {(() => {
                    switch (column.type) {
                        case ColumnType.TEXT:
                            return <>{TranslationHelper.translate(value)}</>;
                        case ColumnType.TEXTFIELD:
                            return (
                                <TextFieldCell
                                    handleValueChange={handleValueChange}
                                    column={column}
                                    row={row}
                                />
                            );
                        case ColumnType.SELECT:
                            return (
                                <SelectCell
                                    handleValueChange={handleValueChange}
                                    column={column}
                                    row={row}
                                />
                            );
                        case ColumnType.AUTOCOMPLETE:
                            return (
                                <AutocompleteCell
                                    handleValueChange={handleValueChange}
                                    column={column}
                                    row={row}
                                />
                            );
                        case ColumnType.CHECKBOX:
                            return (
                                <Checkbox
                                    checked={!!value}
                                    onChange={(e) =>
                                        handleValueChange(
                                            row.id,
                                            column.id,
                                            e.target.checked
                                        )
                                    }
                                    disabled={column.disabled || row.disabled}
                                />
                            );
                        case ColumnType.DATE:
                            return (
                                <DateCellComponent
                                    handleValueChange={handleValueChange}
                                    column={column}
                                    row={row}
                                />
                            );
                        case ColumnType.TIME:
                            return (
                                <TimeCellComponent
                                    handleValueChange={handleValueChange}
                                    column={column}
                                    row={row}
                                />
                            );
                        case ColumnType.DATETIME:
                            return (
                                <DateTimeCell
                                    handleValueChange={handleValueChange}
                                    column={column}
                                    row={row}
                                />
                            );
                        default:
                            return <></>;
                    }
                })()}
            </div>
        </Box>
    );
};

export default EditableTableCell;
