import React from 'react';

import { ICellRendererParams } from 'ag-grid-community';

import { IconButton } from '@material-ui/core';
import { default as ExportIcon } from '@material-ui/icons/GetApp';

import {
    deleteReport,
    IReport,
} from '../../../../../../../../state/ui/reportsSlice';

import { makeReportsUrl } from '../../../../../../../../services/reports';

import { useToolkitDispatch } from '../../../../../../../../hooks';

import TranslationHelper from '../../../../../../../../helpers/TranslationHelper';

import AppConfig from '../../../../../../../../constants/AppConfig';
import { READY } from '../../../../../../../../constants/dictionaries/ReportStatuses';

import DeleteButton from '../../../../../../../../components/DeleteButton';

type TOwnProps = ICellRendererParams;

const ActionsButtons = (props: TOwnProps) => {
    const toolkitDispatch = useToolkitDispatch();

    const enterpriseKey =
        AppConfig &&
        AppConfig.instance.getConfigKey(AppConfig.PROPERTY_GRID)?.key;

    const onClick = () => {
        toolkitDispatch(deleteReport(props.value))
            .unwrap()
            .then(() => {
                enterpriseKey
                    ? props.api.refreshServerSideStore({})
                    : props.api.purgeInfiniteCache();
            });
    };

    const createDownloadUrl = (report: IReport) => {
        const { id } = report;
        const uri = makeReportsUrl(`/${id}/download`);

        return uri.toString();
    };

    const shouldShowButtons = props.value;

    return shouldShowButtons ? (
        <>
            {props.data.status === READY && (
                <IconButton
                    href={createDownloadUrl(props.data)}
                    size="small"
                    title={TranslationHelper.translate('download')}
                >
                    <ExportIcon fontSize="small" />
                </IconButton>
            )}
            <DeleteButton
                title="Removal confirmation"
                tooltip="Delete"
                message="Are you sure?"
                deleteMethod={onClick}
                size="small"
                defaultColor
            />
        </>
    ) : (
        ''
    );
};

export default ActionsButtons;
