import React, { Component, ComponentType } from 'react';
import { compose } from 'redux';

import { withSnackbar, WithSnackbarProps } from 'notistack';
import CrudPane from '../../../../../../components/CrudPane';
import { IParameterMapping } from '../../../../../../state/ui/forms';
import { Connectable, TConnectableProps } from './Connectable.hoc';
import { Themable, TThemableProps } from './Themable.hoc';

import { IconButton, TextField, Tooltip } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import Autocomplete, {
    AutocompleteRenderInputParams,
} from '@material-ui/lab/Autocomplete';

import { responseMessage } from '../../../../../../helpers/responseMessage';
import TranslationHelper from '../../../../../../helpers/TranslationHelper';
import {
    IDefinitionItem,
    IParameterRest,
} from '../../../../../../state/app/mappings';
import { IPreviewAction } from '../../../../../../state/ui/discovery/types';
import MappingsList from './components/MappingsList';

type TProps = TThemableProps & TConnectableProps & WithSnackbarProps;
interface IState {
    mappingsLoaded: boolean;
    blankForm: IParameterMapping[];
    newValue: IParameterMapping;
    validation: {
        source: {
            error: boolean;
            message: string;
        };
        target: {
            error: boolean;
            message: string;
        };
    };
}

const blankRow: IParameterMapping = {
    target: null,
    source: null,
};

class ParametersMappingPane extends Component<TProps, IState> {
    public state: IState = {
        mappingsLoaded: false,
        blankForm: [],
        newValue: blankRow,
        validation: {
            source: {
                error: false,
                message: '',
            },
            target: {
                error: false,
                message: '',
            },
        },
    };

    public componentDidMount() {
        const {
            pane,
            fetchSourceParamsDefinitions,
            fetchTargetParamsDefinitions,
            customAction,
        } = this.props;
        const action = pane && pane.previewAction;

        fetchSourceParamsDefinitions((error) => {
            const message = responseMessage(error.status);
            this.props.enqueueSnackbar(
                `${TranslationHelper.translate(
                    'Parameters definitions download failed'
                )}: ${TranslationHelper.translate(message.text)}`,
                { variant: message.type }
            );
        });

        fetchTargetParamsDefinitions((error) => {
            const message = responseMessage(error.status);
            this.props.enqueueSnackbar(
                `${TranslationHelper.translate(
                    'Parameters definitions download failed'
                )}: ${TranslationHelper.translate(message.text)}`,
                { variant: message.type }
            );
        });

        if (action) {
            const getAction: IPreviewAction = {
                ...action,
                label: 'GET',
                method: 'GET',
            };

            customAction(getAction, undefined, (data: IParameterRest[]) => {
                this.setState({
                    blankForm: data.map((el) => ({
                        source: {
                            name: el.source,
                        },
                        target: {
                            name: el.target,
                        },
                    })),
                });
            });
        }
    }

    public handleNewDataChange = (
        field: string,
        value: IDefinitionItem | null
    ) => {
        this.setState((state) => ({
            newValue: {
                ...state.newValue,
                target: null,
                [field]: value,
            },
        }));
    };

    public addMappingRow = () => {
        this.setState(
            (state) => {
                const { newValue, blankForm } = state;
                const repeated = blankForm.some(
                    (el) =>
                        el.source?.name === newValue.source?.name &&
                        el.target?.name === newValue.target?.name
                );

                if (repeated) {
                    return {
                        validation: {
                            source: {
                                error: true,
                                message: TranslationHelper.translate(
                                    'Mapping already exist'
                                ),
                            },
                            target: {
                                error: true,
                                message: ' ',
                            },
                        },
                    };
                }

                return {
                    validation: {
                        source: {
                            error: !newValue.source,
                            message: !newValue.source
                                ? TranslationHelper.translate('Fill this field')
                                : '',
                        },
                        target: {
                            error: !newValue.target,
                            message: !newValue.target
                                ? TranslationHelper.translate('Fill this field')
                                : '',
                        },
                    },
                };
            },
            () => {
                const { validation } = this.state;
                if (validation.source.error || validation.target.error) {
                    return;
                }

                this.setState((state) => {
                    return {
                        newValue: blankRow,
                        blankForm: [...state.blankForm, state.newValue],
                    };
                });
            }
        );
    };

    public removeMapping = (index: number) => {
        this.setState((state) => {
            return {
                blankForm: state.blankForm.filter((el, i) => i !== index),
            };
        });
    };

    public getOptionName = (option: IDefinitionItem) => {
        return option.name;
    };

    public renderSourceInput = (params: AutocompleteRenderInputParams) => {
        const { validation } = this.state;
        return (
            <TextField
                {...params}
                label={TranslationHelper.translate('Source')}
                error={validation.source.error}
                helperText={validation.source.message}
            />
        );
    };

    public renderTargetInput = (params: AutocompleteRenderInputParams) => {
        const { validation } = this.state;
        return (
            <TextField
                {...params}
                label={TranslationHelper.translate('Target')}
                error={validation.target.error}
                helperText={validation.target.message}
            />
        );
    };

    public handleSourceInputChange = (
        event: any,
        value: IDefinitionItem | null
    ) => {
        this.handleNewDataChange('source', value);
    };
    public handleTargetInputChange = (
        event: any,
        value: IDefinitionItem | null
    ) => {
        this.handleNewDataChange('target', value);
    };

    public getOptionSelected = (
        option: IDefinitionItem,
        value: IDefinitionItem
    ) => {
        return option.name === value.name;
    };

    public renderModification = () => {
        const { classes, sourceParamsDefs, targetParamsDefs } = this.props;
        const { blankForm, newValue } = this.state;
        const filteredTargetParams = targetParamsDefs.filter(
            (el) =>
                newValue.source &&
                newValue.source.type === el.type &&
                newValue.source.name !== el.name
        );

        const autocompleteParams = {
            className: classes.combobox,
            getOptionLabel: this.getOptionName,
            getOptionSelected: this.getOptionSelected,
        };

        return (
            <div className={classes.paneContent}>
                <p>{TranslationHelper.translate('Add new mapping')}</p>

                <div className={classes.formRow}>
                    <Tooltip title={newValue.source?.name || ''}>
                        <Autocomplete
                            value={newValue.source}
                            onChange={this.handleSourceInputChange}
                            options={sourceParamsDefs}
                            renderInput={this.renderSourceInput}
                            {...autocompleteParams}
                        />
                    </Tooltip>
                    <Tooltip title={newValue.target?.name || ''}>
                        <Autocomplete
                            value={newValue.target}
                            onChange={this.handleTargetInputChange}
                            options={filteredTargetParams}
                            renderInput={this.renderTargetInput}
                            {...autocompleteParams}
                        />
                    </Tooltip>
                    <IconButton onClick={this.addMappingRow}>
                        <AddIcon />
                    </IconButton>
                </div>

                <MappingsList
                    removeMapping={this.removeMapping}
                    parameters={blankForm}
                />
            </div>
        );
    };

    public render() {
        const { pane } = this.props;
        const initialValues = this.state.blankForm;

        return (
            <CrudPane
                titles={{ add: 'Edit' }}
                loaded={
                    pane !== null &&
                    (pane.mode === 'add' ||
                        pane.mode === 'edit' ||
                        pane.mode === 'preview')
                }
                initialValues={initialValues}
                onClose={this.handleCloseClick}
                mode="add"
                changeModeHandler={this.handleCloseClick}
                onAdd={this.handleSubmit}
                renderContent={() => {
                    return <>{this.renderModification()}</>;
                }}
                preventSubmitOnEnterKey={true}
            />
        );
    }

    private handleCloseClick = () => {
        this.props.resetLevel(this.props.creatorLevel);
    };

    private handleSubmit = () => {
        const { pane, customAction, enqueueSnackbar } = this.props;
        const { blankForm } = this.state;
        const action = pane && pane.previewAction;

        if (!action) {
            return;
        }

        const data = blankForm
            .filter((el) => el.target && el.source)
            .map((el) => ({
                source: el.source?.name,
                target: el.target?.name,
            }));

        customAction(
            action,
            data,
            () => {
                this.handleCloseClick();
                enqueueSnackbar(
                    TranslationHelper.translate('Mappings saved successfully'),
                    { variant: 'success' }
                );
            },
            (error) => {
                const message = responseMessage(error.status);
                enqueueSnackbar(
                    `${TranslationHelper.translate(
                        'Mappings save failed'
                    )}: ${TranslationHelper.translate(message.text)}`,
                    { variant: message.type }
                );
            }
        );
    };
}

export default compose(
    Themable,
    Connectable,
    withSnackbar
)(ParametersMappingPane) as ComponentType<{}>;
