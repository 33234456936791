import createStyles from '@material-ui/core/styles/createStyles';

import { Theme, WithStyles, withStyles } from '@material-ui/core/styles';

import { areLastStatesEnabled } from '../../../../constants/AppConfig';

const styles = (theme: Theme) => {
    return createStyles({
        pane: {
            position: 'absolute',
            zIndex: 200,
            left: theme.spacing(3),
            top: areLastStatesEnabled() ? 100 : theme.spacing(3),
        },
        activeBreadcrumb: {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.getContrastText(theme.palette.primary.main),
        },
        breadcrumb: {
            display: 'inline-block',
            overflow: 'hidden',
            minWidth: 30,
            maxWidth: 100,
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            padding: '4px 5px',
            fontSize: '10px',
        },
        disabledBreadcrumb: {
            opacity: 0.5,
        },
    });
};

export type TThemableProps = WithStyles<typeof styles>;

export const Themable = withStyles(styles, { withTheme: true });
