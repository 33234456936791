import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => {
    return createStyles({
        switchTrack: {
            backgroundColor: theme.palette.background.paper,
        },
        switchBase: {
            color: theme.palette.grey[400],
        },
        switchColorPrimary: {
            '&.Mui-checked': {
                color: theme.palette.background.paper,
            },
            '&.Mui-checked + .MuiSwitch-track': {
                backgroundColor: theme.palette.background.paper,
            },
        },
    });
});
