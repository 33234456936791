import React, { ReactChild } from 'react';

import { Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import { useStyles } from './Themable.hooks';

import TranslationHelper from '../../helpers/TranslationHelper';
import LoadingSpinner from '../loadingSpinner/LoadingSpinner';
import { IDeviceDetails } from '../../services/trustedDevice';

interface IOwnProps {
    children: ReactChild;
    data: {
        error: string | null;
        loading: boolean;
        deviceDetails: IDeviceDetails | null;
    };
}
const DeviceDetails = ({ data, children }: IOwnProps) => {
    const classes = useStyles();

    const { loading, error, deviceDetails } = data;

    if (loading)
        return (
            <div className={classes.spinner}>
                <LoadingSpinner progressSize="60px" />
            </div>
        );
    if (error)
        return (
            <Alert severity="error">{TranslationHelper.translate(error)}</Alert>
        );

    return (
        <>
            <div className={classes.deviceDetails}>
                <Typography component="ul">
                    <Typography key="browserName" component="li">
                        <b>{`${TranslationHelper.translate(
                            'auth.browserName'
                        )}: `}</b>
                        {deviceDetails?.browserName}
                    </Typography>
                    <Typography key="os" component="li">
                        <b>{`${TranslationHelper.translate('auth.os')}: `}</b>

                        {deviceDetails?.os}
                    </Typography>
                    <Typography key="cpu" component="li">
                        <b>{`${TranslationHelper.translate('auth.cpu')}: `}</b>

                        {deviceDetails?.cpu}
                    </Typography>
                    <Typography key="ip" component="li">
                        <b>{`${TranslationHelper.translate('auth.ip')}: `}</b>
                        {deviceDetails?.ip}
                    </Typography>
                </Typography>
            </div>
            {children}
        </>
    );
};

export default DeviceDetails;
