import { IAddress } from '../state/types';

export function formatAddress(object: { address?: IAddress }) {
    if (object && object.address) {
        const { city, street, streetNumber } = object.address;
        return `${city}, ${street}, ${streetNumber}`;
    } else {
        return '';
    }
}
