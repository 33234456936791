import { ITransferListObject } from 'src/app/components/TransferList/TransferList';

export const filterByType = (item: ITransferListObject, type: string) => {
    return item.type === type;
};

export const filterByName = (item: ITransferListObject, name: string) => {
    return lowerCaseFilter(item, name);
};

export const lowerCaseFilter = (object: ITransferListObject, name: string) => {
    return object.name.toLowerCase().includes(name.toLowerCase());
};

export const filterObjects = (
    items: ITransferListObject[],
    type: string,
    name: string
) => {
    return items.filter(
        (item) => filterByType(item, type) && filterByName(item, name)
    );
};
