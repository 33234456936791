import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => {
    const padding = theme.spacing(2);

    return createStyles({
        typography: {
            margin: theme.spacing(),
        },
        wrapper: {
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 20,
            overflowY: 'auto',
            [theme.breakpoints.down('md')]: {
                borderRadius: 0,
            },
        },
        selectedItemsActions: {
            paddingLeft: padding,
            paddingRight: padding,
            paddingBottom: padding,
            paddingTop: padding,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            color: 'white',
            backgroundColor: theme.palette.primary.light,
        },
        button: {
            color: 'white',
        },
    });
});
