import UrlConst from '../../constants/UrlConst';

import { IPage, IRegisterFilter } from '../../state/app/registers/';
import { IVehicle } from '../../state/app/registers/vehicles';
import { IRequestWithMessages } from '../../state/app/sync';

import apiClient from '../common/apiClient';
import {
    makeEndpoint,
    makeGetRequestSettings,
    makePostRequestSettings,
    makePutRequestSettings,
} from '../common/utils';

export const makeVehiclesUrl = (suffix: string = '', params = {}) => {
    return makeEndpoint(UrlConst.REGISTERS_URL, '/vehicles' + suffix, {
        ...params,
    });
};
export const restGetVehicles = (
    params: IRegisterFilter
): Promise<IPage<IVehicle>> =>
    apiClient.send(makeGetRequestSettings(makeVehiclesUrl('', params)));

export const restPostVehicle = (
    vehicle: IVehicle
): Promise<IRequestWithMessages> =>
    apiClient.send(makePostRequestSettings(makeVehiclesUrl(), vehicle));

export const restPutVehicle = (
    vehicle: IVehicle
): Promise<IRequestWithMessages> =>
    apiClient.send(
        makePutRequestSettings(makeVehiclesUrl(`/${vehicle.id}`), vehicle)
    );

export const restPutVehicleCards = (
    id: string,
    cards: string[]
): Promise<IRequestWithMessages> =>
    apiClient.send(
        makePutRequestSettings(makeVehiclesUrl(`/${id}/cards`), cards)
    );
