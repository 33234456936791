import React, { useMemo } from 'react';
import EditableTable, { TColumn, ColumnType } from '../../../../EditableTable';

import { IAttributesForm } from './AttributesForm';
import { getDefaultValueFieldProps } from '../helpers/getFieldValues';
import { sortAlphabetically } from '../../../../../helpers/comparators';
import TranslationHelper from '../../../../../helpers/TranslationHelper';

interface IOwnProps {
    values: IAttributesForm;
    setFieldValue: (name: string, value: any) => void;
}

export const ObjectsTable = ({ values, setFieldValue }: IOwnProps) => {
    const columns: TColumn[] = [
        {
            id: 'selected',
            label: '',
            type: ColumnType.CHECKBOX,
        },
        {
            id: 'name',
            label: 'Object name',
            type: ColumnType.TEXT,
        },
        {
            id: 'defaultValue',
            label: 'Default value',
            ...getDefaultValueFieldProps(
                values.type,
                values.dictionary,
                !values.lockedToDictionary
            ),
        },
        {
            id: 'multipleValue',
            label: 'Multiple values',
            type: ColumnType.CHECKBOX,
        },
        {
            id: 'required',
            label: 'Required',
            type: ColumnType.CHECKBOX,
        },
    ];
    const onDataChange = (data: any) => {
        setFieldValue('connectedTo', data);
    };

    const data = useMemo(() => {
        return values.connectedTo
            .map((item) => {
                return { ...item, disabled: item.internal };
            })
            .sort((a, b) =>
                sortAlphabetically(
                    TranslationHelper.translate(a.name),
                    TranslationHelper.translate(b.name)
                )
            );
    }, [values.connectedTo]);
    return (
        <EditableTable
            columns={columns}
            data={data}
            onDataChange={onDataChange}
        />
    );
};
