import React from 'react';

import Circle from 'ol/style/Circle';
import Fill from 'ol/style/Fill';
import Icon from 'ol/style/Icon';
import Stroke from 'ol/style/Stroke';
import Style from 'ol/style/Style';
import Text from 'ol/style/Text';

import DOMPurify from 'dompurify';

import { DEFAULT_INDICATOR_COLOR } from '../../constants/dictionaries/ColorConst';
import {
    REVERSED_LOGIC,
    STANDARD_LOGIC,
} from '../../constants/dictionaries/TankLogic';
import { TANK } from '../../constants/dictionaries/ObjectType';

import getIcon from './iconTemplates';

import { IIcon } from '../../state/types';
import { Buffer } from 'buffer';

const icons = {};
const openLayersIconStyles = {};

const svgToUrl = (svg: string) => {
    const base64Svg = Buffer.from(svg).toString('base64');
    return `data:image/svg+xml;base64,${base64Svg}`;
};

export interface IObject {
    name: string;
    type: number;
    params: {
        [key: string]: string;
    };
    data: {
        iconColor?: string;
        iconName?: string;
        azimuth?: number;
        fillLevel?: number;
        logicType?: number;
    };
    state?: {
        color: string;
        kind: string;
    };
}

const getIconColor = (object: IObject) =>
    object.data.iconColor ||
    (object.state && object.state.color) ||
    DEFAULT_INDICATOR_COLOR;

const getIconName = (object: IObject) =>
    object.data.iconName || (object.state && object.state.kind) || 'UNKNOWN';

const getIconAzimuth = (object: IObject) =>
    object.data.azimuth ? Math.round(object.data.azimuth) : 0;

const getIconFillLevel = (object: IObject) => object.data.fillLevel || 0;

const getIconLogicType = (object: IObject) =>
    object.data.logicType || STANDARD_LOGIC;

const createArchivePoint = (object: IObject, hash: string) => {
    const iconColor = getIconColor(object);
    const icon = {
        image: new Circle({
            radius: 5,
            fill: new Fill({
                color: iconColor,
            }),
            stroke: new Stroke({
                color: 'black',
                width: 2,
            }),
        }),
    };
    icons[hash] = icon;
    return icon;
};

const createTankIcon = (object: IObject, hash: string) => {
    const iconName = getIconName(object);
    const level = getIconFillLevel(object);
    const logicType = getIconLogicType(object);
    let iconFillColor = null;
    let iconBorderColor = '#848484';
    let iconColor = '#FFFFFF';

    let iconFillHeight = Math.round((22 * level) / 100);
    iconFillHeight = !isNaN(iconFillHeight) ? iconFillHeight : 0;

    const percent =
        logicType === REVERSED_LOGIC ? Number(100 - level) : Number(level);

    if (percent >= 50) {
        iconFillColor = '#64BF00';
    } else if (percent < 50 && percent >= 10) {
        iconFillColor = '#F8D01C';
    } else {
        iconFillColor = '#FF0000';
        iconBorderColor = '#900000';
        iconColor = '#FFBEBE';
    }

    const icon = getIcon(iconName, {
        iconBorderColor,
        iconColor,
        iconFillColor,
        iconFillHeightNegative: Number(23 - (iconFillHeight || 0)),
        iconFillHeight,
    });
    icons[hash] = icon;
    return icon;
};

const createIcon = (object: IObject, hash: string) => {
    const iconName = getIconName(object);

    if (object.type === TANK && iconName === 'XTP') {
        return createTankIcon(object, hash);
    } else if (
        iconName === 'Archive-B' ||
        iconName === 'Archive-C' ||
        iconName === 'Archive-D' ||
        iconName === 'Archive-E'
    ) {
        return createArchivePoint(object, hash);
    }

    const iconColor = getIconColor(object);
    const iconAngle = getIconAzimuth(object);

    const icon = getIcon(iconName, {
        iconColor,
        iconAngle,
    });
    icons[hash] = icon;
    return icon;
};

const createDiscoveryIcon = (iconDefinition: IIcon, hash: string) => {
    const name = (iconDefinition && iconDefinition.name) || 'UNKNOWN';
    const params = (iconDefinition && iconDefinition.params) || {};

    const icon = getIcon(name, params);
    icons[hash] = icon;
    return icon;
};
// * USED IN DISCOVERY COMPONENT
const createDiscoveryIconStyle = (iconDefinition: IIcon, hash: string) => {
    const iconLabel =
        iconDefinition.sourceSetId === 'lastStates' ||
        iconDefinition.sourceSetId === 'locations'
            ? iconDefinition.data && iconDefinition.data.name
            : '';
    let iconStyle = openLayersIconStyles[hash + iconLabel];
    if (iconStyle) {
        return iconStyle;
    }

    const icon = icons[hash] || createDiscoveryIcon(iconDefinition, hash);
    if (icon.image === undefined) {
        icon.image = new Icon({
            anchor: [0.5, 0.5],
            src: svgToUrl(icon.content),
            size: [icon.width, icon.height],
            height: icon.height,
            width: icon.width,
        });
    }
    const iconImage = icon.image;

    let text = iconLabel;
    if (text && text.length > 40) {
        text = text.slice(0, 40) + '...';
    }

    iconStyle = [
        new Style({
            image: iconImage,
            text: new Text({
                text,
                offsetY: 20,
                stroke: new Stroke({ color: 'white', width: 4 }),
            }),
        }),
    ];

    openLayersIconStyles[hash + iconLabel] = iconStyle;
    return iconStyle;
};
// * USED IN PREVIEW COMPONENT
const createDiscoveryIconComponent = (iconDefinition: IIcon, hash: string) => {
    const icon = icons[hash] || createDiscoveryIcon(iconDefinition, hash);

    return (
        <span
            dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(icon.content || icon.fallbackSVG),
            }}
        />
    );
};
// * USED IN DASHBOARD COMPONENT
const createOpenLayersIconStyle = (object: IObject, hash: string) => {
    const icon = icons[hash] || createIcon(object, hash);
    const iconImage = icon.image
        ? icon.image
        : new Icon({
              anchor: [0.5, 0.5],
              src: svgToUrl(icon.content),
              size: [icon.width, icon.height],
          });

    const iconStyle = [
        new Style({
            image: iconImage,
        }),
    ];

    openLayersIconStyles[hash] = iconStyle;
    return iconStyle;
};

const getIconHash = (object: IObject) => {
    return JSON.stringify(object.data);
};

const getDiscoveryIconHash = (iconDefinition: IIcon) => {
    return iconDefinition.id;
};

export default {
    getDiscoveryIconStyle(iconDefinition: IIcon, customHash?: string) {
        const hash = customHash || getDiscoveryIconHash(iconDefinition);
        return createDiscoveryIconStyle(iconDefinition, hash);
    },
    getDiscoveryIconAsComponent(iconDefinition: IIcon) {
        const hash = getDiscoveryIconHash(iconDefinition);
        return createDiscoveryIconComponent(iconDefinition, hash);
    },
    getIconAsOpenLayersStyle(object: IObject) {
        const hash = getIconHash(object);
        return (
            openLayersIconStyles[hash] ||
            createOpenLayersIconStyle(object, hash)
        );
    },
    getIconAsComponent(object: IObject) {
        const hash = getIconHash(object);
        const icon = icons[hash] || createIcon(object, hash);
        return (
            <span
                dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(icon.content),
                }}
            />
        );
    },
};
