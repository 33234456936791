import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import MobileStepper from '@material-ui/core/MobileStepper';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import { Tooltip } from '@material-ui/core';

import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import { useStyles } from './Themable.hooks';
import { useLanguage } from '../../state/login/index.hooks';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

interface IAdContent {
    content: {
        [key: string]: IAd;
    };
}
interface IAd {
    url: string;
    image: string;
    text: string;
}
interface IOwnProps {
    ads: IAdContent[];
    size: number;
}

const transformAds = (ads: IAdContent[], language: string) => {
    return ads
        .map((ad) => {
            const content = ad.content[language] || ad.content.en;

            if (content) {
                return {
                    image: content.image,
                    url: content.url,
                    text: content.text,
                };
            }

            return null;
        })
        .filter((ad) => ad !== null) as IAd[];
};

const AdsCarousel = ({ ads, size }: IOwnProps) => {
    const classes = useStyles({ size });
    const theme = useTheme();
    const [activeStep, setActiveStep] = React.useState(0);
    const language = useLanguage();

    function handleNext() {
        setActiveStep((prevActiveStep) => {
            return prevActiveStep === maxSteps - 1 ? 0 : prevActiveStep + 1;
        });
    }
    function handleBack() {
        setActiveStep((prevActiveStep) => {
            return prevActiveStep === 0 ? maxSteps - 1 : prevActiveStep - 1;
        });
    }

    function handleStepChange(adIndex: number) {
        setActiveStep(adIndex);
    }

    const data = transformAds(ads, language);
    const maxSteps = data.length;

    if (!data.length) {
        return <></>;
    }
    return (
        <div className={classes.root}>
            <AutoPlaySwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={activeStep}
                onChangeIndex={handleStepChange}
                enableMouseEvents
                interval={5000}
            >
                {data.map((ad, index) => {
                    return (
                        <div key={index}>
                            {Math.abs(activeStep - index) <= 2 ? (
                                <img
                                    className={classes.img}
                                    src={ad.image}
                                    alt={ad.text}
                                />
                            ) : null}
                        </div>
                    );
                })}
            </AutoPlaySwipeableViews>

            <a
                className={classes.a}
                href={data[activeStep]?.url}
                target="_blank"
            >
                {data[activeStep]?.text ? (
                    <Tooltip title={data[activeStep]?.text}>
                        <div className={classes.header}></div>
                    </Tooltip>
                ) : (
                    <div className={classes.header}></div>
                )}
            </a>

            {data.length > 1 && (
                <div className={classes.buttonsContainer}>
                    <div className={classes.buttons}>
                        <IconButton
                            size="small"
                            onClick={handleBack}
                            className={classes.button}
                        >
                            {theme.direction === 'rtl' ? (
                                <KeyboardArrowRight />
                            ) : (
                                <KeyboardArrowLeft />
                            )}
                        </IconButton>
                        <IconButton
                            size="small"
                            onClick={handleNext}
                            className={classes.button}
                        >
                            {theme.direction === 'rtl' ? (
                                <KeyboardArrowLeft />
                            ) : (
                                <KeyboardArrowRight />
                            )}
                        </IconButton>
                    </div>
                </div>
            )}
            <MobileStepper
                steps={maxSteps}
                position="static"
                variant="dots"
                activeStep={activeStep}
                className={classes.stepper}
                backButton={<div />}
                nextButton={<div />}
            />
        </div>
    );
};

export default AdsCarousel;
