import moment from 'moment-timezone';
import { makeReduxDuck } from 'teedux';

import locale from 'browser-locale';
import Cookie from 'cookie';
import URI from 'urijs';

import AppConfig from '../../constants/AppConfig';

import { IRequestError, makeRequest } from '../app/sync';

import { TRootState } from '../../store';
import { ICustomer, ILoginData, ILoginSuccessData, IXssLogin } from './types';

import {
    restConfirmEmail,
    restGetAds,
    restGetLanguages,
    restLoadCustomers,
    restLoginToXssTickets,
    restLoginUser,
    restLogoutUser,
} from '../../services/login';

const getCookies = () => {
    return Cookie.parse(document.cookie) || {};
};

const getBrowserLocale = (): string => locale().substring(0, 2);
interface IState {
    user?: string;
    token?: string;
    customer?: string;
    loginFailed: boolean;
    customers?: ICustomer[];
    error: boolean;
    language: string;
    languages?: string[];
    xssAddress?: string;
    fromPath?: string;
    ads?: IAdContent[];
}

export interface IAdContent {
    content: {
        [key: string]: IAd;
    };
}

export interface IAd {
    url: string;
    image: string;
    text: string;
}

const initialState: IState = {
    user: undefined,
    token: undefined,
    customer: undefined,
    loginFailed: false,
    customers: undefined,
    ads: undefined,
    error: false,
    language: getCookies().language || getBrowserLocale(),
    languages: undefined,
    xssAddress: undefined,
    fromPath: undefined,
};

const storePath = `app/login`;
const duck = makeReduxDuck(storePath, initialState);

export const loginUserSuccessAction = duck.definePayloadlessAction(
    'LOGIN_USER_SUCCESS',
    (state) => {
        const cookies = getCookies();
        const language = cookies.language || getBrowserLocale();
        moment.locale(language);

        return {
            ...state,
            language,
            token: cookies['XTrack-Web-Token'],
            user: cookies.login,
            customer: cookies.customer,
            error: false,
            xssAddress: undefined,
        };
    }
);

export const loginUserFailAction = duck.definePayloadlessAction(
    'LOGIN_USER_FAIL',
    (state) => ({
        ...state,
        error: true,
    })
);

export const logoutUserAction = duck.definePayloadlessAction(
    'LOGOUT_USER',
    () => {
        moment.locale(initialState.language);
        return initialState;
    }
);

export const storeCustomersAction = duck.defineAction<{
    data: ICustomer[];
}>('STORE_CUSTOMERS', (state, { data }) => ({
    ...state,
    customers: data,
}));

export const loginXssTicketsAction = duck.defineAction<{
    data: IXssLogin;
}>('LOGIN_XSS_TICKETS', (state, { data }) => {
    if (data && data.token) {
        const { token, host } = data;
        const protocol = AppConfig.instance.getConfigKey('httpsOnly');
        const isHttps = protocol === undefined || protocol;

        return {
            ...state,
            xssAddress:
                (isHttps ? 'https://' : 'http://') +
                host +
                '/' +
                state.language +
                '/serwis/tickets?token=' +
                token,
        };
    } else {
        return state;
    }
});

export const clearXssAddressAction = duck.definePayloadlessAction(
    'CLEAR_XSS_ADDRESS',
    (state) => ({
        ...state,
        xssAddress: undefined,
    })
);

export const storeLanguagesAction = duck.defineAction<{
    data: string[];
}>('STORE_LANGUAGES', (state, { data }) => ({
    ...state,
    languages: data,
}));

export const storeAdsAction = duck.defineAction<{
    data: IAdContent[];
}>('STORE_ADS', (state, { data }) => ({
    ...state,
    ads: data,
}));

export const savePathAction = duck.definePayloadlessAction(
    'SAVE_PATH',
    (state) => {
        const url = new URI(window.location.href);
        const pathname = url.pathname();

        return {
            ...state,
            fromPath: pathname,
        };
    }
);

export default duck.getReducer();

const makeLoginStorePath = () => `get:${storePath}`;
const makeLogoutStorePath = () => `get:${storePath}/logout`;
const makeCustomersStorePath = () => `get:${storePath}/customers`;
const makeXssStorePath = () => `get:${storePath}/xss`;
const makeConfirmEmailStorePath = () => `get:${storePath}/confirm/email`;
const makeLanguagesStorePath = () => `get:${storePath}/languages`;

export const loginUser = (
    loginObj: ILoginData,
    afterSuccess?: (data: ILoginSuccessData) => void,
    afterFail?: (error: Error) => void
) =>
    makeRequest(
        makeLoginStorePath(),
        () => restLoginUser(loginObj),
        (dispatch, data) => {
            if (afterSuccess) {
                afterSuccess(data);
            }
        },
        (dispatch, error) => {
            dispatch(loginUserFailAction());
            if (afterFail) {
                afterFail(error);
            }
        },
        undefined,
        true
    );

export const logoutUser = (
    afterSuccess?: () => void,
    afterFail?: (error: IRequestError) => void
) =>
    makeRequest(
        makeLogoutStorePath(),
        () => restLogoutUser(),
        (dispatch, data) => {
            dispatch(logoutUserAction());
            if (afterSuccess) {
                afterSuccess();
            }
        },
        (dispatch, error) => {
            dispatch(logoutUserAction());
            if (afterFail) {
                afterFail(error);
            }
        }
    );

export const fetchCustomers = (
    afterSuccess?: (data: ICustomer[]) => void,
    afterFail?: (error: Error) => void
) =>
    makeRequest(
        makeCustomersStorePath(),
        () => restLoadCustomers(),
        (dispatch, data) => {
            dispatch(storeCustomersAction({ data }));
            if (afterSuccess) {
                afterSuccess(data);
            }
        },
        (dispatch, error) => {
            if (afterFail) {
                afterFail(error);
            }
        }
    );

export const loginXssTickets = (
    email: string,
    afterSuccess?: (data: IXssLogin) => void,
    afterFail?: (error: Error) => void
) =>
    makeRequest(
        makeXssStorePath(),
        () => restLoginToXssTickets(email),
        (dispatch, data) => {
            dispatch(loginXssTicketsAction({ data }));
            if (afterSuccess) {
                afterSuccess(data);
            }
        },
        (dispatch, error) => {
            if (afterFail) {
                afterFail(error);
            }
        }
    );

export const confirmEmail = (
    token: string,
    email: string,
    afterSuccess?: () => void,
    afterFail?: (error: Error) => void
) =>
    makeRequest(
        makeConfirmEmailStorePath(),
        () => restConfirmEmail(token, email),
        (dispatch, data) => {
            if (afterSuccess) {
                afterSuccess();
            }
        },
        (dispatch, error) => {
            if (afterFail) {
                afterFail(error);
            }
        }
    );

export const fetchLanguages = () =>
    makeRequest(
        makeLanguagesStorePath(),
        () => restGetLanguages(),
        (dispatch, data) => dispatch(storeLanguagesAction({ data })),
        (dispatch, error) => undefined
    );

export const fetchAds = (targetId: string) =>
    makeRequest(
        makeLanguagesStorePath(),
        () => restGetAds(targetId),
        (dispatch, data) => dispatch(storeAdsAction({ data })),
        (dispatch, error) => undefined
    );

export const savePath = () => savePathAction();

export const getLoginState = (state: TRootState) => state.login;

export const getUserLogin = (state: TRootState) => getLoginState(state).user;
export const getLoginToken = (state: TRootState) => getLoginState(state).token;
export const getCustomer = (state: TRootState) => getLoginState(state).customer;
export const getCustomers = (state: TRootState) =>
    getLoginState(state).customers;
export const getAds = (state: TRootState) => getLoginState(state).ads;
export const getLoginFailed = (state: TRootState) =>
    getLoginState(state).loginFailed;
export const getLoginError = (state: TRootState) => getLoginState(state).error;
export const getLanguage = (state: TRootState) => getLoginState(state).language;
export const getLanguages = (state: TRootState) =>
    getLoginState(state).languages;
export const getXssAddress = (state: TRootState) =>
    getLoginState(state).xssAddress;
export const getFromPath = (state: TRootState) => getLoginState(state).fromPath;
