import { useSelector } from 'react-redux';

import {
    getAlertDialog,
    getAlertsObjectId,
    getAlertsStatuses,
    getAlertsStatusesAsString,
    getFoundObjects,
    getNewAlertsMessage,
    getObjectFilter,
    getShouldRefreshData,
    getTab,
} from './index';

export const useAlertStatuses = () => useSelector(getAlertsStatuses);
export const useAlertStatusesAsString = () =>
    useSelector(getAlertsStatusesAsString);
export const useAlertsObjectId = () => useSelector(getAlertsObjectId);
export const useFoundObjects = () => useSelector(getFoundObjects);
export const useObjectFilter = () => useSelector(getObjectFilter);
export const useNewAlertsMessage = () => useSelector(getNewAlertsMessage);
export const useShouldRefreshAlertsData = () =>
    useSelector(getShouldRefreshData);
export const useTab = () => useSelector(getTab);
export const useDialog = () => useSelector(getAlertDialog);
