import { createStyles, Theme, WithStyles } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import blue from '@material-ui/core/colors/blue';
import green from '@material-ui/core/colors/green';
import orange from '@material-ui/core/colors/orange';
import red from '@material-ui/core/colors/red';
import { SATURATION } from '../../constants/dictionaries/ColorConst';

const styles = (theme: Theme) => {
    const padding = theme.spacing(1);
    return createStyles({
        alertCard: {
            marginBottom: padding * 2,
            padding,
        },
        errorCard: {
            backgroundColor: red[SATURATION],
            color: theme.palette.getContrastText(red[SATURATION]),
        },
        infoCard: {
            backgroundColor: blue[SATURATION],
            color: theme.palette.getContrastText(blue[SATURATION]),
        },
        warningCard: {
            backgroundColor: orange[SATURATION],
            color: theme.palette.common.white,
        },
        successCard: {
            backgroundColor: green[SATURATION],
            color: theme.palette.common.white,
        },
    });
};

export type TThemableProps = WithStyles<typeof styles>;

export const Themable = withStyles(styles, { withTheme: true });
