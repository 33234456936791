import { default as Divider } from '@material-ui/core/Divider';
import { default as Paper } from '@material-ui/core/Paper';
import { withSnackbar, WithSnackbarProps } from 'notistack';
import React, { Component, ComponentType } from 'react';
import { compose } from 'redux';

import { Connectable, TConnectableProps } from './Connectable.hoc';
import { Themable, TThemableProps } from './Themable.hoc';
import { IOwnProps } from './types';

import { responseMessage } from '../../../../helpers/responseMessage';
import TranslationHelper from '../../../../helpers/TranslationHelper';

import { default as LinearProgress } from '@material-ui/core/LinearProgress';

import { ColumnApi, GridApi } from 'ag-grid-community';

import PaneHeader from '../../../../components/PaneHeader';
import SourceSetGrid from '../../../../components/SourceSetGrid';
import AnalyticsGrid from '../AnalyticsGrid';
import EditOverlay from '../EditOverlay';
import SourceSetGridChart from '../SourceSetGridChart';
import GridHeaderLeftControls from './components/GridHeaderLeftControls';
import GridHeaderRightControls from './components/GridHeaderRightControls';

interface IState {
    gridApi?: GridApi;
    gridColumnApi?: ColumnApi;
    shouldClearFocusedCell: boolean;
    fetching: boolean;
    filter: string;
}

type TProps = IOwnProps &
    TThemableProps &
    TConnectableProps &
    WithSnackbarProps;

class DataGridPane extends Component<TProps, IState> {
    public state = {
        gridApi: undefined,
        gridColumnApi: undefined,
        shouldClearFocusedCell: false,
        fetching: false,
        filter: '',
    };

    public setGridApi = (
        gridApi: GridApi,
        gridColumnApi: ColumnApi | undefined = undefined
    ) => {
        this.setState({
            gridApi,
            gridColumnApi,
        });
    };

    public componentDidMount() {
        this.handleFilterModelChange();
    }
    public handleFilterModelChange = () => {
        const { sourceSetModel } = this.props;
        if (sourceSetModel) {
            this.handleFilterChange(sourceSetModel.quickFilter);
        } else {
            this.handleFilterChange('');
        }
    };
    public componentDidUpdate(prevProps: Readonly<TProps>): void {
        const { isChart, sourceSetId, fetching, stopFetchingCollection } =
            this.props;

        const sourceSetName =
            sourceSetId && fetching[sourceSetId + 'Fetching']
                ? sourceSetId + 'Fetching'
                : 'sourceSetFetching';
        const fetchData = fetching[sourceSetName] || null;
        const prevFetchData = prevProps?.fetching[sourceSetName] || null;

        if (fetchData.error && fetchData.error !== prevFetchData?.error) {
            this.showError(fetchData.error.status);
        }

        const isFetching: boolean = fetchData ? fetchData.isFetching : false;

        if (this.state.fetching !== isFetching) {
            this.setState({
                fetching: isFetching,
            });
        }
        if (sourceSetId !== prevProps.sourceSetId) {
            stopFetchingCollection(prevProps?.sourceSetId || '');

            this.handleFilterModelChange();
        }
        if (sourceSetId !== 'lastStates') {
            stopFetchingCollection('lastStates');
        }
        if (!prevProps.isChart && isChart) {
            this.setState({
                gridApi: undefined,
                gridColumnApi: undefined,
            });
        }
    }

    public handleClearClick = () => {
        const {
            creatorLevel,
            queueMapFitToExtent,
            resetLevel,
            stopFetchingCollection,
            sourceSetId,
        } = this.props;
        if (sourceSetId !== 'lastStates') {
            stopFetchingCollection(sourceSetId || '');
        }
        resetLevel(creatorLevel);
        queueMapFitToExtent();
    };

    public handleFilterChange = (filter: string) => {
        this.setState({ filter });
    };

    public showError = (errorCode: number = 0): void => {
        const message = responseMessage(errorCode);
        this.props.enqueueSnackbar(TranslationHelper.translate(message.text), {
            variant: message.type,
        });

        this.handleClearClick();
    };

    public getGridTitle() {
        const { sourceSet, isAnalyticsEnabled } = this.props;

        if (sourceSet && isAnalyticsEnabled) {
            return `${TranslationHelper.translate(
                sourceSet.label
            )} (${TranslationHelper.translate(
                'Analytics'
            )} ${TranslationHelper.translate('BETA')})`;
        }

        if (sourceSet) {
            return TranslationHelper.translate(sourceSet.label);
        }

        return TranslationHelper.translate('Loading...');
    }

    public getRightCustomControls = () => {
        const { gridApi, gridColumnApi } = this.state;

        return (
            <GridHeaderRightControls
                gridApi={gridApi}
                gridColumnApi={gridColumnApi}
            />
        );
    };

    public getLeftCustomControls = () => {
        const { customComponents, sourceSet, isChart } = this.props;

        return customComponents?.leftHeader ? (
            <>
                {customComponents.leftHeader(
                    sourceSet,
                    this.state.filter,
                    this.handleFilterChange
                )}
            </>
        ) : (
            <GridHeaderLeftControls
                showSearchField={!isChart}
                sourceSet={sourceSet}
                searchPhrase={this.state.filter}
                setGridSearch={this.handleFilterChange}
            />
        );
    };

    public render() {
        const {
            sourceSet,
            selectedSourceSetElementId,
            classes,
            layout,
            isChart,
            cellInteractionHandler,
            isAnalyticsEnabled,
        } = this.props;
        const { gridApi } = this.state;

        return (
            <Paper className={classes.pane}>
                <div className={classes.wrapper}>
                    <PaneHeader
                        title={this.getGridTitle()}
                        onCloseClick={this.handleClearClick}
                        renderRightCustomControls={
                            gridApi
                                ? () => this.getRightCustomControls()
                                : undefined
                        }
                        renderLeftCustomControls={
                            gridApi
                                ? () => this.getLeftCustomControls()
                                : undefined
                        }
                    />

                    {!sourceSet || this.state.fetching ? (
                        <LinearProgress />
                    ) : (
                        <Divider />
                    )}

                    {sourceSet && isAnalyticsEnabled && (
                        <AnalyticsGrid
                            sourceSet={sourceSet}
                            sourceSetModel={this.props.sourceSetModel}
                            quickFilterValue={this.state.filter}
                            setGridApi={this.setGridApi}
                        />
                    )}
                    {sourceSet && isChart && !isAnalyticsEnabled && (
                        <SourceSetGridChart
                            key={sourceSet.id + layout}
                            sourceSet={sourceSet}
                            creatorLevel={this.props.creatorLevel}
                            sourceSetModel={this.props.sourceSetModel}
                            selectedSourceSetElementId={
                                selectedSourceSetElementId
                            }
                        />
                    )}
                    {sourceSet && isChart === false && !isAnalyticsEnabled && (
                        <SourceSetGrid
                            key={sourceSet.id}
                            sourceSet={sourceSet}
                            setGridApi={this.setGridApi}
                            sourceSetModel={this.props.sourceSetModel}
                            selectedSourceSetElementId={
                                selectedSourceSetElementId
                            }
                            cellInteractionHandler={cellInteractionHandler}
                            quickFilterValue={this.state.filter}
                        />
                    )}
                    <EditOverlay />
                </div>
            </Paper>
        );
    }
}

export default compose(
    Themable,
    Connectable,
    withSnackbar
)(DataGridPane) as ComponentType<IOwnProps>;
