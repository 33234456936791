import { IDictionaryItem } from '../../../../../../state/app/dictionaries';

export const getTypesIds = (
    activityCategory: number,
    serviceType: IDictionaryItem[],
    objectCategory: IDictionaryItem[]
) => {
    const filteredServiceType = serviceType.filter(
        (item: IDictionaryItem) => item.parentId === activityCategory
    );
    const filteredObjectCategory = objectCategory.filter(
        (item: IDictionaryItem) => item.parentId === activityCategory
    );
    let serviceTypeId: number | '' = '';
    let objectCategoryId: number | '' = '';

    if (filteredServiceType.length === 1) {
        serviceTypeId = filteredServiceType[0].id;
    }
    if (filteredObjectCategory.length === 1) {
        objectCategoryId = filteredObjectCategory[0].id;
    }
    return { serviceTypeId, objectCategoryId };
};
