import { createStyles, Theme, WithStyles } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme: Theme) => {
    return createStyles({
        wrapper: {
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 20,
            overflowY: 'auto',
            [theme.breakpoints.down('md')]: {
                borderRadius: 0,
            },
        },
    });
};

export type TThemableProps = WithStyles<typeof styles>;

export const Themable = withStyles(styles, { withTheme: true });
