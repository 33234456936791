import React, { Component, ComponentType } from 'react';
import { compose } from 'redux';

import { Themable, TThemableProps } from '../../Themable.hoc';
import { Connectable, TConnectableProps } from './Connectable.hoc';

import TranslationHelper from '../../../../helpers/TranslationHelper';

import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Typography from '@material-ui/core/Typography';

import { Link } from 'react-router-dom';

import { Field, Form, Formik } from 'formik';
import FieldWrapper from '../../../../components/common/formikWrappers/FieldWrapper';

import { passwordSchema } from '../../../../schemas';

interface IOwnProps {
    token: string;
    onSubmit: (
        password: string,
        token: string,
        onSuccess: () => void,
        onFailure: (error: any) => void
    ) => void;
    backDisabled?: boolean;
    successInfo?: string;
    login?: string;
    title?: string;
    confirmButtonLabel?: string;
}

type TProps = TThemableProps & TConnectableProps & IOwnProps;

interface IPasswordResetForm {
    password: string;
    passwordRepeat: string;
}

interface IState {
    blankForm: IPasswordResetForm;
    serverError?: string;
    fetching: boolean;
    passwordChanged: boolean;
}
class ResetPassword extends Component<TProps> {
    public state: IState = {
        blankForm: {
            password: '',
            passwordRepeat: '',
        },
        serverError: undefined,
        fetching: false,
        passwordChanged: false,
    };

    public render() {
        const { blankForm, serverError, passwordChanged, fetching } =
            this.state;

        const {
            classes,
            successInfo = 'Password has been changed',
            login,
        } = this.props;

        return (
            <Formik
                enableReinitialize={true}
                initialValues={blankForm}
                validateOnBlur={false}
                validateOnChange={false}
                onSubmit={this.handleValues}
                validationSchema={passwordSchema()}
            >
                {({ submitForm, errors, values, setFieldValue }) => (
                    <Form>
                        {!passwordChanged && (
                            <>
                                <Typography
                                    variant="h6"
                                    gutterBottom
                                    align="center"
                                >
                                    {TranslationHelper.translate(
                                        this.props.title ?? 'Enter new password'
                                    )}
                                    {login &&
                                        ` ${TranslationHelper.translate(
                                            'for login'
                                        )} ${login}`}
                                </Typography>

                                {login && (
                                    <Field
                                        id="login"
                                        name="login"
                                        type="text"
                                        value={login}
                                        component={FieldWrapper}
                                        disabled
                                        style={{ display: 'none' }}
                                    />
                                )}
                                <Field
                                    id="password-id"
                                    error={!!(errors && errors.password)}
                                    helperText={
                                        (errors && errors.password) ||
                                        TranslationHelper.translate(
                                            `Make sure it's at least 8 characters including a number, a lowercase and uppercase letter`
                                        )
                                    }
                                    name={'password'}
                                    label={TranslationHelper.translate(
                                        'Password'
                                    )}
                                    type={'password'}
                                    fullWidth={true}
                                    component={FieldWrapper}
                                    className={classes.formField}
                                    margin="dense"
                                    variant="outlined"
                                />

                                <Field
                                    id="password-repeat-id"
                                    error={!!(errors && errors.passwordRepeat)}
                                    helperText={errors && errors.passwordRepeat}
                                    name={'passwordRepeat'}
                                    label={TranslationHelper.translate(
                                        'Repeat password'
                                    )}
                                    type={'password'}
                                    fullWidth={true}
                                    component={FieldWrapper}
                                    className={classes.formField}
                                    margin="dense"
                                    variant="outlined"
                                />
                            </>
                        )}

                        {passwordChanged && (
                            <>
                                <Typography
                                    variant="h6"
                                    gutterBottom
                                    align="center"
                                >
                                    {TranslationHelper.translate(
                                        'Confirmation'
                                    )}
                                </Typography>
                                <FormControl>
                                    <FormHelperText id="component-error-text">
                                        {TranslationHelper.translate(
                                            successInfo
                                        )}
                                    </FormHelperText>
                                </FormControl>
                            </>
                        )}

                        <div className={classes.formButtons}>
                            {!this.props.backDisabled && (
                                <Link to={'/login'}>
                                    <Button
                                        type="button"
                                        color="primary"
                                        variant="contained"
                                    >
                                        {TranslationHelper.translate('Back')}
                                    </Button>
                                </Link>
                            )}
                            {!passwordChanged && (
                                <Button
                                    type="submit"
                                    color="primary"
                                    variant="contained"
                                    disabled={fetching}
                                >
                                    {TranslationHelper.translate(
                                        this.props.confirmButtonLabel ??
                                            'Change password'
                                    )}
                                </Button>
                            )}
                        </div>
                        {serverError && (
                            <FormControl error={true}>
                                <FormHelperText id="component-error-text">
                                    {serverError}
                                </FormHelperText>
                            </FormControl>
                        )}
                    </Form>
                )}
            </Formik>
        );
    }

    private handleValues = ({ passwordRepeat }: IPasswordResetForm) => {
        const { token, onSubmit, logout } = this.props;
        this.setState({ fetching: true, serverError: undefined });
        onSubmit(
            passwordRepeat,
            token,
            () => {
                this.setState({ passwordChanged: true, fetching: false });
                logout();
            },
            (error) => {
                if (error.status !== 200) {
                    switch (error.status) {
                        case 401:
                            this.setState({
                                serverError: TranslationHelper.translate(
                                    'Token has already expired'
                                ),
                            });
                            break;
                        case 404:
                            this.setState({
                                serverError: TranslationHelper.translate(
                                    'User could not be found'
                                ),
                            });
                            break;
                        case 409:
                            this.setState({
                                serverError: TranslationHelper.translate(
                                    'Could not identify a user'
                                ),
                            });
                            break;
                        default:
                            this.setState({
                                serverError: TranslationHelper.translate(
                                    'Internal server error. Please contact with administrator.'
                                ),
                            });
                            break;
                    }
                }
                this.setState({ fetching: false });
            }
        );
    };
}

export default compose(
    Themable,
    Connectable
)(ResetPassword) as ComponentType<IOwnProps>;
