import { Dispatch } from 'redux';
import { fetchReportSet } from '../../../../services/discovery/fetchReportSet';
import { makeFetchModule } from '../../../_utils';
import { storeReportSets } from '../../entities';

const callbacks = {
    onSuccess: (dispatch: Dispatch, { entities }: { entities: object }) => {
        dispatch(storeReportSets(entities));
    },
};
const { reducer, fetchData, reset } = makeFetchModule(
    'app/discovery/reportSetFetching',
    fetchReportSet,
    callbacks
);

export default reducer;
export { fetchData, reset };
