import { createSlice } from '@reduxjs/toolkit';
import { TRootState } from '../../../store';

const getCurrent = (state: TRootState) => state.ui.discoverySlice as IState;

export const getSettings = (state: TRootState) =>
    getCurrent(state).playerSettings;

export interface IPlayerSettings {
    labels?: boolean;
    model?: string;
    classThreshold?: number;
    objectThreshold?: number;
}

interface IState {
    playerSettings: IPlayerSettings | null;
}

export const initialState: IState = {
    playerSettings: null,
};

const playerSettings = createSlice({
    name: 'playerSettings',
    initialState,
    reducers: {
        setPlayerSettings(state, action) {
            state.playerSettings = action.payload;
        },
    },
});

export const { setPlayerSettings } = playerSettings.actions;

export default playerSettings.reducer;
