import { IFuelTanks, IFuelTanksEvents } from '../../state/app/fuelTanks';
import URI from 'urijs';

import AppConfig from '../../constants/AppConfig';
import UrlConst from '../../constants/UrlConst';

import apiClient from '../common/apiClient';
import { makeGetRequestSettings, makeUrl } from '../common/utils';

export interface IRestGetFuelTanksParams {
    from: string;
    to: string;
    states: string;
}

const makeFuelTanksEventsUrl = (suffix: string, params = {}) => {
    const url = new URI(
        AppConfig.instance.getUrl(UrlConst.FUELTANKS_URL) + suffix
    );
    const updatedParams = { ...params };
    return makeUrl(url, updatedParams);
};

export const restGetMonitoredFuelTanks = (): Promise<IFuelTanks> =>
    apiClient.send(
        makeGetRequestSettings(makeFuelTanksEventsUrl('/', { limit: 100000 }))
    );

export const restGetFuelTankEvents = (
    id: string,
    params: IRestGetFuelTanksParams
): Promise<IFuelTanksEvents> =>
    apiClient.send(
        makeGetRequestSettings(
            makeFuelTanksEventsUrl(`/${id}/events`, {
                ...params,
                limit: 100000,
                orderAscending: false,
            })
        )
    );
