import { IRightItem } from '../../state/ui/usersSlice';

export const SPECIAL_DATA_RIGHTS: IRightItem[] = [
    {
        name: 'ALL_VEHICLES',
        relationId: 'ALL_VEHICLES',
        type: 'SPECIAL',
    },
    {
        name: 'ALL_EMPLOYEES',
        relationId: 'ALL_EMPLOYEES',
        type: 'SPECIAL',
    },
    {
        name: 'ALL_TANKS',
        relationId: 'ALL_TANKS',
        type: 'SPECIAL',
    },
    {
        name: 'ALL_CARDS',
        relationId: 'ALL_CARDS',
        type: 'SPECIAL',
    },
];
