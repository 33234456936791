import React, { useEffect } from 'react';

import moment from 'moment';

import { Field, Form, FormikErrors, FormikValues } from 'formik';

import { useUserSettings } from '../../../../../../../state/user/index.hooks';

import TranslationHelper from '../../../../../../../helpers/TranslationHelper';
import { renderMenuItems } from '../../../../../../../helpers/renderMenuItems';
import TimeFormatter from '../../../../../../../helpers/TimeFormatter';

import { IServiceClient } from '../../../../../../../models/customerService';

import {
    CLIENTS_STATUS_ARRAY,
    STATUS_ARCHIVAL,
} from '../../../../../../../constants/dictionaries/CscClientsStatus';

import FieldWrapper from '../../../../../../../components/common/formikWrappers/FieldWrapper';
import { useStyles } from '../../../../../Themable.hooks';
import DateFieldWrapper from '../../../../../../../components/common/formikWrappers/DateFieldWrapper';
import CustomCheckbox from '../../../../../../../components/common/CustomCheckbox';

interface IOwnProps {
    values: FormikValues;
    errors: FormikErrors<IServiceClient>;
    readOnly?: boolean;
    setFieldValue: (name: string, value: any) => void;
}
const ClientForm = ({
    values,
    errors,
    readOnly = false,
    setFieldValue,
}: IOwnProps) => {
    const classes = useStyles();

    const userSettings = useUserSettings();

    const commonProps = {
        fullWidth: true,
        component: FieldWrapper,
        disabled: readOnly,
    };

    useEffect(() => {
        if (Number(values.status) === STATUS_ARCHIVAL) {
            if (!values.archivalDate) {
                setFieldValue('archivalDate', moment());
            }

            setFieldValue('noDate', false);
        }
    }, [values.status]);

    const renderNoDate =
        values.archivalDate &&
        Number(values.status) !== STATUS_ARCHIVAL &&
        !readOnly;
    return (
        <Form>
            <div className={classes.formWrapper}>
                <Field
                    error={!!(errors && errors.name)}
                    helperText={errors && errors.name}
                    name={'name'}
                    label={TranslationHelper.translate('Name')}
                    type={'text'}
                    required={true}
                    value={values.name}
                    {...commonProps}
                />
                <Field
                    error={!!(errors && errors.shortName)}
                    helperText={errors && errors.shortName}
                    name={'shortName'}
                    label={TranslationHelper.translate('Short name')}
                    type={'text'}
                    value={values.shortName}
                    {...commonProps}
                />

                <Field
                    error={!!(errors && errors.externalNumber)}
                    helperText={errors && errors.externalNumber}
                    name={'externalNumber'}
                    label={TranslationHelper.translate('External number')}
                    type={'text'}
                    value={values.externalNumber}
                    {...commonProps}
                />
                <Field
                    error={!!(errors && errors.vatin)}
                    helperText={errors && errors.vatin}
                    name={'vatin'}
                    label={TranslationHelper.translate('Tax number')}
                    type={'text'}
                    value={values.vatin}
                    {...commonProps}
                />
                <Field
                    error={!!(errors && errors.notice)}
                    helperText={errors && errors.notice}
                    name={'notice'}
                    label={TranslationHelper.translate('Notice')}
                    type={'text'}
                    value={values.notice}
                    {...commonProps}
                />
                {values.status ? (
                    <Field
                        error={!!(errors && errors.status)}
                        helperText={errors && errors.status}
                        name={'status'}
                        label={TranslationHelper.translate('Status')}
                        select={true}
                        required={true}
                        value={values.status}
                        {...commonProps}
                    >
                        {renderMenuItems(CLIENTS_STATUS_ARRAY, (item) => {
                            return {
                                id: String(item.id),
                                name: item.name,
                            };
                        })}
                    </Field>
                ) : null}
                {(values.archivalDate ||
                    Number(values.status) === STATUS_ARCHIVAL) && (
                    <Field
                        error={!!(errors && errors.archivalDate)}
                        helperText={errors && errors.archivalDate}
                        name={'archivalDate'}
                        label={TranslationHelper.translate('Archival date')}
                        {...commonProps}
                        component={DateFieldWrapper}
                        required={true}
                        displayFormat={userSettings.shortDateFormat}
                        value={values.noDate ? null : values.archivalDate}
                        withDateFormatter={TimeFormatter.dateToShortDateString}
                        disabled={
                            Number(values.status) !== STATUS_ARCHIVAL ||
                            readOnly
                        }
                    />
                )}
                {renderNoDate && (
                    <Field
                        name={'noDate'}
                        label={TranslationHelper.translate('Remove archiving')}
                        checked={values.noDate}
                        onChange={() => {
                            setFieldValue('noDate', !values.noDate);
                        }}
                        component={CustomCheckbox}
                        useContrastColors={false}
                    />
                )}
            </div>
        </Form>
    );
};

export default ClientForm;
