import React from 'react';

import { Button } from '@material-ui/core';

import TranslationHelper from '../../helpers/TranslationHelper';

interface IOwnProps {
    href: string;
    label: string;
}
const ExternalLinkButton = ({ href, label }: IOwnProps) => {
    return (
        <Button
            fullWidth
            style={{ justifyContent: 'flex-start' }}
            href={href}
            target={'_blank'}
            rel={'noreferrer'}
        >
            {TranslationHelper.translate(label)}
        </Button>
    );
};

export default ExternalLinkButton;
