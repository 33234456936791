import React, { ReactChild } from 'react';
import { Link } from 'react-router-dom';
import TranslationHelper from '../../helpers/TranslationHelper';

interface IOwnProps {
    children: ReactChild;
}

const menuLessErrorTemplate = ({ children }: IOwnProps) => {
    return (
        <div id="login-bg">
            <div className="login-form">
                <div className="ribbon-logo" />
                <div className="menuless-task-page">
                    <div className="task">
                        <div className="error-page">
                            {children}
                            <Link to="">
                                {TranslationHelper.translate(
                                    'Back to main page'
                                )}
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default menuLessErrorTemplate;
