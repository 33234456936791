import { connect } from 'react-redux';
import { isBottomGridInEditMode } from '../../../../state/ui/customerService/bottomGrid';
import { isClientsAndLocationsInEditMode } from '../../../../state/ui/customerService/clientsAndLocations';
import { reportsPaneVisible } from '../../../../state/ui/reportsSlice';
import { TRootState } from '../../../../store';
import { isManagemenInEditMode } from '../../../management/selectors';
import { isAdminInEditMode } from '../../../users/selectors';
import { shouldDiscoveryBeInEditMode } from '../../selectors';
import { isRoutesPaneInEditMode } from '../../../../state/ui/planning/routes';

const mapStateToProps = (state: TRootState) => ({
    discoveryEdit: shouldDiscoveryBeInEditMode(state),
    adminEdit: isAdminInEditMode(state),
    managementEdit: isManagemenInEditMode(state),
    reportsEdit: reportsPaneVisible(state),
    clientsAndLocationsEdit: isClientsAndLocationsInEditMode(state),
    bottomGridEdit: isBottomGridInEditMode(state),
    planningEdit: isRoutesPaneInEditMode(state),
});

const mapDispatchToProps = {};

type TStateProps = ReturnType<typeof mapStateToProps>;
type TDispatchProps = typeof mapDispatchToProps;

export type TConnectableProps = TStateProps & TDispatchProps;

export const Connectable = connect(mapStateToProps, mapDispatchToProps);
