import { connect } from 'react-redux';

import { activateGrid, resetLevel } from '../../../../state/_actions';
import { setMobileGridVisibility } from '../../../../state/ui/discovery/snapshotting';
import { TRootState } from '../../../../store';
import {
    getGridSourceSetId,
    getSnapshotPaths,
    isMobileGridVisible,
} from '../../selectors';

const mapStateToProps = (state: TRootState) => ({
    listVisible: isMobileGridVisible(state),
    sourceSetId: getGridSourceSetId(state),
    snapshotPaths: getSnapshotPaths(state),
});

const mapDispatchToProps = {
    activateGrid,
    resetLevel,
    setMobileGridVisibility,
};

type TStateProps = ReturnType<typeof mapStateToProps>;
type TDispatchProps = typeof mapDispatchToProps;

export type TConnectableProps = TStateProps & TDispatchProps;

export const Connectable = connect(mapStateToProps, mapDispatchToProps);
