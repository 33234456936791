import React, { Component, ComponentType } from 'react';

import {
    CartesianGrid,
    ComposedChart,
    LabelList,
    Line,
    ResponsiveContainer,
    Scatter,
    Tooltip,
    TooltipPayload,
    XAxis,
    YAxis,
} from 'recharts';
import { compose } from 'redux';
import ChartParamsList from '../../../../../../components/ChartParamsList';
import TimeFormatter from '../../../../../../helpers/TimeFormatter';
import TranslationHelper from '../../../../../../helpers/TranslationHelper';
import { Connectable, TConnectableProps } from './Connectable.hoc';
import { Themable, TThemableProps } from './Themable.hoc';
import { COLORS } from '../../../../../../constants/ColorConst';

type TProps = TThemableProps & TConnectableProps;

const dataSets = [
    {
        id: 'raw',
        name: TranslationHelper.translate('Parameter'),
        dataKey: 'y',
        color: COLORS.navy,
        type: 'line',
    },
    {
        id: 'average',
        name: TranslationHelper.translate('Parameter average'),
        dataKey: 'averageY',
        color: COLORS.red,
        type: 'scatter',
    },
    {
        id: 'calibrated',
        name: TranslationHelper.translate('Parameter after calibration'),
        dataKey: 'calibratedY',
        color: COLORS.green,
        type: 'line',
    },
    {
        id: 'refuelingAmount',
        name: TranslationHelper.translate('Refueling amount'),
        dataKey: 'refuelingAmount',
        color: COLORS.orange,
        type: 'scatter',
    },
];

class CalibrationChart extends Component<TProps> {
    public render() {
        const { classes, activeAttributes, data, type, selectedParamId } =
            this.props;

        return (
            <div className={classes.container}>
                {selectedParamId && data ? (
                    <>
                        <ChartParamsList
                            handleAttributeChange={this.handleAttributeChange}
                            dataSets={dataSets}
                            activeAttributes={activeAttributes}
                        />
                        <div className={classes.chart}>
                            <ResponsiveContainer height="100%" width="100%">
                                <ComposedChart
                                    margin={{
                                        top: 40,
                                        right: 20,
                                        bottom: 10,
                                        left: 10,
                                    }}
                                    data={data}
                                >
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis
                                        dataKey={type}
                                        tickFormatter={this.getFormattedLabel}
                                    />
                                    <YAxis />
                                    {dataSets
                                        .filter(
                                            (dataSet) =>
                                                activeAttributes[
                                                    dataSet.dataKey
                                                ]
                                        )
                                        .map((dataSet) =>
                                            dataSet.type === 'line' ? (
                                                <Line
                                                    name={dataSet.name}
                                                    dataKey={dataSet.dataKey}
                                                    stroke={dataSet.color}
                                                    dot={false}
                                                    key={dataSet.id}
                                                    isAnimationActive={false}
                                                />
                                            ) : (
                                                <Scatter
                                                    name={dataSet.name}
                                                    dataKey={dataSet.dataKey}
                                                    fill={dataSet.color}
                                                    key={dataSet.id}
                                                    isAnimationActive={false}
                                                >
                                                    <LabelList
                                                        dataKey={
                                                            dataSet.dataKey
                                                        }
                                                        position="top"
                                                        formatter={
                                                            this
                                                                .getAveragePointLabelFormatter
                                                        }
                                                    />
                                                </Scatter>
                                            )
                                        )}
                                    <Tooltip
                                        formatter={this.getTooltipFormatter}
                                        labelFormatter={this.getFormattedLabel}
                                    />
                                </ComposedChart>
                            </ResponsiveContainer>
                        </div>
                    </>
                ) : (
                    <div className={classes.placeholder}>
                        <p>
                            {TranslationHelper.translate(
                                'Select a parameter to calibrate'
                            )}
                        </p>
                        <p>
                            {TranslationHelper.translate(
                                'Choose a date for archive events'
                            )}
                        </p>
                    </div>
                )}
            </div>
        );
    }

    private handleAttributeChange = (name: string) => {
        this.props.updateCalibrationChartAttribute(name);
    };

    private getFormattedLabel = (value: any) => {
        if (typeof value === 'string') {
            return TimeFormatter.dateToString(value, this.props.dateFormat);
        }

        return typeof value === 'number' ? value.toFixed(2) : value;
    };

    private getTooltipFormatter = (
        value: string | number | Array<string | number>,
        name: string,
        props: TooltipPayload
    ) => {
        if (props.dataKey === 'time') {
            return [value, name];
        }
        return [
            (value as number).toFixed(2),
            TranslationHelper.translate(name),
        ];
    };

    private getAveragePointLabelFormatter = (value: any) =>
        (value as number).toFixed(2);
}

export default compose(
    Themable,
    Connectable
)(CalibrationChart) as ComponentType<{}>;
