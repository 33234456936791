import React from 'react';

import MenuLessErrorTemplate from './menuLessErrorTemplate';
import TranslationHelper from '../../helpers/TranslationHelper';

const Page403 = () => {
    return (
        <MenuLessErrorTemplate>
            <div style={{ marginBottom: '50px' }}>
                {TranslationHelper.translate(
                    'No access to the resource due to insufficient permissions'
                )}
            </div>
        </MenuLessErrorTemplate>
    );
};

export default Page403;
