import { createStyles, WithStyles } from '@material-ui/core';
import { Theme, withStyles } from '@material-ui/core/styles';

const styles = (theme: Theme) => {
    return createStyles({
        plusIconButton: {
            color: theme.palette.getContrastText(theme.palette.primary.main),
        },
        rolesTableWrapper: {
            maxHeight: '100%',
            overflowY: 'hidden',
            overflowX: 'hidden',
        },
        listItem: {
            width: '80%',
        },
    });
};

export type TThemableProps = WithStyles<typeof styles>;

export const Themable = withStyles(styles, { withTheme: true });
