import React, { useEffect, useState } from 'react';

import { FormikErrors, FormikValues } from 'formik';
import { useSnackbar } from 'notistack';

import { LinearProgress } from '@material-ui/core';

import {
    addLocationSchema,
    editLocationSchema,
} from '../../../../../../../../schemas';

import {
    closePreview,
    fetchLocations,
} from '../../../../../../../../state/_actions';
import { resetLastMapClickPosition } from '../../../../../../../../state/ui/discovery/general';
import { updateForm } from '../../../../../../../../state/ui/forms';
import {
    createLocation,
    fetchLocation,
    updateLocation,
} from '../../../../../../../../state/app/locations';
import { usePrivileges } from '../../../../../../../../state/auth/index.hooks';
import { useRegistersGroups } from '../../../../../../../../state/app/registers/index.hooks';
import { fetchRegistersGroups } from '../../../../../../../../state/app/registers/groups';
import { IMappedMenuItem } from '../../../../../../../../state/types';
import { useUserDataPermissions } from '../../../../../../../../state/user/index.hooks';

import {
    useLocation,
    useSourceSetElementLocationActions,
} from '../../../../../../selectors/composed/index.hooks';
import {
    useCreatorLevel,
    usePane,
} from '../../../../../../selectors/index.hooks';

import TranslationHelper from '../../../../../../../../helpers/TranslationHelper';
import {
    useAppDispatch,
    useToolkitDispatch,
} from '../../../../../../../../hooks';

import CrudPane from '../../../../../../../../components/CrudPane';
import LocationForm from './components/LocationForm';

import {
    DEFAULT_VISIBILITY_TYPE,
    prepareGroupIds,
    prepareGroups,
    prepareVisibilityType,
} from '../../../../../../../../components/VisibilityForm/VisibilityForm';

export interface ILocationForm {
    id: string;
    name: string;
    address: string;
    notes: string;
    longitude: number | string;
    latitude: number | string;
    visibilityType: string;
    groups: IMappedMenuItem[];
}

interface IOwnProps {
    loading: boolean;
    showAlert: boolean;
}
const LocationEdit = ({ loading, showAlert }: IOwnProps) => {
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useAppDispatch();
    const toolkitDispatch = useToolkitDispatch();

    const pane = usePane();
    const location = useLocation();
    const creatorLevel = useCreatorLevel();
    const locationActions = useSourceSetElementLocationActions();
    const privileges = usePrivileges();

    const groups = useRegistersGroups();
    const dataPermissions = useUserDataPermissions();

    const [blankForm, setBlankForm] = useState<ILocationForm>({
        id: '',
        name: '',
        address: '',
        notes: '',
        longitude: '',
        latitude: '',
        visibilityType: DEFAULT_VISIBILITY_TYPE,
        groups: [],
    });

    const [processing, setProcessing] = useState<boolean>(false);
    useEffect(() => {
        if (!groups) {
            toolkitDispatch(fetchRegistersGroups({}));
        }
    }, [groups, toolkitDispatch]);

    useEffect(() => {
        if (location) {
            const address = location.address;
            setBlankForm({
                id: location.id,
                name: location.name,
                address: [
                    address.city,
                    address.street,
                    address.streetNumber,
                ].join(', '),
                notes: location.notes,
                longitude: location.coordinate?.x,
                latitude: location.coordinate?.y,
                visibilityType: prepareVisibilityType(
                    groups,
                    location.groupIds,
                    dataPermissions
                ),
                groups: prepareGroups(groups, location.groupIds),
            });
        }
        if (pane?.mode === 'add' && locationActions?.add) {
            const { address, latitude, longitude, name } =
                locationActions.add.params;
            setBlankForm({
                id: '',
                name: name || '',
                address: Object.values(address || {}).join(', '),
                longitude: longitude || '',
                latitude: latitude || '',
                notes: '',
                visibilityType: DEFAULT_VISIBILITY_TYPE,
                groups: [],
            });
        }
    }, [location, groups]);

    const handleCloseClick = () => {
        dispatch(resetLastMapClickPosition());
        dispatch(closePreview(creatorLevel, false));
    };

    const showNotification = (success: boolean, message: string) => {
        enqueueSnackbar(message, {
            variant: success ? 'success' : 'error',
        });

        setProcessing(false);
    };

    const handleUpdateSubmit = (
        values: FormikValues,
        onSuccess: () => void,
        onError: () => void
    ) => {
        if (location) {
            setProcessing(true);
            const [city, street, streetNumber] = values.address
                .split(',')
                .map((item: string) => item.trim());
            const data = {
                ...location,
                coordinate: {
                    x: parseFloat(values.longitude),
                    y: parseFloat(values.latitude),
                },
                externalId: Number(location.externalId),
                name: values.name,
                address: {
                    ...location.address,
                    city,
                    street,
                    streetNumber,
                },
                notes: values.notes,
                clientId: location.clientId,
                groupIds: prepareGroupIds(
                    values,
                    dataPermissions,
                    location.groupIds
                ),
            };

            dispatch(
                updateLocation(
                    data,
                    () => {
                        dispatch(fetchLocations());
                        dispatch(fetchLocation(location.id));
                        handleCloseClick();

                        showNotification(
                            true,
                            TranslationHelper.translate(
                                'Location changes has been saved'
                            )
                        );
                    },
                    () => {
                        onError();
                        showNotification(
                            false,
                            TranslationHelper.translate(
                                'Unrecognized error has occurred'
                            )
                        );
                    }
                )
            );
        } else {
            showNotification(
                false,
                TranslationHelper.translate(
                    'Click on map to set this location coordinates or input address'
                )
            );
        }
    };

    const handleAddSubmit = (values: FormikValues) => {
        setProcessing(true);

        const [city, street, streetNumber] = values.address
            .split(',')
            .map((item: string) => item.trim());
        const data = {
            coordinate: {
                x: parseFloat(values.longitude),
                y: parseFloat(values.latitude),
            },
            name: values.name,
            address: {
                city,
                street,
                streetNumber,
            },
            notes: values.notes,
            clientId: undefined,
            groupIds: prepareGroupIds(values, dataPermissions),
        };

        dispatch(
            createLocation(
                data,
                (data) => {
                    dispatch(fetchLocations());

                    if (pane && pane.storeResult) {
                        dispatch(
                            updateForm({
                                type: 'task',
                                field: 'location',
                                value: data,
                            })
                        );
                    }
                    handleCloseClick();
                    showNotification(
                        true,
                        TranslationHelper.translate('Location has been added')
                    );
                },
                () => {
                    showNotification(
                        false,
                        TranslationHelper.translate(
                            'Unrecognized error has occurred'
                        )
                    );
                }
            )
        );
    };
    if (loading) {
        return <LinearProgress />;
    }

    return (
        <CrudPane
            titles={{
                add: 'Add location',
                edit: 'Edit location',
            }}
            loaded={!loading}
            initialValues={blankForm}
            validationSchema={
                pane?.mode === 'add' && privileges.manageCustomerServiceCentre
                    ? addLocationSchema
                    : editLocationSchema
            }
            onClose={handleCloseClick}
            changeModeHandler={handleCloseClick}
            mode={(pane && pane.mode) || undefined}
            onAdd={(values) => handleAddSubmit(values)}
            onEdit={handleUpdateSubmit}
            renderContent={(
                mode,
                errors: FormikErrors<ILocationForm>,
                values,
                setFieldValue,
                isSubmitting
            ) => {
                if (isSubmitting || processing) {
                    return <LinearProgress />;
                }
                return (
                    <LocationForm
                        errors={errors}
                        values={values}
                        showAlert={showAlert}
                        setFieldValue={setFieldValue}
                    />
                );
            }}
        />
    );
};

export default LocationEdit;
