import { createStyles, makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() =>
    createStyles({
        paramSelectorWrapper: {
            flex: '0 0 auto',
            width: '20%',
            height: '100%',
        },
        paramSelectorHeader: {
            padding: '3px 5px',
            display: 'block',
            height: '30px',
            boxSizing: 'border-box',
        },
        paramList: {
            height: 'calc(100% - 30px)',
            overflowY: 'scroll',
        },
        paramSelectorItem: {
            cursor: 'pointer',
            padding: '3px 5px',
            borderBottomWidth: '3px',
            borderBottomStyle: 'solid',
        },
        checkbox: {
            padding: '0 6px 0 9px',
        },
    })
);
