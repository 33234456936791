import React, { useEffect, useState } from 'react';

import { fetchChartEvents } from '../../../../../services/discovery/fetchChartEvents';
import { useDiscoveryChartPane } from '../../../selectors/index.hooks';

import EventChart from '../../../../../components/EventChart';

const EventChartPane = () => {
    const chartPane = useDiscoveryChartPane();

    const [allEvents, setAllEvents] = useState([]);
    const [filteredEvents, setFilteredEvents] = useState([]);

    useEffect(() => {
        if (chartPane !== null) {
            fetchChartEvents(chartPane.sourceSetAction.params.allEvents).then(
                (data) => {
                    setAllEvents(data.values);
                }
            );
            fetchChartEvents(
                chartPane.sourceSetAction.params.filteredEvents
            ).then((data) => {
                setFilteredEvents(data.values);
            });
        }
    }, [chartPane]);

    return (
        <>
            {allEvents && filteredEvents && (
                <EventChart
                    chartId="chart"
                    allData={allEvents}
                    filteredData={filteredEvents}
                    sortedAscending={true}
                />
            )}
        </>
    );
};

export default EventChartPane;
