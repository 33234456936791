import React, { Component, ComponentType } from 'react';

import { compose } from 'redux';
import { Connectable, TConnectableProps } from './Connectable.hoc';
import { Themable, TThemableProps } from './Themable.hoc';

import {
    Divider,
    Drawer,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
} from '@material-ui/core';
import ExitIcon from '@material-ui/icons/PowerSettingsNew';
import classNames from 'classnames';

import TranslationHelper from '../../helpers/TranslationHelper';
import Brand from '../Brand/Brand';

interface IOwnProps {
    logo: string;
    branding: boolean;
}

type TProps = IOwnProps & TThemableProps & TConnectableProps;

class MobileNavigation extends Component<TProps> {
    public render() {
        const { branding, classes, isMenuOpen, hideMenu, logo, logout } =
            this.props;

        return (
            <Drawer open={isMenuOpen} onClose={hideMenu}>
                <div className={classes.list}>
                    <List>
                        <ListItem>
                            <div className={classes.center}>
                                <img
                                    className={classes.toolbarMainLogoImg}
                                    alt={TranslationHelper.translate(
                                        'Image not found'
                                    )}
                                    src={
                                        logo ? logo : '/images/xtrack_logo.svg'
                                    }
                                />
                            </div>
                        </ListItem>
                        <Divider />
                        <ListItem button={true} onClick={logout}>
                            <ListItemIcon>
                                <ExitIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary={TranslationHelper.translate('Logout')}
                            />
                        </ListItem>
                    </List>
                    {branding && (
                        <div>
                            <Divider />
                            <div
                                className={classNames(
                                    classes.brand,
                                    classes.center
                                )}
                            >
                                <Brand />
                            </div>
                        </div>
                    )}
                </div>
            </Drawer>
        );
    }
}

export default compose(
    Themable,
    Connectable
)(MobileNavigation) as ComponentType<IOwnProps>;
