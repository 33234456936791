import React from 'react';

import URI from 'urijs';

import { IResource } from '../../../../../state/ui/resources';

import AppConfig from '../../../../../constants/AppConfig';
import UrlConst from '../../../../../constants/UrlConst';

import TranslationHelper from '../../../../../helpers/TranslationHelper';

interface IOwnProps {
    resource: IResource;
    defaultLanguage: string;
}

interface IState {
    selectedLanguage: string;
}

class ApplicationResourcesItem extends React.Component<IOwnProps, IState> {
    constructor(props: IOwnProps) {
        super(props);

        this._onLanguageSelect = this._onLanguageSelect.bind(this);

        this.state = this._getInitialState();
    }

    _getInitialState() {
        const languages = this.props.resource.languages;
        const defaultLanguageIndex = languages.indexOf(
            this.props.defaultLanguage
        );
        const selectedLanguage =
            languages.indexOf('en') > -1 ? 'en' : languages[0];
        const language =
            defaultLanguageIndex > -1
                ? this.props.resource.languages[defaultLanguageIndex]
                : selectedLanguage;
        return {
            selectedLanguage: language,
        };
    }

    _onLanguageSelect(event: React.ChangeEvent<HTMLSelectElement>) {
        this.setState({ selectedLanguage: event.target.value });
    }

    _renderLanguageSelect(item: IResource) {
        const options = item.languages.map((language) => {
            return (
                <option value={language} key={language}>
                    {TranslationHelper.translate(language)}
                </option>
            );
        });

        return (
            <select
                id="languagesSelect"
                defaultValue={this.state.selectedLanguage}
                className="resource-language"
                onChange={this._onLanguageSelect}
            >
                {options}
            </select>
        );
    }

    _renderLinks(item: IResource) {
        return item.formats.map((format) => {
            const url = URI(
                AppConfig.instance.getUrl(UrlConst.RESOURCES_URL) +
                    '/' +
                    item.id
            );
            url.search({
                format: format,
                language: this.state.selectedLanguage,
            });
            return (
                <a className="resource-link" key={format} href={url.toString()}>
                    <span className={'icon icon-' + format}> </span>
                    {TranslationHelper.translate(format)}
                </a>
            );
        });
    }

    render() {
        const { resource } = this.props;
        return (
            <div className="resources-item" id={String(resource.id)}>
                <div className="resource-header">
                    <label htmlFor="languagesSelect">
                        {TranslationHelper.translate(resource.name)}
                    </label>
                    {this._renderLanguageSelect(resource)}
                </div>
                <div className="resource-links">
                    {this._renderLinks(resource)}
                </div>
            </div>
        );
    }
}

export default ApplicationResourcesItem;
