import URI from 'urijs';
import AppConfig from '../../constants/AppConfig';
import {
    makeCredentialsOnlyDeleteRequestSettings,
    makeGetRequestSettings,
    makePostRequestSettings,
    makePutRequestSettings,
    makeUrl,
} from '../common/utils';
import UrlConst from '../../constants/UrlConst';
import apiClient from '../common/apiClient';
import {
    IGridViewListItem,
    IPostServiceGridView,
    IServiceGridView,
} from '../../state/ui/gridViews';

export const makeGridViewsUrl = (suffix: string, params = {}) => {
    const url = new URI(
        AppConfig.instance.getUrl(UrlConst.SOURCE_SETS_VIEWS_URL) + suffix
    );
    return makeUrl(url, params);
};

export const restGetGridViews = (sourceSet: {
    sourceSet: string;
}): Promise<IGridViewListItem[]> =>
    apiClient.send(makeGetRequestSettings(makeGridViewsUrl('', sourceSet)));

export const restGetGridView = (id: string): Promise<IServiceGridView> =>
    apiClient.send(makeGetRequestSettings(makeGridViewsUrl(`/${id}`)));

export const restPostGridView = (
    view: IPostServiceGridView
): Promise<{ id: string }> =>
    apiClient.send(makePostRequestSettings(makeGridViewsUrl(''), view));

export const restPutGridView = (
    view: IServiceGridView
): Promise<{ id: string }> =>
    apiClient.send(
        makePutRequestSettings(makeGridViewsUrl(`/${view.id}`), view)
    );

export const restDeleteView = (id: string): Promise<null> =>
    apiClient.send(
        makeCredentialsOnlyDeleteRequestSettings(makeGridViewsUrl(`/${id}`))
    );
