import React, { Component, ComponentType } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { Button, Dialog, DialogContent } from '@material-ui/core';

import { DatePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import moment, { Moment } from 'moment-timezone';

import Translator from '../../../../helpers/TranslationHelper';

import { Themable, TThemableProps } from './Themable.hoc';

import DialogTitle from '../../../../components/dialogs/DialogTitle';
import { IQuickDateControls } from '../../DateRangeDisplay';

interface IOwnProps {
    newFrom: Moment;
    newTo: Moment;
    isOpen: boolean;
    close: () => void;
    confirm: () => void;
    fromChange: (data: string) => void;
    toChange: (data: string) => void;
    quickDates: IQuickDateControls[];
    handleQuickDates: (from: string, to: string) => void;
    dateFormat: string;
    title: string;
}

type TProps = IOwnProps & TThemableProps;

class DatePickerDialog extends Component<TProps> {
    public render() {
        const {
            classes,
            isOpen,
            close,
            confirm,
            quickDates,
            dateFormat,
            title,
            handleQuickDates,
            newFrom,
            newTo,
        } = this.props;
        return (
            <>
                <Dialog open={isOpen} onClose={close}>
                    <DialogTitle title={title} close={close} />
                    <DialogContent>
                        <div className={classes.datePickers}>
                            <div className={classes.picker}>
                                <DatePicker
                                    value={newFrom}
                                    maxDate={newTo || moment()}
                                    onChange={this.handleFromDateChange}
                                    format={dateFormat}
                                    label={Translator.translate('from')}
                                    variant="inline"
                                    autoOk={true}
                                />
                            </div>
                            <div className={classes.picker}>
                                <DatePicker
                                    value={newTo}
                                    minDate={newFrom}
                                    onChange={this.handleToDateChange}
                                    format={dateFormat}
                                    label={Translator.translate('to')}
                                    variant="inline"
                                    autoOk={true}
                                />
                            </div>
                            <div className={classes.picker}>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    onClick={confirm}
                                >
                                    {Translator.translate('Show')}
                                </Button>
                            </div>
                        </div>
                        <div className={classes.quickButtonsWrapper}>
                            {quickDates.map((button) => (
                                <Button
                                    key={button.label}
                                    color="primary"
                                    variant="contained"
                                    onClick={() => {
                                        handleQuickDates(
                                            button.from,
                                            button.to
                                        );
                                    }}
                                    className={classes.quickButton}
                                >
                                    {Translator.translate(button.label)}
                                </Button>
                            ))}
                        </div>
                    </DialogContent>
                </Dialog>
            </>
        );
    }

    private handleFromDateChange = (date: MaterialUiPickersDate) => {
        if (!!date) {
            this.props.fromChange(moment(date).format());
        }
    };

    private handleToDateChange = (date: MaterialUiPickersDate) => {
        if (!!date) {
            this.props.toChange(moment(date).format());
        }
    };
}

export default compose(
    Themable,
    connect()
)(DatePickerDialog) as ComponentType<IOwnProps>;
