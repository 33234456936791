import { useSnackbar } from 'notistack';
import React from 'react';

import {
    checkIsCustomerServiceReport,
    checkIsLocationReport,
    INewSchedule,
    IReportsDefinition,
    updateSchedule,
} from '../../../../../../state/ui/reportsSlice';
import {
    useReportsDefinitions,
    useSchedule,
} from '../../../../../..//state/ui/reportsSlice/index.hooks';

import { errorMessageHandler } from '../../../../../../helpers/errorMessageHandler';
import TranslationHelper from '../../../../../../helpers/TranslationHelper';

import { useToolkitDispatch } from '../../../../../../hooks';

import SchedulesForm from '../SchedulesForm';
import { IInitialState } from '../SchedulesForm/SchedulesForm';
import moment from 'moment';

interface IOwnProps {
    handleClose: () => void;
}

const SchedulesEditForm = ({ handleClose }: IOwnProps) => {
    const toolkitDispatch = useToolkitDispatch();

    const { enqueueSnackbar } = useSnackbar();

    const schedule = useSchedule();

    const reportDefinitions = useReportsDefinitions();

    const getReportDefinition = () => {
        return reportDefinitions?.filter(
            (definition: IReportsDefinition) =>
                String(definition.templateId) === String(template?.id)
        )?.[0];
    };
    if (!schedule) {
        return <></>;
    }
    const { repetition, timeZone, subjects, type, template, emails } = schedule;

    const isLocationReport = checkIsLocationReport(type.id);
    const isCustomerServiceReport = checkIsCustomerServiceReport(type.id);
    const initialState: IInitialState = {
        reportType: getReportDefinition() || {
            typeId: type.id || '',
            name: type.name || '',
            templateId: template.id || '',
            isLocationReport: isLocationReport || false,
            isCustomerServiceReport: isCustomerServiceReport || false,
        },
        periodicity: repetition.interval,
        timeZone: timeZone,
        dateFrom: moment(repetition.startBoundaryDate),
        dateTo: moment(repetition.endBoundaryDate),
        objects: subjects.map((subject) => {
            return {
                id: subject.id,
                name: subject.name,
                key: String(subject.id),
            };
        }),
        emails: emails ?? [],
        emailsField: '',
        location: isLocationReport ? subjects[0].name : '',
    };

    const handleRequest = (data: INewSchedule, callback: () => void) =>
        toolkitDispatch(
            updateSchedule({
                id: schedule.id,
                schedule: { ...data, id: schedule.id },
            })
        )
            .unwrap()
            .then(() => {
                enqueueSnackbar(
                    TranslationHelper.translate('Schedule updated'),
                    {
                        variant: 'success',
                    }
                );

                callback();
            })
            .catch((error) => {
                const message = errorMessageHandler(error.status)();
                enqueueSnackbar(message, { variant: 'error' });
                callback();
            });

    return (
        <SchedulesForm
            title="Edit schedule"
            initialState={initialState}
            handleClose={handleClose}
            handleRequest={handleRequest}
        />
    );
};

export default SchedulesEditForm;
