import React, { ReactChild, useState, useEffect } from 'react';

import {
    Collapse,
    IconButton,
    ListItem,
    ListItemText,
    Tooltip,
} from '@material-ui/core';
import {
    ExpandLess,
    ExpandMore,
    InfoOutlined as InfoIcon,
} from '@material-ui/icons';
import { default as EditIcon } from '@material-ui/icons/Edit';
import { default as AddIcon } from '@material-ui/icons/Add';

import TranslationHelper from '../../helpers/TranslationHelper';
import { useStyles } from './Themable.hooks';
import LoadingSpinner from '../loadingSpinner/LoadingSpinner';
import { ISourceSetExternalSectionItem, TFetchState } from '../../state/types';

interface IOwnProps {
    children?: ReactChild;
    title: string;
    additionalText?: string;
    openSection: boolean;
    fetchState?: TFetchState<ISourceSetExternalSectionItem>;
    handleToggleCollapse: () => void;
    handleAdd?: () => void;
    handleEdit?: () => void;
}
export const CollapseListItem = ({
    children,
    title,
    additionalText,
    openSection,
    fetchState,
    handleToggleCollapse,
    handleAdd,
    handleEdit,
}: IOwnProps) => {
    const [open, setOpen] = useState(openSection);
    const classes = useStyles();

    const handleClick = () => {
        handleToggleCollapse();
        setOpen((open) => !open);
    };

    useEffect(() => {
        setOpen(openSection);
    }, [openSection]);

    const renderRightIcon = () => {
        if (fetchState?.type === 'error') {
            return (
                <Tooltip
                    title={TranslationHelper.translate(
                        fetchState.error.message
                    )}
                >
                    <div className={classes.icon} data-testid="icon">
                        <InfoIcon />
                    </div>
                </Tooltip>
            );
        }
        if (fetchState?.type === 'loading') {
            return (
                <LoadingSpinner
                    progressSize="1.5em"
                    top="0"
                    left="0"
                    right="0"
                    bottom="0"
                />
            );
        }
        return (
            children && (
                <IconButton onClick={handleClick} style={{}}>
                    {open ? <ExpandLess /> : <ExpandMore />}
                </IconButton>
            )
        );
    };
    return (
        <>
            <ListItem disableGutters={true} className={classes.listItemHeading}>
                <ListItemText
                    primary={TranslationHelper.translate(title)}
                    secondary={TranslationHelper.translate(
                        additionalText || ''
                    )}
                    className={classes.heading}
                />

                {handleAdd && (
                    <Tooltip title={TranslationHelper.translate('Add')}>
                        <IconButton onClick={() => handleAdd()}>
                            <AddIcon />
                        </IconButton>
                    </Tooltip>
                )}
                {handleEdit && (
                    <Tooltip title={TranslationHelper.translate('Edit')}>
                        <IconButton onClick={() => handleEdit()}>
                            <EditIcon />
                        </IconButton>
                    </Tooltip>
                )}
                {renderRightIcon()}
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit={true}>
                {children}
            </Collapse>
        </>
    );
};
