const ARCHIVE_COLORS = [
    { color: '#e74c3c', usages: 0 },
    { color: '#3498db', usages: 0 },
    { color: '#f1c40f', usages: 0 },
    { color: '#22791C', usages: 0 },
    { color: '#98179B', usages: 0 },
    { color: '#733D10', usages: 0 },
    { color: '#34495e', usages: 0 },
    { color: '#31CAB3', usages: 0 },
    { color: '#9B7117', usages: 0 },
    { color: '#8A5ED5', usages: 0 },
    { color: '#17369B', usages: 0 },
    { color: '#DB53B0', usages: 0 },
];

class ColorHelper {
    public static getColorByPercentage(
        percents: number,
        reversedLogic: boolean
    ) {
        let colorHex;
        percents = reversedLogic ? Number(100 - percents) : Number(percents);

        if (percents >= 50) {
            colorHex = '#64BF00';
        } else if (percents < 50 && percents >= 10) {
            colorHex = '#F8D01C';
        } else {
            colorHex = '#FF0000';
        }

        return colorHex;
    }

    public static getArchiveColors() {
        return ARCHIVE_COLORS;
    }

    public static fetchArchiveColor() {
        let minItem = ARCHIVE_COLORS[0];
        for (let i = 1; i < ARCHIVE_COLORS.length; i++) {
            const item = ARCHIVE_COLORS[i];
            if (minItem.usages > item.usages) {
                minItem = item;
            }
        }

        minItem.usages++;
        return minItem.color;
    }

    public static returnArchiveColor(color: string) {
        const item = ARCHIVE_COLORS.find(
            (archiveColor) => archiveColor.color === color
        );
        if (item) {
            item.usages--;
        }
    }

    public static getArchiveColorIndex(color: string) {
        return ARCHIVE_COLORS.findIndex((item) => item.color === color);
    }
}

export default ColorHelper;
