import React, { Component, MouseEvent } from 'react';

import classnames from 'classnames';

interface IProps {
    totalResults: number;
    resultsPerPage: number;
    currentPage: number;
    onPageSelect?: (pageNumber: number) => void;
    className?: string;
}

class Pagination extends Component<IProps> {
    public static defaultProps = {
        currentPage: 1,
        resultsPerPage: 50,
    };

    public render() {
        const { currentPage, className } = this.props;

        const totalPages = this.countTotalPages();
        const prevButtonEnabled = currentPage > 1;
        const nextButtonEnabled = currentPage < totalPages;
        const classes = classnames('pagination', className);

        if (totalPages === 0) {
            return null;
        }

        return (
            <div className={classes}>
                <button
                    className="pagination--prev"
                    disabled={!prevButtonEnabled}
                    onClick={this.handlePrevClick}
                >
                    &lt;{' '}
                </button>
                {this.renderPages(totalPages)}
                <button
                    className="pagination--next"
                    disabled={!nextButtonEnabled}
                    onClick={this.handleNextClick}
                >
                    &gt;{' '}
                </button>
            </div>
        );
    }

    private countTotalPages = () => {
        const { totalResults, resultsPerPage } = this.props;

        return Math.ceil(totalResults / resultsPerPage);
    };

    private handlePageClick =
        (pageNumber: number) => (e: MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();
            this.triggerPageSelect(pageNumber);
        };

    private triggerPageSelect = (pageNumber: number) => {
        if (this.props.onPageSelect) {
            this.props.onPageSelect(pageNumber);
        }
    };

    private handlePrevClick = (event: MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        if (this.props.currentPage > 1) {
            this.triggerPageSelect(this.props.currentPage - 1);
        }
    };

    private handleNextClick = (event: MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        if (this.props.currentPage < this.countTotalPages()) {
            this.triggerPageSelect(this.props.currentPage + 1);
        }
    };

    private renderPages(totalPages: number) {
        const { currentPage } = this.props;
        const classes = classnames('pagination--page');
        const currentClasses = classnames(
            'pagination--page',
            'pagination--page__current'
        );
        const overflow = currentPage + 2;
        const left =
            currentPage -
            2 -
            (overflow > totalPages ? overflow - totalPages : 0);
        const right = currentPage + 2 + (left < 1 ? -(left - 1) : 0);
        const buttons = [];
        for (let i = 1; i <= totalPages; i++) {
            if (totalPages <= 7) {
                buttons.push(
                    <button
                        key={i}
                        data-page={i}
                        className={i === currentPage ? currentClasses : classes}
                        onClick={this.handlePageClick(i)}
                    >
                        {i}
                    </button>
                );
            } else if (
                i === 1 ||
                i === totalPages ||
                (i >= left && i <= right)
            ) {
                if (i === totalPages && right < totalPages - 1) {
                    buttons.push(
                        <button
                            key={totalPages + 1}
                            className="pagination--separator"
                        >
                            ...
                        </button>
                    );
                }
                buttons.push(
                    <button
                        key={i}
                        data-page={i}
                        className={i === currentPage ? currentClasses : classes}
                        onClick={this.handlePageClick(i)}
                    >
                        {i}
                    </button>
                );
                if (i === 1 && left > i + 1) {
                    buttons.push(
                        <button key={0} className="pagination--separator">
                            ...
                        </button>
                    );
                }
            }
        }
        return buttons;
    }
}

export default Pagination;
