import { default as IconButton } from '@material-ui/core/IconButton';
import { default as OptionsIcon } from '@material-ui/icons/MoreVert';
import React, { Component } from 'react';

interface IProps {
    data: {
        _meta: {
            actions: {
                contextMenu: object;
            };
        };
    };
}

class OptionsCellRenderer extends Component<IProps> {
    public render() {
        const { actions } = this.props.data._meta;
        return (
            <IconButton disabled={!Object.keys(actions || {}).length}>
                <OptionsIcon />
            </IconButton>
        );
    }
}

export default OptionsCellRenderer;
