import React from 'react';

import { Paper } from '@material-ui/core';

import { useStyles } from '../../../../../Themable.hooks';

import { useMode } from '../../../../../../../state/ui/customerService/bottomGrid/index.hooks';
import { enterPreviewMode } from '../../../../../../../state/ui/customerService/bottomGrid';

import ContractPreview from '../ContractItemPreview';
import ClientEditForm from '../../ClientsAndLocations/ClientEditForm';
import { useToolkitDispatch } from '../../../../../../../hooks';
import LocationEditForm from '../../ClientsAndLocations/LocationEditForm';
import LocationAddForm from '../../ClientsAndLocations/LocationAddForm';

const ContractItemsPane = () => {
    const classes = useStyles();
    const mode = useMode();
    const toolkitDispatch = useToolkitDispatch();

    const handleClose = () => {
        toolkitDispatch(enterPreviewMode());
    };
    const renderPane = () => {
        switch (mode) {
            case 'create':
                return <></>;
            case 'preview':
                return <ContractPreview />;
            case 'editClient':
                return <ClientEditForm handleClose={handleClose} />;
            case 'editLocation':
                return <LocationEditForm handleClose={handleClose} />;
            case 'addLocation':
                return <LocationAddForm handleClose={handleClose} />;
            default:
                return;
        }
    };
    return (
        <Paper className={classes.formPane}>
            <div className={classes.wrapper}>{renderPane()}</div>
        </Paper>
    );
};

export default ContractItemsPane;
