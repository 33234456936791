import React from 'react';

import { Checkbox, FormControlLabel } from '@material-ui/core';

import { useStyles } from '../../Themable.hooks';

import Translator from '../../../../helpers/TranslationHelper';

interface IOwnProps {
    onlyNotPlanned: boolean;

    handleOnChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    disabled: boolean;
}

export const NoDateSwitchComponent = ({
    onlyNotPlanned,
    handleOnChange,
    disabled,
}: IOwnProps) => {
    const classes = useStyles();

    return (
        <>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={onlyNotPlanned}
                        onChange={handleOnChange}
                        value="onlyNotPlanned"
                        classes={{
                            root: classes.colorCheckbox,
                            checked: classes.colorChecked,
                        }}
                        disabled={disabled}
                    />
                }
                label={Translator.translate('Only without date')}
                classes={{
                    root: classes.root,
                    label: classes.label,
                }}
            />
        </>
    );
};
