import { makeReduxDuck } from 'teedux';

import {
    IRequestErrorWithJSONMessage,
    IRequestWithMessages,
    makeRequest,
} from '../../../app/sync';

import {
    restGetVehicles,
    restPostVehicle,
    restPutVehicle,
    restPutVehicleCards,
} from '../../../../services/registers';

import PageableCollection from '../../../../models/common/PageableCollection';
import Vehicle from '../../../../models/registers/Vehicle';

import { ICard, IPage, IRegisterFilter, mapToPageableCollection } from '..';
import { TRootState } from 'src/app/store';
import { IGroup } from '../../collections/dataTypes';

interface IState {
    vehicles: PageableCollection | null;
    vehiclesFilter: IRegisterFilter;
}

export const initialState: IState = {
    vehicles: null,
    vehiclesFilter: {
        group: undefined,
        name: '',
        start: 0,
        limit: 25,
    },
};

export interface IVehicle {
    id: string;
    externalId: number;
    name: string;
    cards?: ICard[];
    monitoredId?: number;
    fuelConsumption: number;
    fuelConsumptionWork: number;
    timeZone?: {
        id: number;
        name: string;
    };
    group: { name: string };
    groups: IGroup[];
    groupIds: string[];
    plateNumber: string;
    sideNumber: string;
}

const duck = makeReduxDuck('app/registers/vehicles', initialState);

const vehiclesStorePath = 'app/registers/vehicles';

export const setVehicles = duck.defineAction<{
    vehicles: PageableCollection;
}>('SET_VEHICLES', (_, { vehicles }) => ({
    vehicles,
}));

export const setVehiclesFilter = duck.defineAction<{
    vehiclesFilter: IRegisterFilter;
}>('SET_VEHICLES_FILTER', (_, { vehiclesFilter }) => ({
    vehiclesFilter,
}));

export const fetchVehicles = (
    params: IRegisterFilter = initialState.vehiclesFilter,
    afterRequest: (data: IPage<IVehicle>) => void = () => null
) =>
    makeRequest(
        vehiclesStorePath,
        () => restGetVehicles(params),
        (dispatch, data) => {
            dispatch(
                setVehicles({
                    vehicles: mapToPageableCollection(data, Vehicle.fromJson),
                })
            );

            if (afterRequest) {
                afterRequest(data);
            }
        },
        (dispatch, error) => undefined
    );

export const updateVehicle = (
    vehicle: IVehicle,
    filter?: IRegisterFilter,
    afterRequest?: (data: IRequestWithMessages) => void,
    afterFail?: (error: IRequestErrorWithJSONMessage) => void
) =>
    makeRequest(
        `put:${vehiclesStorePath}/${vehicle.id}`,
        () => restPutVehicle(vehicle),
        (dispatch, data) => {
            // @ts-ignore
            dispatch(fetchVehicles(filter));
            if (afterRequest) {
                afterRequest(data);
            }
        },
        (dispatch, error) => {
            if (afterFail) {
                afterFail(error);
            }
        }
    );
export const createVehicle = (
    vehicle: IVehicle,
    filter: IRegisterFilter,
    afterRequest?: (data: IRequestWithMessages) => void,
    afterFail?: (error: IRequestErrorWithJSONMessage) => void
) =>
    makeRequest(
        `post:${vehiclesStorePath}/`,
        () => restPostVehicle(vehicle),
        (dispatch, data) => {
            // @ts-ignore
            dispatch(fetchVehicles(filter));
            if (afterRequest) {
                afterRequest(data);
            }
        },
        (dispatch, error) => {
            if (afterFail) {
                afterFail(error);
            }
        }
    );

export const updateVehicleCards = (
    id: string,
    cards: string[],
    filter: IRegisterFilter,
    afterRequest?: (data: IRequestWithMessages) => void,
    afterFail?: () => void
) =>
    makeRequest(
        `put:${vehiclesStorePath}/${id}/cards`,
        () => restPutVehicleCards(id, cards),
        (dispatch, data) => {
            // @ts-ignore
            dispatch(fetchVehicles(filter));
            if (afterRequest) {
                afterRequest(data);
            }
        },
        () => {
            if (afterFail) {
                afterFail();
            }
        }
    );

const getRegistersVehiclesState = (state: TRootState) =>
    state.app.registers.vehicles;

export const getVehicles = (state: TRootState) =>
    getRegistersVehiclesState(state).vehicles;
export const getVehiclesFilter = (state: TRootState) =>
    getRegistersVehiclesState(state).vehiclesFilter;

export default duck.getReducer();
