import { createStyles, Theme, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        listItem: {
            fontSize: theme.typography.caption.fontSize,
            justifyContent: 'space-between',
            borderTop: '1px solid ' + theme.palette.divider,
            color: theme.palette.primary.dark,
        },
        itemLabel: {
            fontFamily: 'OpenSans-Regular',
            fontSize: theme.typography.caption.fontSize,
            paddingRight: theme.spacing(),
            fontWeight: 'bold',
            wordWrap: 'break-word',
            width: '40%',
        },
        secondaryAction: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            width: '60%',
            wordWrap: 'break-word',
            textAlign: 'right',
        },
    })
);
