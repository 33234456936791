import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { IRightItem } from '../../../../../../../../state/ui/usersSlice';
import {
    useRegistersGroups,
    useRegistersMonitoredObjects,
} from '../../../../../../../../state/app/registers/index.hooks';

import {
    IFilterInitialState,
    IListObjectInitialState,
    ITransferListObject,
} from '../../../../../../../../components/TransferList/TransferList';
import TransferList from '../../../../../../../../components/TransferList';
import {
    findIndexById,
    handleAddedAndRemoved,
    not,
} from '../../../../../../../../components/TransferList/utils/filterObjects';

import { filterObjects } from '../../utils/filterObjects';

import LeftList from '../LeftList';
import RightList from '../RightList';

import { TSelectedRight } from '../../deduceSelectionType';

interface IOwnProps {
    objectLists: IListObjectInitialState<ITransferListObject>;
    setObjectLists: React.Dispatch<
        Partial<IListObjectInitialState<ITransferListObject>>
    >;
    added: IRightItem[];
    setAdded: Dispatch<SetStateAction<IRightItem[]>>;
    removed: IRightItem[];
    setRemoved: Dispatch<SetStateAction<IRightItem[]>>;
    filter: IFilterInitialState;
    setFilter: React.Dispatch<Partial<IFilterInitialState>>;
    filterObjects: (
        items: ITransferListObject[],
        type: string,
        name: string
    ) => ITransferListObject[];
    assignmentsLoading: boolean;
    setAssignmentsLoading: (bool: boolean) => void;
    selectedRight: TSelectedRight;
    setSelectedRight: (right: TSelectedRight) => void;
}

const INITIAL_TYPE = { group: 'GROUP', all: 'ALL' };

function RightsTransferList({
    objectLists,
    setObjectLists,
    added,
    setAdded,
    removed,
    setRemoved,
    filter,
    setFilter,
    assignmentsLoading,
    selectedRight,
}: IOwnProps) {
    const [checked, setChecked] = useState<IRightItem[]>([]);

    const handleToggle = (value: IRightItem) => () => {
        const currentIndex = findIndexById(checked, value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        setChecked(newChecked);
    };

    const groups = useRegistersGroups();
    const monitoredObjects = useRegistersMonitoredObjects();

    useEffect(() => {
        if (filter.typeUnassigned === INITIAL_TYPE.group) {
            setObjectLists({
                unassignedObjects: filterObjects(
                    handleAddedAndRemoved(
                        not(groups || [], objectLists.originalAssignedObjects),
                        added,
                        removed
                    ),
                    filter.typeUnassigned,
                    filter.nameUnassigned
                ),
            });
        }
    }, [groups]);

    useEffect(() => {
        if (filter.typeUnassigned === 'MONITORED_OBJECT') {
            setObjectLists({
                unassignedObjects: filterObjects(
                    handleAddedAndRemoved(
                        not(
                            monitoredObjects,
                            objectLists.originalAssignedObjects
                        ),
                        added,
                        removed
                    ),
                    filter.typeUnassigned,
                    filter.nameUnassigned
                ),
            });
        }
    }, [monitoredObjects]);

    const leftList = (
        <LeftList
            objects={objectLists}
            setObjects={setObjectLists}
            checked={checked}
            setChecked={setChecked}
            filter={filter}
            setFilter={setFilter}
            handleToggle={handleToggle}
        />
    );

    const rightList = (
        <RightList
            objects={objectLists}
            setObjects={setObjectLists}
            checked={checked}
            setChecked={setChecked}
            filter={filter}
            setFilter={setFilter}
            handleToggle={handleToggle}
        />
    );

    return (
        <div
            style={{
                display:
                    assignmentsLoading || selectedRight !== 'SELECTED'
                        ? 'none'
                        : 'block',
            }}
        >
            <TransferList
                objectLists={objectLists}
                setObjectLists={setObjectLists}
                added={added}
                setAdded={setAdded}
                removed={removed}
                setRemoved={setRemoved}
                filter={filter}
                customComponents={{
                    leftList,
                    rightList,
                }}
                checked={checked}
                setChecked={setChecked}
                filterObjects={filterObjects}
            />
        </div>
    );
}

export default RightsTransferList;
