const sortAlphabetically = (a: string, b: string) => {
    const nameA = a.toLowerCase();
    const nameB = b.toLowerCase();

    let comparison = 0;
    if (nameA > nameB) {
        comparison = 1;
    } else if (nameA < nameB) {
        comparison = -1;
    }
    return comparison;
};

export { sortAlphabetically };
