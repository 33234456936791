import { Map } from 'ol';
import { EventsKey } from 'ol/events';
import { unByKey } from 'ol/Observable';

const removeMapOverlays = (map: Map) => {
    map.getOverlays().forEach((overlay) => {
        map.removeOverlay(overlay); // Remove overlays such as popups
    });
};
const removeMapControls = (map: Map) => {
    map.getControls().forEach((control) => {
        map.removeControl(control); // Remove controls like zoom buttons, etc.
    });
};
const removeMapEventListeners = (
    map: Map,
    listener: EventsKey | EventsKey[]
) => {
    unByKey(listener);
};
const removeMapInteractions = (map: Map) => {
    map.getInteractions().forEach((interaction) => {
        map.removeInteraction(interaction); // Remove map interactions (like zoom, pan, etc.)
    });
};
const removeMapLayers = (map: Map) => {
    map.getLayers().forEach((layer) => {
        //@ts-ignore
        const source = layer?.getSource();
        if (source && source.clear) {
            source.clear(); // Clear all features from vector sources
        }
        map.removeLayer(layer); // Remove layers from map
    });
};
export const disposeMap = (map: Map, listener: EventsKey | EventsKey[]) => {
    if (map) {
        removeMapLayers(map);
        removeMapEventListeners(map, listener);
        removeMapInteractions(map);
        removeMapControls(map);
        removeMapOverlays(map);
        map.setTarget(undefined); // Detach map from the DOM element
    }
};
