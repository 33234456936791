import React, { Component, ComponentType } from 'react';

import { compose } from 'redux';

import moment from 'moment-timezone';

import { default as Button } from '@material-ui/core/Button';
import { default as Paper } from '@material-ui/core/Paper';

import { Connectable, TConnectableProps } from './Connectable.hoc';
import { Themable, TThemableProps } from './Themable.hoc';

import Translator from '../../../../../../../../helpers/TranslationHelper';

import TimeRangeButtons from '../../../../../../../../components/TimeRangeButtons';
import { MobileDateTimePicker } from '@mui/x-date-pickers';

interface IOwnProps {
    monitoredId: string;
    level: number;
    from?: any;
    to?: any;
}

interface IState {
    fromDate: any;
    toDate: any;
    datePickerVisible: boolean;
    errorMessage: string;
}

type TProps = IOwnProps & TConnectableProps & TThemableProps;

class TimeRangeControls extends Component<TProps, IState> {
    public state = {
        fromDate: this.props.from
            ? moment(this.props.from).tz(this.props.userSettings.timeZone)
            : moment().tz(this.props.userSettings.timeZone).startOf('day'),
        toDate: this.props.to
            ? moment(this.props.to).tz(this.props.userSettings.timeZone)
            : moment().tz(this.props.userSettings.timeZone).endOf('day'),
        datePickerVisible: false,
        errorMessage: '',
    } as IState;

    public render() {
        const { classes, userSettings } = this.props;
        const { errorMessage } = this.state;
        const dateFormat = userSettings.dateFormat;

        const dateTranslations = Translator.makeDateTimeTranslations();
        const buttons = [
            {
                onClick: () =>
                    this.handleOpenReportSet(
                        moment().startOf('day'),
                        moment().endOf('day')
                    ),
                text: 'Today',
            },
            {
                onClick: () =>
                    this.handleOpenReportSet(
                        moment().subtract(1, 'day').startOf('day'),
                        moment().subtract(1, 'day').endOf('day')
                    ),
                text: 'Yesterday',
            },
            {
                onClick: () => this.toggleDatePicker(),
                text: 'Custom',
            },
        ];
        return (
            <React.Fragment>
                <TimeRangeButtons buttons={buttons} />
                {this.state.datePickerVisible && (
                    <Paper className={classes.picker}>
                        <div className={classes.datePickers}>
                            <MobileDateTimePicker
                                views={[
                                    'year',
                                    'month',
                                    'day',
                                    'hours',
                                    'minutes',
                                    'seconds',
                                ]}
                                localeText={dateTranslations}
                                ampm={false}
                                disableFuture={true}
                                value={this.state.fromDate}
                                maxDate={this.state.toDate || moment()}
                                onChange={this.handleFromDateChange}
                                format={dateFormat}
                                label={Translator.translate('from')}
                                slotProps={{
                                    textField: {
                                        variant: 'standard',
                                        error: !!errorMessage,
                                        helperText: errorMessage,
                                    },
                                }}
                            />

                            <MobileDateTimePicker
                                views={[
                                    'year',
                                    'month',
                                    'day',
                                    'hours',
                                    'minutes',
                                    'seconds',
                                ]}
                                ampm={false}
                                disableFuture={true}
                                localeText={dateTranslations}
                                value={this.state.toDate}
                                minDate={this.state.fromDate}
                                onChange={this.handleToDateChange}
                                format={dateFormat}
                                label={Translator.translate('to')}
                                slotProps={{
                                    textField: {
                                        variant: 'standard',
                                        error: !!errorMessage,
                                        helperText: errorMessage,
                                    },
                                }}
                            />
                        </div>
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={() =>
                                this.handleOpenReportSet(
                                    this.state.fromDate,
                                    this.state.toDate
                                )
                            }
                        >
                            {Translator.translate('Show')}
                        </Button>
                    </Paper>
                )}
            </React.Fragment>
        );
    }

    private toggleDatePicker = () => {
        this.setState(({ datePickerVisible }) => ({
            datePickerVisible: !datePickerVisible,
        }));
    };

    private handleFromDateChange = (date: any) => {
        this.setState({ fromDate: date });
    };

    private handleToDateChange = (date: any) => {
        this.setState({ toDate: date });
    };

    private handleOpenReportSet = (from: any, to: any) => {
        const { monitoredId } = this.props;

        this.setState(
            {
                errorMessage: moment(from).isAfter(to)
                    ? Translator.translate('Invalid hour range selected')
                    : '',
            },
            () => {
                if (!this.state.errorMessage) {
                    this.props.activateReportSet(monitoredId, from, to, {
                        type: 'report-set',
                        level: this.props.level,
                    });
                }
            }
        );
    };
}

export default compose(
    Themable,
    Connectable
)(TimeRangeControls) as ComponentType<IOwnProps>;
