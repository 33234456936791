import React, { ChangeEvent, useState, useEffect } from 'react';

import { useSnackbar } from 'notistack';

import { LinearProgress } from '@material-ui/core';

import PaneHeader from '../../../../../../components/PaneHeader';
import TranslationHelper from '../../../../../../helpers/TranslationHelper';
import { useIsLastStateBeingFetched } from '../../../../selectors/feching/index.hooks';
import {
    useLastState,
    useSourceSetEntitiesIds,
} from '../../../../selectors/composed/index.hooks';

import LastStateCreate from './components/LastStateCreate';
import LastStatePreview from './components/LastStatePreview';
import { COORDS_DECIMAL } from '../../../../../../constants/dictionaries/MapCoordsFormat';
import {
    useCreatorLevel,
    useGridFilters,
    useLastClickCoordinate,
    usePane,
    useSourceSetId,
} from '../../../../selectors/index.hooks';
import { useAppDispatch } from '../../../../../../hooks';
import {
    closePreview,
    fetchLastState,
    resetLastMapClickPosition,
    stopFetchingElement,
} from '../../../../../../state/_actions';
import { usePrivileges } from '../../../../../../state/auth/index.hooks';

const LastState = () => {
    const [coords, setCoords] = useState({ longitude: '', latitude: '' });

    const mapCoordinates = useLastClickCoordinate();
    const lastState = useLastState();
    const preview = usePane();
    const filters = useGridFilters();
    const creatorLevel = useCreatorLevel();
    const privileges = usePrivileges();
    const sourceSetEntitiesIds = useSourceSetEntitiesIds();
    const fetching = useIsLastStateBeingFetched();
    const sourceSetId = useSourceSetId();

    const dispatch = useAppDispatch();

    const { enqueueSnackbar } = useSnackbar();
    useEffect(() => {
        if (mapCoordinates) {
            setCoords({
                longitude: mapCoordinates.x.toFixed(COORDS_DECIMAL),
                latitude: mapCoordinates.y.toFixed(COORDS_DECIMAL),
            });
        }
    }, [mapCoordinates]);

    useEffect(() => {
        if (coords.longitude === null || coords.latitude === null) {
            const lastStateCoords = lastState?._meta?.header?.coordinates;
            lastStateCoords &&
                setCoords({
                    longitude: lastStateCoords.x.toFixed(COORDS_DECIMAL),
                    latitude: lastStateCoords.y.toFixed(COORDS_DECIMAL),
                });
        }
    }, [lastState]);

    useEffect(() => {
        if (preview !== null) {
            dispatch(fetchLastState(preview.elementId, filters));
        }
        return () => {
            if (preview && preview.elementId) {
                dispatch(
                    stopFetchingElement(
                        preview.elementId,
                        preview.elementCollectionName
                    )
                );
            }
        };
    }, [filters]);

    useEffect(() => {
        if (
            sourceSetId === 'lastStates' &&
            preview?.elementId &&
            sourceSetEntitiesIds &&
            !sourceSetEntitiesIds.includes(preview.elementId) &&
            !fetching
        ) {
            {
                handleCloseClick();
            }
        }
    }, [sourceSetEntitiesIds]);

    const showNotification = (
        type: 'error' | 'success' | 'warning' | 'info',

        message: string
    ) => {
        enqueueSnackbar(message, {
            variant: type,
        });
    };

    const handleCoordinatesInput = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;

        const floatRegex = /^[-+]?\d{0,3}[.,]?\d{0,6}$/;

        if (mapCoordinates) {
            dispatch(resetLastMapClickPosition());
        }
        if (!value || value.match(floatRegex)) {
            setCoords((coordsState) => {
                return {
                    ...coordsState,
                    [name]: value.replace(',', '.'),
                };
            });
        }
    };

    const handleItemDelete = () => {
        dispatch(fetchLastState(preview?.elementId, filters));
    };
    const handleCloseClick = (deselect?: boolean) => {
        dispatch(resetLastMapClickPosition());
        dispatch(closePreview(creatorLevel, deselect));
    };

    const checkPrivileges = (privilege: string) => {
        if (!privileges[privilege]) {
            showNotification(
                'error',
                TranslationHelper.translate(
                    'No access to the resource due to insufficient permissions'
                )
            );
            handleCloseClick(false);
            return false;
        }

        return true;
    };

    if (!lastState) {
        return (
            <>
                <PaneHeader
                    title={TranslationHelper.translate('Preview')}
                    onCloseClick={handleCloseClick}
                />
                <LinearProgress />
            </>
        );
    }

    return (
        <React.Fragment>
            {preview !== null &&
                preview.mode === 'add' &&
                checkPrivileges('setCoordinates') && (
                    <LastStateCreate
                        handleCloseClick={handleCloseClick}
                        showNotification={showNotification}
                        handleCoordinatesInput={handleCoordinatesInput}
                        latitude={coords.latitude}
                        longitude={coords.longitude}
                    />
                )}
            {preview !== null && preview.mode === 'preview' && (
                <LastStatePreview
                    handleItemDelete={handleItemDelete}
                    handleCloseClick={handleCloseClick}
                />
            )}
        </React.Fragment>
    );
};

export default LastState;
