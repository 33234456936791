import { useSelector } from 'react-redux';
import {
    getDiscoveryUIPreviewPane,
    getPreviewCreatorLevel,
} from '../../../../selectors';
import { getConfigParamId } from '../../../../selectors/refuellAndLossConfig';

import { getUserSettings } from '../../../../../../state/user';

export const useConfigParamId = () => useSelector(getConfigParamId);
export const usePane = () => useSelector(getDiscoveryUIPreviewPane);
export const useUserSettings = () => useSelector(getUserSettings);
export const useCreatorLevel = () => useSelector(getPreviewCreatorLevel);
