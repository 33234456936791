import React, { ComponentType } from 'react';
import { compose } from 'redux';

import c3, { ChartAPI } from 'c3';
import d3 from 'd3';
import Moment from 'moment-timezone';
import TranslationHelper from '../../helpers/TranslationHelper';

import {
    calculateZoomedChart,
    combineItem,
    getTooltipHeader,
    loadZoomedData,
    mergeData,
    prepareMinMaxData,
    simplify,
} from './utils';

import { Connectable, IOwnProps, TConnectableProps } from './Connectable.hoc';
import { Themable, TThemableProps } from './Themable.hoc';

import './EventChart.css';

type TProps = IOwnProps & TConnectableProps & TThemableProps;

class EventChart extends React.Component<TProps> {
    private dotsChart: null | ChartAPI = null;
    private mainChart: null | ChartAPI = null;
    private resizeTimer?: number;

    private mainChartName = '';
    private mainChartElement: null | HTMLElement = null;

    private reducedChartData: null | any[] = null;
    private unreducedFilteredChartData = null;
    private reducedFilteredChartData: null | any[] = null;

    private zoomExtent?: [number, number];

    private zoomActionTimer?: number;
    private zoomLoaderVisible = false;

    public componentDidUpdate(prevProps: TProps) {
        if (prevProps.isMenuOpen !== this.props.isMenuOpen) {
            this.resizeChart();
        }
    }

    public componentDidMount() {
        window.addEventListener('resize', this.resizeChart);

        this.mainChartName = '#' + this.props.chartId;

        this.mainChartElement = document.getElementById(this.props.chartId);

        this._prepareAndRenderChart(this.props);
    }

    // TODO use componentDidUpdate or mark it as UNSAFE
    public UNSAFE_componentWillReceiveProps(nextProps: TProps) {
        this._prepareAndRenderChart(nextProps, this.props);
    }

    public componentWillUnmount() {
        if (this.mainChart !== null) {
            this.mainChart.destroy();
        }
        if (this.dotsChart !== null) {
            this.dotsChart.destroy();
        }
        window.removeEventListener('resize', this.resizeChart);
    }

    public render() {
        const { chartId, classes } = this.props;

        return (
            <div className={classes.chart}>
                <div className={classes.eventsChartHeader}>
                    <div className={classes.fuelLegend}>
                        {TranslationHelper.translate('Level')}
                    </div>
                    <div className={classes.temperatureLegend}>
                        {TranslationHelper.translate('Temperature')}
                    </div>
                </div>
                <div
                    id="events-chart-container"
                    className={classes.eventsChartContainer}
                >
                    <div className={classes.outerChart}>
                        <div id={chartId} className="events-chart-lines" />
                    </div>
                </div>
            </div>
        );
    }

    private resizeChart = () => {
        clearTimeout(this.resizeTimer);
        this.resizeTimer = window.setTimeout(() => {
            this._prepareAndRenderChart(this.props);
            clearTimeout(this.resizeTimer);
        }, 300);
    };

    private _prepare(
        props: TProps,
        callback: (params: { data: any; allData: any; minMaxData: any }) => void
    ) {
        if (!props || props.allData.length === 0) {
            return;
        }
        const { unreducedChartData, prepareData } = props;

        this.reducedChartData = simplify(unreducedChartData);
        this.unreducedFilteredChartData = prepareData(
            props.filteredData,
            this.props.paramDefinitions
        );
        this.reducedFilteredChartData = simplify(
            this.unreducedFilteredChartData
        );

        const allData = mergeData(
            this.reducedChartData,
            this.reducedFilteredChartData,
            combineItem
        );
        const minMaxData = prepareMinMaxData(
            this.reducedChartData,
            props.sortedAscending
        );
        this.zoomExtent = [+minMaxData.time.min, +minMaxData.time.max];

        callback({ data: props.data, allData, minMaxData });
    }

    private customRender = ({
        data,
        allData,
        minMaxData,
    }: {
        data: any[];
        allData: any[];
        minMaxData: {
            fuelLevel: {
                min: number;
                max: number;
            };
            temperature: {
                min: number;
                max: number;
            };
        };
    }) => {
        const height =
            document.getElementById('events-chart-container')?.clientHeight ||
            0;
        this._renderMainChart(data, allData, minMaxData, height);
    };

    private _prepareAndRenderChart(props: TProps, previousProps?: TProps) {
        if (
            props.allData &&
            props.allData.length > 0 &&
            props.filteredData &&
            (previousProps
                ? previousProps.allData !== props.allData ||
                  previousProps.filteredData !== props.filteredData
                : true)
        ) {
            this._prepare(props, this.customRender);
        }
    }

    private _onChartZoom(/*domain*/) {
        if (!this.zoomLoaderVisible) {
            this.zoomLoaderVisible = true;
            this.forceUpdate();
        }
    }

    private _onChartZoomEnd(domain: any) {
        const { unreducedChartData } = this.props;
        window.clearTimeout(this.zoomActionTimer);

        this.zoomActionTimer = window.setTimeout(() => {
            window.clearTimeout(this.zoomActionTimer);

            const reducedChartData = calculateZoomedChart(
                domain,
                unreducedChartData,
                this.reducedChartData
            );
            this.reducedChartData = reducedChartData;
            const reducedFilteredChartData = calculateZoomedChart(
                domain,
                this.unreducedFilteredChartData,
                this.reducedFilteredChartData
            );
            this.reducedFilteredChartData = reducedFilteredChartData;
            const mergedData = mergeData(
                reducedChartData,
                reducedFilteredChartData,
                combineItem
            );

            loadZoomedData(this.mainChart, domain, mergedData, [
                'level',
                'temperature',
                'amount',
                'eventType',
                'temperaturePoint',
                'levelPoint',
            ]);

            this.zoomExtent = domain;
            this.zoomLoaderVisible = false;
            this.forceUpdate();
        }, 300);
    }

    private _renderMainChart(
        objectData: any[],
        allData: any[],
        minMaxData: {
            fuelLevel: {
                min: number;
                max: number;
            };
            temperature: {
                min: number;
                max: number;
            };
        },
        height: number
    ) {
        if (allData && this.mainChartElement) {
            const that = this;
            if (this.mainChart) {
                this.mainChart.destroy();
            }
            this.mainChart = c3.generate({
                size: {
                    height,
                },
                bindto: this.mainChartName,
                data: {
                    json: allData,
                    keys: {
                        x: 'date',
                        value: [
                            'level',
                            'temperature',
                            'amount',
                            'eventType',
                            'levelPoint',
                            'temperaturePoint',
                        ],
                    },
                    axes: {
                        fuelLevel: 'y',
                        temperature: 'y2',
                        temperaturePoint: 'y2',
                    },
                    types: {
                        level: 'step',
                        temperature: 'line',
                        amount: 'none',
                        eventType: 'none',
                        levelPoint: 'line',
                        temperaturePoint: 'line',
                    },
                },
                axis: {
                    x: {
                        type: 'timeseries',
                        tick: {
                            format: '%d.%m',
                            fit: false,
                        },
                        extent: that.zoomExtent,
                    },
                    y: {
                        padding: {
                            bottom: 0,
                        },
                        tick: {
                            format: (d) => {
                                return d + ' l';
                            },
                        },
                        min: minMaxData.fuelLevel.min,
                        max: minMaxData.fuelLevel.max,
                    },
                    y2: {
                        show: true,
                        padding: {
                            bottom: 0,
                        },
                        tick: {
                            format: (d) => {
                                return d3.format('.2f')(d) + ' °C';
                            },
                        },
                        min: minMaxData.temperature.min,
                        max: minMaxData.temperature.max,
                    },
                },
                legend: {
                    show: false,
                },
                zoom: {
                    enabled: true,
                    onzoom: this._onChartZoom.bind(that),
                    onzoomend: this._onChartZoomEnd.bind(that),
                },
                padding: {
                    top: 10,
                    right: 60,
                    bottom: 0,
                    left: 60,
                },
                transition: {
                    duration: 0,
                },
                grid: {
                    x: {
                        show: false,
                    },
                    y: {
                        show: true,
                    },
                },
                line: {
                    step: {
                        type: 'step-after',
                    },
                },
                tooltip: {
                    position(_, tooltipWidth, tooltipHeight, element) {
                        interface IContext {
                            d3: { mouse: (x: any) => any };
                            currentHeight: number;
                            currentWidth: number;
                            y2Orient: number;
                        }
                        const chartInternal = this as IContext;
                        let [x, y] = chartInternal.d3.mouse(element);
                        const maxHeight =
                            chartInternal.currentHeight - 50 - tooltipHeight;
                        if (y > maxHeight) {
                            y = maxHeight;
                        }
                        const maxWidth =
                            chartInternal.currentWidth -
                            (chartInternal.y2Orient ? 130 : 100) -
                            tooltipWidth;
                        if (x > maxWidth) {
                            x = maxWidth;
                        }
                        return { left: x + 70, top: y + 20 };
                    },
                    contents: (data) => {
                        const level = data[0].value;
                        const temperature = data[1].value;
                        const amount = data[2].value;
                        const amountRow = amount
                            ? '<tr><td>' +
                              TranslationHelper.translate('Amount') +
                              ': </td><td>' +
                              amount.toFixed(2) +
                              ' l</td></tr>'
                            : '';
                        const levelRow = level
                            ? '<tr><td>' +
                              TranslationHelper.translate('Level') +
                              ': </td><td>' +
                              level +
                              ' l</td></tr>'
                            : '';
                        const temperatureRow =
                            temperature || temperature === 0
                                ? '<tr><td>' +
                                  TranslationHelper.translate('Temperature') +
                                  ': </td><td>' +
                                  temperature.toFixed(2) +
                                  '°C</td></tr>'
                                : '';
                        const eventName = getTooltipHeader(data[3].value);

                        return (
                            '<div class="events-chart-tooltip">' +
                            '<table>' +
                            '<tr>' +
                            '<td colspan="2" class="tooltip-header">' +
                            TranslationHelper.translate(eventName) +
                            '</td>' +
                            '</tr>' +
                            '<tr>' +
                            '<td>' +
                            TranslationHelper.translate('Date') +
                            ': </td><td>' +
                            Moment(data[0].x).format(
                                this.props.userSettings.dateFormat
                            ) +
                            '</td>' +
                            '</tr>' +
                            amountRow +
                            levelRow +
                            temperatureRow +
                            '</table>' +
                            '</div>'
                        );
                    },
                },
            });
        }
    }
}

export default compose(
    Connectable,
    Themable
)(EventChart) as ComponentType<IOwnProps>;
