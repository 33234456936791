import URI from 'urijs';
import AppConfig from '../../constants/AppConfig';
import UrlConst from '../../constants/UrlConst';
import { IPrivilege, IRole } from '../../state/app/adminPanel';
import apiClient from '../common/apiClient';
import {
    makeDeleteRequestSettings,
    makeGetRequestSettings,
    makePostRequestSettings,
    makePutRequestSettings,
    makeUrl,
} from '../common/utils';

export const makePrivilegesUrl = (suffix: string, params = {}) => {
    const url = new URI(
        AppConfig.instance.getUrl(UrlConst.PRIVILEGES_ENDPOINT_URL) + suffix
    );
    return makeUrl(url, params);
};

export const makeRolesUrl = (suffix: string, params = {}) => {
    const url = new URI(
        AppConfig.instance.getUrl(UrlConst.ROLES_ENDPOINT_URL) + suffix
    );
    return makeUrl(url, params);
};

export const restGetPrivileges = (): Promise<IPrivilege[]> =>
    apiClient.sendWithoutErrorsHandling(
        makeGetRequestSettings(makePrivilegesUrl(``))
    );

export const restGetRoles = (): Promise<IRole[]> =>
    apiClient.sendWithoutErrorsHandling(
        makeGetRequestSettings(makeRolesUrl(``))
    );

export const restGetRole = (id: number): Promise<IRole> =>
    apiClient.sendWithoutErrorsHandling(
        makeGetRequestSettings(makeRolesUrl(`/${id}`))
    );

export interface IRestPostRole {
    name: string;
    description: string;
    privileges: number[];
}

export const restPostRole = (role: IRestPostRole): Promise<null> =>
    apiClient.sendWithoutErrorsHandling(
        makePostRequestSettings(makeRolesUrl(`/`), role)
    );

export interface IRestPutRole {
    id: number;
    name?: string;
    description?: string;
    privileges?: number[];
}

export const restPutRole = (role: IRestPutRole): Promise<null> =>
    apiClient.sendWithoutErrorsHandling(
        makePutRequestSettings(makeRolesUrl(`/${role.id}`), role)
    );

export const restDeleteRole = (id: number): Promise<null> =>
    apiClient.sendWithoutErrorsHandling(
        makeDeleteRequestSettings(makeRolesUrl(`/${id}`))
    );
