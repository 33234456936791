import React from 'react';
import Brand from '../../Brand';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => {
    return {
        loginFooter: {
            padding: '10px 0px',
            borderTop: '1px solid #ecf0f1',
            fontSize: '14px',
            fontWeight: 'normal',
            margin: 0,
            textAlign: 'right',
        },
    };
});

const LoginFormFooter = () => {
    const classes = useStyles();

    return (
        <footer className={classes.loginFooter}>
            <Brand />
        </footer>
    );
};

export default LoginFormFooter;
