import React, { ComponentType } from 'react';
import { compose } from 'redux';
import Translator from '../../../helpers/TranslationHelper';
import { Themable, TThemableProps } from './Themable.hoc';

import { Checkbox, FormControlLabel } from '@material-ui/core';

interface IOwnProps {
    checked: boolean;
    onChange: () => void;
    label: string;
    disabled?: boolean;
    useContrastColors?: boolean;
    name?: string;
}

type TProps = IOwnProps & TThemableProps;

const CustomCheckbox = (props: TProps) => {
    const { classes } = props;
    const useContrastColors = props.useContrastColors ?? true;

    return (
        <>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={props.checked}
                        onChange={props.onChange}
                        disabled={props.disabled}
                        color={'primary'}
                        classes={
                            useContrastColors
                                ? {
                                      root: classes.colorCheckbox,
                                      checked: classes.colorChecked,
                                  }
                                : undefined
                        }
                    />
                }
                label={Translator.translate(`${props.label}`)}
                disabled={props.disabled}
                classes={
                    useContrastColors
                        ? {
                              root: classes.label,
                              label: classes.label,
                              disabled: classes.disabled,
                          }
                        : undefined
                }
                name={props.name}
            />
        </>
    );
};

export default compose(Themable)(CustomCheckbox) as ComponentType<IOwnProps>;
