import React, { useState } from 'react';

import { Paper } from '@material-ui/core';

import {
    useClientAndLocationPaneVisible,
    useMainMapVisible,
} from '../../state/ui/customerService/clientsAndLocations/index.hooks';
import {
    useBottomGrid,
    useBottomGridPaneVisible,
} from '../../state/ui/customerService/bottomGrid/index.hooks';

import TranslationHelper from '../../helpers/TranslationHelper';

import { useStyles } from './Themable.hooks';

import View from '../../components/View';
import BottomGrid from './components/BottomGrid';
import ClientsAndLocationsGrid from './components/ClientsAndLocationsGrid';
import ClientAndLocationPane from './components/ClientServicePane/components/ClientsAndLocations/ClientAndLocationPane';
import TasksPane from './components/ClientServicePane/components/Tasks/TasksPane';
import ContractsPane from './components/ClientServicePane/components/ContractItems/ContractItemsPane';
import EditOverlay from '../discovery/components/EditOverlay';
import MapDialog from './components/MapDialog/MapDialog';
import EventsPane from './components/ClientServicePane/components/EventsPane/EventsPane';
import PageContent from '../../components/PageContent';
import { Box } from '@mui/material';
import CscMap from './components/CscMap';
import useStateReducer from '../../hooks/useStateReducer';
import {
    STYLE_CONTRACTS,
    STYLE_EVENTS,
    STYLE_TASKS,
} from './components/CscMap/utils/style';
import { useTasksSourceSetWithFilter } from '../../state/ui/customerService/bottomGrid/tasks/index.hooks';
import { useContractItemsSourceSetWithFilter } from '../../state/ui/customerService/bottomGrid/contractItems/index.hooks';
import { useEventsSourceSetWithFilter } from '../../state/ui/customerService/bottomGrid/events/index.hooks';
import { useEventsByRfidSourceSetWithFilter } from '../../state/ui/customerService/bottomGrid/eventsByRfid/index.hooks';

import {
    IFeatureSelectedOnGridParams,
    ISelectFeaturesOnMapParams,
    TVectorGeometrySource,
} from './types';
import { FEATURE_SELECTED } from './components/CscMap/utils/utils';

const CustomerServicePage = () => {
    const mapVisible = useMainMapVisible();
    const classes = useStyles();
    const bottomGrid = useBottomGrid();
    const tasksSourceSet = useTasksSourceSetWithFilter();
    const contractsSourceSet = useContractItemsSourceSetWithFilter();
    const eventsSourceSet = useEventsSourceSetWithFilter();
    const eventsByRfidSourceSet = useEventsByRfidSourceSetWithFilter();
    const clientAndLocationPaneVisible = useClientAndLocationPaneVisible();
    const bottomGridVisible = useBottomGridPaneVisible();
    const paneVisible = clientAndLocationPaneVisible || bottomGridVisible;
    const [locationsSource, setLocationsSource] =
        useState<TVectorGeometrySource>(null);
    const [bottomGridSource, setBottomGridSource] =
        useState<TVectorGeometrySource>(null);

    const [lastSelectedLocation, setLastSelectedLocation] = useState('');
    const [lastSelectedBottomItem, setLastSelectedBottomItem] = useState('');
    const initialState = {
        clientsAndLocations: [],
        Tasks: [],
        ContractItems: [],
        Events: [],
        EventsByRfid: [],
    };
    const { values: selectedItems, handleChangeValues: selectItems } =
        useStateReducer(initialState);

    const gridWithBottomGridClass = bottomGrid
        ? classes.visibleBottomGrid
        : classes.hiddenBottomGrid;

    const gridWithRightPaneClass = paneVisible
        ? classes.paneVisible
        : classes.hiddenPane;
    const gridWithMapClass = mapVisible
        ? classes.gridMapVisible
        : classes.gridMapHidden;
    const paneClass = mapVisible
        ? classes.paneWrapperMapVisible
        : classes.paneWrapperMapHidden;

    //track last selected item id to correctly scroll grid/open pane
    const handleSelectLastItem = (sourceSetId: string, last: string) => {
        sourceSetId === 'clientsAndLocations'
            ? setLastSelectedLocation(last)
            : setLastSelectedBottomItem(last);
    };

    //used in map, to select items(local state, selectedItems - source of truth) and select last item
    const handleSelectFeaturesOnMap = ({
        items,
        sourceSetId,
        lastItemId,
    }: ISelectFeaturesOnMapParams) => {
        handleSelectLastItem(sourceSetId, lastItemId);
        selectItems(sourceSetId, items);
    };

    const getBottomGridSourceSet = () => {
        switch (bottomGrid) {
            case 'Tasks':
                return tasksSourceSet;
            case 'ContractItems':
                return contractsSourceSet;
            case 'Events':
                return eventsSourceSet;
            case 'EventsByRfid':
                return eventsByRfidSourceSet;
            default:
                return null;
        }
    };

    const getBottomGridStyle = () => {
        switch (bottomGrid) {
            case 'Tasks':
                return STYLE_TASKS;
            case 'ContractItems':
                return STYLE_CONTRACTS;
            case 'Events':
                return STYLE_EVENTS;
            case 'EventsByRfid':
                return STYLE_EVENTS;
            default:
                return {};
        }
    };

    //callback after selecting item on grid. reflects changes in local state and select features on map
    const onFeatureSelected = ({
        source,
        sourceSetId,
        items,
    }: IFeatureSelectedOnGridParams) => {
        if (!source) return;

        const itemSet = new Set(items);
        const features = source.getFeatures();
        selectItems(sourceSetId, items);
        features.forEach((feature) => {
            const data = feature?.get('data');
            const id = data?.id;

            feature.unset('selected');

            if (id && itemSet.has(id)) {
                feature.set('selected', FEATURE_SELECTED);
            }
        });
    };
    const bottomGridProps = {
        id: bottomGrid,
        sourceSet: getBottomGridSourceSet(),
        selectedItems: bottomGrid ? selectedItems[bottomGrid] : [],
        source: bottomGridSource,
        style: getBottomGridStyle(),
        setSource: setBottomGridSource,
    };

    const renderPane = () => {
        return bottomGridVisible ? (
            <>
                {bottomGrid === 'Tasks' && bottomGridVisible && <TasksPane />}
                {bottomGrid === 'ContractItems' && bottomGridVisible && (
                    <ContractsPane />
                )}
                {(bottomGrid === 'Events' || bottomGrid === 'EventsByRfid') &&
                    bottomGridVisible && <EventsPane />}
            </>
        ) : (
            clientAndLocationPaneVisible && <ClientAndLocationPane />
        );
    };

    return (
        <View title={TranslationHelper.translate('Client service')}>
            <PageContent>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        height: '100%',
                    }}
                >
                    <div>
                        <div className={gridWithBottomGridClass}>
                            <div className={gridWithMapClass}>
                                <div className={gridWithRightPaneClass}>
                                    <ClientsAndLocationsGrid
                                        selectedItems={selectedItems}
                                        onFeatureSelected={onFeatureSelected}
                                        source={locationsSource}
                                        lastSelectedItem={lastSelectedLocation}
                                        handleSelectLastItem={
                                            handleSelectLastItem
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                        {bottomGrid && (
                            <div className={classes.bottomGrid}>
                                <div className={gridWithMapClass}>
                                    <div className={gridWithRightPaneClass}>
                                        <BottomGrid
                                            onFeatureSelected={
                                                onFeatureSelected
                                            }
                                            lastSelectedBottomItem={
                                                lastSelectedBottomItem
                                            }
                                            source={bottomGridSource}
                                            selectedItems={selectedItems}
                                            handleSelectLastItem={
                                                handleSelectLastItem
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        )}
                        {paneVisible && (
                            <Paper className={paneClass}>{renderPane()}</Paper>
                        )}
                        <MapDialog />
                        <CscMap
                            locationsSource={locationsSource}
                            handleSelectFeatures={handleSelectFeaturesOnMap}
                            selectedItems={selectedItems}
                            setSource={setLocationsSource}
                            bottomGrid={bottomGridProps}
                        />
                    </div>
                </Box>
            </PageContent>
            <EditOverlay />
        </View>
    );
};

export default CustomerServicePage;
