import { ISourceSet } from '../../state/types';
import {
    makeCustomSourceSetFetch,
    makeCustomSourceSetPost,
} from './_shared/fetchMakers';
import { normalizeSingleEntity } from './_shared/normalizers';
import { makeSourceSetUrl } from './_shared/urlMakers';

export const fetchCustomSourceSet = makeCustomSourceSetFetch<ISourceSet>(
    makeSourceSetUrl,
    normalizeSingleEntity
);

export const fetchCustomSourceSetRaw =
    makeCustomSourceSetFetch<ISourceSet>(makeSourceSetUrl);
export const fetchCustomSourceSetRawPost =
    makeCustomSourceSetPost<ISourceSet>(makeSourceSetUrl);

export const archiveEventsPath = '/rest/api/source-sets/archive-events';
