import React from 'react';

import { IconButton, Tooltip } from '@material-ui/core';

import { ICellRendererParams } from 'ag-grid-community';

import TranslationHelper from '../../../../helpers/TranslationHelper';
import { markAlertAsRead } from '../../../../state/app/alertsPage';
import AppConfig from '../../../../constants/AppConfig';

import { useAppDispatch } from '../../../../hooks';
import { InternalLinkAction } from '../../../../components/ButtonActions/ButtonActions';
import { useStyles } from '../../Themable.hooks';
import { usePrivileges } from '../../../../state/auth/index.hooks';
import { default as MarkAsReadIcon } from '@material-ui/icons/CheckCircleOutline';

type TOwnProps = ICellRendererParams;

const AlertActions = (props: TOwnProps) => {
    const enterpriseKey =
        AppConfig &&
        AppConfig.instance.getConfigKey(AppConfig.PROPERTY_GRID)?.key;

    const privileges = usePrivileges();
    const dispatch = useAppDispatch();
    const createEventUrl = () => {
        return `/discovery?action=showEvent&mid=${props.data.object.id}&dt=${props.data.occurAt}`;
    };
    const onClick = () => {
        dispatch(
            markAlertAsRead(props.data.id, () => {
                enterpriseKey
                    ? props.api.refreshServerSideStore({})
                    : props.api.purgeInfiniteCache();
            })
        );
    };
    const classes = useStyles();

    const shouldShowMarkAsReadButton = props.data?.status === 'unread';
    const shouldShowEventRedirectButton =
        props.data?.object?.id && props.data?.occurAt;
    const showEventIcon = {
        id: 'showEvent',
        name: 'showEvent',
        label: TranslationHelper.translate('Go to event'),
    };
    return props.value ? (
        <>
            {privileges.discoveryContext && shouldShowEventRedirectButton && (
                <InternalLinkAction
                    classes={classes}
                    url={createEventUrl()}
                    openInNewTab={true}
                    icon={showEventIcon}
                />
            )}

            {shouldShowMarkAsReadButton && (
                <Tooltip title={TranslationHelper.translate('mark as read')}>
                    <IconButton
                        className={classes.iconButton}
                        onClick={onClick}
                    >
                        <MarkAsReadIcon className={classes.iconButtonIcon} />
                    </IconButton>
                </Tooltip>
            )}
        </>
    ) : (
        ''
    );
};

export default AlertActions;
