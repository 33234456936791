import { connect, ConnectedProps } from 'react-redux';
import { TRootState } from '../../../../../../store';

import { getGroups } from '../../../../../../state/app/collections';
import {
    enterCreateGroupMode,
    getSelectedGroupId,
    selectGroup,
} from '../../../../../../state/ui/groupsSlice';
import {
    getGroupsAsSourceSet,
    getGroupsSourceSetModel,
} from '../../../../selectors';

const mapStateToProps = (state: TRootState) => ({
    sourceSet: getGroupsAsSourceSet(state),
    sourceSetModel: getGroupsSourceSetModel(state),
    selectedGroup: getSelectedGroupId(state),
});
const mapDispatchToProps = {
    selectGroup,
    getGroups,
    enterCreateGroupMode,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type TConnectableProps = ConnectedProps<typeof connector>;

export const Connectable = connector;
