import React, { useState, useEffect } from 'react';

import {
    useCreatorLevel,
    useEditMode,
    useOnlyNotAssigned,
    useIncludeNotPlanned,
    useSourceSetDates,
    useOnlyNotPlanned,
} from '../../Connectable.hooks';

import {
    fetchTasks,
    resetLevel,
    setNotAssignedFilter,
} from '../../../../state/_actions';

import { useAppDispatch } from '../../../../hooks';

import { NotAssignedSwitchComponent } from './NotAssignedSwitchComponent';
interface IOwnProps {
    sourceSetId: string;
}

const NotAssignedSwitch = ({ sourceSetId }: IOwnProps) => {
    const [onlyNotAssigned, setOnlyNotAssigned] = useState<boolean>(false);

    const dispatch = useAppDispatch();

    const editMode = useEditMode();

    const includeNotPlannedFromStore = useIncludeNotPlanned();

    const onlyNotAssignedFromStore = useOnlyNotAssigned();

    const onlyNotPlannedFromStore = useOnlyNotPlanned();

    const sourceSetDates = useSourceSetDates();

    const creatorLevel = useCreatorLevel();

    useEffect(() => {
        setOnlyNotAssigned(onlyNotAssignedFromStore);
    }, []);

    const handleOnlyNotAssigned = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setOnlyNotAssigned(event.target.checked);
        dispatch(setNotAssignedFilter(sourceSetId, event.target.checked));

        const fetchTasksParams = () => {
            return onlyNotPlannedFromStore
                ? {
                      onlyNotAssigned: event.target.checked,
                      includeNotPlanned: onlyNotPlannedFromStore,
                  }
                : {
                      from: sourceSetDates?.from,
                      to: sourceSetDates?.to,
                      onlyNotAssigned: event.target.checked,
                      includeNotPlanned: includeNotPlannedFromStore,
                  };
        };

        dispatch(fetchTasks(fetchTasksParams()));
        if (creatorLevel > 0) {
            dispatch(resetLevel(1));
        }
    };
    return (
        <NotAssignedSwitchComponent
            onlyNotAssigned={onlyNotAssigned}
            handleOnChange={handleOnlyNotAssigned}
            disabled={editMode}
        />
    );
};

export default NotAssignedSwitch;
