import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export interface StyleProps {
    displaySubjectFields?: boolean;
}

export const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) =>
    createStyles({
        field: {
            paddingBottom: theme.spacing(),
            width: '180px',
            margin: '6px',
        },
        fieldMd: {
            paddingBottom: theme.spacing(),
            width: '250px',
            margin: '6px',
        },
        fieldLg: {
            paddingBottom: theme.spacing(),
            width: '340px',
            margin: '6px',
        },
        fieldsArray: {
            display: 'flex',
        },
        card: {
            display: 'flex',
        },
        blockFormFields: {
            display: 'flex',
            flexDirection: 'row',
        },
        formContainer: {
            display: 'flex',
            flexDirection: 'row',
        },
        forumColumn: {
            width: ({ displaySubjectFields }) =>
                displaySubjectFields ? '300px' : '400px',
            marginRight: theme.spacing(),
        },
    })
);
