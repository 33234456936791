import React, { FunctionComponent } from 'react';
import { compose } from 'redux';

import { IconButton, Tooltip } from '@material-ui/core';
import MapIcon from '@material-ui/icons/Map';

import TranslationHelper from '../../../helpers/TranslationHelper';
import { ICoordinates } from '../../../state/types';
import { Themable, TThemableProps } from './Themable.hoc';

interface IOwnProps {
    coordinates?: ICoordinates;
}

type TProps = IOwnProps & TThemableProps;

const GoogleMapsButton: FunctionComponent<TProps> = (props) => {
    const generateUrl = (coordinates: ICoordinates) =>
        `http://maps.google.com?q=${coordinates.y},${coordinates.x}`;

    if (!props.coordinates) {
        return null;
    }

    return (
        <Tooltip title={TranslationHelper.translate('See on Google Maps')}>
            <IconButton
                className={props.classes.contrastText}
                aria-label="See on google maps"
                href={generateUrl(props.coordinates)}
                target={'_blank'}
                rel={'noreferrer'}
            >
                <MapIcon />
            </IconButton>
        </Tooltip>
    );
};

export default compose(Themable)(GoogleMapsButton);
