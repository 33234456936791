import React from 'react';

import { Field } from 'formik';

import { Box, FormHelperText } from '@material-ui/core';

import TranslationHelper from '../../helpers/TranslationHelper';

import TimeFieldWrapper from '../common/formikWrappers/TimeFieldWrapper';
import { Moment } from 'moment';

const TIME_BOX_HEIGHT = 80;

interface ITimeProperties {
    value: Moment;
    errors: string | undefined;
    name: string;
}

interface IOwnProps {
    timeFrom: ITimeProperties;
    timeTo: ITimeProperties;
}

const TimeRangeFormFields = ({ timeFrom, timeTo }: IOwnProps) => {
    const {
        value: timeFromValue,
        errors: timeFromErrors,
        name: timeFromName,
    } = timeFrom;
    const {
        value: timeToValue,
        errors: timeToErrors,
        name: timeToName,
    } = timeTo;

    return (
        <Box display="flex" alignItems="center" pt={1}>
            <Box
                display="flex"
                alignItems="start"
                pt={1}
                flexDirection="column"
                minHeight={TIME_BOX_HEIGHT}
            >
                <Field
                    name={timeFromName}
                    label={TranslationHelper.translate('From time')}
                    value={timeFromValue || null}
                    placeholder="hh:mm"
                    component={TimeFieldWrapper}
                    error={!!timeFromErrors}
                />
                <FormHelperText error={true}>{timeFromErrors}</FormHelperText>
            </Box>

            <Box fontSize={24} px={2}>
                -
            </Box>
            <Box
                display="flex"
                alignItems="start"
                pt={1}
                flexDirection="column"
                minHeight={TIME_BOX_HEIGHT}
            >
                <Field
                    name={timeToName}
                    label={TranslationHelper.translate('To time')}
                    placeholder="hh:mm"
                    value={timeToValue || null}
                    component={TimeFieldWrapper}
                    error={!!timeToErrors}
                />
                <FormHelperText error={true}>{timeToErrors}</FormHelperText>
            </Box>
        </Box>
    );
};

export default TimeRangeFormFields;
