import React from 'react';

import Chart from 'chart.js';

import { REVERSED_LOGIC } from '../../constants/dictionaries/TankLogic';
import ColorHelper from '../../helpers/ColorHelper';

interface IProps {
    name: string;
    values: any[];
    logicType: number;
    containerName: string;
}

class DonutChart extends React.Component<IProps> {
    private chart: Chart | null = null;
    state = {
        name: '',
    };
    handleResize = () => {
        this._renderChart(this.state.name);
    };
    public componentDidMount() {
        this._renderChart(this.state.name);
        this.setState({
            name: this.props.name,
            containerName: this.props.containerName,
            values: this.props.values,
        });
        window.addEventListener('resize', this.handleResize);
    }

    public componentDidUpdate() {
        this._renderChart(this.state.name);
    }
    public componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    public render() {
        return (
            <div>
                <canvas id={this.state.name} />
            </div>
        );
    }

    private getPlugins() {
        return [
            {
                afterDraw(chart: Chart) {
                    const ctx = chart.ctx;
                    if (ctx === null) {
                        return;
                    }
                    const width = chart.width as number;
                    const height = chart.height as number;

                    const fontSize = Number((height / 4).toFixed(2));
                    ctx.font = fontSize + 'px OpenSans-Bold';
                    if (
                        chart.config &&
                        chart.config.options &&
                        chart.config.options.elements &&
                        chart.config.options.elements.center
                    ) {
                        ctx.fillStyle =
                            chart.config.options.elements.center.fontColor;
                        ctx.textBaseline = 'middle';

                        const text = chart.config.options.elements.center.text;

                        const textX = Math.round(
                            (width - ctx.measureText(text).width) / 2
                        );
                        const textY = height / 2 + fontSize / 12;

                        ctx.fillText(text, textX, textY);
                    }
                },
            },
        ];
    }

    private _renderChart(name: string) {
        if (this.chart) {
            this.chart.destroy();
        }

        const canvasObject = document.getElementById(name);
        if (canvasObject) {
            const canvasContext = (
                canvasObject as HTMLCanvasElement
            ).getContext('2d');

            if (canvasContext === null) {
                return;
            }

            canvasContext.canvas.height = 0;
            canvasContext.canvas.width = 0;

            const containerHeight =
                document.querySelector('.' + this.props.containerName)
                    ?.clientHeight || 0;

            canvasContext.canvas.height = containerHeight;
            canvasContext.canvas.width = containerHeight;

            canvasContext.save();
            canvasContext.clearRect(0, 0, containerHeight, containerHeight);
            canvasContext.restore();

            const canvas = (
                document.getElementById(this.state.name) as HTMLCanvasElement
            ).getContext('2d');
            if (canvas) {
                const values = this.props.values;
                const currentLevel = values[0] || 0;
                const maxLevel = values[1] || 0;
                const percentValue =
                    maxLevel !== 0
                        ? Math.round((currentLevel / maxLevel) * 100)
                        : 0;
                const filledArea = percentValue < 100 ? percentValue : 100;
                const emptyArea = 100 - filledArea;
                const chartColor = ColorHelper.getColorByPercentage(
                    filledArea,
                    this.props.logicType === REVERSED_LOGIC
                );

                this.chart = new Chart(canvas, {
                    type: 'doughnut',
                    data: {
                        labels: [],
                        datasets: [
                            {
                                data: [filledArea, emptyArea],
                                backgroundColor: [chartColor, '#F2F2F2'],
                                hoverBackgroundColor: [chartColor, '#F2F2F2'],
                                borderWidth: 0,
                            },
                        ],
                    },
                    options: {
                        cutoutPercentage: 75,
                        rotation: 0.5 * Math.PI,
                        legend: {
                            display: false,
                        },
                        tooltips: {
                            enabled: false,
                        },
                        animation: {
                            duration: 0,
                        },
                        hover: {},
                        events: [],
                        elements: {
                            center: {
                                maxText: '100%',
                                text: !isNaN(percentValue)
                                    ? percentValue + '%'
                                    : '-',
                                fontColor: chartColor,
                                fontFamily: "'OpenSans-Light'",
                                fontStyle: 'normal',
                                minFontSize: 1,
                                maxFontSize: 256,
                            },
                        },
                    },
                    plugins: this.getPlugins(),
                });
            }
        }
    }
}

export default DonutChart;
