import { createSelector } from 'reselect';
import { TRootState } from '../../../store';

import TranslationHelper from '../../../helpers/TranslationHelper';
import { errorMessageHandler } from '../../../helpers/errorMessageHandler';

import {
    createGroupStorePath,
    getUpdateGroupStorePath,
} from '../../../state/app/collections';
import { IGroup } from '../../../state/app/collections/dataTypes';
import { IPendingRequests } from '../../../state/app/sync';
import { ISourceSet, TSourceSetEntity } from '../../../state/types';
import { getSelectedGroupId } from '../../../state/ui/groupsSlice';
import { getDevicesMode } from '../../../state/ui/devices';

export const getGroupsMode = (state: TRootState) => state.ui.groupsSlice.mode;

export const getTab = (state: TRootState) => state.ui.management.tab;

export const isManagemenInEditMode = createSelector(
    [getGroupsMode, getDevicesMode],
    (groupsMode, devicesMode) =>
        (groupsMode && groupsMode !== 'preview') ||
        (devicesMode && devicesMode !== 'preview')
);

export const isUserSettingsEditMode = (state: TRootState) =>
    state.ui.userSettings.mode;

export const getGroupsCollection = (state: TRootState) =>
    state.app.collections.groups;

export const getSourceSetModels = (state: TRootState) =>
    state.ui.discovery.general.sourceSetModels;

export const getSelectedGroup = createSelector(
    [getSelectedGroupId, getGroupsCollection],
    (id, collection) => (id && collection?.entities[id]) || null
);

export const getGroupId = createSelector(
    [getSelectedGroup],
    (group) => group?.id || null
);

const getGroupsAsArray = createSelector(
    [getGroupsCollection],
    (group) => group && group.result.map((id) => group.entities[id])
);

export const getGroupsAsSourceSet = createSelector(
    [getGroupsAsArray],
    (groups): ISourceSet | null =>
        groups && {
            id: 'groups',
            definitionId: 'groups',
            label: 'Groups',
            attributes: [
                { id: 'name', label: 'Name', type: 'text' },
                { id: 'number', label: 'Number', type: 'number' },
            ],
            layersAttributes: [],
            _meta: {},
            entities: groups.map(
                (group: IGroup): TSourceSetEntity => ({
                    ...group,
                    id: group.id.toString(),
                    _meta: {},
                })
            ),
        }
);

export const getGroupsSourceSetModel = createSelector(
    [getSourceSetModels],
    (models) => models.groups || null
);

const getSyncRequests = (state: TRootState) => state.app.sync.requests;

const getRequestResult = (
    requests: IPendingRequests,
    storePath: string,
    successMessage: string
) => {
    const request = requests[storePath];
    if (!request) {
        return null;
    }

    const { success, error } = request;

    return {
        ...request,
        success: success && {
            ...success,
            getMessage: () => TranslationHelper.translate(successMessage),
        },
        error: error && {
            ...error,
            getMessage: errorMessageHandler(error.status, {
                409: 'Group with given name exists',
            }),
        },
    };
};

export const getCreateGroupRequest = createSelector(
    [getSyncRequests],
    (requests) =>
        getRequestResult(requests, createGroupStorePath, 'Group created')
);

export const getUpdateGroupRequest = createSelector(
    [getGroupId, getSyncRequests],
    (id, requests) =>
        getRequestResult(requests, getUpdateGroupStorePath(id), 'Group updated')
);
