import React, {
    Dispatch,
    MouseEventHandler,
    SetStateAction,
    useEffect,
} from 'react';

import { useDispatch } from 'react-redux';

import { useSnackbar } from 'notistack';

import {
    clearEmptyAssignments,
    fetchGroupEmptyAssignments,
    IAssignmentsAccess,
    IGroupAssignment,
} from '../../../../../../../../../../state/ui/groupsSlice';
import { useGroupEmptyAssignmentsLoading } from '../../../../../../../../../../state/ui/groupsSlice/index.hooks';

import { useToolkitDispatch } from '../../../../../../../../../../hooks';
import { useGroupData } from '../../../GroupPreview/Connectable.hooks';

import TranslationHelper from '../../../../../../../../../../helpers/TranslationHelper';
import { useDebounceEffect } from '../../../../../../../../../../helpers/hooks';
import { errorMessageHandler } from '../../../../../../../../../../helpers/errorMessageHandler';

import { DEBOUNCE_LOW } from '../../../../../../../../../../constants/DebounceValues';

import CustomList from '../../../../../../../../../../components/TransferList/components/CustomList';
import {
    IFilterInitialState,
    IListObjectInitialState,
} from '../../../../../../../../../../components/TransferList/TransferList';
import { useUserDataPermissions } from '../../../../../../../../../../state/user/index.hooks';
import { hasAccessToLocations } from '../../_utils/access';

const TYPES = ['ALL', 'VEHICLE', 'EMPLOYEE', 'RFID_CARD', 'TANK', 'LOCATION'];
interface IOwnProps {
    groupId: string;
    objects: IListObjectInitialState<IGroupAssignment>;
    checked: IGroupAssignment[];
    setChecked: Dispatch<SetStateAction<IGroupAssignment[]>>;
    filter: IFilterInitialState;
    setFilter: React.Dispatch<Partial<IFilterInitialState>>;
    handleToggle: (value: IGroupAssignment) => MouseEventHandler;
}

const NAME_FILTER_MIN_LENGTH = 3;

const LeftList = ({
    groupId,
    filter,
    setFilter,
    objects,
    checked,
    setChecked,
    handleToggle,
}: IOwnProps) => {
    const dispatch = useDispatch();
    const toolkitDispatch = useToolkitDispatch();
    const dataPermissions = useUserDataPermissions();

    const assignmentsAccess: IAssignmentsAccess = {
        registers: true,
        locations: hasAccessToLocations(groupId, dataPermissions),
    };
    const types = TYPES.filter(
        (t) => assignmentsAccess.locations || t !== 'LOCATION'
    );

    const { enqueueSnackbar } = useSnackbar();

    const loading = useGroupEmptyAssignmentsLoading();

    const groupData = useGroupData();

    const { nameUnassigned, typeUnassigned } = filter;
    const { unassignedObjects } = objects;
    useDebounceEffect(
        () => {
            handleChangeNameUnassigned();
        },
        [nameUnassigned],
        DEBOUNCE_LOW
    );

    useEffect(() => {
        handleChangeTypeUnassigned(typeUnassigned);
    }, [typeUnassigned]);

    const unassignedTitle = TranslationHelper.translate('objects.unassigned');

    const validName =
        nameUnassigned.includes('*') ||
        nameUnassigned.length >= NAME_FILTER_MIN_LENGTH;

    const prepareFilterParams = (tag: string) => {
        const filter: { name: string; tags?: string; excludedGroupId: string } =
            {
                name: nameUnassigned,
                excludedGroupId: groupData?.id || '',
            };
        if (tag !== 'ALL') {
            filter.tags = tag;
        }
        return filter;
    };
    const handleFetch = (param: string) => {
        toolkitDispatch(
            fetchGroupEmptyAssignments({
                queryParams: prepareFilterParams(param),
                access: assignmentsAccess,
            })
        )
            .unwrap()
            .catch((error) => {
                const message = errorMessageHandler(error.status)();
                enqueueSnackbar(message, {
                    variant: 'error',
                });
            });
    };
    const handleChangeTypeUnassigned = (type: string) => {
        setFilter({ typeUnassigned: type });

        if (!nameUnassigned) {
            return;
        }

        if (validName) {
            handleFetch(type);
        }
    };

    const handleChangeNameUnassigned = () => {
        if (!nameUnassigned) {
            dispatch(clearEmptyAssignments());
            return;
        }
        if (validName) {
            handleFetch(typeUnassigned);
        } else {
            dispatch(clearEmptyAssignments());
        }
    };

    return (
        <CustomList
            loaded={!loading}
            typeInput={{
                type: typeUnassigned,
                setType: (type) => setFilter({ typeUnassigned: type }),
            }}
            nameInput={{
                name: nameUnassigned,
                setName: (name) => setFilter({ nameUnassigned: name }),
            }}
            title={unassignedTitle}
            items={unassignedObjects}
            handleToggle={handleToggle}
            checked={checked}
            setChecked={setChecked}
            tooltipTitle="To search for unassigned objects, you have to enter at least 3 characters or use * as a wildcard."
            types={types}
        />
    );
};

export default LeftList;
