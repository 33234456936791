import moment, { Moment } from 'moment';
import { Action, Dispatch } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { makeReduxDuck } from 'teedux';
import { updateStatus } from '../app/statusSlice';
import { TStatus } from '../types';

// TODO move lastRequestId from state to closure

type TDefaultCallbackData = object & { result: string[]; entities: object };

const AUTO_REFRESH_MARKER = '@autoRefresh';
const BUFFER_MS = 20000;

interface ICallbacks<T> {
    onSuccess?: (dispatch: Dispatch, data: T) => void;
    onFail?: (dispatch: Dispatch, error: Error) => void;
    onReset?: (dispatch: Dispatch) => void;
    onFetchBegin?: () => Action;
}

type TError = Error & {
    code?: number;
};

interface IState {
    //TODO status is duplicating logic from isFetching and error - it can be idle/loading/done/error. both those flags should be removed, as we could operate on status only
    // can be done in #58878
    status: TStatus;
    isFetching: boolean;
    error: TError | null;
}

const fetching: {} = {};

const fetchManager = (() => {
    interface IFetchManagerEntry {
        autoRefreshInterval: number;
        callback: (...args: any[]) => any;
        args: any[];
    }

    interface IFetchManagerIndex {
        [key: string]: IFetchManagerEntry[];
    }

    const index: IFetchManagerIndex = {};

    let savedDispatch: null | ((...args: any[]) => any) = null;

    let intervalCycle: number = 0;
    setInterval(() => {
        intervalCycle++;

        const autoRefreshIds = Object.keys(index);
        autoRefreshIds.forEach((autoRefreshId) => {
            if (!fetching[autoRefreshId]) {
                const buffer: any[] = [];

                const flushBuffer = () => {
                    buffer.forEach(
                        (item) => savedDispatch && savedDispatch(item.action)
                    );
                };

                const consecutiveThunks = index[autoRefreshId].map(
                    ({ autoRefreshInterval, callback, args }) => {
                        if (intervalCycle % (autoRefreshInterval / 5) === 0) {
                            const fakeDispatch = (action: object) => {
                                buffer.push({ action });
                            };

                            return callback(
                                AUTO_REFRESH_MARKER,
                                ...args
                            )(fakeDispatch);
                        } else {
                            return;
                        }
                    }
                );

                Promise.all(consecutiveThunks)
                    .then(flushBuffer)
                    .catch(flushBuffer);
            }
        });
    }, 5000);

    return {
        store: (
            autoRefreshId: string,
            autoRefreshInterval: number = 10,
            callback: (...args: any[]) => any,
            args: any[]
        ) => {
            index[autoRefreshId] = (index[autoRefreshId] || [])
                .filter((item) => item.callback !== callback)
                .concat({ autoRefreshInterval, callback, args });
        },
        removeElementFromStore: (id: string, autoRefreshId: string) => {
            if (index[autoRefreshId]?.length > 0) {
                return (index[autoRefreshId] = index[autoRefreshId].filter(
                    (item) => item.args[0] !== id
                ));
            }
            return;
        },
        removeCollectionFromStore: (autoRefreshId: string) => {
            delete index[autoRefreshId];
        },

        saveDispatch: (dispatch: (...args: any[]) => any) => {
            savedDispatch = savedDispatch || dispatch;
        },
    };
})();

const makeFetchModule = <T = TDefaultCallbackData>(
    prefix: string,
    fetch: (...args: any[]) => Promise<any>,
    callbacks: ICallbacks<T> = {}
) => {
    const initialState: IState = {
        status: 'idle',
        isFetching: false,
        error: null,
    };

    const prefixName = prefix.split('/').pop();
    const autoRefreshId = prefixName ? prefixName.slice(0, -8) : '';

    let lastUserRequestId: number = 0;
    const makeGetRequestId = (baseId: number) => () => baseId++;
    const getRequestId = makeGetRequestId(1);
    let lastMaxUtcPersitedDate: string | undefined;

    function makeTimeStamp() {
        let lastTimestamp: Moment;

        return function () {
            const currentTimestamp = moment();

            if (!lastTimestamp) {
                lastTimestamp = currentTimestamp;
                return currentTimestamp
                    .clone()
                    .subtract(BUFFER_MS, 'milliseconds')
                    .toISOString();
            } else {
                const difference = currentTimestamp.diff(lastTimestamp);
                lastTimestamp = currentTimestamp;
                return currentTimestamp
                    .clone()
                    .subtract(difference + BUFFER_MS, 'milliseconds')
                    .toISOString();
            }
        };
    }

    const getTimeStamp = makeTimeStamp();

    const makeFetchCounter = () => {
        let counter = 0;
        return {
            increase: () => ++counter,
            decrease: () => --counter,
        };
    };

    const fetchCounter = makeFetchCounter();

    const duck = makeReduxDuck(prefix, initialState);

    const fetchBeginAction = duck.definePayloadlessAction(
        'FETCH_BEGIN',
        () => ({
            status: 'loading',
            isFetching: true,
            error: null,
        })
    );

    const fetchSuccessAction = duck.definePayloadlessAction(
        'FETCH_SUCCESS',
        () => ({
            status: 'done',
            isFetching: false,
        })
    );

    const fetchFailAction = duck.defineAction<{ error: Error }>(
        'FETCH_FAIL',
        (_, { error }) => ({
            status: 'error',
            error,
        })
    );

    const resetAction = duck.definePayloadlessAction(
        'RESET',
        () => initialState
    );
    const stopFetchingAction = duck.definePayloadlessAction(
        'STOP_FETCHING',
        () => initialState
    );
    const reducer = duck.getReducer();

    const fetchBegin = (
        dispatch: ThunkDispatch<null, null, Action<any>>,
        onFetchBegin?: () => Action
    ) => {
        if (onFetchBegin) {
            dispatch(onFetchBegin());
        }

        dispatch(fetchBeginAction());
    };

    const fetchSuccess = () => fetchSuccessAction();

    const fetchFail = (error: TError) =>
        fetchFailAction({
            error,
        });

    const reset =
        (...args: any[]): ThunkAction<void, null, null, Action> =>
        (dispatch) => {
            lastUserRequestId = 0;
            if (callbacks.onReset) {
                callbacks.onReset(dispatch);
            }
            return resetAction();
        };

    const stopFetchingElement = (id: string, autoRefreshIdParam: string) => {
        fetchManager.removeElementFromStore(id, autoRefreshIdParam);
        return stopFetchingAction();
    };

    const stopFetchingCollection = (autoRefreshIdParam: string) => {
        fetchManager.removeCollectionFromStore(autoRefreshIdParam);
        return stopFetchingAction();
    };

    const getMaxUtcPersistedDate = (entities: any): string | undefined => {
        const entitiesWithUtcPersistedAt = entities
            .filter((entity: any) => entity.utcPersistedAt)
            .map((entity: any) => new Date(entity.utcPersistedAt));
        if (!entitiesWithUtcPersistedAt?.length) {
            return;
        }
        const maxDate = Math.max(...entitiesWithUtcPersistedAt);
        return maxDate ? new Date(maxDate).toISOString() : undefined;
    };

    const fetchData =
        (...args: any[]): ThunkAction<Promise<any>, null, null, Action> =>
        (dispatch) => {
            fetching[autoRefreshId] = fetchCounter.increase();
            const isAutoRefresh =
                args.length > 0 && args[0] === AUTO_REFRESH_MARKER;
            let requestId: number;
            if (isAutoRefresh) {
                args = args.slice(1);
                requestId = getRequestId();
            } else {
                lastUserRequestId = getRequestId();
                requestId = lastUserRequestId;
            }
            fetchBegin(dispatch, callbacks.onFetchBegin);
            const differential = args[0]?.differential;
            const getArgs = () => {
                if (!differential) {
                    return args;
                }
                let result = { ...args[0] };
                if (differential && requestId > lastUserRequestId) {
                    result.after = lastMaxUtcPersitedDate ?? getTimeStamp();
                }
                return result;
            };
            const finalArgs = getArgs();
            return fetch(...finalArgs)
                .then((data: any) => {
                    fetching[autoRefreshId] = fetchCounter.decrease();
                    if (requestId < lastUserRequestId) {
                        return; // we do not care since it is not the most recent request
                    }
                    if (callbacks.onSuccess) {
                        const isDifferentialResponse =
                            finalArgs.differential && finalArgs.after;
                        const updatedData = isDifferentialResponse
                            ? {
                                  isDifferentialResponse,
                                  ...data,
                              }
                            : data;
                        callbacks.onSuccess(dispatch, updatedData);
                    }
                    dispatch(fetchSuccess());

                    if (data.entities) {
                        if (Object.keys(data.entities).length === 1) {
                            const key = Object.keys(data.entities)[0];
                            const meta = data.entities[key]._meta;
                            if (differential) {
                                lastMaxUtcPersitedDate =
                                    getMaxUtcPersistedDate(
                                        data.entities[autoRefreshId]?.entities
                                    ) ?? lastMaxUtcPersitedDate;
                            }
                            if (meta) {
                                if (meta.autoRefreshId && !isAutoRefresh) {
                                    fetchManager.store(
                                        meta.autoRefreshId,
                                        meta.autoRefreshInterval,
                                        fetchData,
                                        args
                                    );
                                    fetchManager.saveDispatch(dispatch);
                                }
                            }
                        }
                    }

                    return data;
                })
                .catch((error) => {
                    fetching[autoRefreshId] = fetchCounter.decrease();
                    if (requestId < lastUserRequestId) {
                        return; // we do not care since it is not the most recent request
                    }

                    if (error.status) {
                        dispatch(updateStatus(error.status));
                    }

                    dispatch(fetchFail(error));
                    if (callbacks.onFail) {
                        callbacks.onFail(dispatch, error);
                    }
                });
        };

    return {
        reducer,
        fetchData,
        reset,
        stopFetchingElement,
        stopFetchingCollection,
    };
};

export default makeFetchModule;
