import { Theme, makeStyles } from '@material-ui/core';
export interface StyleProps {
    defaultColor: boolean;
}
export const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
    contrastText: {
        color: ({ defaultColor }) =>
            defaultColor
                ? ''
                : theme.palette.getContrastText(theme.palette.primary.main),

        '&:disabled': {
            color: theme.palette.getContrastText(theme.palette.primary.main),
            opacity: 0.5,
        },
    },
}));
