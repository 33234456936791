import { makeReduxDuck } from 'teedux';
import { restGetModules } from '../../../services/iframes';
import { makeRequest } from '../sync';

export interface IModule {
    url?: string;
    urls?: Record<string, string>;
    icon: string;
    external?: boolean;
    names?: Record<string, string>;
    label?: string;
}

export interface IFramesConfig {
    webXClientUrl?: string;
    modules: Record<string, IModule>;
    users?: Record<string, string>;
    enabled?: string;
    disabled?: Record<string, string>;
}

type TOptionalModules = null | IFramesConfig;

interface IState {
    modules: TOptionalModules;
}

const initialState: IState = {
    modules: null,
};

const duck = makeReduxDuck('app/iframes', initialState);

export const setModules = duck.defineAction<{
    modules: TOptionalModules;
}>('SET_MODULES', (state, { modules }) => ({
    ...state,
    modules,
}));

export default duck.getReducer();

const modulesStorePath = `app/iframes/modules`;
const getModulesStorePath = `get:${modulesStorePath}`;

export const getModules = () =>
    makeRequest(
        getModulesStorePath,
        () => restGetModules(),
        (dispatch, data) => dispatch(setModules({ modules: data })),
        (dispatch, error) => undefined
    );
