import { createSelector } from 'reselect';

import { TRootState } from '../../../../store';

const getCalibrations = (state: TRootState) => state.app.calibrations;
const getCalibrationParamName = (state: TRootState) =>
    getCalibrations(state).selectedParam;

export const getCalibrationChartEvents = (state: TRootState) =>
    getCalibrations(state).events;
export const getCalibrationData = (state: TRootState) =>
    getCalibrations(state).calibrationData;
export const getParamsDefinitions = (state: TRootState) =>
    getCalibrations(state).paramsDefinitions;

export const getCalibrationParamId = createSelector(
    [getCalibrationParamName, getParamsDefinitions],
    (paramName, paramDefinitions) => {
        const param = paramDefinitions.find((def) => def.name === paramName);
        return param?.id;
    }
);
