import { useSelector } from 'react-redux';
import {
    getEventsStatus,
    getSelectedEventId,
    getEventsSourceSetModel,
    getEventDates,
    getOpenSectionEvents,
    getEventsAsSourceSet,
    getEventsSourceSetWithFilter,
} from '.';

export const useEvents = () => useSelector(getEventsAsSourceSet);
export const useEventsStatus = () => useSelector(getEventsStatus);
export const useEventId = () => useSelector(getSelectedEventId);
export const useEventsSourceSetModel = () =>
    useSelector(getEventsSourceSetModel);
export const useEventsByRfidSourceSetWithFilter = () =>
    useSelector(getEventsSourceSetWithFilter);
export const useEventDates = () => useSelector(getEventDates);
export const useOpenSectionEvents = () => useSelector(getOpenSectionEvents);
