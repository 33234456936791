import { makeReduxDuck } from 'teedux';

import Moment from 'moment-timezone';

import { TRootState } from '../../../store';

import { IObject } from '../../../helpers/IconGenerator/IconGenerator';

export interface ISelectedObject extends IObject {
    id: string;
    traits?: { binMonitor: boolean };
    updatedAt: Moment.Moment;
    data: {
        fillLevel: number;
        fuelLevel: number;
        capacity: number;
        logicType: number;
    };
}
interface IState {
    selectedObject: ISelectedObject | null;
}

export const initialState: IState = {
    selectedObject: null,
};

const duck = makeReduxDuck('ui/dashboard', initialState);

export const selectDashboardObject = duck.defineAction<{
    object: ISelectedObject;
}>('SELECT_DASHBOARD_OBJECT', (_, { object }) => ({
    selectedObject: object,
}));

export const getSelectedDashboardObject = (state: TRootState) =>
    state.ui.dashboard.selectedObject;

export default duck.getReducer();
