import Simplify from '../../algorithms/simplify';
import {
    REFUELING,
    SUPPLY,
    EVENT_NAME,
} from '../../constants/dictionaries/FuelTankStates.ts';
import TimeFormatter from '../../helpers/TimeFormatter';

import getParamValueByName from '../../selectors/getParamValueByName';

export const isBinMonitor = (data) =>
    data && data.traits && data.traits.binMonitor;

export const mergeData = (allData, filteredData = [], combineItem) => {
    let data = [];
    let i = 0,
        j = 0;
    for (; i < allData.length && j < filteredData.length; ) {
        let first = allData[i];
        let second = filteredData[j];
        if (first.date.getTime() <= second.date.getTime()) {
            if (first.date.getTime() === second.date.getTime()) {
                combineItem && combineItem(first, second);
                j++;
            }
            data.push(first);
            i++;
        } else {
            combineItem && combineItem(second, second);
            data.push(second);
            j++;
        }
    }

    if (i < allData.length) {
        data = data.concat(allData.slice(i));
    }
    if (j < filteredData.length) {
        data = data.concat(filteredData.slice(j));
    }
    return data;
};

export const prepareData = (data, defs) => {
    if (!data || data.length === 0) {
        return null;
    }
    const options = {
        [REFUELING]: ({ params }) => getParamValueByName(defs, 'FUEL', params),
        [SUPPLY]: ({ params }) => getParamValueByName(defs, 'REFUEL', params),
    };

    return (
        (data &&
            data.map((event) => {
                const date = TimeFormatter.dateToJSDate(event.date);
                const x = date.getTime() / 10000000;
                const level = isBinMonitor(event)
                    ? event.data.fillLevel
                    : event.data.fuelLevel;

                return {
                    date,
                    x,
                    level,
                    temperature: event.data.temperature,
                    eventType: event.state.type,
                    amount:
                        (options[event.state.type] &&
                            options[event.state.type](event)) ||
                        null,
                    vehicle: event.data.vehicle,
                    employee: event.data.employee,
                };
            })) ||
        []
    );
};

export const maxPointCount = 200;

export const simplify = (preparedData) => {
    const firstSeries = simplifyData(preparedData, 'level');
    const secondSeries = simplifyData(preparedData, 'temperature');
    return mergeData(firstSeries, secondSeries);
};

export const simplifyData = (data, yProp) => {
    return Simplify.simplifyN(data, maxPointCount, 'x', yProp);
};

export const getTooltipHeader = (eventType) => EVENT_NAME[eventType] || '-';

export const combineItem = (first, second) => {
    first.temperaturePoint = second.temperature;
    first.levelPoint = second.level;
};

export const calculateZoomedChart = (
    domain,
    unreducedChartData,
    reducedChartData
) => {
    if (reducedChartData === null || unreducedChartData === null) {
        return;
    }
    let startDate = domain[0].getTime();
    let stopDate = domain[1].getTime();
    let extentStartKey = -1;
    let extentStopKey = -1;
    let extentKeysCount;

    for (let i = 0; i < reducedChartData.length; i++) {
        let itemDate = reducedChartData[i].date.getTime();
        if (startDate <= itemDate) {
            extentStartKey = i;
            break;
        }
    }

    if (extentStartKey > -1) {
        for (let i = extentStartKey; i < reducedChartData.length; i++) {
            let itemDate = reducedChartData[i].date.getTime();
            if (itemDate >= stopDate) {
                extentStopKey = i;
                break;
            }
        }
    }

    extentKeysCount = extentStopKey - extentStartKey;

    if (extentKeysCount > -1 && extentStartKey > -1 && extentStopKey > -1) {
        let detailedArray = [];
        let beginArray;
        let endArray;
        beginArray = reducedChartData.slice(0, extentStartKey);

        for (let item of unreducedChartData) {
            let itemDate = item.date.getTime();
            if (itemDate >= startDate && itemDate <= stopDate) {
                detailedArray.push(item);
            }
        }
        if (detailedArray.length > maxPointCount) {
            detailedArray = simplify(detailedArray);
        }

        endArray = reducedChartData.slice(
            extentStopKey,
            reducedChartData.length - 1
        );

        reducedChartData = [];
        reducedChartData = reducedChartData.concat(
            beginArray,
            detailedArray,
            endArray
        );
    } else {
        reducedChartData = simplify(unreducedChartData.slice(0));
    }

    return reducedChartData;
};

export const prepareMinMaxData = (data, ascending) => {
    let minIndex = ascending ? 0 : data.length - 1;
    let maxIndex = ascending ? data.length - 1 : 0;

    return {
        time: {
            min: new Date(data[minIndex].date),
            max: new Date(data[maxIndex].date),
        },
        fuelLevel: {
            min: getMinValue(data, 'level'),
            max: getMaxValue(data, 'level'),
        },
        temperature: {
            min: getMinValue(data, 'temperature'),
            max: getMaxValue(data, 'temperature'),
        },
    };
};

export const getMinValue = (data, value) => {
    return Math.min.apply(
        Math,
        data.map((item) => {
            return item[value];
        })
    );
};

export const getMaxValue = (data, value) => {
    return Math.max.apply(
        Math,
        data.map((item) => {
            return item[value];
        })
    );
};

export const loadZoomedData = (chart, domain, reducedChartData, value) => {
    chart.load({
        json: reducedChartData,
        keys: {
            x: 'date',
            value: value,
        },
    });
    chart.zoom(domain);
};
