import React from 'react';
import EventAvailableOutlinedIcon from '@material-ui/icons/EventAvailableOutlined';
import EventRepeatOutlinedIcon from '@material-ui/icons/ScheduleOutlined';

import { useTab } from '../../state/ui/reportsSlice/index.hooks';
import { selectTab } from '../../state/ui/reportsSlice';

import { useToolkitDispatch } from '../../hooks';

import GeneratedReports from './components/GeneratedReports/GeneratedReports';
import TabsContainer from '../../components/TabsContainer';
import ReportingSchedules from './components/ReportingSchedules';

const ReportsPage = () => {
    const currentTab = useTab();
    const toolkitDispatch = useToolkitDispatch();

    const tabs = [
        {
            value: 'reports',
            label: 'Generated reports',
            icon: <EventAvailableOutlinedIcon />,
            mainComponent: <GeneratedReports />,
        },
        {
            privileges: ['reportSchedules'],
            value: 'reportSchedules',
            label: 'Reporting schedules',
            icon: <EventRepeatOutlinedIcon />,
            mainComponent: <ReportingSchedules />,
        },
    ];

    const handleChange = (event: React.ChangeEvent<{}>, value: string) => {
        toolkitDispatch(selectTab(value));
    };

    return (
        <TabsContainer
            tabs={tabs}
            currentTab={currentTab}
            handleChange={handleChange}
            title="Reports"
        />
    );
};

export default ReportsPage;
