import { useSelector } from 'react-redux';
import {
    getAttribute,
    getAttributesAsSourceSet,
    getAttributesSourceSetModel,
    getAttributesStatus,
    getSelectedAttributeId,
} from './index';

export const useAttributes = () => useSelector(getAttributesAsSourceSet);
export const useAttributesStatus = () => useSelector(getAttributesStatus);
export const useSelectedGridViewId = () => useSelector(getSelectedAttributeId);
export const useAttribute = () => useSelector(getAttribute);
export const useAttributesSourceSetModel = () =>
    useSelector(getAttributesSourceSetModel);
