import React, { Component } from 'react';

import { DefaultRoot } from './components';

class Root extends Component {
    public render() {
        return <DefaultRoot />;
    }
}

export default Root;
