import {
    createSlice,
    createSelector,
    createAsyncThunk,
} from '@reduxjs/toolkit';

import { TStatus } from '../../../state/types';

import { TRootState } from '../../../store';
import {
    restDeleteView,
    restGetGridView,
    restGetGridViews,
    restPostGridView,
    restPutGridView,
} from '../../../services/gridViews';

export interface IGridViewListItem {
    id: string;
    name: string;
    description?: string;
    accessType: 'GROUP' | 'PRIVATE' | 'PUBLIC';
}

export interface IServiceGridView {
    id: string;
    configuration: string;
    groupIds: string[];
    name: string;
    sourceSet?: string;
    description?: string;
}

export interface IPostServiceGridView extends Omit<IServiceGridView, 'id'> {
    sourceSet?: string;
}

interface IState {
    views: IGridViewListItem[] | null;
    selectedViewId: string | null;
    view: IServiceGridView | null;
    currentGridConfiguration: string;
    status: TStatus;
    selectedGridViewModified: boolean;
}

export const initialState: IState = {
    views: null,
    view: null,
    selectedViewId: null,
    currentGridConfiguration: '',
    status: 'idle',
    selectedGridViewModified: false,
};

export const fetchGridViews = createAsyncThunk(
    'get:ui/gridViews',
    async (queryParams: { sourceSet: string }) => {
        const response = await restGetGridViews(queryParams);

        return response;
    }
);

export const fetchGridView = createAsyncThunk(
    'get:ui/gridViews/id',
    async (id: string) => {
        const response = await restGetGridView(id);

        return response;
    }
);

export const createGridView = createAsyncThunk(
    'post:ui/gridViews',
    async (
        payload: { view: IPostServiceGridView; sourceSet: string },
        { rejectWithValue, dispatch }
    ) => {
        try {
            dispatch(setStatus('loading'));
            const response = await restPostGridView(payload.view);
            await dispatch(fetchGridViews({ sourceSet: payload.sourceSet }));
            dispatch(selectGridViewId(response.id));
            dispatch(selectView(payload.view));
            return response;
        } catch (e: any) {
            throw rejectWithValue({
                status: e.status,
                errorMsg: e.responseJSON.message,
            });
        }
    }
);

export const updateGridView = createAsyncThunk(
    'put:ui/gridViews',
    async (
        payload: { view: IServiceGridView; sourceSet: string },
        { rejectWithValue, dispatch }
    ) => {
        try {
            dispatch(setStatus('loading'));
            const response = await restPutGridView(payload.view);
            await dispatch(fetchGridViews({ sourceSet: payload.sourceSet }));
            dispatch(selectGridViewId(response.id));

            return response;
        } catch (e: any) {
            throw rejectWithValue({
                status: e.status,
                errorMsg: e.responseJSON.message,
            });
        }
    }
);

export const deleteView = createAsyncThunk(
    'delete:ui/gridViews/id',
    async (payload: { id: string; sourceSet: string }, { dispatch }) => {
        const response = await restDeleteView(payload.id);
        await dispatch(fetchGridViews({ sourceSet: payload.sourceSet }));
        return response;
    }
);

const gridViews = createSlice({
    name: 'gridViews',
    initialState,
    reducers: {
        saveCurrentGridConfiguration(state, action) {
            state.currentGridConfiguration = action.payload;
        },
        selectGridViewId(state, action) {
            state.selectedViewId = action.payload;
        },
        selectView(state, action) {
            state.view = action.payload;
        },
        setStatus(state, action) {
            state.status = action.payload;
        },
        resetViewState(state) {
            state.selectedViewId = null;
            state.selectedGridViewModified = false;
        },
        setSelectedGridViewModified(state, action) {
            state.selectedGridViewModified = action.payload;
        },
        loadViewFromStorage(state, action) {
            state.selectedViewId = action.payload;
            state.selectedGridViewModified = true;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchGridViews.pending, (state) => {
            state.views = [];
            state.status = 'loading';
        });
        builder.addCase(fetchGridViews.rejected, (state) => {
            state.status = 'error';
        });
        builder.addCase(fetchGridViews.fulfilled, (state, action) => {
            state.status = 'done';
            state.selectedGridViewModified = false;

            state.views = action.payload;
        });
        builder.addCase(fetchGridView.pending, (state) => {
            state.status = 'loading';
        });
        builder.addCase(fetchGridView.fulfilled, (state, action) => {
            state.view = action.payload;
            state.status = 'done';
        });
        builder.addCase(fetchGridView.rejected, (state) => {
            state.status = 'error';
        });
    },
});

const getCurrent = (state: TRootState) => state.ui.gridViews as IState;

export const getGridView = (state: TRootState) => getCurrent(state).view;
export const getGridViews = (state: TRootState) => getCurrent(state).views;
export const getSelectedGridViewId = (state: TRootState) =>
    getCurrent(state).selectedViewId;
export const getCurrentGridConfiguration = (state: TRootState) =>
    getCurrent(state).currentGridConfiguration;
export const getGridViewStatus = (state: TRootState) =>
    getCurrent(state).status;

export const getSelectedGridViewModified = (state: TRootState) =>
    getCurrent(state).selectedGridViewModified;
export const getSelectedViewName = createSelector(
    [getGridViews, getSelectedGridViewId],
    (views, viewId) => {
        return views?.filter((view) => view.id === viewId)?.[0]?.name;
    }
);

export const {
    selectGridViewId,
    saveCurrentGridConfiguration,
    setSelectedGridViewModified,
    resetViewState,
    loadViewFromStorage,
    setStatus,
    selectView,
} = gridViews.actions;

export default gridViews.reducer;
