import { IRightItem } from '../../../../../../../state/ui/usersSlice';
import { patchRegistersGroupAssignments } from '../../../../../../../state/ui/groupsSlice';

const isMonitoredObject = (
    item: IRightItem
): item is IRightItem & { registerType: string } => {
    return !!item.registerType;
};

export const prepareGroupAssignmentsData = (items: IRightItem[]) => {
    const validTypes = ['VEHICLE', 'EMPLOYEE', 'TANK'];
    return items
        .filter(
            (
                item
            ): item is IRightItem & {
                registerType: string;
                registerId: number;
            } =>
                isMonitoredObject(item) &&
                validTypes.includes(item.registerType)
        )
        .map(({ type, registerId, relationId, registerType }) => ({
            type,
            registerId,
            relationId,
            registerType,
        }));
};

export const updateGroupAssignments = async (
    id: string,
    added: IRightItem[],
    removed: IRightItem[]
) => {
    const addedGroupAssignments = prepareGroupAssignmentsData(added);
    const removedGroupAssignments = prepareGroupAssignmentsData(removed);
    await patchRegistersGroupAssignments(
        id,
        addedGroupAssignments,
        removedGroupAssignments
    );
};
