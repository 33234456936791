import { FEATURE_SELECTED } from './handleSelect';

const ANGLE = Math.PI / 4;

export const STYLE = {
    'shape-points': 4,
    'shape-radius': 12,
    'shape-stroke-color': [
        'match',
        ['get', 'selected'],
        FEATURE_SELECTED,
        'red', // Border color when selected
        'transparent', // Border color when not selected
    ],
    'shape-stroke-width': 3,

    'shape-fill-color': [
        'color',
        ['get', 'red'],
        ['get', 'green'],
        ['get', 'blue'],
    ],
    'shape-angle': ANGLE,
};
