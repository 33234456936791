export const CSC_CLIENTS_STATUS = {
    1: 'active',
    2: 'blocked',
    3: 'archival',
};

export const STATUS_ARCHIVAL = 3;

export const CLIENTS_STATUS_ARRAY = [
    {
        id: 1,
        name: 'active',
    },
    {
        id: 2,
        name: 'blocked',
    },
    {
        id: 3,
        name: 'archival',
    },
];
