import { connect } from 'react-redux';
import { TRootState } from '../../../../../../store';

import { getUserLogin } from '../../../../../../state/login';
import { getUserEmail } from '../../../../../../state/user';

const mapStateToProps = (state: TRootState) => ({
    login: getUserLogin(state),
    email: getUserEmail(state),
});

const mapDispatchToProps = {};

type TStateProps = ReturnType<typeof mapStateToProps>;
type TDispatchProps = typeof mapDispatchToProps;

export type TConnectableProps = TStateProps & TDispatchProps;

export const Connectable = connect(mapStateToProps, mapDispatchToProps);
