import React, { useEffect, useState } from 'react';

import { default as EyeIcon } from '@material-ui/icons/Visibility';
import { Checkbox, ListItemIcon, Paper, Typography } from '@material-ui/core';

import PaneHeader from '../../../../../../components/PaneHeader';
import { useAppDispatch } from '../../../../../../hooks';
import { useCreatorLevel } from '../../../../selectors/index.hooks';
import { resetLevel } from '../../../../../../state/_actions';
import { useStyles } from './Themable.hooks';
import DataSegmentsList from '../../../BookmarksList/DataSegmentsList';
import IconButtonWithTooltip from '../../../../../../components/IconButtonWithTooltip';
import TranslationHelper from '../../../../../../helpers/TranslationHelper';
import {
    useBookmarkList,
    useCheckedBookmarkList,
} from '../../../../../../state/app/bookmarks/index.hooks';
import { intersection } from '../../../../../../helpers/filterObject';
import { activateReportSetDataSegments } from '../../../../../../state/ui/discovery/snapshotting';
import { setCheckedBookmarks } from '../../../../../../state/app/bookmarks';
import { IBookmark, IBookmarkDataSegment } from '../../../../../../state/types';
import { getOrCreatePool } from '../../../../../../helpers/createPool';

const MAX_SEGMENTS = 5;

const DataSegments = () => {
    const dispatch = useAppDispatch();
    const creatorLevel = useCreatorLevel();
    const classes = useStyles();
    const checkedSegments = useCheckedBookmarkList();
    const bookmarks = useBookmarkList();
    const [checked, setChecked] = useState(checkedSegments);
    const colorPool = getOrCreatePool('data_segments');
    const handleCloseClick = () => {
        dispatch(resetLevel(creatorLevel));
    };

    useEffect(() => {
        return () => {
            const filtered = intersection(bookmarks, checked, 'id');
            dispatch(setCheckedBookmarks(filtered.map((item) => item.id)));
        };
    }, [checked]);

    const handleItemClick = (id: string) => {
        if (checked.includes(id)) {
            setChecked(checked.filter((itemIndex) => itemIndex !== id));
        } else {
            setChecked([...checked, id]);
        }
    };

    const listLeftControls = (bookmark: IBookmark) => {
        return (
            <ListItemIcon>
                <Checkbox
                    checked={checked.includes(bookmark.id)}
                    tabIndex={-1}
                    disableRipple
                    onClick={() => handleItemClick(bookmark.id)}
                    disabled={
                        !checked.includes(bookmark.id) &&
                        checked.length >= MAX_SEGMENTS
                    }
                />
            </ListItemIcon>
        );
    };
    const handleShowTrails = () => {
        const filtered: IBookmarkDataSegment[] = intersection(
            bookmarks,
            checked,
            'id'
        ).map((item) => ({
            ...item,
            color: colorPool.assignColor(item.id).color,
        }));
        dispatch(
            activateReportSetDataSegments(filtered, {
                type: 'report-set',
                level: creatorLevel + 1,
            })
        );
    };

    const deleteBookmarkCallback = (id: string) => {
        if (checked.includes(id)) {
            setChecked(checked.filter((itemIndex) => itemIndex !== id));
        }
    };
    const paneRightControls = () => {
        return (
            <IconButtonWithTooltip
                title={TranslationHelper.translate('Show')}
                onClick={handleShowTrails}
                disabled={!checked.length}
            >
                <EyeIcon />
            </IconButtonWithTooltip>
        );
    };

    return (
        <Paper className={classes.wrapper}>
            <PaneHeader
                title={TranslationHelper.translate('Data segments')}
                onCloseClick={handleCloseClick}
                renderRightCustomControls={paneRightControls}
            />
            <Typography color={'primary'} className={classes.typography}>
                {TranslationHelper.translate('Choose up to 5 segments')}
            </Typography>
            <DataSegmentsList
                renderLeftCustomControls={listLeftControls}
                segments={bookmarks}
                deleteCb={deleteBookmarkCallback}
            />
        </Paper>
    );
};
export default DataSegments;
