import React, { Component } from 'react';
import Select from 'react-select';
import Translator from '../../helpers/TranslationHelper';

const setAvailableOptions = (data) => () => ({
    availableOptions: data.map((item) => ({
        label: item.name,
        value: item,
    })),
});

const setOption = (
    { availableOptions, option },
    { clearable = true, selectedOptionId, optionKey = 'id' }
) => {
    let selectedOption =
        option ||
        (
            (availableOptions &&
                availableOptions.find(
                    (item) => item.value[optionKey] === selectedOptionId
                )) ||
            {}
        ).value;

    if (!clearable && availableOptions && availableOptions.length === 1) {
        selectedOption = availableOptions[0].value;
    }

    return {
        option: selectedOption,
    };
};

class AsyncFilterSelect extends Component {
    state = {
        option: undefined,
        availableOptions: undefined,
    };

    setupOptions = (data) => {
        if (this._isMounted) {
            this.setState(setAvailableOptions(data));
            this.setState(setOption);
        }
    };

    handleChange = (data) => {
        this.setState(
            {
                option: data ? data.value : undefined,
            },
            () => {
                this.props.onOptionSelect(this.state.option);
            }
        );
    };

    componentDidMount() {
        this._isMounted = true;
        this.props.getData().then(this.setupOptions);
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        const {
            name,
            placeholder,
            value = undefined,
            clearable = true,
            required,
        } = this.props;
        const { option, availableOptions } = this.state;

        const disabled =
            availableOptions === undefined ||
            (clearable === false ? availableOptions.length === 1 : false);

        return (
            <Select
                className="header-filter--select"
                name={name}
                clearable={clearable}
                required={required}
                disabled={disabled}
                isLoading={availableOptions === undefined}
                defaultValue={value}
                value={option}
                valueRenderer={(val) => val.name}
                placeholder={placeholder}
                options={availableOptions}
                onChange={this.handleChange}
                noResultsText={Translator.translate('No results found')}
                clearAllText={Translator.translate('Clear all')}
                clearValueText={Translator.translate('Clear')}
            />
        );
    }
}

export default AsyncFilterSelect;
