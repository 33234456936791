import { useSelector } from 'react-redux';
import {
    getBottomGrid,
    getGridMode,
    bottomGridVisible,
    isBottomGridInEditMode,
} from '.';

export const useMode = () => useSelector(getGridMode);
export const useBottomGridPaneVisible = () => useSelector(bottomGridVisible);
export const useBottomGrid = () => useSelector(getBottomGrid);
export const useIsBottomGridInEditMode = () =>
    useSelector(isBottomGridInEditMode);
