import { createSlice } from '@reduxjs/toolkit';
import { TRootState } from 'src/app/store';

interface IState {
    code: number | null;
}

const initialState: IState = {
    code: null,
};

const statusSlice = createSlice({
    name: 'statusSlice',
    initialState,
    reducers: {
        updateStatus(state, action) {
            state.code = action.payload;
        },
    },
});

export const getNetworkStatus = (state: TRootState) => state.app.status.code;

export const { updateStatus } = statusSlice.actions;

export default statusSlice.reducer;
