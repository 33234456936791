import React, { ReactChild, useEffect, useState } from 'react';
import TranslationHelper from '../../../../helpers/TranslationHelper';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import { useStyles } from '../../Themable.hooks';
import {
    IParamAlertCondition,
    IEventAlertCondition,
    ILayerAlertCondition,
} from '../../../../state/ui/forms';
import { alignmentDictionary } from '../../_utils/utils';

import { IDialogProp } from '../../AlertSettingDialog';

interface IOwnProps {
    setFieldValue: (
        name: string,
        value:
            | IParamAlertCondition
            | IEventAlertCondition
            | ILayerAlertCondition
    ) => void;
    dialog: IDialogProp;
    name: string;
    type: number;
    children: ReactChild;
}

const GroupAlertCondition = ({
    type,
    name,
    setFieldValue,
    dialog,
    children,
}: IOwnProps) => {
    const classes = useStyles();
    const [alignment, setAlignment] = useState<string>('left');

    useEffect(() => {
        setAlignment(alignmentDictionary[type]);
    }, []);

    const handleToggle = (
        event: React.MouseEvent<HTMLElement>,
        newAlignment: string
    ) => {
        if (newAlignment !== null) {
            setAlignment(newAlignment);
            setFieldValue(`${name}.type`, alignmentDictionary[newAlignment]);
        }
    };

    const { mode } = dialog;
    return (
        <Card className={classes.card}>
            <CardContent className={classes.card}>
                <ToggleButtonGroup
                    className={classes.toggleButtons}
                    size="small"
                    value={alignment}
                    exclusive
                    onChange={handleToggle}
                >
                    <ToggleButton
                        classes={{ selected: classes.selected }}
                        disabled={mode === 'edit' ? true : false}
                        value="left"
                    >
                        {TranslationHelper.translate('And')}
                    </ToggleButton>
                    <ToggleButton
                        classes={{ selected: classes.selected }}
                        disabled={mode === 'edit' ? true : false}
                        value="center"
                    >
                        {TranslationHelper.translate('Or')}
                    </ToggleButton>
                </ToggleButtonGroup>
                {children}
            </CardContent>
        </Card>
    );
};
export default GroupAlertCondition;
