import React, { Component } from 'react';

import Translator from '../../../helpers/TranslationHelper';
import RFIDCardRow from './RFIDCardRow';
import { IRFIDCard } from './types';

interface IProps {
    rfidCards?: IRFIDCard[];
}

const COLSPAN = 7;

class RFIDCardsTable extends Component<IProps> {
    public renderTableBody() {
        const rfidCards = this.props.rfidCards;
        let items = null;
        if (rfidCards) {
            if (rfidCards.length > 0) {
                items = rfidCards.map((rfidCard, index) => {
                    return <RFIDCardRow rfidCard={rfidCard} key={index} />;
                });
            } else {
                items = (
                    <tr>
                        <td colSpan={COLSPAN} className="table--empty-state">
                            {Translator.translate(
                                'There are no RFID cards to display'
                            )}
                        </td>
                    </tr>
                );
            }
        } else {
            items = (
                <tr>
                    <td colSpan={COLSPAN} className="table--empty-state">
                        {Translator.translate('Loading...')}
                    </td>
                </tr>
            );
        }
        return items;
    }

    public render() {
        return (
            <table className="table table__hoverable">
                <thead>
                    <tr>
                        <th>{Translator.translate('Card number')}</th>
                        <th>{Translator.translate('Card code')}</th>
                        <th>{Translator.translate('Group')}</th>
                        <th>{Translator.translate('Type')}</th>
                        <th>{Translator.translate('Linked')}</th>
                        <th>{Translator.translate('Description')}</th>
                        <th>{Translator.translate('Actions')}</th>
                    </tr>
                </thead>

                <tbody>{this.renderTableBody()}</tbody>
            </table>
        );
    }
}

export default RFIDCardsTable;
