import React, { Component, FormEvent } from 'react';

import Translator from '../../../../helpers/TranslationHelper';
import PrimaryButton from '../../../button/PrimaryButton';
import SecondaryButton from '../../../button/SecondaryButton';
import ValidationMessage from '../../../common/ValidationMessage';
import Dialog from '../../../dialog/Dialog';

import { Connectable, TConnectableProps } from './Connectable.hoc';

type TProps = TConnectableProps;

interface IState {
    userEmail?: string;
    submitButtonDisabled: boolean;
    isUniqueEmailError: boolean;
    isError: boolean;
}

class UserProfileDialog extends Component<TProps, IState> {
    private email: React.RefObject<HTMLInputElement>;
    constructor(props: any) {
        super(props);
        this.onCancel = this.onCancel.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.email = React.createRef();
        this.state = this.getInitialState();
    }

    public getInitialState() {
        return {
            userEmail: this.props.userSettings.userEmail,
            submitButtonDisabled: false,
            isUniqueEmailError: false,
            isError: false,
        };
    }

    public onSubmit(e: FormEvent<HTMLFormElement>) {
        e.preventDefault();
        const email = this.email.current && this.email.current.value;
        if (this.validateForm()) {
            if (!!email) {
                this.setState({
                    submitButtonDisabled: true,
                    isError: false,
                    isUniqueEmailError: false,
                });
                this.props.updateUserEmail(
                    email,
                    () => this.props.hideGlobalDialog(),
                    (error) => {
                        if (error.status === 409) {
                            this.setState({
                                submitButtonDisabled: false,
                                isUniqueEmailError: true,
                            });
                        } else {
                            this.setState({
                                submitButtonDisabled: false,
                                isError: true,
                            });
                        }
                    }
                );
            }
        }
    }

    public validateForm() {
        return true;
    }

    public onCancel(e: Event) {
        e.preventDefault();
        this.props.hideGlobalDialog();
    }

    public render() {
        return (
            <Dialog
                title={Translator.translate('User profile')}
                onClose={this.onCancel}
                classNames="dialog acouunt-dialog"
            >
                <form
                    className="form account-form"
                    onSubmit={this.onSubmit}
                    autoComplete="off"
                >
                    <div className="title">
                        {Translator.translate('Contact data')}
                    </div>
                    <div className="section">
                        <div className="form-group single">
                            <div className="form-title">
                                {Translator.translate('Email')}
                            </div>
                            <div className="form-input">
                                <input
                                    type="email"
                                    className="mtl-input"
                                    ref={this.email}
                                    placeholder={
                                        Translator.translate(
                                            'E.g. john.smith@example.com'
                                        ) + ' '
                                    }
                                    defaultValue={this.state.userEmail}
                                    maxLength={255}
                                    required={true}
                                />
                            </div>
                        </div>
                        <div className="form-group single">
                            <ValidationMessage
                                message={Translator.translate(
                                    'Internal server error. Please contact with administrator.'
                                )}
                                error={this.state.isError}
                            />
                            <ValidationMessage
                                message={Translator.translate(
                                    'Provided email has been already used'
                                )}
                                error={this.state.isUniqueEmailError}
                            />
                        </div>
                    </div>
                    <div className="form-actions">
                        <PrimaryButton
                            icon="icon-floppy-disk"
                            label={Translator.translate('Save')}
                            disabled={this.state.submitButtonDisabled}
                        />
                        <SecondaryButton
                            onClick={this.onCancel}
                            icon="icon-cross"
                            label={Translator.translate('Cancel')}
                        />
                    </div>
                </form>
            </Dialog>
        );
    }
}

export default Connectable(UserProfileDialog);
