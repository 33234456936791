import { INITIAL_TYPE } from '../../AssignRightsDialog';
import { TSelectedRight } from '../../deduceSelectionType';

import { cloneDeep } from 'lodash';

import { IRightItem } from '../../../../../../../../state/ui/usersSlice';

import { restGetGroups } from '../../../../../../../../services/groups';
import { restGetRegistersMonitoredObjects } from '../../../../../../../../services/registers';
import { IGroupItem } from '../../../../../../../../state/app/registers/groups';
import { IRegistersMonitoredObject } from '../../../../../../../../state/app/registers/monitoredObjects';

import TranslationHelper from '../../../../../../../../helpers/TranslationHelper';
import { monitoredObjectTypeConverter } from '../../../../../../../../helpers/monitoredObjectTypeConverter';
import { sortAlphabetically } from '../../../../../../../../helpers/comparators';

import {
    IFilterInitialState,
    IListObjectInitialState,
    ITransferListObject,
} from '../../../../../../../../components/TransferList/TransferList';
import { IRight } from 'src/app/services/auth';

const prepareAssignedGroups = (arr: IGroupItem[]) => {
    const assignedGroups = [...arr].map((group) => {
        const rightObject = {} as IRightItem;
        rightObject.name = group.name;
        rightObject.relationId = group.id;
        rightObject.type = 'GROUP';
        return rightObject;
    });
    return assignedGroups;
};
const prepareAssignedObjects = (arr: IRegistersMonitoredObject[]) => {
    const assignedObjects = [...arr].map((object) => {
        const rightObject = {} as IRightItem;
        rightObject.name = object.name;
        rightObject.relationId = String(object.id);
        rightObject.registerType = monitoredObjectTypeConverter(object.type);
        rightObject.type = 'MONITORED_OBJECT';
        rightObject.registerId = object.objectId;
        return rightObject;
    });

    return assignedObjects;
};

const prepareSpecialRights = (arr: IRightItem[]) => {
    const specialRights = cloneDeep(arr).filter(
        (right) => right.type === 'SPECIAL'
    );
    specialRights.forEach(
        (right) =>
            (right.name = TranslationHelper.translate(String(right.relationId)))
    );
    return specialRights;
};

export const preparePersonalAssignments = async (
    setLoading: (state: boolean) => void,
    setObjectLists: React.Dispatch<
        Partial<IListObjectInitialState<ITransferListObject>>
    >,
    setSelectedRight: (right: TSelectedRight) => void,
    rights: IRight[],
    filter: IFilterInitialState,
    userId: number
) => {
    setLoading(true);
    Promise.all([
        restGetGroups({
            individualForUserId: userId,
        }),
        restGetRegistersMonitoredObjects({
            individualForUserId: userId,
        }),
    ]).then((values) => {
        const assignments: ITransferListObject[] = [];

        const assignedGroups = prepareAssignedGroups(values[0] as IGroupItem[]);
        const assignedObjects = prepareAssignedObjects(values[1]);
        const specialRights = prepareSpecialRights(rights as IRightItem[]);

        assignments.push(
            ...assignedObjects,
            ...assignedGroups,
            ...specialRights
        );

        const sortedAssignments = assignments.sort((a, b) =>
            sortAlphabetically(a.name, b.name)
        );

        setObjectLists({
            originalAssignedObjects: sortedAssignments,
            filteredAssignedObjects:
                filter.typeAssigned === INITIAL_TYPE.all
                    ? sortedAssignments
                    : sortedAssignments.filter(
                          (assignment) => assignment.type === INITIAL_TYPE.group
                      ),
            assignedObjects: sortedAssignments,
        });
        if (!assignments.length) {
            setLoading(false);
            setSelectedRight('NONE');
            return;
        }
        setLoading(false);
        setSelectedRight('SELECTED');
    });
};
