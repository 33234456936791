import { IGroupAssignment } from '../../../../../../../../../state/ui/groupsSlice';

export const prepareGroupAssignmentsData = (
    assignments: IGroupAssignment[]
) => {
    const withAddress: IGroupAssignment[] = [];
    const withoutAddress: IGroupAssignment[] = [];

    assignments.forEach((assignment) => {
        const { address, ...rest } = assignment;
        if (address) {
            withAddress.push({ ...rest });
        } else {
            withoutAddress.push({ ...rest });
        }
    });

    return { withAddress, withoutAddress };
};
