import { ChartOptions } from 'chart.js';
import React, { Component } from 'react';

import { Doughnut } from 'react-chartjs-2';
import ColorHelper from '../../../../../helpers/ColorHelper';

import { IBinaryValue } from '../../../../../state/types';
interface IOwnProps {
    value: string | number | IBinaryValue | boolean;
}

class DoughnutChart extends Component<IOwnProps> {
    public getData = (filled: number, empty: number, color: string) => {
        return {
            labels: [],
            datasets: [
                {
                    data: [filled, empty],
                    backgroundColor: [color, '#F2F2F2'],
                    hoverBackgroundColor: [color, '#F2F2F2'],
                    borderWidth: 0,
                },
            ],
        };
    };

    public getOptions = (percent: number, color: string) => {
        return {
            cutoutPercentage: 75,
            rotation: 0.5 * Math.PI,
            legend: {
                display: false,
            },
            tooltips: {
                enabled: false,
            },
            animation: {
                duration: 0,
            },
            hover: {
                mode: 'dataset',
            },
            maintainAspectRatio: false,
            elements: {
                center: {
                    maxText: '100%',
                    text: percent + '%',
                    fontColor: color,
                    fontFamily: "'OpenSans-Light'",
                    fontStyle: 'normal',
                    minFontSize: 1,
                    maxFontSize: 256,
                },
            },
        };
    };

    public getPlugins = () => {
        return [
            {
                afterDraw(chart: any) {
                    const ctx = chart.ctx;
                    const width = chart.width;
                    const height = chart.height;

                    const fontSize = Number((height / 4).toFixed(2));
                    ctx.font = fontSize + 'px OpenSans-Regular';
                    ctx.fillStyle =
                        chart.config.options.elements.center.fontColor;
                    ctx.textBaseline = 'middle';

                    const text = chart.config.options.elements.center.text;

                    const textX = Math.round(
                        (width - ctx.measureText(text).width) / 2
                    );
                    const textY = height / 2 + fontSize / 12;

                    ctx.fillText(text, textX, textY);
                },
            },
        ];
    };

    public render() {
        const percentValue = Number(this.props.value);
        const filledArea = percentValue < 100 ? percentValue : 100;
        const emptyArea = 100 - Number(filledArea);
        const chartColor = ColorHelper.getColorByPercentage(filledArea, false);

        return (
            <div>
                <Doughnut
                    data={this.getData(filledArea, emptyArea, chartColor)}
                    options={
                        this.getOptions(
                            percentValue,
                            chartColor
                        ) as ChartOptions
                    }
                    plugins={this.getPlugins()}
                    width={60}
                    height={60}
                />
            </div>
        );
    }
}

export default DoughnutChart;
