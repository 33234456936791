import { createStyles, Theme, WithStyles } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme: Theme) => {
    const padding = theme.spacing();
    return createStyles({
        container: {
            display: 'flex',
            flexDirection: 'column',
            height: 250,
            marginTop: padding,
        },
        grid: {
            width: '100%',
            flex: '1 1 auto',
        },
        actions: {
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: padding,
        },
        error: {
            boxShadow: `0px 0px 2px 2px ${theme.palette.error.main}`,
        },
    });
};

export type TThemableProps = WithStyles<typeof styles>;

export const Themable = withStyles(styles, { withTheme: true });
