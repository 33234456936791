import React, { useEffect } from 'react';

import { useDispatch } from 'react-redux';

import UsersIcon from '@material-ui/icons/Person';

import { selectTab } from '../../state/ui/settings';
import { useTab } from './Connectable.hooks';

import UserSettings from './components/UserSettings';
import TabsContainer from '../../components/TabsContainer';

const SettingsPage = () => {
    const currentTab = useTab();
    const dispatch = useDispatch();

    useEffect(() => {
        selectInitialTab();
    }, []);

    const handleChange = (event: React.ChangeEvent<{}>, value: string) => {
        dispatch(selectTab({ tab: value }));
    };

    const selectInitialTab = () => {
        dispatch(selectTab({ tab: 'user' }));
    };

    const tabs = [
        {
            value: 'user',
            label: 'Account settings',
            icon: <UsersIcon />,
            mainComponent: <UserSettings />,
        },
    ];
    return (
        <>
            <TabsContainer
                tabs={tabs}
                currentTab={currentTab}
                handleChange={handleChange}
                title="Settings"
            />
        </>
    );
};

export default SettingsPage;
