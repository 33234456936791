import { connect } from 'react-redux';

import { TRootState } from '../../../../../../../../store';
import { recalcuateData } from '../../../../../../../../state/app/monitoredObjects';

const mapStateToProps = (state: TRootState) => ({
    dateFormat: state.user.shortDateFormat,
});

const mapDispatchToProps = {
    recalcuateData,
};

type TStateProps = ReturnType<typeof mapStateToProps>;
type TDispatchProps = typeof mapDispatchToProps;

export type TConnectableProps = TStateProps & TDispatchProps;

export const Connectable = connect(mapStateToProps, mapDispatchToProps);
