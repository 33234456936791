import { Dispatch } from 'redux';
import { fetchSearchResults } from '../../../../services/discovery/fetchSearchResults';
import { makeFetchModule } from '../../../_utils';
import { storeMonitoredObjects } from '../../entities';
import { storeFoundMonitoredObjectIds } from '../../searcher';

const callbacks = {
    onSuccess: (
        dispatch: Dispatch,
        { result, entities }: { result: string[]; entities: object }
    ) => {
        dispatch(storeMonitoredObjects(entities));
        dispatch(storeFoundMonitoredObjectIds(result));
    },
};
const { reducer, fetchData, reset } = makeFetchModule(
    'app/discovery/searchResultsFetching',
    fetchSearchResults,
    callbacks
);

export default reducer;
export { fetchData, reset };
