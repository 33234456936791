import { Box, Typography } from '@mui/material';
import React from 'react';

import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import BedtimeIcon from '@mui/icons-material/Bedtime';
import WorkIcon from '@mui/icons-material/Work';
import AlarmOnIcon from '@mui/icons-material/AlarmOn';
import { SvgIconComponent } from '@mui/icons-material';

import { getActivityColor } from '../utils/helpers';

import { ITotals } from '../TachograppChart';
import TranslationHelper from '../../../../../helpers/TranslationHelper';
import TimeFormatter from '../../../../../helpers/TimeFormatter';

interface IOwnProps {
    totals: ITotals;
    driver: string;
}

interface IActivitySummaryProps {
    activity: string;
    total: any;
    color: string;
    icon: SvgIconComponent;
}

const ActivitySummary = ({
    activity,
    total,
    color,
    icon: Icon,
}: IActivitySummaryProps) => (
    <Box display="flex" alignItems="center" gap={2} p={2}>
        <Icon sx={{ color }} />
        <Typography variant="body1" fontWeight="medium" sx={{ color }}>
            {TranslationHelper.translate(activity)}:
        </Typography>
        <Typography variant="body1" fontWeight="medium">
            {TimeFormatter.formatDuration(total, 'minutes', 'hh:mm')}
        </Typography>
    </Box>
);

export const ActivitiesList = ({ totals, driver }: IOwnProps) => {
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Typography>{driver}</Typography>
            <ActivitySummary
                activity="driving"
                total={totals.driving}
                color={getActivityColor('driving')}
                icon={LocalShippingIcon}
            />
            <ActivitySummary
                activity="resting"
                total={totals.resting}
                color={getActivityColor('resting')}
                icon={BedtimeIcon}
            />
            <ActivitySummary
                activity="working"
                total={totals.working}
                color={getActivityColor('working')}
                icon={WorkIcon}
            />
            <ActivitySummary
                activity="available"
                total={totals.available}
                color={getActivityColor('available')}
                icon={AlarmOnIcon}
            />
            <ActivitySummary
                activity="unavailable"
                total={totals.unavailable + totals.error}
                color={getActivityColor('unavailable')}
                icon={AlarmOnIcon}
            />
        </Box>
    );
};
