import React, { useEffect, useReducer, useState } from 'react';

import { useSnackbar } from 'notistack';

import { Dialog, DialogContent } from '@material-ui/core';

import { useStyles } from './Themable.hooks';

import { usePersonalGroupId } from '../../../../../../state/ui/usersSlice/index.hooks';
import {
    clearRights,
    fetchUserDataRights,
    IRightItem,
    patchRightsAssignments,
} from '../../../../../../state/ui/usersSlice';
import { IRight } from '../../../../../../services/auth';

import TranslationHelper from '../../../../../../helpers/TranslationHelper';
import { errorMessageHandler } from '../../../../../../helpers/errorMessageHandler';

import { useToolkitDispatch } from '../../../../../../hooks';
import useAsyncThunkWithSnackbar from '../../../../../../hooks/useAsyncThunkWithSnackbar';

import { filterObjects } from './utils/filterObjects';

import DialogTitle from '../../../../../../components/dialogs/DialogTitle';
import LoadingSpinner from '../../../../../../components/loadingSpinner/LoadingSpinner';
import SectionWithTitle from '../../../../../../components/SectionWithTitle';
import RadioButtons from '../../../../../../components/RadioButtons';
import {
    IFilterInitialState,
    IListObjectInitialState,
    ITransferListObject,
} from '../../../../../../components/TransferList/TransferList';

import RightsTransferList from './components/RightsTransferList/RightsTransferList';
import { updateGroupAssignments } from './utils/updateGroupAssignments';
import { preparePersonalAssignments } from './components/RightsTransferList/preparePersonalAssignments';
import { getSelectedAssignmentsPromises } from './getSelectedAssignmentsPromises';
import { deduceSelectedRight, TSelectedRight } from './deduceSelectionType';

export enum SelectedRightOptions {
    ALL = 'ALL',
    SELECTED = 'SELECTED',
    NONE = 'NONE',
}

export const INITIAL_TYPE = { group: 'GROUP', all: 'ALL' };

interface IOwnProps {
    isOpen: boolean;
    closeDialog: () => void;
    userId: number;
}

const AssignRightsDialog = ({ isOpen, closeDialog, userId }: IOwnProps) => {
    const classes = useStyles();

    const { enqueueSnackbar } = useSnackbar();

    const toolkitDispatch = useToolkitDispatch();

    const [selectedRight, setSelectedRight] = useState<TSelectedRight>('');

    const [added, setAdded] = useState<IRightItem[]>([]);
    const [removed, setRemoved] = useState<IRightItem[]>([]);

    const [loading, setLoading] = useState(true);
    const handleAsyncRequest = useAsyncThunkWithSnackbar();

    const personalGroupId = usePersonalGroupId();

    const showNotification = (success: boolean, message: string) => {
        enqueueSnackbar(message, {
            variant: success ? 'success' : 'error',
        });
    };

    const filterInitialState: IFilterInitialState = {
        typeUnassigned: INITIAL_TYPE.group,
        nameUnassigned: '',
        typeAssigned: INITIAL_TYPE.all,
        nameAssigned: '',
    };

    const listObjectsInitialState: IListObjectInitialState<ITransferListObject> =
        {
            unassignedObjects: [],
            assignedObjects: [],
            filteredAssignedObjects: [],
            originalAssignedObjects: [],
        };

    const [filter, setFilter] = useReducer(
        (
            curVals: IFilterInitialState,
            newVals: Partial<IFilterInitialState>
        ) => ({ ...curVals, ...newVals }),
        filterInitialState
    );

    const [objectLists, setObjectLists] = useReducer(
        (
            curVals: IListObjectInitialState<ITransferListObject>,
            newVals: Partial<IListObjectInitialState<ITransferListObject>>
        ) => ({
            ...curVals,
            ...newVals,
        }),
        listObjectsInitialState
    );

    useEffect(() => {
        setLoading(true);

        handleAsyncRequest({
            asyncAction: fetchUserDataRights(userId),
            onSuccess: {
                callback: (rightsData: IRight[]) => {
                    if (
                        rightsData.some((right) => right.relationId === 'ALL')
                    ) {
                        setLoading(false);

                        setSelectedRight('ALL');
                        return;
                    }
                    preparePersonalAssignments(
                        setLoading,
                        setObjectLists,
                        setSelectedRight,
                        rightsData,
                        filter,
                        userId
                    );
                },
            },
            onError: {
                callback: () => setLoading(false),
            },
        });
    }, [userId]);

    const handleSubmit = async () => {
        if (
            !personalGroupId ||
            selectedRight === '' ||
            (selectedRight === 'SELECTED' && !added.length && !removed.length)
        ) {
            handleCloseDialog();
            return;
        }

        const deducedSeletedRight = deduceSelectedRight(
            selectedRight,
            objectLists.assignedObjects
        );

        setLoading(true);

        if (deducedSeletedRight === 'SELECTED') {
            const selectedAssignmentsPromises = getSelectedAssignmentsPromises(
                added,
                removed,
                personalGroupId,
                userId,
                toolkitDispatch
            );

            await Promise.all(selectedAssignmentsPromises)
                .then(() => {
                    showNotification(
                        true,
                        TranslationHelper.translate('Changes have been saved')
                    );
                    setLoading(false);
                    handleCloseDialog();
                })
                .catch((error) => {
                    const message = errorMessageHandler(error.status)();
                    showNotification(false, message);
                    setLoading(false);
                    handleCloseDialog();
                });
        } else {
            await updateGroupAssignments(
                personalGroupId,
                [],
                objectLists.assignedObjects
            );

            toolkitDispatch(
                patchRightsAssignments({
                    id: userId,
                    data: {
                        mode: deducedSeletedRight,
                        added: [],
                        removed: [],
                    },
                })
            )
                .unwrap()
                .then(() => {
                    showNotification(
                        true,
                        TranslationHelper.translate('Changes have been saved')
                    );
                    setLoading(false);
                    handleCloseDialog();
                })
                .catch((error) => {
                    const message = errorMessageHandler(error.status)();
                    showNotification(false, message);
                    setLoading(false);
                    handleCloseDialog();
                });
        }
    };

    const handleCloseDialog = (event?: object, reason?: string) => {
        if (reason === 'backdropClick') {
            return;
        }
        toolkitDispatch(clearRights());
        closeDialog();
    };

    const handleChangeRight = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedRight(e.target.value as TSelectedRight);
    };

    return (
        <>
            <Dialog
                open={isOpen}
                keepMounted={true}
                onClose={handleCloseDialog}
                maxWidth={'lg'}
                classes={{ paper: classes.paper }}
            >
                <DialogTitle
                    title={`${TranslationHelper.translate(
                        'Data access rights'
                    )}`}
                    close={handleCloseDialog}
                    save={handleSubmit}
                    loading={loading}
                ></DialogTitle>
                <DialogContent>
                    <div className={classes.wrapper}>
                        {loading ? (
                            <LoadingSpinner
                                size="50"
                                top="0"
                                left="0"
                                right="0"
                                bottom="0"
                            />
                        ) : (
                            <>
                                <SectionWithTitle title="Rights">
                                    <RadioButtons
                                        options={Object.values(
                                            SelectedRightOptions
                                        )}
                                        value={selectedRight}
                                        handleChange={handleChangeRight}
                                    />
                                </SectionWithTitle>
                            </>
                        )}
                        <RightsTransferList
                            objectLists={objectLists}
                            setObjectLists={setObjectLists}
                            added={added}
                            setAdded={setAdded}
                            removed={removed}
                            setRemoved={setRemoved}
                            filter={filter}
                            setFilter={setFilter}
                            filterObjects={filterObjects}
                            selectedRight={selectedRight}
                            assignmentsLoading={loading}
                            setAssignmentsLoading={setLoading}
                            setSelectedRight={setSelectedRight}
                        />
                    </div>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default AssignRightsDialog;
