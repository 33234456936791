import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => {
    const drawerEasing = theme.transitions.easing.easeInOut;

    return {
        appBar: {
            zIndex: theme.zIndex.appBar,
            transition: theme.transitions.create(['width', 'margin'], {
                easing: drawerEasing,
                duration: theme.transitions.duration.leavingScreen,
            }),
            boxShadow: 'none',
            '@media print': {
                display: 'none',
            },
        },
        toolbar: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '0 8px',
            overflow: 'hidden',
            ...theme.mixins.toolbar,

            [theme.breakpoints.down('sm')]: {
                backgroundColor: theme.palette.primary.main,
            },
        },

        toolbarTitle: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            overflow: 'hidden',
            ...theme.mixins.toolbar,

            [theme.breakpoints.down('sm')]: {
                color: theme.palette.primary.contrastText,
            },
        },
        toolbarMainLogo: {
            paddingRight: theme.spacing(),
            transition: theme.transitions.create('margin-left', {
                easing: drawerEasing,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },

        toolbarMainLogoImg: {
            verticalAlign: 'bottom',
            height: 50,
        },

        contextName: {
            '&:before': {
                content: "' / '",

                [theme.breakpoints.down('sm')]: {
                    content: "''",
                },
            },
        },

        brand: {
            paddingRight: 8,
        },

        contentArea: {
            display: 'flex',
            flexGrow: 1,
            height: 0, // needed in Firefox to prevent weird element height behavior caused by flexGrow: 1
            flexDirection: 'column',
            position: 'relative',
            backgroundColor: theme.palette.background.default,
            padding: theme.spacing(3),
            '@media not print': {
                boxShadow: 'inset 0 2px 10px #ccc',
            },
            alignItems: 'stretch',
        },
        contentAreaNoPadding: {
            padding: 0,
        },
    };
});
