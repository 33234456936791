import React, { ReactChild, ReactElement } from 'react';

import { useStyles } from './Themable.hooks';

import View from '../View/View';
import TranslationHelper from '../../helpers/TranslationHelper';
import { AccessibleTab } from '../accessControl/components';
import EditOverlay from '../../pages/discovery/components/EditOverlay';

import { Tab, Tabs } from '@material-ui/core';
import { IPrivileges } from '../../state/auth';

interface ITab {
    value: string;
    label: string;
    icon: ReactElement;
    mainComponent: ReactElement;
    privileges?: string[];
}
interface IOwnProps {
    tabs: ITab[];
    currentTab: null | string;
    handleChange: (event: React.ChangeEvent<{}>, value: string) => void;
    title: string;
    id?: string;
    children?: ReactChild;
}
const TabsContainer = ({
    children,
    tabs,
    currentTab,
    handleChange,
    title,
    id,
}: IOwnProps) => {
    const classes = useStyles();

    const checkPrivileges = (
        privilegesList: IPrivileges,
        privileges: string[]
    ) => {
        return privileges.some((el) => privilegesList[el]);
    };

    return (
        <View
            title={TranslationHelper.translate(title)}
            noPadding={false}
            noColumn={true}
        >
            <>
                {children}

                <div className={classes.pageWrapper} id={id}>
                    {!!currentTab && (
                        <Tabs
                            value={currentTab}
                            onChange={handleChange}
                            className={classes.tabsBar}
                            indicatorColor="primary"
                        >
                            {tabs.map((tab, i) => {
                                const { privileges, value, label, icon } = tab;
                                return privileges ? (
                                    <AccessibleTab
                                        key={i}
                                        check={(privilegesList) =>
                                            checkPrivileges(
                                                privilegesList,
                                                privileges
                                            )
                                        }
                                        value={value}
                                        label={TranslationHelper.translate(
                                            label
                                        )}
                                        icon={icon}
                                        classes={{
                                            selected: classes.tab,
                                            root: classes.root,
                                        }}
                                    />
                                ) : (
                                    <Tab
                                        key={i}
                                        value={value}
                                        label={TranslationHelper.translate(
                                            label
                                        )}
                                        icon={icon}
                                        classes={{
                                            selected: classes.tab,
                                            root: classes.root,
                                        }}
                                    />
                                );
                            })}
                        </Tabs>
                    )}
                    <div className={classes.contentWrapper}>
                        {tabs.map(
                            (tab, i) =>
                                currentTab === tab.value && (
                                    <React.Fragment key={i}>
                                        {tab.mainComponent}
                                    </React.Fragment>
                                )
                        )}
                    </div>
                    <EditOverlay />
                </div>
            </>
        </View>
    );
};

export default TabsContainer;
