import React, { ReactChild } from 'react';

import moment from 'moment-timezone';

import { default as IconButton } from '@material-ui/core/IconButton';
import { default as List } from '@material-ui/core/List';
import { default as ListItem } from '@material-ui/core/ListItem';
import { default as ListItemSecondaryAction } from '@material-ui/core/ListItemSecondaryAction';
import { default as ListItemText } from '@material-ui/core/ListItemText';
import { default as Delete } from '@material-ui/icons/Delete';

import { useStyles } from './Themable.hooks';

import { IBookmark } from '../../../../state/types';
import { useUserSettings } from '../../../../state/user/index.hooks';
import { deleteBookmark } from '../../../../state/app/bookmarks';

import { useAppDispatch } from '../../../../hooks';

import Translator from '../../../../helpers/TranslationHelper';
import { isDataSegmentsContextEnabled } from '../../../../constants/AppConfig';

interface IOwnProps {
    segments: IBookmark[];
    renderRightCustomControls?: (bookmark: IBookmark) => ReactChild;
    renderLeftCustomControls?: (bookmark: IBookmark) => ReactChild;
    deleteCb?: (id: string) => void;
    allowDelete?: boolean;
}

const DataSegmentsList = ({
    segments,
    renderLeftCustomControls,
    renderRightCustomControls,
    deleteCb,
    allowDelete = true,
}: IOwnProps) => {
    const userSettings = useUserSettings();
    const classes = useStyles();

    const dispatch = useAppDispatch();
    const dataSegmentsContextEnabled = isDataSegmentsContextEnabled();

    const dateFormat = userSettings.dateFormat;

    const handleDeleteBookmark = (id: string) => {
        dispatch(deleteBookmark(id));
        deleteCb && deleteCb(id);
    };

    return (
        <div
            className={
                dataSegmentsContextEnabled
                    ? classes.mainSection
                    : classes.mainSectionDialog
            }
        >
            {segments && segments.length > 0 ? (
                <List>
                    {segments.map((segment) => (
                        <ListItem
                            classes={{
                                secondaryAction: classes.secondaryAction,
                            }}
                            key={segment.id}
                            divider={true}
                        >
                            {renderLeftCustomControls &&
                                renderLeftCustomControls(segment)}

                            <ListItemText
                                primary={segment.monitoredName}
                                primaryTypographyProps={{
                                    className: classes.primaryText,
                                }}
                                secondary={
                                    <React.Fragment>
                                        {`${Translator.translate(
                                            'from'
                                        )} ${moment(segment.from).format(
                                            dateFormat
                                        )}`}
                                        <br />
                                        {`${Translator.translate(
                                            'to'
                                        )} ${moment(segment.to).format(
                                            dateFormat
                                        )}`}
                                    </React.Fragment>
                                }
                            />
                            <ListItemSecondaryAction>
                                {renderRightCustomControls &&
                                    renderRightCustomControls(segment)}
                                {allowDelete && (
                                    <IconButton
                                        onClick={() =>
                                            handleDeleteBookmark(segment.id)
                                        }
                                        title={Translator.translate('Delete')}
                                    >
                                        <Delete />
                                    </IconButton>
                                )}
                            </ListItemSecondaryAction>
                        </ListItem>
                    ))}
                </List>
            ) : (
                <div className={classes.noDataSection}>
                    {Translator.translate('No saved data segments')}
                </div>
            )}
        </div>
    );
};

export default DataSegmentsList;
