import React from 'react';

import SaveIcon from '@material-ui/icons/Save';
import { FormikActions, FormikValues } from 'formik';

import { LinearProgress } from '@material-ui/core';

import {
    resetMode,
    updateDevices,
} from '../../../../../../../../state/ui/devices';
import { IDevice } from '../../../../../../../../state/types';
import { useSelectedMultiDevices } from '../../../../../../../../state/ui/devices/index.hooks';

import TranslationHelper from '../../../../../../../../helpers/TranslationHelper';
import { useToolkitDispatch } from '../../../../../../../../hooks';
import useAsyncThunkWithSnackbar from '../../../../../../../../hooks/useAsyncThunkWithSnackbar';

import IconButtonWithTooltip from '../../../../../../../../components/IconButtonWithTooltip/IconButtonWithTooltip';
import DeviceForm from '../DeviceForm';

export interface IDeviceAttributes {
    eToll: boolean;
}

export interface IDeviceEdit {
    attributes?: {
        eToll: boolean;
    };
    integrations?: {
        eToll: {
            businessNumber: string;
            pin: string;
        };
    };
}

const DevicesMultipleEditionForm = () => {
    const selectedDevices = useSelectedMultiDevices();
    const toolkitDispatch = useToolkitDispatch();

    const handleAsyncRequest = useAsyncThunkWithSnackbar();

    const initialValues: IDeviceEdit = {
        attributes: selectedDevices?.[0]?.attributes,
    };

    const getRightCustomControls = (
        submitForm: () => void,
        submitting: boolean
    ) => (
        <IconButtonWithTooltip
            title={TranslationHelper.translate('Save')}
            onClick={submitForm}
            disabled={submitting}
        >
            <SaveIcon />
        </IconButtonWithTooltip>
    );

    const handleClose = () => {
        toolkitDispatch(resetMode());
    };

    const updateManyDevices = (
        devices: IDevice[],
        data: IDeviceEdit,
        setSubmitting: (isSubmitting: boolean) => void
    ) => {
        const devicesToChange = devices.map((deviceEl) => {
            const deviceToChange = { ...deviceEl };
            deviceToChange.attributes = data?.attributes;
            delete deviceToChange.integrations;
            return deviceToChange;
        });
        handleAsyncRequest({
            asyncAction: updateDevices(devicesToChange),
            onSuccess: {
                callback: () => setSubmitting(false),
                message: TranslationHelper.translate('Devices updated'),
            },
            onError: {
                callback: () => setSubmitting(false),
            },
        });
    };

    const handleValues = (
        data: IDeviceEdit,
        { setSubmitting }: FormikActions<FormikValues>
    ) => {
        updateManyDevices(selectedDevices, data, setSubmitting);
    };

    const getPaneHeader = () => {
        return TranslationHelper.translate('Edit multiple objects');
    };

    if (selectedDevices.length < 1) {
        return <LinearProgress />;
    }

    return (
        <DeviceForm
            handleClose={handleClose}
            handleValues={handleValues}
            getPaneHeader={getPaneHeader}
            getRightCustomControls={getRightCustomControls}
            initialValues={initialValues}
        />
    );
};

export default DevicesMultipleEditionForm;
