import React, { MutableRefObject, useState } from 'react';

import { useSnackbar } from 'notistack';

import videojs from 'video.js';
import Player from 'video.js/dist/types/player';
import 'video.js/dist/video-js.css';
import 'videojs-hotkeys';
import { useDiscoveryUIDialog } from '../../pages/discovery/selectors/index.hooks';

import { DEBOUNCE_LOW } from '../../constants/DebounceValues';
import { useVideoTime } from '../../state/ui/videoUrl/index.hooks';
import TranslationHelper from '../../helpers/TranslationHelper';

const FRAME_TIME = 105;
interface IOwnProps {
    options: any;
    videoRef: MutableRefObject<HTMLVideoElement | null>;
    playerRef: MutableRefObject<Player | null>;
    handleError: () => void;
    handleSrcError: () => void;
}
export const VideoJSPlayer = ({
    options,
    videoRef,
    playerRef,
    handleError,
    handleSrcError,
}: IOwnProps) => {
    const dialog = useDiscoveryUIDialog();
    const videoFromUrlTime = useVideoTime();

    const { enqueueSnackbar } = useSnackbar();

    const time = dialog?.timestamp || videoFromUrlTime;
    const [metaLoaded, setMetaLoaded] = useState(false);
    React.useEffect(() => {
        if (playerRef.current) {
            playerRef.current.dispose();
        }
        playerRef.current = videojs(videoRef?.current || '', options);
    }, []);

    React.useEffect(() => {
        checkTime();
    }, [videoRef.current, time, metaLoaded]);

    React.useEffect(() => {
        const player = playerRef.current;
        if (!player) {
            return;
        }
        player.on('error', () => {
            const error = player.error();
            if (error?.code === 3) {
                player.load();
                player.currentTime(
                    (player.currentTime() as number) + FRAME_TIME
                );
                enqueueSnackbar(
                    TranslationHelper.translate('Corrupted frame was skipped'),
                    {
                        variant: 'warning',
                    }
                );
            } else if (error?.code === 4) {
                handleSrcError();
            } else {
                handleError();
            }
        });
        player.on('loadedmetadata', () => {
            setMetaLoaded(true);
            setStyling(player);
        });

        // Dispose the Video.js player when the functional component unmounts
        return () => {
            if (player && !player.error_ && !player.isDisposed()) {
                player.dispose();
                playerRef.current = null;
            }
        };
    }, [playerRef, videoRef]);

    const checkTime = () => {
        if (!time) {
            return;
        }
        if (videoRef.current && videoRef.current.currentTime !== time) {
            playerRef.current?.pause();
            videoRef.current.currentTime = time;
            setTimeout(checkTime, DEBOUNCE_LOW);
        }
    };

    const setStyling = (player: Player) => {
        if (!playerRef.current) {
            return;
        }
        const verticalVideo =
            playerRef.current?.videoHeight() > playerRef.current?.videoWidth();
        if (verticalVideo) {
            player.addClass('fluid');
            player.aspectRatio('3:4');
        }
        if (!verticalVideo) {
            player.dimensions(960, 540);
        }
    };

    return (
        <div data-vjs-player style={{ minWidth: '100%' }}>
            <video ref={videoRef} className="video-js" />
        </div>
    );
};

export default VideoJSPlayer;
