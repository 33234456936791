import { createStyles, Theme, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => {
    const color = theme.palette.getContrastText(
        theme.palette.background.default
    );
    return createStyles({
        select: {
            width: '100%',
        },
        icon: {
            fill: color,
        },
        root: {
            color: color,
            '& label.Mui-focused': {
                color: color,
            },
            '& .MuiFormLabel-root': {
                color: color,
            },
            '& .MuiInput-underline:after': {
                borderBottomColor: color,
            },
            '& .MuiOutlinedInput-root': {
                '& fieldset': {
                    borderColor: color,
                },
                '&:hover fieldset': {
                    borderColor: color,
                },
                '&.Mui-focused fieldset': {
                    borderColor: color,
                },
            },
        },
    });
});
