import {
    IRightItem,
    patchRightsAssignments,
} from '../../../../../../state/ui/usersSlice';
import { prepareData } from './utils/prepareData';
import { prepareGroupAssignmentsData } from './utils/updateGroupAssignments';
import { patchRegistersGroupAssignments } from '../../../../../../state/ui/groupsSlice';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';

export const getSelectedAssignmentsPromises = (
    added: IRightItem[],
    removed: IRightItem[],
    personalGroupId: string | null,
    userId: number,
    dispatch: ThunkDispatch<null, null, Action>
): Promise<any>[] => {
    const isGroupOrSpecial = (item: IRightItem) =>
        item.type === 'GROUP' || item.type === 'SPECIAL';
    const isMonitoredObject = (item: IRightItem) =>
        item.type === 'MONITORED_OBJECT';

    const addedGroupSpecial = added.filter(isGroupOrSpecial);
    const removedGroupSpecial = removed.filter(isGroupOrSpecial);
    const addedMonitored = added.filter(isMonitoredObject);
    const removedMonitored = removed.filter(isMonitoredObject);

    const hasGroupSpecial =
        addedGroupSpecial.length > 0 || removedGroupSpecial.length > 0;
    const hasMonitored =
        addedMonitored.length > 0 || removedMonitored.length > 0;

    const promises: Promise<any>[] = [];

    if (!personalGroupId) {
        return promises;
    }

    promises.push(
        dispatch(
            patchRightsAssignments({
                id: userId,
                data: {
                    mode: 'SELECTED',
                    added: prepareData(addedGroupSpecial),
                    removed: prepareData(removedGroupSpecial),
                },
            })
        )
    );

    if (!hasGroupSpecial && hasMonitored) {
        const addedGroupAssignments = prepareGroupAssignmentsData(added);
        const removedGroupAssignments = prepareGroupAssignmentsData(removed);
        promises.push(
            patchRegistersGroupAssignments(
                personalGroupId,
                addedGroupAssignments,
                removedGroupAssignments
            )
        );
    } else if (hasGroupSpecial && hasMonitored) {
        const addedGroupAssignments =
            prepareGroupAssignmentsData(addedMonitored);
        const removedGroupAssignments =
            prepareGroupAssignmentsData(removedMonitored);
        promises.push(
            patchRegistersGroupAssignments(
                personalGroupId,
                addedGroupAssignments,
                removedGroupAssignments
            )
        );
    }
    return promises;
};
