import React from 'react';

import { Field, Form, Formik } from 'formik';

import {
    useActivityCategory,
    useObjectCategory,
    useServiceClass,
    useServiceType,
    useStatuses,
} from '../../../../../../../state/app/dictionaries/index.hooks';
import { useUserSettings } from '../../../../../../../state/user/index.hooks';

import TranslationHelper from '../../../../../../../helpers/TranslationHelper';
import {
    mapDictionaryItemToMenuItem,
    renderMenuItems,
} from '../../../../../../../helpers/renderMenuItems';
import TimeFormatter from '../../../../../../../helpers/TimeFormatter';

import { useStyles } from '../../../../../Themable.hooks';

import FieldWrapper from '../../../../../../../components/common/formikWrappers/FieldWrapper';
import DateFieldWrapper from '../../../../../../../components/common/formikWrappers/DateFieldWrapper';

interface IOwnProps {
    initialValues: any;
}
const TaskForm = ({ initialValues }: IOwnProps) => {
    const classes = useStyles();

    const handleValues = () => {};

    const statuses = useStatuses();

    const activityCategory = useActivityCategory();
    const serviceClass = useServiceClass();
    const serviceType = useServiceType();
    const objectCategory = useObjectCategory();
    const userSettings = useUserSettings();

    const commonProps = {
        fullWidth: true,
        component: FieldWrapper,
        //TODO - for now only preview mode is enabled, so all fields can be disabled - remove later on
        disabled: true,
    };

    return (
        <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            onSubmit={handleValues}
            validateOnBlur={false}
            validateOnChange={false}
            validate={() => ({})}
        >
            {({ errors, values }) => (
                <>
                    <Form>
                        <div className={classes.formWrapper}>
                            <Field
                                error={!!(errors && errors.status)}
                                helperText={errors && errors.status}
                                name={'status'}
                                label={TranslationHelper.translate('Status')}
                                type={'number'}
                                required={true}
                                select
                                {...commonProps}
                            >
                                {renderMenuItems(
                                    statuses,
                                    mapDictionaryItemToMenuItem
                                )}
                            </Field>
                            <Field
                                error={!!(errors && errors.plannedOn)}
                                helperText={errors && errors.plannedOn}
                                name={'plannedOn'}
                                label={TranslationHelper.translate(
                                    'Planned on'
                                )}
                                displayFormat={userSettings.shortDateFormat}
                                value={values.plannedOn}
                                withDateFormatter={
                                    TimeFormatter.dateToShortDateString
                                }
                                {...commonProps}
                                component={DateFieldWrapper}
                            />
                            <Field
                                error={!!(errors && errors.statusDate)}
                                helperText={errors && errors.statusDate}
                                name={'statusDate'}
                                label={TranslationHelper.translate(
                                    'Status date'
                                )}
                                displayFormat={userSettings.shortDateFormat}
                                value={values.statusDate}
                                withDateFormatter={
                                    TimeFormatter.dateToShortDateString
                                }
                                {...commonProps}
                                component={DateFieldWrapper}
                            />
                            <Field
                                error={!!(errors && errors.employeeName)}
                                helperText={errors && errors.employeeName}
                                name={'employeeName'}
                                label={TranslationHelper.translate(
                                    'Employee name'
                                )}
                                type={'text'}
                                {...commonProps}
                            />
                            <Field
                                error={!!(errors && errors.vehicleName)}
                                helperText={errors && errors.vehicleName}
                                name={'vehicleName'}
                                label={TranslationHelper.translate(
                                    'Vehicle name'
                                )}
                                type={'text'}
                                {...commonProps}
                            />
                            <Field
                                error={!!(errors && errors.activityCategory)}
                                helperText={errors && errors.activityCategory}
                                name={'activityCategoryId'}
                                label={TranslationHelper.translate('Type 1')}
                                select={true}
                                required={true}
                                {...commonProps}
                            >
                                {renderMenuItems(
                                    activityCategory,
                                    mapDictionaryItemToMenuItem
                                )}
                            </Field>
                            <Field
                                error={!!(errors && errors.serviceType)}
                                helperText={errors && errors.serviceType}
                                name={'serviceTypeId'}
                                label={TranslationHelper.translate('Type 2')}
                                select={true}
                                required={true}
                                {...commonProps}
                            >
                                {renderMenuItems(
                                    serviceType,
                                    mapDictionaryItemToMenuItem,
                                    values.activityCategoryId
                                )}
                            </Field>
                            <Field
                                error={!!(errors && errors.objectCategory)}
                                helperText={errors && errors.objectCategory}
                                name={'objectCategoryId'}
                                label={TranslationHelper.translate('Type 3')}
                                select={true}
                                required={true}
                                {...commonProps}
                            >
                                {renderMenuItems(
                                    objectCategory,
                                    mapDictionaryItemToMenuItem,
                                    values.activityCategoryId
                                )}
                            </Field>
                            <Field
                                name={'serviceClassId'}
                                label={TranslationHelper.translate('Type 4')}
                                select={true}
                                {...commonProps}
                            >
                                {renderMenuItems(
                                    serviceClass,
                                    mapDictionaryItemToMenuItem,
                                    values.activityCategoryId,
                                    true
                                )}
                            </Field>
                            <Field
                                error={!!(errors && errors.unitCount)}
                                helperText={errors && errors.unitCount}
                                name={'unitCount'}
                                label={TranslationHelper.translate(
                                    'Units count'
                                )}
                                type={'number'}
                                {...commonProps}
                            />
                            <Field
                                error={!!(errors && errors.rfidCode)}
                                helperText={errors && errors.rfidCode}
                                name={'rfidCode'}
                                label={TranslationHelper.translate('RFID')}
                                type={'text'}
                                {...commonProps}
                            />

                            <Field
                                error={!!(errors && errors.notice)}
                                helperText={errors && errors.notice}
                                name={'notice'}
                                label={TranslationHelper.translate('Notice')}
                                type={'text'}
                                {...commonProps}
                            />
                            <Field
                                error={!!(errors && errors.externalNumber)}
                                helperText={errors && errors.externalNumber}
                                name={'externalNumber'}
                                label={TranslationHelper.translate(
                                    'External number'
                                )}
                                type={'text'}
                                {...commonProps}
                            />
                        </div>
                    </Form>
                </>
            )}
        </Formik>
    );
};

export default TaskForm;
