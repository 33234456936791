import React from 'react';
import { Button, Tooltip } from '@material-ui/core';
import LinkIcon from '@material-ui/icons/Link';
import VideocamIcon from '@material-ui/icons/Videocam';

import { useStyles } from './Themable.hooks';

import TranslationHelper from '../../helpers/TranslationHelper';

interface ICopyButton {
    title: string;
    callback: () => void;
}
interface IOwnProps {
    copyLink?: ICopyButton;
    copyVideoLink?: ICopyButton;
}

const CopyLinkContainer = ({ copyLink, copyVideoLink }: IOwnProps) => {
    const classes = useStyles();

    return (
        <div className={classes.options}>
            {copyLink && (
                <Tooltip title={copyLink.title} placement="top">
                    <Button
                        aria-label="copy"
                        className={classes.copyButton}
                        size="small"
                        startIcon={<LinkIcon />}
                        onClick={copyLink.callback}
                    >
                        {TranslationHelper.translate('Copy link')}
                    </Button>
                </Tooltip>
            )}
            {copyVideoLink && (
                <Tooltip title={copyVideoLink.title} placement="top">
                    <Button
                        aria-label="copy"
                        className={classes.copyButton}
                        size="small"
                        startIcon={<VideocamIcon />}
                        onClick={copyVideoLink.callback}
                    >
                        {TranslationHelper.translate('Copy video link')}
                    </Button>
                </Tooltip>
            )}
        </div>
    );
};

export default CopyLinkContainer;
