import React from 'react';

import { ICellRendererParams } from 'ag-grid-community';

import EditIcon from '@material-ui/icons/Edit';

import {
    deleteSchedule,
    enterEditMode,
} from '../../../../../../../../state/ui/reportsSlice';

import TranslationHelper from '../../../../../../../../helpers/TranslationHelper';

import { useToolkitDispatch } from '../../../../../../../../hooks';

import DeleteButton from '../../../../../../../../components/DeleteButton';
import IconButtonWithTooltip from '../../../../../../../../components/IconButtonWithTooltip';

type TOwnProps = ICellRendererParams;

const ActionsButtons = (props: TOwnProps) => {
    const toolkitDispatch = useToolkitDispatch();

    const handleDelete = () => {
        toolkitDispatch(deleteSchedule(props.value))
            .unwrap()
            .then(() => {
                props.api.purgeInfiniteCache();
            });
    };

    const handleEit = () => {
        toolkitDispatch(enterEditMode(props.value));
    };

    const shouldShowButtons = props.value;

    return shouldShowButtons ? (
        <>
            <IconButtonWithTooltip
                title={TranslationHelper.translate('Edit')}
                onClick={handleEit}
                size="small"
                defaultColor
            >
                <EditIcon fontSize="small" />
            </IconButtonWithTooltip>
            <DeleteButton
                title="Removal confirmation"
                tooltip="Delete"
                message="Are you sure?"
                deleteMethod={handleDelete}
                size="small"
                defaultColor
            />
        </>
    ) : (
        ''
    );
};

export default ActionsButtons;
