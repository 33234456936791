import { createSelector } from 'reselect';

import { TRootState } from '../../../store';

import TranslationHelper from '../../../helpers/TranslationHelper';
import { errorMessageHandler } from '../../../helpers/errorMessageHandler';

import {
    createRoleStorePath,
    getDeleteRoleStorePath,
    getRolesStorePath,
    getUpdateRoleStorePath,
    IPrivilege,
    IRole,
} from '../../../state/app/adminPanel';

import { IPendingRequests } from 'src/app/state/app/sync';

export const getPrivileges = (state: TRootState) =>
    state.app.adminPanel.privileges;

export interface IPrivilegesGroup {
    groupName: string;
    privilegesArray: IPrivilege[];
    privilegesIds: number[];
}

export const getGroupedPrivileges = createSelector(
    [getPrivileges],
    (data) =>
        Object.entries(
            (data &&
                data.reduce((h, curr) => {
                    if (h[curr.group]) {
                        h[curr.group].push(curr);
                    } else {
                        h[curr.group] = [curr];
                    }
                    return h;
                }, {})) ||
                {}
        ).map(([groupName, privilegesArray]) => ({
            groupName,
            privilegesArray,
            privilegesIds: (privilegesArray as IPrivilege[]).map(
                (privilege) => privilege.id
            ),
        })) as IPrivilegesGroup[]
);

export const getRoleData = (state: TRootState) =>
    state.app.adminPanel.role as IRole;

export const getRoleList = (state: TRootState) =>
    state.app.adminPanel.roles || [];

export const getRoleToDelete = (state: TRootState) =>
    state.app.adminPanel.roleToDelete;

export const getSelectedRoleId = (state: TRootState) =>
    state.ui.roles.selectedRoleId;

export const getMode = (state: TRootState) => state.ui.roles.mode;

export const isRoleDetailsVisible = (state: TRootState) =>
    getSelectedRoleId(state) !== null || getMode(state) === 'create';

// ! Server response handlers

const getSyncRequests = (state: TRootState) => state.app.sync.requests;

const successMessageGetters = {
    roleCreated: () => TranslationHelper.translate('Role created'),
    roleEdited: () => TranslationHelper.translate('Role updated'),
    roleDeleted: () => TranslationHelper.translate('Role deleted'),
};
export const getRoleListRequest = createSelector(
    [getSyncRequests],
    (requests) => {
        const request = requests[getRolesStorePath];
        if (!request) {
            return null;
        }

        const { success, error } = request;

        return {
            ...request,
            success: success && {
                ...success,
            },
            error: error && {
                ...error,
                getMessage: errorMessageHandler(error.status, {
                    409: 'Role with given name exists',
                }),
            },
        };
    }
);

export const getRoleCreationRequest = createSelector(
    [getSyncRequests],
    (requests) => {
        const request = requests[createRoleStorePath];
        if (!request) {
            return null;
        }

        const { success, error } = request;

        return {
            ...request,
            success: success && {
                ...success,
                getMessage: successMessageGetters.roleCreated,
            },
            error: error && {
                ...error,
                getMessage: errorMessageHandler(error.status, {
                    409: 'Role with given name exists',
                }),
            },
        };
    }
);

const makeRequestHandler = (
    roleId: number | null,
    requests: IPendingRequests,
    storePath: (id: number) => string,
    successMessage: () => string
) => {
    const request = roleId && requests && requests[storePath(roleId)];
    if (!request) {
        return null;
    }

    const { success, error } = request;

    return {
        ...request,
        success: success && {
            ...success,
            getMessage: successMessage,
        },
        error: error && {
            ...error,
            getMessage: errorMessageHandler(error.status, {
                409: 'Role with given name exists',
            }),
        },
    };
};

export const getRoleUpdateRequest = createSelector(
    [getSelectedRoleId, getSyncRequests],
    (roleId, requests) =>
        makeRequestHandler(
            roleId,
            requests,
            getUpdateRoleStorePath,
            successMessageGetters.roleEdited
        )
);

export const getRoleDeleteRequest = createSelector(
    [getRoleToDelete, getSyncRequests],
    (roleId, requests) =>
        makeRequestHandler(
            roleId,
            requests,
            getDeleteRoleStorePath,
            successMessageGetters.roleDeleted
        )
);
