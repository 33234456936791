import React, { ReactChild } from 'react';

import { Box } from '@mui/material';

interface IOwnProps {
    children: ReactChild;
}
const PageContent = ({ children }: IOwnProps) => {
    return (
        <Box
            sx={(theme) => ({
                position: 'absolute',
                top: 10,
                left: 10,
                bottom: 10,
                right: 10,
                [theme.breakpoints.down('sm')]: {
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                },
            })}
        >
            {children}
        </Box>
    );
};
export default PageContent;
