import TranslationHelper from '../../../helpers/TranslationHelper';

import { IReport, IReportSet } from '../../../state/types';
import {
    INormalizeCollection,
    INormalizeSingleEntity,
    TAbnomalObject,
} from './fetchMakers';

const makeNormalizeCollection =
    (keyName = 'id', translate?: (reportSet: IReportSet) => IReportSet) =>
    (items: object[]) => {
        return items.reduce(
            (data: { result: object[]; entities: object }, next) => {
                const id = next[keyName].toString();
                data.result.push(id);
                next[keyName] = id;
                data.entities[id] = translate
                    ? translate(next as IReportSet)
                    : next;
                return data;
            },
            { result: [], entities: {} }
        );
    };

const makeNormalizeReport =
    (id: string, translate?: (report: IReport) => IReport) =>
    (report: IReport) => {
        return {
            result: id,
            entities: {
                [id]: translate ? translate(report) : report,
            },
        };
    };

const translateReportSet = (reportSet: IReportSet) => {
    reportSet.reports.forEach((report) => {
        translateReport(report);
    });

    return reportSet;
};

const translateReport = (report: IReport) => {
    report.label = report.labelParams
        ? TranslationHelper.translateFormat(
              report.label,
              report.labelParams,
              report.translate
          )
        : TranslationHelper.translate(report.label, report.translate);
    report.entities.forEach((entity) => {
        const entitySuffix = entity.formatting && entity.formatting.suffix;
        const entityWithFormattingTranslate =
            entity.formatting && entity.formatting.translate;

        entity.translatedLabel = entity.label
            ? `${TranslationHelper.translate(entity.label, entity.translate)}${
                  entitySuffix
                      ? TranslationHelper.translate(
                            entitySuffix,
                            entityWithFormattingTranslate
                        )
                      : ''
              }`
            : entity.translate
            ? typeof entity.value === 'string'
                ? TranslationHelper.translate(entity.value, entity.translate)
                : null
            : null;
    });

    if (report._meta && report._meta.sorted) {
        report.entities.sort((a, b) =>
            (a.translatedLabel || a.label).localeCompare(
                b.translatedLabel || b.label
            )
        );
    }

    return report;
};

const normalizeCollection = makeNormalizeCollection();

export const normalizeMonitoredCollection =
    makeNormalizeCollection('monitoredId');

export const normalizeAndTranslateCollection = makeNormalizeCollection(
    'id',
    translateReportSet
);

export const normalizeAndTranslateReport = (sectionId: string) =>
    makeNormalizeReport(sectionId, translateReport);

const makeNormalizeSingleEntity =
    (normalize: INormalizeCollection) => (singleEntity: TAbnomalObject) =>
        normalize(singleEntity.id ? [singleEntity] : []);

export const normalizeSingleEntity: INormalizeSingleEntity =
    makeNormalizeSingleEntity(normalizeCollection);

export const normalizeAndTranslateSingleEntity: INormalizeSingleEntity =
    makeNormalizeSingleEntity(normalizeAndTranslateCollection);

export const normalizeSingleMonitoredEntity: INormalizeSingleEntity =
    makeNormalizeSingleEntity(normalizeMonitoredCollection); // TODO PROBABLY NOT NEEDED

export const normalizeSingleSourceSet: INormalizeSingleEntity = (
    singleEntity: TAbnomalObject
) => singleEntity;
