import PageableCollection from '../../../../models/common/PageableCollection';
import FuelTank from '../../../../models/registers/FuelTank';
import {
    restGetTanks,
    restPutFuelTankRfidCards,
    restPutTank,
} from '../../../../services/registers';
import {
    IRequestErrorWithJSONMessage,
    IRequestWithMessages,
    makeRequest,
} from '../../../../state/app/sync';
import { makeReduxDuck } from 'teedux';
import { IPage, IRegisterFilter, mapToPageableCollection } from '..';
import { TRootState } from 'src/app/store';
import { IGroup } from '../../collections/dataTypes';

export interface ITank {
    id: string;
    name: string;
    monitoredId: number;
    timeZone?: {
        id: number;
        name: string;
    };
    group?: {
        name: string;
    };
    groups?: IGroup[];
}

interface IState {
    fuelTanks: PageableCollection | null;
    fuelTanksFilter: IRegisterFilter;
}

export const initialState: IState = {
    fuelTanks: null,
    fuelTanksFilter: {
        name: '',
        start: 0,
        limit: 25,
        group: undefined,
    },
};

const duck = makeReduxDuck('app/registers/tanks', initialState);

const fuelTanksStorePath = 'app/registers/tanks';

export const setFuelTanks = duck.defineAction<{
    fuelTanks: PageableCollection;
}>('SET_FUEL_TANKS', (_, { fuelTanks }) => ({
    fuelTanks,
}));

export const setFuelTanksFilter = duck.defineAction<{
    fuelTanksFilter: IRegisterFilter;
}>('SET_FUEL_TANKS_FILTER', (_, { fuelTanksFilter }) => ({
    fuelTanksFilter,
}));

export const fetchFuelTanks = (
    params: IRegisterFilter = initialState.fuelTanksFilter,
    afterRequest: (data: IPage<ITank>) => void = () => null
) =>
    makeRequest(
        `get:${fuelTanksStorePath}`,
        () => restGetTanks(params),
        (dispatch, data) => {
            dispatch(
                setFuelTanks({
                    fuelTanks: mapToPageableCollection(data, FuelTank.fromJson),
                })
            );

            if (afterRequest) {
                afterRequest(data);
            }
        },
        (dispatch, error) => undefined
    );

export const updateFuelTank = (
    tank: ITank,
    filter?: IRegisterFilter,
    afterRequest?: (data: IRequestWithMessages) => void,
    afterFail?: (error: IRequestErrorWithJSONMessage) => void
) =>
    makeRequest(
        `put:${fuelTanksStorePath}/${tank.id}`,
        () => restPutTank(tank),
        (dispatch, data) => {
            // @ts-ignore
            dispatch(fetchFuelTanks(filter));
            if (afterRequest) {
                afterRequest(data);
            }
        },
        (dispatch, error) => {
            if (afterFail) {
                afterFail(error);
            }
        }
    );

export const updateFuelTankCards = (
    id: string,
    cards: string[],
    filter: IRegisterFilter,
    afterRequest?: (data: IRequestWithMessages) => void,
    afterFail?: () => void
) =>
    makeRequest(
        `put:${fuelTanksStorePath}/${id}/send-cards`,
        () => restPutFuelTankRfidCards(id, cards),
        (dispatch, data) => {
            // @ts-ignore
            dispatch(fetchFuelTanks(filter));
            if (afterRequest) {
                afterRequest(data);
            }
        },
        () => {
            if (afterFail) {
                afterFail();
            }
        }
    );

const getRegistersFuelTanksState = (state: TRootState) =>
    state.app.registers.tanks;

export const getFuelTanks = (state: TRootState) =>
    getRegistersFuelTanksState(state).fuelTanks;
export const getFuelTanksFilter = (state: TRootState) =>
    getRegistersFuelTanksState(state).fuelTanksFilter;

export default duck.getReducer();
