import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => {
    const mainThemeColor = theme.palette.primary.main;
    return createStyles({
        root: {
            marginLeft: '10px',
        },
        label: {
            display: 'flex',
            width: '60px',
            textAlign: 'center',
            fontSize: '10px',
            color: theme.palette.getContrastText(mainThemeColor),
        },
        colorCheckbox: {
            color: theme.palette.getContrastText(mainThemeColor),
            '&$colorChecked': {
                color: theme.palette.getContrastText(mainThemeColor),
            },
        },
        colorChecked: {},
        badge: {
            top: 18,
        },
    });
});
