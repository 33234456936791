import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => {
    const spacing = theme.spacing(3);

    return createStyles({
        error: {
            backgroundColor: theme.palette.error.dark,
        },
        pane: {
            display: 'flex',
            height: '100%',
        },
        formWrapper: {
            padding: spacing,
            overflowX: 'hidden',
            overflowY: 'auto',
        },
        wrapper: {
            display: 'flex',
            position: 'relative',
            flexGrow: 1,
            flexDirection: 'column',
            overflow: 'hidden',
        },
        mainWrapper: {
            height: '100%',
            width: '100%',
            position: 'relative',
            zIndex: 200,
            overflowX: 'hidden',
        },
        paddingWrapper: {
            padding: spacing,
        },
        rolesWrapper: {
            marginTop: spacing * 2,
        },
    });
});
