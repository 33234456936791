import React from 'react';

import { useAppDispatch } from '../../../../../../hooks';
import { IRoutesUrlParams } from '../../../../../../services/discovery/_shared/urlMakers';
import { ISourceSet } from '../../../../../../state/types';
import {
    closePreview,
    fetchRoutes,
    setSourceSetDates,
} from '../../../../../../state/_actions';
import {
    useIsAnalyticsModeEnabled,
    useIsGridChart,
    usePane,
    useSourceSetDates,
} from '../../../../selectors/index.hooks';
import ErrorMessage from '../ErrorMessage';
import SearchField from '../../../../../../components/common/SearchField/SearchField';
import DateRangeDisplay from '../../../../../../components/DateRangeDisplay';

interface IOwnProps {
    sourceSet: ISourceSet | null;
    filtering: {
        searchPhrase: string;
        onChange: (searchPhrase: string) => void;
    };
}
const RoutesLeftHeader = ({ sourceSet, filtering }: IOwnProps) => {
    const dispatch = useAppDispatch();

    const pane = usePane();
    const sourceSetDates = useSourceSetDates();
    const isChart = useIsGridChart();
    const isAnalyticsModeEnabled = useIsAnalyticsModeEnabled();

    const handleFetchRoutes = (from: string, to: string) => {
        if (sourceSet?.id) {
            dispatch(setSourceSetDates(sourceSet.id, from, to));
        }

        if (pane) {
            dispatch(closePreview(pane.creatorLevel, false));
        }

        return dispatch(
            fetchRoutes({
                from,
                to,
            } as IRoutesUrlParams) as unknown as Promise<any>
        );
    };
    const renderSearchField = () => {
        return (
            !isChart &&
            sourceSet && (
                <SearchField
                    value={filtering.searchPhrase}
                    filterChange={filtering.onChange}
                />
            )
        );
    };
    const renderFiltering = () => {
        return (
            !isAnalyticsModeEnabled &&
            sourceSet &&
            sourceSetDates && (
                <>
                    <DateRangeDisplay
                        onDateChange={handleFetchRoutes}
                        sourceSetId={sourceSet?.id}
                        dates={sourceSetDates}
                        datesFormat={'short'}
                        small
                    />
                </>
            )
        );
    };

    return (
        <>
            <>
                {renderSearchField()}
                {renderFiltering()}
                <ErrorMessage messages={sourceSet?.messages} />
            </>
        </>
    );
};

export default RoutesLeftHeader;
