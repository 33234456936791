import React, { ReactElement } from 'react';

import { ColumnApi, GridApi } from 'ag-grid-community';

import { Button, Divider } from '@material-ui/core';
import AssessmentIcon from '@mui/icons-material/Assessment';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';

import { useStyles } from '../../Themable.hooks';

import TranslationHelper from '../../../../../../../../helpers/TranslationHelper';
import { useAppDispatch } from '../../../../../../../../hooks';

import { toggleAnalyticsMode } from '../../../../../../../../state/ui/discovery/snapshotting';

import { useIsAnalyticsModeEnabled } from '../../../../../../selectors/index.hooks';
import { useGridSourceSet } from '../../../../../../selectors/composed/index.hooks';

import MenuDropdown from '../../../../../../../../components/MenuDropdown';

interface IOwnProps {
    gridApi?: GridApi;
    gridColumnApi?: ColumnApi;
    gridEnterpriseKey: string;
    handleFullscreen: () => void;
    isGridFullscreen: boolean;
    gridViewOptions: ReactElement;
}

const MenuButtons = ({
    gridApi,
    gridColumnApi,
    gridEnterpriseKey,
    handleFullscreen,
    isGridFullscreen,
    gridViewOptions,
}: IOwnProps) => {
    const isAnalyticsEnabled = useIsAnalyticsModeEnabled();

    const sourceSet = useGridSourceSet();

    const classes = useStyles();

    const dispatch = useAppDispatch();

    const handleAnalyticsMode = () => {
        if (!sourceSet) {
            return;
        }

        dispatch(toggleAnalyticsMode(sourceSet.id));
    };

    const renderAnalyticsButton = () => {
        if (sourceSet && gridEnterpriseKey) {
            return (
                <Button
                    className={classes.optionItem}
                    onClick={handleAnalyticsMode}
                    fullWidth
                >
                    <AssessmentIcon />
                    {`${TranslationHelper.translate(
                        'Analytics'
                    )} (${TranslationHelper.translate('BETA')})`}
                </Button>
            );
        }
        return null;
    };

    const renderGridViewButtons = () => {
        return (
            <>
                {gridViewOptions}
                <Divider style={{ width: '100%' }} />
            </>
        );
    };

    const renderFullscreenButton = () => {
        if (gridColumnApi && gridApi && !isAnalyticsEnabled) {
            return (
                <Button
                    className={classes.optionItem}
                    onClick={handleFullscreen}
                    fullWidth
                >
                    {isGridFullscreen ? (
                        <FullscreenExitIcon />
                    ) : (
                        <FullscreenIcon />
                    )}
                    {TranslationHelper.translate('Fullscreen')}
                </Button>
            );
        }
        return null;
    };
    return (
        <>
            <MenuDropdown>
                <div className={classes.options}>
                    {renderGridViewButtons()}

                    {renderAnalyticsButton()}
                    {renderFullscreenButton()}
                </div>
            </MenuDropdown>
        </>
    );
};
export default MenuButtons;
