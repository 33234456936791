import React, { ReactChild } from 'react';
import TranslationHelper from '../../../helpers/TranslationHelper';

import MuiDialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import DialogActions from '@mui/material/DialogActions';

import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';

import MenuDropdown from '../../MenuDropdown';

interface IOwnProps {
    close: () => void;
    save?: () => void;
    dropDown?: () => ReactChild;
    loading?: boolean;
    title: string;
    titleButton?: () => ReactChild;
}

const DialogTitle = ({
    close,
    save,
    title,
    titleButton,
    dropDown,
    loading,
}: IOwnProps) => {
    return (
        <MuiDialogTitle
            component="div"
            sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                color: 'primary.contrastText',
                bgcolor: 'primary.main',
            }}
        >
            <Typography
                variant="h6"
                sx={{
                    display: 'flex',
                    p: 0,
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                {TranslationHelper.translate(title)}
                {titleButton && titleButton()}
            </Typography>
            <DialogActions
                sx={{
                    p: 0,
                    justifyContent: 'space-between',
                    color: 'primary.contrastText',
                }}
            >
                {dropDown && <MenuDropdown>{dropDown()}</MenuDropdown>}
                {save && (
                    <IconButton
                        aria-label="save"
                        onClick={save}
                        disabled={loading}
                        sx={{
                            py: 0,
                            px: 1,
                        }}
                    >
                        <SaveIcon
                            sx={{
                                color: 'primary.contrastText',
                            }}
                        />
                    </IconButton>
                )}
                {close && (
                    <IconButton
                        aria-label="close"
                        onClick={close}
                        sx={{
                            py: 0,
                            px: 1,
                        }}
                    >
                        <CloseIcon
                            sx={{
                                color: 'primary.contrastText',
                            }}
                        />
                    </IconButton>
                )}
            </DialogActions>
        </MuiDialogTitle>
    );
};

export default DialogTitle;
