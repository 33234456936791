import { createStyles, Theme, WithStyles } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme: Theme) => {
    return createStyles({
        picker: {
            margin: theme.spacing(),
        },
        formControl: {
            margin: theme.spacing(),
        },
        datePickers: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        quickButton: {
            margin: theme.spacing(),
        },
        quickButtonsWrapper: {
            display: 'flex',
            justifyContent: 'space-between',
        },
    });
};

export type TThemableProps = WithStyles<typeof styles>;

export const Themable = withStyles(styles, { withTheme: true });
