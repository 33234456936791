import React, { ReactChild } from 'react';

import {
    Field,
    Form,
    Formik,
    FormikActions,
    FormikErrors,
    FormikValues,
} from 'formik';

import { FormControlLabel, Typography } from '@material-ui/core';

import { useStyles } from '../../Themable.hooks';

import TranslationHelper from '../../../../../../../../helpers/TranslationHelper';

import CheckboxWrapper from '../../../../../../../../components/common/formikWrappers/CheckboxWrapper';
import PaneHeader from '../../../../../../../../components/PaneHeader/PaneHeader';

export interface IDeviceAttributes {
    eToll: boolean;
}

export interface IDeviceEdit {
    attributes?: {
        eToll: boolean;
    };
    integrations?: {
        eToll: {
            businessNumber: string;
            pin: string;
        };
    };
}

interface IOwnProps {
    handleValues: (
        data: IDeviceEdit,
        { setSubmitting }: FormikActions<FormikValues>
    ) => void;
    handleClose: () => void;
    getPaneHeader: () => string;
    getRightCustomControls: (
        callback: () => void,
        isSubmitting: boolean
    ) => ReactChild;
    renderAdditionalFields?: (errors: FormikErrors<IDeviceEdit>) => ReactChild;
    initialValues: FormikValues;
}
const DeviceForm = ({
    handleValues,
    handleClose,
    getPaneHeader,
    getRightCustomControls,
    renderAdditionalFields,
    initialValues,
}: IOwnProps) => {
    const classes = useStyles();

    return (
        <div>
            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                onSubmit={handleValues}
                validateOnBlur={false}
                validateOnChange={false}
            >
                {({
                    submitForm,
                    values,
                    isSubmitting,
                    setFieldValue,
                    errors,
                }) => (
                    <>
                        <PaneHeader
                            onCloseClick={handleClose}
                            title={getPaneHeader()}
                            renderRightCustomControls={() =>
                                getRightCustomControls(submitForm, isSubmitting)
                            }
                            submitting={isSubmitting}
                        />
                        <div className={classes.formWrapper}>
                            <Typography color={'primary'}>
                                {TranslationHelper.translate('eToll')}
                            </Typography>
                            <Form>
                                <FormControlLabel
                                    label={TranslationHelper.translate(
                                        'Send data to eToll'
                                    )}
                                    control={
                                        <Field
                                            name={'attributes.eToll'}
                                            component={CheckboxWrapper}
                                            onClick={() =>
                                                setFieldValue(
                                                    'eToll',
                                                    !values.attributes?.eToll
                                                )
                                            }
                                        />
                                    }
                                />
                                {renderAdditionalFields &&
                                    renderAdditionalFields(errors)}
                            </Form>
                        </div>
                    </>
                )}
            </Formik>
        </div>
    );
};

export default DeviceForm;
