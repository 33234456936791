import createStyles from '@material-ui/core/styles/createStyles';

import { Theme, withStyles, WithStyles } from '@material-ui/core/styles';

const styles = (theme: Theme) => {
    return createStyles({
        container: {
            flexGrow: 1,
            display: 'flex',
            overflow: 'hidden',
            height: '100%',
        },
        chart: {
            flex: '1 1 auto',
            width: 50,
        },
        placeholder: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            width: '100%',
        },
    });
};

export type TThemableProps = WithStyles<typeof styles>;

export const Themable = withStyles(styles, { withTheme: true });
