import { ListItem } from '@material-ui/core';
import React from 'react';

import { DownloadAction } from '../ButtonActions/ButtonActions';
import { useStyles } from './Themable.hooks';

interface IAttachmentItem {
    id: number;
    fileName: string;
}

interface IOwnProps {
    attachments: IAttachmentItem[];
    api: string;
}

const AttachmentsSection = ({ attachments, api }: IOwnProps) => {
    const classes = useStyles();

    const renderAttachment = (attachment: { id: number; fileName: string }) => {
        return (
            <ListItem className={classes.listItem}>
                <div className={classes.itemLabel}>{attachment.fileName}</div>
                <span className={classes.secondaryAction}>
                    <span className={classes.attachment}>
                        <DownloadAction
                            classes={classes}
                            api={`${api}${attachment.id}`}
                            name={attachment.fileName}
                        />
                    </span>
                </span>
            </ListItem>
        );
    };
    return (
        <>
            {attachments.map((attachment) => (
                <div key={attachment.id}>{renderAttachment(attachment)}</div>
            ))}
        </>
    );
};

export default AttachmentsSection;
