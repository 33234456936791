import { createStyles, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => {
    return createStyles({
        iconButton: {
            color: theme.palette.getContrastText(theme.palette.primary.main),
        },
        paper: {
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 30,
            overflowY: 'auto',
            [theme.breakpoints.down('md')]: {
                borderRadius: 0,
            },
        },
    });
});
