import { DEFAULT_VISIBILITY_TYPE } from '../../../../../../components/VisibilityForm/VisibilityForm';

export const BLANK_FORM_TASK = {
    status: -1,
    plannedOn: '',
    statusDate: '',
    employeeName: '',
    vehicleName: '',
    activityCategoryId: '',
    serviceTypeId: '',
    objectCategoryId: '',
    serviceClassId: '',
    unitCount: '',
    rfidCode: '',
    notice: '',
    externalNumber: '',
};

export const BLANK_FORM_CONTRACT_ITEM = {
    status: -1,
    startDate: '',
    stopDate: '',
    nextOccurrenceDate: '',
    assetsCount: '',
    activityCategoryId: '',
    serviceTypeId: '',
    objectCategoryId: '',
    serviceClassId: '',
    notice: '',
    externalNumber: '',
};

export const BLANK_FORM_CONTRACT = {
    name: '',
    startDate: '',
    stopDate: '',
    isActive: false,
};
export const BLANK_FORM_CLIENT = {
    name: '',
    shortName: '',
    vatin: '',
    externalNumber: '',
    notice: '',
    noDate: false,
};

export const BLANK_FORM_CLIENT_EDIT = {
    ...BLANK_FORM_CLIENT,
    id: -1,
    status: 0,
};

export const BLANK_FORM_LOCATION = {
    name: '',
    coordinate: {
        x: '',
        y: '',
    },
    address: {
        city: '',
        street: '',
        streetNumber: '',
        apartmentNo: '',
        areaName0: '',
        areaName3: '',
        zip: '',
    },
    notes: '',
    externalNo: '',
    client: '',
    visibilityType: DEFAULT_VISIBILITY_TYPE,
    groups: [],
};

export const BLANK_FORM_LOCATION_EDIT = {
    ...BLANK_FORM_LOCATION,
    id: '',
    externalId: '',
};
