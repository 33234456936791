import React from 'react';

import { FormikErrors, FormikValues } from 'formik';

import GroupsSection from '../GroupsSection';
import SelectWrapper from '../SelectWrapper';
import { IGroupItem } from '../../state/app/registers/groups';
import { IDataPermissions } from '../../state/user/types';
import { IMappedMenuItem } from '../../state/types';

export const VISIBILITY_SELF = 'self';
export const VISIBILITY_ALL = 'all';
export const VISIBILITY_GROUP = 'group';
const VISIBILITY_AUTHOR = 'author';

export const DEFAULT_VISIBILITY_TYPE = VISIBILITY_SELF;

const renderGroups = (
    values: FormikValues,
    setFieldValue: (name: string, value: any) => void,
    errors?: FormikErrors<{ groups: string }>,
    disabled?: boolean
) => {
    return (
        <GroupsSection
            selectedGroups={values.groups || []}
            handleFormChange={(_, value) => {
                setFieldValue('groups', value);
            }}
            errors={errors?.groups}
            disabled={disabled}
        />
    );
};

export const prepareGroups = (
    groups: IGroupItem[] | null,
    locationGroupsIds?: string[] | null
) => {
    if (!locationGroupsIds) {
        return [];
    }
    return locationGroupsIds
        ?.map((groupId) => {
            const foundItem = groups?.find(
                (item) => item.relationId === groupId
            );
            return foundItem
                ? {
                      id: foundItem.relationId,
                      key: foundItem.relationId,
                      name: foundItem.name,
                  }
                : null;
        })
        .filter(Boolean) as IMappedMenuItem[];
};

export const prepareGroupIds = (
    values: FormikValues,
    dataPermissions?: IDataPermissions,
    entityGroupIds?: string[]
) => {
    if (values.visibilityType === VISIBILITY_SELF) {
        return [dataPermissions?.personalGroup];
    } else if (values.visibilityType === VISIBILITY_ALL) {
        return [dataPermissions?.publicGroup];
    } else if (values.groups?.length) {
        return values.groups.map((group: IGroupItem) => group.id);
    } else {
        return entityGroupIds;
    }
};

export const prepareVisibilityType = (
    groups: IGroupItem[] | null,
    groupIds?: string[],
    dataPermissions?: IDataPermissions
) => {
    if (!dataPermissions || !groupIds) {
        return VISIBILITY_SELF;
    }
    if (groupIds.includes(dataPermissions?.personalGroup)) {
        return VISIBILITY_SELF;
    } else if (groupIds.includes(dataPermissions?.publicGroup)) {
        return VISIBILITY_ALL;
    } else if (groupIds.length) {
        return groups?.find((group) => group.relationId === groupIds[0])
            ? VISIBILITY_GROUP
            : VISIBILITY_AUTHOR;
    }
    return VISIBILITY_SELF;
};
interface IOwnProps<T> {
    setFieldValue: (name: string, value: any) => void;
    values: FormikValues;
    errors?: FormikErrors<T>;
    disabled?: boolean;
    allowedTypes?: string[];
}

const VisibilityForm = <T,>({
    setFieldValue,
    values,
    errors,
    disabled,
    allowedTypes,
}: IOwnProps<T>) => {
    const visibilityTypes = [
        { id: VISIBILITY_ALL, name: 'Visible for all' },
        { id: VISIBILITY_SELF, name: 'Visible for me only' },
        {
            id: VISIBILITY_GROUP,
            name: 'Visible for groups (choose groups)',
            render: renderGroups,
        },
        {
            id: VISIBILITY_AUTHOR,
            name: 'Visible for author',
            hidden: values.visibilityType !== VISIBILITY_AUTHOR,
        },
    ].filter((type) => !allowedTypes || allowedTypes.includes(type.id));

    return (
        <SelectWrapper
            types={visibilityTypes}
            selectValue={values.visibilityType}
            values={values}
            setFieldValue={setFieldValue}
            errors={errors}
            name="visibilityType"
            label="Visibility"
            disabled={disabled}
        />
    );
};

export default VisibilityForm;
