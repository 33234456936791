import { makeReduxDuck } from 'teedux';

interface IState {
    mode: null | 'edited';
}

const initialState: IState = {
    mode: null,
};

const duck = makeReduxDuck('ui/userSettings', initialState);

export const setUserSettingsMode = duck.defineAction<{ mode: null | 'edited' }>(
    'ENTER_MODE',
    (_, { mode }) => ({
        mode,
    })
);

export default duck.getReducer();
