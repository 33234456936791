import React, { ChangeEvent } from 'react';

import { useToolkitDispatch } from '../../../../hooks';

import {
    closeBottomGrid,
    selectBottomGrid,
    TGridTypes,
} from '../../../../state/ui/customerService/bottomGrid';
import { useBottomGrid } from '../../../../state/ui/customerService/bottomGrid/index.hooks';
import { TASKS_GRID_ID } from '../../../../state/ui/customerService/bottomGrid/tasks';
import { CONTRACT_ITEMS_GRID_ID } from '../../../../state/ui/customerService/bottomGrid/contractItems';
import { EVENTS_BY_RFID_ID } from '../../../../state/ui/customerService/bottomGrid/eventsByRfid';
import { EVENTS_GRID_ID } from '../../../../state/ui/customerService/bottomGrid/events';

import SelectField from '../../../../components/SelectField';
import ContractsGrid from './ContractItemsGrid';
import TasksGrid from './TasksGrid/TasksGrid';
import EventsGrid from './EventsGrid/EventsGrid';
import {
    IFeatureSelectedOnGridParams,
    TVectorGeometrySource,
} from '../../types';
import EventsGridByRfid from './EventsGridByRfid/EventsGridByRfid';

export const GRID_TYPES: TGridTypes[] = [
    'Tasks',
    'ContractItems',
    'Events',
    'EventsByRfid',
];

interface IOwnProps {
    onFeatureSelected: (params: IFeatureSelectedOnGridParams) => void;
    source: TVectorGeometrySource;
    selectedItems: { [key: string]: string[] };
    lastSelectedBottomItem: string;
    handleSelectLastItem: (sourceSetId: string, lastItemId: string) => void;
}
const BottomGrid = ({
    onFeatureSelected,
    source,
    selectedItems,
    lastSelectedBottomItem,
    handleSelectLastItem,
}: IOwnProps) => {
    const selectedGrid = useBottomGrid();

    const toolkitDispatch = useToolkitDispatch();

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        handleChangeGrid(e.target.value as TGridTypes);
    };

    const handleChangeGrid = (grid: TGridTypes) => {
        toolkitDispatch(selectBottomGrid(grid));
    };

    const handleClose = () => {
        toolkitDispatch(closeBottomGrid());
    };
    return (
        <>
            {selectedGrid === TASKS_GRID_ID && (
                <TasksGrid
                    handleClose={handleClose}
                    source={source}
                    onFeatureSelected={onFeatureSelected}
                    selectedItems={selectedItems[TASKS_GRID_ID]}
                    lastSelectedBottomItem={lastSelectedBottomItem}
                    handleSelectLastItem={handleSelectLastItem}
                    titleComponent={
                        <SelectField
                            name="selectGrid"
                            value="Tasks"
                            ids={GRID_TYPES}
                            handleChange={handleChange}
                        />
                    }
                />
            )}
            {selectedGrid === CONTRACT_ITEMS_GRID_ID && (
                <ContractsGrid
                    handleClose={handleClose}
                    source={source}
                    onFeatureSelected={onFeatureSelected}
                    selectedItems={selectedItems[CONTRACT_ITEMS_GRID_ID]}
                    lastSelectedBottomItem={lastSelectedBottomItem}
                    handleSelectLastItem={handleSelectLastItem}
                    titleComponent={
                        <SelectField
                            name="selectGrid"
                            value="ContractItems"
                            ids={GRID_TYPES}
                            handleChange={handleChange}
                        />
                    }
                />
            )}
            {selectedGrid === EVENTS_GRID_ID && (
                <EventsGrid
                    handleClose={handleClose}
                    source={source}
                    onFeatureSelected={onFeatureSelected}
                    selectedItems={selectedItems[EVENTS_GRID_ID]}
                    lastSelectedBottomItem={lastSelectedBottomItem}
                    handleSelectLastItem={handleSelectLastItem}
                    titleComponent={
                        <SelectField
                            name="selectGrid"
                            value="Events"
                            ids={GRID_TYPES}
                            handleChange={handleChange}
                        />
                    }
                />
            )}
            {selectedGrid === EVENTS_BY_RFID_ID && (
                <EventsGridByRfid
                    handleClose={handleClose}
                    source={source}
                    onFeatureSelected={onFeatureSelected}
                    selectedItems={selectedItems[EVENTS_BY_RFID_ID]}
                    lastSelectedBottomItem={lastSelectedBottomItem}
                    handleSelectLastItem={handleSelectLastItem}
                    titleComponent={
                        <SelectField
                            name="selectGrid"
                            value="EventsByRfid"
                            ids={GRID_TYPES}
                            handleChange={handleChange}
                        />
                    }
                />
            )}
        </>
    );
};
export default BottomGrid;
