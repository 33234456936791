import React, { useEffect, useState } from 'react';
import { Dialog, LinearProgress, Paper } from '@material-ui/core';
import PlusIcon from '@material-ui/icons/Add';
import TranslationHelper from '../../../../helpers/TranslationHelper';
import { useStyles } from '../../Themable.hooks';
import PaneHeader from '../../../../components/PaneHeader';
import { useDialog } from '../../../../state/app/alertsPage/index.hook';
import {
    activateAddAlertDialog,
    activateEditAlertDialog,
    resetAlertDialog,
} from '../../../../state/app/alertsPage';

import IconButtonWithTooltip from '../../../../components/IconButtonWithTooltip';
import AlertSettingDialog from '../../../../components/AlertSettingDialog';
import SourceSetGrid from '../../../../components/SourceSetGrid';
import SearchField from '../../../../components/common/SearchField/SearchField';
import { useAlertSettingsSourceSetModel } from '../../../../state/ui/alertSettingsSlice/index.hooks';
import { fetchAlertSettings } from '../../../../state/_actions';
import { useAppDispatch } from '../../../../hooks';
import useToggle from '../../../../hooks/useToggle';
import { useAlertSettingsSourceSet } from '../../../../pages/discovery/selectors/entities/index.hooks';

const AlertsDefinitionsGrid = () => {
    const sourceSet = useAlertSettingsSourceSet();
    const sourceSetModel = useAlertSettingsSourceSetModel();
    const dispatch = useAppDispatch();
    const classes = useStyles();

    const { isOpen, handleToggle } = useToggle();

    const dialog = useDialog();

    const [filter, setFilter] = useState(sourceSetModel?.quickFilter || '');

    useEffect(() => {
        dispatch(fetchAlertSettings());

        if (sourceSetModel) {
            handleFilterChange(sourceSetModel.quickFilter);
        } else {
            handleFilterChange('');
        }
    }, []);

    const handleFilterChange = (value: string) => {
        setFilter(value);
    };

    const handleAddAlertSetting = () => {
        if (sourceSet && sourceSet._meta.actions) {
            const action = sourceSet._meta.actions['add'];
            action && dispatch(activateAddAlertDialog());
        }
        handleToggle();
    };

    const renderLeftCustomControls = () => (
        <>
            {sourceSet && (
                <SearchField value={filter} filterChange={handleFilterChange} />
            )}
        </>
    );

    const renderRightCustomControls = () => {
        return (
            <>
                <IconButtonWithTooltip
                    title={TranslationHelper.translate('Add alert')}
                    onClick={handleAddAlertSetting}
                >
                    <PlusIcon />
                </IconButtonWithTooltip>
            </>
        );
    };

    const handleCloseDialog = (event?: object, reason?: string) => {
        if (reason === 'backdropClick') {
            return;
        }
        dispatch(resetAlertDialog());
        handleToggle();
    };

    return (
        <Paper className={classes.pane}>
            <div className={classes.pageWrapper}>
                <PaneHeader
                    title={TranslationHelper.translate('Alerts settings')}
                    renderLeftCustomControls={renderLeftCustomControls}
                    renderRightCustomControls={renderRightCustomControls}
                />
                {!sourceSet && <LinearProgress />}
                {sourceSet && (
                    <SourceSetGrid
                        sourceSet={sourceSet}
                        sourceSetModel={sourceSetModel}
                        quickFilterValue={filter}
                        actionHandlers={{
                            edit: (params) => {
                                if (params.id) {
                                    dispatch(
                                        activateEditAlertDialog({
                                            action: params.action,
                                            id: params.id,
                                        })
                                    );
                                    handleToggle();
                                }
                            },
                            delete: (params) => dispatch(fetchAlertSettings()),
                        }}
                        onRowDataChanged={(event) =>
                            event.api.sizeColumnsToFit()
                        }
                    />
                )}
            </div>
            <Dialog
                open={isOpen}
                maxWidth="lg"
                keepMounted={true}
                onClose={handleCloseDialog}
            >
                <AlertSettingDialog
                    dialog={dialog}
                    close={handleCloseDialog}
                    onSubmit={() => dispatch(fetchAlertSettings())}
                />
            </Dialog>
        </Paper>
    );
};

export default AlertsDefinitionsGrid;
