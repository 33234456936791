import { withSnackbar, WithSnackbarProps } from 'notistack';
import React, { Component, ComponentType } from 'react';
import { compose } from 'redux';
import { Connectable, TConnectableProps } from './Connectable.hoc';
import { Themable, TThemableProps } from './Themable.hoc';

import { ListItem } from '@material-ui/core';
import {
    IAttribute,
    IIcon,
    ISourceSetEntity,
} from '../../../../../../state/types';

import IconGenerator from '../../../../../../helpers/IconGenerator/IconGenerator';
import TimeFormatter from '../../../../../../helpers/TimeFormatter';
import TranslationHelper from '../../../../../../helpers/TranslationHelper';

export interface IOwnProps {
    attributes: IAttribute[];
    entity: ISourceSetEntity;
    id: string;
    cellInteractionHandler: (data: ISourceSetEntity) => void;
}

type TProps = IOwnProps &
    TThemableProps &
    TConnectableProps &
    WithSnackbarProps;

class GridListItem extends Component<TProps> {
    public getIcon = (icon?: IIcon) => {
        return icon ? IconGenerator.getDiscoveryIconAsComponent(icon) : '';
    };

    public activatePreview = () => {
        const { cellInteractionHandler, entity } = this.props;
        cellInteractionHandler(entity);
    };

    public getName = () => {
        const { attributes, entity } = this.props;

        const translate = attributes.find((el) => el.id === 'name')?.translate;
        const name = entity.name;

        return translate && name
            ? TranslationHelper.translate(name, translate)
            : name;
    };

    public render() {
        const { classes, entity, userSettings } = this.props;

        const date = entity.updatedAt || entity.startDate || '';

        return (
            <ListItem onClick={this.activatePreview}>
                <div className={classes.icon}>
                    {this.getIcon(entity._meta.icon)}
                </div>
                <div className={classes.textContainer}>
                    <div className={classes.name}>{this.getName()}</div>
                    <div className={classes.location}>{entity.location}</div>
                    <div className={classes.date}>
                        {date
                            ? TimeFormatter.dateToString(
                                  date,
                                  userSettings.dateFormat
                              )
                            : ''}
                    </div>
                </div>
            </ListItem>
        );
    }
}

export default compose(
    Themable,
    Connectable,
    withSnackbar
)(GridListItem) as ComponentType<IOwnProps>;
