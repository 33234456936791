import React, { useEffect } from 'react';
import { FormikErrors, FormikValues } from 'formik';

import Card from '@material-ui/core/Card';
import Alert from '@material-ui/lab/Alert';
import CardContent from '@material-ui/core/CardContent';

import { useStyles } from '../../Themable.hooks';
import {
    IAlertForm,
    IEventAlertCondition,
    ILayerAlertCondition,
    IParamAlertCondition,
} from '../../../../state/ui/forms';

import GroupAlertCondition from '../GroupAlertCondition/GroupAlertCondition';
import TranslationHelper from '../../../../helpers/TranslationHelper';
import {
    fetchParamsDefinitions,
    setParamsLoading,
} from '../../../../state/app/alerts';
import { IDialogProp } from '../../AlertSettingDialog';
import AlertDefinitionForm from '../AlertDefinitionForm';
import { useAppDispatch } from '../../../../hooks';
import LoadingSpinner from '../../../loadingSpinner/LoadingSpinner';
import { useParamsLoading } from '../../../../state/app/alerts/index.hooks';
import { ConditionsArray } from '../GroupAlertCondition/ConditionsArray';

interface IOwnProps {
    errors: FormikErrors<IAlertForm>;
    values: FormikValues;
    setFieldValue: (
        name: string,
        value:
            | IParamAlertCondition
            | IEventAlertCondition
            | ILayerAlertCondition
    ) => void;
    dialog: IDialogProp;
    displaySubjectFields: boolean;
}

const AlertConditions = ({
    errors,
    values,
    setFieldValue,
    dialog,
    displaySubjectFields,
}: IOwnProps) => {
    const classes = useStyles();
    const dispatch = useAppDispatch();
    const paramsLoading = useParamsLoading();

    <Alert severity="warning">
        {TranslationHelper.translate('Select object or group')}
    </Alert>;
    useEffect(() => {
        if (values.object) {
            dispatch(setParamsLoading({ loading: true }));

            dispatch(fetchParamsDefinitions(values.object.id));
        }
    }, [values.object]);

    const renderConditionsForSubjectFieldsVariant = () => {
        if (paramsLoading) {
            return (
                <Card className={classes.card}>
                    <CardContent className={classes.cardLoading}>
                        <LoadingSpinner />
                    </CardContent>
                </Card>
            );
        }
        if (!values.object && !values.groups.length) {
            return (
                <Card className={classes.card}>
                    <CardContent className={classes.cardLoading}>
                        <Alert severity="warning">
                            {TranslationHelper.translate(
                                'Select object or group'
                            )}
                        </Alert>
                    </CardContent>
                </Card>
            );
        }
        return renderConditions();
    };
    const renderConditions = () => {
        return values.condition.conditions ? (
            <GroupAlertCondition
                name={'condition'}
                type={values.condition.type}
                setFieldValue={setFieldValue}
                dialog={dialog}
            >
                <ConditionsArray
                    name={'condition'}
                    type={values.condition.type}
                    values={values.condition.conditions}
                    errors={errors?.condition}
                    setFieldValue={setFieldValue}
                    dialog={dialog}
                />
            </GroupAlertCondition>
        ) : (
            <Card className={classes.card}>
                <CardContent className={classes.card}>
                    <AlertDefinitionForm
                        name={'condition'}
                        type={values.condition.type}
                        values={values.condition}
                        errors={errors?.condition}
                        setFieldValue={setFieldValue}
                        dialog={dialog}
                    />
                </CardContent>
            </Card>
        );
    };
    return (
        <>
            {displaySubjectFields
                ? renderConditionsForSubjectFieldsVariant()
                : renderConditions()}
        </>
    );
};
export default AlertConditions;
