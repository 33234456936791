import React, { useEffect, useRef, useState } from 'react';

import { Dialog, DialogContent, useMediaQuery } from '@material-ui/core';

import { MapBrowserEvent } from 'ol';
import olMap from 'ol/Map';
import View from 'ol/View';

import { useStyles } from './Themable.hooks';
import {
    useIsMapDialogOpen,
    useMapCoords,
    useSelectedLocation,
} from '../../../../state/ui/customerService/clientsAndLocations/index.hooks';
import { useLanguage } from '../../../../state/login/index.hooks';
import {
    setMapCoords,
    toggleMapDialog,
} from '../../../../state/ui/customerService/clientsAndLocations';

import { useToolkitDispatch } from '../../../../hooks';
import TranslationHelper from '../../../../helpers/TranslationHelper';
import { MOBILE } from '../../../../constants/dictionaries/ScreenSizeConst';

import DialogTitle from '../../../../components/dialogs/DialogTitle/DialogTitle';

import {
    TVectorLayer,
    getControls,
    getInitialLayers,
    transformCoords,
} from '../../../../pages/discovery/components/DiscoveryMap/_utils';

import {
    makePoint,
    setMarker,
} from '../../../../pages/discovery/components/DiscoveryMap/_utils/features';
import { generateTileLayers } from '../../../../pages/discovery/components/DiscoveryMap/_utils/tileLayers';

const MapDialog = () => {
    const classes = useStyles();
    const language = useLanguage();
    const location = useSelectedLocation();
    const [dialogOpened, setDialogOpened] = useState(false);
    const [lastClickPosition, setLastClickPosition] = useState<{
        x: number;
        y: number;
    } | null>(null);
    const open = useIsMapDialogOpen();
    const [markerLayer, setMarkerLayer] = useState<TVectorLayer>(null);

    const isMobile = useMediaQuery(MOBILE);

    const toolkitDispatch = useToolkitDispatch();
    const handleCloseDialog = () => {
        toolkitDispatch(toggleMapDialog());
        setDialogOpened(false);
    };
    const container = useRef(null);

    const mapCoords = useMapCoords();

    useEffect(() => {
        if (open) {
            setDialogOpened(true);
        }
    }, [open]);

    useEffect(() => {
        if (dialogOpened) {
            initMap();
        }
    }, [dialogOpened]);

    useEffect(() => {
        if (lastClickPosition && markerLayer) {
            setMarker(markerLayer, lastClickPosition);
        }
    }, [lastClickPosition]);

    useEffect(() => {
        if (mapCoords) {
            setLastClickPosition({
                x: mapCoords.x,
                y: mapCoords.y,
            });
        }
    }, [mapCoords]);
    const initMap = () => {
        const tileLayer = generateTileLayers(language)[0];

        const { layers, markerLayer } = getInitialLayers(tileLayer);

        const controls = getControls(isMobile);

        const handleMapClick = (event: MapBrowserEvent<MouseEvent>) => {
            const transformedCoordinate = transformCoords(event.coordinate);

            toolkitDispatch(
                setMapCoords({
                    x: transformedCoordinate[0],
                    y: transformedCoordinate[1],
                })
            );
        };

        const getCoords = () => {
            if (mapCoords) return { x: mapCoords?.x, y: mapCoords.y };

            if (location?.coordinate)
                return { x: location?.coordinate.x, y: location.coordinate.y };
            return { x: 18.6466, y: 54.352 };
        };

        const coords = getCoords();
        setMarkerLayer(markerLayer);
        const map = new olMap({
            layers,
            target: container.current as unknown as HTMLDivElement,
            controls,
            view: new View({
                center: makePoint(Number(coords.x), Number(coords.y)),
                zoom: 15,
                minZoom: 4,
            }),
        });
        if (location?.coordinate) {
            setMarker(markerLayer, {
                x: Number(location.coordinate.x),
                y: Number(location.coordinate.y),
            });
        }
        if (mapCoords) {
            setMarker(markerLayer, {
                x: mapCoords.x,
                y: mapCoords.y,
            });
        }
        map.on('click', handleMapClick);
    };
    return (
        <Dialog
            open={open}
            maxWidth="lg"
            classes={{ container: classes.dialog }}
        >
            <div className={classes.dialog}>
                <DialogTitle
                    title={`${TranslationHelper.translate(
                        'Setting coordinates for a location'
                    )}: ${location?.name}`}
                    close={() => handleCloseDialog()}
                />
            </div>

            <DialogContent classes={{ root: classes.root }}>
                <div className={classes.container}>
                    <div className={classes.smallMap}>
                        <div ref={container} className={classes.map}></div>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default MapDialog;
