import { IAlertForm } from '../../../state/ui/forms';
import {
    IAlertRecipients,
    IGroupAlertCondition,
    TAnyAlertCondition,
} from '../../../services/alerts';
import { IAlertProvider, conditionTypes } from '../../../state/app/alerts';
import { mapStringsToDictionary } from '../../../helpers/mapArrayToDictionary';
import { restGetGroups } from '../../../services/groups';
import { restGetRegistersMonitoredObjects } from '../../../services/registers';

export const INITIAL_CONDITION = {
    type: conditionTypes.and,
    conditions: [{ type: '', definitionId: '', paramName: '', paramValue: '' }],
};
export const prepareSubjectData = async (
    objectId: number | undefined,
    groupIds: string[] | undefined
) => {
    let subject = Object.assign({});
    if (objectId) {
        const objects = await restGetRegistersMonitoredObjects();
        const object = objects.filter((obj) => Number(obj.id) === objectId)[0];
        subject.object = object;
    }
    if (groupIds) {
        const groups = await restGetGroups();
        subject.groups = groups.filter((group) => groupIds.includes(group.id));
    }
    return subject;
};

export const prepareRecipients = (
    recipients: IAlertRecipients | undefined,
    providers: IAlertProvider[],
    userLanguage: string
) => {
    const language = userLanguage || 'en';
    const initialRecipients = {
        emails: [],
        phones: [],
        providers: [],
        language,
    };
    if (!recipients) return initialRecipients;
    return {
        ...initialRecipients,
        emails: recipients.emails,
        phones: recipients.phones,
        language: recipients.language,
        providers: mapStringsToDictionary(
            recipients.providers || [],
            providers
        ),
    };
};

const prepareGroupAlertCondition = (conditionData: IGroupAlertCondition) => {
    conditionData.conditions?.forEach((condition: TAnyAlertCondition) =>
        prepareConditionData(condition)
    );
    const mappedConditions = conditionData.conditions?.map(
        (condition: TAnyAlertCondition) => {
            if (condition.type === conditionTypes.layer) {
                condition = prepareLayerAlertCondition(condition);
            }
            return condition;
        }
    );

    conditionData.conditions = mappedConditions;
    return conditionData;
};

const prepareLayerAlertCondition = (data: TAnyAlertCondition) => {
    const conditionDataMapped = { ...data } as any;
    if (conditionDataMapped.features) {
        conditionDataMapped.features = [
            {
                id: conditionDataMapped.features[0] + 'id',
                name: conditionDataMapped.features[0],
            },
        ];
    } else {
        conditionDataMapped.features = [
            {
                id: 'All',
                name: 'All',
            },
        ];
    }
    conditionDataMapped.definitionIdLayer = conditionDataMapped.definitionId;
    return conditionDataMapped;
};

export const prepareConditionData = (conditionData: TAnyAlertCondition) => {
    switch (conditionData.type) {
        case conditionTypes.and:
        case conditionTypes.or:
            return prepareGroupAlertCondition(
                conditionData as IGroupAlertCondition
            );
        case conditionTypes.layer:
            return prepareLayerAlertCondition(conditionData);
        default:
            return conditionData;
    }
};

export const initializeFormData = (userLanguage: string): IAlertForm => {
    return {
        alertSubject: '',
        objectId: -1,
        name: '',
        groups: [],
        recipients: {
            emails: [],
            phones: [],
            providers: [],
            language: userLanguage,
        },
        condition: INITIAL_CONDITION,
        emailsField: '',
        phonesField: '',
        providersField: '',
    };
};
