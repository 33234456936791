import React, { useState } from 'react';

import {
    Card,
    CardActions,
    CardContent,
    Checkbox,
    Collapse,
    FormControlLabel,
    FormGroup,
    Grid,
    IconButton,
} from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import TranslationHelper from '../../../../../../helpers/TranslationHelper';
import { IPrivilege } from '../../../../../../state/app/adminPanel';
import { IPrivilegesGroup } from '../../../../selectors';
import { IFormRole } from '../../PrivilegesList';

interface IOpenGroups {
    [key: string]: boolean;
}
interface IOwnProps {
    handleDirty: (dirt: boolean) => void;
    privilegesListGrouped: IPrivilegesGroup[];
    disableField: boolean;
    formData: IFormRole;
    setFormData: (formData: IFormRole) => void;
}

const POSSIBLE_USE_INS = {
    0: TranslationHelper.translate('shared'),
    1: 'XM',
    2: 'WebX',
};

const PrivilegesListGrouped = ({
    handleDirty,
    privilegesListGrouped,
    disableField,
    formData,
    setFormData,
}: IOwnProps) => {
    const [openGroups, setOpenGroups] = useState<IOpenGroups>({});

    const handleGroupSelectAll = (
        privilegesIds: number[],
        groupName: string
    ) => {
        const groupSelected = !formData.groupSelect[groupName];
        const privileges = {};

        handleDirty(true);

        privilegesIds.forEach((id) => (privileges[id] = groupSelected));

        const isAllSelected =
            groupSelected &&
            privilegesListGrouped
                .filter((g) => g.groupName !== groupName)
                .every((g) => formData.groupSelect[g.groupName] === true);

        setFormData({
            ...formData,
            privileges: {
                ...formData.privileges,
                ...privileges,
            },
            groupSelect: {
                ...formData.groupSelect,
                [groupName]: groupSelected,
            },
            selectAll: isAllSelected,
        });
    };

    const handleChange = (
        privilege: IPrivilege,
        privilegesArray: IPrivilege[],
        groupName: string
    ) => {
        handleDirty(true);

        const isGroupSelected =
            !formData.privileges[privilege.id] &&
            privilegesArray
                .filter((p) => p.id !== privilege.id)
                .every((p) => formData.privileges[p.id]);

        const isAllSelected =
            isGroupSelected &&
            privilegesListGrouped
                .filter((g) => g.groupName !== groupName)
                .every((g) => formData.groupSelect[g.groupName] === true);

        setFormData({
            ...formData,
            privileges: {
                ...formData.privileges,
                [privilege.id]: !formData.privileges[privilege.id],
            },
            groupSelect: {
                ...formData.groupSelect,
                [groupName]: isGroupSelected,
            },
            selectAll: isAllSelected,
        });
    };

    const handleCollapse = (key: string) => {
        setOpenGroups({
            ...openGroups,
            [key]: !openGroups[key],
        });
    };

    return (
        <>
            {privilegesListGrouped.map(
                ({ groupName, privilegesArray, privilegesIds }) => {
                    return (
                        <Grid item={true} md={12} key={groupName}>
                            <Card style={{ margin: 0 }}>
                                <CardActions
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    <div>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    disabled={disableField}
                                                    name={`groupSelect.${groupName}`}
                                                    color="primary"
                                                    checked={
                                                        !!formData.groupSelect[
                                                            groupName
                                                        ]
                                                    }
                                                    indeterminate={
                                                        !formData.groupSelect[
                                                            groupName
                                                        ] &&
                                                        privilegesArray.some(
                                                            (privilege) =>
                                                                !!formData
                                                                    .privileges[
                                                                    privilege.id
                                                                ]
                                                        )
                                                    }
                                                    onChange={() =>
                                                        handleGroupSelectAll(
                                                            privilegesIds,
                                                            groupName
                                                        )
                                                    }
                                                />
                                            }
                                            label={TranslationHelper.translate(
                                                groupName
                                            )}
                                        />
                                    </div>
                                    <IconButton
                                        style={{ marginLeft: 'auto' }}
                                        onClick={() =>
                                            handleCollapse(groupName)
                                        }
                                    >
                                        {openGroups[groupName] ? (
                                            <ExpandLess />
                                        ) : (
                                            <ExpandMore />
                                        )}
                                    </IconButton>
                                </CardActions>

                                <Collapse in={openGroups[groupName]}>
                                    <CardContent>
                                        <FormGroup row={true}>
                                            {privilegesArray &&
                                                privilegesArray.map(
                                                    (privilege) => (
                                                        <FormControlLabel
                                                            key={privilege.id}
                                                            control={
                                                                <Checkbox
                                                                    name={`privileges.${privilege.id}`}
                                                                    disabled={
                                                                        disableField
                                                                    }
                                                                    color="primary"
                                                                    checked={
                                                                        !!formData
                                                                            .privileges[
                                                                            privilege
                                                                                .id
                                                                        ]
                                                                    }
                                                                    onChange={() =>
                                                                        handleChange(
                                                                            privilege,
                                                                            privilegesArray,
                                                                            groupName
                                                                        )
                                                                    }
                                                                />
                                                            }
                                                            label={`${TranslationHelper.translate(
                                                                privilege.name
                                                            )} (${
                                                                POSSIBLE_USE_INS[
                                                                    privilege
                                                                        .useIn
                                                                ]
                                                            })`}
                                                        />
                                                    )
                                                )}
                                        </FormGroup>
                                    </CardContent>
                                </Collapse>
                            </Card>
                        </Grid>
                    );
                }
            )}
        </>
    );
};

export default PrivilegesListGrouped;
