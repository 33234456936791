import { connect } from 'react-redux';
import { activateReportSet } from '../../../../../../../../state/_actions';
import { getUserSettings } from '../../../../../../../../state/user';
import { TRootState } from '../../../../../../../../store';

const mapStateToProps = (state: TRootState) => ({
    userSettings: getUserSettings(state),
});

const mapDispatchToProps = {
    activateReportSet,
};

type TStateProps = ReturnType<typeof mapStateToProps>;
type TDispatchProps = typeof mapDispatchToProps;

export type TConnectableProps = TStateProps & TDispatchProps;

export const Connectable = connect(mapStateToProps, mapDispatchToProps);
