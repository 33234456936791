import React, { Component } from 'react';

import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

import Navigation from './Navigation';

class MaterialPageTemplate extends Component {
    render() {
        const { children } = this.props;

        return (
            <MuiPickersUtilsProvider
                utils={MomentUtils}
                // other props removed, language is set during login
            >
                <Navigation>{children}</Navigation>
            </MuiPickersUtilsProvider>
        );
    }
}

export default MaterialPageTemplate;
