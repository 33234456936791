import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: 0,
        },
        paper: {
            color: 'red',
        },
        container: {
            height: '80vh',
            width: '65vw',
        },
        dialog: {
            justifyContent: 'flex-start',
        },
        smallMap: {
            flexDirection: 'column',
            display: 'flex',
            height: '100%',
        },
        map: {
            height: '100%',
        },
        left: {
            flexGrow: 2,
            marginRight: '20px',
            display: 'flex',
            flexDirection: 'column',
            width: 0,
        },
    })
);
