import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => {
    return createStyles({
        cell: {
            display: 'flex',
            alignItems: 'center',
        },
        icon: {
            color: theme.palette.primary.main,
            marginRight: theme.spacing(1),
            cursor: 'pointer',
        },
        cellValue: {
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
        },
        actions: {
            minWidth: '100px',
        },
    });
});
