import { useSelector } from 'react-redux';
import {
    getRolesData,
    getUserPaneIsVisible,
    getUsersAsSourceSet,
    isAdminInEditMode,
} from '.';

export const useAdminEditMode = () => useSelector(isAdminInEditMode);
export const useUserPaneIsVisible = () => useSelector(getUserPaneIsVisible);
export const useUsersSourceSet = () => useSelector(getUsersAsSourceSet);
export const useRolesData = () => useSelector(getRolesData);
