import { useSelector } from 'react-redux';

import {
    getDateFormat,
    getShortDateFormat,
    getTimeZone,
    getUserDataPermissions,
    getUserSettings,
} from './index';

export const useUserSettings = () => useSelector(getUserSettings);

export const useDateFormat = () => useSelector(getDateFormat);

export const useShortDateFormat = () => useSelector(getShortDateFormat);

export const useUserTimeZone = () => useSelector(getTimeZone);

export const useUserDataPermissions = () => useSelector(getUserDataPermissions);
