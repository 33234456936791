import React, { Component } from 'react';
import { IEmployee } from '../../../state/app/registers/employees';

import Translator from '../../../helpers/TranslationHelper';
import EmployeeRow from './EmployeeRow';

interface IProps {
    employees?: IEmployee[];
    onEditEmployee: (employee: IEmployee) => void;
    onAssignRFID: (employee: IEmployee) => void;
}

const COLSPAN = 7;

class EmployeesTable extends Component<IProps> {
    public renderTableBody() {
        const { employees } = this.props;
        let items = null;
        if (employees) {
            if (employees.length > 0) {
                items = employees.map((employee, index) => {
                    return (
                        <EmployeeRow
                            employee={employee}
                            key={index}
                            onAssign={this.props.onAssignRFID}
                            onEdit={this.props.onEditEmployee}
                        />
                    );
                });
            } else {
                items = (
                    <tr>
                        <td colSpan={COLSPAN} className="table--empty-state">
                            {Translator.translate(
                                'There are no employees to display'
                            )}
                        </td>
                    </tr>
                );
            }
        } else {
            items = (
                <tr>
                    <td colSpan={COLSPAN} className="table--empty-state">
                        {Translator.translate('Loading...')}
                    </td>
                </tr>
            );
        }
        return items;
    }

    public render() {
        return (
            <table className="table table__hoverable">
                <thead>
                    <tr>
                        <th>{Translator.translate('Name')}</th>
                        <th>{Translator.translate('Group')}</th>
                        <th>{Translator.translate('Employee_Full_Name')}</th>
                        <th>{Translator.translate('RFID cards')}</th>
                        <th>{Translator.translate('Employee_Address')}</th>
                        <th>{Translator.translate('Employee_Contact')}</th>
                        <th>{Translator.translate('Actions')}</th>
                    </tr>
                </thead>

                <tbody>{this.renderTableBody()}</tbody>
            </table>
        );
    }
}

export default EmployeesTable;
