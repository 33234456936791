import { useSelector } from 'react-redux';
import {
    getCurrentPage,
    getReports,
    getReportsMode,
    reportsPaneVisible,
    getReportsDefinitions,
    getTab,
    getSchedules,
    getSelectedSchedule,
    getReportDefinitionsStatus,
    getLocationsAsSourceSet,
    getDialogRfidFilter,
    getLocationsStatus,
} from '.';

export const useMode = () => useSelector(getReportsMode);
export const useReportsPaneVisible = () => useSelector(reportsPaneVisible);
export const useTab = () => useSelector(getTab);
export const useCurrentPage = () => useSelector(getCurrentPage);
export const useReportDefinitionsStatus = () =>
    useSelector(getReportDefinitionsStatus);

export const useReports = () => useSelector(getReports);
export const useReportSchedules = () => useSelector(getSchedules);
export const useReportsDefinitions = () => useSelector(getReportsDefinitions);
export const useSchedule = () => useSelector(getSelectedSchedule);
export const useLocations = () => useSelector(getLocationsAsSourceSet);
export const useDialogRfidFilter = () => useSelector(getDialogRfidFilter);
export const useLocationsStatus = () => useSelector(getLocationsStatus);
