import React from 'react';

import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import { IconButton, Tooltip } from '@material-ui/core';
import { Warning } from '@material-ui/icons';
import orange from '@material-ui/core/colors/orange';
import moment from 'moment';

import TranslationHelper from '../../../../../../../../helpers/TranslationHelper';

import { IReportSchedule } from '../../../../../../../../state/ui/reportsSlice';

import { SATURATION } from '../../../../../../../../constants/dictionaries/ColorConst';
interface IOwnProps {
    data: IReportSchedule;
}
const StatusIcon = ({ data }: IOwnProps) => {
    const shouldRenderIcon = data;

    if (!shouldRenderIcon) {
        return null;
    }

    const { repetition, subjects, template, type } = data;

    const isActive = moment(repetition.endBoundaryDate).isAfter();

    const displayWarning = !subjects.length || !template || !type;

    const renderWarningIcon = () => (
        <Tooltip
            title={TranslationHelper.translate(
                'Schedule is missing type, template or subjects'
            )}
        >
            <IconButton size="small">
                <Warning style={{ color: orange[SATURATION] }} />
            </IconButton>
        </Tooltip>
    );
    const renderActiveStatusIcon = () => (
        <Tooltip
            title={TranslationHelper.translate(
                isActive ? 'active' : 'inactive'
            )}
        >
            {isActive ? (
                <CheckIcon color="primary" />
            ) : (
                <CloseIcon color="error" />
            )}
        </Tooltip>
    );

    return displayWarning ? renderWarningIcon() : renderActiveStatusIcon();
};

export default StatusIcon;
