import { connect } from 'react-redux';

import {
    getDiscoveryUIPreviewPane,
    getPreviewCreatorLevel,
} from '../../selectors';

import { closePreview } from '../../../../state/_actions';
import { TRootState } from '../../../../store';
import { getFetching } from '../../selectors/feching';

const mapStateToProps = (state: TRootState) => ({
    preview: getDiscoveryUIPreviewPane(state),
    creatorLevel: getPreviewCreatorLevel(state),
    fetching: getFetching(state),
});

const mapDispatchToProps = {
    closePreview,
};

type TStateProps = ReturnType<typeof mapStateToProps>;
type TDispatchProps = typeof mapDispatchToProps;

export type TConnectableProps = TStateProps & TDispatchProps;

export const Connectable = connect(mapStateToProps, mapDispatchToProps);
