import { Paper } from '@material-ui/core';
import React, { Component, ComponentType } from 'react';
import { compose } from 'redux';
import TranslationHelper from '../../helpers/TranslationHelper';
import { Themable, TThemableProps } from './Themable.hoc';

interface IOwnProps {
    message: string;
    translationContext?: string;
    type: 'info' | 'warning' | 'error';
}

type TProps = IOwnProps & TThemableProps;

class AlertCard extends Component<TProps> {
    public render() {
        const { message, translationContext, type, classes } = this.props;

        return (
            <Paper
                className={[classes[type + 'Card'], classes.alertCard].join(
                    ' '
                )}
            >
                {TranslationHelper.translate(message, translationContext)}
            </Paper>
        );
    }
}

export default compose(Themable)(AlertCard) as ComponentType<IOwnProps>;
