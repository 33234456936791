import 'array-flat-polyfill';
import React, { ComponentType } from 'react';
import { render } from 'react-dom';

import './animation.css';

import App from './App';

import fetchConfig from './helpers/fetchConfig';

interface IMainComponentProps {
    themeName: string;
}

const renderApp = (
    MainComponent: ComponentType<IMainComponentProps>,
    props: IMainComponentProps
) => {
    render(<MainComponent {...props} />, document.getElementById('xtrackweb'));
};

fetchConfig().then(({ title, themeName }) => {
    const props = { themeName };
    document.title = title;
    renderApp(App, props);
});

// Webpack Hot Module Replacement API
if (module.hot) {
    module.hot.accept();
}
