import React, { useState } from 'react';

import { Field, FormikErrors, FormikValues } from 'formik';

import { useSnackbar } from 'notistack';

import { Map as MapIcon } from '@material-ui/icons';
import LanguageIcon from '@material-ui/icons/Language';
import { CircularProgress } from '@material-ui/core';

import {
    setMapCoords,
    toggleMapDialog,
} from '../../../../../../../state/ui/customerService/clientsAndLocations';
import { IServiceLocation } from '../../../../../../../models/customerService';
import { fetchLocationAddress } from '../../../../../../../state/app/locations';

import { useStyles } from '../../../../../Themable.hooks';
import TranslationHelper from '../../../../../../../helpers/TranslationHelper';
import { useAppDispatch, useToolkitDispatch } from '../../../../../../../hooks';

import NumberFieldWrapper from '../../../../../../../components/common/formikWrappers/NumberFieldWrapper';
import IconButtonWithTooltip from '../../../../../../../components/IconButtonWithTooltip';

interface IOwnProps {
    values: FormikValues;
    errors?: FormikErrors<IServiceLocation>;
    readOnly: boolean;
}
export const CoordsSection = ({ values, errors, readOnly }: IOwnProps) => {
    const classes = useStyles();

    const [synchronizingWithMap, setSynchronizingWithMap] =
        useState<boolean>(false);

    const dispatch = useAppDispatch();
    const toolkitDispatch = useToolkitDispatch();

    const { enqueueSnackbar } = useSnackbar();

    const handleSynchronizationClick = () => {
        setSynchronizingWithMap(true);

        const getAddress = () => {
            let address = '';
            if (values.address.city) {
                address += values.address.city;
            }
            if (values.address.street) {
                address += `, ${values.address.street}`;
            }
            if (values.address.streetNumber) {
                address += `, ${values.address.streetNumber}`;
            }
            return address;
        };
        const address = getAddress();

        dispatch(
            fetchLocationAddress(
                address,
                (data) => {
                    setSynchronizingWithMap(false);
                    toolkitDispatch(setMapCoords({ x: data.x, y: data.y }));
                },
                () => {
                    setSynchronizingWithMap(false);
                    enqueueSnackbar(
                        TranslationHelper.translate(
                            'Failed to find location, try again'
                        ),
                        {
                            variant: 'error',
                        }
                    );
                }
            )
        );
    };

    return (
        <div className={classes.inlineContainer}>
            <Field
                error={!!errors?.coordinate?.x}
                helperText={errors?.coordinate?.x}
                name={'coordinate.x'}
                label={TranslationHelper.translate('Lon.')}
                required={true}
                inputProps={{
                    min: -179,
                    max: 179,
                    step: 0.000001,
                }}
                disabled={readOnly}
                component={NumberFieldWrapper}
                value={values.coordinate.x}
            />

            <Field
                className={classes.field}
                error={!!errors?.coordinate?.y}
                helperText={errors?.coordinate?.y}
                name={'coordinate.y'}
                label={TranslationHelper.translate('Lat.')}
                required={true}
                inputProps={{
                    min: -89,
                    max: 89,
                    step: 0.000001,
                }}
                disabled={readOnly}
                component={NumberFieldWrapper}
                value={values.coordinate.y}
            />
            {!readOnly && (
                <>
                    <IconButtonWithTooltip
                        title={TranslationHelper.translate('Geocode address')}
                        aria-label={TranslationHelper.translate(
                            'Geocode address'
                        )}
                        onClick={() => handleSynchronizationClick()}
                        defaultColor
                    >
                        {synchronizingWithMap ? (
                            <CircularProgress size={20} />
                        ) : (
                            <LanguageIcon />
                        )}
                    </IconButtonWithTooltip>
                    <IconButtonWithTooltip
                        title={TranslationHelper.translate('Select on map')}
                        aria-label={TranslationHelper.translate(
                            'Select on map'
                        )}
                        onClick={() => {
                            if (values.coordinate.x && values.coordinate.y) {
                                toolkitDispatch(
                                    setMapCoords({
                                        x: Number(values.coordinate.x),
                                        y: Number(values.coordinate.y),
                                    })
                                );
                            }
                            toolkitDispatch(toggleMapDialog());
                        }}
                        defaultColor
                    >
                        <MapIcon />
                    </IconButtonWithTooltip>
                </>
            )}
        </div>
    );
};
