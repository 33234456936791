import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { DEFAULT_PRIMARY_COLOR } from '../../../constants/ColorConst';

export const useStyles = makeStyles((theme: Theme) => {
    return createStyles({
        tag: {
            maxWidth: '250px',
            backgroundColor: DEFAULT_PRIMARY_COLOR,
            '& .MuiChip-label': {
                color: '#fff',
            },
            '& .MuiChip-deleteIcon': {
                color: 'white',
            },
        },
        root: {
            maxHeight: 150,
            padding: '0 2px',
            overflow: 'auto',
        },
    });
});
