import { createStyles, Theme, WithStyles } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme: Theme) => {
    return createStyles({
        tooltip: {
            '&$open': {
                opacity: 1,
                background: 'black',
            },
        },
        open: {
            // skip
        },
        nested: {
            paddingLeft: theme.spacing(4),
        },
        listItemHeading: {
            paddingLeft: theme.spacing(2),
            paddingBottom: 0,
            paddingTop: 0,
            color: theme.palette.primary.contrastText,
            backgroundColor: theme.palette.grey[200],
        },
        heading: {
            fontSize: theme.typography.subtitle1.fontSize,
            color: theme.palette.primary.dark,
        },
        listItem: {
            fontSize: theme.typography.caption.fontSize,
            justifyContent: 'space-between',
            borderTop: '1px solid ' + theme.palette.divider,
            color: theme.palette.primary.dark,
        },
        itemLabel: {
            fontSize: theme.typography.caption.fontSize,
            paddingRight: theme.spacing(2),
            fontWeight: 'bold',
            color: theme.palette.common.black,
            wordWrap: 'break-word',
            width: '50%',
        },
        list: {
            marginTop: theme.spacing(),
            // borderTop: '1px solid #eee',
            // borderColorTop: theme.palette.secondary.dark,
            // borderBottom: '1px solid #ccc',
            // backgroundColor: theme.palette.secondary.light
        },
        tooltipContentWrapper: {
            position: 'relative',
        },
        tooltipProgressWrapper: {
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        circularProgress: {
            color: 'white',
        },
        tooltipImage: {
            width: 300,
            height: 300,
            position: 'relative',
            zIndex: 10,
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center center',
        },
        iconButton: {
            height: 20,
            width: 20,
            padding: 0,
        },
        iconButtonIcon: {
            height: 17,
            width: 17,
        },
        downloadLink: {
            display: 'inline-block',
            width: 100,
            cursor: 'pointer',
            textDecoration: 'underline',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            justifySelf: 'flex-start',
        },
        attachment: {
            display: 'flex',
            width: '50%',
            justifyContent: 'flex-end',
            alignItems: 'center',
        },
        itemValue: {
            width: '50%',
            wordWrap: 'break-word',
            textAlign: 'right',
        },
    });
};

export type TThemableProps = WithStyles<typeof styles>;

export const Themable = withStyles(styles, { withTheme: true });
