import React, { useState, useEffect, useReducer } from 'react';

import { useSnackbar } from 'notistack';

import { Dialog, DialogContent } from '@material-ui/core';

import { useStyles } from './Themable.hooks';

import { IGroup } from '../../../../../../../../state/app/collections/dataTypes';
import {
    clearEmptyAssignments,
    fetchGroupAssignments,
    patchGroupAssignments,
    IGroupAssignment,
    IAssignmentsAccess,
} from '../../../../../../../../state/ui/groupsSlice';
import {
    useGroupAssignments,
    useGroupEmptyAssignments,
} from '../../../../../../../../state/ui/groupsSlice/index.hooks';

import {
    useAppDispatch,
    useToolkitDispatch,
} from '../../../../../../../../hooks';

import TranslationHelper from '../../../../../../../../helpers/TranslationHelper';
import { errorMessageHandler } from '../../../../../../../../helpers/errorMessageHandler';
import { sortAlphabetically } from '../../../../../../../../helpers/comparators';

import { filterObjects } from './_utils/filterObjects';
import { prepareGroupAssignmentsData } from './_utils/prepareData';

import {
    not,
    union,
    findIndexById,
} from '../../../../../../../../components/TransferList/utils/filterObjects';
import DialogTitle from '../../../../../../../../components/dialogs/DialogTitle';
import LoadingSpinner from '../../../../../../../../components/loadingSpinner/LoadingSpinner';
import TransferList, {
    IFilterInitialState,
    IListObjectInitialState,
} from '../../../../../../../../components/TransferList/TransferList';
import LeftList from './components/LeftList';
import RightList from './components/RightList';
import { useUserDataPermissions } from '../../../../../../../../state/user/index.hooks';
import { hasAccessToLocations } from './_utils/access';

interface IOwnProps {
    isOpen: boolean;
    groupData: IGroup;
    closeDialog: () => void;
}

const AssignToGroupDialog = ({ isOpen, groupData, closeDialog }: IOwnProps) => {
    const classes = useStyles();

    const dispatch = useAppDispatch();
    const toolkitDispatch = useToolkitDispatch();
    const dataPermissions = useUserDataPermissions();

    const assignmentsAccess: IAssignmentsAccess = {
        registers: true,
        locations: hasAccessToLocations(groupData.id, dataPermissions),
    };

    const { enqueueSnackbar } = useSnackbar();

    const emptyAssignments = useGroupEmptyAssignments();

    const assignments = useGroupAssignments();

    const [added, setAdded] = useState<IGroupAssignment[]>([]);
    const [removed, setRemoved] = useState<IGroupAssignment[]>([]);
    const [loading, setLoading] = useState(false);
    const [checked, setChecked] = useState<IGroupAssignment[]>([]);

    const filterInitialState: IFilterInitialState = {
        typeUnassigned: 'ALL',
        nameUnassigned: '',
        typeAssigned: 'ALL',
        nameAssigned: '*',
    };

    const listObjectsInitialState: IListObjectInitialState<IGroupAssignment> = {
        unassignedObjects: emptyAssignments,
        assignedObjects: assignments,
        filteredAssignedObjects: assignments,
        originalAssignedObjects: [],
    };

    const [filter, setFilter] = useReducer(
        (
            curVals: IFilterInitialState,
            newVals: Partial<IFilterInitialState>
        ) => ({ ...curVals, ...newVals }),
        filterInitialState
    );

    const [objectLists, setObjectLists] = useReducer(
        (
            curVals: IListObjectInitialState<IGroupAssignment>,
            newVals: Partial<IListObjectInitialState<IGroupAssignment>>
        ) => ({
            ...curVals,
            ...newVals,
        }),
        listObjectsInitialState
    );

    useEffect(() => {
        setObjectLists({
            unassignedObjects: filterObjects(
                handleAddedAndRemoved(emptyAssignments),
                filter.typeUnassigned,
                filter.nameUnassigned
            ),
        });
    }, [emptyAssignments]);

    useEffect(() => {
        setLoading(true);
        toolkitDispatch(
            fetchGroupAssignments({
                id: groupData.id,
                access: assignmentsAccess,
            })
        )
            .unwrap()
            .then((data) => {
                setObjectLists({ originalAssignedObjects: data });
                setLoading(false);
            })
            .catch((error) => {
                const message = errorMessageHandler(error.status)();
                setLoading(false);
                handleCloseDialog();
                showNotification(false, message);
            });
    }, [groupData.id]);

    useEffect(() => {
        setObjectLists({
            filteredAssignedObjects: assignments,
            assignedObjects: assignments,
        });
    }, [assignments]);

    const handleToggle = (value: IGroupAssignment) => () => {
        const currentIndex = findIndexById(checked, value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        setChecked(newChecked);
    };

    const handleCloseDialog = (event?: object, reason?: string) => {
        if (reason === 'backdropClick') {
            return;
        }
        dispatch(clearEmptyAssignments());

        closeDialog();
    };

    const handleAddedAndRemoved = (arr: IGroupAssignment[]) => {
        const removeAdded = not(arr, added);
        return union(
            removeAdded.sort((a, b) => sortAlphabetically(a.name, b.name)),
            removed
        );
    };

    const showNotification = (success: boolean, message: string) => {
        enqueueSnackbar(message, {
            variant: success ? 'success' : 'error',
        });
    };

    const handleSubmit = () => {
        setLoading(true);
        toolkitDispatch(
            patchGroupAssignments({
                id: groupData.id,
                data: {
                    added: prepareGroupAssignmentsData(added),
                    removed: prepareGroupAssignmentsData(removed),
                },
                access: assignmentsAccess,
            })
        )
            .unwrap()
            .then(() => {
                showNotification(
                    true,
                    TranslationHelper.translate('Changes have been saved')
                );
                setFilter({ nameUnassigned: '' });
                toolkitDispatch(
                    fetchGroupAssignments({
                        id: groupData.id,
                        access: assignmentsAccess,
                    })
                )
                    .unwrap()
                    .then((data) => {
                        setObjectLists({ originalAssignedObjects: data });
                        setLoading(false);
                        handleCloseDialog();
                    })
                    .catch((error) => {
                        const message = errorMessageHandler(error.status)();
                        showNotification(false, message);
                        setLoading(false);
                        handleCloseDialog();
                    });
            })
            .catch((error) => {
                const message = errorMessageHandler(error.status)();
                showNotification(false, message);
                setLoading(false);
                handleCloseDialog();
            });
    };

    const leftList = (
        <LeftList
            groupId={groupData.id}
            objects={objectLists}
            checked={checked}
            setChecked={setChecked}
            filter={filter}
            setFilter={setFilter}
            handleToggle={handleToggle}
        />
    );

    const rightList = (
        <RightList
            groupId={groupData.id}
            objects={objectLists}
            setObjects={setObjectLists}
            filter={filter}
            setFilter={setFilter}
            checked={checked}
            setChecked={setChecked}
            handleToggle={handleToggle}
        />
    );

    return (
        <Dialog
            open={isOpen}
            keepMounted={true}
            onClose={handleCloseDialog}
            maxWidth={'lg'}
            classes={{ paper: classes.paper }}
        >
            <DialogTitle
                title={`${TranslationHelper.translate(
                    'Assigning objects to group'
                )}: "${groupData.name}"`}
                close={handleCloseDialog}
                save={handleSubmit}
                loading={loading}
            ></DialogTitle>
            <DialogContent>
                <div className={classes.wrapper}>
                    {loading ? (
                        <LoadingSpinner
                            size="50"
                            top="0"
                            left="0"
                            right="0"
                            bottom="0"
                        />
                    ) : (
                        <TransferList
                            objectLists={objectLists}
                            setObjectLists={setObjectLists}
                            added={added}
                            setAdded={setAdded}
                            removed={removed}
                            setRemoved={setRemoved}
                            filter={filter}
                            customComponents={{ leftList, rightList }}
                            checked={checked}
                            setChecked={setChecked}
                            filterObjects={filterObjects}
                        />
                    )}
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default AssignToGroupDialog;
