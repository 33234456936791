import React from 'react';

import { useStyles } from '../../Themable.hooks';

interface IOwnProps {
    apiDownload: string;
    name?: string;
    download?: string;
    handleOnLoad: () => void;
    handleError: () => void;
    url: string;
    paramName: string;
}
export const Image = ({
    apiDownload,
    name,
    download,
    url,
    handleOnLoad,
    handleError,
    paramName,
}: IOwnProps) => {
    const classes = useStyles();

    return (
        <a href={apiDownload} download={download}>
            <img
                key={Date.now() + paramName}
                className={classes.validImage}
                src={url}
                title={name}
                onLoad={handleOnLoad}
                onError={handleError}
            />
        </a>
    );
};
