import React from 'react';
import { useStyles } from './Themable.hoc';

import { Paper } from '@material-ui/core';

import CollapseItem from '../../../../components/collapseItem';
import PaneHeader from '../../../../components/PaneHeader';
import TranslationHelper from '../../../../helpers/TranslationHelper';
import ChangePassword from './components/ChangePassword';
import UserData from './components/UserData';

const UserSettings = () => {
    const classes = useStyles();

    return (
        <div className={classes.pageWrapper}>
            <Paper className={classes.pane}>
                <div className={classes.wrapper}>
                    <PaneHeader
                        title={TranslationHelper.translate('Account settings')}
                    />
                    <div className={classes.content}>
                        <UserData />
                    </div>
                    <CollapseItem
                        title={TranslationHelper.translate('Change password')}
                        content={<ChangePassword collapseClose={() => null} />}
                    />
                </div>
            </Paper>
        </div>
    );
};

export default UserSettings;
