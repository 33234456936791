export function mapStringsToDictionary(
    stringsArray: string[],
    dictionaryArray: { id: string; name: string }[]
) {
    return stringsArray.reduce(
        (result: { id: string; name: string }[], string) => {
            const dictionaryItem = dictionaryArray.find(
                (item) => item.id === string
            );
            if (dictionaryItem) {
                result.push({
                    id: dictionaryItem.id,
                    name: dictionaryItem.name,
                });
            }
            return result;
        },
        []
    );
}
