import { Component } from 'react';
import { node, string } from 'prop-types';

import '../../../sass/default/style.scss';

import addStyle from '../../helpers/addStyle';

const setStyling = (themeName) => {
    if (['wiss', 'hiab', 'kingspan', 'borim'].includes(themeName)) {
        addStyle(themeName, `./css/${themeName}.css`);
    }
};

class Theme extends Component {
    render() {
        const { themeName, children } = this.props;

        setStyling(themeName);

        return children;
    }
}

Theme.propTypes = {
    children: node.isRequired,
    themeName: string.isRequired,
};

export default Theme;
