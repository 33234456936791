import React, { Component, MouseEvent } from 'react';

import classnames from 'classnames';

interface IProps {
    title: string;
    isActive?: boolean;
    isCloseable?: boolean;
    onClose?: (component: Component) => void;
    className?: string;
}

class Label extends Component<IProps> {
    public render() {
        const { isActive, isCloseable, className, title, ...other } =
            this.props;
        const classes = classnames(
            {
                label: true,
                label__active: isActive,
                label__closeable: isCloseable,
            },
            className
        );

        return (
            <span {...other} title={title} className={classes}>
                {title}
                {this.renderCloseButton()}
            </span>
        );
    }

    private handleClose = (e: MouseEvent<HTMLButtonElement>) => {
        const { onClose } = this.props;

        e.preventDefault();

        if (onClose) {
            onClose(this);
        }
    };

    private renderCloseButton() {
        const { isCloseable = null } = this.props;
        return (
            isCloseable && (
                <button
                    className="label--close-button"
                    onClick={this.handleClose}
                >
                    x
                </button>
            )
        );
    }
}

export default Label;
