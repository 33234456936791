import React, { Component, ComponentType } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { Themable, TThemableProps } from './Themable.hoc';

import TranslationHelper from '../../helpers/TranslationHelper';

type TProps = TThemableProps;

class Brand extends Component<TProps> {
    public render() {
        const { classes } = this.props;
        return (
            <>
                <span>{TranslationHelper.translate('Powered by')}</span>
                <a
                    href="https://www.xtrack.com"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <img
                        className={classes.image}
                        src="/images/xtrack_logo.svg"
                        alt="XTrack logo"
                    />
                </a>
            </>
        );
    }
}

export default compose(Themable, connect())(Brand) as ComponentType<{}>;
