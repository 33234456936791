import React, { FunctionComponent, useCallback } from 'react';
import { compose } from 'redux';

import { Hidden, IconButton } from '@material-ui/core';
import { default as SearchIcon } from '@material-ui/icons/Search';

import { Connectable, TConnectableProps } from './Connectable.hoc';
import { useStyles } from './Themable.hoc';

import SearchBar from '../../pages/discovery/components/SearchBar';

type TProps = TConnectableProps;

const MobileSearch: FunctionComponent<TProps> = (props) => {
    const classes = useStyles();
    const {
        resetSearcher,
        toggleSearchResults,
        toggleMobileSearch,
        isMobileSearchOpen,
    } = props;

    const closeSearch = useCallback(() => {
        toggleMobileSearch(false);
        toggleSearchResults(false);
        resetSearcher();
    }, [toggleMobileSearch, toggleSearchResults, resetSearcher]);

    const openSearch = useCallback(() => {
        toggleMobileSearch(true);
    }, [toggleMobileSearch]);

    return (
        <Hidden mdUp={true}>
            {!isMobileSearchOpen ? (
                <IconButton
                    color="inherit"
                    aria-label="search object"
                    className={classes.iconButton}
                    onClick={openSearch}
                >
                    <SearchIcon />
                </IconButton>
            ) : (
                <SearchBar isMobile={true} closeSearch={closeSearch} />
            )}
        </Hidden>
    );
};

export default compose(Connectable)(MobileSearch);
