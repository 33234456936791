import React, { Component } from 'react';

import MaterialPageTemplate from './pageTemplates/MaterialPageTemplate';

class PageTemplate extends Component {
    render() {
        const { children } = this.props;

        return <MaterialPageTemplate>{children}</MaterialPageTemplate>;
    }
}

export default PageTemplate;
