import React from 'react';

import Moment from 'moment-timezone';

import { useUserSettings } from '../../../state/user/index.hooks';

import TranslationHelper from '../../../helpers/TranslationHelper';

import EventsTable from './EventsTable';

export interface IDataItem {
    date: string;
    location?: string;
    data: { fillLevel?: string; batteryLevel?: string };
}

interface IOwnProps {
    data?: IDataItem[];
}

const XMonitorArchiveTable = ({ data }: IOwnProps) => {
    const userSettings = useUserSettings();

    const dataStructure = [
        {
            header: TranslationHelper.translate('Date'),
            key: 'date',
            width: '200px',
        },
        {
            header: TranslationHelper.translate('Location'),
            key: 'location',
        },
        { header: TranslationHelper.translate('Level'), key: 'fillLevel' },
        {
            header: TranslationHelper.translate('Battery'),
            key: 'batteryLevel',
        },
    ];

    const preparedData =
        data &&
        data.map(
            ({
                date,
                location = '-',
                data: { fillLevel = '-', batteryLevel = '-' },
            }) => ({
                date: Moment(date).format(userSettings.dateFormat),
                location,
                fillLevel,
                batteryLevel,
            })
        );

    return (
        <EventsTable
            id="xMonitorArchive"
            data={preparedData}
            structure={dataStructure}
        />
    );
};

export default XMonitorArchiveTable;
