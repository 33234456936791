import React, { FormEvent } from 'react';

import Button from '@material-ui/core/Button';
import { MenuItem, TextField, useMediaQuery } from '@material-ui/core';

import TranslationHelper from '../../../helpers/TranslationHelper';
import useStateReducer from '../../../hooks/useStateReducer';
import { sortAlphabetically } from '../../../helpers/comparators';

import { useStyles } from '../Themable.hooks';

import {
    useCustomers,
    useLanguage,
    useLanguages,
} from '../../../state/login/index.hooks';

import { MOBILE } from '../../../constants/dictionaries/ScreenSizeConst';
import { PasswordResetLink } from '../../PasswordResetPage/PasswordResetPage';
import { LoginPageLogo } from './LoginPageLogo';

import LoginFormFooter from '../../../components/Authentication/LoginFormFooter';

interface IOwnProps {
    handleLoginUser: (
        event: FormEvent,
        formValues: {
            login: string;
            password: string;
            customer: string;
            language: string;
        }
    ) => void;
    errors: {
        loginError: boolean;
        emptyLoginError: boolean;
        emptyPasswordError: boolean;
    };
}

interface IInitialState {
    login: string;
    password: string;
    customer: string | undefined;
    language: string | undefined;
}

export const LoginPageForm = ({ handleLoginUser, errors }: IOwnProps) => {
    const classes = useStyles();
    const languages = useLanguages();
    const customers = useCustomers();
    const languageFromStore = useLanguage();

    const isMobile = useMediaQuery(MOBILE);

    const initialState: IInitialState = {
        login: '',
        password: '',
        customer:
            customers && customers.length > 0 ? customers[0].id : undefined,
        language:
            languages && languages.includes(languageFromStore)
                ? languageFromStore
                : 'en',
    };
    const { values, handleChangeValues } = useStateReducer(initialState);

    const { loginError, emptyLoginError, emptyPasswordError } = errors;

    const renderLanguages = () => {
        return languages
            ?.map((lang) => ({
                id: lang,
                name: TranslationHelper.translate(lang, 'lang'),
            }))
            .sort((a, b) => sortAlphabetically(a.name, b.name))
            .map((lang) => (
                <MenuItem key={lang.id} value={lang.id}>
                    {lang.name}
                </MenuItem>
            ));
    };

    const renderCustomers = () => {
        return customers?.map((customer) => (
            <MenuItem key={customer.id} value={customer.id}>
                {customer.name}
            </MenuItem>
        ));
    };

    const handleSubmit = (event: FormEvent) => {
        handleLoginUser(event, values);
    };
    return (
        <form className={classes.loginForm}>
            <LoginPageLogo />

            <div className={classes.formField}>
                <TextField
                    id="login-id"
                    label={TranslationHelper.translate('Login')}
                    name="login"
                    type="text"
                    autoFocus={true}
                    fullWidth={true}
                    margin="dense"
                    variant="outlined"
                    onChange={(e) =>
                        handleChangeValues('login', e.target.value)
                    }
                    error={loginError || emptyLoginError}
                    helperText={
                        emptyLoginError &&
                        TranslationHelper.translate(
                            'Login field can not be empty'
                        )
                    }
                />
            </div>

            <div className={classes.formField}>
                <TextField
                    id="password-id"
                    label={TranslationHelper.translate('Password')}
                    name="password"
                    type="password"
                    fullWidth={true}
                    margin="dense"
                    variant="outlined"
                    onChange={(e) =>
                        handleChangeValues('password', e.target.value)
                    }
                    error={loginError || emptyPasswordError}
                    helperText={
                        emptyPasswordError &&
                        TranslationHelper.translate(
                            'Password field can not be empty'
                        )
                    }
                />
            </div>

            <div className={classes.errorText}>
                {loginError &&
                    TranslationHelper.translate(
                        'The username or password you have entered is invalid'
                    )}
            </div>

            {(!customers || customers.length !== 1) && (
                <div className={classes.formField}>
                    <TextField
                        id="customers-id"
                        className={classes.search}
                        label={TranslationHelper.translate('Data source')}
                        name="selectedCustomer"
                        select={true}
                        fullWidth={isMobile}
                        variant="outlined"
                        margin="dense"
                        value={values.customer}
                        onChange={(e) =>
                            handleChangeValues('customer', e.target.value)
                        }
                    >
                        {renderCustomers()}
                    </TextField>
                </div>
            )}

            <div className={classes.formField}>
                <TextField
                    id="language-id"
                    className={classes.search}
                    label={TranslationHelper.translate('Language')}
                    name="selectedLanguage"
                    select={true}
                    fullWidth={isMobile}
                    variant="outlined"
                    margin="dense"
                    value={values.language}
                    onChange={(e) =>
                        handleChangeValues('language', e.target.value)
                    }
                >
                    {renderLanguages()}
                </TextField>
            </div>

            <div className={classes.formButtons}>
                <div className={classes.passwordResetLink}>
                    <PasswordResetLink />
                </div>
                <Button
                    type="submit"
                    onClick={handleSubmit}
                    color="primary"
                    variant="contained"
                    fullWidth={isMobile}
                >
                    {TranslationHelper.translate('Log in')}
                </Button>
            </div>

            <LoginFormFooter />
        </form>
    );
};
