import { createStyles, WithStyles } from '@material-ui/core';
import { Theme, withStyles } from '@material-ui/core/styles';

const styles = (theme: Theme) => {
    return createStyles({
        navigation: {
            backgroundColor: theme.palette.primary.main,
        },
        actionItemStyles: {
            color: theme.palette.getContrastText(theme.palette.primary.main),
            opacity: 0.6,
            '&$selected': {
                color: theme.palette.getContrastText(
                    theme.palette.primary.main
                ),
                opacity: 1,
            },
        },
        // This is required for the '&$selected' selector to work
        selected: {},
    });
};

export type TThemableProps = WithStyles<typeof styles>;

export const Themable = withStyles(styles, { withTheme: true });
