import { ICalibrationFormReferentialValue } from '../../../../state/ui/forms';

export interface ICalibrationParams {
    value: number;
    params: {
        a: number;
        b: number;
    };
}

export class CalibrationAlgorithm {
    private readonly calibrationSettings: ICalibrationParams[];

    public constructor(
        calibrationSettings: ICalibrationFormReferentialValue[]
    ) {
        this.calibrationSettings =
            this.calculateCalibrationParams(calibrationSettings);
    }

    public calculateCalibratedFuelLevel(value: number) {
        const setting =
            this.calibrationSettings.find((el) => value <= el.value) ||
            this.calibrationSettings[this.calibrationSettings.length - 1];
        const { a, b } = setting.params;
        return a * value + b;
    }

    private calculateCalibrationParams(
        settings: ICalibrationFormReferentialValue[]
    ) {
        if (settings.length <= 1) {
            return [];
        }

        const params = [...settings];
        params.sort((a, b) => a.paramValue - b.paramValue);
        return params.map((el, index) => {
            const values = params[index - 1]
                ? [params[index - 1], el]
                : [el, params[index + 1]];

            const y1 = values[0].fuelLevel;
            const y2 = values[1].fuelLevel;
            const x1 = values[0].paramValue;
            const x2 = values[1].paramValue;

            const a = (y2 - y1) / (x2 - x1);
            const b = y1 - a * x1;

            return {
                value: el.paramValue,
                params: { a, b },
            } as ICalibrationParams;
        });
    }
}
