import React from 'react';
import { useAppDispatch } from '../../../../../../hooks';
import { ITasksUrlParams } from '../../../../../../services/discovery/_shared/urlMakers';
import { ISourceSet } from '../../../../../../state/types';
import {
    closePreview,
    fetchTasks,
    queueMapFitToExtent,
    setNotPlannedFilter,
    setSourceSetDates,
} from '../../../../../../state/_actions';
import {
    useIsAnalyticsModeEnabled,
    useIsGridChart,
    usePane,
    useSourceSetDates,
} from '../../../../selectors/index.hooks';
import SearchField from '../../../../../../components/common/SearchField/SearchField';
import DateRangeDisplay from '../../../../../../components/DateRangeDisplay';
import TasksFilterContainer from '../../../../../../components/TasksFilterContainer';
import {
    useIncludeNotPlanned,
    useOnlyNotAssigned,
} from '../../../../../../components/TasksFilterContainer/Connectable.hooks';
import ErrorMessage from '../ErrorMessage';

interface IOwnProps {
    sourceSet: ISourceSet | null;
    filtering: {
        searchPhrase: string;
        onChange: (searchPhrase: string) => void;
    };
}
const TasksLeftHeader = ({ sourceSet, filtering }: IOwnProps) => {
    const dispatch = useAppDispatch();

    const pane = usePane();
    const onlyNotAssigned = useOnlyNotAssigned();
    const includeNotPlanned = useIncludeNotPlanned();
    const sourceSetDates = useSourceSetDates();
    const isChart = useIsGridChart();
    const isAnalyticsModeEnabled = useIsAnalyticsModeEnabled();

    const handleFetchTasks = (from: string, to: string) => {
        if (sourceSet?.id) {
            dispatch(setSourceSetDates(sourceSet.id, from, to));
            dispatch(setNotPlannedFilter(sourceSet.id, false));
        }

        if (pane) {
            dispatch(closePreview(pane.creatorLevel, false));
        }

        return (
            dispatch(
                fetchTasks({
                    from,
                    to,
                    onlyNotAssigned,
                    includeNotPlanned,
                } as ITasksUrlParams)
            ) as unknown as Promise<any>
        ).then((data) =>
            data?.entities?.tasks?.entities?.length
                ? dispatch(queueMapFitToExtent())
                : null
        );
    };
    const renderSearchField = () => {
        return (
            !isChart &&
            sourceSet && (
                <SearchField
                    value={filtering.searchPhrase}
                    filterChange={filtering.onChange}
                />
            )
        );
    };
    const renderFiltering = () => {
        return (
            !isAnalyticsModeEnabled &&
            sourceSet &&
            sourceSetDates && (
                <>
                    <DateRangeDisplay
                        onDateChange={handleFetchTasks}
                        sourceSetId={sourceSet?.id}
                        dates={sourceSetDates}
                        datesFormat={'short'}
                        small
                    />
                    <TasksFilterContainer sourceSetId={sourceSet.id} />
                </>
            )
        );
    };
    return (
        <>
            <>
                {renderSearchField()}
                {renderFiltering()}
                <ErrorMessage messages={sourceSet?.messages} />
            </>
        </>
    );
};

export default TasksLeftHeader;
