import React, { Component, ComponentType } from 'react';
import { compose } from 'redux';
import { IExportOptions } from '../../components/SourceSetGrid/types';
import { Themable, TThemableProps } from './Themable.hoc';

import ButtonsMenu from './components/ButtonsMenu';

interface IOwnProps {
    buttons: IExportOptions[];
}

type TProps = IOwnProps & TThemableProps;

class ExportButtons extends Component<TProps> {
    public render() {
        const { classes, buttons } = this.props;

        if (buttons && buttons.length) {
            return (
                <ButtonsMenu
                    types={buttons}
                    buttonClass={classes.contrastText}
                />
            );
        }
        return null;
    }
}

export default compose(Themable)(ExportButtons) as ComponentType<IOwnProps>;
