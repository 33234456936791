import { useEffect, useState } from 'react';
import { getDeviceDetails, IDeviceDetails } from '../services/trustedDevice';

export const useDeviceDetails = () => {
    const [deviceDetails, setDeviceDetails] = useState<IDeviceDetails | null>(
        null
    );
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        let isMounted = true;

        const fetchData = async () => {
            try {
                const data = await getDeviceDetails();
                if (isMounted) {
                    setDeviceDetails(data);
                }
            } catch (err) {
                if (isMounted) {
                    setError('Failed to fetch device details');
                    console.error(err);
                }
            } finally {
                if (isMounted) {
                    setLoading(false);
                }
            }
        };

        fetchData();

        return () => {
            isMounted = false;
        };
    }, []);

    return { deviceDetails, loading, error };
};
