import { useState, useEffect } from 'react';

import queryString from 'query-string';

import { TFetchState } from '../state/types';
import UrlConst from '../constants/UrlConst';
import { responseMessage } from '../helpers/responseMessage';
import apiClient from '../services/common/apiClient';
import { makeEndpoint, makeGetRequestSettings } from '../services/common/utils';

interface IFetchExternalProviderOptions {
    sectionId: 'smartSteelMachinesObject';
    data: any;
    customerCode?: string;
}

const useExternalProviderFetch = (options: IFetchExternalProviderOptions) => {
    const [state, setState] = useState<TFetchState<any>>({ type: 'idle' });

    useEffect(() => {
        async function fetchData() {
            try {
                if (state.type === 'idle') {
                    setState({ type: 'loading' });
                }
                const query = {
                    data: queryString.stringify(options.data),
                    sectionId: options.sectionId,
                    customerCode: options.customerCode,
                };
                const response = await apiClient.send(
                    makeGetRequestSettings(
                        makeEndpoint(UrlConst.EXTERNAL_PROVIDERS, '', query)
                    )
                );

                setState({
                    type: 'done',
                    data: response,
                });
            } catch (error: any) {
                const message = responseMessage(error.status);
                setState({ type: 'error', error: { message: message.text } });
            }
        }

        fetchData();
    }, [options.data]);

    return { state };
};

export default useExternalProviderFetch;
