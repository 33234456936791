import omit from 'lodash/omit';
import { Action, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { TRootState } from '../../../store';
import { makeReduxDuck } from 'teedux';
import { updateStatus } from '../statusSlice';

export interface IRequestError {
    status: number;
    id: number;
}

export interface IRequestErrorWithJSONMessage extends IRequestError {
    responseJSON?: {
        code: number;
        message: string;
    };
}
export interface IRequestSuccess {
    id: number;
}
export interface IRequest {
    id: number;
    storePath: string;
    error: IRequestError | null;
    success: IRequestSuccess | null;
}

interface IWithMessage {
    getMessage: () => string;
}

export interface IRequestWithMessages extends IRequest {
    error: (IRequestError & IWithMessage) | null;
    success: (IRequestSuccess & IWithMessage) | null;
}

export interface IPendingRequests {
    [key: string]: IRequest; // key should be in defined keys (keyof)
}

interface IState {
    requests: IPendingRequests;
}

const initialState: IState = {
    requests: {},
};

const duck = makeReduxDuck('app/sync', initialState);

const getNextId = (
    (id = 0) =>
    () =>
        ++id
)();

const beginRequest = duck.defineAction<{
    storePath: string;
    id: number;
}>('BEGIN_REQUEST', ({ requests }, { id, storePath }) => ({
    requests: {
        ...requests,
        [storePath]: {
            id,
            storePath,
            error: null,
            success: null,
        },
    },
}));

const successRequest = duck.defineAction<{
    storePath: string;
    id: number;
}>('SUCCESS_REQUEST', (state, { storePath, id }) => {
    const { requests } = state;

    if (requests[storePath] && requests[storePath].id !== id) {
        return state;
    }

    return {
        requests: {
            ...requests,
            [`${storePath}`]: {
                ...requests[storePath],
                success: {
                    id,
                    storePath,
                },
            },
        },
    };
});

export const forgetRequest = duck.defineAction<{
    storePath: string;
    id: number;
}>('FORGET_REQUEST', ({ requests }, { storePath, id }) =>
    requests[storePath] && requests[storePath].id === id
        ? {
              requests: omit(requests, storePath),
          }
        : { requests }
);

const failRequest = duck.defineAction<{
    storePath: string;
    id: number;
    error: IRequestError;
}>('FAIL_REQUEST', (state, { storePath, error, id }) => {
    const { requests } = state;

    if (requests[storePath] && requests[storePath].id !== id) {
        return state;
    }

    return {
        requests: {
            ...requests,
            [`${storePath}`]: {
                ...requests[storePath],
                error: {
                    id,
                    storePath,
                    status: error.status,
                },
            },
        },
    };
});

export const makeRequest =
    <T>(
        storePath: string,
        requestMaker: () => Promise<T>,
        successCallback: (dispatch: Dispatch, data: T) => void,
        failCallback: (
            dispatch: Dispatch,
            error: Error & IRequestError
        ) => void,
        beginAction?: (dispatch: Dispatch) => void,
        preventStatusUpdate?: boolean
    ): ThunkAction<void, TRootState, null, Action> =>
    (dispatch, getState) => {
        const id = getNextId();
        dispatch(beginRequest({ id, storePath }));
        if (beginAction) {
            beginAction(dispatch);
        }
        return requestMaker()
            .then((data: T) => {
                dispatch(successRequest({ id, storePath }));
                if (getState().app.sync?.requests?.[storePath]?.id === id) {
                    successCallback(dispatch, data);
                }
            })
            .catch((error) => {
                dispatch(failRequest({ id, storePath, error }));
                if (getState().app.sync?.requests?.[storePath]?.id === id) {
                    failCallback(dispatch, error);
                }
                if (!preventStatusUpdate && error.status) {
                    dispatch(updateStatus(error.status));
                }
            });
    };

export default duck.getReducer();
