import { default as Badge } from '@material-ui/core/Badge';
import React, { Component, ComponentType } from 'react';
import { compose } from 'redux';
import Translator from '../../../../../../../../helpers/TranslationHelper';
import ObjectIcon from '../../../../../ObjectIcon';

import { Connectable, TConnectableProps } from './Connectable.hoc';
import { Themable, TThemableProps } from './Themable.hoc';

import { ILastStateHeader } from '../../../../../../../../state/types';

import TimeFormatter from '../../../../../../../../helpers/TimeFormatter';

const objectTypes = {
    8: 'vehicle',
    14: 'fuelTank',
};

const getIconType = (objectType: string) => objectTypes[objectType];
const getIcon = (iconType: string) =>
    (ObjectIcon[iconType] || ObjectIcon.other)();

interface IOwnProps {
    lastStateHeader: ILastStateHeader;
}

type TProps = IOwnProps & TConnectableProps & TThemableProps;

class LastStateExtract extends Component<TProps> {
    public render() {
        const {
            lastStateHeader: { updatedAt, stateName, location, type, icon },
            classes,
            userSettings,
        } = this.props;

        const iconType = getIconType(String(type));
        const headerIcon = icon ? getIcon(iconType) : null;
        const alerts = 0; // NYI placeholder

        return (
            <div className={classes.wrapper}>
                {headerIcon && (
                    <div className={classes.symbolWrapper}>
                        {alerts && alerts > 0 ? (
                            <Badge color={'error'} badgeContent={alerts}>
                                {headerIcon}
                            </Badge>
                        ) : (
                            headerIcon
                        )}
                    </div>
                )}
                <div className={classes.detailsWrapper}>
                    <p className={classes.event}>
                        {Translator.translate(stateName, 'et')}
                    </p>
                    <p>{location}</p>
                    <p className={classes.time}>
                        {updatedAt
                            ? TimeFormatter.dateToString(
                                  updatedAt,
                                  userSettings.dateFormat
                              )
                            : ''}
                    </p>
                </div>
            </div>
        );
    }
}

export default compose(
    Themable,
    Connectable
)(LastStateExtract) as ComponentType<IOwnProps>;
