import React from 'react';

import { Menu, MenuItem } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { default as Tooltip } from '@material-ui/core/Tooltip/Tooltip';
import { default as ExportIcon } from '@material-ui/icons/GetApp';

import TranslationHelper from '../../../helpers/TranslationHelper';
import { IExportOptions } from '../../SourceSetGrid/types';

interface IOwnProps {
    types: IExportOptions[];
    buttonClass: string;
}

const ButtonsMenu = ({ types, buttonClass }: IOwnProps) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const openMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const hideMenu = () => {
        setAnchorEl(null);
    };

    const handleClick = (button: IExportOptions) => {
        button.handler();
        hideMenu();
    };

    return (
        <>
            <Tooltip title={TranslationHelper.translate('Export')}>
                <Button
                    className={buttonClass}
                    aria-controls="export-menu"
                    aria-haspopup="true"
                    onClick={openMenu}
                >
                    <ExportIcon />
                </Button>
            </Tooltip>
            <Menu
                id="export-menu"
                anchorEl={anchorEl}
                keepMounted={true}
                open={Boolean(anchorEl)}
                onClose={hideMenu}
            >
                {types.map((button) => {
                    return (
                        <MenuItem
                            key={button.name}
                            onClick={() => handleClick(button)}
                        >
                            {TranslationHelper.translate(button.name)}
                        </MenuItem>
                    );
                })}
            </Menu>
        </>
    );
};

export default ButtonsMenu;
