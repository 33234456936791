import { sortAlphabetically } from '../../../helpers/comparators';
import { ITransferListObject } from '../TransferList';

export function not<T extends ITransferListObject>(a: T[], b: T[]) {
    const bSet = new Set(b.map((item) => `${item.relationId}-${item.type}`));

    return a.filter((item) => !bSet.has(`${item.relationId}-${item.type}`));
}

export function intersection<T extends ITransferListObject>(a: T[], b: T[]) {
    const bSet = new Set(b.map((item) => `${item.relationId}-${item.type}`));

    return a.filter((item) => bSet.has(`${item.relationId}-${item.type}`));
}

export function findIndexById<T extends ITransferListObject>(a: T[], b: T) {
    return a.findIndex(
        (el) => el.relationId === b.relationId && el.type === b.type
    );
}

export function union<T extends ITransferListObject>(a: T[], b: T[]) {
    return [...a, ...not(b, a)];
}

export const handleAddedAndRemoved = (
    arr: ITransferListObject[],
    added: ITransferListObject[],
    removed: ITransferListObject[]
) => {
    const removeAdded = not(arr, added);
    return union(
        removeAdded.sort((a, b) => sortAlphabetically(a.name, b.name)),
        removed
    );
};
