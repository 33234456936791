import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        pane: {
            display: 'flex',
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
        },
        pageWrapper: {
            display: 'flex',
            position: 'relative',
            flexGrow: 1,
            flexDirection: 'column',
        },
        pageContent: {
            position: 'absolute',
            top: 10,
            left: 10,
            bottom: 10,
            right: 10,
            [theme.breakpoints.down('sm')]: {
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
            },
        },
        paper: {
            width: '1200px',
            height: '700px',
            margin: '0',
        },
        iconButtonIcon: {
            height: 25,
            width: 25,
        },
        iconButton: {
            width: 30,
            padding: 0,
            margin: '0 0 0.5em 0',
        },
    })
);
