import URI from 'urijs';
import AppConfig from '../../constants/AppConfig';
import { makeGetRequestSettings, makeUrl } from '../common/utils';
import UrlConst from '../../constants/UrlConst';
import apiClient from '../common/apiClient';

import { IModel } from '../../state/app/filesSlice';

export const makeFilesUrl = (suffix: string, params = {}) => {
    const url = new URI(AppConfig.instance.getUrl(UrlConst.FILES_URL) + suffix);
    return makeUrl(url, params);
};

export const restGetLabelsModels = (): Promise<IModel[]> =>
    apiClient.send(makeGetRequestSettings(makeFilesUrl('/models')));
