import { useSelector } from 'react-redux';

import { TRootState } from '../../../store';

import { getDictionaryByCode, IDictionaryItem } from './index';

export const useObjectCategory = () =>
    useSelector((state: TRootState) =>
        getDictionaryByCode(state, 'object-category')
    );
export const useActivityCategory = () =>
    useSelector((state: TRootState) =>
        getDictionaryByCode(state, 'activity-category')
    );
export const useServiceClass = () =>
    useSelector((state: TRootState) =>
        getDictionaryByCode(state, 'service-class')
    );
export const useServiceType = () =>
    useSelector((state: TRootState) =>
        getDictionaryByCode(state, 'service-type')
    );
export const useStatuses = () =>
    useSelector((state: TRootState) =>
        getDictionaryByCode(state, 'planning-task-status')
    );

export const useTimeZones = (): IDictionaryItem[] =>
    useSelector((state: TRootState) => getDictionaryByCode(state, 'timezones'));
