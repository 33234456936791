import React, { ReactChild } from 'react';

import { IActionDataParams, IIcon } from '../../state/types';

import { default as CircularProgress } from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

import { default as AttachmentIcon } from '@material-ui/icons/GetApp';
import { default as GridIcon } from '@material-ui/icons/GridOn';
import { default as OpenExternalLinkIcon } from '@material-ui/icons/OpenInNew';
import { default as EyeIcon } from '@material-ui/icons/Visibility';
import { default as LinkIcon } from '@material-ui/icons/Link';
import { default as CustomerServiceIcon } from '@material-ui/icons/ContactPhone';
import { default as ShowEventIcon } from '@material-ui/icons/ExitToApp';

import TranslationHelper from '../../helpers/TranslationHelper';
import { IPreviewActionMeta } from '../../state/ui/discovery/types';
import { usePrivileges } from '../../state/auth/index.hooks';

export const DownloadAction: React.FunctionComponent<{
    classes: {
        downloadLink: string;
        iconButton: string;
        iconButtonIcon: string;
    };
    api: string;
    name: string;
    isMovie?: boolean;
}> = ({ classes, api, name, isMovie }) => {
    const privileges = usePrivileges();
    const url = isMovie ? `${api}&anonymize=${privileges.anonymize}` : api;
    return (
        <>
            <Tooltip title={name}>
                <a href={url} className={classes.downloadLink} download={name}>
                    {name}
                </a>
            </Tooltip>
            <IconButton
                className={classes.iconButton}
                title={TranslationHelper.translate('download')}
                href={url}
                download={name}
            >
                <AttachmentIcon className={classes.iconButtonIcon} />
            </IconButton>
        </>
    );
};
export const PreviewAction = ({
    classes,
    api,
}: {
    classes: {
        tooltipContentWrapper: string;
        tooltipProgressWrapper: string;
        circularProgress: string;
        tooltipImage: string;
        tooltip: string;
        iconButton: string;
        iconButtonIcon: string;
    };
    api: string;
}) => (
    <Tooltip
        title={
            <div className={classes.tooltipContentWrapper}>
                <div className={classes.tooltipProgressWrapper}>
                    <CircularProgress
                        className={classes.circularProgress}
                        thickness={7}
                    />
                </div>
                <div
                    className={classes.tooltipImage}
                    style={{ backgroundImage: `url(${api})` }}
                />
            </div>
        }
        placement="top-start"
        enterDelay={500}
        classes={{ tooltip: classes.tooltip }}
    >
        <IconButton className={classes.iconButton}>
            <EyeIcon className={classes.iconButtonIcon} />
        </IconButton>
    </Tooltip>
);

export const ExternalLinkAction: React.FunctionComponent<{
    classes: {
        downloadLink: string;
        iconButton: string;
        iconButtonIcon: string;
    };
    url: string;
    withCopy?: boolean;
}> = ({ classes, url, withCopy }) => (
    <>
        <a
            href={url}
            className={classes.downloadLink}
            target="_blank"
            title={TranslationHelper.translate('Open in new tab')}
        >
            {url}
        </a>
        <IconButton
            className={classes.iconButton}
            href={url}
            target="_blank"
            title={TranslationHelper.translate('Open in new tab')}
        >
            <OpenExternalLinkIcon className={classes.iconButtonIcon} />
        </IconButton>
        {withCopy && (
            <IconButton
                className={classes.iconButton}
                onClick={() => navigator.clipboard.writeText(url)}
                title={TranslationHelper.translate('Copy to clipboard')}
            >
                <LinkIcon className={classes.iconButtonIcon} />
            </IconButton>
        )}
    </>
);

export const InternalLinkAction: React.FunctionComponent<{
    classes: {
        iconButton: string;
        iconButtonIcon: string;
    };
    url: string;
    openInNewTab: boolean;
    icon: IIcon;
}> = ({ classes, url, openInNewTab, icon }) => (
    <>
        <Tooltip title={TranslationHelper.translate(icon.label ?? '')}>
            <IconButton
                className={classes.iconButton}
                href={url}
                target={openInNewTab ? '_blank' : ''}
            >
                {icon.id === 'customerService' && (
                    <CustomerServiceIcon className={classes.iconButtonIcon} />
                )}
                {icon.id === 'showEvent' && (
                    <ShowEventIcon className={classes.iconButtonIcon} />
                )}
            </IconButton>
        </Tooltip>
    </>
);

export const SourceSetAction: React.FunctionComponent<{
    classes: { iconButton: string; iconButtonIcon: string };
    handleOnClick: () => void;
    label?: string;
}> = ({ classes, handleOnClick, label = 'Show details' }) => (
    <>
        <Tooltip title={TranslationHelper.translate(label)}>
            <IconButton
                className={classes.iconButton}
                aria-label={label}
                onClick={handleOnClick}
            >
                <GridIcon className={classes.iconButtonIcon} />
            </IconButton>
        </Tooltip>
    </>
);

export const ItemAction = ({
    children,
    action,
    classes,
    params,
    label,
    id,
    handler,
}: {
    children: ReactChild;
    classes: { iconButton: string; iconButtonIcon: string };
    params: IActionDataParams;
    handler: (
        type: 'edit' | 'preview' | 'add',
        params: IActionDataParams,
        id: string | undefined
    ) => void;
    action: 'edit' | 'preview' | 'add';
    label: 'Edit' | 'Details' | 'Add';
    id?: string;
}) => {
    return (
        <Tooltip title={TranslationHelper.translate(label)}>
            <IconButton
                onClick={() => handler(action, params, id)}
                aria-label={label}
                className={classes.iconButton}
            >
                {children}
            </IconButton>
        </Tooltip>
    );
};

export const DeleteItemAction = ({
    children,
    classes,
    params,
    handler,
    deleteMeta,
}: {
    children: ReactChild;
    classes: { iconButton: string; iconButtonIcon: string };
    params: IActionDataParams;
    handler: (id: string, message?: string) => void;
    deleteMeta?: IPreviewActionMeta;
}) => {
    return (
        <Tooltip title={TranslationHelper.translate('Delete')}>
            <IconButton
                onClick={() =>
                    handler(params.id || '', deleteMeta?.dialog?.message)
                }
                aria-label={'Delete'}
                className={classes.iconButton}
            >
                {children}
            </IconButton>
        </Tooltip>
    );
};
