import React from 'react';

import { ICellRendererParams } from 'ag-grid-community';

import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import LoopIcon from '@material-ui/icons/Loop';
import HelpIcon from '@material-ui/icons/Help';
import blue from '@material-ui/core/colors/blue';
import { Tooltip } from '@material-ui/core';

import {
    IN_QUEUE,
    NOT_GENERATED,
    READY,
    GENERATING,
    NEW,
    UNKNOWN,
} from '../../../../../../../../constants/dictionaries/ReportStatuses';
import { SATURATION } from '../../../../../../../../constants/dictionaries/ColorConst';

import TranslationHelper from '../../../../../../../../helpers/TranslationHelper';

type TOwnProps = ICellRendererParams;

const StatusIcon = (props: TOwnProps) => {
    const shouldRenderIcon = props.value;
    const renderIcon = (value: number) => {
        switch (value) {
            case READY:
                return <CheckIcon color="primary" />;
            case NOT_GENERATED:
                return <CloseIcon color="error" />;
            case NEW:
            case IN_QUEUE:
            case GENERATING:
                return <LoopIcon style={{ color: blue[SATURATION] }} />;
            case UNKNOWN:
            default:
                return <HelpIcon />;
        }
    };
    return shouldRenderIcon ? (
        <Tooltip
            title={TranslationHelper.translate(`reportstatus${props.value}`)}
        >
            {renderIcon(props.value)}
        </Tooltip>
    ) : (
        ''
    );
};

export default StatusIcon;
