import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { IGetGroupsParams, restGetGroups } from '../../../../services/groups';

import { TRootState } from '../../../../store';
import { IGroup } from '../../collections/dataTypes';

export interface IGroupItem extends IGroup {
    relationId: string;
    type: string;
}
interface IState {
    groups: IGroupItem[] | null;
    groupsLoading: boolean;
}
const initialState: IState = {
    groups: null,
    groupsLoading: false,
};

export const fetchRegistersGroups = createAsyncThunk(
    'get:app/registers/groups',
    async (queryParams: IGetGroupsParams) => {
        const response = await restGetGroups(queryParams);
        return response;
    }
);

const registersGroups = createSlice({
    name: 'registersGroupsSlice',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchRegistersGroups.pending, (state) => {
            state.groupsLoading = true;
        });
        builder.addCase(fetchRegistersGroups.fulfilled, (state, action) => {
            const groups = [...action.payload].map((group) => {
                const rightObject = {} as IGroupItem;
                rightObject.name = group.name;
                rightObject.relationId = group.id;
                rightObject.type = 'GROUP';
                return rightObject;
            });
            state.groups = groups;
            state.groupsLoading = false;
        });

        builder.addCase(fetchRegistersGroups.rejected, (state) => {
            state.groups = [];
            state.groupsLoading = false;
        });
    },
});

export const getGroups = (state: TRootState) =>
    state.app.registers.groups.groups;

export const getGroupsLoading = (state: TRootState) =>
    state.app.registers.groups.groupsLoading;

export default registersGroups.reducer;
