import React from 'react';
import { usePane } from './Connectable.hooks';
import TaskPreview from './components/TaskPreview';
import TaskCrud from './components/TaskCrud';
import { LinearProgress } from '@material-ui/core';

const TaskPane = () => {
    const pane = usePane();

    const mode = pane?.mode;

    switch (mode) {
        case 'preview':
            return <TaskPreview />;
        case 'add':
        case 'edit':
            return <TaskCrud />;
        default:
            return <LinearProgress />;
    }
};

export default TaskPane;
