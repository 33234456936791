import {
    makeGetRequestSettings,
    makeRestReadCollection,
} from '../../common/utils';
import apiClient from '../../common/apiClient';
import { makeCustomerServiceUrl } from '..';
import {
    IServiceTask,
    IServiceTasksUrlParams,
} from '../../../models/customerService';

export const restGetTasks = (
    params: IServiceTasksUrlParams
): Promise<IServiceTask[]> =>
    apiClient.send(
        makeGetRequestSettings(makeCustomerServiceUrl('/tasks', params))
    );

export const restGetTask = (id: number): Promise<IServiceTask> =>
    apiClient.send(
        makeGetRequestSettings(makeCustomerServiceUrl(`/tasks/${id}`))
    );

export const restReadTasksCollection = makeRestReadCollection(restGetTasks);
