import { connect } from 'react-redux';
import { TRootState } from '../../store';

import { logout } from '../../state/_actions/logoutAction';
import { getIsMenuOpen, hideMenu } from '../../state/ui/general';

const mapStateToProps = (state: TRootState) => ({
    isMenuOpen: getIsMenuOpen(state),
});

const mapDispatchToProps = {
    hideMenu,
    logout,
};

type TStateProps = ReturnType<typeof mapStateToProps>;
type TDispatchProps = typeof mapDispatchToProps;

export type TConnectableProps = TStateProps & TDispatchProps;

export const Connectable = connect(mapStateToProps, mapDispatchToProps);
