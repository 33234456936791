import React, { ComponentType } from 'react';
import { compose } from 'redux';

import { Themable, TThemableProps } from './Themable.hoc';

import { ColDef, GridOptions } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';

import { gridTranslateFunc } from '../SourceSetGrid/_utils';

type TProps = GridOptions & TThemableProps;

const defaultColDef: ColDef = {
    resizable: true,
};

const InfiniteRowModelGrid = (props: TProps) => {
    const { classes, ...rest } = props;

    const gridProps: GridOptions = {
        defaultColDef,
        embedFullWidthRows: true,
        suppressScrollOnNewData: true,
        localeTextFunc: gridTranslateFunc,
        suppressContextMenu: true,
        rowModelType: 'infinite',
        cacheBlockSize: 50,
        maxConcurrentDatasourceRequests: 2,
        stopEditingWhenCellsLoseFocus: true,
        //due to api changes in 27/27.1 (server side rowModel) this cause app to crash, as forEeachNode runs differently
        //possible long term solution would be switching to client side aggrid (as it will be problematic to select items on grid with ss model)
        // but for now this works fine, but still we should have an option to use pagination one way or another
        suppressReactUi: true,
        ...rest,
    };

    return (
        <div className={classes.gridContainer}>
            <div className={`ag-theme-balham ${classes.gridWrapper}`}>
                <AgGridReact {...gridProps} />
            </div>
        </div>
    );
};

export default compose(Themable)(
    InfiniteRowModelGrid
) as ComponentType<GridOptions>;
