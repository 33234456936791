import React from 'react';
import { useStyles } from './Themable.hooks';

import { Paper } from '@material-ui/core';

import DevicesGridPane from './components/DevicesGridPane';
import DevicePane from './components/DevicesPane';
import { useDevicesPaneVisible } from '../../../../state/ui/devices/index.hooks';

const Devices = () => {
    const classes = useStyles();
    const devicesPaneVisible = useDevicesPaneVisible();

    const gridPaneClass = devicesPaneVisible
        ? classes.visiblePreviewPane
        : classes.hiddenPreviewPane;

    return (
        <div className={classes.pageWrapper}>
            <div className={gridPaneClass}>
                <DevicesGridPane />
            </div>

            {devicesPaneVisible && (
                <Paper className={classes.paneWrapper}>
                    <DevicePane />
                </Paper>
            )}
        </div>
    );
};

export default Devices;
