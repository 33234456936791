import Feature from 'ol/Feature';

import Geometry from 'ol/geom/Geometry';
import Cluster from 'ol/source/Cluster';
import VectorSource from 'ol/source/Vector';

export const CLUSTERING_DISTANCE = 90;
const MAX_ZOOM = 18;
const END_ANGLE = 2 * Math.PI;
const MAX_FONT_SIZE = 14;
const MID_FONT_SIZE = 12;
const MIN_FONT_SIZE = 10;

const getFontSize = (text: string) => {
    if (text.length <= 2) {
        return MAX_FONT_SIZE;
    } else if (text.length <= 4) {
        return MID_FONT_SIZE;
    } else {
        return MIN_FONT_SIZE;
    }
};

export const createClusterImage = (
    radius: number,
    text: string,
    color: string = '#3399cc'
) => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const size = radius * 2;
    if (ctx) {
        canvas.width = size;
        canvas.height = size;

        ctx.beginPath();
        ctx.arc(radius, radius, radius, 0, END_ANGLE);
        ctx.fillStyle = '#ffffff';
        ctx.fill();

        ctx.beginPath();
        ctx.arc(radius, radius, radius - 1.5, 0, END_ANGLE);
        ctx.fillStyle = color;
        ctx.fill();

        ctx.beginPath();
        ctx.font = getFontSize(text) + 'px Arial';
        ctx.fillStyle = '#ffffff';
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        ctx.fillText(text, radius, radius);
    }
    return canvas.toDataURL();
};

export const toggleCluster = (
    clustering: boolean,
    zoom?: number,
    features?: Feature<Geometry>[]
): VectorSource<Geometry> | Cluster => {
    const allFeature = features
        ?.map((el) => {
            if (el.get('features')) {
                return el.get('features');
            }

            return el;
        })
        ?.flat();

    const source = new VectorSource({
        features: allFeature,
        // @ts-ignore
        displayInLayerSwitcher: false,
    });
    if (!clustering || (zoom && zoom >= MAX_ZOOM)) {
        return source;
    }

    return new Cluster({
        distance: CLUSTERING_DISTANCE,
        source,
    });
};

export const clearCluster = (): VectorSource<Geometry> => {
    return new VectorSource({
        features: [],
        // @ts-ignore
        displayInLayerSwitcher: false,
    });
};
