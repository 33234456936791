import React from 'react';

import { Paper } from '@material-ui/core';

import { useStyles } from './Themable.hoc';

import { useGroupsMode } from '../../../../../../state/ui/groupsSlice/index.hooks';

import TranslationHelper from '../../../../../../helpers/TranslationHelper';

import GroupForm from './components/GroupForm';
import GroupPreview from './components/GroupPreview';

const modeSelector = {
    preview: () => <GroupPreview />,
    create: () => (
        <GroupForm
            title={TranslationHelper.translate('Creating group')}
            tooltip={TranslationHelper.translate('Save')}
        />
    ),
    edit: () => (
        <GroupForm
            title={TranslationHelper.translate('Editing group')}
            tooltip={TranslationHelper.translate('Save')}
        />
    ),
};

const GroupsPane = () => {
    const classes = useStyles();

    const mode = useGroupsMode();

    return (
        <Paper className={classes.pane}>
            <div className={classes.wrapper}>
                {mode && modeSelector[mode] && modeSelector[mode]()}
            </div>
        </Paper>
    );
};

export default GroupsPane;
