import URI from 'urijs';
import AppConfig from '../../constants/AppConfig';
import UrlConst from '../../constants/UrlConst';
import TimeFormatter from '../../helpers/TimeFormatter';
import apiClient from '../common/apiClient';
import {
    makeDeleteRequestSettings,
    makeGetRequestSettings,
    makePostMultipartRequestSettings,
    makePutMultipartRequestSettings,
    makeUrl,
} from '../common/utils';

export interface IAddTask {
    status: number;
    activityCategoryId: number;
    serviceTypeId: number;
    objectCategoryId: number;
    serviceClassId?: number;
    notice: string;
    employeeId?: number;
    vehicleId?: number;
    plannedOn?: string;
    routeId?: number;
    locationId: number;
    unitsCount: number;
    plannedTimeFrom?: string;
    plannedTimeTo?: string;
}

export interface IEditTask extends IAddTask {
    id: number;
}

export const makeTasksUrl = (suffix: string, params = {}) => {
    const url = new URI(AppConfig.instance.getUrl(UrlConst.TASKS_URL) + suffix);
    return makeUrl(url, params);
};

export const restPostTask = (data: FormData): Promise<null> =>
    apiClient.send(makePostMultipartRequestSettings(makeTasksUrl(`/`), data));

export const restPutTask = (data: FormData): Promise<null> =>
    apiClient.send(
        makePutMultipartRequestSettings(
            makeTasksUrl(`/${data.get('id')}`),
            data
        )
    );

export const restDeleteTask = (taskId: number): Promise<null> =>
    apiClient.sendWithoutErrorsHandling(
        makeDeleteRequestSettings(makeTasksUrl(`/${taskId}`))
    );

export const restNotAssignedTasksPresent = (): Promise<boolean> =>
    apiClient.sendWithoutErrorsHandling(
        makeGetRequestSettings(
            makeTasksUrl(`/not-assigned/is-present`, {
                from: TimeFormatter.dateToShortDateString(Date.now()),
                to: TimeFormatter.dateToShortDateString(Date.now()),
            })
        )
    );
