import { COORDS_DECIMAL } from '../constants/dictionaries/MapCoordsFormat';

interface ICoordinate {
    x: number;
    y: number;
}

interface IAddress {
    city: string;
    street: string;
    streetNumber: string;
}

class LocationDetailsHelper {
    public static formatPosition(coordinate: ICoordinate) {
        const latitudeValue = coordinate.y.toFixed(COORDS_DECIMAL);
        const longitudeValue = coordinate.x.toFixed(COORDS_DECIMAL);
        const latitude =
            coordinate.y >= 0
                ? latitudeValue + '° N'
                : Math.abs(+latitudeValue) + '° S';
        const longitude =
            coordinate.x >= 0
                ? longitudeValue + '° E'
                : Math.abs(+longitudeValue) + '° W';

        return latitude + ', ' + longitude;
    }

    public static formatAddress(address: IAddress) {
        let addr = address.city;
        if (address.street.length > 0) {
            addr += ', ' + address.street;
            if (address.streetNumber.length > 0) {
                addr += ' ' + address.streetNumber;
            }
        }

        return addr;
    }
}

export default LocationDetailsHelper;
