import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        wrapper: {
            display: 'flex',
            alignItems: 'center',
        },
        fileName: {
            marginLeft: theme.spacing(),
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
        displayNone: {
            display: 'none',
        },
    })
);
