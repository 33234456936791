export enum ColumnType {
    TEXT = 'text',
    TEXTFIELD = 'textfield',
    SELECT = 'select',
    CHECKBOX = 'checkbox',
    NUMBER = 'number',
    AUTOCOMPLETE = 'autoComplete',
    TIME = 'time',
    DATETIME = 'datetime',
    DATE = 'date',
    DISABLED = 'disabled',
}

export interface ISelectOption {
    value: string;
    label: string;
}

interface BaseColumn {
    id: string;
    label: string;
    type: ColumnType;
    align?: 'left' | 'center' | 'right';
    headerClassName?: string;
    cellClassName?: string;
    disabled?: boolean;
}

interface TextColumn extends BaseColumn {
    type: ColumnType.TEXT;
}

interface InputColumn extends BaseColumn {
    placeholder?: string;
    inputType?: 'text' | 'number' | 'email' | 'password';
    hasError?: (value: any) => boolean;
    getErrorText?: (value: any) => string;
}

export interface TextFieldColumn extends InputColumn {
    type: ColumnType.TEXTFIELD;
}

export interface AutocompleteColumn extends InputColumn {
    type: ColumnType.AUTOCOMPLETE;
    options?: ISelectOption[];
    freeSolo?: boolean;
    valueType?: string;
}
export interface SelectColumn extends BaseColumn {
    type: ColumnType.SELECT;
    options: ISelectOption[];
}

interface DateColumn extends BaseColumn {
    type: ColumnType.DATE;
}
interface TimeColumn extends BaseColumn {
    type: ColumnType.TIME;
}
interface DateTimeColumn extends BaseColumn {
    type: ColumnType.DATETIME;
}
interface CheckboxColumn extends BaseColumn {
    type: ColumnType.CHECKBOX;
}

export type TColumn =
    | TextColumn
    | SelectColumn
    | CheckboxColumn
    | TextFieldColumn
    | DateTimeColumn
    | DateColumn
    | TimeColumn
    | AutocompleteColumn;

export interface RowData {
    id: string | number;
    [key: string]: any;
}
