import { Typography } from '@material-ui/core';
import React, { ReactNode } from 'react';
import TranslationHelper from '../../helpers/TranslationHelper';
import { useStyles } from './Themable.hooks';

interface IOwnProps {
    title: string;
    children: ReactNode;
}
const SectionWithTitle = ({ title, children }: IOwnProps) => {
    const classes = useStyles();

    return (
        <>
            <Typography color={'primary'} className={classes.typography}>
                {TranslationHelper.translate(title)}
            </Typography>
            {children}
        </>
    );
};

export default SectionWithTitle;
