import React, { useEffect, useState } from 'react';

import {
    fetchTasks,
    toggleOnlyNotAssigned,
    toggleOnlyNotPlanned,
} from '../../../../../state/ui/customerService/bottomGrid/tasks';
import {
    useTasksOnlyNotAssigned,
    useTasksOnlyNotPlanned,
} from '../../../../../state/ui/customerService/bottomGrid/tasks/index.hooks';
import { useSelectedClientsAndLocations } from '../../../../../state/ui/customerService/clientsAndLocations/index.hooks';

import { NoDateSwitchComponent } from '../../../../../components/TasksFilterContainer/components/NoDateSwitch/NoDateSwitchComponent';
import { NotAssignedSwitchComponent } from '../../../../../components/TasksFilterContainer/components/NotAssignedSwitch/NotAssignedSwitchComponent';
import { useToolkitDispatch } from '../../../../../hooks';

interface IOwnProps {
    dates: { from: string; to: string };
}
const TasksCheckboxFilters = ({ dates }: IOwnProps) => {
    const [onlyNotAssigned, setOnlyNotAssigned] = useState<boolean>(false);
    const [onlyNotPlanned, setOnlyNotPlanned] = useState<boolean>(false);

    const onlyNotAssignedFromStore = useTasksOnlyNotAssigned();
    const onlyNotPlannedFromStore = useTasksOnlyNotPlanned();
    const selectedClientsAndLocations = useSelectedClientsAndLocations();

    const toolkitDispatch = useToolkitDispatch();

    useEffect(() => {
        setOnlyNotAssigned(onlyNotAssignedFromStore);
        setOnlyNotPlanned(onlyNotPlannedFromStore);
    }, []);

    const handleOnlyNotAssigned = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const checked = event.target.checked;
        setOnlyNotAssigned(checked);

        const fetchTasksParams = () => {
            return onlyNotPlannedFromStore
                ? {
                      selectedClientsAndLocations,
                      onlyNotAssigned: event.target.checked,
                      includeNotPlanned: true,
                  }
                : {
                      selectedClientsAndLocations,
                      from: dates?.from,
                      to: dates?.to,
                      onlyNotAssigned: event.target.checked,
                      includeNotPlanned: true,
                  };
        };
        toolkitDispatch(toggleOnlyNotAssigned(event.target.checked));

        toolkitDispatch(fetchTasks(fetchTasksParams()));
    };

    const handleOnlyNotPlanned = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const checked = event.target.checked;
        setOnlyNotPlanned(checked);

        const fetchTasksParams = () => {
            return event.target.checked
                ? {
                      selectedClientsAndLocations,
                      onlyNotAssigned: onlyNotAssignedFromStore,
                      includeNotPlanned: true,
                  }
                : {
                      selectedClientsAndLocations,
                      from: dates?.from,
                      to: dates?.to,
                      onlyNotAssigned: onlyNotAssignedFromStore,
                      includeNotPlanned: true,
                  };
        };
        toolkitDispatch(toggleOnlyNotPlanned(event.target.checked));
        toolkitDispatch(fetchTasks(fetchTasksParams()));
    };

    return (
        <>
            <NotAssignedSwitchComponent
                handleOnChange={handleOnlyNotAssigned}
                onlyNotAssigned={onlyNotAssigned}
                disabled={false}
            />
            <NoDateSwitchComponent
                onlyNotPlanned={onlyNotPlanned}
                handleOnChange={handleOnlyNotPlanned}
                disabled={false}
            />
        </>
    );
};

export default TasksCheckboxFilters;
