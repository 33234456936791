import { useSelector } from 'react-redux';

import {
    getLayers,
    getAlertDefinitions,
    getObjects,
} from '../../../../state/app/alerts';

export const useLayers = () => useSelector(getLayers);
export const useAlertDefinitions = () => useSelector(getAlertDefinitions);
export const useObjects = () => useSelector(getObjects);
