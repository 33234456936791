import React from 'react';
import { compose } from 'redux';
import { Connectable, TConnectableProps } from './Connectable.hoc';
import { useStyles } from './Themable.hoc';

import TranslationHelper from '../../../../../../helpers/TranslationHelper';

type TProps = TConnectableProps;

const UserData = ({ email, login }: TProps) => {
    const classes = useStyles();

    return (
        <div>
            <div className={classes.header}>
                {TranslationHelper.translate('Account informations')}
            </div>
            <div>
                {TranslationHelper.translate('Login')}: {login}
            </div>
            <div>
                {TranslationHelper.translate('Email')}: {email}
            </div>
        </div>
    );
};

export default compose(Connectable)(UserData);
