import React, { ReactChild } from 'react';

import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { Tooltip } from '@material-ui/core';

import TranslationHelper from '../../helpers/TranslationHelper';
import { useStyles } from './Themable.hooks';

interface IOwnProps {
    title: string;
    children: ReactChild;
}

const IconWithWarning = ({ title, children }: IOwnProps) => {
    const classes = useStyles();
    return (
        <div className={classes.overlayIcon}>
            <Tooltip title={TranslationHelper.translate(title)}>
                <ErrorOutlineIcon
                    className={classes.topIcon}
                    fontSize="small"
                />
            </Tooltip>
            {children}
        </div>
    );
};

export default IconWithWarning;
