import React, { Component } from 'react';

import GlobalDialog from '../../../globalDialog/GlobalDialog';

class RootWrapper extends Component {
    public render() {
        return (
            <div id="flex-container">
                {this.props.children}

                <div className="overlay">
                    <div className="overlay--content">
                        <GlobalDialog />
                    </div>
                </div>
            </div>
        );
    }
}

export default RootWrapper;
