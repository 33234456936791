import React from 'react';

import NotAssignedSwitch from './components/NotAssignedSwitch';
import NoDateSwitch from './components/NoDateSwitch';

interface IOwnProps {
    sourceSetId: string;
}

const TasksFilterContainer = ({ sourceSetId }: IOwnProps) => {
    return (
        <>
            <NotAssignedSwitch sourceSetId={sourceSetId} />
            <NoDateSwitch sourceSetId={sourceSetId} />
        </>
    );
};

export default TasksFilterContainer;
