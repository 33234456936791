import { useSelector } from 'react-redux';
import { getDateFormat } from '../../../../../../state/user';
import { getDetectionChartAttributes } from '../../../../selectors';
import {
    getEvents,
    getOnDemandData,
    isDetectionDataLoading,
} from '../../../../selectors/refuellAndLossConfig';

export const useActiveAttributes = () =>
    useSelector(getDetectionChartAttributes);
export const useDateFormat = () => useSelector(getDateFormat);
export const useEvents = () => useSelector(getEvents);
export const useOnDemandData = () => useSelector(getOnDemandData);
export const useLoading = () => useSelector(isDetectionDataLoading);
