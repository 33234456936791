import Circle from 'ol/style/Circle';
import Fill from 'ol/style/Fill';
import Stroke from 'ol/style/Stroke';

const border = '<circle fill="#555555" cx="12" cy="12" r="12" />';

const makeArchiveIconImage = (iconColor) =>
    new Circle({
        radius: 5,
        fill: new Fill({
            color: iconColor,
        }),
        stroke: new Stroke({
            color: 'black',
            width: 2,
        }),
    });

const makeFallbackSVG = (width, height, iconColor) => `
<svg xmlns="http://www.w3.org/2000/svg" width="${width}px" height="${height}px" viewBox="0 0 ${width} ${height}">
  <g>
  <circle fill="black" cx="${width / 2}" cy="${height / 2}" r="${width / 4}" />
  <circle fill="${iconColor}" cx="${width / 2}" cy="${height / 2}" r="${
    width / 4 - 2
}" />
  </g>
</svg>
`;

export default {
    A: ({ width = 24, height = 24, iconColor, iconAngle }) => ({
        width,
        height,
        content: `
            <svg xmlns="http://www.w3.org/2000/svg" width="${width}px" height="${height}px">
                ${border}
                <g transform="rotate(${
                    iconAngle ? Math.round(iconAngle) : 0
                }, 12, 12)">
                    <circle fill="${iconColor}" cx="${width / 2}" cy="${
            height / 2
        }" r="${width / 2 - 1}" />
                    <path d="m 15.703091,17.124685 c -0.123053,0.07886 -0.284912,0.06146 -0.388492,-0.04212 L 12.000063,13.768027 8.6855274,17.082563 C 8.5819481,17.186142 8.4200883,17.203543 8.2970361,17.124685 8.1730172,17.045965 8.1206752,16.892253 8.1708075,16.754562 L 11.706036,7.0316464 c 0.0453,-0.122914 0.162413,-0.2055012 0.293889,-0.2055012 0.131753,0 0.249419,0.082035 0.294165,0.2055012 L 15.829181,16.7547 c 0.04212,0.115318 0.01201,0.242652 -0.07278,0.327449 -0.01561,0.01561 -0.03342,0.03011 -0.05331,0.04254 l 0,0 z" fill="#FFFFFF" />
                </g>
            </svg>
        `,
    }),

    B: ({ width = 24, height = 24, iconColor }) => ({
        width,
        height,
        content:
            `<svg xmlns="http://www.w3.org/2000/svg" width="${width}px" height="${height}px">` +
            '<g>' +
            border +
            `<circle fill="${iconColor}" cx="${width / 2}" cy="${
                height / 2
            }" r="${width / 2 - 1}" />` +
            '<path d="m 12.43668,7.641543 0,-0.8919531 1.75082,0 0,-0.8747265 c 0,-0.4826172 -0.392109,-0.8747266 -0.874727,-0.8747266 l -2.625547,0 c -0.48289,0 -0.8747261,0.3921094 -0.8747261,0.8747266 l 0,0.8747265 1.7494541,0 0,0.8919531 c -2.9361729,0.2233984 -5.2497275,2.677773 -5.2497275,5.670547 0,3.141523 2.5465234,5.687773 5.6877735,5.687773 3.141249,0 5.687773,-2.54625 5.687773,-5.687773 0,-2.993867 -2.313554,-5.4471486 -5.251093,-5.670547 l 0,0 z m 2.656445,8.765039 C 14.266524,17.23209 13.168399,17.68709 12,17.68709 c -1.168672,0 -2.2676173,-0.455 -3.0933986,-1.280508 -0.8266015,-0.826875 -1.2816015,-1.92582 -1.2816015,-3.094492 0,-1.168399 0.455,-2.266524 1.2816015,-3.093125 C 9.7003905,9.4254493 10.743828,8.9745509 11.861093,8.9403712 L 11.56414,13.233066 c -0.0257,0.360117 0.170898,0.517071 0.43586,0.517071 0.26496,0 0.460195,-0.156954 0.434765,-0.517071 L 12.138906,8.9403712 c 1.116171,0.03418 2.160703,0.4850781 2.954219,1.2785938 0.826874,0.826601 1.281875,1.924726 1.281875,3.093125 0,1.168672 -0.455001,2.267617 -1.281875,3.094492 l 0,0 z" fill="#FFFFFF" />' +
            '</g>' +
            '</svg>',
    }),

    C: ({ width = 24, height = 24, iconColor }) => ({
        width,
        height,
        content:
            `<svg xmlns="http://www.w3.org/2000/svg" width="${width}px" height="${height}px">` +
            '<g>' +
            border +
            `<circle fill="${iconColor}" cx="${width / 2}" cy="${
                height / 2
            }" r="${width / 2 - 1}" />` +
            '<path d="m 17.115253,10.903671 -0.730506,0 c 0,-0.05983 -0.0071,-0.11943 -0.02238,-0.177432 L 15.631863,7.8039878 C 15.550569,7.4783544 15.258047,7.25 14.922594,7.25 l -5.8451878,0 C 8.7419535,7.25 8.4494315,7.4783544 8.3681374,7.8039878 L 7.6376316,10.726239 c -0.014158,0.058 -0.022379,0.117603 -0.022379,0.177432 l -0.7305058,0 c -0.4030455,0 -0.7305057,0.32746 -0.7305057,0.730505 l 0,2.923165 c 0,0.403274 0.3274602,0.730506 0.7305057,0.730506 l 0.7305058,0 0,0.730506 c 0,0.404187 0.3274602,0.731647 0.7316475,0.731647 0.4030455,0 0.7305058,-0.32746 0.7305058,-0.731647 l 0,-0.730506 5.8451881,0 0,0.730506 c 0,0.404187 0.32746,0.731647 0.731647,0.731647 0.403046,0 0.730506,-0.32746 0.730506,-0.731647 l 0,-0.730506 0.730506,0 c 0.403274,0 0.730506,-0.327232 0.730506,-0.730506 l 0,-2.923165 c 0,-0.403045 -0.327232,-0.730505 -0.730506,-0.730505 l 0,0 z m -8.6497194,3.279002 c -0.4260557,0 -0.7712331,-0.345177 -0.7712331,-0.770029 0,-0.426056 0.3451774,-0.771234 0.7712331,-0.771234 0.4248522,0 0.7700295,0.345178 0.7700295,0.771234 0,0.424852 -0.3451773,0.770029 -0.7700295,0.770029 l 0,0 z m 0.7933784,-3.081322 0.5774619,-2.3112919 4.9598181,0 0.577462,2.3112919 -6.114742,0 z m 6.909324,3.081322 c -0.426056,0 -0.771233,-0.345177 -0.771233,-0.770029 0,-0.426056 0.345177,-0.771234 0.771233,-0.771234 0.424852,0 0.77003,0.345178 0.77003,0.771234 0,0.424852 -0.345178,0.770029 -0.77003,0.770029 l 0,0 z" fill="#FFFFFF" />' +
            '</g>' +
            '</svg>',
    }),

    D: ({ width = 24, height = 24, iconColor }) => ({
        width,
        height,
        content:
            `<svg xmlns="http://www.w3.org/2000/svg" width="${width}px" height="${height}px">` +
            '<g>' +
            border +
            `<circle fill="${iconColor}" cx="${width / 2}" cy="${
                height / 2
            }" r="${width / 2 - 1}" />` +
            '<g transform="translate(7.53125,6.5)" fill="#FFFFFF">' +
            '<path d="M 8.25,0 C 7.8703281,0 7.5625,0.30782813 7.5625,0.6875 c 0,0.12048437 0.031109,0.23357812 0.085594,0.3320625 L 3.8665,3.722125 C 4.2337969,4.2466875 4.6906406,4.7037031 5.215375,5.0708281 L 7.9179375,1.2894063 C 8.0164219,1.3438906 8.1296875,1.375 8.25,1.375 8.6296719,1.375 8.9375,1.0671719 8.9375,0.6875 8.9375,0.30782813 8.6296719,0 8.25,0 l 0,0 z"></path><path d="M 7.20225,3.6949688 6.7768594,4.2903437 C 7.192625,4.8394844 7.5205625,5.3918906 7.734375,5.911125 8.0281094,6.6244062 8.0667813,7.2111875 7.8354375,7.4425312 7.7359219,7.5420469 7.5728125,7.5924062 7.3509219,7.5924062 6.5539375,7.5924062 5.0687656,6.9114375 3.5475,5.3901719 2.6527188,4.4953906 1.9597187,3.5163906 1.5962031,2.6336406 1.3024687,1.9203594 1.2637969,1.3335781 1.4951406,1.1022344 1.5946563,1.0027188 1.7575937,0.9521875 1.9796562,0.9521875 c 0.5957188,0 1.5759219,0.3805312 2.6683594,1.2081094 L 5.2423594,1.7355938 C 4.8429219,1.4219219 4.4366094,1.1479531 4.0354531,0.92382813 3.2625313,0.49190625 2.5430625,0.26365625 1.9550781,0.26365625 c -0.4668125,0 -0.859375,0.14575 -1.13506248,0.4214375 -0.1381875,0.1381875 -0.242,0.3025 -0.312125,0.48950005 C 0.1821875,1.874125 0,2.6539219 0,3.4765156 c 0,3.0160625 2.4450937,5.4611563 5.4611562,5.4611563 0.8256875,0 1.6084063,-0.1835625 2.31,-0.5116719 C 7.9559219,8.3551875 8.1181719,8.2520625 8.2525781,8.1176563 8.7728438,7.5973906 8.8115156,6.7089688 8.361375,5.6160156 8.1059688,4.9955469 7.7072187,4.3379531 7.20225,3.6949688 l 0,0 z" />' +
            '</g>' +
            '</g>' +
            '</svg>',
    }),

    E: ({ width = 24, height = 24, iconColor }) => ({
        width,
        height,
        content:
            `<svg xmlns="http://www.w3.org/2000/svg" width="${width}px" height="${height}px">` +
            '<g>' +
            border +
            `<circle fill="${iconColor}" cx="${width / 2}" cy="${
                height / 2
            }" r="${width / 2 - 1}" />` +
            '<polygon fill="#FFFFFF" points="10.8125,11.875 8.625,18 16.5,10.125 11.6875,10.125 13.875,4 6,11.875 " transform="translate(0.75,1)" />' +
            '</g>' +
            '</svg>',
    }),

    UNKNOWN: ({ width = 24, height = 24, iconColor }) => ({
        width,
        height,
        content:
            `<svg xmlns="http://www.w3.org/2000/svg" width="${width}px" height="${height}px">` +
            '<g>' +
            border +
            `<circle fill="${iconColor}" cx="${width / 2}" cy="${
                height / 2
            }" r="${width / 2 - 1}" />` +
            '<path d="m 13.32,12 c 0,0.72897 -0.59103,1.32 -1.32,1.32 -0.72897,0 -1.32,-0.59103 -1.32,-1.32 l 0,-3.96 c 0,-0.72897 0.59103,-1.32 1.32,-1.32 0.72897,0 1.32,0.59103 1.32,1.32 l 0,3.96 z" fill="#FFFFFF"></path><path d="m 12,17.28 c -0.72897,0 -1.32,-0.59103 -1.32,-1.32 0,-0.72897 0.59103,-1.32 1.32,-1.32 0.72897,0 1.32,0.59103 1.32,1.32 0,0.72897 -0.59103,1.32 -1.32,1.32 z" fill="#FFFFFF" />' +
            '</g>' +
            '</svg>',
    }),

    'Archive-A': ({ width = 32, height = 32, iconAngle, iconColor }) => ({
        width,
        height,
        content:
            `<svg width="${width}" height="${height}" viewBox="0 0 ${width} ${height}" xmlns="http://www.w3.org/2000/svg">` +
            '<g fill="none">' +
            `<path stroke-width="2" stroke="black" transform="rotate(${
                iconAngle ? Math.round(iconAngle) : 0
            }, 13, 15)" d="M13.477 6.35l5.96 14.15-5.96-2.163L7.562 20.5l5.915-14.15z" fill="${iconColor}"/>` +
            '</g></svg>',
    }),

    'Archive-B': ({ width = 32, height = 32, iconColor }) => ({
        width,
        height,
        image: makeArchiveIconImage(iconColor),
        fallbackSVG: makeFallbackSVG(width, height, iconColor),
    }),

    'Archive-C': ({ width = 32, height = 32, iconColor }) => ({
        width,
        height,
        image: makeArchiveIconImage(iconColor),
        fallbackSVG: makeFallbackSVG(width, height, iconColor),
    }),

    'Archive-D': ({ width = 32, height = 32, iconColor }) => ({
        width,
        height,
        image: makeArchiveIconImage(iconColor),
        fallbackSVG: makeFallbackSVG(width, height, iconColor),
    }),

    'Archive-E': ({ width = 32, height = 32, iconColor }) => ({
        width,
        height,
        image: makeArchiveIconImage(iconColor),
        fallbackSVG: makeFallbackSVG(width, height, iconColor),
    }),

    square: ({ width = 24, height = 24, iconColor }) => ({
        width,
        height,
        content:
            `<svg xmlns="http://www.w3.org/2000/svg" width="${width}px" height="${height}px">` +
            '<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">' +
            `<g transform="translate(-59.000000, -45.000000)" stroke="#FFFFFF" stroke-width="2" fill="${iconColor}">` +
            '<g transform="translate(60.000000, 46.000000)">' +
            `<rect x="0" y="0" width="${width - 5}" height="${
                height - 5
            }"></rect>` +
            '</g>' +
            '</g>' +
            '</g>' +
            '</svg>',
    }),

    triangle: ({ width = 24, height = 24, iconColor }) => ({
        width,
        height,
        content:
            `<svg width="${width}px" height="${height}px" viewBox="0 0 ${width} ${height}" xmlns="http://www.w3.org/2000/svg">` +
            '<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">' +
            `<polygon stroke="#FFFFFF" stroke-width="2" fill="${iconColor}" points="12 3 22 23 2 23"></polygon>` +
            '</g>' +
            '</svg>',
    }),

    'rev-triangle': ({ width = 24, height = 24, iconColor }) => ({
        width,
        height,
        content:
            `<svg width="${width}px" height="${height}px" viewBox="0 0 ${width} ${height}" xmlns="http://www.w3.org/2000/svg">` +
            '<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">' +
            `<polygon stroke="#FFFFFF" stroke-width="2" fill="${iconColor}" transform="translate(12.000000, 11.000000) rotate(-180.000000) translate(-12.000000, -11.000000) " points="12 1 22 21 2 21"></polygon>` +
            '</g>' +
            '</svg>',
    }),

    trapezoid: ({ width = 34, height = 22, iconColor }) => ({
        width,
        height,
        content:
            `<svg width="${width}px" height="${height}px" viewBox="0 0 ${width} ${height}" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">` +
            '<g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">' +
            '<g id="Rectangle" transform="translate(17.000000, 11.000000) translate(-17.000000, -11.000000) ">' +
            `<path stroke="#FFFFFF" stroke-width="2" fill="${iconColor}" d="M7.53758822,1 L26.4624118,1 L32.64423,21 L1.35577003,21 L7.53758822,1 Z"></path>` +
            '</g>' +
            '</g>' +
            '</svg>',
    }),

    'rev-trapezoid': ({ width = 34, height = 22, iconColor }) => ({
        width,
        height,
        content:
            `<svg width="${width}px" height="${height}px" viewBox="0 0 ${width} ${height}" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">` +
            '<g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">' +
            '<g id="Rectangle" transform="translate(17.000000, 11.000000) rotate(-180.000000) translate(-17.000000, -11.000000) ">' +
            `<path stroke="#FFFFFF" stroke-width="2" fill="${iconColor}" d="M7.53758822,1 L26.4624118,1 L32.64423,21 L1.35577003,21 L7.53758822,1 Z"></path>` +
            '</g>' +
            '</g>' +
            '</svg>',
    }),

    'hiab-square-todo': ({ width = 24, height = 24, iconColor }) => ({
        width,
        height,
        content:
            `<svg xmlns="http://www.w3.org/2000/svg" width="${width}px" height="${height}px">` +
            '<g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">' +
            '<g id="Artboard" transform="translate(-128.000000, -156.000000)" stroke="#FFFFFF">' +
            '<g id="cont_stage3_notif" transform="translate(129.000000, 157.000000)">' +
            `<rect id="Rectangle-2-Copy-2" stroke-width="2" fill="${iconColor}" x="0" y="4" width="19" height="19"></rect>` +
            '<circle id="Oval-6-Copy-2" fill="#D0011B" cx="18" cy="5" r="5"></circle>' +
            '</g>' +
            '</g>' +
            '</g>' +
            '</svg>',
    }),

    'hiab-drive': ({ width = 30, height = 30, iconAngle }) => ({
        width,
        height,
        content:
            `<svg xmlns="http://www.w3.org/2000/svg" width="${width}px" height="${height}px">` +
            '<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">' +
            '<g transform="translate(-286.000000, -96.000000)" stroke="#FFFFFF">' +
            '<g transform="translate(284.000000, 95.000000)">' +
            `<path d="M19.0833651,6.30813124 C22.2873159,7.97452682 24.4755436,11.324515 24.4755436,15.1855911 C24.4755436,20.7084386 19.9983911,25.1855911 14.4755436,25.1855911 L14.4755436,25.1855911 C8.95269608,25.1855911 4.47554358,20.7084386 4.47554358,15.1855911 L4.47554358,15.1855911 L4.47554358,15.1855911 C4.47554358,11.401575 6.57729681,8.10844974 9.67711003,6.40990847 L14.4311262,1.6558923 L19.0833651,6.30813124 Z" id="Combined-Shape-Copy-4" stroke-width="2" fill="#4990E2" transform="translate(14.475544, 13.420742) rotate(${iconAngle}) translate(-14.475544, -13.420742) " />` +
            '</g>' +
            '</g>' +
            '</g>' +
            '</svg>',
    }),

    'hiab-drive-container': ({ width = 40, height = 40, iconAngle }) => ({
        width,
        height,
        content:
            `<svg xmlns="http://www.w3.org/2000/svg" width="${width}px" height="${height}px">` +
            '<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">' +
            '<g transform="translate(-202.000000, -152.000000)" fill="#4990E2">' +
            '<g transform="translate(202.000000, 152.000000)">' +
            '<rect opacity="0.450967817" x="0" y="0" width="40" height="40" rx="20"></rect>' +
            `<path d="M25.4770176,11.2328208 C28.6809683,12.8992164 30.869196,16.2492045 30.869196,20.1102806 C30.869196,25.6331281 26.3920435,30.1102806 20.869196,30.1102806 L20.869196,30.1102806 C15.3463485,30.1102806 10.869196,25.6331281 10.869196,20.1102806 L10.869196,20.1102806 L10.869196,20.1102806 C10.869196,16.3262645 12.9709492,13.0331393 16.0707625,11.334598 L20.8247786,6.58058183 L25.4770176,11.2328208 Z" id="Combined-Shape-Copy-3" stroke="#FFFFFF" stroke-width="2" transform="translate(20.869196, 18.345431) rotate(${iconAngle}) translate(-20.869196, -18.345431) "></path>` +
            '</g>' +
            '</g>' +
            '</g>' +
            '</svg>',
    }),

    'hiab-drive-todo': ({ width = 30, height = 30, iconAngle }) => ({
        width,
        height,
        content:
            `<svg xmlns="http://www.w3.org/2000/svg" width="${width}px" height="${height}px">` +
            '<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">' +
            '<g transform="translate(-286.000000, -96.000000)" stroke="#FFFFFF">' +
            '<g transform="translate(284.000000, 95.000000)">' +
            `<path d="M19.0833651,6.30813124 C22.2873159,7.97452682 24.4755436,11.324515 24.4755436,15.1855911 C24.4755436,20.7084386 19.9983911,25.1855911 14.4755436,25.1855911 L14.4755436,25.1855911 C8.95269608,25.1855911 4.47554358,20.7084386 4.47554358,15.1855911 L4.47554358,15.1855911 L4.47554358,15.1855911 C4.47554358,11.401575 6.57729681,8.10844974 9.67711003,6.40990847 L14.4311262,1.6558923 L19.0833651,6.30813124 Z" id="Combined-Shape-Copy-4" stroke-width="2" fill="#4990E2" transform="translate(14.475544, 13.420742) rotate(${iconAngle}) translate(-14.475544, -13.420742) " />` +
            '<circle fill="#D0011B" cx="25" cy="8" r="5"></circle>' +
            '</g>' +
            '</g>' +
            '</g>' +
            '</svg>',
    }),

    'hiab-drive-container-todo': ({ width = 40, height = 40, iconAngle }) => ({
        width,
        height,
        content:
            `<svg xmlns="http://www.w3.org/2000/svg" width="${width}px" height="${height}px">` +
            '<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">' +
            '<g transform="translate(-282.000000, -152.000000)">' +
            '<g transform="translate(282.000000, 152.000000)">' +
            '<rect fill="#4990E2" opacity="0.450967817" x="0" y="0" width="40" height="40" rx="20"></rect>' +
            `<path d="M23.8116234,13.8249712 C27.0155742,15.4913667 29.2038019,18.8413549 29.2038019,22.702431 C29.2038019,28.2252785 24.7266494,32.702431 19.2038019,32.702431 L19.2038019,32.702431 C13.6809544,32.702431 9.20380188,28.2252785 9.20380188,22.702431 L9.20380188,22.702431 L9.20380188,22.702431 C9.20380188,18.9184149 11.3055551,15.6252897 14.4053683,13.9267484 L19.1593845,9.17273222 L23.8116234,13.8249712 Z" id="Combined-Shape-Copy-2" stroke="#FFFFFF" stroke-width="2" fill="#4990E2" transform="translate(19.203802, 20.937582) rotate(${iconAngle}) translate(-19.203802, -20.937582) " />` +
            '<circle fill="#D0011B" cx="25" cy="14" r="5"></circle>' +
            '</g>' +
            '</g>' +
            '</g>' +
            '</svg>',
    }),

    'hiab-stop': ({ width = 24, height = 24 }) => ({
        width,
        height,
        content:
            `<svg xmlns="http://www.w3.org/2000/svg" width="${width}px" height="${height}px">` +
            '<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">' +
            '<g transform="translate(-213.000000, -45.000000)">' +
            '<g transform="translate(210.000000, 42.000000)">' +
            '<g transform="translate(13.779674, 13.779674) rotate(-58.000000) translate(-13.779674, -13.779674) ">' +
            '<rect fill="#FFFFFF" stroke="#4990E2" stroke-width="2" x="4.7796736" y="4.7796736" width="18" height="18" rx="9"></rect>' +
            '</g>' +
            '</g>' +
            '</g>' +
            '</g>' +
            '</svg>',
    }),

    'hiab-stop-container': ({ width = 40, height = 40 }) => ({
        width,
        height,
        content:
            `<svg width="${width}px" height="${height}px" viewBox="0 0 ${width} ${height}" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">` +
            '<g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">' +
            '<g id="Artboard" transform="translate(-332.000000, -6.000000)">' +
            '<g id="truck_nodata_with_cont" transform="translate(332.000000, 6.000000)">' +
            '<rect id="Rectangle-2-Copy-12" fill="#4A90E2" opacity="0.450967817" x="0" y="0" width="40" height="40" rx="20"></rect>' +
            '<rect id="Combined-Shape-Copy-3" stroke="#4A90E2" stroke-width="2" fill="#FFFFFF" transform="translate(19.882305, 19.808558) rotate(34.000000) translate(-19.882305, -19.808558) " x="9.88230476" y="9.80855765" width="20" height="20" rx="10"></rect>' +
            '</g>' +
            '</g>' +
            '</g>' +
            '</svg>',
    }),

    'hiab-stop-todo': ({ width = 24, height = 24 }) => ({
        width,
        height,
        content:
            `<svg xmlns="http://www.w3.org/2000/svg" width="${width}px" height="${height}px">` +
            '<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">' +
            '<g transform="translate(-213.000000, -45.000000)">' +
            '<g transform="translate(210.000000, 42.000000)">' +
            '<g transform="translate(13.779674, 13.779674) rotate(-58.000000) translate(-13.779674, -13.779674) ">' +
            '<rect fill="#FFFFFF" stroke="#4990E2" stroke-width="2" x="4.7796736" y="4.7796736" width="18" height="18" rx="9"></rect>' +
            '</g>' +
            '<circle id="Oval-6-Copy-6" stroke="#FFFFFF" fill="#D0011B" cx="23" cy="9" r="5"></circle>' +
            '</g>' +
            '</g>' +
            '</g>' +
            '</svg>',
    }),

    'hiab-stop-container-todo': ({ width = 40, height = 40 }) => ({
        width,
        height,
        content:
            `<svg width="${width}px" height="${height}px" viewBox="0 0 ${width} ${height}" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">` +
            '<g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">' +
            '<g id="Artboard" transform="translate(-332.000000, -56.000000)">' +
            '<g id="truck_nodata_with_cont_notif" transform="translate(332.000000, 56.000000)">' +
            `<rect id="Rectangle-2-Copy-11" fill="#4A90E2" opacity="0.450967817" x="0" y="0" width="${width}" height="${height}" rx="${
                width / 2
            }"></rect>` +
            '<rect id="Combined-Shape-Copy-2" stroke="#4A90E2" stroke-width="2" fill="#FFFFFF" transform="translate(20.173001, 20.173001) rotate(-179.000000) translate(-20.173001, -20.173001) " x="10.173001" y="10.173001" width="20" height="20" rx="10"></rect>' +
            '<circle id="Oval-6-Copy" stroke="#FFFFFF" fill="#D0021B" cx="29" cy="15" r="5"></circle>' +
            '</g>' +
            '</g>' +
            '</g>' +
            '</svg>',
    }),

    XTP: ({
        width = 14,
        height = 24,
        iconBorderColor,
        iconColor,
        iconFillColor,
        iconFillHeightNegative = 0,
        iconFillHeight = 0,
    }) => ({
        width,
        height,
        content:
            `<svg xmlns="http://www.w3.org/2000/svg" width="${width}px" height="${height}px">` +
            `<g><rect x="0" y="0" width="${width}" height="${height}" fill="${iconBorderColor}" />` +
            `<rect x="1" y="1" width="12" height="22" fill="${iconColor}" />` +
            `</g>` +
            `<g>` +
            `<rect fill="${iconFillColor}" x="1" y="${iconFillHeightNegative}" width="12" height="${iconFillHeight}" />` +
            `</g>` +
            `<g id="scale" transform="translate(8.000000, 3.000000)" stroke="${iconBorderColor}" stroke-linecap="square">` +
            `<path d="M5.5 9.5L0.5 9.5" />` +
            `<path d="M5.5 5.5L2.5 5.5" />` +
            `<path d="M5.5 1.5L0.5 1.5" />` +
            `<path d="M5.5 13.5L2.5 13.5" />` +
            `<path d="M5.5 17.5L0.5 17.5" />` +
            `</g>` +
            `</svg>`,
    }),

    pin: ({ width = 16, height = 33 }) => ({
        width,
        height,
        content:
            `<svg width="${width}px" height="${height}px" viewBox="0 0 ${width} ${height}" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">` +
            `<defs>` +
            `<linearGradient x1="0%" y1="49.3621233%" x2="100%" y2="49.3612124%" id="linearGradient-1">` +
            `<stop stop-color="#E0E0E0" offset="0%"></stop>` +
            `<stop stop-color="#A09D9D" stop-opacity="0.974326313" offset="43.8875159%"></stop>` +
            `<stop stop-color="#C1C1C1" stop-opacity="0.974326313" offset="100%"></stop>` +
            `</linearGradient>` +
            `<radialGradient cx="50%" cy="0%" fx="50%" fy="0%" r="100%" id="radialGradient-2">` +
            `<stop stop-color="#FF0000" offset="0%"></stop>` +
            `<stop stop-color="#960000" offset="67.0618973%"></stop>` +
            `<stop stop-color="#9F0000" offset="100%"></stop>` +
            `</radialGradient>` +
            `</defs>` +
            `<g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">` +
            `<g id="pin">` +
            `<rect id="Rectangle-3" fill="url(#linearGradient-1)" x="6" y="11" width="4.06666667" height="21.122449"></rect>` +
            `<path d="M0.309637107,10.1595443 C1.26915443,13.4850489 4.34895396,15.9183673 8,15.9183673 C12.418278,15.9183673 16,12.3549194 16,7.95918367 C16,3.56344791 12.418278,0 8,0 C3.581722,0 0,3.56344791 0,7.95918367 C0,8.72250119 0.108003834,9.46072175 0.309637107,10.1595443 L0.309637107,10.1595443 Z" id="Oval-1" fill="url(#radialGradient-2)"></path>` +
            `</g>` +
            `</g>` +
            `</svg>`,
    }),

    location: ({ width = 18, height = 29, iconColor = '#3C495E' }) => ({
        width,
        height,
        content:
            `<svg width="${width}px" height="${height}px" viewBox="0 0 ${width} ${height}" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">` +
            `<defs></defs>` +
            `<g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">` +
            `<g id="location-marker">` +
            `<path d="M0.0703125,9.40540541 C0.0703125,9.40540541 0.20137314,11.535989 1.54840353,14.6052444 C2.95016369,17.7992035 8.24637162,28.6980643 8.24637162,28.6980643 C8.24637162,28.6980643 13.8334725,17.8685659 15.3628991,14.6052444 C16.8474472,11.4376796 17.2882957,9.40540541 17.2882957,9.40540541 L0.0703125,9.40540541 L0.0703125,9.40540541 L0.0703125,9.40540541 Z" id="Path-5" fill="${iconColor}"></path>` +
            `<ellipse id="Oval-1" fill="${iconColor}" cx="8.60869565" cy="8.62162162" rx="8.60869565" ry="8.62162162"></ellipse>` +
            `<ellipse id="Oval-2" fill="#FFFFFF" cx="8.60869565" cy="8.62162162" rx="3.91304348" ry="3.91891892"></ellipse>` +
            `</g>` +
            `</g>` +
            `</svg>`,
    }),

    'watchman-she-operational': ({
        width = 58,
        height = 26,
        colorCircle = '#0fa042',
        colorDiamond = '#e4e4e4',
        colorTriangle = '#e4e4e4',
    }) => ({
        width,
        height,
        content:
            `<svg width="${width}px" height="${height}px" version="1.1" viewBox="0 0 236 100" xmlns="http://www.w3.org/2000/svg">` +
            `<rect fill="#fff" stroke="#3c3c3b" stroke-miterlimit="10" stroke-width="1.53px" x="71.94" y="-64.5" width="92.12" height="229" rx="15.28" ry="15.28" transform="translate(68 168) rotate(-90)"/>` +
            `<g><rect stroke-width="0px" fill="${colorDiamond}" x="90.75" y="22.75" width="54.5" height="54.5" rx="14.45" ry="14.45" transform="translate(-.79 98.08) rotate(-45)"/></g>` +
            `<path stroke-width="0px" fill="${colorTriangle}" d="M183.11,24.35c1.89-3.27,6.07-4.38,9.34-2.49,1.03.6,1.89,1.46,2.49,2.49l25.73,44.45c1.89,3.27.77,7.45-2.5,9.33-1.04.6-2.21.91-3.41.92h-51.46c-3.77,0-6.83-3.06-6.83-6.84,0-1.2.32-2.38.92-3.41l25.73-44.45Z"/>` +
            `<circle fill="${colorCircle}" cx="46.98" cy="50" r="32.56"/>` +
            `</svg>`,
    }),

    'watchman-she-fault-error': ({
        width = 58,
        height = 26,
        colorCircle = '#e4e4e4',
        colorDiamond = '#e4e4e4',
        colorTriangle = '#f7a42d',
    }) => ({
        width,
        height,
        content:
            `<svg width="${width}px" height="${height}px" version="1.1" viewBox="0 0 236 100" xmlns="http://www.w3.org/2000/svg">` +
            `<rect fill="#fff" stroke="#3c3c3b" stroke-miterlimit="10" stroke-width="1.53px" x="71.94" y="-64.5" width="92.12" height="229" rx="15.28" ry="15.28" transform="translate(68 168) rotate(-90)"/>` +
            `<g><rect stroke-width="0px" fill="${colorDiamond}" x="90.75" y="22.75" width="54.5" height="54.5" rx="14.45" ry="14.45" transform="translate(-.79 98.08) rotate(-45)"/></g>` +
            `<path stroke-width="0px" fill="${colorTriangle}" d="M183.11,24.35c1.89-3.27,6.07-4.38,9.34-2.49,1.03.6,1.89,1.46,2.49,2.49l25.73,44.45c1.89,3.27.77,7.45-2.5,9.33-1.04.6-2.21.91-3.41.92h-51.46c-3.77,0-6.83-3.06-6.83-6.84,0-1.2.32-2.38.92-3.41l25.73-44.45Z"/>` +
            `<circle fill="${colorCircle}" cx="46.98" cy="50" r="32.56"/>` +
            `</svg>`,
    }),

    'watchman-she-activated': ({
        width = 58,
        height = 26,
        colorCircle = '#0fa042',
        colorDiamond = 'red',
        colorTriangle = '#e4e4e4',
    }) => ({
        width,
        height,
        content:
            `<svg width="${width}px" height="${height}px" version="1.1" viewBox="0 0 236 100" xmlns="http://www.w3.org/2000/svg">` +
            `<rect fill="#fff" stroke="#3c3c3b" stroke-miterlimit="10" stroke-width="1.53px" x="71.94" y="-64.5" width="92.12" height="229" rx="15.28" ry="15.28" transform="translate(68 168) rotate(-90)"/>` +
            `<g><rect stroke-width="0px" fill="${colorDiamond}" x="90.75" y="22.75" width="54.5" height="54.5" rx="14.45" ry="14.45" transform="translate(-.79 98.08) rotate(-45)"/></g>` +
            `<path stroke-width="0px" fill="${colorTriangle}" d="M183.11,24.35c1.89-3.27,6.07-4.38,9.34-2.49,1.03.6,1.89,1.46,2.49,2.49l25.73,44.45c1.89,3.27.77,7.45-2.5,9.33-1.04.6-2.21.91-3.41.92h-51.46c-3.77,0-6.83-3.06-6.83-6.84,0-1.2.32-2.38.92-3.41l25.73-44.45Z"/>` +
            `<circle fill="${colorCircle}" cx="46.98" cy="50" r="32.56"/>` +
            `</svg>`,
    }),

    'watchman-she-activated-fault-error': ({
        width = 58,
        height = 26,
        colorCircle = '#e4e4e4',
        colorDiamond = 'red',
        colorTriangle = '#f7a42d',
    }) => ({
        width,
        height,
        content:
            `<svg width="${width}px" height="${height}px" version="1.1" viewBox="0 0 236 100" xmlns="http://www.w3.org/2000/svg">` +
            `<rect fill="#fff" stroke="#3c3c3b" stroke-miterlimit="10" stroke-width="1.53px" x="71.94" y="-64.5" width="92.12" height="229" rx="15.28" ry="15.28" transform="translate(68 168) rotate(-90)"/>` +
            `<g><rect stroke-width="0px" fill="${colorDiamond}" x="90.75" y="22.75" width="54.5" height="54.5" rx="14.45" ry="14.45" transform="translate(-.79 98.08) rotate(-45)"/></g>` +
            `<path stroke-width="0px" fill="${colorTriangle}" d="M183.11,24.35c1.89-3.27,6.07-4.38,9.34-2.49,1.03.6,1.89,1.46,2.49,2.49l25.73,44.45c1.89,3.27.77,7.45-2.5,9.33-1.04.6-2.21.91-3.41.92h-51.46c-3.77,0-6.83-3.06-6.83-6.84,0-1.2.32-2.38.92-3.41l25.73-44.45Z"/>` +
            `<circle fill="${colorCircle}" cx="46.98" cy="50" r="32.56"/>` +
            `</svg>`,
    }),
};
