import { connect } from 'react-redux';

import { TRootState } from '../../../store';

const mapStateToProps = (state: TRootState) => ({
    networkStatus: state.app.status.code,
});

const mapDispatchToProps = {};

const tmp = (false as true) && mapStateToProps({} as TRootState);
type TStateProps = typeof tmp;
type TDispatchProps = typeof mapDispatchToProps;

export type TConnectableProps = TStateProps & TDispatchProps;

export const Connectable = connect(mapStateToProps, mapDispatchToProps);
