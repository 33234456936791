import { Map as olMap } from 'ol'; // Assuming you're using OpenLayers
import { Feature } from 'ol';
import { Geometry, Point } from 'ol/geom';
import VectorSource from 'ol/source/Vector';
import { makeMarker } from '../../../../discovery/components/DiscoveryMap/_utils/features';
import WebGLPointsLayer from 'ol/layer/WebGLPoints';

import { ISourceSet, ISourceSetEntity } from '../../../../../state/types';
import { LiteralStyle } from 'ol/style/literal';
import { Cluster } from 'ol/source';
import VectorLayer from 'ol/layer/Vector';
import { getIconImage } from '../../../../discovery/components/DiscoveryMap/_utils/icons';
import { Style } from 'ol/style';
import TranslationHelper from '../../../../../helpers/TranslationHelper';
import { selectAndZoomOnFeatures } from './handlers';
import { TPointsLayer } from '../../../types';

export const FEATURE_SELECTED = 1;
export const FEATURE_NOT_SELECTED = 0;

interface ICreateLayerParams {
    map: olMap | null;
    sourceSet: ISourceSet | null;
    style: LiteralStyle;
    setLayer: (layer: TPointsLayer) => void;
    setSource: (source: VectorSource) => void;
    pointsLayer: TPointsLayer;
    clustering: boolean;
    items: string[];
    clusterColor?: string;
}

const getClustersLayers = (
    source: VectorSource<Geometry>,
    title: string,
    color?: string
) => {
    const styleCache: { [key: number]: Style } = {};
    const clustersLayer = new VectorLayer({
        source,
        // @ts-ignore - incorrect typings on ol side? check with newer version
        title,
        zIndex: 1,
        style: (feature) => {
            const size = feature.get('features')?.length;
            if (size) {
                if (!styleCache[size]) {
                    styleCache[size] = new Style({
                        image: getIconImage(size, color),
                    });
                }
                return [styleCache[size]];
            }
            return;
        },
    });

    return clustersLayer;
};

// Helper to create a feature from a location
const createFeature = (
    { coordinate, id, color, statusId }: ISourceSetEntity,
    sourceSet: ISourceSet
) => {
    const { r, g, b } = color?.rgb || {};
    const feature = new Feature({
        geometry: makeMarker(coordinate?.x || 0, coordinate?.y || 0),
        data: { id },
        id: id.toString(),
        sourceSetId: sourceSet.definitionId,
        statusId,
        color,
        r,
        g,
        b,
    });
    feature.setId(id.toString());
    return feature;
};

// Helper to create a VectorSource or ClusterSource based on clustering
const createSource = (
    features: Feature[],
    clustering: boolean
): VectorSource<Geometry> => {
    if (clustering) {
        return new Cluster({
            distance: 90, // Adjust clustering distance as needed
            source: new VectorSource({
                features,
                // @ts-ignore
                displayInLayerSwitcher: false,
            }),
        });
    } else {
        return new VectorSource({
            features,
            // @ts-ignore
            displayInLayerSwitcher: false,
        });
    }
};

export const createLayer = ({
    map,
    sourceSet,
    style,
    setLayer,
    pointsLayer,
    setSource,
    clustering, // New parameter to enable clustering
    items,
    clusterColor,
}: ICreateLayerParams & { clustering?: boolean }) => {
    // Dispose of the old layer if it exists
    pointsLayer?.dispose();
    pointsLayer && map?.removeLayer(pointsLayer);

    if (!map || !sourceSet?.entities?.length) return;

    const features = sourceSet.entities
        .filter((location) => location.coordinate)
        .map((entity) => createFeature(entity, sourceSet));

    const source = createSource(features, clustering);

    //create layer depending on clustering - if on, Cluster Layer, if not - WebGl
    if (clustering) {
        const layer = getClustersLayers(
            source,
            TranslationHelper.translate(sourceSet.definitionId),
            clusterColor
        );

        setLayer(layer as VectorLayer<Cluster>);
        map.addLayer(layer);
    } else {
        const layer = new WebGLPointsLayer({
            source: source as VectorSource<Point>,
            //@ts-ignore
            title: TranslationHelper.translate(sourceSet.definitionId),
            style,
        });

        setLayer(layer);
        map.addLayer(layer);
    }
    setSource(source);

    // Handler for when rendering is complete
    const onRenderComplete = () =>
        selectAndZoomOnFeatures(
            map,
            source,
            pointsLayer,
            items,
            onRenderComplete
        );
    map.on('rendercomplete', onRenderComplete);
};

export const getFeatureAtPixel = (map: olMap, pixel: number[]) => {
    const features: Feature<Geometry>[] = map.getFeaturesAtPixel(
        pixel
    ) as Feature<Geometry>[];
    if (features.length > 0) {
        return features[0];
    }
    return;
};
