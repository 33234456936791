import React from 'react';
import { FieldArray, FormikErrors, FormikValues } from 'formik';
import AlertDefinitionForm from '../AlertDefinitionForm';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import { useStyles } from '../../Themable.hooks';
import {
    TAnyAlertCondition,
    IGroupAlertCondition,
    IEventAlertCondition,
    ILayerAlertCondition,
    IParamAlertCondition,
} from '../../../../state/ui/forms';

import { AddButtons } from '../AddButtons/AddButtons';
import { IDialogProp } from '../../AlertSettingDialog';

interface IOwnProps {
    errors?: FormikErrors<IGroupAlertCondition>;
    values: FormikValues;
    setFieldValue: (
        name: string,
        value:
            | IParamAlertCondition
            | IEventAlertCondition
            | ILayerAlertCondition
    ) => void;
    dialog: IDialogProp;
    name: string;
    type: number;
}

export const ConditionsArray = ({
    values,
    errors,
    setFieldValue,
    name,
    dialog,
}: IOwnProps) => {
    const classes = useStyles();

    function hasConditionsField(condition: TAnyAlertCondition): boolean {
        return 'conditions' in condition;
    }

    const { mode } = dialog;

    const renderCondition = (
        condition: TAnyAlertCondition,
        index: number,
        remove: <T>(index: number) => T | undefined
    ) => {
        return (
            <div className={classes.condition} key={index}>
                <AlertDefinitionForm
                    name={`${name}.conditions.${index}`}
                    type={condition.type}
                    values={values[index]}
                    errors={errors?.conditions?.[index]}
                    setFieldValue={setFieldValue}
                    dialog={dialog}
                />
                {mode !== 'edit' && index !== 0 && (
                    <IconButton
                        size="small"
                        aria-label="close"
                        onClick={() => {
                            remove(index);
                        }}
                        className={
                            hasConditionsField(condition)
                                ? classes.closeButton
                                : undefined
                        }
                    >
                        <CloseIcon fontSize="small" />
                    </IconButton>
                )}
            </div>
        );
    };

    return (
        <FieldArray name={`${name}.conditions`} validateOnChange={false}>
            {({ remove, push }) => {
                return (
                    <>
                        {values?.map(
                            (condition: TAnyAlertCondition, index: number) =>
                                renderCondition(condition, index, remove)
                        )}
                        {mode !== 'edit' && (
                            <AddButtons
                                values={values}
                                push={push}
                                name={name}
                            />
                        )}
                    </>
                );
            }}
        </FieldArray>
    );
};
