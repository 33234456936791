import { createStyles, makeStyles, Theme } from '@material-ui/core';
import orange from '@material-ui/core/colors/orange';
import { SATURATION } from '../../constants/dictionaries/ColorConst';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            margin: 'auto',
            height: '100%',
            maxHeight: '60%',
        },
        listContainer: {
            height: '100%',
            backgroundColor: theme.palette.background.paper,
        },
        cardHeader: {
            padding: theme.spacing(1, 2),
        },
        list: {
            width: '100%',
            backgroundColor: theme.palette.background.paper,
            overflow: 'auto',
        },
        button: {
            margin: theme.spacing(0.5, 0),
        },
        card: {
            height: '480px',
            minWidth: '40%',
            width: '500px',
        },
        gridItem: {
            height: '100%',
        },
        gridItemButtons: {
            padding: theme.spacing(),
        },
        listItemText: {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
        listItemTextPrimary: {
            display: 'revert',
            whiteSpace: 'nowrap',
        },
        selectType: {
            minWidth: '30%',
            margin: '5px',
        },
        searchByName: {
            minWidth: '40%',
            margin: '5px 10px',
        },
        filtersWrapper: {
            display: 'flex',
            margin: '3px',
        },
        selectAllWrapper: {
            display: 'flex',
            alignItems: 'center',
        },
        noItems: {
            color: theme.palette.grey[400],
            height: '20%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        warningIcon: {
            color: orange[SATURATION],
        },
    })
);
