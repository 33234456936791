import Translator from '../../helpers/TranslationHelper';
import {
    IActionDataParams,
    IAttribute,
    IIcon,
    TSourceSetEntity,
} from '../types';
import { TComponentType } from '../ui/discovery/snapshotting';
import { IPreviewAction } from '../ui/discovery/types';

export default (
    componentName: TComponentType | undefined,
    data: TSourceSetEntity,
    action: IPreviewAction,
    icon?: IIcon,
    params?: IActionDataParams,
    attributes?: IAttribute[]
) => {
    if (componentName === 'frameDetails') {
        const frameParams = {
            ...params,
            id: data.id,
            offset: data.offset,
            updatedAt: data.updatedAt,
            icon,
            coordinates: data._meta.coordinates,
        };

        return Object.assign({}, action, {
            params: frameParams,
        });
    } else if (componentName === 'aggregationDetails') {
        const aggregationParams = {
            ...params,
            id: data.id,
            name: data.name,
            startDate: data.startDate,
            entitiesToConvert: attributes
                ? Object.assign(
                      {},
                      ...attributes.map((attr) => ({
                          [attr.id]: attr.translate
                              ? Translator.translate(
                                    data[attr.id],
                                    attr.translate
                                )
                              : data[attr.id],
                      }))
                  )
                : {},
            attributes,
            icon,
            coordinates: data._meta.coordinates,
        };

        return Object.assign({}, action, {
            params: aggregationParams,
        });
    } else {
        return Object.assign({}, action, {
            params,
        });
    }
};
