import { connect } from 'react-redux';

import { activateGrid } from '../../state/_actions';
import { TRootState } from '../../store';

import { prepareData } from './utils';

import { getIsMenuOpen } from '../../state/ui/general';
import { getUserSettings } from '../../state/user';
import { getParamDefinitions } from '../dashboard/tables/FuelTankOrdinaryEventsTable/selectors';

export interface IOwnProps {
    chartId: string;
    allData: any[];
    filteredData?: any[];
    data?: object | null;
    sortedAscending: boolean;
}

const mapStateToProps = (state: TRootState, { allData }: IOwnProps) => ({
    isMenuOpen: getIsMenuOpen(state),
    paramDefinitions: getParamDefinitions(state),
    unreducedChartData: prepareData(
        allData,
        state.app.monitoredObjects.paramDefinitions
    ),
    prepareData: (data: any, defs: any) => prepareData(data, defs),
    userSettings: getUserSettings(state),
});

const mapDispatchToProps = {
    activateGrid,
};

type TStateProps = ReturnType<typeof mapStateToProps>;
type TDispatchProps = typeof mapDispatchToProps;

export type TConnectableProps = TStateProps & TDispatchProps;

export const Connectable = connect(mapStateToProps, mapDispatchToProps);
