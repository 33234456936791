import React from 'react';

import { useDispatch } from 'react-redux';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button';
import { NavLink } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { Badge, Icon } from '@material-ui/core';

import DashboardIcon from '@material-ui/icons/Dashboard';
import DiscoveryIcon from '@material-ui/icons/AspectRatio';
import ReportsIcon from '@material-ui/icons/InsertChart';
import RegistersIcon from '@material-ui/icons/Storage';
import AdminIcon from '@material-ui/icons/Settings';
import ManagementIcon from '@material-ui/icons/FolderOpen';
import AccountIcon from '@material-ui/icons/Person';
import ContactPhoneIcon from '@material-ui/icons/ContactPhone';
import Divider from '@material-ui/core/Divider';

import ServiceCenterIcon from '@material-ui/icons/Headset';
import InformationIcon from '@material-ui/icons/Info';
import ExitIcon from '@material-ui/icons/PowerSettingsNew';

import RouteOutlinedIcon from '@mui/icons-material/RouteOutlined';

import AppConfig from '../../../../constants/AppConfig';
import Translator from '../../../../helpers/TranslationHelper';
import AlertWithBadge from './CustomIcons/AlertWithBadge';

import { showAboutDialog } from '../../../../state/ui/dialog';

import { AccessibleListItem } from '../../../accessControl/components';

const styles = (theme) => ({
    button: {
        textTransform: 'none',
        display: 'flex',
        paddingLeft: 15,
    },
    active: {
        backgroundColor: theme.palette.action.hover,
    },
});

const NavMenuButton = withStyles(styles)(
    React.forwardRef((props, ref) => (
        <Button
            focusRipple
            ref={ref}
            className={props.classes.button}
            activeClassName={props.classes.active}
            component={NavLink}
            to={props.to}
        >
            {props.children}
        </Button>
    ))
);

const MenuButton = withStyles(styles)(
    React.forwardRef((props, ref) => (
        <Button
            focusRipple
            ref={ref}
            className={props.classes.button}
            onClick={props.onClick}
        >
            {props.children}
        </Button>
    ))
);

export const MainMenu = ({ links }) => (
    <List>
        {AppConfig.instance.getConfigKey(AppConfig.PROPERTY_DASHBOARD) && (
            <AccessibleListItem
                check={(privileges) => privileges.dashboardContext}
                component={NavMenuButton}
                to={'/dashboard'}
            >
                <ListItemIcon>
                    <DashboardIcon />
                </ListItemIcon>
                <ListItemText primary={Translator.translate('Dashboard')} />
            </AccessibleListItem>
        )}

        <AccessibleListItem
            check={(privileges) => privileges.discoveryContext}
            component={NavMenuButton}
            to={'/discovery'}
        >
            <ListItemIcon>
                <DiscoveryIcon />
            </ListItemIcon>
            <ListItemText primary={Translator.translate('Discovery')} />
        </AccessibleListItem>

        <AccessibleListItem
            check={(privileges) => privileges.contextCustomerServiceCentre}
            component={NavMenuButton}
            to={'/customer-service'}
        >
            <ListItemIcon>
                <ContactPhoneIcon />
            </ListItemIcon>
            <ListItemText primary={Translator.translate('Customer service')} />
        </AccessibleListItem>
        <AccessibleListItem
            check={(privileges) => {
                return privileges.contextPlanning && privileges.routes;
            }}
            component={NavMenuButton}
            to={'/planning'}
        >
            <ListItemIcon>
                <RouteOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary={Translator.translate('Routes planning')} />
        </AccessibleListItem>
        {AppConfig.instance.getConfigKey(AppConfig.PROPERTY_REPORTS) && (
            <AccessibleListItem
                check={(privileges) => privileges.reportsContext}
                component={NavMenuButton}
                to={'/reports'}
            >
                <ListItemIcon>
                    <ReportsIcon />
                </ListItemIcon>
                <ListItemText primary={Translator.translate('Reports')} />
            </AccessibleListItem>
        )}

        {AppConfig.instance.getConfigKey(AppConfig.PROPERTY_REGISTERS) && (
            <AccessibleListItem
                check={(privileges) => privileges.registersContext}
                component={NavMenuButton}
                to={'/registers'}
            >
                <ListItemIcon>
                    <RegistersIcon />
                </ListItemIcon>
                <ListItemText primary={Translator.translate('Registers')} />
            </AccessibleListItem>
        )}

        {AppConfig.instance.getConfigKey(AppConfig.PROPERTY_ALERTS) && (
            <AccessibleListItem
                check={(privileges) => privileges.alertsContext}
                component={NavMenuButton}
                to={'/alerts'}
            >
                <ListItemIcon>
                    <AlertWithBadge />
                </ListItemIcon>
                <ListItemText primary={Translator.translate('Alerts')} />
            </AccessibleListItem>
        )}

        <AccessibleListItem
            check={(privileges) =>
                privileges.manageGroups || privileges.eTollReadOnly
            }
            component={NavMenuButton}
            to={'/management'}
        >
            <ListItemIcon>
                <ManagementIcon />
            </ListItemIcon>
            <ListItemText primary={Translator.translate('Management')} />
        </AccessibleListItem>

        <AccessibleListItem
            check={(privileges) => privileges.adminPanel}
            component={NavMenuButton}
            to={'/admin'}
        >
            <ListItemIcon>
                <AdminIcon />
            </ListItemIcon>
            <ListItemText primary={Translator.translate('Admin Panel')} />
        </AccessibleListItem>

        {links &&
            links.map((link) => (
                <ListItem
                    key={link.name}
                    component={NavMenuButton}
                    to={link.slug}
                >
                    <ListItemIcon>
                        <Icon>{link.icon}</Icon>
                    </ListItemIcon>
                    <ListItemText primary={link.name} />
                </ListItem>
            ))}
    </List>
);

export const SecondaryMenu = ({ links, handleLogout }) => {
    const dispatch = useDispatch();

    return (
        <React.Fragment>
            <List>
                <ListItem component={NavMenuButton} to={'/user-account'}>
                    <ListItemIcon>
                        <Badge color="error" showZero={false}>
                            <AccountIcon />
                        </Badge>
                    </ListItemIcon>
                    <ListItemText
                        primary={Translator.translate('User profile')}
                    />
                </ListItem>
                {links &&
                    links.map((link) => (
                        <ListItem
                            key={link.name}
                            component={NavMenuButton}
                            to={link.slug}
                        >
                            <ListItemIcon>
                                <Icon>{link.icon}</Icon>
                            </ListItemIcon>
                            <ListItemText primary={link.name} />
                        </ListItem>
                    ))}
                {AppConfig.instance.getConfigKey(
                    AppConfig.PROPERTY_SERVICE_CENTER
                ) && (
                    <ListItem component={NavMenuButton} to={'/service-center'}>
                        <ListItemIcon>
                            <ServiceCenterIcon />
                        </ListItemIcon>
                        <ListItemText
                            primary={Translator.translate('Service center')}
                        />
                    </ListItem>
                )}
                <ListItem
                    component={MenuButton}
                    onClick={() => dispatch(showAboutDialog())}
                >
                    <ListItemIcon>
                        <InformationIcon />
                    </ListItemIcon>
                    <ListItemText
                        primary={Translator.translate('Information')}
                    />
                </ListItem>
            </List>
            <Divider />
            <List>
                <ListItem component={MenuButton} onClick={handleLogout}>
                    <ListItemIcon>
                        <ExitIcon />
                    </ListItemIcon>
                    <ListItemText primary={Translator.translate('Logout')} />
                </ListItem>
            </List>
        </React.Fragment>
    );
};
