import React from 'react';
import TranslationHelper from '../../helpers/TranslationHelper';
import DonutChart from '../donutChart/DonutChart';
import LoadingSpinner from '../loadingSpinner/LoadingSpinner';
import ActualParametersData from './ActualParametersData';

interface IProps {
    details?: {
        traits?: {
            binMonitor: boolean;
        };
        data: {
            fillLevel: number;
            fuelLevel: number;
            capacity: number;
            logicType: number;
        };
    };
}

const ActualParameters: React.FC<IProps> = ({ details }) => {
    let content = null;

    if (details && details.data) {
        const currentLevel =
            details.traits && details.traits.binMonitor
                ? details.data.fillLevel
                : details.data.fuelLevel;
        const maxLevel =
            details.traits && details.traits.binMonitor
                ? 100
                : details.data.capacity;

        content = (
            <div className="actual-content">
                <div className="donut">
                    <div className="title">
                        {TranslationHelper.translate('Level')}
                    </div>
                    <div className="chart-container">
                        <DonutChart
                            name="fuelLevelChart"
                            values={[currentLevel, maxLevel]}
                            containerName="chart-container"
                            logicType={details.data.logicType}
                        />
                    </div>
                </div>
                <div className="bottom-params">
                    <ActualParametersData
                        data={details.data}
                        param="fuelLevel"
                        unit="l"
                        label="Fuel level"
                        icon="icon-meter3"
                        decimalPlaces={0}
                    />
                    <ActualParametersData
                        data={details.data}
                        param="temperature"
                        unit="°C"
                        label="Temperature"
                        icon="icon-temperature2"
                        decimalPlaces={2}
                    />
                    <ActualParametersData
                        data={details.data}
                        param="capacity"
                        unit="l"
                        label="Capacity"
                        icon="icon-database3"
                    />
                    <ActualParametersData
                        data={details.data}
                        param="totalLevel"
                        unit="l"
                        label="Total"
                        icon="icon-gas"
                        decimalPlaces={0}
                    />
                    <ActualParametersData
                        data={details.data}
                        param="batteryLevel"
                        unit="mV"
                        label="Battery"
                        icon="icon-battery-4"
                    />
                    <ActualParametersData
                        data={details.data}
                        param="height"
                        unit="cm"
                        label="Size"
                        icon="icon-ruler"
                    />
                </div>
            </div>
        );
    } else {
        content = (
            <div className="spinner">
                <LoadingSpinner
                    size="50"
                    top="0"
                    left="0"
                    right="0"
                    bottom="0"
                />
            </div>
        );
    }

    return content;
};

export default ActualParameters;
