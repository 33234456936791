import React, { Component } from 'react';
import { IVehicle } from '../../../state/app/registers/vehicles';

import Translator from '../../../helpers/TranslationHelper';
import VehicleRow from './VehicleRow';

interface IProps {
    vehicles?: IVehicle[];
    onAssignRFID: (vehicle: IVehicle) => void;
    onEditVehicle: (vehicle: IVehicle) => void;
}

const COLSPAN = 7;

class VehiclesTable extends Component<IProps> {
    public renderTableBody() {
        const vehicles = this.props.vehicles;
        let items = null;
        if (vehicles) {
            if (vehicles.length > 0) {
                items = vehicles.map((vehicle, index) => {
                    return (
                        <VehicleRow
                            vehicle={vehicle}
                            key={index}
                            onAssign={this.props.onAssignRFID}
                            onEdit={this.props.onEditVehicle}
                        />
                    );
                });
            } else {
                items = (
                    <tr>
                        <td colSpan={COLSPAN} className="table--empty-state">
                            {Translator.translate(
                                'There are no vehicles to display'
                            )}
                        </td>
                    </tr>
                );
            }
        } else {
            items = (
                <tr>
                    <td colSpan={COLSPAN} className="table--empty-state">
                        {Translator.translate('Loading...')}
                    </td>
                </tr>
            );
        }
        return items;
    }

    public render() {
        return (
            <table className="table table__hoverable">
                <thead>
                    <tr>
                        <th>{Translator.translate('Name')}</th>
                        <th>{Translator.translate('Group')}</th>
                        <th>{Translator.translate('Vehicle plate number')}</th>
                        <th>{Translator.translate('Vehicle side number')}</th>
                        <th>{Translator.translate('RFID cards')}</th>
                        <th>{Translator.translate('Time zone')}</th>
                        <th>{Translator.translate('Actions')}</th>
                    </tr>
                </thead>

                <tbody>{this.renderTableBody()}</tbody>
            </table>
        );
    }
}

export default VehiclesTable;
