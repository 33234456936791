import React, { useState, ChangeEvent, useEffect } from 'react';

import { FormHelperText, TextField, Typography } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

import { useStyles } from './Themable.hooks';

import TranslationHelper from '../../helpers/TranslationHelper';
import { useDebounceEffect } from '../../helpers/hooks';

import { DEBOUNCE_LOW } from '../../constants/DebounceValues';

import SelectList from '../SelectList';
import { lowerCaseFilter } from '../../helpers/filterObject';

interface IOwnProps<T> {
    title: string;
    items: T[];
    selectedItems: T[];
    loaded: boolean;
    filterKey: string;
    listError?: string;
    handleFormChange: <T>(name: string, value: T) => void;
    disabled?: boolean;
}
const SearchableSelectionList = <T,>({
    title,
    items,
    selectedItems,
    loaded,
    filterKey,
    listError,
    handleFormChange,
    disabled,
}: IOwnProps<T>) => {
    const [input, setInput] = useState<string>('');

    const [filteredItems, setFilteredItems] = useState<T[]>([]);
    const classes = useStyles();

    useEffect(() => {
        setFilteredItems(items);
    }, [loaded]);
    const handleChangeName = (e: ChangeEvent<HTMLInputElement>) => {
        setInput(e.target.value);
    };
    const filterItems = () => {
        if (loaded) {
            setFilteredItems(
                items.filter((item) => lowerCaseFilter(item, input, filterKey))
            );
        }
    };
    useDebounceEffect(filterItems, [input], DEBOUNCE_LOW);

    return (
        <>
            <Typography color={'primary'} className={classes.typography}>
                {TranslationHelper.translate(title)}
            </Typography>
            <TextField
                label={TranslationHelper.translate('Search...')}
                value={input}
                fullWidth
                onChange={handleChangeName}
                InputProps={{
                    endAdornment: <SearchIcon />,
                }}
                disabled={disabled}
            />
            <SelectList
                loaded={loaded}
                items={filteredItems}
                allItems={items}
                checked={selectedItems}
                handleFormChange={handleFormChange}
                disabled={disabled}
            >
                {listError && (
                    <FormHelperText style={{ color: 'red' }}>
                        {listError}
                    </FormHelperText>
                )}
            </SelectList>
        </>
    );
};

export default SearchableSelectionList;
