import { createEmpty, extend, isEmpty } from 'ol/extent';

import Feature from 'ol/Feature';

import GeoJSON from 'ol/format/GeoJSON';
import Geometry from 'ol/geom/Geometry';
import Point from 'ol/geom/Point';

import VectorLayer from 'ol/layer/Vector';

import { transform } from 'ol/proj';
import VectorSource from 'ol/source/Vector';

import olMap from 'ol/Map';

import {
    ISourceSet,
    ITrail,
    TSourceSetEntity,
} from '../../../../../state/types';

import { fitMap, flash, MAP_PADDING } from './index';
import { toggleCluster, clearCluster } from './clustering';
import { getIconStyle, ICON_STYLES } from './icons';
import Cluster from 'ol/source/Cluster';
import { Stroke, Style } from 'ol/style';

export const makeMarker = (lat: number, lng: number) =>
    new Point(makePoint(lat, lng));

export const makePoint = (lat: number, lng: number) =>
    transform([lat, lng], 'EPSG:4326', 'EPSG:900913') as [number, number];

export const setMarker = (
    markerLayer: VectorLayer<VectorSource<Geometry>>,
    lastClickCoordinate: { x: number; y: number } | null = null
) => {
    if (lastClickCoordinate === null) {
        return;
    }
    const feature = new Feature({
        geometry: makeMarker(lastClickCoordinate.x, lastClickCoordinate.y),
        id: 'none',
        actions: {},
    });

    feature.setStyle(ICON_STYLES.location);
    markerLayer.getSource()?.clear();
    markerLayer.getSource()?.addFeature(feature);
    markerLayer.getSource()?.changed();
};

const extendSelectionLayer = (
    extent: number[],
    selectionLayer: VectorLayer<VectorSource<Geometry>>
) => {
    const sourceExtent = selectionLayer.getSource()?.getExtent();
    sourceExtent && extend(extent, sourceExtent);
};
const findFeatureInLayer = (
    layer: VectorLayer<VectorSource<Geometry>>,
    id: string | null | undefined
): Feature<Point> => {
    return getAllFeatures(layer)?.find(
        (feature) => feature?.get('id')?.toString() === id?.toString()
    );
};

const getFeatureId = (feature: Feature<Point> | undefined) => {
    return feature?.get('id'.toString());
};

const getAllFeatures = (
    layer: VectorLayer<VectorSource<Geometry>> | VectorLayer<Cluster>
) => {
    const source = (layer.getSource() as Cluster)?.getSource
        ? (layer.getSource() as Cluster).getSource()
        : layer.getSource();

    return source
        ?.getFeatures()
        .map((el) => el.get('features') || el)
        .flat();
};

const selectNewFeatureOnMap = (
    feature: Feature<Point>,
    selectionLayer: VectorLayer<VectorSource<Geometry>>,
    extent: number[],
    map: olMap
) => {
    selectionLayer.getSource()?.clear();
    selectionLayer.getSource()?.addFeature(feature);
    flash(map, feature);
    extendSelectionLayer(extent, selectionLayer);
};

const reselectCurrentFeatureOnMap = (
    feature: Feature<Point>,
    previousFeature: Feature<Point>,
    selectionLayer: VectorLayer<VectorSource<Geometry>>,
    extent: number[],
    map: olMap
) => {
    const { extentPrev, extentNext } = getExtent(previousFeature, feature);
    selectionLayer.getSource()?.clear();
    selectionLayer.getSource()?.addFeature(feature);
    flash(map, feature);
    if (extentNext !== extentPrev) {
        extendSelectionLayer(extent, selectionLayer);
    }
};

const clearSelection = (
    selectionLayer: VectorLayer<VectorSource<Geometry>>,
    clusterLayer: VectorLayer<VectorSource<Geometry>>,
    extent: number[]
) => {
    selectionLayer.getSource()?.clear();

    const features = clusterLayer.getSource()?.getFeatures() || [];
    const childFeatures = features[0] ? features[0].get('features') : [];
    const allFeatures = [...features, ...(childFeatures || [])];

    allFeatures.forEach((feature) => {
        extend(extent, feature.getGeometry().getExtent());
    });
};

const shouldFitMap = (
    extent: number[],
    fitToExtentIsPending: boolean,
    shouldCenterView: boolean
) => {
    return !isEmpty(extent) && (fitToExtentIsPending || shouldCenterView);
};

const getExtent = (
    previousFeature: Feature<Point>,
    nextFeature: Feature<Point>
) => {
    const geometryPrev = previousFeature?.getGeometry();
    const extentPrev = geometryPrev && JSON.stringify(geometryPrev.getExtent());

    const geometryNext = nextFeature.getGeometry();
    const extentNext = geometryNext && JSON.stringify(geometryNext.getExtent());
    return { extentPrev, extentNext };
};

const getZoomAfterReselect = (
    currentZoom: number | undefined,
    previousFeature: Feature<Point>,
    nextFeature: Feature<Point>,
    map: olMap
) => {
    const previousFeatureId = getFeatureId(previousFeature);
    const nextFeatureId = getFeatureId(nextFeature);

    if (previousFeatureId === nextFeatureId) {
        return map.getView().getZoom();
    } else {
        return currentZoom;
    }
};

const isDifferentFeatureExtent = (
    previousFeature: Feature<Point>,
    nextFeature: Feature<Point>
) => {
    const { extentPrev, extentNext } = getExtent(previousFeature, nextFeature);
    return extentNext !== extentPrev;
};

export const selectFeature = (
    map: olMap,
    clusterLayer: VectorLayer<VectorSource<Geometry>>,
    selectionLayer: VectorLayer<VectorSource<Geometry>>,
    entitiesLength: number,
    selectedSourceSetElementId?: string | null,
    fitToExtentIsPending: boolean = false,
    performMapFitToExtent: () => void = () => {
        return;
    }
) => {
    const extent = createEmpty();

    let shouldCenterView = false;
    let maxZoom: number | undefined;

    const clusterFeatures = clusterLayer
        .getSource()
        ?.getFeatures()
        .map((el) => el.get('features') || el)
        .flat();

    const previousFeature =
        findFeatureInLayer(selectionLayer, selectedSourceSetElementId) ||
        undefined;

    const nextFeature =
        findFeatureInLayer(clusterLayer, selectedSourceSetElementId) ||
        undefined;

    if (nextFeature && previousFeature) {
        maxZoom = getZoomAfterReselect(
            maxZoom,
            previousFeature,
            nextFeature,
            map
        );

        reselectCurrentFeatureOnMap(
            nextFeature,
            previousFeature,
            selectionLayer,
            extent,
            map
        );

        shouldCenterView = isDifferentFeatureExtent(
            previousFeature,
            nextFeature
        );
    } else if (nextFeature) {
        selectNewFeatureOnMap(nextFeature, selectionLayer, extent, map);
        shouldCenterView = true;
    } else if (previousFeature && entitiesLength && !clusterFeatures?.length) {
        flash(map, previousFeature);
    } else {
        clearSelection(selectionLayer, clusterLayer, extent);
        fitToExtentIsPending && performMapFitToExtent();
    }

    selectionLayer.getSource()?.changed();

    if (shouldFitMap(extent, fitToExtentIsPending, shouldCenterView)) {
        fitMap(map, extent, MAP_PADDING, maxZoom);
        if (fitToExtentIsPending) {
            performMapFitToExtent();
        }
    }
};

const setFeature = (
    entity: TSourceSetEntity,
    index: number,
    sourceSet: ISourceSet
) => {
    if (!entity._meta || !entity._meta.coordinates) {
        return;
    }

    const feature = new Feature({
        geometry: makeMarker(
            entity._meta.coordinates.x,
            entity._meta.coordinates.y
        ),
        data: entity,
        id: entity.id.toString(),
    });

    if (
        entity._meta &&
        (entity._meta.icon || entity._meta.type === 'location')
    ) {
        const style = getIconStyle(sourceSet, entity);
        style[0].setZIndex(index);
        feature.setStyle(style);
    } else {
        feature.setStyle(getIconStyle(sourceSet, entity));
    }
    return feature;
};

export const setFeatures = (
    map: olMap,
    clustersLayer: VectorLayer<VectorSource<Geometry>>,
    sourceSet: ISourceSet | null,
    shouldMapBeInEditMode: boolean = false,
    clustering: boolean = true
) => {
    flash(map);

    let allFeatures: Array<Feature<Point> | undefined> = [];

    if (
        sourceSet &&
        sourceSet._meta.geolocated === true &&
        sourceSet.entities?.length > 0
    ) {
        allFeatures = sourceSet.entities
            .filter((entity) => entity._meta && entity._meta.coordinates)
            .map((entity, index) => setFeature(entity, index, sourceSet));
    }

    clustersLayer.setOpacity(shouldMapBeInEditMode ? 0.5 : 1);

    const zoom = map.getView().getZoom();

    if (zoom) {
        if (allFeatures.length > 0) {
            clustersLayer.setSource(
                toggleCluster(clustering, zoom, allFeatures as Feature<Point>[])
            );
        } else {
            clustersLayer.setSource(clearCluster());
        }
    }
};

export const setTrail = (
    map: olMap,
    trailLayer: VectorLayer<VectorSource<Geometry>>,
    trailGeoJSON: ITrail | null,
    fitToViewport: boolean
) => {
    if (!(trailLayer && trailGeoJSON)) {
        trailLayer.getSource()?.clear();
        trailLayer.getSource()?.changed();

        return;
    }

    trailLayer.getSource()?.clear();
    trailLayer.setStyle(
        new Style({
            stroke: new Stroke({
                color: trailGeoJSON.color || 'blue',
                width: 5,
            }),
        })
    );
    const geojsonFormat = new GeoJSON();
    const features = geojsonFormat.readFeatures(trailGeoJSON.data, {
        featureProjection: 'EPSG:3857',
        dataProjection: undefined,
    });
    features.forEach((feature) => trailLayer.getSource()?.addFeature(feature));
    trailLayer.getSource()?.changed();

    if (!fitToViewport) {
        return;
    }

    const extent = createEmpty();
    const sourceExtent = trailLayer.getSource()?.getExtent();
    sourceExtent && extend(extent, sourceExtent);

    if (!extent.every(isFinite)) {
        return;
    }

    trailLayer.getSource()?.changed();
    fitMap(map, extent, MAP_PADDING);
};

export const createTrailLayers = (trails: ITrail[], map: olMap) => {
    const newTrailLayers = [];
    for (const trail of trails) {
        const newTrail = new VectorLayer({
            source: new VectorSource(),
            // @ts-ignore
            displayInLayerSwitcher: false,
            zIndex: 1,
        });
        map.addLayer(newTrail);
        newTrailLayers.push(newTrail);
        setTrail(map, newTrail, trail, false);
    }
    return newTrailLayers;
};
