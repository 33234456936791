import { GridApi } from 'ag-grid-community';
import React, { useEffect, useState } from 'react';

import { useStyles } from '../../Themable.hooks';

import { useReportsPaneVisible } from '../../../../state/ui/reportsSlice/index.hooks';
import {
    fetchReportsDefinitions,
    resetMode,
} from '../../../../state/ui/reportsSlice';

import { useToolkitDispatch } from '../../../../hooks';

import { Paper } from '@material-ui/core';
import SchedulesPane from './components/SchedulesPane';
import SchedulesGrid from './components/SchedulesGrid/SchedulesGrid';

const ReportingScheduling = () => {
    const [gridApi, setGridApi] = useState<GridApi | null>(null);

    const toolkitDispatch = useToolkitDispatch();

    const classes = useStyles();

    const reportsPaneVisible = useReportsPaneVisible();

    useEffect(() => {
        toolkitDispatch(fetchReportsDefinitions());
    }, []);

    const handleClose = () => {
        toolkitDispatch(resetMode());
        gridApi?.purgeInfiniteCache();
    };

    const gridPaneClass = reportsPaneVisible
        ? classes.visibleGridPane
        : classes.hiddenGridPane;

    return (
        <>
            <div className={gridPaneClass}>
                <SchedulesGrid gridApi={gridApi} setGridApi={setGridApi} />
            </div>
            {reportsPaneVisible && (
                <Paper className={classes.paneWrapper}>
                    <SchedulesPane handleClose={handleClose} />
                </Paper>
            )}
        </>
    );
};

export default ReportingScheduling;
