import { makeReduxDuck } from 'teedux';
import {
    restGetEvents,
    restGetParamsDefinitions,
} from '../../../services/calibrations';
import { TRootState } from '../../../store';
import { ICalibrationFormReferentialValue } from '../../ui/forms';
import { makeRequest } from '../sync';

const calibrationsStorePath = `app/calibrations`;

export interface IDefinitionItem {
    id: string;
    name: string;
}

export interface IParamDefinitionItem {
    name: string;
    value: number;
    id: string;
    type: string;
}

export interface IGetEventsParams {
    objectId: number;
    from: string;
    to: string;
    params?: string;
}

export interface IEventItem {
    id: string;
    objectMonitoredId: number;
    date: string;
    coordinate: {
        x: number;
        y: number;
    };
    params: {
        [key: string]: IParamDefinitionItem;
    };
    processedParams: {
        distance: number;
    };
}

interface ICalibrationSettingData {
    selectedParam?: string;
    calibrationData?: ICalibrationFormReferentialValue[];
    events?: IEventItem[] | null;
}

interface IState {
    paramsDefinitions: IDefinitionItem[];
    events: IEventItem[] | null;
    selectedParam: string;
    calibrationData: ICalibrationFormReferentialValue[];
}

const initialState: IState = {
    paramsDefinitions: [],
    events: null,
    selectedParam: '',
    calibrationData: [],
};

const duck = makeReduxDuck(calibrationsStorePath, initialState);

export const setParamsDefinitionsAction = duck.defineAction<{
    data: IDefinitionItem[];
}>('SET_PARAM_DEFINITIONS', (_, { data }) => ({
    paramsDefinitions: data,
}));

export const setEventsAction = duck.defineAction<{
    data: IEventItem[];
}>('SET_EVENTS', (_, { data }) => ({
    events: data,
}));

export const setCalibrationDataAction = duck.defineAction<{
    data: ICalibrationSettingData;
}>('SET_CALIBRATION_DATA', (_, { data }) => ({
    ...data,
}));

export default duck.getReducer();

const makeCalibrationsStorePath = () => `get:${calibrationsStorePath}`;

export const fetchParamsDefinitions = (
    objectId: string,
    afterSuccess?: (data: IParamDefinitionItem[]) => void,
    afterFail?: (error: any) => void
) =>
    makeRequest(
        makeCalibrationsStorePath(),
        () =>
            restGetParamsDefinitions({
                objectId,
                numerical: true,
                calibration: true,
                onlyVisible: false,
            }),
        (dispatch, data) => {
            dispatch(setParamsDefinitionsAction({ data }));
            if (afterSuccess) {
                afterSuccess(data);
            }
        },
        (dispatch, error) => {
            if (afterFail) {
                afterFail(error);
            }
        }
    );

export const fetchEvents = (
    params: IGetEventsParams,
    afterSuccess?: (data: IEventItem[]) => void,
    afterFail?: (error: any) => void
) =>
    makeRequest(
        makeCalibrationsStorePath(),
        () => restGetEvents(params),
        (dispatch, data) => {
            dispatch(setEventsAction({ data }));
            if (afterSuccess) {
                afterSuccess(data);
            }
        },
        (dispatch, error) => {
            if (afterFail) {
                afterFail(error);
            }
        },
        (dispatch) => {
            dispatch(setEventsAction({ data: [] }));
        }
    );

export const getParamsDefinitions = (state: TRootState) => {
    return state.app.calibrations.paramsDefinitions;
};

export const setCalibrationData = (data: ICalibrationSettingData) =>
    setCalibrationDataAction({ data });
