import React, { PureComponent } from 'react';

import TranslationHelper from '../../helpers/TranslationHelper';

interface IProps {
    data: object;
    param: string;
    unit: string;
    label: string;
    icon: string;
    decimalPlaces?: number;
}

class ActualParametersData extends PureComponent<IProps> {
    public render() {
        const { data, decimalPlaces, icon, label, param, unit } = this.props;
        let content = null;

        if (data?.[param] || data?.[param] === 0) {
            const value = data[param];
            content = (
                <div className="param-item">
                    <div className="title">
                        {TranslationHelper.translate(label)}
                    </div>
                    <div className="icon">
                        <span className={'icon ' + icon} />
                    </div>
                    <div className="value">
                        {(decimalPlaces !== undefined
                            ? value.toFixed(decimalPlaces)
                            : value) +
                            ' ' +
                            unit}
                    </div>
                </div>
            );
        }

        return content;
    }
}

export default ActualParametersData;
