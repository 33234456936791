export const CALIBRATION = 7;
export const LEAKAGE = 9;
export const LEVEL_CHANGE = 13;
export const SUPPLY = 17;
export const REFUELING = 19;
export const FUEL_TANK_STATES = [SUPPLY, REFUELING].map(createId);
export const FUEL_TANK_LEVEL_STATES = [
    CALIBRATION,
    LEAKAGE,
    LEVEL_CHANGE,
    SUPPLY,
    REFUELING,
].map(createId);
export const EVENT_NAME = (() => {
    const eventMap = [];
    for (let i = 0; i < 21; i++) {
        eventMap[i] = 'XTP' + i;
    }
    return eventMap;
})();

function createId(type: number) {
    return '14XTP' + type;
}
