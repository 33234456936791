import { useSelector } from 'react-redux';
import {
    getAlertSettingsSourceSet,
    getLocationsRegister,
    getSourceSetElements,
} from '.';

export const useGetSourceSetElements = () => useSelector(getSourceSetElements);
export const useAlertSettingsSourceSet = () =>
    useSelector(getAlertSettingsSourceSet);
export const useLocationsRegister = () => useSelector(getLocationsRegister);
