import React, { Component } from 'react';

import { REVERSED_LOGIC } from '../../../constants/dictionaries/TankLogic';
import Translator from '../../../helpers/TranslationHelper';
import { IFuelTank } from './types';
import { Connectable, TConnectableProps } from '../Connectable.hoc';
import { compose } from 'redux';
import GroupsList from '../_shared/GroupsList';
interface IProps {
    fuelTank: IFuelTank;
    onSend?: (fuelTank: IFuelTank) => void;
}

type TProps = IProps & TConnectableProps;

class FuelTankRow extends Component<TProps> {
    public handleSendClick = () => {
        const { onSend } = this.props;
        if (onSend) {
            onSend(this.props.fuelTank);
        }
    };

    public handleEditClick = () => {
        this.props.showFuelTankDialog({ fuelTank: this.props.fuelTank });
    };

    renderGroups = () => {
        const groups = this.props.fuelTank.groups;
        if (groups) {
            return <GroupsList groups={groups} />;
        }
        return null;
    };

    public render() {
        const {
            name,
            capacity,
            reserve,
            logicType,
            fuelType,
            description,
            monitoredId,
            timeZone,
        } = this.props.fuelTank;
        const logicDisplayName =
            logicType === REVERSED_LOGIC
                ? Translator.translate('Reversed')
                : Translator.translate('Simple');
        const columns = [
            name,
            (capacity || 0).toFixed(0),
            (reserve || 0).toFixed(0),
            logicDisplayName,
            this.renderGroups(),
            fuelType,
            description,
            timeZone && timeZone.name,
            // eslint-disable-next-line react/jsx-key
            [
                <button
                    key={'edit'}
                    className="button button-link"
                    onClick={this.handleEditClick}
                >
                    <span className="icon icon-pencil2" />
                    {Translator.translate('Edit')}
                </button>,

                monitoredId && (
                    <button
                        key={'send'}
                        className="button button-link"
                        onClick={this.handleSendClick}
                    >
                        <span className="icon icon-export-cards" />
                        {Translator.translate('Synchronize cards')}
                    </button>
                ),
            ],
        ];
        return (
            <tr>
                {columns.map((column, index) => (
                    <td key={index} className="column">
                        {column}
                    </td>
                ))}
            </tr>
        );
    }
}

export default compose(Connectable)(FuelTankRow);
