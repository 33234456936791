import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        icon: {
            display: 'flex',
            color: theme.palette.grey[500],
            marginRight: theme.spacing(1),
            cursor: 'pointer',
            alignItems: 'center',
        },
        flexCentered: {
            display: 'flex',
            alignItems: 'center',
        },
    })
);
