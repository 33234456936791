import React from 'react';
import TranslationHelper from '../../helpers/TranslationHelper';
import {
    AutocompleteColumn,
    RowData,
    SelectColumn,
    TextFieldColumn,
} from './types';
import { Autocomplete, TextField } from '@mui/material';
import { MenuItem } from '@material-ui/core';

interface TextFieldProps<T> {
    column: TextFieldColumn;
    row: T;
    handleValueChange: (
        rowId: string | number,
        columnId: string,
        value: any
    ) => void;
}

interface SelectProps<T> {
    column: SelectColumn;
    row: T;
    handleValueChange: (
        rowId: string | number,
        columnId: string,
        value: any
    ) => void;
}

interface AutocompleteProps<T> {
    column: AutocompleteColumn;
    row: T;
    handleValueChange: (
        rowId: string | number,
        columnId: string,
        value: any
    ) => void;
}
export const TextFieldCell = ({
    handleValueChange,
    column,
    row,
}: TextFieldProps<RowData>) => {
    const value = row[column.id];

    return (
        <TextField
            variant="outlined"
            size="small"
            value={value || ''}
            onChange={(e) =>
                handleValueChange(row.id, column.id, e.target.value)
            }
            placeholder={TranslationHelper.translate(
                column.placeholder || '...'
            )}
            type={column.inputType || 'text'}
            disabled={row.disabled || column.disabled}
            error={column.hasError?.(value)}
            helperText={column.getErrorText?.(value)}
        />
    );
};

export const SelectCell = ({
    handleValueChange,
    column,
    row,
}: SelectProps<RowData>) => {
    const value = row[column.id];
    return (
        <TextField
            id="defaultValue"
            label={TranslationHelper.translate('Default value')}
            name="defaultValue"
            select={true}
            size="small"
            variant="outlined"
            margin="dense"
            value={value}
            disabled={row.disabled || column.disabled}
            onChange={(e) =>
                handleValueChange(row.id, column.id, e.target.value)
            }
        >
            {column.options?.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                    {TranslationHelper.translate(option.label)}
                </MenuItem>
            ))}
        </TextField>
    );
};

export const AutocompleteCell = ({
    handleValueChange,
    column,
    row,
}: AutocompleteProps<RowData>) => {
    const value = row[column.id];

    return (
        <Autocomplete
            options={
                column.options?.map((option) => ({
                    label: TranslationHelper.translate(String(option.label)),
                    value: String(option.value),
                })) || []
            }
            value={value?.toString() || ''}
            noOptionsText={TranslationHelper.translate('No options to display')}
            freeSolo={column.freeSolo}
            size="small"
            inputValue={value?.toString() || ''}
            disableClearable
            onInputChange={(event, newInputValue, reason) => {
                if (!column.freeSolo && reason === 'input') {
                    return;
                }
                if (
                    column.valueType === 'integer' &&
                    typeof newInputValue === 'string' &&
                    /^[0-9]*$/.test(newInputValue)
                ) {
                    handleValueChange(row.id, column.id, newInputValue);
                } else if (
                    column.valueType === 'number' &&
                    typeof newInputValue === 'string' &&
                    /^[+-]?\d*(\.\d*)?$/.test(newInputValue)
                ) {
                    handleValueChange(row.id, column.id, newInputValue);
                } else if (column.valueType === 'string') {
                    handleValueChange(row.id, column.id, newInputValue);
                }
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant="outlined"
                    label={TranslationHelper.translate(column.label)}
                    margin="dense"
                    disabled={row.disabled || column.disabled}
                />
            )}
        />
    );
};
