import { connect } from 'react-redux';
import { TRootState } from '../../../../../../store';

import { activatePreview } from '../../../../../../state/ui/discovery/snapshotting';
import { getUserSettings } from '../../../../../../state/user';
import { getPreviewCreatorLevel } from '../../../../selectors';
import { getPreviewSourceSetElement } from '../../../../selectors/composed';

const mapStateToProps = (state: TRootState) => ({
    userSettings: getUserSettings(state),
    creatorLevel: getPreviewCreatorLevel(state),
    sourceSetElementReportSet: getPreviewSourceSetElement(state),
});
type TStateProps = ReturnType<typeof mapStateToProps>;

const mapDispatchToProps = {
    activatePreview,
};

type TDispatchProps = typeof mapDispatchToProps;

export type TConnectableProps = TStateProps & TDispatchProps;

export const Connectable = connect(mapStateToProps, mapDispatchToProps);
