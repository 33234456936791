import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => {
    const padding = theme.spacing(2);

    return createStyles({
        dataBlock: {
            flexGrow: 1,
            overflowY: 'auto',
            marginTop: theme.spacing(3),
        },
        wrapper: {
            paddingTop: padding,
            paddingLeft: padding,
            paddingRight: padding,
        },
        innerWrapper: {
            padding: `${padding}px`,
        },
        actionsWrapper: {
            display: 'flex',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            marginTop: theme.spacing(),

            '& button': {
                marginBottom: padding / 2,
            },
        },
        label: {
            fontSize: '12px',
            fontWeight: 'bold',
            marginTop: padding / 2,
            marginBottom: padding / 2,
        },
        coordinates: {
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: padding,
            marginBottom: padding,
        },
        options: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
        },
    });
});
