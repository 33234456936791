import React from 'react';
import {
    MobileDatePicker,
    MobileDateTimePicker,
    MobileTimePicker,
} from '@mui/x-date-pickers';
import moment from 'moment';

import { RowData } from './types';

import { useUserSettings } from '../../state/user/index.hooks';

import TranslationHelper from '../../helpers/TranslationHelper';

import { IEditableCellProps } from './EditableTableCell';

export const DateCellComponent = ({
    handleValueChange,
    column,
    row,
}: IEditableCellProps<RowData>) => {
    const value = row[column.id];

    const dateTranslations = TranslationHelper.makeDateTimeTranslations();

    return (
        <MobileDatePicker
            views={['year', 'month', 'day']}
            localeText={dateTranslations}
            disableFuture={true}
            value={value ? moment(value) : null}
            onChange={(value) => handleValueChange(row.id, column.id, value)}
            label={TranslationHelper.translate('Default value')}
            slotProps={{
                textField: {
                    variant: 'standard',
                    error: false,
                },
            }}
            disabled={row.disabled || column.disabled}
        />
    );
};

export const TimeCellComponent = ({
    handleValueChange,
    column,
    row,
}: IEditableCellProps<RowData>) => {
    const value = row[column.id];

    const dateTranslations = TranslationHelper.makeDateTimeTranslations();

    return (
        <MobileTimePicker
            views={['hours', 'minutes', 'seconds']}
            localeText={dateTranslations}
            ampm={false}
            disableFuture={true}
            value={value ? moment(value) : null}
            onChange={(value) => handleValueChange(row.id, column.id, value)}
            label={TranslationHelper.translate('Default value')}
            slotProps={{
                textField: {
                    variant: 'standard',
                    error: false,
                },
            }}
            disabled={row.disabled || column.disabled}
        />
    );
};

export const DateTimeCell = ({
    handleValueChange,
    column,
    row,
}: IEditableCellProps<RowData>) => {
    const value = row[column.id];

    const dateTranslations = TranslationHelper.makeDateTimeTranslations();
    const userSettings = useUserSettings();

    return (
        <MobileDateTimePicker
            views={['year', 'month', 'day', 'hours', 'minutes', 'seconds']}
            localeText={dateTranslations}
            ampm={false}
            disableFuture={true}
            value={value ? moment(value) : null}
            onChange={(value) => handleValueChange(row.id, column.id, value)}
            format={userSettings.dateFormat}
            label={TranslationHelper.translate('Default value')}
            slotProps={{
                textField: {
                    variant: 'standard',
                    error: false,
                },
            }}
            disabled={row.disabled || column.disabled}
        />
    );
};
