import { ColumnApi, GridApi } from 'ag-grid-community';

export const printGrid = (
    gridColumnApi: ColumnApi,
    gridApi: GridApi,
    fullscreen: boolean,
    handleFullscreen: () => void
) => {
    if (!fullscreen) {
        handleFullscreen();
    }
    if (gridColumnApi !== null) {
        let columns = gridColumnApi
            .getAllColumns()
            ?.filter((column) => column.isVisible());

        columns = columns?.slice(21, columns.length);

        columns && gridColumnApi.setColumnsVisible(columns, false);

        setTimeout(() => {
            gridApi.setDomLayout('print');

            setTimeout(() => {
                print();
                gridApi.setDomLayout('normal');
                columns && gridColumnApi.setColumnsVisible(columns, true);

                if (!fullscreen) {
                    handleFullscreen();
                }
            });
        });
    }
};
