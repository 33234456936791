import React, { ReactNode, useEffect, useRef, useState } from 'react';

import { useSnackbar } from 'notistack';
import { SelectionChangedEvent } from 'ag-grid-community';

import { Divider, LinearProgress, Paper } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import {
    enterPreviewMode,
    resetMode,
} from '../../../../../state/ui/customerService/bottomGrid';
import {
    CONTRACT_ITEMS_GRID_ID,
    fetchContractItems,
    selectContractItem,
} from '../../../../../state/ui/customerService/bottomGrid/contractItems';
import {
    useSelectedGridItemId,
    useSelectedClientsAndLocations,
    useValidRfidFilter,
    useMap,
} from '../../../../../state/ui/customerService/clientsAndLocations/index.hooks';
import {
    useContractItems,
    useContractItemsLoading,
    useContractItemsSourceSetModel,
} from '../../../../../state/ui/customerService/bottomGrid/contractItems/index.hooks';
import { ISourceSetEntity } from '../../../../../state/types';
import {
    selectClient,
    selectLocation,
} from '../../../../../state/ui/customerService/clientsAndLocations';

import { useToolkitDispatch } from '../../../../../hooks';
import TranslationHelper from '../../../../../helpers/TranslationHelper';
import { errorMessageHandler } from '../../../../../helpers/errorMessageHandler';

import { useStyles } from '../../../Themable.hooks';

import IconButtonWithTooltip from '../../../../../components/IconButtonWithTooltip';
import PaneHeader from '../../../../../components/PaneHeader';
import SearchField from '../../../../../components/common/SearchField/SearchField';
import SourceSetGrid from '../../../../../components/SourceSetGrid';
import CscExportButtons from '../../CscExportButtons/CscExportButtons';
import { useBottomGrid } from '../../../../../state/ui/customerService/bottomGrid/index.hooks';
import {
    IFeatureSelectedOnGridParams,
    TVectorGeometrySource,
} from '../../../types';
import { zoomAfterSelect } from '../../../helpers/zoomAfterSelect';

interface IOwnProps {
    handleClose: () => void;
    titleComponent?: ReactNode;
    onFeatureSelected: (params: IFeatureSelectedOnGridParams) => void;

    source: TVectorGeometrySource;
    selectedItems: string[];
    lastSelectedBottomItem: string;
    handleSelectLastItem: (sourceSetId: string, lastItemId: string) => void;
}

const CHECKBOX_POSITION = 0;

const ContractItemsGrid = ({
    handleClose,
    titleComponent,
    onFeatureSelected,
    source,
    selectedItems,
    lastSelectedBottomItem,
    handleSelectLastItem,
}: IOwnProps) => {
    const notInitialRender = useRef(false);

    const sourceSetModel = useContractItemsSourceSetModel();

    const [filter, setFilter] = useState(sourceSetModel?.quickFilter || '');
    const [gridApi, setGridApi] = useState(undefined);

    const classes = useStyles();
    const contractItems = useContractItems();
    const loading = useContractItemsLoading();
    const selectedGrid = useBottomGrid();
    const map = useMap();

    const selectedGridItemId = useSelectedGridItemId();
    const selectedClientsAndLocations = useSelectedClientsAndLocations();
    const rfidFilter = useValidRfidFilter();

    const toolkitDispatch = useToolkitDispatch();
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        resetMultiSelection();
    }, [selectedGrid]);

    useEffect(() => {
        if (!loading) {
            handleFetchContractItems()
                ?.unwrap()
                .catch((error) => {
                    const message = errorMessageHandler(error.status)();
                    enqueueSnackbar(message, {
                        variant: 'error',
                    });
                });
        }
    }, [selectedClientsAndLocations, rfidFilter]);

    useEffect(() => {
        if (notInitialRender.current) {
            toolkitDispatch(resetMode());
        } else {
            notInitialRender.current = true;
        }
    }, [selectedGridItemId]);

    const handleFetchContractItems = () => {
        if (!selectedClientsAndLocations.length) {
            return;
        }
        return toolkitDispatch(
            fetchContractItems({
                selectedClientsAndLocations,
                rfid: rfidFilter || undefined,
            })
        );
    };

    const renderRightCustomControls = () => {
        return (
            <>
                <CscExportButtons api={gridApi} prefix="contracts" />
                <IconButtonWithTooltip
                    title={TranslationHelper.translate('Close')}
                    onClick={() => handleClose()}
                >
                    <CloseIcon />
                </IconButtonWithTooltip>
            </>
        );
    };

    const onSelectionChanged = (e: SelectionChangedEvent) => {
        const selectedNodes = e.api
            .getSelectedNodes()
            .map((selected) => {
                return {
                    id: selected.data.externalId,
                    name: selected.data.locationName,
                    clientId: selected.data.clientId,
                    clientName: selected.data.name,
                    sourceSetId: selected.data.id,
                    locationId: selected.data.locationId,
                    coordinates: selected.data.coordinate,
                    radius: selected.data.radius,
                };
            })
            .filter(Boolean);
        if (!selectedNodes.length) {
            resetMultiSelection();

            return;
        }
        onFeatureSelected({
            source,
            sourceSetId: CONTRACT_ITEMS_GRID_ID,
            items: selectedNodes.map((node) => node.sourceSetId),
        });
    };

    const resetMultiSelection = () => {
        onFeatureSelected({
            source,
            sourceSetId: CONTRACT_ITEMS_GRID_ID,
            items: [],
        });
        toolkitDispatch(resetMode());
        toolkitDispatch(selectContractItem(null));
    };

    const handleCellInteraction = (data: ISourceSetEntity) => {
        handleSelectLastItem(CONTRACT_ITEMS_GRID_ID, data.id);
        zoomAfterSelect(source, map, data.id);

        const locationId = selectedClientsAndLocations.filter(
            (item) => item.id === data.locationId?.toString()
        )[0].locationId;
        toolkitDispatch(
            selectLocation({
                id: locationId,
                externalId: data.locationId?.toString(),
            })
        );
        toolkitDispatch(selectClient(data.clientId));
        toolkitDispatch(enterPreviewMode());
        toolkitDispatch(
            selectContractItem({
                id: data.contractItemId,
                cycleId: data.cycleId,
            })
        );
    };

    const handleSetApiGrid = (
        gridApi: any,
        gridColumnApi: any | undefined = undefined
    ) => {
        setGridApi(gridApi);
    };

    const multiSelectProps = {
        rowSelection: 'multiple',
        checkBox: { index: CHECKBOX_POSITION },
        onSelectionChanged,
        items: selectedItems.map((item) => {
            return { sourceSetId: item };
        }),
    };

    return (
        <Paper className={classes.gridPane}>
            <div className={classes.wrapper}>
                <PaneHeader
                    title={TranslationHelper.translate('Contract items')}
                    titleComponent={titleComponent}
                    renderLeftCustomControls={() => (
                        <>
                            <SearchField
                                value={filter}
                                filterChange={setFilter}
                            />
                        </>
                    )}
                    renderRightCustomControls={() =>
                        renderRightCustomControls()
                    }
                />
                {!contractItems || loading ? <LinearProgress /> : <Divider />}
                {contractItems && (
                    <SourceSetGrid
                        sourceSet={contractItems}
                        cellInteractionHandler={handleCellInteraction}
                        setGridApi={handleSetApiGrid}
                        quickFilterValue={filter}
                        sourceSetModel={sourceSetModel}
                        selectedSourceSetElementId={
                            lastSelectedBottomItem || null
                        }
                        multiSelectProps={multiSelectProps}
                    />
                )}
            </div>
        </Paper>
    );
};

export default ContractItemsGrid;
