import React, { ReactNode } from 'react';

import { Box } from '@mui/material';

interface IOwnProps {
    children: ReactNode;
}
const ListItemContainer = ({ children }: IOwnProps) => {
    return (
        <Box sx={{ p: 1, display: 'flex', alignItems: 'center' }}>
            {children}
        </Box>
    );
};

export default ListItemContainer;
