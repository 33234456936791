import React from 'react';

import { Checkbox, FormControlLabel } from '@material-ui/core';

import { useStyles } from '../../Themable.hooks';

import Translator from '../../../../helpers/TranslationHelper';
interface IOwnProps {
    onlyNotAssigned: boolean;
    handleOnChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    disabled: boolean;
}

export const NotAssignedSwitchComponent = ({
    onlyNotAssigned,
    handleOnChange,
    disabled,
}: IOwnProps) => {
    const classes = useStyles();

    return (
        <>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={onlyNotAssigned}
                        onChange={handleOnChange}
                        value="onlyNotAssigned"
                        classes={{
                            root: classes.colorCheckbox,
                            checked: classes.colorChecked,
                        }}
                        disabled={disabled}
                    />
                }
                label={Translator.translate('Only not assigned')}
                classes={{
                    label: classes.label,
                }}
            />
        </>
    );
};
