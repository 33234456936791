import React from 'react';

import { Formik, FormikActions } from 'formik';

import LinearProgress from '@material-ui/core/LinearProgress';

import { useCreationRequest } from '../../Connectable.hooks';
import { useStyles } from '../../Themable.hooks';

import { trimObjectValues } from '../../../../../../helpers/TrimValuesHelper';
import { TFormCreateUser, titles } from '../../settings';
import { resetMode } from '../../../../../../state/ui/usersSlice';
import { createUser } from '../../../../../../state/app/collections';

import { useAppDispatch } from '../../../../../../hooks';

import UserPaneHeader from '../UserPaneHeader';

import { userCreationSchema } from '../../../../../../schemas';
import { IRestPostUser } from '../../../../../../services/users';
import UserForm from '../UserForm/UserForm';

const blankFormUser: TFormCreateUser = {
    login: '',
    name: '',
    surname: '',
    email: '',
    roles: {},
};

const UserCreationForm = () => {
    const classes = useStyles();

    const creationRequest = useCreationRequest();

    const dispatch = useAppDispatch();

    const handleCloseClick = () => {
        dispatch(resetMode());
    };

    const handleValues = (
        { roles, ...values }: TFormCreateUser,
        { setSubmitting }: FormikActions<TFormCreateUser>
    ) => {
        dispatch(
            createUser(
                {
                    ...(trimObjectValues(values) as IRestPostUser),
                    roles: Object.keys(roles)
                        .filter((key) => roles[key] === true)
                        .map((key) => parseInt(key, 10)),
                },
                () => setSubmitting(false)
            )
        );
    };

    return (
        <Formik
            enableReinitialize={true}
            initialValues={blankFormUser}
            onSubmit={handleValues}
            validateOnBlur={false}
            validateOnChange={false}
            validationSchema={userCreationSchema()}
        >
            {({ submitForm, errors, isSubmitting }) => (
                <>
                    <UserPaneHeader
                        title={titles.create()}
                        onClose={handleCloseClick}
                        onSave={submitForm}
                    />

                    {creationRequest &&
                        creationRequest.success === null &&
                        creationRequest.error === null && <LinearProgress />}

                    <div className={classes.paddingWrapper}>
                        <UserForm fieldSetType="create" errors={errors} />
                    </div>
                </>
            )}
        </Formik>
    );
};

export default UserCreationForm;
