import { useState } from 'react';

const useToggle = (initial: boolean = false) => {
    const [isOpen, setIsOpen] = useState(initial);

    const handleToggle = () => {
        setIsOpen(!isOpen);
    };

    return {
        isOpen,
        handleToggle,
    };
};

export default useToggle;
