import { createStyles, Theme, WithStyles } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme: Theme) => {
    return createStyles({
        autocompleteRoot: {
            height: '100%',
            width: 300,
        },
        autocompleteInput: {
            borderRadius: theme.shape.borderRadius,
            backgroundColor: theme.palette.common.white,
            height: 38,
            flexDirection: undefined,
        },
    });
};

export type TThemableProps = WithStyles<typeof styles>;

export const Themable = withStyles(styles, { withTheme: true });
