import React, { Component, ComponentType } from 'react';
import { compose } from 'redux';
import { Connectable, TConnectableProps } from './Connectable.hoc';
import { Themable, TThemableProps } from './Themable.hoc';

import { BottomNavigation, BottomNavigationAction } from '@material-ui/core';
import { List, Map } from '@material-ui/icons';
import TranslationHelper from '../../../../helpers/TranslationHelper';
import { sourceSetIds } from '../../../../state/types';

type TProps = TThemableProps & TConnectableProps;

class MobileNavigation extends Component<TProps> {
    public actions = {
        list: () => {
            const {
                activateGrid,
                snapshotPaths,
                sourceSetId,
                setMobileGridVisibility,
            } = this.props;

            if (
                snapshotPaths.length &&
                snapshotPaths[0].length &&
                sourceSetId
            ) {
                setMobileGridVisibility(sourceSetId, true);
            } else {
                activateGrid(sourceSetIds.lastStates, {
                    type: 'category',
                    level: 0,
                });
            }
        },
        map: () =>
            this.props.sourceSetId
                ? this.props.setMobileGridVisibility(
                      this.props.sourceSetId,
                      false
                  )
                : null,
    };

    public toggleView = (value: string) => {
        if (this.actions[value]) {
            this.actions[value]();
        }
    };

    public render() {
        const { classes, listVisible } = this.props;

        const wrapperStyle = {
            root: classes.navigation,
        };

        const labelStyle = {
            root: classes.actionItemStyles,
            selected: classes.selected,
        };

        return (
            <BottomNavigation
                value={listVisible ? 'list' : 'map'}
                onChange={(event: object, value: any) => this.toggleView(value)}
                showLabels={true}
                classes={wrapperStyle}
            >
                <BottomNavigationAction
                    value={'map'}
                    label={TranslationHelper.translate('Map')}
                    icon={<Map />}
                    classes={labelStyle}
                />
                <BottomNavigationAction
                    value={'list'}
                    label={TranslationHelper.translate('List')}
                    icon={<List />}
                    classes={labelStyle}
                />
            </BottomNavigation>
        );
    }
}

export default compose(
    Themable,
    Connectable
)(MobileNavigation) as ComponentType<{}>;
