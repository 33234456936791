import React, { Component } from 'react';

import { Connectable, TConnectableProps } from '../Connectable.hoc';

import * as RFIDCardTypes from '../../../constants/dictionaries/RFIDCardTypes';
import Translator from '../../../helpers/TranslationHelper';
import { IRFIDCard } from './types';
import GroupsList from '../_shared/GroupsList';

interface IProps {
    rfidCard: IRFIDCard;
}

type TProps = IProps & TConnectableProps;

const CARD_TYPES = [
    '',
    RFIDCardTypes.ADMIN,
    RFIDCardTypes.MASTER,
    RFIDCardTypes.USER,
];

class RFIDCardRow extends Component<TProps> {
    public handleEditClick = () => {
        this.props.showRFIDCardDialog({ rfidCard: this.props.rfidCard });
    };

    renderGroups = () => {
        const groups = this.props.rfidCard.groups;
        if (groups) {
            return <GroupsList groups={groups} />;
        }
        return null;
    };

    public render() {
        const rfidCard = this.props.rfidCard;
        const isLinked =
            rfidCard.linkedObject && rfidCard.linkedObject.objectId;
        const columns = [
            rfidCard.cardNumber,
            rfidCard.code,
            this.renderGroups(),
            rfidCard.type !== undefined && CARD_TYPES[rfidCard.type],
            isLinked ? Translator.translate('Yes') : Translator.translate('No'),
            rfidCard.description,
            <button
                key="btn"
                className="button button-link"
                onClick={this.handleEditClick}
            >
                <span className="icon icon-pencil2" children={' '} />
                {Translator.translate('Edit')}
            </button>,
        ];

        return (
            <tr>
                {columns.map((column, index) => (
                    <td key={index} className="column">
                        {column}
                    </td>
                ))}
            </tr>
        );
    }
}

export default Connectable(RFIDCardRow);
