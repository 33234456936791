import { makeReduxDuck } from 'teedux';

// TODO: this will be similar for every crud users, roles, etc. We should make it generic.

interface IState {
    selectedRoleId: null | number;
    mode: null | 'preview' | 'edit' | 'create';
}

const initialState: IState = {
    selectedRoleId: null,
    mode: null,
};

const duck = makeReduxDuck('ui/roles', initialState);

export const selectRole = duck.defineAction<{ id: number }>(
    'SELECT_ROLE',
    (_, { id }) => ({
        selectedRoleId: id,
        mode: 'preview',
    })
);

export const resetMode = duck.definePayloadlessAction('RESET_MODE', () => ({
    mode: null,
}));

export const deselectRole = duck.definePayloadlessAction(
    'DESELECT_ROLE',
    () => ({
        selectedRoleId: null,
    })
);

export const enterEditMode = duck.definePayloadlessAction(
    'ENTER_EDIT_MODE',
    () => ({
        mode: 'edit',
    })
);

export const enterPreviewMode = duck.definePayloadlessAction(
    'ENTER_PREVIEW_MODE',
    () => ({
        mode: 'preview',
    })
);

export const enterCreateMode = duck.definePayloadlessAction(
    'ENTER_CREATE_MODE',
    () => ({
        mode: 'create',
        selectedRoleId: null,
    })
);

export default duck.getReducer();
