import React from 'react';

import { default as OtherDeviceIcon } from '@material-ui/icons/DevicesOther';
import { default as FuelTankIcon } from '@material-ui/icons/LocalGasStation';
import { default as VehicleIcon } from '@material-ui/icons/LocalShipping';

export default {
    other: () => <OtherDeviceIcon color="disabled" />,
    vehicle: () => <VehicleIcon color="disabled" />,
    fuelTank: () => <FuelTankIcon color="disabled" />,
};
