import React from 'react';

import TranslationHelper from '../../helpers/TranslationHelper';
import DashboardEventChart from '../EventChart';
import LoadingSpinner from '../loadingSpinner/LoadingSpinner';

interface IProps {
    allData?: any[];
    filteredData?: any[];
    data?: object | null;
    loading: boolean;
}

const EventsChart = ({ allData, filteredData, data, loading }: IProps) => {
    if (loading) {
        return (
            <div className="spinner">
                <LoadingSpinner
                    size="50"
                    top="0"
                    left="0"
                    right="0"
                    bottom="0"
                />
            </div>
        );
    } else if (allData && allData.length > 0) {
        return (
            <DashboardEventChart
                chartId="events-chart"
                allData={allData}
                filteredData={filteredData}
                data={data}
                sortedAscending={false}
            />
        );
    } else {
        return (
            <div className="no-events">
                {TranslationHelper.translate('No events for selected period')}
            </div>
        );
    }
};

export default EventsChart;
