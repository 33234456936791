import { connect, ConnectedProps } from 'react-redux';
import { TRootState } from '../../../../../../store';

import { getPreviewCreatorLevel } from '../../../../selectors';
import { getPreviewLastState } from '../../../../selectors/composed';
import {
    closePreview,
    fetchLastStates,
} from '../../../../../../state/_actions';

const mapStateToProps = (state: TRootState) => ({
    creatorLevel: getPreviewCreatorLevel(state),
    lastState: getPreviewLastState(state),
});

const mapDispatchToProps = {
    closePreview,
    fetchLastStates,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type TConnectableProps = ConnectedProps<typeof connector>;

export const Connectable = connector;
