import React, { useEffect, useState } from 'react';

import debounce from 'lodash.debounce';

import {
    fetchVehicles,
    IVehicle,
    setVehiclesFilter,
} from '../../../state/app/registers/vehicles';
import {
    useVehicles,
    useVehiclesFilter,
} from '../../../state/app/registers/index.hooks';
import {
    showVehicleDialog,
    showVehicleRFIDDialog,
} from '../../../state/ui/dialog';

import { restGetGroups } from '../../../services/groups';

import Vehicle from '../../../models/registers/Vehicle';

import Translator from '../../../helpers/TranslationHelper';

import { DEBOUNCE_MID } from '../../../constants/DebounceValues';

import { useAppDispatch } from '../../../hooks';

import Pagination from '../../common/Pagination';
import PhraseFilter from '../../common/PhraseFilter';

import VehiclesTable from './VehiclesTable';
import AsyncFilterSelect from '../../AsyncFilterSelect';
import TableWrapper from '../_shared/TableWrapper';

const Vehicles = () => {
    const [currentPage, setCurrentPage] = useState<number>(1);

    const filter = useVehiclesFilter();
    const vehicles = useVehicles();

    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(fetchVehicles(filter));
    }, [filter]);

    const handleGroupChange = (option: { id: string }) => {
        const updatedFilter = {
            ...filter,
            group: option && option.id,
            start: 0,
        };
        setCurrentPage(1);
        dispatch(setVehiclesFilter({ vehiclesFilter: updatedFilter }));
    };

    const handlePageChange = (selectedPageNumber?: number) => {
        const targetPageNumber = selectedPageNumber || currentPage;
        const limit = filter.limit || 1;
        const updatedFilter = {
            ...filter,
            start: (targetPageNumber - 1) * limit,
        };
        setCurrentPage(targetPageNumber);
        dispatch(setVehiclesFilter({ vehiclesFilter: updatedFilter }));
        const element = document.querySelector(
            '#registersPageContent .content'
        );
        element?.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    const handleFilterVehicles = debounce((name: string) => {
        const updatedFilter = {
            ...filter,
            name,
            start: 0,
        };
        setCurrentPage(1);
        dispatch(setVehiclesFilter({ vehiclesFilter: updatedFilter }));
    }, DEBOUNCE_MID);

    const handleVehicleDialogClick = (vehicle: IVehicle) => {
        dispatch(showVehicleDialog({ vehicle }));
    };

    const handleRFIDDialogClick = (vehicle: IVehicle) => {
        dispatch(showVehicleRFIDDialog({ vehicle }));
    };

    const handleAddVehicleButtonClick = () => {
        dispatch(showVehicleDialog({ vehicle: new Vehicle() }));
    };

    return (
        <section className="vehicles">
            <header className="header">
                <div className="header-filter">
                    <PhraseFilter
                        placeholder={Translator.translate('Find by name') + ' '}
                        onFilter={handleFilterVehicles}
                        filter={filter.name || ''}
                        autoFocus={true}
                        className="header-filter--input"
                    />

                    <div style={{ width: 300 }}>
                        <AsyncFilterSelect
                            name="vehicle-group"
                            selectedOptionId={filter.group}
                            getData={() => restGetGroups()}
                            placeholder={Translator.translate('Find by group')}
                            onOptionSelect={handleGroupChange}
                        />
                    </div>
                </div>

                <button
                    className="mtl-button primary"
                    onClick={handleAddVehicleButtonClick}
                >
                    {Translator.translate('New vehicle')}
                </button>
            </header>
            <TableWrapper>
                <VehiclesTable
                    vehicles={vehicles ? vehicles.items : undefined}
                    onEditVehicle={handleVehicleDialogClick}
                    onAssignRFID={handleRFIDDialogClick}
                />
            </TableWrapper>
            {vehicles && (
                <Pagination
                    totalResults={vehicles.total}
                    currentPage={currentPage}
                    onPageSelect={handlePageChange}
                    resultsPerPage={filter.limit}
                />
            )}
        </section>
    );
};

export default Vehicles;
