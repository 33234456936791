import React, { ReactChild } from 'react';
import TranslationHelper from '../../helpers/TranslationHelper';

import { Tooltip } from '@material-ui/core';
import { InfoOutlined as InfoIcon } from '@material-ui/icons';
import { useStyles } from './Themable.hooks';

interface IOwnProps {
    children: ReactChild;
    title?: string;
}

const FieldWithTooltip = ({ children, title }: IOwnProps) => {
    const classes = useStyles();

    return (
        <div className={classes.flexCentered}>
            {children}
            {title && (
                <Tooltip title={TranslationHelper.translate(title)}>
                    <div className={classes.icon} data-testid="icon">
                        <InfoIcon fontSize="small" />
                    </div>
                </Tooltip>
            )}
        </div>
    );
};

export default FieldWithTooltip;
