import React, { Component } from 'react';
import { IEmployee } from '../../../state/app/registers/employees';

import Translator from '../../../helpers/TranslationHelper';
import GroupsList from '../_shared/GroupsList';

interface IProps {
    employee: IEmployee;
    onEdit: (employee: IEmployee) => void;
    onAssign: (employee: IEmployee) => void;
}

class EmployeeRow extends Component<IProps> {
    public handleEditClick = () => {
        if (this.props.onEdit) {
            this.props.onEdit(this.props.employee);
        }
    };

    public handleAssignClick = () => {
        if (this.props.onAssign) {
            this.props.onAssign(this.props.employee);
        }
    };

    public renderActions() {
        return (
            <td>
                <button
                    className="button button-link"
                    onClick={this.handleEditClick}
                >
                    <span className="icon icon-pencil2" children={' '} />
                    {Translator.translate('Edit')}
                </button>
                <button
                    className="button button-link"
                    onClick={this.handleAssignClick}
                >
                    <span className="icon icon-add-card" children={' '} />
                    {Translator.translate('Assigning cards')}
                </button>
            </td>
        );
    }
    renderGroups = () => {
        const groups = this.props.employee.groups;
        if (groups) {
            return <GroupsList groups={groups} />;
        }
        return null;
    };

    public render() {
        const employee = this.props.employee;
        let cityWithPostalCode = null;
        let fullStreet = null;

        if (employee.address) {
            const info = employee.address;
            if (info.postalCode || info.city) {
                cityWithPostalCode =
                    (info.city ? info.city + ' ' : '') +
                    (info.postalCode || '');
            }
            if (info.street) {
                const numberSeparator = info.apartmentNumber ? '/' : '';
                fullStreet =
                    info.street +
                    ' ' +
                    (info.houseNumber
                        ? info.houseNumber + numberSeparator
                        : '') +
                    (info.apartmentNumber || '');
            }
        }

        let cards = null;
        if (employee.cards) {
            cards = employee.cards.map((card, idx) => {
                return (
                    <div key={idx}>
                        {card.cardNumber} <br />
                    </div>
                );
            });
        }

        return (
            <tr>
                <td className="column">
                    {employee.name ||
                        employee.surname + ' ' + employee.firstName}
                </td>
                <td className="column">{this.renderGroups()}</td>
                <td className="column">
                    {employee.firstName + ' ' + employee.surname}
                </td>
                <td className="column">{cards}</td>
                <td className="column">
                    <div>{fullStreet}</div>
                    <div>{cityWithPostalCode}</div>
                </td>
                <td className="column">
                    <div>{employee.email || null}</div>
                    <div>{employee.phoneNumber || null}</div>
                    <div>{employee.mobileNumber || null}</div>
                </td>
                {this.renderActions()}
            </tr>
        );
    }
}

export default EmployeeRow;
