import React, { Component, ComponentType } from 'react';

import { compose } from 'redux';

import { Connectable, TConnectableProps } from './Connectable.hoc';
import { Themable, TThemableProps } from './Themable.hoc';

import TranslationHelper from '../../../../helpers/TranslationHelper';

import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import CloseIcon from '@material-ui/icons/Close';

type TProps = TThemableProps & TConnectableProps;

class BookmarkSnackbar extends Component<TProps> {
    componentWillUnmount(): void {
        this.handleClose();
    }
    public render() {
        return (
            <div>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    open={this.props.isSnackbarOpen}
                    autoHideDuration={4000}
                    onClose={this.handleClose}
                >
                    <SnackbarContent
                        className={this.props.classes.snackbarSuccess}
                        message={
                            <span id="message-id">
                                {TranslationHelper.translate(
                                    'Data segment saved'
                                )}
                            </span>
                        }
                        action={[
                            <IconButton
                                key="close"
                                aria-label="Close"
                                color="inherit"
                                onClick={this.handleClose}
                            >
                                <CloseIcon />
                            </IconButton>,
                        ]}
                    />
                </Snackbar>
            </div>
        );
    }

    private handleClose = () => {
        this.props.handleSnackbar(false);
    };
}

export default compose(
    Themable,
    Connectable
)(BookmarkSnackbar) as ComponentType;
