import { TAttributeValue } from '../../../../../state/ui/attributes';
import { ColumnType } from '../../../../EditableTable';
import { ATTRIBUTE_TYPES } from '../../../../../constants/dictionaries/AttributeTypes';

export const getDefaultValueFieldProps = (
    type: number | string,
    dictOptions: { internal: boolean; value: TAttributeValue }[],
    freeSolo: boolean
) => {
    switch (ATTRIBUTE_TYPES[Number(type) as keyof typeof ATTRIBUTE_TYPES]) {
        case 'Integer':
            return {
                type: ColumnType.AUTOCOMPLETE,
                freeSolo,
                valueType: 'integer',
                options: dictOptions.map((opt) => {
                    return { value: opt.value, label: opt.value };
                }),
            } as any;
        case 'String':
            return {
                type: ColumnType.AUTOCOMPLETE,
                freeSolo,
                valueType: 'string',

                options: dictOptions.map((opt) => {
                    return { value: opt.value, label: opt.value };
                }),
            };
        case 'Boolean':
            return {
                type: ColumnType.SELECT,
                options: [
                    { value: true, label: 'True' },
                    { value: false, label: 'False' },
                ],
            };
        case 'Double':
            return {
                type: ColumnType.AUTOCOMPLETE,
                freeSolo,
                valueType: 'number',
                options: dictOptions.map((opt) => {
                    return { value: opt.value, label: opt.value };
                }),
            } as any;
        case 'Date':
            return {
                type: ColumnType.DATE,
            };

        case 'Time':
            return {
                type: ColumnType.TIME,
            };
        case 'DateTime':
            return {
                type: ColumnType.DATETIME,
            };
        case 'File':
            return {
                type: ColumnType.DISABLED,
            };
        default:
            return {
                type: ColumnType.TEXTFIELD,
            };
    }
};

export const getDefaultValue = (
    value: TAttributeValue | undefined,
    type: string | number
) => {
    switch (ATTRIBUTE_TYPES[Number(type) as keyof typeof ATTRIBUTE_TYPES]) {
        case 'Integer':
        case 'Double':
            return value || value === 0 ? Number(value) : undefined;
        default:
            return value === '' ? undefined : value;
    }
};

export const getDictValue = (
    value: string | number | boolean,
    type: string | number
) => {
    switch (ATTRIBUTE_TYPES[Number(type) as keyof typeof ATTRIBUTE_TYPES]) {
        case 'Integer':
        case 'Double':
            return Number(value);
        default:
            return value;
    }
};
