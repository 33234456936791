import React, { Component, ComponentType } from 'react';
import { compose } from 'redux';

import { default as Paper } from '@material-ui/core/Paper';

import GoogleMapsButton from '../../../../../../components/common/GoogleMapsButton';
import PaneHeader from '../../../../../../components/PaneHeader';
import SourceSetElement from '../SourceSetElementPreview/components/SourceSetElement';

import { Connectable, TConnectableProps } from './Connectable.hoc';
import { Themable, TThemableProps } from './Themable.hoc';

import TranslationHelper from '../../../../../../helpers/TranslationHelper';
import { getHeaderName } from '../../_utils';
import { IMovie } from 'src/app/state/types';
import { LinearProgress } from '@material-ui/core';

type TProps = TConnectableProps & TThemableProps;

class FrameDetailsPreview extends Component<TProps> {
    private fetchRecognizedNumbersSection(movies: IMovie[] | undefined) {
        const descriptions = movies
            ?.filter((movie) => movie.frameValid)
            .map(
                (movie) => `${movie.paramName},${movie.fileId},${movie.offset}`
            );
        if (descriptions && descriptions.length > 0) {
            this.props.fetchRecognizedNumbersSection(descriptions);
        }
    }

    public componentDidMount() {
        const {
            preview,
            privileges,
            fetchFrameDetails,
            resetRecognizedNumbersSection,
        } = this.props;
        resetRecognizedNumbersSection();
        if (preview && preview.previewAction) {
            fetchFrameDetails(preview.previewAction);
            if (privileges.experimentalFeatures) {
                this.fetchRecognizedNumbersSection(
                    preview.previewAction.params?.movies
                );
            }
        }
    }

    public render() {
        const {
            classes,
            sourceSetElementReportSet,
            isRecognizedNumbersBeingFetched,
        } = this.props;

        return (
            sourceSetElementReportSet && (
                <Paper className={classes.paper}>
                    <PaneHeader
                        title={TranslationHelper.translate('Details')}
                        onCloseClick={this.handleCloseClick}
                        renderRightCustomControls={() => (
                            <GoogleMapsButton
                                coordinates={
                                    sourceSetElementReportSet?._meta?.header
                                        ?.coordinates
                                }
                            />
                        )}
                    />
                    {isRecognizedNumbersBeingFetched ? (
                        <LinearProgress />
                    ) : (
                        <SourceSetElement
                            sourceSetElementReportSet={
                                sourceSetElementReportSet
                            }
                            creatorLevel={this.props.creatorLevel}
                            getHeaderName={getHeaderName}
                        />
                    )}
                </Paper>
            )
        );
    }

    private handleCloseClick = () => {
        this.props.closePreview(this.props.creatorLevel);
    };
}

export default compose(
    Themable,
    Connectable
)(FrameDetailsPreview) as ComponentType<{}>;
