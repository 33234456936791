import { createStyles, Theme, WithStyles } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme: Theme) => {
    return createStyles({
        container: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
        },
        popover: {
            zIndex: 1,
            width: '90%',
            maxHeight: 300,
        },
        popoverPaper: {
            maxHeight: 300,
            overflowY: 'auto',
        },
        input: {
            display: 'flex',
            flexGrow: 2,
        },
        noItemsFound: {
            padding: theme.spacing(2),
        },
        listItem: {
            display: 'flex',
            justifyContent: 'space-between',
        },
        progress: {
            marginTop: -theme.spacing(),
        },
    });
};

export type TThemableProps = WithStyles<typeof styles>;

export const Themable = withStyles(styles, { withTheme: true });
