import { DependencyList, EffectCallback, useCallback, useEffect } from 'react';

export const useDebounceEffect = (
    effect: EffectCallback,
    deps: DependencyList,
    delay: number
) => {
    const callback = useCallback(effect, deps);

    useEffect(() => {
        const handler = setTimeout(() => {
            callback();
        }, delay);

        return () => {
            clearTimeout(handler);
        };
    }, [callback, delay]);
};

export const useIntervalEffect = (
    effect: EffectCallback,
    deps: DependencyList,
    interval: number
) => {
    const callback = useCallback(effect, deps);

    useEffect(() => {
        const handler = setInterval(() => {
            callback();
        }, interval);

        return () => {
            clearInterval(handler);
        };
    }, [callback, interval]);
};
