import { connect } from 'react-redux';

import {
    activatePreview,
    resetSearcher,
    toggleMobileSearch,
    toggleSearchResults,
} from '../../../../state/_actions';
import { TRootState } from '../../../../store';
import { getSearchedMonitoredObject } from '../../selectors/entities';

interface IProps {
    monitoredObjectId: string;
}

const mapStateToProps = (state: TRootState, props: IProps) => ({
    monitoredObject: getSearchedMonitoredObject(state, props.monitoredObjectId),
});

const mapDispatchToProps = {
    activatePreview,
    toggleSearchResults,
    resetSearcher,
    toggleMobileSearch,
};

type TStateProps = ReturnType<typeof mapStateToProps>;
type TDispatchProps = typeof mapDispatchToProps;

export type TConnectableProps = TStateProps & TDispatchProps;

export const Connectable = connect(mapStateToProps, mapDispatchToProps);
