import React, { useEffect } from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import TranslationHelper from '../../helpers/TranslationHelper';

import { ICoordinates } from '../../state/types';

const initialState = {
    mouseX: null,
    mouseY: null,
};

interface IOwnProps {
    position: ICoordinates | null;
    close: (reason?: string) => void;
    action: () => void;
    buttonText: string;
}
const ContextMenu = ({ position, close, action, buttonText }: IOwnProps) => {
    const [state, setState] = React.useState<{
        mouseX: null | number;
        mouseY: null | number;
    }>(initialState);

    useEffect(() => {
        if (position) {
            setState({
                mouseX: position.x + 10,
                mouseY: position.y - 20,
            });
        }
    }, [position]);

    const handleClose = (event?: object, reason?: string) => {
        close();
        setState(initialState);
    };

    const handleAction = () => {
        action();
        setState(initialState);
    };

    const getAnchorPosition =
        state.mouseY !== null && state.mouseX !== null
            ? { top: state.mouseY, left: state.mouseX }
            : undefined;
    return (
        <Menu
            keepMounted
            open={!!state.mouseY}
            onClose={handleClose}
            anchorReference="anchorPosition"
            anchorPosition={getAnchorPosition}
        >
            <MenuItem onClick={handleAction}>
                {TranslationHelper.translate(buttonText)}
            </MenuItem>
        </Menu>
    );
};

export default ContextMenu;
