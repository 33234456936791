import { useSelector } from 'react-redux';
import {
    getCurrentGridConfiguration,
    getGridView,
    getGridViewStatus,
    getGridViews,
    getSelectedGridViewModified,
    getSelectedGridViewId,
    getSelectedViewName,
} from './index';

export const useGridView = () => useSelector(getGridView);
export const useGridViews = () => useSelector(getGridViews);
export const useGridViewStatus = () => useSelector(getGridViewStatus);
export const useSelectedGridViewId = () => useSelector(getSelectedGridViewId);
export const useCurrentGridConfiguration = () =>
    useSelector(getCurrentGridConfiguration);
export const useSelectedViewName = () => useSelector(getSelectedViewName);
export const useSelectedGridViewModified = () =>
    useSelector(getSelectedGridViewModified);
