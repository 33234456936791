import React, { Component } from 'react';

import Translator from '../../../helpers/TranslationHelper';
import FuelTankRow from './FuelTankRow';
import { IFuelTank } from './types';

interface IProps {
    fuelTanks?: IFuelTank[];
    onSendCards: (fuelTank: IFuelTank) => void;
}

const COLSPAN = 9;

class FuelTankTable extends Component<IProps> {
    public renderTableBody() {
        const fuelTanks = this.props.fuelTanks;
        let items = null;
        if (fuelTanks) {
            if (fuelTanks.length > 0) {
                items = fuelTanks.map((fuelTank, index) => {
                    return (
                        <FuelTankRow
                            fuelTank={fuelTank}
                            key={index}
                            onSend={this.props.onSendCards}
                        />
                    );
                });
            } else {
                items = (
                    <tr>
                        <td colSpan={COLSPAN} className="table--empty-state">
                            {Translator.translate(
                                'There are no fuel tanks to display'
                            )}
                        </td>
                    </tr>
                );
            }
        } else {
            items = (
                <tr>
                    <td colSpan={COLSPAN} className="table--empty-state">
                        {Translator.translate('Loading...')}
                    </td>
                </tr>
            );
        }
        return items;
    }

    public render() {
        return (
            <table className="table table__hoverable">
                <thead>
                    <tr>
                        <th>{Translator.translate('FuelTank_Name')}</th>
                        <th>{Translator.translate('FuelTank_Capacity')}</th>
                        <th>{Translator.translate('FuelTank_Reserve')}</th>
                        <th>{Translator.translate('Logic')}</th>
                        <th>{Translator.translate('Group')}</th>
                        <th>{Translator.translate('Content')}</th>
                        <th>{Translator.translate('FuelTank_Description')}</th>
                        <th>{Translator.translate('Time zone')}</th>
                        <th>{Translator.translate('Actions')}</th>
                    </tr>
                </thead>

                <tbody>{this.renderTableBody()}</tbody>
            </table>
        );
    }
}

export default FuelTankTable;
