import React from 'react';

import { Button } from '@material-ui/core';

import TranslationHelper from '../../../helpers/TranslationHelper';

import { useStyles } from '../Themable.hooks';
import { useDeviceRegisterStatus } from '../../../state/ui/trustedDevices/index.hooks';
import LoadingSpinner from '../../../components/loadingSpinner/LoadingSpinner';
import { useAppDispatch } from '../../../hooks';
import { logout } from '../../../state/_actions/logoutAction';

interface IOwnProps {
    onClick: (params?: any) => void;
}
const TrustedDeviceActions = ({ onClick }: IOwnProps) => {
    const classes = useStyles();
    const dispatch = useAppDispatch();
    const status = useDeviceRegisterStatus();
    return (
        <div className={classes.deviceDetailsRegisterActions}>
            {status === 'idle' && (
                <>
                    <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                        className={classes.formButtons}
                        onClick={() => onClick()}
                    >
                        {TranslationHelper.translate('auth.registerButton')}
                    </Button>
                    <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                        className={classes.formButtons}
                        onClick={() => dispatch(logout())}
                    >
                        {TranslationHelper.translate('Back to login page')}
                    </Button>
                </>
            )}
            {status === 'loading' && (
                <>
                    <div className={classes.spinner}>
                        <LoadingSpinner progressSize="45px" />
                    </div>
                </>
            )}
            {status === 'done' && (
                //empty div just to justify no content
                <div className={classes.spinner} />
            )}
        </div>
    );
};

export default TrustedDeviceActions;
