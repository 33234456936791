import { connect } from 'react-redux';
import { TRootState } from '../../store';

import { getUserSettings } from '../../state/user';

const mapStateToProps = (state: TRootState) => ({
    userSettings: getUserSettings(state),
});

const mapDispatchToProps = {};

type TStateProps = ReturnType<typeof mapStateToProps>;
type TDispatchProps = typeof mapDispatchToProps;

export type TConnectableProps = TStateProps & TDispatchProps;

export const Connectable = connect(mapStateToProps, mapDispatchToProps);
