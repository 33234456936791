import React, { Component, ComponentType } from 'react';
import { compose } from 'redux';

import { Paper, Typography } from '@material-ui/core';

import PaneHeader from '../../../../../../components/PaneHeader';
import ReportSet from '../../../ReportSet';

import { Connectable, TConnectableProps } from './Connectable.hoc';
import { Themable, TThemableProps } from './Themable.hoc';

import { IReportSet } from '../../../../../../state/types';

import TimeFormatter from '../../../../../../helpers/TimeFormatter';

import TranslationHelper from '../../../../../../helpers/TranslationHelper';
import { fetchDataStatus } from '../../../../../../services/discovery/fetchDataStatus';
import { TPreviewPane } from '../../../../../../state/ui/discovery/snapshotting';

import { default as LinearProgress } from '@material-ui/core/LinearProgress';
import { withSnackbar, WithSnackbarProps } from 'notistack';
import { responseMessage } from '../../../../../../helpers/responseMessage';

type TProps = TThemableProps & TConnectableProps & WithSnackbarProps;

class ReportSetPreview extends Component<TProps> {
    private componentIsMount = false;

    public getData = (preview: TPreviewPane) => {
        if (preview !== null) {
            if (preview.reportSetParams) {
                const toTimeToISO = !!preview.reportSetParams.to
                    ? TimeFormatter.toISOString(preview.reportSetParams.to)
                    : null;
                this.props.fetchReportSet(
                    preview.reportSetParams.monitoredId,
                    TimeFormatter.toISOString(preview.reportSetParams.from),
                    toTimeToISO
                );
                const toTimeGetISO = !!preview.reportSetParams.to
                    ? TimeFormatter.getISOString(preview.reportSetParams.to)
                    : null;
                this.props.fetchTrail(
                    preview.reportSetParams.objectId,
                    TimeFormatter.getISOString(preview.reportSetParams.from),
                    toTimeGetISO,
                    this.props.currentSnapshot?.level
                );
            }
        }
    };

    public componentDidMount() {
        this.componentIsMount = true;
        const {
            preview,
            notifyRestorerToContinue,
            notifyRestorerToWait,
            isStateRestoring,
        } = this.props;

        if (preview !== null) {
            const getStatus = (promise = Promise.resolve(true)) => {
                promise.then(() => {
                    if (
                        preview.reportSetParams &&
                        !!preview.reportSetParams.from
                    ) {
                        const toTime = !!preview.reportSetParams.to
                            ? TimeFormatter.toISOString(
                                  preview.reportSetParams.to
                              )
                            : null;
                        fetchDataStatus(
                            [preview.reportSetParams.objectId],
                            TimeFormatter.toISOString(
                                preview.reportSetParams.from
                            ),
                            toTime
                        )
                            .then((code) => {
                                if (this.componentIsMount) {
                                    if (code === 202) {
                                        getStatus(
                                            new Promise((resolve) => {
                                                setTimeout(
                                                    () => resolve(true),
                                                    2000
                                                );
                                            })
                                        );
                                    }
                                    if (code === 200) {
                                        this.getData(preview);
                                        if (isStateRestoring) {
                                            notifyRestorerToContinue();
                                        }
                                    }
                                }
                            })
                            .catch((error) => {
                                const message = responseMessage(error.status);
                                this.props.enqueueSnackbar(
                                    TranslationHelper.translate(message.text),
                                    {
                                        variant: message.type,
                                    }
                                );

                                this.handleCloseClick();
                            });
                    } else {
                        this.props.resetTrail();
                        if (isStateRestoring) {
                            notifyRestorerToContinue();
                        }
                    }
                });
            };
            if (isStateRestoring) {
                notifyRestorerToWait();
            }
            getStatus();
        }
    }

    public componentWillUnmount() {
        this.componentIsMount = false;
    }

    public render() {
        const { classes, reportSet, preview } = this.props;

        const objectName = reportSet?._meta?.header?.name;
        const title = (objectName ? [objectName] : [])
            .concat(TranslationHelper.translate('Reports'))
            .join(' - ');
        const hasStartDate =
            preview &&
            preview.reportSetParams &&
            !!preview.reportSetParams.from;

        return (
            <React.Fragment>
                {
                    <Paper className={classes.wrapper}>
                        <PaneHeader
                            title={title}
                            onCloseClick={this.handleCloseClick}
                        />
                        {!reportSet ? (
                            (hasStartDate && <LinearProgress />) || (
                                <Typography
                                    align="center"
                                    variant="h6"
                                    style={{ marginTop: 10 }}
                                >
                                    {TranslationHelper.translate(
                                        'The route has not been started yet'
                                    )}
                                </Typography>
                            )
                        ) : (
                            <ReportSet
                                reportSet={this.props.reportSet as IReportSet}
                                creatorLevel={this.props.creatorLevel}
                                preview={preview}
                            />
                        )}
                    </Paper>
                }
            </React.Fragment>
        );
    }

    private handleCloseClick = () => {
        this.props.resetLevel(this.props.creatorLevel);
    };
}

export default compose(
    Themable,
    Connectable,
    withSnackbar
)(ReportSetPreview) as ComponentType<{}>;
