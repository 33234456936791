import React, { useEffect, useState } from 'react';

import debounce from 'lodash.debounce';

import {
    useEmployees,
    useEmployeesFilter,
} from '../../../state/app/registers/index.hooks';
import {
    fetchEmployees,
    IEmployee,
    setEmployeesFilter,
} from '../../../state/app/registers/employees';
import {
    showEmployeeDialog,
    showEmployeeRFIDDialog,
} from '../../../state/ui/dialog';

import { restGetGroups } from '../../../services/groups';

import Employee from '../../../models/registers/Employee';

import Translator from '../../../helpers/TranslationHelper';

import { DEBOUNCE_MID } from '../../../constants/DebounceValues';

import { useAppDispatch } from '../../../hooks';

import Pagination from '../../common/Pagination';
import PhraseFilter from '../../common/PhraseFilter';
import EmployeesTable from './EmployeesTable';
import AsyncFilterSelect from '../../AsyncFilterSelect';
import TableWrapper from '../_shared/TableWrapper';

const Employees = () => {
    const [currentPage, setCurrentPage] = useState<number>(1);

    const filter = useEmployeesFilter();
    const employees = useEmployees();

    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(fetchEmployees(filter));
    }, [filter]);

    const handleGroupChange = (option: { id: string }) => {
        const updatedFilter = {
            ...filter,
            group: option && option.id,
            start: 0,
        };
        setCurrentPage(1);
        dispatch(setEmployeesFilter({ employeesFilter: updatedFilter }));
    };

    const handlePageChange = (selectedPageNumber: number) => {
        const targetPageNumber = selectedPageNumber || currentPage;
        const limit = filter.limit || 1;
        const updatedFilter = {
            ...filter,
            start: (targetPageNumber - 1) * limit,
        };
        setCurrentPage(targetPageNumber);
        dispatch(setEmployeesFilter({ employeesFilter: updatedFilter }));

        const element = document.querySelector(
            '#registersPageContent .content'
        );
        element?.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    const handleFilterEmployees = debounce((name: string) => {
        const updatedFilter = {
            ...filter,
            name,
            start: 0,
        };
        setCurrentPage(1);
        dispatch(setEmployeesFilter({ employeesFilter: updatedFilter }));
    }, DEBOUNCE_MID);

    const handleAddEmployeeButtonClick = () => {
        dispatch(showEmployeeDialog({ employee: new Employee() }));
    };

    const handleEmployeeDialogClick = (employee: IEmployee) => {
        dispatch(showEmployeeDialog({ employee }));
    };

    const handleRFIDDialogClick = (employee: IEmployee) => {
        dispatch(showEmployeeRFIDDialog({ employee }));
    };

    return (
        <section className="employees">
            <header className="header">
                <div className="header-filter">
                    <PhraseFilter
                        placeholder={Translator.translate('Find by name') + ' '}
                        onFilter={handleFilterEmployees}
                        filter={filter.name || ''}
                        autoFocus={true}
                        className="header-filter--input"
                    />
                    <div style={{ width: 300 }}>
                        <AsyncFilterSelect
                            name="employee-group"
                            selectedOptionId={filter.group}
                            getData={() => restGetGroups()}
                            placeholder={Translator.translate('Find by group')}
                            onOptionSelect={handleGroupChange}
                        />
                    </div>
                </div>

                <button
                    className="mtl-button primary"
                    onClick={handleAddEmployeeButtonClick}
                >
                    {Translator.translate('New employee')}
                </button>
            </header>
            <TableWrapper>
                <EmployeesTable
                    employees={employees ? employees.items : undefined}
                    onEditEmployee={handleEmployeeDialogClick}
                    onAssignRFID={handleRFIDDialogClick}
                />
            </TableWrapper>
            {employees && (
                <Pagination
                    totalResults={employees.total}
                    currentPage={currentPage}
                    onPageSelect={handlePageChange}
                    resultsPerPage={filter.limit}
                />
            )}
        </section>
    );
};

export default Employees;
