import React, { Component, ComponentType } from 'react';
import { compose } from 'redux';

import { Collapse, IconButton, Paper, SvgIcon } from '@material-ui/core';
import {
    Close,
    KeyboardArrowDown,
    KeyboardArrowUp,
    Notifications,
} from '@material-ui/icons';
import Alert, { Color } from '@material-ui/lab/Alert';

import { Themable, TThemableProps } from './Themable.hoc';
import TranslationHelper from '../../helpers/TranslationHelper';

type SvgIconComponent = typeof SvgIcon;

interface IOwnProps {
    title: string;
    description?: React.ReactNode;
    color?: Color;
    onClose?: () => void;
    icon?: SvgIconComponent;
}

type TProps = IOwnProps & TThemableProps;

interface IOwnState {
    open: boolean;
}

class AlertNotification extends Component<TProps, IOwnState> {
    public static defaultProps = {
        color: 'warning' as Color,
        icon: (<Notifications />) as unknown as SvgIconComponent,
    };

    public state = {
        open: false,
    };

    public toggleDescription = () => {
        this.setState((state: IOwnState) => {
            return {
                open: !state.open,
            };
        });
    };

    public render() {
        const { classes, color, description, icon, onClose, title } =
            this.props;
        const { open } = this.state;

        return (
            <div className={classes.notificationContainer}>
                <Alert
                    className={classes.notificationAlert}
                    icon={icon}
                    severity={color}
                    variant="filled"
                    action={
                        <>
                            {description && (
                                <IconButton
                                    size="small"
                                    className={classes.iconButton}
                                    onClick={this.toggleDescription}
                                    title={TranslationHelper.translate(
                                        'Toggle'
                                    )}
                                >
                                    {open ? (
                                        <KeyboardArrowUp />
                                    ) : (
                                        <KeyboardArrowDown />
                                    )}
                                </IconButton>
                            )}
                            {onClose && (
                                <IconButton
                                    size="small"
                                    className={classes.iconButton}
                                    onClick={onClose}
                                    title={TranslationHelper.translate('Close')}
                                >
                                    <Close />
                                </IconButton>
                            )}
                        </>
                    }
                >
                    {title}
                </Alert>
                {description && (
                    <Collapse in={open}>
                        <Paper className={classes.notificationDescription}>
                            {description}
                        </Paper>
                    </Collapse>
                )}
            </div>
        );
    }
}

export default compose(Themable)(AlertNotification) as ComponentType<IOwnProps>;
