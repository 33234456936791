export const allowedFileExtensions = [
    // images
    'jpg',
    'jpeg',
    'png',
    'gif',
    // videos
    'webm',
    'mkv',
    'avi',
    'mov',
    'mp4',
    'm4p',
    'm4v',
    'mpg',
    'mp2',
    'mpeg',
    'mpe',
    'mpv',
    'm2v',
    // documents
    'doc',
    'docx',
    'pdf',
    'xls',
    'xlsx',
    'odt',
    'ods',
    'txt',
    // other
    'gpx',
    'geojson',
];

export const inputAcceptExtensions = '.' + allowedFileExtensions.join(',.');
