import { useSelector } from 'react-redux';
import {
    getDevicesAsSourceSet,
    getSelectedDevice,
    getSelectedMultiDevices,
    getDevicesSourceSetModel,
    getDevicesLoadingState,
    getSelectedDevicesId,
    getDevicesMode,
    devicesPaneVisible,
    getDevicesCollection,
} from './index';

export const useDevicesSourceSet = () => useSelector(getDevicesAsSourceSet);
export const useSelectedDevice = () => useSelector(getSelectedDevice);
export const useSelectedMultiDevices = () =>
    useSelector(getSelectedMultiDevices);
export const useDevicesSourceSetModel = () =>
    useSelector(getDevicesSourceSetModel);
export const useDevicesLoading = () => useSelector(getDevicesLoadingState);
export const useSelectedDevicesIds = () => useSelector(getSelectedDevicesId);
export const useDevicesMode = () => useSelector(getDevicesMode);
export const useDevicesPaneVisible = () => useSelector(devicesPaneVisible);
export const useDevicesCollection = () => useSelector(getDevicesCollection);
