import React from 'react';
import { TooltipProps } from 'recharts';
import { Box, Typography } from '@mui/material';
import { formatMinute, getActivityColor } from '../utils/helpers';
import { IMinuteData, ISegment } from '../TachograppChart';
import TranslationHelper from '../../../../../helpers/TranslationHelper';
import TimeFormatter from '../../../../../helpers/TimeFormatter';

interface DetailedEventProps extends TooltipProps {
    data: IMinuteData[];
    segments: Map<number, ISegment>;
}

export const DetailedEvent = ({
    label,
    data,
    segments,
}: DetailedEventProps) => {
    const segmentId = data?.[label as number]?.segmentId;
    const segmentStart = Array.from(segments.entries()).find(
        ([_, segment]) => segment.id === segmentId
    );

    if (!segmentStart) {
        return null;
    }
    const segment = segmentStart[1];
    const lastEventTimestamp = data[label as number].lastEventTimestamp;
    return (
        <Box
            sx={{
                backgroundColor: 'white',
                p: 2,
                border: 1,
                borderRadius: 1,
                boxShadow: 1,
            }}
        >
            {lastEventTimestamp && (
                <Typography fontWeight="bold" color="warning">
                    {`${TranslationHelper.translate(
                        'Last event'
                    )}: ${TimeFormatter.dateToTimeString(lastEventTimestamp)}`}
                </Typography>
            )}
            <Typography variant="subtitle1" fontWeight="bold">
                {formatMinute(Number(label))}
            </Typography>
            <Typography
                sx={{
                    color: getActivityColor(segment.activity),
                    textTransform: 'capitalize',
                }}
            >
                {TranslationHelper.translate(segment.activity || '')}
            </Typography>
            <Typography variant="body2" color="text.secondary">
                {`${TranslationHelper.translate(
                    'Duration'
                )}: ${TimeFormatter.formatDuration(
                    segment.duration,
                    'minutes',
                    'hh:mm'
                )}`}
            </Typography>
            <Typography variant="body2" color="text.secondary">
                {`${TranslationHelper.translate(
                    'tacho.start'
                )}: ${TimeFormatter.formatDuration(
                    segment.startTime,
                    'minutes',
                    'hh:mm'
                )}`}
            </Typography>
            <Typography variant="body2" color="text.secondary">
                {`${TranslationHelper.translate(
                    'End'
                )}: ${TimeFormatter.formatDuration(
                    segment.startTime + segment.duration - 1,
                    'minutes',
                    'hh:mm'
                )}`}
            </Typography>
        </Box>
    );
};
