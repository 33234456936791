import { paramsToObject } from './paramsToObject';

export type UrlActionParams = {
    action: string;
    [key: string]: any;
};

export const extractActionParamsFromUrl = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const entries = urlParams.entries();
    return paramsToObject(entries) as UrlActionParams;
};
