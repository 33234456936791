import { createStyles, Theme, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => {
    return createStyles({
        mainSectionDialog: {
            maxHeight: 500,
            overflow: 'auto',
        },
        mainSection: {
            overflow: 'auto',
        },
        noDataSection: {
            height: 100,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        leftIcon: {
            marginRight: theme.spacing(),
        },
        primaryText: {
            wordBreak: 'break-word',
        },
        secondaryAction: {
            paddingRight: '112px',
        },
    });
});
