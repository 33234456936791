import React, { FocusEvent } from 'react';

import { MenuItem } from '@material-ui/core';

import TranslationHelper from '../../../helpers/TranslationHelper';
import {
    IAlertDefinitionItem,
    conditionTypes,
    ILayer,
    ILayerFeature,
} from '../../../state/app/alerts';
import { IParamDefinitionItem } from '../../../state/app/calibrations';
import {
    IEventAlertCondition,
    IEventType,
    IParamAlertCondition,
    ILayerAlertCondition,
} from '../../../state/ui/forms';
export type TDictionaryItem =
    | IAlertDefinitionItem
    | IParamDefinitionItem
    | IEventType
    | ILayer
    | ILayerFeature;

export const mapDictionaryItems = (
    items?: TDictionaryItem[],
    hasEmpty?: boolean,
    valueProp?: string | number,
    translateContext?: string
) => {
    const initial = hasEmpty
        ? [
              <MenuItem key={'-1'} value={-1}>
                  {''}
              </MenuItem>,
          ]
        : [];
    if (items) {
        return initial.concat(
            items.map((item: TDictionaryItem, i) => (
                <MenuItem
                    key={item.id || item.name?.concat(i.toString())}
                    value={
                        (valueProp && item[valueProp]) || item.id || item.name
                    }
                >
                    {translateContext
                        ? TranslationHelper.translate(
                              item.name || '',
                              translateContext
                          )
                        : item.name}
                </MenuItem>
            ))
        );
    }

    return initial;
};

export const trimValue = (
    event: FocusEvent<HTMLInputElement>,
    callback: (name: string, value: any) => void
) => {
    const value = event.target.value.trim();
    callback(event.target.name, value);
};

export const mapOptions = (optionsArray: string[] = [], context = 'wx') => {
    return optionsArray.map((item: string) => (
        <MenuItem key={item} value={item}>
            {TranslationHelper.translate(item, context)}
        </MenuItem>
    ));
};

export const convertParamType = (paramType: string) => {
    switch (paramType) {
        case 'INTEGER':
        case 'INTEGER_64':
        case 'FLOAT':
            return 'NUMERICAL';
        case 'STRING':
            return 'TEXT';
        case 'BOOLEAN':
            return 'BOOLEAN';
        default:
            return 'UNKNOWN';
    }
};

export const alignmentDictionary = {
    left: 1,
    center: 2,
    1: 'left',
    2: 'center',
};

export const defaultConditionValue = {
    [conditionTypes.param]: {
        type: conditionTypes.param,
        definitionId: '',
        paramName: '',
        paramValue: '',
    } as IParamAlertCondition,
    [conditionTypes.event]: {
        type: conditionTypes.event,
        eventType: {
            id: '',
        },
    } as IEventAlertCondition,
    [conditionTypes.layer]: {
        type: conditionTypes.layer,
        definitionId: '',
        layerId: '',
        features: [{ id: 'All', name: 'All' }],
    } as ILayerAlertCondition,
};

export const paramTypes = {
    STRING: 'STRING',
    FLOAT: 'FLOAT',
    INTEGER: 'INTEGER',
    INTEGER_64: 'INTEGER_64',
    BOOLEAN: 'BOOLEAN',
};
