import React, { useState } from 'react';

import SaveIcon from '@material-ui/icons/Save';
import { default as PasswordIcon } from '@material-ui/icons/Lock';
import { default as EditIcon } from '@material-ui/icons/Edit';
import CategoryOutlinedIcon from '@material-ui/icons/CategoryOutlined';

import { enterEditMode } from '../../../../../../state/ui/usersSlice';
import {
    deleteUser,
    resetPassword,
} from '../../../../../../state/app/collections';

import { useAppDispatch } from '../../../../../../hooks';
import useToggle from '../../../../../../hooks/useToggle';

import TranslationHelper from '../../../../../../helpers/TranslationHelper';

import DeleteButton from '../../../../../../components/DeleteButton';
import PaneHeader from '../../../../../../components/PaneHeader';
import IconButtonWithTooltip from '../../../../../../components/IconButtonWithTooltip';
import AssignRightsDialog from '../AssignRightsDialog';
import ResetPasswordDialog from './ResetPasswordDialog';

interface IOwnProps {
    userId?: number | null;
    title: string;
    userDataRightEnabled?: boolean;
    editionEnabled?: boolean;
    deletionEnabled?: boolean;
    passwordResetEnabled?: boolean;
    userLogin?: string;
    userEmail?: string;
    onClose?: () => void;
    onSave?: () => void;
}

const UserPaneHeader = ({
    userId,
    title,
    userDataRightEnabled,
    editionEnabled,
    deletionEnabled,
    passwordResetEnabled,
    userLogin,
    userEmail,
    onClose,
    onSave,
}: IOwnProps) => {
    const { isOpen, handleToggle } = useToggle();

    const [isUserResetDialogOpen, setUserResetDialogOpen] = useState(false);

    const dispatch = useAppDispatch();

    const deleteUserHandler = () => {
        if (userId) {
            dispatch(deleteUser(userId));
        }
    };
    const handleCloseClick = () => {
        if (onClose) {
            onClose();
        }
    };

    const handleEditClick = () => {
        dispatch(enterEditMode());
    };

    const handleResetPasswordOpenDialog = () => {
        setUserResetDialogOpen(true);
    };

    const handleResetPasswordClick = () => {
        if (userId) {
            dispatch(
                resetPassword(userId, () => setUserResetDialogOpen(false))
            );
        }
    };

    const handleResetPasswordDialogClose = () => {
        setUserResetDialogOpen(false);
    };

    const allowedToResetPassword = () => {
        return userLogin?.endsWith('@xtrack.com');
    };

    return (
        <>
            <PaneHeader
                title={title}
                onCloseClick={handleCloseClick}
                renderRightCustomControls={() => (
                    <>
                        {userId && (
                            <>
                                {userDataRightEnabled && (
                                    <IconButtonWithTooltip
                                        title={TranslationHelper.translate(
                                            'Data access rights'
                                        )}
                                        onClick={handleToggle}
                                    >
                                        <CategoryOutlinedIcon />
                                    </IconButtonWithTooltip>
                                )}
                                {passwordResetEnabled && (
                                    <IconButtonWithTooltip
                                        title={
                                            TranslationHelper.translate(
                                                'Reset password'
                                            ) +
                                            (allowedToResetPassword()
                                                ? ' (' +
                                                  TranslationHelper.translate(
                                                      'disabled for global users'
                                                  ) +
                                                  ')'
                                                : '')
                                        }
                                        onClick={handleResetPasswordOpenDialog}
                                        disabled={allowedToResetPassword()}
                                    >
                                        <PasswordIcon />
                                    </IconButtonWithTooltip>
                                )}
                                {editionEnabled && (
                                    <IconButtonWithTooltip
                                        title={TranslationHelper.translate(
                                            'Edit user'
                                        )}
                                        onClick={handleEditClick}
                                    >
                                        <EditIcon />
                                    </IconButtonWithTooltip>
                                )}
                                {deletionEnabled && (
                                    <DeleteButton
                                        title="User removal confirmation"
                                        tooltip="Delete user"
                                        message="Do you really want to DELETE this user?"
                                        deleteMethod={deleteUserHandler}
                                    />
                                )}
                                {isOpen && (
                                    <AssignRightsDialog
                                        isOpen={isOpen}
                                        closeDialog={handleToggle}
                                        userId={userId}
                                    />
                                )}
                                {isUserResetDialogOpen && (
                                    <ResetPasswordDialog
                                        onSubmit={handleResetPasswordClick}
                                        close={handleResetPasswordDialogClose}
                                        canResetPassword={
                                            userEmail !== undefined &&
                                            userEmail !== ''
                                        }
                                    />
                                )}
                            </>
                        )}
                        {onSave && (
                            <IconButtonWithTooltip
                                title={TranslationHelper.translate('Save')}
                                onClick={onSave}
                            >
                                <SaveIcon />
                            </IconButtonWithTooltip>
                        )}
                    </>
                )}
            />
        </>
    );
};

export default UserPaneHeader;
