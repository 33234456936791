import { createStyles, makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() =>
    createStyles({
        gridContainer: {
            flexGrow: 1,
            display: 'flex',
        },
        gridWrapper: {
            flexGrow: 1,
            width: '100%',
        },
    })
);
