import React, { MouseEvent } from 'react';

import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { grey } from '@mui/material/colors';
import { CircularProgress, Tooltip } from '@mui/material';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import ErrorIcon from '@mui/icons-material/Error';
import {
    useSelectedGridViewModified,
    useGridViewStatus,
    useGridViews,
    useSelectedGridViewId,
    useSelectedViewName,
} from '../../state/ui/gridViews/index.hooks';

import TranslationHelper from '../../helpers/TranslationHelper';

import IconButtonWithTooltip from '../IconButtonWithTooltip';

interface IOwnProps {
    callback: (e: MouseEvent<HTMLElement>) => void;
}

const GridViewsButton = ({ callback }: IOwnProps) => {
    const gridViews = useGridViews();

    const selectedGridId = useSelectedGridViewId();
    const selectedViewName = useSelectedViewName();

    const status = useGridViewStatus();
    const selectedGridViewModified = useSelectedGridViewModified();

    const iconTitle = selectedGridId
        ? `${TranslationHelper.translate('Selected view')}: ${selectedViewName}`
        : TranslationHelper.translate('Choose view');
    const sameGrid = gridViews?.some((view) => view.id === selectedGridId);
    const visibilityIcon = (
        <VisibilityIcon sx={{ color: sameGrid ? '' : grey[500] }} />
    );
    if (status === 'error') {
        return (
            <Tooltip
                title={TranslationHelper.translate(
                    'Failed to find saved views, try again'
                )}
            >
                <ErrorIcon fontSize="small" />
            </Tooltip>
        );
    }
    if (status === 'loading') {
        return (
            <CircularProgress
                sx={{ color: 'primary.contrastText' }}
                size={30}
            />
        );
    }

    return (
        <>
            <>
                {gridViews?.length ? (
                    <IconButtonWithTooltip
                        title={iconTitle}
                        onClick={(e) => callback(e)}
                    >
                        {visibilityIcon}
                    </IconButtonWithTooltip>
                ) : (
                    <IconButtonWithTooltip
                        title={TranslationHelper.translate('Add view')}
                        disabled
                    >
                        <VisibilityOffIcon />
                    </IconButtonWithTooltip>
                )}

                {selectedGridViewModified && sameGrid && (
                    <Tooltip
                        title={TranslationHelper.translate(
                            'Unsaved changes will be lost'
                        )}
                    >
                        <PriorityHighIcon
                            fontSize="small"
                            sx={{ color: 'primary.contrastText' }}
                        />
                    </Tooltip>
                )}
            </>
        </>
    );
};

export default GridViewsButton;
