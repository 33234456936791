import { makeReduxDuck } from 'teedux';
import { TRootState } from '../../../store';

export interface IState {
    isMenuOpen: boolean;
}

const initialState: IState = {
    isMenuOpen: false,
};

const duck = makeReduxDuck('ui/general', initialState);

export const showMenu = duck.definePayloadlessAction('SHOW_MENU', () => ({
    isMenuOpen: true,
}));

export const hideMenu = duck.definePayloadlessAction('HIDE_MENU', () => ({
    isMenuOpen: false,
}));

export const getIsMenuOpen = (state: TRootState) => state.ui.general.isMenuOpen;

export default duck.getReducer();
