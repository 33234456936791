import React, { Component } from 'react';
import { func, string, bool } from 'prop-types';

class Button extends Component {
    constructor(props, state) {
        super(props, state);
    }

    render() {
        let iconClassName = this.props.icon ? 'icon ' + this.props.icon : '';

        return (
            <button
                id={this.props.id}
                className={'mtl-button ' + this.props.className}
                onClick={this.props.onClick}
                disabled={this.props.disabled}
            >
                <span className={iconClassName}></span>
                <span>{this.props.label}</span>
            </button>
        );
    }
}

Button.propTypes = {
    onClick: func,
    id: string,
    icon: string,
    label: string,
    className: string,
    disabled: bool,
};

export default Button;
