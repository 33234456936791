import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import {
    useRoleCreateRequest,
    useRoleDeleteRequest,
    useRoleUpdateRequest,
    useTab,
    useUserCreateRequest,
    useUserDeleteRequest,
    useUserPasswordResetRequest,
    useUserUpdateRequest,
} from './Connectable.hooks';
import { selectTab } from '../../state/ui/admin';
import { usePrivileges } from '../../state/auth/index.hooks';

import RolesPage from '../roles';
import UsersPage from '../users';

import ServerMessageDisplay from '../../components/ServerMessageDisplay';
import TabsContainer from '../../components/TabsContainer';

import UsersIcon from '@material-ui/icons/People';
import RolesIcon from '@material-ui/icons/PlaylistAddCheck';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import AttributesPage from './components/AttributesPage';
const AdminPage = () => {
    const currentTab = useTab();
    const userPrivileges = usePrivileges();
    const dispatch = useDispatch();

    const roleCreateRequest = useRoleCreateRequest();
    const roleUpdateRequest = useRoleUpdateRequest();
    const roleDeleteRequest = useRoleDeleteRequest();
    const userCreateRequest = useUserCreateRequest();
    const userUpdateRequest = useUserUpdateRequest();
    const userDeleteRequest = useUserDeleteRequest();
    const userPasswordResetRequest = useUserPasswordResetRequest();

    useEffect(() => {
        selectInitialTab();
    }, []);

    const handleChange = (event: React.ChangeEvent<{}>, value: string) => {
        dispatch(selectTab({ tab: value }));
    };

    const selectInitialTab = () => {
        if (userPrivileges.usersPanel && !currentTab) {
            dispatch(selectTab({ tab: 'users' }));
        } else if (!userPrivileges.rolesPanel && !currentTab) {
            dispatch(selectTab({ tab: 'roles' }));
        }
    };

    const request =
        roleCreateRequest ||
        roleUpdateRequest ||
        roleDeleteRequest ||
        userCreateRequest ||
        userPasswordResetRequest ||
        userUpdateRequest ||
        userDeleteRequest;
    const requestId = (request && request.id) || 0;

    const tabs = [
        {
            privileges: ['usersPanel'],
            value: 'users',
            label: 'Users',
            icon: <UsersIcon />,
            mainComponent: <UsersPage />,
        },
        {
            privileges: ['rolesPanel'],
            value: 'roles',
            label: 'Roles',
            icon: <RolesIcon />,
            mainComponent: <RolesPage />,
        },
        {
            privileges: ['manageAttributesSettings'],
            value: 'attributes',
            label: 'Attributes',
            icon: <FormatListBulletedIcon />,
            mainComponent: <AttributesPage />,
        },
    ];

    return (
        <TabsContainer
            tabs={tabs}
            currentTab={currentTab}
            handleChange={handleChange}
            title="Admin Panel"
            id="adminPanel"
        >
            <ServerMessageDisplay key={requestId} request={request} />
        </TabsContainer>
    );
};

export default AdminPage;
