import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        field: {
            paddingBottom: theme.spacing(),
        },
        formWrapper: {
            padding: theme.spacing(2),
        },
        flex: {
            display: 'flex',
        },
    })
);
