import * as yup from 'yup';
import { memoize } from 'lodash';

import TranslationHelper from '../helpers/TranslationHelper';
import { NAME_STRING_MAX_LENGTH, REQUIRED } from '../constants/yup';

export const createRouteSchema = memoize(() =>
    yup.object().shape({
        name: yup
            .string()
            .trim()
            .required(TranslationHelper.translate(REQUIRED))
            .max(
                NAME_STRING_MAX_LENGTH,
                TranslationHelper.translate('Value in this field is too long')
            ),
        activityCategory: yup
            .number()
            .required(TranslationHelper.translate(REQUIRED))
            .moreThan(-1, TranslationHelper.translate(REQUIRED)),
        plannedOn: yup
            .string()
            .nullable()
            .required(TranslationHelper.translate(REQUIRED)),
    })
);

export const editRouteSchema = memoize(() =>
    yup.object().shape({
        name: yup
            .string()
            .trim()
            .required(TranslationHelper.translate(REQUIRED))
            .max(
                NAME_STRING_MAX_LENGTH,
                TranslationHelper.translate('Value in this field is too long')
            ),
    })
);
