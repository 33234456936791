export const ATTRIBUTE_TYPES = {
    1: 'Integer',
    2: 'Boolean',
    4: 'Double',
    5: 'String',
    6: 'Time',
    7: 'Date',
    8: 'DateTime',
    10: 'File',
};
