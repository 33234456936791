import { connect } from 'react-redux';

import { TRootState } from '../../../../../../../../store';
import { getSourceParamsDefinitions } from '../../../../../../selectors/mappings';

const mapStateToProps = (state: TRootState) => ({
    sourceParamsDefs: getSourceParamsDefinitions(state),
});

const mapDispatchToProps = {};

type TStateProps = ReturnType<typeof mapStateToProps>;
type TDispatchProps = typeof mapDispatchToProps;

export type TConnectableProps = TStateProps & TDispatchProps;

export const Connectable = connect(mapStateToProps, mapDispatchToProps);
