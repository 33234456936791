import { FieldProps } from 'formik';
import React from 'react';

import { DatePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import moment from 'moment-timezone';

interface IOwnProps {
    disabled?: boolean;
    value?: any;
    displayFormat?: string;
    withDateFormatter?: (date: MaterialUiPickersDate) => string;
}

const DateFieldWrapper: React.FunctionComponent<FieldProps & IOwnProps> = ({
    field: { name, value },
    form: { setFieldValue },
    value: rawValue,
    disabled,
    displayFormat,
    withDateFormatter,
    ...props
}) => (
    <DatePicker
        value={
            (rawValue !== null && value && moment(value)) ||
            (!disabled && moment()) ||
            null
        } // ! or always send date from parent component
        onChange={(val: MaterialUiPickersDate) => {
            setFieldValue(
                name,
                withDateFormatter ? withDateFormatter(val) : val
            );
        }}
        format={displayFormat}
        variant="inline"
        autoOk={true}
        disabled={disabled}
        {...props}
    />
);

export default DateFieldWrapper;
