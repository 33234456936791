import React from 'react';

import { Button, Link } from '@material-ui/core';

import { useSnackbar } from 'notistack';

import { LoginPageBackGround } from '../LoginPage/LoginPageBackGround';
import { LoginPageLogo } from '../LoginPage/LoginPageLogo';
import LoginFormFooter from '../../../components/Authentication/LoginFormFooter';

import TranslationHelper from '../../../helpers/TranslationHelper';
import { useAppDispatch } from '../../../hooks';
import { errorMessageHandler } from '../../../helpers/errorMessageHandler';

import { logout } from '../../../state/_actions/logoutAction';
import { migrationResendConfirmationEmail } from '../../../state/auth';

import { useStyles } from '../Themable.hooks';

const MigrationEmailVerification = () => {
    const classes = useStyles();

    const dispatch = useAppDispatch();

    const { enqueueSnackbar } = useSnackbar();

    const showNotification = (success: boolean, message: string) => {
        enqueueSnackbar(message, {
            variant: success ? 'success' : 'error',
        });
    };

    const handleSendVerificationEmail = () => {
        dispatch(
            migrationResendConfirmationEmail(
                () => {
                    showNotification(
                        true,
                        TranslationHelper.translate('auth.migrationEmailResend')
                    );
                },
                (error) => {
                    const message = errorMessageHandler(error.status)();
                    showNotification(false, message);
                }
            )
        );
    };

    return (
        <LoginPageBackGround>
            <div className={classes.loginForm}>
                <LoginPageLogo />
                <div
                    style={{
                        fontStyle: 'italic',
                    }}
                >
                    {TranslationHelper.translate('Thanks')}!
                </div>
                <div className={classes.formButtons}>
                    {TranslationHelper.translate('auth.migrationVerifyEmail')}
                </div>
                <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    className={classes.formButtons}
                    onClick={() => dispatch(logout())}
                >
                    {TranslationHelper.translate('Back to login page')}
                </Button>
                <div className={classes.formButtons}>
                    <Link
                        component="button"
                        variant="body2"
                        underline="always"
                        onClick={handleSendVerificationEmail}
                    >
                        {TranslationHelper.translate(
                            'auth.resendVerificationEmail'
                        )}
                    </Link>
                </div>
                <LoginFormFooter />
            </div>
        </LoginPageBackGround>
    );
};

export default MigrationEmailVerification;
