/* eslint-disable react/prop-types */
import React, { ReactNode } from 'react';

import classNames from 'classnames';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import Hidden from '@material-ui/core/Hidden';
import { MainMenu, SecondaryMenu } from './Menus';

import { useStyles } from './Themable.hooks';
import TranslationHelper from '../../../../helpers/TranslationHelper';
import { useAppDispatch } from '../../../../hooks';
import { logout } from '../../../../state/_actions/logoutAction';
import { hideMenu, showMenu } from '../../../../state/ui/general';
import { useIsMenuOpen } from '../../../../state/ui/general/index.hooks';
import { useIsBottomGridInEditMode } from '../../../../state/ui/customerService/bottomGrid/index.hooks';

import { useDiscoveryEditMode } from '../../../../pages/discovery/selectors/index.hooks';
import { useAdminEditMode } from '../../../../pages/users/selectors/index.hooks';
import {
    useManagementEditMode,
    useUserSettingsEditMode,
} from '../../../../pages/management/selectors/index.hooks';
import { useIsClientAndLocationInEditMode } from '../../../../state/ui/customerService/clientsAndLocations/index.hooks';
import { useLinks } from './selectors';

interface IOwnProps {
    children: ReactNode;
}
const Navigation = ({ children }: IOwnProps) => {
    const classes = useStyles();

    const dispatch = useAppDispatch();

    const discoveryEdit = useDiscoveryEditMode();
    const adminEdit = useAdminEditMode();
    const userSettingsEdit = useUserSettingsEditMode();
    const managementEdit = useManagementEditMode();
    const clientAndLocationEdit = useIsClientAndLocationInEditMode();
    const bottomGridEdit = useIsBottomGridInEditMode();
    const isMenuOpen = useIsMenuOpen();
    const links = useLinks();

    const isEditMode = () => {
        switch (window.location.pathname) {
            case '/discovery':
                return discoveryEdit;
            case '/admin':
                return adminEdit;
            case '/user-account':
                return userSettingsEdit;
            case '/management':
                return managementEdit;
            case '/customer-service':
                return clientAndLocationEdit || bottomGridEdit;
            default:
                return false;
        }
    };

    const handleLogout = () => {
        if (isEditMode()) {
            if (
                window.confirm(
                    TranslationHelper.translate(
                        'Unsaved data will be lost. Do you want to continue?'
                    )
                )
            ) {
                dispatch(logout());
            }
        } else {
            dispatch(logout());
        }
    };

    const handleShowMenu = () => {
        dispatch(showMenu());
    };

    const handleHideMenu = () => {
        dispatch(hideMenu());
    };
    return (
        <div className={classes.root}>
            <Hidden smDown>
                <Drawer
                    variant="permanent"
                    classes={{
                        paper: classNames(
                            classes.drawerPaper,
                            !isMenuOpen && classes.drawerPaperClose
                        ),
                    }}
                    open={isMenuOpen}
                >
                    <div className={classes.drawerSegment}>
                        <AppBar
                            position="absolute"
                            className={classNames(
                                classes.appBar,
                                isMenuOpen && classes.appBarShift
                            )}
                            color="primary"
                        >
                            <Toolbar disableGutters={!isMenuOpen}>
                                <IconButton
                                    color="inherit"
                                    aria-label="open drawer"
                                    onClick={handleShowMenu}
                                    className={classNames(
                                        classes.menuButton,
                                        isMenuOpen && classes.hide
                                    )}
                                >
                                    <MenuIcon />
                                </IconButton>
                            </Toolbar>
                        </AppBar>

                        <div
                            className={classes.toolbar}
                            onClick={handleHideMenu}
                        >
                            <IconButton aria-label="toolbar">
                                <ChevronLeftIcon />
                            </IconButton>
                        </div>

                        <MainMenu links={links.internal} />
                        <Divider />
                    </div>

                    <div className={classes.drawerSegment}>
                        <Divider />
                        <SecondaryMenu
                            links={links.external}
                            handleLogout={handleLogout}
                        />
                    </div>
                </Drawer>
            </Hidden>
            <div className={classes.content}>{children}</div>
        </div>
    );
};

export default Navigation;
