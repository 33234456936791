import { connect } from 'react-redux';
import { TRootState } from '../../store';

import { isMobileSearchOpen } from '../../pages/discovery/selectors';
import {
    resetSearcher,
    toggleMobileSearch,
    toggleSearchResults,
} from '../../state/_actions';

const mapStateToProps = (state: TRootState) => ({
    isMobileSearchOpen: isMobileSearchOpen(state),
});

const mapDispatchToProps = {
    resetSearcher,
    toggleSearchResults,
    toggleMobileSearch,
};

type TStateProps = ReturnType<typeof mapStateToProps>;
type TDispatchProps = typeof mapDispatchToProps;

export type TConnectableProps = TStateProps & TDispatchProps;

export const Connectable = connect(mapStateToProps, mapDispatchToProps);
