import React, { useState, useEffect } from 'react';

import { default as PlusIcon } from '@material-ui/icons/Add';
import Paper from '@material-ui/core/Paper';
import { LinearProgress } from '@material-ui/core';

import { ISourceSetEntity } from '../../../../state/types';
import { getRoles, getUsers } from '../../../../state/app/collections';
import { enterCreateMode, selectUser } from '../../../../state/ui/usersSlice';
import { useUsersSourceSetModel } from '../../../../state/ui/usersSlice/index.hooks';

import TranslationHelper from '../../../../helpers/TranslationHelper';
import { useAppDispatch, useToolkitDispatch } from '../../../../hooks';

import PaneHeader from '../../../../components/PaneHeader';
import SourceSetGrid from '../../../../components/SourceSetGrid';
import { AccessibleIconButton } from '../../../../components/accessControl/components';
import SearchField from '../../../../components/common/SearchField/SearchField';

import { useStyles } from './Themable.hooks';
import { useRolesData, useUsersSourceSet } from '../../selectors/index.hooks';

const UsersGridPane = () => {
    const classes = useStyles();
    const sourceSetModel = useUsersSourceSetModel();
    const sourceSet = useUsersSourceSet();
    const rolesData = useRolesData();
    const [filter, setFilter] = useState(sourceSetModel?.quickFilter || '');
    const dispatch = useAppDispatch();
    const toolkitDispatch = useToolkitDispatch();

    useEffect(() => {
        dispatch(getUsers());
        dispatch(getRoles());

        if (sourceSetModel) {
            handleFilterChange(sourceSetModel.quickFilter);
        } else {
            handleFilterChange('');
        }
    }, []);

    const handleFilterChange = (value: string) => {
        setFilter(value);
    };

    const handleCellInteraction = (data: ISourceSetEntity) => {
        toolkitDispatch(selectUser(data.id));
    };

    return (
        <Paper className={classes.pane}>
            <div className={classes.wrapper}>
                <PaneHeader
                    title={TranslationHelper.translate('Users')}
                    renderRightCustomControls={() => (
                        <AccessibleIconButton
                            check={(privileges) => privileges.addUser}
                            tooltipTitle="Add user"
                            onClick={() => toolkitDispatch(enterCreateMode())}
                            className={classes.plusIconButton}
                            aria-label="Create"
                        >
                            <PlusIcon />
                        </AccessibleIconButton>
                    )}
                    renderLeftCustomControls={() => (
                        <>
                            <SearchField
                                value={filter}
                                filterChange={handleFilterChange}
                            />
                        </>
                    )}
                />
                {!sourceSet && <LinearProgress />}
                {sourceSet && rolesData && (
                    <SourceSetGrid
                        sourceSet={sourceSet}
                        customData={{ roles: rolesData }}
                        sourceSetModel={sourceSetModel}
                        cellInteractionHandler={handleCellInteraction}
                        quickFilterValue={filter}
                    />
                )}
            </div>
        </Paper>
    );
};

export default UsersGridPane;
