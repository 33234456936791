import React, { Component, ComponentType } from 'react';
import { compose } from 'redux';

import { ColDef, ColumnApi, GridApi } from 'ag-grid-community';

import { Connectable, TConnectableProps } from './Connectable.hoc';
import { Themable, TThemableProps } from './Themable.hoc';

import defaultColDef from '../../../../components/SourceSetGrid/_utils/defaultColDef';
import translateFunc from '../../../../components/SourceSetGrid/_utils/gridTranslateFunc';
import AgGridEnterprise from '../../../../components/SourceSetGrid/components/AgGridEnterprise';
import { TGridProps } from '../../../../components/SourceSetGrid/components/AgGridEnterprise/types';
import { ICustomData } from '../../../../components/SourceSetGrid/types';
import AppConfig from '../../../../constants/AppConfig';
import { ISourceSet } from '../../../../state/types';
import { ISourceSetModel } from '../../../../state/ui/discovery/types';
import { getColumnDefs } from '../_utils/columnDefsGetter';

import debounce from 'lodash.debounce';

export interface IOwnProps {
    sourceSet: ISourceSet;
    sourceSetModel: ISourceSetModel | null;
    customData?: ICustomData;
    quickFilterValue?: string;
    setGridApi?: (api: GridApi, columnApi: ColumnApi) => void;
}

export interface IState {
    columnDefs: ColDef[];
    sourceSet: ISourceSet;
}

type TProps = IOwnProps & TThemableProps & TConnectableProps;

class AnalyticsGrid extends Component<TProps, IState> {
    public state: IState = {
        columnDefs: [],
        sourceSet: this.props.sourceSet,
    };

    private gridApi: GridApi | null = null;
    private gridColumnApi: ColumnApi | null = null;
    private delayedSetQuickFilter = debounce((value) => {
        if (!this.gridApi) {
            return;
        }
        this.gridApi.setQuickFilter(value);
    }, 500);

    public componentDidMount() {
        const { customData, userSettings, layersAttributes } = this.props;
        const { sourceSet } = this.state;

        const attributes = [
            ...sourceSet.attributes,
            ...(sourceSet._meta.geolocated ? layersAttributes : []),
        ];
        const columnDefs: ColDef[] = getColumnDefs(
            attributes,
            customData,
            userSettings,
            sourceSet,
            true
        );
        this.setState({
            columnDefs,
        });

        if (this.gridApi && this.props.quickFilterValue !== undefined) {
            this.gridApi.setQuickFilter(this.props.quickFilterValue);
        }
    }

    public componentDidUpdate() {
        if (this.props.quickFilterValue !== undefined) {
            this.delayedSetQuickFilter(this.props.quickFilterValue);
        }
    }

    public setFilterAndSortModels() {
        const { sourceSetModel, quickFilterValue } = this.props;

        if (this.gridApi && this.gridColumnApi && sourceSetModel) {
            this.gridApi.setFilterModel(sourceSetModel.filterModel);
            this.gridColumnApi.applyColumnState({
                state: sourceSetModel.sortModel,
            });
            this.gridApi.setQuickFilter(quickFilterValue || '');
        }
    }

    public handleGridReady = (params: {
        api: GridApi;
        columnApi: ColumnApi;
    }) => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;

        this.gridColumnApi.autoSizeAllColumns();

        if (this.props.setGridApi) {
            this.props.setGridApi(this.gridApi, this.gridColumnApi);
        }
    };

    public render() {
        const { classes } = this.props;

        const enterpriseKey =
            AppConfig &&
            AppConfig.instance.getConfigKey(AppConfig.PROPERTY_GRID)?.key;

        const gridProps: TGridProps = {
            columnDefs: this.state.columnDefs,
            defaultColDef,
            onGridReady: this.handleGridReady,
            enterpriseKey,
            rowData: this.state.sourceSet.entities,
            localeTextFunc: translateFunc,
            enableRangeSelection: true,
            enableCharts: true,
            onFirstDataRendered: this.setFilterAndSortModels.bind(this),
            statusBar: {
                statusPanels: [
                    {
                        statusPanel: 'agTotalAndFilteredRowCountComponent',
                        align: 'left',
                    },
                    {
                        statusPanel: 'agAggregationComponent',
                        statusPanelParams: {
                            aggFuncs: ['count', 'sum', 'avg'],
                        },
                    },
                ],
            },
            sideBar: true,
        };

        return (
            <div className={classes.container}>
                <div className={`ag-theme-balham ${classes.wrapper}`}>
                    <AgGridEnterprise {...gridProps} />
                </div>
            </div>
        );
    }
}

export default compose(
    Themable,
    Connectable
)(AnalyticsGrid) as ComponentType<IOwnProps>;
