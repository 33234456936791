import { Coordinate } from 'ol/coordinate';

const FACTOR = 40075016.68;

class CoordinatesHelper {
    public static normalizePosition(position: Coordinate) {
        const offset = Math.floor(position[0] / FACTOR + 0.5);
        return [position[0] - offset * FACTOR, position[1]];
    }
}

export default CoordinatesHelper;
