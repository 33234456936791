import { connect, ResolveThunks } from 'react-redux';

import {
    restoreState,
    toggleMobileSearch,
    toggleSearchResults,
} from '../../state/_actions';

import { fetchParamsDefinition } from '../../state/app/monitoredObjects';
import { getModules } from '../../state/app/iframes';

import {
    areSearchResultsVisible,
    isMobileSearchOpen,
} from '../../pages/discovery/selectors';
import {
    fetchContextTranslations,
    getMainTranslationsLang,
} from '../../state/app/translations';
import { fetchLanguages, getLanguage, savePath } from '../../state/login';
import { resetRestorer } from '../../state/ui/discovery/snapshotting';
import { getIsMenuOpen, hideMenu } from '../../state/ui/general';
import { getDictionary } from '../../state/app/dictionaries';
import { getStoreBookmarks } from '../../state/app/bookmarks';

import {
    fetchUserRights,
    fetchUserSettings,
    getUserSettings,
} from '../../state/user';

import { TRootState } from '../../store';

const mapStateToProps = (state: TRootState) => ({
    userSettings: getUserSettings(state),
    language: getLanguage(state),
    mainTranslationLanguage: getMainTranslationsLang(state),
    isMenuOpen: getIsMenuOpen(state),
    areSearchResultsVisible: areSearchResultsVisible(state),
    isMobileSearchOpen: isMobileSearchOpen(state),
});

const mapDispatchToProps = {
    getModules,
    fetchParamsDefinition,
    getDictionary,
    fetchContextTranslations,
    fetchUserSettings,
    fetchUserRights,
    fetchLanguages,
    restoreState,
    savePath,
    hideMenu,
    toggleSearchResults,
    toggleMobileSearch,
    resetRestorer,
    getStoreBookmarks,
};

type TStateProps = ReturnType<typeof mapStateToProps>;
type TDispatchProps = ResolveThunks<typeof mapDispatchToProps>;

export type TConnectableProps = TStateProps & TDispatchProps;

export const Connectable = connect(mapStateToProps, mapDispatchToProps);
