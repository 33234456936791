import createStyles from '@material-ui/core/styles/createStyles';

import { Theme, withStyles, WithStyles } from '@material-ui/core/styles';

const styles = (theme: Theme) => {
    return createStyles({
        noItemsFound: {
            paddingTop: theme.spacing(2),
            zIndex: 'inherit',
            width: '100%',
            textAlign: 'center',
        },
        root: {
            height: 300,
            overflowY: 'scroll',
            zIndex: 'inherit',
            backgroundColor: 'white',
        },
        mobileRoot: {
            height: '100%',
            overflowY: 'scroll',
            zIndex: 1000,
            backgroundColor: 'white',
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
        },
        linearProgress: {
            zIndex: 1001,
        },
        list: {
            zIndex: 'inherit',
            backgroundColor: 'inherit',
        },
    });
};

export type TThemableProps = WithStyles<typeof styles>;

export const Themable = withStyles(styles, { withTheme: true });
