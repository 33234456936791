import React from 'react';

import { default as EyeIcon } from '@material-ui/icons/Visibility';

import { IBookmark } from '../../../../state/types';
import { activateReportSet, restoreSnapshot } from '../../../../state/_actions';
import { hideGlobalDialog } from '../../../../state/ui/dialog';
import { useBookmarkList } from '../../../../state/app/bookmarks/index.hooks';

import { useAppDispatch } from '../../../../hooks';

import Translator from '../../../../helpers/TranslationHelper';
import TranslationHelper from '../../../../helpers/TranslationHelper';

import Dialog from '../../../../components/dialog/Dialog';
import DataSegmentsList from './DataSegmentsList';
import IconButtonWithTooltip from '../../../../components/IconButtonWithTooltip';

const BookmarkListDialog = () => {
    const dispatch = useAppDispatch();
    const bookmarks = useBookmarkList();

    const onCancel = () => {
        dispatch(hideGlobalDialog());
    };

    const viewReport = (bookmark: IBookmark) => {
        dispatch(
            activateReportSet(
                bookmark.monitoredId,
                bookmark.from,
                bookmark.to,
                {
                    type: 'report-set',
                    level: bookmark.level,
                },
                bookmark.timeLocked
            )
        );
        dispatch(restoreSnapshot(bookmark.snapshot, bookmark.snapshotPaths));
        dispatch(hideGlobalDialog());
    };

    const renderRightCustomControls = (bookmark: IBookmark) => {
        return (
            <>
                <IconButtonWithTooltip
                    title={TranslationHelper.translate('Show')}
                    onClick={() => viewReport(bookmark)}
                    defaultColor
                >
                    <EyeIcon />
                </IconButtonWithTooltip>
            </>
        );
    };

    return (
        <Dialog
            title={`${Translator.translate('Manage saved segments')}:`}
            onClose={onCancel}
        >
            <DataSegmentsList
                renderRightCustomControls={renderRightCustomControls}
                segments={bookmarks}
            />
        </Dialog>
    );
};

export default BookmarkListDialog;
