import { COLORS } from '../../../../../constants/ColorConst';
import TimeFormatter from '../../../../../helpers/TimeFormatter';
import { TTachoActivity } from '../TachograppChart';

export const formatMinute = (minute: number) => {
    return `${TimeFormatter.formatDuration(minute, 'minutes', 'hh:mm')}`;
};

export const getActivityColor = (activity: TTachoActivity) => {
    switch (activity) {
        case 'driving':
            return COLORS.red;
        case 'resting':
            return COLORS.green;
        case 'working':
            return COLORS.orange;
        case 'available':
            return COLORS.yellow;
        case 'unavailable':
            return COLORS.gray;
        default:
            return '#000000';
    }
};

export const getMinuteInfo = (timestamp: string) => {
    const date = new Date(timestamp);
    return {
        minute: date.getHours() * 60 + date.getMinutes(),
        seconds: date.getSeconds(),
        fullTimestamp: date,
    };
};
