import { createStyles, Theme, WithStyles } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import blue from '@material-ui/core/colors/blue';
import orange from '@material-ui/core/colors/orange';
import red from '@material-ui/core/colors/red';

import { SATURATION } from '../../../../../../constants/dictionaries/ColorConst';

const styles = (theme: Theme) =>
    createStyles({
        button: {
            backgroundColor: 'rgba(255, 255, 255, .6)',
            height: '30px',
            marginTop: '2px',
            marginRight: theme.spacing(),

            '&:hover': {
                backgroundColor: 'rgba(255, 255, 255, .8)',
            },
        },
        error: {
            color: red[SATURATION],
        },
        info: {
            color: blue[SATURATION],
        },
        warning: {
            color: orange[SATURATION],
        },
    });

export type TThemableProps = WithStyles<typeof styles>;

export const Themable = withStyles(styles, { withTheme: true });
