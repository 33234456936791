import { FieldProps } from 'formik';
import React from 'react';

import { DateTimePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import moment from 'moment-timezone';

interface IOwnProps {
    displayFormat?: string;
    withDateFormatter?: (date: MaterialUiPickersDate) => string;
}

const DateTimeFieldWrapper: React.FunctionComponent<FieldProps & IOwnProps> = ({
    field: { name, value },
    form: { setFieldValue },
    displayFormat,
    withDateFormatter,
    ...props
}) => (
    <DateTimePicker
        value={(value && moment(value)) || moment()} // ! or always send date from parent component
        onChange={(val: MaterialUiPickersDate) => {
            setFieldValue(
                name,
                withDateFormatter ? withDateFormatter(val) : val
            );
        }}
        format={displayFormat}
        variant="inline"
        ampm={false}
        autoOk={true}
        {...props}
    />
);

export default DateTimeFieldWrapper;
