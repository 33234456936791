import URI from 'urijs';

import UrlConst from '../../constants/UrlConst';
import AppConfig from '../../constants/AppConfig';

import apiClient from '../common/apiClient';
import {
    makeGetRequestSettings,
    makePostRequestSettings,
    makePutRequestSettings,
    makeUrl,
} from '../common/utils';

import { IMonitoredObject } from '../../state/types';
import {
    IMonitoredObjectCoordinates,
    IRecalculateData,
    TParamDefinitions,
} from 'src/app/state/app/monitoredObjects';

export const makeMonitoredObjectsUrl = (suffix: string, params = {}) => {
    const url = new URI(
        AppConfig.instance.getUrl(UrlConst.MONITORED_OBJECTS_URL) + suffix
    );
    return makeUrl(url, params);
};

export const restGetMonitoredObjects = (
    name?: string
): Promise<IMonitoredObject[]> =>
    apiClient.sendWithoutErrorsHandling(
        makeGetRequestSettings(makeMonitoredObjectsUrl('/current', { name }))
    );

export const restGetMonitoredObjectsParamsDefinition = (
    numerical: boolean = false
): Promise<TParamDefinitions> =>
    apiClient.send(
        makeGetRequestSettings(
            makeMonitoredObjectsUrl('/params/definitions', { numerical })
        )
    );

export const restPutCoordinates = (
    data: IMonitoredObjectCoordinates
): Promise<null> =>
    apiClient.send(
        makePutRequestSettings(
            makeMonitoredObjectsUrl(`/current/${data.monitoredId}/coordinates`),
            data.coordinates
        )
    );

export const restPostRecalcuateData = (
    data: IRecalculateData,
    id: string
): Promise<IMonitoredObject> =>
    apiClient.send(
        makePostRequestSettings(
            makeMonitoredObjectsUrl(
                `/objects/${id}/recalculate?start=${data.start.toISOString()}&reason=${encodeURIComponent(
                    data.reason
                )}`
            ),
            data
        )
    );
