import React, { useState, ChangeEvent, FormEvent } from 'react';

import { useSnackbar } from 'notistack';

import {
    Button,
    CircularProgress,
    FormControl,
    IconButton,
    InputAdornment,
    TextField,
} from '@material-ui/core';
import { default as Autorenew } from '@material-ui/icons/Autorenew';

import { useStyles } from './Themable.hooks';

import { fetchLocationAddress } from '../../../../../../../../state/app/locations';
import { updateCoordinates } from '../../../../../../../../state/app/monitoredObjects';
import { storeLastMapClickPosition } from '../../../../../../../../state/_actions';

import TranslationHelper from '../../../../../../../../helpers/TranslationHelper';

import { useAppDispatch } from '../../../../../../../../hooks';

import PaneHeader from '../../../../../../../../components/PaneHeader';
import { useLastState } from '../../../../../../selectors/composed/index.hooks';

interface IOwnProps {
    latitude: string | number;
    longitude: string | number;
    handleCloseClick: (bool: boolean) => void;
    handleCoordinatesInput: (event: ChangeEvent<HTMLInputElement>) => void;
    showNotification: (
        type: 'error' | 'success' | 'warning' | 'info',

        message: string
    ) => void;
}
const LastStateCreate = ({
    latitude,
    longitude,
    handleCloseClick,
    handleCoordinatesInput,
    showNotification,
}: IOwnProps) => {
    const [address, setAddress] = useState<string>('');
    const [synchronizingWithMap, setSynchronizingWithMap] = useState<any>();

    const lastState = useLastState();

    const classes = useStyles();

    const { enqueueSnackbar } = useSnackbar();

    const dispatch = useAppDispatch();

    const lastStateMetaHeader = lastState?._meta?.header;
    const lastStateName = lastStateMetaHeader?.name || '';

    const handleAddressChange = (event: ChangeEvent<HTMLInputElement>) => {
        setAddress(event.target.value);
    };

    const handleCloseCoordinates = () => {
        handleCloseClick(false);
    };

    const handleSynchronizationClick = (event: any) => {
        setSynchronizingWithMap(true);
        dispatch(
            fetchLocationAddress(
                address,
                (data) => {
                    dispatch(storeLastMapClickPosition(data.x, data.y));
                    setSynchronizingWithMap(false);
                },
                () => {
                    setSynchronizingWithMap(false);
                    showNotification(
                        'error',
                        TranslationHelper.translate(
                            'Failed to find location, try again'
                        )
                    );
                }
            )
        );
    };

    const handleAddSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const lastStateMonitoredId = lastState?._meta?.header?.monitoredId;
        if (latitude && longitude) {
            const data = {
                coordinates: {
                    x: Number(longitude),
                    y: Number(latitude),
                },
                monitoredId: lastStateMonitoredId,
            };
            dispatch(updateCoordinates(data, () => handleCloseClick(false)));
        } else {
            enqueueSnackbar(
                TranslationHelper.translate(
                    'Enter coordinates or press look for location button'
                ),
                { variant: 'warning' }
            );
        }
    };

    return (
        <React.Fragment>
            <PaneHeader
                title={lastStateName}
                onCloseClick={handleCloseCoordinates}
            />

            <form onSubmit={handleAddSubmit}>
                <div className={classes.innerWrapper}>
                    <div>
                        <p>
                            {TranslationHelper.translate('Set new coordinates')}
                        </p>
                        <FormControl fullWidth={true}>
                            <TextField
                                fullWidth={true}
                                label={TranslationHelper.translate(
                                    'Look for location'
                                )}
                                helperText={TranslationHelper.translate(
                                    'Format: City, Street name house number'
                                )}
                                value={address}
                                onChange={handleAddressChange}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="Synhronize with map"
                                                onClick={
                                                    handleSynchronizationClick
                                                }
                                            >
                                                {synchronizingWithMap ? (
                                                    <CircularProgress
                                                        size={20}
                                                    />
                                                ) : (
                                                    <Autorenew />
                                                )}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />

                            <div className={classes.coordinates}>
                                <TextField
                                    name="longitude"
                                    type="text"
                                    label={TranslationHelper.translate('Lon.')}
                                    fullWidth={true}
                                    inputProps={{
                                        min: -179,
                                        max: 179,
                                        step: 0.000001,
                                    }}
                                    value={longitude}
                                    onChange={handleCoordinatesInput}
                                />
                                <TextField
                                    name="latitude"
                                    type="text"
                                    label={TranslationHelper.translate('Lat.')}
                                    fullWidth={true}
                                    inputProps={{
                                        min: -89,
                                        max: 89,
                                        step: 0.000001,
                                    }}
                                    value={latitude}
                                    onChange={handleCoordinatesInput}
                                />
                            </div>
                        </FormControl>
                    </div>

                    <div className={classes.actionsWrapper}>
                        <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                        >
                            {TranslationHelper.translate('Save')}
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleCloseCoordinates}
                        >
                            {TranslationHelper.translate('Back')}
                        </Button>
                    </div>
                </div>
            </form>
        </React.Fragment>
    );
};

export default LastStateCreate;
