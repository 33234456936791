import { TRootState } from '../../../../store';

export interface IDataItem {
    date: string;
    params: { [key: string]: any };
    state: { type: number };
    data: { vehicle?: string; employee?: string };
}
export const getParamDefinitions = (state: TRootState) =>
    state.app.monitoredObjects.paramDefinitions;
export const getDateFormat = (state: TRootState) => state.user.dateFormat;
