import { useSelector } from 'react-redux';

import {
    getEventTypes,
    getAlertsParamsDefinitions,
    getParamsLoading,
} from './index';
import { createSelector } from 'reselect';
import TranslationHelper from '../../../helpers/TranslationHelper';

export const getTranslatedEventTypes = createSelector(
    [getEventTypes],
    (eventTypes) => {
        return eventTypes
            .map((eventType) => ({
                ...eventType,
                name: TranslationHelper.translate(eventType.name || '', 'et'),
            }))
            .sort((a, b) => (a.name || '').localeCompare(b.name || ''));
    }
);

export const useParamsLoading = () => useSelector(getParamsLoading);
export const useAlertsParamsDefinitions = () =>
    useSelector(getAlertsParamsDefinitions);
export const useTranslatedEventTypes = () =>
    useSelector(getTranslatedEventTypes);
