import UrlConst from '../../constants/UrlConst';

import { IPage, IRegisterFilter } from '../../state/app/registers/';
import { IDictionaryItem } from '../../state/app/dictionaries';
import { ITank } from '../../state/app/registers/tanks';
import { IRequestWithMessages } from '../../state/app/sync';

import apiClient from '../common/apiClient';

import {
    makeEndpoint,
    makeGetRequestSettings,
    makePutRequestSettings,
} from '../common/utils';

export const makeFuelTanksUrl = (suffix: string = '', params = {}) => {
    return makeEndpoint(UrlConst.REGISTERS_URL, '/fuel-tanks' + suffix, {
        ...params,
    });
};
export const restGetTanks = (params: IRegisterFilter): Promise<IPage<ITank>> =>
    apiClient.send(makeGetRequestSettings(makeFuelTanksUrl('', params)));

export const restPutTank = (tank: ITank): Promise<IRequestWithMessages> =>
    apiClient.send(
        makePutRequestSettings(makeFuelTanksUrl(`/${tank.id}`), tank)
    );

export const restPutFuelTankRfidCards = (
    id: string,
    cards: string[]
): Promise<IRequestWithMessages> =>
    apiClient.send(
        makePutRequestSettings(makeFuelTanksUrl(`/${id}/send-cards`), cards)
    );

export const restGetSentCards = (id: string): Promise<IDictionaryItem[]> =>
    apiClient.send(
        makeGetRequestSettings(makeFuelTanksUrl(`/${id}/send-cards`))
    );
