import {
    IRestPostLocation,
    IRestPutLocation,
    restDeleteLocation,
    restGetLocation,
    restGetLocationAddress,
    restPostLocation,
    restPutLocation,
} from '../../../services/locations';
import { makeReduxDuck } from 'teedux';
import { IRequestError, makeRequest } from '../sync';
import { storeLocations } from '../entities';

const locationsStorePath = `app/discovery/locations`;

interface IState {}

export interface ILocationAddress {
    address: string;
}

const initialState: IState = {};

const duck = makeReduxDuck(locationsStorePath, initialState);

export default duck.getReducer();

export const fetchLocation = (
    id: string,
    afterRequest?: (data: any) => void,
    afterFail?: () => void
) =>
    makeRequest(
        `get:${locationsStorePath}`,
        () => restGetLocation(id),
        (dispatch, data) => {
            dispatch(storeLocations({ [id]: data }));
            if (afterRequest) {
                afterRequest(data);
            }
        },
        (dispatch, error) => {
            if (afterFail) {
                afterFail();
            }
        }
    );

export const fetchLocationAddress = (
    address: string,
    afterRequest?: (data: { x: number; y: number }) => void,
    afterFail?: () => void
) =>
    makeRequest(
        `get:${locationsStorePath}/geocode`,
        () => restGetLocationAddress({ address }),
        (dispatch, data) => {
            if (afterRequest) {
                afterRequest(data);
            }
        },
        (dispatch, error) => {
            if (afterFail) {
                afterFail();
            }
        }
    );

export const createLocation = (
    location: IRestPostLocation,
    afterRequest?: (data: any) => void,
    afterFail?: () => void
) =>
    makeRequest(
        `post:${locationsStorePath}`,
        () => restPostLocation(location),
        (dispatch, data) => {
            if (afterRequest) {
                afterRequest(data);
            }
        },
        (dispatch, error) => {
            if (afterFail) {
                afterFail();
            }
        }
    );

export const updateLocation = (
    location: IRestPutLocation,
    afterRequest?: () => void,
    afterFail?: () => void
) =>
    makeRequest(
        `put:${locationsStorePath}/${location.id}`,
        () => restPutLocation(location),
        (dispatch) => {
            if (afterRequest) {
                afterRequest();
            }
        },
        (dispatch, error) => {
            if (afterFail) {
                afterFail();
            }
        }
    );

export const deleteLocation = (
    locationId: string,
    afterRequest?: () => void,
    afterFail?: (error: IRequestError) => void
) =>
    makeRequest(
        `delete:${locationsStorePath}/${locationId}`,
        () => restDeleteLocation(locationId),
        (dispatch, data) => {
            if (afterRequest) {
                afterRequest();
            }
        },
        (dispatch, error) => {
            if (afterFail) {
                afterFail(error);
            }
        }
    );
