import { makeStyles, Theme } from '@material-ui/core';
import { alpha } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
    search: {
        display: 'flex',
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: alpha(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: alpha(theme.palette.common.white, 0.25),
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '100%',
        maxWidth: '200px',
    },
    searchIcon: {
        color: theme.palette.primary.main,
    },
    errorIcon: {
        color: theme.palette.error.main,
    },
}));
