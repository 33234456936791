import React, { MouseEvent, ReactChild } from 'react';

import { IconButton, IconButtonProps, Tooltip } from '@material-ui/core';
import { useStyles } from './Themable.hoc';

interface IOwnProps {
    title: string;
    onClick?: (e: MouseEvent<HTMLElement>) => void;
    children: ReactChild;
    disabled?: boolean;
    buttonProps?: IconButtonProps;
    size?: 'medium' | 'small';
    defaultColor?: boolean;
}

const IconButtonWithTooltip = ({
    title,
    onClick,
    children,
    disabled,
    buttonProps,
    size = 'medium',
    defaultColor = false,
}: IOwnProps) => {
    const classes = useStyles({ defaultColor });

    return (
        <Tooltip title={title}>
            <IconButton
                className={classes.contrastText}
                onClick={onClick}
                disabled={disabled}
                size={size}
                {...buttonProps}
            >
                {children}
            </IconButton>
        </Tooltip>
    );
};

export default IconButtonWithTooltip;
