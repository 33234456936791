import React, { useEffect, useMemo } from 'react';

import moment from 'moment-timezone';

import { useSnackbar } from 'notistack';

import LinearProgress from '@mui/material/LinearProgress';
import EditIcon from '@mui/icons-material/Edit';

import { useStyles } from './Themable.hooks';
import {
    useCreatorLevel,
    usePane,
    useSnapshotActionsToTrigger,
} from '../../../../../../selectors/index.hooks';
import { useUserSettings } from '../../../../../../../../state/user/index.hooks';
import {
    IReport,
    ISourceSetEntity,
    sourceSetIds,
} from '../../../../../../../../state/types';
import {
    activatePreview,
    closePreview,
    fetchTask,
    stopFetchingTask,
} from '../../../../../../../../state/_actions';
import { clearForm } from '../../../../../../../../state/ui/forms';
import {
    resetRestorer,
    setActionsToTrigger,
} from '../../../../../../../../state/ui/discovery/snapshotting';
import { usePrivileges } from '../../../../../../../../state/auth/index.hooks';

import { useAppDispatch } from '../../../../../../../../hooks';

import TranslationHelper from '../../../../../../../../helpers/TranslationHelper';
import {
    filterByDate,
    filterById,
} from '../../../../../../../../helpers/filterObject';

import PreviewReports from '../../../../../PreviewReports';
import TaskHeader from '../TaskHeader';

import PaneHeader from '../../../../../../../../components/PaneHeader';
import GoogleMapsButton from '../../../../../../../../components/common/GoogleMapsButton';
import IconButtonWithTooltip from '../../../../../../../../components/IconButtonWithTooltip';
import ShowEventButton from '../../../../../ShowEventButton/ShowEventButton';

import IconWithWarning from '../../../../../../../../components/IconWithWarning/IconWithWarning';
import { useTaskFetching } from '../../../../../../selectors/feching/index.hooks';
import {
    useGridSourceSet,
    useTask,
} from '../../../../../../selectors/composed/index.hooks';

const TaskPreview = () => {
    const dispatch = useAppDispatch();

    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();

    const pane = usePane();
    const sourceSet = useGridSourceSet();
    const task = useTask();
    const creatorLevel = useCreatorLevel();
    const userSettings = useUserSettings();
    const privileges = usePrivileges();
    const actionsToTrigger = useSnapshotActionsToTrigger();

    const taskDetails = task?._meta.header.task || null;
    const taskIcon = task?._meta.header.icon || null;
    const taskReports = (task?.reports as IReport[]) || null;
    const metaHeaderTask = task?._meta.header.task;

    const taskFetchingStatus = useTaskFetching().status;

    const showEventTriggerAction = actionsToTrigger?.some(
        (action) => action.type === 'showEvent'
    );
    const showEventButton = task?._meta.menu?.find(
        (item) => item.id === 'showEvent'
    );
    const hasStatusDate = !!metaHeaderTask?.statusDate;

    useEffect(() => {
        if (pane) {
            dispatch(fetchTask(pane.elementId));
        }
        return () => {
            if (pane?.elementId) {
                dispatch(
                    stopFetchingTask(
                        pane.elementId,
                        pane?.elementCollectionName || ''
                    )
                );
            }
        };
    }, []);

    useEffect(() => {
        if (
            !showEventTriggerAction ||
            taskFetchingStatus === 'idle' ||
            taskFetchingStatus === 'loading'
        ) {
            return;
        }
        if (!task) {
            ejectTriggerAction('Event not found');
        } else if (task && !hasStatusDate) {
            ejectTriggerAction('Selected task does not have related event');
        } else if (task && !showEventButton) {
            ejectTriggerAction('Related event cannot be displayed');
        }
    }, [showEventTriggerAction, task, taskFetchingStatus]);

    const getTaskStatusDate = useMemo(() => {
        return metaHeaderTask?.statusDate || '';
    }, [task]);

    const handleChangeMode = () => {
        const id = metaHeaderTask?.id.toString() || '';
        dispatch(
            activatePreview(sourceSetIds.tasks, id, 'task', 'edit', {
                type: 'preview',
                level: creatorLevel,
            })
        );
    };

    const ejectTriggerAction = (text: string) => {
        dispatch(setActionsToTrigger(undefined, creatorLevel));
        dispatch(resetRestorer());
        enqueueSnackbar(TranslationHelper.translate(text), {
            variant: 'error',
        });
    };

    const handleCloseClick = () => {
        dispatch(clearForm({ type: 'task' }));
        dispatch(closePreview(creatorLevel));
    };

    const renderPlannedOn = () => {
        const plannedOn = metaHeaderTask?.plannedOn;
        return plannedOn ? (
            <div className={classes.plannedOnWrapper}>
                {`(${moment(plannedOn).format(userSettings.shortDateFormat)})`}
            </div>
        ) : (
            <></>
        );
    };
    const getEventId = (entities: ISourceSetEntity[]) => {
        if (!task) {
            return null;
        }
        return (
            filterById(entities, task?.id, 'TASKID')?.id ??
            filterByDate(entities, getTaskStatusDate, 'updatedAt')?.id
        );
    };

    const renderRightCustomControls = () => {
        const coordinates = task?._meta.header.coordinates || undefined;
        const isReadOnly =
            metaHeaderTask?.readOnly ||
            (sourceSet?.definitionId === 'tasksPreview' &&
                !sourceSet._meta?.actions?.edit);

        return (
            <>
                <GoogleMapsButton coordinates={coordinates} />
                {privileges.editTask && task && !isReadOnly && (
                    <IconButtonWithTooltip
                        title={TranslationHelper.translate('Edit')}
                        onClick={handleChangeMode}
                    >
                        <EditIcon />
                    </IconButtonWithTooltip>
                )}
                {showEventButton &&
                    (hasStatusDate ? (
                        <ShowEventButton
                            getEventId={getEventId}
                            showEventButton={showEventButton}
                            triggerOnRender={showEventTriggerAction}
                        />
                    ) : (
                        <IconWithWarning title="Task has no status date, so event won't be selected">
                            <ShowEventButton
                                getEventId={getEventId}
                                showEventButton={showEventButton}
                                triggerOnRender={showEventTriggerAction}
                            />
                        </IconWithWarning>
                    ))}
            </>
        );
    };

    return (
        <>
            <PaneHeader
                title={TranslationHelper.translate('Task')}
                onCloseClick={handleCloseClick}
                renderRightCustomControls={renderRightCustomControls}
                renderLeftCustomControls={renderPlannedOn}
            />
            {taskFetchingStatus === 'loading' && !task ? (
                <LinearProgress />
            ) : (
                <>
                    <TaskHeader taskDetails={taskDetails} taskIcon={taskIcon} />
                    <PreviewReports reports={taskReports} />
                </>
            )}
        </>
    );
};

export default TaskPreview;
