import React, { Component, ComponentType } from 'react';
import { compose } from 'redux';

import { Connectable, TConnectableProps } from './Connectable.hoc';
import { Themable, TThemableProps } from './Themable.hoc';

import Translator from '../../../helpers/TranslationHelper';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

type TProps = TConnectableProps & TThemableProps;

class ErrorDialog extends Component<TProps> {
    public handleClose = (event?: object, reason?: string) => {
        if (reason === 'backdropClick') {
            return;
        }
        window.location.reload();
    };

    public render() {
        const { networkStatus, classes } = this.props;
        let title = '';
        let content = '';
        let buttonText = '';

        if (networkStatus === 401) {
            title = Translator.translate('Session expired');
            content = Translator.translate(
                'Your session has expired, please log in again'
            );
            buttonText = Translator.translate('Log in');
        }

        return (
            <Dialog
                open={networkStatus === 401}
                onClose={this.handleClose}
                disableEscapeKeyDown={true}
            >
                <DialogTitle>{title}</DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    <DialogContentText>{content}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={this.handleClose}
                        color="primary"
                        variant="contained"
                        autoFocus={true}
                    >
                        {buttonText}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default compose(Themable, Connectable)(ErrorDialog) as ComponentType;
