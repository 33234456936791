import React, { Component, ComponentType } from 'react';

import { withSnackbar, WithSnackbarProps } from 'notistack';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { allowedFileExtensions, inputAcceptExtensions } from './fileExtensions';
import { Themable, TThemableProps } from './Themable.hoc';

import { FieldProps } from 'formik';

import {
    Collapse,
    FormHelperText,
    IconButton,
    List,
    ListItem,
    ListItemText,
    Tooltip,
} from '@material-ui/core';

import { default as PlusIcon } from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import { default as ExpandLess } from '@material-ui/icons/ExpandLess';
import { default as ExpandMore } from '@material-ui/icons/ExpandMore';

import TranslationHelper from '../../../../helpers/TranslationHelper';

interface IOwnProps {
    maxSize: number;
    readOnlyFiles?: Array<{ id: number; name: string }>;
    error?: boolean;
}

type TProps = IOwnProps & TThemableProps & FieldProps & WithSnackbarProps;

interface IState {
    open: boolean;
}

class FileUploadWrapper extends Component<TProps, IState> {
    public state = {
        open: true,
    };

    public render() {
        const { classes, form, field, readOnlyFiles, maxSize, error } =
            this.props;
        return (
            <>
                <ListItem className={classes.listItemHeading}>
                    <ListItemText
                        primary={TranslationHelper.translate('Attachments')}
                        classes={{
                            primary: error ? classes.error : classes.heading,
                        }}
                    />

                    <Tooltip title={TranslationHelper.translate('Add')}>
                        <IconButton aria-label="Add" component="label">
                            <input
                                // @ts-ignore
                                id={name}
                                // @ts-ignore
                                name={name}
                                multiple={true}
                                type="file"
                                onChange={this.handleImageChange}
                                className={classes.displayNone}
                                accept={inputAcceptExtensions}
                            />
                            <PlusIcon />
                        </IconButton>
                    </Tooltip>

                    <IconButton
                        onClick={this.handleExpand}
                        disabled={form.values[field.name].length === 0}
                        aria-label="Expand"
                    >
                        {this.state.open ? <ExpandLess /> : <ExpandMore />}
                    </IconButton>
                </ListItem>

                <FormHelperText className={classes.infoMessage} error={error}>
                    {`${TranslationHelper.translate(
                        'Total file size cannot exceed'
                    )} ${maxSize} MB`}
                </FormHelperText>

                <Collapse
                    in={this.state.open}
                    timeout="auto"
                    unmountOnExit={true}
                >
                    {readOnlyFiles && (
                        <List
                            component="div"
                            disablePadding={true}
                            dense={true}
                        >
                            {readOnlyFiles.map((item, index: number) => (
                                <ListItem
                                    key={index}
                                    className={classes.listItem}
                                    disabled={true}
                                >
                                    <ListItemText
                                        primary={item.name}
                                        classes={{ primary: classes.itemLabel }}
                                    />
                                </ListItem>
                            ))}
                        </List>
                    )}
                    <List component="div" disablePadding={true} dense={true}>
                        {form.values[field.name].files.map(
                            (item: File, index: number) => (
                                <ListItem
                                    key={index}
                                    className={classes.listItem}
                                >
                                    <ListItemText
                                        primary={item.name}
                                        classes={{ primary: classes.itemLabel }}
                                    />

                                    <Tooltip
                                        title={TranslationHelper.translate(
                                            'Delete'
                                        )}
                                    >
                                        <IconButton
                                            onClick={() =>
                                                this.handleDelete(item.name)
                                            }
                                            aria-label="Delete"
                                            className={classes.iconButton}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </Tooltip>
                                </ListItem>
                            )
                        )}
                    </List>
                </Collapse>
            </>
        );
    }

    private handleImageChange = (e: any) => {
        const { form, field, maxSize, enqueueSnackbar } = this.props;
        const validatedFiles: File[] = [...form.values[field.name].files];
        let validatedFilesSize = validatedFiles.reduce(
            (acc: number, { size }: File) => acc + size,
            0
        );

        const files = e.target.files;

        for (const file of files) {
            const reader = new FileReader();

            reader.onloadend = () => {
                const fileExtension = file.name
                    .split('.')
                    .slice(-1)[0]
                    .toLowerCase();
                if (!allowedFileExtensions.includes(fileExtension)) {
                    enqueueSnackbar(
                        TranslationHelper.translate('Unsupported file type'),
                        { variant: 'error' }
                    );
                    return;
                }

                validatedFiles.push(file);
                validatedFilesSize += file.size;
                const errors = validatedFilesSize > maxSize * 1000000;

                form.setFieldValue(field.name, {
                    files: validatedFiles,
                    errors,
                });
            };

            reader.readAsDataURL(file);
        }
    };

    private handleExpand = () => {
        this.setState(({ open }) => ({ open: !open }));
    };

    private handleDelete = (name: string) => {
        const { form, field, maxSize } = this.props;
        const filteredFiles = form.values[field.name].files.filter(
            (file: File) => file.name !== name
        );

        const newSize = filteredFiles.reduce(
            (acc: number, { size }: File) => acc + size,
            0
        );

        form.setFieldValue(field.name, {
            files: filteredFiles,
            errors: newSize > maxSize * 1000000,
        });
    };
}

export default compose(
    Themable,
    connect(),
    withSnackbar
)(FileUploadWrapper) as ComponentType<IOwnProps>;
