import React, { FunctionComponent } from 'react';

interface IProps {
    message: string;
    error: boolean;
}

const ValidationMessage: FunctionComponent<IProps> = ({ error, message }) => (
    <div className={'validation' + (error ? ' error' : '')}>{message}</div>
);

export default ValidationMessage;
