export default (id: string, href: string) => {
    if (!document.getElementById(id)) {
        const head = document.getElementsByTagName('head')[0];
        const link = document.createElement('link');

        link.id = id;
        link.rel = 'stylesheet';
        link.type = 'text/css';
        link.href = href;
        link.media = 'all';

        head.appendChild(link);

        document.body.style.display = 'block';
    }
};
