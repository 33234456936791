import React, { useState } from 'react';

import { ListItem, Typography } from '@material-ui/core';
import { Box, Dialog, DialogContent } from '@mui/material';
import { Alert } from '@material-ui/lab';

import useToggle from '../../../../hooks/useToggle';
import { useAppDispatch } from '../../../../hooks';
import TranslationHelper from '../../../../helpers/TranslationHelper';

import { hideGlobalDialog } from '../../../../state/ui/dialog';
import {
    fetchRoutes,
    reassignTasks,
    UNASSIGNED_ID,
} from '../../../../state/ui/planning/routes';

import { useStyles } from './Themable.hooks';

import DialogTitle from '../../../dialogs/DialogTitle';
import LoadingSpinner from '../../../loadingSpinner/LoadingSpinner';
import useAsyncThunkWithSnackbar from '../../../../hooks/useAsyncThunkWithSnackbar';
import {
    useSelectedCategory,
    useSelectedDates,
} from '../../../../state/ui/planning/routes/index.hooks';

interface IOwnProps {
    tasks: { tasksList: { routeName: string; count: number }[]; ids: number[] };
    target: { name: string; id: number };
    warning: boolean;
}

const RouteAssignDialog = ({ tasks, target, warning }: IOwnProps) => {
    const dispatch = useAppDispatch();

    const handleAsyncRequest = useAsyncThunkWithSnackbar();

    const category = useSelectedCategory();
    const dates = useSelectedDates();

    const { isOpen, handleToggle } = useToggle(true);
    const classes = useStyles();

    const [loading, setLoading] = useState(false);
    const handleClose = (event?: object, reason?: string) => {
        if (reason === 'backdropClick') {
            return;
        }
        dispatch(hideGlobalDialog());

        handleToggle();
    };

    const handleSubmit = () => {
        setLoading(true);
        handleAsyncRequest({
            asyncAction: reassignTasks({
                taskIds: tasks.ids,
                targetRouteId:
                    target.id === UNASSIGNED_ID ? undefined : target.id,
            }),
            onSuccess: {
                callback: () => {
                    category &&
                        handleAsyncRequest({
                            asyncAction: fetchRoutes({
                                from: dates.from,
                                to: dates.to,
                                activityCategoryId: category,
                            }),
                        });
                },
                message: TranslationHelper.translate(
                    'Tasks reassigned succesfully'
                ),
            },
            onError: {
                uniqueCase: {
                    504: TranslationHelper.translate(
                        'Due to server timeout, only part of tasks were reassigned'
                    ),
                },
            },
            onFinally: {
                callback: () => {
                    handleClose();
                    setLoading(false);
                },
            },
        });
    };

    const dialogContent = () => {
        if (loading) {
            return (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: 200,
                    }}
                >
                    <LoadingSpinner />
                </Box>
            );
        }
        return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    minWidth: '500px',
                }}
            >
                <Typography color={'primary'} className={classes.typography}>
                    {`${TranslationHelper.translate('Target route')}: ${
                        target.name
                    }`}
                </Typography>
                {warning && (
                    <Alert severity="warning">
                        {TranslationHelper.translate(
                            'Some of the selected tasks cannot be assigned, due to wrong status'
                        )}
                    </Alert>
                )}
                <Typography color={'primary'} className={classes.typography}>
                    {TranslationHelper.translate('Number of tasks by route')}:
                </Typography>

                {tasks.tasksList.map((task, i) => {
                    return (
                        <ListItem key={i} className={classes.listItem}>
                            <div className={classes.itemLabel}>
                                {task.routeName}
                            </div>
                            <div>{task.count}</div>
                        </ListItem>
                    );
                })}
            </Box>
        );
    };
    return (
        <Dialog
            open={isOpen}
            onClose={handleClose}
            maxWidth={'lg'}
            disableEscapeKeyDown
        >
            <DialogTitle
                title={TranslationHelper.translate('Confirm tasks assignment')}
                close={handleClose}
                save={handleSubmit}
                loading={loading}
            />

            <DialogContent style={{ maxWidth: 800 }}>
                {dialogContent()}
            </DialogContent>
        </Dialog>
    );
};

export default RouteAssignDialog;
