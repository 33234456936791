import { IPrivileges } from '../../state/auth';
import { IGridViewListItem } from '../../state/ui/gridViews';

export const hasAccessToView = (
    view: IGridViewListItem,
    privileges: IPrivileges
) => {
    switch (view.accessType) {
        case 'PRIVATE':
            return true;
        case 'PUBLIC':
            return privileges.managePublicGridViews;
        case 'GROUP':
            return privileges.manageGroupGridViews;
        default:
            return false;
    }
};
