import { IDataPermissions } from '../../../../../../../../../state/user/types';

export const hasAccessToLocations = (
    groupId: string,
    dataPermissions: IDataPermissions | undefined
) => {
    return (
        dataPermissions !== undefined &&
        (dataPermissions.type === 'ALL' ||
            (dataPermissions.type === 'GROUPS' &&
                dataPermissions.groups.includes(groupId)))
    );
};
