import {
    createAsyncThunk,
    createSlice,
    createSelector,
} from '@reduxjs/toolkit';

import { TRootState } from '../../../store';

import TranslationHelper from '../../../helpers/TranslationHelper';
import { IDevice, ISourceSet, TSourceSetEntity } from '../../types';
import { getPrivileges } from '../../auth';
import { restReadDevicesCollection } from '../../../services/registers';
import {
    IRestPatchDevice,
    IRestPutDevice,
    restPatchDevices,
    restPutDevice,
} from '../../../services/devices';

import { TOptionalNormalizedData } from '../../app/collections/dataTypes';

interface IState {
    collection: TOptionalNormalizedData<IDevice>;
    selectedDevicesIds: number[];
    selectedDevice: { deviceId: number; collectionId: string } | null;
    mode: null | 'preview' | 'edit' | 'create' | 'editMultiple';
    loading: boolean;
}

export const initialState: IState = {
    collection: { result: [], entities: {} },
    selectedDevicesIds: [],
    selectedDevice: null,
    mode: null,
    loading: false,
};

export const fetchDevices = createAsyncThunk('get:ui/devices', async () => {
    const response = await restReadDevicesCollection();
    return response;
});

export const updateDevices = createAsyncThunk(
    'patch:ui/devices',
    async (devices: IRestPatchDevice[], { rejectWithValue, dispatch }) => {
        try {
            const response = await restPatchDevices(devices);
            await dispatch(fetchDevices());

            return response;
        } catch (e: any) {
            throw rejectWithValue({
                status: e.status,
            });
        } finally {
            dispatch(resetMode());
        }
    }
);
export const updateDevice = createAsyncThunk(
    'put:ui/devices',
    async (device: IRestPutDevice, { rejectWithValue, dispatch }) => {
        try {
            const response = await restPutDevice(device);
            await dispatch(fetchDevices());
            dispatch(goToPreviewMode());

            return response;
        } catch (e: any) {
            throw rejectWithValue({
                status: e.status,
            });
        }
    }
);

const devices = createSlice({
    name: 'devices',
    initialState,
    reducers: {
        selectDevice(state, action) {
            state.selectedDevicesIds = action.payload;
        },
        selectPreviewDevice(state, action) {
            state.mode = 'preview';
            state.selectedDevice = {
                deviceId: action.payload.deviceId,
                collectionId: action.payload.collectionId,
            };
        },
        enterEditMode(state) {
            state.mode = 'edit';
        },
        enterEditMultipleMode(state) {
            state.mode = 'editMultiple';
        },
        goToPreviewMode(state) {
            state.mode = 'preview';
        },
        enterCreateMode(state) {
            state.mode = 'create';
            state.selectedDevicesIds = [];
        },
        resetMode(state) {
            state.mode = null;
        },
        deselectDevices(state) {
            state.selectedDevicesIds = [];
        },
        deselectDevice(state) {
            state.selectedDevice = null;
        },
        setLoading(state, action) {
            state.loading = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(updateDevice.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(updateDevice.fulfilled, (state) => {
            state.selectedDevicesIds = [];
            state.loading = false;
        });
        builder.addCase(updateDevice.rejected, (state) => {
            state.loading = false;
        });
        builder.addCase(updateDevices.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(updateDevices.fulfilled, (state) => {
            state.selectedDevicesIds = [];
            state.loading = false;
        });
        builder.addCase(updateDevices.rejected, (state) => {
            state.loading = false;
        });
        builder.addCase(fetchDevices.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(fetchDevices.fulfilled, (state, action) => {
            state.loading = false;

            state.collection = action.payload;
        });
        builder.addCase(fetchDevices.rejected, (state) => {
            state.loading = false;
            state.collection = { result: [], entities: {} };
        });
    },
});

export const getDevicesLoadingState = (state: TRootState) =>
    state.ui.devices.loading;

export const getDevicesMode = (state: TRootState) => state.ui.devices.mode;

export const getSelectedDevicesId = (state: TRootState) =>
    state.ui.devices.selectedDevicesIds;

export const getSelectedPreviewCollectionId = (state: TRootState) =>
    state.ui.devices.selectedDevice?.collectionId;

export const getDevicesCollection = (state: TRootState) =>
    state.ui.devices.collection;

export const getSourceSetModels = (state: TRootState) =>
    state.ui.discovery.general.sourceSetModels;

export const devicesPaneVisible = createSelector(
    [getDevicesMode],
    (mode) => !!mode
);

const getDevicesAsArray = createSelector(
    [getDevicesCollection],
    (devices) => devices && devices.result.map((id) => devices.entities[id])
);

export const getSelectedMultiDevices = createSelector(
    [getDevicesCollection, getSelectedDevicesId],
    (collection, ids) => {
        if (collection?.entities) {
            return Object.values(collection.entities).filter((el) =>
                ids.includes(el.id)
            );
        }

        return [];
    }
);

export const getSelectedDevice = createSelector(
    [getDevicesCollection, getSelectedPreviewCollectionId],
    (collection, device) =>
        (collection && device && collection.entities[String(device)]) || null
);
export const getDevicesAsSourceSet = createSelector(
    [getDevicesAsArray, getPrivileges],
    (devices, privileges): ISourceSet | null => {
        return (
            devices && {
                id: 'devices',
                definitionId: 'devices',
                label: 'Devices',
                attributes: [
                    { id: 'id', label: 'ID', type: 'number' },
                    { id: 'recurringId', label: 'ID', type: 'string' },
                    {
                        id: 'serialNumber',
                        label: 'Serial number',
                        type: 'string',
                    },
                    { id: 'name', label: 'Object name', type: 'string' },
                    {
                        id: 'groups',
                        label: 'Object groups',
                        type: 'tooltipList',
                        formatting: { pattern: '{0}', separator: ', ' },
                    },
                    ...(privileges.eTollReadOnly ||
                    privileges.eToll ||
                    privileges.eTollEditRegistrationData
                        ? [{ id: 'gridStatus', label: 'eToll', type: 'string' }]
                        : []),
                ],
                layersAttributes: [],
                _meta: {},
                entities: devices?.map(
                    (device: IDevice): TSourceSetEntity => ({
                        ...device,
                        id: device.id + device.serialNumber,
                        recurringId: device.id,
                        gridStatus:
                            device.integrations?.eToll.status !== undefined &&
                            device.attributes?.eToll === true
                                ? `${device.eTollTranslated} (${device.integrations?.eToll.status})`
                                : TranslationHelper.translate('No'),
                        _meta: {},
                    })
                ),
            }
        );
    }
);

export const getDevicesSourceSetModel = createSelector(
    [getSourceSetModels],
    (models) => models.devices || null
);
export const {
    selectDevice,
    selectPreviewDevice,
    enterCreateMode,
    enterEditMode,
    enterEditMultipleMode,
    goToPreviewMode,
    resetMode,
    deselectDevice,
    deselectDevices,
    setLoading,
} = devices.actions;

export default devices.reducer;
