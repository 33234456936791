import React from 'react';

import { LoginPageBackGround } from '../LoginPage/LoginPageBackGround';
import { LoginPageLogo } from '../LoginPage/LoginPageLogo';
import LoginFormFooter from '../../../components/Authentication/LoginFormFooter';

import TranslationHelper from '../../../helpers/TranslationHelper';
import { useAppDispatch } from '../../../hooks';

import { useStyles } from '../Themable.hooks';
import useAsyncThunkWithSnackbar from '../../../hooks/useAsyncThunkWithSnackbar';
import { logout } from '../../../state/_actions/logoutAction';
import { resendTrustedDeviceRegistrationEmails } from '../../../state/ui/trustedDevices';
import TrustedDeviceActions from './TrustedDeviceActions';
import clsx from 'clsx';
import { LOGOUT_TIME_MS } from './consts';

const TrustedDeviceResendingEmails = () => {
    const classes = useStyles();

    const dispatch = useAppDispatch();

    const handleAsyncRequest = useAsyncThunkWithSnackbar();

    const handleTrustedDeviceEmailsDelivery = () => {
        handleAsyncRequest({
            asyncAction: resendTrustedDeviceRegistrationEmails(),
            onSuccess: {
                message: TranslationHelper.translate(
                    'auth.trustedDeviceEmailResend'
                ),
            },
            onFinally: {
                callback: () =>
                    setTimeout(() => dispatch(logout()), LOGOUT_TIME_MS),
            },
        });
    };
    return (
        <LoginPageBackGround>
            <div
                className={clsx(
                    classes.loginForm,
                    classes.trustedDeviceBackground
                )}
            >
                <LoginPageLogo />
                <div className={classes.formButtons}>
                    {TranslationHelper.translate('auth.pendingTrustedDevice')}
                </div>
                <TrustedDeviceActions
                    onClick={handleTrustedDeviceEmailsDelivery}
                />
                <LoginFormFooter />
            </div>
        </LoginPageBackGround>
    );
};

export default TrustedDeviceResendingEmails;
