import { createStyles, Theme, WithStyles } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme: Theme) => {
    const padding = theme.spacing(2);
    return createStyles({
        label: {
            color: theme.palette.getContrastText(theme.palette.primary.main),
            display: 'inline',
            paddingTop: theme.spacing(),
            fontSize: '10px',
        },
        time: {
            paddingTop: theme.spacing(),
            textAlign: 'right',
            '&:first-child': {
                paddingTop: 0,
                textAlign: 'left',
            },
        },
        timeSingle: {
            textAlign: 'center',
        },
        timeWrapper: {
            flexGrow: 1,
            padding: '0 20px',
        },
        timeValue: {
            fontSize: '16px',
            color: theme.palette.getContrastText(theme.palette.primary.main),
            '& strong': {
                fontWeight: 'bold',
                fontSize: '12px',
                verticalAlign: 'top',
                margin: '0 5px',
            },
        },
        timeRange: {
            paddingLeft: padding,
            paddingRight: padding,
            paddingBottom: padding,
            paddingTop: padding,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            backgroundColor: theme.palette.primary.light,
        },
        colorPrimary: {
            color: theme.palette.getContrastText(theme.palette.primary.main),
        },
        colorPrimaryDisabled: {
            //     color: 'white'
        },
        snackbarSuccess: {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.getContrastText(theme.palette.primary.main),
        },
    });
};

export type TThemableProps = WithStyles<typeof styles>;

export const Themable = withStyles(styles, { withTheme: true });
