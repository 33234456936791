import React from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ListSubheader from '@material-ui/core/ListSubheader';
import { useTheme } from '@material-ui/core/styles';
import { VariableSizeList, ListChildComponentProps } from 'react-window';

const LISTBOX_PADDING = 8; // px
const SMALL_ITEM_SIZE = 36;
const REGULAR_ITEM_SIZE = 48;
const ITEM_LIMIT = 8;
const OVERSCAN = 5;

function renderRow(props: ListChildComponentProps) {
    const { data, index, style } = props;
    return React.cloneElement(data[index], {
        style: {
            ...style,
            top: (style.top as number) + LISTBOX_PADDING,
        },
    });
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
    const outerProps = React.useContext(OuterElementContext);
    return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data: any) {
    const ref = React.useRef<VariableSizeList>(null);
    React.useEffect(() => {
        if (ref.current != null) {
            ref.current.resetAfterIndex(0, true);
        }
    }, [data]);
    return ref;
}

export const ListboxComponent = React.forwardRef<HTMLDivElement>(
    function ListboxComponent(props, ref) {
        const { children, ...other } = props;
        const itemData = React.Children.toArray(children);
        const theme = useTheme();
        const smUp = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true });
        const itemCount = itemData.length;
        const itemSize = smUp ? SMALL_ITEM_SIZE : REGULAR_ITEM_SIZE;

        const getChildSize = (child: React.ReactNode) => {
            if (React.isValidElement(child) && child.type === ListSubheader) {
                return REGULAR_ITEM_SIZE;
            }

            return itemSize;
        };

        const getHeight = () => {
            if (itemCount > ITEM_LIMIT) {
                return ITEM_LIMIT * itemSize;
            }
            return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
        };

        const gridRef = useResetCache(itemCount);

        return (
            <div ref={ref}>
                <OuterElementContext.Provider value={other}>
                    <VariableSizeList
                        itemData={itemData}
                        height={getHeight() + 2 * LISTBOX_PADDING}
                        width="100%"
                        style={{ overflowX: 'hidden', whiteSpace: 'nowrap' }}
                        ref={gridRef}
                        outerElementType={OuterElementType}
                        innerElementType="ul"
                        itemSize={(index) => getChildSize(itemData[index])}
                        overscanCount={OVERSCAN}
                        itemCount={itemCount}
                    >
                        {renderRow}
                    </VariableSizeList>
                </OuterElementContext.Provider>
            </div>
        );
    }
);
