import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => {
    const spacing = theme.spacing();
    return {
        wrapper: {
            margin: spacing,
        },
        symbolWrapper: {
            marginRight: spacing * 2,
            marginLeft: spacing,
        },
        detailsWrapper: {
            flexGrow: 1,
            width: '85%',
        },
        adress: {
            fontWeight: 'bold',
            fontSize: '18px',
        },
        time: {
            color: 'gray',
        },
        detailsSection: {
            display: 'flex',
            marginBottom: spacing,
        },
        detailsIcon: {
            width: '15%',
            textAlign: 'center',
            margin: 'auto',
        },
    };
});
