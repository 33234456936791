import React, { useEffect, useState } from 'react';

import { Field, FormikErrors, FormikValues } from 'formik';

import { useSnackbar } from 'notistack';

import { CircularProgress, InputAdornment } from '@material-ui/core';
import MyLocationIcon from '@material-ui/icons/MyLocation';
import LanguageIcon from '@material-ui/icons/Language';

import { useStyles } from './Themable.hooks';

import TranslationHelper from '../../helpers/TranslationHelper';

import { useAppDispatch } from '../../hooks';

import { fetchLocationAddress } from '../../state/app/locations';

import FieldWrapper from '../../components/common/formikWrappers/FieldWrapper';
import NumberFieldWrapper from '../../components/common/formikWrappers/NumberFieldWrapper';
import { ICoordinates } from '../../state/types';
import { storeLastMapClickPosition } from '../../state/_actions';
import IconButtonWithTooltip from '../IconButtonWithTooltip/IconButtonWithTooltip';

interface IAddress {
    address: string;
    longitude: number;
    latitude: number;
}
interface IOwnProps<T extends IAddress> {
    errors: FormikErrors<T>;
    values: FormikValues;
    mapCoordinates: ICoordinates | null;
    setFieldValue: (field: string, value: any) => void;
    callback: (data: ICoordinates) => void;
}

const AddressSection = <T extends IAddress>({
    errors,
    values,
    mapCoordinates,
    setFieldValue,
    callback,
}: IOwnProps<T>) => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useAppDispatch();

    const [synchronizingWithMap, setSynchronizingWithMap] =
        useState<boolean>(false);

    useEffect(() => {
        if (mapCoordinates) {
            setFieldValue('longitude', mapCoordinates.x.toFixed(6) || '');
            setFieldValue('latitude', mapCoordinates.y.toFixed(6) || '');
        }
    }, [mapCoordinates]);

    const showNotification = (success: boolean, message: string) => {
        enqueueSnackbar(message, {
            variant: success ? 'success' : 'error',
        });
    };

    const handleSynchronizationClick = ({ address }: FormikValues) => {
        if (!address) {
            showNotification(
                false,
                TranslationHelper.translate('Enter address of the location')
            );
        } else {
            setSynchronizingWithMap(true);
            dispatch(
                fetchLocationAddress(
                    address,
                    (data) => {
                        callback(data);
                        setSynchronizingWithMap(false);
                    },
                    () => {
                        setSynchronizingWithMap(false);
                        showNotification(
                            false,
                            TranslationHelper.translate(
                                'Failed to find location, try again'
                            )
                        );
                    }
                )
            );
        }
    };

    const handleChangeMarkerPosition = (values: FormikValues) => {
        dispatch(
            storeLastMapClickPosition(
                Number(Number(values.longitude).toFixed(6)),
                Number(Number(values.latitude).toFixed(6))
            )
        );
    };

    return (
        <>
            <Field
                className={classes.field}
                error={!!errors?.address}
                helperText={
                    errors?.address ||
                    TranslationHelper.translate(
                        'Format: City, Street name, House number'
                    )
                }
                name={'address'}
                label={TranslationHelper.translate('Look for location')}
                fullWidth={true}
                component={FieldWrapper}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButtonWithTooltip
                                aria-label={TranslationHelper.translate(
                                    'Geocode address'
                                )}
                                title={TranslationHelper.translate(
                                    'Geocode address'
                                )}
                                onClick={() =>
                                    handleSynchronizationClick(values)
                                }
                                defaultColor
                            >
                                {synchronizingWithMap ? (
                                    <CircularProgress size={20} />
                                ) : (
                                    <LanguageIcon />
                                )}
                            </IconButtonWithTooltip>
                        </InputAdornment>
                    ),
                }}
            />

            <div className={classes.flex}>
                <Field
                    className={classes.field}
                    error={!!errors?.longitude}
                    helperText={errors?.longitude}
                    name={'longitude'}
                    label={TranslationHelper.translate('Lon.')}
                    fullWidth={true}
                    required={true}
                    inputProps={{
                        min: -179,
                        max: 179,
                        step: 0.000001,
                    }}
                    component={NumberFieldWrapper}
                />

                <Field
                    className={classes.field}
                    error={!!errors?.latitude}
                    helperText={errors?.latitude}
                    name={'latitude'}
                    label={TranslationHelper.translate('Lat.')}
                    required={true}
                    fullWidth={true}
                    inputProps={{
                        min: -89,
                        max: 89,
                        step: 0.000001,
                    }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButtonWithTooltip
                                    aria-label={TranslationHelper.translate(
                                        'Synhronize with map'
                                    )}
                                    title={TranslationHelper.translate(
                                        'Synhronize with map'
                                    )}
                                    onClick={() =>
                                        handleChangeMarkerPosition(values)
                                    }
                                    defaultColor
                                >
                                    {synchronizingWithMap ? (
                                        <CircularProgress size={20} />
                                    ) : (
                                        <MyLocationIcon />
                                    )}
                                </IconButtonWithTooltip>
                            </InputAdornment>
                        ),
                    }}
                    component={NumberFieldWrapper}
                />
            </div>
        </>
    );
};

export default AddressSection;
