import { useSelector } from 'react-redux';
import { TRootState } from '../../../store';

import {
    getClustering,
    getCurrentSnapshot,
    getGeneralClustering,
    getGridCreatorLevel,
    getMapFitToExtentIsPending,
    getPreviewPaneObjectId,
    getTimeLock,
    isDiscoverUIGridFullscreen,
    shouldMapBeInEditMode,
    mapInDrawMode,
    getDiscoveryUIPreviewPane,
    getPreviewCreatorLevel,
    areSearcherResultsVisible,
    getIncludeNotPlanned,
    getNotAssignedFilter,
    getSourceSetDates,
    isAnalyticsModeEnabled,
    isDiscoveryUIChartPaneVisible,
    isDiscoverUIDataGridPaneVisible,
    isDiscoveryUICalibrationPaneVisible,
    isDiscoveryUIDetectionPaneVisible,
    getGridChartFlag,
    getDiscoveryUIDataGridPane,
    getSourceSetTime,
    getPreviewAction,
    getActiveChartAttributes,
    shouldDiscoveryBeInEditMode,
    getGridFilters,
    getNearEventsRadius,
    getLastMapClickPosition,
    getGridSourceSetId,
    getActionsToTrigger,
    getDiscoveryUIDialog,
    getExtendedReports,
    getPreviewElementId,
    getPreviewDataSegmentsReports,
    getActiveChartAttributesColors,
    getCurrentSnapshotPath,
    getDiscoveryUIChartPane,
    getCalculatedUIState,
    getCurrentSnapshotLevel,
} from './index';

export const useSnapshot = () => useSelector(getCurrentSnapshot);
export const useCurrentSnapshotLevel = () =>
    useSelector(getCurrentSnapshotLevel);

export const useSnapshotClustering = () => useSelector(getClustering);
export const useSnapshotActionsToTrigger = () =>
    useSelector(getActionsToTrigger);
export const useGridSnapshot = () => useSelector(getDiscoveryUIDataGridPane);
export const usePreviewDataSegmentsReports = () =>
    useSelector(getPreviewDataSegmentsReports);
export const usePreviewPaneId = () => useSelector(getPreviewPaneObjectId);
export const useCreatorLevel = () => useSelector(getPreviewCreatorLevel);
export const usePreviewAction = () => useSelector(getPreviewAction);

export const useExtendedReports = () => useSelector(getExtendedReports);

export const usePane = () => useSelector(getDiscoveryUIPreviewPane);
export const usePreviewElementId = () => useSelector(getPreviewElementId);

export const useActiveChartAttributes = () =>
    useSelector(getActiveChartAttributes);
export const useActiveChartAttributesColors = () =>
    useSelector(getActiveChartAttributesColors);
export const useIsChartPaneVisible = () =>
    useSelector(isDiscoveryUIChartPaneVisible);
export const useDiscoveryChartPane = () => useSelector(getDiscoveryUIChartPane);
export const useIsDataGridPaneVisible = () =>
    useSelector(isDiscoverUIDataGridPaneVisible);
export const useIsCalibrationDataChartPaneVisible = () =>
    useSelector(isDiscoveryUICalibrationPaneVisible);
export const useIsDetectionChartPaneVisible = () =>
    useSelector(isDiscoveryUIDetectionPaneVisible);
export const useAreEmployeesSearchResultsVisible = () =>
    useSelector((state: TRootState) =>
        areSearcherResultsVisible(state, 'employees')
    );
export const useAreVehiclesSearchResultsVisible = () =>
    useSelector((state: TRootState) =>
        areSearcherResultsVisible(state, 'vehicles')
    );

export const useIsGridChart = () => useSelector(getGridChartFlag);

export const useIncludedNotPlanned = () => useSelector(getIncludeNotPlanned);
export const useOnlyNotAssigned = () => useSelector(getNotAssignedFilter);

export const useSourceSetDates = () => useSelector(getSourceSetDates);
export const useSourceSetTime = () => useSelector(getSourceSetTime);

export const useSourceSetId = () => useSelector(getGridSourceSetId);

export const useCalculatedState = () => useSelector(getCalculatedUIState);
export const useGridCreatorLevel = () => useSelector(getGridCreatorLevel);
export const useIsGridFullscreen = () =>
    useSelector(isDiscoverUIGridFullscreen);
export const useGridFilters = () => useSelector(getGridFilters);
export const useIsAnalyticsModeEnabled = () =>
    useSelector(isAnalyticsModeEnabled);

export const useShouldMapBeInEditMode = () =>
    useSelector(shouldMapBeInEditMode);
export const useMapInDrawMode = () => useSelector(mapInDrawMode);
export const useFitToExtentIsPending = () =>
    useSelector(getMapFitToExtentIsPending);
export const useClustering = () => useSelector(getGeneralClustering);
export const useTimeLocked = () => useSelector(getTimeLock);
export const useLastClickCoordinate = () =>
    useSelector(getLastMapClickPosition);

export const useNearEventsRadius = () => useSelector(getNearEventsRadius);

export const useDiscoveryEditMode = () =>
    useSelector(shouldDiscoveryBeInEditMode);

export const useDiscoveryUIDialog = () => useSelector(getDiscoveryUIDialog);
export const useCurrentSnapshotPath = () => useSelector(getCurrentSnapshotPath);
