import { createSelector } from 'reselect';
import {
    getAggregationElements,
    getFramesElements,
    getLastStates,
    getLocations,
    getRecognizedNumbersSection,
    getReportSets,
    getSourceSetElements,
    getSourceSets,
    getTasks,
    getTrails,
} from '../entities';
import {
    getCurrentSnapshot,
    getCurrentSnapshotPathId,
    getDiscoveryUICalibrationPane,
    getDiscoveryUIDataGridPane,
    getDiscoveryUIPreviewPane,
    getGridSourceSetId,
    getParamHelper,
    getPreviewDataSegmentsReports,
    getPreviewElementId,
    getPreviewLocationId,
    getPreviewPaneMonitoredId,
    getSourceSetModels,
    isAnalyticsModeEnabled,
} from '..';
import {
    ILastState,
    IReportSet,
    ISourceSet,
    ITrail,
} from '../../../../state/types';
import { getCustomLayers } from '../../../../state/ui/discovery/general';
import { setEntitiesLayers } from '../../components/DiscoveryMap/_utils/customLayers';
import { getSourceSetWithFilter } from '../sourceSet/getSourceSetWithFilter';
import { IReportSetParams } from 'src/app/state/ui/discovery/types';
import { processCalibrationChartData } from '../charts/processCalibrationChartData';
import {
    getCalibrationChartEvents,
    getCalibrationData,
    getCalibrationParamId,
} from '../calibrations';

export const getGridSourceSet = createSelector(
    [getSourceSets, getGridSourceSetId],
    (sets, setId): ISourceSet | null => (setId && sets[setId]) || null
);

export const getGridSourceSetEntitiesIds = createSelector(
    [getGridSourceSet],
    (sourceSet): string[] | null =>
        sourceSet && sourceSet.entities.map((entity) => entity.id)
);
export const getSourceSetModel = createSelector(
    [getSourceSetModels, getGridSourceSet, isAnalyticsModeEnabled],
    (models, sourceSet, analyticsModeEnabled) =>
        (sourceSet &&
            models[
                analyticsModeEnabled ? `analytics${sourceSet.id}` : sourceSet.id
            ]) ||
        null
);

export const getModeledGridSourceSet = createSelector(
    [getGridSourceSet, getCustomLayers],
    (sourceSet, layers) => {
        if (!sourceSet) {
            return sourceSet;
        }
        let entities = sourceSet.entities;
        if (layers.length) {
            entities = entities?.map((entity) =>
                setEntitiesLayers(entity, layers)
            );
        }
        return entities ? { ...sourceSet, entities } : sourceSet;
    }
);

export const getDiscoveryMapSourceSet = getSourceSetWithFilter(
    getSourceSetModel,
    getGridSourceSet
);

export const getPreviewMonitoredObjectHeader = createSelector(
    [getLastStates, getDiscoveryUIPreviewPane],
    (objects, preview) =>
        (preview &&
            preview.elementId !== null &&
            objects[preview.elementId] &&
            objects[preview.elementId]._meta?.header) ||
        null
);

export const getMonitoredObjectCapacity = createSelector(
    [getPreviewMonitoredObjectHeader],
    (header) => header?.capacity || 0
);
export const getPreviewTask = createSelector(
    [getTasks, getDiscoveryUIPreviewPane],
    (objects, preview) =>
        (preview && preview.elementId !== null && objects[preview.elementId]) ||
        undefined
);
export const getPreviewLastState = createSelector(
    [getLastStates, getDiscoveryUIPreviewPane],
    (lastStates, preview): ILastState | null =>
        (preview &&
            preview.elementId !== null &&
            lastStates[preview.elementId]) ||
        null
);
export const getPreviewReportSet = createSelector(
    [getReportSets, getDiscoveryUIPreviewPane],
    (reportSets, preview): IReportSet | null =>
        (preview &&
            preview.elementId !== null &&
            reportSets[preview.elementId]) ||
        null
);

export const getPreviewSourceSetElement = createSelector(
    [getPreviewElementId, getSourceSetElements],
    (elementId, elements) => (elementId && elements[elementId]) || null
);

export const getSourceSetElementDate = createSelector(
    [getPreviewSourceSetElement],
    (element) => element?._meta?.header?.date || null
);

export const getSourceSetMovieFramesIds = createSelector(
    [getPreviewSourceSetElement, getParamHelper],
    (element, id) =>
        element?.reports
            .filter((report) => {
                return report.id === id;
            })
            .map((report) => report.entities)
            .reduce((acc, curr) => acc.concat(curr), [])
            .map((entity) => entity.id)
);

export const getSourceSetElementEntities = createSelector(
    [getPreviewSourceSetElement],
    (element) => element?.reports.map((report) => report.entities).flat()
);
export const getSourceSetElementMonitoredId = createSelector(
    [getPreviewSourceSetElement],
    (element) => element?._meta?.header?.monitoredId || null
);

export const getFramePreviewSourceSetElement = createSelector(
    [getPreviewElementId, getFramesElements],
    (elementId, elements) => (elementId && elements?.[elementId]) || null
);

export const getFramesSourceSetElement = createSelector(
    [getPreviewElementId, getFramesElements, getRecognizedNumbersSection],
    (elementId, elements, recognizedNumbersReport) => {
        if (!elementId || !elements?.[elementId]) {
            return null;
        }
        const reports = [...elements[elementId].reports];
        if (recognizedNumbersReport) {
            reports.push(recognizedNumbersReport);
        }
        return { ...elements[elementId], reports };
    }
);

export const getAggregationSourceSetElement = createSelector(
    [getPreviewElementId, getAggregationElements],
    (elementId, elements) => (elementId && elements[elementId]) || null
);

const getPreviewPaneTrail = createSelector(
    [getDiscoveryUIPreviewPane, getTrails],
    (pane, trails) =>
        (pane &&
            pane.reportSetParams &&
            pane.reportSetParams.monitoredId &&
            trails &&
            Object.values(trails).find(
                (trail) =>
                    trail.id ===
                    (pane.reportSetParams as IReportSetParams).monitoredId
            )) ||
        null
);
export const getDataSegmentsTrails = createSelector(
    [getPreviewDataSegmentsReports, getTrails],
    (segmentData, trails) => {
        if (!segmentData) {
            return [];
        }
        const resultArray = segmentData.reduce(
            (segmentTrails: ITrail[], segment) => {
                if (trails[segment.id]) {
                    segmentTrails.push({
                        ...trails[segment.id],
                        color: segment.color,
                    });
                }
                return segmentTrails;
            },
            []
        );
        return resultArray;
    }
);
const getDataGridTrail = createSelector(
    [getDiscoveryUIDataGridPane, getTrails],
    (pane, trails) =>
        (pane &&
            pane.preview !== null &&
            pane.preview.reportSetParams &&
            pane.preview.reportSetParams.monitoredId &&
            trails &&
            Object.values(trails).find((trail) => {
                if (
                    pane &&
                    pane.preview !== null &&
                    pane.preview !== undefined
                ) {
                    return (
                        trail.id ===
                        (pane.preview.reportSetParams as IReportSetParams)
                            .monitoredId
                    );
                }
                return false;
            })) ||
        null
);

const getSourceSetElementTrail = createSelector(
    [
        getPreviewSourceSetElement,
        getAggregationSourceSetElement,
        getTrails,
        getCurrentSnapshotPathId,
        getCurrentSnapshot,
    ],
    (preview, aggregation, trails, snapshotPathId, snapshot) => {
        const monitoredId =
            (preview &&
                preview._meta &&
                preview._meta.header &&
                preview._meta.header.monitoredId) ||
            (aggregation &&
                aggregation._meta &&
                aggregation._meta.header &&
                aggregation._meta.header.monitoredId) ||
            snapshotPathId;

        return (
            (monitoredId &&
                trails &&
                Object.values(trails).find(
                    (trail) =>
                        trail.id === String(monitoredId) &&
                        trail.level < snapshot.level
                )) ||
            null
        );
    }
);

export const getTrail = createSelector(
    [getPreviewPaneTrail, getDataGridTrail, getSourceSetElementTrail],
    (previewTrail, gridTrail, elementTrail) =>
        previewTrail || gridTrail || elementTrail
);

export const getPreviewLocation = createSelector(
    [getPreviewLocationId, getLocations],
    (locationId, locations) => (locationId && locations[locationId]) || null
);

export const getDataGridObjectName = createSelector(
    [getPreviewPaneMonitoredId, getReportSets, getLastStates],
    (previewPaneMonitoredId, reportSets, monitoredObjects) => {
        const reportSet =
            previewPaneMonitoredId && reportSets[previewPaneMonitoredId];
        if (!reportSet) {
            return '';
        }
        const monitoredId = reportSet.monitoredId;
        const monitoredObject = monitoredObjects[monitoredId];
        return monitoredObject?._meta?.header?.name;
    }
);

export const getSourceSetElementStartDate = createSelector(
    [getSourceSetElementEntities],
    (entities) => entities?.find((entity) => entity?.id === 'DTST')?.value
);
export const getSourceSetElementLocationActions = createSelector(
    [getSourceSetElementEntities],
    (entities) => entities?.find((entity) => entity?.id === 'Location')?.actions
);
export const getFramesMoviesIds = createSelector(
    [getFramesSourceSetElement],
    (element) => element?.reports[0].entities.map((entity) => entity.id)
);

export const getFrameElementDate = createSelector(
    [getFramePreviewSourceSetElement],
    (element) => element?._meta?.header?.date || null
);
export const getFrameElementMonitoredId = createSelector(
    [getFramePreviewSourceSetElement],
    (element) => element?._meta?.header?.monitoredId || null
);

export const getCalibrationChartType = createSelector(
    [getDiscoveryUICalibrationPane],
    (pane) => pane?.type || 'distance'
);

export const getCalibrationChartData = createSelector(
    [
        getCalibrationChartEvents,
        getCalibrationParamId,
        getCalibrationChartType,
        getCalibrationData,
        getMonitoredObjectCapacity,
    ],
    (allEvents, paramId, type, settings, capacity) =>
        processCalibrationChartData(
            allEvents,
            paramId,
            type,
            settings,
            capacity
        )
);
