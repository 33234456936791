import React, { useEffect, useState } from 'react';

import MultipleSelect, { Option, OptionValues } from 'react-select';

import Moment from 'moment-timezone';

import {
    fetchFuelTankEvents,
    fetchRefuelingsAndSupplies,
    fetchMonitoredFuelTanks,
} from '../../state/app/fuelTanks';
import {
    useDateFormat,
    useShortDateFormat,
} from '../../state/user/index.hooks';

import TimeFormatter from '../../helpers/TimeFormatter';
import TranslationHelper from '../../helpers/TranslationHelper';

import { useAppDispatch } from '../../hooks';

import {
    FUEL_TANK_LEVEL_STATES,
    FUEL_TANK_STATES,
} from '../../constants/dictionaries/FuelTankStates';

import ActualParameters from '../../components/dashboard/ActualParameters';
import EventsChart from '../../components/dashboard/EventsChart';
import SmallMap from '../../components/dashboard/SmallMap';
import {
    FuelTankOrdinaryEventsTable,
    XMonitorArchiveTable,
} from '../../components/dashboard/tables';
import View from '../../components/View/View';
import {
    useFuelTankEvents,
    useFuelTankOrdinaryEvents,
    useFuelTanks,
} from '../../state/app/fuelTanks/index.hooks';

const FROM_DATE = TimeFormatter.toISOString(
    Moment().subtract(28, 'days').startOf('day')
);
const TO_DATE = TimeFormatter.toISOString(Moment().endOf('day'));

const DashboardPage = () => {
    const [selectedObject, setSelectedObject] = useState<any>(null);
    const [eventsLoading, setEventsLoading] = useState<boolean>(false);

    const dateFormat = useDateFormat();
    const shortDateFormat = useShortDateFormat();
    const fuelTanks = useFuelTanks();
    const fuelTankEvents = useFuelTankEvents();
    const fuelTankOrdinaryEvents = useFuelTankOrdinaryEvents();

    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(fetchMonitoredFuelTanks());
    }, []);

    useEffect(() => {
        if (!selectedObject && fuelTanks) {
            setSelectedObject(fuelTanks[0]);
        }
    }, [fuelTanks]);

    useEffect(() => {
        refreshEvents();
    }, [selectedObject]);

    const handleMonitoredObjectChange = (
        object: Option<OptionValues> | null
    ) => {
        setSelectedObject(object);
    };

    const refreshEvents = () => {
        if (selectedObject && selectedObject.id) {
            const objectId = selectedObject.id;
            setEventsLoading(true);
            if (selectedObject.traits && selectedObject.traits.binMonitor) {
                dispatch(
                    fetchFuelTankEvents(
                        objectId,
                        {
                            from: FROM_DATE || '',
                            to: TO_DATE || '',
                            states: '',
                        },
                        () => setEventsLoading(false)
                    )
                );
                dispatch(
                    fetchRefuelingsAndSupplies(objectId, {
                        from: FROM_DATE || '',
                        to: TO_DATE || '',
                        states: '',
                    })
                );
            } else {
                dispatch(
                    fetchFuelTankEvents(
                        objectId,
                        {
                            from: FROM_DATE || '',
                            to: TO_DATE || '',
                            states: FUEL_TANK_LEVEL_STATES.join(','),
                        },
                        () => setEventsLoading(false)
                    )
                );
                dispatch(
                    fetchRefuelingsAndSupplies(objectId, {
                        from: FROM_DATE || '',
                        to: TO_DATE || '',
                        states: FUEL_TANK_STATES.join(','),
                    })
                );
            }
        }
    };

    const renderDashboardDates = () => {
        const from = TimeFormatter.dateToString(FROM_DATE, shortDateFormat);
        const to = TimeFormatter.dateToString(TO_DATE, shortDateFormat);

        return (
            <div className="date-range">
                {from}&nbsp;-&nbsp;{to}
                <button onClick={refreshEvents}>
                    <span className="icon icon-history" />
                </button>
            </div>
        );
    };

    const renderRefuelingsTable = () => {
        return selectedObject?.traits?.binMonitor ? null : (
            <div className="refuelings">
                <div className="box-header">
                    {TranslationHelper.translate('Refuelings and supplies')}
                </div>

                {selectedObject ? (
                    <FuelTankOrdinaryEventsTable
                        data={fuelTankOrdinaryEvents}
                    />
                ) : (
                    noData
                )}
            </div>
        );
    };
    const renderXMonitorArchiveTable = () => {
        return !selectedObject?.traits?.binMonitor ? null : (
            <div className="refuelings">
                <div className="box-header">
                    {TranslationHelper.translate('Archive')}
                </div>

                {selectedObject ? (
                    <XMonitorArchiveTable data={fuelTankEvents} />
                ) : (
                    noData
                )}
            </div>
        );
    };

    const renderLastStateParameters = () => {
        return (
            <div className="actual-params">
                <div className="box-header">
                    <div>
                        {TranslationHelper.translate('Actual parameters')}
                    </div>
                    <div>{updatedAt}</div>
                </div>
                {selectedObject ? (
                    <ActualParameters details={selectedObject} />
                ) : (
                    noData
                )}
            </div>
        );
    };
    const renderSmallMap = () => {
        return (
            <div className="small-map">
                <div className="box-header">
                    {TranslationHelper.translate('Dashboard_Location')}
                </div>
                {selectedObject ? (
                    <SmallMap details={selectedObject} />
                ) : (
                    mapNoData
                )}
            </div>
        );
    };

    const updatedAt = selectedObject
        ? TimeFormatter.dateToString(selectedObject.updatedAt, dateFormat)
        : '';

    const noData = (
        <div className="no-events">
            {TranslationHelper.translate('No data to display')}
        </div>
    );
    const mapNoData = (
        <div
            style={{
                flexGrow: 1,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontStyle: 'italic',
            }}
        >
            {TranslationHelper.translate('No data to display')}
        </div>
    );

    return (
        <View title={TranslationHelper.translate('Dashboard')}>
            <div id="main-container">
                <div className="header">
                    <div className="title">
                        <MultipleSelect
                            name="subjectId"
                            value={selectedObject}
                            options={fuelTanks}
                            noResultsText={TranslationHelper.translate(
                                'No results...'
                            )}
                            labelKey="name"
                            clearable={false}
                            onChange={handleMonitoredObjectChange}
                        />
                    </div>
                    {renderDashboardDates()}
                </div>

                <div className="content">
                    <div className="left">
                        {renderLastStateParameters()}
                        {renderSmallMap()}
                    </div>

                    <div className="right">
                        <div className="events-chart">
                            <div className="box-header">
                                {TranslationHelper.translate('Recent activity')}
                            </div>
                            <EventsChart
                                allData={fuelTankEvents}
                                filteredData={fuelTankOrdinaryEvents}
                                data={selectedObject}
                                loading={eventsLoading}
                            />
                        </div>
                        <div className="activity">
                            {renderRefuelingsTable()}
                            {renderXMonitorArchiveTable()}
                        </div>
                    </div>
                </div>
            </div>
        </View>
    );
};

export default DashboardPage;
