import React from 'react';
import { useStyles } from './Themable.hooks';

import { CircularProgress } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { Autocomplete } from '@material-ui/lab';
import { FormikErrors } from 'formik';
import TranslationHelper from '../../helpers/TranslationHelper';

interface IProps {
    label: string;
    getOptions: () => Promise<any>;
    onChange: (e: React.ChangeEvent<{}>, value: any) => void;
    error?: string | FormikErrors<any>;
    value?: any;
    disableClearable?: boolean;
    disabled?: boolean;
    required?: boolean;
    className?: string;
    optionKey?: string;
}

const LOADER_SIZE = 20;

const AsyncSelectField = ({
    label,
    getOptions,
    error,
    onChange,
    value,
    disableClearable,
    disabled,
    required,
    className,
    optionKey,
}: IProps) => {
    const classes = useStyles();

    const [open, setOpen] = React.useState(false);
    const [options, setOptions] = React.useState<any[]>([]);
    const [loading, setLoading] = React.useState<boolean>(false);
    React.useEffect(() => {
        let active = true;

        if (!loading) {
            return undefined;
        }

        (async () => {
            const response = await getOptions();

            if (active) {
                await setOptions(response);
                setLoading(false);
            }
        })();

        return () => {
            active = false;
        };
    }, [loading]);

    React.useEffect(() => {
        setLoading(true);
        if (!open) {
            setLoading(false);
            setOptions([]);
        }
    }, [open]);
    return (
        <Autocomplete
            fullWidth
            getOptionSelected={(option, value) =>
                option[optionKey || 'id'] === value[optionKey || 'id']
            }
            getOptionLabel={(option) => option.name}
            noOptionsText={TranslationHelper.translate('No options to display')}
            loadingText={TranslationHelper.translate('Loading...')}
            onChange={onChange}
            options={options}
            loading={loading}
            disabled={disabled}
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            className={className || classes.field}
            value={value || undefined}
            disableClearable={disableClearable}
            renderInput={(params) => {
                return (
                    <TextField
                        {...params}
                        label={label}
                        required={required}
                        error={!!error}
                        helperText={error}
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <React.Fragment>
                                    {loading ? (
                                        <CircularProgress
                                            color="inherit"
                                            size={LOADER_SIZE}
                                        />
                                    ) : null}
                                    {params.InputProps.endAdornment}
                                </React.Fragment>
                            ),
                        }}
                    />
                );
            }}
        />
    );
};

export default AsyncSelectField;
