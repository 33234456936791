import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import moment, { Moment } from 'moment-timezone';

import { TRootState } from '../../store';

const weekInMiliseconds = 3600 * 24 * 7 * 1000;

const getTimestampSevenDaysAgo = () => Date.now() - weekInMiliseconds;

const relativeFormat = (
    date: Moment,
    showSeconds: boolean,
    showYear: boolean,
    relative: boolean,
    dateFormatWithoutYearAndSeconds: string,
    dateFormat: string,
    dateFormatWithoutSeconds: string
) => {
    if (!showYear) {
        return date.format(dateFormatWithoutYearAndSeconds);
    }

    if (!relative || date.valueOf() < getTimestampSevenDaysAgo()) {
        return date.format(showSeconds ? dateFormat : dateFormatWithoutSeconds);
    }

    return date.fromNow();
};

interface IOwnProps {
    date?: Moment;
    zonedDate: Moment;
    showSeconds: boolean;
    showYear: boolean;
    timeZone?: string;
    relative: boolean;
}

type TConnectableProps = ReturnType<typeof mapStateToProps>;

type TProps = IOwnProps & TConnectableProps;

class RelativeTime extends PureComponent<TProps> {
    public render() {
        const {
            zonedDate,
            showSeconds,
            showYear,
            relative,
            userSettings,
            language,
        } = this.props;

        if (!zonedDate) {
            const placeholder = '-';
            return <div title={placeholder}>{placeholder}</div>;
        }

        const actualDate = zonedDate && moment(zonedDate);

        if (!moment.isDate(actualDate)) {
            actualDate.locale(language);
        }

        const renderedDate = relativeFormat(
            actualDate,
            showSeconds,
            showYear,
            relative,
            userSettings.dateFormatWithoutYearAndSeconds,
            userSettings.dateFormat,
            userSettings.dateFormatWithoutSeconds
        );

        const titleDate = actualDate.format(userSettings.dateFormat);

        return <div title={titleDate}>{renderedDate}</div>;
    }
}

const mapStateToProps = (state: TRootState) => ({
    userSettings: state.user,
    language: state.login.language,
});

export default connect(mapStateToProps)(RelativeTime);
