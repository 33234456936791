import React, { ChangeEvent, useState } from 'react';

import { InputBase } from '@material-ui/core';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import ClearIcon from '@mui/icons-material/Clear';

import Translator from '../../../helpers/TranslationHelper';
import { useStyles } from './Themable.hooks';
import { IconButton } from '@mui/material';

interface IOwnProps {
    filterChange: (input: string) => void;
    resetSearch: () => void;
    value: string;
}

const rfidPattern = /^[a-fA-F0-9]+$/;

const RfidSearchField = (props: IOwnProps) => {
    const [rfidFilter, setRfidFilter] = useState(props.value);
    const [hasError, setHasError] = useState(false);
    const classes = useStyles();

    const handleChange = ({
        target: { value: filter },
    }: ChangeEvent<HTMLInputElement>) => {
        setRfidFilter(filter.toUpperCase());
        if (filter.length === 0 || filter.match(rfidPattern)) {
            setHasError(false);
            props.filterChange(filter);
        } else {
            setHasError(true);
            props.filterChange('');
        }
    };

    const handleReset = () => {
        setRfidFilter('');
        setHasError(false);
        props.resetSearch();
    };

    return (
        <>
            <div className={classes.search}>
                {hasError ? (
                    <div
                        className={`${classes.searchIcon} ${classes.errorIcon}`}
                    >
                        <FilterAltOffIcon />
                    </div>
                ) : (
                    <div className={classes.searchIcon}>
                        <FilterAltIcon />
                    </div>
                )}
                <InputBase
                    placeholder={Translator.translate('grid.filterRfid')}
                    value={rfidFilter}
                    onChange={handleChange}
                    classes={{
                        root: classes.inputRoot,
                        input: classes.inputInput,
                    }}
                    inputProps={{ 'aria-label': 'rfid-search' }}
                />

                <IconButton
                    style={{ color: 'inherit', padding: '6px' }}
                    aria-label="Delete"
                    onClick={handleReset}
                >
                    <ClearIcon />
                </IconButton>
            </div>
        </>
    );
};

export default RfidSearchField;
