import React, { useState, useEffect } from 'react';

import {
    useCreatorLevel,
    useEditMode,
    useOnlyNotAssigned,
    useIncludeNotPlanned,
    useOnlyNotPlanned,
    useSourceSetDates,
} from '../../Connectable.hooks';

import {
    fetchTasks,
    resetLevel,
    setNotPlannedFilter,
} from '../../../../state/_actions';

import { useAppDispatch } from '../../../../hooks';
import { NoDateSwitchComponent } from './NoDateSwitchComponent';

interface IOwnProps {
    sourceSetId: string;
}

const NoDateSwitch = ({ sourceSetId }: IOwnProps) => {
    const [onlyNotPlanned, setOnlyNotPlanned] = useState<boolean>(false);

    const dispatch = useAppDispatch();

    const editMode = useEditMode();

    const includeNotPlannedFromStore = useIncludeNotPlanned();

    const onlyNotPlannedFromStore = useOnlyNotPlanned();

    const onlyNotAssignedFromStore = useOnlyNotAssigned();

    const sourceSetDates = useSourceSetDates();

    const creatorLevel = useCreatorLevel();

    useEffect(() => {
        setOnlyNotPlanned(onlyNotPlannedFromStore);
    }, [onlyNotPlannedFromStore]);

    const handleOnlyNotPlanned = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setOnlyNotPlanned(event.target.checked);
        dispatch(setNotPlannedFilter(sourceSetId, event.target.checked));

        const fetchTasksParams = () => {
            return event.target.checked
                ? {
                      onlyNotAssigned: onlyNotAssignedFromStore,
                      includeNotPlanned: event.target.checked,
                  }
                : {
                      from: sourceSetDates?.from,
                      to: sourceSetDates?.to,
                      onlyNotAssigned: onlyNotAssignedFromStore,
                      includeNotPlanned: includeNotPlannedFromStore,
                  };
        };
        dispatch(fetchTasks(fetchTasksParams()));
        if (creatorLevel > 0) {
            dispatch(resetLevel(1));
        }
    };

    return (
        <NoDateSwitchComponent
            onlyNotPlanned={onlyNotPlanned}
            handleOnChange={handleOnlyNotPlanned}
            disabled={editMode}
        />
    );
};

export default NoDateSwitch;
