import UrlConst from '../../constants/UrlConst';
import apiClient from '../common/apiClient';
import {
    makeEndpoint,
    makeFormContentPostRequestSettings,
    makeGetRequestSettings,
    makePlainContentPostRequestSettings,
    makePostRequestSettings,
} from '../common/utils';

import {
    ICustomer,
    ILoginData,
    ILoginSuccessData,
    IXssLogin,
} from '../../state/login/types';
import { IAdContent } from 'src/app/state/login';

export const restLoginUser = (
    loginObj: ILoginData
): Promise<ILoginSuccessData> =>
    apiClient.sendWithoutErrorsHandling(
        makeFormContentPostRequestSettings(
            makeEndpoint(UrlConst.LOGIN_URL, ''),
            loginObj
        )
    );

export const restLogoutUser = (): Promise<null> =>
    apiClient.sendWithoutErrorsHandling(
        makePlainContentPostRequestSettings(
            makeEndpoint(UrlConst.AUTHENTICATION_URL, '/logout'),
            {}
        )
    );

export const restLoadCustomers = (): Promise<ICustomer[]> =>
    apiClient.sendWithoutErrorsHandling(
        makeGetRequestSettings(makeEndpoint(UrlConst.CUSTOMERS_URL, ''))
    );

export const restGetAds = (targetId: string): Promise<IAdContent[]> =>
    apiClient.sendWithoutErrorsHandling(
        makeGetRequestSettings(makeEndpoint(UrlConst.ADS_URL, '', { targetId }))
    );

export const restLoginToXssTickets = (email: string): Promise<IXssLogin> =>
    apiClient.sendWithoutErrorsHandling(
        makePostRequestSettings(makeEndpoint(UrlConst.XSS_URL, '/tickets'), {
            email,
        })
    );

export const restConfirmEmail = (token: string, email: string): Promise<null> =>
    apiClient.sendWithoutErrorsHandling(
        makeGetRequestSettings(
            makeEndpoint(UrlConst.USER_URL, '/email/confirmation', {
                token,
                email,
            })
        )
    );

export const restGetLanguages = (): Promise<string[]> =>
    apiClient.sendWithoutErrorsHandling(
        makeGetRequestSettings(
            makeEndpoint(UrlConst.REGISTERS_TRANSLATIONS_URL, '/languages', {
                contexts: 'wx',
            })
        )
    );
