import { TSearchType } from '../../../../state/app/searchers';

import { TRootState } from '../../../../store';

export const getFetching = (state: TRootState) => state.app.fetching;

const isFetchingInProgress = (envelope: { isFetching: boolean }) =>
    envelope.isFetching;

const getSearchResultFetching = (state: TRootState) =>
    getFetching(state).searchResultsFetching;

const getLastStateFetching = (state: TRootState) =>
    getFetching(state).lastStateFetching;

const getReportSetFetching = (state: TRootState) =>
    getFetching(state).reportSetFetching;

const getRecognizedNumbersFetching = (state: TRootState) =>
    getFetching(state).recognizedNumbersFetching;

const getSourceSetFetching = (state: TRootState) =>
    getFetching(state).sourceSetFetching;

const getLocationsRegisterFetching = (state: TRootState) =>
    getFetching(state).locationsRegister;

const getSourceSetElementFetching = (state: TRootState) =>
    getFetching(state).sourceSetElementFetching;

const getSearcherFetching = (state: TRootState, type: TSearchType) => {
    switch (type) {
        case 'vehicles':
            return getFetching(state).vehiclesSearcher;
        case 'employees':
            return getFetching(state).employeesSearcher;
    }
};

const getTrailFetching = (state: TRootState) =>
    getFetching(state).trailFetching;

export const areSearcherResultsBeingFetched = (
    state: TRootState,
    type: TSearchType
) => isFetchingInProgress(getSearcherFetching(state, type));

export const areSearchResultsBeingFetched = (state: TRootState) =>
    isFetchingInProgress(getSearchResultFetching(state));

export const isLastStateBeingFetched = (state: TRootState) =>
    isFetchingInProgress(getLastStateFetching(state));

export const isReportSetBeingFetched = (state: TRootState) =>
    isFetchingInProgress(getReportSetFetching(state));

export const isRecognizedNumbersBeingFetchted = (state: TRootState) =>
    isFetchingInProgress(getRecognizedNumbersFetching(state));

export const isSourceSetBeingFetched = (state: TRootState) =>
    isFetchingInProgress(getSourceSetFetching(state));

export const isLocationsRegisterBeingFetched = (state: TRootState) =>
    isFetchingInProgress(getLocationsRegisterFetching(state));
export const isSourceSetElementFetching = (state: TRootState) =>
    isFetchingInProgress(getSourceSetElementFetching(state));
export const isTrailBeingFetched = (state: TRootState) =>
    isFetchingInProgress(getTrailFetching(state));

export const getTaskFetching = (state: TRootState) =>
    getFetching(state).taskFetching;
