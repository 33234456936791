import React, { ReactElement } from 'react';
import { useStyles } from './Themable.hoc';

import { Collapse, IconButton } from '@material-ui/core';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

interface IOwnProps {
    title: string;
    content: ReactElement;
}

type TProps = IOwnProps;

const CollapseItem = ({ title, content }: TProps) => {
    const [expanded, setExpanded] = React.useState(false);
    const classes = useStyles();

    return (
        <>
            <div
                className={classes.header}
                onClick={() => setExpanded(!expanded)}
            >
                {title}
                <IconButton>
                    {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
            </div>
            <Collapse in={expanded} timeout="auto" unmountOnExit={true}>
                {React.cloneElement(content, {
                    collapseClose: () => setExpanded(false),
                })}
            </Collapse>
        </>
    );
};

export default CollapseItem;
