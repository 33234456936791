import React, {
    Dispatch,
    MouseEventHandler,
    SetStateAction,
    useEffect,
} from 'react';

import { IRightItem } from '../../../../../../../../state/ui/usersSlice';

import TranslationHelper from '../../../../../../../../helpers/TranslationHelper';
import { useDebounceEffect } from '../../../../../../../../helpers/hooks';

import { DEBOUNCE_LOW } from '../../../../../../../../constants/DebounceValues';

import { lowerCaseFilter } from '../../utils/filterObjects';

import CustomList from '../../../../../../../../components/TransferList/components/CustomList';
import {
    IFilterInitialState,
    IListObjectInitialState,
} from '../../../../../../../../components/TransferList/TransferList';

const TYPES = ['ALL', 'GROUP', 'MONITORED_OBJECT', 'SPECIAL'];

interface IOwnProps {
    objects: IListObjectInitialState<IRightItem>;
    setObjects: React.Dispatch<Partial<IListObjectInitialState<IRightItem>>>;
    checked: IRightItem[];
    setChecked: Dispatch<SetStateAction<IRightItem[]>>;
    filter: IFilterInitialState;
    setFilter: React.Dispatch<Partial<IFilterInitialState>>;
    handleToggle: (value: IRightItem) => MouseEventHandler | undefined;
    disabled?: boolean;
}

const RightList = ({
    objects,
    setObjects,
    checked,
    setChecked,
    filter,
    setFilter,
    handleToggle,
    disabled,
}: IOwnProps) => {
    const { nameAssigned, typeAssigned } = filter;
    const { assignedObjects, filteredAssignedObjects } = objects;

    useDebounceEffect(
        () => {
            handleChangeNameAssigned();
        },
        [nameAssigned, assignedObjects],
        DEBOUNCE_LOW
    );

    useEffect(() => {
        handleChangeTypeAssigned(typeAssigned);
    }, [typeAssigned]);

    const handleChangeNameAssigned = () => {
        const filteredObjects =
            typeAssigned === 'ALL'
                ? assignedObjects.filter((object) =>
                      lowerCaseFilter(object, nameAssigned)
                  )
                : assignedObjects.filter(
                      (object) =>
                          lowerCaseFilter(object, nameAssigned) &&
                          object.type === typeAssigned
                  );
        setObjects({ filteredAssignedObjects: filteredObjects });
    };

    const handleChangeTypeAssigned = (type: string) => {
        const filteredObjects =
            typeAssigned === 'ALL'
                ? assignedObjects
                : assignedObjects.filter(
                      (object) =>
                          object.type === type &&
                          lowerCaseFilter(object, nameAssigned)
                  );
        setObjects({ filteredAssignedObjects: filteredObjects });
    };

    return (
        <CustomList
            loaded={true}
            typeInput={{
                type: typeAssigned,
                setType: (type) => setFilter({ typeAssigned: type }),
            }}
            nameInput={{
                name: nameAssigned,
                setName: (name) => setFilter({ nameAssigned: name }),
            }}
            title={TranslationHelper.translate('objects.assigned')}
            items={filteredAssignedObjects}
            handleToggle={handleToggle}
            checked={checked}
            setChecked={setChecked}
            types={TYPES}
            disabled={disabled}
        />
    );
};

export default RightList;
