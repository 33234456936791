import React, { MutableRefObject, ReactChild, useState } from 'react';

import Player from 'video.js/dist/types/player';

import {
    Box,
    Dialog,
    DialogContent,
    IconButton,
    Typography,
} from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';

import TranslationHelper from '../../../helpers/TranslationHelper';
import VideoJSPlayer from '../../VideoJSPlayer';

interface IOwnProps {
    videoSrc: string;
    videoRef: MutableRefObject<HTMLVideoElement | null>;
    playerRef: MutableRefObject<Player | null>;
    open: boolean;
    handleClose: () => void;
    children: ReactChild;
    type: string;
}

const VideoPlayerDialog = ({
    videoSrc,
    videoRef,
    playerRef,
    open,
    handleClose,
    children,
    type,
}: IOwnProps) => {
    const [error, setError] = useState(false);
    const ERROR_MAX_RETRIES = 60;
    const ERROR_DEFAULT_TIMEOUT_RETRY = 5000;
    const ERROR_FIRST_TIMEOUT_RETRY = 2000;

    let errorRetries = 0;

    const handleError = () => {
        setError(true);
    };

    const resetData = () => {
        setError(false);
    };

    const handleRetryOnSrcError = () => {
        const player = playerRef.current;
        if (ERROR_MAX_RETRIES >= errorRetries) {
            errorRetries++;
            const delay =
                errorRetries === 1
                    ? ERROR_FIRST_TIMEOUT_RETRY
                    : ERROR_DEFAULT_TIMEOUT_RETRY;
            //must be null here to hide error dialog
            //@ts-ignore
            player.error(null);

            setTimeout(() => {
                if (player && !player.isDisposed()) {
                    player.src(videoJsOptions.sources);
                    player.pause();
                    player.load();
                }
            }, delay);
        } else {
            handleError();
        }
    };

    const videoJsOptions = {
        autoplay: 'muted',
        playbackRates: [0.5, 1, 1.25, 1.5, 2],
        controlBar: {
            liveDisplay: false,
            seekToLive: false,
            skipButtons: {
                backward: 10,
                forward: 10,
            },
        },
        plugins: {
            hotkeys: {
                seekStep: 10,
                enableModifiersForNumbers: false,
            },
        },
        controls: true,
        sources: [
            {
                src: videoSrc,
                type,
            },
        ],
    };

    return (
        <Dialog open={open} onClose={handleClose} maxWidth={'lg'}>
            {children}
            <DialogContent
                sx={{
                    '.MuiDialogContent-root&.MuiDialogContent-root': { p: 0.2 },
                }}
            >
                {!error ? (
                    <VideoJSPlayer
                        options={videoJsOptions}
                        videoRef={videoRef}
                        playerRef={playerRef}
                        handleError={handleError}
                        handleSrcError={handleRetryOnSrcError}
                    />
                ) : (
                    <Box
                        sx={{
                            width: '100%',
                            height: 300,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <Typography>
                            {TranslationHelper.translate(
                                'The video cannot be played'
                            )}
                        </Typography>
                        <IconButton onClick={resetData}>
                            <RefreshIcon />
                        </IconButton>
                    </Box>
                )}
            </DialogContent>
        </Dialog>
    );
};

export default VideoPlayerDialog;
