import React, { Component, ComponentType } from 'react';
import { compose } from 'redux';

import { Field, FieldProps, FormikErrors, FormikValues } from 'formik';
import moment from 'moment-timezone';

import { withSnackbar, WithSnackbarProps } from 'notistack';
import { Connectable, TConnectableProps } from './Connectable.hoc';
import { Themable, TThemableProps } from './Themable.hoc';

import DateFieldWrapper from '../../../../../../components/common/formikWrappers/DateFieldWrapper';
import FieldWrapper from '../../../../../../components/common/formikWrappers/FieldWrapper';
import CrudPane from '../../../../../../components/CrudPane';
import DateRangeDisplay from '../../../../../../components/DateRangeDisplay';

import { IDefinitionItem } from '../../../../../../state/app/calibrations';
import { IPreviewAction } from '../../../../../../state/ui/discovery/types';
import {
    ICalibrationFormReferentialValue,
    ICalibrationSubmitForm,
} from '../../../../../../state/ui/forms';

import {
    Button,
    FormHelperText,
    LinearProgress,
    MenuItem,
    TextField,
} from '@material-ui/core';

import RefreshIcon from '@material-ui/icons/Autorenew';

import { responseMessage } from '../../../../../../helpers/responseMessage';
import TimeFormatter from '../../../../../../helpers/TimeFormatter';
import TranslationHelper from '../../../../../../helpers/TranslationHelper';

import { calibrationSchema } from '../../../../../../schemas';

import EditableGridWrapper from '../../../../../../components/common/formikWrappers/EditableGridWrapper';

const mapDictionaryItems = (items: IDefinitionItem[], hasEmpty?: boolean) => {
    const initial = hasEmpty
        ? [
              <MenuItem key={'-1'} value={-1}>
                  {''}
              </MenuItem>,
          ]
        : [];
    return initial.concat(
        items.map((item: IDefinitionItem) => (
            <MenuItem key={item.name} value={item.name}>
                {TranslationHelper.translate(item.name)}
            </MenuItem>
        ))
    );
};

const initialReferentialValues = [
    {
        paramValue: 6000,
        fuelLevel: 0,
    },
    {
        paramValue: 0,
        fuelLevel: 0,
    },
];

type TProps = TThemableProps & TConnectableProps & WithSnackbarProps;
interface IState {
    calibrationLoaded: boolean;
    eventsFetching: boolean;
    blankForm: ICalibrationSubmitForm;
}

class CalibrationSetting extends Component<TProps, IState> {
    public state: IState = {
        calibrationLoaded: false,
        eventsFetching: false,
        blankForm: {
            from: TimeFormatter.toUTCLocalString(moment()),
            params: {
                paramName: '',
                referentialFrom: '',
                referentialTo: '',
                referentialValues: initialReferentialValues,
            },
            '@type': 'registers.calibration',
        },
    };

    public componentDidMount() {
        const {
            pane,
            customAction,
            fetchParamsDefinitions,
            setCalibrationData,
        } = this.props;
        const action = pane && pane.previewAction;

        fetchParamsDefinitions(
            (pane && pane.elementId) || '',
            (paramDefData) => {
                if (
                    pane &&
                    (pane.mode === 'edit' || pane.mode === 'preview') &&
                    action
                ) {
                    let getAction: IPreviewAction = action;
                    if (pane.mode === 'edit') {
                        getAction = {
                            ...action,
                            label: 'GET',
                            method: 'GET',
                        };
                    }

                    customAction(
                        getAction,
                        undefined,
                        (data: ICalibrationSubmitForm) => {
                            this.setState({
                                calibrationLoaded: true,
                                blankForm: data,
                            });
                            this.fetchArchiveEvents(
                                data.params.referentialFrom,
                                data.params.referentialTo,
                                paramDefData.find(
                                    (def) => def.name === data.params.paramName
                                )?.id
                            );
                            setCalibrationData({
                                selectedParam: data.params.paramName,
                                calibrationData: data.params.referentialValues,
                                events: null,
                            });
                        }
                    );
                }

                if (pane && pane.mode === 'add') {
                    setCalibrationData({
                        selectedParam: '',
                        calibrationData: initialReferentialValues,
                        events: null,
                    });
                }
            },
            (error) => {
                const message = responseMessage(error.status);
                this.props.enqueueSnackbar(
                    `${TranslationHelper.translate(
                        'Parameters definitions download failed'
                    )}: ${TranslationHelper.translate(message.text)}`,
                    { variant: message.type }
                );
            }
        );
    }

    public fetchArchiveEvents = (
        from?: string,
        to?: string,
        paramId?: string
    ) => {
        const { pane, fetchEvents } = this.props;
        const objectId = pane?.elementId;

        if (!objectId || !from || !to || !paramId) {
            return;
        }

        this.setState({ eventsFetching: true });

        fetchEvents(
            {
                objectId: Number(objectId),
                from: TimeFormatter.toISOString(from),
                to: TimeFormatter.toISOString(to),
                params: paramId,
            },
            () => {
                this.setState({ eventsFetching: false });
            },
            (error) => {
                const message = responseMessage(error.status);
                this.props.enqueueSnackbar(
                    `${TranslationHelper.translate(
                        'Archive events download failed'
                    )}: ${TranslationHelper.translate(message.text)}`,
                    { variant: message.type }
                );
                this.setState({ eventsFetching: false });
            }
        );
    };

    public updateEventsDates = (
        from: string,
        to: string,
        { form }: FieldProps
    ) => {
        const { selectedParamId, userSettings } = this.props;

        form.setFieldValue(
            'params.referentialFrom',
            TimeFormatter.toTimeZonedDate(from, userSettings.timeZone).format()
        );
        form.setFieldValue(
            'params.referentialTo',
            TimeFormatter.toTimeZonedDate(to, userSettings.timeZone).format()
        );

        this.fetchArchiveEvents(from, to, selectedParamId);
    };

    public refreshData = (
        calibrationData: ICalibrationFormReferentialValue[]
    ) => {
        const { setCalibrationData } = this.props;
        setCalibrationData({ calibrationData });
    };

    public validateGridData = (
        gridValues: ICalibrationFormReferentialValue[]
    ) => {
        let error;

        const paramValuesArray = gridValues.map((row) => row.paramValue);
        if (new Set(paramValuesArray).size !== paramValuesArray.length) {
            error = TranslationHelper.translate('Duplicate values entered');
        }

        for (const object of gridValues) {
            for (const value of Object.values(object)) {
                if (Number.isNaN(value)) {
                    error = TranslationHelper.translate(
                        'Invalid value entered'
                    );
                    break;
                }
            }

            if (error) {
                break;
            }
        }

        return error;
    };

    public handleParamName = (selectedParam: string, values: FormikValues) => {
        const { setCalibrationData, definitions } = this.props;
        this.fetchArchiveEvents(
            values.params.referentialFrom,
            values.params.referentialTo,
            definitions.find((def) => def.name === selectedParam)?.id
        );
        setCalibrationData({ selectedParam });
    };

    public renderModification = (
        values: FormikValues,
        errors: FormikErrors<ICalibrationSubmitForm>,
        setFieldValue: (name: string, value: any) => void
    ) => {
        const { classes, definitions, pane, userSettings, objectHeader } =
            this.props;
        const inEditMode = pane && pane.mode === 'edit';
        const inPreviewMode = pane && pane.mode === 'preview';
        const dates = {
            from: values.params.referentialFrom,
            to: values.params.referentialTo,
        };

        return (
            <div>
                <Field name={'params.referentialFrom'}>
                    {(fieldProps: FieldProps) => (
                        <>
                            <DateRangeDisplay
                                onDateChange={(from, to) =>
                                    this.updateEventsDates(from, to, fieldProps)
                                }
                                dates={dates}
                                error={
                                    !!(errors && errors.params?.referentialFrom)
                                }
                            />
                            <FormHelperText
                                error={
                                    !!(errors && errors.params?.referentialFrom)
                                }
                            >
                                {errors && errors.params?.referentialFrom}
                            </FormHelperText>
                        </>
                    )}
                </Field>

                <div className={classes.paneContent}>
                    <Field
                        className={classes.field}
                        error={!!(errors && errors.params?.paramName)}
                        helperText={errors && errors.params?.paramName}
                        disabled={inPreviewMode || inEditMode}
                        name={'params.paramName'}
                        InputProps={{
                            onChange: (
                                e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                                setFieldValue(e.target.name, e.target.value);
                                this.handleParamName(e.target.value, values);
                            },
                        }}
                        label={TranslationHelper.translate(
                            'Parameter to calibrate'
                        )}
                        select={true}
                        fullWidth={true}
                        component={FieldWrapper}
                        required={true}
                    >
                        {mapDictionaryItems(definitions)}
                    </Field>
                    {!definitions && <LinearProgress />}
                    <Field
                        className={classes.field}
                        error={!!(errors && errors.from)}
                        helperText={
                            (errors && errors.from) ||
                            TranslationHelper.translate(
                                'Only data from the last 90 days will be recalculated'
                            )
                        }
                        name={'from'}
                        label={TranslationHelper.translate('Effective date')}
                        fullWidth={true}
                        required={true}
                        disabled={inPreviewMode || inEditMode}
                        component={DateFieldWrapper}
                        displayFormat={userSettings.shortDateFormat}
                        withDateFormatter={TimeFormatter.toISOString}
                    />
                    <TextField
                        label={TranslationHelper.translate('Tank capacity')}
                        margin="dense"
                        fullWidth={true}
                        disabled={true}
                        value={
                            objectHeader?.capacity ||
                            TranslationHelper.translate(
                                'Capacity is undefined!'
                            )
                        }
                    />
                    {values.params.paramName && (
                        <Field
                            error={
                                !!(errors && errors.params?.referentialValues)
                            }
                            helperText={
                                (errors && errors.params?.referentialValues) ||
                                TranslationHelper.translate(
                                    'At least 2 rows required'
                                )
                            }
                            name={'params.referentialValues'}
                            validate={this.validateGridData}
                            gridProps={{
                                rowData: values.params.referentialValues,
                            }}
                            disabled={inPreviewMode}
                            deleteRows={true}
                            addRows={{
                                paramValue: 0,
                                fuelLevel: 0,
                            }}
                            rowDataTypes={{
                                paramValue: 'number',
                                fuelLevel: 'number',
                            }}
                            additionalActionComponent={
                                <Button
                                    variant="contained"
                                    color="primary"
                                    startIcon={<RefreshIcon />}
                                    onClick={() =>
                                        this.refreshData(
                                            values.params.referentialValues
                                        )
                                    }
                                >
                                    {TranslationHelper.translate('Refresh')}
                                </Button>
                            }
                            isAdditionalActionComponentDisabled={
                                inPreviewMode ||
                                values.params.referentialValues.length < 2 ||
                                !!errors.params?.referentialValues
                            }
                            component={EditableGridWrapper}
                        />
                    )}
                </div>
            </div>
        );
    };

    public render() {
        const { pane } = this.props;
        const inPreviewMode = pane && pane.mode === 'preview';
        const { calibrationLoaded, eventsFetching } = this.state;
        const initialValues = this.state.blankForm;

        return (
            <CrudPane
                titles={{
                    add: 'Add calibration',
                    edit: 'Edit calibration',
                    preview: 'Calibration details',
                }}
                loaded={
                    pane !== null &&
                    (pane.mode === 'add' ||
                        pane.mode === 'edit' ||
                        pane.mode === 'preview')
                }
                initialValues={initialValues}
                validationSchema={calibrationSchema()}
                onClose={this.handleCloseClick}
                changeModeHandler={this.handleCloseClick}
                mode={(pane && pane.mode) || undefined}
                onAdd={(values, onSuccess) => {
                    this.handleSubmit(values);
                }}
                onEdit={
                    !inPreviewMode
                        ? (values, onSuccess) => {
                              this.handleSubmit(values);
                          }
                        : undefined
                }
                renderContent={(
                    mode,
                    errors,
                    values,
                    setFieldValue,
                    isSubmitting
                ) => {
                    if (
                        isSubmitting ||
                        (mode !== 'add' && !calibrationLoaded)
                    ) {
                        return <LinearProgress />;
                    }
                    return (
                        <>
                            {eventsFetching && <LinearProgress />}
                            {this.renderModification(
                                values,
                                errors,
                                setFieldValue
                            )}
                        </>
                    );
                }}
            />
        );
    }

    private handleCloseClick = () => {
        this.props.resetLevel(this.props.creatorLevel);
    };

    private handleSubmit = (data: FormikValues) => {
        const { pane, customAction, enqueueSnackbar } = this.props;
        const action = pane && pane.previewAction;

        if (!action) {
            return;
        }

        const dataWithDates = {
            ...data,
            from: TimeFormatter.toLocalStartOfDay(data.from),
        };

        customAction(
            action,
            dataWithDates,
            () => {
                this.handleCloseClick();
                enqueueSnackbar(
                    TranslationHelper.translate(
                        'Calibration saved successfully'
                    ),
                    { variant: 'success' }
                );
            },
            (error) => {
                const message = responseMessage(error.status);
                enqueueSnackbar(
                    `${TranslationHelper.translate(
                        'Calibration save failed'
                    )}: ${TranslationHelper.translate(message.text)}`,
                    { variant: message.type }
                );
            }
        );
    };
}

export default compose(
    Themable,
    Connectable,
    withSnackbar
)(CalibrationSetting) as ComponentType<{}>;
