type TRouteStatusEntry = {
    id: number;
    name: string;
};

type TRouteStatusMap = {
    [key: number]: TRouteStatusEntry;
};

export const ROUTE_STATUS: TRouteStatusMap = {
    0: { id: 0, name: 'Not assigned' },
    2: { id: 2, name: 'Assigned' },
    5: { id: 5, name: 'Downloaded' },
    6: { id: 6, name: 'Cancelled' },
    7: { id: 7, name: 'Update required' },
    9: { id: 9, name: 'Terminated' },
    10: { id: 10, name: 'Download started' },
    11: { id: 11, name: 'Finished' },
};
