import { makeReduxDuck } from 'teedux';
import {
    IRestPostRole,
    IRestPutRole,
    restDeleteRole,
    restGetPrivileges,
    restGetRole,
    restGetRoles,
    restPostRole,
    restPutRole,
} from '../../../services/roles';
import { deselectRole, resetMode } from '../../ui/roles';
import { makeRequest } from '../sync';

export interface IRole {
    id: number;
    name: string;
    description: string;
    readonly: boolean;
    privileges?: number[];
}

export interface IPrivilege {
    id: number;
    name: string;
    group: string;
    useIn: number;
}

type TOptionalPrivileges = null | IPrivilege[];
type TOptionalRoles = null | IRole[];
type TOptionalRole = null | IRole;
type TOptionalRoleToDelete = null | number;

interface IState {
    roles: TOptionalRoles;
    privileges: TOptionalPrivileges;
    role: TOptionalRole;
    roleToDelete: TOptionalRoleToDelete;
}

const initialState: IState = {
    roles: null,
    privileges: null,
    role: null,
    roleToDelete: null,
};

const duck = makeReduxDuck('app/adminPanel/rolesPanel', initialState);

export const setPrivileges = duck.defineAction<{
    collection: TOptionalPrivileges;
}>('SET_PRIVILEGES', (state, { collection }) => ({
    ...state,
    privileges: collection,
}));

export const setRoles = duck.defineAction<{
    collection: TOptionalRoles;
}>('SET_ROLES', (state, { collection }) => ({
    ...state,
    roles: collection,
}));

export const setRole = duck.defineAction<{
    role: TOptionalRole;
}>('SET_ROLE', (state, { role }) => ({
    ...state,
    role,
}));

export const setRoleToDelete = duck.defineAction<{
    roleToDelete: TOptionalRoleToDelete;
}>('SET_ROLE_TO_DELETE', (state, { roleToDelete }) => ({
    ...state,
    roleToDelete,
}));

export default duck.getReducer();

const rolesStorePath = `app/adminPanel/roles`;
export const getRolesStorePath = `get:${rolesStorePath}`;
const pivilegesStorePath = `app/adminPanel/privileges`;
const getPrivilegesStorePath = `get:${pivilegesStorePath}`;
export const createRoleStorePath = `post:${rolesStorePath}`;

export const getPrivileges = () =>
    makeRequest(
        getPrivilegesStorePath,
        () => restGetPrivileges(),
        (dispatch, data) => dispatch(setPrivileges({ collection: data })),
        (dispatch, error) => undefined
    );

export const getRoles = () =>
    makeRequest(
        getRolesStorePath,
        () => restGetRoles(),
        (dispatch, data) => dispatch(setRoles({ collection: data })),
        (dispatch, error) => undefined
    );

export const getRole = (
    id: number,
    afterRequest?: (data: IRole) => void,
    mapData?: (data: IRole) => void
) =>
    makeRequest(
        `${getRolesStorePath}/${id}`,
        () => restGetRole(id),
        (dispatch, data) => {
            dispatch(setRole({ role: (mapData && mapData(data)) || data }));
            if (afterRequest) {
                afterRequest(data);
            }
        },
        (dispatch, error) => undefined
    );

export const createRole = (role: IRestPostRole, afterRequest: () => void) =>
    makeRequest(
        createRoleStorePath,
        () => restPostRole(role),
        (dispatch, data) => {
            dispatch(resetMode());
            // @ts-ignore
            dispatch(getRoles());
            if (afterRequest) {
                afterRequest();
            }
        },
        (dispatch, error) => {
            if (afterRequest) {
                afterRequest();
            }
        }
    );

export const getUpdateRoleStorePath = (id: number) =>
    `put:${rolesStorePath}/${id}`;

export const updateRole = (role: IRestPutRole, afterRequest: () => void) =>
    makeRequest(
        getUpdateRoleStorePath(role.id),
        () => restPutRole(role),
        (dispatch, data) => {
            // @ts-ignore
            dispatch(getRoles());
            if (afterRequest) {
                afterRequest();
            }
        },
        (dispatch, error) => {
            if (afterRequest) {
                afterRequest();
            }
        }
    );

export const getDeleteRoleStorePath = (id: number) =>
    `delete:${rolesStorePath}/${id}`;

export const deleteRole = (id: number) =>
    makeRequest(
        getDeleteRoleStorePath(id),
        () => restDeleteRole(id),
        (dispatch, data) => {
            dispatch(deselectRole());
            // @ts-ignore
            dispatch(getRoles());
        },
        (dispatch, error) => undefined
    );
