import React, { ChangeEvent, ReactNode } from 'react';

import { MenuItem, TextField } from '@material-ui/core';

import { useStyles } from './Themable.hooks';
import TranslationHelper from '../../helpers/TranslationHelper';

interface IOwnProps<T> {
    name: string;
    value: T;
    overrideClass?: any;
    ids?: T[];
    handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
    label?: string;
    translateContext?: string;
    renderOptions?: () => ReactNode;
}
const SelectField = <T extends string>({
    name,
    value,
    overrideClass: overrideClass,
    handleChange,
    ids,
    label,
    translateContext = 'wx',
    renderOptions,
}: IOwnProps<T>) => {
    const classes = overrideClass ?? useStyles();

    const defaultRenderOptions = () => {
        return ids?.map((id) => {
            return (
                <MenuItem key={id} value={id}>
                    {TranslationHelper.translate(id, translateContext)}
                </MenuItem>
            );
        });
    };
    return (
        <TextField
            className={classes.select}
            name={name}
            select
            label={TranslationHelper.translate(label || '')}
            variant="outlined"
            value={value}
            onChange={handleChange}
            classes={{ root: classes.root }}
            SelectProps={{
                classes: { root: classes.root, icon: classes.icon },
            }}
            size="small"
        >
            {renderOptions ? renderOptions() : defaultRenderOptions()}
        </TextField>
    );
};

export default SelectField;
