import { ISourceSetEntity } from '../../../../../state/types';

//typeguard for location with gps in sourceSetEntity
export const hasValidGps = (
    entity: ISourceSetEntity
): entity is typeof entity & {
    location: { gps: { latitude: number; longitude: number }; routeId: number };
} => {
    return (
        typeof entity?.location === 'object' &&
        entity.location !== null &&
        typeof entity.location.gps === 'object' &&
        entity.location.gps !== null &&
        typeof entity.location.gps.latitude === 'number' &&
        typeof entity.location.gps.longitude === 'number'
    );
};
