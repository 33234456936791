import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { TRootState } from '../../store';
import AppConfig from '../../constants/AppConfig';
import UrlConst from '../../constants/UrlConst';
import { logoutUser } from '../../state/login';

export const logout =
    (): ThunkAction<void, TRootState, null, Action<any>> => (dispatch) => {
        const redirectToLoginPage = () =>
            (document.location.href = AppConfig.instance.getUrl(
                UrlConst.LOGIN_PAGE_URL
            ));
        dispatch(logoutUser(redirectToLoginPage, redirectToLoginPage));
    };
