import React, { useEffect } from 'react';

import { default as LinearProgress } from '@material-ui/core/LinearProgress/LinearProgress';
import { default as Paper } from '@material-ui/core/Paper';
import { default as MultiLineChartIcon } from '@material-ui/icons/MultilineChart';
import { default as GridIcon } from '@material-ui/icons/GridOn';
import { IconButton } from '@material-ui/core';

import {
    activateGrid,
    activatePreview,
    closePreview,
    fetchSourceSetElement,
} from '../../../../../../state/_actions';
import { makeSourceSetId } from '../../../../../../services/discovery/_shared/utils';
import { makeSourceSetUrl } from '../../../../../../services/discovery/_shared/urlMakers';
import {
    IPreviewAction,
    ISourceSetAction,
} from '../../../../../../state/ui/discovery/types';

import TranslationHelper from '../../../../../../helpers/TranslationHelper';
import { useAppDispatch } from '../../../../../../hooks';

import { useStyles } from './Themable.hooks';

import {
    useCreatorLevel,
    useGridFilters,
    useGridSnapshot,
    usePane,
    usePreviewAction,
} from '../../../../selectors/index.hooks';
import { useIsSourceSetElementFetching } from '../../../../selectors/feching/index.hooks';
import { usePreviewSourceSetElement } from '../../../../selectors/composed/index.hooks';

import GoogleMapsButton from '../../../../../../components/common/GoogleMapsButton';
import PaneHeader from '../../../../../../components/PaneHeader';
import SourceSetElement from './components/SourceSetElement';
import SourceSetElementMenu from './components/SourceSetElementMenu';

const SourceSetElementPreview = () => {
    const dispatch = useAppDispatch();

    const classes = useStyles();

    const previewAction = usePreviewAction();
    const sourceSetElementReportSet = usePreviewSourceSetElement();
    const creatorLevel = useCreatorLevel();
    const grid = useGridSnapshot();
    const pane = usePane();
    const fetching = useIsSourceSetElementFetching();
    const filters = useGridFilters();

    useEffect(() => {
        if (previewAction) {
            const actionWithGridFilter = {
                ...previewAction,
                params: { ...previewAction.params, ...filters },
            };
            dispatch(fetchSourceSetElement(actionWithGridFilter));
        }
    }, [filters]);

    const handleCloseClick = () => {
        dispatch(closePreview(creatorLevel));
    };

    const handleMenuClick = (id: string) => {
        if (sourceSetElementReportSet) {
            dispatch(
                activatePreview(id, sourceSetElementReportSet.id, id, 'edit', {
                    type: 'preview',
                    level: creatorLevel + 1,
                })
            );
        }
    };
    const changeGrid = (
        action: IPreviewAction,
        sourceSetAction: ISourceSetAction
    ) => {
        if (pane?.elementId && grid) {
            dispatch(
                activateGrid(
                    makeSourceSetId(sourceSetAction.params) ||
                        makeSourceSetUrl(sourceSetAction).toString(),
                    {
                        type: grid.isChart
                            ? 'source-set-grid'
                            : 'source-set-grid-chart',
                        level: creatorLevel - 1,
                    },
                    grid.preview ? grid.preview : null,
                    false,
                    sourceSetAction,
                    false,
                    false,
                    false,
                    true,
                    0,
                    false
                )
            );
            dispatch(
                activatePreview(
                    'archiveEvents',
                    pane?.elementId,
                    'sourceSetElement',
                    'preview',
                    {
                        type: 'source-set-element',
                        level: creatorLevel,
                    },
                    action,
                    false,
                    undefined,
                    false
                )
            );
        }
    };
    const renderChangeGridButton = () => {
        if (
            previewAction &&
            !previewAction._webx?.changeSourceSetDisabled &&
            grid?.sourceSetAction
        ) {
            const sourceSetAction = grid.sourceSetAction;
            const handleChangeGrid = () =>
                changeGrid(previewAction, sourceSetAction);
            return (
                <IconButton
                    className={classes.iconButton}
                    onClick={handleChangeGrid}
                >
                    {grid?.isChart ? <GridIcon /> : <MultiLineChartIcon />}
                </IconButton>
            );
        }
        return <></>;
    };

    const renderRightCustomControls = () => {
        return (
            <>
                {renderChangeGridButton()}
                {sourceSetElementReportSet?._meta && (
                    <SourceSetElementMenu
                        meta={sourceSetElementReportSet._meta}
                        handleClick={handleMenuClick}
                        paneId={sourceSetElementReportSet.id}
                    />
                )}
                <GoogleMapsButton
                    coordinates={
                        sourceSetElementReportSet?._meta?.header?.coordinates
                    }
                />
            </>
        );
    };

    if (fetching) {
        return <LinearProgress />;
    }

    return (
        <Paper className={classes.paper}>
            <PaneHeader
                title={TranslationHelper.translate('Details')}
                onCloseClick={handleCloseClick}
                renderRightCustomControls={renderRightCustomControls}
            />
            {sourceSetElementReportSet && (
                <SourceSetElement
                    sourceSetElementReportSet={sourceSetElementReportSet}
                    creatorLevel={creatorLevel}
                />
            )}
        </Paper>
    );
};

export default SourceSetElementPreview;
