import { VariantType } from 'notistack';

export const responseMessage = (
    code: number
): { text: string; type: VariantType } => {
    switch (code) {
        case 200:
            return { text: 'Changes have been saved', type: 'success' };

        case 202:
        case 204:
            return {
                text: 'Data may still be pending. Please try later',
                type: 'warning',
            };

        case 401:
            return { text: 'Verification token has expired', type: 'error' };

        case 403:
            return {
                text: 'No access to the resource due to insufficient permissions',
                type: 'error',
            };

        case 404:
            return { text: 'Resource not found', type: 'error' };

        case 422:
            return {
                text: 'Boundary conditions in the message are not satisfied',
                type: 'error',
            };

        case 500:
            return {
                text: 'Internal server error. Please contact with administrator.',
                type: 'error',
            };
        case 504:
            return {
                text: 'The server did not respond in time. Please try again later',
                type: 'error',
            };
        default:
            return { text: 'Unrecognized error has occurred', type: 'error' };
    }
};
