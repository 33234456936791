import UrlConst from '../../constants/UrlConst';
import {
    IAlertDefinitionItem,
    IAlertProvider,
    IRawLayer,
} from '../../state/app/alerts';
import apiClient from '../common/apiClient';
import { IPageResponse } from '../common/types';
import {
    makeEndpoint,
    makeGetRequestSettings,
    makePostRequestSettings,
} from '../common/utils';

export interface IAlertContentDetail {
    content: string;
    conditionType: number;
    children?: IAlertContentDetail[];
}

export interface IAlert {
    id: string;
    externalId: string;
    name: string;
    content: string;
    object: {
        id: number;
        name: string;
        group: {
            id: number;
            name: string;
        };
        timeZone: string;
        type: string;
    };
    occurAt: string;
    updatedAt: string;
    createdAt: string;
    status: string;
    alertSettingId: number;
    detailedContent: IAlertContentDetail;
}

// TODO: move some interfaces from state, services should not rely on state, rather the opposite
export interface IEventType {
    id: number | '';
    kind?: string;
    type?: number;
    name?: string;
}

export interface IAlertSetting {
    id: number;
    name: string;
}

export interface ILayerFeature {
    id?: string;
    name: string;
}

export interface IAlertCondition {
    type: number;
}

export interface IGroupAlertCondition extends IAlertCondition {
    conditions?: TAnyAlertCondition[];
}
export interface IParamAlertCondition extends IAlertCondition {
    definitionId: number;
    paramName: string;
    paramValue: object | string | number | boolean | any[];
}

export interface IEventAlertCondition extends IAlertCondition {
    eventType: IEventType;
}

export interface ILayerAlertCondition extends IAlertCondition {
    definitionId: number | '';
    layerId: string;
    features: ILayerFeature[];
}

export type TAnyAlertCondition =
    | IGroupAlertCondition
    | IParamAlertCondition
    | IEventAlertCondition
    | ILayerAlertCondition;

export interface IAlertRecipients {
    emails?: string[];
    phones?: string[];
    providers?: string[];
    language: string;
}

// TODO: use this instead of IAlertForm while invoking customAction
export interface IAlertSettingWithCondition {
    id: number;
    name: string;
    condition: TAnyAlertCondition;
    groupIds?: string[];
    objectId?: number;
    recipients: IAlertRecipients;
}

export interface IPostAlertSetting {
    condition: TAnyAlertCondition;
    objectId?: number;
    groupIds?: string[];
    recipients: IAlertRecipients;
    name: string;
}

export interface IPutAlertSetting extends IPostAlertSetting {
    id: number;
}
export interface IGetAlertsParams {
    statuses?: string; // (read); (unread); (read,unread)
    limit?: number;
    after?: string; // datetime
    objectId?: string;
    start?: number;
    orderBy?: 'createdAt' | 'occurAt';
    sortOrder?: 'asc' | 'desc';
}

export type TAlertResponse = IPageResponse<IAlert>;

export const restGetAlertDefinitions = (
    parameterType: string
): Promise<IAlertDefinitionItem[]> =>
    apiClient.sendWithoutErrorsHandling(
        makeGetRequestSettings(
            makeEndpoint(UrlConst.ALERT_DEFINITIONS_URL, '', {
                parameterType,
            })
        )
    );

export const restGetAlerts = (
    queryParams: IGetAlertsParams
): Promise<TAlertResponse> =>
    apiClient.sendWithoutErrorsHandling(
        makeGetRequestSettings(
            makeEndpoint(UrlConst.ALERTS_URL, '', queryParams)
        )
    );

export const restGetAlertsCount = (status: string): Promise<number> =>
    apiClient.sendWithoutErrorsHandling(
        makeGetRequestSettings(
            makeEndpoint(UrlConst.ALERTS_URL, '/count', { status })
        )
    );

export const restGetEventTypes = (forAlerts: boolean): Promise<IEventType[]> =>
    apiClient.sendWithoutErrorsHandling(
        makeGetRequestSettings(
            makeEndpoint(UrlConst.EVENT_TYPES, '', { forAlerts })
        )
    );

export const restPostAlertStatus = (id: string): Promise<string> =>
    apiClient.sendWithoutErrorsHandling(
        makePostRequestSettings(
            makeEndpoint(UrlConst.ALERTS_URL, `/${id}/status`),
            { status: 'read' }
        )
    );

export const restGetLayers = (): Promise<IRawLayer[]> =>
    apiClient.sendWithoutErrorsHandling(
        makeGetRequestSettings(makeEndpoint(UrlConst.LAYERS, ''))
    );

export const restGetProviders = (): Promise<IAlertProvider[]> =>
    apiClient.sendWithoutErrorsHandling(
        makeGetRequestSettings(makeEndpoint(UrlConst.ALERTS_URL, '/providers'))
    );
