import React from 'react';

import { ColumnApi, GridApi } from 'ag-grid-community';

import moment from 'moment';

import { ISourceSetEntity } from '../../../../../../../../state/types';

import { exportAsGpx } from '../../../../_utils';

import {
    useGridObjectName,
    useGridSourceSet,
} from '../../../../../../selectors/composed/index.hooks';

import AppConfig from '../../../../../../../../constants/AppConfig';

import ExportButtons from '../../../../../../../../components/ExportButtons';
import { IExportOptions } from '../../../../../../../../components/SourceSetGrid/types';
import { printGrid } from '../../../../../../../../components/ExportButtons/_utils/printGrid';

interface IOwnProps {
    gridApi?: GridApi;
    gridColumnApi?: ColumnApi;
    isGridFullscreen: boolean;
    handleFullscreen: () => void;
}

//TODO - this could be refactored to reausable component
const GridHeaderExportButtons = ({
    gridColumnApi,
    gridApi,
    isGridFullscreen,
    handleFullscreen,
}: IOwnProps) => {
    const sourceSet = useGridSourceSet();

    const objectName = useGridObjectName();

    const gridEnterpriseKey = AppConfig?.instance.getConfigKey(
        AppConfig.PROPERTY_GRID
    )?.key;
    const exportGPX = (fileName: string) => {
        if (!gridApi) {
            return;
        }

        const data: ISourceSetEntity[] = [];
        gridApi.forEachNodeAfterFilterAndSort((node: any) => {
            data.push(node.data);
        });

        const action = sourceSet?._meta?.actions
            ? sourceSet._meta.actions.exportGpx
            : null;

        if (sourceSet && action) {
            exportAsGpx(sourceSet, data, action, fileName);
        }
    };

    const getExportOptions = () => {
        if (!gridApi || !gridColumnApi) {
            return [];
        }

        const objectNameInfix =
            objectName && sourceSet?.id !== 'lastStates'
                ? `_${objectName}`
                : ``;
        const fileName = `export${objectNameInfix}${
            sourceSet ? `_${sourceSet.label}` : ''
        }_${moment().format('YYYYMMDD_HHmmss')}`;

        const exportGpxAction = sourceSet?._meta?.actions?.exportGpx;

        const exportOptions: IExportOptions[] = [
            {
                name: 'CSV',
                handler: () =>
                    gridApi.exportDataAsCsv({ fileName: fileName + '.csv' }),
            },
        ];

        if (gridEnterpriseKey) {
            exportOptions.push({
                name: 'XLSX',
                handler: () =>
                    gridApi.exportDataAsExcel({ fileName: fileName + '.xlsx' }),
            });
        }
        if (exportGpxAction) {
            exportOptions.push({
                name: 'GPX',
                handler: () => exportGPX(fileName),
            });
        }
        exportOptions.push({
            name: 'Print',
            handler: () =>
                printGrid(
                    gridColumnApi,
                    gridApi,
                    !!isGridFullscreen,
                    handleFullscreen
                ),
        });

        return exportOptions;
    };

    if (gridColumnApi && gridApi) {
        return <ExportButtons buttons={getExportOptions()} />;
    }
    return null;
};

export default GridHeaderExportButtons;
