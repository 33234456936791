import { connect } from 'react-redux';
import { TRootState } from '../../../../store';

import { getCustomers } from '../../../../state/login';

const mapStateToProps = (state: TRootState) => ({
    customers: getCustomers(state),
});

const mapDispatchToProps = {};

type TStateProps = ReturnType<typeof mapStateToProps>;
type TDispatchProps = typeof mapDispatchToProps;

export type TConnectableProps = TStateProps & TDispatchProps;

export const Connectable = connect(mapStateToProps, mapDispatchToProps);
