import { IMovie } from '../../state/types';
import { makeLocalFetch, TAbnomalObject } from './_shared/fetchMakers';
import { normalizeSingleEntity } from './_shared/normalizers';

const convertToReportSet = (args: any[]): TAbnomalObject => {
    const data = args[0];
    const params = data.params;
    const monitoredId = params.monitoredId;
    const eventId = params.eventId;
    const ownerId = eventId ?? monitoredId;
    const context = eventId ? 'ARCHIVE' : 'LAST_STATE';
    const movies = params.movies as IMovie[];
    const coordinates = params.coordinates;

    return {
        id: params.id,
        type: 'frame',
        reports: [
            {
                id: data.id,
                label: 'Frame details',
                entities:
                    movies &&
                    movies.map((movie) => {
                        const labels = movie.labels
                            ? `&labels=${movie.labels}`
                            : '';
                        return {
                            ...movie,
                            value: `${movie.paramName}_${movie.fileName}_offset_${movie.offset}s.jpg`,
                            label: movie.paramName,
                            id: movie.paramName,
                            type: data.componentName,
                            actions: {
                                preview: {
                                    api: movie.frameValid
                                        ? `/rest/api/monitored/movies/${movie.fileId}/frames/${movie.offset}?monitoredId=${ownerId}&context=${context}&paramName=${movie.paramName}&maxWidth=400&maxHeight=400`
                                        : '',
                                    method: 'GET',
                                },
                                download: {
                                    api: movie.frameValid
                                        ? `/rest/api/monitored/movies/${movie.fileId}/frames/${movie.offset}?monitoredId=${ownerId}&context=${context}&paramName=${movie.paramName}${labels}`
                                        : '',
                                    method: 'GET',
                                },
                            },
                        };
                    }),
                _meta: { expanded: true, actions: {} },
            },
        ],
        _meta: {
            header: {
                name: `Frame`,
                offset: params.offset,
                date: params.updatedAt,
                icon: params.icon,
                coordinates,
                monitoredId,
            },
        },
    };
};

export const fetchFrameDetails = makeLocalFetch(
    convertToReportSet,
    normalizeSingleEntity
);
