import { IAttribute, ISourceSetEntityParams } from '../../state/types';
import { makeLocalFetch, TAbnomalObject } from './_shared/fetchMakers';
import { normalizeAndTranslateSingleEntity } from './_shared/normalizers';

const convertToReportSet = (args: any[]): TAbnomalObject => {
    const data = args[0];
    const params = data.params;
    const monitoredId = params.monitoredId;
    const entitiesToConvert =
        params.entitiesToConvert as ISourceSetEntityParams;
    const attributes = params.attributes as IAttribute[];
    const coordinates = params.coordinates;

    return {
        id: params.id,
        type: 'aggregation',
        reports: [
            {
                id: data.id,
                label: 'aggregationDetails',
                entities:
                    entitiesToConvert &&
                    attributes &&
                    attributes
                        .filter(
                            (attribute) =>
                                entitiesToConvert[attribute.id] !== undefined
                        )
                        .map((attribute) => ({
                            ...attribute,
                            value: entitiesToConvert[attribute.id],
                        })),
                _meta: { expanded: true, actions: params.actions },
            },
        ],
        _meta: {
            header: {
                name: params.name,
                date: params.startDate,
                icon: params.icon,
                monitoredId,
                coordinates,
            },
        },
    };
};

export const fetchAggregationDetails = makeLocalFetch(
    convertToReportSet,
    normalizeAndTranslateSingleEntity
);
