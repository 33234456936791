import React, { useEffect } from 'react';
import SearchField from '../../../../../../components/common/SearchField/SearchField';

import { useAppDispatch } from '../../../../../../hooks';
import { ITasksUrlParams } from '../../../../../../services/discovery/_shared/urlMakers';
import { ISourceSet } from '../../../../../../state/types';
import { fetchTasks } from '../../../../../../state/_actions';
import {
    useGridSnapshot,
    useIsGridChart,
    useSourceSetDates,
    useSourceSetTime,
} from '../../../../selectors/index.hooks';

interface IOwnProps {
    sourceSet: ISourceSet | null;
    filtering: {
        searchPhrase: string;
        onChange: (searchPhrase: string) => void;
    };
}
const TasksPreviewLeftHeader = ({ sourceSet, filtering }: IOwnProps) => {
    const dispatch = useAppDispatch();

    const sourceSetDates = useSourceSetDates();
    const sourceSetTime = useSourceSetTime();
    const isChart = useIsGridChart();
    const gridSnapshot = useGridSnapshot();
    useEffect(() => {
        if (sourceSetDates && sourceSetTime) {
            const { from, to } = sourceSetDates;
            const { realizationTimeFrom, realizationTimeTo } = sourceSetTime;
            dispatch(
                fetchTasks({
                    from,
                    to,
                    realizationTimeFrom,
                    realizationTimeTo,
                    monitoredId:
                        gridSnapshot.sourceSetAction?.params.monitoredId,
                    readOnly: true,
                } as ITasksUrlParams) as unknown as Promise<any>
            );
        }
    }, [gridSnapshot]);

    const renderSearchField = () => {
        return (
            !isChart &&
            sourceSet && (
                <SearchField
                    value={filtering.searchPhrase}
                    filterChange={filtering.onChange}
                />
            )
        );
    };

    return (
        <>
            <>{renderSearchField()}</>
        </>
    );
};

export default TasksPreviewLeftHeader;
