import { makeStyles } from '@material-ui/core';
import orange from '@material-ui/core/colors/orange';
import { SATURATION } from '../../constants/dictionaries/ColorConst';

export const useStyles = makeStyles(() => {
    return {
        overlayIcon: {
            position: 'relative',
            display: 'inline-flex',
            padding: 12,
        },
        topIcon: {
            position: 'absolute',
            bottom: 5,
            left: 30,
            color: orange[SATURATION],
        },
    };
});
