import { Record } from 'immutable';
import TimeZone from './TimeZone';

class FuelTank extends Record({
    id: undefined,
    externalId: undefined,
    monitoredId: undefined,
    name: undefined,
    capacity: undefined,
    reserve: undefined,
    fuelType: undefined,
    logicType: undefined,
    description: undefined,
    timeZone: new TimeZone(),
    groups: [],
    groupIds: [],
    sentCards: [],
    assignedToPersonalGroup: false,
}) {
    static fromJson(options) {
        return new FuelTank({
            id: options.id,
            externalId: options.externalId,
            monitoredId: options.monitoredId,
            name: options.name,
            capacity: options.capacity,
            reserve: options.reserve,
            logicType: options.logicType,
            fuelType: options.fuelType,
            description: options.description,
            sentCards: options.sentCards,
            groups: options.groups,
            groupIds: options.groupIds,
            timeZone: new TimeZone(options.timeZone),
            assignedToPersonalGroup: options.assignedToPersonalGroup,
        });
    }

    toJson() {
        return this.toObject();
    }
}

export default FuelTank;
