import URI from 'urijs';
import AppConfig from '../../constants/AppConfig';
import UrlConst from '../../constants/UrlConst';
import apiClient from '../common/apiClient';
import {
    makeGetRequestSettings,
    makePatchRequestSettings,
    makePostRequestSettings,
    makeUrl,
} from '../common/utils';

import { IUserRights, TSetUsersSettings } from '../../state/user/types';

export const makeUserUrl = (suffix: string, params = {}) => {
    const url = new URI(AppConfig.instance.getUrl(UrlConst.USER_URL) + suffix);
    return makeUrl(url, params);
};

export const makeUserRightsUrl = (params = {}) => {
    const url = new URI(AppConfig.instance.getUrl(UrlConst.USER_RIGHTS_URL));
    return makeUrl(url, params);
};

export const makePasswordChangeUrl = (id: number, params = {}) => {
    const url = new URI(
        AppConfig.instance.getUrl(UrlConst.USERS_ENDPOINT_URL) +
            `/${id}/password`
    );
    return makeUrl(url, params);
};

export const restGetUserSettings = (
    refresh: boolean = false
): Promise<TSetUsersSettings> =>
    apiClient.sendWithoutErrorsHandling(
        makeGetRequestSettings(
            makeUserUrl(`/settings`, { refresh, preventCache: Date.now() })
        )
    );

export const restGetUserRights = (): Promise<IUserRights> =>
    apiClient.sendWithoutErrorsHandling(
        makeGetRequestSettings(makeUserRightsUrl())
    );

export const restResendUserEmail = () =>
    apiClient.sendWithoutErrorsHandling(
        makePostRequestSettings(makeUserUrl(`/email/token`), {})
    );

export const restSaveUserEmail = (email: string) =>
    apiClient.sendWithoutErrorsHandling(
        makePatchRequestSettings(makeUserUrl(''), { email })
    );

export const restChangePassword = (
    oldPassword: string,
    newPassword: string,
    id: number
) =>
    apiClient.send(
        makePatchRequestSettings(makePasswordChangeUrl(id), {
            oldPassword,
            newPassword,
        })
    );
