import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        header: {
            padding: theme.spacing(3),
        },
        headerBottom: {
            display: 'flex',
            justifyContent: 'space-between',
        },
        headerIcon: {
            float: 'left',
            margin: '5px 5px 0 -10px',
        },
        headerName: {
            fontWeight: 'bold',
        },
        headerDate: {
            fontSize: '80%',
            color: 'gray',
        },
    })
);
