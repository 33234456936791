import React from 'react';
import { LoginPageBackGround } from '../LoginPage/LoginPageBackGround';
import { useStyles } from '../Themable.hooks';
import { LoginPageLogo } from '../LoginPage/LoginPageLogo';
import LoginFormFooter from '../../../components/Authentication/LoginFormFooter';
import ResetPassword from '../../PasswordResetPage/components/ResetPassword';
import { useAppDispatch } from '../../../hooks';
import { migrationChangePassword } from '../../../state/auth';

export const MigrationResetPassword = () => {
    const classes = useStyles();

    const dispatch = useAppDispatch();

    const query = new URLSearchParams(location.search);
    const token = query.get('token');
    const login = query.get('login') || '';

    const handleChangePassword = (
        newPassword: string,
        token: string,
        onSuccess: () => void,
        onFailure: (error: any) => void
    ) => {
        dispatch(
            migrationChangePassword(
                newPassword,
                token,
                login,
                onSuccess,
                onFailure
            )
        );
    };
    return (
        <LoginPageBackGround>
            <div className={classes.loginForm}>
                <LoginPageLogo />
                <ResetPassword
                    token={token || ''}
                    backDisabled={true}
                    onSubmit={(newPassword, token, onSuccess, onFailure) =>
                        handleChangePassword(
                            newPassword,
                            token,
                            onSuccess,
                            onFailure
                        )
                    }
                    successInfo={'auth.migrationCompleted'}
                    login={login}
                />
                <LoginFormFooter />
            </div>
        </LoginPageBackGround>
    );
};
