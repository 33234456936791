import { SYSTEM_OBJECTS } from '../../../../../constants/dictionaries/SystemObjects';
import { RowData } from '../../../../../components/EditableTable';
import {
    IConnectedObject,
    TAttributeValue,
} from '../../../../../state/ui/attributes';

interface MyTableRow extends RowData {
    id: string | number;
    name: string;
    defaultValue: TAttributeValue;
    multipleValue: boolean;
    required: boolean;
}
export const createInitialConnectedObjectsState = (
    emptyColumns: MyTableRow[],
    values: IConnectedObject[]
) => {
    const mappedFormValues = values.map((entry) => {
        const { type, defaultValue, multipleValue, required, internal } = entry;
        return {
            type,
            id: type,
            name: SYSTEM_OBJECTS[type as keyof typeof SYSTEM_OBJECTS],
            defaultValue,
            multipleValue,
            required,
            selected: true,
            internal,
        };
    });
    const propsDataTypes = new Set(
        mappedFormValues.map((item) => {
            return item.id;
        })
    );

    const missingItems = emptyColumns
        .filter((item) => !propsDataTypes.has(Number(item.id)))
        .map((item) => ({
            id: item.id,
            name: item.name,
            defaultValue: item.defaultValue,
            multipleValue: item.multipleValue,
            required: item.required,
            selected: false,
            internal: false,
            type: Number(item.id),
        }));
    const newData = [...mappedFormValues, ...missingItems];
    return newData;
};
