import { createStyles, Theme, WithStyles } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme: Theme) => {
    const padding = theme.spacing(2);
    return createStyles({
        wrapper: {
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 20,
            overflowY: 'auto',
        },
        paneContent: {
            padding,
        },
        field: {
            paddingBottom: theme.spacing(),
        },
    });
};

export type TThemableProps = WithStyles<typeof styles, true>;

export const Themable = withStyles(styles, { withTheme: true });
