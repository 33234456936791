import React from 'react';

import { Field, Form, FormikErrors } from 'formik';

import { FormControl } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormLabel from '@material-ui/core/FormLabel';

import { useRoles } from '../../Connectable.hooks';
import { useStyles } from '../../Themable.hooks';

import TranslationHelper from '../../../../../../helpers/TranslationHelper';
import { fieldSets, IFormUser } from '../../settings';
import CustomCheckbox from '../CustomCheckbox';
import Fields from '../Fields';

interface IOwnProps {
    disabled?: boolean;
    errors?: FormikErrors<IFormUser>;
    fieldSetType: string;
    noValidate?: boolean;
}
const UserForm = ({
    disabled = false,
    errors,
    noValidate = false,
    fieldSetType,
}: IOwnProps) => {
    const roles = useRoles();

    const classes = useStyles();

    return (
        <Form noValidate={noValidate}>
            <Fields
                fields={fieldSets[fieldSetType]}
                errors={errors}
                disabled={disabled}
            />

            <FormControl
                component="fieldset"
                className={classes.rolesWrapper}
                disabled={disabled}
            >
                <FormLabel component="legend">
                    {TranslationHelper.translate('Select roles:')}
                </FormLabel>
                <FormGroup>
                    {roles &&
                        roles.map((role) => (
                            <FormControlLabel
                                key={role.id}
                                control={
                                    <Field
                                        name={`roles.${role.id}`}
                                        component={CustomCheckbox}
                                    />
                                }
                                label={role.name}
                            />
                        ))}
                </FormGroup>
            </FormControl>
        </Form>
    );
};

export default UserForm;
