import { createStyles, Theme, WithStyles } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme: Theme) => {
    return createStyles({
        pane: {
            // position: 'absolute',
            // display: 'flex',
            // flexDirection: 'column',
            height: '100%',
            position: 'relative',
            zIndex: 200,
            overflowX: 'hidden',
            [theme.breakpoints.down('md')]: {
                borderRadius: 0,
            },
        },
        nested: {
            paddingLeft: theme.spacing(4),
        },
    });
};

export type TThemableProps = WithStyles<typeof styles>;

export const Themable = withStyles(styles, { withTheme: true });
