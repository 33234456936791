/* eslint-disable react/display-name */
import React from 'react';

import {
    EmployeeDialog,
    EmployeeRFIDDialog,
    FuelTankDialog,
    RFIDCardDialog,
    SendRFIDCardsDialog,
    VehicleDialog,
    VehicleRFIDDialog,
} from './components';

import About from './components/ApplicationAboutDialog/ApplicationAboutDialog';
import UserProfileDialog from './components/UserProfileDialog/UserProfileDialog';
import BookmarksListDialog from '../../pages/discovery/components/BookmarksList/BookmarkListDialog';

import {
    ABOUT,
    ATTRIBUTES,
    BOOKMARKS_LIST,
    EMPLOYEE,
    EMPLOYEE_RFID,
    FUEL_TANK,
    GRID_VIEW,
    LOGIN_MIGRATION,
    PLAYER_SETTINGS,
    REGISTER_VEHICLE,
    REGISTER_VEHICLE_RFID,
    RFID_CARD,
    ROUTES_MANAGEMENT_TASKS_ASSIGN_CONFIRMATION,
    SEND_RFID_CARDS,
    TASK_ID_ROUTES,
    USER_PROFILE,
} from '../../constants/dictionaries/GlobalDialogTypes';
import LoginMigrationDialog from './components/LoginMigrationDialog';
import GridViewDialog from './components/GridViewDialog';
import RouteAssingDialog from './components/RouteAssignDialog';
import AttributesDialog from './components/AttributesDialog';
import PlayerSettingsDialog from './components/PlayerSettingsDialog/PlayerSettingsDialog';
import { IPlayerSettings } from '../../state/ui/discoverySlice';
import TaskGridDialog, {
    ITaskGridDialogFilter,
} from './components/TaskGridDialog';

export default {
    [EMPLOYEE]: (data: any) => <EmployeeDialog employee={data.employee} />,
    [EMPLOYEE_RFID]: (data: any) => (
        <EmployeeRFIDDialog employee={data.employee} />
    ),
    [REGISTER_VEHICLE]: (data: any) => <VehicleDialog vehicle={data.vehicle} />,
    [REGISTER_VEHICLE_RFID]: (data: any) => (
        <VehicleRFIDDialog vehicle={data.vehicle} />
    ),
    [RFID_CARD]: (data: any) => <RFIDCardDialog rfidCard={data.rfidCard} />,
    [FUEL_TANK]: (data: any) => <FuelTankDialog tank={data.fuelTank} />,
    [SEND_RFID_CARDS]: (data: any) => (
        <SendRFIDCardsDialog fuelTank={data.fuelTank} />
    ),
    [ABOUT]: () => <About />,
    [USER_PROFILE]: () => <UserProfileDialog />,
    [BOOKMARKS_LIST]: () => <BookmarksListDialog />,
    [LOGIN_MIGRATION]: (data: { redirectAfterLogin?: () => void }) => (
        <LoginMigrationDialog redirectAfterLogin={data?.redirectAfterLogin} />
    ),
    [GRID_VIEW]: (data: { id?: string; sourceSet: string }) => {
        return <GridViewDialog viewId={data.id} sourceSet={data.sourceSet} />;
    },
    [ATTRIBUTES]: (data: { id?: string }) => {
        return <AttributesDialog attributeId={data.id} />;
    },
    [ROUTES_MANAGEMENT_TASKS_ASSIGN_CONFIRMATION]: (data: {
        tasks: {
            tasksList: { routeName: string; count: number }[];
            ids: number[];
        };
        target: { name: string; id: number };
        warning: boolean;
    }) => {
        return (
            <RouteAssingDialog
                tasks={data.tasks}
                target={data.target}
                warning={data.warning}
            />
        );
    },

    [PLAYER_SETTINGS]: (data: {
        handleChange: () => any;
        playerSettings: IPlayerSettings;
    }) => {
        return (
            <PlayerSettingsDialog
                playerSettings={data.playerSettings}
                handleChange={data.handleChange}
            />
        );
    },
    [TASK_ID_ROUTES]: (data: {
        filter: ITaskGridDialogFilter;
        routeId?: number;
        setFieldValue: (field: string, value: any) => void;
    }) => {
        return (
            <TaskGridDialog
                filter={data.filter}
                routeId={data.routeId}
                setFieldValue={data.setFieldValue}
            />
        );
    },
};
