import { Button } from '@material-ui/core';
import React from 'react';
import TranslationHelper from '../../../../helpers/TranslationHelper';
import { useStyles } from '../../Themable.hooks';
import { hasMoreOccurencesThan } from '../../../../helpers/hasMoreOccurencesThan';
import { FormikValues } from 'formik';

interface IOwnProps {
    values: FormikValues;
    push: (obj: any) => void;
    name: string;
}
export const AddButtons = ({ values, push, name }: IOwnProps) => {
    const classes = useStyles();

    const CONDITIONS_MAX_LENGTH = 4;
    const GROUP_NEST_MAX_LENGTH = 2;

    return (
        <>
            <Button
                type="button"
                variant="contained"
                color="primary"
                disabled={values.length >= CONDITIONS_MAX_LENGTH}
                className={classes.addButton}
                onClick={() => {
                    push({
                        type: '',
                        definitionId: '',
                        paramName: '',
                        paramValue: '',
                    });
                }}
            >
                {TranslationHelper.translate('Add condition')}
            </Button>
            <Button
                type="button"
                variant="contained"
                color="primary"
                disabled={
                    values.length >= CONDITIONS_MAX_LENGTH ||
                    hasMoreOccurencesThan(
                        name,
                        'conditions',
                        GROUP_NEST_MAX_LENGTH
                    )
                }
                className={classes.addButton}
                onClick={() => {
                    push({
                        type: 1,
                        conditions: [
                            {
                                type: '',
                                definitionId: '',
                                paramName: '',
                                paramValue: '',
                            },
                        ],
                    });
                }}
            >
                {TranslationHelper.translate('Add group')}
            </Button>
        </>
    );
};
