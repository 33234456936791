export const CAMERAS_RANGE = [-1, 1];
export const MAX_RANGE = { minus: -5, plus: 5 };
export const LABELS_MODEL_NOT_SELECTED = 'NONE';
export const CLASS_THRESRHOLD_NOT_SELECTED = 0;
export const OBJECT_THRESRHOLD_NOT_SELECTED = 0;

export const SLIDER_INPUT_MAX_LENGTH = 4;
export const SLIDER_STEP = 0.01;
export const SLIDER_MIN = 0;
export const SLIDER_MAX = 1;

export const SLIDER_MARKS = [
    {
        value: 0,
        label: '0',
    },
    {
        value: 0.25,
        label: '0.25',
    },
    {
        value: 0.5,
        label: '0.5',
    },
    {
        value: 0.75,
        label: '0.75',
    },
    {
        value: 1,
        label: '1',
    },
];
