import { createSelector } from 'reselect';
import { TRootState } from '../../../../store';
import {
    makeRefuelAndLossConfigOnDemandDataStorePath,
    makeRefuellAndLossConfigStorePath,
} from '../../../../state/app/refuellAndLossConfiguration';
import { IRequest } from '../../../../state/app/sync';

const getRefuellAndLossConfiguration = (state: TRootState) =>
    state.app.refuellAndLossConfiguration;

const getSyncRequests = (state: TRootState) => state.app.sync.requests;

export const getConfigParamId = createSelector(
    [getRefuellAndLossConfiguration],
    (config) => config.selectedParam
);

export const getEvents = createSelector(
    [getRefuellAndLossConfiguration],
    (config) => config.events
);

export const getOnDemandData = createSelector(
    [getRefuellAndLossConfiguration],
    (config) => config.onDemandData
);

const isLoading = (request: IRequest) => {
    return request && !request.success && !request.error;
};

export const isDetectionDataLoading = createSelector(
    [getSyncRequests],
    (requests) => {
        const dataRequest = requests[makeRefuellAndLossConfigStorePath()];
        const onDemandRequest =
            requests[makeRefuelAndLossConfigOnDemandDataStorePath()];

        return isLoading(dataRequest) || isLoading(onDemandRequest);
    }
);
