import createStyles from '@material-ui/core/styles/createStyles';

import { Theme, withStyles, WithStyles } from '@material-ui/core/styles';

const styles = (theme: Theme) => {
    return createStyles({
        overlay: {
            background: 'rgba(255, 255, 255, 0.5)',
            position: 'absolute',
            inset: 0,
            zIndex: 12,
        },
    });
};

export type TThemableProps = WithStyles<typeof styles>;

export const Themable = withStyles(styles, { withTheme: true });
