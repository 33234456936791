import React from 'react';

import { ClassNameMap } from '@material-ui/core/styles/withStyles';

import ChipsFieldWrapper from '../../common/formikWrappers/ChipsFieldWrapper';
import TranslationHelper from '../../../helpers/TranslationHelper';
import { validateEmail } from '../../../helpers/validationHelpers';

interface IOwnProps {
    formValues: any;
    errors: {};
    classes: ClassNameMap<'field'>;
    handleFormChange: (name: string, value: string) => void;
    handleErrors: (name: string, value: string) => void;
}
const EmailsChipsWrapper = ({
    formValues,
    errors,
    classes,
    handleFormChange,
    handleErrors,
}: IOwnProps) => {
    const field = {
        name: 'emails',
        value: formValues.emails,
        onChange: () => {
            return;
        },
        onBlur: () => {
            return;
        },
    };
    const form = {
        values: formValues,
        setFieldValue: handleFormChange,
        setFieldError: (name: string, value: string) => {
            handleErrors(name, value);
        },
    } as any;

    const chipFieldProps = {
        className: classes.field,
        error: errors?.['emails'] || errors?.['emailsField'],
        helperText: errors?.['emails'] || errors?.['emailsField'],
        name: 'Emails',
        label: TranslationHelper.translate('E-mail addresses'),
        type: 'email',
        value: formValues.emails,
        form,
        field,
    };

    return (
        <ChipsFieldWrapper
            inputController={'emailsField'}
            validateInput={validateEmail}
            {...chipFieldProps}
        />
    );
};
export default EmailsChipsWrapper;
