import { makeReduxDuck } from 'teedux';
import { IState } from './dataTypes';

export const initialState: IState = {
    users: null,
    roles: null,
    groups: null,
};

export const duck = makeReduxDuck('app/collections', initialState);
