import { makeReduxDuck } from 'teedux';

import {
    ILastState,
    ILocation,
    IMonitoredObject,
    IReportSet,
    IReport,
    ISourceSet,
    ITask,
    IBookmark,
    IAttribute,
    ITrail,
} from '../../types';
import { unionBy } from 'lodash';
import { makeRequest } from '../sync';
import { fetchTrail } from '../../../services/discovery/fetchTrail';
import TimeFormatter from '../../../helpers/TimeFormatter';

interface IState {
    monitoredObjects: {
        [objectId: string]: IMonitoredObject;
    };
    locations: {
        [locationId: string]: ILocation;
    };
    locationsRegister?: ISourceSet;
    lastStates: {
        [objectId: string]: ILastState;
    };
    routes: {
        [objectId: string]: ISourceSet;
    };
    reportSets: {
        [reportId: string]: IReportSet;
    };
    sourceSets: {
        [sourceSetId: string]: ISourceSet;
    };
    sourceSetElements: {
        [sourceSetElementId: string]: IReportSet;
    };
    tasks: {
        [objectId: string]: ITask;
    };
    trails: {
        [objectId: string]: ITrail;
    };
    frames: {
        [sourceSetElementId: string]: IReportSet;
    };
    aggregations: {
        [sourceSetElementId: string]: IReportSet;
    };
    reports: {
        [id: string]: IReport;
    };
}

const initialState: IState = {
    monitoredObjects: {},
    lastStates: {},
    routes: {},
    locations: {},
    locationsRegister: undefined,
    reportSets: {},
    sourceSets: {},
    sourceSetElements: {},
    trails: {},
    tasks: {},
    frames: {},
    aggregations: {},
    reports: {},
};

const duck = makeReduxDuck('app/discovery/entities', initialState);

const storeEntitiesAction = duck.defineAction<{
    entityType: keyof IState;
    entities: {
        [key: string]: {
            entities: any;
            attributes: IAttribute[];
        };
    };
    isDifferentialResponse?: boolean;
}>(
    'STORE_ENTITIES',
    (state, { entityType, entities, isDifferentialResponse }) => {
        if (isDifferentialResponse) {
            const elementEntities =
                state[entityType]?.[Object.keys(entities)[0]];
            if (elementEntities) {
                let updatedEntities = { ...elementEntities };

                updatedEntities.entities = unionBy(
                    Object.values(entities)[0].entities,

                    updatedEntities.entities,
                    'id'
                );
                return {
                    ...state,
                    [entityType]: {
                        ...state[entityType],
                        [Object.keys(entities)[0]]: updatedEntities,
                    },
                };
            }
        }

        return {
            ...state,
            [entityType]: {
                ...state[entityType],
                ...entities,
            },
        };
    }
);

const resetEntitiesAction = duck.defineAction<{
    entityType: keyof IState;
}>('RESET_ENTITIES', (state, { entityType }) => ({
    ...state,
    [entityType]: initialState[entityType],
}));

export default duck.getReducer();

const makeStoreEntities =
    (entityType: keyof IState) =>
    (entities: any, isDifferentialResponse?: boolean) =>
        storeEntitiesAction({
            entityType,
            entities,
            isDifferentialResponse,
        });

const makeResetEntities = (entityType: keyof IState) => () =>
    resetEntitiesAction({
        entityType,
    });

export const fetchTrails = (
    segments: IBookmark[],
    level: number,
    afterSuccess?: (data: any) => void,
    afterFail?: (error: Error) => void
) =>
    makeRequest(
        `get:app/entities/trails`,
        () =>
            Promise.all(
                segments.map(async (segment) => {
                    return await fetchTrail(
                        segment.monitoredId,
                        TimeFormatter.getISOString(segment.from),
                        TimeFormatter.getISOString(segment.to),
                        level
                    );
                })
            ),
        (dispatch, data) => {
            const trails = data.reduce((acc, obj) => {
                const entityId = obj.result[0];
                const entityData = obj.entities[entityId].data;
                const storeId = `${entityId}_${TimeFormatter.getISOString(
                    entityData.features[0]?.properties.from.dateTime
                )}_${TimeFormatter.getISOString(
                    entityData.features[0]?.properties.to.dateTime
                )}`;
                acc[storeId] = obj.entities[entityId];
                return acc;
            }, {});
            dispatch(storeTrails(trails));
            if (afterSuccess) {
                afterSuccess(trails);
            }
        },
        (dispatch, error) => {
            if (afterFail) {
                afterFail(error);
            }
        }
    );

export const storeMonitoredObjects = makeStoreEntities('monitoredObjects');
export const storeLastStates = makeStoreEntities('lastStates');
export const storeRoutes = makeStoreEntities('routes');
export const storeLocations = makeStoreEntities('locations');
export const storeLocationsRegister = makeStoreEntities('locationsRegister');
export const resetLocationsRegister = makeResetEntities('locationsRegister');
export const storeReportSets = makeStoreEntities('reportSets');
export const storeReports = makeStoreEntities('reports');
export const resetReports = makeResetEntities('reports');
export const storeSourceSets = makeStoreEntities('sourceSets');
export const storeSourceSetElements = makeStoreEntities('sourceSetElements');
export const storeFrames = makeStoreEntities('frames');
export const storeAggregations = makeStoreEntities('aggregations');
export const storeTrails = makeStoreEntities('trails');
export const resetTrails = makeResetEntities('trails');
export const storeTasks = makeStoreEntities('tasks');
