import { createStyles, Theme, WithStyles } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme: Theme) => {
    const mainThemeColor = theme.palette.primary.main;
    return createStyles({
        label: {
            color: theme.palette.getContrastText(mainThemeColor),
            '&$disabled': {
                color: theme.palette.getContrastText(mainThemeColor),
            },
        },
        disabled: {},
        colorCheckbox: {
            color: theme.palette.getContrastText(mainThemeColor),
            '&$colorChecked': {
                color: theme.palette.getContrastText(mainThemeColor),
            },
        },
        colorChecked: {},
        badge: {
            top: 18,
        },
    });
};

export type TThemableProps = WithStyles<typeof styles>;

export const Themable = withStyles(styles, { withTheme: true });
