import React, { useState } from 'react';

import { GridApi } from 'ag-grid-community';

import { Paper } from '@material-ui/core';

import { useStyles } from '../../Themable.hooks';

import { resetMode } from '../../../../state/ui/reportsSlice';
import { useReportsPaneVisible } from '../../../../state/ui/reportsSlice/index.hooks';

import { useToolkitDispatch } from '../../../../hooks';

import AppConfig from '../../../../constants/AppConfig';

import ReportsGrid from './components/ReportsGrid';
import ReportsPane from './components/ReportsPane';

const GeneratedReports = () => {
    const [gridApi, setGridApi] = useState<GridApi | null>(null);

    const toolkitDispatch = useToolkitDispatch();

    const classes = useStyles();

    const reportsPaneVisible = useReportsPaneVisible();

    const enterpriseKey = AppConfig?.instance.getConfigKey(
        AppConfig.PROPERTY_GRID
    )?.key;

    const handleClose = () => {
        toolkitDispatch(resetMode());
        enterpriseKey
            ? gridApi?.refreshServerSideStore({})
            : gridApi?.purgeInfiniteCache();
    };

    const gridPaneClass = reportsPaneVisible
        ? classes.visibleGridPane
        : classes.hiddenGridPane;

    return (
        <>
            <div className={gridPaneClass}>
                <ReportsGrid gridApi={gridApi} setGridApi={setGridApi} />
            </div>
            {reportsPaneVisible && (
                <Paper className={classes.paneWrapper}>
                    <ReportsPane handleClose={handleClose} />
                </Paper>
            )}
        </>
    );
};

export default GeneratedReports;
