import { default as Button } from '@material-ui/core/Button';
import { default as Paper } from '@material-ui/core/Paper';
import React, { Fragment } from 'react';
import TranslationHelper from '../../helpers/TranslationHelper';

type TColorUnion = 'inherit' | 'primary' | 'secondary' | 'default';

interface IOwnProps {
    buttons: {
        onClick: () => void;
        text: string;
        color?: TColorUnion;
    }[];
}

const TimeRangeButtons = ({ buttons }: IOwnProps) => {
    const renderButton = (
        onClick: () => void,
        text: string,
        color: TColorUnion = 'primary'
    ) => {
        return (
            <Button color={color} onClick={onClick}>
                {TranslationHelper.translate(text)}
            </Button>
        );
    };
    return (
        <Paper style={{ display: 'flex', justifyContent: 'space-between' }}>
            {buttons.map((button, i) => {
                return (
                    <Fragment key={i}>
                        {renderButton(
                            button.onClick,
                            button.text,
                            button.color
                        )}
                    </Fragment>
                );
            })}
        </Paper>
    );
};

export default TimeRangeButtons;
