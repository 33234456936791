import { connect } from 'react-redux';
import { TRootState } from '../../../../../../store';

import { setUserSettingsMode } from '../../../../../../state/ui/userSettings';
import { getUserId } from '../../../../../../state/user';
import { getUserLogin } from '../../../../../../state/login';

const mapStateToProps = (state: TRootState) => ({
    userId: getUserId(state),
    login: getUserLogin(state),
});

const mapDispatchToProps = {
    setUserSettingsMode,
};

type TStateProps = ReturnType<typeof mapStateToProps>;
type TDispatchProps = typeof mapDispatchToProps;

export type TConnectableProps = TStateProps & TDispatchProps;

export const Connectable = connect(mapStateToProps, mapDispatchToProps);
