import { Dispatch } from 'redux';
import { fetchRoutes } from '../../../../services/discovery/fetchRoutes';
import { makeFetchModule } from '../../../_utils';
import { storeSourceSets } from '../../entities';

const callbacks = {
    onSuccess: (dispatch: Dispatch, { entities }: { entities: object }) => {
        dispatch(storeSourceSets(entities));
    },
};
const { reducer, fetchData, reset } = makeFetchModule(
    'app/discovery/routesFetching',
    fetchRoutes,
    callbacks
);

export default reducer;
export { fetchData, reset };
