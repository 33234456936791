import { useSelector } from 'react-redux';
import {
    getCreateGroupRequest,
    getSelectedGroup,
    getUpdateGroupRequest,
    isManagemenInEditMode,
    isUserSettingsEditMode,
} from '.';

export const useUserSettingsEditMode = () =>
    useSelector(isUserSettingsEditMode);
export const useManagementEditMode = () => useSelector(isManagemenInEditMode);
export const useSelectedGroup = () => useSelector(getSelectedGroup);

export const useCreateGroupRequest = () => useSelector(getCreateGroupRequest);
export const useUpdateGroupRequest = () => useSelector(getUpdateGroupRequest);
