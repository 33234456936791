import React from 'react';

import { Redirect, Route, Switch } from 'react-router-dom';

import { useMediaQuery } from '@material-ui/core';

import AppConfig from '../../../../constants/AppConfig';

import PlanningPage from '../../../../pages/planning/PlanningPage';
import AdminPage from '../../../../pages/admin';
import DashboardPage from '../../../../pages/dashboard/DashboardPage';
import DiscoveryPage from '../../../../pages/discovery/DiscoveryPage';
import IFramePage from '../../../../pages/iframe/IFramePage';
import LoginPageContainer from '../../../../pages/login/LoginPageContainer';
import RegistersPage from '../../../../pages/registers/RegistersPage';
import ReportsPage from '../../../../pages/reports/ReportsPage';
import ServiceCenterPage from '../../../../pages/serviceCenter/ServiceCenterPage';
import ManagementPage from '../../../../pages/management/ManagementPage';
import CustomerServicePage from '../../../../pages/customerService/CustomerServicePage';
import SettingsPage from '../../../../pages/settings';
import AlertsPage from '../../../../pages/alerts/AlertsPage';
import AuthGuard from '../../../AuthGuard';
import ConfigPreloadGuard from '../../../ConfigPreloadGuard';
import PageTemplate from '../../../PageTemplate';
import ErrorDialog from '../../../dialogs/ErrorDialog';
import NoInternetDialog from '../../../dialogs/NoInternetDialog';
import { AccessibleRoute } from '../../../accessControl/components';
import LinkVideoPlayer from '../../../LinkVideoPlayer/LinkVideoPlayer';
import { MOBILE } from '../../../../constants/dictionaries/ScreenSizeConst';
import { ILinkVideoArgs } from '../../../../services/discovery/_shared/urlMakers';
import { useToolkitDispatch } from '../../../../hooks';
import {
    setVideoParams,
    toggleVideoDialog,
} from '../../../../state/ui/videoUrl';
import { paramsToObject } from '../../../../helpers/paramsToObject';
import {
    TrustedDeviceConfirmation,
    TrustedDeviceRegistration,
    TrustedDeviceResendingEmails,
} from '../../../../pages/login/TrustedDevice';
import { useFromPath } from '../../../../state/login/index.hooks';
import { useRequiredActions } from '../../../../state/auth/index.hooks';
import { usePrivileges } from '../../../../state/auth/index.hooks';
import chooseInitialPage from '../../../../helpers/chooseInitialPage';

const App = () => {
    const isMobile = useMediaQuery(MOBILE);
    const toolkitDispatch = useToolkitDispatch();

    const privileges = usePrivileges();

    const redirectHandler = () => {
        if (isMobile) {
            return <Redirect to={chooseInitialPage(privileges)} />;
        }

        if (AppConfig.instance.getConfigKey(AppConfig.PROPERTY_DASHBOARD)) {
            return (
                <Redirect
                    to={
                        privileges.dashboardContext
                            ? '/dashboard'
                            : chooseInitialPage(privileges)
                    }
                />
            );
        }
        return <Redirect to={chooseInitialPage(privileges)} />;
    };

    const fromPath = useFromPath();
    const requiredActions = useRequiredActions() ?? [];

    const renderRoutes = () => {
        return fromPath?.startsWith('/user-trusted-devices') ? (
            <>
                <Switch>
                    <AccessibleRoute
                        check={(privileges) =>
                            privileges.requireDeviceConfirmation
                        }
                        path="/user-trusted-devices"
                        exact={true}
                        component={TrustedDeviceRegistration}
                    />
                    <AccessibleRoute
                        check={(privileges) => privileges.manageTrustedDevice}
                        exact={true}
                        path="/user-trusted-devices/confirmation"
                        component={TrustedDeviceConfirmation}
                    />
                    <AccessibleRoute
                        check={(privileges) =>
                            privileges.requireDeviceConfirmation
                        }
                        exact={true}
                        path="/user-trusted-devices/resend"
                        component={TrustedDeviceResendingEmails}
                    />
                </Switch>
            </>
        ) : (
            <PageTemplate>
                <Switch>
                    <Route exact={true} path="/">
                        {redirectHandler()}
                    </Route>
                    <AccessibleRoute
                        check={(privileges) => privileges.discoveryContext}
                        path="/discovery"
                        exact={true}
                        component={DiscoveryPage}
                    />
                    {AppConfig.instance.getConfigKey(
                        AppConfig.PROPERTY_DASHBOARD
                    ) && (
                        <AccessibleRoute
                            check={(privileges) => privileges.dashboardContext}
                            path="/dashboard"
                            exact={true}
                            component={DashboardPage}
                        />
                    )}

                    {AppConfig.instance.getConfigKey(
                        AppConfig.PROPERTY_REGISTERS
                    ) && (
                        <AccessibleRoute
                            check={(privileges) => privileges.registersContext}
                            path="/registers"
                            exact={true}
                            component={RegistersPage}
                        />
                    )}

                    {AppConfig.instance.getConfigKey(
                        AppConfig.PROPERTY_ALERTS
                    ) && (
                        <AccessibleRoute
                            check={(privileges) => privileges.alertsContext}
                            path="/alerts"
                            exact={true}
                            component={AlertsPage}
                        />
                    )}

                    {AppConfig.instance.getConfigKey(
                        AppConfig.PROPERTY_REPORTS
                    ) && (
                        <AccessibleRoute
                            check={(privileges) => privileges.reportsContext}
                            path="/reports"
                            exact={true}
                            component={ReportsPage}
                        />
                    )}

                    <AccessibleRoute
                        check={(privileges) =>
                            privileges.manageGroups || privileges.eTollReadOnly
                        }
                        exact={true}
                        path="/management"
                        component={ManagementPage}
                    />
                    <AccessibleRoute
                        check={(privileges) =>
                            privileges.contextCustomerServiceCentre
                        }
                        path="/customer-service"
                        component={CustomerServicePage}
                    />
                    <AccessibleRoute
                        check={(privileges) => privileges.contextPlanning}
                        path="/planning"
                        exact={true}
                        component={PlanningPage}
                    />
                    <AccessibleRoute
                        check={(privileges) => privileges.adminPanel}
                        path="/admin"
                        exact={true}
                        component={AdminPage}
                    />

                    <Route
                        path="/user-account"
                        component={SettingsPage}
                        exact={true}
                    />

                    <Route
                        path="/service-center"
                        component={ServiceCenterPage}
                        exact={true}
                    />

                    <Route
                        path="/iframe/:iframe"
                        component={IFramePage}
                        exact={true}
                    />
                    <Redirect from="*" to="/404" />
                </Switch>

                <Route
                    path="/player"
                    render={() => {
                        const urlParams = new URLSearchParams(
                            window.location.search
                        );
                        const entries = urlParams.entries();
                        const params = paramsToObject(
                            entries
                        ) as ILinkVideoArgs;
                        if (params.monitoredId) {
                            toolkitDispatch(setVideoParams(params));

                            toolkitDispatch(toggleVideoDialog());
                        }
                        return redirectHandler();
                    }}
                />
                <LinkVideoPlayer />
                <NoInternetDialog />
                <ErrorDialog />
            </PageTemplate>
        );
    };
    return (
        <AuthGuard renderCover={() => <LoginPageContainer />}>
            <ConfigPreloadGuard
                fetchOnlyTranslations={requiredActions.length > 0}
            >
                {renderRoutes()}
            </ConfigPreloadGuard>
        </AuthGuard>
    );
};

export default App;
