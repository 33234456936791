import React, {
    ChangeEvent,
    Dispatch,
    MouseEventHandler,
    SetStateAction,
} from 'react';

import { FixedSizeList } from 'react-window';

import {
    Card,
    CardHeader,
    Divider,
    ListItem,
    ListItemText,
    ListItemIcon,
    Checkbox,
    TextField,
    MenuItem,
    Tooltip,
    IconButton,
} from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Warning } from '@material-ui/icons';

import { useStyles } from '../../Themable.hooks';

import TranslationHelper from '../../../../helpers/TranslationHelper';

import {
    findIndexById,
    intersection,
    not,
    union,
} from '../../utils/filterObjects';
import FieldWithTooltip from '../../../FieldWithTooltip';
import { ITEMS_TOGGLE_LIMIT, ITransferListObject } from '../../TransferList';

interface IOwnProps<T> {
    loaded: boolean;
    typeInput: {
        type: string;
        setType: (value: string) => void;
    };
    nameInput: {
        name: string;
        setName: (value: string) => void;
    };
    title: string;
    items: T[];
    handleToggle: (value: T) => MouseEventHandler | undefined;
    checked: T[];
    setChecked: Dispatch<SetStateAction<T[]>>;
    types: string[];
    tooltipTitle?: string;
    disabled?: boolean;
}

const LISTBOX_PADDING = 52; // px
const ITEM_SIZE = 36;
const CustomList = <T extends ITransferListObject>({
    loaded,
    typeInput,
    nameInput,
    title,
    items,
    handleToggle,
    checked,
    setChecked,
    tooltipTitle,
    types,
    disabled,
}: IOwnProps<T>) => {
    const classes = useStyles();
    const { type, setType } = typeInput;
    const { name, setName } = nameInput;

    const numberOfChecked = (selectedItems: T[]) =>
        intersection(checked, selectedItems).length;

    const renderNoItemsContainer = () => {
        return (
            items.length === 0 &&
            TranslationHelper.translate(
                'No data to display. Try to change your filters.'
            )
        );
    };

    const getHeight = () => {
        return 6 * ITEM_SIZE;
    };

    const renderItemsList = (items: T[]) => {
        const renderRow = ({ data, index, style }: any) => {
            const item = data[index];
            style = { ...style, marginTop: '12px' };
            const uniqueKey = data[index].relationId.concat(data[index].type);
            const labelId = `transfer-list-all-item-${uniqueKey}-label`;
            return (
                <ListItem
                    key={uniqueKey}
                    role="listitem"
                    button
                    onClick={handleToggle(item)}
                    style={style}
                >
                    <ListItemIcon>
                        <Checkbox
                            checked={findIndexById(checked, item) !== -1}
                            tabIndex={-1}
                            disableRipple
                            inputProps={{
                                'aria-labelledby': labelId,
                            }}
                        />
                    </ListItemIcon>
                    <Tooltip
                        title={item.name}
                        enterDelay={500}
                        enterNextDelay={500}
                    >
                        <ListItemText
                            className={classes.listItemText}
                            classes={{
                                primary: classes.listItemTextPrimary,
                            }}
                            id={labelId}
                            primary={item.name}
                            secondary={`${TranslationHelper.translate(
                                `assignType.${item.registerType ?? item.type}`
                            )} ${item.address ?? ''}`}
                        />
                    </Tooltip>
                </ListItem>
            );
        };
        return items.length > 0 ? (
            <div className={classes.listContainer}>
                <FixedSizeList
                    className={classes.list}
                    itemData={items}
                    height={getHeight() + 2 * LISTBOX_PADDING}
                    width="100%"
                    itemCount={items.length}
                    itemSize={60}
                >
                    {renderRow}
                </FixedSizeList>
            </div>
        ) : (
            <div className={classes.noItems}>
                {loaded && renderNoItemsContainer()}
            </div>
        );
    };

    const handleToggleAll = (items: T[]) => () => {
        if (numberOfChecked(items) === items.length) {
            setChecked(not(checked, items));
        } else {
            setChecked(union(checked, items));
        }
    };
    const handleChangeType = (e: ChangeEvent<HTMLInputElement>) => {
        setType(e.target.value);
    };

    const handleChangeName = (e: ChangeEvent<HTMLInputElement>) => {
        setName(e.target.value);
    };
    return (
        <Card className={classes.card}>
            <CardHeader className={classes.cardHeader} title={title} />

            <div className={classes.filtersWrapper}>
                <FieldWithTooltip title={tooltipTitle}>
                    <TextField
                        name={'nameFilter'}
                        id={'nameFilter'}
                        label={TranslationHelper.translate('Find...')}
                        value={name}
                        onChange={handleChangeName}
                        className={classes.searchByName}
                        disabled={disabled}
                    />
                </FieldWithTooltip>

                <TextField
                    select
                    label={TranslationHelper.translate('Type')}
                    value={type}
                    onChange={handleChangeType}
                    className={classes.selectType}
                    disabled={disabled}
                >
                    {types.map((option) => (
                        <MenuItem key={option} value={option}>
                            {TranslationHelper.translate(
                                `assignType.${option}`
                            )}
                        </MenuItem>
                    ))}
                </TextField>
            </div>
            <div className={classes.selectAllWrapper}>
                <Checkbox
                    onClick={handleToggleAll(items)}
                    checked={
                        numberOfChecked(items) === items.length &&
                        items.length !== 0
                    }
                    indeterminate={
                        numberOfChecked(items) !== items.length &&
                        numberOfChecked(items) !== 0
                    }
                    disabled={
                        items.length === 0 ||
                        items.length > ITEMS_TOGGLE_LIMIT ||
                        !loaded
                    }
                    inputProps={{ 'aria-label': 'all items selected' }}
                />
                <div>{`${numberOfChecked(items)}/${
                    items.length
                } ${TranslationHelper.translate('Selected')}`}</div>
                {items.length >= ITEMS_TOGGLE_LIMIT && (
                    <Tooltip
                        title={TranslationHelper.translate(
                            'assignToGroup.tooMany'
                        )}
                    >
                        <IconButton size="small">
                            <Warning className={classes.warningIcon} />
                        </IconButton>
                    </Tooltip>
                )}
            </div>
            <Divider />
            {loaded ? renderItemsList(items) : <LinearProgress />}
        </Card>
    );
};

export default CustomList;
