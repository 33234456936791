import { Field } from 'formik';
import React from 'react';
import TranslationHelper from '../../../../../../helpers/TranslationHelper';
import FieldWrapper from '../../../../../../components/common/formikWrappers/FieldWrapper';

export interface IField {
    name: string;
    label: string;
    type?: string;
    autoComplete?: string;
    required?: boolean;
}

const Fields: React.FunctionComponent<{
    fields: IField[];
    errors?: object;
    disabled?: boolean;
}> = ({ fields, errors, disabled }) => (
    <>
        {fields.map(({ name, label, type, autoComplete, required }) => (
            <Field
                error={!!(errors && errors[name])}
                helperText={errors && errors[name]}
                fullWidth={true}
                component={FieldWrapper}
                key={name}
                name={name}
                label={TranslationHelper.translate(label)}
                type={type || 'text'}
                autoComplete={autoComplete}
                required={required ? required : false}
                disabled={disabled ?? false}
            />
        ))}
    </>
);

export default Fields;
