import { Component, ComponentType } from 'react';

import { compose } from 'redux';

import { IRequestWithMessages } from '../../state/app/sync';

import { withSnackbar, WithSnackbarProps } from 'notistack';
import { Connectable, TConnectableProps } from './Connectable.hoc';

interface IOwnProps {
    request: IRequestWithMessages | null;
}

type TProps = IOwnProps & TConnectableProps & WithSnackbarProps;

class ServerMessageDisplay extends Component<TProps> {
    public render() {
        const { request, enqueueSnackbar, forgetRequest } = this.props;

        if (!request) {
            return false;
        }

        let type: 'info' | 'error' | 'success' = 'info';

        if (request.error) {
            type = 'error';
        } else if (request.success) {
            type = 'success';
        }

        const getMessage =
            request.error?.getMessage || request.success?.getMessage;
        if (getMessage) {
            enqueueSnackbar(getMessage(), {
                variant: type,
                preventDuplicate: true,
                onExit: () => {
                    forgetRequest(request);
                },
            });
        }
        return null;
    }
}

export default compose(
    Connectable,
    withSnackbar
)(ServerMessageDisplay) as ComponentType<IOwnProps>;
