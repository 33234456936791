import { createStyles, Theme, WithStyles } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme: Theme) => {
    return createStyles({
        picker: {
            padding: theme.spacing(2),
        },
        formControl: {
            margin: theme.spacing(),
        },
        datePickers: {
            '& > div': {
                marginBottom: 20,
            },
        },
    });
};

export type TThemableProps = WithStyles<typeof styles>;

export const Themable = withStyles(styles, { withTheme: true });
