import AppConfig from '../constants/AppConfig';
import apiClient from '../services/common/apiClient';

export default () =>
    apiClient.getJsonConfiguration('webx.json').then((config) => {
        AppConfig.instance.setConfig(config);
        return {
            title: AppConfig.instance.getConfigKey('title') || 'WebX',
            themeName: AppConfig.instance.getConfigKey('th'),
        };
    });
