import { createStyles, Theme, WithStyles } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme: Theme) => {
    return createStyles({
        paramContainer: {
            flex: '0 1 auto',
            display: 'flex',
            justifyContent: 'space-around',
            alignItems: 'flex-end',
        },
        paramItem: {
            flexGrow: 1,
            flexBasis: 0,
            textAlign: 'center',
            fontSize: theme.typography.caption.fontSize,
            fontWeight: 'bold',
        },
        paramItemIcon: {
            fontSize: '24px',
            lineHeight: '24px',
            margin: '10px 0',
        },
    });
};

export type TThemableProps = WithStyles<typeof styles>;

export const Themable = withStyles(styles, { withTheme: true });
