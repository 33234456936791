import React, { useEffect } from 'react';

import { LinearProgress } from '@material-ui/core';

import {
    enterClientEditMode,
    enterLocationAddMode,
    enterLocationEditMode,
    resetMode,
} from '../../../../../../../state/ui/customerService/bottomGrid';
import {
    useContractItem,
    useContractItemCycleId,
    useContractItemId,
    useContractsItemsPaneLoading,
    useOpenSectionContract,
    useOpenSectionContractAttachments,
    useOpenSectionContracts,
} from '../../../../../../../state/ui/customerService/bottomGrid/contractItems/index.hooks';
import {
    useSelectedClient,
    useSelectedLocation,
} from '../../../../../../../state/ui/customerService/clientsAndLocations/index.hooks';
import {
    fetchContractItem,
    toggleCollapseContract,
    toggleCollapseContractAttachments,
    toggleCollapseContracts,
} from '../../../../../../../state/ui/customerService/bottomGrid/contractItems';
import TranslationHelper from '../../../../../../../helpers/TranslationHelper';
import { useToolkitDispatch } from '../../../../../../../hooks';

import PaneHeader from '../../../../../../../components/PaneHeader/PaneHeader';
import { CollapseListItem } from '../../../../../../../components/CollapseListItem/CollapseListItem';
import ContractItemForm from '../../ContractItems/ContractItemForm';
import ContractForm from '../../Contract/ContractForm';
import AttachmentsSection from '../../../../../../../components/AttachmentsSection';
import {
    BLANK_FORM_CONTRACT,
    BLANK_FORM_CONTRACT_ITEM,
    BLANK_FORM_LOCATION,
} from '../../ClientsAndLocations/blankForms';
import ClientSection from '../../ClientsAndLocations/ClientSection';
import LocationSection from '../../ClientsAndLocations/LocationSection';

const ContractItemPreview = () => {
    const toolkitDispatch = useToolkitDispatch();
    const client = useSelectedClient();
    const location = useSelectedLocation();
    const paneLoading = useContractsItemsPaneLoading();
    const contractItem = useContractItem();
    const contractItemId = useContractItemId();
    const contract = contractItem?.contract;

    const openSectionContracts = useOpenSectionContracts();
    const openSectionContract = useOpenSectionContract();
    const openSectionContractAttachments = useOpenSectionContractAttachments();
    const contractCycleId = useContractItemCycleId();

    const handleClose = () => {
        toolkitDispatch(resetMode());
    };
    useEffect(() => {
        contractItemId &&
            toolkitDispatch(
                fetchContractItem({
                    id: contractItemId,
                    cycleId: contractCycleId,
                })
            );
    }, [contractItemId, contractCycleId]);

    const handleToggleCollapseContracts = () => {
        toolkitDispatch(toggleCollapseContracts());
    };

    const handleToggleCollapseContract = () => {
        toolkitDispatch(toggleCollapseContract());
    };
    const handleToggleCollapseContractAttachments = () => {
        toolkitDispatch(toggleCollapseContractAttachments());
    };

    const handleEditClient = () => {
        toolkitDispatch(enterClientEditMode());
    };

    const handleEditLocation = () => {
        toolkitDispatch(enterLocationEditMode());
    };

    const handleAddLocation = () => {
        toolkitDispatch(enterLocationAddMode());
    };
    const initialValues = {
        client,
        location: location ? { ...BLANK_FORM_LOCATION, ...location } : null,
        contractItem: contractItem
            ? { ...BLANK_FORM_CONTRACT_ITEM, ...contractItem }
            : null,
        contract: contract ? { ...BLANK_FORM_CONTRACT, ...contract } : null,
    };

    return (
        <div>
            <PaneHeader
                onCloseClick={handleClose}
                title={TranslationHelper.translate('Details')}
            />
            {!contractItem || paneLoading ? (
                <LinearProgress />
            ) : (
                <div>
                    {initialValues?.contractItem && (
                        <CollapseListItem
                            title="Contract item"
                            openSection={openSectionContracts}
                            handleToggleCollapse={handleToggleCollapseContracts}
                        >
                            <ContractItemForm
                                initialValues={initialValues.contractItem}
                            />
                        </CollapseListItem>
                    )}
                    {initialValues?.contractItem?.attachments?.length && (
                        <CollapseListItem
                            title="Attachments"
                            openSection={openSectionContractAttachments}
                            handleToggleCollapse={
                                handleToggleCollapseContractAttachments
                            }
                        >
                            <AttachmentsSection
                                attachments={
                                    initialValues.contractItem.attachments
                                }
                                api={`/rest/api/csc/contract-items/${initialValues.contractItem.id}/attachments/`}
                            />
                        </CollapseListItem>
                    )}
                    {initialValues?.contract && (
                        <CollapseListItem
                            title="Contract"
                            openSection={openSectionContract}
                            handleToggleCollapse={handleToggleCollapseContract}
                        >
                            <ContractForm
                                initialValues={initialValues.contract}
                            />
                        </CollapseListItem>
                    )}
                    <ClientSection
                        client={initialValues.client}
                        handleEditClient={handleEditClient}
                    />
                    <LocationSection
                        location={initialValues.location}
                        handleEdit={handleEditLocation}
                        handleAdd={handleAddLocation}
                    />
                </div>
            )}
        </div>
    );
};

export default ContractItemPreview;
