import React, { ReactChild } from 'react';

import { FixedSizeList } from 'react-window';

import {
    Checkbox,
    ListItem,
    ListItemIcon,
    ListItemText,
    Paper,
    Tooltip,
} from '@material-ui/core';

import { useStyles } from '../SearchableSelectionList/Themable.hooks';

import TranslationHelper from '../../helpers/TranslationHelper';

import { DELAY } from '../../constants/TooltipSettings';
import {
    findIndexById,
    intersection,
    not,
    union,
} from '../../helpers/filterObject';
import LoadingSpinner from '../loadingSpinner/LoadingSpinner';

const LISTBOX_PADDING = 52; // px
const ITEM_SIZE = 24;

interface IOwnProps<T> {
    items: T[];
    allItems: T[];
    checked: T[];
    loaded: boolean;
    handleFormChange: <T>(name: string, value: T) => void;
    children?: ReactChild;
    disabled?: boolean;
}
const SelectList = <T,>({
    items,
    allItems,
    loaded,
    checked,
    handleFormChange,
    children,
    disabled,
}: IOwnProps<T>) => {
    const classes = useStyles();

    const getHeight = () => {
        return 8 * ITEM_SIZE;
    };
    const numberOfChecked = (selectedItems: T[]) => {
        return intersection(checked, selectedItems, 'id').length;
    };
    const handleToggleAll = (items: T[]) => {
        if (numberOfChecked(items) === items.length) {
            handleFormChange('objects', not(checked, items, 'id'));
        } else {
            handleFormChange('objects', union(checked, items));
        }
    };

    const handleToggle = (value: T) => () => {
        const currentIndex = findIndexById(checked, value);

        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        handleFormChange('objects', newChecked);
    };

    const renderRow = ({ data, index, style }: any) => {
        const item = data[index];
        style = { ...style };
        const uniqueKey = item.id + item.name;
        const labelId = `item-${uniqueKey}`;
        return (
            <ListItem
                key={uniqueKey}
                role="listitem"
                button
                onClick={handleToggle(item)}
                style={style}
                disabled={disabled}
            >
                <ListItemIcon>
                    <Checkbox
                        checked={findIndexById(checked, item) !== -1}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{
                            'aria-labelledby': labelId,
                        }}
                        disabled={disabled}
                    />
                </ListItemIcon>
                <Tooltip
                    title={item.name}
                    enterDelay={DELAY}
                    enterNextDelay={DELAY}
                >
                    <ListItemText
                        className={classes.listItemText}
                        id={labelId}
                        primary={item.name}
                    />
                </Tooltip>
            </ListItem>
        );
    };
    const renderList = () => {
        if (!loaded) {
            return (
                <div className={classes.noItems}>
                    <LoadingSpinner />
                </div>
            );
        }
        if (!items.length) {
            return (
                <div className={classes.noItems}>
                    {TranslationHelper.translate('No results...')}
                </div>
            );
        }
        return (
            <FixedSizeList
                className={classes.list}
                itemData={items}
                height={getHeight() + LISTBOX_PADDING}
                width="100%"
                itemCount={items.length}
                itemSize={60}
            >
                {renderRow}
            </FixedSizeList>
        );
    };
    return (
        <div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <Checkbox
                    onClick={() => handleToggleAll(items)}
                    checked={
                        numberOfChecked(items) === items.length &&
                        items.length !== 0
                    }
                    indeterminate={
                        numberOfChecked(items) !== items.length &&
                        numberOfChecked(items) !== 0
                    }
                    disabled={items.length === 0 || !loaded || disabled}
                    inputProps={{ 'aria-label': 'all items selected' }}
                />

                <div>{`${checked?.length || 0} /${
                    allItems.length
                } ${TranslationHelper.translate('Selected')}`}</div>
            </div>
            {children}
            <Paper>{renderList()}</Paper>
        </div>
    );
};

export default SelectList;
