import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        pageWrapper: {
            position: 'absolute',
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
        },
        visibleGridPane: {
            position: 'absolute',
            top: 0,
            left: 0,
            bottom: 0,
            right: 500 + 10,
        },
        hiddenGridPane: {
            position: 'absolute',
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
        },
        paneWrapper: {
            position: 'absolute',
            top: 0,
            left: 'calc(100% - 500px)',
            width: 500,
            maxHeight: '100%',
            right: 0,
            overflowY: 'auto',
            overflowX: 'hidden',
            display: 'flex',
            height: '100%',
        },
        gridPane: {
            display: 'flex',
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
        },
        wrapper: {
            display: 'flex',
            position: 'relative',
            flexGrow: 1,
            flexDirection: 'column',
        },
    })
);
