import { createStyles, Theme, WithStyles } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme: Theme) => {
    return createStyles({
        picker: {
            margin: theme.spacing(),
        },
        optionsWrapper: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
        },
        acceptButton: {
            alignSelf: 'flex-end',
            justifySelf: 'flex-end',
            margin: theme.spacing(),
        },
    });
};

export type TThemableProps = WithStyles<typeof styles>;

export const Themable = withStyles(styles, { withTheme: true });
