import React, { Component, ComponentType, MouseEvent } from 'react';
import { compose } from 'redux';

import { default as Paper } from '@material-ui/core/Paper';
import TranslationHelper from '../../../../helpers/TranslationHelper';

import AggregationDetailsPreview from './components/AggregationDetailsPreview';
import CalibrationSetting from './components/CalibrationSetting';
import FrameDetailsPreview from './components/FrameDetailsPreview';
import LastState from './components/LastState';
import RegisterRefueling from './components/RegisterRefueling';
import ReportSetPreview from './components/ReportSetPreview';
import SourceSetElementPreview from './components/SourceSetElementPreview';
import DetectionSettings from './components/DetectionSettings';

import { withSnackbar, WithSnackbarProps } from 'notistack';
import { Connectable, TConnectableProps } from './Connectable.hoc';
import { Themable, TThemableProps } from './Themable.hoc';

import { responseMessage } from '../../../../helpers/responseMessage';
import ParametersMappingPane from './components/ParametersMappingPane';

import ErrorBoundary from '../../../../components/ErrorBoundary';
import EditEvent from './components/EditEvent';
import EditObject from './components/EditObject';
import LocationPane from './components/LocationPane';
import TaskLinkagePreview from './components/TaskLinkagePreview';
import TaskPane from './components/TaskPane';
import SearchEvents from './components/SearchEvents/SearchEvents';
import DataSegments from './components/DataSegments';
import DataSegmentsReports from './components/DataSegments/DataSegmentsReports';

interface IOwnProps {
    mode?: string;
}
type TProps = IOwnProps &
    TThemableProps &
    TConnectableProps &
    WithSnackbarProps;

const previewTypes = {
    monitoredObject: {
        component: LastState,
        fetching: ['lastState'],
    },
    reportSet: {
        component: ReportSetPreview,
        fetching: ['reportSet', 'trail'],
    },
    sourceSetElement: {
        component: SourceSetElementPreview,
        fetching: ['sourceSetElement'],
    },
    location: {
        component: LocationPane,
        fetching: ['location'],
    },
    task: {
        component: TaskPane,
        fetching: ['tasks'],
    },
    externalLinkage: {
        component: TaskLinkagePreview,
        fetching: [],
    },
    frameDetails: {
        component: FrameDetailsPreview,
        fetching: [],
    },
    aggregationDetails: {
        component: AggregationDetailsPreview,
        fetching: [],
    },
    calibrationSetting: {
        component: CalibrationSetting,
        fetching: [],
    },
    registerRefueling: {
        component: RegisterRefueling,
        fetching: [],
    },
    refuelingAndFuelLossDetectionSettings: {
        component: DetectionSettings,
        fetching: [],
    },
    devices: {
        component: ParametersMappingPane,
        fetching: [],
    },
    editEvent: {
        component: EditEvent,
        fetching: [],
    },
    editObject: {
        component: EditObject,
        fetching: ['lastState'],
    },
    searchEvents: {
        component: SearchEvents,
        fetching: [],
    },
    dataSegments: {
        component: DataSegments,
        fetching: [],
    },
    reportSetDataSegments: {
        component: DataSegmentsReports,
        fetching: [],
    },
};

class PreviewPane extends Component<TProps> {
    public componentDidUpdate(prevProps: TProps) {
        const {
            fetching,
            enqueueSnackbar,
            closePreview,
            creatorLevel,
            preview,
        } = this.props;

        if (preview) {
            previewTypes[preview.elementType].fetching.some((type: string) => {
                const error = fetching[type + 'Fetching']?.error;

                if (
                    prevProps.fetching[type + 'Fetching']?.error !== error &&
                    error
                ) {
                    const message = responseMessage(error.status);
                    enqueueSnackbar(TranslationHelper.translate(message.text), {
                        variant: message.type,
                    });
                    closePreview(creatorLevel);
                }
            });
        }
    }

    public render() {
        const { classes, preview } = this.props;

        return (
            <Paper onClick={this.handleClick} className={classes.pane}>
                {preview !== null && previewTypes[preview.elementType] && (
                    <ErrorBoundary>
                        {React.createElement(
                            previewTypes[preview.elementType].component,
                            {
                                key: JSON.stringify(preview),
                            }
                        )}
                    </ErrorBoundary>
                )}
            </Paper>
        );
    }

    private handleClick = (event: MouseEvent<HTMLDivElement>) => {
        event.stopPropagation();
    };
}

export default compose(
    Themable,
    Connectable,
    withSnackbar
)(PreviewPane) as ComponentType<IOwnProps>;
