import { Dispatch } from 'redux';

import { IEmployee } from '../../../../state/app/registers/employees';
import { IPage } from '../../../../state/app/registers';
import { IVehicle } from '../../../../state/app/registers/vehicles';

import {
    restGetEmployees,
    restGetVehicles,
} from '../../../../services/registers';

import { makeFetchModule } from '../../../_utils';

import { ISearchResult, storeSearcherData, TSearchType } from '../../searchers';

const callbacks = <T>(searcherType: TSearchType) => ({
    onSuccess: (dispatch: Dispatch, data: ISearchResult[]) => {
        dispatch(storeSearcherData(data, searcherType));
    },
});

const vehiclesFetchModule = makeFetchModule(
    'app/searchers/vehiclesFetching',
    (name: string, limit: number): Promise<ISearchResult[]> =>
        restGetVehicles({ name, limit }).then((page: IPage<IVehicle>) => {
            const items = page.values;
            if (items) {
                return items.map((item: IVehicle) => ({
                    id: item.externalId,
                    label: item.name,
                    cards: item.cards,
                }));
            } else {
                return [];
            }
        }),
    callbacks<ISearchResult>('vehicles')
);

const employeesFetchModule = makeFetchModule(
    'app/searchers/employeesFetching',
    (name: string, limit: number): Promise<ISearchResult[]> =>
        restGetEmployees({ name, limit }).then((page: IPage<IEmployee>) => {
            const items = page.values;
            if (items) {
                return items.map((item: IEmployee) => ({
                    id: item.externalId,
                    label: item.name,
                    cards: item.cards,
                }));
            } else {
                return [];
            }
        }),
    callbacks<ISearchResult>('employees')
);

export const reducer = {
    employees: employeesFetchModule.reducer,
    vehicles: vehiclesFetchModule.reducer,
};
export const fetchData = {
    employees: employeesFetchModule.fetchData,
    vehicles: vehiclesFetchModule.fetchData,
};
export const reset = {
    employees: employeesFetchModule.reset,
    vehicles: vehiclesFetchModule.reset,
};
