import React, { useEffect } from 'react';

import { useStyles } from '../../../../Themable.hooks';

import { useMode } from '../../../../../../state/ui/customerService/bottomGrid/index.hooks';

import EventPreview from './EventPreview/EventPreview';
import { useAppDispatch } from '../../../../../../hooks';
import { closePreview } from '../../../../../../state/_actions';

const EventsPane = () => {
    const dispatch = useAppDispatch();

    const classes = useStyles();

    const mode = useMode();

    useEffect(() => {
        return () => {
            dispatch(closePreview(0));
        };
    }, []);

    const renderPane = () => {
        switch (mode) {
            case 'create':
                return <></>;
            case 'preview':
                return <EventPreview />;

            default:
                return;
        }
    };
    return <div className={classes.wrapper}>{renderPane()}</div>;
};

export default EventsPane;
