import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import grey from '@material-ui/core/colors/grey';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        header: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            backgroundColor: grey[200],
            padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
            fontSize: '18px',
            fontWeight: 600,
            cursor: 'pointer',
        },
    })
);
