import React, { useEffect } from 'react';

import { useSnackbar } from 'notistack';

import { IconButton, ListItem, Tooltip } from '@material-ui/core';

import { default as EditIcon } from '@material-ui/icons/Edit';
import { Skeleton } from '@material-ui/lab';

import { usePrivileges } from '../../../../../../../state/auth/index.hooks';

import {
    useLocationDetailsLoading,
    useOpenSectionLocations,
    useSelectedClientsAndLocations,
    useSelectedLocationId,
} from '../../../../../../../state/ui/customerService/clientsAndLocations/index.hooks';

import {
    fetchLocation,
    selectLocation,
    IMultiSelectLocation,
    toggleCollapseLocations,
    resetLocation,
} from '../../../../../../../state/ui/customerService/clientsAndLocations';

import { useStyles } from '../../../../../Themable.hooks';

import { useToolkitDispatch } from '../../../../../../../hooks';

import TranslationHelper from '../../../../../../../helpers/TranslationHelper';
import { errorMessageHandler } from '../../../../../../../helpers/errorMessageHandler';

import { CollapseListItem } from '../../../../../../../components/CollapseListItem/CollapseListItem';
import LocationPreviewForm from '../LocationPreviewForm';

import { useRegistersGroups } from '../../../../../../../state/app/registers/index.hooks';
import { fetchRegistersGroups } from '../../../../../../../state/app/registers/groups';
import {
    prepareGroups,
    prepareVisibilityType,
} from '../../../../../../../components/VisibilityForm/VisibilityForm';
import { useUserDataPermissions } from '../../../../../../../state/user/index.hooks';
import { IServiceLocation } from '../../../../../../../models/customerService';
interface IOwnProps {
    location: IServiceLocation | null;
    handleEdit: () => void;
    handleAdd: () => void;
    showList?: boolean;
}
const LocationSection = ({
    location,
    handleAdd,
    handleEdit,
    showList,
}: IOwnProps) => {
    const classes = useStyles();
    const openSectionLocations = useOpenSectionLocations();
    const privileges = usePrivileges();
    const locationId = useSelectedLocationId();
    const loading = useLocationDetailsLoading();
    const toolkitDispatch = useToolkitDispatch();
    const selectedClientsAndLocations = useSelectedClientsAndLocations();
    const groups = useRegistersGroups();
    const dataPermissions = useUserDataPermissions();

    const handleToggleCollapseLocations = () => {
        toolkitDispatch(toggleCollapseLocations());
    };

    const { enqueueSnackbar } = useSnackbar();

    const selectedLocations = selectedClientsAndLocations.filter(
        (item) => item.id
    );

    const anyClientPresent =
        selectedClientsAndLocations.filter((item) => !!item.clientId).length >
        0;

    useEffect(() => {
        locationId?.id
            ? toolkitDispatch(fetchLocation(locationId.id))
                  .unwrap()
                  .catch((error) => {
                      const message = errorMessageHandler(error.status)();
                      enqueueSnackbar(message, {
                          variant: 'error',
                      });
                  })
            : toolkitDispatch(resetLocation());
    }, [locationId]);

    useEffect(() => {
        if (!groups) {
            toolkitDispatch(fetchRegistersGroups({}));
        }
    }, [groups, toolkitDispatch]);

    const isAddLocationVisible = privileges.locations && anyClientPresent;

    if (!location && !selectedLocations.length) {
        return (
            <CollapseListItem
                title={'Location'}
                openSection={false}
                handleToggleCollapse={() => {}}
                handleAdd={isAddLocationVisible ? handleAdd : undefined}
            ></CollapseListItem>
        );
    }

    const multipleLocations = selectedLocations.length > 1;

    const isMultiLocation = multipleLocations && showList;

    const handleEditFromList = (location: IMultiSelectLocation) => {
        toolkitDispatch(
            selectLocation({ externalId: location.id, id: location.locationId })
        );

        toolkitDispatch(fetchLocation(location.locationId))
            .unwrap()
            .then(() => {
                handleEdit();
            });
    };

    const renderLocationsList = (list: IMultiSelectLocation[]) => {
        const maxLocationsLength = 5;

        const locationsList = list
            .slice(0, maxLocationsLength)
            .map((location) => (
                <ListItem key={location.id} className={classes.listItem}>
                    <div className={classes.itemLabel}>
                        {TranslationHelper.translate(location.name)}
                    </div>
                    {privileges.locations && (
                        <Tooltip title={TranslationHelper.translate('Edit')}>
                            <IconButton
                                onClick={() => handleEditFromList(location)}
                            >
                                <EditIcon />
                            </IconButton>
                        </Tooltip>
                    )}
                </ListItem>
            ));
        return (
            <>
                {locationsList}
                {list.length > maxLocationsLength && (
                    <ListItem key={'...'} className={classes.listItem}>
                        <div className={classes.itemLabel}>...</div>
                    </ListItem>
                )}
            </>
        );
    };
    const initialValues = {
        ...location,
        visibilityType: prepareVisibilityType(
            groups,
            location?.groupIds,
            dataPermissions
        ),
        groups: prepareGroups(groups, location?.groupIds),
    };
    return (
        <>
            {loading || !location ? (
                <Skeleton variant="rect" height={50} />
            ) : (
                <CollapseListItem
                    title={isMultiLocation ? 'Locations' : 'Location'}
                    additionalText={
                        isMultiLocation
                            ? `${TranslationHelper.translate('Selected')}: ${
                                  selectedLocations.length
                              }`
                            : ''
                    }
                    openSection={openSectionLocations}
                    handleToggleCollapse={handleToggleCollapseLocations}
                    handleEdit={
                        privileges.locations && !isMultiLocation
                            ? handleEdit
                            : undefined
                    }
                    handleAdd={isAddLocationVisible ? handleAdd : undefined}
                >
                    {isMultiLocation ? (
                        <>{renderLocationsList(selectedLocations)}</>
                    ) : (
                        <LocationPreviewForm initialValues={initialValues} />
                    )}
                </CollapseListItem>
            )}
        </>
    );
};
export default LocationSection;
