import { makeReduxDuck } from 'teedux';

interface IState {
    tab: null | string;
}

export const initialState: IState = {
    tab: null,
};

const duck = makeReduxDuck('ui/settings', initialState);

export const selectTab = duck.defineAction<{
    tab: string;
}>('SELECT_TAB', (_, { tab }) => ({
    tab,
}));

export default duck.getReducer();
