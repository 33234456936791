import React, { ComponentType, useEffect, useState } from 'react';

import { compose } from 'redux';

import { default as List } from '@material-ui/core/List';

import { TConnectableProps } from './Connectable.hoc';
import { Themable, TThemableProps } from './Themable.hoc';

import { IActionDataParams, IReport } from '../../../../state/types';
import { TFormTypes } from '../../../../state/ui/forms';
import {
    TComponentType,
    TPreviewPane,
    TSnapshotType,
    activateChart,
    activateGrid,
    activatePreview,
    handleExpandReportUpdate,
} from '../../../../state/ui/discovery/snapshotting';
import {
    IPreviewAction,
    ISourceSetAction,
} from '../../../../state/ui/discovery/types';
import { makeSourceSetId } from '../../../../services/discovery/_shared/utils';
import { makeSourceSetUrl } from '../../../../services/discovery/_shared/urlMakers';

import { useAppDispatch } from '../../../../hooks';

import ReportList from './ReportList';
import DeletionDialog from '../../../../components/dialogs/DeletionDialog';
import { customAction } from '../../../../state/_actions';
import {
    useExtendedReports,
    usePreviewElementId,
    useTimeLocked,
} from '../../selectors/index.hooks';

interface IOwnProps {
    expanded?: boolean;
    report: IReport;
    from?: string;
    to?: string;
    preview?: TPreviewPane;
    creatorLevel: number;
    afterItemDelete?: () => void;
    customReportActions?: {
        [key: string]: (
            elementCollectionName: string,
            elementId: string | null,
            elementType: string,
            mode: string,
            snapshot: {
                type: TSnapshotType;
                level: number;
                storeResult?: TFormTypes;
            },
            previewAction: IPreviewAction
        ) => void;
    };
}

type TProps = IOwnProps & TConnectableProps & TThemableProps;

const Report: React.FC<TProps> = ({
    classes,
    preview,
    report,
    creatorLevel,
    customReportActions,
    afterItemDelete,
}: TProps) => {
    const dispatch = useAppDispatch();

    const [open, setOpen] = useState(false);
    const [isDeletionDialogOpen, setIsDeletionDialogOpen] = useState(false);
    const [idToDelete, setIdToDelete] = useState<string | null>(null);
    const [deleteMessage, setDeleteMessage] = useState<string | undefined>(
        undefined
    );
    const expandedReports = useExtendedReports();
    const monitoredId = usePreviewElementId();
    const timeLocked = useTimeLocked();

    let hasDeleteAction;

    if (report._meta?.actions) {
        hasDeleteAction = report._meta.actions.delete !== undefined;
    }

    useEffect(() => {
        if (expandedReports[report.label] !== undefined) {
            setOpen(!expandedReports[report.label]);
        } else {
            setOpen((report._meta && report._meta.expanded) || false);
        }
    }, []);

    const handleOpenSourceSetClick = (action?: ISourceSetAction) => {
        if (action) {
            dispatch(
                activateGrid(
                    makeSourceSetId(action.params) ||
                        makeSourceSetUrl(action).toString(),
                    {
                        type: 'source-set-grid',
                        level: creatorLevel + 1,
                    },
                    preview || null,
                    false,
                    action,
                    timeLocked
                )
            );
        }
    };

    const handleOpenChartClick = () => {
        const action = report?._meta?.actions?.chart;

        if (action) {
            dispatch(
                activateChart(
                    makeSourceSetId(action.params)!,
                    action,
                    {
                        type: 'source-set-chart',
                        level: creatorLevel + 1,
                    },
                    timeLocked
                )
            );
        }
    };

    const handleOpenGridChartClick = () => {
        const action = report?._meta?.actions?.sourceSet;
        if (action) {
            dispatch(
                activateGrid(
                    makeSourceSetId(action.params) ||
                        makeSourceSetUrl(action).toString(),
                    {
                        type: 'source-set-grid-chart',
                        level: creatorLevel + 1,
                    },
                    preview ? preview : null,
                    false,
                    action,
                    timeLocked
                )
            );
        }
    };

    const handleAction = (
        action: IPreviewAction,
        type: 'add' | 'edit' | 'preview' | 'internalLink',
        params?: IActionDataParams
    ) => {
        if (action) {
            const actionWithParams = {
                ...action,
                params: {
                    ...action.params,
                    ...params,
                } as IActionDataParams,
            };
            if (
                action.componentName &&
                customReportActions?.[action.componentName]
            ) {
                const activateCustomAction =
                    customReportActions[action.componentName];
                dispatch(
                    activateCustomAction(
                        actionWithParams?.params?.id || monitoredId || '',
                        monitoredId || action?.params?.id || '',
                        actionWithParams.componentName as string,
                        type,
                        {
                            type:
                                (action.componentName as TComponentType) ||
                                'preview',
                            level: creatorLevel + 1,
                        },
                        actionWithParams
                    )
                );
                return;
            }
            dispatch(
                activatePreview(
                    actionWithParams?.params?.id || monitoredId || '',
                    monitoredId || action?.params?.id || '',
                    actionWithParams.componentName as string,
                    type,
                    {
                        type:
                            (action.componentName as TComponentType) ||
                            'preview',
                        level: creatorLevel + 1,
                    },
                    actionWithParams
                )
            );
        }
    };

    const handleReportAction = (
        type: 'add' | 'edit' | 'preview',
        params?: IActionDataParams
    ) => {
        const action = report?._meta?.actions?.[type];
        if (action) {
            handleAction(action, type, params);
        }
    };

    const handleEntityAction = (
        type: 'add' | 'edit' | 'preview' | 'internalLink',
        params: IActionDataParams,
        collectionId?: string
    ) => {
        const action = report?.entities?.filter(
            (entity) => entity.id === collectionId
        )?.[0].actions?.[type];
        if (action) {
            const typeAssertedAction = { ...(action as IPreviewAction) };
            handleAction(typeAssertedAction, type, params);
        }
    };

    const handleClick = () => {
        dispatch(handleExpandReportUpdate(report.label));
        setOpen(!open);
    };

    const openDeletionDialog = (id: string, message?: string) => {
        setIsDeletionDialogOpen(true);
        setIdToDelete(id);
        setDeleteMessage(message);
    };

    const closeDeletionDialog = () => {
        setIsDeletionDialogOpen(false);
        setIdToDelete(null);
    };

    const handleDeleteAction = () => {
        const deleteAction = report?._meta?.actions?.delete;

        if (!deleteAction) {
            return;
        }
        const action = {
            ...deleteAction,
            params: { ...deleteAction.params, id: idToDelete || '' },
        };

        dispatch(customAction(action, undefined, afterItemDelete));
    };
    return (
        <List component="nav" disablePadding={true} className={classes.list}>
            <ReportList
                open={open}
                handleClick={handleClick}
                classes={classes}
                report={report}
                handleReportAction={handleReportAction}
                handleEntityAction={handleEntityAction}
                handleOpenSourceSetClick={handleOpenSourceSetClick}
                handleOpenChartClick={handleOpenChartClick}
                handleOpenGridChartClick={handleOpenGridChartClick}
                openDeletionDialog={openDeletionDialog}
            />

            {hasDeleteAction && (
                <DeletionDialog
                    isOpen={isDeletionDialogOpen}
                    close={closeDeletionDialog}
                    confirm={() => {
                        handleDeleteAction();
                        closeDeletionDialog();
                    }}
                    title="Removal confirmation"
                    message={deleteMessage || 'Are you sure?'}
                />
            )}
        </List>
    );
};

export default compose(Themable)(Report) as ComponentType<IOwnProps>;
