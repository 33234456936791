import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        fieldMulti: {
            display: 'flex',
            marginTop: theme.spacing(),
            marginBottom: theme.spacing(2),
            paddingBottom: theme.spacing(),
            width: '740px',
        },
    })
);
