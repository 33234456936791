import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        typography: {
            margin: `${theme.spacing()}px 0px`,
        },
        noItems: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            height: '244px',
        },
        list: {
            width: '100%',
            backgroundColor: theme.palette.background.paper,
            overflow: 'auto',
        },
        listItemText: {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
    })
);
