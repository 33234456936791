import {
    makeGetRequestSettings,
    makeRestReadCollection,
} from '../../common/utils';
import apiClient from '../../common/apiClient';
import { makeCustomerServiceUrl } from '..';
import {
    IContractItem,
    IServiceContractLocationIdsParams,
    IServiceContractsUrlParams,
} from '../../../models/customerService';

export const restGetContractItems = (
    params: IServiceContractsUrlParams
): Promise<IContractItem[]> =>
    apiClient.send(
        makeGetRequestSettings(
            makeCustomerServiceUrl('/contract-items', params)
        )
    );

export const restGetLocationIds = (
    params: IServiceContractLocationIdsParams
): Promise<number[]> =>
    apiClient.send(
        makeGetRequestSettings(
            makeCustomerServiceUrl('/contract-items/location-ids', params)
        )
    );

export const restGetContractItem = (
    id: number,
    cycleId: number | undefined
): Promise<IContractItem> =>
    apiClient.send(
        makeGetRequestSettings(
            makeCustomerServiceUrl(`/contract-items/${id}`, { cycleId })
        )
    );

export const restGetContractItemRfids = (
    locationIds: string[]
): Promise<string[]> => {
    return apiClient.send(
        makeGetRequestSettings(
            makeCustomerServiceUrl('/contract-items/rfid-ids', {
                locationIds: locationIds.join(','),
            })
        )
    );
};

export const restReadContractItemsCollection =
    makeRestReadCollection(restGetContractItems);
