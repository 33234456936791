import React, { useEffect, useState } from 'react';

import { Divider, LinearProgress, Paper } from '@material-ui/core';
import { default as PlusIcon } from '@material-ui/icons/Add';
import MapIcon from '@mui/icons-material/Map';

import { GridApi, SelectionChangedEvent } from 'ag-grid-community';

import {
    closeBottomGrid,
    DEFAULT_BOTTOM_GRID,
    selectBottomGrid,
} from '../../../../state/ui/customerService/bottomGrid';
import {
    enterCreateMode,
    selectClientsAndLocations,
    resetMode as resetClientAndLocationMode,
    toggleCollapseLocations,
    toggleMap,
    CLIENTS_AND_LOCATIONS_GRID_ID,
    fetchLocationIdsByRfid,
} from '../../../../state/ui/customerService/clientsAndLocations';
import { useBottomGrid } from '../../../../state/ui/customerService/bottomGrid/index.hooks';
import {
    enterPreviewMode,
    fetchClients,
    fetchLocations,
    selectClient,
    selectGridItemId,
    selectLocation,
    resetValidRfidFilter,
    setValidRfidFilter,
} from '../../../../state/ui/customerService/clientsAndLocations';
import {
    useClientsAndLocationsStatus,
    useClientsSourceSetModel,
    useClientsAndLocations,
    useValidRfidFilter,
    useMap,
    useSelectedClientsAndLocations,
} from '../../../../state/ui/customerService/clientsAndLocations/index.hooks';
import { ISourceSet, ISourceSetEntity } from '../../../../state/types';
import { resetMode } from '../../../../state/ui/customerService/bottomGrid';
import { selectContractItem } from '../../../../state/ui/customerService/bottomGrid/contractItems';
import { selectTask } from '../../../../state/ui/customerService/bottomGrid/tasks';
import { usePrivileges } from '../../../../state/auth/index.hooks';

import { useToolkitDispatch } from '../../../../hooks';

import TranslationHelper from '../../../../helpers/TranslationHelper';

import { useStyles } from '../../Themable.hooks';

import PaneHeader from '../../../../components/PaneHeader';
import SourceSetGrid from '../../../../components/SourceSetGrid';
import RfidSearchField from '../../../../components/common/RfidSearchField/RfidSearchField';
import SearchField from '../../../../components/common/SearchField/SearchField';
import IconButtonWithTooltip from '../../../../components/IconButtonWithTooltip';
import CscExportButtons from '../CscExportButtons/CscExportButtons';
import { useSnackbar } from 'notistack';
import { handleQueryAction } from '../../../../helpers/handleQueryAction';
import { handleGridNodeSelection } from '../../../../helpers/handleGridNodeSelection';
import { useDebounceEffect } from '../../../../helpers/hooks';
import { DEBOUNCE_MID } from '../../../../constants/DebounceValues';
import useAsyncThunkWithSnackbar from '../../../../hooks/useAsyncThunkWithSnackbar';
import isEqual from 'lodash.isequal';
import {
    IFeatureSelectedOnGridParams,
    TVectorGeometrySource,
} from '../../types';
import { zoomAfterSelect } from '../../helpers/zoomAfterSelect';

const CHECKBOX_POSITION = 0;

interface IOwnProps {
    selectedItems: { [key: string]: string[] };
    onFeatureSelected: (params: IFeatureSelectedOnGridParams) => void;
    source: TVectorGeometrySource;
    lastSelectedItem: string;
    handleSelectLastItem: (sourceSetId: string, lastItemId: string) => void;
}

const ClientsAndLocationsGrid = ({
    selectedItems,
    onFeatureSelected,
    source,
    lastSelectedItem,
    handleSelectLastItem,
}: IOwnProps) => {
    const { enqueueSnackbar } = useSnackbar();

    const sourceSetModel = useClientsSourceSetModel();

    const [filter, setFilter] = useState(sourceSetModel?.quickFilter || '');
    const [localSelected, setLocalSelected] = useState({});
    const validRfidFilter = useValidRfidFilter() || '';
    const [gridApi, setGridApi] = useState<GridApi | undefined>(undefined);
    const status = useClientsAndLocationsStatus();
    const classes = useStyles({});
    const clientsAndLocations = useClientsAndLocations();
    const bottomGrid = useBottomGrid();
    const map = useMap();
    const privileges = usePrivileges();
    const selectedClientsAndLocations = useSelectedClientsAndLocations();
    const toolkitDispatch = useToolkitDispatch();
    useEffect(() => {
        if (!clientsAndLocations) {
            toolkitDispatch(fetchClients());
            toolkitDispatch(fetchLocations());
        }
    }, []);

    const handleAsyncRequest = useAsyncThunkWithSnackbar();

    useDebounceEffect(
        () => {
            if (validRfidFilter) {
                handleAsyncRequest({
                    asyncAction: fetchLocationIdsByRfid(validRfidFilter),
                });
            } else {
                toolkitDispatch(resetValidRfidFilter());
            }
        },
        [validRfidFilter],
        DEBOUNCE_MID
    );

    useEffect(() => {
        if (
            !bottomGrid &&
            selectedClientsAndLocations.some((item) => item.id)
        ) {
            toolkitDispatch(selectBottomGrid(DEFAULT_BOTTOM_GRID));
        }
    }, [selectedClientsAndLocations]);

    useEffect(() => {
        if (gridApi && clientsAndLocations) {
            if (
                !handleQueryAction('selectLocation', (urlParams) => {
                    const location = clientsAndLocations.entities.find(
                        (entity) => entity.locationId === urlParams.locationId
                    );
                    handleLocationSelect(gridApi, location);
                })
            ) {
                setTimeout(
                    () =>
                        selectRowOnlyIfOneResultAndRfidFilterIsPresent(
                            gridApi,
                            clientsAndLocations
                        ),
                    200
                );
            }
        }
    }, [clientsAndLocations, gridApi]);

    const selectRowOnlyIfOneResultAndRfidFilterIsPresent = (
        gridApi: GridApi,
        clientsAndLocations: ISourceSet
    ) => {
        if (clientsAndLocations.entities.length === 1 && validRfidFilter) {
            handleLocationSelect(gridApi, clientsAndLocations.entities[0]);
        }
    };

    const handleLocationSelect = (
        api: GridApi,
        location: ISourceSetEntity | undefined
    ) => {
        if (location?.locationId) {
            handleGridNodeSelection(
                api,
                location.locationId,
                'locationId',
                locationNotFoundSnackbarError
            );
            handleCellInteraction(location);
            handleToggleCollapseLocationsSection();
        } else {
            locationNotFoundSnackbarError();
        }
    };

    const locationNotFoundSnackbarError = () => {
        enqueueSnackbar(
            TranslationHelper.translate(
                'Location with provided id does not exist'
            ),
            { variant: 'error' }
        );
    };

    const handleCreateClient = () => {
        resetBottomGridSelection();
        toolkitDispatch(enterCreateMode());
    };

    const resetBottomGridSelection = () => {
        toolkitDispatch(resetMode());
        toolkitDispatch(selectContractItem(null));
        toolkitDispatch(selectTask(null));
    };
    const resetMultiSelection = () => {
        onFeatureSelected({
            source,
            sourceSetId: CLIENTS_AND_LOCATIONS_GRID_ID,
            items: [],
        });
        setLocalSelected([]);
        toolkitDispatch(closeBottomGrid());
        resetBottomGridSelection();
        toolkitDispatch(resetClientAndLocationMode());
        toolkitDispatch(selectClientsAndLocations([]));
    };
    const handleToggleMap = () => {
        toolkitDispatch(toggleMap());
    };

    const onSelectionChanged = (e: SelectionChangedEvent) => {
        const selectedNodes = e.api
            .getSelectedNodes()
            .map((selected) => {
                return {
                    id: selected.data.externalId,
                    name: selected.data.locationName,
                    clientId: selected.data.clientId,
                    clientName: selected.data.name,
                    sourceSetId: selected.data.id,
                    locationId: selected.data.locationId,
                    coordinates: selected.data.coordinate,
                    radius: selected.data.radius,
                };
            })
            .filter(Boolean);
        if (!selectedNodes.length) {
            resetMultiSelection();

            return;
        }
        if (!isEqual(localSelected, selectedNodes)) {
            setLocalSelected(selectedNodes);

            onFeatureSelected({
                source,
                sourceSetId: CLIENTS_AND_LOCATIONS_GRID_ID,
                items: selectedNodes.map((node) => node.sourceSetId),
            });
            toolkitDispatch(selectClientsAndLocations(selectedNodes));
        }
    };

    const handleCellInteraction = (data: ISourceSetEntity) => {
        if (lastSelectedItem === data.id) {
            toolkitDispatch(
                selectBottomGrid(bottomGrid || DEFAULT_BOTTOM_GRID)
            );
        }
        zoomAfterSelect(source, map, data.id);
        handleSelectLastItem(CLIENTS_AND_LOCATIONS_GRID_ID, data.id);
        resetBottomGridSelection();
        toolkitDispatch(enterPreviewMode());
        toolkitDispatch(selectClient(data.clientId));
        toolkitDispatch(
            selectLocation({ externalId: data.externalId, id: data.locationId })
        );
        toolkitDispatch(selectGridItemId(data.id));
    };
    const handleSetApiGrid = (
        gridApi: any,
        gridColumnApi: any | undefined = undefined
    ) => {
        setGridApi(gridApi);
    };

    const handleToggleCollapseLocationsSection = () => {
        toolkitDispatch(toggleCollapseLocations());
    };

    const multiSelectProps = {
        rowSelection: 'multiple',
        checkBox: { index: CHECKBOX_POSITION },
        onSelectionChanged,
        items: selectedItems[CLIENTS_AND_LOCATIONS_GRID_ID].map((item) => {
            return { sourceSetId: item };
        }),
    };

    const renderRightCustomControls = () => {
        const renderMapButton = (
            <IconButtonWithTooltip
                title={TranslationHelper.translate('Map')}
                onClick={handleToggleMap}
                size="small"
            >
                <MapIcon />
            </IconButtonWithTooltip>
        );
        return (
            <>
                <RfidSearchField
                    value={validRfidFilter}
                    filterChange={(value) => {
                        if (value.length === 0) {
                            toolkitDispatch(resetValidRfidFilter());
                        } else {
                            toolkitDispatch(setValidRfidFilter(value));
                        }
                    }}
                    resetSearch={() => {
                        toolkitDispatch(resetValidRfidFilter());
                    }}
                />
                {renderMapButton}
                {privileges.manageCustomerServiceCentre && (
                    <>
                        <CscExportButtons
                            api={gridApi}
                            prefix="clientsAndLocations"
                        />
                        <IconButtonWithTooltip
                            title={TranslationHelper.translate('Add client')}
                            onClick={handleCreateClient}
                        >
                            <PlusIcon />
                        </IconButtonWithTooltip>
                    </>
                )}
            </>
        );
    };

    return (
        <Paper className={classes.gridPane}>
            <div className={classes.wrapper}>
                <PaneHeader
                    title={TranslationHelper.translate('Clients and locations')}
                    renderRightCustomControls={() =>
                        renderRightCustomControls()
                    }
                    renderLeftCustomControls={() => (
                        <>
                            <SearchField
                                value={filter}
                                filterChange={setFilter}
                            />
                        </>
                    )}
                />
                {!clientsAndLocations || status === 'loading' ? (
                    <LinearProgress />
                ) : (
                    <Divider />
                )}
                {clientsAndLocations && (
                    <SourceSetGrid
                        sourceSet={clientsAndLocations}
                        cellInteractionHandler={handleCellInteraction}
                        setGridApi={handleSetApiGrid}
                        quickFilterValue={filter}
                        sourceSetModel={sourceSetModel}
                        selectedSourceSetElementId={lastSelectedItem || null}
                        multiSelectProps={multiSelectProps}
                    />
                )}
            </div>
        </Paper>
    );
};

export default ClientsAndLocationsGrid;
