import React from 'react';
import { string } from 'prop-types';

import CircularProgress from '@material-ui/core/CircularProgress';

import TranslationHelper from '../../helpers/TranslationHelper';

class LoadingSpinner extends React.Component {
    constructor(props) {
        super(props);

        this.state = this._setStyle();

        this._setStyle = this._setStyle.bind(this);
    }

    UNSAFE_componentWillReceiveProps() {
        this.setState(this._setStyle());
    }

    _setStyle() {
        let opacity = this.props.opacity || 0.7;

        return {
            overlayStyle: {
                top: this.props.top + 'px',
                bottom: this.props.bottom + 'px',
                left: this.props.left + 'px',
                right: this.props.right + 'px',
                backgroundColor: 'rgba(255, 255, 255, ' + opacity + ')',
                zIndex: this.props.zIndex,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexFlow: 'column',
            },
            spinnerStyle: {
                width: this.props.size + 'px',
                height: this.props.size + 'px',
            },
        };
    }

    render() {
        return (
            <div className="sk-overlay" style={this.state.overlayStyle}>
                <CircularProgress
                    color={'primary'}
                    size={this.props.progressSize || '4em'}
                />
                {this.props.message && (
                    <div>
                        {' '}
                        {TranslationHelper.translate(this.props.message)}{' '}
                    </div>
                )}
            </div>
        );
    }
}

LoadingSpinner.propTypes = {
    progressSize: string,
    size: string,
    top: string,
    right: string,
    bottom: string,
    left: string,
    backgroundColor: string,
    opacity: string,
    zIndex: string,
    message: string,
};

export default LoadingSpinner;
