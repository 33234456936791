import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { CreateCSSProperties } from '@material-ui/core/styles/withStyles';

export const useStyles = makeStyles((theme: Theme) => {
    const wrapperStyle: CreateCSSProperties<{}> = {
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
    };

    return createStyles({
        pageWrapper: {
            ...wrapperStyle,
        },
        visiblePreviewPane: {
            ...wrapperStyle,
            right: 500 + 10,
        },
        hiddenPreviewPane: {
            ...wrapperStyle,
        },
        paneWrapper: {
            position: 'absolute',
            top: 0,
            left: 'calc(100% - 500px)',
            width: 500,
            maxHeight: '100%',
            right: 0,
            overflowY: 'auto',
            overflowX: 'hidden',
            display: 'flex',
            height: '100%',
        },
    });
});
