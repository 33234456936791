import { connect } from 'react-redux';
import { TRootState } from '../../../../store';
import { logout } from '../../../../state/_actions/logoutAction';

const mapStateToProps = (state: TRootState) => ({});

const mapDispatchToProps = {
    logout,
};

type TStateProps = ReturnType<typeof mapStateToProps>;
type TDispatchProps = typeof mapDispatchToProps;

export type TConnectableProps = TStateProps & TDispatchProps;

export const Connectable = connect(mapStateToProps, mapDispatchToProps);
