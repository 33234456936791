import React, { useEffect } from 'react';

import { Formik } from 'formik';

import { useSnackbar } from 'notistack';

import { default as EditIcon } from '@material-ui/icons/Edit';
import { LinearProgress } from '@mui/material';
import {
    enterRouteEditMode,
    fetchRoute,
    resetRouteMode,
} from '../../../../../state/ui/planning/routes';
import {
    useSelectedRoute,
    useSelectedRouteId,
} from '../../../../../state/ui/planning/routes/index.hooks';

import TranslationHelper from '../../../../../helpers/TranslationHelper';
import { useToolkitDispatch } from '../../../../../hooks';
import useAsyncThunkWithSnackbar from '../../../../../hooks/useAsyncThunkWithSnackbar';

import PaneHeader from '../../../../../components/PaneHeader/PaneHeader';
import IconButtonWithTooltip from '../../../../../components/IconButtonWithTooltip';
import RoutesForm from './RoutesForm';
import { getFormFields } from './utils';
import { usePrivileges } from '../../../../../state/auth/index.hooks';

const RoutesPreviewPane = () => {
    const toolkitDispatch = useToolkitDispatch();

    const selectedRoute = useSelectedRoute();
    const selectedRouteId = useSelectedRouteId();
    const handleAsyncRequest = useAsyncThunkWithSnackbar();
    const { enqueueSnackbar } = useSnackbar();
    const privileges = usePrivileges();
    const handleClose = () => {
        toolkitDispatch(resetRouteMode());
    };
    useEffect(() => {
        if (selectedRouteId) {
            handleAsyncRequest({
                asyncAction: fetchRoute(selectedRouteId),
                onError: {
                    callback: () => handleClose(),
                },
            });
        }
    }, [selectedRouteId]);

    if (selectedRoute.status === 'idle') {
        return <></>;
    }
    if (selectedRoute.status === 'loading') {
        return <LinearProgress />;
    }
    if (!selectedRoute.data) {
        enqueueSnackbar('Route could not be displayed. Please try again', {
            variant: 'error',
        });
        handleClose();
        return <></>;
    }
    const {
        name,
        plannedOn,
        status,
        progress,
        vehicle,
        employee,
        activityCategoryId,
        realStart,
        realStop,
        nodePath,
    } = selectedRoute.data;
    const initialValues = {
        name,
        plannedOn,
        status,
        progress,
        realStart,
        realStop,
        activityCategory: activityCategoryId,
        vehicle: { label: vehicle?.name, id: vehicle?.id },
        employee: { label: employee?.name, id: employee?.id },
        nodePath,
    };

    const handleEditForm = () => {
        toolkitDispatch(enterRouteEditMode());
    };

    const getRightCustomControls = () => (
        <>
            {privileges.editRoute && (
                <IconButtonWithTooltip
                    title={TranslationHelper.translate('Edit route')}
                    onClick={handleEditForm}
                >
                    <EditIcon />
                </IconButtonWithTooltip>
            )}
        </>
    );

    const handleValues = () => {};

    const fields = getFormFields(true);

    return (
        <div>
            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                onSubmit={handleValues}
                validateOnBlur={false}
                validateOnChange={false}
            >
                {({ errors, values, setFieldValue }) => (
                    <>
                        <PaneHeader
                            onCloseClick={handleClose}
                            title={TranslationHelper.translate('Route details')}
                            renderRightCustomControls={() =>
                                getRightCustomControls()
                            }
                        />

                        <RoutesForm
                            values={values}
                            errors={errors}
                            setFieldValue={setFieldValue}
                            fields={fields}
                        />
                    </>
                )}
            </Formik>
        </div>
    );
};

export default RoutesPreviewPane;
