import React, { Component } from 'react';
import { func, string, bool } from 'prop-types';

import Button from './Button';

class SecondaryButton extends Component {
    constructor(props, state) {
        super(props, state);
    }

    render() {
        return (
            <Button
                id={this.props.id}
                onClick={this.props.onClick}
                className="secondary"
                icon={this.props.icon}
                label={this.props.label}
                disabled={this.props.disabled}
            />
        );
    }
}

SecondaryButton.propTypes = {
    onClick: func,
    id: string,
    label: string,
    icon: string,
    disabled: bool,
};

export default SecondaryButton;
