import React, { Component, ComponentType } from 'react';
import { compose } from 'redux';
import TranslationHelper from '../../../helpers/TranslationHelper';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import Button from '@material-ui/core/Button';

import Slide, { SlideProps } from '@material-ui/core/Slide';

import { TransitionProps } from '@material-ui/core/transitions';

import { Connectable, TConnectableProps } from './Connectable.hoc';
import { Themable, TThemableProps } from './Themable.hoc';

interface IOwnProps {
    isOpen: boolean;
    close: () => void;
    confirm: () => void;
    title: string;
    message: string;
}
type TProps = IOwnProps & TThemableProps & TConnectableProps;

const TransitionComponent = React.forwardRef<unknown, TransitionProps>(
    function Transition(props, ref) {
        return <Slide direction="up" ref={ref} {...(props as SlideProps)} />;
    }
);

class DeletionDialog extends Component<TProps> {
    public handleClose = (event?: object, reason?: string) => {
        const { close } = this.props;
        if (reason === 'backdropClick') {
            return;
        }

        close();
    };
    public render() {
        const { close, isOpen, confirm, title, message, classes } = this.props;
        return (
            <Dialog
                open={isOpen}
                TransitionComponent={TransitionComponent}
                keepMounted={true}
                onClose={this.handleClose}
            >
                <DialogTitle>{TranslationHelper.translate(title)}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {TranslationHelper.translate(message)}
                    </DialogContentText>
                </DialogContent>
                <DialogActions className={classes.actions}>
                    <Button onClick={close} color="primary" variant="contained">
                        {TranslationHelper.translate('No, keep it')}
                    </Button>
                    <Button
                        onClick={confirm}
                        classes={{
                            containedSecondary: classes.containedSecondary,
                        }}
                        color="secondary"
                        variant="contained"
                    >
                        {TranslationHelper.translate(
                            'Yes, I want to DELETE it'
                        )}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default compose(
    Themable,
    Connectable
)(DeletionDialog) as ComponentType<IOwnProps>;
