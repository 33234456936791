import { IBookmark } from '../../../app/state/types';

const bookmarkedReports = 'bookmarkedReports';

const parseBookmarks = () =>
    JSON.parse(localStorage.getItem(bookmarkedReports) || '[]');
const setBookmarks = (bookmarks: IBookmark[]) =>
    localStorage.setItem(bookmarkedReports, JSON.stringify(bookmarks));

export const clearBookmarks = () => localStorage.removeItem(bookmarkedReports);

export const fetchBookmarks = () =>
    new Promise<IBookmark[]>((resolve) => resolve(parseBookmarks()));

export const saveBookmarkToLocalStorage = (newBookmark: IBookmark) =>
    new Promise<void>((resolve) => {
        const bookmarks = parseBookmarks();
        if (bookmarks.some((entity: any) => entity.id === newBookmark.id)) {
            return resolve();
        }
        bookmarks.unshift(newBookmark);
        return resolve(setBookmarks(bookmarks));
    });

export const deleteBookmarkInLocalStorage = (bookmarkId: string) =>
    new Promise<void>((resolve) => {
        let bookmarks = parseBookmarks();
        bookmarks = bookmarks.filter(
            (bookmark: IBookmark) => bookmarkId !== bookmark.id
        );
        return resolve(setBookmarks(bookmarks));
    });
