import { makeReduxDuck } from 'teedux';

import { makeRequest } from '../../app/sync';

import { TRootState } from '../../../store';
import { restGetResources } from '../../../services/resources';

export interface IResource {
    formats: string[];
    id: number;
    languages: string[];
    name: string;
}
interface IState {
    resources: IResource[];
}

const initialState: IState = {
    resources: [],
};

const resourcesStorePath = 'app/ui/resurces';

const duck = makeReduxDuck(resourcesStorePath, initialState);

export const setResourcesAction = duck.defineAction<{
    resources: IResource[];
}>('SET_RESOURCES', (_, { resources }) => ({
    resources,
}));

const makeAlertsStorePath = () => `get:${resourcesStorePath}`;

export const fetchResources = (
    afterSuccess?: (data: IResource[]) => void,
    afterFail?: () => void
) =>
    makeRequest(
        makeAlertsStorePath(),
        () => restGetResources(),
        (dispatch, data) => {
            dispatch(setResourcesAction({ resources: data }));
            if (afterSuccess) {
                afterSuccess(data);
            }
        },
        (dispatch, error) => {
            if (afterFail) {
                afterFail();
            }
        }
    );

export const getResources = (state: TRootState) => {
    return state.ui.resources.resources;
};
export default duck.getReducer();
