import React from 'react';

import Moment from 'moment-timezone';

import { Divider, IconButton, ListItem } from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import Tooltip from '@material-ui/core/Tooltip';

import { useStyles } from '../Themable.hooks';
import TranslationHelper from '../../../../../../../../../helpers/TranslationHelper';
import { useSelectedDevice } from '../../../../../../../../../state/ui/devices/index.hooks';
import { useDateFormat } from '../../../../../../../../../state/user/index.hooks';

interface IOwnProps {
    item: {
        label: string;
        id: string;
        copy: boolean;
    };
    copyTooltip: {
        [id: string]: string;
    };
    setCopyTooltip: (value: { [id: string]: string }) => void;
}
const DeviceListItem = ({ item, copyTooltip, setCopyTooltip }: IOwnProps) => {
    const classes = useStyles();
    const device = useSelectedDevice();
    const dateFormat = useDateFormat();

    const deviceItem = device?.integrations?.eToll;

    const copyToClipboard = (value: string) => {
        navigator.clipboard.writeText(value);
    };

    const copyLink = (value: string, id: string) => {
        setCopyTooltip({
            [id]: TranslationHelper.translate('Copied!'),
        });
        copyToClipboard(value);
    };

    const prepareValue = (id: string) => {
        const result =
            device?.[id] ||
            device?.attributes?.[id] ||
            deviceItem?.[id] ||
            null;
        switch (id) {
            case 'eToll':
                return device?.eTollTranslated;
            case 'registeredAt':
                return deviceItem?.[id]
                    ? Moment(result).format(dateFormat)
                    : null;
            case 'status':
                return deviceItem?.[id] || null;
            default:
                return TranslationHelper.translate(result);
        }
    };

    if (!prepareValue(item.id)) {
        return null;
    }

    return (
        <>
            <ListItem className={classes.listItem}>
                <div className={classes.itemLabel}>
                    {TranslationHelper.translate(item.label)}
                </div>
                <div className={classes.itemValue}>{prepareValue(item.id)}</div>
                <div className={classes.copyIcon}>
                    {item.copy ? (
                        <Tooltip
                            title={
                                copyTooltip[item.id] ||
                                TranslationHelper.translate('Copy')
                            }
                            placement="left"
                        >
                            <span>
                                <IconButton
                                    size="small"
                                    disabled={!deviceItem?.[item.id]}
                                    className={classes.iconButton}
                                    onClick={() =>
                                        copyLink(deviceItem?.[item.id], item.id)
                                    }
                                >
                                    <FileCopyIcon fontSize="small" />
                                </IconButton>
                            </span>
                        </Tooltip>
                    ) : null}
                </div>
            </ListItem>
            <Divider />
        </>
    );
};

export default DeviceListItem;
