import createStyles from '@material-ui/core/styles/createStyles';

import { Theme, withStyles, WithStyles } from '@material-ui/core/styles';

const styles = (theme: Theme) => {
    return createStyles({
        listItem: {
            display: 'flex',
            justifyContent: 'space-between',
            zIndex: 'inherit',
        },
        event: {
            textAlign: 'right',
            fontSize: '13px',
        },
        eventName: {
            fontWeight: 'bold',
        },
    });
};

export type TThemableProps = WithStyles<typeof styles>;

export const Themable = withStyles(styles, { withTheme: true });
