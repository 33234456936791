import React from 'react';

import { Paper } from '@material-ui/core';

import UserPane from './components/UserPane';
import UsersGridPane from './components/UsersGridPane';

import { useStyles } from './Themable.hooks';

import { useUserPaneIsVisible } from './selectors/index.hooks';

const UsersPage = () => {
    const userPaneIsVisible = useUserPaneIsVisible();
    const classes = useStyles();
    const userPaneClass = userPaneIsVisible
        ? classes.visibleUserPane
        : classes.hiddenUserPane;

    return (
        <div className={classes.usersPageWrapper} id="usersPage">
            <div className={userPaneClass}>
                <UsersGridPane />
            </div>

            {userPaneIsVisible && (
                <Paper className={classes.userPaneWrapper}>
                    <UserPane />
                </Paper>
            )}
        </div>
    );
};

export default UsersPage;
