import * as yup from 'yup';
import { memoize } from 'lodash';

import TranslationHelper from '../helpers/TranslationHelper';
import { NAME_STRING_MAX_LENGTH, REQUIRED } from '../constants/yup';

export const attributesSchema = memoize(() =>
    yup.object().shape({
        name: yup
            .string()
            .trim()
            .required(TranslationHelper.translate(REQUIRED))
            .max(
                NAME_STRING_MAX_LENGTH,
                TranslationHelper.translate('Value in this field is too long')
            ),
        label: yup
            .string()
            .trim()
            .required(TranslationHelper.translate(REQUIRED))
            .max(
                NAME_STRING_MAX_LENGTH,
                TranslationHelper.translate('Value in this field is too long')
            ),
        regex: yup
            .string()
            .trim()
            .max(
                NAME_STRING_MAX_LENGTH,
                TranslationHelper.translate('Value in this field is too long')
            ),
        type: yup.number().required(TranslationHelper.translate(REQUIRED)),
        dictionary: yup
            .array()
            .when('lockedToDictionary', (lockedToDictionary: boolean): any => {
                return lockedToDictionary
                    ? yup.array().min(1, TranslationHelper.translate(REQUIRED))
                    : undefined;
            }),
    })
);
