import React, { Component, ComponentType } from 'react';

import { compose } from 'redux';
import { Connectable, TConnectableProps } from './Connectable.hoc';
import { Themable, TThemableProps } from './Themable.hoc';

import FullScreenSpinner from '../FullScreenSpinner/FullScreenSpinner';

interface IOwnProps {
    isStateRestoring: boolean;
    shouldRestorerWait?: boolean;
    restorerHandler(): void;
}

type TProps = IOwnProps & TThemableProps & TConnectableProps;

class StateRestorer extends Component<TProps> {
    public componentDidUpdate() {
        setTimeout(() => {
            if (this.props.isStateRestoring && !this.props.shouldRestorerWait) {
                this.props.restorerHandler();
            }
        });
    }

    public render() {
        const { children, isStateRestoring } = this.props;

        return (
            <React.Fragment>
                {isStateRestoring && (
                    <FullScreenSpinner
                        opacity={'1'}
                        message={'Restoring application state'}
                    />
                )}
                {children}
            </React.Fragment>
        );
    }
}

export default compose(
    Themable,
    Connectable
)(StateRestorer) as ComponentType<IOwnProps>;
