import React from 'react';
import ServiceCenter from '../../components/serviceCenter/ServiceCenter';

class ServiceCenterPage extends React.Component {
    render() {
        return <ServiceCenter />;
    }
}

export default ServiceCenterPage;
