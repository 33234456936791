import React, { ReactChild } from 'react';

import { Field, FormikErrors, FormikValues } from 'formik';

import { MenuItem } from '@material-ui/core';

import TranslationHelper from '../../helpers/TranslationHelper';
import FieldWrapper from '../common/formikWrappers/FieldWrapper';

interface ISelectTypes {
    id: string;
    name: string;
    hidden?: boolean;
}

interface IOwnProps<T> {
    types: {
        id: string;
        name: string;
        render?: (
            values: FormikValues,
            setFieldValue: (name: string, value: any) => void,
            errors?: FormikErrors<T>,
            disabled?: boolean
        ) => ReactChild;
    }[];
    selectValue: string;
    values: FormikValues;
    setFieldValue: (name: string, value: any) => void;
    errors?: FormikErrors<T>;
    name: string;
    label: string;
    disabled?: boolean;
}
const renderSelectValues = (types: ISelectTypes[]) => {
    return types.map((selectType) => {
        return selectType.hidden ? null : (
            <MenuItem key={selectType.id} value={selectType.id}>
                {TranslationHelper.translate(selectType.name)}
            </MenuItem>
        );
    });
};

const SelectWrapper = <T,>({
    types,
    name,
    label,
    selectValue,
    values,
    setFieldValue,
    errors,
    disabled,
}: IOwnProps<T>) => {
    const renderFn = types.filter((type) => type.id === selectValue)[0]?.render;

    return (
        <>
            <Field
                fullWidth={true}
                name={name}
                label={TranslationHelper.translate(label)}
                select={true}
                component={FieldWrapper}
                required={true}
                disabled={disabled}
            >
                {renderSelectValues(types)}
            </Field>
            {renderFn
                ? renderFn(values, setFieldValue, errors, disabled)
                : null}
        </>
    );
};
export default SelectWrapper;
