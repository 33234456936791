import { createStyles, makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() =>
    createStyles({
        dialogContent: {
            display: 'flex',
            minWidth: '300px',
            minHeight: '300px',
        },
        card: {
            display: 'flex',
            flexDirection: 'column',
        },
        spinner: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
        },
        cardLoading: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '100px',
        },
        cardConent: {
            display: 'flex',
            flexDirection: 'column',
        },
        condition: {
            display: 'flex',
            flexDirection: 'row',
        },
        setupField: {
            marginBottom: '18px',
            width: '100%',
        },
        setupFields: {
            display: 'block',
            marginBottom: '12px',
        },
        addButton: {
            margin: '6px',
            width: '200px',
        },
        toggleButtons: {
            margin: '0 0 12px 6px',
        },
        selected: {
            fontWeight: 'bold',
            border: '1px solid black',
        },
        closeButton: {
            position: 'relative',
            '&:hover': {
                backgroundColor: 'transparent',
            },
            '&:hover::after': {
                content: '""',
                position: 'absolute',
                width: '20px',
                height: '20px',
                borderRadius: '50%',
                backgroundColor: 'rgba(0, 0, 0, 0.08)',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
            },
            '&:hover::before': {
                content: '""',
                position: 'absolute',
                left: 0,
                top: 0,
                bottom: 0,
                width: '2px',
                backgroundColor: 'red',
            },
        },
    })
);
