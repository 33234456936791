import { connect, ConnectedProps } from 'react-redux';

import { TRootState } from '../../../../store';

import {
    fetchParamsDefinitions,
    fetchAlertDefinitions,
    setChosenParams,
    getAlertDefinitions,
    getAlertsParamsDefinitions,
    getChosenParams,
} from '../../../../state/app/alerts';

const mapStateToProps = (state: TRootState) => ({
    definitions: getAlertDefinitions(state),
    paramsAvailable: getAlertsParamsDefinitions(state),
    chosenParams: getChosenParams(state),
});

const mapDispatchToProps = {
    fetchAlertDefinitions,
    fetchParamsDefinitions,
    setChosenParams,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type TConnectableProps = ConnectedProps<typeof connector>;

export const Connectable = connector;
