import { useSelector } from 'react-redux';

import {
    getFuelTankEvents,
    getFuelTankOrdinaryEvents,
    getFuelTanks,
} from './index';

export const useFuelTanks = () => useSelector(getFuelTanks);

export const useFuelTankEvents = () => useSelector(getFuelTankEvents);

export const useFuelTankOrdinaryEvents = () =>
    useSelector(getFuelTankOrdinaryEvents);
