import { connect } from 'react-redux';
import {
    closePreview,
    fetchFrameDetails,
    fetchRecognizedNumbersSection,
    resetRecognizedNumbersSection,
} from '../../../../../../state/_actions';
import { TRootState } from '../../../../../../store';
import {
    getDiscoveryUIPreviewPane,
    getPreviewCreatorLevel,
} from '../../../../selectors';
import { isRecognizedNumbersBeingFetchted } from '../../../../selectors/feching';
import { getFramesSourceSetElement } from '../../../../selectors/composed';
import { getPrivileges } from '../../../../../../state/auth';

const mapStateToProps = (state: TRootState) => ({
    sourceSetElementReportSet: getFramesSourceSetElement(state),
    isRecognizedNumbersBeingFetched: isRecognizedNumbersBeingFetchted(state),
    creatorLevel: getPreviewCreatorLevel(state),
    preview: getDiscoveryUIPreviewPane(state),
    privileges: getPrivileges(state),
});

const mapDispatchToProps = {
    fetchFrameDetails,
    fetchRecognizedNumbersSection,
    resetRecognizedNumbersSection,
    closePreview,
};

type TStateProps = ReturnType<typeof mapStateToProps>;
type TDispatchProps = typeof mapDispatchToProps;

export type TConnectableProps = TStateProps & TDispatchProps;

export const Connectable = connect(mapStateToProps, mapDispatchToProps);
