import { connect } from 'react-redux';
import { isDiscoverUIGridFullscreen } from '../../pages/discovery/selectors';
import { storeSourceSetModel } from '../../state/_actions';
import { getLayersAttributes } from '../../state/ui/discovery/general';
import { getUserSettings } from '../../state/user';
import { TRootState } from '../../store';

const mapStateToProps = (state: TRootState) => ({
    userSettings: getUserSettings(state),
    layersAttributes: getLayersAttributes(state),
    isGridFullscreen: isDiscoverUIGridFullscreen(state),
});

const mapDispatchToProps = {
    storeSourceSetModel,
};

type TStateProps = ReturnType<typeof mapStateToProps>;
type TDispatchProps = typeof mapDispatchToProps;

export type TConnectableProps = TStateProps & TDispatchProps;

export const Connectable = connect(mapStateToProps, mapDispatchToProps);
