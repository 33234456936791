import { ICellRendererParams } from 'ag-grid-community';
import React from 'react';
import DetailsCell from '../../../components/DetailsCell';
import { gridValueGetter } from '../../../components/SourceSetGrid/_utils';

const MULTIPLE_OBJECTS = 2;

export const reportsDetailCellRenderer = ({ data }: ICellRendererParams) => {
    const value = gridValueGetter({
        value: data?.subjects,
        type: 'list',
        formatting: {
            pattern: '{name}',
            separator: ', ',
        },
    });
    const stringValue = typeof value === 'string' ? value : '';
    return (
        <>
            {data?.subjects.length > MULTIPLE_OBJECTS && (
                <DetailsCell summary={stringValue}>
                    <>{stringValue}</>
                </DetailsCell>
            )}
            {stringValue}
        </>
    );
};
