import { combineReducers } from 'redux';

import adminPanel from './adminPanel';
import alerts from './alerts';
import alertsPage from './alertsPage';
import bookmarks from './bookmarks';
import calibrations from './calibrations';
import collections from './collections';
import dictionaries from './dictionaries';
import entities from './entities';
import fetching from './fetching';
import fuelTanks from './fuelTanks';
import iframes from './iframes';
import mappings from './mappings';
import monitoredObjects from './monitoredObjects';
import refuellAndLossConfiguration from './refuellAndLossConfiguration';
import searcher from './searcher';
import searchers from './searchers';
import registers from './registers';
import status from './statusSlice';
import sync from './sync';
import tasks from './tasks';
import files from './filesSlice';
import translations from './translations';

export default combineReducers({
    adminPanel,
    alertsPage,
    collections,
    dictionaries,
    entities,
    fetching,
    fuelTanks,
    files,
    iframes,
    monitoredObjects,
    registers,
    searcher,
    searchers,
    sync,
    status,
    tasks,
    refuellAndLossConfiguration,
    alerts,
    translations,
    bookmarks,
    calibrations,
    mappings,
});
