import { IPrivileges } from '../state/auth';

const chooseInitialPage = (privileges: IPrivileges) => {
    privileges = privileges || {};
    let firstPage;

    if (privileges.discoveryContext) {
        firstPage = '/discovery';
    } else if (privileges.reportsContext) {
        firstPage = '/reports';
    } else if (privileges.registersContext) {
        firstPage = '/registers';
    } else if (privileges.alertsContext) {
        firstPage = '/alerts';
    } else {
        firstPage = '/user-account';
    }

    return firstPage;
};

export default chooseInitialPage;
