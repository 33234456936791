import React, { useEffect, useState } from 'react';

import debounce from 'lodash.debounce';

import {
    useFuelTanks,
    useFuelTanksFilter,
} from '../../../state/app/registers/index.hooks';
import {
    fetchFuelTanks,
    setFuelTanksFilter,
} from '../../../state/app/registers/tanks';
import { showSendRFIDCardsDialog } from '../../../state/ui/dialog';

import { restGetGroups } from '../../../services/groups';

import Translator from '../../../helpers/TranslationHelper';

import { DEBOUNCE_MID } from '../../../constants/DebounceValues';

import { useAppDispatch } from '../../../hooks';

import Pagination from '../../common/Pagination';
import PhraseFilter from '../../common/PhraseFilter';
import FuelTanksTable from './FuelTankTable';
import TableWrapper from '../_shared/TableWrapper';
import AsyncFilterSelect from '../../AsyncFilterSelect';

import { IFuelTank } from './types';

const FuelTanks = () => {
    const [currentPage, setCurrentPage] = useState<number>(1);

    const filter = useFuelTanksFilter();
    const fuelTanks = useFuelTanks();

    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(fetchFuelTanks(filter));
    }, [filter]);

    const handleGroupChange = (option: { id: string }) => {
        const updatedFilter = {
            ...filter,
            group: option && option.id,
            start: 0,
        };
        setCurrentPage(1);
        dispatch(setFuelTanksFilter({ fuelTanksFilter: updatedFilter }));
    };

    const handlePageChange = (selectedPageNumber: number) => {
        const targetPageNumber = selectedPageNumber || currentPage;

        const limit = filter.limit || 1;
        const updatedFilter = {
            ...filter,
            start: (targetPageNumber - 1) * limit,
        };
        setCurrentPage(targetPageNumber);
        dispatch(setFuelTanksFilter({ fuelTanksFilter: updatedFilter }));

        const element = document.querySelector(
            '#registersPageContent .content'
        );
        element?.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    const handleFuelTanksFilter = debounce((name: string) => {
        const updatedFilter = {
            ...filter,
            name,
            start: 0,
        };
        setCurrentPage(1);
        dispatch(setFuelTanksFilter({ fuelTanksFilter: updatedFilter }));
    }, DEBOUNCE_MID);

    const handleSendRFIDCardsClick = (fuelTank: IFuelTank) => {
        dispatch(showSendRFIDCardsDialog({ fuelTank }));
    };

    const renderPagination = () => {
        if (!fuelTanks) {
            return null;
        }
        return (
            <Pagination
                totalResults={fuelTanks.total}
                currentPage={currentPage}
                onPageSelect={handlePageChange}
                resultsPerPage={filter.limit}
            />
        );
    };

    return (
        <section className="fuel-tanks">
            <header className="header">
                <div className="header-filter">
                    <PhraseFilter
                        placeholder={Translator.translate('Find by name') + ' '}
                        autoFocus={true}
                        onFilter={handleFuelTanksFilter}
                        filter={filter.name || ''}
                    />
                    <div style={{ width: 300 }}>
                        <AsyncFilterSelect
                            name="employee-group"
                            selectedOptionId={filter.group}
                            getData={() => restGetGroups()}
                            placeholder={Translator.translate('Find by group')}
                            onOptionSelect={handleGroupChange}
                        />
                    </div>
                </div>
            </header>
            <TableWrapper>
                <FuelTanksTable
                    fuelTanks={fuelTanks ? fuelTanks.items : undefined}
                    onSendCards={handleSendRFIDCardsClick}
                />
            </TableWrapper>
            {renderPagination()}
        </section>
    );
};

export default FuelTanks;
