import { combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import * as state from './state';

const storageKey = 'webx-1';

const reducer = combineReducers(state);

const getState = (key: string) => {
    const rootStateString = localStorage.getItem(key);
    if (rootStateString === null) {
        return {};
    }
    return JSON.parse(rootStateString);
};

const store = configureStore({
    reducer,
    preloadedState: getState(storageKey),
    middleware: (getDefaultMiddleware) =>
        //TODO as for now, we have some non-serializable data in redux state, such as Date/Moment
        //this causes error/warning in redux toolkit
        //changing our state to not include non serializable data will be done in #54258
        getDefaultMiddleware({
            immutableCheck: false,
            serializableCheck: false,
        }),
});

store.subscribe(() => {
    localStorage.setItem(
        storageKey,
        JSON.stringify({ auth: store.getState().auth })
    );
});

export type TRootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export default store;
