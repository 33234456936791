import React, { Component, ComponentType, ReactChild } from 'react';

import {
    Form,
    Formik,
    FormikActions,
    FormikErrors,
    FormikValues,
} from 'formik';
import { connect } from 'react-redux';
import { compose } from 'redux';
import TranslationHelper from '../../helpers/TranslationHelper';
import { Themable, TThemableProps } from './Themable.hoc';

import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import DeletionDialog from '../dialogs/DeletionDialog';
import PaneHeader from '../PaneHeader';

type IMode = 'add' | 'edit' | 'preview';

interface IOwnProps {
    titles: {
        add?: string;
        edit?: string;
        preview?: string;
    };
    mode?: string;
    inCreate?: boolean;
    loaded: boolean;
    initialValues: any;
    validationSchema?: any;
    renderContent: (
        mode: string,
        errors: FormikErrors<{}>,
        values: FormikValues,
        setFieldValue: (field: string, value: any) => void,
        isSubmitting: boolean,
        validateForm: (values?: any) => Promise<FormikErrors<FormikValues>>
    ) => ReactChild;
    onDelete?: () => void;
    changeModeHandler?: (mode: string) => void;
    onEdit?: (
        values: FormikValues,
        onSuccess: () => void,
        onError: () => void
    ) => void;
    onAdd?: (
        values: FormikValues,
        onSuccess: () => void,
        onError: () => void
    ) => void;
    onClose?: () => void;
    renderRightCustomControls?: () => ReactChild;
    renderLeftCustomControls?: () => ReactChild;
    preventSubmitOnEnterKey?: boolean;
}

interface IState {
    mode: IMode;
    isDeletionDialogOpen: boolean;
}

type TProps = IOwnProps & TThemableProps;

class CrudPane extends Component<TProps, IState> {
    public static getDerivedStateFromProps(
        nextProps: TProps,
        lastState: IState
    ) {
        if (lastState.mode !== 'add' && nextProps.inCreate) {
            return {
                mode: 'add',
                isDeletionDialogOpen: false,
            };
        }
        return null;
    }

    public state: IState = {
        mode: this.props.inCreate ? 'add' : 'preview',
        isDeletionDialogOpen: false,
    };

    public renderPaneControls = (
        mode: string,
        isDeletionDialogOpen: boolean,
        isSubmitting: boolean
    ) => {
        const {
            renderRightCustomControls,
            onDelete,
            onAdd,
            onEdit,
            loaded,
            classes,
        } = this.props;
        return (
            <React.Fragment>
                {renderRightCustomControls && renderRightCustomControls()}
                {loaded && mode === 'add' && onAdd && (
                    <Tooltip title={TranslationHelper.translate('Save')}>
                        <IconButton
                            type="submit"
                            name="save"
                            className={classes.contrastText}
                            disabled={isSubmitting}
                        >
                            <SaveIcon />
                        </IconButton>
                    </Tooltip>
                )}
                {loaded && mode === 'edit' && onEdit && (
                    <Tooltip title={TranslationHelper.translate('Save')}>
                        <IconButton
                            type="submit"
                            className={classes.contrastText}
                            disabled={isSubmitting}
                        >
                            <SaveIcon />
                        </IconButton>
                    </Tooltip>
                )}
                {loaded && mode === 'preview' && onEdit && (
                    <Tooltip title={TranslationHelper.translate('Edit')}>
                        <IconButton
                            onClick={this.handleEditClick}
                            className={classes.contrastText}
                            disabled={isSubmitting}
                        >
                            <EditIcon />
                        </IconButton>
                    </Tooltip>
                )}
                {loaded && mode === 'preview' && onDelete && (
                    <>
                        <Tooltip title={TranslationHelper.translate('Delete')}>
                            <IconButton
                                onClick={this.openDeletionDialog}
                                className={classes.contrastText}
                                disabled={isSubmitting}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </Tooltip>
                        <DeletionDialog
                            isOpen={isDeletionDialogOpen}
                            close={this.closeDeletionDialog}
                            confirm={() => {
                                onDelete();
                                this.closeDeletionDialog();
                            }}
                            title="Removal confirmation"
                            message="Are you sure?"
                        />
                    </>
                )}
            </React.Fragment>
        );
    };

    public render() {
        const {
            classes,
            titles,
            renderContent,
            initialValues,
            validationSchema,
            mode: propsMode,
            renderLeftCustomControls,
            preventSubmitOnEnterKey,
        } = this.props;
        const { isDeletionDialogOpen, mode: stateMode } = this.state;
        const mode = propsMode || stateMode;
        return (
            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                onSubmit={this.handleSubmit}
                validateOnBlur={false}
                validateOnChange={false}
                validationSchema={validationSchema}
            >
                {({
                    isSubmitting,
                    errors,
                    values,
                    setFieldValue,
                    resetForm,
                    validateForm,
                }) => (
                    <Form
                        noValidate={true}
                        onKeyPress={
                            preventSubmitOnEnterKey
                                ? this.handlePreventSubmitOnEnter
                                : undefined
                        }
                    >
                        <div className={classes.pane}>
                            <PaneHeader
                                title={TranslationHelper.translate(
                                    titles[mode] || titles.preview
                                )}
                                onCloseClick={() =>
                                    this.handleCloseClick(resetForm)
                                }
                                renderLeftCustomControls={
                                    renderLeftCustomControls
                                }
                                renderRightCustomControls={() =>
                                    this.renderPaneControls(
                                        mode,
                                        isDeletionDialogOpen,
                                        isSubmitting
                                    )
                                }
                            />
                            {renderContent(
                                mode,
                                errors,
                                values,
                                setFieldValue,
                                isSubmitting,
                                validateForm
                            )}
                        </div>
                    </Form>
                )}
            </Formik>
        );
    }

    private handlePreventSubmitOnEnter = (
        e: React.KeyboardEvent<HTMLFormElement>
    ) => {
        if (e.key === 'Enter') {
            e.preventDefault();
        }
    };

    private handleCloseClick = (resetForm: () => void) => {
        resetForm();
        if (this.props.mode === 'edit' || this.state.mode === 'edit') {
            this.setState({ mode: 'preview' });
            if (this.props.changeModeHandler) {
                this.props.changeModeHandler('preview');
            }
        } else if (this.props.onClose) {
            this.props.onClose();
        }
    };

    private handleEditClick = () => {
        this.setState({ mode: 'edit' });
        if (this.props.changeModeHandler) {
            this.props.changeModeHandler('edit');
        }
    };

    private handleSubmit = (
        values: FormikValues,
        formikActions: FormikActions<FormikValues>
    ) => {
        const { onAdd, onEdit, mode } = this.props;
        const { mode: stateMode } = this.state;
        if ((mode === 'add' || stateMode === 'add') && onAdd) {
            onAdd(
                values,
                () => this.handleCloseClick(formikActions.resetForm),
                () => formikActions.setSubmitting(false)
            );
        } else if ((mode === 'edit' || stateMode === 'edit') && onEdit) {
            onEdit(
                values,
                () => this.handleCloseClick(formikActions.resetForm),
                () => formikActions.setSubmitting(false)
            );
        }
    };

    private openDeletionDialog = () => {
        this.setState({ isDeletionDialogOpen: true });
    };

    private closeDeletionDialog = () => {
        this.setState({ isDeletionDialogOpen: false });
    };
}

export default compose(
    Themable,
    connect()
)(CrudPane) as ComponentType<IOwnProps>;
