import { Dispatch } from 'react';
import { stringToData } from './converters';
import {
    IActivateGridParams,
    IActivatePreviewParams,
    activateGridWithSelectedOptions,
    activatePreviewWithSelectedOptions,
    startRestoringState,
} from '../state/ui/discovery/snapshotting';
import moment from 'moment';
import { makeSourceSetId } from '../services/discovery/_shared/utils';
import { makeSourceSetUrl } from '../services/discovery/_shared/urlMakers';
import TimeFormatter from './TimeFormatter';
import { fetchTrail } from '../state/_actions';

export async function dispatchActionFromQuery(
    params: {
        action: string;
        [key: string]: any;
    },
    dispatch: Dispatch<any>
) {
    if (!params.action) {
        return;
    }

    let queryDecoded:
        | {
              action: {
                  type: string;
                  params: {
                      [key: string]: any;
                  };
              };
          }
        | undefined;
    stringToData(
        params.action,
        (data) => {
            queryDecoded = data;
        },
        () => {
            queryDecoded = {
                action: {
                    type: params.action,
                    params: { ...params },
                },
            };
        }
    );
    switch (queryDecoded?.action?.type) {
        case 'showEventFromTask': {
            const params: IActivatePreviewParams = {
                elementCollectionName: 'tasks',
                elementId: queryDecoded.action.params.id,
                elementType: 'task',
                mode: 'preview',
                snapshot: {
                    type: 'preview',
                    level: 0,
                },
                actionsToTrigger: [{ type: 'showEvent' }],
            };
            dispatch(startRestoringState());
            dispatch(activatePreviewWithSelectedOptions(params));

            break;
        }
        case 'showEvent': {
            const date = queryDecoded.action.params.dt;
            const monitoredId = queryDecoded.action.params.mid;
            const from = TimeFormatter.toISOString(moment(date).startOf('day'));
            const to = TimeFormatter.toISOString(moment(date).endOf('day'));
            dispatch(
                fetchTrail(
                    monitoredId,
                    TimeFormatter.getISOString(from),
                    TimeFormatter.getISOString(to),
                    1
                )
            );
            if (date && monitoredId) {
                const action = {
                    api: '/rest/api/source-sets/archive-events',
                    label: 'Show event',
                    method: 'GET',
                    params: {
                        from,
                        monitoredId,
                        reportId: 'archiveEvents',
                        to,
                    },
                };
                const params: IActivateGridParams = {
                    sourceSetId:
                        makeSourceSetId(action.params) ||
                        makeSourceSetUrl(action).toString(),
                    snapshot: {
                        type: 'source-set-grid',
                        level: 0,
                    },
                    preview: {
                        elementCollectionName: '',
                        creatorLevel: 0,
                        mode: '',
                        elementId: null,
                        previewAction: null,
                        elementType: '',
                        reportSetParams: {
                            monitoredId: monitoredId.toString(),
                            from: '',
                            to: '',
                            objectId: '',
                        },
                    },
                    action,
                    actionsToTrigger: [{ type: 'showEvent', date }],
                };
                dispatch(activateGridWithSelectedOptions(params));
            }

            break;
        }

        default:
            return;
    }
}
