import React from 'react';

import { useDispatch } from 'react-redux';

import { default as Button } from '@material-ui/core/Button';
import { default as Bookmarks } from '@material-ui/icons/Bookmarks';

import { useStyles } from './Themable.hooks';

import { showBookmarskListDialog } from '../../../../state/ui/dialog';
import { useBookmarkList } from '../../../../state/app/bookmarks/index.hooks';
import {
    IActivatePreviewParams,
    activatePreviewWithSelectedOptions,
} from '../../../../state/ui/discovery/snapshotting';

import Translator from '../../../../helpers/TranslationHelper';

import { useShouldMapBeInEditMode } from '../../selectors/index.hooks';
import { isDataSegmentsContextEnabled } from '../../../../constants/AppConfig';

const DataSegmentsToggler = () => {
    const bookmarks = useBookmarkList();
    const editMode = useShouldMapBeInEditMode();
    const classes = useStyles();

    const dataSegmentsContextEnabled = isDataSegmentsContextEnabled();
    const dispatch = useDispatch();

    const openBookmarksList = () => {
        dispatch(showBookmarskListDialog({}));
    };

    const openBoomarksInPane = () => {
        const params: IActivatePreviewParams = {
            elementCollectionName: 'dataSegments',
            elementId: null,
            elementType: 'dataSegments',
            mode: 'preview',
            snapshot: {
                type: 'preview',
                level: 0,
            },
            closeGrid: true,
        };
        dispatch(activatePreviewWithSelectedOptions(params));
    };

    const handleOnClick = () => {
        return dataSegmentsContextEnabled
            ? openBoomarksInPane()
            : openBookmarksList();
    };
    const disabled = bookmarks.length === 0 || editMode;

    return (
        <Button
            disabled={disabled}
            color="primary"
            variant="contained"
            onClick={handleOnClick}
        >
            <Bookmarks className={classes.leftIcon} />
            {Translator.translate('Saved segments')}
        </Button>
    );
};

export default DataSegmentsToggler;
