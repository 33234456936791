import { connect, ResolveThunks } from 'react-redux';

import { TRootState } from '../../store';

import { getLanguage } from '../../state/login';
import { getUserSettings } from '../../state/user';
import { hideGlobalDialog } from '../../state/ui/dialog';
import {
    getEmployeesFilter,
    updateEmployee,
    createEmployee,
    updateEmployeeCards,
} from '../../state/app/registers/employees';
import {
    updateVehicle,
    createVehicle,
    updateVehicleCards,
    getVehiclesFilter,
} from '../../state/app/registers/vehicles';
import {
    updateFuelTankCards,
    getFuelTanksFilter,
} from '../../state/app/registers/tanks';
import {
    getRfidCardsFilter,
    updateRfidCard,
    createRfidCard,
} from '../../state/app/registers/rfidCards';

const mapStateToProps = (state: TRootState) => ({
    userSettings: getUserSettings(state),
    language: getLanguage(state),
    vehiclesFilter: getVehiclesFilter(state),
    fuelTanksFilter: getFuelTanksFilter(state),
    employeesFilter: getEmployeesFilter(state),
    rfidCardsFilter: getRfidCardsFilter(state),
});

const mapDispatchToProps = {
    updateEmployee,
    createEmployee,
    updateEmployeeCards,
    updateVehicleCards,
    updateVehicle,
    createVehicle,
    updateFuelTankCards,
    hideGlobalDialog,
    updateRfidCard,
    createRfidCard,
};

type TStateProps = ReturnType<typeof mapStateToProps>;
type TDispatchProps = ResolveThunks<typeof mapDispatchToProps>;

export type TConnectableProps = TStateProps & TDispatchProps;

export const Connectable = connect(mapStateToProps, mapDispatchToProps);
