import React, { useEffect, useRef, useState } from 'react';

import { Field, FormikErrors, FormikValues } from 'formik';

import moment from 'moment';

import VehicleIcon from '@mui/icons-material/LocalShipping';
import EmployeeIcon from '@mui/icons-material/Person';
import { Box } from '@mui/material';

import TimeFormatter from '../../../../../../../../../../helpers/TimeFormatter';
import TranslationHelper from '../../../../../../../../../../helpers/TranslationHelper';
import { getTypesIds } from '../../../../helpers';

import { IPrivileges } from '../../../../../../../../../../state/auth';
import { ITaskForm } from '../../../../../../../../../../state/ui/forms';
import { IDictionaryItem } from '../../../../../../../../../../state/app/dictionaries';
import useSearchersLogic from '../../../../../../../../../../state/app/searchers/index.hooks';

import { areLocationsEnabled } from '../../../../../../../../../../constants/AppConfig';
import { DONE } from '../../../../../../../../../../constants/dictionaries/TaskStatuses';

import { mapDictionaryItems } from '../../../../../../_utils';

import DateFieldWrapper from '../../../../../../../../../../components/common/formikWrappers/DateFieldWrapper';
import FieldWrapper from '../../../../../../../../../../components/common/formikWrappers/FieldWrapper';
import FileUploadWrapper from '../../../../../../../../../../components/common/formikWrappers/FileUploadWrapper';
import GridSearchSelectWrapper from '../../../../../../../../../../components/common/formikWrappers/GridSearchSelectWrapper';
import PhraseFilterWrapper from '../../../../../../../../../../components/common/formikWrappers/PhraseFilterWrapper';
import CustomCheckbox from '../../../../../../../../../../components/common/CustomCheckbox';
import TimeRangeFormFields from '../../../../../../../../../../components/TimeRangeDisplay/TimeRangeFormFields';
import TaskLinkagesSection from './TaskLinkagesSection';
import { showTaskRoutesDialog } from '../../../../../../../../../../state/ui/dialog';
import { useAppDispatch } from '../../../../../../../../../../hooks';

interface IOwnProps {
    classes: {
        paneContent: string;
        attachmentsPane: string;
        missingDataWrapper: string;
        linkage: string;
        fieldSm: string;
        fieldMd: string;
    };
    activityCategory: IDictionaryItem[];
    objectCategory: IDictionaryItem[];
    serviceClass: IDictionaryItem[];
    serviceType: IDictionaryItem[];
    statuses: IDictionaryItem[];
    privileges: IPrivileges;
    onSelectLocation: () => void;
    onAddLocation?: () => void;
    values: FormikValues;
    errors: FormikErrors<ITaskForm>;
    setFieldValue: (name: string, value: any) => void;
    dateFormat: string;
    mode: string;
}

const TaskModification = ({
    errors,
    values,
    onSelectLocation,
    onAddLocation,
    activityCategory,
    serviceClass,
    serviceType,
    objectCategory,
    statuses,
    classes,
    dateFormat,
    setFieldValue,
    mode,
    privileges,
}: IOwnProps) => {
    const [checkboxDisabled, setCheckboxDisabled] = useState<boolean>(
        values.status === DONE && mode === 'edit'
    );
    const initialRender = useRef(true);
    const dispatch = useAppDispatch();

    const {
        onSearchRegister,
        areSearchResultsVisible,
        getRegisterResults,
        areSearchResultsFetching,
        onResetRegister,
    } = useSearchersLogic();

    useEffect(() => {
        if (values.route?.id && !initialRender.current) {
            setFieldValue('route', { label: '' });
        }
    }, [values.noDate, values.plannedOn, values.activityCategory]);

    useEffect(() => {
        if (!initialRender.current) {
            const { serviceTypeId, objectCategoryId } = getTypesIds(
                values.activityCategory,
                serviceType,
                objectCategory
            );
            setFieldValue('serviceType', serviceTypeId);
            setFieldValue('objectCategory', objectCategoryId);
            setFieldValue('serviceClass', '');
        } else {
            initialRender.current = false;
        }
    }, [values.activityCategory]);

    useEffect(() => {
        if (values.status === DONE) {
            if (!values.plannedOn) {
                setFieldValue('plannedOn', moment());
            }
            setFieldValue('noDate', false);
            setCheckboxDisabled(true);
        } else {
            setCheckboxDisabled(false);
        }
    }, [values.status]);

    useEffect(() => {
        if (values.noDate === false && values.plannedOn === null) {
            setFieldValue('plannedOn', moment());
        }
    }, [values.noDate]);

    const location = errors && (errors.location as unknown as IDictionaryItem);
    const hasRightToLocations = areLocationsEnabled() && privileges.locations;

    const missingDropdownData = [
        activityCategory,
        serviceType,
        objectCategory,
        statuses,
    ].some((el) => el?.length < 1);
    return (
        <div>
            {/*TODO this is only temporary solution - it appears in more places in application, so it probably needs more universal approach*/}
            {missingDropdownData ? (
                <h1 className={classes.missingDataWrapper}>
                    {TranslationHelper.translate(
                        'Missing configuration of services required to create new task.'
                    )}
                </h1>
            ) : (
                <>
                    <div className={classes.paneContent}>
                        <Box display="flex">
                            <Field
                                error={!!(errors && errors.plannedOn)}
                                helperText={errors && errors.plannedOn}
                                name={'plannedOn'}
                                label={TranslationHelper.translate('Date')}
                                component={DateFieldWrapper}
                                required={true}
                                disabled={values.noDate}
                                displayFormat={dateFormat}
                                value={values.noDate ? null : values.plannedOn}
                                withDateFormatter={
                                    TimeFormatter.dateToShortDateString
                                }
                            />
                            <Field
                                name={'noDate'}
                                label={TranslationHelper.translate(
                                    'Without date'
                                )}
                                checked={values.noDate}
                                onChange={() =>
                                    setFieldValue('noDate', !values.noDate)
                                }
                                component={CustomCheckbox}
                                useContrastColors={false}
                                disabled={checkboxDisabled}
                            />
                        </Box>
                        <TimeRangeFormFields
                            timeFrom={{
                                value: values.plannedTimeFrom,
                                errors: errors.plannedTimeFrom,
                                name: 'plannedTimeFrom',
                            }}
                            timeTo={{
                                value: values.plannedTimeTo,
                                errors: errors.plannedTimeTo,
                                name: 'plannedTimeTo',
                            }}
                        />
                        <Field
                            error={!!(errors && location && location.id)}
                            helperText={errors && location && location.id}
                            name={'location.label'}
                            label={TranslationHelper.translate('Address')}
                            type={'text'}
                            component={GridSearchSelectWrapper}
                            required={true}
                            InputProps={{ readOnly: true }}
                            onClick={
                                hasRightToLocations
                                    ? onSelectLocation
                                    : undefined
                            }
                            onAdd={
                                hasRightToLocations ? onAddLocation : undefined
                            }
                        />
                        <Field
                            name={'vehicle'}
                            placeholder={TranslationHelper.translate(
                                'Find by name'
                            )}
                            fetchDataHandler={(name: string) =>
                                onSearchRegister(name, 'vehicles')
                            }
                            visible={areSearchResultsVisible('vehicles')}
                            fetching={areSearchResultsFetching('vehicles')}
                            results={getRegisterResults('vehicles')}
                            afterSelect={() => {
                                setFieldValue('route', { label: '' });
                            }}
                            onClose={() => {
                                onResetRegister('vehicles');
                            }}
                            component={PhraseFilterWrapper}
                            icon={<VehicleIcon />}
                        />
                        <Field
                            name={'employee'}
                            placeholder={TranslationHelper.translate(
                                'Find by name'
                            )}
                            visible={areSearchResultsVisible('employees')}
                            fetching={areSearchResultsFetching('employees')}
                            results={getRegisterResults('employees')}
                            afterSelect={() => {
                                setFieldValue('route', { label: '' });
                            }}
                            onClose={() => {
                                onResetRegister('employees');
                            }}
                            fetchDataHandler={(name: string) =>
                                onSearchRegister(name, 'employees')
                            }
                            component={PhraseFilterWrapper}
                            icon={<EmployeeIcon />}
                        />

                        <Field
                            name={'route.label'}
                            placeholder={TranslationHelper.translate('Route')}
                            component={GridSearchSelectWrapper}
                            InputProps={{ readOnly: true }}
                            label={TranslationHelper.translate('Route')}
                            disabled={
                                !values.activityCategory ||
                                (!values.employee.id && !values.vehicle.id)
                            }
                            onClick={() =>
                                values.activityCategory &&
                                (values.employee.id || values.vehicle.id) &&
                                dispatch(
                                    showTaskRoutesDialog({
                                        filter: {
                                            activityCategoryId:
                                                values.activityCategory,
                                            employeeId: values.employee.id,
                                            vehicleId: values.vehicle.id,
                                            date: values.plannedOn,
                                        },
                                        routeId: values.route.id,
                                        setFieldValue: setFieldValue,
                                    })
                                )
                            }
                        />

                        <Field
                            error={!!(errors && errors.status)}
                            helperText={errors && errors.status}
                            name={'status'}
                            label={TranslationHelper.translate('Status')}
                            select={true}
                            fullWidth={true}
                            component={FieldWrapper}
                            required={true}
                        >
                            {mapDictionaryItems(
                                statuses,
                                undefined,
                                undefined,
                                true
                            )}
                        </Field>
                        <Field
                            error={!!(errors && errors.activityCategory)}
                            helperText={errors && errors.activityCategory}
                            name={'activityCategory'}
                            label={TranslationHelper.translate('Type 1')}
                            select={true}
                            fullWidth={true}
                            component={FieldWrapper}
                            required={true}
                        >
                            {mapDictionaryItems(activityCategory)}
                        </Field>
                        <Field
                            error={!!(errors && errors.serviceType)}
                            helperText={errors && errors.serviceType}
                            name={'serviceType'}
                            label={TranslationHelper.translate('Type 2')}
                            select={true}
                            fullWidth={true}
                            component={FieldWrapper}
                            disabled={!values.activityCategory}
                            required={true}
                        >
                            {mapDictionaryItems(
                                serviceType,
                                values.activityCategory
                            )}
                        </Field>
                        <Field
                            error={!!(errors && errors.objectCategory)}
                            helperText={errors && errors.objectCategory}
                            name={'objectCategory'}
                            label={TranslationHelper.translate('Type 3')}
                            select={true}
                            fullWidth={true}
                            component={FieldWrapper}
                            disabled={!values.activityCategory}
                            required={true}
                        >
                            {mapDictionaryItems(
                                objectCategory,
                                values.activityCategory
                            )}
                        </Field>
                        <Field
                            name={'serviceClass'}
                            label={TranslationHelper.translate('Type 4')}
                            select={true}
                            fullWidth={true}
                            disabled={!values.activityCategory}
                            component={FieldWrapper}
                        >
                            {mapDictionaryItems(
                                serviceClass,
                                values.activityCategory,
                                true
                            )}
                        </Field>
                        <Field
                            error={!!(errors && errors.unitsCount)}
                            helperText={errors && errors.unitsCount}
                            name={'unitsCount'}
                            label={TranslationHelper.translate('Units count')}
                            type={'number'}
                            fullWidth={true}
                            component={FieldWrapper}
                            inputProps={{
                                min: '0',
                            }}
                            required={true}
                        />
                        <Field
                            error={!!(errors && errors.notice)}
                            helperText={errors && errors.notice}
                            name={'notice'}
                            label={TranslationHelper.translate('Notice')}
                            type={'text'}
                            fullWidth={true}
                            multiline={true}
                            component={FieldWrapper}
                        />
                    </div>
                    <div className={classes.attachmentsPane}>
                        <Field
                            error={
                                values &&
                                values.attachments &&
                                values.attachments.errors
                            }
                            name={'attachments'}
                            component={FileUploadWrapper}
                            readOnlyFiles={values.readOnlyFiles}
                            maxSize={20}
                        />
                    </div>
                    {mode === 'add' && privileges.xtrackServiceSystem && (
                        <TaskLinkagesSection values={values} errors={errors} />
                    )}
                </>
            )}
        </div>
    );
};

export default TaskModification;
