import React, { Component, ComponentType } from 'react';
import { compose } from 'redux';

import classNames from 'classnames';
import { debounce } from 'lodash';
import moment, { Moment } from 'moment-timezone';

import { Connectable, TConnectableProps } from './Connectable.hoc';
import { Themable, TThemableProps } from './Themable.hoc';

import { Button, IconButton } from '@material-ui/core';
import { default as CalendarIcon } from '@material-ui/icons/CalendarToday';
import { default as PreviousIcon } from '@material-ui/icons/KeyboardArrowLeft';
import { default as NextIcon } from '@material-ui/icons/KeyboardArrowRight';

import DatePickerDialog from './components/DatePickerDialog';

import TimeFormatter from '../../helpers/TimeFormatter';
import TranslationHelper from '../../helpers/TranslationHelper';
import IconButtonWithTooltip from '../IconButtonWithTooltip';

interface IOwnProps {
    onDateChange: (from: string, to: string) => void;
    sourceSetId?: string;
    dates: { from: string; to: string };
    datesFormat?: 'short';
    error?: boolean;
    small?: boolean;
    calendarIconOnly?: boolean;
}

interface IState {
    isTimePickerEnabled: boolean;
    newFromDate: string;
    newToDate: string;
}

export interface IQuickDateControls {
    label: string;
    from: string;
    to: string;
}

type TProps = TThemableProps & TConnectableProps & IOwnProps;

const DatesAsButton: React.FunctionComponent<{
    classes: {
        colorPrimary: string;
        changeDateIcon: string;
        time: string;
        timeValue: string;
        label: string;
        timeRange: string;
        calendarIcon: string;
        error: string;
        small: string;
    };
    from: Moment;
    to: Moment;
    onClick: () => void;
    isNavigationEnabled: boolean;
    moveRangeLeft?: () => void;
    moveRangeRight?: () => void;
    dateFormat: string;
    error?: boolean;
    small?: boolean;
    calendarIconOnly?: boolean;
}> = ({
    classes,
    from,
    to,
    onClick,
    isNavigationEnabled,
    moveRangeLeft,
    moveRangeRight,
    dateFormat,
    error,
    small,
    calendarIconOnly,
}) => {
    const shouldDisplayDates = to.isValid() && from.isValid();

    let timeFrom = '';
    let timeTo = '';

    if (shouldDisplayDates) {
        timeFrom = from.format(dateFormat);
        timeTo = to.format(dateFormat);
    }

    const showDates = () => {
        return (
            <>
                <p className={classes.time}>
                    <span className={small ? classes.small : classes.timeValue}>
                        {timeFrom}
                    </span>
                </p>
                {displayToDate && (
                    <p className={classes.time}>
                        <span className={classes.label}>{'-'}</span>
                        <span
                            className={
                                small ? classes.small : classes.timeValue
                            }
                        >
                            {timeTo}
                        </span>
                    </p>
                )}
            </>
        );
    };

    const displayToDate = Math.abs(from.diff(to, 'day')) !== 0;
    const timeRangeClassNames = classNames({
        [classes.timeRange]: true,
        [classes.error]: error,
    });
    if (calendarIconOnly) {
        return (
            <IconButtonWithTooltip
                aria-label={TranslationHelper.translate('Date')}
                title={`${timeFrom} - ${timeTo}`}
                onClick={onClick}
                defaultColor
            >
                <CalendarIcon className={classes.calendarIcon} />
            </IconButtonWithTooltip>
        );
    }
    return (
        <div className={timeRangeClassNames}>
            {isNavigationEnabled && (
                <IconButton
                    aria-label="Previous"
                    onClick={moveRangeLeft}
                    disabled={!shouldDisplayDates}
                    className={classes.changeDateIcon}
                >
                    <PreviousIcon
                        color="primary"
                        className={classes.colorPrimary}
                    />
                </IconButton>
            )}

            <Button onClick={onClick}>
                <CalendarIcon className={classes.calendarIcon} />
                {shouldDisplayDates ? (
                    showDates()
                ) : (
                    <p className={classes.time}>
                        <span className={classes.timeValue}>
                            {TranslationHelper.translate('Choose a date')}
                        </span>
                    </p>
                )}
            </Button>

            {isNavigationEnabled && (
                <IconButton
                    aria-label="Next"
                    onClick={moveRangeRight}
                    disabled={!shouldDisplayDates}
                    className={classes.changeDateIcon}
                >
                    <NextIcon
                        color="primary"
                        className={classes.colorPrimary}
                    />
                </IconButton>
            )}
        </div>
    );
};

class DateRangeDisplay extends Component<TProps, IState> {
    public state = {
        isTimePickerEnabled: false,
        newFromDate: this.props.dates?.from || moment().startOf('day').format(),
        newToDate: this.props.dates?.to || moment().endOf('day').format(),
    };

    public quickDates = {
        today: moment().format(),
        yesterday: moment().subtract(1, 'day').format(),
        lastSevenDays: moment().subtract(7, 'day').format(),
        lastThirtyDays: moment().subtract(30, 'day').format(),
    };

    public quickDateControls: IQuickDateControls[] = [
        {
            label: 'Today',
            from: this.quickDates.today,
            to: this.quickDates.today,
        },
        {
            label: 'Yesterday',
            from: this.quickDates.yesterday,
            to: this.quickDates.yesterday,
        },
        {
            label: 'Last 7 days',
            from: this.quickDates.lastSevenDays,
            to: this.quickDates.today,
        },
        {
            label: 'Last 30 days',
            from: this.quickDates.lastThirtyDays,
            to: this.quickDates.today,
        },
    ];

    public dateChange = debounce((from: string, to: string) => {
        this.props.onDateChange(from, to);
    }, 500);

    public handleQuickDate = (from: string, to: string) => {
        this.handleDatesChange(from, to);
        this.closeDialog();
    };

    public handleDatesChange = (from: string, to: string) => {
        const startOfFrom = TimeFormatter.startOfDay(from);
        const endOfTo = TimeFormatter.endOfDay(to);

        this.setState({ newFromDate: startOfFrom, newToDate: endOfTo });

        if (this.props.datesFormat === 'short') {
            const shortFrom = TimeFormatter.dateToShortDateString(from);
            const shortTo = TimeFormatter.dateToShortDateString(to);
            this.dateChange(shortFrom, shortTo);
        } else {
            this.dateChange(startOfFrom, endOfTo);
        }
    };

    public makeMove = (
        from: string,
        to: string,
        direction: 'left' | 'right'
    ) => {
        let diff = moment(to).diff(moment(from), 'days') + 1;

        diff = direction === 'left' ? -diff : diff;

        const newFromDate = this.addDays(from, diff);
        const newToDate = this.addDays(to, diff);

        this.handleDatesChange(newFromDate, newToDate);
    };

    public addDays = (date: string, daysToAdd: number) =>
        moment(date).add(daysToAdd, 'days').format();

    public moveRangeLeft = () => {
        const { dates } = this.props;
        if (!dates) {
            return;
        }
        this.makeMove(dates.from, dates.to, 'left');
    };

    public moveRangeRight = () => {
        const { dates } = this.props;
        if (!dates) {
            return;
        }
        this.makeMove(dates.from, dates.to, 'right');
    };

    public render() {
        const { classes, dates, userSettings, error, small, calendarIconOnly } =
            this.props;

        const { from, to } = dates;

        return (
            <>
                {
                    <>
                        <DatesAsButton
                            classes={classes}
                            from={moment(from)}
                            to={moment(to)}
                            onClick={() => this.openDialog()}
                            isNavigationEnabled={true}
                            moveRangeLeft={() => this.moveRangeLeft()}
                            moveRangeRight={() => this.moveRangeRight()}
                            dateFormat={userSettings.shortDateFormat}
                            error={error}
                            small={small}
                            calendarIconOnly={calendarIconOnly}
                        />

                        <DatePickerDialog
                            newFrom={moment(this.state.newFromDate)}
                            newTo={moment(this.state.newToDate)}
                            isOpen={this.state.isTimePickerEnabled}
                            close={this.closeDialog}
                            fromChange={this.handleNewFromDate}
                            toChange={this.handleNewToDate}
                            confirm={() => {
                                this.handleDatesChange(
                                    this.state.newFromDate,
                                    this.state.newToDate
                                );
                                this.closeDialog();
                            }}
                            quickDates={this.quickDateControls}
                            handleQuickDates={this.handleQuickDate}
                            dateFormat={userSettings.shortDateFormat}
                            title="Select date range"
                        />
                    </>
                }
            </>
        );
    }

    private handleNewFromDate = (date: string) => {
        this.setState({ newFromDate: date });
    };

    private handleNewToDate = (date: string) => {
        this.setState({ newToDate: date });
    };

    private closeDialog = () => {
        this.setState({ isTimePickerEnabled: false });
    };

    private openDialog = () => {
        this.setState({ isTimePickerEnabled: true });
    };
}

export default compose(
    Themable,
    Connectable
)(DateRangeDisplay) as ComponentType<IOwnProps>;
