import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => {
    const spacing = theme.spacing(2);
    return createStyles({
        copyIcon: {
            width: '10%',
            display: 'flex',
            justifyContent: 'end',
        },
        iconButton: {
            color: 'grey',
        },
        itemLabel: {
            fontFamily: 'OpenSans-Regular',
            fontSize: theme.typography.caption.fontSize,
            padding: `${theme.spacing()}px ${theme.spacing()}px ${theme.spacing()}px 0 `,
            fontWeight: 'bold',
            wordWrap: 'break-word',
            width: '60%',
        },
        itemValue: {
            fontFamily: 'OpenSans-Regular',
            textAlign: 'right',
            width: '50%',
            wordBreak: 'break-word',
        },
        listItem: {
            minHeight: '3vh',
            fontSize: theme.typography.caption.fontSize,
            justifyContent: 'space-between',
            color: theme.palette.primary.dark,
        },
        sectionTitle: {
            padding: spacing,
        },
    });
});
