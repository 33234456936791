import { debounce, uniq } from 'lodash';
import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { makeReduxDuck } from 'teedux';
import { fetchData, reset } from '../fetching/searchResultsFetching';

interface IState {
    searchPhrase: string;
    foundMonitoredObjectIds: string[] | null;
}

const initialState: IState = {
    searchPhrase: '',
    foundMonitoredObjectIds: null,
};

const duck = makeReduxDuck('app/discovery/searcher', initialState);

const updateSearchPhraseAction = duck.defineAction<{
    searchPhrase: string;
}>('UPDATE_SEARCH_PHRASE', (state, { searchPhrase }) => ({
    searchPhrase,
}));

const storeFoundMonitoredObjectIdsAction = duck.defineAction<{
    foundMonitoredObjectIds: string[];
}>(
    'STORE_FOUND_MONITORED_OBJECT_IDS',
    (state, { foundMonitoredObjectIds }) => ({
        foundMonitoredObjectIds,
    })
);

const resetAction = duck.definePayloadlessAction('RESET', () => initialState);

export default duck.getReducer();

export const updateSearchPhrase =
    (
        searchPhrase: string,
        isMobile?: boolean
    ): ThunkAction<void, null, null, Action> =>
    (dispatch) => {
        dispatch(
            updateSearchPhraseAction({
                searchPhrase,
            })
        );
        if (searchPhrase === '') {
            dispatch(reset());
            dispatch(resetSearcher());
        } else {
            if (isMobile) {
                debouncedMobileFetchData(searchPhrase.trim(), dispatch);
            } else {
                debouncedFetchData(searchPhrase.trim(), dispatch);
            }
        }
    };

const debouncedFetchData = debounce((trimmedSearchPhrase, dispatch) => {
    dispatch(fetchData(trimmedSearchPhrase));
}, 500);

// TODO
// ideally fetching should be separate from updating input and debounce should be moved to the component (useDebounce if functional)
const debouncedMobileFetchData = debounce((trimmedSearchPhrase, dispatch) => {
    dispatch(fetchData(trimmedSearchPhrase));
}, 1500);

export const storeFoundMonitoredObjectIds = (
    foundMonitoredObjectIds: string[]
) =>
    storeFoundMonitoredObjectIdsAction({
        foundMonitoredObjectIds: uniq(foundMonitoredObjectIds),
    });

export const resetSearcher = () => resetAction();
