import { Record } from 'immutable';

class EmployeeAddress extends Record({
    city: undefined,
    postalCode: undefined,
    street: undefined,
    houseNumber: undefined,
    apartmentNumber: undefined,
}) {
    static fromJson(options) {
        return new EmployeeAddress(options);
    }

    toJson() {
        return this.toObject();
    }
}

export default EmployeeAddress;
