import { connect } from 'react-redux';
import { TRootState } from '../../store';

import {
    hasAccessToAllVehicles,
    hasAccessToCards,
    hasAccessToEmployees,
    hasAccessToTanks,
    hasAccessToVehicles,
} from '../../state/user';

const mapStateToProps = (state: TRootState) => ({
    hasAccessToCards: hasAccessToCards(state),
    hasAccessToVehicles: hasAccessToVehicles(state),
    hasAccessToEmployees: hasAccessToEmployees(state),
    hasAccessToTanks: hasAccessToTanks(state),
    hasAccessToAllVehicles: hasAccessToAllVehicles(state),
});

const mapDispatchToProps = {};

type TStateProps = ReturnType<typeof mapStateToProps>;
type TDispatchProps = typeof mapDispatchToProps;

export type TConnectableProps = TStateProps & TDispatchProps;

export const Connectable = connect(mapStateToProps, mapDispatchToProps);
