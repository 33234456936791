import React from 'react';

import { Field, FieldProps, FormikErrors, FormikValues } from 'formik';

import TranslationHelper from '../../../../../../../helpers/TranslationHelper';
import FieldWrapper from '../../../../../../../components/common/formikWrappers/FieldWrapper';
import CheckboxWrapper from '../../../../../../../components/common/formikWrappers/CheckboxWrapper';
import DateRangeDisplay from '../../../../../../../components/DateRangeDisplay';
import { IRefuellAndLossConfigurationForm } from '../../../../../../../state/ui/forms';
import {
    fetchOnDemandData,
    IGetFuelingOnDemandParams,
} from '../../../../../../../state/app/refuellAndLossConfiguration/index';
import FieldWithTooltip from '../../../../../../../components/FieldWithTooltip/FieldWithTooltip';
import PaneSectionWithTitle from '../../PaneSectionWithTitle/index';
import { useStyles } from '../Themable.hooks';

import {
    Button,
    FormControlLabel,
    FormHelperText,
    Tooltip,
} from '@material-ui/core';
import { InfoOutlined as InfoIcon } from '@material-ui/icons';
import RefreshIcon from '@material-ui/icons/Autorenew';
import { useAppDispatch } from '../../../../../../../hooks';

interface IOwnProps {
    values: FormikValues;
    errors: FormikErrors<IRefuellAndLossConfigurationForm>;
    setFieldValue: any;
    updateEventsDates: (from: string, to: string, { form }: FieldProps) => void;
    fetchParams: IGetFuelingOnDemandParams | null;
    validateForm: (values?: any) => Promise<FormikErrors<FormikValues>>;
}

const DetectionSettingsForm: React.FC<IOwnProps> = ({
    values,
    errors,
    setFieldValue,
    updateEventsDates,
    fetchParams,
    validateForm,
}) => {
    const classes = useStyles();

    const dispatch = useAppDispatch();

    const dates = {
        from: values.params.referentialFrom,
        to: values.params.referentialTo,
    };

    const commonProps = {
        fullWidth: true,
        component: FieldWrapper,
    };

    const refreshData = () => {
        if (fetchParams) {
            dispatch(
                fetchOnDemandData(
                    fetchParams,
                    values as IRefuellAndLossConfigurationForm
                )
            );
        }
    };

    const handleDateChange = (
        from: string,
        to: string,
        fieldProps: FieldProps
    ) => {
        updateEventsDates(from, to, fieldProps);

        if (fetchParams) {
            dispatch(
                fetchOnDemandData(
                    {
                        ...fetchParams,
                        from,
                        to,
                    },
                    values as IRefuellAndLossConfigurationForm
                )
            );
        }
    };

    const checkboxField = (
        label: string,
        fieldName: string,
        onClick: () => void
    ) => {
        return (
            <FormControlLabel
                label={TranslationHelper.translate(label)}
                control={
                    <Field
                        name={fieldName}
                        component={CheckboxWrapper}
                        onClick={onClick}
                    />
                }
            />
        );
    };

    return (
        <div>
            <Field name={'params.referentialFrom'}>
                {(fieldProps: FieldProps) => (
                    <>
                        <DateRangeDisplay
                            onDateChange={(from, to) =>
                                handleDateChange(from, to, fieldProps)
                            }
                            dates={dates}
                            error={!!(errors && errors.params?.referentialFrom)}
                        />
                        <FormHelperText
                            error={!!(errors && errors.params?.referentialFrom)}
                        >
                            {errors && errors.params?.referentialFrom}
                        </FormHelperText>
                    </>
                )}
            </Field>
            <PaneSectionWithTitle
                classes={classes}
                title="Detection"
                tooltipTitle="detection.tooltip"
            >
                <div className={classes.paneContent}>
                    <FieldWithTooltip
                        title={'detection.levelThreshold.tooltip'}
                    >
                        <Field
                            error={
                                !!(errors && errors.detector?.levelThreshold)
                            }
                            helperText={
                                errors && errors.detector?.levelThreshold
                            }
                            name={'detector.levelThreshold'}
                            label={TranslationHelper.translate(
                                'detection.levelThreshold'
                            )}
                            type={'number'}
                            required={true}
                            {...commonProps}
                        />
                    </FieldWithTooltip>
                    <FieldWithTooltip
                        title={'detection.refuellingThreshold.tooltip'}
                    >
                        <Field
                            error={
                                !!(
                                    errors &&
                                    errors.detector?.refuelingThreshold
                                )
                            }
                            helperText={
                                errors && errors.detector?.refuelingThreshold
                            }
                            name={'detector.refuelingThreshold'}
                            label={TranslationHelper.translate(
                                'detection.refuellingThreshold'
                            )}
                            type={'number'}
                            required={true}
                            {...commonProps}
                        />
                    </FieldWithTooltip>
                    <div className={classes.flexCentered}>
                        <Field
                            error={!!(errors && errors.detector?.lossThreshold)}
                            helperText={
                                errors && errors.detector?.lossThreshold
                            }
                            name={'detector.lossThreshold'}
                            label={TranslationHelper.translate(
                                'detection.lossThreshold'
                            )}
                            type={'number'}
                            required={true}
                            {...commonProps}
                        />
                        <Tooltip
                            title={TranslationHelper.translate(
                                'detection.lossThreshold.tooltip'
                            )}
                        >
                            <div className={classes.icon} data-testid="icon">
                                <InfoIcon fontSize="small" />
                            </div>
                        </Tooltip>
                    </div>
                    <FieldWithTooltip title={'detection.samplesNumber.tooltip'}>
                        <Field
                            error={!!(errors && errors.detector?.samplesNumber)}
                            helperText={
                                errors && errors.detector?.samplesNumber
                            }
                            name={'detector.samplesNumber'}
                            label={TranslationHelper.translate(
                                'detection.samplesNumber'
                            )}
                            type={'number'}
                            required={true}
                            {...commonProps}
                        />
                    </FieldWithTooltip>
                </div>
            </PaneSectionWithTitle>
            <PaneSectionWithTitle
                classes={classes}
                title="Simplify"
                tooltipTitle="simplify.tooltip"
            >
                <div className={classes.paneContent}>
                    <FieldWithTooltip title={'simplify.enabled.tooltip'}>
                        {checkboxField('Enabled', 'simplify.enabled', () =>
                            setFieldValue(
                                'simplify.enabled',
                                !values.simplify.enabled
                            )
                        )}
                    </FieldWithTooltip>
                    <FieldWithTooltip title={'simplify.tolerance.tooltip'}>
                        <Field
                            error={!!(errors && errors.simplify?.tolerance)}
                            helperText={errors && errors.simplify?.tolerance}
                            name={'simplify.tolerance'}
                            label={TranslationHelper.translate(
                                'simplify.tolerance'
                            )}
                            type={'number'}
                            required={true}
                            {...commonProps}
                        />
                    </FieldWithTooltip>
                    <FieldWithTooltip title={'simplify.highQuality.tooltip'}>
                        {checkboxField(
                            'simplify.highQuality',
                            'simplify.highQuality',
                            () =>
                                setFieldValue(
                                    'simplify.highQuality',
                                    !values.simplify.highQuality
                                )
                        )}
                    </FieldWithTooltip>
                </div>
            </PaneSectionWithTitle>
            <PaneSectionWithTitle
                classes={classes}
                title="smooth"
                tooltipTitle="smooth.tooltip"
            >
                <div className={classes.paneContent}>
                    <FieldWithTooltip title={'smooth.enabled.tooltip'}>
                        {checkboxField('Enabled', 'smooth.enabled', () =>
                            setFieldValue(
                                'smooth.enabled',
                                !values.smooth.enabled
                            )
                        )}
                    </FieldWithTooltip>
                    <FieldWithTooltip title={'smooth.bandwidth.tooltip'}>
                        <Field
                            error={!!(errors && errors.smooth?.bandwidth)}
                            helperText={errors && errors.smooth?.bandwidth}
                            name={'smooth.bandwidth'}
                            label={TranslationHelper.translate(
                                'smooth.bandwidth'
                            )}
                            type={'number'}
                            required={true}
                            {...commonProps}
                        />
                    </FieldWithTooltip>
                </div>
            </PaneSectionWithTitle>
            <div className={classes.paneContent}>
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<RefreshIcon />}
                    onClick={() => validateForm().then(() => refreshData())}
                >
                    {TranslationHelper.translate('Refresh')}
                </Button>
            </div>
        </div>
    );
};

export default DetectionSettingsForm;
