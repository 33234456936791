import React from 'react';

import { FormikActions, FormikValues, FormikErrors } from 'formik';

import cloneDeep from 'lodash/cloneDeep';

import { LinearProgress } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';

import {
    goToPreviewMode,
    setLoading,
    updateDevice,
} from '../../../../../../../../state/ui/devices';
import { IPrivileges } from '../../../../../../../../state/auth';
import { useSelectedDevice } from '../../../../../../../../state/ui/devices/index.hooks';
import { usePrivileges } from '../../../../../../../../state/auth/index.hooks';

import TranslationHelper from '../../../../../../../../helpers/TranslationHelper';
import { useToolkitDispatch } from '../../../../../../../../hooks';
import useAsyncThunkWithSnackbar from '../../../../../../../../hooks/useAsyncThunkWithSnackbar';

import IconButtonWithTooltip from '../../../../../../../../components/IconButtonWithTooltip/IconButtonWithTooltip';
import FieldWrapper from '../../../../../../../../components/common/formikWrappers/FieldWrapper';
import { AccessibleField } from '../../../../../../../../components/accessControl/components';
import DeviceForm from '../DeviceForm';

export interface IDeviceAttributes {
    eToll: boolean;
}

export interface IDeviceEdit {
    attributes?: {
        eToll: boolean;
    };
    integrations?: {
        eToll: {
            businessNumber: string;
            pin: string;
        };
    };
}

const DeviceEditionForm = () => {
    const device = useSelectedDevice();
    const privileges = usePrivileges();

    const initialValues: IDeviceEdit = {
        attributes: device?.attributes,
        integrations: device?.integrations,
    };

    const toolkitDispatch = useToolkitDispatch();

    const handleAsyncRequest = useAsyncThunkWithSnackbar();

    const handleClose = () => {
        toolkitDispatch(goToPreviewMode());
    };

    const updateSingleDevice = (
        id: number | undefined,
        data: IDeviceEdit,
        setSubmitting: (isSubmitting: boolean) => void
    ) => {
        if (!id) {
            return;
        }

        const prepareData = (data: IDeviceEdit) => {
            const result = cloneDeep(data);
            if (!privileges.eTollEditRegistrationData) {
                delete result.integrations;
            }
            return result;
        };

        toolkitDispatch(setLoading({ loading: true }));
        const device = { id, ...prepareData(data) };

        handleAsyncRequest({
            asyncAction: updateDevice(device),
            onSuccess: {
                callback: () => setSubmitting(false),
                message: TranslationHelper.translate('Device updated'),
            },
            onError: {
                callback: () => setSubmitting(false),
            },
        });
    };

    const handleValues = (
        data: IDeviceEdit,
        { setSubmitting }: FormikActions<FormikValues>
    ) => {
        updateSingleDevice(device?.id, data, setSubmitting);
    };

    const getPaneHeader = () => {
        return `${TranslationHelper.translate('Edit')}: ${
            device?.name || device?.serialNumber || ''
        }`;
    };

    if (!device) {
        return <LinearProgress />;
    }
    const getRightCustomControls = (
        submitForm: () => void,
        submitting: boolean
    ) => (
        <IconButtonWithTooltip
            title={TranslationHelper.translate('Save')}
            onClick={submitForm}
            disabled={submitting}
        >
            <SaveIcon />
        </IconButtonWithTooltip>
    );

    const renderAdditionalFields = (errors: FormikErrors<IDeviceEdit>) => {
        return (
            <>
                <AccessibleField
                    check={(privileges: IPrivileges) =>
                        privileges.eTollEditRegistrationData
                    }
                    error={
                        !!(
                            errors &&
                            (errors as IDeviceEdit).integrations?.eToll
                                ?.businessNumber
                        )
                    }
                    helperText={
                        errors &&
                        (errors as IDeviceEdit).integrations?.eToll
                            ?.businessNumber
                    }
                    name={'integrations.eToll.businessNumber'}
                    label={TranslationHelper.translate('Business number')}
                    fullWidth={true}
                    component={FieldWrapper}
                />
                <AccessibleField
                    check={(privileges: IPrivileges) =>
                        privileges.eTollEditRegistrationData
                    }
                    error={
                        !!(
                            errors &&
                            (errors as IDeviceEdit).integrations?.eToll?.pin
                        )
                    }
                    helperText={
                        errors &&
                        (errors as IDeviceEdit).integrations?.eToll?.pin
                    }
                    name={'integrations.eToll.pin'}
                    label={TranslationHelper.translate('PIN')}
                    fullWidth={true}
                    component={FieldWrapper}
                />
            </>
        );
    };
    return (
        <DeviceForm
            handleClose={handleClose}
            handleValues={handleValues}
            getPaneHeader={getPaneHeader}
            getRightCustomControls={getRightCustomControls}
            initialValues={initialValues}
            renderAdditionalFields={renderAdditionalFields}
        />
    );
};

export default DeviceEditionForm;
