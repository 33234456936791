import { createStyles, WithStyles } from '@material-ui/core';
import { Theme, withStyles } from '@material-ui/core/styles';

const styles = (theme: Theme) => {
    return createStyles({
        rolesPageWrapper: {
            position: 'absolute',
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            display: 'flex',
            justifyContent: 'space-between',
        },
        rolesWrapper: {
            width: '29%',
            maxHeight: '100%',
            overflowY: 'auto',
            overflowX: 'hidden',
            zIndex: 0,
        },
        permissionsOuterWrapper: {
            width: '70%',
            height: '100%',
            overflowY: 'hidden',
            overflowX: 'hidden',
            zIndex: 13,
        },
    });
};

export type TThemableProps = WithStyles<typeof styles>;

export const Themable = withStyles(styles, { withTheme: true });
