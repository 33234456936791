import React, { MutableRefObject } from 'react';
import { Checkbox, FormControlLabel, Grid, TextField } from '@material-ui/core';

import TranslationHelper from '../../../../../../helpers/TranslationHelper';
import { useStyles } from '../../Themable.hooks';
import PrivilegesListGrouped from '../PrivilegesListGrouped';
import { IPrivilegesGroup } from '../../../../selectors';
import { IFormRole } from '../../PrivilegesList';

interface IOwnProps {
    handleDirty: (dirt: boolean) => void;
    privilegesListGrouped: IPrivilegesGroup[];
    disableField: boolean;
    formData: IFormRole;
    setFormData: (formData: IFormRole) => void;
    nameRef?: MutableRefObject<HTMLInputElement | undefined>;
    descriptionRef?: MutableRefObject<HTMLInputElement | undefined>;
}

const GRID_SPACING = 1;

const PrivilegesListFormFields = ({
    formData,
    privilegesListGrouped,
    setFormData,
    handleDirty,
    disableField,
    nameRef,
    descriptionRef,
}: IOwnProps) => {
    const classes = useStyles();

    const handleSelectAll = () => {
        const selectAll = !formData.selectAll;
        const privileges = {};
        const groups = {};
        privilegesListGrouped.forEach(({ privilegesIds, groupName }) => {
            groups[groupName] = selectAll;
            privilegesIds.forEach((id) => {
                privileges[id] = selectAll;
            });
        });

        handleDirty(true);

        setFormData({
            ...formData,
            privileges: {
                ...formData.privileges,
                ...privileges,
            },
            groupSelect: {
                ...formData.groupSelect,
                ...groups,
            },
            selectAll,
        });
    };

    return (
        <div className={classes.formWrapper}>
            <div className={classes.inputsWrapper}>
                <TextField
                    name={'name'}
                    defaultValue={formData.name}
                    label={TranslationHelper.translate('Role name')}
                    role="role-name"
                    className={classes.nameField}
                    required={true}
                    disabled={disableField}
                    onChange={() => handleDirty(true)}
                    inputRef={nameRef}
                />

                <TextField
                    name={'description'}
                    defaultValue={formData.description}
                    label={TranslationHelper.translate('Role description')}
                    role="role-description"
                    className={classes.descriptionField}
                    required={true}
                    disabled={disableField}
                    onChange={() => handleDirty(true)}
                    inputRef={descriptionRef}
                />
            </div>
            <Grid
                container={true}
                spacing={GRID_SPACING}
                className={classes.privilegesGroupsWrapper}
            >
                <FormControlLabel
                    control={
                        <Checkbox
                            name="selectAll"
                            color="primary"
                            disabled={disableField}
                            checked={!!formData.selectAll}
                            indeterminate={
                                !formData.selectAll &&
                                privilegesListGrouped.some(
                                    ({ privilegesIds }) =>
                                        privilegesIds.some(
                                            (id) => !!formData.privileges[id]
                                        )
                                )
                            }
                            onClick={handleSelectAll}
                        />
                    }
                    label={TranslationHelper.translate('Select all privileges')}
                />
            </Grid>
            <PrivilegesListGrouped
                handleDirty={handleDirty}
                privilegesListGrouped={privilegesListGrouped}
                disableField={disableField}
                formData={formData}
                setFormData={setFormData}
            />
        </div>
    );
};

export default PrivilegesListFormFields;
