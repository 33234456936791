import React, { useState, useReducer, ChangeEvent } from 'react';

import { ValidationError } from 'yup';

import moment, { Moment } from 'moment';

import SaveIcon from '@material-ui/icons/Save';
import { MenuItem, TextField } from '@material-ui/core';

import { useStyles } from '../../../../Themable.hooks';

import { useLanguage } from '../../../../../../state/login/index.hooks';
import {
    INewSchedule,
    IReportsDefinition,
} from '../../../../../../state/ui/reportsSlice';
import { useReportsDefinitions } from '../../../../../../state/ui/reportsSlice/index.hooks';

import { restGetTimezoneDictionary } from '../../../../../../services/registers';

import TranslationHelper from '../../../../../../helpers/TranslationHelper';
import TimeFormatter from '../../../../../../helpers/TimeFormatter';
import { renderMenuItems } from '../../../../../../helpers/renderMenuItems';

import { reportScheduleSchema } from '../../../../../../schemas';

import { PERIODICITY_ITEMS } from '../../../../../../constants/dictionaries/reportPeriodicity';

import IconButtonWithTooltip from '../../../../../../components/IconButtonWithTooltip/IconButtonWithTooltip';
import PaneHeader from '../../../../../../components/PaneHeader/PaneHeader';
import ObjectsSection from '../../../../../../components/ObjectsSection';
import DatePresets from '../../../../../../components/DatePresets';
import AsyncSelectField from '../../../../../../components/AsyncSelectField';
import EmailsChipsWrapper from '../../../../../../components/formComponents/EmailsChipsWrapper';
import LocationField from '../../../GeneratedReports/components/ReportsForm/LocationField';

interface IOwnProps {
    title: string;
    initialState: IInitialState;
    handleClose: () => void;
    handleRequest: (data: INewSchedule, callback: () => void) => void;
}

export interface IMappedObject {
    id: number;
    key: string;
    name: string;
}
export interface IInitialState {
    reportType: {
        typeId: number | '';
        name: string;
        templateId: number | '';
        isLocationReport: boolean;
        isCustomerServiceReport: boolean;
    };
    periodicity: string;
    timeZone: {
        id: number;
        name: string;
    };
    dateTo: Moment;
    dateFrom: Moment;
    objects: IMappedObject[];
    emails?: string[];
    emailsField?: string;
    location: string;
}

export interface IErrors {
    ['reportType.templateId']?: string;
    periodicity?: string;
    timeZone?: string;
    dateTo?: string;
    dateFrom?: string;
    objects?: string;
}

const mapPeriodicityItems = (items: string[]) => {
    return items.map((item) => (
        <MenuItem key={item} value={item}>
            {TranslationHelper.translate(item)}
        </MenuItem>
    ));
};
const SchedulesForm = ({
    title,
    handleClose,
    initialState,
    handleRequest,
}: IOwnProps) => {
    const classes = useStyles();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [errors, setErrors] = useState<IErrors>({});

    const language = useLanguage();

    const reportsDefinitions = useReportsDefinitions();

    const [formValues, setFormValues] = useReducer(
        (curVals, newVals) => ({ ...curVals, ...newVals }),
        initialState
    );

    const {
        reportType,
        dateFrom,
        dateTo,
        objects,
        periodicity,
        timeZone,
        location,
    } = formValues;

    const handleFormChange = <T,>(name: string, value: T) => {
        setFormValues({ [name]: value });
    };

    const getRightCustomControls = (submitting: boolean) => (
        <IconButtonWithTooltip
            title={TranslationHelper.translate('Save')}
            onClick={handleValues}
            disabled={submitting}
        >
            <SaveIcon />
        </IconButtonWithTooltip>
    );
    const validateFields = () => {
        reportScheduleSchema
            .validate(formValues, { abortEarly: false })
            .then(() => {
                setErrors({});
            })

            .catch((err: ValidationError) => {
                const newErrors = err.inner.reduce((acc, error) => {
                    acc[error.path || ''] = TranslationHelper.translate(
                        error.errors[0]
                    );
                    return acc;
                }, {});
                setErrors(newErrors);
            });
    };

    const prepareData = (data: IInitialState) => {
        const requestData: INewSchedule = {
            type: Number(data.reportType.typeId),
            repetition: {
                interval: data.periodicity,
                startBoundaryDate: TimeFormatter.dateToShortDateString(
                    data.dateFrom
                ),
                endBoundaryDate: TimeFormatter.dateToShortDateString(
                    data.dateTo
                ),
            },
            timeZone: data.timeZone.id,
            subjects: data.objects.map((o) => o.id),
            template: Number(data.reportType.templateId),
            language,
            emails: data.emails,
        };
        return requestData;
    };

    const handleClearForm = () => {
        setIsSubmitting(false);
        setErrors({});
        handleClose();
    };

    const handleValues = async () => {
        const isDataValid = await reportScheduleSchema.isValid(formValues);

        if (isDataValid) {
            setIsSubmitting(true);

            const data = prepareData(formValues);
            handleRequest(data, handleClearForm);
        } else {
            validateFields();
            setIsSubmitting(false);
        }
    };

    const handleChangePeriodicity = (e: ChangeEvent<HTMLInputElement>) => {
        handleFormChange('periodicity', e.target.value);
        const dateFrom = () => {
            switch (e.target.value) {
                case PERIODICITY_ITEMS.daily:
                    return moment().startOf('day');
                case PERIODICITY_ITEMS.weekly:
                    return moment().day(1 + 7);
                case PERIODICITY_ITEMS.monthly:
                    return moment().startOf('month').add(1, 'month');
                default:
                    return moment().startOf('day');
            }
        };
        handleFormChange('dateFrom', dateFrom());
        handleFormChange('dateTo', dateFrom().add(1, 'day'));
    };

    const handleErrors = (name: string, value: string) => {
        return setErrors({ [name]: value });
    };

    return (
        <form onSubmit={handleValues}>
            <PaneHeader
                onCloseClick={handleClose}
                title={TranslationHelper.translate(title)}
                renderRightCustomControls={() =>
                    getRightCustomControls(isSubmitting)
                }
                submitting={isSubmitting}
            />

            <div className={classes.formWrapper}>
                <TextField
                    error={!!errors?.['reportType.templateId']}
                    helperText={errors?.['reportType.templateId']}
                    name="reportType"
                    label={TranslationHelper.translate('Report')}
                    value={reportType.templateId}
                    onChange={(e) => {
                        handleFormChange('objects', initialState.objects);
                        handleFormChange('location', initialState.location);
                        if (reportsDefinitions) {
                            const report = reportsDefinitions.filter(
                                (definition) =>
                                    definition.templateId ===
                                    Number(e.target.value)
                            )[0];
                            handleFormChange('reportType', report);
                        }
                    }}
                    select
                    fullWidth
                    required
                    data-testid={'reportType'}
                    className={classes.field}
                >
                    {renderMenuItems(
                        reportsDefinitions || [],
                        (item: IReportsDefinition) => {
                            return {
                                id: String(item.templateId),
                                name: item.name,
                            };
                        }
                    )}
                </TextField>
                <TextField
                    error={!!errors?.periodicity}
                    helperText={errors?.periodicity}
                    name="periodicity"
                    label={TranslationHelper.translate('Periodicity')}
                    value={periodicity}
                    onChange={handleChangePeriodicity}
                    select
                    fullWidth
                    required
                    data-testid={'periodicity'}
                    className={classes.field}
                >
                    {mapPeriodicityItems(Object.values(PERIODICITY_ITEMS))}
                </TextField>
                <EmailsChipsWrapper
                    formValues={formValues}
                    handleErrors={handleErrors}
                    handleFormChange={handleFormChange}
                    errors={errors}
                    classes={{ field: classes.fieldMulti }}
                />
                <AsyncSelectField
                    key={'timeZone'}
                    required
                    label={TranslationHelper.translate('Time zone')}
                    getOptions={restGetTimezoneDictionary}
                    error={errors?.['timeZone.name']}
                    onChange={(e, value) => handleFormChange('timeZone', value)}
                    value={timeZone}
                    disableClearable={true}
                />

                <DatePresets
                    dateFrom={dateFrom}
                    dateTo={dateTo}
                    handleFormChange={handleFormChange}
                    errors={errors}
                    title="schedule.duration"
                    showButtons={false}
                    dateOnly
                    disablePast
                />
                {reportType.isLocationReport ? (
                    <LocationField
                        errors={errors.objects}
                        location={location}
                        onChange={handleFormChange}
                        subjectsKey="id"
                        extended={reportType.isCustomerServiceReport}
                    />
                ) : (
                    <ObjectsSection
                        objects={objects}
                        handleFormChange={handleFormChange}
                        errors={errors?.objects}
                    />
                )}
            </div>
        </form>
    );
};

export default SchedulesForm;
