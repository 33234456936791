import React, { useState } from 'react';

import { Field, FieldArray, FormikErrors, FormikValues } from 'formik';

import Button from '@mui/material/Button';
import { default as ListItem } from '@mui/material/ListItem';
import { default as ListItemText } from '@mui/material/ListItemText';
import { Collapse } from '@mui/material';
import { default as IconButton } from '@mui/material/IconButton';
import { default as ExpandLess } from '@mui/icons-material/ExpandLess';
import { default as ExpandMore } from '@mui/icons-material/ExpandMore';
import CloseIcon from '@mui/icons-material/Close';

import { useStyles } from './Themable.hooks';

import TranslationHelper from '../../../../../../../../../../helpers/TranslationHelper';

import { mapDictionaryItems } from '../../../../../../_utils';

import { APPLICATION_ITEMS } from '../../../../../../../../../../constants/dictionaries/ExternalApplicationItems';
import { DIGITS_ONLY_REGEX } from '../../../../../../../../../../constants/regex';

import NumberFieldWrapper from '../../../../../../../../../../components/common/formikWrappers/NumberFieldWrapper';
import FieldWrapper from '../../../../../../../../../../components/common/formikWrappers/FieldWrapper';

const CONDITIONS_MAX_LENGTH = 5;

interface IOwnProps {
    values: FormikValues;
    errors: FormikErrors<any>;
}
const TaskLinkagesSection = ({ values, errors }: IOwnProps) => {
    const classes = useStyles();
    const [open, setOpen] = useState(true);

    const handleExpandClick = () => {
        setOpen(!open);
    };
    return (
        <>
            <ListItem disableGutters={true} className={classes.listItemHeading}>
                <ListItemText
                    primary={TranslationHelper.translate('Linkages')}
                    className={classes.heading}
                />
                <IconButton onClick={handleExpandClick}>
                    {open ? <ExpandLess /> : <ExpandMore />}
                </IconButton>
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit={true}>
                <FieldArray name="externalLinkages" validateOnChange={false}>
                    {({ remove, push }) => {
                        return (
                            <div className={classes.linkagesContainer}>
                                {values &&
                                    values.externalLinkages?.map(
                                        (
                                            value: {
                                                appName: string;
                                                externalId: string;
                                            },
                                            index: number
                                        ) => (
                                            <div
                                                className={classes.linkage}
                                                key={index}
                                            >
                                                <Field
                                                    name={`externalLinkages.${index}.appName`}
                                                    component={FieldWrapper}
                                                    label={TranslationHelper.translate(
                                                        'linkage.application'
                                                    )}
                                                    required={true}
                                                    error={
                                                        !!errors
                                                            ?.externalLinkages?.[
                                                            index
                                                        ]?.appName
                                                    }
                                                    helperText={
                                                        errors
                                                            ?.externalLinkages?.[
                                                            index
                                                        ]?.appName
                                                    }
                                                    select={true}
                                                    className={classes.fieldSm}
                                                >
                                                    {mapDictionaryItems(
                                                        APPLICATION_ITEMS
                                                    )}
                                                </Field>
                                                <Field
                                                    name={`externalLinkages.${index}.externalId`}
                                                    component={
                                                        NumberFieldWrapper
                                                    }
                                                    regex={DIGITS_ONLY_REGEX}
                                                    fullWidth={true}
                                                    label={TranslationHelper.translate(
                                                        'linkage.identificator'
                                                    )}
                                                    required={true}
                                                    error={
                                                        !!errors
                                                            ?.externalLinkages?.[
                                                            index
                                                        ]?.externalId
                                                    }
                                                    helperText={
                                                        errors
                                                            ?.externalLinkages?.[
                                                            index
                                                        ]?.externalId
                                                    }
                                                    className={classes.fieldMd}
                                                />
                                                <IconButton
                                                    aria-label="close"
                                                    onClick={() => {
                                                        remove(index);
                                                    }}
                                                >
                                                    <CloseIcon />
                                                </IconButton>
                                            </div>
                                        )
                                    )}
                                <Button
                                    type="button"
                                    variant="contained"
                                    color="primary"
                                    disabled={
                                        values.externalLinkages?.length >=
                                        CONDITIONS_MAX_LENGTH
                                    }
                                    onClick={() => {
                                        push({
                                            appName: APPLICATION_ITEMS[0].id,
                                            externalId: '',
                                        });
                                    }}
                                >
                                    {TranslationHelper.translate('Add linkage')}
                                </Button>
                            </div>
                        );
                    }}
                </FieldArray>
            </Collapse>
        </>
    );
};
export default TaskLinkagesSection;
