import React, { useEffect, useState } from 'react';

import queryString from 'query-string';

import { LoginPageBackGround } from '../LoginPage/LoginPageBackGround';
import { LoginPageLogo } from '../LoginPage/LoginPageLogo';
import LoginFormFooter from '../../../components/Authentication/LoginFormFooter';

import { useToolkitDispatch } from '../../../hooks';
import { errorMessageHandler } from '../../../helpers/errorMessageHandler';

import { useStyles } from '../Themable.hooks';
import { confirmTrustedDevice } from '../../../state/ui/trustedDevices';
import { useDeviceRegisterStatus } from '../../../state/ui/trustedDevices/index.hooks';
import LoadingSpinner from '../../../components/loadingSpinner/LoadingSpinner';
import TranslationHelper from '../../../helpers/TranslationHelper';
import { Alert } from '@material-ui/lab';
import clsx from 'clsx';

const TrustedDeviceConfirmation = () => {
    const classes = useStyles();

    const toolkitDispatch = useToolkitDispatch();
    const status = useDeviceRegisterStatus();

    const [error, setError] = useState<null | {
        status: number;
        errorMsg: string;
    }>(null);
    useEffect(() => {
        const query = queryString.parse(location.search, {});
        toolkitDispatch(confirmTrustedDevice(query.id))
            .unwrap()
            .catch((e) => setError(e));
    }, []);
    const renderAlert = () => {
        if (status === 'loading') {
            return (
                <div className={classes.spinner}>
                    <LoadingSpinner progressSize="60px" />
                </div>
            );
        }
        if (status === 'done') {
            return (
                <Alert severity="success">
                    {TranslationHelper.translate('auth.acceptedTrustedDevice')}
                </Alert>
            );
        }
        if (error) {
            const message = errorMessageHandler(error.status, {
                422: TranslationHelper.translate(
                    'auth.trustedDeviceAlreadyConfirmed'
                ),
            })();
            return (
                <Alert severity="error">
                    {TranslationHelper.translate(message)}
                </Alert>
            );
        }
        return <></>;
    };
    return (
        <LoginPageBackGround>
            <div
                className={clsx(
                    classes.loginForm,
                    classes.trustedDeviceBackground
                )}
            >
                <LoginPageLogo />
                {renderAlert()}
                <LoginFormFooter />
            </div>
        </LoginPageBackGround>
    );
};

export default TrustedDeviceConfirmation;
