import { createStyles, Theme, WithStyles } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme: Theme) => {
    const padding = theme.spacing(2);
    return createStyles({
        formRow: {
            display: 'flex',
            alignItems: 'center',
            margin: `0 -${padding}px`,
            padding: `${theme.spacing()}px ${padding}px`,
            borderBottom: '1px solid rgba(140, 140, 140, 0.5)',
        },
        combobox: {
            flexGrow: 1,
            '&:nth-child(2)': {
                marginLeft: padding,
            },
        },
        listItemHeading: {
            paddingLeft: theme.spacing(2),
            paddingBottom: 0,
            paddingTop: 0,
            color: theme.palette.primary.contrastText,
            backgroundColor: theme.palette.grey[200],
            margin: `0 -${padding}px`,
            width: 'auto',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        heading: {
            fontSize: theme.typography.subtitle1.fontSize,
            color: theme.palette.primary.dark,
        },
    });
};

export type TThemableProps = WithStyles<typeof styles, true>;

export const Themable = withStyles(styles, { withTheme: true });
