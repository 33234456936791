import TileLayer from 'ol/layer/Tile';
import OSMSource from 'ol/source/OSM';
import XYZ from 'ol/source/XYZ';

import AppConfig from '../../../../../constants/AppConfig';
import TranslationHelper from '../../../../../helpers/TranslationHelper';

import { TILE_LAYERS } from './const';

interface ILayerHere {
    type: string;
    apiKey: string;
}

class LayerOSM {
    public urls: string[] = [
        'https://tiles1.osm.xtrack.com/tile/{z}/{x}/{y}.png',
        'https://tiles2.osm.xtrack.com/tile/{z}/{x}/{y}.png',
        'https://tiles3.osm.xtrack.com/tile/{z}/{x}/{y}.png',
        'https://tiles4.osm.xtrack.com/tile/{z}/{x}/{y}.png',
    ];
}

const generatedOSMTileLayer = (layer: LayerOSM, language: string) => {
    const OSMTileSource = new OSMSource({
        attributions: [
            '<a href="http://www.openstreetmap.org/copyright/' +
                language +
                '" target="_blank" rel="noopener noreferrer">' +
                TranslationHelper.translate('© OpenStreetMap contributors') +
                '</a>',
        ],
    });

    OSMTileSource.setUrls(layer.urls);

    return new TileLayer({
        // @ts-ignore
        baseLayer: true,
        title: 'OSM',
        visible: false,
        source: OSMTileSource,
    });
};

const generateHereTileLayer = (layer: ILayerHere) => {
    const hereLayers = {
        'here-normal': {
            title: `HERE (${TranslationHelper.translate('normal')})`,
            scheme: 'explore.day',
        },
        'here-satellite': {
            title: `HERE (${TranslationHelper.translate('satellite')})`,
            scheme: 'satellite.day',
        },
        'here-hybrid': {
            title: `HERE (${TranslationHelper.translate('hybrid')})`,
            scheme: 'explore.satellite.day',
        },
    };

    const urlTpl =
        'https://maps.hereapi.com/v3/base/mc/{z}/{x}/{y}/png?style={scheme}&apiKey={apiKey}';

    const createUrl = (
        tpl: string,
        userLayer: ILayerHere,
        hereLayersData: any
    ) => {
        return tpl
            .replace('{scheme}', hereLayersData.scheme)
            .replace('{apiKey}', userLayer.apiKey);
    };

    return new TileLayer({
        visible: false,
        preload: Infinity,
        // @ts-ignore
        baseLayer: true,
        title: hereLayers[layer.type].title,
        source: new XYZ({
            url: createUrl(urlTpl, layer, hereLayers[layer.type]),
            attributions:
                'Map Tiles &copy; ' +
                new Date().getFullYear() +
                ' ' +
                '<a href="http://developer.here.com">HERE</a>',
        }),
    });
};

export const generateTileLayers = (language: string) => {
    const userLayers: any[] = AppConfig.instance.getConfigKey(
        AppConfig.PROPERTY_LAYERS
    );

    let generatedLayers: any = [];

    generatedLayers = userLayers
        //@ts-ignore
        .map((layer) => {
            switch (layer.type) {
                case TILE_LAYERS.OSM:
                    return generatedOSMTileLayer(
                        { ...new LayerOSM(), ...layer },
                        language
                    );

                case TILE_LAYERS.HERE_NORMAL:
                    return generateHereTileLayer(layer as ILayerHere);

                case TILE_LAYERS.HERE_SATELLITE:
                    return generateHereTileLayer(layer as ILayerHere);

                case TILE_LAYERS.HERE_HYBRID:
                    return generateHereTileLayer(layer as ILayerHere);

                default:
                    break;
            }
        })
        .filter(Boolean);

    if (generatedLayers.length === 0) {
        generatedLayers.push(generatedOSMTileLayer(new LayerOSM(), language));
    }

    generatedLayers[0].values_.visible = true;
    return generatedLayers;
};
