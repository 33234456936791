import URI from 'urijs';

import { IAddress, ILocation, IPage } from '../../state/types';
import { ILocationAddress } from '../../state/app/locations';

import AppConfig from '../../constants/AppConfig';
import UrlConst from '../../constants/UrlConst';

import apiClient from '../common/apiClient';
import {
    makeDeleteRequestSettings,
    makeGetRequestSettings,
    makePatchRequestSettings,
    makePostRequestSettings,
    makePutRequestSettings,
    makeRestReadPageCollection,
    makeUrl,
} from '../common/utils';
import {
    IGetLocationGroupEmptyAssignmentsParams,
    IRestPatchGroupAssignments,
} from '../groups';

export interface IRestLocationAddress {
    city: string;
    street?: string;
    streetNumber?: string;
    areaName0?: string; // country
    areaName1?: string; // voivodeship
    areaName2?: string; // county
    areaName3?: string; // commune
    zip?: string;
    apartmentNo?: string;
}

export interface IRestLocationCoordinate {
    x: number;
    y: number;
}

export interface IRestPostLocation {
    coordinate: IRestLocationCoordinate;
    name: string;
    address: IAddress;
    notes: string;
    externalNo?: string;
    clientId?: number;
    groupIds?: string[];
}

export interface IRestPutLocation {
    id: string;
    externalId: number;
    coordinate: IRestLocationCoordinate;
    name: string;
    address: IAddress;
    notes: string;
    externalNo?: string;
    clientId?: number;
    groupIds?: string[];
}

export interface ILocationGroupAssignment {
    name: string;
    relationId: string;
    address: IAddress;
}

export const makeLocationsUrl = (suffix: string, params = {}) => {
    const url = new URI(
        AppConfig.instance.getUrl(UrlConst.LOCATIONS_URL) + suffix
    );
    return makeUrl(url, params);
};

export const restGetLocation = (
    id: string,
    context?: string
): Promise<ILocation> =>
    apiClient.send(
        makeGetRequestSettings(makeLocationsUrl(`/${id}`, { context }))
    );

export const restGetLocations = (queryParams: {
    limit: number;
    context: string;
}): Promise<IPage<ILocation>> =>
    apiClient.sendWithoutErrorsHandling(
        makeGetRequestSettings(makeLocationsUrl('', queryParams))
    );

export const restReadLocationsCollection = makeRestReadPageCollection(
    restGetLocations,
    'externalId'
);

export const restGetLocationAddress = (
    queryParams: ILocationAddress
): Promise<{ x: number; y: number }> =>
    apiClient.send(
        makeGetRequestSettings(makeLocationsUrl('/geocode', queryParams))
    );

export const restGetLocationGroupAssignments = (
    id: string
): Promise<ILocationGroupAssignment[]> =>
    apiClient.sendWithoutErrorsHandling(
        makeGetRequestSettings(makeLocationsUrl(`/groups/${id}/assignments`))
    );

export const restGetEmptyLocationGroupAssignments = (
    queryParams?: IGetLocationGroupEmptyAssignmentsParams
): Promise<ILocationGroupAssignment[]> =>
    apiClient.sendWithoutErrorsHandling(
        makeGetRequestSettings(
            makeLocationsUrl('/groups/assignments', queryParams)
        )
    );

export const restPatchLocationGroupAssignments = (
    id: string,
    assignments: IRestPatchGroupAssignments
): Promise<null> =>
    apiClient.sendWithoutErrorsHandling(
        makePatchRequestSettings(
            makeLocationsUrl(`/groups/${id}/assignments`),
            assignments
        )
    );

export const restPostLocation = (location: IRestPostLocation): Promise<null> =>
    apiClient.send(makePostRequestSettings(makeLocationsUrl('/'), location));

export const restPutLocation = (location: IRestPutLocation): Promise<null> =>
    apiClient.send(
        makePutRequestSettings(makeLocationsUrl(`/${location.id}`), location)
    );

export const restDeleteLocation = (id: string): Promise<null> =>
    apiClient.send(makeDeleteRequestSettings(makeLocationsUrl(`/${id}`)));
