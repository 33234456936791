import React, { memo, useCallback } from 'react';

import { useStyles } from './Themable.hoc';

import { FieldProps } from 'formik';

import { Button, FormHelperText, Typography } from '@material-ui/core';

import TranslationHelper from '../../../../helpers/TranslationHelper';

interface IOwnProps {
    error?: boolean;
    acceptExtensions?: string[];
}

type TProps = IOwnProps & FieldProps;

const SingleFileUploadWrapper = (props: TProps) => {
    const { form, field, error, acceptExtensions } = props;
    const classes = useStyles();

    const handleFileChange = (e: any) => {
        const [file] = e.target.files;

        if (!file) {
            return;
        }
        const reader = new FileReader();
        reader.onloadend = () => {
            form.setFieldValue(field.name, file);
        };
        reader.readAsDataURL(file);
    };

    const getAcceptedExtensions = useCallback(() => {
        if (!acceptExtensions) {
            return;
        }

        if (acceptExtensions.length > 1) {
            return acceptExtensions?.join(',.');
        } else {
            return `.${acceptExtensions}`;
        }
    }, [acceptExtensions]);

    return (
        <>
            <div className={classes.wrapper}>
                <Button
                    aria-label="Add"
                    component="label"
                    color="primary"
                    variant="contained"
                >
                    <input
                        id={field.name}
                        name={field.name}
                        type="file"
                        onChange={handleFileChange}
                        className={classes.displayNone}
                        accept={getAcceptedExtensions()}
                    />
                    {TranslationHelper.translate('File')}
                </Button>
                <Typography className={classes.fileName}>
                    {field.value
                        ? field.value.name
                        : TranslationHelper.translate('Choose a file')}
                </Typography>
            </div>

            <FormHelperText error={error}>
                {form.errors[field.name]}
            </FormHelperText>
        </>
    );
};

export default memo(SingleFileUploadWrapper);
