import React, { FunctionComponent, useEffect, useState } from 'react';
import { compose } from 'redux';
import { Connectable, TConnectableProps } from './Connectable.hoc';
import { useStyles } from './Themable.hooks';

import SearchField from '../../../../../../components/common/SearchField/SearchField';
import IconButtonWithTooltip from '../../../../../../components/IconButtonWithTooltip';
import PaneHeader from '../../../../../../components/PaneHeader/PaneHeader';
import SourceSetGrid from '../../../../../../components/SourceSetGrid/SourceSetGrid';
import TranslationHelper from '../../../../../../helpers/TranslationHelper';

import { Paper } from '@material-ui/core';
import { default as PlusIcon } from '@material-ui/icons/Add';
import { ISourceSetEntity } from '../../../../../../state/types';
import { useDispatch } from 'react-redux';

type TProps = TConnectableProps;

const GroupsGridPane: FunctionComponent<TProps> = ({
    sourceSet,
    sourceSetModel,
    selectGroup,
    getGroups,
    enterCreateGroupMode,
    selectedGroup,
}) => {
    const [filter, setFilter] = useState(sourceSetModel?.quickFilter || '');
    const classes = useStyles();

    const dispatch = useDispatch();

    useEffect(() => {
        getGroups();
    }, []);

    const handleCellInteraction = (data: ISourceSetEntity) => {
        dispatch(selectGroup(data.id));
    };

    return (
        <Paper className={classes.pane}>
            <div className={classes.wrapper}>
                <PaneHeader
                    title={TranslationHelper.translate('Groups')}
                    renderRightCustomControls={() => (
                        <IconButtonWithTooltip
                            title={TranslationHelper.translate('Add group')}
                            onClick={() => enterCreateGroupMode()}
                        >
                            <PlusIcon />
                        </IconButtonWithTooltip>
                    )}
                    renderLeftCustomControls={() => (
                        <SearchField value={filter} filterChange={setFilter} />
                    )}
                />
                {sourceSet && (
                    <SourceSetGrid
                        sourceSet={sourceSet}
                        cellInteractionHandler={handleCellInteraction}
                        quickFilterValue={filter}
                        sourceSetModel={sourceSetModel}
                        selectedSourceSetElementId={selectedGroup}
                    />
                )}
            </div>
        </Paper>
    );
};

export default compose(Connectable)(GroupsGridPane);
