import { TSearchType } from '../../../../state/app/searchers';
import { TRootState } from '../../../../store';

const getSearcher = (state: TRootState) => state.app.searcher;

const getSearchers = (state: TRootState) => state.app.searchers;

export const getSearchPhrase = (state: TRootState) =>
    getSearcher(state).searchPhrase;

export const getFoundMonitoredObjectIds = (state: TRootState) =>
    getSearcher(state).foundMonitoredObjectIds;

export const getSearcherResults = (state: TRootState, type: TSearchType) =>
    getSearchers(state)[type].data;
