import { WithStyles } from '@material-ui/core';
import createStyles from '@material-ui/core/styles/createStyles';

import { Theme, withStyles } from '@material-ui/core/styles';

const styles = (theme: Theme) => {
    return createStyles({
        searchInput: {
            border: 0,
            flexGrow: 1,
            marginLeft: theme.spacing(),
        },
        root: {
            display: 'flex',
        },
        mobileRoot: {
            display: 'flex',
            backgroundColor: 'white',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            justifyContent: 'flex-start',
            alignItems: 'center',
        },
        resetSearchBtn: {
            justifySelf: 'flex-end',
        },
    });
};

export type TThemableProps = WithStyles<typeof styles>;

export const Themable = withStyles(styles, { withTheme: true });
