import { makeReduxDuck } from 'teedux';

import {
    IRequestErrorWithJSONMessage,
    IRequestWithMessages,
    makeRequest,
} from '../../../app/sync';

import { TRootState } from '../../../../store';

import {
    restGetEmployees,
    restPostEmployee,
    restPutEmployee,
    restPutEmployeeCards,
} from '../../../../services/registers';

import PageableCollection from '../../../../models/common/PageableCollection';
import Employee from '../../../../models/registers/Employee';

import { ICard, IPage, IRegisterFilter, mapToPageableCollection } from '..';
import { IGroup } from '../../collections/dataTypes';

interface IAddress {
    postalCode: string;
    city: string;
    street: string;
    houseNumber: string;
    apartmentNumber: string;
}

export interface IEmployee {
    id: string;
    externalId: number;
    group: { name: string };
    groups: IGroup[];
    groupIds: string[];
    address: IAddress;
    cards: ICard[];
    name: string;
    firstName: string;
    surname: string;
    email: string;
    phoneNumber: string;
    mobileNumber: string;
    timeZone: {
        name: string;
    };
}

interface IState {
    employees: PageableCollection | null;
    employeesFilter: IRegisterFilter;
}

export const initialState: IState = {
    employees: null,
    employeesFilter: {
        group: undefined,
        name: '',
        start: 0,
        limit: 25,
    },
};

const duck = makeReduxDuck('app/registers/employees', initialState);

const employeesStorePath = 'app/registers/employees';

export const setEmployees = duck.defineAction<{
    employees: PageableCollection;
}>('SET_EMPLOYEES', (_, { employees }) => ({
    employees,
}));

export const setEmployeesFilter = duck.defineAction<{
    employeesFilter: IRegisterFilter;
}>('SET_EMPLOYEES_FILTER', (_, { employeesFilter }) => ({
    employeesFilter,
}));

export const fetchEmployees = (
    params: IRegisterFilter = initialState.employeesFilter,
    afterRequest: (data: IPage<IEmployee>) => void = () => null
) =>
    makeRequest(
        `get:${employeesStorePath}`,
        () => restGetEmployees(params),
        (dispatch, data) => {
            dispatch(
                setEmployees({
                    employees: mapToPageableCollection(data, Employee.fromJson),
                })
            );

            if (afterRequest) {
                afterRequest(data);
            }
        },
        (dispatch, error) => undefined
    );

export const updateEmployee = (
    employee: IEmployee,
    filter?: IRegisterFilter,
    afterRequest?: (data: IRequestWithMessages) => void,
    afterFail?: (error: IRequestErrorWithJSONMessage) => void
) =>
    makeRequest(
        `put:${employeesStorePath}/${employee.id}`,
        () => restPutEmployee(employee),
        (dispatch, data) => {
            // @ts-ignore
            dispatch(fetchEmployees(filter));
            if (afterRequest) {
                afterRequest(data);
            }
        },
        (dispatch, error) => {
            if (afterFail) {
                afterFail(error);
            }
        }
    );

export const createEmployee = (
    employee: IEmployee,
    filter: IRegisterFilter,
    afterRequest?: (data: IRequestWithMessages) => void,
    afterFail?: () => void
) =>
    makeRequest(
        `post:${employeesStorePath}/`,
        () => restPostEmployee(employee),
        (dispatch, data) => {
            // @ts-ignore
            dispatch(fetchEmployees(filter));
            if (afterRequest) {
                afterRequest(data);
            }
        },
        () => {
            if (afterFail) {
                afterFail();
            }
        }
    );

export const updateEmployeeCards = (
    id: string,
    cards: string[],
    filter: IRegisterFilter,
    afterRequest?: (data: IRequestWithMessages) => void,
    afterFail?: () => void
) =>
    makeRequest(
        `put:${employeesStorePath}/${id}/cards`,
        () => restPutEmployeeCards(id, cards),
        (dispatch, data) => {
            // @ts-ignore
            dispatch(fetchEmployees(filter));
            if (afterRequest) {
                afterRequest(data);
            }
        },
        () => {
            if (afterFail) {
                afterFail();
            }
        }
    );

const getRegistersEmployeesState = (state: TRootState) =>
    state.app.registers.employees;

export const getEmployees = (state: TRootState) =>
    getRegistersEmployeesState(state).employees;
export const getEmployeesFilter = (state: TRootState) =>
    getRegistersEmployeesState(state).employeesFilter;

export default duck.getReducer();
