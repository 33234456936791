import {
    BarFormat,
    BarFormatterParams,
} from 'ag-grid-community/dist/lib/interfaces/iSparklineCellRendererParams';

const formatter: (params: BarFormatterParams) => BarFormat = (
    params: BarFormatterParams
) => {
    const { yValue } = params;
    return {
        fill: yValue <= 20 ? 'red' : yValue < 80 ? 'orange' : 'green',
    };
};
export const sparklineOptions = {
    type: 'bar',
    label: {
        enabled: true,
        color: 'black',
        fontSize: 10,
        fontWeight: 'bold',
        formatter: (params: { value: number }) => {
            return `${params.value}%`;
        },
    },
    paddingOuter: 0,
    padding: {
        top: 0,
        bottom: 0,
    },
    valueAxisDomain: [0, 100],
    axis: {
        strokeWidth: 0,
    },
    tooltip: {
        enabled: false,
    },
    formatter,
};
