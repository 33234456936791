import React, { useState } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Checkbox,
} from '@mui/material';

import EditableTableCell from './EditableTableCell';
import TranslationHelper from '../../helpers/TranslationHelper';
import { RowData, TColumn } from './types';

interface IOwnProps<T extends RowData> {
    columns: TColumn[];
    data: T[];
    onDataChange: (newData: T[]) => void;
}

function EditableTable<T extends RowData>({
    columns,
    data,
    onDataChange,
}: IOwnProps<T>) {
    const handleValueChange = (
        rowId: string | number,
        columnId: string,
        value: any
    ): void => {
        const newData = data.map((row) =>
            row.id === rowId ? { ...row, [columnId]: value } : row
        );
        onDataChange(newData);
    };
    const [selectAllChecked, setSelectAllChecked] = useState(false);

    const selectAll = () => {
        const newData = data.map((row) => {
            return { ...row, ['selected']: !selectAllChecked };
        });
        setSelectAllChecked((checked) => !checked);

        onDataChange(newData);
    };

    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        {columns.map((column) => (
                            <TableCell
                                key={column.id}
                                align={column.align || 'left'}
                            >
                                {column.id === 'selected' ? (
                                    <Checkbox
                                        onChange={selectAll}
                                        checked={data.every(
                                            (item) => item.selected
                                        )}
                                    />
                                ) : (
                                    TranslationHelper.translate(column.label)
                                )}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((row) => (
                        <TableRow key={row.id}>
                            {columns.map((column) => (
                                <TableCell
                                    key={`${row.id}-${column.id}`}
                                    align={column.align || 'left'}
                                    size="small"
                                >
                                    <EditableTableCell
                                        column={column}
                                        row={row}
                                        handleValueChange={handleValueChange}
                                    />
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default EditableTable;
