import { createSelector } from 'reselect';
import { TRootState } from 'src/app/store';
import { makeReduxDuck } from 'teedux';

import { restGetDictionary } from '../../../services/registers';

import { makeRequest } from '../sync';

export interface IDictionaryItem {
    id: number;
    parentId?: number;
    name: string;
    shortName?: string;
}

export type TDicionaryType =
    | 'object-category'
    | 'service-type'
    | 'service-class'
    | 'activity-category'
    | 'planning-task-status'
    | 'timezones';

interface IState {
    dictionaries: { [key: string]: IDictionaryItem[] };
}
export const initialState: IState = {
    dictionaries: {},
};
const duck = makeReduxDuck('app/registers', initialState);

const registersStorePath = `app/registers`;

export const setDictionary = duck.defineAction<{
    name: string;
    data: IDictionaryItem[];
}>('SET_DICTIONARY', (state, { name, data }) => ({
    ...state,
    dictionaries: {
        ...state.dictionaries,
        [name]: data,
    },
}));

const makeDictionaryStorePath = (name: TDicionaryType) =>
    `get:${registersStorePath}/dictionaries/${name}`;

export const getDictionary = (name: TDicionaryType) =>
    makeRequest(
        makeDictionaryStorePath(name),
        () => restGetDictionary(name),
        (dispatch, data) => dispatch(setDictionary({ name, data })),
        (dispatch, error) => undefined
    );

export const getDictionaryByCode = (
    state: TRootState,
    code: TDicionaryType
) => {
    return state.app.dictionaries.dictionaries[code] || [];
};

export const getDictionaries = (state: TRootState) => {
    return state.app.dictionaries.dictionaries;
};

export const getMappedDictionaries = createSelector(
    [getDictionaries],
    (dictionaries) => {
        const result: { [key: string]: { [key: string]: IDictionaryItem } } =
            {};
        Object.entries(dictionaries).forEach(
            ([key, val]) =>
                (result[key] = val.reduce(
                    (
                        accumulator: { [id: string]: IDictionaryItem },
                        next: IDictionaryItem
                    ) => {
                        accumulator[next.id] = next;
                        return accumulator;
                    },
                    {}
                ))
        );

        return result;
    }
);

export default duck.getReducer();
