import { IGroupAssignment } from '../../../../../../../../../state/ui/groupsSlice';
import { stringToRegExp } from './stringToRegexp';

export const filterByType = (items: IGroupAssignment[], type: string) => {
    if (type === 'ALL') {
        return items;
    }
    return items.filter((item) => item.registerType === type);
};

export const filterByName = (items: IGroupAssignment[], name: string) => {
    if (name === '') {
        return items;
    }
    return items.filter((item) => lowerCaseFilter(item, name));
};

export const lowerCaseFilter = (object: IGroupAssignment, name: string) => {
    if (object.address) {
        return (
            object.name
                .toLowerCase()
                .match(stringToRegExp(name.toLowerCase())) ||
            object.address
                .toLowerCase()
                .match(stringToRegExp(name.toLowerCase()))
        );
    }
    return object.name.toLowerCase().match(stringToRegExp(name.toLowerCase()));
};

export const filterObjects = (
    items: IGroupAssignment[],
    type: string,
    name: string
) => {
    const filteredByType = filterByType(items, type);
    return filterByName(filteredByType, name);
};
