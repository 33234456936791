import { Moment } from 'moment-timezone';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import MouseWheelZoom from 'ol/interaction/MouseWheelZoom';
import VectorLayer from 'ol/layer/Vector';
import olMap from 'ol/Map';
import Overlay from 'ol/Overlay';
import { transform } from 'ol/proj';
import VectorSource from 'ol/source/Vector';
import View from 'ol/View';

import ProjectionsConst from '../../../constants/ProjectionsConst';

import IconGenerator from '../../../helpers/IconGenerator';
import LocationDetailsHelper from '../../../helpers/LocationDetailsHelper';
import { IObject } from '../../../helpers/IconGenerator/IconGenerator';

import TranslationHelper from '../../../helpers/TranslationHelper';
import { TRootState } from '../../../store';
import RelativeTime from '../../common/RelativeTime';
import LoadingSpinner from '../../loadingSpinner/LoadingSpinner';

import { generateTileLayers } from '../../../pages/discovery/components/DiscoveryMap/_utils/tileLayers';
import { getIsMenuOpen } from '../../../state/ui/general';

interface IOwnProps {
    details?: IObject & {
        coordinate?: { x: number; y: number };
        location?: string;
        updatedAt: Moment;
    };
}

type TConnectableProps = ReturnType<typeof mapStateToProps>;

type TProps = IOwnProps & TConnectableProps;

class SmallMap extends PureComponent<TProps> {
    private map: null | olMap = null;
    private mapPopup: null | Overlay = null;
    private mapFeature: null | Feature<Point> = null;

    public componentDidMount() {
        if (!this.map && this.props.details) {
            this._initMap();
        }
    }
    public componentDidUpdate(prevProps: TProps) {
        if (
            this.map !== null &&
            prevProps.isMenuOpen !== this.props.isMenuOpen
        ) {
            this.map.updateSize();
        }
        if (
            this.map &&
            this.props.details &&
            prevProps.details !== this.props.details
        ) {
            this._reRenderMap();
        }
    }

    public _initMap() {
        const details = this.props.details;
        if (!details || !details.coordinate) {
            return;
        }
        const coordinates: [number, number] = [
            details.coordinate.x,
            details.coordinate.y,
        ];
        const position = transform(
            coordinates,
            ProjectionsConst.EPSG4326,
            ProjectionsConst.EPSG900913
        );
        const geometry = new Point(position);
        this.mapFeature = new Feature(geometry);
        const olStyle = IconGenerator.getIconAsOpenLayersStyle(details);
        this.mapFeature.setStyle(olStyle);

        const iconHeight = olStyle[0].getImage().getSize()[1];
        const verticalOffset = -14 - Math.round(iconHeight / 2);

        this.mapPopup = new Overlay({
            element: document.getElementById('small-map-popup') as HTMLElement,
            positioning: 'bottom-center',
            stopEvent: false,
            position,
            offset: [0, verticalOffset],
        });

        const tileLayer = generateTileLayers(this.props.language)[0];

        this.map = new olMap({
            layers: [
                tileLayer,
                new VectorLayer({
                    source: new VectorSource({ features: [this.mapFeature] }),
                }),
            ],
            overlays: [this.mapPopup],
            interactions: [new MouseWheelZoom({ useAnchor: false })],
            target: 'small-map',
            view: new View({
                center: position,
                zoom: 15,
                minZoom: 4,
            }),
        });
    }

    public _reRenderMap() {
        const details = this.props.details;
        if (!details || !details.coordinate) {
            return;
        }
        const coordinates = [details.coordinate.x, details.coordinate.y];
        const position = transform(
            [coordinates[0], coordinates[1]],
            ProjectionsConst.EPSG4326,
            ProjectionsConst.EPSG900913
        );
        if (
            this.map === null ||
            this.mapFeature === null ||
            this.mapPopup === null
        ) {
            return;
        }
        this.map.getView().setCenter(position);
        // @ts-ignore
        this.mapFeature.getGeometry().setCoordinates(position);
        this.mapFeature.setStyle(
            IconGenerator.getIconAsOpenLayersStyle(details)
        );
        this.mapPopup.setPosition(position);
    }

    public render() {
        const details = this.props.details;
        let content = null;
        if (details) {
            if (!details.coordinate) {
                content = (
                    <>
                        <div id="small-map" style={{ display: 'none' }}>
                            <div
                                id="small-map-popup"
                                className="map-popup ol-floating-element"
                            />
                        </div>

                        <div
                            style={{
                                flexGrow: 1,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                fontStyle: 'italic',
                            }}
                        >
                            {TranslationHelper.translate('No data to display')}
                        </div>
                    </>
                );
            } else {
                const position = LocationDetailsHelper.formatPosition(
                    details.coordinate
                );
                const address = details.location || '-';
                content = (
                    <div id="small-map">
                        <div
                            id="small-map-popup"
                            className="map-popup ol-floating-element"
                        >
                            <div className="small">
                                <div className="data-icon">
                                    <span className="icon icon-compass6" />
                                </div>
                                <div>
                                    <div className="address">{address}</div>
                                    <div className="position">{position}</div>
                                    <div className="date">
                                        <RelativeTime
                                            zonedDate={details.updatedAt}
                                            showYear={true}
                                            showSeconds={true}
                                            relative={false}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            }
        } else {
            content = (
                <div className="spinner">
                    <LoadingSpinner
                        size="50"
                        top="0"
                        left="0"
                        right="0"
                        bottom="0"
                    />
                </div>
            );
        }

        return content;
    }
}

const mapStateToProps = (state: TRootState) => ({
    isMenuOpen: getIsMenuOpen(state),
    language: state.login.language,
});

export default connect(mapStateToProps)(SmallMap);
