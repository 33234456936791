import { connect, ConnectedProps } from 'react-redux';

import {
    createRole,
    setRole,
    updateRole,
} from '../../../../state/app/adminPanel';
import { deselectRole, resetMode } from '../../../../state/ui/roles';
import { TRootState } from '../../../../store';
import {
    getGroupedPrivileges,
    getMode,
    getRoleCreationRequest,
    getRoleData,
    getRoleUpdateRequest,
    getSelectedRoleId,
} from '../../selectors';

const mapStateToProps = (state: TRootState) => ({
    privilegesListGrouped: getGroupedPrivileges(state),
    mode: getMode(state),
    roleData: getRoleData(state),
    selectedRoleId: getSelectedRoleId(state),
    creationRequest: getRoleCreationRequest(state),
    updateRequest: getRoleUpdateRequest(state),
    userCanEdit: state.auth.privileges.editRole,
});

const mapDispatchToProps = {
    deselectRole,
    resetMode,
    createRole,
    updateRole,
    setRole,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type TConnectableProps = ConnectedProps<typeof connector>;

export const Connectable = connector;
