import { connect } from 'react-redux';
import { getPrivileges, getRoles } from '../../state/app/adminPanel';
import { TRootState } from '../../store';
import { isRoleDetailsVisible } from './selectors';

const mapStateToProps = (state: TRootState) => ({
    roleEditIsVisible: isRoleDetailsVisible(state),
});

const mapDispatchToProps = { getRoles, getPrivileges };

type TStateProps = ReturnType<typeof mapStateToProps>;
type TDispatchProps = typeof mapDispatchToProps;

export type TConnectableProps = TStateProps & TDispatchProps;

export const Connectable = connect(mapStateToProps, mapDispatchToProps);
