import moment from 'moment-timezone';
import { makeReduxDuck } from 'teedux';

import {
    restGetUserRights,
    restGetUserSettings,
    restResendUserEmail,
    restSaveUserEmail,
} from '../../services/userSettings';
import { makeRequest } from '../app/sync';

import * as AccessRights from '../../constants/dictionaries/AccessRights';

import { TRootState } from '../../store';
import { clearXssAddressAction } from '../login';
import {
    ISettings,
    IUserEmail,
    IUserRights,
    IUserSettings,
    TSetUsersSettings,
} from './types';

export type TUserState = ISettings & IUserSettings & IUserEmail;

const initialState: TUserState = {
    dateFormat: 'YYYY-MM-DD HH:mm:ss',
    dateFormatNumeric: 'YYYYMMDDHHmmss',
    dateFormatWithoutSeconds: 'YYYY-MM-DD HH:mm',
    dateFormatWithoutYearAndSeconds: 'MM-DD HH:mm',
    shortDateFormat: 'YYYY-MM-DD',
    timeZone: 'UTC',
    dateDefaultPresets: [],
    binaryHidden: undefined,
    userEmail: undefined,
    userEmailVerified: undefined,
    customerNumber: undefined,
    rights: undefined,
};

const storePath = `app/user`;
const duck = makeReduxDuck(storePath, initialState);

export const setUserSettingsAction = duck.defineAction<{
    data: TSetUsersSettings;
}>('SET_USER_SETTINGS', (state, { data }) => {
    const currentTimeZone = moment.tz.guess(true);
    const todayEnd = moment().endOf('day');
    const todayStart = moment().startOf('day');
    const yesterdayEnd = moment().subtract(1, 'day').endOf('day');
    const yesterdayStart = moment().subtract(1, 'day').startOf('day');
    const lastWeekStart = moment().subtract(1, 'week').startOf('isoWeek');
    const lastWeekEnd = moment().subtract(1, 'week').endOf('isoWeek');

    const defaultPresets = [];
    defaultPresets.push({
        fromDate: todayStart,
        toDate: todayEnd,
        name: 'Today',
    });
    defaultPresets.push({
        fromDate: yesterdayStart,
        toDate: yesterdayEnd,
        name: 'Yesterday',
    });
    defaultPresets.push({
        fromDate: lastWeekStart,
        toDate: lastWeekEnd,
        name: 'Last week',
    });
    return {
        ...state,
        dateFormat: data.dateFormat || state.dateFormat,
        dateFormatWithoutSeconds:
            data.dateFormatWithoutSeconds || state.dateFormatWithoutSeconds,
        shortDateFormat: data.shortDateFormat || state.shortDateFormat,
        timeZone: currentTimeZone,
        dateDefaultPresets: defaultPresets,
        userEmail: data.email,
        customerNumber: data.customerNumber,
        dateFormatWithoutYearAndSeconds: (
            data.dateFormatWithoutSeconds || state.dateFormatWithoutSeconds
        ).replace(new RegExp('[-.]?YYYY[-.]?'), ''),
        dateFormatNumeric: (data.dateFormat || state.dateFormat).replace(
            /[\W_]+/g,
            ''
        ),
        userId: data.userId,
    };
});

export const setUserDataAction = duck.defineAction<{
    data: TSetUsersSettings;
}>('SET_USER_DATA', (state, { data }) => ({
    ...state,
    userEmail: data.email,
    customerNumber: data.customerNumber,
    userEmailVerified: data.emailVerified,
}));

export const setUserRightsAction = duck.defineAction<{
    data: IUserRights;
}>('SET_USER_RIGHTS', (state, { data }) => ({
    ...state,
    rights: { ...data },
    binaryHidden: data.paramsFilter && data.paramsFilter.binaryHidden,
}));

export const setUserEmailNotVerifiedAction = duck.definePayloadlessAction(
    'SET_USER_EMAIL_NOT_VERIFIED',
    () => ({
        userEmailVerified: false,
    })
);

export const setUserEmailAction = duck.defineAction<{
    email: string;
}>('SET_USER_EMAIL', (state, { email }) => ({
    ...state,
    userEmail: email,
    userEmailVerified: false,
}));

export default duck.getReducer();

const makeUserSettingsStorePath = () => `get:${storePath}`;
const makeUserRightsStorePath = () => `get:${storePath}/rights`;
const makeUserEmailStorePath = () => `get:${storePath}/email`;

export const fetchUserSettings = () =>
    makeRequest(
        makeUserSettingsStorePath(),
        () => restGetUserSettings(),
        (dispatch, data) => {
            dispatch(setUserSettingsAction({ data }));
        },
        (dispatch, error) => undefined
    );

export const fetchUserData = (
    afterRequest?: (data: TSetUsersSettings) => void
) =>
    makeRequest(
        makeUserSettingsStorePath(),
        () => restGetUserSettings(true),
        (dispatch, data) => {
            dispatch(setUserDataAction({ data }));
            if (afterRequest) {
                afterRequest(data);
            }
        },
        (dispatch, error) => undefined
    );

export const fetchUserRights = () =>
    makeRequest(
        makeUserRightsStorePath(),
        () => restGetUserRights(),
        (dispatch, data) => {
            dispatch(setUserRightsAction({ data }));
        },
        (dispatch, error) => undefined
    );

export const resendUserEmail = () =>
    makeRequest(
        makeUserEmailStorePath(),
        () => restResendUserEmail(),
        (dispatch, data) => {
            dispatch(setUserEmailNotVerifiedAction());
        },
        (dispatch, error) => undefined
    );

export const updateUserEmail = (
    email: string,
    afterRequest?: () => void,
    afterFail?: (error: any) => void
) =>
    makeRequest(
        makeUserSettingsStorePath(),
        () => restSaveUserEmail(email),
        (dispatch, data) => {
            dispatch(setUserEmailAction({ email }));
            dispatch(clearXssAddressAction());
            if (afterRequest) {
                afterRequest();
            }
        },
        (dispatch, error) => {
            if (afterFail) {
                afterFail(error);
            }
        }
    );

export const getUserSettings = (state: TRootState) => state.user;

export const getUserRights = (state: TRootState) =>
    getUserSettings(state).rights;

export const getUserDataPermissions = (state: TRootState) =>
    getUserRights(state)?.dataPermissions;

export const getDefaultDatePresets = (state: TRootState) =>
    getUserSettings(state).dateDefaultPresets;

export const hasAccessToCards = (state: TRootState) =>
    state.user.rights
        ? state.user.rights.cards.access !== AccessRights.NONE
        : false;

export const hasAccessToEmployees = (state: TRootState) =>
    state.user.rights
        ? state.user.rights.employees.access !== AccessRights.NONE
        : false;

export const hasAccessToTanks = (state: TRootState) =>
    state.user.rights
        ? state.user.rights.tanks.access !== AccessRights.NONE
        : false;

export const hasAccessToVehicles = (state: TRootState) =>
    state.user.rights
        ? state.user.rights.vehicles.access !== AccessRights.NONE
        : false;

export const hasAccessToAllVehicles = (state: TRootState) =>
    state.user.rights
        ? state.user.rights.vehicles.access === AccessRights.ALL
        : false;

export const hasAccessToRegisters = (state: TRootState) =>
    hasAccessToVehicles(state) ||
    hasAccessToTanks(state) ||
    hasAccessToCards(state) ||
    hasAccessToEmployees(state);

export const getTimeZone = (state: TRootState) =>
    getUserSettings(state).timeZone;

export const getDateFormatWithoutYearAndSeconds = (state: TRootState) =>
    getUserSettings(state).dateFormatWithoutYearAndSeconds;

export const getDateFormatWithoutSeconds = (state: TRootState) =>
    getUserSettings(state).dateFormatWithoutSeconds;

export const getDateFormatNumeric = (state: TRootState) =>
    getUserSettings(state).dateFormatNumeric;

export const getShortDateFormat = (state: TRootState) =>
    getUserSettings(state).shortDateFormat;

export const getDateFormat = (state: TRootState) =>
    getUserSettings(state).dateFormat;

export const getBinaryHidden = (state: TRootState) =>
    getUserSettings(state).binaryHidden;

export const getUserEmail = (state: TRootState) =>
    getUserSettings(state).userEmail;

export const getUserEmailVerified = (state: TRootState) =>
    getUserSettings(state).userEmailVerified;

export const getCustomerNumber = (state: TRootState) =>
    getUserSettings(state).customerNumber;

export const getUserId = (state: TRootState) => getUserSettings(state).userId;
