import React from 'react';

import {
    Dialog,
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@material-ui/core';

import TranslationHelper from '../../../../../../helpers/TranslationHelper';

import useToggle from '../../../../../../hooks/useToggle';
import { useStyles } from './Themable.hooks';

interface IOwnProps {
    onSubmit?: () => void;
    close?: () => void;
    canResetPassword: boolean;
}

const ResetPasswordDialog = ({
    onSubmit,
    close,
    canResetPassword,
}: IOwnProps) => {
    const { isOpen, handleToggle } = useToggle(true);
    const classes = useStyles();

    const handleClose = (event?: object, reason?: string) => {
        if (reason === 'backdropClick') {
            return;
        }
        handleToggle();
        close && close();
    };

    const handleResetPasswordClick = () => {
        onSubmit && onSubmit();
    };

    return (
        <Dialog open={isOpen} keepMounted={true}>
            <DialogTitle>
                {TranslationHelper.translate('Reset password')}
            </DialogTitle>
            {canResetPassword ? (
                <>
                    <DialogContent>
                        {TranslationHelper.translate(
                            'Do you really want to reset password for this user?'
                        )}
                    </DialogContent>
                    <DialogActions className={classes.actions}>
                        <Button
                            color="primary"
                            onClick={handleClose}
                            variant="contained"
                        >
                            {TranslationHelper.translate("No, don't reset")}
                        </Button>
                        <Button
                            onClick={handleResetPasswordClick}
                            color="secondary"
                            variant="contained"
                        >
                            {TranslationHelper.translate(
                                'Yes, I want to reset password'
                            )}
                        </Button>
                    </DialogActions>
                </>
            ) : (
                <>
                    <DialogContent>
                        {TranslationHelper.translate(
                            'This user does not have a valid email address and their password cannot be reset.'
                        )}
                    </DialogContent>
                    <DialogActions>
                        <Button
                            color="primary"
                            onClick={handleClose}
                            variant="contained"
                        >
                            {TranslationHelper.translate('Close')}
                        </Button>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
};

export default ResetPasswordDialog;
