import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { restGetLabelsModels } from '../../../services/files';
import { TRootState } from '../../../store';
import { TStatus } from '../../types';

export interface IModel {
    name: string;
    classes: unknown[];
}

interface IState {
    status: TStatus;
    models: string[] | null;
}

const initialState: IState = {
    status: 'idle',
    models: null,
};

export const fetchLabelsModels = createAsyncThunk(
    'get:app/files/models',
    async () => {
        const response = await restGetLabelsModels();
        return response;
    }
);

const statusSlice = createSlice({
    name: 'statusSlice',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchLabelsModels.pending, (state) => {
            state.status = 'loading';
        });
        builder.addCase(fetchLabelsModels.fulfilled, (state, action) => {
            state.status = 'done';
            state.models = action.payload.map((model) => model.name);
        });
        builder.addCase(fetchLabelsModels.rejected, (state) => {
            state.status = 'error';
        });
    },
});

export const getLabelModels = (state: TRootState) => state.app.files.models;
export const getLabelModelsStatus = (state: TRootState) =>
    state.app.files.status;

export default statusSlice.reducer;
