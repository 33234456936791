import { Feature } from 'ol';
import { hasValidGps } from './hasValidGps';
import { makeMarker } from '../../../../../pages/discovery/components/DiscoveryMap/_utils/features';
import { ISourceSet } from '../../../../../state/types';
import { IColor } from '../../../../../helpers/ColorPool';

export const getFeatures = (
    sourceSet: ISourceSet,
    routesColors: { [key: string]: IColor }
) => {
    return sourceSet.entities
        .filter(hasValidGps)
        .map(({ location: { gps }, id, routeId }) => {
            const { latitude, longitude } = gps;
            const rgb = routesColors[String(routeId)]?.rgb;
            return new Feature({
                geometry: makeMarker(longitude, latitude),
                data: { id },
                id: id.toString(),
                red: rgb?.r,
                green: rgb?.g,
                blue: rgb?.b,
            });
        });
};
