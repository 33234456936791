import React, { useEffect, useState } from 'react';

import { Divider, LinearProgress, Paper } from '@material-ui/core';
import { default as PlusIcon } from '@material-ui/icons/Add';

import { GridApi } from 'ag-grid-community';

import { useAppDispatch, useToolkitDispatch } from '../../../../../../hooks';
import TranslationHelper from '../../../../../../helpers/TranslationHelper';

import {
    useAttributes,
    useAttributesSourceSetModel,
    useAttributesStatus,
} from '../../../../../../state/ui/attributes/index.hooks';
import { fetchAttributes } from '../../../../../../state/ui/attributes';
import { showAttributesDialog } from '../../../../../../state/ui/dialog';

import { useStyles } from '../../Themable.hooks';

import CscExportButtons from '../../../../../../pages/customerService/components/CscExportButtons/CscExportButtons';

import PaneHeader from '../../../../../../components/PaneHeader';
import SourceSetGrid from '../../../../../../components/SourceSetGrid';
import SearchField from '../../../../../../components/common/SearchField/SearchField';
import IconButtonWithTooltip from '../../../../../../components/IconButtonWithTooltip';
import { useSnackbar } from 'notistack';

const AttributesGrid = () => {
    const sourceSetModel = useAttributesSourceSetModel();

    const classes = useStyles({});

    const status = useAttributesStatus();
    const attributes = useAttributes();

    const { enqueueSnackbar } = useSnackbar();
    const toolkitDispatch = useToolkitDispatch();
    const dispatch = useAppDispatch();

    const [filter, setFilter] = useState(sourceSetModel?.quickFilter || '');
    const [gridApi, setGridApi] = useState<GridApi | undefined>(undefined);
    const [refreshGrid, setRefreshGrid] = useState(false);

    useEffect(() => {
        setRefreshGrid(false);
        toolkitDispatch(fetchAttributes());
    }, [refreshGrid]);

    const handleCreateAttribute = () => {
        dispatch(showAttributesDialog({}));
    };

    const handleSetApiGrid = (gridApi: GridApi) => {
        setGridApi(gridApi);
    };

    const renderRightCustomControls = () => {
        return (
            <>
                <CscExportButtons api={gridApi} prefix="attributes" />
                <IconButtonWithTooltip
                    title={TranslationHelper.translate('Add attribute')}
                    onClick={handleCreateAttribute}
                >
                    <PlusIcon />
                </IconButtonWithTooltip>
            </>
        );
    };

    return (
        <Paper className={classes.gridPane}>
            <div className={classes.wrapper}>
                <PaneHeader
                    title={TranslationHelper.translate('Attributes')}
                    renderRightCustomControls={() =>
                        renderRightCustomControls()
                    }
                    renderLeftCustomControls={() => (
                        <SearchField value={filter} filterChange={setFilter} />
                    )}
                />
                {!attributes || status === 'loading' ? (
                    <LinearProgress />
                ) : (
                    <Divider />
                )}
                {attributes && (
                    <SourceSetGrid
                        sourceSet={attributes}
                        setGridApi={handleSetApiGrid}
                        quickFilterValue={filter}
                        sourceSetModel={sourceSetModel}
                        selectedSourceSetElementId={null}
                        actionHandlers={{
                            edit: (params) => {
                                return dispatch(
                                    showAttributesDialog({ id: params.id })
                                );
                            },
                            delete: () => {
                                setRefreshGrid(true);
                                enqueueSnackbar(
                                    TranslationHelper.translate(
                                        'Attribute was deleted'
                                    ),
                                    { variant: 'success' }
                                );
                            },
                        }}
                    />
                )}
            </div>
        </Paper>
    );
};

export default AttributesGrid;
