import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        listItem: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'start',
            padding: `0 ${theme.spacing(2)}px`,
        },
        listNested: {
            padding: `0 0 0 ${theme.spacing(2)}px`,
        },
    })
);
