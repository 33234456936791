import URI from 'urijs';
import AppConfig from '../../constants/AppConfig';
import UrlConst from '../../constants/UrlConst';
import { IContextTranslations } from '../../state/app/translations';
import apiClient from '../common/apiClient';
import { makeGetRequestSettings, makeUrl } from '../common/utils';

export const makeContextTranslationsUrl = (params = {}) => {
    const url = new URI(
        AppConfig.instance.getUrl(UrlConst.REGISTERS_TRANSLATIONS_URL)
    );
    return makeUrl(url, params);
};

export const restGetContextTranslations = (
    lang: string
): Promise<IContextTranslations> =>
    apiClient.sendWithoutErrorsHandling(
        makeGetRequestSettings(makeContextTranslationsUrl({ lang }))
    );
