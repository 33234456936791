import React from 'react';

import MenuLessErrorTemplate from './menuLessErrorTemplate';
import TranslationHelper from '../../helpers/TranslationHelper';

const Page404 = () => {
    return (
        <MenuLessErrorTemplate>
            <div style={{ marginBottom: '50px' }}>
                {TranslationHelper.translate('Page does not exist')}
            </div>
        </MenuLessErrorTemplate>
    );
};
export default Page404;
