import React from 'react';
import { compose } from 'redux';
import { Connectable, TConnectableProps } from './Connectable.hoc';
import { useStyles } from './Themable.hoc';

import IconGenerator from '../../../../../../helpers/IconGenerator';
import TimeFormatter from '../../../../../../helpers/TimeFormatter';
import TranslationHelper from '../../../../../../helpers/TranslationHelper';
import { IReportSetHeader } from '../../../../../../state/types';

interface IOwnProps {
    header: IReportSetHeader;
    getHeaderName?: (header: IReportSetHeader) => string;
}

type TProps = IOwnProps & TConnectableProps;

const EventHeader = ({ header, getHeaderName, userSettings }: TProps) => {
    const classes = useStyles();
    const icon =
        header && IconGenerator.getDiscoveryIconAsComponent(header.icon);

    return (
        <div className={classes.header}>
            <div className={classes.headerIcon}>{icon}</div>
            <h1 className={classes.headerName}>
                {getHeaderName
                    ? getHeaderName(header)
                    : `${TranslationHelper.translate(header.name, 'et')}`}
            </h1>
            <div className={classes.headerBottom}>
                <p className={classes.headerDate}>
                    {TimeFormatter.dateToString(
                        header.date,
                        userSettings.dateFormat
                    )}
                </p>
                <div className={classes.headerDate}>{header.objectName}</div>
            </div>
        </div>
    );
};

export default compose(Connectable)(EventHeader);
