import { makeReduxDuck } from 'teedux';
import { restGetEvents } from '../../../services/calibrations';
import { makeRequest } from '../sync';
import { IEventItem, IGetEventsParams } from '../calibrations';
import { IRefuelAndLossConfiguration } from '../../ui/forms';
import {
    IOnDemandData,
    restGetOnDemandData,
} from '../../../services/fuelingDetection';

const refuellAndLossConfigStorePath = `app/refuellAndLossConfiguration`;
const FUELLVL = 'FUELLVL';

interface IState {
    selectedParam: string;
    events: IEventItem[] | null;
    onDemandData: IOnDemandData[] | null;
}
const initialState: IState = {
    selectedParam: FUELLVL,
    events: null,
    onDemandData: null,
};

export interface IGetFuelingOnDemandParams {
    monitoredId: number;
    from: string;
    to: string;
    limit?: string;
}

const duck = makeReduxDuck(refuellAndLossConfigStorePath, initialState);

export const setEventsAction = duck.defineAction<{
    data: IEventItem[];
}>('SET_EVENTS', (_, { data }) => ({
    events: data,
}));

export const setOnDemandDataAction = duck.defineAction<{
    data: IOnDemandData[];
}>('SET_ON_DEMAND_DATA', (_, { data }) => ({
    onDemandData: data,
}));

export const clearDetectionData = duck.definePayloadlessAction(
    'CLEAR_DETECTION_DATA',
    () => ({
        events: null,
        onDemandData: null,
    })
);

export default duck.getReducer();

export const makeRefuellAndLossConfigStorePath = () =>
    `get:${refuellAndLossConfigStorePath}`;

export const makeRefuelAndLossConfigOnDemandDataStorePath = () =>
    `get:${refuellAndLossConfigStorePath}OnDemandData`;

export const fetchEvents = (
    params: IGetEventsParams,
    afterSuccess?: (data: IEventItem[]) => void,
    afterFail?: (error: any) => void
) =>
    makeRequest(
        makeRefuellAndLossConfigStorePath(),
        () => restGetEvents(params),
        (dispatch, data) => {
            dispatch(setEventsAction({ data }));
            if (afterSuccess) {
                afterSuccess(data);
            }
        },
        (dispatch, error) => {
            if (afterFail) {
                afterFail(error);
            }
        },
        (dispatch) => {
            dispatch(setEventsAction({ data: [] }));
        }
    );

export const fetchOnDemandData = (
    params: IGetFuelingOnDemandParams,
    body: IRefuelAndLossConfiguration,
    afterSuccess?: (data: IOnDemandData[]) => void,
    afterFail?: (error: any) => void
) =>
    makeRequest(
        makeRefuelAndLossConfigOnDemandDataStorePath(),
        () => restGetOnDemandData(params, body),
        (dispatch, data) => {
            dispatch(setOnDemandDataAction({ data }));
            if (afterSuccess) {
                afterSuccess(data);
            }
        },
        (dispatch, error) => {
            if (afterFail) {
                afterFail(error);
            }
        },
        (dispatch) => {
            dispatch(setOnDemandDataAction({ data: [] }));
        }
    );
