export const SYSTEM_OBJECTS = {
    1: 'User',
    3: 'Employee',
    5: 'Task',
    7: 'Client',
    8: 'Vehicle',
    9: 'Location',
    13: 'CyclicTask',
    14: 'FuelTank',
};
