import React, { useEffect, useRef } from 'react';

import { resetMode } from '../../../../../../../state/ui/customerService/bottomGrid';

import PreviewPane from '../../../../../../../pages/discovery/components/PreviewPane/PreviewPane';
import { useToolkitDispatch } from '../../../../../../../hooks';
import { usePane } from '../../../../../../../pages/discovery/selectors/index.hooks';

const EventPreview = () => {
    const pane = usePane();
    const toolkitDispatch = useToolkitDispatch();
    const isMounted = useRef(false);

    useEffect(() => {
        if (pane === null && isMounted.current) {
            toolkitDispatch(resetMode());
        } else {
            isMounted.current = true;
        }
    }, [pane]);

    return (
        <div
            style={{
                position: 'absolute',
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
            }}
        >
            <PreviewPane />
        </div>
    );
};

export default EventPreview;
