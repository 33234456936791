import React from 'react';

import { connect } from 'react-redux';

import { instanceOf } from 'prop-types';

import { Connectable } from '../Connectable.hoc';

import { restGetAvailableRfidCards } from '../../../services/registers';

import Employee from '../../../models/registers/Employee';
import RFIDCard from '../../../models/registers/RFIDCard';

import Translator from '../../../helpers/TranslationHelper';

import PrimaryButton from '../../button/PrimaryButton';
import SecondaryButton from '../../button/SecondaryButton';
import ValidationMessage from '../../common/ValidationMessage';
import Dialog from '../../dialog/Dialog';
import CustomAutocomplete from '../../common/CustomAutocomplete';

class EmployeeCardDialog extends React.Component {
    state = {
        selectedCards: this.props.employee.cards
            ? this.props.employee.cards
            : [],
        validationMessage: undefined,
        cards: [],
        loading: false,
    };

    handleCancel = (event) => {
        event.preventDefault();
        this.props.hideGlobalDialog();
    };

    handleSubmit = (event) => {
        event.preventDefault();
        if (this.isFormValid()) {
            let cardIds = this.state.selectedCards.map((sel) => {
                return sel.id;
            });
            const {
                updateEmployeeCards,
                employee,
                employeesFilter: filter,
                hideGlobalDialog,
            } = this.props;

            updateEmployeeCards(
                employee.id,
                cardIds,
                filter,
                (validation) => {
                    if (validation.success) {
                        hideGlobalDialog();
                    } else if (validation.messages) {
                        this.setState({
                            validationMessage: validation.messages[0],
                        });
                    }
                },
                () => {
                    this.setState({
                        validationMessage: Translator.translate(
                            'Unrecognized error has occurred'
                        ),
                    });
                }
            );
        }
    };

    isFormValid = () => {
        return true;
    };

    handleCardSelect = (e, selectedCards) => {
        this.setState({
            selectedCards,
        });
    };
    handleSetLoading = () => {
        this.setState({ loading: true });
    };

    render() {
        const employee = this.props.employee;

        return (
            <Dialog
                onClose={this.handleCancel}
                title={Translator.translate('Assigning cards')}
            >
                <form
                    className="form employee-rfid-form"
                    onSubmit={this.handleSubmit}
                    autoComplete="off"
                >
                    <div className="section">
                        <div className="form-group">
                            <div className="form-title">
                                {Translator.translate('Employee')}
                            </div>
                            <div className="form-label">{employee.name}</div>
                        </div>
                        <div className="form-group single">
                            <div className="form-title">
                                {Translator.translate('RFID cards')}
                            </div>
                            <div className="form-input">
                                <CustomAutocomplete
                                    value={this.state.selectedCards}
                                    inputTexts={{ placeholder: 'Select cards' }}
                                    multi
                                    onChange={this.handleCardSelect}
                                    onOpen={restGetAvailableRfidCards}
                                    optionKeys={{
                                        name: 'cardNumber',
                                        value: 'id',
                                    }}
                                />
                            </div>
                        </div>
                        <div className="form-group">
                            <ValidationMessage
                                message={Translator.translate(
                                    this.state.validationMessage
                                )}
                                error={
                                    this.state.validationMessage !== undefined
                                }
                            />
                        </div>
                    </div>
                    <div className="form-actions">
                        <PrimaryButton
                            icon="icon-floppy-disk"
                            label={Translator.translate('Save')}
                        />
                        <SecondaryButton
                            onClick={this.handleCancel}
                            icon="icon-cross"
                            label={Translator.translate('Cancel')}
                        />
                    </div>
                </form>
            </Dialog>
        );
    }
}

EmployeeCardDialog.propTypes = {
    employee: instanceOf(Employee),
};

export default Connectable(EmployeeCardDialog);
