import { default as Button } from '@material-ui/core/Button';
import React, { Component, ComponentType } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Themable, TThemableProps } from './Themable.hoc';

interface IOwnProps {
    disabled?: boolean;
    handleClick: () => void;
}
type TProps = IOwnProps & TThemableProps;

class RawTogglerButton extends Component<TProps> {
    public render() {
        const { classes, disabled, children, handleClick } = this.props;
        return (
            <Button
                color="primary"
                variant="contained"
                className={classes.button}
                disabled={disabled}
                onClick={handleClick}
            >
                {children}
            </Button>
        );
    }
}

export default compose(
    Themable,
    connect()
)(RawTogglerButton) as ComponentType<IOwnProps>;
