import apiClient from '../common/apiClient';
import { makeEndpoint, makeGetRequestSettings } from '../common/utils';

import UrlConst from '../../constants/UrlConst';
import { IDefinitionItem } from '../../state/app/mappings';

export const restGetParamsDefinitions = (
    objectId: string | undefined = undefined,
    mappingsSource: boolean,
    mappingsTarget: boolean
): Promise<IDefinitionItem[]> =>
    apiClient.sendWithoutErrorsHandling(
        makeGetRequestSettings(
            makeEndpoint(UrlConst.PARAMS_DEFINITIONS_URL, '', {
                ...(objectId !== undefined && { objectId }),
                mappingsSource,
                mappingsTarget,
                onlyVisible: false,
            })
        )
    );
