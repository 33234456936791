import { connect } from 'react-redux';
import { Action, bindActionCreators } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import {
    fetchSourceSet,
    queueMapFitToExtent,
    resetLevel,
} from '../../../../state/_actions';
import { setMobileScrollOffset } from '../../../../state/ui/discovery/snapshotting';
import { TRootState } from '../../../../store';
import {
    getGridSourceSetAction,
    getMobileGridScrollOffset,
} from '../../selectors';
import { createCellInteractionHandler } from '../DataGridPane/selectors';
import { IOwnProps } from '../DataGridPane/types';

const mapStateToProps = (state: TRootState) => ({
    sourceSetAction: getGridSourceSetAction(state),
    offset: getMobileGridScrollOffset(state),
});
type TStateProps = ReturnType<typeof mapStateToProps>;

const mapDispatchToProps = (
    dispatch: ThunkDispatch<null, null, Action>,
    ownProps: IOwnProps
) => ({
    cellInteractionHandler: createCellInteractionHandler(dispatch, ownProps),
    ...bindActionCreators(
        {
            resetLevel,
            fetchSourceSet,
            queueMapFitToExtent,
            setMobileScrollOffset,
        },
        dispatch
    ),
});

type TDispatchProps = ReturnType<typeof mapDispatchToProps>;

export type TConnectableProps = TStateProps & TDispatchProps;

export const Connectable = connect(mapStateToProps, mapDispatchToProps);
