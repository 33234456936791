import React, { Dispatch, ReactNode, SetStateAction } from 'react';

import { Button, Grid, Tooltip } from '@material-ui/core';

import { useStyles } from './Themable.hooks';

import TranslationHelper from '../../helpers/TranslationHelper';
import { intersection, not } from './utils/filterObjects';
import CustomList from './components/CustomList';

export const ITEMS_TOGGLE_LIMIT = 10000;

export interface IFilterInitialState {
    typeUnassigned: string;
    nameUnassigned: string;
    typeAssigned: string;
    nameAssigned: string;
}
export interface IListObjectInitialState<T> {
    unassignedObjects: T[];
    assignedObjects: T[];
    filteredAssignedObjects: T[];
    originalAssignedObjects: T[];
}

export interface ITransferListObject {
    relationId: string;
    type: string;
    name: string;
}

interface IOwnProps<T> {
    objectLists: IListObjectInitialState<T>;
    setObjectLists: React.Dispatch<Partial<IListObjectInitialState<T>>>;
    added: T[];
    setAdded: Dispatch<SetStateAction<T[]>>;
    removed: T[];
    setRemoved: Dispatch<SetStateAction<T[]>>;
    filter: IFilterInitialState;
    checked: T[];
    setChecked: Dispatch<SetStateAction<T[]>>;
    filterObjects: (items: T[], type: string, name: string) => T[];
    customComponents: { leftList: ReactNode; rightList: ReactNode };
}

export const DisabledTransferList = () => {
    const classes = useStyles();

    const doNothing = () => {};

    const listObjects = {
        unassignedObjects: [],
        assignedObjects: [],
        filteredAssignedObjects: [],
        originalAssignedObjects: [],
    };

    const filter = {
        typeUnassigned: '',
        nameUnassigned: '',
        typeAssigned: '',
        nameAssigned: '',
    };

    const renderCustomList = (title: string) => {
        return (
            <Grid className={classes.gridItem} item>
                <CustomList
                    loaded={true}
                    typeInput={{
                        type: '',
                        setType: doNothing,
                    }}
                    nameInput={{
                        name: '',
                        setName: doNothing,
                    }}
                    title={TranslationHelper.translate(title)}
                    items={[]}
                    handleToggle={() => undefined}
                    checked={[]}
                    setChecked={doNothing}
                    types={[]}
                    disabled={true}
                />
            </Grid>
        );
    };
    const leftList = renderCustomList('objects.unassigned');
    const rightList = renderCustomList('objects.assigned');

    return (
        <TransferList
            objectLists={listObjects}
            setObjectLists={doNothing}
            added={[]}
            setAdded={doNothing}
            removed={[]}
            setRemoved={doNothing}
            filter={filter}
            checked={[]}
            setChecked={doNothing}
            filterObjects={() => []}
            customComponents={{ leftList, rightList }}
        />
    );
};

export default function TransferList<T extends ITransferListObject>({
    objectLists,
    setObjectLists,
    added,
    setAdded,
    removed,
    setRemoved,
    filter,
    checked,
    setChecked,
    filterObjects,
    customComponents,
}: IOwnProps<T>) {
    const { typeAssigned, nameAssigned, typeUnassigned, nameUnassigned } =
        filter;

    const {
        assignedObjects,
        unassignedObjects,
        filteredAssignedObjects,
        originalAssignedObjects,
    } = objectLists;
    const classes = useStyles();

    const leftChecked = intersection(checked, unassignedObjects);
    const rightChecked = intersection(checked, assignedObjects);

    const handleAllRight = () => {
        handleAdd(unassignedObjects);
    };

    const handleAllLeft = () => {
        handleRemove(filteredAssignedObjects);
    };

    const handleCheckedRight = () => {
        handleAdd(leftChecked);
    };

    const handleCheckedLeft = () => {
        handleRemove(rightChecked);
    };

    const handleAdd = (toAdd: T[]) => {
        setObjectLists({
            assignedObjects: assignedObjects.concat(toAdd),
            filteredAssignedObjects: filteredAssignedObjects.concat(
                filterObjects(toAdd, typeAssigned, nameAssigned)
            ),
            unassignedObjects: not(unassignedObjects, toAdd),
        });
        setChecked(not(checked, toAdd));
        setAdded(added.concat(not(toAdd, originalAssignedObjects)));
        setRemoved(not(removed, toAdd));
    };
    const handleRemove = (toRemove: T[]) => {
        setObjectLists({
            assignedObjects: not(assignedObjects, toRemove),
            filteredAssignedObjects: not(filteredAssignedObjects, toRemove),
            unassignedObjects: unassignedObjects.concat(
                filterObjects(toRemove, typeUnassigned, nameUnassigned)
            ),
        });
        setChecked(not(checked, toRemove));
        setRemoved(
            removed.concat(intersection(toRemove, originalAssignedObjects))
        );
        setAdded(not(added, toRemove));
    };

    return (
        <Grid
            container
            spacing={2}
            justifyContent="center"
            alignItems="center"
            className={classes.root}
        >
            <Grid className={classes.gridItem} item>
                {customComponents.leftList}
            </Grid>
            <Grid className={classes.gridItemButtons} item>
                <Grid container direction="column" alignItems="center">
                    <Tooltip
                        title={TranslationHelper.translate('Move all right')}
                    >
                        <span>
                            <Button
                                variant="outlined"
                                size="small"
                                className={classes.button}
                                onClick={handleAllRight}
                                disabled={
                                    unassignedObjects.length === 0 ||
                                    unassignedObjects.length >
                                        ITEMS_TOGGLE_LIMIT
                                }
                                aria-label="move all right"
                            >
                                ≫
                            </Button>
                        </span>
                    </Tooltip>

                    <Tooltip
                        title={TranslationHelper.translate(
                            'Move selected right'
                        )}
                    >
                        <span>
                            <Button
                                variant="outlined"
                                size="small"
                                className={classes.button}
                                onClick={handleCheckedRight}
                                disabled={leftChecked.length === 0}
                                aria-label="move selected right"
                            >
                                &gt;
                            </Button>
                        </span>
                    </Tooltip>

                    <Tooltip
                        title={TranslationHelper.translate(
                            'Move selected left'
                        )}
                    >
                        <span>
                            <Button
                                variant="outlined"
                                size="small"
                                className={classes.button}
                                onClick={handleCheckedLeft}
                                disabled={rightChecked.length === 0}
                                aria-label="move selected left"
                            >
                                &lt;
                            </Button>
                        </span>
                    </Tooltip>

                    <Tooltip
                        title={TranslationHelper.translate('Move all left')}
                    >
                        <span>
                            <Button
                                variant="outlined"
                                size="small"
                                className={classes.button}
                                onClick={handleAllLeft}
                                disabled={
                                    filteredAssignedObjects.length === 0 ||
                                    filteredAssignedObjects.length >
                                        ITEMS_TOGGLE_LIMIT
                                }
                                aria-label="move all left"
                            >
                                ≪
                            </Button>
                        </span>
                    </Tooltip>
                </Grid>
            </Grid>
            <Grid className={classes.gridItem} item>
                {customComponents.rightList}
            </Grid>
        </Grid>
    );
}
