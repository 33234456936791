import React, { Component, ComponentType, Fragment } from 'react';
import { compose } from 'redux';

import moment, { Moment } from 'moment-timezone';

import { Hidden } from '@material-ui/core';
import { default as LinearProgress } from '@material-ui/core/LinearProgress';

import Report from '../Report';

import { Connectable, TConnectableProps } from './Connectable.hoc';
import { Themable, TThemableProps } from './Themable.hoc';

import { IBookmark, IReportSet } from '../../../../state/types';
import { TPreviewPane } from '../../../../state/ui/discovery/snapshotting';

import TimeFormatter from '../../../../helpers/TimeFormatter';
import TranslationHelper from '../../../../helpers/TranslationHelper';

import { default as IconButton } from '@material-ui/core/IconButton';
import { default as Bookmark } from '@material-ui/icons/Bookmark';
import { default as CalendarIcon } from '@material-ui/icons/CalendarToday';
import { default as PreviousIcon } from '@material-ui/icons/KeyboardArrowLeft';
import { default as NextIcon } from '@material-ui/icons/KeyboardArrowRight';

import TimeRangeControls from '../PreviewPane/components/LastState/components/TimeRangeControls';
import BookmarkSnackbar from './BookmarkSnackbar';

import { AccessibleIconButton } from '../../../../components/accessControl/components';

interface IOwnProps {
    reportSet: IReportSet;
    creatorLevel: number;
    preview: TPreviewPane | null;
}

interface IState {
    isTimePickerEnabled: boolean;
}

type TProps = IOwnProps & TThemableProps & TConnectableProps;

const millisecondsInAlmostFullDay = 86399999;
const millisecondsInFullDay = 86400000;

class ReportSet extends Component<TProps, IState> {
    public state = {
        isTimePickerEnabled: false,
    };

    public makeMove = (
        reportSet: IReportSet,
        from: any,
        to: any,
        direction: 'left' | 'right'
    ) => {
        let diff = Math.abs(moment(to).diff(moment(from)));

        if (diff === millisecondsInAlmostFullDay) {
            diff = millisecondsInFullDay;
        }

        if ((diff + 60000) % millisecondsInFullDay === 0) {
            diff = diff + 60000;
        }

        const getDiff = () => (direction === 'left' ? -diff : diff);

        const monitoredId = reportSet._meta?.header?.monitoredId;

        const maxDate = moment()
            .tz(this.props.userSettings.timeZone)
            .endOf('day');

        if (monitoredId) {
            this.props.activateReportSet(
                monitoredId,
                moment(from)
                    .add(getDiff(), 'millisecond')
                    .tz(this.props.userSettings.timeZone)
                    .format(TimeFormatter.ISO_FORMAT),
                moment
                    .min(
                        moment(to)
                            .add(getDiff(), 'millisecond')
                            .tz(this.props.userSettings.timeZone),
                        maxDate
                    )
                    .format(TimeFormatter.ISO_FORMAT),
                {
                    type: 'report-set',
                    level: this.props.creatorLevel,
                }
            );
        }
    };

    public moveRangeLeft = () => {
        const { reportSet } = this.props;
        const { from, to } =
            (reportSet._meta && reportSet._meta.params) || reportSet;

        this.makeMove(reportSet, from, to, 'left');
    };

    public moveRangeRight = () => {
        const { reportSet } = this.props;
        const { from, to } =
            (reportSet._meta && reportSet._meta.params) || reportSet;

        this.makeMove(reportSet, from, to, 'right');
    };

    public handleBookmarkClick = (reportData: IBookmark) => {
        this.props.saveBookmark(reportData);
        this.props.handleSnackbar(true);
    };

    public render() {
        const {
            classes,
            preview,
            reportSet,
            isReportSetBeingFetched,
            isTrailBeingFetched,
            currentSnapshot,
            snapshotPaths,
            timeLocked,
            userSettings,
        } = this.props;

        const format = userSettings.dateFormat;

        const { colorPrimary, colorPrimaryDisabled } = classes;
        const buttonClasses = { colorPrimary };
        const buttonClassesDisabled = { colorPrimary: colorPrimaryDisabled };

        const from =
            (reportSet._meta &&
                reportSet._meta.params &&
                reportSet._meta.params.from) ||
            reportSet.from;
        const to =
            (reportSet._meta &&
                reportSet._meta.params &&
                reportSet._meta.params.to) ||
            reportSet.to;

        const fromMoment = moment(from);
        const toMoment = moment(to);

        const timeFrom = fromMoment.format(format);
        const timeTo = toMoment.format(format);

        const monitoredId =
            reportSet._meta &&
            reportSet._meta.header &&
            reportSet._meta.header.monitoredId;

        const monitoredName =
            reportSet._meta &&
            reportSet._meta.header &&
            reportSet._meta.header.name;

        const isFull = (time: Moment) =>
            (time.hours() === 0 || time.hours() === 23) &&
            (time.minutes() === 0 || time.minutes() === 59) &&
            (time.seconds() === 0 || time.seconds() === 59);

        const displayWithTime = !(isFull(fromMoment) && isFull(toMoment));
        const displayToDate =
            !fromMoment.isSame(toMoment, 'day') ||
            Math.abs(fromMoment.diff(toMoment)) < millisecondsInAlmostFullDay;
        const blockNext = toMoment.isAfter(moment());

        const reportData: IBookmark = {
            id: `${monitoredId}_${reportSet.from}_${reportSet.to}`,
            monitoredName: monitoredName || '',
            monitoredId: monitoredId || '',
            level: this.props.creatorLevel,
            from:
                (reportSet._meta &&
                    reportSet._meta.params &&
                    reportSet._meta.params.from) ||
                reportSet.from ||
                '-',
            to:
                (reportSet._meta &&
                    reportSet._meta.params &&
                    reportSet._meta.params.to) ||
                reportSet.to ||
                '-',
            snapshot: currentSnapshot,
            snapshotPaths,
            timeLocked,
        };

        return (
            <React.Fragment>
                {(isReportSetBeingFetched || isTrailBeingFetched) && (
                    <LinearProgress />
                )}
                {reportSet && from && to && (
                    <Fragment>
                        <div className={classes.timeRange}>
                            <IconButton
                                aria-label="Previous"
                                onClick={this.moveRangeLeft}
                                disabled={timeLocked}
                            >
                                <PreviousIcon
                                    color="primary"
                                    classes={
                                        timeLocked
                                            ? buttonClassesDisabled
                                            : buttonClasses
                                    }
                                />
                            </IconButton>
                            <div className={classes.timeWrapper}>
                                <p
                                    className={
                                        displayToDate
                                            ? classes.time
                                            : classes.timeSingle
                                    }
                                >
                                    <span className={classes.label}>
                                        {TranslationHelper.translate(
                                            displayToDate ? 'from' : 'for'
                                        )}
                                    </span>{' '}
                                    <span className={classes.timeValue}>
                                        {timeFrom.split(' ')[0]}{' '}
                                        {displayWithTime && (
                                            <strong>
                                                {timeFrom.split(' ')[1]}
                                            </strong>
                                        )}
                                    </span>
                                </p>
                                {displayToDate && (
                                    <p className={classes.time}>
                                        <span className={classes.label}>
                                            {TranslationHelper.translate('to')}
                                        </span>{' '}
                                        <span className={classes.timeValue}>
                                            {timeTo.split(' ')[0]}{' '}
                                            {displayWithTime && (
                                                <strong>
                                                    {timeTo.split(' ')[1]}
                                                </strong>
                                            )}
                                        </span>
                                    </p>
                                )}
                            </div>
                            <IconButton
                                aria-label="Next"
                                disabled={timeLocked || blockNext}
                                onClick={this.moveRangeRight}
                            >
                                <NextIcon
                                    color="primary"
                                    classes={
                                        timeLocked || blockNext
                                            ? buttonClassesDisabled
                                            : buttonClasses
                                    }
                                />
                            </IconButton>
                            <IconButton
                                aria-label="Calendar"
                                onClick={() =>
                                    this.setState({
                                        isTimePickerEnabled:
                                            !this.state.isTimePickerEnabled,
                                    })
                                }
                                disabled={timeLocked}
                            >
                                <CalendarIcon
                                    color="primary"
                                    classes={
                                        timeLocked
                                            ? buttonClassesDisabled
                                            : buttonClasses
                                    }
                                />
                            </IconButton>

                            <Hidden smDown={true}>
                                <AccessibleIconButton
                                    check={(privileges) => privileges.bookmarks}
                                    onClick={() =>
                                        this.handleBookmarkClick(reportData)
                                    }
                                    title={TranslationHelper.translate('Save')}
                                >
                                    <Bookmark
                                        color="primary"
                                        classes={buttonClasses}
                                    />
                                </AccessibleIconButton>
                            </Hidden>
                        </div>
                        {this.state.isTimePickerEnabled && monitoredId && (
                            <TimeRangeControls
                                monitoredId={monitoredId}
                                level={this.props.creatorLevel}
                                from={from}
                                to={to}
                            />
                        )}
                    </Fragment>
                )}

                {reportSet &&
                    reportSet.reports &&
                    reportSet.reports.map((report, index) => (
                        <Report
                            key={index}
                            preview={preview}
                            report={report}
                            from={reportSet.from}
                            to={reportSet.to}
                            creatorLevel={this.props.creatorLevel}
                        />
                    ))}
                {reportSet &&
                    reportSet.reports &&
                    reportSet.reports.length === 0 && (
                        <p>
                            {TranslationHelper.translate(
                                'No report set data found'
                            )}
                        </p>
                    )}
                <BookmarkSnackbar />
            </React.Fragment>
        );
    }
}

export default compose(
    Themable,
    Connectable
)(ReportSet) as ComponentType<IOwnProps>;
