import React from 'react';

import { Tooltip } from '@material-ui/core';
import { InfoOutlined as InfoIcon } from '@material-ui/icons';

import { IGroup } from '../../../state/app/collections/dataTypes';

const MAX_GROUPS_TO_DISPLAY = 3;

interface IOwnProps {
    groups: IGroup[];
}
const GroupsList = ({ groups }: IOwnProps) => {
    const renderGroups = () => {
        return renderGroupsList(groups.slice(0, 3));
    };

    const renderGroupsList = (groups: IGroup[]) => {
        return groups.map(({ name, id }) => {
            return <div key={id}>{name}</div>;
        });
    };
    return (
        <>
            {groups.length > MAX_GROUPS_TO_DISPLAY ? (
                <>
                    <div>{renderGroups()}...</div>
                    <Tooltip
                        title={groups.map((group) => group.name).toString()}
                    >
                        <div
                            style={{ display: 'inline', cursor: 'pointer' }}
                            data-testid="icon"
                        >
                            <InfoIcon fontSize="small" />
                        </div>
                    </Tooltip>
                </>
            ) : (
                <>{renderGroups()}</>
            )}
        </>
    );
};

export default GroupsList;
