import React from 'react';

import { Dialog, DialogContent, useMediaQuery } from '@material-ui/core';

import ChangeLoginForm from '../../../Authentication/ChangeLoginForm/ChangeLoginForm';
import LoginFormFooter from '../../../Authentication/LoginFormFooter';

import useToggle from '../../../../hooks/useToggle';
import { useAppDispatch } from '../../../../hooks';

import { LoginPageLogo } from '../../../../pages/login/LoginPage/LoginPageLogo';

import { MOBILE } from '../../../../constants/dictionaries/ScreenSizeConst';
import { hideGlobalDialog } from '../../../../state/ui/dialog';

interface IOwnProps {
    redirectAfterLogin?: () => void;
}

const LoginMigrationDialog = ({ redirectAfterLogin }: IOwnProps) => {
    const { isOpen, handleToggle } = useToggle(true);

    const isMobile = useMediaQuery(MOBILE);

    const dispatch = useAppDispatch();

    const handleClose = (event?: object, reason?: string) => {
        if (reason === 'backdropClick') {
            return;
        }
        dispatch(hideGlobalDialog());

        handleToggle();
        redirectAfterLogin && redirectAfterLogin();
    };
    return (
        <Dialog
            open={isOpen}
            onClose={handleClose}
            maxWidth={'lg'}
            fullScreen={isMobile}
            disableEscapeKeyDown
        >
            <DialogContent style={{ maxWidth: 400 }}>
                <LoginPageLogo />

                <ChangeLoginForm />

                <LoginFormFooter />
            </DialogContent>
        </Dialog>
    );
};

export default LoginMigrationDialog;
