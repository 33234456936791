import React, { Component, ComponentType } from 'react';
import { compose } from 'redux';
import { Themable, TThemableProps } from './Themable.hoc';

import { withSnackbar, WithSnackbarProps } from 'notistack';
import { IReportEntity } from '../../../../../../../../state/types';

import { Button, LinearProgress } from '@material-ui/core';

import { responseMessage } from '../../../../../../../../helpers/responseMessage';
import TranslationHelper from '../../../../../../../../helpers/TranslationHelper';
import { sendData } from './_utils/sendToggleAction';

interface IOwnProps {
    data: IReportEntity;
}

type TProps = IOwnProps & TThemableProps & WithSnackbarProps;

interface IState {
    value: string;
    pending: boolean;
}

class ToggleAction extends Component<TProps> {
    public state = {
        value: this.props.data.value,
        pending: false,
    } as IState;

    public componentDidUpdate(prevProps: Readonly<TProps>): void {
        if (
            prevProps.data.value !== this.props.data.value &&
            this.props.data.value !== this.state.value
        ) {
            this.setState({
                value: this.props.data.value,
            });
        }
    }

    public handleClick = () => {
        this.setState({
            pending: true,
        });
        if (this.props.data.actions) {
            sendData(this.props.data.actions[this.state.value])
                .then(() => {
                    const message = responseMessage(200);
                    this.props.enqueueSnackbar(
                        TranslationHelper.translate(message.text),
                        {
                            variant: message.type,
                        }
                    );

                    return true;
                })
                .catch((error) => {
                    const message = responseMessage(error.status);
                    this.props.enqueueSnackbar(
                        TranslationHelper.translate(message.text),
                        {
                            variant: message.type,
                        }
                    );
                    return false;
                })
                .then((success) => {
                    let value = this.state.value;

                    if (success) {
                        value =
                            this.state.value === 'stateOn'
                                ? 'stateOff'
                                : 'stateOn';
                    }

                    this.setState({
                        pending: false,
                        value,
                    });
                });
        }
    };

    public render() {
        const { classes } = this.props;
        const { actions } = this.props.data;
        const { value, pending } = this.state;

        return (
            <Button
                onClick={this.handleClick}
                disabled={pending}
                fullWidth
                style={{ justifyContent: 'flex-start' }}
            >
                {pending && <LinearProgress className={classes.buttonLoader} />}
                {TranslationHelper.translate(actions && actions[value].label)}
            </Button>
        );
    }
}

export default compose(
    Themable,
    withSnackbar
)(ToggleAction) as ComponentType<IOwnProps>;
