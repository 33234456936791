import React, { lazy, Suspense } from 'react';

import { LinearProgress } from '@material-ui/core';

import { TGridProps } from './types';

const AgGridEnterprise = lazy(() => import('./AgGridEnterprise'));

export default (props: TGridProps) => {
    return (
        <Suspense fallback={<LinearProgress />}>
            <AgGridEnterprise {...props} />
        </Suspense>
    );
};
