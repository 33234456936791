import store from '../store';

import { vsprintf } from 'sprintf-js';

import { getContextTranslations } from '../state/app/translations';

const getPhraseFromStore = (phrase: string, context: string): string => {
    const translationContext = getContextTranslations(store.getState());
    const wxContext = 'wx';
    if (translationContext) {
        if (
            translationContext[context] &&
            translationContext[context][phrase]
        ) {
            return translationContext[context][phrase];
        } else if (
            context !== wxContext &&
            translationContext[wxContext] &&
            translationContext[wxContext][phrase]
        ) {
            return translationContext[wxContext][phrase];
        }
    }
    return phrase;
};
class TranslationHelper {
    public static translateFormat(
        phrase: string,
        argsToFormat: any[],
        context: string = 'wx'
    ) {
        return vsprintf(getPhraseFromStore(phrase, context), argsToFormat);
    }

    public static translate(phrase: string, context: string = 'wx') {
        return getPhraseFromStore(phrase, context);
    }

    public static makeDateTimeTranslations() {
        return {
            dateTimePickerToolbarTitle: this.translate('Select date and time'),
            okButtonLabel: this.translate('Confirm'),
            cancelButtonLabel: this.translate('Cancel'),
            cancelLabel: this.translate('Cancel'),
            okLabel: this.translate('Confirm'),
            clearLabel: this.translate('Clear'),
            invalidLabel: this.translate('Invalid'),
            invalidDateMessage: this.translate('Invalid date format'),
            maxDateMessage: this.translate(
                'Date should not be after maximal date'
            ),
            minDateMessage: this.translate(
                'Date should not be before minimal date'
            ),
            todayLabel: this.translate('Today'),
        };
    }
}

export default TranslationHelper;
