import React, { Component, ComponentType } from 'react';

import { connect } from 'react-redux';
import { TRootState } from '../../../store';

import { Tooltip } from '@material-ui/core';

import TranslationHelper from '../../../helpers/TranslationHelper';

import { IPrivileges } from '../../../state/auth';

interface IOwnProps {
    userPrivileges: IPrivileges;
}
interface IProps {
    check: (privilegesList: IPrivileges) => boolean;
    tooltipTitle?: string;
    dispatch?: any;
    target?: string;
    rel?: string;
}
type TProps = IOwnProps & IProps;
const hasAccessWrapper = <P extends object>(
    WrappedComponent: ComponentType<P>
): ComponentType<IProps & P> => {
    class HasAccessWrapper extends Component<TProps> {
        public render() {
            const { check, tooltipTitle, userPrivileges, dispatch, ...rest } =
                this.props as TProps;
            const props = rest as P;

            if (check(userPrivileges)) {
                if (!!tooltipTitle) {
                    return (
                        <Tooltip
                            title={TranslationHelper.translate(tooltipTitle)}
                        >
                            <span>
                                <WrappedComponent {...this.state} {...props} />
                            </span>
                        </Tooltip>
                    );
                } else {
                    return <WrappedComponent {...this.state} {...props} />;
                }
            } else {
                return <></>;
            }
        }
    }
    // @ts-ignore
    return connect((state: TRootState) => ({
        userPrivileges: state.auth.privileges,
    }))(HasAccessWrapper);
};

export default hasAccessWrapper;
