import React, { Component, ComponentType } from 'react';
import { compose } from 'redux';
import View from '../../components/View';
import TranslationHelper from '../../helpers/TranslationHelper';
import { IModule } from '../../state/app/iframes';
import { Connectable, TConnectableProps } from './Connectable.hoc';
import { Themable, TThemableProps } from './Themable.hoc';
import Page404 from '../error/Page404';

type TProps = TThemableProps & TConnectableProps;

const en = 'en';

class IFramePage extends Component<TProps> {
    public makeExternalAddress(iframe: IModule, language: string): string {
        return (
            iframe.url ||
            (iframe.urls && (iframe.urls[language] || iframe.urls[en])) ||
            ''
        );
    }

    public makeInternalAddress(
        webXClientUrl: string,
        iframe: IModule,
        user: string,
        language: string
    ) {
        const param = {
            username: user,
            pass: '',
            nrk: '',
            forward: iframe.url,
        };
        return (
            webXClientUrl +
            '/' +
            language +
            '/sfGuardAuth/signinExternal?p=' +
            window.btoa(JSON.stringify(param))
        );
    }

    public getAddress(
        module: string,
        modules: Record<string, IModule>,
        webxClientUrl: string
    ) {
        let address;
        const user = this.props.loginStore.user;
        const language = this.props.loginStore.language;
        const iframeModule = modules[module];
        if (iframeModule && user) {
            if (iframeModule.external) {
                address = this.makeExternalAddress(iframeModule, language);
            } else {
                address = this.makeInternalAddress(
                    webxClientUrl as string,
                    iframeModule,
                    user,
                    language
                );
            }
        } else {
            address = '';
        }

        return address;
    }

    public getName(module: string, modules: Record<string, IModule>) {
        const iframe = modules[module];
        let name = '';
        if (iframe) {
            name =
                (iframe.label && TranslationHelper.translate(iframe.label)) ||
                ((iframe.names &&
                    iframe.names[this.props.loginStore.language]) as string);
        }
        return name;
    }

    public render() {
        const { modules, webxClientUrl, classes } = this.props;
        if (!modules) {
            return;
        }
        const { iframe } = this.props.match.params;

        const title = this.getName(iframe, modules);
        return title ? (
            <View title={title} noPadding={true} noColumn={true}>
                <div className={classes.fullPage}>
                    <iframe
                        src={this.getAddress(iframe, modules, webxClientUrl)}
                        frameBorder="0"
                        width="100%"
                        height="100%"
                    />
                </div>
            </View>
        ) : (
            <Page404></Page404>
        );
    }
}

export default compose(Themable, Connectable)(IFramePage) as ComponentType<{}>;
