import { Dispatch } from 'redux';
import { fetchLastState } from '../../../../services/discovery/fetchLastState';
import { makeFetchModule } from '../../../_utils';
import { storeLastStates } from '../../entities';

const callbacks = {
    onSuccess: (dispatch: Dispatch, { entities }: { entities: object }) => {
        dispatch(storeLastStates(entities));
    },
};
const {
    reducer,
    fetchData,
    reset,
    stopFetchingElement,
    stopFetchingCollection,
} = makeFetchModule(
    'app/discovery/lastStateFetching',
    fetchLastState,
    callbacks
);

export default reducer;
export { fetchData, reset, stopFetchingElement, stopFetchingCollection };
