import { createStyles, Theme, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            flexGrow: 1,
            display: 'flex',
            overflow: 'hidden',
            height: '100%',
        },
        chart: {
            flex: '1 1 auto',
            width: 50,
        },
        wrapper: {
            flexGrow: 1,
            width: '100%',
        },
        paramSelectorWrapper: {
            width: '20%',
            height: '100%',
        },
        paramSelectorHeader: {
            padding: '3px 5px',
            display: 'block',
            height: '30px',
            boxSizing: 'border-box',
        },
        paramList: {
            height: 'calc(100% - 30px)',
            overflowY: 'scroll',
            display: 'grid',
            alignContent: 'start',
        },
        paramSelectorItem: {
            cursor: 'pointer',
            padding: '3px 5px',
            borderBottomWidth: '3px',
            borderBottomStyle: 'solid',
        },
        tooltip: {
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            padding: theme.spacing(2),
            boxShadow: '0 3px 5px rgba(0, 0, 0, 0.3)',
        },
        tooltipDate: {
            fontSize: '60%',
            color: 'gray',
        },
        tooltipEntry: {
            fontSize: '80%',
        },
    })
);
