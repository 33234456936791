import React, { ReactChild, ReactNode } from 'react';

import { Box, IconButton, Typography } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import ClearIcon from '@mui/icons-material/Clear';

import TranslationHelper from '../../helpers/TranslationHelper';
import { styles } from './styles';
import { StyledBarBox } from '../Styled/StyledBarBox';
interface IOwnProps {
    onCloseClick?: () => void;
    title: string;
    renderRightCustomControls?: () => ReactChild;
    renderLeftCustomControls?: () => ReactChild;
    submitting?: boolean;
    titleComponent?: ReactNode;
}

const PaneHeader = ({
    title,
    onCloseClick,
    renderLeftCustomControls,
    renderRightCustomControls,
    submitting,
    titleComponent,
}: IOwnProps) => {
    return (
        <Box sx={styles().wrapper}>
            <StyledBarBox>
                <StyledBarBox>
                    {titleComponent ?? (
                        <Typography
                            variant="h6"
                            sx={{
                                wordBreak: 'break-word',
                                mr: 1,
                                ...styles().contrastText,
                            }}
                        >
                            {title}
                        </Typography>
                    )}

                    {renderLeftCustomControls && (
                        <Box sx={styles().leftCustomControls}>
                            {renderLeftCustomControls()}
                        </Box>
                    )}
                </StyledBarBox>

                <Box sx={styles().rightCustomControls}>
                    {renderRightCustomControls && renderRightCustomControls()}
                    {onCloseClick && (
                        <Tooltip title={TranslationHelper.translate('Close')}>
                            <IconButton
                                onClick={onCloseClick}
                                disabled={submitting}
                            >
                                <ClearIcon sx={styles().contrastText} />
                            </IconButton>
                        </Tooltip>
                    )}
                </Box>
            </StyledBarBox>
        </Box>
    );
};

export default PaneHeader;
