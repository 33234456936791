import { Dispatch } from 'redux';
import { fetchLocationsRegister } from '../../../../services/discovery/fetchLocations';
import { makeFetchModule } from '../../../_utils';
import { resetLocationsRegister, storeLocationsRegister } from '../../entities';

const callbacks = {
    onSuccess: (dispatch: Dispatch, sourceSet: object) => {
        dispatch(storeLocationsRegister(sourceSet));
    },
    onReset: (dispatch: Dispatch) => {
        dispatch(resetLocationsRegister());
    },
};
const { reducer, fetchData, reset } = makeFetchModule(
    'app/discovery/locationsRegister',
    fetchLocationsRegister,
    callbacks
);

export default reducer;
export { fetchData, reset };
