import React from 'react';

import { Field, Form, Formik } from 'formik';

import SaveIcon from '@material-ui/icons/Save';

import { useStyles } from './Themable.hooks';

import {
    goToPreviewMode,
    resetMode,
} from '../../../../../../../../state/ui/groupsSlice';
import {
    createGroup,
    updateGroup,
} from '../../../../../../../../state/app/collections';

import TranslationHelper from '../../../../../../../../helpers/TranslationHelper';
import {
    useAppDispatch,
    useToolkitDispatch,
} from '../../../../../../../../hooks';

import { groupSchema } from '../../../../../../../../schemas';

import { IGroupForm } from '../../../../settings';

import FieldWrapper from '../../../../../../../../components/common/formikWrappers/FieldWrapper';
import PaneHeader from '../../../../../../../../components/PaneHeader/PaneHeader';
import IconButtonWithTooltip from '../../../../../../../../components/IconButtonWithTooltip/IconButtonWithTooltip';
import { useSelectedGroup } from '../../../../../../../../pages/management/selectors/index.hooks';
interface IOwnProps {
    title: string;
    tooltip: string;
}

const blankForm: IGroupForm = {
    name: '',
    number: 0,
};

const GroupForm = ({ title, tooltip }: IOwnProps) => {
    const classes = useStyles();

    const groupData = useSelectedGroup();
    const dispatch = useAppDispatch();
    const toolkitDispatch = useToolkitDispatch();

    const handleClose = (submitting: boolean) => {
        if (!submitting) {
            groupData?.id ? dispatch(goToPreviewMode()) : dispatch(resetMode());
        }
    };

    const getRightCustomControls = (
        submitForm: () => void,
        submitting: boolean
    ) => (
        <IconButtonWithTooltip
            title={tooltip}
            onClick={submitForm}
            disabled={submitting}
        >
            <SaveIcon />
        </IconButtonWithTooltip>
    );

    const handleValues = (group: IGroupForm, { setSubmitting }: any) => {
        const data = { ...group };
        if (groupData && groupData.id) {
            dispatch(
                updateGroup(
                    {
                        id: groupData.id,
                        name: data.name.trim(),
                        number: data.number || 0,
                    },
                    () => {
                        setSubmitting(false);
                        toolkitDispatch(goToPreviewMode());
                    }
                )
            );
        } else {
            dispatch(
                createGroup(
                    { number: data.number || 0, name: data.name.trim() },
                    () => {
                        setSubmitting(false);
                        toolkitDispatch(resetMode());
                    }
                )
            );
        }
    };

    const commonProps = {
        fullWidth: true,
        component: FieldWrapper,
    };

    return (
        <div>
            <Formik
                enableReinitialize={true}
                initialValues={groupData || blankForm}
                onSubmit={handleValues}
                validateOnBlur={false}
                validateOnChange={false}
                validationSchema={groupSchema()}
                validate={(values) => ({})}
            >
                {({ submitForm, errors, values, isSubmitting }) => (
                    <>
                        <PaneHeader
                            onCloseClick={() => handleClose(isSubmitting)}
                            title={title}
                            renderRightCustomControls={() =>
                                getRightCustomControls(submitForm, isSubmitting)
                            }
                            submitting={isSubmitting}
                        />
                        <div className={classes.formWrapper}>
                            <Form>
                                <Field
                                    error={!!(errors && errors.name)}
                                    helperText={errors && errors.name}
                                    name={'name'}
                                    label={TranslationHelper.translate('Name')}
                                    type={'text'}
                                    required={true}
                                    {...commonProps}
                                />

                                <Field
                                    error={!!(errors && errors.number)}
                                    helperText={errors && errors.number}
                                    name={'number'}
                                    label={TranslationHelper.translate(
                                        'Number'
                                    )}
                                    type={'number'}
                                    required={true}
                                    {...commonProps}
                                />
                            </Form>
                        </div>
                    </>
                )}
            </Formik>
        </div>
    );
};

export default GroupForm;
