import { connect } from 'react-redux';
import { changePassword, resetPassword } from '../../state/auth';
import { getCustomers } from '../../state/login';
import { TRootState } from '../../store';

import { handleCustomers } from '../../services/Customers';

const mapStateToProps = (state: TRootState) => ({
    customers: getCustomers(state),
});

const mapDispatchToProps = {
    changePassword,
    resetPassword,
    handleCustomers,
};

type TStateProps = ReturnType<typeof mapStateToProps>;
type TDispatchProps = typeof mapDispatchToProps;

export type TConnectableProps = TStateProps & TDispatchProps;

export const Connectable = connect(mapStateToProps, mapDispatchToProps);
