import { FieldProps } from 'formik';
import React from 'react';

import AsyncSelectField from '../../../AsyncSelectField';

interface IOwnProps {
    valueKey: string;
    label: string;
    getOptions: any;
    afterOnChange?: (value: string) => void;
}

type TProps = FieldProps & IOwnProps;

const AsyncSelectWrapper: React.FunctionComponent<TProps> = ({
    valueKey,
    label,
    field,
    form,
    getOptions,
    afterOnChange,
    ...props
}) => {
    return (
        <AsyncSelectField
            label={label}
            getOptions={getOptions}
            {...field}
            {...props}
            disableClearable={true}
            onChange={(event, value) => {
                form.setFieldValue(field.name, value[valueKey]);
            }}
        />
    );
};

export default AsyncSelectWrapper;
