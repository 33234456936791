import React, { useEffect, useMemo, useState } from 'react';

import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    Tooltip,
    ReferenceLine,
    ResponsiveContainer,
} from 'recharts';

import { cloneDeep } from 'lodash';

import TimeFormatter from '../../../../helpers/TimeFormatter';
import TranslationHelper from '../../../../helpers/TranslationHelper';
import { getActivityColor } from './utils/helpers';
import { DetailedEvent } from './components/DetailedTooltip';
import { ActivitiesList } from './components/ActivitiesList';
import { useAppDispatch } from '../../../../hooks';
import {
    useCurrentSnapshotLevel,
    useDiscoveryChartPane,
    useGridCreatorLevel,
    usePreviewPaneId,
} from '../../selectors/index.hooks';
import { fetchTrail, selectObject } from '../../../../state/_actions';
import { ISourceSetEntity } from '../../../../state/types';
import { processMinuteData } from './utils/processMinuteData';
import LoadingSpinner from '../../../../components/loadingSpinner/LoadingSpinner';
import { useSourceSet } from '../../selectors/composed/index.hooks';

export type TTachoActivity =
    | 'driving'
    | 'resting'
    | 'working'
    | 'available'
    | 'unavailable'
    | 'error'
    | null;
export interface IMinuteData {
    event: ISourceSetEntity | null;
    minute: number;
    segmentId: number;
    driving: number;
    resting: number;
    working: number;
    available: number;
    error: number;
    unavailable: number;
    activity: TTachoActivity;
    lastEventTimestamp: string | null;
}

export interface ITotals {
    driving: number;
    resting: number;
    working: number;
    available: number;
    error: number;
    unavailable: number;
}

export interface ISegment {
    id: number;
    activity: TTachoActivity;
    startTime: number;
    status: number | undefined;
    duration: number;
}

export const MINUTES = 1440;

const TachographChart = () => {
    const snapshotLevel = useCurrentSnapshotLevel();
    const chartPane = useDiscoveryChartPane();
    const sourceSet = useSourceSet();
    const creatorLevel = useGridCreatorLevel();
    const selectedSourceSetElementId = usePreviewPaneId();
    const dispatch = useAppDispatch();

    const [loading, setLoading] = useState(false);
    const tachoKey =
        chartPane?.sourceSetAction.params.paramsPrefix === 'tacho_dr1'
            ? 'TACHO_DR1_WORK_ST'
            : 'TACHO_DR2_WORK_ST';

    const driver = TranslationHelper.translate(`driver.${tachoKey}`);

    const eventsArray = useMemo(
        () => cloneDeep(sourceSet?.entities ?? []),
        [sourceSet?.id, tachoKey]
    );

    const { data, segments } = useMemo(
        () => processMinuteData(eventsArray, tachoKey),
        [eventsArray]
    );

    const totals = useMemo<ITotals>(() => {
        return data.reduce<ITotals>(
            (acc, minute) => {
                if (!minute.activity) {
                    return acc;
                }
                acc[minute.activity]++;
                return acc;
            },
            {
                driving: 0,
                resting: 0,
                working: 0,
                available: 0,
                error: 0,
                unavailable: 0,
            }
        );
    }, [data]);

    useEffect(() => {
        if (!chartPane?.sourceSetAction.params) {
            return;
        }
        setLoading(true);
        const { from, to, monitoredId } = chartPane?.sourceSetAction.params;

        dispatch(
            fetchTrail(
                monitoredId,
                TimeFormatter.getISOString(from as string),
                TimeFormatter.getISOString(to as string),
                snapshotLevel
            )
        );
    }, [tachoKey]);

    useEffect(() => {
        if (totals.unavailable === MINUTES && loading) {
            setLoading(false);
        }
    }, [totals]);

    const handleBarClick = (data: any) => {
        if (data?.activePayload?.[0]?.payload.activity !== 'unavailable') {
            dispatch(
                selectObject(
                    sourceSet,
                    data?.activePayload?.[0]?.payload.event,
                    creatorLevel,
                    false
                )
            );
        }
    };
    const selectedElement = data.find((element) => {
        return element?.event?.id === selectedSourceSetElementId;
    })?.minute;

    return (
        <>
            <ActivitiesList totals={totals} driver={driver} />

            {loading && (
                <LoadingSpinner
                    size="50"
                    top="0"
                    left="0"
                    right="0"
                    bottom="0"
                />
            )}
            <ResponsiveContainer
                height="70%"
                width="95%"
                key={`${tachoKey} + ${totals.unavailable}`}
            >
                <BarChart
                    data={data}
                    margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                    onClick={loading ? undefined : handleBarClick}
                    key={`${tachoKey} + ${totals.unavailable}`}
                >
                    <XAxis
                        dataKey="minute"
                        tickFormatter={(value) =>
                            TimeFormatter.formatDuration(
                                Math.floor(value / 60),
                                'minutes',
                                'mm'
                            )
                        }
                        interval={59}
                        minTickGap={50}
                    />

                    <YAxis domain={[0, 1]} hide />
                    <Tooltip
                        content={
                            <DetailedEvent data={data} segments={segments} />
                        }
                        isAnimationActive={false}
                    />

                    {Object.keys(totals).map((activity) => (
                        <Bar
                            key={activity}
                            dataKey={activity}
                            fill={getActivityColor(activity as TTachoActivity)}
                            name={
                                activity.charAt(0).toUpperCase() +
                                activity.slice(1)
                            }
                            stackId={
                                activity === 'unavailable'
                                    ? 'unavailable'
                                    : 'stack'
                            }
                            cursor="pointer"
                            onAnimationEnd={() => {
                                setLoading(false);
                            }}
                        />
                    ))}
                    {selectedElement && (
                        <ReferenceLine
                            x={selectedElement}
                            stroke="black"
                            label={TranslationHelper.translate(
                                'Selected event'
                            )}
                        />
                    )}
                </BarChart>
            </ResponsiveContainer>
        </>
    );
};

export default TachographChart;
