export function removeDuplicatesById<T extends { id: string }>(arr: T[]): T[] {
    const uniqueIds = new Set<string>();
    const result: T[] = [];

    for (const current of arr) {
        if (!uniqueIds.has(current.id)) {
            uniqueIds.add(current.id);
            result.push(current);
        }
    }

    return result;
}
