import { createSlice } from '@reduxjs/toolkit';

import { TRootState } from '../../../store';
import { ILinkVideoArgs } from '../../../services/discovery/_shared/urlMakers';

interface IState {
    isDialogOpen: boolean;
    videoParams: ILinkVideoArgs | null;
    time: number;
}

const initialState: IState = {
    videoParams: null,
    isDialogOpen: false,
    time: 0,
};

const videoUrl = createSlice({
    name: 'videoUrl',
    initialState,
    reducers: {
        openVideoDialog(state) {
            state.isDialogOpen = true;
        },
        toggleVideoDialog(state) {
            state.isDialogOpen = !state.isDialogOpen;
        },
        setVideoParams(state, action) {
            state.videoParams = action.payload;
        },
        setVideoTime(state, action) {
            state.time = action.payload;
        },
    },
});

export const getVideoUrl = (state: TRootState) =>
    state.ui.videoUrl.isDialogOpen;
export const getVideoParams = (state: TRootState) =>
    state.ui.videoUrl.videoParams;
export const getVideoTime = (state: TRootState) => state.ui.videoUrl.time;

export const {
    openVideoDialog,
    setVideoParams,
    toggleVideoDialog,
    setVideoTime,
} = videoUrl.actions;

export default videoUrl.reducer;
