import UrlConst from '../../constants/UrlConst';
import {
    IEventItem,
    IGetEventsParams,
    IParamDefinitionItem,
} from '../../state/app/calibrations';
import apiClient from '../common/apiClient';
import { makeEndpoint, makeGetRequestSettings } from '../common/utils';

export interface IRestGetParamsDefinitions {
    objectId?: string;
    numerical?: boolean;
    calibration?: boolean;
    onlyVisible?: boolean;
    types?: string;
}

export const restGetParamsDefinitions = (
    queryParams: IRestGetParamsDefinitions
): Promise<IParamDefinitionItem[]> =>
    apiClient.sendWithoutErrorsHandling(
        makeGetRequestSettings(
            makeEndpoint(UrlConst.PARAMS_DEFINITIONS_URL, '', queryParams)
        )
    );

export const restGetEvents = (
    params: IGetEventsParams
): Promise<IEventItem[]> =>
    apiClient.sendWithoutErrorsHandling(
        makeGetRequestSettings(
            makeEndpoint(UrlConst.SOURCE_EVENTS_URL, '', params)
        )
    );
