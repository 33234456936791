import { IReportSetMeta, IReportSetParams } from '../state/types';
import TimeFormatter from './TimeFormatter';

export const getParamsFromStrategies = (
    strategies: string[],
    meta: IReportSetMeta
) => {
    const params: Partial<IReportSetParams> = {};

    strategies.forEach((strategy) => {
        if (strategy === 'startAndEndOfDay') {
            params.from = TimeFormatter.startOfDay(meta?.header?.date || '');
            params.to = TimeFormatter.endOfDay(meta?.header?.date || '');
        }
        if (strategy === 'monitoredId') {
            params.monitoredId = Number(meta?.header?.monitoredId);
        }
    });
    return params;
};
