export const DEFAULT_PRIMARY_COLOR = '#2d2d2d';

export const COLORS = {
    red: '#e53935',
    navy: '#3949AB',
    orange: '#FB8C00',
    green: '#7CB342',
    yellow: '#FDD835',
    gray: '#B9BAC1',
};
