import {
    createSlice,
    createSelector,
    createAsyncThunk,
} from '@reduxjs/toolkit';

import { TRootState } from '../../../../store';
import {
    restGetRoutesManagementTasks,
    restGetRoutesManagementUnassignedTasks,
} from '../../../../services/planning';

import { getMappedDictionaries } from '../../../../state/app/dictionaries';
import { ISourceSet, TStatus } from '../../../../state/types';

import TranslationHelper from '../../../../helpers/TranslationHelper';

import { getSourceSetModels } from '../../devices';
import { CSC_TASK_STATUS } from '../../../../constants/dictionaries/CscTaskStatus';
import {
    getRoutesAsObject,
    getSelectedRoutesColors,
    UNASSIGNED_ID,
} from '../routes';
import * as TaskStatuses from '../../../../constants/dictionaries/TaskStatuses';
import { ColorPool } from '../../../../helpers/ColorPool';
import { getSourceSetWithFilter } from '../../../../pages/discovery/selectors/sourceSet/getSourceSetWithFilter';

interface IMultiSelectedItem {
    id: number;
    sourceSetId: number;
}
interface IState {
    tasks: ITask[][] | null;
    mode: null | 'preview';
    selectedTaskId: number | null;
    selectedTask: { status: TStatus; data: ITask | null };
    selectedTasks: IMultiSelectedItem[];
    status: TStatus;
}

export interface ITask {
    id: number;
    location: {
        id: number;
        name: string;
        address: string;
    };
    activityCategoryId: number;
    serviceTypeId: number;
    objectCategoryId: number;
    serviceClassId?: number;
    plannedOn?: string;
    statusDate: string;
    status: number;
    plannedTimeFrom?: string;
    plannedTimeTo?: string;
    notice?: string;
    hasAttachments: boolean;
    routeId?: number;
    vehicle?: { id: number; name: string };
    employee?: { id: number; name: string };
    externalLinkages?: Array<{ appName: string; externalId: string }>;
}

export const initialState: IState = {
    tasks: null,
    mode: null,
    selectedTaskId: null,
    selectedTask: {
        status: 'idle',
        data: null,
    },
    selectedTasks: [],
    status: 'idle',
};

const ASSIGNABLE_TASK_STATUSES = TaskStatuses.TO_DO || TaskStatuses.IN_PROGRESS;

export const fetchPlanningTasks = createAsyncThunk(
    'get:ui/planning/routes-management/routes/:id/tasks',
    async (
        params: {
            selectedRoutes: IMultiSelectedItem[];
            activityCategoryId: number;
            dates: { from: string; to: string };
        },
        { rejectWithValue }
    ) => {
        const ids = params.selectedRoutes.map((route) => route.id);
        const responses = await Promise.all(
            ids.map(async (id) => {
                const getValues = async () => {
                    try {
                        if (id === UNASSIGNED_ID) {
                            const response =
                                await restGetRoutesManagementUnassignedTasks({
                                    activityCategoryId:
                                        params.activityCategoryId,
                                    from: params.dates.from,
                                    to: params.dates.to,
                                });
                            return response;
                        }
                        const response = await restGetRoutesManagementTasks(
                            String(id)
                        );
                        return response;
                    } catch (e: any) {
                        throw rejectWithValue({
                            status: e.status,
                            errorMsg: e.responseJSON.message,
                        });
                    }
                };

                const values = await getValues();
                return values;
            })
        );
        return responses;
    }
);
const tasks = createSlice({
    name: 'tasks',
    initialState,
    reducers: {
        enterTasksPreviewMode(state, action) {
            state.mode = 'preview';
            state.selectedTaskId = action.payload;
        },
        selectTask(state, action) {
            state.selectedTaskId = action.payload;
        },
        resetTasksPaneMode(state) {
            state.mode = null;
        },
        resetTasks(state) {
            state.tasks = null;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchPlanningTasks.pending, (state) => {
            state.status = 'loading';
        });
        builder.addCase(fetchPlanningTasks.rejected, (state) => {
            state.status = 'error';
            state.tasks;
        });
        builder.addCase(fetchPlanningTasks.fulfilled, (state, action) => {
            state.status = 'done';
            state.tasks = action.payload;
        });
    },
});

const getCurrent = (state: TRootState) => state.ui.planning.tasks as IState;

export const getTasksPaneMode = (state: TRootState) => getCurrent(state).mode;
export const getTasks = (state: TRootState) => getCurrent(state).tasks;
export const getTasksStatus = (state: TRootState) => getCurrent(state).status;
export const getSelectedTaskId = (state: TRootState) =>
    getCurrent(state).selectedTaskId;
export const getSelectedTask = (state: TRootState) =>
    getCurrent(state).selectedTask;

export const getSelectedTasks = (state: TRootState) =>
    getCurrent(state).selectedTasks;

export const getTasksSourceSetModel = createSelector(
    [getSourceSetModels],
    (models) => models.routesManagementTasks || null
);

export const isTasksPaneVisible = createSelector(
    [getTasksPaneMode],
    (mode) => !!mode
);

export const getTasksAsSourceSet = createSelector(
    [
        getTasks,
        getMappedDictionaries,
        getSelectedRoutesColors,
        getRoutesAsObject,
    ],
    (tasks, dictionaries, routeColors, routesObj): ISourceSet | null => {
        return (
            tasks && {
                id: 'routesManagementTasks',
                definitionId: 'routesManagementTasks',
                label: 'Tasks',
                attributes: [
                    {
                        id: 'placeholder',
                        label: '',
                        type: 'text',
                    },
                    {
                        id: 'hasAttachments',
                        label: 'Attachments',
                        type: 'iconAttachment',
                        cellClass: 'booleanType',
                    },
                    {
                        id: 'routeName',
                        label: 'Route',
                        type: 'text',
                    },
                    {
                        id: 'status',
                        label: 'Status',
                        type: 'text',
                        translate: 'wx',
                    },
                    {
                        id: 'plannedOn',
                        label: 'Planned on',
                        type: 'text',
                        formatting: { pattern: 'short' },
                    },
                    {
                        id: 'plannedTimeFrom',
                        label: 'From time',
                        type: 'text',
                    },
                    {
                        id: 'plannedTimeTo',
                        label: 'To time',
                        type: 'text',
                    },
                    {
                        id: 'statusDate',
                        label: 'Status date',
                        type: 'date',
                    },
                    {
                        id: 'locationName',
                        label: 'Location name',
                        type: 'text',
                    },
                    {
                        id: 'address',
                        label: 'Address',
                        type: 'text',
                    },
                    { id: 'vehicle', label: 'Vehicle name', type: 'text' },
                    { id: 'employee', label: 'Employee name', type: 'text' },

                    {
                        id: 'activityCategoryId',
                        label: 'Type 1',
                        type: 'text',
                    },
                    {
                        id: 'serviceTypeId',
                        label: 'Type 2',
                        type: 'text',
                    },
                    {
                        id: 'objectCategoryId',
                        label: 'Type 3',
                        type: 'text',
                    },
                    {
                        id: 'serviceClassId',
                        label: 'Type 4',
                        type: 'text',
                    },
                    {
                        id: 'notice',
                        label: 'Notice',
                        type: 'text',
                    },
                    {
                        id: 'externalLinkages',
                        label: 'External linkages',
                        type: 'list',
                        formatting: {
                            pattern: '{appName}: {externalId}',
                            separator: ', ',
                        },
                    },
                ],
                layersAttributes: [],
                _meta: {
                    actions: {
                        delete: {
                            label: 'Delete',
                            method: 'DELETE',
                            api: '/rest/api/routes-management/{id}',
                            params: {},
                        },
                    },
                },
                entities: tasks
                    .map((task: ITask[], i): any => {
                        return task.map((taskValue) => {
                            return {
                                ...taskValue,
                                color: taskValue?.routeId
                                    ? routeColors[String(taskValue.routeId)]
                                    : ColorPool.getDefaultColor(),
                                actions: ['delete', 'preview'],
                                activityCategoryId:
                                    dictionaries['activity-category']?.[
                                        taskValue.activityCategoryId
                                    ]?.name,
                                serviceTypeId:
                                    dictionaries['service-type']?.[
                                        taskValue.serviceTypeId
                                    ]?.name,
                                objectCategoryId:
                                    dictionaries['object-category']?.[
                                        taskValue.objectCategoryId
                                    ]?.name,
                                serviceClassId:
                                    dictionaries['service-class']?.[
                                        taskValue?.serviceClassId || ''
                                    ]?.name,
                                locationName: taskValue.location.name,
                                address: taskValue.location.address,
                                vehicle: taskValue.vehicle?.name,
                                employee: taskValue.employee?.name,
                                routeId: taskValue.routeId || UNASSIGNED_ID,
                                status: TranslationHelper.translate(
                                    CSC_TASK_STATUS[taskValue.status]
                                ),
                                draggable:
                                    taskValue.status ===
                                    ASSIGNABLE_TASK_STATUSES,
                                statusId: taskValue.status,
                                routeName:
                                    routesObj[taskValue.routeId || '']?.name,
                                _meta: {
                                    actions: {
                                        preview: {
                                            params: {
                                                id: taskValue.id,
                                            },
                                        },
                                    },
                                },
                            };
                        });
                    })
                    .flat(),
            }
        );
    }
);

export const getTasksSourceSetWithFilter = getSourceSetWithFilter(
    getTasksSourceSetModel,
    getTasksAsSourceSet
);
export const {
    enterTasksPreviewMode,
    resetTasksPaneMode,
    resetTasks,
    selectTask,
} = tasks.actions;

export default tasks.reducer;
