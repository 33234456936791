import { FieldProps } from 'formik';
import React from 'react';

import TextField from '@material-ui/core/TextField';

interface IOwnProps {
    afterOnChange?: (value: string) => void;
}

type TProps = FieldProps & IOwnProps;

const FieldWrapper: React.FunctionComponent<TProps> = ({
    field,
    form,
    afterOnChange,
    ...props
}) => (
    <TextField
        margin="dense"
        fullWidth={false}
        {...field}
        {...props}
        onChange={
            !afterOnChange
                ? field.onChange
                : (event) => {
                      form.setFieldValue(field.name, event.target.value);
                      afterOnChange(event.target.value);
                  }
        }
    />
);

export default FieldWrapper;
