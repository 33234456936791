export const getFormFields = (readOnly?: boolean) => {
    return {
        name: { disabled: readOnly },
        activityCategoryId: { disabled: readOnly },
        plannedOn: { disabled: readOnly },
        employee: { disabled: readOnly },
        vehicle: { disabled: readOnly },
        status: { disabled: readOnly },
        realStart: { disabled: readOnly },
        realStop: { disabled: readOnly },
        progress: { disabled: readOnly },
        nodePath: { disabled: readOnly },
    };
};
