import moment from 'moment';
import { IMultiIcon } from './types';
import TimeFormatter from '../../../helpers/TimeFormatter';

const generateNames = (params: IMultiIcon[]) =>
    params.map((icon) => icon.label ?? icon.name).join(' ');

export default (args: any) => {
    const paramsData = args.params.data;
    const paramsColId = args.params.column.colId;
    if (args.type === 'multiIcon') {
        const rawValue = paramsData[paramsColId];
        return rawValue ? generateNames(rawValue as IMultiIcon[]) : '';
    } else if (args.type === 'date') {
        const value = args.params.getValue(paramsColId, paramsData);

        return value ? moment(value, 'DD.MM.YYYY') : null;
    } else if (args.type === 'duration') {
        const value = args.params.getValue(paramsColId, paramsData);
        return value ? TimeFormatter.formatDuration(value) : null;
    } else {
        return args.params.getValue(paramsColId, paramsData);
    }
};
