import is from 'predicates';

class PageableCollection {
    constructor(items, metadata) {
        metadata = metadata || {};

        this.start = metadata.start || 0;
        this.total = is.number(metadata.total) ? metadata.total : 0;
        this.items = items || [];
        this.limit = metadata.limit || 50;
    }

    clone(items) {
        return new PageableCollection(items, {
            start: this.start,
            total: this.total,
        });
    }

    append(collection) {
        return new PageableCollection(this.items.concat(collection.items), {
            start: Math.min(this.start, collection.start),
            total: collection.total,
        });
    }

    get hasMore() {
        return this.hasNext;
    }

    get hasNext() {
        return this.start + this.items.length < this.total;
    }

    get hasPrev() {
        return this.start > 0;
    }

    get nextPageStart() {
        return this.start + this.items.length;
    }

    get prevPageStart() {
        return this.start - this.limit;
    }
}

export default PageableCollection;
