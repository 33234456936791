import { withSnackbar, WithSnackbarProps } from 'notistack';
import React, { Component, ComponentType, CSSProperties } from 'react';
import { compose } from 'redux';
import { Connectable, TConnectableProps } from './Connectable.hoc';
import { Themable, TThemableProps } from './Themable.hoc';

import { ISourceSet } from '../../../../state/types';
import GridListItem from './components/ListItem';

import { Divider, LinearProgress, Paper } from '@material-ui/core';
import debounce from 'lodash.debounce';
import { AutoSizer } from 'react-virtualized';
import { ListChildComponentProps, VariableSizeList } from 'react-window';
import PaneHeader from '../../../../components/PaneHeader/PaneHeader';
import TranslationHelper from '../../../../helpers/TranslationHelper';
import { CreateCSSProperties } from '@material-ui/core/styles/withStyles';

export interface IOwnProps {
    sourceSet: ISourceSet | null;
    creatorLevel: number;
}

type TProps = IOwnProps &
    TThemableProps &
    TConnectableProps &
    WithSnackbarProps;

class DataGridPaneMobile extends Component<TProps> {
    public setScrollOffset = debounce((data) => {
        const { sourceSet, setMobileScrollOffset } = this.props;
        if (sourceSet) {
            setMobileScrollOffset(sourceSet?.id, data.scrollOffset);
        }
    }, 500);

    public renderRow = (props: ListChildComponentProps) => {
        const { index, style } = props;
        const { sourceSet, cellInteractionHandler } = this.props;

        const entities = sourceSet?.entities;

        if (sourceSet && entities) {
            return (
                <div
                    key={index}
                    style={
                        {
                            ...(style as CreateCSSProperties<{}>),
                            height: 'auto',
                        } as CSSProperties
                    }
                >
                    <GridListItem
                        attributes={sourceSet.attributes}
                        entity={entities[index]}
                        id={sourceSet.id}
                        cellInteractionHandler={cellInteractionHandler}
                    />
                    <Divider />
                </div>
            );
        }

        return null;
    };

    public getItemSize = (index: number) => {
        const { sourceSet } = this.props;

        return sourceSet?.entities[index]?.location ? 78 : 56;
    };

    public getGridTitle() {
        const { sourceSet } = this.props;

        if (sourceSet) {
            return TranslationHelper.translate(sourceSet.label);
        }

        return TranslationHelper.translate('Loading...');
    }

    public handleClearClick = () => {
        this.props.resetLevel(this.props.creatorLevel);
        this.props.queueMapFitToExtent();
    };

    public render() {
        const { classes, sourceSet, creatorLevel, offset } = this.props;

        return (
            <Paper className={classes.pane}>
                {creatorLevel > 0 && (
                    <div className={classes.paneHeader}>
                        <PaneHeader
                            title={this.getGridTitle()}
                            onCloseClick={this.handleClearClick}
                        />
                    </div>
                )}
                {!sourceSet && <LinearProgress />}
                {sourceSet && !sourceSet.entities.length && (
                    <div className={classes.message}>
                        {TranslationHelper.translate('No data to display')}
                    </div>
                )}
                <AutoSizer>
                    {({ height, width }) => (
                        <VariableSizeList
                            height={height}
                            width={width}
                            estimatedItemSize={78}
                            itemSize={this.getItemSize}
                            itemCount={sourceSet?.entities.length || 0}
                            onScroll={this.setScrollOffset}
                            initialScrollOffset={offset}
                        >
                            {this.renderRow}
                        </VariableSizeList>
                    )}
                </AutoSizer>
            </Paper>
        );
    }
}

export default compose(
    Themable,
    Connectable,
    withSnackbar
)(DataGridPaneMobile) as ComponentType<IOwnProps>;
