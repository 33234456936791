import React, { Component, ComponentType } from 'react';
import { connect } from 'react-redux';
import { TRootState } from '../../../store';

import { IPrivileges } from '../../../state/auth';

interface IOwnProps {
    userPrivileges: IPrivileges;
}
type TProps = IOwnProps;

const hasAccess =
    (hasPermission: (privilegesList: IPrivileges) => boolean) =>
    (WrappedComponent: ComponentType) => {
        class HasAccess extends Component<TProps> {
            public render() {
                if (hasPermission(this.props.userPrivileges)) {
                    return <WrappedComponent {...this.state} {...this.props} />;
                } else {
                    return null;
                }
            }
        }

        return connect((state: TRootState) => ({
            userPrivileges: state.auth.privileges,
        }))(HasAccess) as ComponentType<{}>;
    };

export default hasAccess;
