import createStyles from '@material-ui/core/styles/createStyles';

import { Theme, withStyles, WithStyles } from '@material-ui/core/styles';

const styles = (theme: Theme) => {
    return createStyles({
        gridContainer: {
            flexGrow: 1,
            display: 'flex',
        },
        gridWrapper: {
            flexGrow: 1,
            width: '100%',
        },
    });
};

export type TThemableProps = WithStyles<typeof styles>;

export const Themable = withStyles(styles, { withTheme: true });
