import React, { useEffect, useState } from 'react';
import { Divider, LinearProgress } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '../../../dialogs/DialogTitle';

import { useAppDispatch } from '../../../../hooks';
import useToggle from '../../../../hooks/useToggle';
import { Box } from '@mui/material';
import { hideGlobalDialog } from '../../../../state/ui/dialog';
import TranslationHelper from '../../../../helpers/TranslationHelper';

import SourceSetGrid from '../../../SourceSetGrid/SourceSetGrid';

import {
    fetchRoutes,
    ISelectedRouteForTask,
} from '../../../../state/ui/planning/routes';
import { useStyles } from './Themable.hooks';

import {
    useRoutesForTask,
    useRoutesSourceSetModel,
    useRoutesStatus,
} from '../../../../state/ui/planning/routes/index.hooks';
import useAsyncThunkWithSnackbar from '../../../../hooks/useAsyncThunkWithSnackbar';
import { usePrivileges } from '../../../../state/auth/index.hooks';
import { SelectionChangedEvent } from 'ag-grid-community';
import isEqual from 'lodash.isequal';
import { ITaskGridDialogFilter } from './index';

interface IOwnProps {
    filter: ITaskGridDialogFilter;
    routeId?: number;
    setFieldValue: (field: string, value: any) => void;
}

const TaskGridDialog = (props: IOwnProps) => {
    const classes = useStyles();
    const privileges = usePrivileges();
    const sourceSetModel = useRoutesSourceSetModel();
    const status = useRoutesStatus();
    const routes = useRoutesForTask();
    const handleAsyncRequest = useAsyncThunkWithSnackbar();
    const { isOpen, handleToggle } = useToggle(true);
    const dispatch = useAppDispatch();
    const { filter, setFieldValue, routeId } = props;

    const [selectedRoute, setSelectedRoute] = useState<
        ISelectedRouteForTask | undefined
    >(
        routeId
            ? {
                  id: routeId,
                  sourceSetId: routeId,
                  name: '',
              }
            : undefined
    );

    const CHECKBOX_POSITION = 0;

    useEffect(() => {
        if (filter.activityCategoryId) {
            handleFetchRoutes();
        }
    }, []);

    const handleClose = (event?: object, reason?: string) => {
        if (reason === 'backdropClick') {
            return;
        }
        dispatch(hideGlobalDialog());
        handleToggle();
    };

    const handleSave = () => {
        if (setFieldValue && selectedRoute) {
            setFieldValue('route', {
                id: selectedRoute.id,
                label: selectedRoute.name,
            });
            setFieldValue('employee', {
                id: selectedRoute.employee?.id,
                label: selectedRoute.employee?.name,
            });
            setFieldValue('vehicle', {
                id: selectedRoute.vehicle?.id,
                label: selectedRoute.vehicle?.name,
            });
        }
        dispatch(hideGlobalDialog());
        handleToggle();
    };

    const handleFetchRoutes = () =>
        handleAsyncRequest({
            asyncAction: fetchRoutes({
                from: filter.date,
                to: filter.date,
                activityCategoryId: filter.activityCategoryId,
                employeeId: filter.employeeId,
                vehicleId: filter.vehicleId,
            }),
        });

    const onSelectionChanged = (e: SelectionChangedEvent) => {
        const selectedNode: ISelectedRouteForTask | undefined = e.api
            .getSelectedNodes()
            .map((selected) => {
                return {
                    id: selected.data.id,
                    sourceSetId: selected.data.id,
                    name: selected.data.name,
                    employee: selected.data.employee,
                    vehicle: selected.data.vehicle,
                };
            })
            .find((e) => !!e);
        if (isEqual(selectedNode, selectedRoute)) {
            return;
        }
        setSelectedRoute(selectedNode);
    };

    const singleSelectProps = {
        rowSelection: 'single',
        checkBox: { index: CHECKBOX_POSITION },
        onSelectionChanged,
        items: selectedRoute ? [selectedRoute] : [],
    };

    const dialogContent = () => {
        return (
            <div className={classes.wrapper}>
                {status === 'idle' && (
                    <Box
                        sx={{
                            p: 1,
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        {TranslationHelper.translate(
                            'Select category to load routes'
                        )}
                    </Box>
                )}
                {status === 'loading' ? <LinearProgress /> : <Divider />}

                {routes && (
                    <SourceSetGrid
                        sourceSet={routes}
                        sourceSetModel={sourceSetModel}
                        selectedSourceSetElementId={selectedRoute?.sourceSetId}
                        multiSelectProps={singleSelectProps}
                    />
                )}
            </div>
        );
    };
    return (
        <Dialog
            open={isOpen}
            onClose={handleClose}
            maxWidth={'lg'}
            disableEscapeKeyDown
        >
            <DialogTitle
                title={TranslationHelper.translate('Choose route')}
                save={selectedRoute ? () => handleSave() : undefined}
                close={handleClose}
            />
            <DialogContent
                style={{ minWidth: 1200, minHeight: 500, maxWidth: 1200 }}
            >
                {privileges.routes && dialogContent()}
            </DialogContent>
        </Dialog>
    );
};

export default TaskGridDialog;
