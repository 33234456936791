import React, { ReactChild } from 'react';
import { useStyles } from '../Themable.hooks';
import AppConfig from '../../../constants/AppConfig';

export const LoginPageBackGround = ({ children }: { children: ReactChild }) => {
    const classes = useStyles();
    const chooseBackgroundClass = () => {
        const theme = AppConfig.instance.getConfigKey('th');
        const placeholderBackground = classes.root;

        const backgroundOptions = {
            default: classes.defaultThemeBg,
        };

        return backgroundOptions[theme] || placeholderBackground;
    };

    return <div className={chooseBackgroundClass()}>{children}</div>;
};
