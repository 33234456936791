import React, { FunctionComponent, useState } from 'react';

import DeleteIcon from '@material-ui/icons/Delete';
import TranslationHelper from '../../helpers/TranslationHelper';
import DeletionDialog from '../dialogs/DeletionDialog/DeletionDialog';
import IconButtonWithTooltip from '../IconButtonWithTooltip/IconButtonWithTooltip';

interface IOwnProps {
    title: string;
    tooltip: string;
    message: string;
    deleteMethod: () => void;
    size?: 'medium' | 'small';
    defaultColor?: boolean;
}

type TProps = IOwnProps;

const DeleteButton: FunctionComponent<TProps> = ({
    title,
    message,
    deleteMethod,
    tooltip,
    size = 'medium',
    defaultColor = false,
}) => {
    const [open, setOpen] = useState(false);

    return (
        <>
            <IconButtonWithTooltip
                title={TranslationHelper.translate(tooltip)}
                onClick={() => setOpen(true)}
                size={size}
                defaultColor={defaultColor}
            >
                <DeleteIcon fontSize={size} />
            </IconButtonWithTooltip>

            {open && (
                <DeletionDialog
                    isOpen={open}
                    close={() => setOpen(false)}
                    confirm={() => {
                        deleteMethod();
                        setOpen(false);
                    }}
                    title={title}
                    message={message}
                />
            )}
        </>
    );
};

export default DeleteButton;
