import { default as Divider } from '@material-ui/core/Divider';
import { default as Paper } from '@material-ui/core/Paper';
import React, { Component, ComponentType } from 'react';
import { compose } from 'redux';

import SearchBar from '../SearchBar';
import SearchResults from '../SearchResults';
import { Connectable, TConnectableProps } from './Connectable.hoc';
import { Themable, TThemableProps } from './Themable.hoc';

import EditOverlay from '../EditOverlay/EditOverlay';

interface ISelfProps {
    mode?: 'mobile' | 'floating';
}

type TProps = ISelfProps & TThemableProps & TConnectableProps;

class SearchPane extends Component<TProps> {
    public render() {
        const { classes, areSearchResultsVisible } = this.props;
        return (
            <div className={classes.pane}>
                <Paper>
                    <SearchBar />
                    <Divider />
                    {areSearchResultsVisible && <SearchResults />}

                    <EditOverlay />
                </Paper>
            </div>
        );
    }
}

export default compose(
    Themable,
    Connectable
)(SearchPane) as ComponentType<ISelfProps>;
