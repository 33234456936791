import {
    UrlActionParams,
    extractActionParamsFromUrl,
} from './extractActionParamsFromUrl';

type TQueryAction = 'selectLocation' | 'selectReport';

export function handleQueryAction(
    action: TQueryAction,
    callback: (params: UrlActionParams) => void
): boolean {
    const params = extractActionParamsFromUrl();
    const hasAction = action === params?.action;
    if (hasAction) {
        callback(params);
    }
    return hasAction;
}
