import React from 'react';

import { SnackbarProvider } from 'notistack';
import ServerMessageDisplay from '../../../../../../components/ServerMessageDisplay/ServerMessageDisplay';
import {
    useCreateGroupRequest,
    useUpdateGroupRequest,
} from '../../../../../../pages/management/selectors/index.hooks';

const MessageHandler = () => {
    const createGroupRequest = useCreateGroupRequest();
    const updateGroupRequest = useUpdateGroupRequest();

    const request = createGroupRequest || updateGroupRequest;

    return (
        <SnackbarProvider
            maxSnack={5}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            autoHideDuration={3000}
        >
            <ServerMessageDisplay request={request} />
        </SnackbarProvider>
    );
};

export default MessageHandler;
