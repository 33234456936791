import { ColumnState } from 'ag-grid-community';
import { makeReduxDuck } from 'teedux';
import { TRootState } from '../../../../store';
import { IAttribute, ILayersData } from '../../../types';
import { ISourceSetModel } from '../types';

interface IState {
    sourceSetModels: {
        [sourceSetId: string]: ISourceSetModel;
    };

    lastMapClickPosition: null | { x: number; y: number };
    nearEventsRadius: null | number;
    mapClustering: boolean;
    layersAttributes: IAttribute[];
    customLayers: ILayersData[];
}

export const initialState: IState = {
    sourceSetModels: {},
    lastMapClickPosition: null,
    nearEventsRadius: null,
    mapClustering: true,
    layersAttributes: [],
    customLayers: [],
};

const duck = makeReduxDuck('ui/discovery/general', initialState);

const storeSourceSetModelAction = duck.defineAction<{
    filterModel: object;
    sortModel: ColumnState[];
    sourceSetId: string;
    sourceSetElementIds: Set<string> | null;
    quickFilter: string;
}>(
    'STORE_SOURCE_SET_MODEL',
    (
        { sourceSetModels },
        {
            sourceSetId,
            sourceSetElementIds,
            filterModel,
            sortModel,
            quickFilter,
        }
    ) => ({
        sourceSetModels: {
            ...sourceSetModels,
            [sourceSetId]: {
                sourceSetElementIds,
                filterModel,
                sortModel,
                quickFilter,
            },
        },
    })
);

const storeMapClickPositionAction = duck.defineAction<{
    x: number;
    y: number;
}>('STORE_MAP_CLICK_POSITION', (_, { x, y }) => ({
    lastMapClickPosition: { x, y },
}));

const storeNearEventsRadiusAction = duck.defineAction<{
    radius: number | null;
}>('STORE_NEAR_EVENTS_RADIUS', (_, { radius }) => ({
    nearEventsRadius: radius,
}));

const storeMapClustering = duck.defineAction<{
    state: boolean;
}>('STORE_MAP_CLUSTERING', (_, { state }) => ({
    mapClustering: state,
}));

const resetMapClickPositionAction = duck.definePayloadlessAction(
    'RESET_MAP_CLICK_POSITION',
    () => ({
        lastMapClickPosition: null,
    })
);

const storeLayersAction = duck.defineAction<{
    data: {
        attributes: IAttribute[];
        layersData: ILayersData[];
    };
}>('STORE_LAYERS_ATTRIBUTES', (_, { data }) => ({
    layersAttributes: data.attributes,
    customLayers: data.layersData,
}));

export const storeSourceSetModel = (
    sourceSetId: string,
    data: ISourceSetModel
) =>
    storeSourceSetModelAction({
        sourceSetId,
        ...data,
    });

export const storeLastMapClickPosition = (x: number, y: number) =>
    storeMapClickPositionAction({
        x,
        y,
    });

export const storeNearEventsRadius = (radius: number | null) =>
    storeNearEventsRadiusAction({ radius });

export const setMapClustering = (state: boolean) =>
    storeMapClustering({
        state,
    });

export const resetLastMapClickPosition = () => resetMapClickPositionAction();

export const storeLayers = (data: {
    attributes: IAttribute[];
    layersData: ILayersData[];
}) => storeLayersAction({ data });

export const getLayersAttributes = (state: TRootState) =>
    state.ui.discovery.general.layersAttributes;

export const getCustomLayers = (state: TRootState) =>
    state.ui.discovery.general.customLayers;

export default duck.getReducer();
