import { createStyles, Theme, WithStyles } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme: Theme) => {
    return createStyles({
        label: {
            color: theme.palette.getContrastText(theme.palette.primary.main),
            display: 'inline',
            fontSize: '16px',
            marginLeft: theme.spacing(),
            marginRight: theme.spacing(),
        },
        time: {
            textAlign: 'right',
            '&:first-child': {
                paddingRight: theme.spacing(),
                textAlign: 'left',
            },
        },
        timeValue: {
            fontSize: '16px',
            color: theme.palette.getContrastText(theme.palette.primary.main),
            '& strong': {
                fontWeight: 'bold',
                fontSize: '12px',
                verticalAlign: 'top',
                margin: '0 5px',
            },
        },
        timeRange: {
            display: 'flex',
            alignItems: 'center',
            marginRight: theme.spacing(0),
            backgroundColor: theme.palette.primary.main,
            width: '100%',
        },
        error: {
            boxShadow: `0px 0px 2px 2px ${theme.palette.error.main}`,
        },
        colorPrimary: {
            color: theme.palette.getContrastText(theme.palette.primary.main),
        },
        changeDateIcon: {
            padding: '4px',
        },
        calendarIcon: {
            marginRight: 10,
            color: theme.palette.getContrastText(theme.palette.primary.main),
        },
        small: {
            fontSize: '12px',
            color: theme.palette.getContrastText(theme.palette.primary.main),
        },
    });
};

export type TThemableProps = WithStyles<typeof styles>;

export const Themable = withStyles(styles, { withTheme: true });
