import React, { Component } from 'react';

class TableWrapper extends Component {
    public render() {
        return (
            <div
                className="content"
                style={{
                    overflow: 'scroll',
                    maxHeight: 'calc(70vh - 80px)',
                }}
            >
                {this.props.children}
            </div>
        );
    }
}

export default TableWrapper;
