import React from 'react';

import { connect } from 'react-redux';

import { instanceOf } from 'prop-types';

import classnames from 'classnames';

import MultipleSelect from 'react-select';

import { Connectable } from '../../Connectable.hoc';

import {
    updateRfidCard,
    createRfidCard,
    getRfidCardsFilter,
} from '../../../../state/app/registers/rfidCards';

import { restGetGroups } from '../../../../services/groups';

import RFIDCard from '../../../../models/registers/RFIDCard';

import Translator from '../../../../helpers/TranslationHelper';

import * as RFIDCardTypes from '../../../../constants/dictionaries/RFIDCardTypes';

import PrimaryButton from '../../../button/PrimaryButton';
import SecondaryButton from '../../../button/SecondaryButton';
import ValidationMessage from '../../../common/ValidationMessage';
import CustomAutocomplete from '../../../common/CustomAutocomplete';
import Dialog from '../../../dialog/Dialog';
import AsyncFilterSelect from '../../../AsyncFilterSelect';
import LoadingSpinner from '../../../loadingSpinner/LoadingSpinner';

import { restGetRfidCard } from '../../../../services/registers';

const typeValues = [
    { label: RFIDCardTypes.ADMIN, value: 1, clearableValue: false },
    { label: RFIDCardTypes.MASTER, value: 2, clearableValue: false },
    { label: RFIDCardTypes.USER, value: 3, clearableValue: false },
];

class RFIDCardDialog extends React.Component {
    state = {
        groups: this.props.rfidCard.groups || [],
        type: this.props.rfidCard.type || 0,
        code: this.props.rfidCard.code || '',
        validationMessage: undefined,
        loading: false,
        linkedObjectName: undefined,
        initialized: false,
    };

    componentDidMount() {
        this._isMounted = true;
        const id = this.props.rfidCard.id;
        const linkedObject = this.props.rfidCard.linkedObject;
        if (id !== undefined && linkedObject && linkedObject.objectId) {
            restGetRfidCard(id).then((results) => {
                this.setState({
                    linkedObjectName: results.linkedObject
                        ? results.linkedObject.objectName
                        : '-',
                    initialized: true,
                });
            });
        } else {
            this.setState({
                initialized: true,
                linkedObjectName: '-',
            });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.initialized !== this.state.initialized) {
            this.code.focus();
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }
    _prepareRfidCardData = () => {
        const { groups, type } = this.state;

        return this.props.rfidCard.merge({
            code: this.code ? this.code.value : this.props.rfidCard.code,
            cardNumber: this.cardNumber
                ? this.cardNumber.value
                : this.props.rfidCard.cardNumber,
            description: this.description
                ? this.description.value
                : this.props.rfidCard.description,
            group: undefined,
            groupIds: groups.map((group) => group.id),
            groups: undefined,
            type,
        });
    };
    handleSubmit = (event) => {
        event.preventDefault();

        this.setState({
            loading: true,
        });
        const data = this._prepareRfidCardData();
        const {
            rfidCardsFilter: filter,
            updateRfidCard,
            createRfidCard,
            hideGlobalDialog,
        } = this.props;

        const successCallback = (validation) => {
            if (validation.success) {
                hideGlobalDialog();
            } else if (validation.messages) {
                if (this._isMounted) {
                    this.setState({
                        validationMessage: validation.messages[0],
                        loading: false,
                    });
                }
            }
        };
        const failCallback = () => {
            if (this._isMounted) {
                this.setState({
                    validationMessage: Translator.translate(
                        'Unrecognized error has occurred'
                    ),
                    loading: false,
                });
            }
        };
        const action = data.id ? updateRfidCard : createRfidCard;

        action(
            data,
            filter,
            (validation) => successCallback(validation),
            () => failCallback()
        );
    };

    handleRfidCodeChange = (event) => {
        this.setState({
            code: event.target.value,
        });
    };

    handleGroupChange = (e, groups) => {
        this.setState({ groups });
    };

    handleTypeChange = (option) => {
        let type = option ? option.value : undefined;
        this.setState({ type });
    };

    handleCancel = (event) => {
        event.preventDefault();
        this.props.hideGlobalDialog();
    };

    formatInput = (ref) => {
        ref.value = ref.value.trim();
    };

    render() {
        const { groups, type, validationMessage, loading, linkedObjectName } =
            this.state;
        const rfidCard = this.props.rfidCard;

        const rfidCodeInputIsValid = this.state.code.match(/^[\da-f]+$/i);

        const rfidCodeInputClasses = classnames({
            'mtl-input': true,
            error: !rfidCodeInputIsValid,
        });

        return (
            <Dialog
                title={
                    rfidCard.id === undefined
                        ? Translator.translate('New RFID card')
                        : Translator.translate('Edit RFID card')
                }
                onClose={this.handleCancel}
            >
                <form
                    className="form rfid-card-form"
                    onSubmit={this.handleSubmit}
                    autoComplete="off"
                >
                    {this.state.initialized ? (
                        <div className="section">
                            {linkedObjectName && (
                                <div className="form-group single">
                                    <div className="form-title">
                                        {Translator.translate(
                                            'Linked object name'
                                        )}
                                    </div>
                                    <div className="form-label">
                                        {linkedObjectName}
                                    </div>
                                </div>
                            )}
                            <div className="form-group single">
                                <div className="form-title">
                                    {Translator.translate('Card code')}
                                </div>

                                <div className="form-input">
                                    <input
                                        type="text"
                                        className={rfidCodeInputClasses}
                                        onChange={this.handleRfidCodeChange}
                                        ref={(element) => (this.code = element)}
                                        defaultValue={rfidCard.code}
                                        required
                                        placeholder={
                                            Translator.translate(
                                                'Only hexadecimal'
                                            ) + ' '
                                        }
                                        maxLength="255"
                                        onBlur={() =>
                                            this.formatInput(this.code)
                                        }
                                    />
                                </div>
                            </div>

                            <div className="form-group">
                                <div className="form-title">
                                    {Translator.translate('Card number')}
                                </div>

                                <div className="form-input">
                                    <input
                                        type="text"
                                        className="mtl-input"
                                        ref={(element) =>
                                            (this.cardNumber = element)
                                        }
                                        defaultValue={rfidCard.cardNumber}
                                        maxLength="255"
                                        required
                                        onBlur={() =>
                                            this.formatInput(this.cardNumber)
                                        }
                                    />
                                </div>
                            </div>

                            <div className="form-group single">
                                <div className="form-title">
                                    {Translator.translate('Card group')}
                                </div>

                                <div className="form-input">
                                    <CustomAutocomplete
                                        value={groups}
                                        inputTexts={{
                                            placeholder: 'Select group',
                                        }}
                                        multi
                                        onChange={this.handleGroupChange}
                                        onOpen={restGetGroups}
                                        optionKeys={{
                                            name: 'name',
                                            value: 'id',
                                        }}
                                        required
                                    />
                                </div>
                            </div>

                            <div className="form-group single">
                                <div className="form-title">
                                    {Translator.translate('Card type')}
                                </div>

                                <div className="form-input">
                                    <MultipleSelect
                                        name="type"
                                        clearable={false}
                                        required
                                        value={type}
                                        placeholder={Translator.translate(
                                            'Select card type'
                                        )}
                                        options={typeValues}
                                        onChange={this.handleTypeChange}
                                        noResultsText={Translator.translate(
                                            'No results found'
                                        )}
                                        clearAllText={Translator.translate(
                                            'Clear all'
                                        )}
                                    />
                                </div>
                            </div>

                            <div className="form-group single">
                                <div className="form-title">
                                    {Translator.translate('Description')}
                                </div>

                                <div className="form-input">
                                    {
                                        <input
                                            type="text"
                                            className="mtl-input"
                                            ref={(element) =>
                                                (this.description = element)
                                            }
                                            placeholder={
                                                Translator.translate(
                                                    'E.g. My card description'
                                                ) + ' '
                                            }
                                            defaultValue={rfidCard.description}
                                            maxLength="255"
                                        />
                                    }
                                </div>
                            </div>

                            <div className="form-group">
                                <ValidationMessage
                                    message={Translator.translate(
                                        validationMessage
                                    )}
                                    error={validationMessage !== undefined}
                                />
                            </div>
                        </div>
                    ) : (
                        <div className="section content">
                            <LoadingSpinner
                                size="50"
                                top="0"
                                left="0"
                                right="0"
                                bottom="0"
                            />
                        </div>
                    )}

                    <div className="form-actions">
                        <PrimaryButton
                            icon="icon-floppy-disk"
                            label={Translator.translate('Save')}
                            disabled={loading}
                        />

                        <SecondaryButton
                            onClick={this.handleCancel}
                            icon="icon-cross"
                            label={Translator.translate('Cancel')}
                        />
                    </div>
                </form>
            </Dialog>
        );
    }
}

RFIDCardDialog.propTypes = {
    rfidCard: instanceOf(RFIDCard),
};

export default Connectable(RFIDCardDialog);
