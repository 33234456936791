import React from 'react';

import { Formik, FormikValues } from 'formik';

import { BLANK_FORM_LOCATION } from '../blankForms';
import LocationForm from '../LocationForm/LocationForm';

interface IOwnProps {
    initialValues: FormikValues | null;
}
const LocationPreviewForm = ({ initialValues }: IOwnProps) => {
    const handleValues = () => {};

    return (
        <Formik
            enableReinitialize={true}
            initialValues={initialValues || BLANK_FORM_LOCATION}
            onSubmit={handleValues}
            validateOnBlur={false}
            validateOnChange={false}
            validate={() => ({})}
        >
            {({ errors, values, setFieldValue }) => (
                <LocationForm
                    values={values}
                    errors={errors}
                    readOnly
                    setFieldValue={setFieldValue}
                />
            )}
        </Formik>
    );
};

export default LocationPreviewForm;
