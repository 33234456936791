import React, { Component } from 'react';

import Translator from '../../../helpers/TranslationHelper';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

interface IState {
    open: boolean;
}
class NoInternetDialog extends Component {
    public state: IState = {
        open: false,
    };

    public componentDidMount() {
        window.addEventListener('offline', this.handleClickOpen);
        window.addEventListener('online', this.handleClose);
    }

    public componentWillUnmount() {
        window.removeEventListener('offline', this.handleClickOpen);
        window.removeEventListener('online', this.handleClose);
    }

    public handleClickOpen = () => {
        this.setState({ open: true });
    };

    public handleClose = (event?: object, reason?: string) => {
        if (reason === 'backdropClick') {
            return;
        }
        setTimeout(() => this.setState({ open: false }), 5000);
    };

    public render() {
        return (
            <Dialog
                open={this.state.open}
                onClose={this.handleClose}
                disableEscapeKeyDown={true}
            >
                <DialogTitle>{Translator.translate('Error')}</DialogTitle>
                <DialogContent style={{ minWidth: 300 }}>
                    <DialogContentText>
                        {Translator.translate(
                            'No internet - check Your connection'
                        )}
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        );
    }
}

export default NoInternetDialog;
