import {
    FormControl,
    FormControlLabel,
    Radio,
    RadioGroup,
} from '@material-ui/core';
import React from 'react';
import TranslationHelper from '../../helpers/TranslationHelper';

interface IOwnProps {
    options: string[];
    value: string;
    handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const RadioButtons = ({ options, value, handleChange }: IOwnProps) => {
    return (
        <FormControl component="fieldset">
            <RadioGroup
                row
                aria-label="radioButtons"
                name="radioButtons"
                value={value}
                onChange={handleChange}
            >
                {options.map((option, i) => (
                    <FormControlLabel
                        value={option}
                        control={<Radio color={'primary'} />}
                        label={TranslationHelper.translate(option)}
                        key={i}
                    />
                ))}
            </RadioGroup>
        </FormControl>
    );
};

export default RadioButtons;
