import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { TStatus } from '../../../state/types';

import { TRootState } from '../../../store';

import {
    restPatchConfirmTrustedDevice,
    restPostRegisterUserTrustedDevice,
    restPostResendTrustedDeviceRegistrationEmails,
} from '../../../services/auth';
import { IDeviceDetails } from '../../../services/trustedDevice';

interface IState {
    status: TStatus;
    error: { status: number; errorMsg: string } | null;
}

export const initialState: IState = {
    status: 'idle',
    error: null,
};

export const registerTrustedDevice = createAsyncThunk(
    'get:ui/trustedDevices',
    async (details: IDeviceDetails, { rejectWithValue }) => {
        try {
            const response = await restPostRegisterUserTrustedDevice(details);

            return response;
        } catch (e: any) {
            throw rejectWithValue({
                status: e.status,
                errorMsg: e.responseJSON?.message,
            });
        }
    }
);

export const resendTrustedDeviceRegistrationEmails = createAsyncThunk(
    'get:ui/trustedDevices/resend',
    async (_, { rejectWithValue }) => {
        try {
            const response =
                await restPostResendTrustedDeviceRegistrationEmails();

            return response;
        } catch (e: any) {
            throw rejectWithValue({
                status: e.status,
                errorMsg: e.responseJSON?.message,
            });
        }
    }
);

export const confirmTrustedDevice = createAsyncThunk(
    'get:ui/trustedDevices/confirmation/:id',
    async (id: number, { rejectWithValue }) => {
        try {
            const response = await restPatchConfirmTrustedDevice(id);

            return response;
        } catch (e: any) {
            throw rejectWithValue({
                status: e.status,
                errorMsg: e.responseJSON?.message,
            });
        }
    }
);
const trustedDevices = createSlice({
    name: 'trustedDevices',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(registerTrustedDevice.pending, (state) => {
            state.status = 'loading';
        });
        builder.addCase(registerTrustedDevice.rejected, (state) => {
            state.status = 'error';
        });
        builder.addCase(registerTrustedDevice.fulfilled, (state) => {
            state.status = 'done';
        });
        builder.addCase(
            resendTrustedDeviceRegistrationEmails.pending,
            (state) => {
                state.status = 'loading';
            }
        );
        builder.addCase(
            resendTrustedDeviceRegistrationEmails.rejected,
            (state) => {
                state.status = 'error';
            }
        );
        builder.addCase(
            resendTrustedDeviceRegistrationEmails.fulfilled,
            (state) => {
                state.status = 'done';
            }
        );
        builder.addCase(confirmTrustedDevice.pending, (state) => {
            state.status = 'loading';
        });
        builder.addCase(confirmTrustedDevice.rejected, (state) => {
            state.status = 'error';
        });
        builder.addCase(confirmTrustedDevice.fulfilled, (state) => {
            state.status = 'done';
        });
    },
});

const getCurrent = (state: TRootState) => state.ui.trustedDevices as IState;

export const getDeviceRegisterStatus = (state: TRootState) =>
    getCurrent(state).status;

export default trustedDevices.reducer;
