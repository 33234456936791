import React, { useState } from 'react';

import { v4 as uuidv4 } from 'uuid';

import { Divider, LinearProgress, List } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { default as EditIcon } from '@material-ui/icons/Edit';

import { useStyles } from './Themable.hooks';

import {
    enterEditMode,
    resetMode,
} from '../../../../../../../../state/ui/devices';
import { usePrivileges } from '../../../../../../../../state/auth/index.hooks';
import {
    useDevicesLoading,
    useSelectedDevice,
} from '../../../../../../../../state/ui/devices/index.hooks';

import IconButtonWithTooltip from '../../../../../../../../components/IconButtonWithTooltip';
import PaneHeader from '../../../../../../../../components/PaneHeader/PaneHeader';
import TranslationHelper from '../../../../../../../../helpers/TranslationHelper';
import { useToolkitDispatch } from '../../../../../../../../hooks';

import DeviceListItem from './components';

const DEVICE_DETAILS_LIST_ITEMS = [
    { id: 'serialNumber', label: 'Serial number' },
    { id: 'name', label: 'Object name' },
];

const ETOLL_LIST_ITEMS = [
    {
        id: 'eToll',
        label: 'Sending data to SPOE KAS system (eToll)',
    },
    { id: 'status', label: 'Status' },
    { id: 'registeredAt', label: 'Date of registration' },
    {
        id: 'businessNumber',
        label: 'Business number',
        copy: true,
    },
    { id: 'pin', label: 'PIN', copy: true },
];

const DevicesPreview = () => {
    const [copyTooltip, setCopyTooltip] = useState({});

    const toolkitDispatch = useToolkitDispatch();

    const privileges = usePrivileges();
    const loading = useDevicesLoading();
    const device = useSelectedDevice();
    const classes = useStyles();

    const handleEnterEditMode = () => toolkitDispatch(enterEditMode());

    const handleCloseClick = () => {
        toolkitDispatch(resetMode());
    };

    const getRightCustomControls = () => (
        <IconButtonWithTooltip
            title={TranslationHelper.translate('Edit device')}
            onClick={handleEnterEditMode}
        >
            <EditIcon />
        </IconButtonWithTooltip>
    );

    const renderSection = (title: string, sectionItems: Array<any>) => {
        return (
            <>
                <div className={classes.sectionTitle} title={title}>
                    <Typography color={'primary'}>
                        {TranslationHelper.translate(title)}
                    </Typography>
                </div>
                <List component="div" disablePadding={true} dense={true}>
                    <Divider />
                    {sectionItems.map((item, i) => (
                        <div key={uuidv4()}>
                            <DeviceListItem
                                copyTooltip={copyTooltip}
                                setCopyTooltip={setCopyTooltip}
                                item={item}
                            />
                        </div>
                    ))}
                </List>
            </>
        );
    };
    return (
        <div>
            <PaneHeader
                onCloseClick={handleCloseClick}
                title={`${TranslationHelper.translate('Device details')}: ${
                    device?.name || device?.serialNumber || ''
                }`}
                renderRightCustomControls={
                    privileges.eToll || privileges.eTollEditRegistrationData
                        ? () => getRightCustomControls()
                        : undefined
                }
            />
            {device && !loading ? (
                <div>
                    {renderSection('Device details', DEVICE_DETAILS_LIST_ITEMS)}
                    {(privileges.eToll ||
                        privileges.eTollReadOnly ||
                        privileges.eTollEditRegistrationData) &&
                        renderSection('eToll', ETOLL_LIST_ITEMS)}
                </div>
            ) : (
                <LinearProgress />
            )}
        </div>
    );
};

export default DevicesPreview;
