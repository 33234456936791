import React, { ReactChild } from 'react';

import TranslationHelper from '../../../../../../helpers/TranslationHelper';

import { Tooltip, Typography } from '@material-ui/core';
import { InfoOutlined as InfoIcon } from '@material-ui/icons';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';

interface IOwnProps {
    children: ReactChild;
    classes: ClassNameMap;
    title: string;
    tooltipTitle?: string;
}

const PaneSectionWithTitle = ({
    children,
    classes,
    title,
    tooltipTitle,
}: IOwnProps) => {
    return (
        <>
            <div className={classes.formWrapper}>
                <div className={classes.flexCentered}>
                    <Typography
                        color={'primary'}
                        className={classes.typography}
                    >
                        {TranslationHelper.translate(title)}
                    </Typography>
                    {tooltipTitle && (
                        <Tooltip
                            title={TranslationHelper.translate(tooltipTitle)}
                        >
                            <div className={classes.icon} data-testid="icon">
                                <InfoIcon fontSize="small" />
                            </div>
                        </Tooltip>
                    )}
                </div>
            </div>
            {children}
        </>
    );
};

export default PaneSectionWithTitle;
