import React, { useEffect, useRef, useState } from 'react';
import { Prompt } from 'react-router-dom';
import { compose } from 'redux';
import { Connectable, TConnectableProps } from './Connectable.hoc';
import { useStyles } from './Themable.hooks';

import { LinearProgress } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';

import PrivilegesListFormFields from './components/PrivilegesListFormFields';
import IconButtonWithTooltip from '../../../../components/IconButtonWithTooltip';
import PaneHeader from '../../../../components/PaneHeader';
import TranslationHelper from '../../../../helpers/TranslationHelper';
import { IRole } from '../../../../state/app/adminPanel';
import { IRequestWithMessages } from '../../../../state/app/sync';
import { IPrivilegesGroup } from '../../selectors';

type TProps = TConnectableProps;

export interface IFormRole {
    id: number;
    name: string;
    description: string;
    privileges: {
        [key: string]: boolean;
    };
    groupSelect: {
        [key: string]: boolean;
    };
    selectAll: boolean;
}

const initialForm = {
    id: -1,
    name: '',
    description: '',
    privileges: {},
    groupSelect: {},
    selectAll: false,
};

const PrivilegesList = ({
    privilegesListGrouped,
    mode,
    roleData,
    selectedRoleId,
    creationRequest,
    updateRequest,
    userCanEdit,
    deselectRole,
    resetMode,
    createRole,
    updateRole,
    setRole,
}: TProps) => {
    const classes = useStyles();
    const [formData, setFormData] = useState<IFormRole>(initialForm);
    const [readonly, setReadonly] = useState(false);
    const [isDirty, setDirty] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [loading, setLoading] = useState(false);

    const nameRef = useRef<HTMLInputElement>();
    const descriptionRef = useRef<HTMLInputElement>();

    const setFormPrivileges = (priviliges: number[]) => {
        return priviliges.reduce(
            (obj, key) => Object.assign(obj, { [key]: true }),
            {}
        );
    };

    const setFormGroupSelect = (
        privilegesGrouped: IPrivilegesGroup[],
        data: IRole
    ) =>
        privilegesGrouped.reduce(
            (obj, group) =>
                Object.assign(obj, {
                    [group.groupName]: group.privilegesIds.every((id) =>
                        data.privileges ? data.privileges.includes(id) : false
                    ),
                }),
            {}
        );

    const getRightCustomControls = (isSubmitting: boolean = false) => (
        <IconButtonWithTooltip
            title={TranslationHelper.translate('Save')}
            children={<SaveIcon />}
            disabled={isSubmitting}
            buttonProps={{ type: 'submit' }}
        />
    );

    const isRequestActive = (req: IRequestWithMessages) =>
        req && req.success === null && req.error === null;

    const handleValues = (event: React.SyntheticEvent) => {
        event.preventDefault();
        setSubmitting(true);

        const { id, privileges } = formData;
        const name = nameRef.current?.value || '';
        const description = descriptionRef.current?.value || '';

        if (selectedRoleId) {
            const role = {
                id,
                name,
                description,
                privileges: Object.keys(privileges)
                    .filter((key) => privileges[key])
                    .map((key) => parseInt(key, 10)),
            };

            updateRole(role, () => {
                setSubmitting(false);

                setRole({
                    role: {
                        ...role,
                        readonly,
                    },
                });

                setFormData(formData);
                setDirty(false);
            });
        } else {
            createRole(
                {
                    name,
                    description,
                    privileges: Object.keys(privileges)
                        .filter((key) => privileges[key])
                        .map((key) => parseInt(key, 10)),
                },
                () => setSubmitting(false)
            );
        }
    };

    const handleDirty = (dirty: boolean) => {
        if (dirty && !isDirty) {
            setDirty(dirty);
        }
    };

    useEffect(() => {
        if (roleData) {
            setFormData({
                id: roleData.id,
                name: roleData.name,
                description: roleData.readonly
                    ? TranslationHelper.translate(roleData.description)
                    : roleData.description || '',
                privileges: roleData.privileges
                    ? setFormPrivileges(roleData.privileges)
                    : {},
                groupSelect: setFormGroupSelect(
                    privilegesListGrouped,
                    roleData
                ),
                selectAll: false,
            });
            setReadonly(roleData.readonly);
            setLoading(false);
        } else {
            setFormData(initialForm);
            setReadonly(false);
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        setLoading(true);
        setFormData({
            id: roleData.id || -1,
            name: roleData.name || '',
            description: roleData.readonly
                ? TranslationHelper.translate(roleData.description)
                : roleData.description || '',
            privileges: roleData.privileges
                ? setFormPrivileges(roleData.privileges)
                : {},
            groupSelect: setFormGroupSelect(privilegesListGrouped, roleData),
            selectAll: false,
        });
        setReadonly(roleData.readonly);
        setTimeout(() => setLoading(false));
    }, [roleData]);

    const disableField = mode === 'create' ? false : readonly || !userCanEdit;
    const request = creationRequest || updateRequest;

    return (
        <form className={classes.editForm} onSubmit={handleValues}>
            <Prompt
                when={!!isDirty}
                message={TranslationHelper.translate(
                    'Unsaved data will be lost. Do you want to continue?'
                )}
            />
            {isDirty && <div className={classes.overlay} />}

            <PaneHeader
                title={TranslationHelper.translate(
                    mode === 'create' ? 'Creating role' : 'Editing role'
                )}
                onCloseClick={() => {
                    resetMode();
                    deselectRole();
                }}
                renderRightCustomControls={() =>
                    getRightCustomControls(submitting)
                }
            />

            {isRequestActive(request as IRequestWithMessages) || loading ? (
                <LinearProgress />
            ) : (
                <PrivilegesListFormFields
                    formData={formData}
                    privilegesListGrouped={privilegesListGrouped}
                    setFormData={setFormData}
                    handleDirty={handleDirty}
                    disableField={disableField}
                    nameRef={nameRef}
                    descriptionRef={descriptionRef}
                />
            )}
        </form>
    );
};

export default compose(Connectable)(PrivilegesList);
