import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formWrapper: {
            padding: theme.spacing(2),
        },
        buttonsWrapper: {
            display: 'flex',
            justifyContent: 'space-between',
        },
    })
);
