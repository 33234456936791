import React from 'react';

import { Divider, Paper } from '@material-ui/core';

import EventChartPane from './components/EventChartPane';
import {
    useDiscoveryChartPane,
    useGridCreatorLevel,
} from '../../selectors/index.hooks';
import { resetLevel } from '../../../../state/_actions';

import TachographChart from '../TachographChart/TachograppChart';

import TranslationHelper from '../../../../helpers/TranslationHelper';
import { useAppDispatch } from '../../../../hooks';
import TimeFormatter from '../../../../helpers/TimeFormatter';

import PaneHeader from '../../../../components/PaneHeader';
import { useStyles } from './Themable.hooks';

const ChartPane = () => {
    const classes = useStyles();
    const chartPane = useDiscoveryChartPane();
    const creatorLevel = useGridCreatorLevel();

    const dispatch = useAppDispatch();

    const tachoDate = chartPane?.sourceSetAction.params.from;

    const tachoChart =
        chartPane?.sourceSetAction.componentName === 'driverTachoChart';
    const chartComponent = tachoChart ? (
        <TachographChart />
    ) : (
        <EventChartPane />
    );

    const handleClearClick = () => {
        dispatch(resetLevel(creatorLevel));
    };

    const tachoLabel = `${TranslationHelper.translate(
        'Driver activity tachograph'
    )} ${TimeFormatter.dateToShortDateString((tachoDate as string) || '')}`;

    return (
        <Paper className={classes.pane}>
            <div className={classes.wrapper}>
                <PaneHeader
                    title={
                        tachoChart
                            ? tachoLabel
                            : TranslationHelper.translate('Chart')
                    }
                    onCloseClick={handleClearClick}
                />
                <Divider />
                {chartComponent}
            </div>
        </Paper>
    );
};

export default ChartPane;
