import { createStyles, WithStyles } from '@material-ui/core';
import { Theme, withStyles } from '@material-ui/core/styles';

const styles = (theme: Theme) => {
    return createStyles({
        pane: {
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: '56px',
            left: 0,
            [theme.breakpoints.down('md')]: {
                borderRadius: 0,
            },
        },
        wrapper: {
            display: 'flex',
            position: 'relative',
            flexGrow: 1,
            flexDirection: 'column',
        },
        paneHeader: {
            position: 'absolute',
            inset: '0 0 0 0',
            zIndex: 1100,
            height: '56px',
            [theme.breakpoints.down('md')]: {
                top: -56,
            },
            [`${theme.breakpoints.up('xs')} and (orientation: landscape)`]: {
                top: -48,
            },
            [theme.breakpoints.up('sm')]: {
                top: -64,
            },
        },
        message: {
            padding: theme.spacing() * 2,
        },
    });
};

export type TThemableProps = WithStyles<typeof styles>;

export const Themable = withStyles(styles, { withTheme: true });
