export const cellRenderers = {
    tooltipList: 'groupsCellRenderer',
    actions: 'actionsCellRenderer',
    bar: 'agSparklineCellRenderer',
    iconAttachment: 'attachmentIconCellRenderer',
    multiIcon: 'multiIconCellRenderer',
    alertIcon: 'alertCellRenderer',
    icon: 'iconCellRenderer',
    statusIcon: 'statusIconCellRenderer',
};
