import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        searchButton: {
            margin: '6px',
        },
        typography: {
            margin: `${theme.spacing()}px 0px`,
        },
        formWrapper: {
            padding: theme.spacing(2),
        },
        slider: {
            width: '90%',
        },
    })
);
