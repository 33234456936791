import apiClient from '../../../../../../../../../services/common/apiClient';
import { IToggleButtonMeta } from '../../../../../../../../../state/types';

export const sendData = async (data: IToggleButtonMeta) => {
    const requestSettings = {
        url: data.api,
        withCredentials: true,
        method: 'POST',
        data: JSON.stringify(data.body),
        contentType: 'application/json',
        xhrFields: {
            withCredentials: true,
        },
        headers: {
            'Content-Type': 'application/json',
        },
    };

    return apiClient.sendWithoutErrorsHandling(requestSettings);
};
