import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => {
    return createStyles({
        contrastText: {
            color: theme.palette.getContrastText(theme.palette.primary.main),
        },
        options: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
        },
        optionItem: {
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
        },
    });
});
