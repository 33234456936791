import React, { useState } from 'react';

import { useDispatch } from 'react-redux';

import TextField from '@material-ui/core/TextField/TextField';
import { default as EditIcon } from '@material-ui/icons/Edit';
import CategoryOutlinedIcon from '@material-ui/icons/CategoryOutlined';

import { useStyles } from './Themable.hooks';
import { useGroupData } from './Connectable.hooks';
import IconButtonWithTooltip from '../../../../../../../../components/IconButtonWithTooltip';
import PaneHeader from '../../../../../../../../components/PaneHeader/PaneHeader';
import TranslationHelper from '../../../../../../../../helpers/TranslationHelper';
import {
    enterEditGroupMode,
    resetMode,
} from '../../../../../../../../state/ui/groupsSlice';
import AssignToGroupDialog from '../AssignToGroupDialog';
import { Tooltip } from '@material-ui/core';

const GroupPreview = () => {
    const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

    const classes = useStyles();
    const groupData = useGroupData();

    const dispatch = useDispatch();

    const handleClose = () => {
        dispatch(resetMode());
    };

    const handleEditForm = () => {
        dispatch(enterEditGroupMode());
    };

    const handleOpenDialog = () => {
        setIsDialogOpen(true);
    };

    const getRightCustomControls = () => (
        <>
            <IconButtonWithTooltip
                title={TranslationHelper.translate('Assigning to group')}
                onClick={handleOpenDialog}
                children={<CategoryOutlinedIcon />}
            />
            <IconButtonWithTooltip
                title={TranslationHelper.translate('Edit group')}
                onClick={handleEditForm}
                children={<EditIcon />}
            />
        </>
    );

    const getGroupNumber = (number?: number) => {
        return number !== undefined ? number : '';
    };

    const handleCloseDialog = () => {
        setIsDialogOpen(false);
    };

    return (
        <div>
            <PaneHeader
                onCloseClick={handleClose}
                title={TranslationHelper.translate('Group details')}
                renderRightCustomControls={() => getRightCustomControls()}
            />

            <div className={classes.formWrapper}>
                <Tooltip
                    title={groupData?.name || ''}
                    placement="top"
                    enterDelay={500}
                    leaveDelay={500}
                >
                    <TextField
                        label={TranslationHelper.translate('Name')}
                        value={groupData?.name || ''}
                        disabled={true}
                        fullWidth={true}
                        inputProps={{ className: classes.inputWrapper }}
                    />
                </Tooltip>
                <TextField
                    label={TranslationHelper.translate('Number')}
                    value={getGroupNumber(groupData?.number)}
                    disabled={true}
                    fullWidth={true}
                />
            </div>
            {groupData && isDialogOpen && (
                <AssignToGroupDialog
                    isOpen={isDialogOpen}
                    closeDialog={handleCloseDialog}
                    groupData={groupData}
                />
            )}
        </div>
    );
};

export default GroupPreview;
