import { createSelector } from 'reselect';

import { useSelector } from 'react-redux';

import TranslationHelper from '../../../../helpers/TranslationHelper';
import { TRootState } from '../../../../store';

const lowerCaseObject = (object: object): object => {
    return (
        Object.keys(object).reduce((lowerCased, key) => {
            lowerCased[key.toLowerCase()] = object[key];
            return lowerCased;
        }, {}) || {}
    );
};

const getModules = (state: TRootState) => state.app.iframes.modules;
const getLogin = (state: TRootState) => state.login;

export const getLinks = createSelector(
    [getModules, getLogin],
    (iframeConfig, loginStore) => {
        const external = [];
        const internal = [];
        if (iframeConfig !== null && iframeConfig.modules && loginStore.user) {
            const login = loginStore.user.toLowerCase();
            const allAllowed =
                iframeConfig.users === undefined &&
                iframeConfig.enabled === undefined &&
                iframeConfig.disabled === undefined;
            const enabledModules =
                (iframeConfig.enabled && iframeConfig.enabled.split(',')) || [];

            const lowerCasedUsers = lowerCaseObject(iframeConfig.users || {});
            const allowedModules =
                (lowerCasedUsers[login] && lowerCasedUsers[login].split(',')) ||
                [];
            const lowerCasedDisabled = lowerCaseObject(
                iframeConfig.disabled || {}
            );
            const disabledModules =
                (lowerCasedDisabled[login] &&
                    lowerCasedDisabled[login].split(',')) ||
                [];
            const modulesSet = Array.from(
                new Set([...enabledModules, ...allowedModules])
            );
            const finalModules = modulesSet.filter(
                (module) => !disabledModules.includes(module)
            );

            for (const name of Object.keys(iframeConfig.modules)) {
                const module = iframeConfig.modules[name];
                const link = {
                    name:
                        (module.label &&
                            TranslationHelper.translate(module.label)) ||
                        (module.names && module.names[loginStore.language]) ||
                        name,
                    slug: '/iframe/' + name,
                    icon: module.icon || name,
                };
                if (allAllowed || finalModules.includes(name)) {
                    module.external ? external.push(link) : internal.push(link);
                }
            }
        }
        return { external, internal };
    }
);

export const useLinks = () => useSelector(getLinks);
