import { default as LinearProgress } from '@material-ui/core/LinearProgress';
import { default as List } from '@material-ui/core/List';
import React, { Component, ComponentType } from 'react';
import { compose } from 'redux';

import SearchResultItem from '../SearchResultItem';
import { Connectable, TConnectableProps } from './Connectable.hoc';
import { Themable, TThemableProps } from './Themable.hoc';

import TranslationHelper from '../../../../helpers/TranslationHelper';

interface IOwnProps {
    isMobile?: boolean;
}

type TProps = TConnectableProps & TThemableProps & IOwnProps;

class SearchResults extends Component<TProps> {
    public render() {
        const {
            classes,
            foundMonitoredObjectIds,
            areSearchResultsBeingFetched,
            isMobile,
        } = this.props;

        return (
            <React.Fragment>
                {areSearchResultsBeingFetched && (
                    <LinearProgress
                        variant="query"
                        className={classes.linearProgress}
                    />
                )}
                {foundMonitoredObjectIds && (
                    <div
                        className={isMobile ? classes.mobileRoot : classes.root}
                    >
                        {foundMonitoredObjectIds.length === 0 && (
                            <p className={classes.noItemsFound}>
                                {TranslationHelper.translate('No items found')}
                            </p>
                        )}
                        {foundMonitoredObjectIds.length > 0 && (
                            <List dense={true} className={classes.list}>
                                {foundMonitoredObjectIds.map((id) => (
                                    <SearchResultItem
                                        key={id}
                                        monitoredObjectId={id}
                                        isMobile={isMobile}
                                    />
                                ))}
                            </List>
                        )}
                    </div>
                )}
            </React.Fragment>
        );
    }
}

export default compose(
    Themable,
    Connectable
)(SearchResults) as ComponentType<IOwnProps>;
