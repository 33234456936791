import { makeReduxDuck } from 'teedux';
import {
    restDeleteTask,
    restNotAssignedTasksPresent,
    restPostTask,
    restPutTask,
} from '../../../services/tasks';
import { makeRequest } from '../sync';
import { TRootState } from 'src/app/store';

const tasksStorePath = `app/discovery/tasks`;

interface IState {
    notAssignedPresent: boolean;
}

const initialState: IState = {
    notAssignedPresent: false,
};

const duck = makeReduxDuck(tasksStorePath, initialState);

export const setNotAssignedPresent = duck.defineAction<{
    data: boolean;
}>('SET_NOT_ASSIGNED_TASKS_PRESENT', (state, { data }) => ({
    ...state,
    notAssignedPresent: data,
}));

export default duck.getReducer();

const areNotAssignedTasksStorePath = `get:${tasksStorePath}/notAssignedPresent`;

export const areNotAssignedTasksPresent = () =>
    makeRequest(
        areNotAssignedTasksStorePath,
        () => restNotAssignedTasksPresent(),
        (dispatch, data) => dispatch(setNotAssignedPresent({ data })),
        (dispatch, error) => undefined
    );

export const createTask = (
    data: FormData,
    afterRequest?: () => void,
    afterFailure?: () => any
) =>
    makeRequest(
        `post:${tasksStorePath}`,
        () => restPostTask(data),
        (dispatch) => {
            // TODO: maybe will be used - dispatch(resetMode())
            if (afterRequest) {
                afterRequest();
            }
        },
        (dispatch, error) => {
            if (error.status === 409 && afterFailure) {
                dispatch(afterFailure());
            }
        }
    );

export const updateTask = (
    data: FormData,
    afterRequest?: () => void,
    afterFailure?: () => any
) =>
    makeRequest(
        `put:${tasksStorePath}/${data.get('id')}`,
        () => restPutTask(data),
        (dispatch) => {
            if (afterRequest) {
                afterRequest();
            }
        },
        (dispatch, error) => {
            if (error.status === 409 && afterFailure) {
                dispatch(afterFailure());
            }
        }
    );

export const deleteTask = (taskId: number, afterRequest?: () => void) =>
    makeRequest(
        `delete:${tasksStorePath}/${taskId}`,
        () => restDeleteTask(taskId),
        (dispatch, data) => {
            if (afterRequest) {
                afterRequest();
            }
        },
        (dispatch, error) => {
            if (afterRequest) {
                afterRequest();
            }
        }
    );

export const hasNotAssigendTasks = (state: TRootState) =>
    state.app.tasks.notAssignedPresent;
