import { Dispatch } from 'redux';
import { fetchTrail } from '../../../../services/discovery/fetchTrail';
import { makeFetchModule } from '../../../_utils';
import { resetTrails, storeTrails } from '../../entities';

const callbacks = {
    onFetchBegin: () => resetTrails(),
    onSuccess: (dispatch: Dispatch, { entities }: { entities: object }) => {
        dispatch(storeTrails(entities));
    },
    onReset: (dispatch: Dispatch) => {
        dispatch(resetTrails());
    },
};
const { reducer, fetchData, reset } = makeFetchModule(
    'app/discovery/trailFetching',
    fetchTrail,
    callbacks
);

export default reducer;
export { fetchData, reset };
