import { createSelector } from 'reselect';
import { ISourceSet } from '../../../../state/types';
import { ISourceSetModel } from '../../../../state/ui/discovery/types';
const clonedeep = require('lodash.clonedeep');

export const getSourceSetWithFilter = (
    getSourceSetModel: (state: any) => ISourceSetModel | null,
    getSourceSet: (state: any) => ISourceSet | null
) =>
    createSelector([getSourceSetModel, getSourceSet], (model, sourceSet) => {
        let mapSourceSet = clonedeep(sourceSet);

        if (
            sourceSet &&
            model &&
            model.sourceSetElementIds !== null &&
            sourceSet.entities.length !== model.sourceSetElementIds.size
        ) {
            mapSourceSet = {
                ...sourceSet,
                entities: sourceSet.entities.filter(
                    (entity: { id: string }) =>
                        model.sourceSetElementIds &&
                        model.sourceSetElementIds.has(entity.id)
                ),
            };
        }
        return mapSourceSet;
    });
