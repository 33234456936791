import React, { useEffect } from 'react';

import { useSnackbar } from 'notistack';

import useToggle from '../../../../hooks/useToggle';
import { useAppDispatch, useToolkitDispatch } from '../../../../hooks';
import useAsyncThunkWithSnackbar from '../../../../hooks/useAsyncThunkWithSnackbar';

import { errorMessageHandler } from '../../../../helpers/errorMessageHandler';
import { createInitialConnectedObjectsState } from './helpers/createInitialConnectedObjectsState';

import { hideGlobalDialog } from '../../../../state/ui/dialog';
import {
    createAttribute,
    fetchAttribute,
    resetAttributeState,
    updateAttribute,
} from '../../../../state/ui/attributes';
import { useAttribute } from '../../../../state/ui/attributes/index.hooks';

import { SYSTEM_OBJECTS } from '../../../../constants/dictionaries/SystemObjects';
import { AttributesForm, IAttributesForm } from './components/AttributesForm';
import { getDefaultValue, getDictValue } from './helpers/getFieldValues';
import TranslationHelper from '../../../../helpers/TranslationHelper';

interface IOwnProps {
    attributeId?: string;
}

export const BLANK_OBJECTS = [
    ...Object.entries(SYSTEM_OBJECTS).map(([id, name]) => ({
        type: Number(id),
        id,
        name,
        defaultValue: '',
        multipleValue: false,
        required: false,
        selected: false,
        internal: false,
    })),
];

const BLANK_FORM = {
    name: '',
    label: '',
    regex: '',
    type: 1,
    lockedToDictionary: false,
    connectedTo: BLANK_OBJECTS,
    dictionary: [],
    dictionaryField: '',
    internal: false,
    dictionaryItemRemoved: false,
};

const AttributesDialog = ({ attributeId }: IOwnProps) => {
    const dispatch = useAppDispatch();
    const toolkitDispatch = useToolkitDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const attribute = useAttribute();
    const { isOpen, handleToggle } = useToggle(true);

    const handleAsyncRequest = useAsyncThunkWithSnackbar();

    useEffect(() => {
        if (attributeId) {
            toolkitDispatch(fetchAttribute(attributeId))
                .unwrap()
                .catch((error) => {
                    const message = errorMessageHandler(error.status)();
                    enqueueSnackbar(message, {
                        variant: 'error',
                    });
                    handleClose();
                });
        }
    }, [attributeId, toolkitDispatch]);

    const title = attributeId ? 'Edit attribute' : 'Create attribute';

    const initialValues =
        attributeId && attribute
            ? {
                  name: attribute.definition.name,
                  label: attribute.definition.label,
                  regex: attribute.definition.regex || '',
                  type: attribute.definition.type,
                  internal: attribute.definition.internal,
                  lockedToDictionary:
                      attribute.definition.lockedToDictionary || false,
                  connectedTo: createInitialConnectedObjectsState(
                      BLANK_OBJECTS,
                      attribute.connectedTo
                  ),
                  dictionary: attribute.dictionary || [],
                  dictionaryField: '',
                  dictionaryItemRemoved: false,
              }
            : BLANK_FORM;

    const handleClose = (event?: object, reason?: string) => {
        if (reason === 'backdropClick') {
            return;
        }
        dispatch(hideGlobalDialog());
        toolkitDispatch(resetAttributeState());
        handleToggle();
    };

    const prepareData = (values: IAttributesForm) => {
        return {
            connectedTo: values.connectedTo
                .filter((object) => object.selected)
                .map(({ type, defaultValue, multipleValue, required }) => ({
                    type,
                    defaultValue: getDefaultValue(defaultValue, values.type),
                    multipleValue,
                    required,
                })),
            dictionary: values.dictionary.map((dict) =>
                getDictValue(dict.value, values.type)
            ),
            definition: {
                name: values.name,
                label: values.label,
                regex: values.regex,
                lockedToDictionary: values.lockedToDictionary,
            },
        };
    };

    const handleSubmit = (values: IAttributesForm, resetForm: () => void) => {
        const data = prepareData(values);
        handleAsyncRequest({
            asyncAction: attributeId
                ? updateAttribute({
                      attribute: values.internal
                          ? {
                                connectedTo: data.connectedTo,
                                dictionary: data.dictionary,
                            }
                          : data,
                      id: attributeId,
                  })
                : createAttribute({
                      ...data,
                      definition: {
                          ...data.definition,
                          type: Number(values.type),
                      },
                  }),
            onSuccess: {
                callback: () => {
                    resetForm();
                    handleClose();
                },
                message: attributeId
                    ? TranslationHelper.translate('Attribute updated')
                    : TranslationHelper.translate('Attribute created'),
            },
        });
    };

    return (
        <AttributesForm
            isOpen={isOpen}
            title={title}
            initialValues={initialValues}
            handleClose={handleClose}
            handleSubmit={handleSubmit}
            editMode={!!attributeId}
        />
    );
};

export default AttributesDialog;
