import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        gridPane: {
            display: 'flex',
        },
        wrapper: {
            display: 'flex',
            position: 'relative',
            flexGrow: 1,
            flexDirection: 'column',
            height: '600px',
            width: '100%',
        },
        gridBorder: {
            border: `1px solid #BDC3C7`,
            display: 'flex',
            flexGrow: 1,
        },
        progress: {
            marginTop: -theme.spacing(),
        },
        stack: {
            marginBottom: theme.spacing(),
        },
    })
);
