import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
    IRestPatchRightsAssignments,
    IRight,
    restGetRights,
    restPatchRightsAssignments,
} from '../../../services/auth';
import { TRootState } from '../../../store';
import { getSourceSetModels } from '../../../pages/discovery/selectors';
import { createSelector } from 'reselect';

interface IState {
    selectedUserId: null | string;
    mode: null | 'preview' | 'edit' | 'create';
    rights: IRight[];
    personalGroup: null | string;
}

export interface IRightItem extends IRight {
    name: string;
}

const initialState: IState = {
    selectedUserId: null,
    mode: null,
    rights: [],
    personalGroup: null,
};

export const fetchUserDataRights = createAsyncThunk(
    'get:ui/users/rights',
    async (id: number) => {
        return await restGetRights(id);
    }
);

export const patchRightsAssignments = createAsyncThunk(
    'patch:ui/users/rights',
    async (payload: { id: number; data: IRestPatchRightsAssignments }) => {
        return await restPatchRightsAssignments(payload.id, payload.data);
    }
);

const usersSlice = createSlice({
    name: 'usersSlice',
    initialState,
    reducers: {
        clearRights(state) {
            state.rights = [];
        },
        goToPreviewMode(state) {
            state.mode = 'preview';
        },
        enterCreateMode(state) {
            state.mode = 'create';
            state.selectedUserId = null;
        },
        enterEditMode(state) {
            state.mode = 'edit';
        },
        resetMode(state) {
            state.mode = null;
        },
        selectUser(state, action) {
            state.selectedUserId = action.payload;
            state.mode = 'preview';
        },
        deselectUser(state) {
            state.selectedUserId = null;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchUserDataRights.fulfilled, (state, action) => {
            state.rights = [...action.payload];
        });
        builder.addCase(fetchUserDataRights.rejected, (state) => {
            state.rights = [];
        });
    },
});

export const getUsersSourceSetModel = createSelector(
    [getSourceSetModels],
    (models) => models.users || null
);

export const getRights = (state: TRootState) => state.ui.usersSlice.rights;

export const getPersonalGroupId = createSelector(
    [getRights],
    (rights: IRight[]) => {
        const personalGroupRight = rights.find(
            (right) => right.type === 'PERSONAL_GROUP'
        );
        return personalGroupRight?.relationId || null;
    }
);

export const {
    clearRights,
    goToPreviewMode,
    enterCreateMode,
    enterEditMode,
    resetMode,
    selectUser,
    deselectUser,
} = usersSlice.actions;

export default usersSlice.reducer;
