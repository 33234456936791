import { makeRequest } from '../sync';

import { restCustomRequest } from '../../../services/common/utils';
import { IPreviewAction } from '../../ui/discovery/types';

export const getStorePath = (actionData: IPreviewAction) =>
    `${actionData.method}:${actionData.api}/${
        (actionData.params && actionData.params.id) || null
    }`;

export const customAction = (
    actionData: IPreviewAction,
    dataToSend?: any,
    afterSuccess?: (data: any) => void,
    afterFail?: (error: any) => void
) =>
    makeRequest(
        getStorePath(actionData),
        () => restCustomRequest(actionData, dataToSend),
        (dispatch, data) => {
            if (afterSuccess) {
                afterSuccess(data);
            }
        },
        (dispatch, error) => {
            if (afterFail) {
                afterFail(error);
            }
        }
    );
