const MAX_DISTANCE_IN_KM = 5.0;
const MAX_TIME_IN_MINUTES = 20.0;
const MAX_LEVEL_DELTA = 100.0;

export type CalculationType = 'time' | 'distance';

export interface IDataEntry {
    y: number;
    averageY?: number;
    calibratedY: number;
    time: string;
    distance: number;
}

interface IThresholdStrategy {
    isExceeded: (threshold: number) => boolean;
    normalize: (entry: IDataEntry) => number;
}

const timeStrategy: IThresholdStrategy = {
    isExceeded: (threshold: number) => threshold > MAX_TIME_IN_MINUTES,
    normalize: (entry: IDataEntry) => new Date(entry.time).getTime() / 60000,
};

const distanceStrategy: IThresholdStrategy = {
    isExceeded: (threshold: number) => threshold > MAX_DISTANCE_IN_KM,
    normalize: (entry: IDataEntry) => entry.distance,
};

export class AverageGroup {
    private strategy: IThresholdStrategy;
    private prevNormalizedValue?: number;
    constructor(
        calculation: CalculationType,
        public count: number = 0,
        private min: number = 99999,
        private max: number = -99999,
        private sum: number = 0,
        private threshold: number = 0
    ) {
        this.strategy =
            calculation === 'time' ? timeStrategy : distanceStrategy;
    }

    public apply(entry: IDataEntry) {
        if (this.min > entry.y) {
            this.min = entry.y;
        }
        if (this.max < entry.y) {
            this.max = entry.y;
        }
        if (!this.isFinished()) {
            this.sum += entry.y;
            const normalizedValue = this.strategy.normalize(entry);
            this.threshold +=
                this.prevNormalizedValue !== undefined
                    ? normalizedValue - this.prevNormalizedValue
                    : 0.0;
            this.prevNormalizedValue = normalizedValue;
            this.count++;
        }
    }

    public calculateAverage() {
        return this.count === 0 ? 0 : this.sum / this.count;
    }

    public isFinished() {
        return (
            this.max - this.min > MAX_LEVEL_DELTA ||
            this.strategy.isExceeded(this.threshold)
        );
    }
}
