import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => {
    const spacing = theme.spacing(3);
    return createStyles({
        formWrapper: {
            padding: spacing,
            paddingTop: theme.spacing(),
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'space-between',
            overflow: 'auto',
        },
        inputsWrapper: {
            display: 'flex',
            justifyContent: 'space-between',
            paddingBottom: theme.spacing(),
        },
        nameField: {
            width: '25%',
        },
        descriptionField: {
            width: '70%',
        },
        privilegesGroupsWrapper: {
            height: '100%',
            width: '100%',
            overflow: 'auto',
            marginTop: theme.spacing(),
            marginBottom: theme.spacing(),
            marginLeft: theme.spacing(),
            marginRight: theme.spacing(),
        },
        editForm: {
            height: `calc(100% - ${theme.spacing(9)}px)`,
        },
        error: {
            backgroundColor: theme.palette.error.dark,
        },
        overlay: {
            background: 'rgba(255, 255, 255, 0.5)',
            position: 'absolute',
            inset: '-70px 0 0 0',
            zIndex: -1,
        },
    });
});
