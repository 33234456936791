import { Record } from 'immutable';

class TimeZone extends Record({
    id: undefined,
    name: undefined,
}) {
    static fromJson(options) {
        return new TimeZone({
            id: options.id,
            name: options.name,
        });
    }

    toJson() {
        return this.toObject();
    }
}
export default TimeZone;
