import React, { ReactElement } from 'react';

import { ColumnApi, GridApi } from 'ag-grid-community';

import {
    useSelectedGridViewModified,
    useGridViews,
} from '../../state/ui/gridViews/index.hooks';

import MenuDropdown from '../MenuDropdown';
import ListContainer from '../ListContainer';
import GridViewItem from './GridViewItem';
import GridViewsButton from './GridViewsButton';

interface IOwnProps {
    sourceSet: string;
    gridColumnApi?: ColumnApi;
    gridApi?: GridApi;
    gridViewOptions: ReactElement;
}

const GridViewsMenu = ({
    sourceSet,
    gridApi,
    gridColumnApi,
    gridViewOptions,
}: IOwnProps) => {
    const gridViews = useGridViews();

    const selectedGridViewModified = useSelectedGridViewModified();

    return (
        <MenuDropdown
            iconComponent={(callback) => (
                <GridViewsButton callback={callback} />
            )}
        >
            <ListContainer>
                {selectedGridViewModified
                    ? gridViewOptions
                    : gridViews?.map((view) => (
                          <GridViewItem
                              view={view}
                              key={view.name}
                              sourceSet={sourceSet}
                              gridColumnApi={gridColumnApi}
                              gridApi={gridApi}
                          />
                      ))}
            </ListContainer>
        </MenuDropdown>
    );
};

export default GridViewsMenu;
