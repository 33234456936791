import { FieldProps, FormikProps } from 'formik';
import React from 'react';

import ChipInput, { ChipRendererArgs } from 'material-ui-chip-input';
import { useStyles } from './Themable.hoc';
import Chip from '@material-ui/core/Chip/Chip';
import { Tooltip } from '@material-ui/core';

interface ChipItem {
    value: string | number | boolean;
    disabled: boolean;
}

interface IOwnProps {
    inputController: string;
    validateInput: (
        input: string,
        form: FormikProps<any>,
        fieldName: string,
        fieldValue: any,
        inputController: string
    ) => boolean;
    formatValue?: (value: string) => string;
    onDeleteCallback?: () => void;
    complexChipData?: ChipItem[];
}

type TProps = FieldProps & IOwnProps;

const ChipsFieldWrapper = ({
    field,
    form,
    inputController,
    validateInput,
    formatValue,
    complexChipData,
    onDeleteCallback,
    ...props
}: TProps) => {
    const classes = useStyles();

    const renderChip = (
        { text, className, handleDelete, handleClick }: ChipRendererArgs,
        key: any
    ) => {
        const chipItem = complexChipData?.find((item) => item.value === text);
        const isDisabled = chipItem?.disabled || false;
        const chip = (
            <Chip
                label={text}
                className={className}
                key={key}
                onDelete={handleDelete}
                onClick={handleClick}
                disabled={isDisabled}
            />
        );
        if (text.length > 53) {
            return (
                <Tooltip title={text} key={'tooltip' + key}>
                    {chip}
                </Tooltip>
            );
        }
        return chip;
    };

    const prepareValue = (value: string) => {
        if (formatValue) {
            return formatValue(value);
        }
        return value;
    };
    const handleChipAdd = (chip: string) => {
        const newChip = complexChipData
            ? { value: prepareValue(chip), internal: false }
            : prepareValue(chip);
        form.setFieldValue(field.name, [...(field.value as string[]), newChip]);
        form.setFieldValue(inputController, '');
    };

    const handleChipDelete = (chip: string, index: number) => {
        form.setFieldValue(
            field.name,
            (field.value as string[]).filter((item, i) => i !== index)
        );
        onDeleteCallback && onDeleteCallback();
    };

    return (
        <ChipInput
            margin="dense"
            value={field.value}
            newChipKeyCodes={[9, 13, 186, 188]}
            onBeforeAdd={(chip) => {
                if (!chip) return true;
                return validateInput(
                    chip,
                    form,
                    field.name,
                    field.value,
                    inputController
                );
            }}
            onAdd={handleChipAdd}
            onDelete={handleChipDelete}
            blurBehavior={'add'}
            onUpdateInput={(input) =>
                form.setFieldValue(inputController, input.target.value)
            }
            inputValue={form.values[inputController]}
            classes={{
                chipContainer: classes.chipInput,
                chip: classes.chip,
            }}
            chipRenderer={renderChip}
            {...props}
        />
    );
};
export default ChipsFieldWrapper;
