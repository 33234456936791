import URI from 'urijs';
import AppConfig from '../../constants/AppConfig';
import { makeUrl } from '../common/utils';
import UrlConst from '../../constants/UrlConst';

export const makeCustomerServiceUrl = (suffix: string, params = {}) => {
    const url = new URI(
        AppConfig.instance.getUrl(UrlConst.CUSTOMER_SERVICE_URL) + suffix
    );
    return makeUrl(url, params);
};
