/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';

import { useResources } from '../../../../../state/ui/resources/index.hooks';
import { useLanguage } from '../../../../../state/login/index.hooks';
import { fetchResources } from '../../../../../state/ui/resources';

import { useAppDispatch } from '../../../../../hooks';

import TranslationHelper from '../../../../../helpers/TranslationHelper';

import ApplicationResourcesItem from './ApplicationResourcesItem';
import LoadingSpinner from '../../../../loadingSpinner/LoadingSpinner';

const ApplicationResources = () => {
    const resources = useResources();
    const language = useLanguage();

    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(fetchResources());
    }, []);

    const renderItems = () => {
        let listItems = null;
        if (resources === undefined) {
            listItems = (
                <div className="loading-spinner--container">
                    <LoadingSpinner
                        size="20"
                        top="14"
                        left="8"
                        right="8"
                        bottom="14"
                    />
                </div>
            );
        } else if (resources.length > 0) {
            listItems = resources.map((item, i) => {
                return (
                    <ApplicationResourcesItem
                        key={i}
                        resource={item}
                        defaultLanguage={language}
                    />
                );
            });
        } else {
            listItems = (
                <div className="resources-item">
                    {TranslationHelper.translate('No resources files')}
                </div>
            );
        }

        return listItems;
    };

    return (
        <div className="application-resources">
            <header>{TranslationHelper.translate('Resource files')}</header>
            <div id="resourcesList" className="resources-list">
                {renderItems()}
            </div>
        </div>
    );
};

export default ApplicationResources;
