import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { Notifications } from '@material-ui/icons';
import SettingsIcon from '@material-ui/icons/Settings';

import { selectTab } from '../../state/app/alertsPage';

import TabsContainer from '../../components/TabsContainer';

import AlertsGrid from './components/AlertsGrid';
import { useTab } from '../../state/app/alertsPage/index.hook';
import AlertsDefinitionsGrid from './components/AlertsDefinitionsGrid';

const AlertsPage = () => {
    const currentTab = useTab();
    const dispatch = useDispatch();

    const tabs = [
        {
            privileges: ['alertsContext'],
            value: 'alerts',
            label: 'Alerts',
            icon: <Notifications />,
            mainComponent: <AlertsGrid />,
        },
        {
            value: 'alertsSettings',
            label: 'Alerts settings',
            icon: <SettingsIcon />,
            mainComponent: <AlertsDefinitionsGrid />,
        },
    ];

    useEffect(() => {
        selectInitialTab();
    }, []);

    const handleChange = (event: React.ChangeEvent<{}>, value: string) => {
        dispatch(selectTab({ tab: value }));
    };

    const selectInitialTab = () => {
        if (currentTab) return;

        dispatch(selectTab({ tab: 'alerts' }));
    };
    return (
        <TabsContainer
            tabs={tabs}
            currentTab={currentTab}
            handleChange={handleChange}
            title="Alerts"
        />
    );
};

export default AlertsPage;
