import { Dispatch } from 'redux';
import { fetchTask } from '../../../../services/discovery/fetchTask';
import { makeFetchModule } from '../../../_utils';
import { storeTasks } from '../../entities';

const callbacks = {
    onSuccess: (dispatch: Dispatch, { entities }: { entities: object }) => {
        dispatch(storeTasks(entities));
    },
};
const { reducer, fetchData, reset, stopFetchingElement } = makeFetchModule(
    'app/discovery/taskFetching',
    fetchTask,
    callbacks
);

export default reducer;
export { fetchData, reset, stopFetchingElement };
