import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        menuIcon: {
            color: theme.palette.getContrastText(theme.palette.primary.main),
        },
        menu: {
            maxHeight: '400px',
        },
    })
);
