import React, { useEffect, useState } from 'react';
import { Field, FormikErrors } from 'formik';

import {
    FormControlLabel,
    ToggleButton,
    ToggleButtonGroup,
} from '@mui/material';
import { styled } from '@mui/material/styles';

import TranslationHelper from '../../../../../helpers/TranslationHelper';

import { ATTRIBUTE_TYPES } from '../../../../../constants/dictionaries/AttributeTypes';

import { useStyles } from '../Themable.hooks';

import CheckboxWrapper from '../../../../common/formikWrappers/CheckboxWrapper';
import { DictionaryWrapper } from './DictionaryWrapper';
import { IAttributesForm } from './AttributesForm';
import { ObjectsTable } from './ObjectsTable';

interface IOwnProps {
    values: IAttributesForm;
    errors: FormikErrors<IAttributesForm>;
    setFieldValue: (name: string, value: any) => void;
    setFieldError: (name: string, value: string) => void;
}

const CustomToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    height: '50px',
    backgroundColor: theme.palette.grey[100],
    borderRadius: theme.shape.borderRadius,
    '& .MuiToggleButton-root': {
        border: 'none',
        '&.Mui-selected': {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
        },
    },
}));

const DICTIONARY_TYPES = ['Integer', 'String', 'Double'];
export const BottomForm = ({
    values,
    setFieldValue,
    setFieldError,
    errors,
}: IOwnProps) => {
    const initialTab = 'objects';
    const [alignment, setAlignment] = useState(initialTab);
    const [showDictionary, setShowDictionary] = useState(true);

    const classes = useStyles();

    useEffect(() => {
        if (
            !DICTIONARY_TYPES.includes(
                ATTRIBUTE_TYPES[values.type as keyof typeof ATTRIBUTE_TYPES]
            )
        ) {
            setShowDictionary(false);
            setAlignment(initialTab);
        } else {
            setShowDictionary(true);
        }
    }, [values.type]);

    useEffect(() => {
        if (values.dictionaryItemRemoved) {
            resetDefaultValues();
        }
    }, [values.dictionaryItemRemoved]);

    const resetDefaultValues = () => {
        values.connectedTo.forEach((object) => (object.defaultValue = ''));
        setFieldValue('dictionaryItemRemoved', false);
    };
    const handleChange = (
        event: React.MouseEvent<HTMLElement>,
        newAlignment: string
    ) => {
        if (newAlignment !== null) {
            setAlignment(newAlignment);
        }
    };

    return (
        <>
            <CustomToggleButtonGroup
                value={alignment}
                exclusive
                onChange={handleChange}
                aria-label="Platform"
                fullWidth
                size="medium"
                disabled={!showDictionary}
            >
                <ToggleButton value="objects">
                    {TranslationHelper.translate('Objects')}
                </ToggleButton>
                <ToggleButton value="dictionary">
                    {TranslationHelper.translate('Dictionary')}
                </ToggleButton>
            </CustomToggleButtonGroup>
            {alignment === 'objects' && (
                <ObjectsTable values={values} setFieldValue={setFieldValue} />
            )}
            {alignment === 'dictionary' && showDictionary && (
                <>
                    <FormControlLabel
                        label={TranslationHelper.translate(
                            'Only values from the dictionary are available'
                        )}
                        control={
                            <Field
                                name={'lockedToDictionary'}
                                component={CheckboxWrapper}
                                onClick={() => {
                                    if (!values.lockedToDictionary) {
                                        resetDefaultValues();
                                    }
                                    setFieldValue(
                                        'lockedToDictionary',
                                        !values.lockedToDictionary
                                    );
                                }}
                            />
                        }
                        disabled={!!values.internal}
                    />
                    <DictionaryWrapper
                        formValues={values}
                        handleFormChange={(name, val) => {
                            setFieldValue(name, val);
                        }}
                        setFieldError={setFieldError}
                        errors={errors}
                        classes={{ field: classes.fieldMulti }}
                        inputType={
                            ATTRIBUTE_TYPES[
                                values.type as keyof typeof ATTRIBUTE_TYPES
                            ]
                        }
                    />
                </>
            )}
        </>
    );
};
