import React from 'react';

import { LoginPageBackGround } from '../LoginPage/LoginPageBackGround';
import { LoginPageLogo } from '../LoginPage/LoginPageLogo';
import LoginFormFooter from '../../../components/Authentication/LoginFormFooter';

import TranslationHelper from '../../../helpers/TranslationHelper';
import { useAppDispatch } from '../../../hooks';

import { useStyles } from '../Themable.hooks';
import { IDeviceDetails } from '../../../services/trustedDevice';
import useAsyncThunkWithSnackbar from '../../../hooks/useAsyncThunkWithSnackbar';
import { registerTrustedDevice } from '../../../state/ui/trustedDevices';
import { logout } from '../../../state/_actions/logoutAction';
import TrustedDeviceActions from './TrustedDeviceActions';
import clsx from 'clsx';
import { LOGOUT_TIME_MS } from './consts';
import DeviceDetails from '../../../components/DeviceDetails/DeviceDetails';
import { useDeviceDetails } from '../../../hooks/useDeviceDetails';

const TrustedDeviceRegistration = () => {
    const classes = useStyles();

    const dispatch = useAppDispatch();

    const { deviceDetails, loading, error } = useDeviceDetails();

    const handleAsyncRequest = useAsyncThunkWithSnackbar();

    const handleTrustedDeviceRegistration = (
        deviceDetails: IDeviceDetails | null
    ) => {
        if (!deviceDetails) return;

        handleAsyncRequest({
            asyncAction: registerTrustedDevice(deviceDetails),
            onSuccess: {
                message: TranslationHelper.translate(
                    'auth.trustedDeviceRegistered'
                ),
            },
            onError: {
                uniqueCase: {
                    409: TranslationHelper.translate(
                        'auth.trustedDeviceAlreadyRegistered'
                    ),
                },
            },
            onFinally: {
                callback: () =>
                    setTimeout(() => dispatch(logout()), LOGOUT_TIME_MS),
            },
        });
    };

    return (
        <LoginPageBackGround>
            <div
                className={clsx(
                    classes.loginForm,
                    classes.trustedDeviceBackground
                )}
            >
                <LoginPageLogo />
                <div className={classes.formButtons}>
                    {TranslationHelper.translate(
                        'auth.untrustedDeviceDetected'
                    )}
                </div>
                <DeviceDetails data={{ error, loading, deviceDetails }}>
                    <TrustedDeviceActions
                        onClick={() =>
                            handleTrustedDeviceRegistration(deviceDetails)
                        }
                    />
                </DeviceDetails>

                <LoginFormFooter />
            </div>
        </LoginPageBackGround>
    );
};

export default TrustedDeviceRegistration;
