import React, { useEffect } from 'react';

import {
    useGlobalDialogData,
    useGlobalDialogType,
} from '../../state/ui/dialog/index.hooks';

import options from './options';

const GlobalDialog = () => {
    const dialogData = useGlobalDialogData();
    const dialogType = useGlobalDialogType();

    useEffect(() => {
        const overlay = document.querySelector('.overlay');
        if (dialogType) {
            overlay?.classList.add('overlay__visible');
        } else {
            overlay?.classList.remove('overlay__visible');
        }
    }, [dialogType]);

    if (!dialogType) {
        return <div />;
    }

    return options[dialogType](dialogData);
};

export default GlobalDialog;
