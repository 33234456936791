import React, { Component, ComponentType } from 'react';
import { compose } from 'redux';

import { Themable, TThemableProps } from './Themable.hoc';

import { Connectable, TConnectableProps } from './Connectable.hoc';

import TranslationHelper from '../../helpers/TranslationHelper';

import { Link, RouteComponentProps, withRouter } from 'react-router-dom';

import FullScreenSpinner from '../../components/FullScreenSpinner';
import LoginFormFooter from '../../components/Authentication/LoginFormFooter';

import ResetPassword from './components/ResetPassword';
import SendEmailForm from './components/SendEmailForm';

type TProps = TThemableProps & TConnectableProps & RouteComponentProps;

interface IState {
    token?: string;
}

export const PasswordResetLink: React.FunctionComponent<{}> = () => (
    <Link to={'/password-reset'}>
        {TranslationHelper.translate('Forgot your password?')}
    </Link>
);

class PasswordResetPage extends Component<TProps> {
    public state: IState = {
        token: '',
    };

    public componentDidMount() {
        const { location, handleCustomers } = this.props;

        const query = new URLSearchParams(location.search);
        const token = query.get('token');
        this.setState({ token });

        handleCustomers();
    }

    public render() {
        const { classes, changePassword, resetPassword, customers } =
            this.props;

        return (
            <div className={classes.root}>
                <div className={classes.loginForm}>
                    {!this.state.token && !customers && <FullScreenSpinner />}
                    {this.state.token ? (
                        <ResetPassword
                            token={this.state.token}
                            onSubmit={(password, token, onSuccess, onFailure) =>
                                changePassword(
                                    { newPassword: password },
                                    token,
                                    onSuccess,
                                    onFailure
                                )
                            }
                        />
                    ) : (
                        customers && (
                            <SendEmailForm
                                onSubmit={(password, onSuccess, onFailure) =>
                                    resetPassword(
                                        password,
                                        onSuccess,
                                        onFailure
                                    )
                                }
                                customers={customers}
                            />
                        )
                    )}
                    <LoginFormFooter />
                </div>
            </div>
        );
    }
}

export default compose(
    withRouter,
    Themable,
    Connectable
)(PasswordResetPage) as ComponentType<{}>;
