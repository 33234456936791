import React from 'react';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Box } from '@mui/material';

import { Field, Form, Formik } from 'formik';

import TranslationHelper from '../../../../helpers/TranslationHelper';

import { MOBILE } from '../../../../constants/dictionaries/ScreenSizeConst';

import { useGridViewStatus } from '../../../../state/ui/gridViews/index.hooks';
import { gridViewSchema } from '../../../../schemas/discoverySchemas';

import DialogTitle from '../../../../components/dialogs/DialogTitle';
import VisibilityForm from '../../../../components/VisibilityForm/VisibilityForm';
import {
    VISIBILITY_SELF,
    VISIBILITY_GROUP,
    VISIBILITY_ALL,
} from '../../../../components/VisibilityForm/VisibilityForm';
import FieldWrapper from '../../../../components/common/formikWrappers/FieldWrapper';
import LoadingSpinner from '../../../../components/loadingSpinner/LoadingSpinner';
import { IGridViewForm } from './GridViewDialog';
import { usePrivileges } from '../../../../state/auth/index.hooks';
import { IPrivileges } from '../../../../state/auth';

interface IOwnProps {
    isOpen: boolean;
    title: string;
    initialValues: IGridViewForm;
    editMode: boolean;
    handleClose: (event?: object, reason?: string) => void;
    handleSubmit: (values: IGridViewForm, resetForm: () => void) => void;
}

const mapPrivilegesToVisiblityTypes = (privileges: IPrivileges) => {
    const types = [VISIBILITY_SELF];
    if (privileges.manageGroupGridViews) {
        types.push(VISIBILITY_GROUP);
    }
    if (privileges.managePublicGridViews) {
        types.push(VISIBILITY_ALL);
    }
    return types;
};

const GridViewForm = ({
    isOpen,
    initialValues,
    handleSubmit,
    handleClose,
    title,
    editMode,
}: IOwnProps) => {
    const isMobile = useMediaQuery(MOBILE);
    const gridViewStatus = useGridViewStatus();
    const privileges = usePrivileges();

    const allowedVisibilityTypes = mapPrivilegesToVisiblityTypes(privileges);

    return (
        <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            onSubmit={(values, { resetForm }) =>
                handleSubmit(values, resetForm)
            }
            validateOnBlur={false}
            validateOnChange={false}
            validationSchema={gridViewSchema}
        >
            {({ errors, values, setFieldValue, submitForm }) => (
                <Dialog
                    open={isOpen}
                    onClose={handleClose}
                    maxWidth={'lg'}
                    fullScreen={isMobile}
                    disableEscapeKeyDown
                >
                    <DialogTitle
                        title={TranslationHelper.translate(title)}
                        close={handleClose}
                        save={submitForm}
                    />
                    {gridViewStatus === 'loading' && (
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                width: '300px',
                                height: '200px',
                            }}
                        >
                            <LoadingSpinner />
                        </Box>
                    )}
                    {gridViewStatus === 'done' && (
                        <DialogContent style={{ maxWidth: 400 }}>
                            <Form>
                                <Field
                                    error={!!(errors && errors.name)}
                                    helperText={errors && errors.name}
                                    name={'name'}
                                    label={TranslationHelper.translate('Name')}
                                    autoComplete={'name'}
                                    type={'text'}
                                    component={FieldWrapper}
                                    required={true}
                                    fullWidth={true}
                                />
                                <VisibilityForm
                                    values={values}
                                    setFieldValue={setFieldValue}
                                    disabled={editMode}
                                    allowedTypes={allowedVisibilityTypes}
                                />
                                <Field
                                    error={!!(errors && errors.description)}
                                    helperText={errors && errors.description}
                                    name={'description'}
                                    label={TranslationHelper.translate(
                                        'Description'
                                    )}
                                    autoComplete={'description'}
                                    type={'text'}
                                    component={FieldWrapper}
                                    fullWidth={true}
                                />
                            </Form>
                        </DialogContent>
                    )}
                </Dialog>
            )}
        </Formik>
    );
};

export default GridViewForm;
