import React from 'react';

import { useStyles } from './Themable.hooks';
import { IIcon, ITaskDetails } from '../../../../../../../../state/types';
import { useUserSettings } from '../../../../../../../../state/user/index.hooks';

import IconGenerator from '../../../../../../../../helpers/IconGenerator';
import TimeFormatter from '../../../../../../../../helpers/TimeFormatter';
import Translator from '../../../../../../../../helpers/TranslationHelper';

import { default as VehicleIcon } from '@material-ui/icons/LocalShipping';
import { default as EmployeeIcon } from '@material-ui/icons/Person';

import { Tooltip, Typography } from '@material-ui/core';

interface IOwnProps {
    taskDetails: ITaskDetails | null;
    taskIcon: IIcon | null;
}

const TaskHeader = ({ taskDetails, taskIcon }: IOwnProps) => {
    const classes = useStyles();
    const userSettings = useUserSettings();

    if (taskDetails === null) {
        return null;
    }

    const { vehicleName, employeeName, location, statusDate, statusName } =
        taskDetails;

    const statusIcon = taskIcon
        ? IconGenerator.getDiscoveryIconAsComponent(taskIcon)
        : undefined;

    const renderTaskStatus = () => {
        return statusDate
            ? `${Translator.translate(
                  statusName
              )}, ${TimeFormatter.dateToString(
                  statusDate,
                  userSettings.dateFormat
              )}`
            : `${Translator.translate(statusName)}`;
    };

    return (
        <div className={classes.wrapper}>
            <div className={classes.detailsSection}>
                <div className={classes.detailsIcon}>{statusIcon}</div>
                <div className={classes.detailsWrapper}>
                    <p className={classes.adress}>{location.address}</p>
                    <p className={classes.time}>{renderTaskStatus()}</p>
                </div>
            </div>

            <div className={classes.detailsSection}>
                <div className={classes.detailsIcon}>
                    <VehicleIcon color="disabled" />
                </div>
                <div className={classes.detailsWrapper}>
                    <Tooltip title={vehicleName || ''} placement="bottom-start">
                        <Typography noWrap={true}>{vehicleName}</Typography>
                    </Tooltip>
                </div>
            </div>

            <div className={classes.detailsSection}>
                <div className={classes.detailsIcon}>
                    <EmployeeIcon color="disabled" />
                </div>
                <div className={classes.detailsWrapper}>
                    <Tooltip
                        title={employeeName || ''}
                        placement="bottom-start"
                    >
                        <Typography noWrap={true}>{employeeName}</Typography>
                    </Tooltip>
                </div>
            </div>
        </div>
    );
};

export default TaskHeader;
