import React, { Fragment } from 'react';
import TranslationHelper from '../../../../../../helpers/TranslationHelper';
import { ISourceSetMessage } from '../../../../../../state/types';

import { IconButton, Tooltip } from '@material-ui/core';
import { Info, Warning } from '@material-ui/icons';
import { Themable, TThemableProps } from './Themable.hoc';

interface IOwnProps {
    messages?: ISourceSetMessage[];
    onClick?: () => void;
}

const getIcon = (type: 'info' | 'warning' | 'error') => {
    switch (type) {
        case 'warning':
        case 'error':
            return <Warning />;

        case 'info':
        default:
            return <Info />;
    }
};

const ErrorMessage = ({
    messages,
    classes,
    onClick,
}: IOwnProps & TThemableProps) => {
    return messages?.length ? (
        <Tooltip
            title={messages.map((error) => (
                <Fragment key={error.content}>
                    {TranslationHelper.translate(error.content)}
                    <br />
                </Fragment>
            ))}
        >
            <IconButton
                size="small"
                className={[classes[messages[0].level], classes.button].join(
                    ' '
                )}
                onClick={onClick}
            >
                {getIcon(messages[0].level)}
            </IconButton>
        </Tooltip>
    ) : null;
};

export default Themable(ErrorMessage);
