import { createStyles, Theme, WithStyles } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme: Theme) => {
    const padding = theme.spacing();
    return createStyles({
        listItemHeading: {
            paddingLeft: padding * 2,
            paddingBottom: 0,
            paddingTop: 0,
            color: theme.palette.primary.dark,
            backgroundColor: theme.palette.grey[200],
        },
        infoMessage: {
            paddingLeft: padding,
        },
        heading: {
            fontSize: theme.typography.subtitle1.fontSize,
        },
        listItem: {
            fontSize: theme.typography.caption.fontSize,
            justifyContent: 'space-between',
            borderBottom: '1px solid ' + theme.palette.divider,
            color: theme.palette.primary.dark,
        },
        itemLabel: {
            fontSize: theme.typography.caption.fontSize,
            paddingRight: padding * 2,
            color: theme.palette.common.black,
            wordWrap: 'break-word',
            width: '90%',
        },
        iconButton: {
            height: 25,
            width: 25,
            padding: 0,
        },
        iconButtonIcon: {
            height: 17,
            width: 17,
        },
        plusIconButton: {
            color: theme.palette.getContrastText(theme.palette.primary.main),
        },
        error: {
            fontSize: theme.typography.subtitle1.fontSize,
            color: theme.palette.error.main,
        },
        displayNone: {
            display: 'none',
        },
    });
};

export type TThemableProps = WithStyles<typeof styles>;

export const Themable = withStyles(styles, { withTheme: true });
