import React, { Component, ComponentType } from 'react';
import { compose } from 'redux';

import { Connectable, TConnectableProps } from './Connectable.hoc';
import { Themable, TThemableProps } from './Themable.hoc';

import {
    LinearProgress,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    Typography,
} from '@material-ui/core';

import { default as PlusIcon } from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import FileCopyIcon from '@material-ui/icons/FileCopy';

import DeletionDialog from '../../../../components/dialogs/DeletionDialog';
import PaneHeader from '../../../../components/PaneHeader';
import TranslationHelper from '../../../../helpers/TranslationHelper';
import { IRole } from '../../../../state/app/adminPanel';
import { IRequestWithMessages } from '../../../../state/app/sync';

import { AccessibleIconButton } from '../../../../components/accessControl/components';

type TProps = TThemableProps & TConnectableProps;

interface IState {
    isDeletionDialogOpen: boolean;
}

class RolesList extends Component<TProps, IState> {
    public state: IState = {
        isDeletionDialogOpen: false,
    };

    public isRequestActive = (request: IRequestWithMessages) =>
        request && request.success === null && request.error === null;

    public render() {
        const {
            classes,
            rolesList,
            deleteRole,
            setRole,
            selectedRoleId,
            enterCreateMode,
            roleToDeleteId,
            getRolesRequest,
        } = this.props;
        const { isDeletionDialogOpen } = this.state;

        return (
            <div>
                <DeletionDialog
                    isOpen={isDeletionDialogOpen}
                    close={this.closeDeletionDialog}
                    confirm={() => {
                        deleteRole(roleToDeleteId as number);
                        this.closeDeletionDialog();
                    }}
                    title="Role removal confirmation"
                    message="Do you really want to DELETE this role?"
                />
                <PaneHeader
                    title={TranslationHelper.translate('Roles')}
                    renderRightCustomControls={() => (
                        <AccessibleIconButton
                            check={(privileges) => privileges.addRole}
                            tooltipTitle="Add role"
                            onClick={() => {
                                setRole({ role: { id: -1 } as IRole });
                                enterCreateMode();
                            }}
                            className={classes.plusIconButton}
                            aria-label="Create"
                        >
                            <PlusIcon />
                        </AccessibleIconButton>
                    )}
                />

                {this.isRequestActive(
                    getRolesRequest as IRequestWithMessages
                ) && <LinearProgress />}

                <div className={classes.rolesTableWrapper}>
                    <List dense={false}>
                        {rolesList.map((role) => (
                            <ListItem
                                key={role.id}
                                divider={true}
                                button={true}
                                onClick={() => this.selectRoleOnList(role.id)}
                                selected={role.id === selectedRoleId}
                            >
                                <ListItemText
                                    primary={
                                        <Typography
                                            variant="body1"
                                            className={classes.listItem}
                                        >
                                            {role.name}
                                        </Typography>
                                    }
                                    secondary={
                                        role.readonly
                                            ? TranslationHelper.translate(
                                                  role.description
                                              )
                                            : role.description
                                    }
                                    classes={{ secondary: classes.listItem }}
                                />
                                <ListItemSecondaryAction>
                                    <AccessibleIconButton
                                        check={(privileges) =>
                                            privileges.addRole
                                        }
                                        tooltipTitle="Copy role"
                                        onClick={() => this.copyRole(role.id)}
                                        aria-label="Copy"
                                    >
                                        <FileCopyIcon />
                                    </AccessibleIconButton>
                                    <AccessibleIconButton
                                        check={(privileges) =>
                                            privileges.deleteRole
                                        }
                                        tooltipTitle="Delete role"
                                        onClick={() =>
                                            this.openDeletionDialog(role.id)
                                        }
                                        disabled={role.readonly}
                                        aria-label="Delete"
                                    >
                                        <DeleteIcon />
                                    </AccessibleIconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                        ))}
                    </List>
                </div>
            </div>
        );
    }

    private selectRoleOnList = (id: number) => {
        this.props.getRole(id, (data) =>
            this.props.selectRole({ id: data.id })
        );
    };

    private copyRole = (id: number) => {
        this.props.getRole(
            id,
            () => {
                this.props.enterCreateMode();
            },
            (data) => {
                data.name = `${TranslationHelper.translate('Copy of')} ${
                    data.name
                }`;
                data.id = -1;
                return data;
            }
        );
    };

    private openDeletionDialog = (id: number) => {
        this.setState({ isDeletionDialogOpen: true });
        this.props.setRoleToDelete({ roleToDelete: id });
    };

    private closeDeletionDialog = () => {
        this.setState({ isDeletionDialogOpen: false });
    };
}

export default compose(Themable, Connectable)(RolesList) as ComponentType<{}>;
