import { FormikValues } from 'formik';
import {
    IEventAlertCondition,
    IEventType,
    IGroupAlertCondition,
    TAnyAlertCondition,
    ILayerAlertCondition,
    IPutAlertSetting,
} from '../../../services/alerts';
import { conditionTypes } from '../../../state/app/alerts';
import { TAlertDialog } from '../../../state/app/alertsPage';
import { IPostAlertSetting } from '../../../services/alerts';
import { IGroup } from '../../../state/app/collections/dataTypes';

const clonedeep = require('lodash.clonedeep');

const prepareGroupAlertCondition = (
    conditionDataArg: IGroupAlertCondition,
    events: IEventType[]
) => {
    const mappedConditions = conditionDataArg.conditions?.map(
        (condition: TAnyAlertCondition) =>
            (condition = prepareCondition(condition, events))
    );
    conditionDataArg.conditions = mappedConditions;
    return conditionDataArg;
};
const prepareLayerAlertCondition = (data: ILayerAlertCondition) => {
    const conditionDataMapped = { ...data };

    if (conditionDataMapped.features[0].id === 'All') {
        conditionDataMapped.features = [];
    }
    const updatedFeatures = conditionDataMapped.features.map(
        (feat: any) => feat.name
    );
    conditionDataMapped.features = updatedFeatures;
    return conditionDataMapped;
};

const prepareEventAlertCondition = (
    data: IEventAlertCondition,
    events: IEventType[]
) => {
    data.eventType =
        events.find((event) => {
            return event.id === data.eventType.id;
        }) || data.eventType;
    return data;
};
const prepareCondition = (
    conditionData: TAnyAlertCondition,
    events: IEventType[]
): TAnyAlertCondition => {
    switch (conditionData.type) {
        case conditionTypes.and:
        case conditionTypes.or:
            conditionData = conditionData as IGroupAlertCondition;
            return prepareGroupAlertCondition(conditionData, events);

        case conditionTypes.param:
            return conditionData;

        case conditionTypes.event:
            return prepareEventAlertCondition(
                conditionData as IEventAlertCondition,
                events
            );

        case conditionTypes.layer:
            return prepareLayerAlertCondition(
                conditionData as ILayerAlertCondition
            );

        default:
            return conditionData;
    }
};

const prepareSubject = (copiedData: FormikValues, dialog: TAlertDialog) => {
    if (dialog.elementId) {
        return {
            objectId: Number(dialog.elementId),
        };
    } else if (copiedData.alertSubject === 'object') {
        return {
            objectId: copiedData.object.id,
        };
    } else if (copiedData.alertSubject === 'group') {
        return {
            groupIds: copiedData.groups.map((group: IGroup) => group.id),
        };
    }

    return {};
};

const prepareRecipients = (copiedData: FormikValues) => {
    const { recipients } = copiedData;
    const emails: string[] | undefined = recipients?.emails;
    const providers: string[] | undefined = recipients?.providers?.map(
        (provider: { id: string; name: string }) => provider.id
    );

    return {
        recipients: {
            ...recipients,
            emails: emails ? Array.from(new Set(emails)) : undefined,
            providers: providers,
        },
    };
};

export const prepareSubmitAction = (
    data: FormikValues,
    dialog: TAlertDialog,
    events: IEventType[]
) => {
    const copiedData = clonedeep(data);

    let alertData: IPostAlertSetting = {
        ...prepareSubject(copiedData, dialog),
        ...prepareRecipients(copiedData),
        name: copiedData.name,
        condition: prepareCondition(copiedData.condition, events),
    };
    const action = dialog.dialogAction;
    let actionWithParams;

    if (dialog.mode === 'edit') {
        actionWithParams = {
            ...action,
            params: { id: dialog.elementCollectionName },
        };
        alertData = {
            ...alertData,
            id: Number(dialog.elementCollectionName),
            objectId: undefined,
        } as IPutAlertSetting;
    }

    return { action, actionWithParams, alertData };
};
