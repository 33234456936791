import React from 'react';

import { useStyles } from './Themable.hooks';
import AttributesGrid from './components/AttributesGrid';

const AttributesPage = () => {
    const classes = useStyles();

    return (
        <div className={classes.pageWrapper}>
            <div className={classes.hiddenGridPane}>
                <AttributesGrid />
            </div>
        </div>
    );
};

export default AttributesPage;
