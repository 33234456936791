export const calculateRefueling = (
    capacity: number,
    data: number,
    prevData?: number
) => {
    if (capacity === 0) {
        return {};
    }

    const diff = capacity * 0.05;
    if (prevData && data - prevData > diff) {
        return {
            refuelingAmount: data - prevData,
        };
    }

    return {};
};
