import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => {
    const padding = theme.spacing(2);

    return createStyles({
        field: {
            paddingBottom: theme.spacing(),
        },
        fieldWithSpace: {
            padding: theme.spacing(),
        },
        paneContent: {
            padding,
        },
    });
});
