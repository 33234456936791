import React from 'react';
import LoadingSpinner from '../loadingSpinner/LoadingSpinner';

interface IProps {
    opacity?: string;
    zIndex?: string;
    message?: string;
}

export default (props: IProps) => (
    <LoadingSpinner
        width="100"
        height="100"
        top="0"
        right="0"
        bottom="0"
        left="0"
        opacity={props.opacity || '0.7'}
        zIndex={props.zIndex || '10000'}
        message={props.message}
    />
);
