import base64url from 'base64url';
import pako from 'pako';

// * this will omit values that are undefined
const dataToString = (data: any): string => {
    const dataToQuery = JSON.stringify(data);
    const packedData = pako.deflateRaw(dataToQuery, { to: 'string' });
    return base64url.fromBase64(btoa(packedData));
};

// * onSuccess callback required - pako.inflateRaw can break on invalid string
const stringToData = (
    query: string,
    onSuccess: (data: any) => void,
    onFail?: (err: any) => void
): void => {
    try {
        const decodedData = atob(base64url.toBase64(query));
        const restoredData = JSON.parse(
            pako.inflateRaw(decodedData, { to: 'string' })
        );
        onSuccess(restoredData);
    } catch (err) {
        if (onFail) {
            onFail(err);
        }
    }
};

const convertArrayToMap = (array: any[], key: string): Map<string, any> =>
    array.reduce((map, object) => map.set(object[key], object), new Map());

export { dataToString, stringToData, convertArrayToMap };
