import React, { useEffect } from 'react';

import { Field, FormikErrors, FormikValues } from 'formik';

import { LinearProgress } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';

import { useStyles } from '../FormsThemable.hooks';
import {
    useLayers,
    useAlertDefinitions,
    useObjects,
} from './Connectable.hooks';

import FieldWrapper from '../../../../components/common/formikWrappers/FieldWrapper';
import TranslationHelper from '../../../../helpers/TranslationHelper';
import {
    fetchAlertDefinitions,
    fetchLayers,
} from '../../../../state/app/alerts';
import { mapDictionaryItems, TDictionaryItem } from '../../_utils/utils';
import { useAppDispatch } from '../../../../hooks';
import { IDialogProp } from '../../AlertSettingDialog';

export const LAYER_ALERT_DEFINITION = 'LAYER';

interface IOwnProps {
    errors?: FormikErrors<any>;
    values: FormikValues;
    dialog: IDialogProp;
    name: string;
    setFieldValue: (name: string, value: any) => void;
}

const LayerAlertDefinitionForm = ({
    name,
    errors,
    dialog,
    values,
    setFieldValue,
}: IOwnProps) => {
    const classes = useStyles({});
    const layers = useLayers();
    const alertDefinitions = useAlertDefinitions();
    const objects = useObjects();
    const dispatch = useAppDispatch();

    const { mode } = dialog;

    const inEditMode = mode === 'edit';
    useEffect(() => {
        dispatch(fetchLayers());
        dispatch(fetchAlertDefinitions(LAYER_ALERT_DEFINITION));
    }, []);

    const sortByName = (a: TDictionaryItem, b: TDictionaryItem) => {
        const aName = a?.name?.toLowerCase();
        const bName = b?.name?.toLowerCase();

        if (!aName || !bName) {
            return 0;
        }
        if (aName < bName) {
            return -1;
        }
        if (aName > bName) {
            return 1;
        }
        return 0;
    };

    const getFeatures = (id: string) => {
        return [
            ...objects,
            ...(layers
                .find((el) => el.id === id)
                ?.features.map((feat) => {
                    return { id: feat.name + 'id', name: feat.name };
                }) || []),
        ];
    };
    const changeFeaturesField = (value: any) => {
        setFieldValue(`${name}.features[0].id`, value);
        setFieldValue(
            `${name}.features[0].name`,
            getFeatures(values.layerId).filter((feat) => feat.id === value)[0]
                .name
        );
    };

    const changeDefinitionIdLayerField = (value: string) => {
        setFieldValue(`${name}.definitionId`, value);
    };
    const renderLayerNameField = () => {
        return (
            <Field
                className={classes.fieldLg}
                error={!!errors?.layerId}
                helperText={errors?.layerId}
                disabled={inEditMode}
                name={`${name}.layerId`}
                label={TranslationHelper.translate('Layer name')}
                select={true}
                fullWidth={true}
                component={FieldWrapper}
                required={true}
                variant="outlined"
            >
                {mapDictionaryItems(
                    layers.sort(sortByName),
                    undefined,
                    'id',
                    'wx'
                )}
            </Field>
        );
    };
    const renderLayerObjectField = () => {
        return (
            <Field
                className={classes.fieldLg}
                error={!!errors?.features?.[0]?.id}
                helperText={errors?.features?.[0]?.id}
                disabled={inEditMode}
                label={TranslationHelper.translate('Layer object')}
                select={true}
                fullWidth={true}
                component={FieldWrapper}
                required={true}
                variant="outlined"
                name={`${name}.features[0].id`}
                afterOnChange={changeFeaturesField}
            >
                {mapDictionaryItems(
                    getFeatures(values.layerId).sort(sortByName),
                    undefined,
                    'id',
                    'wx'
                )}
            </Field>
        );
    };
    return (
        <>
            <Field
                className={classes.field}
                error={!!errors?.definitionIdLayer}
                helperText={errors?.definitionIdLayer}
                disabled={inEditMode}
                name={`${name}.definitionIdLayer`}
                label={TranslationHelper.translate('Alert definition')}
                select={true}
                fullWidth={true}
                component={FieldWrapper}
                required={true}
                variant="outlined"
                value={values.definitionId}
                afterOnChange={changeDefinitionIdLayerField}
            >
                {mapDictionaryItems(
                    alertDefinitions[LAYER_ALERT_DEFINITION],
                    undefined,
                    'id',
                    'wx'
                )}
            </Field>
            {inEditMode ? (
                <Tooltip
                    title={
                        TranslationHelper.translate(
                            layers.filter(
                                (layer) => layer.id === values.layerId
                            )[0]?.name
                        ) || ''
                    }
                    placement="top"
                    arrow
                    enterDelay={500}
                >
                    <span>{renderLayerNameField()}</span>
                </Tooltip>
            ) : (
                renderLayerNameField()
            )}
            {inEditMode ? (
                <Tooltip
                    title={
                        TranslationHelper.translate(values.features[0]?.name) ||
                        ''
                    }
                    placement="top"
                    arrow
                    enterDelay={500}
                >
                    <span>{renderLayerObjectField()}</span>
                </Tooltip>
            ) : (
                renderLayerObjectField()
            )}

            {!layers && <LinearProgress />}
        </>
    );
};

export default LayerAlertDefinitionForm;
