import React, { ReactChild } from 'react';

import { Formik, FormikActions, FormikValues } from 'formik';

import {
    fetchRoutes,
    IRoute,
    resetRouteMode,
    updateRoute,
} from '../../../../../state/ui/planning/routes';
import {
    useSelectedCategory,
    useSelectedDates,
    useSelectedRoute,
} from '../../../../../state/ui/planning/routes/index.hooks';

import { editRouteSchema } from '../../../../../schemas/planningSchemas';

import TranslationHelper from '../../../../../helpers/TranslationHelper';
import { useToolkitDispatch } from '../../../../../hooks';
import useAsyncThunkWithSnackbar from '../../../../../hooks/useAsyncThunkWithSnackbar';

import PaneHeader from '../../../../../components/PaneHeader/PaneHeader';
import RoutesForm from './RoutesForm';
import { useSnackbar } from 'notistack';

interface IOwnProps {
    rightControls: (
        submitForm: () => void,
        isSubmitting: boolean
    ) => ReactChild;
}
const RoutesEditPane = ({ rightControls }: IOwnProps) => {
    const toolkitDispatch = useToolkitDispatch();

    const handleAsyncRequest = useAsyncThunkWithSnackbar();
    const selectedRoute = useSelectedRoute();
    const category = useSelectedCategory();
    const dates = useSelectedDates();

    const { enqueueSnackbar } = useSnackbar();

    const handleClose = () => {
        toolkitDispatch(resetRouteMode());
    };

    if (!selectedRoute.data) {
        enqueueSnackbar('Route could not be displayed. Please try again', {
            variant: 'error',
        });
        handleClose();

        return <></>;
    }

    const {
        name,
        plannedOn,
        status,
        progress,
        vehicle,
        employee,
        activityCategoryId,
    } = selectedRoute.data;
    const initialValues = {
        name,
        plannedOn,
        status,
        progress,
        activityCategory: activityCategoryId,
        vehicle: { label: vehicle?.name, id: vehicle?.id },
        employee: { label: employee?.name, id: employee?.id },
    };

    const handleValues = (
        values: FormikValues,
        { setSubmitting }: FormikActions<FormikValues>
    ) => {
        if (!selectedRoute.data?.id) {
            return;
        }
        const route = {
            name: values.name,
            employeeId: values.employee.id,
            vehicleId: values.vehicle.id,
        };
        handleAsyncRequest({
            asyncAction: updateRoute({ id: selectedRoute.data.id, route }),
            onSuccess: {
                callback: () => {
                    handleClose();
                    setSubmitting(false);
                    category &&
                        handleAsyncRequest({
                            asyncAction: fetchRoutes({
                                from: dates.from,
                                to: dates.to,
                                activityCategoryId: category,
                            }),
                        });
                },
                message: TranslationHelper.translate('Route updated'),
            },
            onError: {
                callback: () => setSubmitting(false),
            },
        });
    };

    const fields: { [K in keyof IRoute]?: { disabled?: boolean } } = {
        name: {},
        activityCategoryId: { disabled: true },
        plannedOn: { disabled: true },
        employee: {},
        vehicle: {},
    };

    return (
        <div>
            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                onSubmit={handleValues}
                validateOnBlur={false}
                validateOnChange={false}
                validationSchema={editRouteSchema}
            >
                {({
                    errors,
                    values,
                    setFieldValue,
                    submitForm,
                    isSubmitting,
                }) => (
                    <>
                        <PaneHeader
                            onCloseClick={handleClose}
                            title={TranslationHelper.translate('Edit route')}
                            renderRightCustomControls={() =>
                                rightControls(submitForm, isSubmitting)
                            }
                        />

                        <RoutesForm
                            values={values}
                            errors={errors}
                            setFieldValue={setFieldValue}
                            fields={fields}
                        />
                    </>
                )}
            </Formik>
        </div>
    );
};

export default RoutesEditPane;
