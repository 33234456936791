import React, { ReactChild } from 'react';

import { Formik, FormikActions, FormikValues } from 'formik';

import moment from 'moment';

import {
    createRoute,
    fetchRoutes,
    IRoute,
    resetRouteMode,
} from '../../../../../state/ui/planning/routes';
import {
    useSelectedCategory,
    useSelectedDates,
} from '../../../../../state/ui/planning/routes/index.hooks';

import { createRouteSchema } from '../../../../../schemas/planningSchemas';

import TranslationHelper from '../../../../../helpers/TranslationHelper';
import { useToolkitDispatch } from '../../../../../hooks';
import useAsyncThunkWithSnackbar from '../../../../../hooks/useAsyncThunkWithSnackbar';
import TimeFormatter from '../../../../../helpers/TimeFormatter';

import PaneHeader from '../../../../../components/PaneHeader/PaneHeader';
import RoutesForm from './RoutesForm';

interface IOwnProps {
    rightControls: (
        submitForm: () => void,
        isSubmitting: boolean
    ) => ReactChild;
}
const RoutesCreatePane = ({ rightControls }: IOwnProps) => {
    const toolkitDispatch = useToolkitDispatch();
    const category = useSelectedCategory();
    const dates = useSelectedDates();
    const handleAsyncRequest = useAsyncThunkWithSnackbar();

    const handleClose = () => {
        toolkitDispatch(resetRouteMode());
    };

    const initialValues = {
        name: '',
        plannedOn: moment().toString(),
        status: '',
        progress: '',
        activityCategory: '',
        vehicle: {},
        employee: {},
    };

    const handleValues = (
        values: FormikValues,
        { setSubmitting }: FormikActions<FormikValues>
    ) => {
        const route = {
            name: values.name,
            plannedOn: TimeFormatter.dateToShortDateString(values.plannedOn),
            activityCategoryId: values.activityCategory,
            employeeId: values.employee.id,
            vehicleId: values.vehicle.id,
        };
        handleAsyncRequest({
            asyncAction: createRoute(route),
            onSuccess: {
                callback: () => {
                    handleClose();
                    setSubmitting(false);
                    category &&
                        handleAsyncRequest({
                            asyncAction: fetchRoutes({
                                from: dates.from,
                                to: dates.to,
                                activityCategoryId: category,
                            }),
                        });
                },
                message: TranslationHelper.translate('Route created'),
            },
            onError: {
                callback: () => setSubmitting(false),
            },
        });
    };

    const fields: { [K in keyof IRoute]?: { disabled?: boolean } } = {
        name: {},
        activityCategoryId: {},
        plannedOn: {},
        employee: {},
        vehicle: {},
    };

    return (
        <div>
            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                onSubmit={handleValues}
                validateOnBlur={false}
                validateOnChange={false}
                validationSchema={createRouteSchema}
            >
                {({
                    errors,
                    values,
                    setFieldValue,
                    submitForm,
                    isSubmitting,
                }) => (
                    <>
                        <PaneHeader
                            onCloseClick={handleClose}
                            title={TranslationHelper.translate('Add route')}
                            renderRightCustomControls={() =>
                                rightControls(submitForm, isSubmitting)
                            }
                        />

                        <RoutesForm
                            values={values}
                            errors={errors}
                            setFieldValue={setFieldValue}
                            fields={fields}
                        />
                    </>
                )}
            </Formik>
        </div>
    );
};

export default RoutesCreatePane;
