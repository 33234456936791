import React, { useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';

import { useSnackbar } from 'notistack';

import {
    GridApi,
    GridReadyEvent,
    IDatasource,
    IGetRowsParams,
    IServerSideGetRowsParams,
    IServerSideDatasource,
} from 'ag-grid-community';

import { LinearProgress, Paper } from '@material-ui/core';
import { default as PlusIcon } from '@material-ui/icons/Add';

import { useStyles } from '../../../../Themable.hooks';

import TranslationHelper from '../../../../../../helpers/TranslationHelper';
import { useIntervalEffect } from '../../../../../../helpers/hooks';

import {
    enterCreateMode,
    fetchReports,
    IReport,
    IReports,
} from '../../../../../../state/ui/reportsSlice';
import { useUserSettings } from '../../../../../../state/user/index.hooks';

import { useAppDispatch, useToolkitDispatch } from '../../../../../../hooks';

import AppConfig from '../../../../../../constants/AppConfig';
import {
    IN_QUEUE,
    GENERATING,
    NEW,
} from '../../../../../../constants/dictionaries/ReportStatuses';

import InfiniteRowModelGrid from '../../../../../../components/InfiniteRowModelGrid';
import PaneHeader from '../../../../../../components/PaneHeader';
import IconButtonWithTooltip from '../../../../../../components/IconButtonWithTooltip';
import { useReports } from '../../../../../../state/ui/reportsSlice/index.hooks';
import ServerSideRowModelGrid from '../../../../../../components/ServerSideRowModelGrid';
import { getColDefs, gridProps, serverSideGridProps } from './utils/gridConfig';
import { handleQueryAction } from '../../../../../../helpers/handleQueryAction';
import { handleGridNodeSelection } from '../../../../../../helpers/handleGridNodeSelection';

const REPORTS_LIMIT = 25;
const INTERVAL = 15000;

interface IOwnProps {
    gridApi: GridApi | null;
    setGridApi: (gridApi: GridApi | null) => void;
}
const ReportsGrid = ({ gridApi, setGridApi }: IOwnProps) => {
    const [dataSourceLoading, setDataSourceLoading] = useState<boolean>(true);

    const classes = useStyles();

    const toolkitDispatch = useToolkitDispatch();
    const dispatch = useAppDispatch();
    const history = useHistory();

    const userSettings = useUserSettings();
    const reports = useReports();
    const { enqueueSnackbar } = useSnackbar();

    const enterpriseKey = AppConfig?.instance.getConfigKey(
        AppConfig.PROPERTY_GRID
    )?.key;

    useIntervalEffect(
        () => {
            if (reports && gridApi && hasReportsInProgress(reports)) {
                enterpriseKey
                    ? gridApi.refreshServerSideStore({})
                    : gridApi.purgeInfiniteCache();
            }
        },
        [reports],
        INTERVAL
    );

    useEffect(() => {
        if (!dataSourceLoading && gridApi) {
            handleQueryAction('selectReport', (params) =>
                handleSelectReport(gridApi, Number(params.id))
            );
        }
    }, [dataSourceLoading]);

    const handleSelectReport = (api: GridApi, reportId: number) => {
        history.push('/reports');
        handleGridNodeSelection(api, reportId, 'id', () => {
            enqueueSnackbar(
                TranslationHelper.translate(
                    'Report with provided id does not exist'
                ),
                { variant: 'error' }
            );
        });
    };

    const hasReportsInProgress = (data: IReports) => {
        return data.values.some((value: IReport) => {
            switch (value.status) {
                case NEW:
                case IN_QUEUE:
                case GENERATING:
                    return true;
                default:
                    return false;
            }
        });
    };

    const getRowsData = (params: IGetRowsParams) => ({
        start: params.startRow,
        end: params.endRow,
    });

    const getRowsDataServerSide = (params: IServerSideGetRowsParams) => ({
        start: params.request.startRow,
        end: params.request.endRow,
    });

    const getRows = (params: IGetRowsParams | IServerSideGetRowsParams) => {
        setDataSourceLoading(true);

        gridApi?.hideOverlay();
        const { start } = enterpriseKey
            ? getRowsDataServerSide(params as IServerSideGetRowsParams)
            : getRowsData(params as IGetRowsParams);

        toolkitDispatch(
            fetchReports({ start: start || 0, limit: REPORTS_LIMIT })
        )
            .unwrap()
            .then((data) => {
                const { values, total } = data;
                setDataSourceLoading(false);
                params.successCallback(values, total);
                if (total === 0) {
                    gridApi?.showNoRowsOverlay();
                }
            });
    };
    const reportsDataSource: IDatasource = { getRows };

    const reportsDataSourceEnt: IServerSideDatasource = { getRows };

    const setDataSource = (api: GridApi) => {
        if (enterpriseKey) {
            api.setServerSideDatasource(reportsDataSourceEnt);
        } else {
            api.setDatasource(reportsDataSource);
        }
    };
    const onGridReady = ({ api }: GridReadyEvent) => {
        setGridApi(api);
        setDataSource(api);
    };
    const renderRightCustomControls = () => {
        return (
            <IconButtonWithTooltip
                title={TranslationHelper.translate('Add report')}
                onClick={() => dispatch(enterCreateMode())}
            >
                <PlusIcon />
            </IconButtonWithTooltip>
        );
    };

    const colDefs = getColDefs(userSettings);

    return (
        <Paper className={classes.gridPane}>
            <div className={classes.wrapper}>
                <PaneHeader
                    title={TranslationHelper.translate('Reports')}
                    renderRightCustomControls={
                        gridApi ? () => renderRightCustomControls() : undefined
                    }
                />
                {dataSourceLoading && <LinearProgress />}

                {enterpriseKey ? (
                    <ServerSideRowModelGrid
                        columnDefs={colDefs}
                        onGridReady={onGridReady}
                        {...serverSideGridProps}
                    />
                ) : (
                    <InfiniteRowModelGrid
                        columnDefs={colDefs}
                        onGridReady={onGridReady}
                        {...gridProps}
                    />
                )}
            </div>
        </Paper>
    );
};

export default ReportsGrid;
