import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';

import {
    IReportSetParams,
    ISourceSet,
    ISourceSetEntity,
    previewTypes,
} from '../types';
import {
    activatePreview,
    activateReportSet,
    queueMapFitToExtent,
    resetLevel,
} from '../ui/discovery/snapshotting';
import preparePreviewAction from './preparePreviewAction';

export const closePreview =
    (
        level: number,
        deselect: boolean = true
    ): ThunkAction<void, null, null, Action<any>> =>
    (dispatch) => {
        dispatch(resetLevel(level));
        if (deselect) {
            dispatch(queueMapFitToExtent());
        }
    };

export default (
        sourceSet: ISourceSet | null,
        data: ISourceSetEntity,
        creatorLevel: number,
        timeLocked: boolean,
        prevSelectedId?: string | null
    ): ThunkAction<void, null, null, Action<any>> =>
    (dispatch) => {
        if (!sourceSet || !data || data.id === prevSelectedId) {
            return;
        }
        if (previewTypes[sourceSet.definitionId]) {
            dispatch(
                activatePreview(
                    sourceSet.definitionId,
                    data.id,
                    previewTypes[sourceSet.definitionId],
                    'preview',
                    {
                        type: 'preview',
                        level: creatorLevel + 1,
                    },
                    undefined,
                    timeLocked,
                    undefined,
                    false
                )
            );
        } else {
            if (
                !sourceSet._meta ||
                !sourceSet._meta.defaultAction ||
                !sourceSet._meta.actions ||
                !data._meta
            ) {
                return;
            }

            const defaultAction = sourceSet._meta.defaultAction;
            const action = sourceSet._meta.actions[defaultAction];
            if (!action) {
                return;
            }
            const params = data?._meta?.actions?.[defaultAction].params;
            const componentName =
                sourceSet._meta.actions &&
                sourceSet._meta.actions[defaultAction] &&
                sourceSet._meta.actions[defaultAction].componentName;
            const icon = data._meta.icon || sourceSet._meta.icon;

            if (defaultAction === 'preview') {
                dispatch(
                    activatePreview(
                        sourceSet.definitionId,
                        data.id,
                        (componentName && previewTypes[componentName]) ||
                            'sourceSetElement',
                        'preview',
                        {
                            type: componentName || 'source-set-element',
                            level: creatorLevel + 1,
                        },
                        preparePreviewAction(
                            componentName,
                            data,
                            action,
                            icon,
                            params,
                            sourceSet.attributes
                        ),
                        timeLocked,
                        undefined,
                        false
                    )
                );
            } else if (defaultAction === 'reportSet' && params) {
                const reportSetParams = params as IReportSetParams;
                dispatch(
                    activateReportSet(
                        reportSetParams.monitoredId.toString(),
                        reportSetParams.from,
                        reportSetParams.to,
                        {
                            type: 'report-set',
                            level: creatorLevel + 1,
                        },
                        reportSetParams.blocked,
                        sourceSet.definitionId !== 'routes',
                        data.id
                    )
                );
            }
        }
    };
