import React, { ChangeEvent, Component } from 'react';

import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';

import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';

import IconButton from '@material-ui/core/IconButton';
import TypeAheadHandler from '../../helpers/TypeAheadHandler';

interface IProps {
    filter: string;
    onFilter: (filter: string) => void;
    onChange?: (filter: string) => void;
    onReset?: () => void;
    omitEmpty?: boolean;
    placeholder: string;
    className?: string;
    shouldFocus?: boolean;
    onRef?: (node: any) => void;
    icon?: React.ComponentType<void>;
    autoFocus?: boolean;
    error?: boolean;
    helperText?: string;
    disabled?: boolean;
}

interface IState {
    filter?: string;
}

class PhraseFilter extends Component<IProps, IState> {
    public state = {
        filter: this.props.filter,
    };

    private typeAheadHandler = new TypeAheadHandler(
        1,
        (filter: string) => {
            if (!this.props.omitEmpty || !!filter) {
                this.props.onFilter(filter);
            } else if (this.props.onReset) {
                this.props.onReset();
            }
        },
        this.props.filter
    );

    private textInput: any;

    public componentDidUpdate(prevProps: IProps) {
        if (!prevProps.shouldFocus && this.props.shouldFocus) {
            this.focusInput();
        }
    }

    public render() {
        const {
            filter,
            disabled,
            helperText,
            autoFocus,
            onChange,
            onRef,
            error,
            icon,
            onReset,
        } = this.props;
        return (
            <TextField
                type="text"
                placeholder={this.props.placeholder}
                onChange={this.handleChange}
                value={onChange ? filter : this.state.filter}
                margin="normal"
                autoFocus={autoFocus}
                error={error}
                helperText={helperText}
                inputRef={(input) => {
                    this.textInput = input;
                    if (onRef) {
                        onRef(input);
                    }
                }}
                fullWidth={true}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <IconButton color="primary" disabled={disabled}>
                                {icon || <SearchIcon />}
                            </IconButton>
                        </InputAdornment>
                    ),
                    endAdornment: onReset && (
                        <InputAdornment position="end">
                            <IconButton
                                color="primary"
                                onClick={this.resetFilter}
                                disabled={disabled}
                            >
                                <ClearIcon />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
                disabled={disabled}
            />
        );
    }

    private focusInput = () => {
        if (this.textInput) {
            this.textInput.focus();
        }
    };

    private resetFilter = () => {
        if (this.props.onReset) {
            this.props.onReset();
        }
        this.setState({ filter: '' });
        this.focusInput();
    };

    private handleChange = ({
        target: { value: filter },
    }: ChangeEvent<HTMLInputElement>) => {
        this.typeAheadHandler.change(filter);
        this.props.onChange
            ? this.props.onChange(filter)
            : this.setState({ filter });
    };
}

export default PhraseFilter;
