import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => {
    const padding = theme.spacing(2);
    return {
        dataBlock: {
            flexGrow: 1,
            overflowY: 'auto',
            marginTop: theme.spacing(3),
        },
        wrapper: {
            paddingTop: padding,
            paddingLeft: padding,
            paddingRight: padding,
        },
        paneContent: {
            padding,
        },
        attachmentsPane: {
            paddingBottom: padding,
        },
        missingDataWrapper: {
            height: '60vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: theme.spacing(),
            margin: theme.spacing(),
        },
        actionsWrapper: {
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: theme.spacing(),
        },
        label: {
            fontSize: '12px',
            fontWeight: 'bold',
            marginTop: padding,
            marginBottom: padding / 2,
        },
        coordinates: {
            marginTop: padding,
            marginBottom: padding,
        },
        plannedOnWrapper: {
            display: 'flex',
            alignItems: 'center',
        },
        listItemHeading: {
            paddingLeft: theme.spacing(2),
            paddingBottom: 0,
            paddingTop: 0,
            color: theme.palette.primary.contrastText,
            backgroundColor: theme.palette.grey[200],
        },
        heading: {
            fontSize: theme.typography.subtitle1.fontSize,
            color: theme.palette.primary.dark,
        },
        linkagesContainer: {
            margin: theme.spacing(),
            marginBottom: '10vh',
        },
        linkage: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
        },
        fieldSm: {
            paddingBottom: theme.spacing(),
            width: '45%',
            marginRight: theme.spacing(),
        },
        fieldMd: {
            paddingBottom: theme.spacing(),
            width: '50%',
        },
    };
});
