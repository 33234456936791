import React from 'react';

import { IconButton } from '@material-ui/core';

import DeleteIcon from '@material-ui/icons/Delete';
import { ICellRendererParams } from 'ag-grid-community';

const DeleteRowRenderer = (props: ICellRendererParams) => {
    const deleteRow = () => {
        const deletedRow = props.node.data;
        props.api.applyTransaction({ remove: [deletedRow] });
        props.api.dispatchEvent({ type: 'rowDeleted' });
    };
    return (
        <IconButton aria-label="delete" size="small" onClick={deleteRow}>
            <DeleteIcon fontSize="inherit" color="error" />
        </IconButton>
    );
};

export default DeleteRowRenderer;
