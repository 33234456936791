import React from 'react';

import { useStyles } from '../../../../Themable.hooks';

import { useMode } from '../../../../../../state/ui/reportsSlice/index.hooks';

import { Paper } from '@material-ui/core';
import SchedulesCreateForm from '../SchedulesCreateForm';
import SchedulesEditForm from '../SchedulesEditForm';

interface IOwnProps {
    handleClose: () => void;
}

const SchedulesPane = ({ handleClose }: IOwnProps) => {
    const classes = useStyles();

    const mode = useMode();
    const renderReportsPane = () => {
        switch (mode) {
            case 'create':
                return <SchedulesCreateForm handleClose={handleClose} />;
            case 'edit':
                return <SchedulesEditForm handleClose={handleClose} />;
            default:
                return;
        }
    };
    return (
        <Paper className={classes.formPane}>
            <div className={classes.wrapper}>{renderReportsPane()}</div>
        </Paper>
    );
};

export default SchedulesPane;
