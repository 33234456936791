import React, { Component } from 'react';

import Translator from '../../helpers/TranslationHelper';

interface IProps {
    id: number;
    tabName: string;
    buttonClass: string;
    handleClick: (tabName: string) => void;
}

class RegistersTab extends Component<IProps> {
    public render() {
        return (
            <li key={this.props.id}>
                <button
                    className={this.props.buttonClass}
                    onClick={this.props.handleClick.bind(
                        this,
                        this.props.tabName
                    )}
                >
                    {Translator.translate(this.props.tabName)}
                </button>
            </li>
        );
    }
}

export default RegistersTab;
