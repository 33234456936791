import React from 'react';

import { Field, Form, Formik } from 'formik';

import { FormControlLabel } from '@material-ui/core';

import { useUserSettings } from '../../../../../../state/user/index.hooks';

import TranslationHelper from '../../../../../../helpers/TranslationHelper';
import TimeFormatter from '../../../../../../helpers/TimeFormatter';

import { useStyles } from '../../../../Themable.hooks';

import FieldWrapper from '../../../../../../components/common/formikWrappers/FieldWrapper';
import DateFieldWrapper from '../../../../../../components/common/formikWrappers/DateFieldWrapper';
import CheckboxWrapper from '../../../../../../components/common/formikWrappers/CheckboxWrapper';

interface IOwnProps {
    initialValues: any;
}
const ContractForm = ({ initialValues }: IOwnProps) => {
    const classes = useStyles();

    const handleValues = () => {};

    const userSettings = useUserSettings();

    const commonProps = {
        fullWidth: true,
        component: FieldWrapper,
        //TODO - for now only preview mode is enabled, so all fields can be disabled - remove later on
        disabled: true,
    };

    return (
        <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            onSubmit={handleValues}
            validateOnBlur={false}
            validateOnChange={false}
            validate={() => ({})}
        >
            {({ errors, values, setFieldValue }) => (
                <>
                    <Form>
                        <div className={classes.formWrapper}>
                            <Field
                                error={!!(errors && errors.name)}
                                helperText={errors && errors.name}
                                name={'name'}
                                label={TranslationHelper.translate('Name')}
                                type={'text'}
                                {...commonProps}
                            />
                            <Field
                                error={!!(errors && errors.startDate)}
                                helperText={errors && errors.startDate}
                                name={'startDate'}
                                label={TranslationHelper.translate(
                                    'Start date'
                                )}
                                displayFormat={userSettings.shortDateFormat}
                                value={values.startDate}
                                withDateFormatter={
                                    TimeFormatter.dateToShortDateString
                                }
                                {...commonProps}
                                component={DateFieldWrapper}
                            />
                            <Field
                                error={!!(errors && errors.stopDate)}
                                helperText={errors && errors.stopDate}
                                name={'stopDate'}
                                label={TranslationHelper.translate('Stop date')}
                                displayFormat={userSettings.shortDateFormat}
                                value={values.stopDate}
                                withDateFormatter={
                                    TimeFormatter.dateToShortDateString
                                }
                                {...commonProps}
                                component={DateFieldWrapper}
                            />
                            <FormControlLabel
                                label={TranslationHelper.translate('Is active')}
                                control={
                                    <Field
                                        name={'isActive'}
                                        component={CheckboxWrapper}
                                        onClick={() =>
                                            setFieldValue(
                                                'isActive',
                                                !values.isActive
                                            )
                                        }
                                    />
                                }
                                disabled={true}
                            />
                        </div>
                    </Form>
                </>
            )}
        </Formik>
    );
};

export default ContractForm;
