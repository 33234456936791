import { createStyles, Theme, WithStyles } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme: Theme) => {
    const padding = theme.spacing();
    return createStyles({
        picker: {
            padding: padding * 2,
        },
        formControl: {
            margin: padding,
        },
        layout: {
            flexGrow: 1,
            display: 'grid',
            gridTemplateColumns: 'auto 400px',
            gridTemplateRows: '1fr 1fr',
            gridColumnGap: padding * 3 + 'px',
            gridRowGap: padding * 3 + 'px',
            boxSizing: 'border-box',
            margin: padding * 3,
        },
        layoutMap: {
            gridTemplateAreas: `
                "map map"
                "map map"
            `,
        },
        layoutMapGridPreview: {
            gridTemplateAreas: `
                "map preview"
                "grid preview"
            `,
        },
        layoutMapPreview: {
            gridTemplateAreas: `
                "map preview"
                "map preview"
            `,
        },
        layoutMapGrid: {
            gridTemplateAreas: `
                "map map"
                "grid grid"
            `,
        },
        layoutFullGrid: {
            gridTemplateAreas: `
                "grid grid"
                "grid grid"
            `,
        },
        mapPane: {
            gridArea: 'map',
            // background: 'red',
            position: 'relative',
        },
        dataGridPane: {
            gridArea: 'grid',
            // background: 'green',
            position: 'relative',
        },
        previewPane: {
            gridArea: 'preview',
            // background: 'blue',
            position: 'relative',
        },
        content: {
            position: 'absolute',
            top: 10,
            left: 10,
            bottom: 10,
            right: 10,
            [theme.breakpoints.down('sm')]: {
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
            },
        },
        mobileNav: {
            position: 'fixed',
            left: 0,
            bottom: 0,
            right: 0,
            height: '56px',
        },
        mobilePreviewPane: {
            [theme.breakpoints.down('md')]: {
                top: -56,
            },
            [`${theme.breakpoints.up('xs')} and (orientation: landscape)`]: {
                top: -48,
            },
            [theme.breakpoints.up('sm')]: {
                top: -64,
            },
        },
    });
};

export type TThemableProps = WithStyles<typeof styles>;

export const Themable = withStyles(styles, { withTheme: true });
