import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        wrapper: {
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 20,
            overflowY: 'auto',
        },
        paneContent: {
            padding: `0px ${theme.spacing(2)}px ${theme.spacing(
                2
            )}px ${theme.spacing(2)}px`,
        },
        field: {
            paddingBottom: theme.spacing(),
        },
        formWrapper: {
            padding: theme.spacing(),
            overflowX: 'hidden',
            overflowY: 'auto',
        },
        icon: {
            display: 'flex',
            color: theme.palette.grey[500],
            marginRight: theme.spacing(1),
            cursor: 'pointer',
            alignItems: 'center',
        },
        flexCentered: {
            display: 'flex',
            alignItems: 'center',
        },
        typography: {
            marginRight: theme.spacing(2),
        },
    })
);
