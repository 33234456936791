import React, { Component, ComponentType } from 'react';
import { compose } from 'redux';

import { default as TasksIcon } from '@material-ui/icons/Assignment';
import { default as PoiIcon } from '@material-ui/icons/PinDrop';
import { default as RoutesIcon } from '@material-ui/icons/Timeline';
import { default as LastStateIcon } from '@material-ui/icons/TrackChanges';

import { Connectable, TConnectableProps } from './Connectable.hoc';

import { Themable, TThemableProps } from './Themable.hoc';

import Badge from '@material-ui/core/Badge';
import TogglerButton from './components/TogglerButton';
import Wrapper from './components/Wrapper';

import { sourceSetIds, TPrimarySourceSetIds } from '../../../../state/types';

import TranslationHelper from '../../../../helpers/TranslationHelper';

import {
    areLastStatesEnabled,
    areLocationsEnabled,
    areRoutesEnabled,
} from '../../../../constants/AppConfig';

import DataSegmentsToggler from '../BookmarksList/DataSegmentsToggler';

interface IOwnProps {
    bottom?: string;
}

type TProps = IOwnProps & TThemableProps & TConnectableProps;

interface IToggleLifecycle {
    onMount?: (props: TProps) => void;
    onDismount?: (props: TProps) => void;
}

interface ITrigger {
    label: string;
    sourceSetId: TPrimarySourceSetIds;
    icon: () => JSX.Element;
    enabled: () => boolean;
    hasBadge?: (props: TProps) => boolean;
    lifecycle?: IToggleLifecycle;
}

class GridTogglers extends Component<TProps> {
    public gridTriggers: ITrigger[] = [
        {
            label: 'Last states',
            sourceSetId: sourceSetIds.lastStates,
            icon: () => <LastStateIcon />,
            enabled: () => areLastStatesEnabled(),
        },
        {
            label: 'Routes',
            sourceSetId: sourceSetIds.routes,
            icon: () => <RoutesIcon />,
            enabled: () => areRoutesEnabled() && this.props.privileges.routes,
        },
        {
            label: 'Tasks',
            sourceSetId: sourceSetIds.tasks,
            icon: () => <TasksIcon />,
            enabled: () => this.props.privileges.tasks,
            hasBadge: (props: TProps) => props.areTasksPresent,
            lifecycle: (() => {
                let task: number;
                return {
                    onMount: (props: TProps) => {
                        props.areNotAssignedTasksPresent();
                        task = window.setInterval(() => {
                            props.areNotAssignedTasksPresent();
                        }, 30000);
                    },
                    onDismount: () => {
                        if (task) {
                            clearInterval(task);
                        }
                    },
                };
            })(),
        },
        {
            label: 'POI',
            sourceSetId: sourceSetIds.locations,
            icon: () => <PoiIcon />,
            enabled: () =>
                areLocationsEnabled() && this.props.privileges.locations,
        },
    ];

    public componentDidMount() {
        this.gridTriggers.forEach((trigger) =>
            trigger.lifecycle?.onMount?.(this.props)
        );
    }

    public componentWillUnmount() {
        this.gridTriggers.forEach((trigger) =>
            trigger.lifecycle?.onDismount?.(this.props)
        );
    }

    public handleOpenGrid = (id: string) => {
        if (
            this.props.gridSnapshot?.sourceSetId === id &&
            this.props.isDataGridPaneVisible
        ) {
            return;
        }

        this.props.activateGrid(id, {
            type: 'category',
            level: 0,
        });
    };

    public render() {
        const { bottom, classes, editMode } = this.props;

        return (
            <Wrapper style={{ bottom }}>
                {this.gridTriggers
                    .filter((t) => t.enabled())
                    .map((gridTrigger, index) => (
                        <Badge
                            key={index}
                            invisible={
                                !gridTrigger.hasBadge ||
                                !gridTrigger.hasBadge(this.props)
                            }
                            classes={{ badge: classes.badge }}
                            badgeContent=" "
                            color="error"
                        >
                            <TogglerButton
                                handleClick={() =>
                                    this.handleOpenGrid(gridTrigger.sourceSetId)
                                }
                                disabled={editMode}
                            >
                                {gridTrigger.icon()}
                                &nbsp;&nbsp;
                                {TranslationHelper.translate(gridTrigger.label)}
                            </TogglerButton>
                        </Badge>
                    ))}
                {this.props.privileges.bookmarks && <DataSegmentsToggler />}
            </Wrapper>
        );
    }
}

export default compose(
    Themable,
    Connectable
)(GridTogglers) as ComponentType<IOwnProps>;
