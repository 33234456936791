import {
    createAsyncThunk,
    createSelector,
    createSlice,
} from '@reduxjs/toolkit';

import { chunk } from 'lodash';

import { TRootState } from '../../../../store';
import {
    IFetchRoutes,
    restGetRoute,
    restGetRoutes,
    restPatchRoute,
    restPostRoute,
    restPutRoute,
} from '../../../../services/planning';
import { ISourceSet, TStatus } from '../../../../state/types';
import { removeDuplicatesById } from '../../../../helpers/removeDuplicatesById';
import { getSourceSetModels } from '../../devices';
import { getMappedDictionaries } from '../../../../state/app/dictionaries';
import TranslationHelper from '../../../../helpers/TranslationHelper';
import { ROUTE_STATUS } from '../../../../constants/dictionaries/RouteStatus.';
import moment from 'moment';
import { getOrCreatePool } from '../../../../helpers/createPool';
import { IColor } from '../../../../helpers/ColorPool';

const MAX_ITEM_LIMIT = 300;

export const UNASSIGNED_ID = -1;

type IPostRouteBase = Pick<IRoute, 'name' | 'plannedOn' | 'activityCategoryId'>;

export interface IPostRoute extends IPostRouteBase {
    employeeId: number;
    vehicleId: number;
}

export interface IPatchRoute extends Pick<IRoute, 'name'> {
    employeeId: number;
    vehicleId: number;
}

export interface ISelectedRoute {
    id: number;
    sourceSetId: number;
    name: string;
}

export interface ISelectedRouteForTask {
    id: number;
    sourceSetId: number;
    name: string;
    employee?: { id: number; name: string };
    vehicle?: { id: number; name: string };
}

export interface IRoute {
    id: number;
    name: string;
    activityCategoryId: number;
    plannedOn?: string;
    progress?: number;
    status: number;
    realStart?: string;
    realStop?: string;
    employee?: { id: number; name: string };
    vehicle?: { id: number; name: string };
    nodePath: string;
}

interface IState {
    routes: IRoute[] | null;
    mode: null | 'preview' | 'create' | 'edit';
    selectedRouteId: number | null;
    selectedRoute: { status: TStatus; data: IRoute | null };
    selectedRoutes: ISelectedRoute[];
    status: TStatus;
    dates: { from: string; to: string };
    activityCategory: number | '';
}

export const initialState: IState = {
    routes: null,
    mode: null,
    selectedRouteId: null,
    selectedRoute: {
        status: 'idle',
        data: null,
    },
    selectedRoutes: [],
    status: 'idle',
    dates: {
        from: moment(new Date()).format('YYYY-MM-DD'),
        to: moment(new Date()).format('YYYY-MM-DD'),
    },
    activityCategory: '',
};

export const fetchRoutes = createAsyncThunk(
    'get:ui/planning/routes-management/routes',
    async (params: IFetchRoutes) => {
        const response = await restGetRoutes(params);

        return response;
    }
);

export const fetchRoute = createAsyncThunk(
    'get:ui/planning/routes-management/routes/:id',
    async (id: number) => {
        const response = await restGetRoute(id);

        return response;
    }
);
export const createRoute = createAsyncThunk(
    'post:ui/planning/routes-management/routes',
    async (route: IPostRoute) => {
        const response = await restPostRoute(route);

        return response;
    }
);

export const updateRoute = createAsyncThunk(
    'put:ui/planning/routes-management/routes/{routeId}',
    async (params: { id: number; route: IPatchRoute }) => {
        const response = await restPutRoute(params.id, params.route);

        return response;
    }
);

const processChunk = async (
    routeId: number | undefined,
    ids: number[],
    fetchCall: (
        ids: number[],
        routeId: number | undefined
    ) => Promise<{ id: string }>
) => {
    const tasksChunk = chunk(ids, MAX_ITEM_LIMIT);
    for (let i = 0; i < tasksChunk.length; i++) {
        await fetchCall(tasksChunk[i], routeId);
    }

    return;
};

const handlePatchRouteTasks = async (
    tasksIds: number[],
    routeId: number | undefined
): Promise<{ id: string }> => {
    const tasksIdsChunks = Math.floor(tasksIds.length / MAX_ITEM_LIMIT);
    if (tasksIdsChunks >= 1) {
        await processChunk(routeId, tasksIds, restPatchRoute);
        return Promise.resolve({ id: 'chunked' });
    }
    const response = await restPatchRoute(tasksIds, routeId);
    return response;
};

export const reassignTasks = createAsyncThunk(
    'patch:ui/planning/routes-management/routes',
    async (
        params: { taskIds: number[]; targetRouteId?: number },
        { rejectWithValue }
    ) => {
        try {
            const response = await handlePatchRouteTasks(
                params.taskIds,
                params.targetRouteId
            );
            return response;
        } catch (e: any) {
            throw rejectWithValue({
                status: e.status,
                errorMsg: e.responseJSON?.message,
            });
        }
    }
);

const routes = createSlice({
    name: 'routes',
    initialState,
    reducers: {
        enterRoutePreviewMode(state, action) {
            state.mode = 'preview';
            state.selectedRouteId = action.payload;
        },
        enterRouteCreateMode(state) {
            state.mode = 'create';
        },
        enterRouteEditMode(state) {
            state.mode = 'edit';
        },
        resetRouteMode(state) {
            state.mode = null;
        },
        selectRoutes(state, action) {
            state.selectedRoutes = action.payload;
        },
        selectActivityCategory(state, action) {
            state.activityCategory = action.payload;
        },
        selectDates(state, action) {
            state.dates = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchRoutes.pending, (state) => {
            state.selectedRoutes = [];
            state.status = 'loading';
        });
        builder.addCase(fetchRoutes.rejected, (state) => {
            state.status = 'error';
        });
        builder.addCase(fetchRoutes.fulfilled, (state, action) => {
            state.status = 'done';
            state.routes = action.payload;
        });
        builder.addCase(fetchRoute.pending, (state) => {
            state.selectedRoute.status = 'loading';
        });
        builder.addCase(fetchRoute.rejected, (state) => {
            state.selectedRoute.status = 'error';
        });
        builder.addCase(fetchRoute.fulfilled, (state, action) => {
            state.selectedRoute.status = 'done';
            state.selectedRoute.data = action.payload;
        });
    },
});

const getCurrent = (state: TRootState) => state.ui.planning.routes as IState;

export const getRoutesPaneMode = (state: TRootState) => getCurrent(state).mode;
export const getRoutes = (state: TRootState) => getCurrent(state).routes;
export const getRoutesStatus = (state: TRootState) => getCurrent(state).status;
export const getSelectedRouteId = (state: TRootState) =>
    getCurrent(state).selectedRouteId;
export const getSelectedRoute = (state: TRootState) =>
    getCurrent(state).selectedRoute;
export const getSelectedRoutes = (state: TRootState) =>
    getCurrent(state).selectedRoutes;

export const getSelectedRoutesColors = createSelector(
    [getSelectedRoutes],
    (routes) => {
        const pool = getOrCreatePool('planning');
        const selectedAttributes: string[] = routes.map((route) =>
            String(route.id)
        );
        pool.releaseColors(selectedAttributes);
        return selectedAttributes.reduce(
            (assignedColors: { [key: string]: IColor }, id: string) => {
                assignedColors[id] = pool.assignColor(id);
                return assignedColors;
            },
            {}
        );
    }
);

export const getSelectedCategory = (state: TRootState) =>
    getCurrent(state).activityCategory;
export const getSelectedDates = (state: TRootState) => getCurrent(state).dates;

export const getRoutesSourceSetModel = createSelector(
    [getSourceSetModels],
    (models) => models.planningRoutes || null
);
export const getRoutesAsObject = createSelector(
    [getRoutes],
    (routes: IRoute[] | null) => {
        if (!routes) {
            return {};
        }

        return routes.reduce<Record<string, IRoute>>((acc, route) => {
            const id = route?.id;
            if (id) {
                acc[id] = route;
            }
            return acc;
        }, {});
    }
);

export const isRoutesPaneVisible = createSelector(
    [getRoutesPaneMode],
    (mode) => !!mode
);
export const isRoutesPaneInEditMode = createSelector(
    [getRoutesPaneMode],
    (mode) => mode && mode !== 'preview'
);
export const getRoutesAsSouceSet = createSelector(
    [getRoutes, getMappedDictionaries],
    (routes, dictionaries): ISourceSet | null =>
        routes && {
            id: 'planningRoutes',
            definitionId: 'planningRoutes',
            label: 'Routes',
            attributes: [
                {
                    id: 'placeholder',
                    label: '',
                    type: 'text',
                },
                {
                    id: 'actions',
                    label: 'Actions',
                    type: 'actions',
                },
                {
                    id: 'name',
                    label: 'Name',
                    type: 'text',
                },
                { id: 'vehicle', label: 'Vehicle name', type: 'text' },
                { id: 'employee', label: 'Employee name', type: 'text' },
                {
                    id: 'plannedOn',
                    label: 'Planned on',
                    type: 'date',
                    formatting: { pattern: 'short' },
                },
                {
                    id: 'realStart',
                    label: 'Real start date',
                    type: 'date',
                },
                {
                    id: 'realStop',
                    label: 'Real stop date',
                    type: 'date',
                },
                {
                    id: 'status',
                    label: 'Status',
                    type: 'text',
                    translate: 'wx',
                },
                {
                    id: 'progress',
                    label: 'Progress',
                    type: 'bar',
                },
                {
                    id: 'activityCategoryId',
                    label: 'Type 1',
                    type: 'text',
                },
                {
                    id: 'nodePath',
                    label: 'Node path',
                    type: 'text',
                },
            ],
            layersAttributes: [],
            _meta: {
                actions: {
                    delete: {
                        label: 'Delete',
                        method: 'DELETE',
                        api: '/rest/api/routes-management/routes/{id}',
                        params: {},
                    },
                },
            },
            entities: removeDuplicatesById([
                {
                    id: UNASSIGNED_ID,
                    name: TranslationHelper.translate('Unassigned'),
                },
                ...routes
                    .map((route: IRoute): any => {
                        return {
                            ...route,
                            actions: ['delete', 'preview'],
                            activityCategoryId:
                                dictionaries['activity-category']?.[
                                    route.activityCategoryId
                                ]?.name,
                            vehicle: route.vehicle?.name,
                            employee: route.employee?.name,
                            status: TranslationHelper.translate(
                                ROUTE_STATUS[route.status]?.name
                            ),
                            _meta: {
                                actions: {
                                    delete: {
                                        params: {
                                            id: route.id,
                                        },
                                    },
                                    preview: {
                                        params: {
                                            id: route.id,
                                        },
                                    },
                                },
                            },
                        };
                    })

                    .flat(),
            ]),
        }
);
export const getRoutesAsSourceSetForTask = createSelector(
    [getRoutes, getMappedDictionaries],
    (routes, dictionaries): ISourceSet | null =>
        routes && {
            id: 'planningRoutes',
            definitionId: 'planningRoutes',
            label: 'Routes',
            attributes: [
                {
                    id: 'placeholder',
                    label: '',
                    type: 'text',
                },
                {
                    id: 'name',
                    label: 'Name',
                    type: 'text',
                },
                { id: 'vehicleName', label: 'Vehicle name', type: 'text' },
                { id: 'employeeName', label: 'Employee name', type: 'text' },
                {
                    id: 'plannedOn',
                    label: 'Planned on',
                    type: 'date',
                    formatting: { pattern: 'short' },
                },
                {
                    id: 'realStart',
                    label: 'Real start date',
                    type: 'date',
                },
                {
                    id: 'realStop',
                    label: 'Real stop date',
                    type: 'date',
                },
                {
                    id: 'status',
                    label: 'Status',
                    type: 'text',
                    translate: 'wx',
                },
                {
                    id: 'progress',
                    label: 'Progress',
                    type: 'bar',
                },
                {
                    id: 'activityCategoryId',
                    label: 'Type 1',
                    type: 'text',
                },
            ],
            layersAttributes: [],
            _meta: {},
            entities: removeDuplicatesById([
                ...routes
                    .map((route: IRoute): any => {
                        return {
                            ...route,
                            activityCategoryId:
                                dictionaries['activity-category']?.[
                                    route.activityCategoryId
                                ]?.name,
                            vehicle: route.vehicle,
                            vehicleName: route.vehicle?.name,
                            employee: route.employee,
                            employeeName: route.employee?.name,
                            status: TranslationHelper.translate(
                                ROUTE_STATUS[route.status]?.name
                            ),
                        };
                    })
                    .flat(),
            ]),
        }
);

export const {
    enterRoutePreviewMode,
    enterRouteCreateMode,
    enterRouteEditMode,
    resetRouteMode,
    selectRoutes,
    selectActivityCategory,
    selectDates,
} = routes.actions;

export default routes.reducer;
