import React from 'react';

import { ListItem } from '@material-ui/core';

import { CollapseListItem } from '../CollapseListItem/CollapseListItem';
import { IExternalSection } from '../../state/types';
import useExternalProviderFetch from '../../hooks/useExternalProviderFetch';
import { useStyles } from './Themable.hooks';
import { useCustomer } from '../../state/login/index.hooks';

interface IOwnProps {
    section: IExternalSection;
    dataObject: any;
    open: boolean;
    toggleCollapse: (title: string) => void;
}

const ExternalProviderSection = ({
    section,
    dataObject,
    open,
    toggleCollapse,
}: IOwnProps) => {
    const customerCode = useCustomer();

    const classes = useStyles();
    const { state } = useExternalProviderFetch({
        sectionId: section.id,
        data: dataObject,
        customerCode,
    });

    const handleToggleCollapse = () => {
        toggleCollapse(section.id);
    };

    return (
        <CollapseListItem
            title={section.title}
            openSection={open}
            handleToggleCollapse={handleToggleCollapse}
            fetchState={state}
        >
            <>
                {state.type === 'done' ? (
                    state.data.map((item) => {
                        return (
                            <ListItem
                                key={item.label}
                                className={classes.listItem}
                            >
                                <div className={classes.itemLabel}>
                                    {item.label}
                                </div>
                                <span className={classes.secondaryAction}>
                                    {item.value}
                                </span>
                            </ListItem>
                        );
                    })
                ) : (
                    <></>
                )}
            </>
        </CollapseListItem>
    );
};

export default ExternalProviderSection;
