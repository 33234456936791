import { createStyles, Theme, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => {
    const spacing = theme.spacing();

    return createStyles({
        photoOverlayButton: {
            color: '#fff',
            backgroundColor: 'rgba(0, 0, 0, .2)',
            position: 'absolute',
            top: spacing,
            right: spacing,
            zIndex: 10,

            '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, .25)',
            },
        },

        settingsContainer: {
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            padding: spacing,
            zIndex: 10,
            backgroundColor: 'rgba(0, 0, 0, .75)',
            color: '#fff',
        },

        settingsButton: {
            color: '#fff',
            position: 'absolute',
            top: spacing,
            right: spacing,
            display: 'block',
        },

        settings: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'start',
            height: '100%',
            padding: spacing,
        },

        button: {
            color: '#fff',
        },
        buttonPlay: {
            color: '#fff',
        },
        downloadCircularProgress: {
            color: '#fff',
        },

        sliderContainer: {
            display: 'flex',
            alignItems: 'center',
        },

        actionsContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: '5vh',
        },

        sliderMark: {
            width: '12px',
            height: '12px',
            backgroundColor: '#8c8c8c',
            borderRadius: '50%',
            margin: '-5px',
            opacity: 1,
        },

        sliderMarkLabel: {
            color: '#fff',
            whiteSpace: 'normal',
            textAlign: 'center',
        },

        slider: {
            marginTop: '25px',
        },

        sliderRail: {
            backgroundColor: 'rgba(255, 255, 255, .2)',
        },

        sliderTrack: {
            backgroundColor: 'rgb(255, 255, 255)',
        },

        sliderThumb: {
            backgroundColor: 'rgb(255, 255, 255)',
            '&:hover': {
                boxShadow: '0 0 0 8px rgba(255, 255, 255, 0.16)',
            },
        },

        sliderValueLabel: {
            color: 'rgba(0, 0, 0, 0)',
            top: '-25px',
        },

        switchTrack: {
            backgroundColor: 'rgb(255, 255, 255)',
        },
        switchBase: {
            color: theme.palette.grey[500],
        },
        switchColorPrimary: {
            '&.Mui-checked': {
                color: 'rgba(255, 255, 255)',
            },
            '&.Mui-checked + .MuiSwitch-track': {
                backgroundColor: 'rgb(255, 255, 255)',
            },
        },

        selected: {
            fontWeight: 'bold',
            border: '1px solid white',
            color: theme.palette.grey[100],
            padding: '4px',

            '&.Mui-selected': {
                color: 'white',
                backgroundColor: theme.palette.grey[500],
            },
            '&.Mui-selected:hover': {
                backgroundColor: theme.palette.grey[700],
            },
            '&:hover': {
                backgroundColor: theme.palette.grey[700],
            },
        },
        toggleButtons: {
            color: '#fff',
        },
    });
});
