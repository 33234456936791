import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => {
    return createStyles({
        containedSecondary: {
            backgroundColor: theme.palette.error.dark,
        },
        actions: {
            justifyContent: 'space-between',
        },
        listItem: {
            fontSize: theme.typography.caption.fontSize,
            justifyContent: 'space-between',
            borderBottom: '1px solid ' + theme.palette.divider,
            color: theme.palette.primary.dark,
        },
        itemLabel: {
            fontSize: theme.typography.caption.fontSize,
            paddingRight: theme.spacing() * 2,
            color: theme.palette.common.black,
            wordWrap: 'break-word',
            width: '90%',
        },
        typography: {
            margin: `${theme.spacing()}px 0px`,
        },
    });
});
