/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { connect } from 'react-redux';

import LoadingSpinner from '../loadingSpinner/LoadingSpinner';
import PrimaryButton from '../button/PrimaryButton';
import SecondaryButton from '../button/SecondaryButton';
import Translator from '../../helpers/TranslationHelper';

import { fetchUserData, resendUserEmail } from '../../state/user';
import { showUserProfileDialog } from '../../state/ui/dialog';
import { getXssAddress, loginXssTickets } from '../../state/login';

class ServiceCenter extends Component {
    constructor(props) {
        super(props);

        this._onEditProfile = this._onEditProfile.bind(this);
        this._onTryAgain = this._onTryAgain.bind(this);
        this._onResendEmail = this._onResendEmail.bind(this);
        this._onLoginToXss = this._onLoginToXss.bind(this);

        this.state = this._getInitialState();
    }

    _getInitialState() {
        return {
            userEmail: this.props.userSettings.userEmail,
            userEmailVerified: this.props.userSettings.userEmailVerified,
            customerNumber: this.props.userSettings.customerNumber,
            loading: false,
            loginError: false,
        };
    }

    componentDidMount() {
        if (!this.state.customerNumber || !this.state.userEmail) {
            this._getUserData();
        } else if (!this.props.xssAddress) {
            this._loginToXss(this.state.userEmail);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.userEmail) {
            if (prevState.userEmail !== this.state.userEmail) {
                this._loginToXss(this.state.userEmail);
            } else if (
                this.state.userEmailVerified &&
                !prevState.userEmailVerified
            ) {
                this._loginToXss(this.state.userEmail);
            }
        }
    }

    _onEditProfile() {
        this.props.showUserProfileDialog();
    }

    _onTryAgain() {
        this.setState({ loading: true, userEmail: undefined });
        this._getUserData();
    }

    _onResendEmail() {
        this.props.resendUserEmail();
    }

    _onLoginToXss() {
        this._loginToXss(this.state.userEmail);
    }

    _getIFrame(iframe) {
        return (
            <div className="full-page-iframe">
                <iframe
                    src={this.props.xssAddress}
                    frameBorder="0"
                    width="100%"
                    height="100%"
                />
            </div>
        );
    }

    _loginToXss(email) {
        this.setState({ loginError: false });
        this.props.loginXssTickets(
            email,
            (data) => {
                if (!data) {
                    this.setState({ loading: false });
                } else {
                    this.setState({ loading: false, userEmailVerified: false });
                }
            },
            () => this.setState({ loading: false, loginError: true })
        );
    }

    _getUserData() {
        this.props.fetchUserData((data) => {
            if (!data.emailVerified) {
                this.setState({ loading: false, userEmail: data.email });
            }
        });
    }

    _renderCustomerNumberMessage() {
        return (
            <div className="info-message">
                <div className="row icon icon-info" />
                <label className="row">
                    {Translator.translate('Missing customer number for XSS')}
                </label>
                <div className="row">
                    <PrimaryButton
                        onClick={this._onTryAgain}
                        icon="icon-reset"
                        label={Translator.translate('Refresh')}
                    />
                </div>
            </div>
        );
    }

    _renderXssLoginError() {
        return (
            <div className="info-message">
                <div className="row icon icon-info" />
                <label className="row">
                    {Translator.translate(
                        'There was an unexpected error while loading XSS'
                    )}
                </label>
                <div className="row">
                    <PrimaryButton
                        onClick={this._onLoginToXss}
                        icon="icon-reset"
                        label={Translator.translate('Refresh')}
                    />
                </div>
            </div>
        );
    }

    _renderEmailVerifiedMessage() {
        return (
            <div className="info-message">
                <div className="row icon icon-info" />
                <label className="row">
                    {Translator.translate('User email has not been verified')}
                </label>
                <label className="row">
                    {Translator.translate(
                        'Please check an email box for a verification email'
                    )}
                </label>
                <div className="row">
                    <PrimaryButton
                        onClick={this._onTryAgain}
                        icon="icon-reset"
                        label={Translator.translate('Refresh')}
                    />
                    <SecondaryButton
                        onClick={this._onResendEmail}
                        icon="icon-email"
                        label={Translator.translate('Resend email')}
                    />
                </div>
            </div>
        );
    }

    _renderEmailMessage() {
        return (
            <div className="info-message">
                <div className="row icon icon-info" />
                <label className="row">
                    {Translator.translate('Missing email for XSS')}
                </label>
                <label className="row">
                    {Translator.translate(
                        "Please provide an additional information in user's profile"
                    )}
                </label>
                <div className="row">
                    <PrimaryButton
                        onClick={this._onEditProfile}
                        icon="icon-user"
                        label={Translator.translate('Edit profile')}
                    />
                </div>
            </div>
        );
    }

    render() {
        let component = null;
        if (this.state.loginError) {
            component = this._renderXssLoginError();
        } else if (this.props.xssAddress) {
            component = this._getIFrame();
        } else if (this.state.loading) {
            component = (
                <div className="spinner">
                    <LoadingSpinner
                        size="50"
                        top="0"
                        left="0"
                        right="0"
                        bottom="0"
                    />
                </div>
            );
        } else if (!this.state.customerNumber) {
            component = this._renderCustomerNumberMessage();
        } else if (!this.state.userEmail) {
            component = this._renderEmailMessage();
        } else {
            component = this._renderEmailVerifiedMessage();
        }
        return <div className="service-center">{component}</div>;
    }
}

const mapStateToProps = (state) => ({
    userSettings: state.user,
    xssAddress: getXssAddress(state),
});

const mapDispatchToProps = {
    fetchUserData,
    resendUserEmail,
    loginXssTickets,
    showUserProfileDialog,
};

export default connect(mapStateToProps, mapDispatchToProps)(ServiceCenter);
