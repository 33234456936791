import React, { useState } from 'react';

import { FormikErrors } from 'formik';

import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

import { useStyles } from './Themable.hooks';

import Translator from '../../../helpers/TranslationHelper';

import { ListboxComponent } from './ListboxComponent';

interface IInputTexts {
    placeholder: string;
    clear?: string;
    noOptions?: string;
    loading?: string;
}

interface IOptions {
    value?: string;
    title?: string;
    id?: string | number;
}

interface IOwnProps<T extends IOptions[]> {
    value: IOptions[];
    inputTexts?: IInputTexts;
    multi: boolean;
    disabled?: boolean;
    onChange: (
        e: React.ChangeEvent<{}>,
        value: IOptions | IOptions[] | null
    ) => void;
    onOpen: () => Promise<T>;
    optionKeys: { name: string; value: string };
    label?: string;
    required?: boolean;
    error?: string | FormikErrors<T>;
}

const CustomAutocomplete = <T extends IOptions[]>(props: IOwnProps<T>) => {
    const [options, setOptions] = useState<IOptions[]>([]);
    const [loading, setLoading] = useState(false);
    const classes = useStyles();
    const {
        error,
        inputTexts,
        multi,
        label,
        onChange,
        onOpen,
        optionKeys,
        required,
        value,
        disabled,
        ...rest
    } = props;

    const handleOnOpen = () => {
        setLoading(true);
        onOpen().then((options) => {
            setOptions(options);
            setLoading(false);
        });
    };

    const handleRequiredProp = () => {
        return multi ? value.length === 0 : required;
    };

    return (
        <Autocomplete
            getOptionSelected={(option, value) =>
                option[optionKeys.value] === value[optionKeys.value]
            }
            getOptionLabel={(option) => option[optionKeys.name]}
            noOptionsText={Translator.translate(
                inputTexts?.noOptions || 'No results ...'
            )}
            loadingText={Translator.translate(
                inputTexts?.loading || 'Loading...'
            )}
            onChange={onChange}
            options={options}
            loading={loading}
            onOpen={handleOnOpen}
            size="small"
            classes={{ tag: classes.tag, root: classes.root }}
            clearText={Translator.translate(inputTexts?.clear || 'Clear all')}
            ListboxComponent={
                ListboxComponent as React.ComponentType<
                    React.HTMLAttributes<HTMLElement>
                >
            }
            fullWidth
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={label || ''}
                    placeholder={Translator.translate(
                        inputTexts?.placeholder || ''
                    )}
                    error={!!error}
                    helperText={error}
                    InputLabelProps={{
                        required: required,
                    }}
                    inputProps={{
                        ...params.inputProps,
                        required: required && handleRequiredProp(),
                    }}
                />
            )}
            {...rest}
            value={value}
            multiple={multi}
            disabled={disabled}
        />
    );
};

export default CustomAutocomplete;
