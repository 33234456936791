import React, { Component, ComponentType } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { Themable, TThemableProps } from './Themable.hoc';

import { IconButton, Tooltip } from '@material-ui/core';

import SettingsIcon from '@material-ui/icons/Settings';

import TranslationHelper from '../../helpers/TranslationHelper';

import SettingsDialog from './components/SettingsDialog';

import { TSourceSetAction } from '../../state/types';
import { ISourceSetAction } from '../../state/ui/discovery/types';
import { IFormValues } from './components/SettingsDialog/types';

interface IState {
    isDialogOpen: boolean;
}

interface IOwnProps {
    configureAction: TSourceSetAction;
    afterAccept: (action: ISourceSetAction) => void;
}

type TProps = IOwnProps & TThemableProps;

class GridSettings extends Component<TProps, IState> {
    public state: IState = {
        isDialogOpen: false,
    };

    public render() {
        const { classes, configureAction } = this.props;

        return (
            <>
                <Tooltip
                    title={TranslationHelper.translate(configureAction.label)}
                >
                    <IconButton
                        className={classes.label}
                        onClick={this.toggleDialog}
                    >
                        <SettingsIcon />
                    </IconButton>
                </Tooltip>

                <SettingsDialog
                    isOpen={this.state.isDialogOpen}
                    confirm={this.confirmSettings}
                    close={this.toggleDialog}
                    title={configureAction.label}
                    options={configureAction.options || []}
                />
            </>
        );
    }

    private toggleDialog = () => {
        this.setState({ isDialogOpen: !this.state.isDialogOpen });
    };

    private confirmSettings = (options: IFormValues) => {
        const { api, method, label, params } = this.props.configureAction;
        this.toggleDialog();
        this.props.afterAccept({
            api,
            method,
            label,
            params: { ...params, ...options },
        });
    };
}

export default compose(
    Themable,
    connect()
)(GridSettings) as ComponentType<IOwnProps>;
