import React, { FocusEvent, useEffect } from 'react';
import { Field, FormikValues, FormikErrors } from 'formik';
import TranslationHelper from '../../../../helpers/TranslationHelper';
import ChipsFieldWrapper from '../../../../components/common/formikWrappers/ChipsFieldWrapper';
import FieldWrapper from '../../../../components/common/formikWrappers/FieldWrapper';
import { IAlertForm } from '../../../../state/ui/forms';
import { mapOptions, trimValue } from '../../_utils/utils';
import { AccessibleField } from '../../../../components/accessControl/components';
import { IPrivileges } from '../../../../state/auth';
import {
    preparePhoneNumber,
    validateEmail,
    validatePhoneNumber,
} from '../../../../helpers/validationHelpers';
import CustomAutocomplete from '../../../../components/common/CustomAutocomplete/CustomAutocomplete';
import { restGetProviders } from '../../../../services/alerts';
import { restGetRegistersMonitoredObjects } from '../../../../services/registers';
import { useProviders } from '../../Connectable.hooks';
import { useStyles } from '../FormsThemable.hooks';
import { MenuItem } from '@material-ui/core';
import GroupsSection from '../../../../components/GroupsSection';
import { INITIAL_CONDITION } from '../../_utils/prepareFormData';

interface IOwnProps {
    values: FormikValues;
    errors: FormikErrors<IAlertForm>;
    setFieldValue: (name: string, value: any) => void;
    setupFieldClass: string;
    languages?: string[];
    inEditMode: boolean;
    displaySubjectFields: boolean;
}

const ALERT_SUBJECTS = [
    { id: 'object', name: 'Object' },
    { id: 'group', name: 'Group' },
];
const renderAlertSubjects = () => {
    return ALERT_SUBJECTS.map((subject) => (
        <MenuItem key={subject.id} value={subject.id}>
            {TranslationHelper.translate(subject.name)}
        </MenuItem>
    ));
};

export const AlertBaseInfo: React.FC<IOwnProps> = ({
    errors,
    setFieldValue,
    setupFieldClass,
    languages,
    values,
    inEditMode,
    displaySubjectFields,
}) => {
    const providers = useProviders();
    const classes = useStyles({ displaySubjectFields });

    useEffect(() => {
        if (displaySubjectFields && !values.alertSubject) {
            setFieldValue('alertSubject', 'object');
        }
    }, []);
    const recipients =
        errors &&
        (errors.recipients as unknown as {
            emails: [];
            phones: [];
            providers: [];
            language: string;
        });
    const alertSubject = values?.alertSubject;

    return (
        <div className={classes.formContainer}>
            <div className={classes.forumColumn}>
                <Field
                    className={setupFieldClass}
                    error={!!(errors && errors.name)}
                    helperText={errors && errors.name}
                    name={'name'}
                    label={TranslationHelper.translate('Name')}
                    autoComplete={'alert-name'}
                    type={'text'}
                    component={FieldWrapper}
                    required={true}
                    fullWidth={true}
                    onBlur={(event: FocusEvent<HTMLInputElement>) =>
                        trimValue(event, setFieldValue)
                    }
                />
                <Field
                    className={setupFieldClass}
                    error={!!(errors && recipients?.language)}
                    helperText={errors && recipients?.language}
                    name={'recipients.language'}
                    label={TranslationHelper.translate('Notification language')}
                    select={true}
                    component={FieldWrapper}
                    required={true}
                >
                    {mapOptions(languages, 'lang')}
                </Field>
                <Field
                    className={setupFieldClass}
                    error={
                        !!((errors && recipients?.emails) || errors.emailsField)
                    }
                    helperText={
                        (errors && recipients?.emails) || errors.emailsField
                    }
                    inputController={'emailsField'}
                    name={'recipients.emails'}
                    label={TranslationHelper.translate('E-mail addresses')}
                    type={'email'}
                    validateInput={validateEmail}
                    component={ChipsFieldWrapper}
                />
                {providers.length > 0 && (
                    <CustomAutocomplete
                        value={values.recipients.providers || []}
                        inputTexts={{
                            placeholder: 'Select providers',
                        }}
                        label={TranslationHelper.translate('Providers')}
                        multi
                        onChange={(e, value) => {
                            setFieldValue('recipients.providers', value);
                        }}
                        onOpen={restGetProviders}
                        optionKeys={{
                            name: 'name',
                            value: 'id',
                        }}
                    />
                )}
                <AccessibleField
                    check={(privileges: IPrivileges) =>
                        privileges.alertSettingSms
                    }
                    className={setupFieldClass}
                    error={
                        !!((errors && recipients?.phones) || errors.phonesField)
                    }
                    helperText={
                        (errors && recipients?.phones) || errors.phonesField
                    }
                    inputController={'phonesField'}
                    name={'recipients.phones'}
                    label={TranslationHelper.translate('Phone numbers')}
                    type={'text'}
                    validateInput={validatePhoneNumber}
                    formatValue={preparePhoneNumber}
                    component={ChipsFieldWrapper}
                />
            </div>
            {displaySubjectFields && (
                <div className={classes.forumColumn}>
                    <Field
                        className={setupFieldClass}
                        name={'alertSubject'}
                        label={TranslationHelper.translate('Alert subject')}
                        select={true}
                        component={FieldWrapper}
                        required={true}
                        disabled={inEditMode}
                        afterOnChange={() => {
                            //reset conditions and object when we change alert subject
                            setFieldValue('object', undefined);
                            setFieldValue('condition', INITIAL_CONDITION);
                        }}
                    >
                        {renderAlertSubjects()}
                    </Field>
                    {alertSubject === 'group' && (
                        <GroupsSection
                            selectedGroups={values.groups || []}
                            handleFormChange={(_, value) => {
                                setFieldValue('groups', value);
                            }}
                            errors={errors.groups}
                        />
                    )}
                    {alertSubject === 'object' && (
                        <CustomAutocomplete
                            value={values.object}
                            inputTexts={{
                                placeholder:
                                    TranslationHelper.translate(
                                        'Select object'
                                    ),
                            }}
                            label={TranslationHelper.translate('Object')}
                            onChange={(e, value) => {
                                setFieldValue('object', value);
                                //reset conditions when we change object
                                setFieldValue('condition', INITIAL_CONDITION);
                            }}
                            onOpen={restGetRegistersMonitoredObjects}
                            optionKeys={{
                                name: 'name',
                                value: 'id',
                            }}
                            multi={false}
                            required={alertSubject === 'object'}
                            disabled={inEditMode}
                            error={errors.object}
                        />
                    )}
                </div>
            )}
        </div>
    );
};

export default AlertBaseInfo;
