import React, { ComponentType } from 'react';

import { IPrivileges } from '../../../state/auth';
import { Redirect } from 'react-router-dom';
import { usePrivileges } from '../../../state/auth/index.hooks';

interface IProps {
    check: (privilegesList: IPrivileges) => boolean;
    target?: string;
    rel?: string;
    exact?: boolean;
    path: string;
    component: ComponentType;
}
type TProps = IProps;

const hasRouteAccessWrapper =
    <P extends object>(WrappedComponent: ComponentType<P>) =>
    (params: IProps) => {
        const userPrivileges = usePrivileges();
        const { check, ...rest } = params as TProps;
        const props = rest as P;
        if (check(userPrivileges)) {
            return <WrappedComponent {...props} />;
        } else {
            return <Redirect to={'/403'} />;
        }
    };

export default hasRouteAccessWrapper;
