import React, { ReactChild } from 'react';

import { Popover } from '@material-ui/core';
import { InfoOutlined as InfoIcon } from '@material-ui/icons';

import { useStyles } from './Themable.hooks';

interface IOwnProps {
    children: ReactChild;
    summary: string | number;
}

const DetailsCell = ({ children, summary }: IOwnProps) => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
        null
    );

    const classes = useStyles();
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    return (
        <span className={classes.cell}>
            <>
                <span
                    className={classes.icon}
                    onClick={handleClick}
                    data-testid="icon"
                >
                    <InfoIcon />
                </span>
                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                >
                    <div className={classes.popover}>{children}</div>
                </Popover>
            </>
            {summary}
        </span>
    );
};

export default DetailsCell;
