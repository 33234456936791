import moment from 'moment';

import { findLast } from 'lodash';

function isStringArray<T>(array: T[] | string[]): array is string[] {
    return typeof array[0] === 'string';
}

export function combineExtents(arrays: number[][]) {
    return arrays.reduce((acc, curr) => {
        return acc.map((value, index) => {
            if (index < 2) {
                return Math.min(value, curr[index]); // Minimum of first and second numbers
            } else {
                return Math.max(value, curr[index]); // Maximum of third and fourth numbers
            }
        });
    });
}

export function intersection<T>(a: T[], b: (T | string)[], key: string) {
    if (isStringArray(b)) {
        return a.filter((ar: T) => b.includes(ar[key]));
    } else {
        return a.filter((ar: T) => b.find((rm) => ar[key] === rm[key]));
    }
}
export function not<T>(a: T[], b: T[], key: string) {
    return a.filter((ar: T) => !b.find((rm: T) => rm[key] === ar[key]));
}

export function union<T>(a: T[], b: T[]) {
    return [...a, ...not(b, a, 'id')];
}

export function findIndexById<T>(a: T[], b: T) {
    return a.findIndex((el: T) => el['id'] === b['id']);
}

export const lowerCaseFilter = <T>(object: T, phrase: string, key: string) => {
    return object[key].toLowerCase().includes(phrase.toLowerCase());
};

export function filterByDate<T>(
    entities: T[],
    dateString: string,
    param?: string
): T | null {
    const targetTime = moment(dateString);
    const maxDiff = 300000;
    let closestItem: any = null;
    let closestDiff = Infinity;

    entities.forEach((item) => {
        const itemTime = moment(param ? item[param] : item);
        const diff = Math.abs(itemTime.diff(targetTime));

        if (diff < closestDiff && diff < maxDiff) {
            closestItem = item;
            closestDiff = diff;
        }
    });
    return closestItem;
}

export function filterById<T>(entities: T[], id: string, param: string) {
    return findLast(entities, (item) => String(item[param]) === id);
}
