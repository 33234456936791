import React from 'react';

import classNames from 'classnames';

import { useMediaQuery } from '@material-ui/core';

import AppConfig from '../../../constants/AppConfig';
import { useStyles } from '../Themable.hooks';
import { MOBILE } from '../../../constants/dictionaries/ScreenSizeConst';
import TranslationHelper from '../../../helpers/TranslationHelper';
import defaultLogo from '../../../../assets/images/xtrack_ribbon.svg';

export const LoginPageLogo = () => {
    const theme = AppConfig.instance.getConfigKey('th');
    const userBranding = AppConfig.instance.getConfigKey(
        AppConfig.PROPERTY_BRANDING
    );
    const userLoginLogo =
        userBranding?.loginLogo &&
        `/configuration/assets/${userBranding.loginLogo}`;

    const classes = useStyles();
    const isMobile = useMediaQuery(MOBILE);
    const logoClass = classNames({
        [classes.loginLogo]: true,
        [classes[theme]]: true,
        [classes.mobileLogo]: isMobile,
    });

    const generateLogo = () => {
        switch (theme) {
            case 'borim':
            case 'wiss':
            case 'kingspan':
                return <div className={logoClass} />;
            case 'hiab':
                return (
                    <>
                        <div className={classes.hiabAdditional} />{' '}
                        <div className={logoClass} />
                    </>
                );
            case 'branding':
                return (
                    <img
                        className={classes.loginLogo}
                        alt={TranslationHelper.translate('Image not found')}
                        src={userLoginLogo}
                    />
                );
            default:
                return (
                    <img
                        className={classes.loginLogo}
                        alt={TranslationHelper.translate('Image not found')}
                        src={defaultLogo}
                    />
                );
        }
    };

    return generateLogo();
};
