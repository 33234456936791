import React from 'react';

import { Formik, FormikActions } from 'formik';

import { useSnackbar } from 'notistack';

import SaveIcon from '@material-ui/icons/Save';

import { createLocation } from '../../../../../../../state/ui/customerService/clientsAndLocations';
import { useUserDataPermissions } from './../../../../../../../state/user/index.hooks';
import {
    useSelectedClientId,
    useSelectedClientsAndLocations,
} from './../../../../../../../state/ui/customerService/clientsAndLocations/index.hooks';
import { INewServiceLocation } from './../../../../../../../models/customerService';

import { cscAddLocationSchema } from '../../../../../../../schemas';

import { useToolkitDispatch } from '../../../../../../../hooks';
import TranslationHelper from '../../../../../../../helpers/TranslationHelper';
import { errorMessageHandler } from '../../../../../../../helpers/errorMessageHandler';

import PaneHeader from '../../../../../../../components/PaneHeader';
import { BLANK_FORM_LOCATION } from '../blankForms';
import IconButtonWithTooltip from '../../../../../../../components/IconButtonWithTooltip';
import LocationForm from '../LocationForm/LocationForm';

import { prepareGroupIds } from './../../../../../../../components/VisibilityForm/VisibilityForm';

interface IOwnProps {
    handleClose: () => void;
}

const LocationAddForm = ({ handleClose }: IOwnProps) => {
    const toolkitDispatch = useToolkitDispatch();

    const selectedClientId = useSelectedClientId();

    const selectedClientsAndLocations = useSelectedClientsAndLocations();
    const dataPermissions = useUserDataPermissions();

    const clients = selectedClientsAndLocations
        .filter((item) => !!item.clientId)
        .map((item) => ({ id: item.clientId, name: item.clientName }));

    const { enqueueSnackbar } = useSnackbar();

    const showNotification = (success: boolean, message: string) => {
        enqueueSnackbar(message, {
            variant: success ? 'success' : 'error',
        });
    };

    const handleValues = (
        values: INewServiceLocation,
        { setSubmitting }: FormikActions<INewServiceLocation>
    ) => {
        const updatedLocation = {
            ...values,
            groupIds: prepareGroupIds(values, dataPermissions),
        };

        toolkitDispatch(createLocation(updatedLocation))
            .unwrap()
            .then(() => {
                showNotification(
                    true,
                    TranslationHelper.translate('Location has been added')
                );
                handleClose();
            })
            .catch((error) => {
                const message = errorMessageHandler(error.status, {
                    409: 'Location with given external number exists',
                })();
                showNotification(false, message);
                setSubmitting(false);
            });
    };
    const getRightCustomControls = (
        submitForm: () => void,
        submitting: boolean
    ) => (
        <IconButtonWithTooltip
            title={TranslationHelper.translate('Save')}
            onClick={submitForm}
            disabled={submitting}
        >
            <SaveIcon />
        </IconButtonWithTooltip>
    );
    return (
        <Formik
            enableReinitialize={true}
            initialValues={{
                ...BLANK_FORM_LOCATION,
                client:
                    clients?.find(
                        (client) => Number(client.id) === selectedClientId
                    )?.id ||
                    clients[0]?.id ||
                    BLANK_FORM_LOCATION.client,
            }}
            onSubmit={handleValues}
            validateOnBlur={false}
            validateOnChange={false}
            validationSchema={cscAddLocationSchema()}
        >
            {({ errors, values, submitForm, isSubmitting, setFieldValue }) => (
                <>
                    <PaneHeader
                        onCloseClick={handleClose}
                        title={TranslationHelper.translate('Add location')}
                        renderRightCustomControls={() =>
                            getRightCustomControls(submitForm, isSubmitting)
                        }
                    />

                    <LocationForm
                        values={values}
                        errors={errors}
                        setFieldValue={setFieldValue}
                        clients={clients}
                    />
                </>
            )}
        </Formik>
    );
};

export default LocationAddForm;
