import { makeReduxDuck } from 'teedux';

import { IRequestWithMessages, makeRequest } from '../../../app/sync';

import { TRootState } from '../../../../store';

import {
    restGetRfidCards,
    restPostRfidCard,
    restPutRfidCard,
} from '../../../../services/registers';

import PageableCollection from '../../../../models/common/PageableCollection';
import RFIDCard from '../../../../models/registers/RFIDCard';

import { IPage, IRegisterFilter, mapToPageableCollection } from '..';

export interface IRFIDCard {
    id: string;
    linkedObject: { objectName: string };
    cardNumber: string;
    code: string;
    group: { name: string };
    groups: { name: string }[];
    groupIds: string[];
    type: string;
    description: string;
}

interface IState {
    rfidCards: PageableCollection | null;
    rfidCardsFilter: IRegisterFilter;
}

export const initialState: IState = {
    rfidCards: null,
    rfidCardsFilter: {
        name: '',
        start: 0,
        limit: 25,
        group: undefined,
    },
};

const duck = makeReduxDuck('app/registers/rfidCards', initialState);

const rfidCardsStorePath = 'app/registers/rfidCards';

export const setRfidCards = duck.defineAction<{
    rfidCards: PageableCollection;
}>('SET_RFID_CARDS', (_, { rfidCards }) => ({
    rfidCards,
}));

export const setRfidCardsFilter = duck.defineAction<{
    rfidCardsFilter: IRegisterFilter;
}>('SET_RFID_CARDS_FILTER', (_, { rfidCardsFilter }) => ({
    rfidCardsFilter,
}));

export const fetchRfidCards = (
    params: IRegisterFilter = initialState.rfidCardsFilter,
    afterRequest: (data: IPage<IRFIDCard>) => void = () => null
) =>
    makeRequest(
        `get:${rfidCardsStorePath}`,
        () => restGetRfidCards(params),
        (dispatch, data) => {
            dispatch(
                setRfidCards({
                    rfidCards: mapToPageableCollection(data, RFIDCard.fromJson),
                })
            );

            if (afterRequest) {
                afterRequest(data);
            }
        },
        (dispatch, error) => undefined
    );

export const updateRfidCard = (
    rfidCard: IRFIDCard,
    filter: IRegisterFilter,
    afterRequest?: (data: IRequestWithMessages) => void,
    afterFail?: () => void
) =>
    makeRequest(
        `put:${rfidCardsStorePath}/${rfidCard.id}`,
        () => restPutRfidCard(rfidCard),
        (dispatch, data) => {
            // @ts-ignore
            dispatch(fetchRfidCards(filter));
            if (afterRequest) {
                afterRequest(data);
            }
        },
        () => {
            if (afterFail) {
                afterFail();
            }
        }
    );
export const createRfidCard = (
    rfidCard: IRFIDCard,
    filter: IRegisterFilter,
    afterRequest?: (data: IRequestWithMessages) => void,
    afterFail?: () => void
) =>
    makeRequest(
        `post:${rfidCardsStorePath}/`,
        () => restPostRfidCard(rfidCard),
        (dispatch, data) => {
            // @ts-ignore
            dispatch(fetchRfidCards(filter));
            if (afterRequest) {
                afterRequest(data);
            }
        },
        () => {
            if (afterFail) {
                afterFail();
            }
        }
    );

const getRegistersRfidCardsState = (state: TRootState) =>
    state.app.registers.rfidCards;

export const getRfidCards = (state: TRootState) =>
    getRegistersRfidCardsState(state).rfidCards;
export const getRfidCardsFilter = (state: TRootState) =>
    getRegistersRfidCardsState(state).rfidCardsFilter;

export default duck.getReducer();
