import React, { Component, ComponentType } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import {
    createStyles,
    Theme,
    withStyles,
    WithStyles,
} from '@material-ui/core/styles';

const styles = (theme: Theme) =>
    createStyles({
        wrapper: {
            position: 'absolute',
            bottom: theme.spacing(4),
            left: theme.spacing(3),
            zIndex: 0,
        },
    });

const Themable = withStyles(styles, { withTheme: true });

type TThemableProps = WithStyles<typeof styles>;

interface IOwnProps {
    style: object;
}
type TProps = IOwnProps & TThemableProps;

class Wrapper extends Component<TProps> {
    public render() {
        const { classes, children, style } = this.props;
        return (
            <div className={classes.wrapper} style={style}>
                {children}
            </div>
        );
    }
}

export default compose(
    Themable,
    connect()
)(Wrapper) as ComponentType<IOwnProps>;
