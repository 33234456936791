import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';

import Theme from './components/Theme';

import Root from './components/Root';

import '../assets/images/xtrack-favicon.ico';

import store from './store';

import { SnackbarProvider } from 'notistack';

interface IOwnProps {
    themeName: string;
}

const App: React.FunctionComponent<IOwnProps> = ({ themeName }) => (
    <Provider store={store}>
        <Router>
            <Theme themeName={themeName}>
                <SnackbarProvider
                    maxSnack={5}
                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                    autoHideDuration={3000}
                    preventDuplicate={true}
                >
                    <Root />
                </SnackbarProvider>
            </Theme>
        </Router>
    </Provider>
);

export default App;
