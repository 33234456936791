import URI from 'urijs';
import AppConfig from '../../../constants/AppConfig';
import {
    makeGetRequestSettings,
    makePostRequestSettings,
    makePutRequestSettings,
    makeRestReadCollection,
    makeUrl,
} from '../../common/utils';
import UrlConst from '../../../constants/UrlConst';
import apiClient from '../../common/apiClient';
import {
    IServiceClient,
    INewServiceClient,
} from '../../../models/customerService';

export const makeCustomerServiceUrl = (suffix: string, params = {}) => {
    const url = new URI(
        AppConfig.instance.getUrl(UrlConst.CUSTOMER_SERVICE_URL) + suffix
    );
    return makeUrl(url, params);
};

export const restGetClients = (): Promise<IServiceClient[]> =>
    apiClient.sendWithoutErrorsHandling(
        makeGetRequestSettings(makeCustomerServiceUrl('/clients'))
    );

export const restGetClient = (id: number): Promise<IServiceClient> =>
    apiClient.sendWithoutErrorsHandling(
        makeGetRequestSettings(makeCustomerServiceUrl(`/clients/${id}`))
    );

export const restPostCreateClient = (
    client: INewServiceClient
): Promise<{ id: string }> =>
    apiClient.send(
        makePostRequestSettings(makeCustomerServiceUrl('/clients'), client)
    );

export const restPutEditClient = (
    client: IServiceClient
): Promise<{ id: string }> =>
    apiClient.send(
        makePutRequestSettings(
            makeCustomerServiceUrl(`/clients/${client.id}`),
            client
        )
    );

export const restReadClientsCollection = makeRestReadCollection(restGetClients);
