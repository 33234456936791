import React, {
    ChangeEvent,
    Component,
    ComponentType,
    FocusEvent,
} from 'react';

import { compose } from 'redux';

import MomentUtils from '@date-io/moment';

import { withSnackbar, WithSnackbarProps } from 'notistack';

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    TextField,
} from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

import { Connectable, TConnectableProps } from './Connectable.hoc';

import { IReportEntity } from '../../../../../../../../state/types';
import { IRecalculateData } from '../../../../../../../../state/app/monitoredObjects';

import Translator from '../../../../../../../../helpers/TranslationHelper';
import TimeFormatter from '../../../../../../../../helpers/TimeFormatter';

import { AccessibleButton } from '../../../../../../../../components/accessControl/components';
import PaneHeader from '../../../../../../../../components/PaneHeader';

interface IOwnProps {
    data: IReportEntity;
    monitoredId: string;
}

type TProps = IOwnProps & TConnectableProps & WithSnackbarProps;

interface IState {
    showDialog: boolean;
    recalculated: boolean;
    form: IRecalculateData;
}

class ObjectRecalculation extends Component<TProps> {
    public currentDate = new Date(new Date().setHours(0, 0, 0, 0));

    public state = {
        showDialog: false,
        recalculated: this.props.data.actions?.onClick?.disabled,
        form: {
            start: this.currentDate,
            reason: '',
        },
    } as IState;

    public componentDidUpdate(prevProps: Readonly<TProps>) {
        if (
            this.props.data.actions?.onClick?.disabled !==
            prevProps.data.actions?.onClick?.disabled
        ) {
            this.setState({
                recalculated: false,
            });
        }
    }

    public toggleDialog = (show: boolean, reason?: string) => {
        if (reason === 'backdropClick') {
            return;
        }

        this.setState({
            showDialog: show,
            form: {
                start: this.currentDate,
                reason: '',
            },
        });
    };

    public handleDateChange = (date: MaterialUiPickersDate | null) => {
        date?.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });

        this.setState((state: IState) => ({
            form: {
                ...state.form,
                start: date,
            },
        }));
    };

    public handleReasonChange = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value.slice(0, 100);
        this.setState((state: IState) => ({
            form: {
                ...state.form,
                reason: value,
            },
        }));
    };

    public handleReasonBlur = (event: FocusEvent<HTMLInputElement>) => {
        const value = event.target.value.trim();
        this.setState((state: IState) => ({
            form: {
                ...state.form,
                reason: value,
            },
        }));
    };

    public handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        this.setState({
            recalculated: true,
        });
        this.props.recalcuateData(
            this.state.form,
            this.props.monitoredId,
            () => {
                this.props.enqueueSnackbar(
                    Translator.translate('Recalculating state started'),
                    {
                        variant: 'success',
                    }
                );
                this.toggleDialog(false);
            },
            () => {
                this.props.enqueueSnackbar(
                    Translator.translate('Unknown error'),
                    {
                        variant: 'error',
                    }
                );

                this.setState({
                    recalculated: false,
                });
            }
        );
    };

    public render() {
        const { data, dateFormat } = this.props;
        const { showDialog, form, recalculated } = this.state;
        const disabled = data.actions?.onClick?.disabled;

        return (
            <>
                <AccessibleButton
                    check={(privileges) => privileges.objectRecalculation}
                    disabled={disabled || recalculated}
                    onClick={() => this.toggleDialog(true)}
                    fullWidth
                    style={{ justifyContent: 'flex-start' }}
                >
                    {Translator.translate(data.label)}
                </AccessibleButton>

                <Dialog
                    open={showDialog}
                    onClose={(event?: object, reason?: string) => {
                        this.toggleDialog(false, reason);
                    }}
                >
                    <form onSubmit={this.handleSubmit}>
                        <PaneHeader
                            title={Translator.translate(data.label)}
                            onCloseClick={() => this.toggleDialog(false)}
                        />
                        <DialogContent>
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <DatePicker
                                    format={dateFormat}
                                    value={form.start}
                                    onChange={this.handleDateChange}
                                    label={Translator.translate('Date')}
                                    fullWidth={true}
                                    required={true}
                                    disableFuture={true}
                                    minDate={TimeFormatter.subtractedDate(31)}
                                />
                            </MuiPickersUtilsProvider>

                            <TextField
                                fullWidth={true}
                                multiline={true}
                                rows={4}
                                label={Translator.translate('Reason')}
                                margin="normal"
                                required={true}
                                value={form.reason}
                                onChange={this.handleReasonChange}
                                onBlur={this.handleReasonBlur}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={() => this.toggleDialog(false)}
                                variant="contained"
                            >
                                {Translator.translate('Cancel')}
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                type="submit"
                                disabled={recalculated}
                            >
                                {Translator.translate('Recalculate')}
                            </Button>
                        </DialogActions>
                    </form>
                </Dialog>
            </>
        );
    }
}

export default compose(
    Connectable,
    withSnackbar
)(ObjectRecalculation) as ComponentType<IOwnProps>;
