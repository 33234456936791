import React, { ChangeEvent } from 'react';

import { useSnackbar } from 'notistack';

import { ColumnApi, GridApi } from 'ag-grid-community';

import { Radio, Tooltip, Typography } from '@mui/material';
import { Box } from '@mui/system';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import EditIcon from '@mui/icons-material/Edit';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';

import { showGridViewDialog } from '../../state/ui/dialog';
import {
    deleteView,
    fetchGridView,
    IGridViewListItem,
    saveCurrentGridConfiguration,
    selectGridViewId,
} from '../../state/ui/gridViews';
import { useSelectedGridViewId } from '../../state/ui/gridViews/index.hooks';
import { usePrivileges } from '../../state/auth/index.hooks';

import {
    applyGridConfiguration,
    getGridConfiguration,
} from '../../helpers/gridConfiguration';
import TranslationHelper from '../../helpers/TranslationHelper';
import { useAppDispatch, useToolkitDispatch } from '../../hooks';
import { errorMessageHandler } from '../../helpers/errorMessageHandler';

import ListItemContainer from '../ListContainer/ListItemContainer';
import IconButtonWithTooltip from '../IconButtonWithTooltip';
import DeleteButton from '../DeleteButton';
import { hasAccessToView } from './access';

interface IOwnProps {
    view: IGridViewListItem;
    sourceSet: string;
    gridColumnApi?: ColumnApi;
    gridApi?: GridApi;
}
const GridViewItem = ({
    view,
    sourceSet,
    gridApi,
    gridColumnApi,
}: IOwnProps) => {
    const { enqueueSnackbar } = useSnackbar();

    const selectedGridId = useSelectedGridViewId();
    const dispatch = useAppDispatch();
    const toolkitDispatch = useToolkitDispatch();
    const privileges = usePrivileges();

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        toolkitDispatch(fetchGridView(e.target.value))
            .unwrap()
            .then(async (view) => {
                await applyGridConfiguration(
                    view.configuration,
                    gridColumnApi,
                    gridApi
                );
                toolkitDispatch(
                    saveCurrentGridConfiguration(
                        getGridConfiguration(gridColumnApi, gridApi)
                    )
                );
                toolkitDispatch(selectGridViewId(view.id));
            })
            .catch((error) => {
                const message = errorMessageHandler(error.status)();
                enqueueSnackbar(message, {
                    variant: 'error',
                });
            });
    };

    const handleEdit = () => {
        toolkitDispatch(
            saveCurrentGridConfiguration(
                getGridConfiguration(gridColumnApi, gridApi)
            )
        );
        dispatch(showGridViewDialog({ id: view.id, sourceSet }));
    };

    const handleDelete = () => {
        toolkitDispatch(deleteView({ id: view.id, sourceSet }))
            .unwrap()
            .then(() =>
                enqueueSnackbar(
                    TranslationHelper.translate('View deleted succesfully'),
                    {
                        variant: 'success',
                    }
                )
            )
            .catch((error) => {
                const message = errorMessageHandler(error.status)();
                enqueueSnackbar(message, {
                    variant: 'error',
                });
            });
    };

    return (
        <ListItemContainer>
            <Box
                sx={{
                    width: 250,
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                <Radio
                    color="primary"
                    checked={selectedGridId === view.id}
                    onChange={handleChange}
                    value={view.id}
                    name="radio-buttons"
                    inputProps={{ 'aria-label': 'grid-view-radio' }}
                />
                {view.accessType === 'PRIVATE' && (
                    <Tooltip title={TranslationHelper.translate('Private')}>
                        <AdminPanelSettingsIcon fontSize="small" />
                    </Tooltip>
                )}
                <Box
                    sx={{
                        px: 1,
                        width: 200,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        textWrap: 'nowrap',
                    }}
                >
                    {view.name}
                </Box>

                <Box sx={{ display: 'flex' }}>
                    <Tooltip
                        title={
                            <>
                                <Typography>{view.name}</Typography>
                                <>{view.description}</>
                            </>
                        }
                    >
                        <div
                            style={{
                                display: 'inline-flex',
                                cursor: 'pointer',
                            }}
                            data-testid="icon"
                        >
                            <InfoOutlinedIcon />
                        </div>
                    </Tooltip>
                </Box>
            </Box>
            {hasAccessToView(view, privileges) && (
                <>
                    {selectedGridId === view.id && (
                        <IconButtonWithTooltip
                            title={TranslationHelper.translate('Edit')}
                            onClick={handleEdit}
                            size="small"
                        >
                            <EditIcon sx={{ color: 'black' }} />
                        </IconButtonWithTooltip>
                    )}
                    <DeleteButton
                        title="Removal confirmation"
                        tooltip="Delete"
                        message="Are you sure?"
                        deleteMethod={handleDelete}
                        size="small"
                        defaultColor
                    />
                </>
            )}
        </ListItemContainer>
    );
};

export default GridViewItem;
