import { makeReduxDuck } from 'teedux';
import { restGetParamsDefinitions } from '../../../services/mappings';
import { makeRequest } from '../sync';

const mappingsStorePath = `app/mappings`;

export interface IDefinitionItem {
    id?: string;
    name: string;
    type?: string;
    _value?: string;
}

export interface IParameterRest {
    target: string;
    source: string;
}

interface IState {
    sourceParamsDefinitions: IDefinitionItem[];
    targetParamsDefinitions: IDefinitionItem[];
}

const initialState: IState = {
    sourceParamsDefinitions: [],
    targetParamsDefinitions: [],
};

const duck = makeReduxDuck(mappingsStorePath, initialState);

export const setSourceParamsDefinitionsAction = duck.defineAction<{
    data: IDefinitionItem[];
}>('SET_SOURCE_PARAMS_DEFINITIONS', (_, { data }) => ({
    sourceParamsDefinitions: data,
}));

export const setTargetParamsDefinitionsAction = duck.defineAction<{
    data: IDefinitionItem[];
}>('SET_TARGET_PARAMS_DEFINITIONS', (_, { data }) => ({
    targetParamsDefinitions: data,
}));

export default duck.getReducer();

export const fetchSourceParamsDefinitions = (
    afterFail?: (error: any) => void
) =>
    makeRequest(
        `get:${mappingsStorePath}/source`,
        () => restGetParamsDefinitions(undefined, true, false),
        (dispatch, data) => {
            dispatch(setSourceParamsDefinitionsAction({ data }));
        },
        (dispatch, error) => {
            if (afterFail) {
                afterFail(error);
            }
        }
    );

export const fetchTargetParamsDefinitions = (
    afterFail?: (error: any) => void
) =>
    makeRequest(
        `get:${mappingsStorePath}/target`,
        () => restGetParamsDefinitions(undefined, false, true),
        (dispatch, data) => {
            dispatch(setTargetParamsDefinitionsAction({ data }));
        },
        (dispatch, error) => {
            if (afterFail) {
                afterFail(error);
            }
        }
    );
