import {
    resetSearcherForType,
    toggleEmployeesSearchResult,
    toggleVehiclesSearchResult,
    updateSearcherForType,
} from '../../_actions';
import { TSearchType } from '.';
//TODO those should be imported from state/searcher directory
// it should be completed in #54841
import {
    useAreEmployeesSearchResultsVisible,
    useAreVehiclesSearchResultsVisible,
} from '../../../pages/discovery/selectors/index.hooks';
import { useAppDispatch } from '../../../hooks';
import {
    useIsEmployeesFetching,
    useIsVehiclesFetching,
} from '../../../pages/discovery/selectors/feching/index.hooks';
import {
    useEmployeesSearcherResults,
    useVehiclesSearcherResults,
} from '../../../pages/discovery/selectors/searcher/index.hooks';

const useSearchersLogic = () => {
    const dispatch = useAppDispatch();

    const areEmployeesSearcherResultsVisible =
        useAreEmployeesSearchResultsVisible();
    const areVehiclesSearcherResultsVisible =
        useAreVehiclesSearchResultsVisible();
    const employeesSearcherResults = useEmployeesSearcherResults();
    const vehiclesSearcherResults = useVehiclesSearcherResults();
    const isVehiclesFetching = useIsVehiclesFetching();
    const isEmployeesFetching = useIsEmployeesFetching();

    const onSearchRegister = (name: string, type: TSearchType) => {
        dispatch(updateSearcherForType(name, type));
        switch (type) {
            case 'vehicles':
                dispatch(toggleVehiclesSearchResult(true));
                break;
            case 'employees':
                dispatch(toggleEmployeesSearchResult(true));
                break;
        }
    };

    const areSearchResultsVisible = (type: TSearchType) => {
        switch (type) {
            case 'vehicles':
                return areVehiclesSearcherResultsVisible;
            case 'employees':
                return areEmployeesSearcherResultsVisible;
            default:
                return false;
        }
    };

    const getRegisterResults = (type: TSearchType) => {
        switch (type) {
            case 'vehicles':
                return vehiclesSearcherResults;
            case 'employees':
                return employeesSearcherResults;
            default:
                return [];
        }
    };

    const areSearchResultsFetching = (type: TSearchType) => {
        switch (type) {
            case 'vehicles':
                return isVehiclesFetching;
            case 'employees':
                return isEmployeesFetching;
            default:
                return false;
        }
    };

    const onResetRegister = (type: TSearchType) => {
        dispatch(resetSearcherForType(type));
    };

    return {
        onSearchRegister,
        areSearchResultsVisible,
        getRegisterResults,
        areSearchResultsFetching,
        onResetRegister,
    };
};

export default useSearchersLogic;
