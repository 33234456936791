import { IResource } from '../../state/ui/resources';

import UrlConst from '../../constants/UrlConst';

import apiClient from '../common/apiClient';
import { makeEndpoint, makeGetRequestSettings } from '../common/utils';

export const restGetResources = (): Promise<IResource[]> =>
    apiClient.send(
        makeGetRequestSettings(makeEndpoint(UrlConst.RESOURCES_URL, '/'))
    );
