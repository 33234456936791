import React from 'react';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Box } from '@mui/material';

import { Field, Form, Formik, FormikErrors } from 'formik';

import TranslationHelper from '../../../../../helpers/TranslationHelper';
import {
    mapDictionaryItemToMenuItem,
    renderMenuItems,
    transformDictionaryToDictionaryArray,
} from '../../../../../helpers/renderMenuItems';

import { MOBILE } from '../../../../../constants/dictionaries/ScreenSizeConst';
import { ATTRIBUTE_TYPES } from '../../../../../constants/dictionaries/AttributeTypes';

import { useAttributesStatus } from '../../../../../state/ui/attributes/index.hooks';
import {
    IConnectedObjectWithId,
    TAttributeValue,
} from '../../../../../state/ui/attributes';
import { attributesSchema } from '../../../../../schemas/adminSchemas';

import DialogTitle from '../../../../../components/dialogs/DialogTitle';

import FieldWrapper from '../../../../../components/common/formikWrappers/FieldWrapper';
import LoadingSpinner from '../../../../../components/loadingSpinner/LoadingSpinner';
import { BLANK_OBJECTS } from '../AttributesDialog';
import { BottomForm } from './BottomForm';

export interface IAttributesForm {
    label: string;
    name: string;
    type: number | string;
    regex: string;
    lockedToDictionary: boolean;
    connectedTo: IConnectedObjectWithId[];
    dictionary: { internal: boolean; value: TAttributeValue }[];
    dictionaryField: string;
    internal?: boolean;
    dictionaryItemRemoved: boolean;
}
interface IOwnProps {
    isOpen: boolean;
    title: string;
    initialValues: IAttributesForm;
    editMode: boolean;
    handleClose: (event?: object, reason?: string) => void;
    handleSubmit: (values: IAttributesForm, resetForm: () => void) => void;
}

export const AttributesForm = ({
    isOpen,
    initialValues,
    handleSubmit,
    handleClose,
    title,
    editMode,
}: IOwnProps) => {
    const isMobile = useMediaQuery(MOBILE);
    const attributeStatus = useAttributesStatus();

    const handleChangeType = (
        setFieldValue: (name: string, value: any) => void,
        setFieldError: (name: string, value: string) => void
    ) => {
        setFieldValue('dictionary', []);
        setFieldValue('dictionaryField', null);
        setFieldValue('connectedTo', BLANK_OBJECTS);
        setFieldError('dictionary', '');
    };

    const renderContent = (
        values: IAttributesForm,
        errors: FormikErrors<IAttributesForm>,
        setFieldError: (name: string, value: any) => void,
        setFieldValue: (name: string, value: string) => void
    ) => {
        return (
            <>
                {attributeStatus === 'loading' && (
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '300px',
                            height: '200px',
                        }}
                    >
                        <LoadingSpinner />
                    </Box>
                )}
                {attributeStatus === 'done' && (
                    <DialogContent style={{ width: 800, height: 800 }}>
                        <Form>
                            <Box sx={{ display: 'flex', gap: 2 }}>
                                <Field
                                    error={!!errors?.label}
                                    helperText={errors?.label}
                                    name={'label'}
                                    label={TranslationHelper.translate(
                                        'attributes.label'
                                    )}
                                    value={
                                        values.internal
                                            ? TranslationHelper.translate(
                                                  values.label
                                              )
                                            : values.label
                                    }
                                    autoComplete={'label'}
                                    type={'text'}
                                    component={FieldWrapper}
                                    required={true}
                                    fullWidth={true}
                                    disabled={initialValues.internal}
                                />
                                <Field
                                    error={!!errors?.name}
                                    helperText={errors?.name}
                                    name={'name'}
                                    label={TranslationHelper.translate('Name')}
                                    autoComplete={'name'}
                                    type={'text'}
                                    component={FieldWrapper}
                                    required={true}
                                    fullWidth={true}
                                    disabled={initialValues.internal}
                                />
                            </Box>
                            <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
                                <Field
                                    error={!!errors?.type}
                                    helperText={errors?.type}
                                    name={'type'}
                                    label={TranslationHelper.translate('Type')}
                                    component={FieldWrapper}
                                    select={true}
                                    fullWidth={true}
                                    disabled={
                                        editMode || initialValues.internal
                                    }
                                    afterOnChange={() =>
                                        handleChangeType(
                                            setFieldValue,
                                            setFieldError
                                        )
                                    }
                                >
                                    {renderMenuItems(
                                        transformDictionaryToDictionaryArray(
                                            ATTRIBUTE_TYPES
                                        ),
                                        mapDictionaryItemToMenuItem
                                    )}
                                </Field>
                                <Field
                                    error={!!errors?.regex}
                                    helperText={errors?.regex}
                                    name={'regex'}
                                    label={TranslationHelper.translate('Regex')}
                                    autoComplete={'regex'}
                                    type={'text'}
                                    component={FieldWrapper}
                                    fullWidth={true}
                                    disabled={initialValues.internal}
                                />
                            </Box>
                            <BottomForm
                                values={values}
                                setFieldError={setFieldError}
                                setFieldValue={setFieldValue}
                                errors={errors}
                            />
                        </Form>
                    </DialogContent>
                )}
            </>
        );
    };
    return (
        <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            onSubmit={(values, { resetForm }) =>
                handleSubmit(values, resetForm)
            }
            validateOnBlur={false}
            validateOnChange={false}
            validationSchema={attributesSchema}
        >
            {({ errors, values, setFieldValue, submitForm, setFieldError }) => (
                <Dialog
                    open={isOpen}
                    onClose={handleClose}
                    maxWidth={'lg'}
                    fullScreen={isMobile}
                    disableEscapeKeyDown
                >
                    <DialogTitle
                        loading={attributeStatus === 'loading'}
                        title={TranslationHelper.translate(title)}
                        close={handleClose}
                        save={submitForm}
                    />
                    {renderContent(
                        values,
                        errors,
                        setFieldError,
                        setFieldValue
                    )}
                </Dialog>
            )}
        </Formik>
    );
};
