import React from 'react';

class MenulessPageTemplate extends React.Component {
    constructor(props) {
        super(props);
    }

    render(page) {
        return (
            <div id="login-bg">
                <div className="login-form">
                    <div className="ribbon-logo" />
                    {page}
                </div>
            </div>
        );
    }
}

export default MenulessPageTemplate;
