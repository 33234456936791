import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { makeReduxDuck } from 'teedux';
import { fetchData, reset } from '../fetching/searchers';
import { ICard } from '../../app/registers';

export type TSearchType = 'vehicles' | 'employees';

export interface ISearchResult {
    id: number;
    label: string;
    cards?: ICard[];
}

interface ISearcher<V> {
    searchPhrase: string;
    data?: V[];
}

interface IState {
    [key: string]: ISearcher<ISearchResult>;
}

const initialState: IState = {
    vehicles: { searchPhrase: '' },
    employees: { searchPhrase: '' },
};

const duck = makeReduxDuck('app/searchers', initialState);

const updateSearchPhraseAction = duck.defineAction<{
    searchPhrase: string;
    searchType: TSearchType;
}>('UPDATE_SEARCH_PHRASE_FOR_TYPE', (state, { searchPhrase, searchType }) => ({
    [searchType]: { data: state[searchType].data, searchPhrase },
}));

const storeDataAction = duck.defineAction<{
    data: ISearchResult[];
    searchType: TSearchType;
}>('STORE_SEARCH_DATA_FOR_TYPE', (state, { data, searchType }) => ({
    [searchType]: { searchPhrase: state[searchType].searchPhrase, data },
}));

const resetDataAction = duck.defineAction<{
    searchType: TSearchType;
}>('RESET_SEARCH_DATA_FOR_TYPE', (state, { searchType }) => ({
    [searchType]: initialState[searchType],
}));

export default duck.getReducer();

export const updateSearchPhrase =
    (
        searchPhrase: string,
        searchType: TSearchType
    ): ThunkAction<void, null, null, Action> =>
    (dispatch) => {
        const trimmedSearchPhrase = searchPhrase.trim();
        dispatch(
            updateSearchPhraseAction({
                searchPhrase: trimmedSearchPhrase,
                searchType,
            })
        );
        if (trimmedSearchPhrase === '') {
            dispatch(reset[searchType]());
            dispatch(resetSearcher(searchType));
        } else {
            dispatch(fetchData[searchType](trimmedSearchPhrase));
        }
    };

export const storeSearcherData = (
    data: ISearchResult[],
    searchType: TSearchType
) =>
    storeDataAction({
        data,
        searchType,
    });

export const resetSearcher = (searchType: TSearchType) =>
    resetDataAction({ searchType });
