import { connect, ResolveThunks } from 'react-redux';

import { getUserSettings, updateUserEmail } from '../../../../state/user';
import { hideGlobalDialog } from '../../../../state/ui/dialog';

import { TRootState } from '../../../../store';

const mapStateToProps = (state: TRootState) => ({
    userSettings: getUserSettings(state),
});

const mapDispatchToProps = {
    updateUserEmail,
    hideGlobalDialog,
};

type TStateProps = ReturnType<typeof mapStateToProps>;
type TDispatchProps = ResolveThunks<typeof mapDispatchToProps>;

export type TConnectableProps = TStateProps & TDispatchProps;

export const Connectable = connect(mapStateToProps, mapDispatchToProps);
