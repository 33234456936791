import React from 'react';

import { MenuItem } from '@material-ui/core';
import TranslationHelper from './TranslationHelper';
import { IDictionaryItem } from '../state/app/dictionaries';

interface IMenuItem {
    id: string;
    parentId?: number;
    name: string;
}

export const mapDictionaryItemToMenuItem = <T extends IDictionaryItem>(
    item: T
) => {
    return {
        id: String(item.id),
        name: item.name,
        parentId: item.parentId,
    };
};

export const transformDictionaryToDictionaryArray = (dictionary: {
    [key: number]: string;
}): { id: number; name: string }[] => {
    return Object.entries(dictionary).map(([id, name]) => ({
        id: Number(id),
        name,
    }));
};

export const renderMenuItems = <T,>(
    items: T[],
    mapMenuItem: (item: T) => IMenuItem,
    parentId?: number,

    hasEmpty: boolean = false,
    translate: boolean = true
) => {
    const initial = hasEmpty
        ? [
              <MenuItem key={'-1'} value={-1}>
                  {''}
              </MenuItem>,
          ]
        : [];
    return initial.concat(
        items
            .map((item) => mapMenuItem(item))
            .filter(
                (item) => parentId === undefined || item.parentId === parentId
            )
            .map((item) => {
                return (
                    <MenuItem key={item.id} value={item.id}>
                        {translate
                            ? TranslationHelper.translate(item.name)
                            : item.name}
                    </MenuItem>
                );
            })
    );
};
