import URI from 'urijs';
import AppConfig from '../../constants/AppConfig';
import UrlConst from '../../constants/UrlConst';
import { IUser } from '../../state/app/collections/dataTypes';
import apiClient from '../common/apiClient';
import {
    makeDeleteRequestSettings,
    makeGetRequestSettings,
    makePostRequestSettings,
    makePutRequestSettings,
    makeUrl,
    makeRestReadCollection,
} from '../common/utils';
import { restGetRoles } from '../roles';

export const makeUsersUrl = (suffix: string, params = {}) => {
    const url = new URI(
        AppConfig.instance.getUrl(UrlConst.USERS_ENDPOINT_URL) + suffix
    );
    return makeUrl(url, params);
};

const restGetUsers = (): Promise<IUser[]> =>
    apiClient.sendWithoutErrorsHandling(
        makeGetRequestSettings(makeUsersUrl(``))
    );

export const restGetUser = (userId: number): Promise<IUser> =>
    apiClient.sendWithoutErrorsHandling(
        makeGetRequestSettings(makeUsersUrl(`/${userId}`))
    );

export interface IRestPostUser {
    login: string;
    name: string;
    surname: string;
    email: string;
    password: string;
    roles: number[];
}

export const restPostUser = (user: IRestPostUser): Promise<null> =>
    apiClient.send(makePostRequestSettings(makeUsersUrl(``), user));

export interface IRestPutUser {
    id: number;
    name: string;
    surname: string;
    email: string;
    roles: number[];
}

export const restPutUser = (user: IRestPutUser): Promise<null> =>
    apiClient.send(makePutRequestSettings(makeUsersUrl(`/${user.id}`), user));

export const restDeleteUser = (userId: number): Promise<null> =>
    apiClient.sendWithoutErrorsHandling(
        makeDeleteRequestSettings(makeUsersUrl(`/${userId}`))
    );

export const restPostUserPasswordReset = (userId: number): Promise<null> =>
    apiClient.sendWithoutErrorsHandling(
        makePostRequestSettings(makeUsersUrl(`/${userId}/password-reset`), {})
    );

export const restReadUsersCollection = makeRestReadCollection(restGetUsers);
export const restReadRolesCollection = makeRestReadCollection(restGetRoles);
