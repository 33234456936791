export const drawerWidth = 240;

import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => {
    const drawerEasing = theme.transitions.easing.easeInOut;
    return createStyles({
        root: {
            flexGrow: 1,
            height: '100%',
            zIndex: 1,
            position: 'relative',
            display: 'flex',

            '@media not print': {
                overflow: 'hidden',
            },
        },
        appBar: {
            zIndex: theme.zIndex.appBar,
            transition: theme.transitions.create(['width', 'margin'], {
                easing: drawerEasing,
                duration: theme.transitions.duration.leavingScreen,
            }),
            boxShadow: 'none',
            '@media print': {
                display: 'none',
            },
        },
        appBarShift: {
            marginLeft: drawerWidth,
            width: `calc(100% - ${drawerWidth}px)`,
            transition: theme.transitions.create(['width', 'margin'], {
                easing: drawerEasing,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        menuButton: {
            marginLeft: 6,
            marginRight: 36,
        },
        hide: {
            display: 'none',
        },
        drawerPaper: {
            position: 'relative',
            width: drawerWidth,
            transition: theme.transitions.create('width', {
                easing: drawerEasing,
                duration: theme.transitions.duration.enteringScreen,
            }),
            display: 'flex',
            border: 0,
            flexDirection: 'column',
            justifyContent: 'space-between',
            overflow: 'hidden',
            '@media print': {
                display: 'none',
            },
        },
        drawerPaperClose: {
            width: 60,
            overflowX: 'hidden',
            transition: theme.transitions.create('width', {
                easing: drawerEasing,
                duration: theme.transitions.duration.leavingScreen,
            }),
        },

        // drawerSegment prevents menu items from resizing and triggering "layout jumps"
        drawerSegment: {
            minWidth: drawerWidth,
        },
        toolbar: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '0 8px',
            overflow: 'hidden',
            ...theme.mixins.toolbar,

            [theme.breakpoints.down('sm')]: {
                backgroundColor: theme.palette.primary.main,
            },
        },
        content: {
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            backgroundColor: theme.palette.background.default,
            position: 'relative',
            zIndex: 1300,
            height: '100vh',
        },
    });
});
