import Attribution from 'ol/control/Attribution';
import Zoom from 'ol/control/Zoom';
import { createEmpty, extend, isEmpty } from 'ol/extent';

import Feature from 'ol/Feature';

import Geometry from 'ol/geom/Geometry';
import Point from 'ol/geom/Point';

import VectorLayer from 'ol/layer/Vector';

import Control from 'ol/control/Control';
import Cluster from 'ol/source/Cluster';
import VectorSource from 'ol/source/Vector';
import Circle from 'ol/style/Circle';
import Style from 'ol/style/Style';
import View from 'ol/View';

import { transform } from 'ol/proj';

import olMap from 'ol/Map';

// import Easing from 'ol/easing'
import { unByKey } from 'ol/Observable';

// @ts-ignore
import LayerSwitcher from 'ol-ext/control/LayerSwitcher';

import TranslationHelper from '../../../../../helpers/TranslationHelper';

import { ISourceSet } from '../../../../../state/types';

import { generateTileLayers } from './tileLayers';

import { getCustomLayers } from './customLayers';
import { setFeatures, makePoint } from './features';
import { CLUSTERING_DISTANCE } from './clustering';
import { getIconImage } from './icons';
import { initLayer as initNearEventsLayer } from './nearEventsLayer';
import ProjectionsConst from '../../../../../constants/ProjectionsConst';
import CoordinatesHelper from '../../../../../helpers/CoordinatesHelper';

const PADDING = 40;
const LARGE_PADDING = 100;
export const MAP_PADDING = [PADDING, PADDING, PADDING, PADDING];
export const LARGE_MAP_PADDING = [
    LARGE_PADDING,
    LARGE_PADDING,
    LARGE_PADDING,
    LARGE_PADDING,
];

export type TVectorLayer = VectorLayer<VectorSource<Geometry>> | null;

const clusterSource = new Cluster({
    source: new VectorSource(),
    distance: CLUSTERING_DISTANCE,
});

export const fitMap = (
    map: olMap,
    extent: number[],
    padding?: number[],
    maxZoom?: number
) => {
    map.getView().fit(extent, {
        duration: 300,
        size: map.getSize(),
        padding,
        maxZoom,
    });
};
export const flash = (() => {
    let listenerKey: any = null;

    return (map: olMap, feature?: Feature<Point>) => {
        const crosshair = document.querySelector(
            '.animated-crosshair'
        ) as HTMLDivElement;
        if (listenerKey !== null) {
            unByKey(listenerKey);
        }
        if (!feature) {
            crosshair.style.display = 'none';
            return;
        }
        crosshair.style.display = 'block';
        listenerKey = map.on('postcompose', animate);

        function animate() {
            const geometry = (
                feature as Feature<Point>
            ).getGeometry() as Geometry;
            const extent = geometry.getExtent();
            const pixel = map.getPixelFromCoordinate([extent[0], extent[1]]);
            crosshair.style.left = pixel[0] - 20 + 'px';
            crosshair.style.top = pixel[1] - 20 + 'px';
        }
    };
})();

const getClustersLayers = () => {
    const styleCache = {};
    const clustersLayer = new VectorLayer({
        // @ts-ignore
        displayInLayerSwitcher: false,
        source: clusterSource,
        zIndex: 1,
        style: (feature) => {
            const size = feature.get('features')?.length;
            if (size) {
                if (size === 1) {
                    return feature.get('features')[0].getStyle();
                }
                if (!styleCache[size]) {
                    styleCache[size] = new Style({
                        image: getIconImage(size),
                    });
                }
                return [styleCache[size]];
            }
            return null;
        },
    });

    return clustersLayer;
};
export const getInitialLayers = (
    tileLayers: VectorLayer<VectorSource<Geometry>>[]
) => {
    const markerLayer = new VectorLayer({
        source: new VectorSource(),
        style: new Style({
            image: new Circle({
                radius: 0,
            }),
        }),
        // @ts-ignore
        displayInLayerSwitcher: false,
        zIndex: 1,
    });

    const selectionLayer = new VectorLayer({
        source: new VectorSource(),
        style: new Style({
            image: new Circle({
                radius: 0,
            }),
        }),
        zIndex: 2,
        // @ts-ignore
        displayInLayerSwitcher: false,
    });
    const trailLayer = new VectorLayer({
        source: new VectorSource(),
        // @ts-ignore
        displayInLayerSwitcher: false,
        zIndex: 1,
    });

    const layers = [...tileLayers];

    const clustersLayer = getClustersLayers();

    const nearEventsLayer = initNearEventsLayer();

    layers.push(trailLayer);
    layers.push(selectionLayer);
    layers.push(markerLayer);
    layers.push(nearEventsLayer);
    layers.push(clustersLayer);

    return {
        layers,
        trailLayer,
        selectionLayer,
        markerLayer,
        nearEventsLayer,
        clustersLayer,
    };
};

const handleCustomLayers = (
    map: olMap,
    layerSwitcher: any,
    tileLayers: any,
    handleLayersChange: (map: olMap) => void
) => {
    getCustomLayers().then(
        (customLayers: VectorLayer<VectorSource<Geometry>>[]) => {
            map.getLayers().extend(customLayers);

            if (customLayers.length && tileLayers.length <= 1) {
                map.addControl(layerSwitcher);
            }
            handleLayersChange(map);
            layerSwitcher.on('drawlist', handleLayersChange(map));
        }
    );
};

export const getControls = (mobile: boolean) => {
    const controls: Control[] = [new Attribution()];

    if (!mobile) {
        controls.push(
            new Zoom({
                zoomInTipLabel: TranslationHelper.translate('Zoom in'),
                zoomOutTipLabel: TranslationHelper.translate('Zoom out'),
                className: 'ol-zoom-custom',
            })
        );
    }
    return controls;
};
export const makeMapFromSourceSet = (
    sourceSet: ISourceSet | null,
    container: HTMLDivElement,
    language: string,
    handleLayersChange: (map?: olMap) => void,
    mobile: boolean,
    clustering: boolean = true
) => {
    const generatedTileLayers = generateTileLayers(language);

    const controls = getControls(mobile);

    const view = new View({
        center: makePoint(18.6466, 54.352),
        zoom: 4,
        maxZoom: 19,
        minZoom: 3,
        enableRotation: false,
    });

    const {
        layers,
        clustersLayer,
        trailLayer,
        selectionLayer,
        markerLayer,
        nearEventsLayer,
    } = getInitialLayers(generatedTileLayers);

    const map = new olMap({
        layers,
        controls,
        target: container,
        view,
    });

    const layerSwitcher = new LayerSwitcher({
        onchangeCheck: () => handleLayersChange(map),
    });
    layerSwitcher.element.title = TranslationHelper.translate('Layers');

    if (generatedTileLayers.length > 1) {
        map.addControl(layerSwitcher);
    }

    if (!mobile) {
        handleCustomLayers(
            map,
            layerSwitcher,
            generatedTileLayers,
            handleLayersChange
        );
    }
    setFeatures(map, clustersLayer, sourceSet, clustering);

    return {
        map,
        clustersLayer,
        trailLayer,
        selectionLayer,
        markerLayer,
        nearEventsLayer,
    };
};

export const fitMapToFeatures = (
    map: olMap,
    features: Feature<Point>[],
    callback: () => void = () => null
) => {
    const extent = createEmpty();

    features.forEach((feature) => {
        const geometry = feature.getGeometry();

        if (geometry) {
            extend(extent, geometry.getExtent());
        }
    });

    if (!isEmpty(extent)) {
        fitMap(map, extent, MAP_PADDING);
    }
    callback();
};

export const centerOnFeature = (map: olMap, coordinates: number[]) => {
    map.getView().animate({
        zoom: 16,
        center: transform(
            coordinates,
            ProjectionsConst.EPSG4326,
            ProjectionsConst.EPSG900913
        ),
        duration: 250,
    });
};

export const transformCoords = (coords: number[]) => {
    const coordinate = CoordinatesHelper.normalizePosition(coords);
    return transform(
        coordinate,
        ProjectionsConst.EPSG900913,
        ProjectionsConst.EPSG4326
    );
};
