import { useSelector } from 'react-redux';

import { getEmployees, getEmployeesFilter } from './employees';
import { getVehicles, getVehiclesFilter } from './vehicles';
import { getFuelTanks, getFuelTanksFilter } from './tanks';
import { getRfidCards, getRfidCardsFilter } from './rfidCards';
import { getGroups, getGroupsLoading } from './groups';
import {
    getMonitoredObjects,
    getMonitoredObjectsLoading,
} from './monitoredObjects';

export const useEmployees = () => useSelector(getEmployees);
export const useEmployeesFilter = () => useSelector(getEmployeesFilter);

export const useVehicles = () => useSelector(getVehicles);
export const useVehiclesFilter = () => useSelector(getVehiclesFilter);

export const useFuelTanks = () => useSelector(getFuelTanks);
export const useFuelTanksFilter = () => useSelector(getFuelTanksFilter);

export const useRfidCards = () => useSelector(getRfidCards);
export const useRfidCardsFilter = () => useSelector(getRfidCardsFilter);

export const useRegistersGroups = () => useSelector(getGroups);
export const useRegistersGroupsLoading = () => useSelector(getGroupsLoading);

export const useRegistersMonitoredObjects = () =>
    useSelector(getMonitoredObjects);
export const useRegistersMonitoredObjectsLoading = () =>
    useSelector(getMonitoredObjectsLoading);
