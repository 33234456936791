import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { useTab } from './Connectable.hooks';
import { selectTab } from '../../state/ui/management';
import { usePrivileges } from '../../state/auth/index.hooks';

import Devices from './components/Devices';
import Groups from './components/Groups';
import TabsContainer from '../../components/TabsContainer';

import DevicesIcon from '@material-ui/icons/DevicesOther';
import UsersIcon from '@material-ui/icons/People';

const ManagementPage = () => {
    const currentTab = useTab();
    const userPrivileges = usePrivileges();
    const dispatch = useDispatch();

    const tabs = [
        {
            privileges: ['manageGroups'],
            value: 'groups',
            label: 'Groups',
            icon: <UsersIcon />,
            mainComponent: <Groups />,
        },
        {
            privileges: ['devicesManagement', 'eTollReadOnly'],
            value: 'devices',
            label: 'Devices',
            icon: <DevicesIcon />,
            mainComponent: <Devices />,
        },
    ];

    useEffect(() => {
        selectInitialTab();
    }, []);

    const handleChange = (event: React.ChangeEvent<{}>, value: string) => {
        dispatch(selectTab({ tab: value }));
    };

    const selectInitialTab = () => {
        if (currentTab) return;
        if (userPrivileges.manageGroups) {
            dispatch(selectTab({ tab: 'groups' }));
        } else if (
            !userPrivileges.manageGroups &&
            (userPrivileges.devicesManagement || userPrivileges.eTollReadOnly)
        ) {
            dispatch(selectTab({ tab: 'devices' }));
        }
    };

    return (
        <TabsContainer
            tabs={tabs}
            currentTab={currentTab}
            handleChange={handleChange}
            title="Management"
        />
    );
};

export default ManagementPage;
