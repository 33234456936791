import VectorLayer from 'ol/layer/Vector';
import Geometry from 'ol/geom/Geometry';
import Point from 'ol/geom/Point';
import Polygon from 'ol/geom/Polygon';
import GeometryCollection from 'ol/geom/GeometryCollection';
import { circular } from 'ol/geom/Polygon';
import Draw from 'ol/interaction/Draw';
import Modify from 'ol/interaction/Modify';
import Snap from 'ol/interaction/Snap';
import { Projection, transform } from 'ol/proj';
import VectorSource from 'ol/source/Vector';
import { getDistance } from 'ol/sphere';
import Fill from 'ol/style/Fill';
import Stroke from 'ol/style/Stroke';
import Style from 'ol/style/Style';
import Feature from 'ol/Feature';
import olMap from 'ol/Map';
import Circle from 'ol/style/Circle';

import ProjectionsConst from '../../../../../constants/ProjectionsConst';

export interface INearEventsInteractions {
    modify: Modify;
    draw: Draw;
    snap: Snap;
}

export const initLayer = () => {
    const nearEventsSource = new VectorSource();

    const geodesicStyle = new Style({
        geometry: (feature) => {
            return feature.get('modifyGeometry') || feature.getGeometry();
        },
        fill: new Fill({
            color: 'rgba(91, 82, 130, 0.4)',
        }),
        stroke: new Stroke({
            color: '#5b5282',
            width: 2,
        }),
        image: new Circle({
            radius: 200,
            fill: new Fill({
                color: 'rgba(0, 0, 0, 0)',
            }),
        }),
    });

    return new VectorLayer({
        source: nearEventsSource,
        style: () => {
            return geodesicStyle;
        },
        // @ts-ignore
        displayInLayerSwitcher: false,
    });
};

export const initInteractions = (
    map: olMap,
    source: VectorSource<Geometry> | null,
    onChange: (radius: number, coordinates: number[]) => void
): INearEventsInteractions | null => {
    if (!source) {
        return null;
    }
    const defaultStyle = new Modify({ source: source })
        .getOverlay()
        .getStyleFunction();

    const modify = new Modify({
        source,
        style: (feature) => {
            feature
                .get('features')
                .forEach((modifyFeature: Feature<GeometryCollection>) => {
                    const modifyGeometry = modifyFeature.get('modifyGeometry');
                    if (modifyGeometry) {
                        const modifyPoint = (feature! as Feature<Point>)
                            .getGeometry()!
                            .getCoordinates();
                        const geometries = modifyFeature!
                            .getGeometry()!
                            .getGeometries();
                        const polygon = (
                            geometries![0] as Polygon
                        ).getCoordinates()[0];
                        const center = (
                            geometries![1] as Point
                        ).getCoordinates();
                        const projection = map.getView().getProjection();
                        let first, last, radius, stepRadius;
                        if (
                            modifyPoint[0] === center[0] &&
                            modifyPoint[1] === center[1]
                        ) {
                            // center is being modified
                            // get unchanged radius from diameter between polygon vertices
                            first = transform(
                                polygon[0],
                                projection,
                                ProjectionsConst.EPSG4326
                            );
                            last = transform(
                                polygon[(polygon.length - 1) / 2],
                                projection,
                                ProjectionsConst.EPSG4326
                            );
                            radius = getDistance(first, last) / 2;
                            stepRadius = Math.round(radius);
                        } else {
                            // radius is being modified
                            first = transform(
                                center,
                                projection,
                                ProjectionsConst.EPSG4326
                            );
                            last = transform(
                                modifyPoint,
                                projection,
                                ProjectionsConst.EPSG4326
                            );
                            radius = getDistance(first, last);
                            stepRadius = Math.min(
                                Math.ceil(radius / 50) * 50,
                                200
                            ); // TODO: make step and uppper bound params
                        }
                        // update the polygon using new center or radius
                        const circle = circular(
                            transform(
                                center,
                                projection,
                                ProjectionsConst.EPSG4326
                            ),
                            stepRadius,
                            128
                        );
                        circle.transform(ProjectionsConst.EPSG4326, projection);
                        (geometries![0] as Polygon).setCoordinates(
                            circle.getCoordinates()
                        );
                        // save changes to be applied at the end of the interaction
                        modifyGeometry.setGeometries(geometries);
                        modifyGeometry.set('radius', stepRadius);
                    }
                });
            return defaultStyle!(feature, 0);
        },
    });

    modify.on('modifystart', (event) => {
        event.features.forEach((feature) => {
            feature = feature as Feature<Geometry>;
            let geometry = feature.getGeometry();
            if (geometry?.getType() === 'GeometryCollection') {
                geometry = geometry as GeometryCollection;
                feature.set('modifyGeometry', geometry.clone(), true);
            }
        });
    });

    modify.on('modifyend', (event) => {
        event.features.forEach((feature) => {
            const modifyGeometry = feature.get('modifyGeometry');
            if (modifyGeometry) {
                feature = feature as Feature<Geometry>;
                feature.setGeometry(modifyGeometry);
                feature.unset('modifyGeometry', true);
                const geometries = modifyGeometry.getGeometries();
                const center = (geometries![1] as Point).getCoordinates();
                onChange(modifyGeometry.get('radius'), center);
            }
        });
    });

    const draw = new Draw({
        source: source,
        type: 'Circle',
    });

    draw.on('drawstart', () => {
        if (source.getFeatures().length > 0) {
            draw.abortDrawing();
        }
    });

    const snap = new Snap({ source: source });

    return { modify, draw, snap };
};

export const updateRadius = (
    source: VectorSource<GeometryCollection>,
    projection: Projection,
    radius: number
) => {
    source.forEachFeature((feature) => {
        const geometry = feature.getGeometry();
        const geometries = feature.getGeometry()?.getGeometries();
        if (!geometry || !geometries) {
            return;
        }
        const center = transform(
            (geometries[1] as Point).getCoordinates(),
            projection,
            ProjectionsConst.EPSG4326
        );
        const circle = circular(center, radius, 128);
        circle.transform(ProjectionsConst.EPSG4326, projection);
        (geometries[0] as Polygon).setCoordinates(circle.getCoordinates());
        geometry.setGeometries(geometries);
        geometry.set('radius', radius);
    });
};

export const updatePosition = (
    source: VectorSource<GeometryCollection>,
    projection: Projection,
    coordinates: number[]
) => {
    source.forEachFeature((feature) => {
        const geometry = feature.getGeometry();
        const geometries = feature.getGeometry()?.getGeometries();
        if (!geometry || !geometries) {
            return;
        }
        const circle = circular(coordinates, geometry.get('radius'), 128);
        circle.transform(ProjectionsConst.EPSG4326, projection);
        (geometries[0] as Polygon).setCoordinates(circle.getCoordinates());
        (geometries[1] as Point).setCoordinates(
            transform(coordinates, ProjectionsConst.EPSG4326, projection)
        );
        geometry.setGeometries(geometries);
    });
};

export const initCircle = (
    source: VectorSource<GeometryCollection>,
    projection: Projection,
    coordinates: number[]
) => {
    const circle = new Feature<GeometryCollection>();
    circle.setGeometry(
        new GeometryCollection([new Polygon([]), new Point([])])
    );
    source.addFeature(circle);
    updatePosition(source, projection, coordinates);
};
