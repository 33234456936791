import React, { useEffect, useState } from 'react';

import { Formik, FormikActions, FormikValues } from 'formik';

import { useSnackbar } from 'notistack';

import SaveIcon from '@material-ui/icons/Save';

import { updateLocation } from '../../../../../../../state/ui/customerService/clientsAndLocations';
import { useSelectedLocation } from '../../../../../../../state/ui/customerService/clientsAndLocations/index.hooks';
import { fetchRegistersGroups } from '../../../../../../../state/app/registers/groups';
import { useRegistersGroups } from '../../../../../../../state/app/registers/index.hooks';
import { IMappedMenuItem } from '../../../../../../../state/types';

import { IServiceLocation } from '../../../../../../../models/customerService';

import { cscEditLocationSchema } from '../../../../../../../schemas';

import { useToolkitDispatch } from '../../../../../../../hooks';
import TranslationHelper from '../../../../../../../helpers/TranslationHelper';
import { errorMessageHandler } from '../../../../../../../helpers/errorMessageHandler';

import PaneHeader from '../../../../../../../components/PaneHeader';
import { BLANK_FORM_LOCATION_EDIT } from '../blankForms';
import IconButtonWithTooltip from '../../../../../../../components/IconButtonWithTooltip';
import LocationForm from '../LocationForm/LocationForm';
import {
    prepareGroupIds,
    prepareGroups,
    prepareVisibilityType,
} from '../../../../../../../components/VisibilityForm/VisibilityForm';
import { useUserDataPermissions } from '../../../../../../../state/user/index.hooks';

interface IOwnProps {
    handleClose: () => void;
}

type IServiceLocationWithGroups = IServiceLocation & {
    groups: IMappedMenuItem[];
    visibilityType: string;
};

const LocationEditForm = ({ handleClose }: IOwnProps) => {
    const location = useSelectedLocation();

    const toolkitDispatch = useToolkitDispatch();

    const [initialValues, setInitialValues] =
        useState<IServiceLocationWithGroups>({
            ...BLANK_FORM_LOCATION_EDIT,
            groups: [],
            groupIds: [],
        });
    const { enqueueSnackbar } = useSnackbar();
    const groups = useRegistersGroups();
    const dataPermissions = useUserDataPermissions();

    useEffect(() => {
        if (!groups) {
            toolkitDispatch(fetchRegistersGroups({}));
        }
    }, [groups, toolkitDispatch]);
    useEffect(() => {
        if (location) {
            setInitialValues({
                ...BLANK_FORM_LOCATION_EDIT,
                ...location,
                client: location.clientId?.toString() || '',
                visibilityType: prepareVisibilityType(
                    groups,
                    location.groupIds,
                    dataPermissions
                ),
                groups: prepareGroups(groups, location.groupIds),
                externalId: location.externalId,
            });
        }
    }, [location, groups]);

    const showNotification = (success: boolean, message: string) => {
        enqueueSnackbar(message, {
            variant: success ? 'success' : 'error',
        });
    };

    const handleValues = (
        values: FormikValues,
        { setSubmitting }: FormikActions<IServiceLocation>
    ) => {
        const updatedLocation = { ...values } as IServiceLocation;
        updatedLocation.coordinate = {
            x: Number(values.coordinate.x),
            y: Number(values.coordinate.y),
        };
        updatedLocation.groupIds = prepareGroupIds(
            values,
            dataPermissions,
            location?.groupIds
        );
        toolkitDispatch(updateLocation(updatedLocation))
            .unwrap()
            .then(() => {
                showNotification(
                    true,
                    TranslationHelper.translate(
                        'Location changes has been saved'
                    )
                );
                handleClose();
            })
            .catch((error) => {
                const message = errorMessageHandler(error.status, {
                    409: 'Location with given external number exists',
                })();
                showNotification(false, message);
                setSubmitting(false);
            });
    };
    const getRightCustomControls = (
        submitForm: () => void,
        submitting: boolean
    ) => (
        <IconButtonWithTooltip
            title={TranslationHelper.translate('Save')}
            onClick={submitForm}
            disabled={submitting}
        >
            <SaveIcon />
        </IconButtonWithTooltip>
    );

    return (
        <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            onSubmit={handleValues}
            validateOnBlur={false}
            validateOnChange={false}
            validationSchema={cscEditLocationSchema()}
        >
            {({ errors, values, submitForm, isSubmitting, setFieldValue }) => (
                <>
                    <PaneHeader
                        onCloseClick={handleClose}
                        title={TranslationHelper.translate('Edit location')}
                        renderRightCustomControls={() =>
                            getRightCustomControls(submitForm, isSubmitting)
                        }
                    />

                    <LocationForm
                        values={values}
                        errors={errors}
                        setFieldValue={setFieldValue}
                    />
                </>
            )}
        </Formik>
    );
};

export default LocationEditForm;
