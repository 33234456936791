import { Feature, Map } from 'ol';
import { TVectorGeometrySource } from '../types';
import {
    fitMap,
    LARGE_MAP_PADDING,
} from '../../discovery/components/DiscoveryMap/_utils';
import { MAX_ZOOM } from '../../../constants/map/zoom';
import ClusterSource from 'ol/source/Cluster';
import { Geometry } from 'ol/geom';

export const zoomAfterSelect = (
    source: TVectorGeometrySource,
    map: Map | null,
    id: string
) => {
    if (!source) {
        return;
    }
    let feature: Feature<Geometry> | null;
    if (source instanceof ClusterSource) {
        //?
        const clusters = source.getFeatures();

        // Loop through each cluster to find the feature
        feature = null;
        for (const cluster of clusters) {
            const featuresInCluster = cluster.get('features');
            feature = featuresInCluster.find((f: Feature) => f.getId() === id);
            if (feature) {
                break;
            }
        }
    } else {
        feature = source.getFeatureById(id);
    }

    const geometry = feature?.getGeometry();
    const extent = geometry?.getExtent();
    if (extent && map) {
        fitMap(map, extent, LARGE_MAP_PADDING, MAX_ZOOM);
    }
};
