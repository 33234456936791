import { createStyles, WithStyles } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const styles = () => {
    return createStyles({
        dialogContent: {
            minWidth: 300,
        },
    });
};

export type TThemableProps = WithStyles<typeof styles, true>;

export const Themable = withStyles(styles, { withTheme: true });
