import React, { useEffect } from 'react';

import { Field, Form, FormikErrors, FormikValues } from 'formik';

import TranslationHelper from '../../../../../../../helpers/TranslationHelper';

import { renderMenuItems } from '../../../../../../../helpers/renderMenuItems';
import { useToolkitDispatch } from '../../../../../../../hooks';

import { useStyles } from '../../../../../Themable.hooks';
import { IServiceLocation } from '../../../../../../../models/customerService';

import { setMapCoords } from '../../../../../../../state/ui/customerService/clientsAndLocations';
import { useMapCoords } from '../../../../../../../state/ui/customerService/clientsAndLocations/index.hooks';
import { COORDS_DECIMAL } from '../../../../../../../constants/dictionaries/MapCoordsFormat';
import VisibilityForm from '../../../../../../../components/VisibilityForm';
import FieldWrapper from '../../../../../../../components/common/formikWrappers/FieldWrapper';
import { CoordsSection } from './CoordsSection';

interface IOwnProps {
    values: FormikValues;
    errors?: FormikErrors<IServiceLocation>;
    readOnly?: boolean;
    clients?: { id: string; name: string }[];
    setFieldValue: (field: string, value: any) => void;
}
const LocationForm = ({
    values,
    errors,
    readOnly = false,
    clients,
    setFieldValue,
}: IOwnProps) => {
    const classes = useStyles();

    const toolkitDispatch = useToolkitDispatch();

    const mapCoords = useMapCoords();
    const commonProps = {
        fullWidth: true,
        component: FieldWrapper,
        disabled: readOnly,
    };

    useEffect(() => {
        return () => {
            toolkitDispatch(setMapCoords(null));
        };
    }, []);

    useEffect(() => {
        if (mapCoords) {
            setFieldValue('coordinate', {
                x: mapCoords.x.toFixed(COORDS_DECIMAL),
                y: mapCoords.y.toFixed(COORDS_DECIMAL),
            });
        }
    }, [mapCoords]);

    return (
        <Form>
            <div className={classes.formWrapper}>
                {clients && (
                    <Field
                        error={!!errors?.client}
                        helperText={errors?.client}
                        name="client"
                        label={TranslationHelper.translate('Client')}
                        value={values.client}
                        select
                        required
                        {...commonProps}
                    >
                        {renderMenuItems(clients, (item) => ({
                            id: item.id.toString(),
                            name: item.name,
                        }))}
                    </Field>
                )}
                <Field
                    error={!!(errors && errors.name)}
                    helperText={errors && errors.name}
                    name={'name'}
                    label={TranslationHelper.translate('Name')}
                    type={'text'}
                    required={true}
                    value={values.name}
                    {...commonProps}
                />
                <Field
                    error={!!(errors && errors.address?.zip)}
                    helperText={errors && errors.address?.zip}
                    name={'address.zip'}
                    label={TranslationHelper.translate('Postal code')}
                    type={'text'}
                    value={values.address.zip}
                    {...commonProps}
                />
                <Field
                    error={!!(errors && errors.address?.areaName0)}
                    helperText={errors && errors.address?.areaName0}
                    name={'address.areaName0'}
                    label={TranslationHelper.translate('Country')}
                    type={'text'}
                    value={values.address.areaName0}
                    {...commonProps}
                />
                <Field
                    error={!!(errors && errors.address?.city)}
                    helperText={errors && errors.address?.city}
                    name={'address.city'}
                    label={TranslationHelper.translate('City')}
                    type={'text'}
                    required={true}
                    value={values.address.city}
                    {...commonProps}
                />
                <Field
                    error={!!(errors && errors.address?.areaName3)}
                    helperText={errors && errors.address?.areaName3}
                    name={'address.areaName3'}
                    label={TranslationHelper.translate('Commune')}
                    type={'text'}
                    value={values.address.areaName3}
                    {...commonProps}
                />
                <Field
                    error={!!(errors && errors.address?.street)}
                    helperText={errors && errors.address?.street}
                    name={'address.street'}
                    label={TranslationHelper.translate('Street')}
                    type={'text'}
                    value={values.address.street}
                    {...commonProps}
                />
                <Field
                    error={!!(errors && errors.address?.streetNumber)}
                    helperText={errors && errors.address?.streetNumber}
                    name={'address.streetNumber'}
                    label={TranslationHelper.translate('Street number')}
                    type={'text'}
                    value={values.address.streetNumber}
                    {...commonProps}
                />
                <Field
                    error={!!(errors && errors.address?.apartmentNo)}
                    helperText={errors && errors.address?.apartmentNo}
                    name={'address.apartmentNo'}
                    label={TranslationHelper.translate('Apartment number')}
                    type={'text'}
                    value={values.address.apartmentNo}
                    {...commonProps}
                />

                <Field
                    error={!!(errors && errors.externalNo)}
                    helperText={errors && errors.externalNo}
                    name={'externalNo'}
                    label={TranslationHelper.translate('External number')}
                    type={'text'}
                    required={true}
                    value={values.externalNo}
                    {...commonProps}
                />
                <Field
                    error={!!(errors && errors.notes)}
                    helperText={errors && errors.notes}
                    name={'notes'}
                    label={TranslationHelper.translate('Notes')}
                    type={'text'}
                    value={values.notes}
                    {...commonProps}
                />
                {values.coordinate && (
                    <CoordsSection
                        values={values}
                        errors={errors}
                        readOnly={readOnly}
                    />
                )}
                <VisibilityForm
                    setFieldValue={setFieldValue}
                    values={values}
                    errors={errors}
                    disabled={readOnly}
                />
            </div>
        </Form>
    );
};

export default LocationForm;
