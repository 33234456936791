import { useToolkitDispatch } from '../hooks';

import { useSnackbar } from 'notistack';
import { AsyncThunkAction } from '@reduxjs/toolkit';
import { errorMessageHandler } from '../helpers/errorMessageHandler';
import TranslationHelper from '../helpers/TranslationHelper';

interface IAsyncActionResult {
    callback?: (data?: any) => void;
    message?: string;
    uniqueCase?: { [code: number]: string };
}
interface IAsyncThunkWrapper {
    asyncAction: AsyncThunkAction<any, any, any>;
    onSuccess?: IAsyncActionResult;
    onError?: IAsyncActionResult;
    onFinally?: {
        callback?: () => void;
    };
}

const useAsyncThunkWithSnackbar = () => {
    const toolkitDispatch = useToolkitDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const handleAsyncRequest = async ({
        asyncAction,
        onSuccess,
        onError,
        onFinally,
    }: IAsyncThunkWrapper) => {
        try {
            const result = await toolkitDispatch(asyncAction).unwrap(); // Extract result

            if (onSuccess?.callback) {
                onSuccess.callback(result);
            }
            if (onSuccess?.message) {
                enqueueSnackbar(onSuccess.message, { variant: 'success' });
            }
        } catch (error: any) {
            if (onError?.callback) {
                onError.callback();
            }
            const message = errorMessageHandler(
                error.status,
                onError?.uniqueCase
            )();
            enqueueSnackbar(
                error.errorMsg
                    ? TranslationHelper.translate(error.errorMsg)
                    : message,
                { variant: 'error' }
            );
        } finally {
            if (onFinally?.callback) {
                onFinally.callback();
            }
        }
    };
    return handleAsyncRequest;
};
export default useAsyncThunkWithSnackbar;
