import * as yup from 'yup';
import { memoize } from 'lodash';

import TranslationHelper from '../helpers/TranslationHelper';
import { NAME_STRING_MAX_LENGTH, REQUIRED } from '../constants/yup';

export const gridViewSchema = memoize(() =>
    yup.object().shape({
        name: yup
            .string()
            .trim()
            .required(TranslationHelper.translate(REQUIRED))
            .max(
                NAME_STRING_MAX_LENGTH,
                TranslationHelper.translate('Value in this field is too long')
            ),
        description: yup
            .string()
            .trim()
            .max(
                512,
                TranslationHelper.translate('Value in this field is too long')
            ),
        groups: yup
            .mixed()
            .when('visibilityType', (visibilityType: string): any => {
                if (visibilityType === 'group') {
                    return yup
                        .array()
                        .min(
                            1,
                            TranslationHelper.translate(
                                'At least 1 group required'
                            )
                        );
                } else {
                    return;
                }
            }),
    })
);
