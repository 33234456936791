import {
    createSlice,
    createSelector,
    createAsyncThunk,
} from '@reduxjs/toolkit';

import {
    restGetLocation,
    restReadLocationsCollection,
    restPostLocation,
    restPutLocation,
} from '../../../../services/locations';

import { restGetLocationIds } from '../../../../services/customerService/contracts';

import {
    restGetClient,
    restPostCreateClient,
    restPutEditClient,
    restReadClientsCollection,
} from '../../../../services/customerService/clients';
import { CSC_CLIENTS_STATUS } from '../../../../constants/dictionaries/CscClientsStatus';

import {
    ICoordinates,
    ILocation,
    ISourceSet,
    TStatus,
} from '../../../../state/types';
import { TOptionalNormalizedData } from '../../../../state/app/collections/dataTypes';
import {
    IServiceClient,
    INewServiceClient,
    IServiceLocation,
    INewServiceLocation,
} from '../../../../models/customerService';

import { TRootState } from '../../../../store';
import { IPrivileges, getPrivileges } from '../../../../state/auth';
import { Map } from 'ol';
import { getSourceSetWithFilter } from '../../../../pages/discovery/selectors/sourceSet/getSourceSetWithFilter';

interface IState {
    mode:
        | null
        | 'preview'
        | 'create'
        | 'editClient'
        | 'editLocation'
        | 'addLocation';
    clients: TOptionalNormalizedData<IServiceClient>;
    locations: TOptionalNormalizedData<ILocation>;
    status: TStatus;
    selectedLocationId: ILocationId | null;
    selectedClientsAndLocations: IMultiSelectLocation[];
    selectedClientId: number | null;
    selectedGridItemId: string | null;
    openSectionClients: boolean;
    openSectionLocations: boolean;
    client: IServiceClient | null;
    clientDetailsLoading: boolean;
    mapDialog: boolean;
    mapCoords: null | { x: number; y: number };
    location: IServiceLocation | null;
    locationDetailsLoading: boolean;
    mainMapVisible: boolean;
    validRfidFilter: string | null;
    locationsIdsByRfid: number[] | null;
    map: Map | null;
}

export interface ILocationId {
    id: string;
    externalId: string;
}

export interface IMultiSelectLocation {
    id: string;
    name: string;
    clientId: string;
    clientName: string;
    sourceSetId: string;
    locationId: string;
    coordinates: ICoordinates;
    radius?: number;
}

export type TLocationWithCoordinates = IMultiSelectLocation & {
    coordinates: ICoordinates;
};

export const initialState: IState = {
    mode: null,
    clients: null,
    locations: null,
    selectedLocationId: null,
    selectedClientsAndLocations: [],
    selectedClientId: null,
    selectedGridItemId: null,
    openSectionClients: false,
    openSectionLocations: false,
    status: 'idle',
    client: null,
    clientDetailsLoading: false,
    mapDialog: false,
    mapCoords: null,
    location: null,
    locationDetailsLoading: false,
    mainMapVisible: false,
    validRfidFilter: null,
    locationsIdsByRfid: null,
    map: null,
};

export const CLIENTS_AND_LOCATIONS_GRID_ID = 'clientsAndLocations';
export const fetchClients = createAsyncThunk(
    'get:ui/customerService/clientsAndLocations/clients',
    async () => {
        const response = await restReadClientsCollection();

        return response;
    }
);

export const fetchClient = createAsyncThunk(
    'get:ui/customerService/clientsAndLocations/clients/id',
    async (id: number) => {
        const response = await restGetClient(id);

        return response;
    }
);

export const fetchLocations = createAsyncThunk(
    'get:ui/customerService/clientsAndLocations/locations',
    async () => {
        const response = await restReadLocationsCollection({
            limit: 1000000,
            context: 'CUSTOMER_SERVICE',
        });
        return response;
    }
);

export const fetchLocation = createAsyncThunk(
    'get:ui/customerService/clientsAndLocations/locations/id',
    async (id: string, { rejectWithValue }) => {
        try {
            const response = await restGetLocation(id, 'CUSTOMER_SERVICE');
            return response;
        } catch (e: any) {
            throw rejectWithValue({
                status: e.status,
                errorMsg: e.responseJSON.message,
            });
        }
    }
);

export const fetchLocationIdsByRfid = createAsyncThunk(
    'get:ui/customerService/clientsAndLocations/locationIds',
    async (rfid: string) => {
        const response = await restGetLocationIds({ rfid });
        return response;
    }
);

export const createClient = createAsyncThunk(
    'post:ui/customerService/clientsAndLocations/clients/id',
    async (client: INewServiceClient, { rejectWithValue, dispatch }) => {
        try {
            const response = await restPostCreateClient(client);
            await dispatch(fetchClients());
            return response;
        } catch (e: any) {
            throw rejectWithValue({
                status: e.status,
                errorMsg: e.responseJSON.message,
            });
        }
    }
);

export const updateClient = createAsyncThunk(
    'post:ui/customerService/clientsAndLocations/clients/id',
    async (client: IServiceClient, { rejectWithValue, dispatch }) => {
        try {
            const response = await restPutEditClient(client);
            await dispatch(fetchClients());
            return response;
        } catch (e: any) {
            throw rejectWithValue({
                status: e.status,
                errorMsg: e.responseJSON.message,
            });
        }
    }
);

export const createLocation = createAsyncThunk(
    'post:ui/customerService/clientsAndLocations/locations/id',
    async (location: INewServiceLocation, { rejectWithValue, dispatch }) => {
        try {
            const response = await restPostLocation({
                name: location.name,
                notes: location.notes || '',
                address: location.address,
                coordinate: {
                    x: Number(location.coordinate.x),
                    y: Number(location.coordinate.y),
                },
                externalNo: location.externalNo,
                clientId: Number(location.client),
                groupIds: location.groupIds,
            });
            await dispatch(fetchLocations());
            return response;
        } catch (e: any) {
            throw rejectWithValue({
                status: e.status,
                errorMsg: e.responseJSON.message,
            });
        }
    }
);

export const updateLocation = createAsyncThunk(
    'post:ui/customerService/clientsAndLocations/locations/id',
    async (location: IServiceLocation, { rejectWithValue, dispatch }) => {
        try {
            const response = await restPutLocation({
                id: location.id,
                name: location.name,
                notes: location.notes || '',
                address: location.address,
                coordinate: {
                    x: Number(location.coordinate.x),
                    y: Number(location.coordinate.y),
                },
                externalNo: location.externalNo,
                externalId: Number(location.externalId),
                clientId:
                    (location.client && Number(location.client)) || undefined,
                groupIds: location.groupIds,
            });
            await dispatch(fetchLocations());
            return response;
        } catch (e: any) {
            throw rejectWithValue({
                status: e.status,
                errorMsg: e.responseJSON.message,
            });
        }
    }
);

const clientsAndLocations = createSlice({
    name: 'clientsAndLocations',
    initialState,
    reducers: {
        enterPreviewMode(state) {
            state.mode = 'preview';
            state.mapCoords = null;
        },
        enterCreateMode(state) {
            state.mode = 'create';
        },
        enterClientEditMode(state) {
            state.mode = 'editClient';
        },
        enterLocationEditMode(state) {
            state.mode = 'editLocation';
        },
        enterLocationAddMode(state) {
            state.mode = 'addLocation';
        },
        selectLocation(state, action) {
            state.selectedLocationId = action.payload;
        },
        resetLocation(state) {
            state.location = null;
        },
        selectClientsAndLocations(state, action) {
            state.selectedClientsAndLocations = action.payload;
        },
        resetClient(state) {
            state.client = null;
        },
        selectClient(state, action) {
            state.selectedClientId = action.payload;
        },
        selectGridItemId(state, action) {
            state.selectedGridItemId = action.payload;
        },
        toggleCollapseClients(state) {
            state.openSectionClients = !state.openSectionClients;
        },
        toggleCollapseLocations(state) {
            state.openSectionLocations = !state.openSectionLocations;
        },
        toggleMapDialog(state) {
            state.mapDialog = !state.mapDialog;
        },
        resetMode(state) {
            state.mode = null;
        },
        setMapCoords(state, action) {
            state.mapCoords = action.payload;
        },
        toggleMap(state) {
            state.mainMapVisible = !state.mainMapVisible;
        },
        setValidRfidFilter(state, action) {
            state.validRfidFilter = action.payload;
        },
        resetValidRfidFilter(state) {
            state.validRfidFilter = null;
            state.locationsIdsByRfid = null;
        },
        setMap(state, action) {
            state.map = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchClients.fulfilled, (state, action) => {
            state.clients = action.payload;
        });
        builder.addCase(fetchLocations.pending, (state) => {
            state.status = 'loading';
        });
        builder.addCase(fetchLocations.fulfilled, (state, action) => {
            state.locations = action.payload;
            state.status = 'done';
        });
        builder.addCase(fetchLocationIdsByRfid.pending, (state) => {
            state.status = 'loading';
        });
        builder.addCase(fetchLocationIdsByRfid.fulfilled, (state, action) => {
            state.locationsIdsByRfid = action.payload;
            state.status = 'done';
        });
        builder.addCase(fetchLocationIdsByRfid.rejected, (state, action) => {
            state.status = 'done';
        });
        builder.addCase(fetchClient.pending, (state) => {
            state.client = null;
            state.clientDetailsLoading = true;
        });
        builder.addCase(fetchClient.fulfilled, (state, action) => {
            if (state.selectedClientId === action.payload.id) {
                state.client = action.payload;
            }
            state.clientDetailsLoading = false;
        });
        builder.addCase(fetchLocation.pending, (state) => {
            state.location = null;
            state.locationDetailsLoading = true;
        });
        builder.addCase(fetchLocation.rejected, (state) => {
            state.location = null;
            state.locationDetailsLoading = false;
            state.selectedLocationId = null;
        });
        builder.addCase(fetchLocation.fulfilled, (state, action) => {
            const location = action.payload;
            if (state.selectedLocationId?.id === location.id) {
                state.location = {
                    ...location,
                    client: location.clientId?.toString() || '',
                };
            }
            state.locationDetailsLoading = false;
        });
    },
});

const getCurrent = (state: TRootState) =>
    state.ui.customerService.clientsAndLocations as IState;

export const getClientsAndLocationsMode = (state: TRootState) =>
    getCurrent(state).mode;

export const getSelectedLocationId = (state: TRootState) =>
    getCurrent(state).selectedLocationId;

export const getSelectedClientsAndLocations = (state: TRootState) =>
    getCurrent(state).selectedClientsAndLocations;

export const getSelectedClientId = (state: TRootState) =>
    getCurrent(state).selectedClientId;

export const getSelectedGridItemId = (state: TRootState) =>
    getCurrent(state).selectedGridItemId;

export const getClients = (state: TRootState) => getCurrent(state).clients;

export const getLocations = (state: TRootState) => getCurrent(state).locations;

export const getClientsEntities = (state: TRootState) =>
    getCurrent(state).clients?.entities;
export const getSourceSetModels = (state: TRootState) =>
    state.ui.discovery.general.sourceSetModels;

export const getClientsAndLocationsStatus = (state: TRootState) =>
    getCurrent(state).status;

export const getClientDetailsLoading = (state: TRootState) =>
    getCurrent(state).clientDetailsLoading;
export const getLocationDetailsLoading = (state: TRootState) =>
    getCurrent(state).locationDetailsLoading;
export const getOpenSectionClients = (state: TRootState) =>
    state.ui.customerService.clientsAndLocations.openSectionClients;
export const getOpenSectionLocations = (state: TRootState) =>
    state.ui.customerService.clientsAndLocations.openSectionLocations;
export const clientsAndLocationsPaneVisible = createSelector(
    [getClientsAndLocationsMode],
    (mode) => !!mode
);

export const getIsMapDialogOpen = (state: TRootState) =>
    getCurrent(state).mapDialog;

export const getLocationIdsByRfid = (state: TRootState) =>
    getCurrent(state).locationsIdsByRfid;

export const getValidRfidFilter = (state: TRootState) =>
    getCurrent(state).validRfidFilter;

export const isClientsAndLocationsInEditMode = createSelector(
    [getClientsAndLocationsMode],
    (mode) => mode && mode !== 'preview'
);

const getClientsAsArray = createSelector(
    [getClients],
    (clients) => clients && clients.result.map((id) => clients.entities[id])
);

const getLocationsAsArray = createSelector(
    [getLocations],
    (locations) =>
        locations && locations.result.map((id) => locations.entities[id])
);

const getFilteredLocations = createSelector(
    [getLocationsAsArray, getLocationIdsByRfid],
    (locations, ids) => {
        if (ids === null) {
            return locations;
        }
        if (ids.length === 0) {
            return [];
        }
        return (
            locations &&
            locations.filter((location) => ids.includes(+location.externalId))
        );
    }
);
export const getClientsAndLocationsSourceSetModel = createSelector(
    [getSourceSetModels],
    (models) => models.clientsAndLocations || null
);

export const getMapCoords = (state: TRootState) => getCurrent(state).mapCoords;
export const getMainMapVisible = (state: TRootState) =>
    getCurrent(state).mainMapVisible;

export const getSelectedClient = (state: TRootState) =>
    getCurrent(state).client;
export const getMap = (state: TRootState) => getCurrent(state).map;
export const getSelectedLocation = (state: TRootState) =>
    getCurrent(state).location;

const mergeClientsAndLocations = (
    clients: IServiceClient[],
    locations: ILocation[],
    normalizedClients: TOptionalNormalizedData<IServiceClient>,
    privileges: IPrivileges,
    rfidLocations: number[] | null
) => {
    const usedClientIds = new Set<number>();
    return locations
        .map((location: ILocation): any => {
            usedClientIds.add(location.clientId);
            return {
                ...location,
                locationName: location.name,
                name: '',
                ...normalizedClients?.entities[location.clientId],
                id: `client${location.clientId}location${location.id}`,
                locationId: location.id,
                city: location.address.city,
                street: location.address.street,
                apartmentNo: location.address.apartmentNo
                    ? `${location.address.streetNumber} / ${
                          location.address.apartmentNo || ''
                      }`
                    : location.address.streetNumber,
                postalCode: location.address.zip,
                commune: location.address.areaName3,
                locationNotice: location.notes,
                status: CSC_CLIENTS_STATUS[
                    normalizedClients?.entities[location.clientId]?.status || ''
                ],
                address: '',
                _meta: {},
            };
        })
        .concat(
            privileges.showClientsWithLocationOnly || rfidLocations !== null
                ? []
                : clients
                      .filter((client) => !usedClientIds.has(client.id))
                      .map((client: IServiceClient): any => ({
                          ...client,
                          id: client.id.toString(),
                          clientId: client.id,
                          status: CSC_CLIENTS_STATUS[client.status],
                          _meta: {},
                      }))
        );
};

export const getClientsAndLocationsAsSourceSet = createSelector(
    [
        getClientsAsArray,
        getFilteredLocations,
        getClients,
        getPrivileges,
        getLocationIdsByRfid,
    ],
    (
        clients,
        locations,
        normalizedClients,
        privileges,
        rfidLocations
    ): ISourceSet | null =>
        clients &&
        locations && {
            id: 'clientsAndLocations',
            definitionId: CLIENTS_AND_LOCATIONS_GRID_ID,
            label: 'Clients and Locations',
            attributes: [
                {
                    id: 'location',
                    type: 'parent',
                    label: 'Location',
                    selectable: true,
                    children: [
                        { id: 'locationName', label: 'Name', type: 'text' },
                        {
                            id: 'postalCode',
                            label: 'Postal code',
                            type: 'text',
                        },
                        {
                            id: 'city',
                            label: 'City',
                            type: 'text',
                        },
                        { id: 'street', label: 'Street', type: 'text' },
                        {
                            id: 'apartmentNo',
                            label: 'Building and apartment number',
                            type: 'text',
                        },
                        { id: 'commune', label: 'Commune', type: 'text' },
                        { id: 'locationNotice', label: 'Notice', type: 'text' },
                        {
                            id: 'externalNo',
                            label: 'External number',
                            type: 'text',
                        },
                    ],
                },
                {
                    id: 'client',
                    type: 'parent',
                    label: 'Client',
                    children: [
                        { id: 'name', label: 'Name', type: 'text' },
                        {
                            id: 'shortName',
                            label: 'Short name',
                            type: 'text',
                        },
                        {
                            id: 'externalNumber',
                            label: 'External number',
                            type: 'text',
                        },
                        { id: 'vatin', label: 'Tax number', type: 'text' },
                        { id: 'notice', label: 'Notice', type: 'text' },
                        {
                            id: 'status',
                            label: 'Status',
                            type: 'text',
                            translate: 'wx',
                        },
                    ],
                },
            ],
            layersAttributes: [],
            _meta: {},
            entities: mergeClientsAndLocations(
                clients,
                locations,
                normalizedClients,
                privileges,
                rfidLocations
            ),
        }
);

export const getClientsAndLocationsSourceSetWithFilter = getSourceSetWithFilter(
    getClientsAndLocationsSourceSetModel,
    getClientsAndLocationsAsSourceSet
);

export const {
    enterPreviewMode,
    enterCreateMode,
    enterClientEditMode,
    enterLocationAddMode,
    enterLocationEditMode,
    resetMode,
    selectLocation,
    resetLocation,
    selectClient,
    resetClient,
    selectGridItemId,
    toggleCollapseClients,
    toggleCollapseLocations,
    selectClientsAndLocations,
    toggleMapDialog,
    setMapCoords,
    toggleMap,
    setValidRfidFilter,
    resetValidRfidFilter,
    setMap,
} = clientsAndLocations.actions;

export default clientsAndLocations.reducer;
