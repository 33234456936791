import { connect, ResolveThunks } from 'react-redux';

import {
    showFuelTankDialog,
    showRFIDCardDialog,
    hideGlobalDialog,
} from '../../state/ui/dialog';
import { TRootState } from '../../store';

const mapStateToProps = (state: TRootState) => ({
    //userSettings: getUserSettings(state),
});

const mapDispatchToProps = {
    showFuelTankDialog,
    showRFIDCardDialog,
    hideGlobalDialog,
};

type TStateProps = ReturnType<typeof mapStateToProps>;
type TDispatchProps = ResolveThunks<typeof mapDispatchToProps>;

export type TConnectableProps = TStateProps & TDispatchProps;

export const Connectable = connect(mapStateToProps, mapDispatchToProps);
