import React, { Component, ReactNode } from 'react';

import { List as IMList, OrderedMap } from 'immutable';

import { List, ListRowRenderer } from 'react-virtualized/dist/es/List';

interface IProps {
    items: OrderedMap<string, string>;
    collapsedItems: IMList<any>;
    groupRenderer: (
        group: any,
        collapsed: any,
        onClick: (key: string) => void,
        key: string,
        style: object
    ) => ReactNode;
    itemRenderer: (entry: any, key: string, style: object) => ReactNode;
    isGroup: (entry: any) => boolean;
    getItemGroupKey: (entry: any) => string;
    onToggleGroup: (key: string, items: IMList<any>) => void;
    calculateRowHeight: () => number;
    rowCount: number;
    rowHeight: number;
    width: number;
    height: number;
}

interface IState {
    itemsList: IMList<any>;
}

class VirtualTreeView extends Component<IProps, IState> {
    public state = {
        // List operates over index so we need a list
        // map is still required to fast search of concrete item
        itemsList: this.props.items.toList(),
    };

    private listRef: List | null = null;

    public UNSAFE_componentWillReceiveProps(nextProps: IProps) {
        if (!this.props.items.equals(nextProps.items)) {
            this.setState({ itemsList: nextProps.items.toList() });
            if (this.listRef) {
                this.listRef.recomputeRowHeights();
            }
        }
        if (!this.props.collapsedItems.equals(nextProps.collapsedItems)) {
            if (this.listRef) {
                this.listRef.recomputeRowHeights();
            }
        }
    }

    public render() {
        const { rowCount, width, height, calculateRowHeight } = this.props;
        return (
            <List
                ref={(element) => (this.listRef = element)}
                rowCount={rowCount}
                rowHeight={calculateRowHeight || this.calculateRowHeight}
                rowRenderer={this.rowRenderer}
                width={width}
                height={height}
            />
        );
    }

    private isGroup(entry: any) {
        return (
            (this.props.isGroup && this.props.isGroup(entry)) || entry.isGroup
        );
    }

    private getItemGroupKey(entry: any) {
        return (
            (this.props.getItemGroupKey && this.props.getItemGroupKey(entry)) ||
            entry.group.name
        );
    }

    private calculateRowHeight = (row: { index: number }) => {
        const entry = this._getDatum(row.index, this.state.itemsList);
        if (
            entry === undefined ||
            (!this.isGroup(entry) &&
                // @ts-ignore
                this.props.collapsedItems.get(this.getItemGroupKey(entry)))
        ) {
            return 0;
        } else {
            return this.props.rowHeight;
        }
    };

    private _getDatum(index: number, items: IMList<any>) {
        return items.get(index % items.size);
    }

    private handleToggleGroup = (key: string) => {
        const { onToggleGroup } = this.props;

        if (onToggleGroup) {
            onToggleGroup(key, this.props.collapsedItems);
        }
    };

    private rowRenderer: ListRowRenderer = ({ index, key, style }) => {
        const items = this.state.itemsList;
        const entry = this._getDatum(index, items);
        if (entry === undefined) {
            return null;
        }
        let tag: ReactNode = null;
        if (entry && this.isGroup(entry)) {
            tag = this.props.groupRenderer(
                entry,
                this.props.collapsedItems.get(entry.key),
                this.handleToggleGroup,
                key,
                style
            );
        } else if (
            // @ts-ignore

            !this.props.collapsedItems.get(this.getItemGroupKey(entry))
        ) {
            tag = this.props.itemRenderer(entry, key, style);
        }
        return tag;
    };
}

export default VirtualTreeView;
