import React, { Component, ComponentType } from 'react';
import { compose } from 'redux';

import LoadingSpinner from '../loadingSpinner/LoadingSpinner';

import withWidth, { WithWidthProps } from '@material-ui/core/withWidth';
import { withSnackbar, WithSnackbarProps } from 'notistack';
import { Connectable, TConnectableProps } from './Connectable.hoc';

import AppConfig from '../../constants/AppConfig';
import UrlConst from '../../constants/UrlConst';
import fetchLicenseKeys from '../../helpers/fetchLicenseKeys';
import { isMobile } from '../../helpers/IsMobile';
import TranslationHelper from '../../helpers/TranslationHelper';

interface IOwnProps {
    fetchOnlyTranslations: boolean;
}

type TProps = IOwnProps &
    TConnectableProps &
    WithSnackbarProps &
    WithWidthProps;

interface IState {
    configLoaded: boolean;
}
class ConfigPreloadGuard extends Component<TProps, IState> {
    public state = {
        configLoaded: false,
    };
    private componentIsMounted: boolean = false;

    public componentDidMount() {
        const { language, mainTranslationLanguage, savePath } = this.props;
        this.componentIsMounted = true;
        savePath();
        Promise.all(
            this.props.fetchOnlyTranslations
                ? [
                      this.props.fetchContextTranslations(language),
                      this.props.fetchLanguages(),
                  ]
                : [
                      this.props.fetchUserSettings(),
                      this.props.fetchUserRights(),
                      this.props.fetchParamsDefinition(false, undefined, () => {
                          throw new Error();
                      }),
                      this.props.getModules(),
                      this.props.getDictionary('activity-category'),
                      this.props.getDictionary('object-category'),
                      this.props.getDictionary('service-class'),
                      this.props.getDictionary('service-type'),
                      this.props.getDictionary('planning-task-status'),
                      this.props.getDictionary('timezones'),
                      mainTranslationLanguage !== language &&
                          this.props.fetchContextTranslations(language),
                      this.props.restoreState(true, this.showRestoreError),
                      this.props.getStoreBookmarks(),
                      fetchLicenseKeys(),
                  ]
        )
            .then(() => {
                if (this.componentIsMounted === true) {
                    this.setState({ configLoaded: true });
                }
            })
            .catch(() => {
                document.location.href = AppConfig.instance.getUrl(
                    UrlConst.LOGIN_PAGE_URL
                );
            });
    }

    public componentDidUpdate() {
        const {
            hideMenu,
            isMenuOpen,
            restoreState,
            areSearchResultsVisible,
            isMobileSearchOpen,
            toggleSearchResults,
            toggleMobileSearch,
            resetRestorer,
        } = this.props;
        window.onpopstate = () => {
            resetRestorer();
            restoreState();

            if (this.isMobile()) {
                if (isMenuOpen) {
                    hideMenu();
                }
                if (areSearchResultsVisible) {
                    toggleSearchResults(false);
                }
                if (isMobileSearchOpen) {
                    toggleMobileSearch(false);
                }
            }
        };
    }

    public componentWillUnmount() {
        this.componentIsMounted = false;
    }

    public showRestoreError = (context: string): void => {
        this.props.enqueueSnackbar(
            TranslationHelper.translateFormat('Restoring data failed for %s', [
                context,
            ]),
            {
                variant: 'error',
            }
        );
    };

    public isMobile = () => {
        const { width } = this.props;
        return !!isMobile(width);
    };

    public render() {
        return this.state.configLoaded === true ? (
            this.props.children
        ) : (
            <div id="right-container" className="hide-menu-no-animate">
                <LoadingSpinner
                    width="100"
                    height="100"
                    top="0"
                    right="0"
                    bottom="0"
                    left="0"
                    opacity="0.7"
                />
            </div>
        );
    }
}

export default compose(
    Connectable,
    withSnackbar,
    withWidth()
)(ConfigPreloadGuard) as ComponentType<IOwnProps>;
