import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        iconButton: {
            height: 30,
            width: 30,
            padding: 0,
            color: theme.palette.getContrastText(theme.palette.primary.main),
            alignSelf: 'center',
        },
        iconButtonIcon: {
            height: 25,
            width: 25,
        },
    })
);
