import { Dispatch } from 'redux';
import { fetchMonitoredObject } from '../../../../services/discovery/fetchMonitoredObject';
import { makeFetchModule } from '../../../_utils';
import { storeMonitoredObjects } from '../../entities';

const callbacks = {
    onSuccess: (dispatch: Dispatch, { entities }: { entities: object }) => {
        dispatch(storeMonitoredObjects(entities));
    },
};
const { reducer, fetchData, reset } = makeFetchModule(
    'app/discovery/monitoredObjectFetching',
    fetchMonitoredObject,
    callbacks
);

export default reducer;
export { fetchData, reset };
