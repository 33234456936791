import { createStyles, WithStyles } from '@material-ui/core';
import { Theme, withStyles } from '@material-ui/core/styles';

const styles = (theme: Theme) => {
    const spacing = theme.spacing(2);
    return createStyles({
        icon: {
            marginRight: spacing,
            width: '24px',
        },
        textContainer: {
            flex: 1,
            minWidth: 0,
        },
        name: {
            fontWeight: 'bold',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
        location: {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
        date: {
            fontSize: '0.8em',
        },
    });
};

export type TThemableProps = WithStyles<typeof styles>;

export const Themable = withStyles(styles, { withTheme: true });
