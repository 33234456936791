import debounce from 'lodash.debounce';

class TypeAheadHandler {
    public inputStringLength: number;
    public isClear: boolean;
    public action: (name: string) => void;

    constructor(
        inputStringLength: number,
        action: (name: string) => void,
        previousValue: string
    ) {
        this.action = debounce(action, 250);
        this.inputStringLength = inputStringLength;
        this.isClear = !previousValue || previousValue.length === 0;
    }

    public change(inputString: string) {
        const length = inputString.length;

        if (!this.isClear && length < this.inputStringLength) {
            this.action('');
            this.isClear = true;
        } else if (length >= this.inputStringLength) {
            this.action(inputString);
            this.isClear = false;
        }
    }
}

export default TypeAheadHandler;
