import { Dispatch } from 'react';
import {
    IGroupAlertCondition,
    IParamAlertCondition,
    TAnyAlertCondition,
} from '../../../services/alerts';
import {
    fetchParamsDefinitions,
    fetchAlertDefinitions,
    fetchEventTypes,
    setChosenParams,
    fetchLayers,
    conditionTypes,
} from '../../../state/app/alerts';
import { convertParamType } from './utils';
import { LAYER_ALERT_DEFINITION } from '../components/LayerAlertDefinitionForm/LayerAlertDefinitionForm';
import { IParamDefinitionItem } from '../../../state/app/calibrations';

type TFetch = (afterSuccess: (data: any[]) => void) => void;
type TFetchNotMarked = (
    marker: string,
    fetch: TFetch,
    storeResults?: boolean
) => Promise<any>;

const initializeFetch = () => {
    const store = {};
    const fetches = {};
    const markers: string[] = [];
    return async (marker: string, fetch: TFetch, storeResults?: boolean) => {
        if (!markers.includes(marker)) {
            markers.push(marker);
            const request = fetch(
                (data) => storeResults && (store[marker] = data)
            );
            if (storeResults) {
                fetches[marker] = request;
            }
            await request;
        } else {
            if (storeResults && fetches[marker]) {
                await fetches[marker];
            }
        }
        return store[marker];
    };
};

const fetchGroupAlertCondition = async (
    conditionData: IGroupAlertCondition,
    fetchNotMarked: TFetchNotMarked,
    dispatch: Dispatch<any>,
    objectId?: number
) => {
    const promises =
        conditionData.conditions?.map((condition: TAnyAlertCondition) =>
            fetchConditionData(condition, fetchNotMarked, dispatch, objectId)
        ) || [];
    await Promise.all(promises);
};

const fetchParamAlertCondition = async (
    condition: IParamAlertCondition,
    fetchNotMarked: TFetchNotMarked,
    dispatch: Dispatch<any>,
    objectId?: number
) => {
    const params = await fetchNotMarked(
        `paramsDefinitions${objectId}`,
        (afterSuccess: (data: any[]) => void) =>
            dispatch(
                fetchParamsDefinitions(
                    objectId?.toString() || '',
                    async (paramsData) => {
                        afterSuccess(paramsData);
                    }
                )
            ),
        true
    );
    if (params) {
        const currentParam = (params as IParamDefinitionItem[]).find(
            (param) => {
                return param.name === condition.paramName;
            }
        );
        if (currentParam) {
            dispatch(setChosenParams(currentParam));
        }
        if (currentParam) {
            await fetchNotMarked(
                `alertDefintions${objectId}${convertParamType(
                    currentParam.type
                )}`,
                () =>
                    dispatch(
                        fetchAlertDefinitions(
                            convertParamType(currentParam.type)
                        )
                    )
            );
        }
    }
};

const fetchConditionData = async (
    conditionData: TAnyAlertCondition,
    fetchNotMarked: TFetchNotMarked,
    dispatch: Dispatch<any>,
    objectId?: number
) => {
    switch (conditionData.type) {
        case conditionTypes.and:
        case conditionTypes.or:
            await fetchGroupAlertCondition(
                conditionData as IGroupAlertCondition,
                fetchNotMarked,
                dispatch,
                objectId
            );
            break;
        case conditionTypes.param:
            await fetchParamAlertCondition(
                conditionData as IParamAlertCondition,
                fetchNotMarked,
                dispatch,
                objectId
            );
            break;
        case conditionTypes.event:
            await fetchNotMarked('events', () =>
                dispatch(fetchEventTypes(true))
            );
            break;
        case conditionTypes.layer:
            await fetchNotMarked('layers', () => dispatch(fetchLayers()));
            await fetchNotMarked(
                `alertDefintions${objectId}${LAYER_ALERT_DEFINITION}`,
                () => dispatch(fetchAlertDefinitions(LAYER_ALERT_DEFINITION))
            );
            break;
        default:
            break;
    }
};

export const fetchConditionsData = async (
    conditionData: TAnyAlertCondition,
    dispatch: Dispatch<any>,
    objectId?: number
) => {
    const fetchNotMarked = initializeFetch();
    await fetchConditionData(conditionData, fetchNotMarked, dispatch, objectId);
};
