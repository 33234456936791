import { ISourceSetEntity } from '../../../../../state/types';
import {
    IMinuteData,
    ISegment,
    MINUTES,
    TTachoActivity,
} from '../TachograppChart';
import { getMinuteInfo } from './helpers';

const STATUS_UNAVAILABLE = 7;

const STATUS_TO_ACTIVITY: { [key: number]: TTachoActivity } = {
    0: 'resting',
    1: 'available',
    2: 'working',
    3: 'driving',
    6: 'unavailable',
    7: 'unavailable',
};

export const processMinuteData = (
    eventsArray: ISourceSetEntity[],
    tachoKey: 'TACHO_DR1_WORK_ST' | 'TACHO_DR2_WORK_ST'
) => {
    // group events by minute and keep only the last event per minute
    const eventsByMinute = new Map<number, ISourceSetEntity>();
    eventsArray.forEach((event) => {
        if (!event.updatedAt || event[tachoKey] === undefined) {
            return;
        }
        const { minute } = getMinuteInfo(event.updatedAt);
        eventsByMinute.set(minute, event);
    });

    // populate minuteData - if no event with tacho param found in given minute, use last one
    let lastEvent = null;
    let currentSegmentId = 0;
    const segments = new Map<number, ISegment>();
    const minuteData: IMinuteData[] = [];

    for (let minute = 0; minute < MINUTES; minute++) {
        let event: ISourceSetEntity | null,
            activity: TTachoActivity,
            status: number,
            lastEventTimestamp: string | null = null;

        if (eventsByMinute.has(minute)) {
            event = eventsByMinute.get(minute) ?? null;
        } else {
            event = lastEvent;
            lastEventTimestamp = lastEvent?.updatedAt ?? null;
        }

        status = event?.[tachoKey] ?? STATUS_UNAVAILABLE;
        activity = STATUS_TO_ACTIVITY[status] || 'unavailable';
        lastEvent = event;

        if (minute === 0 || minuteData[minute - 1].activity !== activity) {
            currentSegmentId++;
            segments.set(minute, {
                id: currentSegmentId,
                activity,
                startTime: minute,
                status,
                duration: 0,
            });
        }
        minuteData.push({
            event,
            minute,
            segmentId: currentSegmentId,
            driving: activity === 'driving' ? 1 : 0,
            resting: activity === 'resting' ? 1 : 0,
            working: activity === 'working' ? 1 : 0,
            available: activity === 'available' ? 1 : 0,
            error: activity === 'error' ? 1 : 0,
            unavailable: activity === 'unavailable' ? 0.1 : 0,
            activity,
            lastEventTimestamp:
                activity === 'unavailable' ? null : lastEventTimestamp,
        });
    }

    segments.forEach((segment) => {
        let duration = 1;
        let minute = segment.startTime + 1;
        while (
            minute < MINUTES &&
            minuteData[minute].segmentId === segment.id
        ) {
            duration++;
            minute++;
        }
        segment.duration = duration;
    });

    return { data: minuteData, segments };
};
