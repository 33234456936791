import React, { Component, ComponentType } from 'react';
import { compose } from 'redux';

import classNames from 'classnames';

import hasAccess from '../../components/accessControl/hasAccess';
import StateRestorer from '../../components/StateRestorer';
import View from '../../components/View/View';
import PreviewPane from './components/PreviewPane';
import MapPaneComponents from './MapPaneComponents';

import { Connectable, TConnectableProps } from './Connectable.hoc';
import { Themable, TThemableProps } from './Themable.hoc';

import { isMobile } from '../../helpers/IsMobile';
import TimeFormatter from '../../helpers/TimeFormatter';
import TranslationHelper from '../../helpers/TranslationHelper';

import { areLastStatesEnabled } from '../../constants/AppConfig';

import withWidth, { WithWidthProps } from '@material-ui/core/withWidth';
import moment from 'moment-timezone';
import { Prompt } from 'react-router-dom';

import DataGridPaneMobile from './components/DataGridPaneMobile';
import MobileNavigation from './components/MobileNavigation';

import ErrorBoundary from '../../components/ErrorBoundary';
import {
    withMediaQuery,
    IWithMediaQueryProps,
} from '../../components/WithMediaQuery/WithMediaQuery';

import Bookmarks from './components/Bookmarks/Bookmarks';
import SearchResults from './components/SearchResults';
import { TDataGridPane } from 'src/app/state/ui/discovery/snapshotting';
import DiscoveryGrid from './components/DiscoveryGrid/DiscoveryGrid';

type TProps = TConnectableProps &
    TThemableProps &
    WithWidthProps &
    IWithMediaQueryProps;

type TStyling = void | {
    predicate: () => boolean;
    style: object;
};

class DiscoveryPage extends Component<TProps> {
    componentDidMount() {
        if (areLastStatesEnabled()) {
            if (this.props.gridSnapshot?.sourceSetId !== 'lastStates') {
                this.fetchSourceSets();
            } else {
                this.props.fetchLastStates({ differential: true });
            }
        } else {
            this.fetchSourceSets();
        }
    }

    componentDidUpdate(prevProps: TProps) {
        this.fetchSourceSets(prevProps);
    }

    componentWillUnmount() {
        this.props.stopFetchingCollection('lastStates');
        this.props.stopFetchingCollection(this.props.sourceSet?.id || '');
    }

    fetchSourceSets = (prevProps?: TProps) => {
        const {
            isDataGridPaneVisible,
            fetchLastStates,
            fetchLocations,
            fetchRoutes,
            gridSnapshot,
            isStateRestoring,
            notifyRestorerToWait,
            notifyRestorerToContinue,
            fetchSourceSet,
            gridFilters,
        } = this.props;

        const isDifferentSourceSet = prevProps
            ? gridSnapshot?.sourceSetId !==
                  prevProps?.gridSnapshot?.sourceSetId ||
              gridSnapshot?.filters !== prevProps.gridSnapshot?.filters
            : true;

        if (
            gridSnapshot &&
            (isDataGridPaneVisible ||
                gridSnapshot.sourceSetId === 'lastStates') &&
            isDifferentSourceSet
        ) {
            const { sourceSetDates, sourceSetAction } = gridSnapshot;

            const currentDay = TimeFormatter.dateToShortDateString(moment());
            const from = sourceSetDates?.from || currentDay;
            const to = sourceSetDates?.to || currentDay;
            switch (gridSnapshot.sourceSetId) {
                case 'lastStates':
                    fetchLastStates({ ...gridFilters, differential: true });

                    break;
                case 'locations':
                    fetchLocations();
                    break;
                case 'routes':
                    fetchRoutes({ from, to });
                    this.handleSourceSetDates(from, to);
                    break;
                case 'tasks': {
                    this.handleFetchTasks(gridSnapshot, from, to);
                    break;
                }
                default:
                    if (sourceSetAction !== null) {
                        sourceSetAction.params = {
                            ...sourceSetAction.params,
                            ...gridFilters,
                        };
                        if (isStateRestoring) {
                            notifyRestorerToWait();
                        }

                        (
                            fetchSourceSet(
                                sourceSetAction
                            ) as unknown as Promise<any>
                        ).then(() => {
                            if (isStateRestoring) {
                                notifyRestorerToContinue();
                            }
                        });
                    }
            }
        }
    };

    handleFetchTasks = (snapshot: TDataGridPane, from: string, to: string) => {
        const {
            fetchTasks,
            setNotAssignedFilter,
            setNotPlannedFilter,
            setIncludeNotPlanned,
        } = this.props;

        const { onlyNotAssigned, onlyNotPlanned } = snapshot;

        const fetchTasksParams = () => {
            return onlyNotPlanned
                ? {
                      onlyNotAssigned,
                      includeNotPlanned: true,
                  }
                : {
                      from,
                      to,
                      onlyNotAssigned,
                      includeNotPlanned: true,
                  };
        };

        fetchTasks(fetchTasksParams());
        this.handleSourceSetDates(from, to);
        setNotAssignedFilter(snapshot.sourceSetId, !!onlyNotAssigned);
        setNotPlannedFilter(snapshot.sourceSetId, !!onlyNotPlanned);

        setIncludeNotPlanned(snapshot.sourceSetId, true);
    };

    handleSourceSetDates = (from: string, to: string) => {
        const { gridSnapshot, setSourceSetDates } = this.props;
        const shortFrom = TimeFormatter.dateToShortDateString(from);
        const shortTo = TimeFormatter.dateToShortDateString(to);
        gridSnapshot &&
            setSourceSetDates(gridSnapshot.sourceSetId, shortFrom, shortTo);
    };

    isMobile = () => {
        const { width, mediaQuery, isDataGridFullscreen } = this.props;
        return !!(!mediaQuery && isMobile(width) && !isDataGridFullscreen);
    };

    getPromptMessage = (location: any, action?: string) => {
        return action === 'REPLACE' || (action === 'PUSH' && this.isMobile())
            ? true
            : TranslationHelper.translate(
                  'Unsaved data will be lost. Do you want to continue?'
              );
    };

    getMapPaneComponentsStyling = (
        isBottomPaneVisible: boolean,
        isGridFullscreen: boolean
    ): TStyling => {
        const { isPreviewPaneVisible } = this.props;

        return [
            {
                predicate: () => this.isMobile(),
                style: {
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0,
                },
            },
            {
                predicate: () =>
                    !isPreviewPaneVisible &&
                    !isBottomPaneVisible &&
                    !isGridFullscreen,
                style: {
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0,
                },
            },
            {
                predicate: () =>
                    !isPreviewPaneVisible &&
                    isBottomPaneVisible &&
                    !isGridFullscreen,
                style: {
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    bottom: 'calc(50% + 10px)',
                    left: 0,
                },
            },
            {
                predicate: () =>
                    isPreviewPaneVisible &&
                    isBottomPaneVisible &&
                    !isGridFullscreen,
                style: {
                    position: 'absolute',
                    top: 0,
                    right: '430px',
                    bottom: 'calc(50% + 10px)',
                    left: 0,
                },
            },
            {
                predicate: () =>
                    isPreviewPaneVisible &&
                    !isBottomPaneVisible &&
                    !isGridFullscreen,
                style: {
                    position: 'absolute',
                    top: 0,
                    right: '430px',
                    bottom: 0,
                    left: 0,
                },
            },
            {
                predicate: () => isBottomPaneVisible && isGridFullscreen,
                style: { visibility: 'hidden' },
            },
        ].find(({ predicate }) => predicate());
    };

    getMobileBottomPaneStyling = (): TStyling => {
        const { mobileUIState, isMobileGridVisible } = this.props;

        return [
            {
                predicate: () =>
                    !!mobileUIState?.dataGridPane && !!isMobileGridVisible,
                style: {
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0,
                },
            },
        ].find(({ predicate }) => predicate());
    };

    getMobileMapPaneComponentsStyling = (): TStyling =>
        [
            {
                predicate: () => true,
                style: {
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    bottom: 56,
                    left: 0,
                },
            },
        ].find(({ predicate }) => predicate());

    getMobilePreviewPaneStyling = (): TStyling => {
        const { mobileUIState } = this.props;

        return [
            {
                predicate: () => !!mobileUIState?.previewPane,
                style: {
                    position: 'absolute',
                    right: 0,
                    bottom: 0,
                    left: 0,
                    zIndex: 1100,
                },
            },
        ].find(({ predicate }) => predicate());
    };

    getPreviewPaneStyling = (
        isBottomPaneVisible: boolean,
        isGridFullscreen: boolean
    ): TStyling => {
        const { isPreviewPaneVisible } = this.props;
        return [
            {
                predicate: () =>
                    isPreviewPaneVisible &&
                    isBottomPaneVisible &&
                    !isGridFullscreen,
                style: {
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 'calc(100% - 420px)',
                },
            },
            {
                predicate: () =>
                    isPreviewPaneVisible &&
                    !isBottomPaneVisible &&
                    !isGridFullscreen,
                style: {
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 'calc(100% - 420px)',
                },
            },
            {
                predicate: () =>
                    isPreviewPaneVisible &&
                    isBottomPaneVisible &&
                    isGridFullscreen,
                style: {
                    display: 'none',
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 'calc(100% - 420px)',
                },
            },
        ].find(({ predicate }) => predicate());
    };

    renderDesktop = (
        layoutClassName: string,
        isBottomPaneVisible: boolean,
        isGridFullscreen: boolean
    ) => {
        const previewPaneStyling = this.getPreviewPaneStyling(
            isBottomPaneVisible,
            isGridFullscreen
        );

        if (this.isMobile()) {
            return null;
        }

        return (
            <>
                <DiscoveryGrid
                    layoutClassName={layoutClassName}
                    isBottomPaneVisible={isBottomPaneVisible}
                />

                {previewPaneStyling && (
                    <div style={previewPaneStyling.style}>
                        <PreviewPane />
                    </div>
                )}
            </>
        );
    };

    renderMobile = () => {
        const { sourceSet, creatorLevel, classes } = this.props;

        const mobileBottomPaneStyling = this.getMobileBottomPaneStyling();
        const mobilePreviewPaneStyling = this.getMobilePreviewPaneStyling();

        if (!this.isMobile()) {
            return null;
        }
        return (
            <>
                <ErrorBoundary>
                    <Bookmarks />
                </ErrorBoundary>
                {mobilePreviewPaneStyling && (
                    <div
                        className={classes.mobilePreviewPane}
                        style={mobilePreviewPaneStyling.style}
                    >
                        <PreviewPane />
                    </div>
                )}
                {mobileBottomPaneStyling && (
                    <DataGridPaneMobile
                        sourceSet={sourceSet}
                        creatorLevel={creatorLevel}
                    />
                )}
                <div className={classes.mobileNav}>
                    <MobileNavigation />
                </div>
            </>
        );
    };

    render() {
        const {
            isPreviewPaneVisible,
            isDataGridPaneVisible,
            isChartPaneVisible,
            isCalibrationChartPaneVisible,
            isDetectionChartPaneVisible,
            isDataGridFullscreen,
            isStateRestoring,
            isAnalyticsEnabled,
            nextSnapshot,
            shouldRestorerWait,
            areSearchResultsVisible,
            classes,
            editMode,
        } = this.props;

        const {
            layout,
            layoutMap,
            layoutMapGrid,
            layoutMapGridPreview,
            layoutMapPreview,
            layoutFullGrid,
            content,
        } = classes;

        const isGridFullscreen = isDataGridFullscreen || isAnalyticsEnabled;

        const isBottomPaneVisible =
            isDataGridPaneVisible ||
            isChartPaneVisible ||
            isCalibrationChartPaneVisible ||
            isDetectionChartPaneVisible;

        const layoutClassName = classNames({
            [layout]: true,
            [layoutMapGridPreview]: isPreviewPaneVisible && isBottomPaneVisible,
            [layoutMapGrid]: !isPreviewPaneVisible && isBottomPaneVisible,
            [layoutMapPreview]: isPreviewPaneVisible && !isBottomPaneVisible,
            [layoutMap]: !isPreviewPaneVisible && !isBottomPaneVisible,
            [layoutFullGrid]: isBottomPaneVisible && isGridFullscreen,
        });

        const mobileMapPaneComponentsStyling =
            this.getMobileMapPaneComponentsStyling();

        const mapPaneComponentsStyling = this.getMapPaneComponentsStyling(
            isBottomPaneVisible,
            isGridFullscreen
        );

        const mapPaneStyling = this.isMobile()
            ? mobileMapPaneComponentsStyling
            : mapPaneComponentsStyling;

        // TODO This component renders too often (every time the lastState source set is being fetched on autor refresh)
        // window.console.log('GRID:', layoutClassName)
        return (
            <View
                title={TranslationHelper.translate('Discovery')}
                noPadding={true}
                noColumn={true}
            >
                <div className={content}>
                    <StateRestorer
                        isStateRestoring={isStateRestoring}
                        shouldRestorerWait={shouldRestorerWait}
                        restorerHandler={nextSnapshot}
                    >
                        {this.isMobile() && areSearchResultsVisible && (
                            <SearchResults isMobile={true} />
                        )}

                        {mapPaneStyling && !isGridFullscreen && (
                            <div style={mapPaneStyling.style}>
                                <ErrorBoundary>
                                    <MapPaneComponents
                                        layoutClassName={layoutClassName}
                                        searchEnabled={areLastStatesEnabled()}
                                    />
                                </ErrorBoundary>
                            </div>
                        )}
                        {this.renderDesktop(
                            layoutClassName,
                            isBottomPaneVisible,
                            isGridFullscreen
                        )}
                        {this.renderMobile()}
                    </StateRestorer>

                    <Prompt when={editMode} message={this.getPromptMessage} />
                </div>
            </View>
        );
    }
}

export default compose(
    Themable,
    Connectable,
    hasAccess((privileges) => privileges.discoveryContext),
    withWidth(),
    withMediaQuery('print')
)(DiscoveryPage) as ComponentType<{}>;
