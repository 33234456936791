import { TParamDefinitions } from '../state/app/monitoredObjects';

export default (
    defs: TParamDefinitions | undefined,
    name: string,
    data = {}
) => {
    if (!name) {
        return;
    }
    const def = defs && defs.find((definition) => definition.name === name);
    const value = def && data[def.id];
    return value && value.value;
};
