import { createSelector } from 'reselect';
import { TRootState } from '../../../../store';

const getEntities = (state: TRootState) => state.app.entities;

export const getLastStates = (state: TRootState) =>
    getEntities(state).lastStates;

export const getMonitoredObjects = (state: TRootState) =>
    getEntities(state).monitoredObjects;

export const getSearchedMonitoredObject = (
    state: TRootState,
    monitoredObjectId: string
) => getMonitoredObjects(state)[monitoredObjectId];

export const getTasks = (state: TRootState) => getEntities(state).tasks;

export const getReportSets = (state: TRootState) =>
    getEntities(state).reportSets;

export const getSourceSets = (state: TRootState) =>
    getEntities(state).sourceSets;
export const getSourceSetElements = (state: TRootState) =>
    getEntities(state).sourceSetElements;

export const getFramesElements = (state: TRootState) =>
    getEntities(state).frames;
export const getRecognizedNumbersSection = (state: TRootState) =>
    getEntities(state).reports['containerNumbersParams'];
export const getAggregationElements = (state: TRootState) =>
    getEntities(state).aggregations;

export const getTrails = (state: TRootState) => getEntities(state).trails;
export const getLocations = (state: TRootState) => getEntities(state).locations;
export const getLocationsRegister = (state: TRootState) =>
    getEntities(state).locationsRegister;

export const getUsersSourceSet = createSelector(
    [getSourceSets],
    (sets) => (sets && sets.users) || null
);

export const getAlertSettingsSourceSet = createSelector(
    [getSourceSets],
    (sets) => (sets && sets.alertSettings) || null
);
