import { connect } from 'react-redux';

import { TRootState } from '../../../store';

const mapStateToProps = (state: TRootState) => ({});

const mapDispatchToProps = {};

type TStateProps = ReturnType<typeof mapStateToProps>;
type TDispatchProps = typeof mapDispatchToProps;

export type TConnectableProps = TStateProps & TDispatchProps;

export const Connectable = connect(mapStateToProps, mapDispatchToProps);
