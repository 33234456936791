import { createStyles, Theme, WithStyles } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme: Theme) => {
    const padding = theme.spacing(2);
    return createStyles({
        pane: {
            height: '100%',
            position: 'relative',
            zIndex: 200,
        },
        bar: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginRight: -padding,
            color: theme.palette.getContrastText(theme.palette.primary.main),
        },
        contrastText: {
            color: theme.palette.getContrastText(theme.palette.primary.main),
        },
        titleControls: {
            display: 'flex',
            justifyContent: 'space-around',
            alignItems: 'center',
            color: theme.palette.getContrastText(theme.palette.primary.main),
        },
    });
};

export type TThemableProps = WithStyles<typeof styles>;

export const Themable = withStyles(styles, { withTheme: true });
