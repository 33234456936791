import React, { Component, ComponentType } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import {
    Button,
    Dialog,
    DialogContent,
    FormControlLabel,
} from '@material-ui/core';

import { Field, Form, Formik, FormikErrors } from 'formik';

import CheckboxWrapper from '../../../../components/common/formikWrappers/CheckboxWrapper';
import FieldWrapper from '../../../../components/common/formikWrappers/FieldWrapper';

import TranslationHelper from '../../../../helpers/TranslationHelper';

import { Themable, TThemableProps } from './Themable.hoc';

import DialogTitle from '../../../dialogs/DialogTitle';

import { IFormValues } from './types';
import { ISourceSetActionOptions } from '../../../../state/ui/discovery/types';

interface IOwnProps {
    isOpen: boolean;
    confirm: (values: IFormValues) => void;
    close: () => void;
    title: string;
    options: ISourceSetActionOptions[];
}

type TProps = IOwnProps & TThemableProps;

class SettingsDialog extends Component<TProps> {
    public render() {
        const { classes, isOpen, close, title, options } = this.props;

        const initialValues: IFormValues = options.reduce(
            (obj, item) => ((obj[item.id] = item.default), obj),
            {}
        );

        return (
            <>
                <Dialog open={isOpen} onClose={close}>
                    <DialogTitle title={title} close={close} />
                    <DialogContent>
                        <div>
                            <Formik
                                enableReinitialize={true}
                                initialValues={initialValues}
                                validateOnBlur={false}
                                validateOnChange={true}
                                onSubmit={this.acceptOptions}
                                validate={(values) => {
                                    const errors: FormikErrors<IFormValues> =
                                        {};

                                    options.forEach((option) => {
                                        if (option.type === 'integer') {
                                            if (
                                                !Number.isInteger(
                                                    values[option.id] as number
                                                )
                                            ) {
                                                errors[option.id] =
                                                    TranslationHelper.translate(
                                                        'Enter an integer'
                                                    );
                                            }
                                            if (
                                                option.min &&
                                                values[option.id] < option.min
                                            ) {
                                                errors[
                                                    option.id
                                                ] = `${TranslationHelper.translate(
                                                    'Minimum value is'
                                                )}: ${option.min}`;
                                            }
                                            if (
                                                option.max &&
                                                values[option.id] > option.max
                                            ) {
                                                errors[
                                                    option.id
                                                ] = `${TranslationHelper.translate(
                                                    'Maximum value is'
                                                )}: ${option.max}`;
                                            }
                                        }
                                    });
                                    return errors;
                                }}
                            >
                                {({
                                    submitForm,
                                    errors,
                                    values,
                                    setFieldValue,
                                }) => (
                                    <Form className={classes.optionsWrapper}>
                                        {options.map((option) => {
                                            if (option.type === 'boolean') {
                                                return (
                                                    <FormControlLabel
                                                        key={option.id}
                                                        label={TranslationHelper.translate(
                                                            option.label
                                                        )}
                                                        control={
                                                            <Field
                                                                name={option.id}
                                                                label={TranslationHelper.translate(
                                                                    option.label
                                                                )}
                                                                type={
                                                                    option.type
                                                                }
                                                                component={
                                                                    CheckboxWrapper
                                                                }
                                                                onClick={() => {
                                                                    setFieldValue(
                                                                        option.id,
                                                                        !values[
                                                                            option
                                                                                .id
                                                                        ]
                                                                    );
                                                                }}
                                                                className={
                                                                    classes.picker
                                                                }
                                                            />
                                                        }
                                                    />
                                                );
                                            } else {
                                                return (
                                                    <Field
                                                        key={option.id}
                                                        error={
                                                            !!(
                                                                errors &&
                                                                errors[
                                                                    option.id
                                                                ]
                                                            )
                                                        }
                                                        helperText={
                                                            errors &&
                                                            errors[option.id]
                                                        }
                                                        name={option.id}
                                                        label={TranslationHelper.translate(
                                                            option.label
                                                        )}
                                                        type={
                                                            option.type ===
                                                            'integer'
                                                                ? 'number'
                                                                : option.type
                                                        }
                                                        step={
                                                            option.type ===
                                                            'integer'
                                                                ? '1'
                                                                : undefined
                                                        }
                                                        required={true}
                                                        component={FieldWrapper}
                                                        className={
                                                            classes.picker
                                                        }
                                                    />
                                                );
                                            }
                                        })}
                                        <div className={classes.acceptButton}>
                                            <Button
                                                color="primary"
                                                variant="contained"
                                                type="submit"
                                            >
                                                {TranslationHelper.translate(
                                                    'Accept'
                                                )}
                                            </Button>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </DialogContent>
                </Dialog>
            </>
        );
    }

    private acceptOptions = (values: IFormValues) => {
        this.props.confirm(values);
    };
}

export default compose(
    Themable,
    connect()
)(SettingsDialog) as ComponentType<IOwnProps>;
