import React, { Component } from 'react';

import { IVehicle } from '../../../state/app/registers/vehicles';

import Translator from '../../../helpers/TranslationHelper';
import GroupsList from '../_shared/GroupsList';

interface IProps {
    vehicle: IVehicle;
    onEdit?: (vehicle: IVehicle) => void;
    onAssign?: (vehicle: IVehicle) => void;
}

class VehicleRow extends Component<IProps> {
    public handleEditClick = () => {
        if (this.props.onEdit) {
            this.props.onEdit(this.props.vehicle);
        }
    };

    public handleAssignClick = () => {
        if (this.props.onAssign) {
            this.props.onAssign(this.props.vehicle);
        }
    };

    public _renderActions() {
        return (
            <td>
                <button
                    className="button button-link"
                    onClick={this.handleEditClick}
                >
                    <span className="icon icon-pencil2"> </span>
                    {Translator.translate('Edit')}
                </button>
                <button
                    className="button button-link"
                    onClick={this.handleAssignClick}
                >
                    <span className="icon icon-add-card"> </span>
                    {Translator.translate('Assigning cards')}
                </button>
            </td>
        );
    }

    renderGroups = () => {
        const groups = this.props.vehicle.groups;
        if (groups) {
            return <GroupsList groups={groups} />;
        }
        return null;
    };

    public render() {
        const vehicle = this.props.vehicle;

        let cards = null;
        if (vehicle.cards) {
            cards = vehicle.cards.map((card, idx) => (
                <div key={idx}>
                    {card.cardNumber} <br />
                </div>
            ));
        }

        return (
            <tr>
                <td className="column">{vehicle.name}</td>
                <td className="column">{this.renderGroups()}</td>
                <td className="column">{vehicle.plateNumber}</td>
                <td className="column">{vehicle.sideNumber}</td>
                <td className="column">{cards}</td>
                <td className="column">
                    {vehicle.timeZone && vehicle.timeZone.name}
                </td>
                {this._renderActions()}
            </tr>
        );
    }
}

export default VehicleRow;
