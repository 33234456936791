import { useSelector } from 'react-redux';
import {
    getAds,
    getCustomer,
    getCustomers,
    getFromPath,
    getLanguage,
    getLanguages,
    getLoginError,
} from './index';

export const useLanguage = () => useSelector(getLanguage);
export const useLanguages = () => useSelector(getLanguages);
export const useCustomers = () => useSelector(getCustomers);
export const useCustomer = () => useSelector(getCustomer);

export const useAds = () => useSelector(getAds);

export const useLoginError = () => useSelector(getLoginError);
export const useFromPath = () => useSelector(getFromPath);
