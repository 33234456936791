import React from 'react';

import { instanceOf } from 'prop-types';

import Immutable from 'immutable';

import Tooltip from '@material-ui/core/Tooltip';

import { Connectable } from '../Connectable.hoc';

import { restGetGroupsWithQuantity } from '../../../services/groups';

import {
    restGetRfidCardsByGroup,
    restGetSentCards,
} from '../../../services/registers';

import RFIDCard from '../../../models/registers/RFIDCard';

import Translator from '../../../helpers/TranslationHelper';
import {
    CARD_SHORTCUTS,
    CARD_TYPES,
} from '../../../constants/dictionaries/RFIDCardTypes';
import FuelTank from '../../../models/registers/FuelTank';
import LoadingSpinner from '../../loadingSpinner/LoadingSpinner';
import VirtualTreeView from '../../common/VirtualTreeView';
import ValidationMessage from '../../common/ValidationMessage';
import PrimaryButton from '../../button/PrimaryButton';
import SecondaryButton from '../../button/SecondaryButton';
import Dialog from '../../dialog/Dialog';

const TYPES = CARD_TYPES.slice(1);
const COLOR_CLASSES = [
    'type-info gray',
    'type-info red',
    'type-info blue',
    'type-info green',
];

class SendRFIDCardsDialog extends React.Component {
    constructor(props) {
        super(props);
        this._onCancel = this._onCancel.bind(this);
        this._onSubmit = this._onSubmit.bind(this);
        this._onClearAll = this._onClearAll.bind(this);
        this._handleAvailableCardsClick =
            this._handleAvailableCardsClick.bind(this);
        this._handleSentCardsClick = this._handleSentCardsClick.bind(this);
        this._availableItemRenderer = this._availableItemRenderer.bind(this);
        this._availableGroupRenderer = this._availableGroupRenderer.bind(this);
        this._sentItemRenderer = this._sentItemRenderer.bind(this);
        this._sentGroupRenderer = this._sentGroupRenderer.bind(this);

        this.state = this._getInitialState();
    }

    _getInitialState() {
        return {
            availableCards: Immutable.OrderedMap(), // key is group name, value is object with _meta property and rfid code cards map
            flatAvailableCards: Immutable.OrderedMap(), // used for tree view, keys are group name or rfid code, example order: group, card, card, group
            collapsedAvailableCards: Immutable.OrderedMap(), // key is group name, value is boolean collapse state
            sentCards: Immutable.OrderedMap(), // key is group name, value is object with rfid codes cards map
            flatSentCards: Immutable.OrderedMap(), // same as flatAvailableCards
            collapsedSentCards: Immutable.OrderedMap(), // key is group name, value is true/false collapse state
            initialized: false,
            minimalSetError: false,
            sendingCards: false,
            validationMessage: undefined,
            loadedCards: Immutable.OrderedMap(),
            loading: {},
        };
    }

    componentDidMount() {
        this._isMounted = true;
        let fuelTank = this.props.fuelTank;
        let promises = [
            restGetGroupsWithQuantity('RFID_CARD'),
            restGetSentCards(fuelTank.id),
        ];
        Promise.all(promises).then((results) => {
            this._prepareSentCards(results[1]);
            const nonEmptyGroups = results[0].filter((group) => group.quantity);
            const availableCards = this._prepareAvailableGroups(nonEmptyGroups);
            const flatAvailableCards = this._flattenCardsMap(availableCards);
            const collapsedAvailableCards = flatAvailableCards.map(() => true);
            this.setState({
                initialized: true,
                availableCards,
                flatAvailableCards,
                collapsedAvailableCards,
            });
        });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.loading !== this.state.loading) {
            const currentLoading = { ...this.state.loading };
            const loadingGroups = Object.keys(currentLoading);
            let hasChanged = loadingGroups
                .map((groupKey) => {
                    return this._handleLoadingGroupOfCards(
                        groupKey,
                        currentLoading
                    );
                })
                .includes(true);
            if (hasChanged) {
                this.setState({ loading: currentLoading });
            }
        }
    }

    _handleLoadingGroupOfCards(groupKey, currentLoading) {
        const status = currentLoading[groupKey];
        switch (status) {
            case 'pending':
            case 'moving': {
                const currentCards = this.state.availableCards.get(groupKey);
                const groupMeta = currentCards.get('_meta');
                this._loadGroupOfCards(groupMeta.id, groupKey).then(() =>
                    this.setState({
                        loading: {
                            ...this.state.loading,
                            [groupKey]: 'finished_' + status,
                        },
                    })
                );
                currentLoading[groupKey] = 'loading';
                const availableCards = this.state.availableCards.set(
                    groupKey,
                    currentCards.set('_meta', { ...groupMeta, loading: true })
                );
                const flatAvailableCards =
                    this._flattenCardsMap(availableCards);
                this.setState({ availableCards, flatAvailableCards });
                return true;
            }
            case 'finished_pending': {
                const loadedGroup = this.state.loadedCards.get(groupKey);
                const loadedCards = this.state.loadedCards.set(
                    groupKey,
                    loadedGroup
                );
                const availableCards =
                    this._prepareAvailableCardsFromLoadedCards(
                        groupKey,
                        loadedGroup
                    );
                const flatAvailableCards =
                    this._flattenCardsMap(availableCards);
                this.setState({
                    availableCards,
                    flatAvailableCards,
                    loadedCards,
                });
                delete currentLoading[groupKey];
                return true;
            }
            case 'finished_moving': {
                const loadedGroup = this.state.loadedCards.get(groupKey);
                const availableCards =
                    this._prepareAvailableCardsFromLoadedCards(
                        groupKey,
                        loadedGroup
                    );
                this._moveAvailableGroup(groupKey, loadedGroup, availableCards);
                delete currentLoading[groupKey];
                return true;
            }
        }
        return false;
    }

    _prepareAvailableCardsFromLoadedCards(groupKey, loadedGroup) {
        const sentGroup = this.state.sentCards.get(groupKey);
        const filteredCards = loadedGroup.filter(
            (item) =>
                !sentGroup ||
                !sentGroup.has(this._makeCardKey(groupKey, item.code))
        );
        return this.state.availableCards.set(groupKey, filteredCards);
    }

    _prepareSentCards(data) {
        let currentSentCards = (data && data.cards) || {};
        let groupKeys = Object.keys(currentSentCards);
        let sentCards = this._convertToImmutableMap(
            currentSentCards,
            groupKeys
        );

        let flatSentCards = this._flattenCardsArray(
            groupKeys,
            currentSentCards
        );
        let collapsedSentCards = flatSentCards.map(() => true);

        this.setState({
            sentCards,
            flatSentCards,
            collapsedSentCards,
        });
        return sentCards;
    }

    _prepareAvailableGroups = (data) => {
        let availableGroups;
        if (data) {
            const groups = data.reduce((map, item) => {
                map[item.group.name] = item;
                return map;
            }, {});
            const names = Object.keys(groups).sort();
            availableGroups = Immutable.OrderedMap().withMutations((map) => {
                names.forEach((key) => {
                    const item = groups[key];
                    map.set(
                        key,
                        Immutable.OrderedMap({
                            _meta: {
                                size: item.quantity,
                                loaded: false,
                                id: item.group.id,
                            },
                        })
                    );
                });
            });
        } else {
            availableGroups = Immutable.OrderedMap();
        }
        return availableGroups;
    };

    _makeCardKey(groupName, cardCode) {
        return groupName + cardCode;
    }

    _convertToImmutableMap(data, groupKeys) {
        groupKeys = groupKeys.sort();
        return Immutable.OrderedMap().withMutations((map) => {
            groupKeys.forEach((key) => {
                let group = data[key].reduce((object, card) => {
                    card.group = { name: key };
                    object[this._makeCardKey(key, card.code)] =
                        RFIDCard.fromJson(card);
                    return object;
                }, {});
                map.set(key, Immutable.OrderedMap(group));
            });
        });
    }

    _flattenCardsArray(keys, data) {
        return Immutable.OrderedMap().withMutations((map) => {
            keys.forEach((key) => {
                let group = { key, isGroup: true };
                map.set(key, group);
                for (let card of data[key]) {
                    map.set(
                        this._makeCardKey(key, card.code),
                        RFIDCard.fromJson(card)
                    );
                }
            });
        });
    }

    _flattenCardsMap(data) {
        return Immutable.OrderedMap().withMutations((map) => {
            for (let key of data.keys()) {
                let group = { key, isGroup: true };
                map.set(key, group);
                for (let item of data
                    .get(key)
                    .filter((v, k) => k !== '_meta')
                    .values()) {
                    map.set(this._makeCardKey(key, item.code), item);
                }
            }
        });
    }

    _prepareRFIDCardData() {
        return this.state.sentCards.withMutations((map) => {
            map.forEach((value, key) => {
                map.set(key, value.toList());
            });
        });
    }

    _loadGroupOfCards(groupId, key) {
        return restGetRfidCardsByGroup(groupId).then((cards) => {
            const loadedGroup = Immutable.OrderedMap().withMutations((map) => {
                map.set('_meta', {
                    size: cards.length,
                    loaded: true,
                    id: groupId,
                });
                cards.forEach((card) => {
                    card.group.name = key;
                    map.set(
                        this._makeCardKey(key, card.code),
                        RFIDCard.fromJson(card)
                    );
                });
            });
            const loadedCards = this.state.loadedCards.set(key, loadedGroup);
            this.setState({
                loadedCards,
            });
            return loadedCards;
        });
    }

    _handleAvailableCardsClick(group) {
        const key = group.key;
        const groupMeta = this.state.availableCards.get(key).get('_meta');
        if (!groupMeta.loaded) {
            this.setState({
                loading: { ...this.state.loading, [key]: 'pending' },
            });
        }
        this.setState({
            collapsedAvailableCards: this._toggleGroup(
                key,
                this.state.collapsedAvailableCards
            ),
        });
    }

    _handleSentCardsClick(group) {
        let key = group.key;
        this.setState({
            collapsedSentCards: this._toggleGroup(
                key,
                this.state.collapsedSentCards
            ),
        });
    }

    _toggleGroup(key, groups) {
        groups = groups.withMutations((map) => {
            key && map.set(key, !map.get(key));
        });
        return groups;
    }

    _onClearAll(e) {
        e.preventDefault();
        const sentCards = Immutable.OrderedMap();
        const flatSentCards = this._flattenCardsMap(sentCards);
        const availableCards = this.state.availableCards.withMutations(
            (map) => {
                this.state.loadedCards.forEach((v, k) => {
                    map.set(k, v);
                });
            }
        );
        const flatAvailableCards = this._flattenCardsMap(availableCards);
        this.setState({
            availableCards,
            flatAvailableCards,
            sentCards,
            flatSentCards,
        });
    }

    _onSubmit(e) {
        e.preventDefault();
        if (this._validateForm()) {
            let data = this._prepareRFIDCardData();
            this.setState({ sendingCards: true });

            const {
                fuelTank,
                updateFuelTankCards,
                fuelTanksFilter: filter,
                hideGlobalDialog,
            } = this.props;

            updateFuelTankCards(
                fuelTank.id,
                data,
                filter,
                () => {
                    hideGlobalDialog();
                },
                () => {
                    if (this._isMounted) {
                        this.setState({
                            validationMessage: Translator.translate(
                                'Unrecognized error has occurred'
                            ),
                        });
                    }
                }
            );
        }
    }

    _validateForm() {
        let uniqueTypes = new Set();
        let minimalSet = false;
        for (let card of this.state.flatSentCards.values()) {
            card.type > 0 && uniqueTypes.add(card.type);
            if (uniqueTypes.size === 3) {
                minimalSet = true;
                break;
            }
        }

        this.setState({ minimalSetError: !minimalSet });

        return minimalSet;
    }

    _onCancel(e) {
        e.preventDefault();
        this.props.hideGlobalDialog();
    }

    _onAvailableRowClick(entry) {
        let id = entry.group.name;
        const cardKey = this._makeCardKey(id, entry.code);
        let currentCards = this.state.availableCards.get(id);
        let availableCards = this.state.availableCards.set(
            id,
            currentCards.delete(cardKey)
        );
        let flatAvailableCards = this._flattenCardsMap(availableCards);
        let sentCards;
        if (this.state.sentCards.has(id)) {
            sentCards = this.state.sentCards.set(
                id,
                this.state.sentCards.get(id).set(cardKey, entry)
            );
        } else {
            let tuple = {};
            tuple[cardKey] = entry;
            sentCards = this.state.sentCards.set(
                id,
                Immutable.OrderedMap(tuple)
            );
        }
        let flatSentCards = this._flattenCardsMap(sentCards);

        this.setState({
            availableCards,
            flatAvailableCards,
            sentCards,
            flatSentCards,
        });
    }

    _moveAvailableGroup(groupKey, movedItems, availableCards) {
        availableCards = availableCards.set(
            groupKey,
            Immutable.OrderedMap({ _meta: movedItems.get('_meta') })
        );
        let flatAvailableCards = this._flattenCardsMap(availableCards);
        let collapsedAvailableCards = this.state.collapsedAvailableCards.set(
            groupKey,
            true
        );
        let sentCards = this.state.sentCards
            .set(groupKey, movedItems.delete('_meta'))
            .sortBy((value, key) => key);
        let collapsedSentCards = this.state.collapsedSentCards.set(
            groupKey,
            true
        );
        let flatSentCards = this._flattenCardsMap(sentCards);

        this.setState({
            availableCards,
            flatAvailableCards,
            collapsedAvailableCards,
            sentCards,
            flatSentCards,
            collapsedSentCards,
        });
    }

    _onMoveAvailableGroupClick(group) {
        let groupKey = group.key;
        let movedItems = this.state.loadedCards.get(groupKey);
        if (!movedItems) {
            this.setState({
                loading: { ...this.state.loading, [groupKey]: 'moving' },
            });
        } else {
            this._moveAvailableGroup(
                groupKey,
                movedItems,
                this.state.availableCards
            );
        }
    }

    _onSentRowClick(entry) {
        let id = entry.group.name;
        const cardKey = this._makeCardKey(id, entry.code);
        let currentCards = this.state.sentCards.get(id).delete(cardKey);
        let sentCards;
        if (currentCards.size > 0) {
            sentCards = this.state.sentCards.set(id, currentCards);
        } else {
            sentCards = this.state.sentCards.delete(id);
        }
        let flatSentCards = this._flattenCardsMap(sentCards);
        let availableCards = this.state.availableCards.set(
            id,
            this.state.availableCards.get(id).set(cardKey, entry)
        );
        let flatAvailableCards = this._flattenCardsMap(availableCards);
        this.setState({
            availableCards,
            flatAvailableCards,
            sentCards,
            flatSentCards,
        });
    }

    _onRemoveSentGroupClick(group) {
        let key = group.key;
        let availableCards = this.state.availableCards;
        if (this.state.loadedCards.has(key)) {
            availableCards = this.state.availableCards.set(
                key,
                this.state.loadedCards.get(key)
            );
        }
        let flatAvailableCards = this._flattenCardsMap(availableCards);
        let sentCards = this.state.sentCards.delete(key);
        let flatSentCards = this._flattenCardsMap(sentCards);
        let collapsedSentCards = this.state.collapsedSentCards.set(key, true);
        this.setState({
            availableCards,
            flatAvailableCards,
            sentCards,
            flatSentCards,
            collapsedSentCards,
        });
    }

    _availableItemRenderer(entry, key, style) {
        const text = this._getRfidDescription(entry);
        return (
            <span className="node item" key={key} style={style}>
                <div className="card-info" title={text}>
                    <div className={COLOR_CLASSES[entry.type]}>
                        {CARD_SHORTCUTS[entry.type]}
                    </div>
                    <div className="description">{text}</div>
                </div>
                <button
                    type="button"
                    className="mtl-button move-btn item"
                    onClick={this._onAvailableRowClick.bind(this, entry)}
                >
                    <span className="icon icon-plus"></span>
                </button>
            </span>
        );
    }

    _availableGroupRenderer(group, collapsed, onClick, key, style) {
        let availableCards = this.state.availableCards.get(group.key);
        let nodeClass = collapsed ? 'node' : 'node expanded';
        const sentGroup = this.state.sentCards.get(group.key);
        const groupMeta = availableCards.get('_meta');
        const size = groupMeta.loaded
            ? availableCards.size - 1
            : groupMeta.size - ((sentGroup && sentGroup.size) || 0);
        return (
            <span key={key} style={style} className={nodeClass}>
                <Tooltip
                    title={group.key.length > 20 ? group.key : ''}
                    aria-label={group.key}
                >
                    <span className="group" onClick={onClick.bind(null, group)}>
                        {Translator.translate('Group')} {group.key} ({size}){' '}
                    </span>
                </Tooltip>
                {groupMeta.loading && (
                    <div className="section content">
                        <LoadingSpinner
                            progressSize="1.5em"
                            top="0"
                            left="-47"
                            right="0"
                            bottom="0"
                        />
                    </div>
                )}
                {!groupMeta.loading && size ? (
                    <button
                        type="button"
                        className="mtl-button move-btn"
                        onClick={this._onMoveAvailableGroupClick.bind(
                            this,
                            group,
                            availableCards
                        )}
                    >
                        <span className="icon icon-plus"></span>
                    </button>
                ) : null}
            </span>
        );
    }

    _sentGroupRenderer(group, collapsed, onClick, key, style) {
        let sentCards = this.state.sentCards.get(group.key);
        let nodeClass = collapsed ? 'node' : 'node expanded';
        return (
            <span key={key} style={style} className={nodeClass}>
                <Tooltip
                    title={group.key.length > 20 ? group.key : ''}
                    aria-label={group.key}
                >
                    <span className="group" onClick={onClick.bind(null, group)}>
                        {Translator.translate('Group')} {group.key} (
                        {sentCards.size}){' '}
                    </span>
                </Tooltip>
                <button
                    type="button"
                    className="mtl-button move-btn"
                    onClick={this._onRemoveSentGroupClick.bind(
                        this,
                        group,
                        sentCards
                    )}
                >
                    <span className="icon icon-minus2"></span>
                </button>
            </span>
        );
    }

    _sentItemRenderer(entry, key, style) {
        const text = this._getRfidDescription(entry);
        return (
            <span className="node item" key={key} style={style}>
                <div className="card-info" title={text}>
                    <div className={COLOR_CLASSES[entry.type]}>
                        {CARD_SHORTCUTS[entry.type]}
                    </div>
                    <div className="description">{text}</div>
                </div>
                <button
                    type="button"
                    className="mtl-button move-btn item"
                    value="-"
                    onClick={this._onSentRowClick.bind(this, entry)}
                >
                    <span className="icon icon-minus2"></span>
                </button>
            </span>
        );
    }

    _getRfidDescription(entry) {
        return (
            (entry.cardNumber || '(' + entry.code + ')') +
            (entry.description || entry.linkedObject.objectName
                ? ': ' + (entry.description || entry.linkedObject.objectName)
                : '')
        );
    }

    _renderLegend() {
        return TYPES.map((value, index) => {
            let i = index + 1;
            return (
                <div key={index} className="legend-item">
                    <div className={COLOR_CLASSES[i]}>{CARD_SHORTCUTS[i]}</div>
                    <div className="description">- {value}</div>
                </div>
            );
        });
    }

    renderTreeView(
        items,
        collapsedItems,
        groupRenderer,
        itemRenderer,
        onToggleGroup
    ) {
        return (
            <VirtualTreeView
                items={items}
                collapsedItems={collapsedItems}
                rowCount={items.size}
                rowHeight={30}
                width={300}
                height={255}
                groupRenderer={groupRenderer}
                itemRenderer={itemRenderer}
                onToggleGroup={onToggleGroup}
            />
        );
    }

    render() {
        return (
            <Dialog
                title={Translator.translate('Cards synchronization')}
                onClose={this._onCancel}
                classNames="dialog send-rfids-dialog"
            >
                <form
                    className="form send-rfid-cards-form"
                    onSubmit={this._onSubmit}
                    autoComplete="off"
                >
                    {this.state.initialized ? (
                        <div className="section content">
                            <div className="form-group single">
                                <div className="form-title">
                                    {Translator.translate('Tank')}
                                </div>
                                <div className="form-label">
                                    {this.props.fuelTank.name}
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="form-title">
                                    {Translator.translate('Available cards')}
                                </div>
                                {this.renderTreeView(
                                    this.state.flatAvailableCards,
                                    this.state.collapsedAvailableCards,
                                    this._availableGroupRenderer,
                                    this._availableItemRenderer,
                                    this._handleAvailableCardsClick
                                )}
                            </div>
                            <div className="form-group">
                                <div className="form-title">
                                    {Translator.translate('Shared cards')}
                                </div>
                                {this.renderTreeView(
                                    this.state.flatSentCards,
                                    this.state.collapsedSentCards,
                                    this._sentGroupRenderer,
                                    this._sentItemRenderer,
                                    this._handleSentCardsClick
                                )}
                            </div>
                            <div className="form-group bottom">
                                <div>
                                    <ValidationMessage
                                        message={Translator.translate(
                                            'Minimal 3 cards of each type must be sent'
                                        )}
                                        error={this.state.minimalSetError}
                                    />
                                    <ValidationMessage
                                        message={Translator.translate(
                                            this.state.validationMessage
                                        )}
                                        error={
                                            this.state.validationMessage !==
                                            undefined
                                        }
                                    />
                                </div>
                                <div>
                                    <button
                                        className="mtl-button secondary right"
                                        onClick={this._onClearAll}
                                    >
                                        <span className="icon icon-cross"></span>
                                        {Translator.translate('Clear')}
                                    </button>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="section content">
                            <LoadingSpinner
                                size="50"
                                top="0"
                                left="0"
                                right="0"
                                bottom="0"
                            />
                        </div>
                    )}
                    <div className="form-actions">
                        {this.state.initialized && !this.state.sendingCards ? (
                            <PrimaryButton
                                onClick={this._onSubmit}
                                icon="icon-floppy-disk"
                                label={Translator.translate('Save')}
                            />
                        ) : null}
                        <SecondaryButton
                            onClick={this._onCancel}
                            icon="icon-cross"
                            label={Translator.translate('Cancel')}
                        />
                    </div>
                    <div className="section">
                        <div className="form-group single">
                            <div className="form-label legend">
                                {this._renderLegend()}
                            </div>
                        </div>
                    </div>
                </form>
            </Dialog>
        );
    }
}

SendRFIDCardsDialog.propTypes = {
    fuelTank: instanceOf(FuelTank),
};

export default Connectable(SendRFIDCardsDialog);
