import React from 'react';

import { GridApi } from 'ag-grid-community';

import moment from 'moment';

import ExportButtons from '../../../../components/ExportButtons/ExportButtons';
import { IExportOptions } from '../../../../components/SourceSetGrid/types';
import AppConfig from '../../../../constants/AppConfig';

const CscExportButtons = ({
    api,
    prefix,
}: {
    api: GridApi | undefined;
    prefix: string;
}) => {
    const gridEnterpriseKey =
        AppConfig &&
        AppConfig.instance.getConfigKey(AppConfig.PROPERTY_GRID) &&
        AppConfig.instance.getConfigKey(AppConfig.PROPERTY_GRID).key;

    const getExportOptions = (gridApi: GridApi | undefined) => {
        if (!gridApi) return [];

        const fileName = `export_${prefix}_${moment().format(
            'YYYYMMDD_HHmmss'
        )}`;

        const exportOptions: IExportOptions[] = [
            {
                name: 'CSV',
                handler: () =>
                    gridApi.exportDataAsCsv({ fileName: fileName + '.csv' }),
            },
        ];

        if (gridEnterpriseKey) {
            exportOptions.push({
                name: 'XLSX',
                handler: () =>
                    gridApi.exportDataAsExcel({ fileName: fileName + '.xlsx' }),
            });
        }

        return exportOptions;
    };

    return <ExportButtons buttons={getExportOptions(api)} />;
};

export default CscExportButtons;
