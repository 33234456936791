import { Record } from 'immutable';
import TimeZone from './TimeZone';

class Vehicle extends Record({
    id: undefined,
    externalId: undefined,
    externalNumber: undefined,
    monitoredId: undefined,
    plateNumber: undefined,
    sideNumber: undefined,
    mark: undefined,
    model: undefined,
    productionYear: undefined,
    vin: undefined,
    fuelType: undefined,
    fuelConsumption: undefined,
    fuelConsumptionWork: undefined,
    name: undefined,
    tankSize: undefined,
    groups: [],
    groupIds: [],
    timeZone: new TimeZone(),
    cards: [],
    assignedToPersonalGroup: false,
}) {
    static fromJson(options) {
        return new Vehicle({
            id: options.id,
            externalId: options.externalId,
            externalNumber: options.externalNumber,
            monitoredId: options.monitoredId,
            plateNumber: options.plateNumber,
            sideNumber: options.sideNumber,
            mark: options.mark,
            model: options.model,
            cards: options.cards,
            productionYear: options.productionYear,
            vin: options.vin,
            fuelType: options.fuelType,
            fuelConsumption: options.fuelConsumption,
            fuelConsumptionWork: options.fuelConsumptionWork,
            tankSize: options.tankSize,
            name: options.name,
            groupIds: options.groupIds,
            groups: options.groups,
            timeZone: new TimeZone(options.timeZone),
            assignedToPersonalGroup: options.assignedToPersonalGroup,
        });
    }

    toJson() {
        return this.toObject();
    }
}

export default Vehicle;
