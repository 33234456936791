import { createStyles, Theme, WithStyles } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme: Theme) => {
    const padding = theme.spacing(2);
    return createStyles({
        wrapper: {
            display: 'flex',
            justifyContent: 'space-between',
        },
        symbolWrapper: {
            marginRight: padding * 2,
            marginLeft: padding,
        },
        detailsWrapper: {
            flexGrow: 1,
        },
        event: {
            fontWeight: 'bold',
            fontSize: '18px',
        },
        time: {
            color: 'gray',
        },
    });
};

export type TThemableProps = WithStyles<typeof styles>;

export const Themable = withStyles(styles, { withTheme: true });
