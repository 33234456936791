import React from 'react';
import { useDispatch } from 'react-redux';

import classNames from 'classnames';

import { useStyles } from './Themable.hooks';
import { useLanguage } from './Connectable.hooks';

import '../../../assets/images/xtrack_logo.svg';
import { showMenu } from '../../state/ui/general';
import AppConfig from '../../constants/AppConfig';
import TranslationHelper from '../../helpers/TranslationHelper';

import {
    AppBar,
    Hidden,
    IconButton,
    Toolbar,
    Typography,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';

import Brand from '../Brand';
import MobileNavigation from '../MobileNavigation';
import MobileSearch from '../MobileSearch';

interface IOwnProps {
    title: string;
    children: JSX.Element | JSX.Element[];
    noPadding?: boolean;
    noColumn?: boolean;
}

const View = ({ title, noPadding, noColumn, children }: IOwnProps) => {
    const dispatch = useDispatch();
    const language = useLanguage();
    const classes = useStyles();
    const checkHttpsOnly = () => {
        if (httpsOnly) {
            const httpsRegex = /^https:\/\//;
            return !!topBannerLink.match(httpsRegex);
        } else {
            return true;
        }
    };

    const brandingTheme =
        AppConfig && AppConfig.instance.getConfigKey('th') === 'branding';

    const httpsOnly = AppConfig && AppConfig.instance.getConfigKey('httpsOnly');
    const userBranding =
        brandingTheme &&
        AppConfig.instance.getConfigKey(AppConfig.PROPERTY_BRANDING);

    const userPageLogo =
        userBranding &&
        userBranding.topLogo &&
        `/configuration/assets/${userBranding.topLogo}`;

    const xtrackBrand = userBranding && userBranding.xtrackBrand;

    const languagesConfig = AppConfig.instance.getConfigKey(
        AppConfig.PROPERTY_LANGUAGES
    );
    const userLanguageConfig = languagesConfig && languagesConfig[language];

    const topBannerImage =
        userLanguageConfig?.topBannerImage &&
        `/configuration/assets/${userLanguageConfig.topBannerImage}`;
    const topBannerLink = userLanguageConfig?.topBannerLink;
    const topBanner = topBannerImage && topBannerLink && checkHttpsOnly();

    const handleShowMenu = () => {
        dispatch(showMenu());
    };

    return (
        <React.Fragment>
            <AppBar
                position="static"
                className={classNames(classes.appBar)}
                color="inherit"
            >
                <Toolbar className={classes.toolbar}>
                    <div className={classes.toolbarTitle}>
                        <Hidden mdUp>
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                onClick={handleShowMenu}
                            >
                                <MenuIcon />
                            </IconButton>
                            <MobileNavigation
                                logo={userPageLogo}
                                branding={brandingTheme && xtrackBrand}
                            />
                        </Hidden>
                        <Hidden smDown>
                            <div className={classes.toolbarMainLogo}>
                                <img
                                    className={classes.toolbarMainLogoImg}
                                    alt={TranslationHelper.translate(
                                        'Image not found'
                                    )}
                                    src={
                                        userPageLogo
                                            ? userPageLogo
                                            : '/images/xtrack_logo.svg'
                                    }
                                />
                            </div>
                        </Hidden>
                        <Typography
                            variant="h6"
                            color="inherit"
                            noWrap={true}
                            className={classes.contextName}
                        >
                            {title}
                        </Typography>
                    </div>
                    {topBanner && (
                        <Hidden smDown>
                            <a
                                target="_blank"
                                href={topBannerLink}
                                rel="nofollow"
                            >
                                <img
                                    alt={TranslationHelper.translate(
                                        'Image not found'
                                    )}
                                    className={classes.toolbarMainLogoImg}
                                    src={topBannerImage}
                                />
                            </a>
                        </Hidden>
                    )}
                    <Hidden smDown>
                        {brandingTheme && xtrackBrand ? (
                            <div className={classes.brand}>
                                <Brand />
                            </div>
                        ) : (
                            <div />
                        )}
                    </Hidden>
                    <MobileSearch />
                </Toolbar>
            </AppBar>
            <section
                className={classNames(
                    classes.contentArea,
                    noPadding && classes.contentAreaNoPadding
                )}
                style={{
                    flexDirection: noColumn ? 'row' : 'column',
                    backgroundColor: '#f9f8fb',
                    backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='28' height='49' viewBox='0 0 28 49'%3E%3Cg fill-rule='evenodd'%3E%3Cg id='hexagons' fill='%23e5e5e5' fill-opacity='0.4' fill-rule='nonzero'%3E%3Cpath d='M13.99 9.25l13 7.5v15l-13 7.5L1 31.75v-15l12.99-7.5zM3 17.9v12.7l10.99 6.34 11-6.35V17.9l-11-6.34L3 17.9zM0 15l12.98-7.5V0h-2v6.35L0 12.69v2.3zm0 18.5L12.98 41v8h-2v-6.85L0 35.81v-2.3zM15 0v7.5L27.99 15H28v-2.31h-.01L17 6.35V0h-2zm0 49v-8l12.99-7.5H28v2.31h-.01L17 42.15V49h-2z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`,
                }}
            >
                {children}
            </section>
        </React.Fragment>
    );
};

export default View;
