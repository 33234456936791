import React, { Component, FunctionComponent } from 'react';
import TranslationHelper from '../../../../helpers/TranslationHelper';
import LoadingSpinner from '../../../loadingSpinner/LoadingSpinner';

interface ICellProps {
    dataKey: string;
    width?: string;
    row: { rowClass?: string; [key: string]: any };
}
const Cell: FunctionComponent<ICellProps> = ({ dataKey, width, row }) => {
    if (dataKey === 'icon') {
        return (
            <div
                className="cell icon"
                key={dataKey}
                style={{ flexBasis: width || 0, flexGrow: width ? 0 : 1 }}
            >
                <span className={'icon ' + row[dataKey]} />
            </div>
        );
    }

    return (
        <div
            className={`cell ${row.rowClass || ''}`}
            key={dataKey}
            style={{ flexBasis: width || 0, flexGrow: width ? 0 : 1 }}
        >
            {dataKey === 'amount'
                ? row[dataKey] && row[dataKey].toFixed(2)
                : row[dataKey]}
        </div>
    );
};

const Row: FunctionComponent<{ children: JSX.Element[] }> = ({ children }) => (
    <div style={{ display: 'flex' /*display: 'flex', fontSize: '12px' */ }}>
        {children}
    </div>
);

interface IStructureItem {
    header: string;
    key: string;
    width?: string;
}

interface IEventsTableProps {
    id?: string;
    data?: { [key: string]: any } | null;
    structure: IStructureItem[];
}

class EventsTable extends Component<IEventsTableProps> {
    public render() {
        const { data, structure } = this.props;

        if (!data) {
            return (
                <div className="spinner">
                    <LoadingSpinner
                        size="50"
                        top="0"
                        left="0"
                        right="0"
                        bottom="0"
                    />
                </div>
            );
        }

        if (data.length === 0) {
            return (
                <div className="no-events">
                    {TranslationHelper.translate('No data to display')}
                </div>
            );
        }
        return (
            <>
                <div className="fixed-header-table">
                    <div className="fixed-header-table-header">
                        {structure.map(({ header, key, width }) => (
                            <div
                                className="cell"
                                key={key}
                                style={{
                                    flexBasis: width || 0,
                                    flexGrow: width ? 0 : 1,
                                }}
                            >
                                {header}
                            </div>
                        ))}
                    </div>
                    <div className="fixed-header-table-body">
                        {data.map((row: { [key: string]: any }, i: number) => (
                            <Row key={i}>
                                {structure.map(({ key, width }) => (
                                    <Cell
                                        key={key}
                                        dataKey={key}
                                        row={row}
                                        width={width}
                                    />
                                ))}
                            </Row>
                        ))}
                    </div>
                </div>
            </>
        );
    }
}

export default EventsTable;
