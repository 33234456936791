import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => {
    const spacing = theme.spacing(3);
    return createStyles({
        pane: {
            display: 'flex',
            height: '100%',
        },
        wrapper: {
            display: 'flex',
            position: 'relative',
            flexGrow: 1,
            flexDirection: 'column',
            overflow: 'hidden',
        },
        formWrapper: {
            padding: spacing,
            overflowX: 'hidden',
            overflowY: 'auto',
        },
        rolesWrapper: {
            marginTop: spacing * 2,
        },
    });
});
