import React, { useEffect, useRef, useState } from 'react';

import Player from 'video.js/dist/types/player';

import { makeLinkVideoURL } from '../../../../../../../../services/discovery/_shared/urlMakers';
import { usePreviewAction } from '../../../../../../selectors/index.hooks';
import {
    useFramesMoviesIds,
    useSourceSetMovieFramesIds,
} from '../../../../../../selectors/composed/index.hooks';

import TranslationHelper from '../../../../../../../../helpers/TranslationHelper';
import { getHlsParam } from '../../../../../../../../helpers/getHlsParam';

import { TOOLTIP_DEBOUNCE } from '../../../../../../../../constants/DebounceValues';

import VideoPlayerDialog from '../../../../../../../../components/dialogs/VideoPlayerDialog/VideoPlayerDialog';
import DialogTitle from '../../../../../../../../components/dialogs/DialogTitle/DialogTitle';
import CopyLinkContainer from '../../../../../../../../components/CopyLinkContainer/CopyLinkContainer';
import SelectField from '../../../../../../../../components/SelectField';

interface IOwnProps {
    src: string;
    open: boolean;
    handleClose: (event?: object, reason?: string) => void;
    updateVideoDialog: (time: number, param?: string) => void;
    timestamp: number;
    videoParams: {
        reportId: string;
        selectedCamera: string;
        monitoredId: string | null;
        date: string | null;
        range?: number[];
        anonymize?: boolean;
        labels?: boolean;
        model?: string;
        classThreshold?: number;
        objectThreshold?: number;
    };
}

const MoviePlayer = ({
    src,
    open,
    handleClose,
    updateVideoDialog,
    videoParams,
}: IOwnProps) => {
    const [copyLinkTooltip, setCopyLinkTooltip] = useState(
        TranslationHelper.translate('Copy to clipboard')
    );
    const [copyVideoLinkTooltip, setCopyVideoLinkTooltip] = useState(
        TranslationHelper.translate('Create and copy to clipboard')
    );
    const {
        anonymize,
        reportId,
        selectedCamera,
        monitoredId,
        date,
        range,
        labels,
        model,
        classThreshold,
        objectThreshold,
    } = videoParams;
    const sourceSetFramesIds = useSourceSetMovieFramesIds(reportId);
    const framesIds = useFramesMoviesIds();
    const previewAction = usePreviewAction();

    const ids = sourceSetFramesIds || framesIds;
    const videoRef = useRef<HTMLVideoElement | null>(null);
    const playerRef = React.useRef<Player | null>(null);
    const hls = getHlsParam(anonymize, labels);
    useEffect(() => {
        setTimeout(() => videoRef.current?.focus());
    }, [open]);

    const copyToClipboard = (text: string) => {
        navigator.clipboard.writeText(text);
    };

    const copyLink = () => {
        videoRef.current && updateVideoDialog(videoRef.current.currentTime);
        setCopyLinkTooltip(TranslationHelper.translate('This was copied!'));
        setTimeout(() => copyToClipboard(window.location.href));
        setTimeout(
            () =>
                setCopyLinkTooltip(
                    TranslationHelper.translate('Copy to clipboard')
                ),
            TOOLTIP_DEBOUNCE
        );
    };

    const copyVideoLink = () => {
        if (date && monitoredId) {
            setCopyVideoLinkTooltip(
                TranslationHelper.translate('Link was created and copied!')
            );
            setTimeout(() =>
                copyToClipboard(
                    makeLinkVideoURL({
                        timestamp: date,
                        monitoredId,
                        selectedCamera,
                        rangeMinus: range?.[0],
                        rangePlus: range?.[1],
                        anonymize,
                        hls,
                        labels,
                        model,
                        classThreshold,
                        objectThreshold,
                    }).toString()
                )
            );
            setTimeout(
                () =>
                    setCopyVideoLinkTooltip(
                        TranslationHelper.translate(
                            'Create and copy to clipboard'
                        )
                    ),
                TOOLTIP_DEBOUNCE
            );
        }
    };

    const handleChangeCamera = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        handleClose();
        updateVideoDialog(
            videoRef.current?.currentTime || 0,
            event.target.value
        );
    };
    const renderDropdownButtons = () => {
        return (
            <div>
                <CopyLinkContainer
                    copyLink={
                        !previewAction?._webx?.changeSourceSetDisabled
                            ? { title: copyLinkTooltip, callback: copyLink }
                            : undefined
                    }
                    copyVideoLink={{
                        title: copyVideoLinkTooltip,
                        callback: copyVideoLink,
                    }}
                />
            </div>
        );
    };

    const renderTitleButton = () => {
        return (
            <SelectField
                name="selectedCamera"
                value={selectedCamera}
                ids={ids}
                handleChange={handleChangeCamera}
                translateContext={'mp'}
            />
        );
    };

    return (
        <VideoPlayerDialog
            videoSrc={src}
            videoRef={videoRef}
            playerRef={playerRef}
            open={open}
            handleClose={handleClose}
            type={hls ? 'application/x-mpegURL' : 'video/mp4'}
        >
            <DialogTitle
                title={`XTrack Player:`}
                close={handleClose}
                dropDown={renderDropdownButtons}
                titleButton={renderTitleButton}
            />
        </VideoPlayerDialog>
    );
};

export default MoviePlayer;
