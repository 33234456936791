import React, { ReactNode, forwardRef } from 'react';

import { Box } from '@mui/material';

interface IOwnProps {
    children: ReactNode;
}
const ListContainer = forwardRef<HTMLDivElement, IOwnProps>(
    ({ children }: IOwnProps, ref) => {
        return (
            <Box ref={ref} sx={{ display: 'flex', flexDirection: 'column' }}>
                {children}
            </Box>
        );
    }
);

export default ListContainer;
