import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        pageWrapper: {
            position: 'absolute',
            top: 10,
            left: 10,
            bottom: 10,
            right: 10,
            maxHeight: '100%',
        },
        tabsBar: {
            background: theme.palette.grey[200],
            height: '70px',
            whiteSpace: 'nowrap',
        },
        root: {
            whiteSpace: 'nowrap',
        },
        tab: {
            background: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            whiteSpace: 'nowrap',
        },
        contentWrapper: {
            position: 'absolute',
            top: 70,
            left: 0,
            bottom: 0,
            right: 0,
        },
    })
);
