import React from 'react';

import { useHistory } from 'react-router-dom';

import { Field, Formik } from 'formik';

import { useSnackbar } from 'notistack';

import { Button } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import TranslationHelper from '../../../helpers/TranslationHelper';
import { errorMessageHandler } from '../../../helpers/errorMessageHandler';
import { useAppDispatch } from '../../../hooks';

import FieldWrapper from '../../common/formikWrappers/FieldWrapper';

import { loginMigrationSchema } from '../../../schemas';
import { hideGlobalDialog } from '../../../state/ui/dialog';
import { migrationChangeEmail } from '../../../state/auth';

import { useStyles } from './Themable.hooks';

interface IMigrationNewLogin {
    email: string;
    confirmEmail: string;
}
const blankForm: IMigrationNewLogin = {
    email: '',
    confirmEmail: '',
};

const ChangeLoginForm = () => {
    const history = useHistory();

    const classes = useStyles();
    const dispatch = useAppDispatch();

    const { enqueueSnackbar } = useSnackbar();

    const handleSubmit = (data: IMigrationNewLogin) => {
        dispatch(
            migrationChangeEmail(
                data.email,
                () => {
                    history.replace('/user-migration/verification');
                    dispatch(hideGlobalDialog());
                },
                (error) => {
                    const message = errorMessageHandler(error.status, {
                        409: 'auth.dupedMigration',
                    })();
                    enqueueSnackbar(message, {
                        variant: 'error',
                    });
                }
            )
        );
    };

    return (
        <>
            <Alert severity="warning">
                {TranslationHelper.translate('auth.changeLoginAlert')}
            </Alert>

            <div style={{ margin: '10px 0' }}>
                {TranslationHelper.translate('auth.changeLoginHeading')}
            </div>
            <Formik
                enableReinitialize={true}
                initialValues={blankForm}
                onSubmit={handleSubmit}
                validateOnBlur={false}
                validateOnChange={false}
                validationSchema={loginMigrationSchema()}
            >
                {({ errors, submitForm }) => {
                    return (
                        <>
                            <Field
                                id="email-id"
                                error={!!(errors && errors.email)}
                                helperText={errors && errors.email}
                                name={'email'}
                                label={TranslationHelper.translate('E-mail')}
                                type={'email'}
                                fullWidth={true}
                                component={FieldWrapper}
                                margin="dense"
                                variant="outlined"
                            />
                            <Field
                                id="confirm-email-id"
                                error={!!(errors && errors.confirmEmail)}
                                helperText={errors && errors.confirmEmail}
                                name={'confirmEmail'}
                                label={TranslationHelper.translate(
                                    'Repeat E-mail'
                                )}
                                type={'email'}
                                fullWidth={true}
                                component={FieldWrapper}
                                margin="dense"
                                variant="outlined"
                            />
                            <div className={classes.formButtons}>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    className={classes.saveButton}
                                    onClick={submitForm}
                                >
                                    {TranslationHelper.translate('Confirm')}
                                </Button>
                            </div>
                        </>
                    );
                }}
            </Formik>
        </>
    );
};

export default ChangeLoginForm;
