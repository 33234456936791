import React from 'react';

import { useDispatch } from 'react-redux';

import version from '../../../../version';

import { hideGlobalDialog } from '../../../../state/ui/dialog';

import Label from '../../../common/Label';
import ApplicationResources from './components/ApplicationResources';

import Dialog from '../../../dialog/Dialog';
import TranslationHelper from '../../../../helpers/TranslationHelper';
import '../../../../../assets/images/xtrack_logo.svg';

const ApplicationAboutDialog = () => {
    const dispatch = useDispatch();

    const handleCancel = (event: MouseEvent) => {
        event.preventDefault();
        dispatch(hideGlobalDialog());
    };

    return (
        <Dialog
            title={TranslationHelper.translate('About')}
            onClose={handleCancel}
        >
            <section>
                <div className="application-menu--content">
                    <div id="about-logo"></div>
                    <div className="application-menu--item">
                        <div className="column">
                            <label>
                                {TranslationHelper.translate('Version:')}
                            </label>
                        </div>
                        <div className="column">
                            <Label title={version.number} />
                        </div>
                    </div>
                </div>
                <ApplicationResources />
                <footer className="dialog--footer">
                    <label>{TranslationHelper.translate('Powered by')}</label>
                    <a
                        href="https://www.xtrack.com"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img
                            className="application-menu--footer-image"
                            src="/images/xtrack_logo.svg"
                        />
                    </a>
                </footer>
            </section>
        </Dialog>
    );
};

export default ApplicationAboutDialog;
