import React, { useEffect, useState } from 'react';

import {
    CellPosition,
    NavigateToNextCellParams,
    SelectionChangedEvent,
} from 'ag-grid-community';

import { LinearProgress, Paper } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';

import { useStyles } from './Themable.hooks';

import SearchField from '../../../../../../components/common/SearchField/SearchField';
import IconButtonWithTooltip from '../../../../../../components/IconButtonWithTooltip';
import PaneHeader from '../../../../../../components/PaneHeader/PaneHeader';
import SourceSetGrid from '../../../../../../components/SourceSetGrid/SourceSetGrid';
import TranslationHelper from '../../../../../../helpers/TranslationHelper';

import { ISourceSetEntity } from '../../../../../../state/types';
import {
    enterEditMultipleMode,
    fetchDevices,
    selectDevice,
    selectPreviewDevice,
    setLoading,
} from '../../../../../../state/ui/devices';

import { useToolkitDispatch } from '../../../../../../hooks';
import {
    useDevicesLoading,
    useDevicesSourceSet,
    useDevicesSourceSetModel,
    useSelectedDevicesIds,
} from '../../../../../../state/ui/devices/index.hooks';
import { usePrivileges } from '../../../../../../state/auth/index.hooks';

const CHECKBOX_POSITION = 1;
const HIDDEN_ID_POSITION = 0;

const DevicesGridPane = () => {
    const sourceSet = useDevicesSourceSet();
    const sourceSetModel = useDevicesSourceSetModel();
    const classes = useStyles();
    const privileges = usePrivileges();
    const loading = useDevicesLoading();
    const selectedDevicesIds = useSelectedDevicesIds();
    const toolkitDispatch = useToolkitDispatch();

    const [filter, setFilter] = useState(sourceSetModel?.quickFilter || '');

    useEffect(() => {
        toolkitDispatch(setLoading({ loading: true }));
        toolkitDispatch(fetchDevices());
    }, []);

    const handleCellInteraction = (data: ISourceSetEntity) => {
        data.recurringId &&
            toolkitDispatch(
                selectPreviewDevice({
                    deviceId: data.recurringId,
                    collectionId: data.id,
                })
            );
    };
    const onSelectionChanged = (e: SelectionChangedEvent) => {
        const devicesIds = e.api
            .getSelectedNodes()
            .map((device) => device.data.recurringId);

        toolkitDispatch(selectDevice(devicesIds));
    };

    const getLeftCustomControls = () => {
        return (
            <>
                <SearchField value={filter} filterChange={setFilter} />
                {selectedDevicesIds.length > 1 && privileges.eToll && (
                    <IconButtonWithTooltip
                        title={TranslationHelper.translate('Edit devices')}
                        onClick={() => toolkitDispatch(enterEditMultipleMode())}
                    >
                        <EditIcon />
                    </IconButtonWithTooltip>
                )}
            </>
        );
    };

    const nextCellHandler = (
        params: NavigateToNextCellParams
    ): CellPosition => {
        const suggestedNextCell = params.nextCellPosition;
        if (suggestedNextCell !== null) {
            return suggestedNextCell;
        } else {
            return params.previousCellPosition;
        }
    };

    const multiSelectProps = {
        rowSelection: 'multiple',
        checkBox: { index: CHECKBOX_POSITION },
        checkBoxHeader: { index: CHECKBOX_POSITION },
        onSelectionChanged,
        nextCellHandler,
    };
    return (
        <Paper className={classes.pane}>
            <div className={classes.wrapper}>
                <PaneHeader
                    title={TranslationHelper.translate('Devices')}
                    renderLeftCustomControls={() => getLeftCustomControls()}
                />
                {loading && <LinearProgress />}
                {sourceSet && !loading && (
                    <SourceSetGrid
                        sourceSet={sourceSet}
                        quickFilterValue={filter}
                        sourceSetModel={sourceSetModel}
                        cellInteractionHandler={handleCellInteraction}
                        hide={{ index: HIDDEN_ID_POSITION }}
                        multiSelectProps={multiSelectProps}
                    />
                )}
            </div>
        </Paper>
    );
};

export default DevicesGridPane;
