import createStyles from '@material-ui/core/styles/createStyles';

import { Theme, withStyles, WithStyles } from '@material-ui/core/styles';

const drawerWidth = 240;

const styles = (theme: Theme) => {
    return createStyles({
        toolbarMainLogoImg: {
            verticalAlign: 'bottom',
            height: 50,
        },
        list: {
            width: drawerWidth,
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
        },
        center: {
            display: 'flex',
            justifyContent: 'center',
            margin: '0 auto',
        },
        brand: {
            padding: theme.spacing(),
        },
    });
};

export type TThemableProps = WithStyles<typeof styles>;

export const Themable = withStyles(styles, { withTheme: true });
