import { combineReducers } from 'redux';

import general from './general';
import snapshotting from './snapshotting';
import visibility from './visibility';

export default combineReducers({
    general,
    snapshotting,
    visibility,
});
