import { Switch, Tooltip } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import TranslationHelper from '../../helpers/TranslationHelper';
import { useAppDispatch } from '../../hooks';
import { useGridFilters } from '../../pages/discovery/selectors/index.hooks';
import { setGridFilters } from '../../state/ui/discovery/snapshotting';

import { useStyles } from './Themable.hooks';

interface IOwnProps {
    title: string;
    initialValue: boolean;
    sourceSetId: string;
    filterId: string;
}
const SwitchToggle = ({
    title,
    initialValue,
    sourceSetId,
    filterId,
}: IOwnProps) => {
    const dispatch = useAppDispatch();

    const [checked, setChecked] = useState(false);
    const classes = useStyles();

    const gridFilters = useGridFilters();

    useEffect(() => {
        if (gridFilters?.[filterId] !== undefined) {
            setChecked(!!gridFilters[filterId]);
        } else {
            handleChange(initialValue);
        }
    }, []);

    const handleChange = (checked: boolean) => {
        setChecked(checked);
        dispatch(
            setGridFilters(sourceSetId, {
                ...gridFilters,
                [filterId]: checked,
            })
        );
    };
    return (
        <Tooltip title={TranslationHelper.translate(title)}>
            <Switch
                classes={{
                    track: classes.switchTrack,
                    colorPrimary: classes.switchColorPrimary,
                    switchBase: classes.switchBase,
                }}
                color="primary"
                onChange={(e) => handleChange(e.target.checked)}
                checked={checked}
            />
        </Tooltip>
    );
};

export default SwitchToggle;
