import { Feature, Map } from 'ol';
import { Geometry } from 'ol/geom';
import VectorSource from 'ol/source/Vector';

import {
    fitMap,
    LARGE_MAP_PADDING,
} from '../../../../discovery/components/DiscoveryMap/_utils';
import { MAX_ZOOM } from '../../../../../constants/map/zoom';

import { Cluster } from 'ol/source';

import {
    ISelectFeaturesOnMapParams,
    TPointsLayer,
    TVectorGeometrySource,
} from '../../../types';
import { FEATURE_NOT_SELECTED, FEATURE_SELECTED } from './utils';

export const handleMultiSelect = (
    feature: Feature<Geometry>,
    handleSelectFeatures: (params: ISelectFeaturesOnMapParams) => void,
    selectedItems: { [key: string]: string[] }
) => {
    const id = feature?.get('data')?.id;
    if (id != null) {
        const isSelected = feature.get('selected') === FEATURE_SELECTED;
        const sourceSetId = feature.get('sourceSetId');

        const items = isSelected
            ? selectedItems[sourceSetId]?.filter((item) => item !== id)
            : [...selectedItems[sourceSetId], id];
        handleSelectFeatures({
            items: items,
            sourceSetId: feature.get('sourceSetId'),
            lastItemId: isSelected ? '' : id,
        });

        feature.set(
            'selected',
            isSelected ? FEATURE_NOT_SELECTED : FEATURE_SELECTED
        );
    }
};
export const handleSingleSelect = (
    feature: Feature<Geometry>,
    source: TVectorGeometrySource,
    handleSelectFeatures: (params: ISelectFeaturesOnMapParams) => void
) => {
    const id = feature?.get('data')?.id;

    if (id != null) {
        //map
        const features = source?.getFeatures();
        features?.forEach((feature) => {
            feature.unset('selected');
        });
        const isSelected = feature.get('selected') === FEATURE_SELECTED;

        //state/grid
        handleSelectFeatures({
            items: [id],
            sourceSetId: feature.get('sourceSetId'),
            lastItemId: isSelected ? '' : id,
        });
        feature.set(
            'selected',
            isSelected ? FEATURE_NOT_SELECTED : FEATURE_SELECTED
        );
    }
};

export const selectAndZoomOnFeatures = (
    map: Map,
    source: VectorSource | Cluster,
    pointsLayer: TPointsLayer,
    items: string[],
    onRenderComplete: () => void
) => {
    const extent = pointsLayer?.getSource()?.getExtent();
    const itemSet = new Set(items);
    const features = source.getFeatures();

    features.forEach((feature) => {
        const data = feature?.get('data');
        const id = data?.id;
        feature.unset('selected');
        if (id && itemSet.has(id)) {
            feature.set('selected', FEATURE_SELECTED);
        }
    });

    if (extent) {
        fitMap(map, extent, LARGE_MAP_PADDING, MAX_ZOOM);
    }

    map.un('rendercomplete', onRenderComplete);
};
