import React, { Component, ComponentType } from 'react';

import { compose } from 'redux';

import { FormikValues } from 'formik';

import moment from 'moment-timezone';

import { Connectable, TConnectableProps } from './Connectable.hoc';
import { Themable, TThemableProps } from './Themable.hoc';

import { getTypesIds } from '../../helpers';
import TimeFormatter from '../../../../../../../../helpers/TimeFormatter';

import { taskSchema } from '../../../../../../../../schemas';

import { IAddTask, IEditTask } from '../../../../../../../../services/tasks';

import { ITaskForm } from '../../../../../../../../state/ui/forms';
import { ITask, sourceSetIds } from '../../../../../../../../state/types';

import GoogleMapsButton from '../../../../../../../../components/common/GoogleMapsButton';
import CrudPane from '../../../../../../../../components/CrudPane';
import TaskForm from './components/TaskForm';
import { showTaskRoutesDialog } from '../../../../../../../../state/ui/dialog';
import { withSnackbar, WithSnackbarProps } from 'notistack';
import TranslationHelper from '../../../../../../../../helpers/TranslationHelper';

type TProps = TThemableProps & TConnectableProps & WithSnackbarProps;

interface IState {
    lastLoadedTask: ITask | null;
    blankForm: ITaskForm;
}

let setFieldValueRef: (field: string, value: any) => void;

const autoCompleteState = (nextProps: TProps, currentState: IState) => {
    const activityCategory = nextProps.activityCategory[0].id;
    const { serviceTypeId: serviceType, objectCategoryId: objectCategory } =
        getTypesIds(
            activityCategory,
            nextProps.serviceType,
            nextProps.objectCategory
        );
    return {
        ...currentState,
        blankForm: {
            ...currentState.blankForm,
            activityCategory,
            serviceType,
            objectCategory,
        },
    };
};

class TaskCrud extends Component<TProps, IState> {
    public static getDerivedStateFromProps(
        nextProps: TProps,
        currentState: IState
    ) {
        if (currentState.lastLoadedTask === nextProps.task) {
            return currentState;
        }

        const nextTask = nextProps.task;
        const data = nextTask && nextTask._meta.header.task;
        if (!data) {
            if (nextProps.activityCategory.length === 1) {
                return autoCompleteState(nextProps, currentState);
            }
            return currentState;
        }
        return {
            lastLoadedTask: nextProps.task,
            blankForm: {
                notice: data.notice || '',
                plannedOn: data.plannedOn || null,
                noDate: !data.plannedOn,
                plannedTimeFrom: data.plannedTimeFrom
                    ? moment(data.plannedTimeFrom, 'HH:mm')
                    : undefined,
                plannedTimeTo: data.plannedTimeTo
                    ? moment(data.plannedTimeTo, 'HH:mm')
                    : undefined,
                location: { label: data.location.name, id: data.location.id },
                status: data.statusId,
                vehicle: { label: data.vehicleName || '', id: data.vehicleId },
                employee: {
                    label: data.employeeName || '',
                    id: data.employeeId,
                },
                route: {
                    id: data.route?.id || '',
                    label: data.route?.name || '',
                },
                activityCategory:
                    data.activityCategoryId !== undefined
                        ? data.activityCategoryId
                        : '',
                objectCategory:
                    data.objectCategoryId !== undefined
                        ? data.objectCategoryId
                        : '',
                serviceType:
                    data.serviceTypeId !== undefined ? data.serviceTypeId : '',
                serviceClass:
                    data.serviceClassId !== undefined
                        ? data.serviceClassId
                        : '',
                unitsCount: data.unitsCount,
                attachments: { files: [], errors: false },
                readOnlyFiles: data.attachments || [],
                externalLinkages: data.externalLinkages || [],
            },
        };
    }

    public state: IState = {
        lastLoadedTask: null,
        blankForm: {
            notice: '',
            plannedOn: moment(this.props.sourceSetDates?.from).isAfter(
                moment(),
                'days'
            )
                ? this.props.sourceSetDates?.from
                : TimeFormatter.dateToShortDateString(moment()),
            noDate: false,
            plannedTimeFrom: undefined,
            plannedTimeTo: undefined,
            location: { label: '' },
            status: 1,
            vehicle: { label: '' },
            employee: { label: '' },
            activityCategory: '',
            objectCategory: '',
            serviceType: '',
            serviceClass: '',
            unitsCount: 0,
            attachments: { files: [], errors: false },
            readOnlyFiles: [],
            externalLinkages: [],
            route: { label: '' },
        },
    };

    public componentDidMount() {
        const { pane } = this.props;
        if (pane && pane.mode !== 'add') {
            this.props.fetchTask(pane.elementId);
        }
    }

    public componentWillUnmount() {
        const { pane, task } = this.props;
        if (task && task.id) {
            this.props.stopFetchingTask(
                task.id,
                pane?.elementCollectionName || ''
            );
        }
    }

    public renderPlannedOn = () => {
        const { task, userSettings, classes } = this.props;
        const plannedOn = task && task._meta.header.task.plannedOn;
        return task && plannedOn ? (
            <div className={classes.plannedOnWrapper}>
                {`(${moment(plannedOn).format(userSettings.shortDateFormat)})`}
            </div>
        ) : (
            <></>
        );
    };

    public handleAddSubmit = (
        values: FormikValues,
        onSuccess: () => void,
        onError: () => void
    ) => {
        const { createTask } = this.props;
        const files: File[] =
            (values.attachments && values.attachments.files) || [];

        const failureAction = this.props.privileges.routes
            ? this.showDialogOnFailure(values, onError)
            : this.showSnackBarOnFailure(
                  TranslationHelper.translate('No sufficient rights'),
                  onError
              );

        createTask(
            this.createTaskForm(this.createTask(values), files),
            this.handleOnSuccess(onSuccess),
            failureAction
        );
    };

    public handleEditSubmit = (
        values: FormikValues,
        onSuccess: () => void,
        onError: () => void
    ) => {
        const { task, updateTask } = this.props;
        if (task) {
            const id = task._meta.header.task.id;
            const newTask: IEditTask = {
                id,
                ...this.createTask(values),
            };
            const files: File[] =
                (values.attachments && values.attachments.files) || [];
            const form = this.createTaskForm(newTask, files);
            form.append('id', id.toString());

            const failureAction = this.props.privileges.routes
                ? this.showDialogOnFailure(values, onError)
                : this.showSnackBarOnFailure(
                      TranslationHelper.translate('No sufficient rights'),
                      onError
                  );

            updateTask(form, this.handleOnSuccess(onSuccess), failureAction);
        }
    };
    public render() {
        const { activityCategory, task, pane, storedResult, privileges } =
            this.props;
        const { blankForm } = this.state;
        const initialValues = storedResult || blankForm;
        const isReadOnly = task && task._meta.header.task.readOnly;
        const coordinates =
            (task && task._meta.header.coordinates) || undefined;
        const missingData = activityCategory.length < 1;

        return (
            <CrudPane
                titles={{ add: 'Add task', edit: 'Edit task' }}
                loaded={
                    task !== undefined ||
                    (pane !== null && pane.mode === 'add' && !missingData)
                }
                initialValues={initialValues}
                validationSchema={taskSchema()}
                onAdd={
                    privileges.addTask
                        ? (values, onSuccess, onError) =>
                              this.handleAddSubmit(values, onSuccess, onError)
                        : undefined
                }
                onEdit={
                    privileges.editTask && !isReadOnly
                        ? (values, onSuccess, onError) => {
                              this.handleEditSubmit(values, onSuccess, onError);
                          }
                        : undefined
                }
                changeModeHandler={this.handleChangeMode}
                onClose={this.handleCloseClick}
                mode={(pane && pane.mode) || 'preview'}
                inCreate={(pane && pane.mode === 'add') || false}
                renderLeftCustomControls={() => this.renderPlannedOn()}
                renderRightCustomControls={() => (
                    <GoogleMapsButton coordinates={coordinates} />
                )}
                renderContent={(
                    mode,
                    errors,
                    values,
                    setFieldValue,
                    isSubmitting
                ) => {
                    setFieldValueRef = setFieldValue;
                    return (
                        <TaskForm
                            mode={mode}
                            errors={errors}
                            values={values}
                            setFieldValue={setFieldValue}
                            isSubmitting={isSubmitting}
                        />
                    );
                }}
            />
        );
    }

    private createTask(values: FormikValues) {
        return {
            status: values.status as number,
            objectCategoryId: values.objectCategory as number,
            activityCategoryId: values.activityCategory as number,
            serviceTypeId: values.serviceType as number,
            serviceClassId: values.serviceClass as number,
            notice: values.notice as string,
            plannedOn: values.noDate ? undefined : (values.plannedOn as string),
            employeeId: values.employee && (values.employee.id as number),
            vehicleId: values.vehicle && (values.vehicle.id as number),
            locationId: values.location && values.location.id,
            unitsCount: values.unitsCount,
            externalLinkages: values.externalLinkages,
            plannedTimeFrom: values.plannedTimeFrom
                ? moment(values.plannedTimeFrom).format('HH:mm')
                : undefined,
            plannedTimeTo: values.plannedTimeTo
                ? moment(values.plannedTimeTo).format('HH:mm')
                : undefined,
            routeId: values.route && (values.route.id as number),
        };
    }

    private readonly createTaskForm = (
        task: IAddTask | IEditTask,
        files: File[]
    ) => {
        if (task.serviceClassId === -1) {
            task.serviceClassId = undefined;
        }
        const form = new FormData();
        form.append(
            'task',
            new Blob([JSON.stringify(task)], { type: 'application/json' })
        );
        for (const file of files) {
            form.append('files', file);
        }
        return form;
    };

    private readonly handleCloseClick = () => {
        this.props.clearForm({ type: 'task' });
        this.props.closePreview(this.props.creatorLevel);
    };

    private readonly handleChangeMode = (mode: string) => {
        const { task } = this.props;
        const id = (task && task._meta.header.task.id.toString()) || '';
        this.props.activatePreview(sourceSetIds.tasks, id, 'task', mode, {
            type: 'preview',
            level: this.props.creatorLevel,
        });
        this.props.clearForm({ type: 'task' });
    };

    private readonly handleOnSuccess = (onSuccess: () => void) => () => {
        const {
            onlyNotAssigned,
            onlyNotPlanned,
            includeNotPlanned,
            fetchTasks,
            sourceSetDates,
        } = this.props;
        if (!sourceSetDates) {
            //handle update task if there is no tasks grid available
            onSuccess();
            return;
        }
        const fetchTasksParams = () => {
            return onlyNotPlanned
                ? {
                      onlyNotAssigned,
                      includeNotPlanned: true,
                  }
                : {
                      from: sourceSetDates.from,
                      to: sourceSetDates.to,
                      onlyNotAssigned,
                      includeNotPlanned,
                  };
        };
        fetchTasks(fetchTasksParams());
        onSuccess();
    };

    private readonly showSnackBarOnFailure = (
        message: string,
        onError: any
    ) => {
        const { enqueueSnackbar } = this.props;
        onError();
        enqueueSnackbar(message, { variant: 'error' });
        return undefined;
    };

    private readonly showDialogOnFailure =
        (values: FormikValues, onError: any) => () => {
            onError();
            return showTaskRoutesDialog({
                filter: {
                    activityCategoryId: values.activityCategory,
                    employeeId: values.employee.id,
                    vehicleId: values.vehicle.id,
                    date: values.plannedOn,
                },
                setFieldValue: setFieldValueRef,
            });
        };
}

export default compose(
    Themable,
    Connectable,
    withSnackbar
)(TaskCrud) as ComponentType<{}>;
