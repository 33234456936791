import React from 'react';

import { Tooltip } from '@material-ui/core';
import { InfoOutlined as InfoIcon } from '@material-ui/icons';

import { useStyles } from './Themable.hooks';

import TranslationHelper from '../../../../helpers/TranslationHelper';
import { sortAlphabetically } from '../../../../helpers/comparators';

const MULTIPLE_OBJECTS = 2;

interface IOwnProps {
    list: string[] | string | undefined;
}

const ListWithHoverCellRenderer = ({ list }: IOwnProps) => {
    const classes = useStyles();

    if (list === undefined) {
        return <></>;
    }

    const arrayList = Array.isArray(list)
        ? list.sort((a: string, b: string) => sortAlphabetically(a, b))
        : [list];
    const stringValue = arrayList.join(', ');

    return (
        <div style={{ display: 'flex' }}>
            {arrayList.length > MULTIPLE_OBJECTS && (
                <Tooltip title={TranslationHelper.translate(stringValue)}>
                    <div className={classes.icon} data-testid="icon">
                        <InfoIcon />
                    </div>
                </Tooltip>
            )}
            <div className={classes.cellValue}>{stringValue}</div>
        </div>
    );
};

export default ListWithHoverCellRenderer;
