import { Record } from 'immutable';
import RFIDLinkedObject from './RFIDLinkedObject';

class RFIDCard extends Record({
    id: undefined,
    externalId: undefined,
    description: undefined,
    code: undefined,
    cardNumber: undefined,
    type: undefined,
    group: undefined,
    groups: [],
    groupIds: [],
    linkedObject: undefined,
}) {
    static fromJson(options) {
        return new RFIDCard({
            id: options.id,
            externalId: options.externalId,
            description: options.description,
            code: options.code,
            cardNumber: options.cardNumber,
            type: options.type,
            group: options.group,
            groups: options.groups,
            groupIds: options.groupIds,
            linkedObject: new RFIDLinkedObject(options.linkedObject),
        });
    }

    toJson() {
        return this.toObject();
    }

    static toSelectOption(card) {
        if (card.value && card.title) {
            return card;
        }
        return {
            value: card.id,
            title: card.cardNumber,
        };
    }
}

export default RFIDCard;
