import AppConfig from '../constants/AppConfig';

import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { ICustomer } from '../state/login/types';

import { fetchCustomers, storeCustomersAction } from '../state/login';
import { TRootState } from '../store';

export const handleCustomers =
    (
        afterSuccess?: (data: ICustomer[]) => void
    ): ThunkAction<void, TRootState, null, Action<any>> =>
    (dispatch) => {
        const customers = AppConfig.instance.getConfigKey(
            AppConfig.PROPERTY_CUSTOMERS
        );

        if (customers?.length) {
            dispatch(storeCustomersAction({ data: customers }));
        } else {
            if (afterSuccess) {
                dispatch(fetchCustomers((data) => afterSuccess(data)));
            } else {
                dispatch(fetchCustomers());
            }
        }
    };
