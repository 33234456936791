import { Divider, Paper, Switch } from '@material-ui/core';
import React, { Component, ComponentType } from 'react';
import { compose } from 'redux';
import PaneHeader from '../../../../components/PaneHeader';
import { Connectable, TConnectableProps } from './Connectable.hoc';
import { Themable, TThemableProps } from './Themable.hoc';

import TranslationHelper from '../../../../helpers/TranslationHelper';
import CalibrationChart from './components/CalibrationChart';

type TProps = TThemableProps & TConnectableProps;

class CalibrationPane extends Component<TProps> {
    public handleCalibrationTypeChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        this.props.updateCalibrationChartType(
            event.target.checked ? 'distance' : 'time'
        );
    };

    public render() {
        const { classes, calibrationType } = this.props;

        return (
            <Paper className={classes.pane}>
                <div className={classes.wrapper}>
                    <PaneHeader
                        title={TranslationHelper.translate('Calibration')}
                        renderRightCustomControls={() => (
                            <div className={classes.switch}>
                                {TranslationHelper.translate('Time')}
                                <Switch
                                    color="default"
                                    onChange={this.handleCalibrationTypeChange}
                                    checked={calibrationType === 'distance'}
                                />
                                {TranslationHelper.translate('Distance (km)')}
                            </div>
                        )}
                    />
                    <Divider />
                    <CalibrationChart />
                </div>
            </Paper>
        );
    }
}

export default compose(Themable, Connectable)(CalibrationPane) as ComponentType;
