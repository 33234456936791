import apiClient from './apiClient';

import URI from 'urijs';
import AppConfig from '../../constants/AppConfig';

import { IPreviewAction } from '../../state/ui/discovery/types';
import { makeGenericUrl } from '../discovery/_shared/urlMakers';
import { INormalizedData } from '../../state/app/collections/dataTypes';
import { IPage } from '../../state/types';

export interface IUrl {
    normalizeQuery: () => string;
    addQuery: (name: string, value: any) => void;
}

const withCredentials = {
    withCredentials: true,
    xhrFields: {
        withCredentials: true,
    },
};

const sharedSettings = {
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    },
    contentType: 'application/json',
    ...withCredentials,
};

const formContentSettings = {
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    },
    contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
    ...withCredentials,
};

const plainContentSettings = {
    headers: {
        Accept: '*/*',
        'Content-Type': 'text/plain',
    },
    contentType: 'text/plain',

    type: 'text/plain',
    ...withCredentials,
};

const makeMultipartRequestSettings = (
    url: IUrl,
    method: string,
    data: any
) => ({
    headers: {
        Accept: 'application/json',
    },
    enctype: 'multipart/form-data',
    processData: false,
    contentType: false,
    cache: false,
    timeout: 300000,
    withCredentials: true,
    xhrFields: {
        withCredentials: true,
    },
    method,
    url,
    data,
});

export const makeGetRequestSettings = (url: IUrl) => ({
    ...sharedSettings,
    method: 'GET',
    url,
});

export const makeCredentialsOnlyGetRequestSettings = (url: IUrl) => ({
    ...withCredentials,
    method: 'GET',
    url,
});

export const makePostRequestSettings = (url: IUrl, body: object) => ({
    ...sharedSettings,
    method: 'POST',
    url,
    data: JSON.stringify(body),
});

export const makeFormContentPostRequestSettings = (
    url: IUrl,
    body: object
) => ({
    ...formContentSettings,
    method: 'POST',
    url,
    data: body,
});

export const makePlainContentPostRequestSettings = (
    url: IUrl,
    body: object
) => ({
    ...plainContentSettings,
    method: 'POST',
    url,
    data: JSON.stringify(body),
});

export const makePatchRequestSettings = (url: IUrl, body: object) => ({
    ...sharedSettings,
    method: 'PATCH',
    url,
    data: JSON.stringify(body),
});

export const makePostMultipartRequestSettings = (url: IUrl, body: object) =>
    makeMultipartRequestSettings(url, 'POST', body);

export const makePutRequestSettings = (
    url: IUrl,
    body: object,
    headers: object = {}
) => ({
    ...sharedSettings,
    headers: {
        ...sharedSettings.headers,
        ...headers,
    },
    method: 'PUT',
    url,
    data: JSON.stringify(body),
});

export const makePutMultipartRequestSettings = (url: IUrl, body: object) =>
    makeMultipartRequestSettings(url, 'PUT', body);

export const makeDeleteRequestSettings = (url: IUrl) => ({
    ...sharedSettings,
    method: 'DELETE',
    url,
});

export const makeCredentialsOnlyDeleteRequestSettings = (url: IUrl) => ({
    ...withCredentials,
    method: 'DELETE',
    url,
});

export const makeCustomRequestSetting = (
    previewAction: IPreviewAction,
    body?: object
) => {
    const url = makeGenericUrl(previewAction);
    return {
        ...sharedSettings,
        method: previewAction.method,
        url,
        data: JSON.stringify(body),
    };
};

export const makeGeoGetRequestSettings = (url: IUrl) => ({
    ...sharedSettings,
    headers: {
        ...sharedSettings.headers,
        Accept: 'application/vnd.geo+json',
    },
    method: 'GET',
    url,
});

export const restCustomRequest = (
    previewAction: IPreviewAction,
    body?: object
): Promise<null> =>
    apiClient.sendWithoutErrorsHandling(
        makeCustomRequestSetting(previewAction, body)
    );

export const makeRawFetch =
    <T>(makeUrlCallback: (...args: any[]) => IUrl) =>
    (...args: any[]): Promise<T> => {
        return apiClient.sendWithoutErrorsHandling(
            makeGetRequestSettings(makeUrlCallback(...args))
        );
    };
export const makeUrl = (url: IUrl, params: object = {}) => {
    Object.entries(params || {}).forEach(([name, value]) => {
        if (value == undefined) {
            return;
        }
        if (Array.isArray(value)) {
            value.forEach((v) => url.addQuery(name, v));
        } else {
            url.addQuery(name, value);
        }
    });
    return url;
};

export const makeEndpoint = (urlConst: string, suffix: string, params = {}) => {
    const url = new URI(AppConfig.instance.getUrl(urlConst) + suffix);
    return makeUrl(url, params);
};

const normalizeCollection = <T extends { id: number | string }>(
    data: { id: number | string }[],
    key: string = 'id'
): INormalizedData<T> => {
    return {
        result: data.map((item) => item[key].toString()),
        entities: data.reduce((result, next) => {
            result[next[key]] = next;
            return result;
        }, {}),
    };
};
export const makeRestReadCollection =
    <T extends { id: number }>(
        restGetCollection: (params?: any) => Promise<T[]>,
        key: string = 'id'
    ): ((params?: any) => Promise<INormalizedData<T>>) =>
    (params) =>
        restGetCollection(params).then((data) =>
            normalizeCollection<T>(data, key)
        );

export const makeRestReadPageCollection =
    <T extends { id: string }>(
        restGetCollection: (params?: any) => Promise<IPage<T>>,
        key: string = 'id'
    ): ((params?: any) => Promise<INormalizedData<T>>) =>
    (params) =>
        restGetCollection(params).then((data) =>
            normalizeCollection<T>(data.values, key)
        );
