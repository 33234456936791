import { useSelector } from 'react-redux';
import {
    getClientsAndLocationsMode,
    clientsAndLocationsPaneVisible,
    getSelectedClient,
    getSelectedLocation,
    getSelectedLocationId,
    getClientsAndLocationsStatus,
    getSelectedGridItemId,
    getOpenSectionClients,
    getOpenSectionLocations,
    isClientsAndLocationsInEditMode,
    getSelectedClientId,
    getClientDetailsLoading,
    getSelectedClientsAndLocations,
    getClientsEntities,
    getIsMapDialogOpen,
    getMapCoords,
    getLocationDetailsLoading,
    getMainMapVisible,
    getClientsAndLocationsSourceSetWithFilter,
    getClientsAndLocationsSourceSetModel,
    getClientsAndLocationsAsSourceSet,
    getValidRfidFilter,
    getMap,
} from './';

export const useSelectedLocationId = () => useSelector(getSelectedLocationId);
export const useLocationDetailsLoading = () =>
    useSelector(getLocationDetailsLoading);
export const useClientsCollections = () => useSelector(getClientsEntities);
export const useSelectedClientsAndLocations = () =>
    useSelector(getSelectedClientsAndLocations);
export const useSelectedLocation = () => useSelector(getSelectedLocation);
export const useSelectedClient = () => useSelector(getSelectedClient);
export const useSelectedClientId = () => useSelector(getSelectedClientId);
export const useSelectedGridItemId = () => useSelector(getSelectedGridItemId);
export const useClientDetailsLoading = () =>
    useSelector(getClientDetailsLoading);
export const useMode = () => useSelector(getClientsAndLocationsMode);
export const useClientAndLocationPaneVisible = () =>
    useSelector(clientsAndLocationsPaneVisible);
export const useIsClientAndLocationInEditMode = () =>
    useSelector(isClientsAndLocationsInEditMode);
export const useClientsAndLocations = () =>
    useSelector(getClientsAndLocationsAsSourceSet);
export const useClientAndLocationsSourceSetWithFilter = () =>
    useSelector(getClientsAndLocationsSourceSetWithFilter);
export const useClientsSourceSetModel = () =>
    useSelector(getClientsAndLocationsSourceSetModel);
export const useClientsAndLocationsStatus = () =>
    useSelector(getClientsAndLocationsStatus);
export const useOpenSectionClients = () => useSelector(getOpenSectionClients);
export const useOpenSectionLocations = () =>
    useSelector(getOpenSectionLocations);
export const useIsMapDialogOpen = () => useSelector(getIsMapDialogOpen);
export const useMapCoords = () => useSelector(getMapCoords);
export const useMainMapVisible = () => useSelector(getMainMapVisible);
export const useValidRfidFilter = () => useSelector(getValidRfidFilter);
export const useMap = () => useSelector(getMap);
